export default {
  loadingFlags: {
    listRoutes: false,
    listRouteGroups: false,
    fetchUserRegister: false,
    updateUnproductiveCallsLimit: false,
    update: false,
    updateRouteGroup: false,
    createRouteGroup: false,
    deleteRouteGroup: false,
  },
  alert: null,
  errors: {},
  errorFlags: {
    updateRouteGroup: {},
    createRouteGroup: {},
  },
  routes: [],
  routeGroups: [],
  filters: {},
  pagination: {
    telephony_route_groups: {
      per_page: 15,
      current_page: 1,
    },
    telephony_routes: {
      per_page: 15,
      current_page: 1,
      search: "",
    },
  },
  user: null,
}
