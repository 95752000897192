import axios from "axios";

export default {
  searchQualifications({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading", "searchQualifications");
    commit("setList", []);
    let params = {
      start_date: payload.startDate,
      end_date: payload.endDate,
      campaign_id: payload.campaign_id,
      agent_id:payload.agent_id
    }
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/qualifications/total`, { params }
        )
        .then(response => {
          commit("setList", response.data.data);
          resolve();
        })
        .catch(response => {
          commit("setAlert", {
            message: response.response.data.detail,
            type: "danger"
          });
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading", "searchQualifications");
        });
    });
  },

  findDeliveredCalls({ commit }, payload) {
    commit("setDeliveredCalls", []);
    commit("startLoading", "findDeliveredCalls");
    let params = {
      start_date: payload.startDate,
      end_date: payload.endDate,
      campaign_id: payload.campaign_id,
      agent_id:payload.agent_id,
      'trashed[0]': 'campaign'
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`/agent/statistics`, { params }
        )
        .then(response => {
          commit("setDeliveredCalls", response.data.data);
          resolve();
        })
        .catch(response => {
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading", "findDeliveredCalls");
        });
    });
  },
  downloadTable({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading", "downloadTable");

    return new Promise((resolve, reject) => {
      axios
        .get(`/qualifications/total/csv`, {
          params: {
            start_date: payload.startDate,
            end_date: payload.endDate,
            campaign_id: payload.campaignId,
            agent_id:payload.agentId,
            email: payload.email,
          }
        })
        .then(() => {
          commit("system/setSuccessMessage", "success_message.generate_report", { root: true });
          resolve();
        })
        .catch(response => {
          commit('system/setErrorMessage', 'Houve um problema ao gerar o relatório. Verifique a campanha e tente novamente mais tarde', { root: true })
          response.response.status == 422 &&
          response.response.data.errors &&
          commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading", "downloadTable");
        });
    });
  }
};
