<template>
  <div class="d-flex gap-4 pe-4">
    <div class="col-12 col-sm-8 col-md-9 justify-content-between">
      <collapse-progress>
        <accordion-step
          id="collapseSettings"
          :title="$t('general_settings')"
          icon="far fa-gear"
          :completed="completedGeneralSettings"
          :show="allCollapse.collapseSettings?.show"
          @toggle-collapse="toggleCollapse('collapseSettings')"
        >
          <general-settings
            :name="fields.name"
            @update:name="$emit('update:fields', { ...fields, name: $event })"
            :type-audio="fields.type_audio"
            @update:type-audio="$emit('update:fields', { ...fields, type_audio: $event })"
            :errors="errors"
            @toggle-collapse="toggleCollapse('collapseAudio')"
          />
        </accordion-step>
        <accordion-step
          id="collapseAudio"
          :title="$t('audio_capitalize')"
          icon="far fa-volume"
          :completed="completedAudioSettings"
          :disabled="!completedGeneralSettings"
          :show="allCollapse.collapseAudio?.show"
          @toggle-collapse="toggleCollapse('collapseAudio')"
        >
          <audio-settings
            :url-audio="fields.audio"
            @update:audio="$emit('update:fields', { ...fields, audio: $event })"
            @toggle-collapse="toggleCollapse('collapseKeys')"
          />
        </accordion-step>
        <accordion-step
          id="collapseKeys"
          :title="$t('keys_settings')"
          icon="far fa-star"
          :completed="completedKeysConfig"
          :disabled="!completedAudioSettings || !completedGeneralSettings"
          :show="allCollapse.collapseKeys?.show"
          @toggle-collapse="toggleCollapse('collapseKeys')"
        >
          <keys-settings
            :errors="errors"
            :keys="fields.keys"
            @update:keys="$emit('update:fields', { ...fields, keys: $event })"
            :redirect-id="fields.redirect_id"
            @update:redirect-id="$emit('update:fields', { ...fields, redirect_id: $event })"
            @toggle-collapse="toggleCollapse('collapseAdvanced')"
            :disable-next="!completedKeysConfig"
          />
        </accordion-step>
        <accordion-step
          id="collapseAdvanced"
          :title="$t('advanced_settings')"
          icon="far fa-sliders-simple"
          :completed="completedAdvancedConfig"
          :disabled="!completedKeysConfig || !completedAudioSettings || !completedGeneralSettings"
          :show="allCollapse.collapseAdvanced?.show"
          @toggle-collapse="toggleCollapse('collapseAdvanced')"
        >
          <advanced-config
            :timeout="fields.timeout"
            :errors="errors"
            @update:timeout="$emit('update:fields', { ...fields, timeout: $event })"
          />
        </accordion-step>
      </collapse-progress>

      <div class="col-12 ps-5 d-flex flex-column align-items-center">
        <app-button
          color="text-capitalize save"
          :class="!disableSubmit ?'btn-primary':'btn-light tx-text-gray' "
          :loading="loading.update"
          @click="$emit('submit')"
          :blocked="disableSubmit "
        >
          {{ $t("save") }}
        </app-button>
        <slot name="button" />
      </div>
    </div>
    <div class="col-12 col-sm-4 col-md-3  gap-4">
      <resume-progress
        v-if="completedAudioSettings || completedKeysConfig || completedAdvancedConfig"
        :fields="fields"
      />
      <empty-progress
        v-else
      />

      <modal-component
        :show="showModal"
        :center="true"
        @close="removeDefaultKey"
      >
        <template #content>
          <key-default-modal
            :redirect-id="fields.redirect_id"
            @update:redirect_id="$emit('update:fields', { ...fields, redirect_id: $event })"
            @close-modal="removeDefaultKey"
            @submit="$emit('submit')"
          />
        </template>
      </modal-component>
    </div>
  </div>
</template>

<script>
import CollapseProgress from "@components/collapse-progress.vue";
import AppButton from "@components/app-button.vue";
import EmptyProgress from "@dialer/manager/pages/settings/receptive_ivrs/components/empty-progress.vue";
import AccordionStep from "@components/accordion-step.vue";
import AdvancedConfig from "@dialer/manager/pages/settings/receptive_ivrs/components/advanced-config.vue";
import KeysSettings from "@dialer/manager/pages/settings/receptive_ivrs/components/keys-settings.vue";
import GeneralSettings from "@dialer/manager/pages/settings/receptive_ivrs/components/general-settings.vue";
import AudioSettings from "@dialer/manager/pages/settings/receptive_ivrs/components/audio-settings.vue";
import ResumeProgress from "@dialer/manager/pages/settings/receptive_ivrs/components/resume-progress.vue";
import ModalComponent from "@components/modal-component.vue";
import KeyDefaultModal from "@dialer/manager/pages/settings/receptive_ivrs/components/key-default-modal.vue";
import { mapGetters } from "vuex";

export default {
  name: "FormsReceptiveIvr",
  components: {
    KeyDefaultModal, ModalComponent,
    ResumeProgress,
    AudioSettings,
    GeneralSettings,
    KeysSettings,
    AdvancedConfig,
    AccordionStep,
    EmptyProgress,
    AppButton,
    CollapseProgress,
  },
  props: {
    fields: {
      type: Object,
      required: true,
    },
    showModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      allCollapse: {
        collapseSettings: {
          show: true,
        },
        collapseAudio: {
          show: false,
        },
        collapseKeys: {
          show: false,
        },
        collapseAdvanced: {
          show: false,
        },
      },
      currentCollapse: "collapseSettings",
    };
  },

  emits: ["update:fields", "update:show-modal", "submit"],

  computed: {
    ...mapGetters("manager/receptive_ivrs", {
      errors: "getErrors",
      loading: "getLoadingFlags",
    }),

    completedKeysConfig() {
      return !!(this.fields.keys.every(key => key.key && key.id));
    },
    completedGeneralSettings() {
      return !!(this.fields.type_audio && this.fields.name);
    },
    completedAudioSettings() {
      return !!(this.fields.audio);
    },
    completedAdvancedConfig() {
      return !!(this.fields.timeout);
    },
    disableSubmit() {
      return !this.completedGeneralSettings || !this.completedAudioSettings || !this.completedKeysConfig || !this.completedAdvancedConfig;
    },
  },

  methods: {
    toggleCollapse(collapse) {
      if (collapse !== this.currentCollapse) {
        this.allCollapse[this.currentCollapse].show = false
      }
      this.allCollapse[collapse].show = !this.allCollapse[collapse].show

      this.currentCollapse = collapse;
      if (!this.fields.timeout && collapse === "collapseAdvanced") {
        this.$emit("update:fields", { ...this.fields, timeout: 5 });
      }
    },
    removeDefaultKey() {
      this.$emit("update:show-modal", false)
      this.$emit("update:fields", {
        ...this.fields, redirect_id: "",
      });
    },
  },
}
</script>


<style scoped lang="scss">
:deep(.border-custom) {
  border: 1px solid $color-gray-blue-300 !important;
  border-radius: 0.625rem;
}

:deep(.btn-light) {
  color: $color-text-gray;
  background-color: $color-gray-blue-300;
}

.save {
  padding: 0.5rem 8.75rem 0.5rem 8.7rem;
}
</style>