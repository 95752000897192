<template>
  <div class="container-fluid">
    <header-component
      :title="$t('products_and_services')"
    >
      <template #buttons>
        <app-button
          style="width: 328px"
          @click="handleNewCategory"
          class="create-category"
        >
          {{ $t('new_category') }}
        </app-button>
      </template>
    </header-component>
    <categories-table />
    <scroll-to-top />
  </div>
</template>

<script>
import HeaderComponent from "@/components/header-component.vue";
import CategoriesTable from "@dialer/manager/pages/settings/products-and-services/components/categories-table.vue";
import AppButton from "@components/app-button.vue";
import {mapActions} from "vuex";
import ScrollToTop from "@components/scroll-to-top.vue";

export default {
  components: {
    ScrollToTop,
    AppButton,
    HeaderComponent,
    CategoriesTable
  },

  methods: {
    ...mapActions("manager/products_and_services", [
      "toggleModal",
      "toggleCategoryModal"
    ]),


    handleNewCategory() {
      this.toggleCategoryModal({
        title: this.$t('new_category'),
        button: this.$t('create_new_category'),
        type: "category",
        actionName: "createCategory",
        handleName: "handleCategoryData",
        data: {
          name: "",
          team_ids: [],
          is_active: true
        },
        dataState: "categoryDataState"
      })
      this.toggleModal(true)
    }
  }
};
</script>
