export default {
  startLoadingFlags: (state, flags) => state.loadingFlags[flags] = true,
  stopLoadingFlags: (state, flags) => state.loadingFlags[flags] = false,
  setErrors: (state, errors) => state.errors = errors,
  setList: (state, list) => state.list = list,
  clearList(state) {
    state.list = [];
  },
  setAllList: (state, allList) => state.allList = allList,
  setPagination: (state, pagination) => state.pagination = pagination,
  setCurrentPage: (state, page) => state.pagination.current_page = page,
  setPerPage: (state, perPage) => state.pagination.per_page = perPage,
  setFilters: (state, filters) => state.filters = filters,
  setIncludes: (state, include) => state.includes = include,
  setListCompanies: (state, listCompanies) => state.listCompanies = listCompanies,
}
