export default {
    loading: false,
    loadingContext: '',
    errorMessage: '',
    errors: {},
    list: [],
    campaing: null,

    dataSelect: [],
    activeDataSelect: []

}
