export default {
  startLoading: (state, flag) => state.loadingFlags[flag] = true,
  stopLoading: (state, flag) => state.loadingFlags[flag] = false,
  setStep: (state, step) => state.step = step,
  setErrors: (state, errors) => state.errors = errors,
  setPreAdjustedCsv: (state, preAdjustedCsv) => state.preAdjustedCsv = preAdjustedCsv,
  setHeaderCsv: (state, headerCsv) => state.headerCsv = headerCsv,
  setHeaderLabels: (state, headerLabels) => state.headerLabels = headerLabels,
  setValidationCsv: (state, validationCsv) => state.validationCsv = validationCsv,
  clearCsvList (state) {
    state.step = 0
  },
}
