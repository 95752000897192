<template>
  <div
    v-if="intervalAgentsList.length"
    class="card"
  >
    <div class="card-header">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-0">
          {{ $t('all_intervals') }}
        </h4>
        <button
          class="btn bg-gray-blue-300 tx-text-gray text-small"
          @click="openModal"
          style="width: 153px;height: 32px;line-height: 0"
        >
          {{ $t('download_table') }}
        </button>
      </div>
    </div>

    <div class="card-body p-0">
      <div class="table-responsive">
        <table class="table table-hover mb-0">
          <thead>
            <tr>
              <th> {{ $t('agents_capitalize') }} </th>
              <th>
                <sort-link
                  :title="$t('productive')"
                  :dir="sorts.productive.dir"
                  column="productive"
                  @sort="sort"
                />
              </th>
              <th>
                <sort-link
                  :title="$t('unproductive')"
                  :dir="sorts.unproductive.dir"
                  column="unproductive"
                  @sort="sort"
                />
              </th>
              <th>
                <sort-link
                  :title="$t('NR17')"
                  :dir="sorts.NR17.dir"
                  column="NR17"
                  @sort="sort"
                />
              </th>
              <th>
                <sort-link
                  :title="$t('uppercases.undefined')"
                  :dir="sorts.undefined.dir"
                  column="undefined"
                  @sort="sort"
                />
              </th>
              <th> {{ $t('uppercases.total_time') }} </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="agent in intervalAgentsList"
              :key="agent.id"
            >
              <td :title="agent.name">
                {{ agent.extension_number ? `${agent.extension_number} -` : "" }} {{ agent.name }}
              </td>
              <td>
                <interval-tooltip-card
                  :title="$t('productive')"
                  :intervals="agent.productive.intervals"
                  :duration="agent.productive.totalDuration"
                />
              </td>
              <td>
                <interval-tooltip-card
                  :title="$t('unproductive')"
                  :intervals="agent.unproductive.intervals"
                  :duration="agent.unproductive.totalDuration"
                />
              </td>
              <td>
                <interval-tooltip-card
                  :title="$t('NR17')"
                  :intervals="agent.NR17.intervals"
                  :duration="agent.NR17.totalDuration"
                />
              </td>
              <td>
                <interval-tooltip-card
                  :title="$t('capitalizes.undefined')"
                  :intervals="agent.undefined.intervals"
                  :duration="agent.undefined.totalDuration"
                />
              </td>
              <td class="tx-text-gray">
                {{ formatSecondsToMMSS(agent.totalDuration) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <interval-modal-csv
      ref="modalCsv"
      :campaign="campaign"
      :start-date="startDate"
      :end-date="endDate"
      :exceeded="exceeded"
    />
  </div>


  <card-loading
    v-if="loadingFlags.searchIntervals"
    :height="200"
  />
</template>

<script>
import { mapGetters } from "vuex";
import { formatSecondsToMMSS } from "@/utils.js";
import IntervalModalCsv from "@dialer/manager/pages/reports/intervals/components/interval-modal-csv.vue";
import SortLink from "@dialer/manager/components/sort-link.vue";
import IntervalTooltipCard from "@dialer/manager/pages/reports/intervals/components/interval-tooltip-card.vue";
import CardLoading from "@/components/card-loading.vue";

export default {
  name: "AllIntervalsTable",
  data() {
    return {
      intervalAgentsList: [],
      sorts: {
        productive: {
          dir: "",
        },
        unproductive: {
          dir: "",
        },
        NR17: {
          dir: "",
        },
        undefined: {
          dir: "",
        }
      },
    }
  },

  props: {
    showSearchMessage: {
      type: Boolean,
      default: true
    },

    campaign: {
      type: Number,
      default: 0
    },

    startDate: {
      type: String,
      default: ""
    },

    endDate: {
      type: String,
      default: ""
    },

    exceeded: {
      type: Boolean,
      default: false
    }
  },

  components: {
    IntervalTooltipCard,
    SortLink,
    IntervalModalCsv,
    CardLoading
  },

  computed: {
    ...mapGetters("manager/intervals_report", {
      list: "getList",
      loadingFlags: "getLoadingFlags"
    })
  },

  methods: {
    formatSecondsToMMSS,
    groupedAgentsInterval() {
      const agents = this.list.map(agent => {
        const getTypeData = (type) => {
          const intervals = agent.intervals.filter(interval => interval.type === type);
          const totalDuration = intervals.reduce((acc, curr) => acc + curr.duration, 0);
          return { totalDuration, intervals };
        };

        return {
          id: agent.id,
          name: agent.name,
          extension_number: agent.extension_number,
          NR17: getTypeData("NR17"),
          productive: getTypeData("productive"),
          unproductive: getTypeData("unproductive"),
          undefined: getTypeData("undefined"),
          totalDuration: agent.intervals.reduce((acc, curr) => acc + curr.duration, 0)
        };
      });

      this.intervalAgentsList = agents;
      this.tableAgent = agents.map(agent => {
        return agent.name;
      });
    },

    openModal() {
      this.$refs.modalCsv.openModal();
    },

    sort(data) {
      this.sorts.productive.dir = "";
      this.sorts.unproductive.dir = "";
      this.sorts.NR17.dir = "";
      this.sorts.undefined.dir = "";
      this.sorts[data.column].dir = data.dir;
      this.sortTable(data);
    },

    sortTable(data) {
      this.intervalAgentsList.sort((a, b) => {
        const sort = data.dir === "asc" ? 1 : -1;
        const valueA = a[data.column].totalDuration;
        const valueB = b[data.column].totalDuration;
        return valueA > valueB ? sort : -sort;
      });
    }
  },

  watch: {
    list() {
      this.groupedAgentsInterval();
    }
  }
}
</script>

<style lang="scss" scoped>
.card-empty-state {
  height: 200px;
}
</style>
