import busySignal from "@/assets/audio/busy-signal.mp3";
import ringing from "@/assets/audio/ringing.mp3";
import webWhatsapp from "@/assets/audio/web_whatsapp.mp3";

const audios = new Map();
audios.set("busy-signal", new Audio(busySignal));
audios.set("ringing", new Audio(ringing));
audios.set("web_whatsapp", new Audio(webWhatsapp));

export default {
  play(name, volume = 1, loop = false) {
    const audio = audios.get(name);
    audio.currentTime = 0;
    audio.volume = volume;
    audio.loop = loop;
    audio.play();
  },
  stop(name) {
    const audio = audios.get(name);
    audio.pause();
    audio.currentTime = 0;
  }
};
