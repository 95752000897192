<template>
  <modal-component
    :show="modelValue"
    @close="close"
  >
    <template #content>
      <div class="modal-header">
        <h3
          id="modalReport"
          class="modal-title"
        >
          {{ $t('generate_report') }}
          <span
            class="ml-2"
            v-if="reportType === 'responses'"
          >
            - {{ $t('responses') }}
          </span>
        </h3>
        <button
          aria-label="Close"
          class="btn-close px-4"
          data-bs-dismiss="modal"
          type="button"
        />
      </div>
      <div class="modal-body">
        <p>
          {{ $t('generate_report_2') }}
        </p>
        <form>
          <div class="mb-3">
            <label
              class="col-form-label"
              for="input-email"
            >
              {{ $t('report_email') }}
            </label>
            <input
              id="input-email"
              v-model="email"
              class="form-control"
              type="email"
            >
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-secondary"
          data-bs-dismiss="modal"
          type="button"
        >
          {{ $t('cancel_general') }}
        </button>
        <app-button
          class="btn btn-primary"
          type="button"
          @click="submit"
        >
          {{ $t('generate_csv') }}
        </app-button>
      </div>
    </template>
  </modal-component>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import ModalComponent from "@components/modal-component.vue";
import AppButton from "@components/app-button.vue";

export default {
  components: {AppButton, ModalComponent},
  props: {
    listId: {type: Number, required: true},
    modelValue: {type: Boolean, required: true},
    reportType: {type: String, required: true}
  },
emits: ['update:modelValue'],
  data () {
    return {
      modal: null,
      email: ''
    }
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser"
    }),
  },

  watch: {
    modelValue (){
      this.email = this.reportType === 'responses' ? this.user.email : null
    }
  },

  methods: {
    ...mapActions("manager/sms_dashboard", [
      "downloadTable",
      "downloadResponses",
    ]),

    open () {
      if (this.modal)
        this.modal.show()
      this.email = this.reportType === 'responses' ? this.user.email : null
    },

    close () {
      this.$emit('update:modelValue')
    },

    async submit () {
      if(this.reportType === 'responses'){
        await this.generateResponse()
      } else{
        await this.generateReport()
      }

      this.close()
    },

    async generateReport () {
      await this.downloadTable({
        email: this.email,
        listId: this.listId
      })
    },

    async generateResponse () {
      await this.downloadResponses({
        email: this.email,
        list_id: this.listId
      })
    },
  }
}
</script>
