<template>
  <modal-component
    :show="show"
    @close="show = false"
  >
    <template #content>
      <div class="modal-header">
        <h3 class="modal-title">
          {{ $t("generate_report") }}
        </h3>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div class="modal-body">
        <p>
          {{ $t("generate_report_2") }}
        </p>
        <form @submit.prevent="generateCSV">
          <div class="mb-3">
            <label
              for="input-email"
              class="col-form-label"
            >{{ $t("report_email") }}</label>
            <input
              type="email"
              class="form-control"
              id="input-email"
              v-model="email"
            >
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
        >
          {{ $t("cancel_general") }}
        </button>
        <app-button
          @click="generateCSV"
          :loading="loadingFlags.downloadTable"
          style="min-width: 6.5rem;"
        >
          {{ $t("generate_csv") }}
        </app-button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import AppButton from "@components/app-button.vue";
import { mapGetters, mapActions } from "vuex";
import ModalComponent from "@components/modal-component.vue";

export default {
  name: "IntervalModalCsv",
  components: { ModalComponent, AppButton },
  props: {
    startDate: {
      type: String,
      default: ""
    },
    endDate: {
      type: String,
      default: ""
    },
    campaign: {
      type: Number,
      default: 0
    },
    exceeded: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      email: "",
      show: false
    };
  },
  mounted() {
    this.email = this.user.email;
  },
  computed: {
    ...mapGetters("auth", {
      user: "getUser"
    }),
    ...mapGetters("manager/intervals_report", {
      loadingFlags: "getLoadingFlags"
    })
  },
  methods: {
    ...mapActions("manager/intervals_report", ["downloadTable"]),
    openModal() {
      this.show = true;
    },
    generateCSV() {
      this.downloadTable({
        startDate: this.startDate,
        endDate: this.endDate,
        campaign_id: this.campaign,
        exceeded: this.exceeded == false ? 0 : 1,
        csv: true,
        email: this.email
      }).then(() => (this.show = false));
    }
  }
};
</script>
