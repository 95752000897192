<template>
  <div class="not-logged-in-campaign d-flex position-absolute">
    <img
      src="@assets/img/illustrations/arrow-blue.svg"
      alt="blue arrow"
      class="arrow align-self-start"
    >
    <h2 class="mb-0 align-self-end">
      {{ $t('select_one_campaign_to_initiate') }}
    </h2>
  </div>
</template>

<script>
export default {
  name: "NotLoggedInCampaign"
}
</script>

<style lang="scss" scoped>
.not-logged-in-campaign {
  top: 24px;
  left: 160px;
  width: 350px;
  height: 86px;
  gap: 32px;

  h2 {
    width: 230px;
    text-align: left;
    rotate: -2deg;
    vertical-align: bottom;
  }

  .arrow {
    width: 60px;
  }
}
</style>
