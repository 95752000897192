<template>
  <textarea
    tabindex="0"
    :ref="refInput"
    class="form-control custom-input custom-textarea rounded tx-text-200 border-0"
    :class="customClass"
    rows="1"
    :value="modelValue"
    @input="handleInput"
  />
</template>

<script>
export default {
  name: 'InfiniteTextarea',
  emits: [
    'update:modelValue',
    'linesCount'
  ],

  props: {
    customClass: {
      type: String,
      default: null
    },
    modelValue: {
      type: [Number, String],
      required: true
    },
    refInput: {
      type: String,
      default: "textarea"
    }
  },

  mounted(){
    this.adjustRows()
  },

  methods: {
    handleInput(event) {
      this.adjustRows()
      const text = event.target.value
      this.$emit('update:modelValue', text)
    },

    adjustRows() {
      const element = this.$refs.input
      element.style.height = 'auto';
      element.style.height = (element.scrollHeight) + 'px';
      const rows = Math.ceil((element.scrollHeight - 18) / 24)
      this.$emit('linesCount', rows);
    },
  },
}
</script>

<style scoped lang="scss">
.custom-textarea {
  margin-right: 60px;
  resize: none;
}
</style>