import axios from "axios";

export default {
  getMailingList({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading");
    commit("setList", []);
    return new Promise((resolve, reject) => {
      axios
        .get("/mailing_list_sms", {params: {page:payload.page}})
        .then(response => {
          commit("setList", response.data.data);
          commit("setPagination", response.data.meta.pagination)
          resolve();
        })
        .catch(response => {
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },
  getChartData({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading");
    commit("setChartData", []);
    let params = {
      start_date: payload.startDate,
      end_date: payload.endDate
    };
    return new Promise((resolve, reject) => {
      axios
        .get("/sms/statistics/daily", { params })
        .then(response => {
          commit("setChartData", response.data.data);
          resolve();
        })
        .catch(response => {
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },
  downloadTable({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading");
    let params = {
      email: payload.email,
      list_id: payload.listId
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/mailing_list_sms/csv`, { params })
        .then(() => {
          commit('system/setSuccessMessage', "success_message.generate_report", { root: true })
          resolve();
        })
        .catch(response => {
          commit('system/setErrorMessage', 'Houve um problema ao gerar o relatório. Verifique a campanha e tente novamente mais tarde', { root: true })
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },
  downloadResponses({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading");
    let params = {
      email: payload.email,
      list_id: payload.list_id
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/mailing_list_sms/reply-csv`, { params })
        .then(() => {
          commit('system/setSuccessMessage', "success_message.generate_report", { root: true })
          resolve();
        })
        .catch(response => {
          commit('system/setErrorMessage', 'Houve um problema ao gerar o relatório. Verifique a campanha e tente novamente mais tarde', { root: true })
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },
  buySms({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading");
    return new Promise((resolve, reject) => {
      axios
        .post(`/message_sms/buy`, {amount_sms: payload.amount_sms})
        .then((response) => {
          commit('system/setSuccessMessage', response.data.detail, { root: true })
          resolve();
        })
        .catch(response => {
          if (response.response.status == 422 && response.response.data.errors)
            commit('setErrors', response.response.data.errors);
          else
            commit('system/setErrorMessage', response.response.data, { root: true })
          reject({ message: response.response.data.detail, errors: response.response.data.errors });
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },
  deleteMailingList({commit}, payload) {
    commit("clearErrors");
    commit("startLoading");
    return new Promise((resolve, reject) => {
      axios
        .delete(`/mailing_list_sms/${payload.id}`)
        .then(() => {
          commit('system/setSuccessMessage', 'delete_list_success', { root: true })
          resolve();
        })
        .catch(response => {
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },
  uploadMailingList({ commit }, payload){
    commit("clearErrors");
    commit("startLoading");
    commit("setListHeaderPreview", []);
    commit('setFileName', "");
    commit('setloadingContext', 'create');
    return new Promise((resolve, reject) => {
      axios
        .post('/mailing_list_sms/upload', payload.formData)
        .then((response) => {
          commit('setListDataPreview', response.data.data);
          commit('setFileName', response.data.file_name);
          if (response.data.header.length > 0)
            commit('setListHeaderPreview', response.data.header);
          else
            commit('setListHeaderPreview',
              new Array(response.data.data[0].length).fill("")
            );
        })
        .catch(response => {
          commit("system/setErrorMessage", "errors_message.validate_csv", { root: true })
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit('setloadingContext', '');
          commit("stopLoading");
        });
    });
  },
  validateMailingList({ commit }, payload){
    commit("clearErrors");
    commit("startLoading");
    commit("setValidatedMailingListData", [])
    return new Promise((resolve, reject) => {
      axios
        .post('/mailing_list_sms/validate',
         {name: payload.name,
          schedule: payload.schedule,
          mailing: payload.mailing,
          separator: payload.separator,
          delimiter: payload.delimiter,
          has_header: payload.has_header,})
        .then((response) => {
          commit("setValidatedMailingListData", response.data.data)
          resolve()
        })
        .catch(response => {
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },
  createMailingList({ commit }, payload){
    commit("clearErrors");
    commit("startLoading");
    return new Promise((resolve) => {
      axios
        .post('/mailing_list_sms',
         {data: payload.data,
          delimiter: payload.delimiter,
          has_header: payload.has_header,
          file_name: payload.file_name,
          header: payload.header,
          mailing: payload.mailing,
          message: payload.message,
          name: payload.name,
          schedule: payload.schedule,
          separator: payload.separator,
        })
        .then(() => {
          commit('system/setSuccessMessage', "success_message.create_mailing_list", { root: true })
          resolve()
        })
        .catch(response => {
          commit('system/setErrorMessage', response.response.data, { root: true })
        })
        .finally(() => {
          commit('setloadingContext', '');
          commit("stopLoading");
        });
    });
  },
  startMailingList({ commit }, payload){
    commit("clearErrors");
    commit("startLoading");
    commit("setStartedList", [])
    return new Promise((resolve, reject) => {
      axios
        .put(`/mailing_list_sms/${payload.id}/start_list` )
        .then((response) => {
          commit("system/setSuccessMessage",  "success_message.started", { root: true })
          commit("setStartedList", response.data.data)
          resolve()
        })
        .catch(response => {
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit('setloadingContext', '');
          commit("stopLoading");
        });
    });
  },
};
