import store from "@store";

import VoicePanel from "@dialer/manager/pages/voice/voice-panel";

export default {
  path: "voice-panel",
  name: "voice-panel",
  components: {
    main: VoicePanel
  },
  beforeEnter: (to, from, next) => {
    if (store.getters["auth/isSupervisorOrManager"]) next();
    else next({
      name: "not-found",
      params: {
        pathMatch: to.path.split('/').slice(1)
      }
    });
  }
};
