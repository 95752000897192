import axios from "axios";
import moment from "moment";

export default {
  /**
   * @param {Object} state
   * @returns {void}
  */
  submit({ commit }, payload) {
    commit("startLoading", "sendNps");
    return new Promise((resolve, reject) => {
      axios
        .post("/users/nps", payload)
        .then(() => {
          commit("setShowNps", false);
          commit("system/setSuccessMessage", "success_message.send_feedback", { root: true});
          commit("setClosedAt", null);
          // update lastNps
          commit("auth/setUserProperty", { property: 'last_nps', value: moment().format() }, { root: true});

          resolve();
        })
        .catch((response) => {
          commit("system/setErrorMessage", response.response.data, { root: true});
          reject();
        })
        .finally(() => {
          commit("stopLoading", "sendNps");
        });
    });
  },

  showNps({ commit }) {
    commit("setShowNps", true);
  },

  closeNpsWhenOpen({ commit, state, dispatch }) {
    let currDate = moment().format();

    if(state.showNps) {
      commit("setShowNps", false);
      commit("setClosedAt", currDate);
      dispatch("showNpsAfterClosed")
    } 
  },

  showNpsAfterClosed({ commit, state }) {
    let currDate = moment();
    let diffNpsClosedAt = currDate.diff(state.closedAt, "milliseconds");
    let timeToNpsAppearInMilliseconds = 12 * 60 * 60 * 1000; // 12 hours
    let timeLeft = timeToNpsAppearInMilliseconds - diffNpsClosedAt;

    if(timeLeft > 0) setTimeout(() => commit("setShowNps", true), timeLeft);
    else commit("setShowNps", true);
  },
} 
