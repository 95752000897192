<template>
  <div class="table-responsive">
    <div
      class="col-lg-12 vertical-center"
      v-if="loading?.findById && loading.remove"
    >
      <div class="spinner-border text-muted vertical-cente">
        <span class="visually-hidden" />
      </div>
      <br>
      <span class="text-muted"> {{ $t("loading_capitalize") }}... </span>
    </div>

    <table
      class="table mb-0"
      v-else
    >
      <thead style="border-top: none">
        <tr>
          <th scope="col col-md">
            {{ $t("uppercases.name") }}
          </th>
          <th scope="col">
            {{ $t("description") }}
          </th>
          <th scope="col" />
        </tr>
      </thead>
      <tbody style="border-top: none">
        <template v-if="criterials.length">
          <tr
            v-for="item in criterials"
            :key="item.id"
          >
            <td style="">
              <dot-status
                :color="item.color"
                size="12px"
              />
              {{ item.name }}
            </td>
            <td>
              <div class="overflow-eellipsis">
                {{ item.description }}
              </div>
            </td>

            <td style="width: 95px">
              <button
                class="btn text-secondary btn-sm"
                @click="open(item)"
              >
                <span class="fe fe-edit" />
              </button>

              <button
                class="btn text-danger btn-sm"
                @click="remove(item)"
              >
                <span class="fe fe-trash" />
              </button>
            </td>
          </tr>
        </template>
        <empty-state
          v-else-if="showEmptyState"
          :title="$t('no_feedback')"
          icon="feedback.svg"
          :sub-title="$t('no_feedback_description')"
        >
          <template #buttons>
            <button
              class="btn-light btn btn-sm px-5"
              @click="create()"
            >
              {{ $t("add_feedback") }}
            </button>
          </template>
        </empty-state>
        <tr v-else>
          <td
            class="text-center text-muted"
            colspan="3"
          >
            <span class="fe fe-alert-octagon" />
            {{ $t("no_feedback") }}
          </td>
        </tr>
      </tbody>
    </table>

    <form-criteria ref="formCriteria" />
  </div>
</template>

<script>
import formCriteria from "./form-criteria.vue";
import { mapGetters, mapActions } from "vuex";
import DotStatus from "@/components/dot-status";
import EmptyState from "@components/empty-state.vue";

export default {
  components: {
    EmptyState,
    formCriteria,
    DotStatus,
  },

  props: {
    criterials: {
      default: () => {
        return [];
      },
      type: Array,
    },
    showEmptyState: {
      default: false,
      type: Boolean,
    },
  },

  computed: {
    ...mapGetters("manager/criterion", {
      register: "getRegister",
      loading: "getLoadingFlags",
    }),
  },

  methods: {
    ...mapActions("system", ["showConfirmationMessage"]),
    ...mapActions("manager/criterion", [
      "removeCriteria",
      "findById",
      "clearErrors",
    ]),
    create() {
      this.$refs.formCriteria.new();
    },
    open(item) {
      this.$refs.formCriteria.edit(item);
    },

    async remove(item) {
      let response = await this.showConfirmationMessage({
        title: this.$t("delete_feedback_question"),
        text: this.$t("delete_feedback_confirmation"),
        type: "badWarning",
      });

      if (response.isConfirmed) {
        this.removeCriteria({
          list_id: item.list_id,
          id: item.id,
        }).then(() => {
          this.findById({ id: this.register.id });
        });
      }
    },
  },
};
</script>
