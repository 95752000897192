<template>
  <div class="row">
    <div class="col-12 order-2 order-lg-1 col-lg-8 col-xl-9 justify-content-between">
      <collapse-progress>
        <accordion-step
          id="collapseSettings"
          :title="$t('general_settings')"
          icon="far fa-gear"
          :completed="completedGeneralSettings"
          :show="allCollapse.collapseSettings?.show"
          @toggle-collapse="toggleCollapse('collapseSettings')"
        >
          <general-settings
            :name="fields.name"
            @update:name="$emit('update:fields', { ...fields, name: $event })"
            :office-hour="fields.office_hour"
            @update:office-hour="$emit('update:fields', { ...fields, office_hour: $event })"
            :type-audio="fields.type_audio"
            @update:type-audio="$emit('update:fields', { ...fields, type_audio: $event })"
            :errors="errors"
            :disable-next="!completedGeneralSettings"
            @toggle-collapse="toggleCollapse('collapseAudio')"
          />
        </accordion-step>
        <accordion-step
          id="collapseAudio"
          :title="$t('audio_capitalize')"
          icon="far fa-volume"
          :completed="completedAudioSettings"
          :disabled="!completedGeneralSettings"
          :show="allCollapse.collapseAudio?.show"
          @toggle-collapse="toggleCollapse('collapseAudio')"
        >
          <audio-settings
            :url-audio="fields.audio"
            @update:audio="$emit('update:fields', { ...fields, audio: $event })"
            @toggle-collapse="toggleCollapse('collapseKeys')"
          />
        </accordion-step>
        <accordion-step
          id="collapseKeys"
          :title="$t('keys_settings')"
          icon="far fa-star"
          :completed="completedKeysConfig"
          :disabled="!completedAudioSettings || !completedGeneralSettings"
          :show="allCollapse.collapseKeys?.show"
          @toggle-collapse="toggleCollapse('collapseKeys')"
        >
          <keys-settings
            :errors="errors"
            :keys="fields.keys"
            @update:keys="$emit('update:fields', { ...fields, keys: $event })"
            :redirect-type="fields.redirect_type"
            @update:redirect-type="$emit('update:fields', { ...fields, redirect_type: $event })"
            :instances="fields.instances[0]"
            @update:instances="$emit('update:fields', { ...fields, instances: $event })"
            @toggle-collapse="toggleCollapse('collapseAdvanced')"
            :disable-next="!completedKeysConfig"
          />
        </accordion-step>
        <accordion-step
          id="collapseAdvanced"
          :title="$t('advanced_settings')"
          icon="far fa-sliders-simple"
          :completed="completedAdvancedConfig"
          :disabled="!completedKeysConfig || !completedAudioSettings || !completedGeneralSettings"
          :show="allCollapse.collapseAdvanced?.show"
          @toggle-collapse="toggleCollapse('collapseAdvanced')"
        >
          <advanced-config
            :wait-time="fields.wait_time"
            :errors="errors"
            @update:wait-time="$emit('update:fields', { ...fields, wait_time: $event })"
          />
        </accordion-step>
      </collapse-progress>

      <div class="col-12 ps-5 d-flex flex-column align-items-center gap-4">
        <app-button
          color="text-capitalize save"
          :class="!disableSubmit ?'btn-primary':'btn-light tx-text-gray' "
          :loading="loading.update"
          @click="$emit('submit')"
          :blocked="disableSubmit "
        >
          {{ $t("save") }}
        </app-button>
        <slot name="button" />
      </div>
    </div>
    <div class="col-12 order-1 order-lg-2 col-lg-4 col-xl-3 gap-4 resume-progress">
      <resume-progress
        v-if="completedAudioSettings || completedAdvancedConfig"
        :fields="fields"
      />
      <empty-progress
        v-else
      />
    </div>
  </div>
</template>

<script>
import CollapseProgress from "@components/collapse-progress.vue";
import AppButton from "@components/app-button.vue";
import EmptyProgress from "@dialer/manager/pages/settings/ivrs/components/empty-progress.vue";
import AccordionStep from "@components/accordion-step.vue";
import GeneralSettings from "@dialer/manager/pages/settings/ivrs/components/general-settings.vue";
import AudioSettings from "@dialer/manager/pages/settings/ivrs/components/audio-settings.vue";
import KeysSettings from "@dialer/manager/pages/settings/ivrs/components/keys-settings.vue";
import AdvancedConfig from "@dialer/manager/pages/settings/ivrs/components/advanced-config.vue";
import ResumeProgress from "@dialer/manager/pages/settings/ivrs/components/resume-progress.vue";
import { mapGetters } from "vuex";

export default {
  name: "FormActiveIvr",
  components: {
    ResumeProgress,
    AudioSettings,
    GeneralSettings,
    KeysSettings,
    AdvancedConfig,
    AccordionStep,
    EmptyProgress,
    AppButton,
    CollapseProgress,
  },
  props: {
    fields: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      allCollapse: {
        collapseSettings: {
          show: true,
        },
        collapseAudio: {
          show: false,
        },
        collapseKeys: {
          show: false,
        },
        collapseAdvanced: {
          show: false,
        },
      },
      currentCollapse: "collapseSettings",
      WABA_INSTANCE_TYPE_ID: 2,
      ULTRAMSG_INSTANCE_TYPE_ID: 1,
      OMNI_REDIRECT_ID: 2,
      DIALER_REDIRECT_ID: 1,
    };
  },

  emits: ["update:fields", "update:show-modal", "submit"],

  computed: {
    ...mapGetters("manager/ivrs", {
      errors: "getErrors",
      loading: "getLoadingFlags",
    }),

    completedKeysConfig() {
      if (this.fields.redirect_type === this.DIALER_REDIRECT_ID) {
        return true;
      }
      if (this.fields.redirect_type === this.OMNI_REDIRECT_ID) {
        if (this.fields.instances[0]?.type === this.ULTRAMSG_INSTANCE_TYPE_ID) {
          return !!(this.fields.instances[0].quick_message_id && this.fields.instances[0].message);
        }
        if (this.fields.instances[0]?.type === this.WABA_INSTANCE_TYPE_ID) {
          return !!(this.fields.instances[0].template_id);
        }
      }
      return false;
    },
    completedGeneralSettings() {
      return !!(this.fields.type_audio && this.fields.name && this.fields.office_hour);
    },
    completedAudioSettings() {
      return !!(this.fields.audio);
    },
    completedAdvancedConfig() {
      return !!(this.fields.wait_time);
    },
    disableSubmit() {
      return !this.completedGeneralSettings || !this.completedAudioSettings || !this.completedKeysConfig || !this.completedAdvancedConfig;
    },
  },

  methods: {
    toggleCollapse(collapse) {
      if (collapse !== this.currentCollapse) {
        this.allCollapse[this.currentCollapse].show = false
      }
      this.allCollapse[collapse].show = !this.allCollapse[collapse].show

      this.currentCollapse = collapse;
      if (!this.fields.wait_time && collapse === "collapseAdvanced") {
        this.$emit("update:fields", { ...this.fields, wait_time: 5 });
      }
    },
  },
}
</script>


<style scoped lang="scss">
:deep(.border-custom) {
  border: 1px solid $color-gray-blue-300 !important;
  border-radius: 0.625rem;
}

:deep(.btn-light) {
  color: $color-text-gray;
  background-color: $color-gray-blue-300;
}

.save {
  padding: 0.5rem 8.75rem 0.5rem 8.7rem;
}

@media (max-width: 992px) {
  .resume-progress {
    padding: 0 0.75rem 0 3.25rem;
  }
}
</style>