
import axios from "axios";

export default {

  /**
*
* @param {*} param0
* @param {*} payload
*/
  loadDataSelect({ commit }) {
    commit('startLoading')
    commit('setDataSelect', null);
    return new Promise((resolve, reject) => {
      //TODO use filters
      axios.get('/routes', { params: { per_page: '1000' } })
        .then((response) => {
          commit('setDataSelect', response.data.data);
          resolve();
        }).catch((response) => {
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('stopLoading')
        });
    });
  },
}

