export default {
  startLoadingFlag: (state, loadingFlag) => state.loadingFlag[loadingFlag] = true,
  stopLoadingFlag: (state, loadingFlag) => state.loadingFlag[loadingFlag] = false,
  clearErrors: (state) => state.errors = {},
  startLoading: state => state.loading = true,
  stopLoading: state => state.loading = false,
  setList: (state, list) => state.list = list,
  clearList: state => state.list = [],
  setCallQualificated: (state, callQualificated) => state.callQualificated = callQualificated,
  setErrors: (state, errors) => state.errors = errors,
  setErrorsQualification: (state, errors) => state.errorsQualification = errors,
  clearErrorsQualification: (state) => {
    state.errorsQualification = {}
  },
  setPagination: (state, pagination) => state.pagination = pagination,
  setAlert: (state, alert) => state.alert = alert,
  setRegister: (state, register) => state.register = register,
  setQualificationRegister: (state, qualificationRegister) => state.qualificationRegister = qualificationRegister,
  setInfiniteQualification: (state, qualification) => state.qualificationRegister = state.qualificationRegister.concat(qualification),
  setQualificationPagination: (state, qualificationPagination) => state.qualificationPagination = qualificationPagination,
  setDataSelect: (state, dataSelect) => state.dataSelect = dataSelect,
  setQualificationListDataSelect: (state, qualificationListDataSelect) => state.qualificationListDataSelect = qualificationListDataSelect,
  setEachQualificationListDataSelect: (state, eachQualificationListDataSelect) => state.eachQualificationListDataSelect = eachQualificationListDataSelect
}
