<template>
  <div class="table-responsive">
    <table class="table table-nowrap card-table">
      <thead>
        <tr>
          <th>{{ $t("partners_extract.table.history") }}</th>
          <th>{{ $t("partners_extract.table.date") }}</th>
          <th>{{ $t("partners_extract.table.value") }}</th>
          <th>{{ $t("partners_extract.table.status") }}</th>
          <th>{{ $t("partners_extract.table.balance") }}</th>
        </tr>
      </thead>

      <tbody>
        <loading-table-component
          v-if="loading"
          :columns="5"
          :lines="15"
          :list-width="[200, 100, 100, 200, 100]"
        />
        <template v-else>
          <template v-if="!items?.length">
            <tr>
              <td
                colspan="5"
                class="text-center"
              >
                <div>
                  <div class="empty-state">
                    <img
                      width="196"
                      height="147"
                      loading="lazy"
                      alt="Empty"
                      src="@assets/img/empty-box.svg"
                      class="empty-img"
                    >
                  </div>

                  <p class="tx-text-300 mb-2 fw-bolder fs-4">
                    {{ $t('empty_filter') }}
                  </p>
                  <p class="tx-text-gray fs-5">
                    {{ $t('try_another_date') }}
                  </p>
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr
              v-for="(item, index) in items"
              :key="`item-${index}`"
            >
              <td>
                <div
                  style="gap: 0.5rem"
                  class="d-flex align-items-center line-clamp"
                >
                  <icon-card
                    :variant="getIcon(item.status).icon"
                    rounded="rounded-circle"
                    :color="getIcon(item.status).color"
                    :bg="getIcon(item.status).bg"
                  />
                  <span class="tx-text-200">{{ item.name }}</span>
                </div>
              </td>
              <td class="tx-text-gray">
                {{ formatDate(item.date) }}
              </td>
              <td>
                <div class="d-flex flex-column justify-content-center">
                  <span class="tx-text-gray">
                    {{ formatCurrency(parseFloat(item.value)) }}
                  </span>
                  <span
                    style="font-size: 0.75rem"
                    class="tx-text-200"
                    v-if="item?.percentage"
                  >
                    {{ $t("partners_extract.commission") }}:
                    {{ item.percentage }}%
                  </span>
                </div>
              </td>
              <td>
                <div
                  class="status-badge"
                  :class="getStatus(item.status)"
                >
                  {{ $t(`partners_extract.status.${formatStatus(item.status)[0]}`) }}
                </div>
              </td>
              <td>
                <balance-component
                  :show-status="showStatus(item.status)"
                  :status="formatState(item.status)"
                  :value="item.balance"
                />
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script>
import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";
import IconCard from "@components/icon-card.vue";
import i18n from "@/mixins/i18n";
import {Utils} from "@/utils";
import BalanceComponent from "@partner/pages/component/balance-component.vue";


export default {
  name: "ExtractTable",
  components: {
    BalanceComponent,
    IconCard,
    LoadingTableComponent
  },

  props: {
    items: {
      type: Array,
      required: true
    },

    loading: {
      type: Boolean,
      default: false
    }
  },
  mixins: [i18n],

  data() {
    return {
      extract: {
        PENDING_STATUS: 'Análise pendente',
        SUCCEED_STATUS: 'Aprovado',
        REFUSED_STATUS: 'Recusado',
      },
      bills: {
        PENDING_STATUS: 'PENDING',
        PAID_STATUS: 'PAID',
        CANCELED_STATUS: 'CANCELED',
        OVERDUE_STATUS: 'OVERDUE',
        WAITING_STATUS: 'WAITING',
        SCHEDULED_STATUS: 'SCHEDULED',
      }
    }
  },

  methods: {
    formatCurrency: Utils.formatCurrency,

    showStatus(status) {
      const formattedStatus = this.formatStatus(status);
      return !(formattedStatus[1] === this.extract.REFUSED_STATUS || formattedStatus[1] === this.bills.CANCELED_STATUS);
    },

    formatState(status) {
      const formattedStatus = this.formatStatus(status);

      if (formattedStatus[1] === this.extract.REFUSED_STATUS || formattedStatus[1] === this.bills.CANCELED_STATUS || formattedStatus[1] === this.extract.SUCCEED_STATUS) {
        return -1;
      }

      if (formattedStatus[1] === this.bills.PAID_STATUS) {
        return 1;
      }

      return 0;
    },

    formatStatus(status) {

      let res = Object.entries(this.extract).find((stats) => stats[1] === status)

      if (!res) {
        res = Object.entries(this.bills).find((stats) => stats[1] === status)
      }

      return res
    },

    getIcon(status) {
      if (status === this.extract.PENDING_STATUS || status === this.extract.SUCCEED_STATUS || status === this.extract.REFUSED_STATUS) {
        return {
          icon: 'fa-regular fa-dollar',
          color: '#015D0D',
          bg: '#c9f2cd52 !important',
        }
      }

      return {
        icon: 'fa-regular fa-building payment-color',
        color: '##3057F2 !important',
        bg: '#e1e9f452 !important'
      }
    },


    formatDate(date) {
      return this.formatLocaleDate(date, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      })
    },

    getStatus(status) {

      const classes = {
        PENDING_STATUS: 'info',
        PAID_STATUS: 'success',
        CANCELED_STATUS: 'danger',
        OVERDUE_STATUS: 'danger',
        WAITING_STATUS: 'info',
        SCHEDULED_STATUS: 'info',
        SUCCEED_STATUS: 'success',
        REFUSED_STATUS: 'danger'
      }
      return classes[this.formatStatus(status)[0]]
    }
  },
}
</script>
<style scoped lang="scss">
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.status-badge {
  font-size: 0.75rem;
  padding: 0.5rem;
  display: block;
  text-align: center;
  border-radius: 6px;
  text-transform: uppercase;

  &.success {
    background-color: #C9F2CD;
    color: #015D0D;
  }

  &.info {
    background-color: #E1E9F4;
    color: #1A4A89;
  }

  &.danger {
    background-color: #FFD7D9;
    color: #8F0000;
  }
}


.empty {
  &-state {
    padding: 22px 32px 14px 32px;
    display: inline-block;
  }

  &-img {
    display: block;
  }
}
</style>