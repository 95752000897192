<template>
  <div
    class="d-flex align-items-center"
    style="gap: 8px"
  >
    <button
      v-if="opportunity?.status === 'deleted'"
      class="btn btn-default d-flex align-items-center justify-content-center"
      @click="showRestoreModal = true"
    >
      {{ $t('reactivate') }}
    </button>
    <template v-else>
      <button
        :class="status.gain === 'gain' ? 'btn-gain' : 'btn-default'"
        :disabled="status.lost !== ''"
        class="btn gain p-0 lost-gain-btns fs-6"
        @click="toggleGainStatus(gainQualification.id, 'gain')"
      >
        {{ $t('gain') }}
      </button>
      <button
        ref="lostDropdown"
        :class="lostBtnClass"
        :disabled="status.gain === 'gain'"
        class="btn lost p-0 lost-gain-btns fs-6"
        @click="toggleLostStatus"
      >
        {{ $t('lost') }}
      </button>
      <div class="dropdown-menu lost-reasons-dropdown">
        <div class="d-flex flex-column h-100">
          <div class="dropdown-header">
            <h4>{{ $t('reason') }}</h4>
          </div>
          <div class="dropdown-body h-100 d-flex flex-column overflow-hidden">
            <div class="options-menu d-flex justify-content-between align-items-center gap-4">
              <div class="search-container flex-grow-1 position-relative">
                <i class="fa-regular fa-search fs-6 tx-text-gray position-absolute" />
                <input
                  v-model="reasonFilter"
                  :placeholder="$t('search_capitalize')"
                  class="form-control fs-6 border-0"
                  type="text"
                >
              </div>
              <button
                :class="{'active': hasInternalNote}"
                class="btn internal-note d-flex justify-content-center align-items-center"
                @click="toggleInternalNote"
              >
                <i
                  :class="hasInternalNote ? 'fa-xmark' : 'fa-note'"
                  class="fa-regular fs-5"
                />
              </button>
            </div>
            <textarea
              v-if="hasInternalNote"
              ref="textarea"
              v-model="internalNote"
              :placeholder="$t('internal_note')"
              class="px-3 py-3 text-medium rounded-3 note-context w-100 my-2 border-0"
              data-test="textarea-field"
            />
            <div
              id="reasons-container"
              class="d-flex flex-column reasons-container"
            >
              <div
                v-for="qualification in lostQualification"
                :key="qualification.id"
                class="qualification-option d-flex align-items-center flex-shrink-0 pe-2"
              >
                <div
                  :title="qualification.name"
                  class="qualification d-flex align-items-center ps-3 cursor-pointer h-100 w-100 rounded-2"
                  @click="_qualifyOpportunity(qualification.id, 'lost', 'success_message.qualify_opportunity')"
                >
                  <span
                    :style="{'background': qualification.color}"
                    class="handler-styles"
                  />
                  <p class="mb-0 multi-line-truncate">
                    {{ qualification.name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <button
      ref="moreOptionsDropdown"
      :class="{'active': showMoreOptionsDropdown}"
      :disabled="disableDeleteOportunity"
      class="btn more-options-btn d-flex align-items-center justify-content-center lost-gain-btns"
      @click="toggleMoreOptions"
    >
      <i class="fa-regular fa-ellipsis-vertical" />
    </button>
    <div class="dropdown-menu dropdown-menu-contact gap-2 px-2">
      <div
        class="dropdown-item delete-item d-flex align-items-center gap-3 cursor-pointer rounded-2"
        @click="showDeleteModal = true"
      >
        <i
          class="fa-regular fa-trash"
          style="color: #DE033A"
        />
        <p
          class="m-0 fs-6"
          style="color: #F23F2C"
        >
          {{ $t('exclude_opportunity') }}
        </p>
      </div>
    </div>
  </div>

  <mark-off-opportunity-modal
    :show="showMarkOffModal"
    @close="closeMarkOffModal"
    @mark-off="_qualifyOpportunity"
  />

  <restore-or-delete-opportunity-modal
    :show="showDeleteModal"
    @close="closeDeleteModal"
    @delete="_qualifyOpportunity(null, 'deleted', 'success_message.delete_opportunity')"
  />

  <restore-or-delete-opportunity-modal
    :show="showRestoreModal"
    scope="restore"
    @close="closeRestoreModal"
    @restore="_qualifyOpportunity"
  />
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {Dropdown} from "bootstrap";
import MarkOffOpportunityModal from "@crm/pages/opportunity/components/modals/mark-off-opportunity-modal.vue";
import RestoreOrDeleteOpportunityModal
  from "@crm/pages/opportunity/components/modals/restore-or-delete-opportunity-modal.vue";

export default {
  name: "OpportunityQualifyActions",
  components: {
    RestoreOrDeleteOpportunityModal,
    MarkOffOpportunityModal,
  },

  data() {
    return {
      status: {
        gain: '',
        lost: '',
      },

      lostDropdown: null,
      moreOptionsDropdown: null,

      showMoreOptionsDropdown: false,

      reasonFilter: '',

      hasInternalNote: false,
      internalNote: '',

      showMarkOffModal: false,
      showDeleteModal: false,
      showRestoreModal: false,
    };
  },

  computed: {
    ...mapGetters("crm/opportunities", {
      opportunity: "getOpportunity",
    }),

    ...mapGetters("crm/funnels", {
      currentFunnel: "getCurrentFunnel",
    }),

    lostQualification() {
      return this.currentFunnel.qualification_list.filter((qualification) => {
          const isNegative = !qualification.is_positive;
          const matchesFilter = qualification.name
            .toLowerCase()
            .includes(this.reasonFilter.toLowerCase());

          return isNegative && matchesFilter;
        });
    },

    gainQualification() {
      return this.currentFunnel.qualification_list.find((qualification) => qualification.is_positive)
    },

    lostBtnClass() {
      if (this.status.lost === 'lost') {
        return 'btn-lost';
      }

      if (this.status.lost === 'pre-lost') {
        return 'pre-lost';
      }

      return 'btn-default';
    },

    disableDeleteOportunity() {
      return this.opportunity.status === 'deleted'
        || this.opportunity.status === 'gain'
        || this.opportunity.status === 'lost'
    },
  },

  mounted() {
    this.lostDropdown = new Dropdown(this.$refs["lostDropdown"]);
    this.moreOptionsDropdown = new Dropdown(this.$refs["moreOptionsDropdown"]);
  },

  methods: {
    ...mapActions("crm/opportunities", [
      "qualifyOpportunity",
      "fetchOpportunityWithoutLoading",
      "fetchOpportunityLogs"
    ]),

    toggleGainStatus(qualificationId, status) {
      this.opportunity.status === 'gain'
        ? this.showMarkOffModal = true
        : this._qualifyOpportunity(qualificationId, status, 'success_message.qualify_opportunity');
    },

    toggleLostStatus() {
      if (this.opportunity.status === 'lost') {
        this.showMarkOffModal = true
        return;
      }

      if (this.status.lost === 'pre-lost') {
        this.status.lost = '';
        this.lostDropdown.hide();
        return;
      }

      this.status.lost = 'pre-lost';
      this.moreOptionsDropdown.hide();
      this.showMoreOptionsDropdown = false;
      this.lostDropdown.show();
    },

    toggleInternalNote() {
      if (this.hasInternalNote) {
        this.internalNote = '';
        this.hasInternalNote = false
        return;
      }
      this.hasInternalNote = true;
    },

    toggleMoreOptions() {
      if (this.showMoreOptionsDropdown) {
        this.moreOptionsDropdown.hide();
        this.showMoreOptionsDropdown = false;
        return;
      }

      this.lostDropdown.hide();
      this.status.lost = '';
      this.moreOptionsDropdown.show();
      this.showMoreOptionsDropdown = true;
    },

    _qualifyOpportunity(qualification_id = null, status = 'active', message = 'success_message.reactivate_opportunity') {
      const payload = {
        opportunityFunnelId: this.opportunity.opportunity_funnel_id,
        data: {
          status: status,
        },
        message,
      }

      if (this.opportunity.status === 'active' && status !== 'deleted') {
        payload.data.qualification_id = qualification_id;
      }

      if (this.internalNote) {
        payload.data.qualification_note = this.internalNote;
      }

      this.qualifyOpportunity(payload).then(() => {
        this.fetchOpportunityWithoutLoading(this.$route.params.opportunityId);
        this.fetchOpportunityLogs({
          opportunityId: this.opportunity.id,
          payload: {}
        })
        this.hasInternalNote = false;
        this.internalNote = '';
        this.lostDropdown.hide();
        this.moreOptionsDropdown.hide();
        this.showMoreOptionsDropdown = false;

        if (status === 'lost' && this.reasonFilter !== '') {
          this.reasonFilter = '';
          this.searchQuery();
        }

        if (status === 'active') {
          this.closeMarkOffModal();
          this.closeRestoreModal();
        }

        if (status === 'deleted') {
          this.closeDeleteModal();
        }
      })
    },

    resetStatus() {
      this.status = {
        gain: '',
        lost: '',
      }
    },

    closeMarkOffModal() {
      this.showMarkOffModal = false;
    },

    closeDeleteModal() {
      this.showDeleteModal = false;
      this.moreOptionsDropdown.hide();
      this.showMoreOptionsDropdown = false;
    },

    closeRestoreModal() {
      this.showRestoreModal = false;
    },
  },

  watch: {
    opportunity: {
      handler(newVal) {
        this.resetStatus();
        if (newVal && newVal.status === 'gain') {
          this.status.gain = 'gain';
        }

        if (newVal && newVal.status === 'lost') {
          this.status.lost = 'lost';
        }
      },
      immediate: true,
    },
  }
};
</script>


<style lang="scss" scoped>
.btn-default, .btn-gain, .btn-lost, .pre-lost, .btn-restore {
  height: 32px;
  width: 100px;
  font-size: 12px;
}

.btn-default {
  background-color: $color-gray-blue-300;
  color: $color-text-gray;

  &:hover {
    background-color: $color-gray-200;
  }

  &.gain:hover {
    background-color: #D6E9D9;
    color: #006C17;
  }

  &:disabled {
    background-color: $color-gray-blue-300;
    color: $color-text-gray;
    opacity: 40%;
  }
}

.btn-gain {
  background-color: #006C17;
  color: $color-text-light;
}

.pre-lost, .btn-default.lost:hover {
  background-color: #F2C6D1;
  color: #b80935;
}

.btn-lost {
  background-color: #E60B42;
  color: $color-text-light;
}

.lost-reasons-dropdown {
  width: 342px;
  height: 526px;

  .dropdown-header, .dropdown-body {
    padding: 16px 24px;
  }

  .dropdown-header, .qualification-option {
    border-bottom: 1px solid $color-gray-blue-300;
  }
}

.fa-search {
  top: 32%;
  left: 8px;
}

.form-control {
  padding-left: 28px;

  &::placeholder {
    color: $color-text-gray;
  }
}

.more-options-btn, .internal-note {
  width: 32px;
  height: 32px;
  color: $color-text-gray;

  &.active {
    background-color: $color-blue-300;
    color: $color-text-light;
  }
}

.more-options-btn {
  &:disabled {
    opacity: 40%;
  }

  &:not(.active):hover {
    background-color: $color-gray-200;
  }
}

.more-options-btn, textarea {
  background-color: $color-gray-blue-300;
}

textarea {
  background-color: #FFE9A9;
  color: #754000;
  outline: none;
  resize: none;
  max-height: 144px;

  &::-webkit-scrollbar {
    width: 3px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #b0c5c2 !important;
    border-radius: 20px !important;
  }
}

.qualification-option {
  min-height: 48px;
  padding-bottom: 8px;
}

.qualification {
  gap: 16px;

  &:hover {
    background-color: $color-gray-blue-300;
  }
}

.handler-styles {
  border-radius: 100%;
  height: 1rem;
  width: 1rem;
}

.reasons-container {
  overflow-y: scroll;
  gap: 5px;
}

.delete-item:hover {
  background-color: $color-gray-blue-300;
}
.lost-gain-btns{
  height: 40px;
  min-width: 40px;
}
.multi-line-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: calc(1.2em * 3);
  line-height: 1.2em;
  width: fit-content;
}

</style>