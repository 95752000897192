<template>
  <div class="col-12 mb-3">
    <card-loading
      v-if="isLoadingAllData"
      :height="420"
    />

    <div
      class="card"
      v-if="!allAgentsLoadingFlags.loadAllAgentsOnline && !isLoadingAllData"
    >
      <div class="p-4">
        <div class="d-flex align-items-center">
          <div class="custom-icon d-flex align-items-center justify-content-center rounded-1 me-3">
            <i class="fa-regular fa-user text-primary fs-4" />
          </div>
          <h2 class="card-title m-0">
            {{ $t('capitalizes.agents') }}
          </h2>
        </div>
      </div>

      <div
        v-if="!allAgentsLoadingFlags.loadAllAgentsOnline && !isLoadingAllData"
        class="px-4 pb-4 pt-3 border-bottom"
      >
        <div class="row">
          <div
            v-for="agent in agentsStatus"
            :key="agent.label"
            class="col-4 col-lg-2"
          >
            <div class="d-flex flex-column align-items-start">
              <h2>{{ agent.value }}</h2>
              <div class="d-flex align-items-center gap-2">
                <dot-status
                  size="12px"
                  :color="agent.color"
                />
                <span class="text-uppercase text-small tx-text-gray">{{ agent.label }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-header gap-3">
        <search-input
          class="me-3"
          :placeholder="$t('search_by_agents')"
          @search="search"
        />
        <div class="col-3">
          <single-select
            id="Campaigns"
            :list-array="campaigns"
            :current-option="selectedCampaign?.name"
            label="name"
            :initial-text="$t('filter_by_campaign')"
            @selected-option="filterByCampaign"
          />
        </div>
        <div class="col-3">
          <single-select
            id="Teams"
            :list-array="teams"
            :current-option="selectedTeam?.name"
            label="name"
            :initial-text="$t('filter_by_team')"
            @selected-option="filterByTeam"
          />
        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-hover table-nowrap card-table">
          <thead>
            <tr>
              <th />
              <th scope="col">
                <sort-link
                  column="user_name"
                  :title="$t('capitalizes.name')"
                  :dir="sorts.user_name.dir"
                  @sort="sort"
                  @click.prevent
                />
              </th>
              <th scope="col">
                {{ $t('local') }}
              </th>
              <th scope="col">
                {{ $t('status') }}
              </th>
              <th scope="col">
                <sort-link
                  column="total_calls"
                  :title="$t('today_cal')"
                  :dir="sorts.total_calls.dir"
                  @sort="sort"
                  @click.prevent
                />
              </th>
              <th scope="col">
                <sort-link
                  column="converted_metrics"
                  :title="$t('conversion')"
                  :dir="sorts.converted_metrics.dir"
                  @sort="sort"
                  @click.prevent
                />
              </th>
              <th scope="col">
                {{ $t('total_time_capitalize') }}
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr v-if="agentsLoadingFlags.fetchAgents && !isLoadingAllData">
              <td
                class="p-0"
                v-for="i in 8"
                :key="i"
              >
                <table-loading :rows="10">
                  <div class="cell col" />
                </table-loading>
              </td>
            </tr>

            <template v-if="Object.values(agents).length && !agentsLoadingFlags.fetchAgents && !isLoadingAllData">
              <tr
                v-for="(agent, index) in sortedAgents"
                :key="agent.id"
                @mouseover="selectAgent(agent)"
                @mouseleave="deselectAgent"
                :class="{
                  'text-muted': agent.status === AgentStatus.OFFLINE
                }"
              >
                <td
                  class="py-2 pe-3 text-center"
                  v-if="sorts.converted_metrics.dir === 'desc'"
                >
                  <img
                    v-if="shouldShowAward(index, agent)"
                    :src="awardImages[index]"
                    :alt="`Award ${index}`"
                  >
                  <span
                    v-else
                    class="ms-2"
                  >
                    {{ getRank(index) }}°
                  </span>
                </td>
                <td v-else />
                <td
                  class="py-2 ps-3 text-truncate overflow-ellipsis agent-name"
                  :title="agent.name"
                >
                  {{ agent.name }}
                </td>
                <td
                  class="py-2 text-truncate overflow-ellipsis logged-campaign-name"
                  :title="findLoggedCampaign(agent.logged_campaign)?.name"
                >
                  {{ findLoggedCampaign(agent.logged_campaign)?.name }}
                </td>
                <td class="py-2">
                  <div v-if="agent.status !== AgentStatus.ON_WORK_BREAK">
                    <dot-status
                      v-if="agent.status !== AgentStatus.OFFLINE"
                      size="12px"
                      :color="obtainAgentLabelAndColorStatus(agent.status).color"
                    />
                    {{ obtainAgentLabelAndColorStatus(agent.status).label }}
                  </div>
                  <VTooltip v-else>
                    <div
                      class="cursor-pointer"
                      :class="{
                        'agent-requested-interval-exit text-truncate': agent.work_break_exit_pending
                      }"
                    >
                      <dot-status
                        size="12px"
                        color="var(--color-interval)"
                      />
                      {{ agent.work_break_exit_pending ? $t('return_requested') : $t('capitalizes.break') }}
                    </div>
                    <template #popper>
                      {{ agent.work_break ? agent.work_break.name : agent.work_break_interval_name }}
                    </template>
                  </VTooltip>
                </td>
                <td class="py-2">
                  {{ agent.total_calls ? agent.total_calls : "" }}
                </td>
                <td class="py-2">
                  {{ agent.converted_metrics ? agent.converted_metrics : "" }}
                </td>
                <td class="py-2">
                  <div class="d-flex align-items-center gap-3">
                    {{ convertSecondsToTime(agent.total_time) }}
                    <progressbar-multiple-lg
                      :data="formattedAgentStatusMetrics(agent)"
                      :to-fixed="1"
                    />
                  </div>
                </td>
                <td class="py-2 position-relative">
                  <agent-tooltip-info
                    v-if="
                      agent.status !== AgentStatus.OFFLINE && (selectedAgent === agent || spiedAgent?.id === agent?.id)
                    "
                    :agent="Object.values(agents).find(a => a.id === agent.id)"
                    :intervals="campaignsWorkBreaks.find(campaign => campaign.id === agent.logged_campaign)?.work_break_group.intervals"
                    @show-info-modal="handleInfoModal"
                  />
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <div
        v-if="Object.values(agents).length && agentsPagination && agentsPagination.total > agentsPagination.per_page"
        class="card-footer"
      >
        <app-paginator
          :pagination="agentsPagination"
          @change-page="changePage"
        />
      </div>

      <div
        v-if="!Object.values(agents).length && !agentsLoadingFlags.fetchAgents && !isLoadingAllData"
        class="card-body"
      >
        <div class="d-flex flex-column align-items-center justify-content-center gap-3">
          <h2 class="my-0">
            Oops...
          </h2>
          <img
            :src="require(`@/assets/img/aloisio/rival.svg`)"
            class="align-self-center"
            alt="Aloísio"
            width="125"
          >
          <span class="text-medium">
            {{ $t('no_agent_found') }}
          </span>
        </div>
      </div>
    </div>

    <agent-spy
      ref="agentSpy"
      :campaign="findLoggedCampaign(selectedAgentToSpy?.logged_campaign)"
    />

    <modal-component
      :show="showInfoModal"
      :all-customized="true"
      :center="true"
      @close="showInfoModal = false"
    >
      <template #content>
        <button
          class="btn btn-close position-absolute end-0 mt-2 fs-2"
          @click="showInfoModal = false"
          style="margin-right: -40px;"
        />
        <info-client-and-agent
          v-if="showInfoModal"
          :agent="agentInfo"
          :campaign="findLoggedCampaign(agentInfo.logged_campaign)"
          :spy="false"
        />
      </template>
    </modal-component>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import { AgentStatus, countAgentsByStatus, convertSecondsToTime } from "@/utils";
import DotStatus from "@/components/dot-status.vue";
import SearchInput from "@/components/search-input.vue";
import AppPaginator from "@/components/app-paginator.vue";
import ProgressbarMultipleLg from "@/components/progressbar-multiple-lg.vue";
import AgentTooltipInfo from "@dialer/manager/pages/dashboards/campaign/components/agent-tooltip-info.vue";
import AgentSpy from "@dialer/manager/pages/dashboards/campaign/components/agent-spy.vue";
import InfoClientAndAgent from "@dialer/manager/pages/dashboards/campaign/components/info-client-and-agent.vue";
import ModalComponent from "@/components/modal-component.vue";
import TableLoading from "@admin/components/table-loading.vue";
import CardLoading from "@/components/card-loading.vue";
import SingleSelect from "@/components/single-select.vue";
import SortLink from "@dialer/manager/components/sort-link";
import FirstAward from "@/assets/img/first-award.svg";
import SecondAward from "@/assets/img/second-award.svg";
import ThirdAward from "@/assets/img/third-award.svg";

export default {
  name: "AgentsCard",
  data() {
    return {
      AgentStatus,
      campaigns: [],
      selectedCampaign: null,
      teams: [],
      selectedTeam: null,
      selectedAgent: null,
      showInfoModal: false,
      isLoadingAllData: false,
      searchValue: "",
      awardImages: [FirstAward, SecondAward, ThirdAward],
      sorts: {
        user_name: {
          dir: ""
        },
        converted_metrics: {
          dir: ""
        },
        total_calls: {
          dir: ""
        }
      }
    };
  },

  mounted() {
    window.addEventListener("click", this.mustHideTooltipButton);
    this.isLoadingAllData = true;

    this.sorts["user_name"].dir = "";
    this.sorts["converted_metrics"].dir = "";
    this.sorts["total_calls"].dir = "";
    this.sorts.converted_metrics.dir = "desc";
    this.setAgentsFilters({
      default_order_by: "converted_metrics",
      order_by_type: "desc",
      search: this.searchValue,
      campaignId: this.selectedCampaign?.id,
      teamId: this.selectedTeam?.id,
    });
    this.setAgentsCurrentPage(1);
    this.setFiltersIncludes(["teams", "work_break_groups"]);

    const promises = [
      this.loadAllAgentsOnline(),
      this.findFilters(),
      this.fetchAgents(),
      this.fetchCampaignsWorkBreak()
    ];

    Promise.all(promises).finally(() => {
      this.isLoadingAllData = false;
    });
  },

  beforeUnmount() {
    window.removeEventListener("click", this.mustHideTooltipButton);
    this.clearAgentsData();
    this.clearAgents();
  },

  components: {
    DotStatus,
    SearchInput,
    AppPaginator,
    ProgressbarMultipleLg,
    AgentSpy,
    AgentTooltipInfo,
    InfoClientAndAgent,
    ModalComponent,
    TableLoading,
    CardLoading,
    SingleSelect,
    SortLink
  },

  computed: {
    ...mapGetters("manager/campaign/agents", {
      allAgentsOnline: "getAllAgentsOnline",
      allAgentsLoadingFlags: "getLoadingFlags"
    }),

    ...mapGetters("manager/agents", {
      agents: "getAgents",
      agentsPagination: "getAgentsPagination",
      agentsLoadingFlags: "getLoadingFlags"
    }),

    ...mapGetters("manager/filters", {
      filters: "getFilters"
    }),

    ...mapGetters("manager/campaign/work_break", {
      campaignsWorkBreaks: "getCampaignsWorkBreak"
    }),

    ...mapGetters("system", {
      spiedAgent: "getSpiedAgent",
      jsSipStatus: "getJsSipStatus",
      showSpy: "getShowSpy",
      selectedAgentToSpy: "getSelectedAgentToSpy"
    }),

    agentsStatus() {
      return [
        {
          label: this.$t('capitalizes.idle'),
          value: countAgentsByStatus(this.allAgentsOnline, AgentStatus.IDLE),
          color: "var(--color-idle)"
        },
        {
          label: this.$t('capitalizes.talking'),
          value:
            countAgentsByStatus(this.allAgentsOnline, AgentStatus.ON_CALL) +
            countAgentsByStatus(this.allAgentsOnline, AgentStatus.ON_MANUAL_CALL_CONNECTED) +
            countAgentsByStatus(this.allAgentsOnline, AgentStatus.MANUAL_CALL_CONNECTED),
          color: "var(--color-call)"
        },
        {
          label: this.$t('acronym.act'),
          value: countAgentsByStatus(this.allAgentsOnline, AgentStatus.ACW),
          color: "var(--color-tpa)"
        },
        {
          label: this.$t('acronym.mact'),
          value: countAgentsByStatus(this.allAgentsOnline, AgentStatus.ON_MANUAL_CALL_ACW),
          color: "var(--color-mtpa)"
        },
        {
          label: this.$t('capitalizes.manual'),
          value: countAgentsByStatus(this.allAgentsOnline, AgentStatus.ON_MANUAL_CALL),
          color: "var(--color-manual)"
        },
        {
          label: this.$t('capitalizes.break'),
          value: countAgentsByStatus(this.allAgentsOnline, AgentStatus.ON_WORK_BREAK),
          color: "var(--color-interval)"
        }
      ];
    },

    sortedAgents() {
      return Object.values(this.agents).sort((a, b) => {
        if (a.status === 0 && b.status !== 0) {
          return 1;
        }
        if (a.status !== 0 && b.status === 0) {
          return -1;
        }

        if (this.sorts.user_name.dir) {
          return this.sorts.user_name.dir === "asc" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
        }

        if (this.sorts.total_calls.dir) {
          return this.sorts.total_calls.dir === "asc" ? a.total_calls - b.total_calls : b.total_calls - a.total_calls;
        }

        if (this.sorts.converted_metrics.dir) {
          const aConverted = a.converted_metrics || 0;
          const bConverted = b.converted_metrics || 0;
          return this.sorts.converted_metrics.dir === "asc"
            ? aConverted - bConverted
            : bConverted - aConverted;
        }

        return a.name.localeCompare(b.name);
      });
    }
  },

  methods: {
    convertSecondsToTime,
    ...mapActions("manager/campaign/agents", ["loadAllAgentsOnline", "clearAgentsData"]),
    ...mapActions("manager/campaign/work_break", ["fetchCampaignsWorkBreak"]),
    ...mapActions("manager/agents", ["fetchAgents", "clearAgents", "setAgentsFilters", "setAgentsCurrentPage"]),
    ...mapActions("manager/filters", ["findFilters", "setFiltersIncludes"]),

    changePage(page) {
      this.setAgentsCurrentPage(page);
      this.fetchAgents();
    },

    findLoggedCampaign(campaign_id) {
      return this.campaigns.find(campaign => {
        return campaign.id && campaign.id === campaign_id;
      });
    },

    obtainAgentLabelAndColorStatus(status) {
      switch (status) {
        case AgentStatus.IDLE:
          return {
            label: this.$t('capitalizes.idle'),
            color: "var(--color-idle)"
          };
        case AgentStatus.ON_CALL:
          return {
            label: this.$t('capitalizes.talking'),
            color: "var(--color-call)"
          };

        case AgentStatus.ON_MANUAL_CALL_CONNECTED:
          return {
            label: this.$t('capitalizes.manual'),
            color: "var(--color-call)"
          };
        case AgentStatus.MANUAL_CALL_CONNECTED:
          return {
            label: this.$t('acronym.mact'),
            color: "var(--color-call)"
          };
        case AgentStatus.ACW:
          return {
            label: this.$t('acronym.act'),
            color: "var(--color-tpa)"
          };
        case AgentStatus.ON_MANUAL_CALL_ACW:
          return {
            label: this.$t('acronym.mact'),
            color: "var(--color-mtpa)"
          };
        case AgentStatus.ON_MANUAL_CALL:
          return {
            label: this.$t('capitalizes.manual'),
            color: "var(--color-manual)"
          };
        case AgentStatus.ON_WORK_BREAK:
          return {
            label: this.$t('capitalizes.break'),
            color: "var(--color-interval)"
          };
        default:
          return {
            label: "Offline",
            color: ""
          };
      }
    },

    formattedAgentStatusMetrics({ total_time, metrics }) {
      return [
        {
          color: "var(--color-idle)",
          percentage: total_time ? (metrics.idle / total_time) * 100 : 0,
          count: convertSecondsToTime(metrics.idle),
          title: this.$t('capitalizes.idle')
        },
        {
          color: "var(--color-call)",
          percentage: total_time ? (metrics.speaking / total_time) * 100 : 0,
          count: convertSecondsToTime(metrics.speaking),
          title: this.$t('capitalizes.talking')
        },
        {
          color: "var(--color-tpa)",
          percentage: total_time ? (metrics.acw / total_time) * 100 : 0,
          count: convertSecondsToTime(metrics.acw),
          title: this.$t('acronym.act')
        },
        {
          color: "var(--color-mtpa)",
          percentage: total_time ? (metrics.manual_acw / total_time) * 100 : 0,
          count: convertSecondsToTime(metrics.manual_acw),
          title: this.$t('acronym.mact')
        },
        {
          color: "var(--color-manual)",
          percentage: total_time ? (metrics.manual / total_time) * 100 : 0,
          count: convertSecondsToTime(metrics.manual),
          title: this.$t('capitalizes.manual')
        },
        {
          color: "var(--color-interval)",
          percentage: total_time ? (metrics.interval / total_time) * 100 : 0,
          count: convertSecondsToTime(metrics.interval),
          title: this.$t('capitalizes.break')
        }
      ];
    },

    handleInfoModal(agent) {
      this.agentInfo = {
        id: agent.id,
        name: agent.name,
        status: agent.status,
        logged_campaign: agent.logged_campaign,
        count: agent.total_calls,
        average: agent.total_time, // OBTER A MÉDIA: TOTAL TIME NÃO É A MÉDIA
        mailing: agent.mailing,
        teams: agent.teams
      };
      this.showInfoModal = true;
    },

    mustHideTooltipButton(event) {
      const clickedElement = event.target;
      // verify if clicked element is outside tooltip and agents table
      const parentElement = clickedElement.closest("tbody") || clickedElement.closest(".v-popper__popper--shown");
      if (!parentElement) {
        this.selectedAgent = null;
      }
    },

    selectAgent(agent) {
      const popper = document.querySelector(".v-popper__popper--shown");
      if (!popper) {
        this.selectedAgent = agent;
      }
    },

    deselectAgent() {
      const popper = document.querySelector(".v-popper__popper--shown");
      if (!popper) {
        this.selectedAgent = null;
      }
    },

    search: _.debounce(function(value) {
      this.searchValue = value;
      this.setAgentsFilters({
        search: value,
        campaignId: this.selectedCampaign?.id,
        teamId: this.selectedTeam?.id
      });
      this.setAgentsCurrentPage(1);
      this.fetchAgents();
    }, 300),

    filterByCampaign(campaign) {
      this.selectedCampaign = campaign;
      this.setAgentsFilters({
        search: this.searchValue,
        campaignId: campaign.id,
        teamId: this.selectedTeam?.id
      });
      this.setAgentsCurrentPage(1);
      this.fetchAgents();
    },

    filterByTeam(team) {
      this.selectedTeam = team;
      this.setAgentsFilters({
        search: this.searchValue,
        campaignId: this.selectedCampaign?.id,
        teamId: team.id
      });
      this.setAgentsCurrentPage(1);
      this.fetchAgents();
    },

    sort(data) {
      this.sorts["user_name"].dir = "";
      this.sorts["converted_metrics"].dir = "";
      this.sorts["total_calls"].dir = "";
      this.sorts[data.column].dir = data.dir;
      this.setAgentsFilters({
        default_order_by: data.column,
        order_by_type: data.dir,
        search: this.searchValue,
        campaignId: this.selectedCampaign?.id,
        teamId: this.selectedTeam?.id
      });
      this.setAgentsCurrentPage(1);
      this.fetchAgents();
    },

    shouldShowAward(index, agent) {
      return (index + 1 + (this.agentsPagination.current_page - 1) * 15 <= 3) && agent.converted_metrics;
    },

    getRank(index) {
      return index + 1 + (this.agentsPagination.current_page - 1) * 15;
    }
  },

  watch: {
    showSpy() {
      setTimeout(() => this.$refs.agentSpy.startSpy(), 1);
    },

    filters(data) {
      if (data.campaigns && data.campaigns.active.length) {
        this.campaigns = data.campaigns.active;
        this.campaigns.unshift({
          id: null,
          name: this.$t('all_campaigns')
        });
      }

      if (data.teams && data.teams.length) {
        this.teams = data.teams;
        this.teams.unshift({
          id: null,
          name: this.$t('all_teams')
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-icon {
  width: 40px;
  height: 40px;
  background-color: $color-gray-blue-300;
}

.form-select {
  background-color: $color-gray-blue-300;
  color: $color-text-gray;
  border-radius: 10px;
}

.agent-requested-interval-exit {
  width: 160px;
  height: 24px;
  background-color: var(--color-interval);
  color: var(--bs-black);
  border-radius: 6px;
}

.agent-name {
  max-width: 11rem;
}

.logged-campaign-name {
  max-width: 7rem;
}
</style>
