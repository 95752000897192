<template>
  <off-canvas-user ref="usersOffCanvas" />
  <div class="my-5">
    <div class="card">
      <div
        class="card-header"
        style="border-bottom: none"
      >
        <search-input
          class="b4"
          @search="search"
        />
        <ul
          class="nav nav-tabs nav-tabs-sm card-header-tabs"
          style="margin-left: 36px"
        >
          <li class="nav-item">
            <button
              @click="activeTab = 'underReview'"
              class="nav-link active two-lines"
              ref="underReviewTab"
              role="tab"
              data-bs-toggle="tab"
              data-bs-target="#underReview"
            >
              <span class="text-muted"> ATIVOS </span>
              <br>
              <span class="subtitle">
                {{ this.totalActiveUsers }}
              </span>
            </button>
          </li>
          <li class="nav-item">
            <button
              @click="activeTab = 'finalized'"
              class="nav-link two-lines"
              ref="finalizedTab"
              role="tab"
              data-bs-toggle="tab"
              data-bs-target="#finalized"
            >
              <span class="text-muted"> INATIVOS </span>
              <br>
              <span class="subtitle">
                {{ this.totalInactiveUsers }}
              </span>
            </button>
          </li>
        </ul>
      </div>
      <div class="card-body px-0 py-0">
        <div class="tab-content">
          <div
            role="tabpanel"
            id="underReview"
            class="table-responsive tab-pane active"
          >
            <table class="table table-hover table-nowrap card-table mb-0">
              <thead>
                <tr>
                  <th>
                    <a
                      class="list-sort text-muted fw-normal"
                      href="#"
                    >NOME</a>
                  </th>
                  <th>
                    <a
                      class="list-sort text-muted fw-normal"
                      href="#"
                    >SETOR</a>
                  </th>
                  <th>
                    <a
                      class="list-sort text-muted fw-normal"
                      href="#"
                    >EMAIL</a>
                  </th>
                </tr>
              </thead>

              <tbody class="list fs-base">
                <tr
                  v-if="
                    activeUsers.length == 0 && !loadingFlags.findActiveUsers
                  "
                >
                  <td
                    class="text-center text-muted"
                    colspan="6"
                  >
                    <span class="fe fe-alert-octagon" /> Nenhum usuário
                    encontrado!
                  </td>
                </tr>
                <tr v-if="loadingFlags.findActiveUsers">
                  <td
                    class="p-0 loading"
                    v-for="i in 6"
                    :key="i"
                  >
                    <table-loading :rows="6">
                      <div class="cell col" />
                    </table-loading>
                  </td>
                </tr>

                <template
                  v-for="item in activeUsers"
                  :key="item.id"
                  v-else
                >
                  <tr
                    class="mx-3 cursor-pointer"
                    @mouseover="selectedItem = item.id"
                    @mouseleave="selectedItem = null"
                    @click="openOffCanvas(item)"
                  >
                    <td>
                      {{ item.name }}
                    </td>
                    <td>
                      {{ item.settings.sector }}
                    </td>
                    <td>
                      {{ item.email }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <div v-if="!loadingFlags.findActiveUsers && activeUsers.length">
              <div>
                <paginator
                  class=""
                  :pagination="activeUsersPagination"
                  @change-page="changePage"
                />
              </div>
            </div>
          </div>

          <div
            role="tabpanel"
            id="finalized"
            class="table-responsive tab-pane"
          >
            <table class="table table-hover table-nowrap card-table">
              <thead>
                <tr>
                  <th>
                    <a
                      class="list-sort text-muted fw-normal"
                      href="#"
                    >NOME</a>
                  </th>
                  <th>
                    <a
                      class="list-sort text-muted fw-normal"
                      href="#"
                    >SETOR</a>
                  </th>
                  <th>
                    <a
                      class="list-sort text-muted fw-normal"
                      href="#"
                    >EMAIL</a>
                  </th>
                </tr>
              </thead>

              <tbody class="list fs-base">
                <tr
                  v-if="
                    inactiveUsers.length === 0 &&
                      !loadingFlags.findInactiveUsers
                  "
                >
                  <td
                    class="text-center text-muted"
                    colspan="7"
                  >
                    <span class="fe fe-alert-octagon" /> Nenhum usuário
                    encontrado!
                  </td>
                </tr>
                <template v-if="loadingFlags.findInactiveUsers">
                  <td
                    class="p-0 loading"
                    v-for="i in 6"
                    :key="i"
                  >
                    <table-loading :rows="6">
                      <div class="cell col" />
                    </table-loading>
                  </td>
                </template>

                <template
                  v-for="item in inactiveUsers"
                  :key="item.id"
                  v-else
                >
                  <tr
                    class="cursor-pointer"
                    @mouseover="selectedItem = item.id"
                    @mouseleave="selectedItem = null"
                    @click="openOffCanvas(item)"
                  >
                    <td>
                      {{ item.name }}
                    </td>
                    <td>
                      {{ item.settings.sector }}
                    </td>
                    <td>
                      {{ item.email }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <div
              class="card-footer"
              v-if="!loadingFlags.findInactiveUsers && inactiveUsers.length"
            >
              <div>
                <paginator
                  v-if="!loadingFlags.findInactiveUsers"
                  :pagination="inactiveUsersPagination"
                  :recently-quantity="this.inactiveUsersPagination.per_page"
                  @change-page="changePage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";
import TableLoading from "@admin/components/table-loading.vue";
import SearchInput from "@components/search-input";
import paginator from "@/components/app-paginator.vue";
import offCanvasUser from "./offcanvas-users.vue";

export default {
  data() {
    return {
      activeTab: "underReview",
      selectedItem: null
    };
  },

  /**
   *
   */
  mounted() {
    this.findActiveUsers();
    this.findInactiveUsers();
  },

  /**
   *
   */
  computed: {
    ...mapGetters("admin/users", {
      loadingFlags: "getLoadingFlags",
      activeUsers: "getActiveUsers",
      activeUsersPagination: "getActiveUsersPagination",
      totalActiveUsers: "getTotalActiveUsers",
      inactiveUsers: "getInactiveUsers",
      inactiveUsersPagination: "getInactiveUsersPagination",
      totalInactiveUsers: "getTotalInactiveUsers"
    })
  },

  /**
   *
   */
  components: {
    TableLoading,
    SearchInput,
    paginator,
    offCanvasUser
  },

  /**
   *
   */
  methods: {
    ...mapActions("admin/users", ["findActiveUsers", "findInactiveUsers"]),
    ...mapMutations("admin/users", [
      "setActiveUsersFilters",
      "setActiveUsersPage",
      "setInactiveUsersFilters",
      "setInactiveUsersPage"
    ]),

    /**
     * search function for search table input
     */
    search: _.debounce(function(term) {
      if (this.activeTab == "underReview") {
        this.setActiveUsersFilters({
          search: term
        });
        this.setActiveUsersPage(1);
        this.findActiveUsers();
      }
      if (this.activeTab == "finalized") {
        this.setInactiveUsersFilters({
          search: term
        });
        this.setInactiveUsersPage(1);
        this.findInactiveUsers();
      }
    }, 300),

    /**
     * change page on paginator
     */
    changePage(page) {
      if (this.activeTab == "underReview") {
        this.setActiveUsersPage(page);
        this.findActiveUsers();
      }
      if (this.activeTab == "finalized") {
        this.setInactiveUsersPage(1);
        this.findInactiveUsers();
      }
    },

    /**
     * open pages users offCanvas
     */
    openOffCanvas(item) {
      let editMode =
        this.activeTab == "finalized" ? "reactivate" : "desactivate";
      this.$refs.usersOffCanvas.edit({ item, editMode });
    }
  }
};
</script>
