<template>
  <div class="main-content">
    <div class="container-fluid">
      <header-component title="Telecom">
        <template #buttons>
          <button
            class="btn btn-primary px-6"
            @click="$refs.addEndpoint.openModal()"
          >
            Adicionar novo endpoint
          </button>
        </template>
      </header-component>

      <div
        class="tab-pane fade show active"
        id="contactsListPane"
        role="tabpanel"
        aria-labelledby="contactsListTab"
      >
        <div class="col-lg-12">
          <div
            class="card"
          >
            <div
              class="card-header"
              style="border-bottom: none"
            >
              <search-input
                @search="search"
                :placeholder="$t('placeholders.search_sip_trunk')"
              />
            </div>
            <div class="card-body px-0 py-0">
              <div class="tab-content">
                <div
                  role="tabpanel"
                  id="underReview"
                  class="table-responsive tab-pane active"
                >
                  <table class="table table-houver table-nowrap card-table mb-0">
                    <thead>
                      <tr class="col-12">
                        <th class="col-2">
                          <a
                            class="list-sort text-muted fw-normal"
                            href="#"
                          >TRONCO SIP</a>
                        </th>
                        <th class="col-2">
                          <a
                            class="list-sort text-muted fw-normal"
                            href="#"
                          >IP</a>
                        </th>
                        <th class="col-2">
                          <a
                            class="list-sort text-muted fw-normal"
                            href="#"
                          >PORTA</a>
                        </th>
                        <th class="col-2">
                          <a
                            class="list-sort text-muted fw-normal"
                            href="#"
                          >TIPO DE AUTENTICAÇÂO</a>
                        </th>
                      </tr>
                    </thead>

                    <tbody class="list fs-base">
                      <tr v-if="routes.length == 0 && !loadingFlags.fetchRoutes">
                        <td
                          class="text-center text-muted"
                          colspan="6"
                        >
                          <span class="fe fe-alert-octagon" /> Nenhuma rota encontrada
                        </td>
                      </tr>
                      <tr v-if="loadingFlags.fetchRoutes">
                        <td
                          class="p-0 loading"
                          v-for="i in 4"
                          :key="i"
                        >
                          <table-loading :rows="4">
                            <div class="cell col" />
                          </table-loading>
                        </td>
                      </tr>

                      <template
                        v-for="(item, index) in routes"
                        :key="index"
                        v-else
                      >
                        <tr
                          class="cursor-pointer"
                        >
                          <td class="col-2">
                            {{ item.endpoint }}
                          </td>
                          <td class="col-2">
                            {{ item.ip }}
                          </td>
                          <td class="col-2">
                            {{ item.port }}
                          </td>
                          <td class="col-2 d-flex flex-row justify-content-between w-100">
                            <span>{{ item.authentication_type }}</span>
                            <i
                              class="fa-light fa-trash cursor-pointer"
                              @click="$refs.deleteEndpoint.openModal(item)"
                            />
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                  <div
                    class="card-footer"
                  >
                    <paginator
                      v-if="!loadingFlags.fetchRoutes"
                      :pagination="routesPagination"
                      @change-page="changePage"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <add-endpoint ref="addEndpoint" />
  <delete-endpoint ref="deleteEndpoint" />
</template>
<script>
import _ from "lodash";
import AddEndpoint from "./components/add-endpoint.vue";
import DeleteEndpoint from "./components/delete-endpoint.vue";
import TableLoading from "@admin/components/table-loading.vue";
import SearchInput from "@components/search-input.vue";
import Paginator from "@components/app-paginator.vue";
import { mapActions, mapGetters, mapMutations } from 'vuex';
import HeaderComponent from "@/components/header-component.vue";

export default {

  mounted() {
    this.fetchRoutes();
  },

  computed: {
    ...mapGetters("admin/telecom", {
      loadingFlags: "getLoadingFlags",
      routes: "getRoutes",
      routesPagination: "getRoutesPagination"
    }),
  },

  components: {
    SearchInput,
    TableLoading,
    Paginator,
    HeaderComponent,
    AddEndpoint,
    DeleteEndpoint

  },

  beforeUnmount() {
    this.clearParams();
  },

  methods: {
    ...mapActions("admin/telecom", [
      "fetchRoutes",
      "clearParams"
    ]),
    ...mapMutations("admin/telecom", [
      "setRoutesFilters",
      "setRoutesPage"
    ]),

    search: _.debounce(function(event) {
      this.setRoutesFilters({
        search: event,
      });
      this.setRoutesPage(1);
      this.fetchRoutes();
    }, 300),

    changePage(page) {
      this.setRoutesPage(page);
      this.fetchRoutes();
    },
  },
}

</script>
<style lang="scss" scoped>

table.table>tbody>tr:hover td  {
  background-color: $color-gray-blue-300;
  .fa-trash {
    color: #F23F2C;
}

}
.fa-trash {
  color: transparent;
}
</style>
