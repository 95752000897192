<template>
  <modal-component
    :background-blur="true"
    :center="true"
    :show="showModal"
    @close="$emit('cancel')"
  >
    <template #content>
      <div class="modal-header">
        <div class="modal-title d-flex align-items-center justify-content-between w-100">
          <h4
            class="tx-text-200 mb-0"
          >
            {{ textHeader }}
          </h4>
          <div>
            <button
              aria-label="Close"
              class="btn"
              type="button"
              @click="$emit('cancel')"
            >
              <i class="fa-regular fa-close" />
            </button>
          </div>
        </div>
      </div>
      <div class="modal-body pb-0 d-flex flex-column align-items-center text-center">
        <h2 class="mb-3">
          {{ title }}
        </h2>
        <p class="fs-4 mb-5 w-75">
          {{ subTitle }}
        </p>
        <app-button
          color="btn-danger"
          size="w-75"
          :loading="loading"
          @click="$emit('confirm')"
        >
          {{ confirmationMessage }}
        </app-button>
        <button
          aria-label="Close"
          class="btn mb-4 mt-2 tx-text-gray"
          type="button"
          @click="$emit('cancel')"
        >
          {{ cancelMessage }}
        </button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from "@components/modal-component.vue";
import AppButton from "@components/app-button.vue";

export default {
  name: "LeavePageModal",
  components: {AppButton, ModalComponent},

  emits: ['cancel', 'confirm'],

  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    textHeader: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    confirmationMessage: {
      type: String,
      default: ''
    },
    cancelMessage: {
      type: String,
      default: 'Cancelar'
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
