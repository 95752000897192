import ManualCalls from "@dialer/manager/pages/reports/manual-calls";

export default {
  path: "manual-calls",
  name: "manual-calls",
  components: {
    main: ManualCalls
  },
  meta: {
    "short-side-bar": true
  },
};
