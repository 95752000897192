<template>
  <div
    class="d-flex justify-content-center align-items-center cover"
    style="height: 100vh;"
  >
    <div
      class="card p-4 m-0"
      style="width: 450px"
    >
      <div
        v-if="loadingFlags.fetchPaymentData"
        class="card-body d-flex align-items-center justify-content-center"
        style="height: 550px;"
      >
        <div
          class="spinner-border text-secondary"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <div v-else>
        <main class="text-center">
          <div v-if="paymentData.qr_code_base64">
            <img
              src="~@assets/img/logo.svg"
              width="100"
            >
            <div>
              <img
                :src="'data:image/jpeg;base64,' + paymentData.qr_code_base64"
                width="256"
              >
            </div>
          </div>
          <div v-else>
            <h4
              class="mb-0"
              style="margin: 1rem;"
            >
              {{ paymentData.payment_status === "approved" ?
                "O seu pagamento foi efetuado com sucesso!" :
                "A chave PIX expirou. Por favor, solicite uma nova transação."
              }}
            </h4>
            <img
              v-if="paymentData.payment_status == 'approved'"
              src="~@assets/img/illustrations/pluzinha-joinha.svg"
              width="150"
              style="margin: 2rem 0;"
            >
            <img
              v-else
              src="~@assets/img/illustrations/pluzinha-clock.svg"
              width="150"
              style="margin: 2rem 0;"
            >
          </div>
          <div class="text-small-2 my-3">
            <span
              class="py-1 custom-badge"
              :style="{'color': badge.color, 'background-color': badge.background,}"
            >
              {{ badge.title }}
            </span>
          </div>
          <h2 style="font-size: 2rem;">
            {{ formatCurrency(Number(paymentData.transaction_amount)) }}
          </h2>
        </main>

        <div class="d-flex justify-content-between border-bottom px-4 custom-padding">
          <h4 class="mb-0 fw-normal">
            Empresa
          </h4>
          <p class="mb-0 text-muted">
            {{ paymentData.company }}
          </p>
        </div>
        <div class="d-flex justify-content-between border-bottom px-4 custom-padding">
          <h4 class="mb-0 fw-normal">
            Gerado em
          </h4>
          <p class="mb-0 text-muted">
            {{ formatDate(paymentData.created_at, 'D [de] MMMM [de] YYYY') }}
          </p>
        </div>
        <div
          class="d-flex justify-content-between px-4 custom-padding"
          v-if="paymentData.qr_code_base64 === null"
        >
          <h4 class="mb-0 fw-normal">
            {{ paymentData.payment_status === "approved" ? 'Pago em' : 'Expirado em' }}
          </h4>
          <p class="mb-0 text-muted">
            {{ formatDate(paymentData.updated_at, 'D [de] MMMM [de] YYYY') }}
          </p>
        </div>

        <copy-to-clipboard
          v-if="paymentData.copy_paste_pix_key != null"
          class="mt-4"
          :text-to-copy="paymentData.copy_paste_pix_key"
        >
          <template #main>
            <app-button
              class="w-100"
              data-test="copyPastePixKey-button"
            >
              Copiar chave Pix
            </app-button>
          </template>
        </copy-to-clipboard>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "@components/app-button";

import CopyToClipboard from '@dialer/manager/pages/dashboards/campaign/components/copy-to-clipboard';
import { mapActions, mapGetters } from "vuex";
import { Utils } from "@/utils"

export default {
  data() {
    return {
      badge: {
        color: "",
        background: "",
        title: ""
      }
    }
  },

  components: {
    AppButton,
    CopyToClipboard,
  },

  mounted() {
    this.fetchPaymentData({ payment_id: this.$route.params.id })
      .then(() => this.defineBadgeStatus());
  },

  computed: {
    ...mapGetters("admin/pix", {
      paymentData: "getPaymentData",
      loadingFlags: "getLoadingFlags"
    })
  },

  methods: {
    ...mapActions("admin/pix", ["fetchPaymentData"]),

    formatDate: Utils.formatDate,
    formatCurrency: Utils.formatCurrency,

    defineBadgeStatus() {
      switch (this.paymentData.payment_status) {
        case "expired":
          this.badge.color = "#920000";
          this.badge.background = "#F2C6D1";
          this.badge.title = "Expirado"
          break;
        case "approved":
          this.badge.color = "#015D0D";
          this.badge.background = "#D6E9D9";
          this.badge.title = "Pago"
          break;
        case "pending":
          this.badge.color = "#754000";
          this.badge.background = "#F7EAC4";
          this.badge.title = "Não Pago"
          break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-badge {
  padding-right: .5rem;
  padding-left: .5rem;
  border-radius: .375rem;
  line-height: .75rem;
}

.cover {
  background-image: url("~@/assets/img/covers/pix-payment-cover.png");
  background-size: cover;
}

.custom-padding {
  padding-top: .5rem;
  padding-bottom: .5rem;
}
</style>
