export default {
  startLoading: (state, flag) => state.loadingFlags[flag] = true,
  stopLoading: (state, flag) => state.loadingFlags[flag] = false,
  setList: (state, list) => state.list = list,
  clearList: (state) => state.list = [],
  setAlert: (state, alert) => {
    state.alert = alert
  },
  setErrors: (state, errors) => { state.errors = errors; },
  setErrorFlag: (state, { flag, errors }) => state.errorFlags[flag] = errors,
  clearErrors: (state) => {
    state.errors = {}
    state.errorMessage = ""
  },
  setFilters: (state, filters) => {
    state.filters = filters;
  },
  setPagination: (state, { pagination, flag }) => state.pagination[flag] = pagination,
  setCurrentPage: (state, { page, flag }) => state.pagination[flag].current_page = page,
  setRoutesFilters: (state, { search, flag }) => state.pagination[flag].search = search,
  setRoutes: (state, routes) => state.routes = routes,
  setRouteGroups: (state, routeGroups) => state.routeGroups = routeGroups,
  setUser: (state, user) => state.user = user,
}