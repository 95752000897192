<template>
  <new-schedule
    v-if="showSchedulesForm"
    @back="backToQualificationList"
    @schedule="handleSchedule"
  />
  <qualification-list
    v-else
    @select-qualification="handleQualification"
  />
</template>

<script>
import QualificationList from "./qualification-list"
import NewSchedule from "./new-schedule";
import { mapGetters, mapActions } from "vuex";

export default {
  emits: [
    "hide-qualification-dropdown",
    "qualification-form-selected"
  ],

  data() {
    return {
      showInputNotes: false,
      showSchedulesForm: false,
      scheduleQualification: {}
    }
  },

  components: {
    NewSchedule,
    QualificationList,
  },

  computed: {
    ...mapGetters("agent/call", {
      call: "getCall",
      manualCallAcw: "getManualCallACW"
    })
  },

  methods: {
    ...mapActions("agent/qualification", ["manualQualify", "qualify"]),

    async submit(qualification) {
      let qualificationMethod = ["dialer", "receptive"].includes(this.call.call_mode) ? this.qualify : this.manualQualify;

      await qualificationMethod(qualification)
        .then(() => this.$emit('hide-qualification-dropdown'));
    },

    handleQualification(data) {
      if (data.qualification.allow_schedule) {
        this.scheduleQualification = data;
        this.showSchedulesForm = true;
        this.$emit("qualification-form-selected", "new_schedule");
      } else {
        const qualification = {
          /**
           * O código abaixo não deve ser removido, por conta de uma regra de negócio
           * ainda não totalmente estabelecida no front novo, será necessário manter
           * quando descomentada a linha vai qualificar a chamada MTPA ao invés da
           * chamada corrente
           */
          // call_id: this.manualCallAcw ? this.manualCallAcw.telephony_id : this.call.telephony_id,
          call_id: this.call.telephony_id,
          fields: {
            qualification_id: data.qualification.id,
            qualification_type: data.qualification,
            qualification_note: data.note,
          }
        };
        this.submit(qualification);
      }
    },

    handleSchedule(data) {
      let date = data.date + " " + data.time + ":00";
      const qualification = {
        call_id: this.call.telephony_id,
        fields: {
          date: date,
          note: data.note,
          phone: data.phone,
          qualification_id: this.scheduleQualification.qualification.id,
          qualification_note: this.scheduleQualification.note,
        }
      };
      this.submit(qualification);
    },

    backToQualificationList() {
      this.showSchedulesForm = false;
      this.$emit("qualification-form-selected", "qualification");
    }
  }
}
</script>

<style lang="scss" scoped>
.container-overflow {
  width: 342px;
  margin-top: 7px;
  color: black;
}
</style>
