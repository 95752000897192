export default {
  startLoading: (state, flag) => state.loadingFlags[flag] = true,
  stopLoading: (state, flag) => state.loadingFlags[flag] = false,
  setImprovedMessage: (state, improvedMessage) => state.improvedMessage = improvedMessage,
  setSuggestedMessage: (state, suggestedMessage) => state.suggestedMessage = suggestedMessage,
  setNewMessage: (state, message) => state.newMessage = message,
  setMessages: (state, {chatId, messages}) => state.messages[chatId] = messages,
  setPagination: (state, {chatId, pagination}) => state.paginations[chatId] = pagination,
  setStashedMessages: (state, {chatId, stashedMessages}) => state.stashedMessages[chatId] = stashedMessages,
  setMessagesForSearch: (state, {chatId, messagesForSearch}) => state.messagesForSearch[chatId] = messagesForSearch,

  setPaginationForSearch: (state, {chatId, type, paginationForSearch}) => {
    if (!state.paginationsForSearch[chatId]) {
      state.paginationsForSearch[chatId] = {}
    }
    state.paginationsForSearch[chatId][type] = paginationForSearch
  },

  clearChatMessages: (state, chatId) => {
    delete state.messages[chatId]
    delete state.paginations[chatId]
    delete state.stashedMessages[chatId]
    delete state.messagesForSearch[chatId]
    delete state.paginationsForSearch[chatId]
  },

  clearChatSearchMessages: (state, chatId) => {
    delete state.messagesForSearch[chatId]
    delete state.paginationsForSearch[chatId]
  }
}
