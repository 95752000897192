<template>
  <canvas
    ref="chart"
    height="110"
  />
</template>

<script>
import {nextTick} from "vue";
import {Chart} from "chart.js";
import {formatSeconds} from "@/utils";

export default {
  data() {
    return {
      options: {
        type: "line",
        options: {
          height: 114,
          layout: {
            padding: {
              bottom: 0,
            }
          },
          plugins: {
            tooltip: {
              enabled: false,
              position: 'nearest',
              external: this.externalTooltipHandler
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              display: true,
              ticks: {
                font: {
                  size: 12,
                },
                count: 4,
                callback: this.formatTicks,
                // stepSize: 3,
              },
              grid: {
                display: true,
                drawOnChartArea: true,
                borderDash: [2, 2],
                color: "#DFDFDF"
              },
              suggestedMin: 0,
              // suggestedMax: 30
            },
            x: {
              display: true,
              ticks: {
                font: {
                  size: 12,
                },
                align: 'inner',
                padding: 6,
                maxRotation: 0,
                minRotation: 0,
                autoSkip: false,
              },
              grid: {
                display: true,
                drawOnChartArea: true,
                borderDash: [2, 2],
                color: function (context) {
                  if (context.tick.value == 0 || context.tick.value == 6 || context.tick.value == 12 || context.tick.value == 18 || context.tick.value == 23) {
                    return "#DFDFDF"
                  }
                  return '#FFF';
                },
              }
            },
          },
        },
        data: {
          labels: ['00:00', '', '', '', '', '', '06:00', '', '', '', '', '', '12:00', '', '', '', '', '', '18:00', '', '', '', '', '23:50'],
          datasets: [
            {
              borderWidth: 2,
              borderColor: this.lineColor,
              label: this.$t('today_capitalize'),
              data: [],
            },
            {
              borderWidth: 1,
              borderColor: this.dottedColor,
              backgroundColor: "#AFAFAF",
              borderDash: [5, 3],
              label: this.$t('yesterday_capitalize'),
              data: []
            }
          ],
        },
      },

      cursorX: 0,
      cursorY: 0,
    };
  },
  mounted() {
    new Chart(this.$refs.chart, this.options);

    window.addEventListener('mousemove', this.setCursorXPosition);
    window.addEventListener('mousemove', this.setCursorYPosition);
  },
  unmounted() {
    window.removeEventListener('mousemove', this.setCursorXPosition);
    window.removeEventListener('mousemove', this.setCursorYPosition);
  },
  props: {
    // Color of the line from the current day
    lineColor: {
      type: String,
      default: "blue",
    },

    // Color of the line from the previous day
    dottedColor: {
      type: String,
      default: "#AFAFAF",
    },

    // Hides the graph if is loading
    loading: {
      type: Boolean,
      default: true,
    },

    // Chart data from the current day
    data: {
      type: Array,
      default: () => new Array(24).fill(0)
    },

    // Chart data from the previous day
    dottedData: {
      type: Array,
      default: () => new Array(24).fill(0)
    },

    // If true shows the tooltip data as time using
    // the format: hh mm ss
    useTime: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        this.update();
      },
    },

    dottedData: {
      immediate: true,
      handler() {
        this.update();
      },
    },
  },

  methods: {

    /**
     * Get the chart instance object
     */
    getChartIntance() {
      return Chart.getChart(this.$refs.chart);
    },

    /**
     * Updates the chart data
     */
    async update() {
      await nextTick();
      let chart = this.getChartIntance();
      if (chart) {
        chart.data.datasets[0].data = this.data;
        chart.data.datasets[1].data = this.dottedData;

        chart.update();
      }
    },

    /**
     * Implements a personalized tooltip in the rendered chart
     *
     * @param { Object } context
     */
    externalTooltipHandler(context) {
      const {tooltip, chart} = context;
      const tooltipEl = this.getOrCreateTooltip(chart);

      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      // Set Text
      if (tooltip.body) {
        const bodyLines = tooltip.body.map(b => b.lines);
        const tableHead = document.createElement("thead");
        const tableBody = document.createElement("tbody");

        const trTimeLabel = document.createElement("tr");
        const tdTimeLabel = document.createElement("td");
        const timeLabelValue = document.createElement("span");
        tdTimeLabel.innerText = `${this.$t('time_capitalize')}`;
        tdTimeLabel.style.color = "#888888";
        tdTimeLabel.style.paddingBottom = "10px";
        timeLabelValue.innerText = `${tooltip.dataPoints[0].parsed.x}h`;
        timeLabelValue.style.color = "#1B1B2B";

        tdTimeLabel.appendChild(timeLabelValue);
        trTimeLabel.appendChild(tdTimeLabel);
        tableBody.appendChild(trTimeLabel);

        bodyLines.forEach((body) => {
          let [label, value] = body.toString().trim().split(" ");

          // If there's no data today, show only the data from yesterday
          if (value == undefined) {
            value = label;
            label = this.$t('yesterday_capitalize')
          }

          const span = document.createElement("span");
          if (label === this.$t('yesterday_capitalize'))
            span.style.borderBottom = "3px dashed #AFAFAF";
          else
            span.style.background = this.lineColor;

          span.style.marginRight = "8px";
          span.style.height = "3px";
          span.style.width = "18px";
          span.style.display = "inline-block";

          const tr = document.createElement("tr");
          tr.style.backgroundColor = "inherit";
          tr.style.borderWidth = 0;
          tr.style.borderBottom = label === "Hoje" && "1px solid #E4E4E4";

          const td = document.createElement("td");
          td.style.borderWidth = 0;
          td.style.display = 'flex';
          td.style.alignItems = 'center';
          td.style.whiteSpace = 'nowrap';

          if (label === this.$t('yesterday_capitalize'))
            td.style.paddingTop = "8px";
          else
            td.style.paddingBottom = "8px";

          const labelTextNode = document.createElement("span");
          labelTextNode.innerText = label;
          labelTextNode.style.marginRight = '0.8rem';
          const spanValue = document.createElement("span");

          if (this.useTime)
            spanValue.innerText = formatSeconds(value);
          else
            spanValue.innerText = value;

          spanValue.style.textAlign = "right";
          spanValue.style.marginLeft = 'auto';

          td.appendChild(span);
          td.appendChild(labelTextNode);
          td.appendChild(spanValue);
          tr.appendChild(td);
          tableBody.appendChild(tr);
        });

        const tableRoot = tooltipEl.querySelector("table");

        // Remove old children
        while (tableRoot.firstChild) {
          tableRoot.firstChild.remove();
        }

        // Add new children
        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
      }

      const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      if (tooltipEl.clientWidth + this.cursorX > window.innerWidth) {
        // If tooltip will overflow the screen change its position to avoid it
        tooltipEl.style.left = (context.chart.width * 1.1) - (tooltipEl.clientWidth - 150) + "px";
      } else {
        tooltipEl.style.left = positionX + tooltip.caretX + "px";
        tooltipEl.style.transform = "translateY(-20px)";
      }
      tooltipEl.style.top = positionY + tooltip.caretY + "px";
      tooltipEl.style.font = tooltip.options.bodyFont.string;
      tooltipEl.style.padding = "16px";
    },

    getOrCreateTooltip(chart) {
      let tooltipEl = chart.canvas.parentNode.querySelector("div");

      if (!tooltipEl) {
        tooltipEl = document.createElement("div");
        tooltipEl.style.background = "#FFF";
        tooltipEl.style.borderRadius = "12px";
        tooltipEl.style.border = "1px solid #E1E9F4";
        tooltipEl.style.color = "#1B1B2B";
        tooltipEl.style.opacity = "1";
        tooltipEl.style.pointerEvents = "none";
        tooltipEl.style.position = "absolute";
        tooltipEl.style.transition = "all .1s ease";
        tooltipEl.style.zIndex = "9999";
        tooltipEl.style.boxShadow = "0px 4px 18px 0px #22364D1F";
        tooltipEl.style.minWidth = '150px';

        const table = document.createElement("table");
        table.style.margin = "0px";
        table.style.width = "100%";
        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
      }
      return tooltipEl;
    },

    isArrrayEmpty(array) {
      return array.every(item => item === 0);
    },

    setCursorXPosition(event) {
      this.cursorX = event.clientX;
    },
    setCursorYPosition(event) {
      this.cursorY = event.clientY;
    },
    formatTicks(data) {
      return Math.round(data);
    }
  },
};
</script>

<style
    lang="scss"
    scoped
>
.chart {
  height: 120px;
}

</style>
