
export default {
    getLoadingFlags: state => state.loading,
    getList: state => state.list,
    getErrorMessage: state => state.errorMessage,
    getErrors: state => state.errors,
    getPagination: state => state.pagination,
    getCampaign(state) {
        return state.campagin
    },
}
