<template>
  <div
    class="tab-pane fade"
    id="tab-dialer"
    role="tabpanel"
    aria-labelledby="list-tab-dialer"
  >
    <div class="card mt-4">
      <div
        class="card-header"
        style="height: 56px;padding: 16px 24px;"
      >
        <div class="form-check form-switch d-flex align-items-center">
          <input
            id="switchAvaliationAI"
            class="form-check-input"
            :class="configAI.activeAvaliationAI ? 'bg-primary' : 'bg-gray-300'"
            type="checkbox"
            role="switch"
            v-model="configAI.activeAvaliationAI"
            style="height: 16px;width: 28px;"
            :checked="configAI.activeAvaliationAI"
            @change="switchAvaliationAI"
          >
          <label
            for="switchAvaliationAI"
            class="mb-0 cursor-pointer h4"
            style="margin-left: 16px;"
          >
            {{ $t("ai_evaluation") }}
          </label>
        </div>
      </div>
      <div
        class="card-body"
        :class="{ 'disable-interaction': !configAI.activeAvaliationAI }"
      >
        <div class="row mb-4">
          <div class=" col-12 col-lg-6">
            <field-selector
              id="select-campaigns"
              :current-selected="configAI.campaigns"
              :list-array="campaigns"
              :loading="loadingFlags.loadSettingsIA"
              :description="$t('added_calls_will_be_evaluate_by_ai')"
              icon="fa-regular fa-clipboard-list pb-1 fs-5 tx-blue-300"
              search-name="name"
              :title="$t('capitalizes.campaigns')"
              type-select="multi-selected"
              @selected="selectedCampaign"
            />
          </div>
          <div class=" col-12 col-lg-6">
            <field-selector
              id="select-qualifications"
              :current-selected="configAI.qualifications"
              :list-array="qualifications"
              :loading="loadingFlags.loadSettingsIA"
              :multi-select-check="true"
              :description="$t('only_calls_with_qualification_evaluate_ia')"
              icon="fa-regular fa-thumbs-up fs-5 tx-blue-300"
              search-name="name"
              :title="$t('qualifications_capitalize')"
              type-select="select-check"
              @selected="selectedQualification"
            />
          </div>
        </div>
        <div class="row">
          <div class=" col-12 col-lg-6">
            <field-selector
              id="min-call"
              :loading="loadingFlags.loadSettingsIA"
              :select-field="false"
              :description="$t('calls_less_than_min_time_not_evaluate')"
              icon="fa-regular fa-timer fs-5 tx-blue-300"
              search-name="name"
              :time-filed-name="$t('call_time')"
              :title="$t('min_time')"
            >
              <template #body>
                <app-input
                  v-model="configAI.min_call_duration"
                  :errors="errors.min_call_duration"
                  :label="$t('call_time')"
                  :small-text="$t('in_minutes')"
                  :max="15"
                  :min="1"
                  small-text-color="tx-text-gray"
                  type="number"
                />
              </template>
            </field-selector>
          </div>
          <div class=" col-12 col-lg-6">
            <field-selector
              id="max-call"
              :loading="loadingFlags.loadSettingsIA"
              :select-field="false"
              :description="$t('calls_more_than_max_time_not_evaluate')"
              icon="fa-regular fa-timer fs-5 tx-blue-300"
              search-name="name"
              :time-filed-name="$t('call_time')"
              :title="$t('max_time')"
            >
              <template #body>
                <app-input
                  v-model="configAI.max_call_duration"
                  :errors="errors.max_call_duration"
                  :label="$t('call_time')"
                  :small-text="$t('in_minutes')"
                  small-text-color="tx-text-gray"
                  type="number"
                  :max="15"
                  :min="1"
                />
              </template>
            </field-selector>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card"
      :class="{ 'disable-interaction': !configAI.activeAvaliationAI }"
    >
      <div
        class="card-header"
        style="height: 56px;padding: 16px 24px;"
      >
        <h4 class="mb-0">
          {{ $t("feedback_filter") }}
        </h4>
      </div>
      <div class="card-body">
        <field-selector
          id="select-feedback"
          :current-selected="settingsAI.criterion_list?.name"
          :list-array="feedbacks"
          :loading="loadingFeedbacks.loadFeedbacks"
          :description="$t('group_criteria_will_be_used_by_ia_evaluation')"
          icon="fa-regular fa-comments fs-5 tx-blue-300"
          search-name="name"
          :text-select="$t('ai_evaluation')"
          :title="$t('evaluation_criteria')"
          :label="$t('select_group')"
          type-select="single-selected"
          @selected="selectedFeedback"
        />
        <div v-if="errors.criterion_list_id">
          <p class="mb-0 text-danger mt-2 fs-6">
            {{ errors.criterion_list_id[0] }}
          </p>
        </div>
      </div>
    </div>
    <div style="padding-bottom: 40px;">
      <div
        class="card"
        :class="{ 'disable-interaction': !configAI.activeAvaliationAI }"
      >
        <div
          class="card-header"
          style="height: 56px;padding: 16px 24px;"
        >
          <h4 class="mb-0">
            {{ $t("call_frequency") }}
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div
              class="col-12 col-lg-6"
              style="height: 112px;"
            >
              <field-selector
                :select-field="false"
                icon="fa-regular fa-phone-plus fs-5 tx-blue-300"
                search-name="name"
                :title="$t('number_of_daily_evaluations_per_agent')"
              >
                <template #body>
                  <div style="margin-top: 25px;">
                    <app-input
                      v-model="configAI.calls_per_agent"
                      :errors="errors.calls_per_agent"
                      :min="1"
                      type="number"
                    />
                  </div>
                </template>
              </field-selector>
            </div>
            <div
              class="col-12 col-lg-6 background-image rounded overflow-hidden d-flex align-items-center justify-content-center"
              style="height:112px;"
            >
              <div class="d-flex align-items-center">
                <i
                  class="fa-sharp fa-regular fa-microchip-ai text-primary me-4"
                  style="font-size: 24px;"
                />
                <p class="mb-0">
                  Você possui {{ settingsAI.call_balance }} ligações disponíveis por mês.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="configAI.activeAvaliationAI"
      class="w-100 d-flex flex-column justify-content-center align-items-center"
    >
      <app-button
        @click="sendConfigAI"
        :disabled="isButtonDisabled || loadingFlags.updateSettingsIA"
        :loading="loadingFlags.updateSettingsIA"
        class="btn btn-primary col-5 mb-4"
      >
        {{ $t("capitalizes.save") }}
      </app-button>
      <button
        class="btn  col mb-3 tx-text-gray"
        @click="$router.push('/manager/insights-ai')"
      >
        {{ $t("back_capitalize") }}
      </button>
    </div>
  </div>
</template>

<script>
import AppButton from "@components/app-button.vue";
import AppInput from "@components/app-input.vue";
import FieldSelector from "@dialer/manager/pages/insights-ai/components/field-selector.vue";
import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
  name: "TabDialer",

  components: { FieldSelector, AppInput, AppButton },

  provide() {
    return {
      config: this.settingsIA,
    };
  },

  data() {
    return {
      frequency: [
        { id: 1, name: this.$t("day") },
        { id: 2, name: this.$t("week") },
        { id: 3, name: this.$t("month") },
      ],
      campaigns: [],
      qualifications: [],
      isSettings: false,
      isFeedbacks: false,
      feedback: {},
      configAI: {
        campaigns: [],
        qualifications: [],
        criterion_list: null,
        call_balance: 0,
        min_call_duration: 0,
        max_call_duration: 0,
        calls_per_agent: 0,
        activeAvaliationAI: true,
      },
      isButtonDisabled: true,
      selectFrequency: "",
    };
  },

  methods: {
    ...mapActions("manager/filters", ["findFilters", "setFiltersIncludes"]),
    ...mapActions("manager/insights", [
      "loadSettingsIA",
      "tempQualifications",
      "updateSettingsIA",
      "changeStatusAvaliationAI",
    ]),
    ...mapActions("manager/criterion", ["loadFeedbacks"]),
    ...mapActions("manager/qualification", ["findById"]),
    ...mapActions("auth", ["me"]),
    ...mapMutations("manager/insights", ["setErros"]),

    selectedCampaign($event) {
      const { type, index, id } = $event;
      if (type === "remove") {
        if (index >= 0 && index < this.configAI.campaigns.length) {
          const qualificationId = this.configAI.campaigns[index]?.dialer_settings?.qualification_list_id;
          this.configAI.campaigns.splice(index, 1);
          const isQualificationUsedByAnotherCampaign = this.configAI.campaigns.some(
              campaign => campaign.dialer_settings.qualification_list_id === qualificationId,
          );
          if (qualificationId && !isQualificationUsedByAnotherCampaign) {
            this.configAI.qualifications = this.configAI.qualifications.filter(
                qualification => qualification.list_id !== qualificationId,
            );
            this.qualifications.splice(index, 1);
          }
        }
      } else {
        const campaignExists = this.configAI.campaigns.some(campaign => campaign.id === id);
        if (!campaignExists) {
          this.tempQualifications(id).then(() => {
            this.configAI.campaigns.push(this.campaign);
          });
        }
      }
    },

    selectedQualification($event) {
      if (!this.configAI.qualifications.includes($event)) {
        this.configAI.qualifications = $event;
      }
    },

    selectedFeedback($event) {
      this.configAI.criterion_list = $event.id;
    },

    addQualifications() {
      if (this.qualificationsList) {
        const exists = this.qualifications.some(item => item.id === this.qualificationsList.id);
        if (!exists) {
          this.qualifications.push(this.qualificationsList);
        }
      }
    },

    handlerSettingsIA() {
      this.configAI.campaigns = this.settingsAI.campaigns;
      this.configAI.qualifications = this.settingsAI.qualifications.map(qualification => {
        return {
          ...qualification,
          checked: true,
        };
      });
      this.configAI.criterion_list = this.settingsAI.criterion_list ? this.settingsAI.criterion_list.id : null;
      this.configAI.activeAvaliationAI = this.settingsAI.active;
      this.configAI.calls_per_agent = this.settingsAI.calls_per_agent;
      this.configAI.min_call_duration = parseInt(this.settingsAI.min_call_duration / 60);
      this.configAI.max_call_duration = parseInt(this.settingsAI.max_call_duration / 60);
    },

    bindQualificationsToCampaings() {
      this.configAI.campaigns.forEach(campaign => {
        const { qualification_list_id } = campaign.dialer_settings;
        this.findById({ id: qualification_list_id });
      });
    },

    checkFieldsFilled() {
      for (const key in this.configAI) {
        if (this.configAI[key] === null || this.configAI[key] === "") {
          return false;
        }
      }
      return true;
    },

    sendConfigAI() {
      const campaigns = this.configAI.campaigns.map(campaign => campaign.id);
      const qualifications = this.configAI.qualifications.map(qualifications => qualifications.id);
      const config = {
        campaigns: campaigns,
        qualifications: qualifications,
        calls_per_agent: this.configAI.calls_per_agent,
        criterion_list_id: this.configAI.criterion_list,
        min_call_duration: parseInt(this.configAI.min_call_duration * 60),
        max_call_duration: parseInt(this.configAI.max_call_duration * 60),
      };
      this.updateSettingsIA(config).then(() => {
        this.me().then(() => this.$router.push("/manager/insights-ai"));
      });
    },

    filterCampaigns() {
      this.campaigns = this.filters.campaigns.active;
    },

    limitCallTime() {
      const minDuration = this.configAI.min_call_duration;
      const maxDuration = this.configAI.max_call_duration;
      const minCallsPerAgent = this.configAI.calls_per_agent;

      this.configAI.min_call_duration = Math.min(Math.max(parseInt(minDuration), 1), 15);
      this.configAI.max_call_duration = Math.min(Math.max(parseInt(maxDuration), 1), 15);
      this.configAI.calls_per_agent = Math.max(parseInt(minCallsPerAgent), 1);
    },

    switchAvaliationAI() {
      this.changeStatusAvaliationAI({ active: this.configAI.activeAvaliationAI });
    },
  },

  computed: {
    ...mapGetters("manager/filters", {
      filters: "getFilters",
    }),
    ...mapGetters("manager/qualification", {
      qualificationsList: "getRegister",
    }),
    ...mapGetters("manager/insights", {
      loadingFlags: "getLoadingFlags",
      settingsAI: "getSettingsIA",
      errors: "getError",
      currentTab: "getCurrentTab",
    }),
    ...mapGetters("manager/criterion", {
      feedbacks: "getDataSelect",
      loadingFeedbacks: "getLoadingFlags",
    }),
    ...mapGetters("manager/campaign", {
      campaign: "getCampaign",
    }),
  },

  mounted() {
    this.setFiltersIncludes(["qualifications"]);
    this.findFilters().then(() => {
      this.filterCampaigns();
    });
    this.loadSettingsIA().then(() => {
      this.handlerSettingsIA();
      this.bindQualificationsToCampaings();
    });
    this.loadFeedbacks();
  },

  unmounted() {
    this.setErros({});
  },

  watch: {
    qualificationsList(data) {
      if (data) {
        this.addQualifications();
      }
    },

    configAI: {
      handler() {
        this.isButtonDisabled = !this.checkFieldsFilled();
      },
      deep: true,
    },
    "configAI.min_call_duration": {
      handler: "limitCallTime",
      deep: true,
    },
    "configAI.max_call_duration": {
      handler: "limitCallTime",
      deep: true,
    },
    "configAI.calls_per_agent": {
      handler: "limitCallTime",
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.card,
.card-header {
  border-color: $color-gray-blue-300;
}

.disable-interaction {
  position: relative;
}

.background-image {
  background-image: url("~@assets/img/texture-text.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.disable-interaction::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  pointer-events: all;
  z-index: 10;
}
</style>
