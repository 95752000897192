export default {
  startLoadingFlags: (state, payload) => (state.loadingFlags[payload] = true),
  stopLoadingFlags: (state, payload) => (state.loadingFlags[payload] = false),
  setSettingsIA: (state, settings) => (state.settingsIA = settings),
  setErros: (state, payload) => (state.errors = payload),
  setDashboardIA: (state, payload) => (state.dashboardIA = payload),
  setCampaigns: (state, payload) => (state.campaigns = payload),
  setMetricsCampaign: (state, campaign) => (state.metricsCampaign = campaign),
  setCampaignsAgents: (state, payload) => (state.campaignsAgents = payload),
  setAgentsCalls: (state, payload) => (state.agentsCalls = payload),

  setCampaignsPagination: (state, page) => (state.campaignsPagination = page),
  setCampaignsCurrentPage: (state, page) =>
    (state.campaignsPagination.current_page = page),
  setCampaignsFilters: (state, filters) => (state.campaignsFilters = filters),

  setCampaignAgentsPagination: (state, page) =>
    (state.campaignAgentsPagination = page),
  setCampaignAgentsCurrentPage: (state, page) =>
    (state.campaignAgentsPagination.current_page = page),
  setCampaignAgentsFilters: (state, filters) =>
    (state.campaignAgentsFilters = filters),

  setAgentsPagination: (state, page) => (state.agentCallsPagination = page),
  setAgentsCallsCurrentPage: (state, page) =>
    (state.agentCallsPagination.current_page = page),
  setAgentsFilters: (state, filters) => (state.agentsCallsFilters = filters),
  setCurrentTab: (state, tab) => (state.currentTab = tab)
};
