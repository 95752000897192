import store from "@/store";
import IndexSystem from "@admin/pages/system/index-system.vue";
import LimitPerAgent from "@admin/pages/system/limit-per-agent.vue";

export default {
  path: "system",
  components: {
    main: IndexSystem
  },
  children: [
    {
      path: "",
      name: "limit-per-agent",
      components: {
        system: LimitPerAgent
      }
    },
  ],

  beforeEnter: (to, from, next) => {
    const restrictedAdminLevels =
      store.getters["admin/getRestrictedAdminLevels"];
    if (
      restrictedAdminLevels.includes(
        store.getters["auth/getUser"].settings.access
      )
    )
      next();
    else next("/");
  }
};
