<template>
  <div class="main-content">
    <div class="container-fluid">
      <header-component
        pretitle="OMNICHANNEL 3C+"
        :title="$t('agents_reports')"
        redirect-link="/manager/reports"
      />
      <menu-search
        @searched="searched = true"
        :avaliable-filters="['group-channels', 'teams', 'agents']"
        @update-filters="applyFilters"
        min-date="2024-08-03"
      />
      <ul
        class="nav nav-tabs"
        id="myTab"
        role="tablist"
      >
        <li
          class="nav-item"
          role="presentation"
        >
          <button
            class="nav-link active"
            id="chats-tab"
            data-bs-toggle="tab"
            data-bs-target="#chats-tab-pane"
            type="button"
            role="tab"
            aria-controls="chats-tab-pane"
            aria-selected="true"
          >
            {{ $t('chats') }}
          </button>
        </li>
        <li
          class="nav-item"
          role="presentation"
        >
          <button
            class="nav-link"
            id="time-tab"
            data-bs-toggle="tab"
            data-bs-target="#time-tab-pane"
            type="button"
            role="tab"
            aria-controls="time-tab-pane"
            aria-selected="false"
          >
            {{ $t('time') }}
          </button>
        </li>
        <li
          class="nav-item"
          role="presentation"
        >
          <button
            class="nav-link"
            id="contact-tab"
            data-bs-toggle="tab"
            data-bs-target="#contact-tab-pane"
            type="button"
            role="tab"
            aria-controls="contact-tab-pane"
            aria-selected="false"
          >
            {{ $t('capitalizes.qualifications') }}
          </button>
        </li>
      </ul>
      <div
        class="tab-content"
        id="myTabContent"
      >
        <div
          class="tab-pane fade show active"
          id="chats-tab-pane"
          role="tabpanel"
          aria-labelledby="chats-tab"
          tabindex="0"
        >
          <tab-chats :searched="searched" />
        </div>
        <div
          class="tab-pane fade"
          id="time-tab-pane"
          role="tabpanel"
          aria-labelledby="time-tab"
          tabindex="0"
        >
          <tab-time :searched="searched" />
        </div>
        <div
          class="tab-pane fade"
          id="contact-tab-pane"
          role="tabpanel"
          aria-labelledby="contact-tab"
          tabindex="0"
        >
          <tab-qualifications :searched="searched" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@admin/pages/3c-partners/partners/components/header-component.vue";
import TabChats from "@dialer/manager/pages/reports/chats-agents/components/tab-chats.vue";
import TabTime from "@dialer/manager/pages/reports/chats-agents/components/tab-time.vue";
import TabQualifications from "@dialer/manager/pages/reports/chats-agents/components/tab-qualifications.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import MenuSearch from "../components/menu-search.vue";

export default {
  name: "ChatsAgentsReport",
  components: { MenuSearch, TabQualifications, TabTime, TabChats, HeaderComponent },
  data() {
    return {
      searched: false
    };
  },

  computed: {
    ...mapGetters("manager/chats_agents_report", {
    })
  },

  methods: {
    ...mapMutations("manager/chats_agents_report", ["setFilters"]),
    ...mapActions("manager/chats_agents_report", ["fetchAgentsReportList"]),
    applyFilters(filters) {
      this.setFilters(filters);
      this.fetchAgentsReportList();
    }
  }
};
</script>

<style scoped lang="scss">
.nav-tabs {
  margin: 24px 0;
}
</style>
