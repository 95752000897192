<template>
  <div
    ref="modal"
    class="modal fade prevent-multiples-connections"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center b9">
          <img
            src="@assets/img/abduction.svg"
            class="mb-4"
          >
          <template v-if="asKToForce">
            <strong class="title">
              <p>{{ $t('connected_another_place') }}</p>
            </strong>
            <p
              class="b3"
              style="font-size: 14px"
            >
              {{
                $t('user_connected_another_place')
              }}
            </p>
            <div class="row options my-4 mx-5 b1 pt-4">
              <div class="col-5 b5">
                <button
                  class="w-100 py-3 close"
                  @click="exit()"
                >
                  {{ $t('close') }}
                </button>
              </div>
              <div class="col-5 offset-2 b5">
                <button
                  class="w-100 py-3 ok"
                  @click="force()"
                >
                  {{ $t('use_here') }}
                </button>
              </div>
            </div>
          </template>
          <template v-else>
            <strong class="title">
              <p>{{ $t('you_were_disconnected') }}</p>
            </strong>
            <p
              class="b3"
              style="font-size: 14px"
            >
              {{ $t('user_log_another') }} <br>
              {{ $t("computer_or_navigator") }}
            </p>

            <div class="row options my-4 mx-5 b1 pt-4">
              <div class="col-6 offset-3 b5">
                <button
                  class="w-100 py-3 ok"
                  @click="exit()"
                >
                  {{ $t('got_it') }}
                </button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { Modal } from "bootstrap";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      modal: null,
      channel: null,
      isActiveTab: undefined, // undefined, false, true
      timer: null,
      asKToForce: false,
    };
  },

  mounted() {
    this.modal = new Modal(this.$refs.modal);

    this.channel = new BroadcastChannel("tab");
    this.channel.addEventListener("message", (message) => {

      if (message.data == "another-tab" && this.isActiveTab)
        this.channel.postMessage("already-open");

      if (message.data == "already-open" && this.isActiveTab === undefined) {
        this.isActiveTab = false;
        this.setJsSIPActive(false);
        this.setSocketIOActive(false);
        this.asKToForce = true;
        this.modal.show();
      }

      if (message.data == "force-close" && this.isActiveTab) {
        this.isActiveTab = false;
        this.setJsSIPActive(false);
        this.setSocketIOActive(false);
        this.asKToForce = false;
        this.modal.show();
      }
    });

    this.channel.postMessage("another-tab");
    this.timer = setTimeout(() => {
      if (this.isActiveTab === undefined) {
        this.isActiveTab = true;
        if(!this.jssipIsActive && this.isAuthenticated) {
          this.setJsSIPActive(true);
        }
      }
    }, 1000); //wait tabs response
  },

  beforeUnmount() {
    this.channel.close();
    this.channel = null;
  },

  computed: {
    ...mapGetters("system", {
      jssipIsActive: "getJsSIPActive",
    }),

    ...mapGetters("auth", {
      isAuthenticated: "isAuthenticated"
    })
  },

  methods: {
    ...mapMutations("system", ["setJsSIPActive", "setSocketIOActive"]),

    ...mapActions("auth", ["logout"]),

    force() {
      this.channel.postMessage("force-close");
      this.modal.hide();
      this.setJsSIPActive(true);
      this.setSocketIOActive(true);
      this.isActiveTab = true;
    },

    exit() {
      this.modal.hide();
      this.logout();
      this.$router.push({
        name: "login",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.prevent-multiples-connections {
  color: $color-text-300;
  .options {
    border-top: 1px solid $color-gray-100;
    button {
      border-radius: 16px;
      &.close {
        border: 1px solid $color-blue-300;
        background-color: white;
        color: $color-blue-300;
      }
      &.ok {
        background-color: $color-blue-300;
        border: 1px solid $color-blue-300;
        color: white;
        font-weight: 300;
      }
    }
  }
}
</style>
