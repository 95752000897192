<template>
  <div
    ref="canvas"
    class="offcanvas offcanvas-end"
    style="width: 454px !important;"
  >
    <div class="offcanvas-header ">
      <h4 class="offcanvas-title">
        {{ $t('chat_details') }}
      </h4>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      />
    </div>
    <div
      v-if="data"
      class="offcanvas-body"
    >
      <div class="row">
        <h4>
          {{ $t("abstract") }}
        </h4>
        <div class="mt-4">
          <div class="col-12 d-flex">
            <div class="col-6">
              <label class="form-label tx-text-gray">
                {{ $t('group_channel') }}
              </label>
              <p class="text-truncate overflow-ellipsis pe-1">
                {{ data.group_channel? data.group_channel : '-' }}
              </p>
            </div>
            <div class="col-6">
              <label class="form-label tx-text-gray">
                {{ $t('channel') }}
              </label>
              <p class="overflow-ellipsis pe-1">
                {{ data.channel ? data.channel : "-" }}
              </p>
            </div>
          </div>
          <div class="col-12 d-flex">
            <div class="col-6">
              <label class="form-label tx-text-gray">
                {{ $t('agent_capitalize') }}
              </label>
              <p>
                {{ data.agent ? data.agent : "-" }}
              </p>
            </div>
            <div class="col-6">
              <label class="form-label tx-text-gray">
                {{ $t('protocol') }}
              </label>
              <p>
                {{ data.protocol ? data.protocol : "-" }}
                <icon-copy-text :text="data.protocol" />
              </p>
            </div>
          </div>
          <div class="col-12 d-flex">
            <div class="col-6">
              <label class="form-label tx-text-gray">
                {{ $t('client_name') }}
              </label>
              <p>
                {{ data.name ? data.name : "-" }}
              </p>
            </div>
            <div class="col-6">
              <label class="form-label tx-text-gray">
                {{ $t('number') }}
              </label>
              <p>
                {{ data.number ? formatPhoneNumber(data.number) : "-" }}
                <icon-copy-text :text="data.number" />
              </p>
            </div>
          </div>
          <div class="col-12 d-flex">
            <div
              v-if="data?.status"
              class="col-6"
            >
              <label class="form-label tx-text-gray">
                {{ $t('status_omni') }}
              </label>
              <p :class="getStatusColorClass(data?.status)">
                {{ getStatusName(data?.status) }}
              </p>
            </div>
            <div class="col-6">
              <label class="form-label tx-text-gray">
                {{ $t('qualification') }}
              </label>
              <p>
                {{ data.qualification ? data.qualification : "-" }}
              </p>
            </div>
          </div>
          <div class="col-12 d-flex">
            <div class="col-6">
              <label class="form-label tx-text-gray">
                {{ $t('start') }}
              </label>
              <p>
                {{ data.start ? formatDate(data.start) : "-" }}
              </p>
            </div>
            <div class="col-6">
              <label class="form-label tx-text-gray">
                {{ $t('end') }}
              </label>
              <p>
                {{ data.end ? formatDate(data.end) : "-" }}
              </p>
            </div>
          </div>
          <div class="col-12 d-flex">
            <div class="col-6">
              <label class="form-label tx-text-gray">
                {{ $t('queue_time') }}
              </label>
              <p>
                {{ data.time_in_queue ? formatDate(data.time_in_queue) : "-" }}
              </p>
            </div>
            <div class="col-6">
              <label class="form-label tx-text-gray">
                {{ $t('total_time_capitalize') }}:
              </label>
              <p>
                {{ data.total_time ? formatTotalTime(data.total_time) : "-" }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <timeline-chat
        v-if="false"
        :protocol="data.protocol"
        :contact-id="data.contact_id"
      />
    </div>
    <div class="offcanvas-footer">
      <div
        class="d-flex align-items-center"
        style="padding: 1.5rem 3rem; gap: 1rem"
      >
        <app-button
          :loading="loadingFlags.fetchChatPdf"
          :disabled="loadingFlags.fetchChatPdf"
          class="btn-export tx-text-gray col py-0"
          style="height: 32px"
          @click="exportReport(data)"
        >
          {{ $t('export') }}
        </app-button>
        <app-button
          class="col py-0"
          style="height: 32px"
          @click="spy(data)"
        >
          <i class="fa-regular fa-eye" /> {{ $t('see_chat') }}
        </app-button>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "@components/app-button.vue";
import { Offcanvas } from "bootstrap";
import { formatPhoneNumber } from "@/utils";
import moment from "moment";
import {mapActions, mapGetters, mapMutations} from 'vuex';
import IconCopyText from "@components/icon-copy-text.vue";
import TimelineChat from "./timeline-chat/timeline-chat.vue";

export default {
  name: "OffcanvasChatsReport",

  components: {
    TimelineChat,
    IconCopyText,
    AppButton
  },

  props: {
    data: {
      type: Object,
      default: ()=>{}
    }
  },

  data() {
    return {
      offcanvas: null,
    }
  },

  mounted() {
    this.offcanvas = new Offcanvas(this.$refs.canvas)
  },

  computed: {
    ...mapGetters("manager/chats_report", {
      loadingFlags: "getLoadingFlags",
    })
  },

  methods: {
    formatPhoneNumber,
    ...mapActions("manager/chats_report", ["fetchChatPdf"]),
    ...mapActions("whatsapp/chat", ["findChat"]),
    ...mapMutations("whatsapp/chat", ["setCurrentChat"]),

    open() {
      this.offcanvas.show()
    },

    formatDate(date) {
      return moment(date).format("DD/MM [às] HH[h]mm[m]");
    },

    formatTotalTime(date) {
      let duration = moment.duration(date, 'seconds');
      let hours = Math.floor(duration.asHours());
      let minutes = duration.minutes();
      let seconds = duration.seconds();

      return moment().startOf('day').add({ hours, minutes, seconds }).format("HH[h] mm[m] ss[s]");
    },

    getStatusColorClass(status) {
      switch(status){
        case "IN_PROGRESS":
          return "tx-green-300"
        case "IN_QUEUE":
          return "tx-blue-300"
        case "IN_SNOOZE":
          return "tx-orange-300"
        case "FINISHED":
          return ""
        default:
          return ""
      }
    },

    getStatusName(status) {
      switch(status){
        case "IN_PROGRESS":
          return this.$t('in_progress')
        case "IN_QUEUE":
          return this.$t('capitalizes.queue')
        case "IN_SNOOZE":
          return this.$t('list_metrics_on_hold')
        case "FINISHED":
          return this.$t('finalized_f')
        default:
          return ""
      }
    },

    exportReport(item) {
      const chatData = {
        id: item.chat_id,
        instance_id: item.instance_id,
        number: item.number
      }
      this.fetchChatPdf(chatData)
    },

    spy(item) {
      const chatId = item.number + item.instance_id

      this.findChat(chatId).then(chat => {
        this.setCurrentChat(chat);
        this.$router.push("/whatsapp/" + chat.id);
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.offcanvas-header {
  box-shadow: 0 5px 10px -2px rgba(30, 31, 37, 0.19);
}

.offcanvas-footer {
  box-shadow: 0 -5px 10px -2px rgba(30, 31, 37, 0.19);;
}

.btn-export {
  background: #E1E9F4;
  border: none;
  &:hover{
    background-color: #D2DDEC;
  }
}
</style>
