<template>
  <div class="d-flex justify-content-center align-items-center h-100">
    <div class="text-center">
      <img
        src="@/assets/img/omni-splash.svg"
        class="mb-1r"
      >
      <h1 class="mb-1r">
        Omnichannel 3C+
      </h1>
      <p
        class="mb-0 fs-4"
        v-html="$t('omni_no_chat')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "WhatsappNoAccess"
}
</script>

<style scoped lang="scss">
.mb-1r{
  margin-bottom: 1rem;
}
</style>