import axiosOmni from "@/plugins/axios-omni.js";

export default {
  fetchPerformanceReportList({ commit, getters }) {
    commit('startLoadingFlags', 'fetchQualificationChatsReportList');
    commit('setChatStatistics', [])
    commit('setChatQualifications', [])
    return new Promise((resolve, reject) => {
      axiosOmni.get('/whatsapp/reports/utilization', {params: getters.getParams })
        .then((response) => {
          commit('setChatStatistics', response.data.chats_utilization)
          commit('setChatQualifications', response.data.qualifications_utilization)
          resolve();
        }).catch((error) => {
          commit('system/setErrorMessage', error.response.data, { root: true })
          reject();
        })
        .finally(() => {
          commit('stopLoadingFlags', 'fetchQualificationChatsReportList')
        });
    });
  },

  fetchSendCsvEmail({commit, getters}, payload){
    commit('startLoadingFlags', 'fetchSendCsvEmail')
    return new Promise((resolve, reject) => {
      axiosOmni.get(`/whatsapp/reports/qualifications/performance/csv`, {params: {...getters.getParams, ...payload}})
      .then(() => {
        commit('system/setSuccessMessage', 'CSV enviado!', {root: true})
        resolve()
      })
      .catch((error) => {
        commit('system/setErrorMessage', error.response.data, { root: true })
        reject()
      })
      .finally(() => {
        commit('stopLoadingFlags', 'fetchSendCsvEmail')
      })
    })
  },
}
