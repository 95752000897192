<template>
  <tr
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
    :style="isHovered && !isSystemNative? 'background-color: #E1E9F4' : ''"
    :class="{'dragabble': !isSystemNative}"
  >
    <td
      style="width: 24px"
      :style="!isHovered ? 'border-top-color: transparent !important;' : ''"
      class="pe-0"
    >
      <div
        style="min-width: 16px; min-height: 16px;"
        :style="!isSystemNative ? 'cursor: grab' : '' "
        class="d-flex align-items-center justify-content-center"
      >
        <i
          v-show="isHovered && !isSystemNative"
          class="fa-regular fa-grip-dots-vertical"
        />
      </div>
    </td>
    <td style="width: 40px">
      <div class="d-flex align-items-center justify-content-center">
        <dot-status
          :color="qualification.color"
          size="8px"
        />
      </div>
    </td>
    <td>
      <div class="overflow-ellipsis tx-text-gray">
        {{ qualification.id }}
      </div>
    </td>
    <td>
      <span :class="isSystemNative ? 'tx-text-gray' : 'tx-text-200'">
        {{ qualification.name }}
      </span>
    </td>
    <td>
      <span class="tx-text-gray">
        {{ translateReadableImpact(qualification.is_positive) }}
      </span>
    </td>
    <td v-if="!isCRM">
      <span class="overflow-ellipsis">
        {{ translateReadableBehavior(qualification.behavior) }}
      </span>
    </td>
    <td>
      <span
        class="tx-text-gray"
        v-if="isSystemNative"
      >
        {{ $t("system_native_qualification") }}
      </span>
    </td>

    <td style="width: 114px">
      <div style="min-width: 82px; min-height: 42px">
        <button
          v-show="isHovered && !isSystemNative"
          class="btn tx-text-gray"
          @click="$emit('edit', qualification)"
        >
          <i class="fa-regular fa-pen-to-square" />
        </button>

        <button
          v-show="isHovered && !isSystemNative"
          style="color: #F23F2C"
          class="btn"
          @click="$emit('remove', qualification)"
        >
          <i class="fa-regular fa-trash" />
        </button>
      </div>
    </td>
    <td
      style="border-top: 0 !important; width: 32px"
      class="ps-0"
    />
  </tr>
</template>
<script>
import DotStatus from "@components/dot-status.vue";

export default {
  name: "QualificationItem",
  components: { DotStatus },

  props: {
    qualification: {
      type: Object,
      required: true,
    },

    isSystemNative: {
      type: Boolean,
      default: false,
    },

    isCRM: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "edit",
    "remove",
  ],

  data() {
    return {
      behaviors: {
        1: this.$t("not_dial_phone_again"),
        2: this.$t("not_dial_client_again"),
        3: this.$t("repeat"),
      },

      isHovered: false,
    }
  },

  methods: {
    translateReadableImpact(impact) {
      return impact ? this.$t("positive_capitalize") : this.$t("negative_capitalize");
    },

    translateReadableBehavior(behavior) {
      let translated_behavior = "";
      translated_behavior = this.behaviors[behavior]

      return translated_behavior;
    },

  },
}
</script>