<template>
  <div class="nav-item dropup">
    <a
      class="nav-link dropdown-toggle dropdown-wallet"
      role="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <div class="w-100 item-wallet d-flex align-items-center justify-content-between">
        <div>
          <i class="fa-regular fa-wallet fs-4 me-2" />
          <span>
            {{ formatCurrency(parseFloat(user.company.balance) + parseFloat(user.company.credit_limit)) }}
          </span>
        </div>
        <i
          @click="updateAccountBalance"
          class="fa-regular fa-arrows-rotate fs-4"
          :class="{
            'rotate-animation': updatingUser
          }"
        />
      </div>
    </a>
    <ul
      class="dropdown-menu position-fixed"
      style="left: 240px; bottom: 68px; width: 236px;"
    >
      <li class="dropdown-item d-flex justify-content-between">
        <p class="m-0">
          {{ $t('credit') }}
        </p>
        <p class="m-0">
          {{ formatCurrency(user.company.balance) }}
        </p>
      </li>
      <li>
        <hr
          class="dropdown-divider"
          style="margin: 0 1.5em"
        >
      </li>
      <li class="dropdown-item d-flex justify-content-between">
        <p class="m-0">
          {{ $t('limit') }}
        </p>
        <p class="m-0">
          {{ formatCurrency(parseInt(user.company.credit_limit)) }}
        </p>
      </li>
      <li
        class="dropdown-item"
        v-if="currency === 'BRL'"
      >
        <app-button
          class="w-100 btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#modalCredit"
        >
          {{ $t('add_credit') }}
        </app-button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Utils } from "@/utils";
import AppButton from "@/components/app-button.vue";

export default {
  name: "AccountBalance",
  data() {
    return {
      updatingUser: false,
      currency: ""
    };
  },
  components: {
    AppButton
  },
  computed: {
    ...mapGetters("auth", {
      user: "getUser"
    })
  },
  methods: {
    ...mapActions("auth", ["me"]),
    // formatCurrency: Utils.formatCurrency,
    updateAccountBalance() {
      if (!this.updatingUser) {
        this.updatingUser = true;
        setTimeout(() => {
          this.updatingUser = false;
        }, 2000);

        this.me();
      }
    },
    formatCurrency(value) {
      const coin = this.user.company.currency;
      const language = this.user.company["company-data"]?.data.language;
      this.currency = coin;
      return Utils.formatCurrency(value, language, coin);
    }
  }
};
</script>

<style lang="scss" scoped>
.rotate-animation {
  animation: rotate 2s infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
