export default {
  loadingFlags: {
    fetchQualificationChatsReportList: false,
    fetchSendCsvEmail: false,
  },
  errors: {},
  filters: {},
  include: [],
  qualificationList: [],
  spiedChat: null,
  chatLogs: {}
}
