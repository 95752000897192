<template>
  <section class="w-100 border card rounded">
    <div
      class="card-header border-0 p-0"
      style="height: 56px;"
    >
      <search-input
        @search="search"
        :search="term"
        class="w-100 mx-4"
      />
    </div>
    <div class="card-body p-0">
      <div class="table-responsive">
        <table class="table table-hover mb-0">
          <thead>
            <tr>
              <th
                v-for="(head, idx) in tableHead"
                :key="idx"
                :class="head.customClass"
              >
                {{ head.title }}
              </th>
            </tr>
          </thead>
          <tbody class="list fs-base">
            <loading-table-component
              v-if="loadingFlags['loadAgentsCalls']"
              :columns="4"
              :lines="5"
              :list-width="[72, 72, 72]"
            />
            <template v-else>
              <tr
                v-for="call in calls"
                :key="call.call_id"
                class="tx-text-gray"
                @click="$emit('openModal', call)"
                @mouseenter="activeHover = call.call_id"
                @mouseleave="activeHover = ''"
              >
                <td class="d-flex gap-4">
                  <div class="d-flex align-items-center overflow-ellipsis">
                    <copy-to-clipboard
                      :text-to-copy="call.call_history.data.number"
                      translate-y="-30px"
                      translate-x="-375px"
                      :position-relative="false"
                    >
                      <template #main>
                        <span class="cursor-pointer">
                          {{ call.call_history.data.number }}
                        </span>
                      </template>
                    </copy-to-clipboard>

                    <i
                      v-if="call.overall_score < 3"
                      class="fa-regular fa-triangle-exclamation tx-yellow-400 ms-3"
                    />
                  </div>
                </td>
                <td>
                  <p class="mb-0 tx-text-gray ">
                    <span
                      :class="[metricsNoteColor(call.overall_score)]"
                      class="fw-medium"
                    >{{ scoreFixed(call.overall_score) }}
                    </span>
                    /10
                  </p>
                </td>
                <td class="text-center">
                  {{ formatSeconds(call.call_history.data.speaking_time, true, "h[h] m[min] s[s]") }}
                </td>
                <td class="d-flex align-items-center justify-content-between">
                  <span>{{ formatDate(call.call_history.data.call_date, "DD/MM/YYYY [às] HH:mm") }}</span>
                  <i
                    v-show="activeHover === call.call_id"
                    class="far fa-chevron-right position-absolute end-0 me-4"
                  />
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div
        v-if="!calls.length && !loadingFlags['loadAgentsCalls']"
        class="text-center my-4"
      >
        <img
          src="@/assets/img/search-pluszinha.svg"
          alt="pluzinha busca"
        >
        <p class="mb-0 tx-text-200">
          {{ $t('no_calls_yet') }}
        </p>
      </div>
    </div>
    <div
      class="card-footer"
      v-if="pagination.total_pages > 1"
    >
      <app-paginator
        v-if="!loadingFlags['loadAgentsCalls']"
        :pagination="pagination"
        @change-page="changePage"
      />
    </div>
  </section>
</template>

<script>
import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";
import CopyToClipboard from '@dialer/manager/pages/dashboards/campaign/components/copy-to-clipboard';
import { formatSeconds, Utils } from "@/utils";
import SearchInput from "@components/search-input.vue";
import AppPaginator from "@components/app-paginator.vue";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TableAI",
  components: {
    AppPaginator,
    SearchInput,
    LoadingTableComponent,
    CopyToClipboard
  },
  props: {
    tableHead: {
      type: Array,
      default: () => [this.$t('CAMPAIGN'), this.$t('uppercases.evaluation'), this.$t('uppercases.call_duration'), this.$t('uppercases.date')]
    },
    customFunction: {
      type: Function,
      default: () => {}
    },
    calls: {
      type: Array,
      default: () => []
    },
    agentId: {
      type: Number,
      required: true
    }
  },
  emits: ["openModal", "closeModal"],
  data() {
    return {
      term: "",
      activeHover: ""
    };
  },
  computed: {
    ...mapGetters("manager/insights", {
      pagination: "getAgentCallsPagination",
      loadingFlags: "getLoadingFlags"
    })
  },
  methods: {
    ...mapActions("manager/insights", ["loadAgentsCalls", "setAgentsCallsCurrentPage", "setAgentsFilters"]),
    metricsNoteColor(value) {
      if (value >= 6) {
        return "tx-green-400";
      } else if (value >= 4) {
        return "tx-yellow-400";
      }
      return "tx-red-400";
    },
    formatDate: Utils.formatDate,
    formatSeconds,
    scoreFixed(value) {
      return value.toFixed(1);
    },
    changePage(page) {
      const ids = {
        campaignId: this.$route.params.id,
        agentId: this.agentId
      };
      this.setAgentsCallsCurrentPage(page);
      this.loadAgentsCalls(ids);
    },
    search: _.debounce(function(term) {
      const ids = {
        campaignId: this.$route.params.id,
        agentId: this.agentId
      };
      this.term = term;
      this.setAgentsFilters({
        search: term
      });
      this.loadAgentsCalls(ids);
    }, 300)
  }
};
</script>

<style scoped>
.table td {
  padding: 0 16px !important;
  line-height: 40px;
}
</style>
