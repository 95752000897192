<template>
  <partner-sidebar />
  <router-view name="main" />
  <whatsapp-redirect />
</template>

<script>
import WhatsappRedirect from "@partner/pages/component/whatsapp-redirect.vue";
import PartnerSidebar from "@partner/pages/component/partner-sidebar.vue";

export default {
  components: {
    WhatsappRedirect,
    PartnerSidebar
  }
}
</script>
