<template>
  <div class="card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <p class="card-header-title">
            {{ $t('chats_by_group_channels') }}
          </p>
        </div>
        <!--<div class="col-auto d-flex">-->
        <!--  <app-button-->
        <!--    v-if="chatsReportList"-->
        <!--    class="btn-sm btn-export tx-text-gray col-12 cursor-pointer"-->
        <!--    @click="show = true"-->
        <!--  >-->
        <!--    Baixar CSV-->
        <!--  </app-button>-->
        <!--</div>-->
      </div>
    </div>
    <div class="card-body p-0">
      <div class="table-responsive">
        <table class="table table-nowrap table-striped my-0 card-table">
          <thead>
            <tr>
              <th
                scope="col"
                class="column-title"
              >
                <sort-link
                  :title="$t('uppercases.group_channels')"
                  column="group_channel_name"
                  :dir="sorts.group_channel_name.dir"
                  @sort="sort"
                  @click.prevent
                  class="text-muted fw-normal"
                />
              </th>
              <th
                scope="col"
                class="column-title"
              >
                <sort-link
                  :title="$t('uppercases.chats')"
                  column="group_channel_chats"
                  :dir="sorts.group_channel_chats.dir"
                  @sort="sort"
                  @click.prevent
                  class="text-muted fw-normal"
                />
              </th>
              <th
                scope="col"
                class="column-title"
              >
                <sort-link
                  :title="$t('uppercases.initiated_by_the_agent')"
                  column="initiated_chats_agent"
                  :dir="sorts.initiated_chats_agent.dir"
                  @sort="sort"
                  @click.prevent
                  class="text-muted fw-normal"
                />
              </th>
              <th
                scope="col"
                class="column-title"
              >
                <sort-link
                  :title="$t('uppercases.initiated_by_the_client')"
                  column="initiated_chats_client"
                  :dir="sorts.initiated_chats_client.dir"
                  @sort="sort"
                  @click.prevent
                  class="text-muted fw-normal"
                />
              </th>
              <th
                scope="col"
                class="column-title"
              >
                <sort-link
                  :title="$t('uppercases.finished')"
                  column="finished_chats"
                  :dir="sorts.finished_chats.dir"
                  @sort="sort"
                  @click.prevent
                  class="text-muted fw-normal"
                />
              </th>
              <th
                scope="col"
                class="column-title"
              >
                <sort-link
                  :title="$t('uppercases.transfers_made')"
                  column="group_channel_transfers"
                  :dir="sorts.group_channel_transfers.dir"
                  @sort="sort"
                  @click.prevent
                  class="text-muted fw-normal"
                />
              </th>
              <th
                scope="col"
                class="column-title"
              >
                <sort-link
                  :title="$t('uppercases.messages_sent')"
                  column="messages_sent"
                  :dir="sorts.messages_sent.dir"
                  @sort="sort"
                  @click.prevent
                  class="text-muted fw-normal"
                />
              </th>
              <th
                scope="col"
                class="column-title"
              >
                <sort-link
                  :title="$t('uppercases.messages_received')"
                  column="messages_received"
                  :dir="sorts.messages_received.dir"
                  @sort="sort"
                  @click.prevent
                  class="text-muted fw-normal"
                />
              </th>
            </tr>
          </thead>
          <tbody v-if="loadingFlags.fetchGroupChannelsReportList">
            <loading-table-component
              :lines="5"
              :columns="8"
            />
          </tbody>
          <tbody v-else-if="chatsReportList && chatsReportList.group_channels_metrics">
            <tr
              v-for="item in chatsReportList.group_channels_metrics"
              :key="item.group_channel_id"
            >
              <td
                :title="item.group_channel_name"
                class="text-truncate overflow-ellipsis"
                style="max-width: 20px"
              >
                {{ item.group_channel_name }}
              </td>
              <td>{{ item.group_channel_chats }}</td>
              <td>
                {{ item.initiated_chats_agent }}
                <span class="tx-text-gray">
                  {{ calculatePercentage(item.group_channel_chats, item.initiated_chats_agent) }}
                </span>
              </td>
              <td>
                {{ item.initiated_chats_client }}
                <span class="tx-text-gray">
                  {{ calculatePercentage(item.group_channel_chats, item.initiated_chats_client) }}
                </span>
              </td>
              <td>{{ item.finished_chats }}</td>
              <td>{{ item.group_channel_transfers }}</td>
              <td>{{ item.messages_sent }}</td>
              <td>{{ item.messages_received }}</td>
            </tr>
            <tr class="fw-bold">
              <td>Total</td>
              <td>{{ total.group_channel_chats }}</td>
              <td>
                {{ total.initiated_chats_agent }}
                <span class="tx-text-gray">{{
                  calculatePercentage(total.group_channel_chats, total.initiated_chats_agent)
                }}</span>
              </td>
              <td>
                {{ total.initiated_chats_client }}
                <span class="tx-text-gray">{{
                  calculatePercentage(total.group_channel_chats, total.initiated_chats_client)
                }}</span>
              </td>
              <td>{{ total.finished_chats }}</td>
              <td>{{ total.group_channel_transfers }}</td>
              <td>{{ total.messages_sent }}</td>
              <td>{{ total.messages_received }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <table-empty v-if="!chatsReportList && !loadingFlags.fetchGroupChannelsReportList" />
  </div>
  <export-csv-reports
    :show="show"
    :loading="loadingFlags.fetchSendChatCsvEmail"
    @close="show = false"
    @download="download"
  />
</template>

<script>
import SortLink from "@dialer/manager/components/sort-link.vue";
import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import TableEmpty from "@dialer/manager/pages/reports/components/table-empty.vue";
import ExportCsvReports from "@dialer/manager/pages/reports/components/export-csv-reports.vue";
//import AppButton from "@components/app-button.vue";

export default {
  name: "TableChatsReport",
  components: { /*AppButton,*/ ExportCsvReports, TableEmpty, LoadingTableComponent, SortLink },
  data() {
    return {
      show: false,
      sorts: {
        group_channel_name: {
          dir: ""
        },
        group_channel_chats: {
          dir: ""
        },
        initiated_chats_agent: {
          dir: ""
        },
        initiated_chats_client: {
          dir: ""
        },
        finished_chats: {
          dir: ""
        },
        group_channel_transfers: {
          dir: ""
        },
        messages_sent: {
          dir: ""
        },
        messages_received: {
          dir: ""
        }
      }
    };
  },
  computed: {
    ...mapGetters("manager/group_channels_report", {
      loadingFlags: "getLoadingFlags",
      chatsReportList: "getChatsReportList"
    }),
    total() {
      return this.chatsReportList.group_channels_total_metrics;
    }
  },
  methods: {
    ...mapMutations("manager/group_channels_report", ["sortChatsReportList"]),
    ...mapActions("manager/group_channels_report", ["fetchSendChatCsvEmail"]),

    download(fieldEmail) {
      this.fetchSendChatCsvEmail(fieldEmail).then(() => {
        this.show = false;
      });
    },
    sort(params) {
      this.sorts["group_channel_name"].dir = "";
      this.sorts["group_channel_chats"].dir = "";
      this.sorts["initiated_chats_agent"].dir = "";
      this.sorts["initiated_chats_client"].dir = "";
      this.sorts["finished_chats"].dir = "";
      this.sorts["group_channel_transfers"].dir = "";
      this.sorts["messages_sent"].dir = "";
      this.sorts["messages_received"].dir = "";
      this.sorts[params.column].dir = params.dir;
      this.sortChatsReportList(params);
    },
    calculatePercentage(total, percentage) {
      const value = (percentage * 100) / total;
      if (Number.isNaN(value)) {
        return "(0%)";
      }
      return `(${value.toFixed(2)}%)`;
    }
  }
};
</script>

<style scoped lang="scss">
.btn-export {
  background: #e1e9f4;
  border: none;
  padding: 8px 16px;
  &:hover {
    background-color: #d2ddec;
  }
}
.column-title{
  width: 158px;
  white-space: normal;
  overflow-wrap: break-word;
}
</style>
