<template>
  <button
    class="btn btn-danger hangup-button px-4 fw-bold"
    :disabled="loadingFlags.hangup || isConsultHold"
    @click="sendToIvrAfterCall()"
  >
    {{ $t("IVR_after") }}
  </button>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("system", {
      isConsultHold: "isConsultHold",
    }),
    ...mapGetters("agent/call", {
      call: "getCall",
      loadingFlags: "getLoadingFlags"
    }),

    ...mapGetters("agent/agent", {
      loggedCampaign: "getCampaign"
    }),
  },

  methods: {
    ...mapActions("agent/call", ["hangup"]),
    sendToIvrAfterCall() {
      let ivrAfterCallId = this.loggedCampaign.ivr_after_call_id || 0;

      if (this.call.ivr_after_call_id && this.call.call_mode === "receptive") {
        ivrAfterCallId = this.call.ivr_after_call_id;
      }

      this.hangup({
        id: this.call.id,
        options: {
          ivr_after_call_id: ivrAfterCallId
        },
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.hangup-button {
  height: 32px;
  line-height: 0.9;
}
</style>
