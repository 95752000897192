<template>
  <div>
    <table class="table table-nowrap table-hover app-datatable m-0">
      <thead>
        <tr>
          <th class="ps-4">
            ID
          </th>
          <th class="w-50">
            PARTNER
          </th>
          <th class="w-25">
            CNPJ
          </th>
          <th class="w-25">
            CPF
          </th>
          <th class="w-25">
            CLIENTES ATIVOS
          </th>
          <th class="pe-auto" />
        </tr>
      </thead>
      <tbody>
        <LoadingTable
          v-if="loadingFlags.loadActivePartners"
          :columns="5"
          :lines="15"
          :list-width="[80, 300, 150, 80, 80]"
        />
        <tr v-else-if="!partners.length">
          <td
            class="text-center"
            colspan="5"
          >
            <img
              alt="Imagem nenhum parceiro encontrado"
              class="mb-4"
              src="@/assets/img/empty-box.svg"
            >
            <div v-if="partnersParams.search === ''">
              <h4>Nada para mostrar, por enquanto...</h4>
              <p class="text-muted fw-medium">
                Crie um novo partner
              </p>
              <button
                class="btn btn-outline-primary mb-5"
                style="width: 195px"
                type="button"
                @click="this.$router.push('/admin/partners/new')"
              >
                Novo partner
              </button>
            </div>
            <div v-else>
              <h4>Nenhum partner encontrado</h4>
              <p class="text-muted fw-medium mb-5">
                Tente uma nova pesquisa
              </p>
            </div>
          </td>
        </tr>

        <tr
          v-for="partner in partners"
          :key="partner.id"
          class="cursor-pointer"
        >
          <td
            class="ps-4"
            @click="routePartner(partner.user_id)"
          >
            <span
              href="#"
              style="color: #6E84A3;"
            >
              {{ partner.id }}
            </span>
          </td>
          <td @click="routePartner(partner.user_id)">
            <span href="#">{{ partner.cnpj ? partner.commercial_name : partner.name }}</span>
          </td>

          <td @click="routePartner(partner.user_id)">
            {{ partner.cnpj ? partner.cnpj : "-" }}
          </td>
          <td @click="routePartner(partner.user_id)">
            {{ partner.cpf ? partner.cpf : "-" }}
          </td>

          <td @click="routePartner(partner.user_id)">
            {{ partner.active_companies }}
          </td>

          <td
            class="pe-4"
            @click="routePartner(partner.user_id)"
          >
            <i class="fa-regular fa-chevron-right" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-if="!loadingFlags.loadActivePartners && this.partners.length">
    <div class="col-lg-12 card-footer">
      <paginator
        v-if="!loadingFlags.loadActivePartners"
        :pagination="pagination"
        @change-page="changePage"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import paginator from "@components/app-paginator.vue";
import LoadingTable from "@dialer/manager/components/loading-table-component.vue";

export default {
  name: "TablePartnersActive",

  props: {
    searchValue: {
      type: String,
      default: ""
    }
  },

  computed: {
    ...mapGetters("admin/partners", {
      partners: "getActivePartners",
      loadingFlags: "getLoadingFlags",
      pagination: "getActivePartnersPagination",
      partnersParams: "getActivePartnersParams"
    })
  },

  components: {
    paginator,
    LoadingTable
  },

  methods: {
    ...mapActions("admin/partners", [
      "loadActivePartners",
      "setActivePartnersCurrentPage",
      "setActivePartnersFilters",
      "detailsPartners"
    ]),

    changePage(page) {
      this.setActivePartnersCurrentPage(page);
      this.loadActivePartners();
    },
    routePartner(index) {
      this.detailsPartners(index).then(() => {
        this.$router.push(`/admin/partners/${index}`);
      });
    }
  },

  watch: {
    searchValue(search) {
      this.setActivePartnersFilters(search);
      this.setActivePartnersCurrentPage(1);
      this.loadActivePartners();
    }
  }
};
</script>
