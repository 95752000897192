
<template>
  <div class="bg-gray-blue-300 tx-text-gray rounded-4 div-block-waba d-flex justify-content-center align-items-center">
    {{ $t("sent_template_wait_cycle") }}
  </div>
</template>
<script>
import {mapMutations} from "vuex";

export default {
  name: "WabaBlock",
  props: {
    currentChat: {type: Object, required: true}
  },
  data() {
    return {
      end_message_cycle: null,
      countdownInterval: null
    }
  },
  mounted() {
    this.end_message_cycle = this.currentChat.waba_message_received.end_message_cycle;
    this.setBlockedTimer()
    this.countdownInterval = setInterval(this.setBlockedTimer, 1000);
  },
  methods: {
    ...mapMutations("whatsapp/chat", {
      setExpiredCycle: "setExpiredWaba"
    }),
    setBlockedTimer(){
      const endTime = this.end_message_cycle * 1000;
      const now = Date.now();

      const distance = endTime - now;

      if (distance < 60000) {
        clearInterval(this.countdownInterval);
        this.setExpiredCycle(this.currentChat.id);
      }
    }
  },
  beforeUnmount() {
    clearInterval(this.countdownInterval);
  },
}
</script>


<style scoped lang="scss">

</style>