<template>
  <div
    class="offcanvas-body d-flex flex-column gap-1rem"
  >
    <action-component
      icon-class="fa-regular fa-message-check"
      icon-color="#5CB868"
      :name="$t('multiple_choice_question')"
    />

    <div class="col-auto">
      <label
        class="form-label"
      >{{ $t('message_text') }}</label>
      <div
        class="form-control"
        v-html="textContent.replace(/\n/g, '<br>')"
      />
    </div>
    <p class="m-0 fw-medium">
      {{ $t('options') }}
    </p>
    <div
      v-for="(item,index) in choices"
      :key="index"
      class="col-auto"
    >
      <label
        class="form-label"
      >{{ item.expected_message_response }}</label>
      <textarea-with-count
        id="optionTitle"
        class="form-control"
        v-model="choices[index].text_content"
      />
    </div>
    <div
      v-for="(item, index) in new_choices"
      :key="index"
      class="col-auto"
    >
      <label
        class="form-label"
        for="optionTitle"
      >{{ item.expected_message_response }}
      </label>
      <div class="input-group">
        <textarea-with-count
          id="optionTitle"
          v-model="new_choices[index].text_content"
          :is-invalid="hasErrors && !new_choices[index].text_content"
          :placeholder="$t('option') + ' ' + item.expected_message_response"
          :max-length="255"
          :max-height="264"
        />
        <span
          class="input-group-text fe fe-x cursor-pointer"
          @click="new_choices.splice(index, 1)"
        />
      </div>
    </div>
    <hr class="w-100">
    <button
      class="btn btn-sm btn-light align-self-center btn-new-option"
      @click="addNewChoiceItem"
    >
      + {{ $t('new_option') }}
    </button>
    <div class="mt-auto" />
    <app-button
      :disabled="disableButton || loadingFlags.updateAction || loadingFlags.createAction"
      class="col-auto"
      @click="editAction"
    >
      {{ $t('capitalizes.save') }}
    </app-button>
    <app-button
      class="col-auto"
      color="text-danger"
      @click="_deleteAction"
    >
      <span class="fa-regular fa-trash" />
      {{ $t('capitalizes.exclude') }}
    </app-button>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

import ActionComponent from "@chatbot/pages/workflow/components/action-component.vue";
import AppButton from "@components/app-button.vue";
import TextareaWithCount from "@components/textarea-with-count.vue";

export default {
  name: "EditChoice",

  components: {
    TextareaWithCount,
    AppButton,
    ActionComponent
  },

  data() {
    return {
      textContent: "",
      choices: [],
      new_choices: [],
      hasErrors: false,
      originalChoices:[]
    }
  },

  mounted() {
    this.textContent = this.actionToEdit.text_content
    this.choices = this.actionToEdit.choices.data
    this.originalChoices = JSON.parse(JSON.stringify(this.actionToEdit.choices.data));
  },


  computed: {
    ...mapGetters("chatbot/actions", {
      actionToEdit: "getActionToEdit",
      loadingFlags: "getLoadingFlags",
    }),
    changedChoices(){
      return this.choices.filter((choice, index) => {
        const originalChoice = this.originalChoices[index];
        return choice.text_content !== originalChoice?.text_content;
      });
    },

    disableButton(){
      if(!this.new_choices.length && !this.changedChoices.length){
        return true
      }
      return Object.values(this.new_choices).some(el => !el.text_content)
          || Object.values(this.changedChoices).some(el => !el.text_content);

    }
  },

  methods: {
    ...mapActions("chatbot/chatbots", ["fetchChatbot"]),
    ...mapActions("chatbot/actions", [
      "createAction",
      "deleteAction",
      "updateAction"
    ]),
    ...mapMutations("chatbot/actions", [
      "setOffcanvasEditActionIsOpen",
      "setActionToEdit",
      "changeUpdateCoordinates"
    ]),

    addNewChoiceItem() {
      const totalChoices = this.choices.length + this.new_choices.length
      if (totalChoices == 12) {
        return
      }

      this.new_choices.push({
        text_content: "",
        expected_message_response: String(totalChoices + 1),
        default_next_action: "",
      })
    },

    editAction() {
      if (!this.textContent || Object.values(this.new_choices).some(el => !el.text_content)) {
        this.hasErrors = true
        return
      } else {
        this.hasErrors = false
      }

      const asyncCreateActions = this.new_choices.map((choice) => {
        const payload = {
          type: "multiple_choice_item",
          expected_message_response: choice.expected_message_response,
          chatbot_action_id: this.actionToEdit.id,
          text_content: choice.text_content,
          chatbot_id: this.$route.params.id
        }
        return this.createAction(payload)
      })

      const asyncEditActions = this.changedChoices.map((choice) =>{
        const payload = {
          id: choice.id,
          text_content: choice.text_content,
        }
        const actionId = choice.id
        return this.updateAction({actionId, payload})
      })
      Promise.all(asyncEditActions).then(() => {
        Promise.all(asyncCreateActions).then(() => {
          this.new_choices = []
          this.setOffcanvasEditActionIsOpen(false)
          this.fetchChatbot(this.$route.params.id)
        })
      })
    },

    _deleteAction() {
      this.deleteAction(this.actionToEdit.id).then(() => {
        this.setOffcanvasEditActionIsOpen(false)
        this.fetchChatbot(this.$route.params.id)
      })
    }
  },

  watch: {
    actionToEdit(action) {
      if (action.type === "multiple_choice") {
        this.textContent = action.text_content
        this.choices = action.choices.data
      }
    }
  },
}
</script>
<style scoped>
.gap-1rem {
  gap: 1rem;
}
.btn-new-option{
  width: 141px;
}
</style>
