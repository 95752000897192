<template>
  <div class="player-audio">
    <div class="d-flex flex-row align-items-center gap-2">
      <button
        class="btn p-0"
        v-if="playing"
        @click="pause"
        style="width: 20px"
      >
        <i class="fa-solid fa-pause" />
      </button>
      <button
        class="btn shadow-none play p-0"
        style="width: 20px"
        @click="play"
        v-else
      >
        <i class="fa-solid fa-play" />
      </button>
      <div
        class="show-time col text-center"
      >
        {{ currentTime }}
      </div>
      <bar
        :data="visualization"
        :size="23"
        :space="0.5"
        :item-width="2"
        :percentage="percentage"
        @select-time="selectTime"
      />
    </div>
  </div>
</template>

<script>
import Bar from "@/components/audio-summary.vue";
import { mapGetters } from "vuex";

export default {
  /**
   * data
   */
  data() {
    return {
      audio: null,
      duration: "00:00",
      currentTime: "00:00",
      percentage: 0,
      playing: false,
      timer: null,
      visualization: [],
      loading: false,
      failed: false,
    };
  },

  /**
   * register events
   */
  mounted() {
    this.visualization = new Array(42).fill(10);
    this.audio = new Audio();
    // this.load();
    this.audio.addEventListener("canplay", this.canplay);
    this.audio.addEventListener("ended", this.ended);
    this.audio.addEventListener("pause", this.paused);
  },

  /**
   * unregister events
   */
  beforeUnmount() {
    this.audio.removeEventListener("canplay", this.canplay);
    this.audio.removeEventListener("ended", this.ended);
    this.audio.removeEventListener("pause", this.paused);
    this.pause();
  },

  /**
   *
   */
  computed: {
    /**
     *
     */
    ...mapGetters("auth", {
      user: "getUser",
    }),
  },

  /**
   *
   */
  props: {
    path: {
      type: String,
      required: true,
    },
  },

  /**
   *
   */
  components: {
    Bar,
  },

  /**
   *
   */
  watch: {
    path() {
      this.load();
    },
  },

  /**
   *
   */
  methods: {

    /**
     *
     */
    load() {
      let audioContext = new AudioContext();
      this.loading = true;
      let url = this.path;
      if (this.user && this.user.company)
        url = url.replace("://app.", "://" + this.user.company.domain + ".");
      fetch(url)
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => {
          const blob = new Blob([arrayBuffer], { type: "audio/mp3" });
          this.audio.src = window.URL.createObjectURL(blob);
          audioContext.decodeAudioData(arrayBuffer).then((audioBuffer) => {
            this.loading = false;
            this.visualize(audioBuffer);
          });
        })
        .catch(() => {
          this.failed = true;
        });
    },

    /**
     *
     */
    visualize(audioBuffer) {
      let rawData = audioBuffer.getChannelData(0);
      let samples = 42;
      let blockSize = Math.floor(rawData.length / samples);
      const filteredData = [];
      for (let i = 0; i < samples; i++) {
        let blockStart = blockSize * i;
        let sum = 0;
        for (let j = 0; j < blockSize; j++) {
          sum = sum + Math.abs(rawData[blockStart + j]);
        }
        filteredData.push(sum / blockSize);
      }
      this.visualization = this.normalize(filteredData);
    },

    /**
     * 
     *
     * @param {*} data
     */
    normalize(data) {
      let temp = [];
      for (let item of data) {
        let t = (item * 10000) % 100;
        if (this.allowZero) {
          temp.push(t);
        } else {
          temp.push(t > 10 ? t : 10);
        }
      }
      return temp;
    },

    /**
     *
     */
    canplay() {
      let minutes = Math.floor(this.audio.duration / 60);
      let seconds = Math.round(this.audio.duration % 60);
      this.duration =
        minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
      minutes = Math.floor(this.audio.currentTime / 60);
      seconds = Math.round(this.audio.currentTime % 60);
      this.currentTime =
        minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
    },

    /**
     *
     */
    paused() {
      this.playing = false;
    },

    /**
     *
     */
    timeUpdate() {
      let minutes = Math.floor(this.audio.currentTime / 60);
      let seconds = Math.round(this.audio.currentTime % 60);
      this.currentTime =
        minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
      this.percentage = this.audio.currentTime / (this.audio.duration / 100);
    },

    /**
     *
     */
    ended() {
      this.playing = false;
      this.currentTime = "00:00";
      this.audio.currentTime = 0;
      this.percentage = 0;
      clearInterval(this.timer);
    },

    /**
     *
     */
    play() {
      this.audio.play();
      this.playing = true;
      this.timer = setInterval(() => {
        1;
        this.timeUpdate();
      }, 10);
    },

    /**
     *
     */
    pause() {
      this.audio.pause();
    },

    /**
     *
     * @param {*} percentage
     */
    selectTime(percentage) {
      this.audio.currentTime = this.audio.duration * (percentage / 100);
      this.percentage = percentage;
    },
  },
};
</script>

<style lang="scss">
.player-audio {
  .show-time {
    line-height: 23px;
    font-size: 12px;
    font-variant-numeric: tabular-nums;
  }
}
.message-audio {
  width: 360px;
  height: 64.5px;
  .play,
  .pause {
    border-radius: 10px;
    width: 31.5px;
    text-align: center;
    background-color: transparent;
    border: none;
    color: #2c7be5;
    font-size: 20px;
    &:hover {
      color: #2c7be5 !important;
      background-color: transparent;
    }
  }
  .bar {
    width: 50px;
    height: 17px;
    margin-top: 11px;
  }
  .show-time {
    line-height: 40.5px;
    font-size: 12px;
    font-variant-numeric: tabular-nums;
  }
  .loading {
    width: 31.5px;
    height: 31.5px;
    margin-top: 5px;
    color: #eaf2fd !important;
  }
  min-height: 40.5px;
}
</style>
