<template>
  <div class="panel panel-default">
    <div
      class="p-3 m-3 error-message"
      v-if="showMessage"
    >
      <p class="text-danger">
        {{ errorMessage }}
      </p>
      <button
        class="btn error-button"
        role="button"
        aria-pressed="true"
        @click="showErrorReload"
      >
        <i class="fas fa-sync-alt" />
        <p> Recarregar </p>
      </button>
    </div>
    <div class="panel-body p-3 m-3">
      <div
        class="btn btn-sm"
        :class="[inCall ? 'text-success fade-in' : 'btn-default fade-out']"
      >
        <i
          :class="[registered || inCall ? 'fa-phone' : 'fa-volume-control-phone blink_me']"
          class="fas fa-fw phone"
        />
      </div>

      <span>{{ inCall ? 'Em ligação' : label }}</span>

      <button
        v-if="registered && inCall"
        @click="muteMicrophone"
        style="background: none; border: none;"
      >
        <i
          :class="['fs-5 microphone fa-regular', muted ? 'fa-microphone-slash text-danger' : 'fa-microphone text-success']"
          aria-hidden="true"
        />
      </button>
    </div>
  </div>
  <audio
    id="audio"
    ref="audio"
    autoplay
  />
</template>

<script>
import jsSip from "jssip";
import axios from "axios"


export default {
  data () {
    return {
      user: null,
      ua: null,
      registered: false,
      inCall: false,
      wsAttempts: 0,
      registerAttempts: 0,
      session: null,
      muted: false,
      label: '',
      showMessage: false,
      errorMessage: "Não foi possível conectar. Por favor, tente recarregar a página.",
    }
  },

  mounted() {
    if (window.location.protocol !== 'http:') {
      this.showMessage = true
    } else {
      const host = window.location.host.split(".");
      const subdomain = host[0];
      const baseURL = process.env.VUE_APP_BA12AS.replace("app", subdomain);

      axios.create({
        baseURL,
      }).get("/me", { params: {api_token: this.$route.query.api_token, include: 'company' } })
        .then((response) => {
          this.user = response.data.data
          this.connectJsSip()
          this.sipEvents();
        });
    }
  },

  methods: {
    sipEvents () {
      this.ua.on('disconnected', (e) => {
        console.log('disconnected, trying reconnect...', e)
        this.label = 'Desconectado, tentando conectar...'
        this.showMessage = true
        this.wsAttempts++
        let attempts = this.inCall ? 10 : 5
        if (this.wsAttempts > attempts) {
          this.showErrorRegister()
          this.ua.stop()
        }
      })

      this.ua.on('newRTCSession', (data) => {
        let session = data.session
        this.session = session

        session.on('accepted', () => {
          this.inCall = true
        })

        session.on('peerconnection', (data) => {
          data.peerconnection.addEventListener('addstream', (data) => {
            document.getElementById('audio').srcObject = data.stream
            document.getElementById('audio').play()
          })
        })

        session.on('getusermediafailed', (e) => {
          console.log('getusermediafailed: ', e)
          this.showMessage = true
        })

        session.on('ended', () => {
          console.log('ended')
          this.session = null
          this.inCall = false
        })

        session.on('muted', () => {
          this.muted = true
        })

        session.on('unmuted', () => {
          this.muted = false
        })

        session.answer({
          mediaConstraints: {
            audio: true,
            video: false
          }
        })
      })

      this.ua.on('registered', () => {
        console.log('registered')
        this.label = 'Ramal registrado'
        this.registered = true
        this.showMessage = false
      })

      this.ua.on('unregistered', () => {
        console.log('unregistered')
        this.label = 'Ramal desconectado, tentando conectar...'
        this.registered = false
        this.showMessage = false
      })

      this.ua.on('registrationFailed', (e) => {
        console.log('registrationFailed', e)
        this.label = 'Tentando registrar...'
        this.registered = false
        this.showMessage = true
        setTimeout(() => {
          if (this.ua.isConnected() && !this.ua.isRegistered()) {
            if (this.registerAttempts <= 10) {
              console.log('trying register...')
              this.label = 'Tentando registrar...'
              this.ua.register()
              this.registerAttempts++
            } else {
              console.log('Register 10 attempts')
              this.showErrorRegister()
            }
          }
        }, 5000)
      })
    },

    connectJsSip () {
      console.log('connectJsSip: ', this.user)
      const host = this.user.company.domain + "." + process.env.VUE_APP_BA34ZX;
      const socket = new jsSip.WebSocketInterface("wss://" + host + ":4443")
      const configuration = {
        sockets: [socket],
        uri: "sip:" + this.user.telephony_id + "@" + host,
        password: this.user.extension_password,
        register: true,
        register_expires: 30,
        session_timers: false,
        no_answer_timeout: 60,
      };

      this.ua = new jsSip.UA(configuration);

      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(() => {
          console.log("[UserMedia] Permission is granted!");
          this.ua.start();
        })
        .catch(() => {
          console.log("[UserMedia] Not allowed");
          this.ua.stop();
          this.showMessage = true
        })
    },

    showErrorReload () {
      if (window.location.protocol !== 'https:') {
        window.location.href = 'https:' + window.location.href.substring(window.location.protocol.length)
        return;
      }
      window.location.reload()
    },

    muteMicrophone () {
      if (this.inCall) {
        if (this.muted) {
          this.session.unmute()
        } else {
          this.session.mute()
        }
      }
    },

  },
}
</script>

<style lang="scss" scoped>
    audio {
      display:none;
    }

    .phone {
      transform: scale(1.8,1.8);
    }

    .microphone {
      transform: scale(1.3,1.3);
    }

    .blink_me {
      animation: blinker 1s linear infinite;
    }

    @keyframes blinker {
      50% { opacity: 0; }
    }

    .panel-body {
      background-color: white;
      border-color: black;
      border-radius: 30px;
      display: flex;
      gap: 10px;
    }

    .error-message {
      border-radius: 30px;
      background-color: white;
      display: grid;
      align-content: space-between;
      justify-content: center;
      align-items: center;
      justify-items: center;
    }

    .error-button {
      background-color: #f5f5f5;
      border-radius: 30px;
      width: 150px;
      height: 60px;
      border-color: black;
    }
</style>
