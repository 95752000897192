<template>
  <ul>
    <slot />
  </ul>
</template>
<script>
export default {
  name: "CollapseProgress",
}
</script>
<style scoped lang="scss">
ul {
  list-style-type: none;
  padding-left: 0;
  position: relative;
  margin-bottom: 0;
}

:slotted(li) {
  position: relative;
  padding-left: 40px;
}

:slotted(li) .icon-progress {
  position: absolute;
  left: 0.25rem;
  top: 1.5rem;
  font-size: 1.5rem;
  color: $color-gray-300;
}


:slotted(li)::before {
  content: '';
  position: absolute;
  left: 15px;
  top: 2.9rem;
  bottom: -1.5rem;
  width: 2px;
  background-color: $color-gray-300;
  z-index: 0;
}

:slotted(li).completed::before {
  background-color: $color-blue-300;
  top: 2.9rem;
}

:slotted(li):not(.completed)::before {
  border-left: 2px dashed $color-gray-300;
  background: none;
}

:slotted(li):last-child::before {
  display: none;
}


</style>