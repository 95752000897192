<template>
  <div class="card">
    <div class="card-header ">
      <h4 class="mb-0">
        {{ $t('capitalizes.criteria') }}
      </h4>
      <ul
        class="nav nav-tabs p-0 m-0 list-unstyled"
        style="height: 60px; line-height: 58px;"
        id="myTab"
        role="tablist"
      >
        <li
          v-if="metrics.criteria.length >= 3 && metrics.criteria.length <= 8"
          class="nav-item h-100 p-0"
          role="presentation"
        >
          <button
            class="nav-link p-0 text-small"
            style="padding-bottom: 1px !important;"
            :class="{ 'fw-medium active': activeTabCriteria === 'graphic' }"
            :id="`graphic-${metrics.type}-tab`"
            data-bs-toggle="pane"
            :data-bs-target="`#graphic-${metrics.type}-pane`"
            role="pane"
            :aria-controls="`graphic-${metrics.type}-pane`"
            :aria-selected="tabGraphic"
            @click="activeTabCriteria = 'graphic'"
          >
            {{ $t('uppercases.graphic') }}
          </button>
        </li>
        <li
          class="nav-item h-100 p-0"
          role="presentation"
        >
          <button
            class="nav-link p-0 text-small"
            style="padding-bottom: 1px !important;"
            :class="{ 'fw-medium active': activeTabCriteria === 'insights' }"
            :id="`insights-${metrics.type}-tab`"
            data-bs-toggle="pane"
            :data-bs-target="`#insights-${metrics.type}-pane`"
            role="pane"
            :aria-controls="`insights-${metrics.type}-pane`"
            :aria-selected="tabInsights"
            @click="activeTabCriteria = 'insights'"
          >
            INSIGHTS
          </button>
        </li>
      </ul>
    </div>
    <div class="card-body p-0">
      <div class="tab-content">
        <div
          class="tab-pane fade p-4"
          :class="{ 'show active': activeTabCriteria === 'graphic' }"
          :id="`graphic-${metrics.type}-pane`"
          role="tabpanel"
          :aria-labelledby="`graphic-${metrics.type}-tabe`"
          tabindex="0"
          style="height: 240px;"
        >
          <radar-chart
            :chart-color="chartColor(metrics.overall_score)"
            :data="insightsNote().scores"
            :labels="insightsNote().names"
          />
        </div>
        <div
          :class="{ 'show active': activeTabCriteria === 'insights' }"
          class="tab-pane fade overflow-auto"
          :id="`insights-${metrics.type}-pane`"
          role="tabpanel"
          :aria-labelledby="`insights-${metrics.type}-pane`"
          tabindex="0"
          style="height: 240px;"
        >
          <div v-if="insightsNote().goodNote.length">
            <div class="py-2 bg-gray d-flex justify-content-between align-items-center px-4">
              <h5 class="card-title mb-0">
                {{ $t('doing_well') }}
              </h5>
              <img
                alt="star"
                src="@/assets/img/blue-message.svg"
                style="padding-right: 16px"
              >
            </div>
            <ul class="px-0  m-0 list-unstyled">
              <li
                v-for="(criterion, index) in insightsNote().goodNote"
                :key="criterion.id"
                class="d-flex align-items-center justify-content-between px-4"
                :class="{ 'border-bottom': index !== insightsNote().goodNote.length - 1 }"
              >
                <p class="mb-0">
                  {{ criterion.name }}
                </p>
                <p class="mb-0 tx-text-gray">
                  <span class="fw-medium tx-green-400">{{ scoreFixed(criterion.score) }}</span>
                  /10
                </p>
              </li>
            </ul>
          </div>
          <div
            v-if="insightsNote().badNote.length"
            class="py-2 bg-gray d-flex justify-content-between align-items-center px-4"
          >
            <h5 class="card-title mb-0">
              {{ $t('can_be_better') }}
            </h5>
            <img
              alt="star"
              src="@/assets/img/plus-star.svg"
              style="padding-right: 16px"
            >
          </div>
          <ul class="px-0 m-0 list-unstyled">
            <li
              v-for="(criterion, index) in insightsNote().badNote"
              :key="criterion.id"
              class="d-flex align-items-center justify-content-between px-4"
              :class="{ 'border-bottom': index !== insightsNote().badNote.length - 1 }"
            >
              <p class="mb-0">
                {{ criterion.name }}
              </p>
              <p class="mb-0 tx-text-gray">
                <span
                  class="fw-medium"
                  :class="[metricsNoteColor(criterion.score)]"
                >{{
                  scoreFixed(criterion.score)
                }}</span>
                /10
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RadarChart from "@dialer/manager/pages/insights-ai/components/radar-chart.vue";

export default {
  name: "CardCriteria",
  components: { RadarChart },
  props: {
    metrics: {
      type: [Object, Array],
      required: true
    }
  },
  data() {
    return {
      activeTabCriteria: "",
      tabGraphic: false,
      tabInsights: false
    };
  },
  mounted() {
    this.tab();
  },
  unmounted() {
    this.tab();
  },
  methods: {
    insightsNote() {
      let criteria;
      if (Array.isArray(this.metrics)) {
        criteria = this.metrics.flatMap(metrics => metrics.criteria);
      } else if (typeof this.metrics === "object") {
        criteria = this.metrics.criteria;
      }

      const badNote = criteria.filter(item => item.score < 6);
      const goodNote = criteria.filter(item => item.score >= 6);
      const names = criteria.map(item => item.name);
      const scores = criteria.map(item => item.score);
      return { badNote, goodNote, names, scores };
    },
    scoreFixed(value) {
      if (value) {
        return value.toFixed(1);
      }
    },
    metricsNoteColor(value) {
      if (value >= 6) {
        return "tx-green-400";
      } else if (value >= 4 && value < 6) {
        return "tx-yellow-400";
      } else {
        return "tx-red-400";
      }
    },
    chartColor(value) {
      if (value >= 6) {
        return "#5CB868BF";
      } else if (value >= 4 && value < 6) {
        return "#FFBD00BF";
      } else {
        return "#F23F2CBF";
      }
    },
    tab() {
      if (this.metrics.criteria.length >= 3 && this.metrics.criteria.length <= 8) {
        this.tabGraphic = true;
        this.activeTabCriteria = "graphic";
      } else {
        this.tabInsights = true;
        this.activeTabCriteria = "insights";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
li {
  padding-top: 8px;
  padding-bottom: 8px;
}
.bg-gray {
  background-color: $color-gray-blue-200;
}
</style>
