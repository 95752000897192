<template>
  <div
    class="d-flex align-items-center"
    style="gap: 16px"
  >
    <card-default
      v-if="action.type === 'default'"
      :node-id="nodeId"
      :action="action"
    />
    <card-sdr
      v-if="action.type == 'sdr'"
      :action="action"
    />
    <card-message
      v-else-if="action.type == 'question' && !action.need_chat_response || action.type === 'message'"
      :action="action"
    />
    <card-question
      v-else-if="action.type == 'question'"
      :action="action"
    />
    <card-choice
      v-else-if="action.type == 'multiple_choice'"
      :action="action"
      :choices="action?.choices?.data || action.multiple_choice_items"
      :id="action.id"
    />

    <card-transfer
      v-else-if="action?.transfer_to_group_channel_id || action.type === 'transfer'"
      :action="action"
    />
    <card-default-next-action
      v-else-if="action.type == 'back'"
      :text="$t('back_capitalize')"
      :action="action"
    />
    <card-default-next-action
      v-else-if="action.type == 'restart'"
      :text="$t('restart_chatbot')"
      :action="action"
    />
    <card-default-next-action
      v-else-if="action.type == 'finish'"
      :text="$t('end')"
      :action="action"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";

import CardTransfer from "@chatbot/pages/workflow/components/action-cards/card-transfer.vue";
import CardChoice from "@chatbot/pages/workflow/components/action-cards/card-choice.vue";
import CardMessage from "@chatbot/pages/workflow/components/action-cards/card-message.vue";
import CardQuestion from "@chatbot/pages/workflow/components/action-cards/card-question.vue";

import CardDefaultNextAction
  from "@chatbot/pages/workflow/components/action-cards/card-default-next-action.vue";
import CardSdr from "@chatbot/pages/workflow/components/action-cards/card-sdr.vue";
import CardDefault from "@chatbot/pages/workflow/components/action-cards/card-default.vue";

export default {
  name: "CardActions",

  components: {
    CardDefault,
    CardSdr,
    CardDefaultNextAction,

    CardQuestion,
    CardMessage,
    CardChoice,
    CardTransfer
  },

  props: {
    action: {
      type: Object,
      required: true
    },
    nodeId: {
      type: String,
      default: ''
    }
  },

  computed:{
    ...mapGetters("chatbot/actions", {
      actions: "getActions"
    }),
  },
};
</script>


