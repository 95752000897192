<template>
  <div
    v-if="pagination"
    class="d-flex justify-content-between"
  >
    <button
      class="btn tx-text-gray"
      :class="{
        'disabled text-muted': pagination.current_page === 1
      }"
      @click="$emit('change-page', pagination.current_page - 1)"
    >
      <span class="fa-regular fa-chevron-left pe-3" />
      {{ $t('prev_pg') }}
    </button>
    <div class="d-flex justify-content-between align-items-center">
      <div
        v-if="showQuantity"
        style="position: relative !important; left: -100px"
      >
        <div class="dropup">
          <span class="text-muted">
            {{ $t('show_capitalizes') }}:
          </span>
          <button
            aria-expanded="false"
            class="btn btn-sm dropdown-toggle align-items-top"
            data-bs-toggle="dropdown"
            style="background-color: rgba(227, 235, 246, 0.5) !important;"
            type="button"
          >
            {{ show }}
          </button>
          <div class="dropdown-menu px-2 py-0">
            <div
              class="dropdown-item px-1 pb-1"
              @click="$emit('change-quantity', 15)"
            >
              <input
                id="radio15"
                v-model="show"
                :value="15"
                class="form-check-input"
                name="flexRadioDefault"
                type="radio"
              >
              <span
                class="form-label mx-2"
                href="#"
              > 15 </span>
            </div>
            <div
              class="dropdown-item px-1 pb-1"
              @click="$emit('change-quantity', 30)"
            >
              <input
                id="radio30"
                v-model="show"
                :value="30"
                class="form-check-input"
                name="flexRadioDefault"
                type="radio"
              >
              <span
                class="form-label mx-2"
                href="#"
              > 30 </span>
            </div>
            <div
              class="dropdown-item px-1 pb-1"
              @click="$emit('change-quantity', 50)"
            >
              <input
                id="radio50"
                v-model="show"
                :value="50"
                class="form-check-input"
                name="flexRadioDefault"
                type="radio"
              >
              <span
                class="form-label mx-2"
                href="#"
              > 50 </span>
            </div>
            <div
              class="dropdown-item px-1 pb-1"
              @click="$emit('change-quantity', 'all')"
            >
              <input
                id="radio50"
                v-model="show"
                :value="'Todos'"
                class="form-check-input"
                name="flexRadioDefault"
                type="radio"
              >
              <span
                class="form-label mx-2"
                href="#"
              > {{ $t('all_dropdown') }} </span>
            </div>
          </div>
        </div>
      </div>
      <ul class="list-pagination pagination pagination-tabs card-pagination m-0">
        <li
          v-for="(item, i) in pages"
          :key="i"
          :class="{
            'active': item === pagination.current_page
          }"
        >
          <button
            class="page-link"
            :class="{
              disabled: item == '...'
            }"
            @click="$emit('change-page', item)"
          >
            {{ item }}
          </button>
        </li>
      </ul>
    </div>
    <button
      class="btn tx-text-gray"
      :class="{
        'disabled text-muted': pagination.current_page === totalPages
      }"
      @click="$emit('change-page', pagination.current_page + 1)"
    >
      {{ $t('next_pg') }}
      <span class="fa-regular fa-chevron-right ps-3" />
    </button>
  </div>
</template>

<script>

export default {
  name: "AppPaginator",
  emits: ["change-page", "change-quantity"],

  data() {
    return {
      pages: [],
      show: null,
      totalPages: 0
    };
  },

  mounted() {
    this.show = this.recentlyQuantity;
    this.updateLastPage();
    this.render();
  },

  watch: {
    pagination: {
      handler() {
        this.updateLastPage();
        this.render();
      },
      deep: true
    }
  },

  methods: {
    render() {
      this.pages = [];

      if (this.pagination) {
        for (let i = this.pagination.current_page - 3; i <= this.pagination.current_page + 3; i++) {
          if (i > 0 && i <= this.totalPages) this.pages.push(i);
        }
        if (this.totalPages > 3) {
          if (this.pagination?.current_page > 3) {
            this.pages.splice(0, 0, 1);
            if (this.pagination?.current_page - 3 == 1) {
              this.pages.splice(1, 1);
            }
            this.pages.splice(1, 0, "...");
          } else if (this.pagination.current_page < this.totalPages - 2) {
            this.pages.splice(this.totalPages - 1, 1, "...");
            this.pages.splice(this.totalPages, 1, this.totalPages);
          }
        }
      }
    },

    updateLastPage() {
      if (this.pagination) {
        this.totalPages = this.pagination.total_pages || this.pagination.last_page;
      }
    }
  },

  props: {
    pagination: {
      type: Object,
      default: function () {
        return null;
      }
    },
    showQuantity: {
      type: Boolean,
      default: false
    },
    recentlyQuantity: {
      type: Number,
      default: null
    }
  }
};
</script>
<style scoped>
.btn:hover {
  color: var(--color-blue-300) !important;
}

.nav-link.active {
  background-color: transparent !important;
}

.dropup .dropdown-toggle::after {
  vertical-align: 0 !important;
}
</style>
