import axiosOmni from "@/plugins/axios-omni.js"

export default {
  fetchQuickMessages({ commit }, groupChannelId)  {
    commit('startLoading', 'fetchQuickMessages');
    return new Promise((resolve, reject) => {
      axiosOmni
        .get(`/group-channel/${groupChannelId}/quick-messages`, { params: { per_page: -1 } })
        .then(response => {
          commit("setQuickMessages", response.data.data);
          resolve();
        })
        .catch((error) => {
          commit('system/setErrorMessage', error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit('stopLoading', 'fetchQuickMessages');
        });
    });
  },
}
