<template>
  <div class="main-content">
    <div class="container-fluid">
      <header-component
        pretitle="OMNICHANNEL 3C+"
        title="Relatório de Qualificações"
        redirect-link="/manager/reports"
      />
      <menu-search
        @searched="searched = true"
        :enable-advanced-filters="false"
        @update-filters="submit"
      />
      <div class="d-flex gap-4">
        <div class="col-12">
          <table-before-search v-if="!searched && !chatsReportList.length" />
          <qualification-chats-report-table
            v-else
            :data="chatsReportList"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@components/header-component.vue";
import TableBeforeSearch from "@dialer/manager/pages/reports/components/table-before-search.vue";
import {mapActions, mapGetters, mapMutations} from 'vuex';
import QualificationChatsReportTable
  from "@dialer/manager/pages/reports/chat-qualifications/components/qualification-chats-report-table.vue";
import MenuSearch from "@dialer/manager/pages/reports/components/menu-search.vue";

export default {
  name: "ChatsReport",

  components: {
    MenuSearch,
    QualificationChatsReportTable,
    TableBeforeSearch,
    HeaderComponent,
  },

  data() {
    return {
      searched: false,
    }
  },

  computed: {
    ...mapGetters("manager/qualification_chats_report", {
      loadingFlags: "getLoadingFlags",
      chatsReportList: "getQualificationList",
    }),
  },

  methods: {
    ...mapActions("manager/qualification_chats_report", ["fetchQualificationChatsReportList"]),
    ...mapMutations("manager/qualification_chats_report", ["setFilters"]),
    submit (filters) {
      this.setFilters({...filters})
      this.fetchQualificationChatsReportList()
    }
  },
}
</script>

