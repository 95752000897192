export default {
  startLoading: (state, flag) => state.loading[flag] = true,
  stopLoading: (state, flag) => state.loading[flag] = false,

  setLoadingContext: (state, loadingContext) => state.loadingContext = loadingContext,

  setErrors: (state, errors) => state.errors = errors,
  setFeedbackStatistic: (state, feedbackStatistic) => state.feedbackStatistic = feedbackStatistic,
  feedbackAgent: (state, feedbackAgent) => state.feedbackAgent = feedbackAgent,

  sortFeedbackStatistic: (state, sorting) => {
    state.feedbackStatistic.sort((a, b) => {
      return a[sorting.column] < b[sorting.column] ? -1 :
        (a[sorting.column] > b[sorting.column] ? 1 : 0)
    });
    if (sorting.dir == 'desc') state.feedbackStatistic.reverse();
  },

  addFeedbackAgent: (state, payload) => state.feedbackAgent[payload.id] = payload.data,
  clearFeedbackAgent: (state) => state.clearFeedbackAgent = {}
}
