<template>
  <div class="col-lg-12">
    <header-component
      :title="userName"
      pretitle="3C PARTNERS"
      redirect-link="/admin/partners"
    />
    <ul
      id="myTab"
      class="nav nav-tabs"
      role="tablist"
    >
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          id="dashboard-tab"
          ref="tab-dashboard"
          aria-controls="dashboard"
          aria-selected="true"
          class="nav-link p-1 active"
          data-bs-target="#dashboard"
          data-bs-toggle="tab"
          role="tab"
          type="button"
        >
          Dashboard
        </button>
      </li>
      <li
        class="nav-item"
        role="presentation"
      >
        <button
          id="register-tab"
          ref="tab-register"
          aria-controls="register"
          aria-selected="false"
          class="nav-link p-1"
          data-bs-target="#register"
          data-bs-toggle="tab"
          role="tab"
          type="button"
        >
          Cadastro
        </button>
      </li>
    </ul>

    <div class="col-lg-12">
      <div class="tab-content">
        <div
          id="dashboard"
          aria-labelledby="home-tab"
          class="tab-pane fade show active"
          role="tabpanel"
        >
          <dashboard-partner-admin />
        </div>
        <div
          id="register"
          aria-labelledby="home-tab"
          class="tab-pane fade show"
          role="tabpanel"
        >
          <create-update-partner-form :partner-details="user" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@admin/pages/3c-partners/partners/components/header-component.vue";
import DashboardPartnerAdmin from "@admin/pages/3c-partners/partners/components/dashboard-partner-admin.vue";
import { mapActions, mapGetters } from "vuex";
import CreateUpdatePartnerForm from "@admin/pages/3c-partners/partners/components/create-update-partner-form.vue";

export default {
  name: "PartnerDetails",
  components: {CreateUpdatePartnerForm, DashboardPartnerAdmin, HeaderComponent },
  mounted() {
    this.loadPartnerById(this.$route.params.id);
  },
  computed: {
    ...mapGetters("admin/partners", {
      user: "getPartner"
    }),
    userName() {
      return this.user.commercial_name || this.user.name;
    }
  },
  methods: {
    ...mapActions("admin/partners", ["loadPartnerById"])
  }
};
</script>
