<template>
  <div
    class="preview-file b5"
    ref="preview-file"
  >
    <div>
      <div
        class="b5 close-btn"
        @click="$emit('close')"
      >
        <span
          class="fe fe-x text-muted"
          style="font-size: 27px"
        />
      </div>
      <div class="center text-center">
        <div
          v-if="types.image.includes(file.type)"
          class="video-container"
        >
          <img
            alt="Image preview"
            class="img b4 video-preview"
            :src="createObjectURL(file)"
            style="object-fit: cover !important; border-radius: 10px"
          >
          <div class="d-flex flex-row send-container">
            <textarea
              ref="textareaImage"
              style="width: 320px"
              cols="30"
              rows="10"
              class="col"
              v-model="text"
              data-test="imageCaption-textarea"
            />
            <button
              class="submit"
              @click="emitImage()"
              data-test="sendFile-button"
            >
              <span class="fe fe-send h1 b3" />
            </button>
          </div>
        </div>
        <div
          class="w-100 h-100 d-flex flex-column justify-content-center align-items-center"
          v-else-if="types.audio.includes(file.type)"
        >
          <audio
            class="w-100 w-xl-50"
            controls
          >
            <source :src="createObjectURL(file)">
          </audio>
          <h1 class="text-muted b8 m-0 py-3">
            {{ file.name }}
          </h1>
          <small class="text-muted b2 m-0">
            {{ formatBytes(file.size) }}
          </small>
          <br>
          <button
            class="submit mx-0 my-3"
            @click="$emit('sendAudio')"
            data-test="sendFile-button"
          >
            <span class="fe fe-send h1 b3" />
          </button>
        </div>
        <div
          class="video-container"
          v-else-if="types.video.includes(file.type)"
        >
          <video
            class="video-preview"
            controls
          >
            <source
              :src="createObjectURL(file)"
              type="video/mp4"
            >
          </video>
          <div class="d-flex flex-row send-container">
            <textarea
              ref="textareaVideo"
              style="width: 320px"
              cols="30"
              rows="10"
              class="col"
              v-model="text"
              data-test="videoCaption-textarea"
            />
            <button
              class="submit"
              @click="emitVideo()"
              data-test="sendFile-button"
            >
              <span class="fe fe-send h1 b3" />
            </button>
          </div>
        </div>
        <div
          v-else
          class="w-100 h-100 d-flex flex-column justify-content-center align-items-center"
        >
          <file-icon
            :size="150"
            :file-name="file.name"
          />
          <h1 class="text-muted b8 m-0 py-3">
            {{ file.name }}
          </h1>
          <small class="text-muted b2 m-0">
            {{ formatBytes(file.size) }}
          </small>
          <br>
          <button
            class="submit mx-0 my-3"
            @click="emitDocument()"
            data-test="sendFile-button"
          >
            <span class="fe fe-send h1 b3" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fileIcon from "./file-icon.vue";
import {Utils, WhatsappMediaTypes} from "@/utils";
import {mapMutations} from "vuex";

export default {
  emits: ["close", "sendDocument", "sendImage", "sendAudio", "sendVideo"],

  data() {
    return {
      types: WhatsappMediaTypes,
      text: "",
      notAcceptedFileTypes: ["", "application/octet-stream", "application/x-msdownload", "text/x-sh"],
      fileMaxSize: 16000000
    };
  },

  mounted() {
    window.addEventListener("keydown", this.keydown)
    if (this.types.image.includes(this.file.type)) {
      this.$refs.textareaImage.focus()
    }
    else if (this.types.video.includes(this.file.type)) {
      this.$refs.textareaVideo.focus()
    }
  },

  beforeUnmount() {
    window.removeEventListener("keydown", this.keydown);
  },

  components: {
    fileIcon
  },

  props: {
    file: {
      type: File,
      default: null
    }
  },

  methods: {
    ...mapMutations("system", ["setErrorMessage", "setCustomizedTitleMessage"]),

    validate() {
      if (this.file)
        if (this.file.size > this.fileMaxSize) {
          this.$emit("close");
          this.setErrorMessage(this.$t('large_file'));
          this.setCustomizedTitleMessage(this.$t(" "));
        } else if (this.notAcceptedFileTypes.includes(this.file.type)) {
          this.$emit("close");
          this.setErrorMessage(this.$t('not_supported_format'));
        }
    },

    keydown(e) {
      if (e.keyCode === 27) {
        this.$emit("close");
        return
      }

      if (e.keyCode === 13) {
        if (this.types.image.includes(this.file.type)) this.emitImage();
        else if (this.types.audio.includes(this.file.type)) this.$emit("sendAudio");
        else if (this.types.video.includes(this.file.type)) this.emitVideo();
        else this.emitDocument();
      }
    },

    emitImage() {
      this.$emit("sendImage", this.text);
      this.text = "";
    },

    emitVideo() {
      this.$emit("sendVideo", this.text);
      this.text = "";
    },

    emitDocument() {
      this.$emit("sendDocument");
    },

    formatBytes: Utils.formatBytes,

    createObjectURL(file) {
      return URL.createObjectURL(file);
    }
  },

  watch: {
    file: {
      immediate: true,
      handler() {
        this.validate();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.preview-file {
  background-color: #edf2f9;
  padding: 36px 0px;
  overflow: hidden;
  height: calc(100vh - 56px);
  position: relative;
  z-index: 999;

  > div {
    height: 100%;

    .center {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);

      .img {
        width: 100%;
        background-color: black;
        height: 60vh;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: auto 100%;
      }

      .submit {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        position: relative;
        margin: 13px;
        margin-right: 0px;
        border: none;
        color: white;
        background-color: #2c7be5;
      }

      textarea {
        width: 100%;
        font-size: 14px;
        padding: 1rem !important;
        font-weight: 200;
        margin-top: 13px;
        margin-bottom: 13px;
        padding-left: 13px !important;
        padding-right: 13px !important;
        padding-top: 13px !important;
        padding-bottom: 13px !important;
        height: 46px;
        line-height: 20px;
        overflow: hidden;
        resize: none;
        border: none;
        outline: none;
        border-radius: 26px;
      }
    }
  }
}

.video-preview {
  min-width: 35rem;
}

.send-container {
  width: 35rem !important;
  margin: auto;
}

.close-btn {
  cursor: pointer;
  right: 15px;
  position: absolute;
  top: 0;
  z-index: 99999;
}

@media only screen and (max-width: 1199.999px) {
  .preview-file {
    padding: 36px 0;
    height: 100%;

    > div {
      .center {
        height: 100%;
      }
    }
  }


  .video-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 12px;
  }

  .video-preview {
    min-width: 0;
    width: 100% !important;
    height: 50% !important;
    border-radius: 10px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.33);
    object-fit: cover;
    object-position: center;
  }

  .send-container {
    width: 100% !important;
    margin: 0 auto;
  }
}
</style>
