<template>
  <div
    v-if="show"
    class="bg-gray-blue-200 d-flex justify-content-between rounded card-tip"
  >
    <div class="d-flex align-items-center">
      <i class="fa-regular fa-lightbulb-on d-inline-block" />
      <span class="ps-3 d-inline-block">
        {{ text }}
      </span>
    </div>
    <i
      class="fa-regular fa-xmark d-inline-block cursor-pointer"
      @click="show = false"
    />
  </div>
</template>

<script>
export default {
  name: "CardTip",
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: true,
    }
  },
}
</script>


<style scoped lang="scss">
.card-tip {
  padding: 16px;
  border: 1px solid $color-gray-blue-300;
  color: $color-blue-500
}
</style>