export default {
    startLoading: (state, flag) => state.loading[flag] = true,
    stopLoading: (state, flag) => state.loading[flag] = false,
    setList: (state, list) => state.list = list,
    setErrorMessage: (state, errorMesage) => state.errorMessage = errorMesage,
    setErrors: (state, errors) => state.errors = errors,
    clearErrors: (state) => {
        state.errors = {}
        state.errorMessage = ""
    },
    sortList: (state, sorting) => {
      if(sorting.currentSort == 'agent') {
        state.list.sort((a,b) => {
          if(a.agent.name.toLowerCase() < b.agent.name.toLowerCase())
            return -1;
          if(a.agent.name.toLowerCase() > b.agent.name.toLowerCase())
            return 1;
          else
            return 0;
        });
      } else {
        state.list.sort((a,b) => {
          if(a[sorting.currentSort] < b[sorting.currentSort])
            return -1;
          if(a[sorting.currentSort] > b[sorting.currentSort])
            return 1;
          else
            return 0;
        });
      }

      if(sorting.dir == 'desc')
        state.list.reverse();
    }
}
