<template>
  <div class="col-12 col-xl-6">
    <div class="card">
      <div class="card-header">
        <h4 class="card-header-title">
          {{ $t('capitalizes.calls') }}
        </h4>
      </div>
      <div class="card-body">
        <div class="row justify-content-center">
          <div
            class="col-12 col-lg-6 col-xl agent-queue-status p-0 text-truncate"
          >
            <p class="number-status">
              {{ Object.values(calls).filter((call) => call.agent == '-').length }}
            </p>
            <div class="d-flex align-items-center">
              <span class="me-1 dot-status bg-abandoned" />
              {{ $t('capitalizes.on_hold') }}
            </div>
          </div>

          <div
            class="col-12 col-lg-6 col-xl agent-queue-status p-0 text-truncate"
          >
            <p class="number-status">
              {{ Object.values(calls).filter((call) => call.agent != '-').length }}
            </p>
            <div class="d-flex align-items-center">
              <span class="me-1 dot-status bg-speaking" />
              {{ $t('capitalizes.talking') }}
            </div>
          </div>
        </div>

        <div class="table-responsive mt-4">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">
                  {{ $t('uppercases.phone') }}
                </th>
                <th scope="col">
                  {{ $t('uppercases.on_hold') }}
                </th>
                <th scope="col">
                  {{ $t('uppercases.talking') }}
                </th>
                <th scope="col">
                  {{ $t('uppercases.agent') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-muted"
                v-for="(call, index) in calls"
                :key="index"
              >
                <td>
                  <span :class="'me-1 dot-status bg-' + call.agent != '-' ? 'speaking' : 'abandoned'" />
                  <span class="fw-bold"> {{ formatPhoneNumber(call.phone) }} </span>
                </td>
                <td> {{ convertSecondsToTime(call.hold_time) }} </td>
                <td> {{ convertSecondsToTime(call.speaking_time) }} </td>
                <td> {{ call.agent }} </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { convertSecondsToTime, formatPhoneNumber } from "@/utils.js";

export default {
  computed: {
    ...mapGetters("manager/receptive_calls", {
      calls: "getCalls",
    }),
  },
  methods: {
    ...mapActions("manager/receptive_calls", ["loadCalls"]),
    convertSecondsToTime,
    formatPhoneNumber,
  },
  mounted() {
    this.loadCalls({ queue_id: this.$router.currentRoute._rawValue.params.id });
  },
};
</script>
<style>
.table > :not(:first-child) {
  border-top: none !important;
}

.number-status {
  font-size: 1.5rem;
  color: #8492a6;
  margin: 0;
}

.agent-queue-status {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dot-status {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
}
</style>
