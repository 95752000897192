import chatsReport from "@dialer/manager/pages/reports/chats/chats-report";

export default {
  path: "chats-report",
  name: "chats-report",
  components: {
    main: chatsReport,
  },
  meta: {
    "short-side-bar": true
  }
};
