<template>
  <div class="dropdown">
    <button
      ref="dropdown"
      :disabled="loading"
      aria-expanded="false"
      class="d-flex justify-content-center align-items-center btn btn-transfer-chat"
      :class="{
        'small-active': small
      }"
      data-test="transferChat-button"
      @click="toggleOpenTransferForm"
    >
      <i
        class="fa-regular"
        :class="{
          'fa-arrow-right-arrow-left': !opened,
          'fa-xmark': opened
        }"
      />
    </button>
    <div
      class="dropdown-menu border-0"
      ref="dropdownMenu"
    >
      <transfer-chat-form
        @handle-dropdown-click="toggleOpenTransferForm"
        @dropdown-hide="toggleOpenTransferForm"
      />
    </div>
  </div>
</template>
<script>
import {Dropdown} from "bootstrap";
import {mapGetters, mapMutations} from "vuex";
import TransferChatForm from "@dialer/manager/components/topbar/transfer-chat-form.vue";

export default {
  components: {TransferChatForm},
  data() {
    return {
      dropdown: null,
      opened: false
    };
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    },

    small: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    this.dropdown = new Dropdown(this.$refs.dropdown);

    this.$refs.dropdown.addEventListener('show.bs.dropdown', () => {
      this.opened = true;
    });
    this.$refs.dropdown.addEventListener('hide.bs.dropdown', () => {
      this.opened = false;
    });
    if (this.openTransferForm) {
      this.toggleOpenTransferForm()
    }
    document.addEventListener("click", this.handleClickOutside);
  },

  beforeUnmount() {
    this.$refs.dropdown.removeEventListener('show.bs.dropdown', () => {
      this.opened = true;
    });
    this.$refs.dropdown.removeEventListener('hide.bs.dropdown', () => {
      this.opened = false;
    });
    document.removeEventListener("click", this.handleClickOutside);
  },

  methods: {
    ...mapMutations("whatsapp/chat", ["toggleOpenTransferForm"]),

    handleClickOutside(event) {
      const dropdownButton = this.$refs.dropdown;
      const dropdownMenu = this.$refs.dropdownMenu;
      const buttonContains = dropdownButton.contains(event.target)
      const menuContains = dropdownMenu.contains(event.target)

      if (!buttonContains && !menuContains && this.opened) {
        this.toggleOpenTransferForm();
      }
    },
  },

  computed: {
    ...mapGetters("whatsapp/chat", {
      openTransferForm: "getOpenTransferForm",
    }),
  },

  watch: {
    openTransferForm(open) {
      if (open) {
        this.dropdown.show()
      } else {
        this.dropdown.hide()
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-transfer-chat {
  height: 32px;
  width: 32px;
  background-color: #E1E9f4;
  color: #677C92;

  &.show, &:hover {
    background-color: #3057f2;
    color: white;
  }

  &.small-active {
    max-width: 32px !important;
    min-width: 32px !important;
    width: 32px !important;
    padding: 0.25rem !important;
    font-size: 0.875rem !important;
  }
}

.dropdown-menu {
  width: 340px;
  max-height: 600px;
  border-radius: 0.625rem;
  box-shadow: 0 0.25rem 1.125rem 0 rgba(34, 54, 77, 0.12);
  padding: 1.5rem;

  .nav {
    height: 30px !important;
  }

  .option {
    padding: 0.5rem;
    line-height: 1.3;

    &:hover,
    &:focus,
    &:focus-visible {
      background-color: $color-gray-blue-300;
    }
  }

  .tab-pane {
    max-height: 400px !important;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px !important;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-gray-200 !important;
      border-radius: 50px !important;
    }
  }

  .disabled {
    opacity: 0.6 !important;
  }
}
</style>
