<template>
  <select
    class="form-select"
    :disabled="loadingFlags.getDataSelect"
    @input="$emit('update:modelValue', $event.target.value)"
    :value="modelValue"
  >
    <option
      :label="item.name"
      v-for="item in dataSelect"
      :key="item.id"
      :value="item.id"
    />
  </select>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  emits: ["update:modelValue"],

  mounted() {
    this.getDataSelect();
  },

  computed: {
    ...mapGetters("manager/ivrs", {
      dataSelect: "getDataSelect",
      loadingFlags: "getLoadingFlags",
    }),
  },

  props: {
    modelValue: {
      default: "",
      type: String,
    },
  },

  methods: {
    ...mapActions("manager/ivrs", ["getDataSelect"]),
  },
};
</script>
