<template>
  <div class="form-group">
    <label class="form-label text-capitalize">{{ $t("agent") }}<span class="text-lowercase">(s)</span></label>
    <button
      type="button"
      class="btn btn-link p-0"
      ref="agentsInfo"
    >
      <span class="fe fe-info text-primary ms-2 cursor-pointer" />
    </button>
    <small class="form-text text-muted">{{ $t("no_team_agent") }}</small>
    <VueMultiselect
      @focus="handleAmplitude"
      label="name"
      :placeholder="$t('select_more_agents')"
      :select-label="$t('click_or_enter_to_select')"
      :deselect-label="$t('click_or_enter_to_remove')"
      :selected-label="$t('selected')"
      track-by="id"
      :disabled="
        loading &&
          (loadingContext == 'add-agent' || loadingContext == 'remove-agent')
      "
      @select="select"
      @remove="remove"
      :options="options"
      v-model="selecteds"
      :multiple="true"
    />
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { generateTooltip } from "@/utils.js";

export default {
  data() {
    return {
      selecteds: [],
      options: [],
      agentsInfoTooltip: null,
    };
  },

  /**
   *
   */
  mounted() {
    this.load();
  },

  /**
   *
   */
  computed: {
    /**
     * Getters
     */
    ...mapGetters("manager/campaign", {
      agentsPerCampaign: "getAgetsPerCampagin",
      loading: "isLoading",
      loadingContext: "getLoadingContext",
    }),

    /**
     *
     */
    ...mapGetters("manager/agent", {
      activeDataSelect: "getActiveDataSelect",
    }),
  },

  /**
   *
   */
  watch: {
    /**
     *
     */
    agentsPerCampaign(data) {
      let temp = [];
      for (let i in data) {
        temp.push({
          id: data[i].id,
          name:
            data[i].extension.extension_number +
            " - " +
            data[i].name,
        });
      }
        this.selecteds = temp;
    },

    /**
     *
     */
    activeDataSelect(activeDataSelect) {
      this.options = [];
      for (let agent of activeDataSelect) {
        this.options.push({
          name: agent.extension.extension_number + " - " + agent.name,
          id: agent.id,
        });
      }
    },
  },

  /**
   *
   */
  components: {
    VueMultiselect,
  },

  /**
   *
   */
  props: {

    /**
     *
     */
    campaign: {
      require: true,
      default: null,
      type: Object,
    },
  },

  /**
   *
   */
  methods: {
    /**
     *
     */
    ...mapActions("manager/campaign", [
      "findAgentsByCampaign",
      "addAgentsInCampaign",
      "deleteAgentsFromCampaign",
    ]),
    ...mapActions("manager/agent", ["loadActiveDataSelect"]),
    ...mapMutations("system", ["setFlashMessage"]),
    ...mapMutations("auth", ["trackEvents"]),

    handleAmplitude(){
      this.trackEvents('[Manager] Edit Team')
    },
    generateTooltip,

    /**
     *
     */
    load() {
      this.findAgentsByCampaign({ id: this.campaign.id });
      this.loadActiveDataSelect();


      this.agentsInfoTooltip = this.generateTooltip(this.$t('tooltips.agents_info'), this.$refs.agentsInfo);

    },

    /**
     *
     */
    remove(agent) {
      this.deleteAgentsFromCampaign({
        campaign_id: this.campaign.id,
        agent_id: agent.id,
      }).catch((data) => {
        this.load();
        this.setFlashMessage({
          message: data.message,
          type: "danger",
        });
      });
    },

    /**
     *
     */
    select(agent) {
      this.addAgentsInCampaign({
        campaign_id: this.campaign.id,
        agents: [agent.id],
      }).catch((data) => {
        this.load();
        this.setFlashMessage({
          message: data.message,
          type: "danger",
        });
      });
    },

    /**
     *
     */
  },
};
</script>
