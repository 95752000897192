<template>
  <modal-component
    :show="show"
    @close="onHideModal"
    :center="true"
  >
    <template #content>
      <div class="row justify-content-center">
        <button
          type="button"
          class="btn-close"
          @click="show = false"
          style="position: absolute; right: .8rem; top: .8rem;"
        />
        <div class="col-lg-9 mt-5">
          <h2 class="text-center">
            Alteração da data de bloqueio
          </h2>
          <hr class="nav-divider">
        </div>
        <div class="col-lg-6 mb-4">
          <div class="d-flex justify-content-between">
            <p class="text-muted">
              Bloqueio atual da empresa
            </p>
            <span v-if="blockedAt != null">
              {{ timestampToDate(blockedAt, 'DD/MM/Y') }}
            </span>
            <span v-else>
              -
            </span>
          </div>
          <div
            class="input-group input-group-merge"
            ref="date"
          >
            <flat-pickr
              class="form-control rounded-start"
              placeholder="Data"
              type="text"
              aria-label="Input group appended"
              aria-describedby="inputGroup"
              v-model="date"
              data-input
              :config="config"
            />
            <div
              class="input-group-text cursor-pointer"
              data-toggle
            >
              <i
                class="fe fe-calendar"
                style="color: var(--color-blue-300);"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-8 mb-5">
          <label
            class="form-label fs-6 fw-normal"
            for="textArea"
          >
            Motivo da alteração<span style="color: var(--color-red-300);">*</span>
          </label>
          <div class="d-flex justify-content-center">
            <div class="input-group">
              <textarea
                placeholder="Digite aqui"
                v-model="changeReason"
                class="form-control rounded-4"
                id="textarea"
                rows="5"
                required
              />
            </div>
          </div>
          <app-button
            @click="submitChangeBlockDate()"
            class="w-100 mt-5 mb-3 rounded-4"
            :class="(!changeReason || date == null) ? 'btn-light disabled' : 'btn-primary'"
            :disabled="!changeReason || loading.releaseAccessToBlockedCompany || date == null"
            :loading="loading.releaseAccessToBlockedCompany"
          >
            Confirmar
          </app-button>

          <button
            class="w-100 btn btn-outline-secondary border-0 rounded-4"
            @click="show = false"
          >
            Cancelar
          </button>
        </div>
      </div>
    </template>
  </modal-component>
</template>


<script>
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import { Utils } from "@/utils";
import ModalComponent from '@/components/modal-component.vue';
import moment from "moment";
import { mapActions, mapGetters } from "vuex"
import AppButton from "@components/app-button";

export default {
  data() {
    return {
      show: false,
      config: {
        locale: Portuguese,
        inline: false,
        altInput: true,
        altFormat: 'd/m/Y',
        enableTime: false,
        defaultDate: '',
        wrap: true,
        minDate: (new Date(this.blockedAt*1000).fp_incr(-30) < Date.now()) ? Date.now() : new Date(this.blockedAt*1000).fp_incr(-395),
        maxDate: new Date(this.originalBlockDate*1000).fp_incr(365)
      },
      changeReason: '',
      date: null
    }

  },
  components: {
    flatPickr,
    ModalComponent,
    AppButton
  },
  computed: {
    ...mapGetters('admin/companies', {
      loading: "getLoadingFlags",
    })
  },
  mounted() {
    this.date = this.timestampToDate(this.blockedAt, 'Y-MM-DD');
  },
  methods: {
    ...mapActions("admin/companies", ["findById", "submitChangeBlockDate", "releaseAccessToBlockedCompany", "setBlockCompaniesParams", "findCompanyHistory"]),
    submitChangeBlockDate() {
      this.setBlockCompaniesParams({
        blocked_at: Number(moment(this.date).format("X")),
        change_reason: this.changeReason
      });
      this.releaseAccessToBlockedCompany({ id: this.id })
      .then(() => {
        this.findById({
          id: this.$route.params.id
        });
        this.findCompanyHistory({
          id: this.$route.params.id
        });
      });
    },
    showModal() {
      this.show = true;
    },
    onHideModal() {
      this.show = false;
    },
    timestampToDate: Utils.timestampToDate,
  },
  props: {
    blockedAt: {
      type: Number,
      default: 0
    },
    originalBlockDate: {
      type: Number,
      default: 0
    },
    id: {
      type: Number,
      default: 0
    }
  }
}
</script>
