export default {
  startLoadingFlag: (state, loadingFlag) => state.loadingFlag[loadingFlag] = true,
  stopLoadingFlag: (state, loadingFlag) => state.loadingFlag[loadingFlag] = false,
  setCategories: (state, categories) => state.categories = categories,
  setTotalProducts: (state, totalProducts) => state.totalProducts = totalProducts,
  setShowModal: (state, showModal) => state.showModal = showModal,
  setCategoryData: (state, categoryData) => state.categoryData = categoryData,
  setCategoryModal: (state, categoryModal) => state.categoryModal = categoryModal,
  setProductData: (state, productData) => state.productData = productData,
  setEdit: (state, edit) => state.edit = edit,
  setErrors: (state, errors) => state.errors = errors,
  clearErrors: (state) => state.errors = {},
  setExclude: (state, exclude) => state.exclude = exclude,
  setActiveList: (state, activeList) => state.activeList = activeList,
  setProducts: (state, products) => state.listProducts = products,
  setSynchronizedProducts: (state, products) => state.synchronizedProducts = products,
  setOpportunityTotalAmount: (state, value) => state.opportunityTotalAmount = value
}
