export default {
  filters: {},
  include: [],
  pagination: {
    per_page: 15,
    current_page: 1
  },
  loadingFlags: {
    findSchedules: false,
    remove: false,
    update: false
  },
  errorMessage: '',
  errors: {},
  schedules: [],
  scheduleToUpdateModal: {
    data: null
  },
}
