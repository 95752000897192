<template>
  <vue-multiselect
    v-model="selecteds"
    :multiple="multiple"
    :class="{ 'form-select p-0': true, 'is-invalid': errors }"
    :options="options"
    :group-label="'groupName'"
    :group-values="'values'"
    :group-select="false"
    :label="'name'"
    :placeholder="$t('select_one_campaign')"
    :select-label="$t('to_select')"
    :selected-label="$t('selected')"
    :deselect-label="$t('remove_capitalize')"
    track-by="id"
    open-direction="bottom"
    @select="select"
    @remove="remove"
  />
  <div class="invalid-feedback">
    {{ errors && errors[0] }}
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueMultiselect from "vue-multiselect";

export default {
  data() {
    return {
      selecteds: "",
      options: []
    }
  },

  emits: ["update:modelValue"],
  components: {
    VueMultiselect
  },

  mounted() {
    this.loadDataSelect();
    if (this.modelValue) this.selecteds = this.modelValue
  },

  computed: {
    ...mapGetters("manager/campaign", {
      campaigns: "getDataSelect",
      loading: "isLoading",
    }),
  },

  props: {
    modelValue: {
      type: [Number, String, Object],
      default: '',
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    errors: {
      type: Array,
      default: null,
    },

    onlyActiveCampaigns: {
      type: Boolean,
      default: false,
    }
  },

  methods: {
    ...mapActions("manager/campaign", ["loadDataSelect"]),

    select(value) {
      if (this.multiple) {
        const selecteds = [...this.selecteds, value];
        const ids = selecteds.map((item) => item.id).join(",");
        this.$emit("update:modelValue", ids);
      } else {
        this.$emit("update:modelValue", value.id);
      }
    },

    remove(value) {
      if (this.multiple) {
        const selecteds = this.selecteds.filter((item) => item.id !== value.id);
        const ids = selecteds.map((item) => item.id).join(",");
        this.$emit("update:modelValue", ids);
      } else {
        this.$emit("update:modelValue", "");
      }
    }
  },

  watch:{
    campaigns: {
      handler(data) {
        if(Object.values(data).length) {
          for(let key in data) {
            if (this.onlyActiveCampaigns && key == "trashed") continue;
            this.options.push({
              groupName: key == 'trashed' ? this.$t('excluded_f') : this.$t('actives_f'),
              values: data[key]
            });
          }

          this.options.sort((a ,b) => {
            if(a.groupName.toUpperCase() < b.groupName.toUpperCase())
              return -1;

            if(a.groupName.toUpperCase() < b.groupName.toUpperCase())
              return 1;

            return 0;
          });

        }
      },
      deep: true
    }
  }
};
</script>
