<template>
  <div
    class="tab-pane fade show"
    id="ia"
    role="tabpanel"
    aria-labelledby="home-tab"
  >
    <div class="col-lg-12">
      <div
        class="card h-100 mb-4"
      >
        <h4 class="mb-2 card-header">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              data-test="ia-switch"
              v-model="fields.openai_status"
              type="checkbox"
              @change="disableOpenAi"
            >
            <label class="col form-label text-large mt-1 mb-0 ml-6">{{ $t('ai') }}
            </label>
          </div>
        </h4>
        <div
          class="card-body d-flex flex-column justify-content-between pt-4 pb-5"
        >
          <div v-if="fields.openai_status">
            <div>
              <dot-status
                color="#3057F2"
                size="10px"
              >
                {{ $t('activated_masc') }}
              </dot-status>
              <label
                class="form-label my-0 mx-1 fw-light h tx-blue-300"
              >{{ $t('activated_fem') }}</label>
            </div>
            <h5 class="fw-light mb-0 mt-3">
              {{ $t('agents_use_ai') }}
            </h5>
          </div>
          <div v-else>
            <dot-status
              color="#E60B42"
              size="10px"
            >
              {{ $t('deactivated_masc') }}
            </dot-status>
            <label
              class="form-label my-0 mx-1 fw-light h5 tx-red-300"
            >{{ $t('deactivated_fem') }}</label>

            <h5 class="fw-light mb-0 mt-3">
              {{ $t('agents_cannot_use_ai') }}
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-lg-12"
      :class="fields.openai_status ? 'switchOn' : 'switchOff'"
    >
      <div
        class="card h-100 mb-4"
      >
        <div
          class="card-header"
        >
          <div class="d-flex justify-content-start align-items-center">
            <h4 class="m-0 pe-3">
              {{ $t('context') }}
            </h4>
            <span
              class="fe fe-alert-circle alert-icon"
              ref="moreInfoContext"
            />
          </div>
        </div>
        <div
          class="card-body"
          style="padding: 0px 24px"
        >
          <div class="form-group">
            <div
              class="form-label fw-light m-0 h5"
              style="padding: 16px 0;"
            >
              {{ $t('describe_company_functions') }}
            </div>
            <div
              class="rounded"
              style="border: 1px solid #E1E9F4;"
            >
              <textarea
                :class="{
                  'form-control message border-0 fw-light': true,
                  'is-invalid': errors.message,
                }"
                class="h5 text-area-color"
                style="padding: 16px 16px 0;"
                rows="4"
                :placeholder="$t('type_here_placeholder')"
                :maxlength="MAXIMUM_MESSAGE_LENGTH"
                v-model="fields.openai_context"
                :disabled="!fields.openai_status"
              />
              <div
                class="me-2 text-end"
              >
                <div class="invalid-feedback">
                  {{ errors.message && errors.message[0] }}
                </div>
                <small
                  class="count-message"
                  style="font-size: 10px;"
                >
                  {{ fields.openai_context.length }}/{{ MAXIMUM_MESSAGE_LENGTH }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-lg-12"
      :class="fields.openai_status ? 'switchOn' : 'switchOff'"
    >
      <div
        class="card h-100 m-0"
      >
        <div class="card-header">
          <h4 class="m-0">
            {{ $t('parameters') }}
          </h4>
          <small
            v-if=" parseInt(fields.max_tokens) !== 200 ||
              parseFloat(fields.presence_penalty) !== 1 ||
              parseFloat(fields.temperature) !== 0.8 ||
              parseFloat(fields.top_p) !== 0.8 "
            class="config"
            @click="defaultConfig()"
          >{{ $t('reset_default_config') }}</small>
        </div>

        <div
          class="card-body"
          style="padding: 16px 24px;"
        >
          <div
            class="row"
            style="padding: 24px 0;"
          >
            <div class="col-6">
              <div class="mb-5">
                <div class="d-flex justify-content-start align-items-center mb-4">
                  <i
                    class="fa-light fa-text rounded-circle fw-normal h5 me-2 mb-0"
                    style="padding: 8px 10px;"
                  />
                  <h5 class="fw-light mb-0 fw-normal h5 me-3">
                    {{ $t('response_size') }}
                  </h5>
                  <span
                    class="fe fe-alert-circle alert-icon w-60"
                    ref="moreInfoMaxTokens"
                  />
                </div>
                <div class="d-flex align-items-center w-100">
                  <input
                    type="range"
                    class="me-3"
                    min="100"
                    max="500"
                    step="50"
                    :disabled="!fields.openai_status"
                    v-model="fields.max_tokens"
                  >
                  <h6
                    class="fw-light m-0 count-message"
                  >
                    {{ fields.max_tokens }}
                  </h6>
                </div>
              </div>
              <div class="mb-3">
                <div class="d-flex justify-content-start align-items-center mb-4">
                  <i
                    class="fa-light fa-lightbulb-on rounded-circle fw-normal h5 me-2 mb-0"
                    style="padding: 10px;"
                  />
                  <h5 class="fw-light m-0 fw-normal h5 me-3">
                    {{ $t('creativity') }}
                  </h5>
                  <span
                    class="fe fe-alert-circle alert-icon w-60"
                    ref="moreInfoPresencePenalty"
                  />
                </div>
                <div class="d-flex align-items-center w-100">
                  <input
                    type="range"
                    class="me-3"
                    min="0"
                    max="1.0"
                    step="0.1"
                    :disabled="!fields.openai_status"
                    v-model="fields.presence_penalty"
                  >
                  <h6
                    class="fw-light m-0 count-message"
                  >
                    {{ fields.presence_penalty }}
                  </h6>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-5">
                <div class="d-flex justify-content-start align-items-center mb-4">
                  <i
                    class="fa-light fa-arrows-cross rounded-circle fw-normal h5 me-2 mb-0"
                    style="padding: 9px 11px;"
                  />
                  <h5 class="fw-light m-0 fw-normal h5 me-3">
                    {{ $t('complexity') }}
                  </h5>
                  <span
                    class="fe fe-alert-circle alert-icon w-60"
                    ref="moreInfoTemperature"
                  />
                </div>
                <div class="d-flex align-items-center">
                  <input
                    type="range"
                    class="me-3"
                    id="customRange1"
                    min="0"
                    max="1.0"
                    step="0.1"
                    :disabled="!fields.openai_status"
                    v-model="fields.temperature"
                  >
                  <h6
                    class="fw-light m-0 count-message"
                  >
                    {{ fields.temperature }}
                  </h6>
                </div>
              </div>
              <div class="mb-3">
                <div class="d-flex justify-content-start align-items-center mb-4">
                  <i
                    class="fa-light fa-objects-column rounded-circle fw-normal h5 me-2 mb-0"
                    style="padding: 9px 11px;"
                  />
                  <h5 class="fw-light m-0 fw-normal h5 me-3">
                    {{ $t('variety') }}
                  </h5>
                  <span
                    class="fe fe-alert-circle alert-icon w-60"
                    ref="moreInfoTopP"
                  />
                </div>
                <div class="d-flex align-items-center">
                  <input
                    type="range"
                    class="me-3"
                    id="customRange1"
                    min="0"
                    max="1.0"
                    step="0.1"
                    :disabled="!fields.openai_status"
                    v-model="fields.top_p"
                  >
                  <h6
                    class="fw-light m-0 count-message"
                  >
                    {{ fields.top_p }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      v-if="fields.openai_status"
      class="col-lg-12 btn btn-primary mt-4 mb-5"
      @click="submit()"
    >
      {{ $t('save_general') }}
    </button>
    <button
      v-else
      class="col-lg-12 btn mt-4 mb-5"
      disabled
    >
      {{ $t('save_general') }}
    </button>
  </div>
</template>

<script>
import DotStatus from "@/components/dot-status.vue";
import {mapGetters, mapMutations, mapActions} from 'vuex';
import {Tooltip} from "bootstrap";

export default {
  components: {
    DotStatus
  },

  data() {
    return {
      MAXIMUM_MESSAGE_LENGTH: 5000,
      fields: {
        group_channel_id: this.$route.params.id,
        openai_status: false,
        openai_context: "",
        max_tokens: 200,
        presence_penalty: 1,
        temperature: 0.8,
        top_p: 0.8,
      }
    }
  },

  mounted() {
    this.fetchOpenAiSettings(this.$route.params.id)

    this.generateTooltips();
  },

  watch: {
    openAiSettings(value) {
      this.fields.instance_id = this.$route.params.id,
        this.fields.openai_status = value.status,
        this.fields.openai_context = value.context || "",
        this.fields.max_tokens = value.max_tokens,
        this.fields.presence_penalty = value.presence_penalty,
        this.fields.temperature = value.temperature,
        this.fields.top_p = value.top_p
    }
  },

  computed: {
    ...mapGetters("manager/quick_messages", {
      errors: "getErrors"
    }),

    ...mapGetters("manager/whatsapp", {
      openAiSettings: "getOpenAiSettings"
    })
  },

  methods: {
    ...mapMutations("system", ["setSuccessMessage", "setInfoMessage", "setCustomizedTitleMessage"]),
    ...mapMutations("auth", ["trackEvents"]),
    ...mapActions("manager/whatsapp", [
      "fetchOpenAiSettings",
      "createOpenAiSettings"
    ]),

    disableOpenAi() {
      if (!this.fields.openai_status) {
        this.includeOpenAiSettings();
        this.trackEvents("[Manager] Disable IA")
      }
    },

    defaultConfig() {
      this.fields.max_tokens = 200;
      this.fields.presence_penalty = 1;
      this.fields.temperature = 0.8;
      this.fields.top_p = 0.8;
    },

    submit() {
      this.includeOpenAiSettings();
      this.trackEvents("[Manager] Change IA Settings");
    },

    includeOpenAiSettings() {
      this.createOpenAiSettings({
        id: this.$route.params.id,
        fields: this.fields
      })
    },

    generateTooltips() {
      this.generateContextTooltip();
      this.generateMaxTokensTooltip();
      this.generatePresencePenaltyTooltip();
      this.generateTemperatureTooltip();
      this.generateTopPTooltip();
    },

    generateContextTooltip() {
      let tooltip = document.createElement("div");
      tooltip.innerHTML = `
        <div style="width: 300px;">
            ${this.$t('context_description')}
        </div>`;
      this.more_info_tooltip = new Tooltip(this.$refs.moreInfoContext, {
        html: true,
        placement: "top",
        title: tooltip,
      });
    },

    generateMaxTokensTooltip() {
      let tooltip = document.createElement("div");
      tooltip.innerHTML = `
        <div style="width: 300px;">
          ${this.$t('response_size_description')}
        </div>`;

      this.more_info_tooltip = new Tooltip(this.$refs.moreInfoMaxTokens, {
        html: true,
        placement: "top",
        title: tooltip,
      });
    },

    generatePresencePenaltyTooltip() {
      let tooltip = document.createElement("div");
      tooltip.innerHTML = `
        <div style="width: 300px;">
            ${this.$t('creativity_description')}
        </div>`;

      this.more_info_tooltip = new Tooltip(this.$refs.moreInfoPresencePenalty, {
        html: true,
        placement: "top",
        title: tooltip,
      });
    },

    generateTemperatureTooltip() {
      let tooltip = document.createElement("div");
      tooltip.innerHTML = `
        <div style="width: 300px;">
           ${this.$t('complexity_description')}
        </div>`;

      this.more_info_tooltip = new Tooltip(this.$refs.moreInfoTemperature, {
        html: true,
        placement: "top",
        title: tooltip,
      });
    },

    generateTopPTooltip() {
      let tooltip = document.createElement("div");
      tooltip.innerHTML = `
        <div style="width: 300px;">
           ${this.$t('variety_description')}
        </div>`;

      this.more_info_tooltip = new Tooltip(this.$refs.moreInfoTopP, {
        html: true,
        placement: "top",
        title: tooltip,
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.message {
  resize: none;
}

.tooltip-inner {
  max-width: 1000px !important;
}

.switchOff {
  color: #C5C5C5;

  .text-area-color, .text-area-color::placeholder, .count-message, .config {
    color: #C5C5C5;
  }

  .config {
    cursor: not-allowed;
  }

  i {
    color: #C5C5C5;
    background-color: #F4F4F4;
  }

  input[type="range"] {
    width: 90%;
    border-radius: 11px !important;
    color: #C5C5C5;
    position: relative;
    overflow: hidden;
  }

  input[type="range"]:active {
    cursor: grabbing;
  }

  input[type="range"],
  input[type="range"]::-webkit-slider-runnable-track,
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 16px;
  }

  input[type="range"]::-webkit-slider-thumb {
    width: 16px;
    height: 16px;
    background: linear-gradient(#C5C5C5 0 0) scroll no-repeat left center / 50% 5px;
    background-color: currentColor;
    box-shadow: calc(-100vmax - 16px) 0 0 100vmax #C5C5C5;
    border-radius: 100%;

    clip-path: polygon(
        100% 0,
        4px 0,
        0 5px,
        -100vmax 5px,
        -100vmax 11px,
        0 11px,
        4px 100%,
        100% 100%
    );
  }

  input[type="range"]:active::-webkit-slider-thumb {
    cursor: grabbing;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    background: linear-gradient(#F4F4F4 0 0) scroll no-repeat center / 100% 5px;
  }

  .submit-button {
    border-color: #C5C5C5;
    background-color: #F4F4F4;
    color: #FFF;
  }
}

.switchOn {
  .text-area-color {
    color: $color-text-200;
  }

  .count-message, .config, .alert-icon {
    color: $color-text-gray;
  }

  .config {
    cursor: pointer;
  }

  i {
    color: $color-text-gray;
    background-color: #E1E9F4;
  }

  input[type="range"] {
    width: 90%;
    border-radius: 11px !important;
    color: $color-blue-300;
    position: relative;
    overflow: hidden;
  }

  input[type="range"]:active {
    cursor: grabbing;
  }

  input[type="range"],
  input[type="range"]::-webkit-slider-runnable-track,
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 16px;
  }

  input[type="range"]::-webkit-slider-thumb {
    width: 16px;
    height: 16px;
    background: linear-gradient($color-blue-300 0 0) scroll no-repeat left center / 50% 5px;
    background-color: currentColor;
    box-shadow: calc(-100vmax - 16px) 0 0 100vmax $color-blue-300;
    border-radius: 100%;

    clip-path: polygon(
        100% 0,
        4px 0,
        0 5px,
        -100vmax 5px,
        -100vmax 11px,
        0 11px,
        4px 100%,
        100% 100%
    );
  }

  input[type="range"]:active::-webkit-slider-thumb {
    cursor: grabbing;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    background: linear-gradient(#E1E9F4 0 0) scroll no-repeat center / 100% 5px;
  }
}

.submit-button {
  border-color: $color-blue-300;
  background-color: $color-blue-300;
  color: #fff;
}

.btn {
  &:disabled {
    background-color: #C5C5C5 !important;
    color: #FFF !important;
  }
}

.form-check {
  &.form-switch {
    padding-left: 2.5rem;
    position: relative;

    .form-check-input {
      float: none;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 2rem;
      height: 1.125rem;
      min-width: 2rem;
      min-height: 1.125rem;
      margin-left: 0;
    }
  }
}

</style>
