<template>
  <div
    ref="canvas"
    class="offcanvas offcanvas-end criteria-canvas"
  >
    <div class="offcanvas-header border-0 pb-0">
      <div class="col d-flex align-items-center gap-3">
        <button
          class="btn btn-rounded-circle btn-white float-start ms-3"
          aria-label="Close"
          data-bs-dismiss="offcanvas"
          type="button"
        >
          <i class="fa-regular fa-arrow-left" />
        </button>
        <h1 class="text-truncate mb-0">
          {{ criteria?.name || $t("add_feedback") }}
        </h1>
      </div>
    </div>

    <div
      class="offcanvas-body "
    >
      <div class="card">
        <div class="card-header">
          <h4 class="mb-0">
            {{ $t("feedback_data") }}
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="form-group col-6">
              <app-input
                v-model="fields.name"
                :label="$t('name_data')"
                :required="true"
                :errors="mode == 'new' ? errors.addCriteria.name : errors.updateCriteria.name"
              />
            </div>

            <div class="form-group col-6">
              <label class="form-label required">{{ $t("color_general") }} </label>
              <color-picker-dropdown
                v-model="fields.color"
                :errors="
                  mode == 'new'
                    ? errors.addCriteria.color && errors.addCriteria.color[0]
                    : errors.updateCriteria.color && errors.updateCriteria.color[0]
                "
              />
            </div>
          </div>
          <div class="col-lg-12">
            <div class="form-group">
              <div class="row">
                <div class="col">
                  <label class="form-label required">{{ $t("description") }}</label>
                </div>
                <div class="col-auto">
                  <small
                    :class="fields.description.length > maxDescription ? 'text-danger' : 'text-muted'"
                    class="form-text mb-0"
                  > {{ fields.description.length }} / {{ maxDescription }} </small>
                </div>
              </div>
              <textarea
                v-model="fields.description"
                :class="{
                  'is-invalid': mode == 'new' ? errors.addCriteria.description : errors.updateCriteria.description
                }"
                class="form-control pe-3"
                :placeholder="$t('description')"
              />
              <div class="invalid-feedback">
                {{
                  mode == "new"
                    ? errors.addCriteria.description && errors.addCriteria.description[0]
                    : errors.updateCriteria.description && errors.updateCriteria.description[0]
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header justify-content-start">
          <div class="d-flex align-items-center">
            <h4 class="mb-0">
              {{ $t("ia_complement") }}
            </h4>
            <tooltip-component
              color="tx-blue-300"
              icon="fa-regular fa-circle-info ps-3"
              :title="$t('ia_options')"
            />
          </div>
        </div>
        <div class="card-body">
          <label class="form-label">{{ $t("keywords") }}</label>
          <div
            class="card mb-0"
            :class="errors.addCriteria.keywords || errors.updateCriteria.keywords ? 'border-danger' : 'border'"
            style="height:185px;"
          >
            <div class="card-body p-0 pb-3 ">
              <div
                class="input-group border-bottom rounded-top"
                :class="errors.addCriteria.keywords || errors.updateCriteria.keywords ? 'border-danger' : 'border'"
                style="margin-bottom: 8px"
              >
                <span class="input-group-text border-0">
                  <i class="fa-regular fa-tag" />
                </span>
                <input
                  v-model="tags"
                  class="form-control rounded-top border-0 pe-3"
                  :placeholder="$t('add_feedback_tag')"
                  type="text"
                  @keyup.enter="addTag"
                >
              </div>
              <div
                class="d-flex gap-2 flex-wrap px-3 overflow-auto"
                style="max-height: 130px;"
              >
                <div
                  v-for="(keyword, index) in fields.keywords"
                  :key="index"
                  class="d-flex justify-content-between align-items-center badge-status gap-2 col-auto text-truncate"
                  style="max-width: 490px;"
                >
                  <p class=" fs-6 mb-0  text-truncate">
                    {{ keyword }}
                  </p>
                  <i
                    class="fal fa-times tx-blue-300"
                    data-bs-toggle="collapse"
                    @click.stop="removeObjectFilter(index)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="text-danger text-small mb-4">
            {{
              mode == "new"
                ? errors.addCriteria.keywords && errors.addCriteria.keywords[0]
                : errors.updateCriteria.keywords && errors.updateCriteria.keywords[0]
            }}
          </div>

          <div class="col-lg-12">
            <div class="form-group">
              <div class="row">
                <div class="col">
                  <label class="form-label">{{ $t("ideal_phrase") }}</label>
                </div>
                <div class="col-auto">
                  <small
                    :class="fields.ideal_phrase.length > maxPhrase ? 'text-danger' : 'text-muted'"
                    class="form-text mb-0"
                  > {{ fields.ideal_phrase.length }} / {{ maxPhrase }} </small>
                </div>
              </div>
              <textarea
                v-model="fields.ideal_phrase"
                :class="{
                  'is-invalid': mode == 'new' ? errors.addCriteria.ideal_phrase : errors.updateCriteria.ideal_phrase
                }"
                class="form-control pe-3"
                :placeholder="$t('description')"
              />
              <div class="invalid-feedback">
                {{
                  mode == "new"
                    ? errors.addCriteria.ideal_phrase && errors.addCriteria.ideal_phrase[0]
                    : errors.updateCriteria.ideal_phrase && errors.updateCriteria.ideal_phrase[0]
                }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <app-button
          v-if="mode == 'new'"
          :loading="loading.addCriteria"
          class="w-50"
          @click="create()"
        >
          {{ $t("save_general") }}
        </app-button>
        <app-button
          v-else-if="mode == 'edit'"
          :loading="loading.updateCriteria"
          class="w-50"
          @click="update()"
        >
          {{ $t("update_omni") }}
        </app-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Offcanvas } from "bootstrap";
import { colors } from "@/utils";
import appButton from "@/components/app-button.vue";
import { mapActions, mapGetters } from "vuex";
import ColorPickerDropdown from "@/components/color-picker-dropdown.vue";
import TooltipComponent from "@components/tooltip-component.vue";
import AppInput from "@components/app-input.vue";

export default {
  data() {
    return {
      offcanvas: null,
      criteria: null,
      colors: [],
      fields: {
        name: "",
        color: "",
        description: "",
        keywords: [],
        ideal_phrase: "",
      },
      tags: "",
      mode: "new",
      maxDescription: 150,
      maxPhrase: 100,
    };
  },

  mounted() {
    this.colors = colors;
    this.offcanvas = new Offcanvas(this.$refs.canvas);
  },

  components: {
    AppInput,
    TooltipComponent,
    appButton,
    ColorPickerDropdown,
  },

  computed: {
    ...mapGetters("manager/criterion", {
      list: "getRegister",
      loading: "getLoadingFlags",
      errors: "getErrorFlags",
    }),
  },

  methods: {
    ...mapActions("manager/criterion", ["updateCriteria", "addCriteria", "findById", "clearErrors"]),

    edit(item) {
      this.clearErrors("addCriteria");
      this.clearErrors("updateCriteria");

      this.criteria = item;
      this.fields = {
        name: item.name,
        color: item.color,
        description: item.description,
        keywords: item.keywords || [],
        ideal_phrase: item.ideal_phrase || "",
      };
      this.mode = "edit";
      this.offcanvas.show();
    },

    new() {
      this.clearErrors("addCriteria");
      this.clearErrors("updateCriteria");

      this.mode = "new";
      this.fields = {
        name: "",
        color: "",
        description: "",
        keywords: [],
        ideal_phrase: "",
      };

      this.offcanvas.show();
    },

    create() {
      this.addCriteria({
        list_id: this.list.id,
        fields: this.fields,
      })
          .then(() => {
            this.offcanvas.hide();
          });
    },

    update() {
      this.updateCriteria({
        list_id: this.criteria.list_id,
        id: this.criteria.id,
        fields: this.fields,
      })
          .then(() => {
            this.offcanvas.hide();
          });
    },
    addTag() {
      if (this.tags.trim() !== "") {
        this.fields.keywords.push(this.tags.trim());
        this.tags = "";
      }
    },
    removeObjectFilter(index) {
      this.fields.keywords.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-wrapper {
  position: relative;
}

.tags {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.tag {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
}

.form-control {
  padding-right: 100px; /* Espaço para acomodar as tags dentro do campo de entrada */
}

.badge-status {
  border-radius: 6px;
  font-size: 12px;
  padding: 3px 8px;
  background-color: #e1e9f4;
  color: $color-blue-500;
}

.border {
  border-color: #edf2f9ff !important;
}

.criteria-canvas {
  background-color: #F9FAFC;
  width: 624px !important
}
</style>
