<template>
  <modal-component
    :show="showModal"
    @close="showModal = false"
    :center="true"
    size="modal-xl"
  >
    <template #content>
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title text-large">
            {{ $t('area_code_rules') }}
          </h3>

          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>

        <div class="table-responsive">
          <table class="table table-nowrap card-table">
            <thead>
              <tr>
                <th
                  v-for="(header, index) in headers"
                  :key="index"
                  scope="col"
                >
                  {{ header }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(areaCodeRule, i) in areaCodeRules"
                :key="i"
              >
                <td
                  v-for="(rule, j) in areaCodeRule"
                  :key="j"
                >
                  {{ rule }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="modal-footer justify-content-center text-medium">
          <i class="fe fe-alert-triangle tx-interval me-1" />
          <strong class="text-medium">OBS: </strong>
          {{ $t('include_national_holidays') }}
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from "@/components/modal-component.vue";

export default {
  data() {
    return {
      showModal: false,

      headers: [
        this.$t('states'),
        this.$t('utils_days'),
        this.$t('saturdays'),
        this.$t('sundays_and_holidays'),
        this.$t('area_code')
      ],

      areaCodeRules: [
        [
          "São Paulo",
          "08:00 às 20:00",
          "08:00 às 14:00",
          this.$t('forbidden'),
          "11, 12, 13, 14, 15, 16, 17, 18 e 19",
        ],
        [
          "Minas Gerais",
          "09:00 às 20:00",
          "10:00 às 13:00",
          this.$t('forbidden'),
          "31, 32, 33, 34, 35, 37 e 38",
        ],
        [
          "Paraná",
          "08:00 às 18:00",
          "08:00 às 13:00",
          this.$t('forbidden'),
          "41, 42, 43, 44, 45 e 46",
        ],
        [
          "Rio de Janeiro",
          "09:00 às 19:00",
          this.$t('forbidden'),
          this.$t('forbidden'),
          "21, 22 e 24",
        ],
        [
          "Santa Catarina",
          "08:00 às 20:00	",
          "08:00 às 14:00	",
          this.$t('forbidden'),
          "47, 48 e 49",
        ],
        [
          "Espírito Santo",
          "08:00 às 19:00",
          this.$t('forbidden'),
          this.$t('forbidden'),
          "27 e 28",
        ],
        [
          "Amazonas",
          "Até às 19:00",
          this.$t('forbidden'),
          this.$t('forbidden'),
          "92 e 97",
        ],
        [
          "Alagoas",
          "08:00 às 20:00",
          "08:00 às 14:00",
          this.$t('forbidden'),
          "82",
        ]
      ]
    }
  },

  components: {
    ModalComponent,
  },

  methods: {
    openModal () {
      this.showModal = true;
    }
  }
}

</script>

<style lang="scss" scoped>

tr:hover {
  background-color : unset;
}

</style>
