<template>
  <div
    ref="canvas"
    class="offcanvas offcanvas-end"
  >
    <div
      class="offcanvas-header"
      style="border: none"
    >
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      />
    </div>
    <div class="offcanvas-body">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h2 style="font-weight: 100">
            Editar cupom
          </h2>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="form-label required">Nome</label>
            <input
              v-model="fields.name"
              class="form-control"
              inputmode="numeric"
              type="text"
              placeholder=""
            >
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-label">Valor do cupom</label>
            <div
              class="input-group input-group-merge input-group-reverse"
            >
              <input
                v-model="fields.credit"
                type="number"
                class="form-control"
                placeholder="0,00"
                aria-label="0,00"
                aria-describedby="inputGroupReverse"
              >
              <div
                class="input-group-text"
                id="inputGroupReverse"
              >
                <span class="text-dark">R$</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label class="form-label">Licenças discador</label>
            <input
              v-model="fields.dialer_licenses"
              type="text"
              inputmode="numeric"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label class="form-label">Licenças Webphone</label>
            <input
              v-model="fields.webphone_licenses"
              type="text"
              inputmode="numeric"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label for="form-label required">Parceiro</label>
            <select
              v-model="fields.partner_id"
              class="form-select"
            >
              <option value="" />
              <option
                v-for="(item, i) in listPartners"
                :key="i"
                :value="item.id"
              >
                {{ item.trading_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="d-flex justify-content-between align-items-center">
            <label
              class="form-label"
            >Rotas</label>
            <button
              @click="registerNewRoute()"
              class="btn btn-outline-primary border-0"
            >
              + Cadastrar rota
            </button>
          </div>
          <div
            class="col-lg-12 rounded ps-3 mb-3"
            style="background: #f9fbfd"
          >
            <div
              class="py-1"
              v-for="(item, i) in listRoutesOfCoupons"
              :key="i"
            >
              <div
                @click="fillRouteFields(item)"
                class="d-flex justify-content-between cursor-pointer"
              >
                <span>
                  {{ item.name }}
                </span>
                <span
                  class="fe fe-chevron-down"
                  style="color: #2C7BE5"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="this.newRoute || this.editRoute"
          class="col-lg-12 px-4 mx-0 mb-3"
        >
          <div
            class="row  rounded"
            style="background: #f9fbfd"
          >
            <div
              class="col-lg-12"
            >
              <div class="form-group">
                <label for="form-label required">Nome</label>
                <input
                  v-model="routeFields.name"
                  class="form-control"
                  inputmode="numeric"
                  type="text"
                  placeholder="Nome"
                >
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="form-label">Host</label>
                <input
                  v-model="routeFields.host"
                  type="text"
                  inputmode="numeric"
                  class="form-control"
                  placeholder="Host"
                >
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="form-label">Route</label>
                <input
                  v-model="routeFields.route"
                  type="number"
                  inputmode="numeric"
                  class="form-control"
                  placeholder="Route"
                >
              </div>
            </div>
            <div
              class="col-lg-12"
            >
              <div class="form-group">
                <label for="form-label required">Endpoint</label>
                <input
                  v-model="routeFields.endpoint"
                  class="form-control"
                  inputmode="numeric"
                  type="text"
                  placeholder="Ex: PJSIP/fluxoti_endpoint/sip:"
                >
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <div class="form-check form-switch form-control d-flex justify-content-start align-items-center ps-2 py-2">
                    <input
                      v-model="routeFields.uses_country_code"
                      class="form-check-input ms-1"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                    >
                    <div class="d-flex flex-column">
                      <label
                        class="form-check-label px-3"
                        for="flexSwitchCheckDefault"
                      >Usa código do País</label>
                      <small class="px-3 fw-light text-muted">Inclui o +55</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <div class="form-check form-switch form-control d-flex justify-content-start ps-2 py-3">
                    <input
                      v-model="routeFields.allow_landline"
                      class="form-check-input ms-1"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                    >
                    <label
                      class="form-check-label px-3"
                      for="flexSwitchCheckDefault"
                    >Fixo</label>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <div class="form-check form-switch form-control d-flex justify-content-start ps-2 py-3">
                    <input
                      v-model="routeFields.allow_mobile"
                      class="form-check-input ms-1"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                    >
                    <label
                      class="form-check-label px-3"
                      for="flexSwitchCheckDefault"
                    >Móvel</label>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center my-0">
                <button
                  @click="deleteRoute()"
                  class="btn btn-outline-danger border-0 text-center py-2"
                >
                  <span
                    class="fe fe-trash"
                  />
                  <label class="form-label my-0 cursor-pointer">Excluir Rota</label>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- {{ this.listRoutesOfCoupons }} -->
      <button
        class="col-lg-12 btn btn-primary"
        @click="submit()"
      >
        {{ $t('capitalizes.save') }}
      </button>
      <div class="d-flex justify-content-center my-3">
        <button
          @click="deleteCoupon()"
          class="btn btn-outline-danger border-0 text-center py-2"
        >
          <span
            class="fe fe-trash"
          />
          <label class="form-label my-0 cursor-pointer">Excluir Cupom</label>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { Offcanvas } from "bootstrap";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
    data() {
        return {
        colors: [],
            fields: {
                coupon_id: "",
                credit: "",
                dialer_licenses: "",
                name: "",
                partner_id: "",
                webphone_licenses: "",
            },
            routeFields: {
              id: "",
              allow_landline: false,
              allow_mobile: false,
              endpoint: "",
              host: "",
              name: "",
              route: "",
              uses_country_code: false,
            },
        instanceCreated: false,
        offcanvas: null,
        mode: '',
        newRoute: false,
        editRoute: false,
        }
    },

    /**
     *
     */
    mounted() {
      this.$store.dispatch("admin/partners/findPartners")
      this.offcanvas = new Offcanvas(this.$refs.canvas)
    },

    components: {

    },
    /**
     *
     */
    methods: {
        ...mapActions("admin/coupons", [
          "createCoupons",
          "editCoupons",
          "deleteCoupons",
          "findRoutesOfCoupon",
          "createRoutes",
          "deleteRoutes",
          ]),
        ...mapMutations("admin/coupons", [
          "setListRoutesOfCoupon"
        ]),
        /**
         *
         */
        submit() {
            if(this.mode == 'edit'){
                this.editCoupons({
                  id: this.fields.coupon_id,
                  credit: this.fields.credit,
                  dialer_licenses: this.fields.dialer_licenses,
                  name: this.fields.name,
                  partner_id: this.fields.partner_id,
                  webphone_licenses: this.fields.webphone_licenses,
                })
                .then(() => {
                  if(this.newRoute){
                    this.createRoutes({
                      id: this.createdCouponData.id,
                      allow_landline: this.routeFields.allow_landline,
                      allow_mobile: this.routeFields.allow_mobile,
                      endpoint: this.routeFields.endpoint,
                      host: this.routeFields.host,
                      name: this.routeFields.name,
                      route: this.routeFields.route,
                      uses_country_code: this.routeFields.uses_country_code,
                    }).then(() => {
                        window.location.reload()
                      })
                      .catch((data) => {
                        this.errorMessage = data.message;
                      });
                  }
                  else window.location.reload()
                })
            }
            if (this.mode == 'new') {
                this.createCoupons({
                  credit: this.fields.credit,
                  dialer_licenses: this.fields.dialer_licenses,
                  name: this.fields.name,
                  partner_id: this.fields.partner_id,
                  webphone_licenses: this.fields.webphone_licenses,
                })
                .then(() => {
                  if(this.newRoute){
                    this.createRoutes({
                      id: this.createdCouponData.id,
                      allow_landline: this.routeFields.allow_landline,
                      allow_mobile: this.routeFields.allow_mobile,
                      endpoint: this.routeFields.endpoint,
                      host: this.routeFields.host,
                      name: this.routeFields.name,
                      route: this.routeFields.route,
                      uses_country_code: this.routeFields.uses_country_code,
                    }).then(() => {
                        window.location.reload()
                      })
                  }
                  else window.location.reload()
                })
            }
        },
        edit(item) {
          this.mode = "edit";
          this.fields = {
            coupon_id: item.id,
            credit: item.credit,
            dialer_licenses: item.dialer_licenses,
            name: item.name,
            partner_id: item.partner?.id,
            webphone_licenses: item.webphone_licenses,
          };
          this.findRoutesOfCoupon({id: item.id})
          this.offcanvas.show();
    },
        new() {
        this.mode = "new";
        this.setListRoutesOfCoupon("");
        this.fields = {
            id: "",
            credit: "",
            dialer_licenses: "",
            name: "",
            partner_id: "",
            webphone_licenses: "",
        };
        this.offcanvas.show();
        },
        fillRouteFields(item) {
          this.editRoute = !this.editRoute;

          this.routeFields.id = item.id
          this.routeFields.allow_landline = item.allow_landline
          this.routeFields.allow_mobile = item.allow_mobile
          this.routeFields.endpoint = item.endpoint
          this.routeFields.host = item.host
          this.routeFields.name = item.name
          this.routeFields.route = item.route
          this.routeFields.uses_country_code = item.uses_country_code
        },
        registerNewRoute() {
          this.newRoute = !this.newRoute;

          this.routeFields.id = "";
          this.routeFields.allow_landline = false;
          this.routeFields.allow_mobile = false;
          this.routeFields.endpoint = "";
          this.routeFields.host = "";
          this.routeFields.name = "";
          this.routeFields.route = "";
          this.routeFields.uses_country_code = false;
        },
        deleteRoute() {
          this.deleteRoutes({id:this.fields.coupon_id , route_id: this.routeFields.id})
          .then(() => {
            window.location.reload()
          })
        },
        deleteCoupon() {
          this.deleteCoupons({id: this.fields.coupon_id})
          .then(() => {
            window.location.reload()
          });
        },
    },

    /**
     *
     */
    computed: {
        ...mapGetters("admin/coupons", {
            listRoutesOfCoupons: "getListRoutesOfCoupon",
            createdCouponData: "getCreatedCouponData",
            routeData: "getRouteData"
        }),
        ...mapGetters("admin/partners", {
          listPartners: "getList"
        })
    }
}
</script>
