export default {
  loadingFlags: {
    fetchAgentsReportList: false,
    fetchSendChatCsvEmail: false,
    fetchSendTimeCsvEmail: false,
  },
  errors: {},
  filters: {},
  include: [],
  agentsReportList: [],
  totalReportList: [],
}
