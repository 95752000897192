<template>
  <div>
    <multi-selected
      v-if="multipleSelect"
      :id="id"
      :current-selected="currentSelected"
      :field-name="searchName"
      :list-object="listArray"
      :text-select="textSelect"
      :not-found="$t('result_not_found')"
      @selected="handleSelected"
    />
    <select-group
      v-if="multiSelectCheck"
      :id="id"
      :current-selected="currentSelected"
      :label="searchName"
      :options="listArray"
      @selected="handleSelected"
    />
    <single-select
      v-if="singleSelect"
      :id="id"
      :list-array="listArray"
      :current-option="currentSelected"
      :label="searchName"
      @selected-option="handleSelected"
    />
  </div>
</template>

<script>
import MultiSelected from "@dialer/manager/pages/insights-ai/components/multi-selected.vue";
import SelectGroup from "@dialer/manager/pages/insights-ai/components/select-group.vue";
import SingleSelect from "@dialer/manager/pages/insights-ai/components/single-select.vue";

export default {
  name: "SelectsComponents",
  components: { SelectGroup, SingleSelect, MultiSelected },
  props: {
    id: {
      type: String,
      required: true
    },
    listArray: {
      type: [Array, Object],
      required: true
    },
    searchName: {
      type: String,
      required: true
    },
    textSelect: {
      type: String,
      default: "Selecione"
    },
    multipleSelect: {
      type: Boolean,
      default: false
    },
    multiSelectCheck: {
      type: Boolean,
      default: false
    },
    singleSelect: {
      type: Boolean,
      default: false
    },
    currentSelected: {
      type: [Array, Object, String],
      default: null
    }
  },
  emits: ["selected"],
  methods: {
    handleSelected(selected) {
      this.$emit("selected", selected);
    }
  }
};
</script>
