<template>
  <div
    ref="handleContainer"
    class="position-relative"
  >
    <div>
      <div v-show="!showSearch">
        <button
          class="btn border w-100 d-flex justify-content-between align-items-center text-muted"
          style="height: 40px;padding: 8px 16px !important;"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSelectGroup"
          aria-expanded="false"
          aria-controls="collapseSelectGroup"
          @click="handleOpenSearch"
        >
          {{ selectedItems }}
          <i class="fas fa-chevron-down" />
        </button>
      </div>
      <div
        v-show="showSearch"
        class="input-group border rounded-top mb-3"
      >
        <span
          class="input-group-text border-0"
          style="height: 40px;padding-left: 16px"
        >
          <i class="fas fa-search" />
        </span>
        <input
          v-model="search"
          type="text"
          ref="inputSearch"
          class="form-control border-0 ps-0"
          placeholder="Username"
          style="height: 40px;"
        >
        <span
          class="input-group-text rounded-0 border-0"
          style="height: 40px; border-top-right-radius: 8px !important;padding-right: 16px"
          @click="handleCloseSearch"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSelectGroup"
          aria-expanded="false"
          aria-controls="collapseSelectGroup"
        >
          <i class="fas fa-chevron-up" />
        </span>
      </div>
    </div>
    <div
      class="position-absolute w-100"
      style="top: 39px;"
    >
      <div
        ref="handleCloseCollapse"
        class="collapse rounded-bottom"
        id="collapseSelectGroup"
      >
        <div
          class="card card-body rounded-0 rounded-bottom shadow-sm border-top-0 mb-0 pt-0"
          style="padding: 24px 16px 14px 16px important;"
        >
          <div v-if="filterSearch.length">
            <div v-if="filterSearch.length === list.length">
              <div
                class="d-flex"
                style="padding-top: 8px;"
              >
                <input
                  id="checkedAll"
                  type="checkbox"
                  class="form-check-input me-3"
                  :class="[allChecked ? 'bg-primary' : 'input-color']"
                  :checked="allChecked"
                  @change="selectAll"
                >
                <label
                  for="checkedAll"
                  class="fw-medium"
                >{{ $t("select_all") }}</label>
              </div>
              <div
                class="border w-100"
                style="margin: 8px 0 !important;"
              />
            </div>
            <div
              class="overflow-auto"
              style="padding: 0 16px !important;max-height: 200px;"
            >
              <div
                class="d-flex align-items-center list"
                v-for="(item, index) in filterSearch"
                :key="index"
              >
                <input
                  :id="index"
                  type="checkbox"
                  class="form-check-input mt-0 me-3"
                  :checked="item.checked"
                  @change="selectItem(item)"
                  :class="[allChecked ? 'bg-secondary' : item.checked ? 'bg-primary' : 'input-color']"
                >
                <label
                  class="w-100"
                  :for="index"
                >{{ item[label] }}</label>
              </div>
            </div>
          </div>
          <div
            v-else-if="loading"
            class="card-body text-center"
          >
            <div
              class="spinner-border"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <p
            class="mb-0 text-center"
            v-else
          >
            {{ message }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Collapse } from "bootstrap";

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    list: {
      type: Array,
      required: true
    },
    message: {
      type: String,
      default: () => this.$t("search_not_found")
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ["select"],
  data() {
    return {
      collapse: null,
      showSearch: false,
      search: "",
      allChecked: false,
      options: [],
      selected: []
    };
  },
  mounted() {
    this.options = this.list.map(item => {
      return {
        id: item.id,
        name: item[this.label],
        checked: item.checked || false
      };
    });
    const initialChecked = this.options.filter(item => item.checked);

    this.selected = initialChecked;
    this.$emit("select", this.selected);
    this.$nextTick(() => {
      this.collapse = new Collapse(this.$refs.handleCloseCollapse, {
        toggle: false
      });
      this.closeCollapse();
    });
    document.addEventListener("click", this.closeCollapse);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.closeCollapse);
  },
  computed: {
    selectedItems() {
      if (this.selected.length === 1) {
        return `${this.selected.length} ${this.$t("selected")}`;
      } else if (this.selected.length > 1) {
        return `${this.selected.length} ${this.$t("selected_plural")}`;
      } else {
        return this.$t("select");
      }
    },
    filterSearch() {
      return this.options.filter(item => item[this.label].toLowerCase().includes(this.search.toLowerCase()));
    }
  },
  watch: {
    list() {
      this.options = this.list.map(item => {
        return {
          id: item.id,
          name: item[this.label],
          checked: item.checked || false
        };
      });
      const initialChecked = this.options.filter(item => item.checked);
      this.selected = initialChecked;
      this.$emit("select", this.selected);
    }
  },
  methods: {
    handleOpenSearch() {
      this.showSearch = true;
      setTimeout(() => {
        this.$nextTick(() => {
          this.onFocus();
        });
      }, 400);
    },
    handleCloseSearch() {
      setTimeout(() => {
        this.showSearch = false;
      }, 200);
    },
    selectAll() {
      this.allChecked = !this.allChecked;
      this.selected = this.allChecked ? [...this.options] : [];
      this.options.forEach(check => {
        check.checked = this.allChecked;
      });
      this.$emit("select", this.selected);
    },
    selectItem(item, checked = false) {
      const verifySelected = this.selected.some(selected => selected.name === item.name);
      if (!checked) {
        item.checked = !item.checked;
      } else {
        this.selected.push(item);
        return;
      }

      if (item.checked && !verifySelected) {
        this.selected.push(item);
      } else {
        this.selected = this.selected.filter(selected => selected.name !== item.name);
      }
      this.$emit("select", this.selected);
      this.allChecked = this.selected.length === this.options.length;
    },
    closeCollapse(event) {
      if (this.showSearch) {
        const isSelfClick = this.$refs.handleContainer;
        const box = event && event.target && isSelfClick.contains(event.target);
        if (isSelfClick && !box) {
          this.collapse.hide();
          this.handleCloseSearch();
          this.search = "";
        }
      }
    },
    onFocus() {
      if (this.showSearch) {
        this.$refs.inputSearch.focus();
      }
    }
  }
};
</script>
<style scoped lang="scss">
.list {
  height: 32px;
  line-height: 32px;
  padding: 0 16px !important;

  &:hover {
    background-color: $color-gray-blue-300;
    border-radius: 10px;
  }

  .input-color {
    background-color: $color-gray-300;
  }
}
</style>
