<template>
  <div
    :id="id"
    ref="componentRef"
    class="position-relative mb-0"
    style="height: 40px;"
    :style="{ 'z-index': zIndex }"
  >
    <div class="card position-absolute w-100 mb-0">
      <div
        :class="{ 'p-0 pe-1': showSearch }"
        class="card-header border-bottom-0"
        style="height: 40px; padding: 12px 16px !important;"
      >
        <div :disabled="animation">
          <div
            v-if="!showSearch"
            class="d-flex justify-content-between align-items-center cursor-pointer"
            data-bs-toggle="collapse"
            :href="`#toggleCollapse${id}`"
            @click="toggle"
          >
            <span>{{ optionSelected || currentOption || initialText || $t('select') }}</span>
            <i class="far fa-chevron-down m-0 fs-5 tx-text-gray" />
          </div>
          <div
            v-else
            class="input-group"
          >
            <span class="input-group-text border-0  bg-white rounded-0 rounded-top p-0">
              <i class="far fa-search m-0 fs-5" />
            </span>
            <input
              v-model="termValue"
              ref="inputSearch"
              class="form-control border-0"
              :placeholder="$t('type_here_placeholder')+'...'"
              type="text"
            >
            <span
              class="input-group-text border-0 rounded-0 rounded-top  bg-white cursor-pointer p-0"
              data-bs-toggle="collapse"
              :href="`#toggleCollapse${id}`"
              @click="toggle"
            >
              <i class="far fa-chevron-up tx-text-gray m-0 fs-5" />
            </span>
          </div>
        </div>
      </div>

      <div
        :id="`toggleCollapse${id}`"
        class="collapse"
      >
        <div
          class="card-body overflow-auto py-2"
          style="max-height: 240px;padding: 6px 16px !important;"
        >
          <ul class="p-0 m-0 transition list-unstyled">
            <template v-if="filterList.length">
              <li
                v-for="(item, index) in filterList"
                :key="index"
                :class="{ 'list-hover': activeHover }"
                class="m-0 list"
                data-bs-toggle="collapse"
                :href="`#toggleCollapse${id}`"
                @click="selected(item)"
                @mouseenter="activeHover = true"
                @mouseleave="activeHover = false"
              >
                {{ item[label] }}
              </li>
            </template>
            <li
              v-else
              class="text-center w-100 pb-2"
            >
              {{ message }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    listArray: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    message: {
      type: String,
      default: "Nenhum resultado encontrado"
    },
    currentOption: {
      type: String,
      default: ""
    },
    initialText: {
      type: String,
      default: "Selecione"
    },
    zIndex: {
      type: Number,
      default: 5
    }
  },
  emits: ["selectedOption"],
  data() {
    return {
      showSearch: false,
      animation: false,
      activeHover: false,
      termValue: "",
      optionSelected: "",
      notFound: false
    };
  },
  computed: {
    filterList() {
      return this.listArray.filter(item => item[this.label].toLowerCase().includes(this.termValue.toLowerCase()));
    }
  },
  methods: {
    toggle() {
      if (!this.animation) {
        this.animation = true;
        this.showSearch = !this.showSearch;
        setTimeout(() => {
          this.animation = false;
          this.$nextTick(() => {
            this.onFocus();
          });
        }, 400);
      }
    },
    selected(item) {
      this.$emit("selectedOption", item);
      this.optionSelected = item[this.label];
      this.showSearch = false;
    },
    handleClickOutside(event) {
      const dataCollapse = document.querySelector(`[href='#toggleCollapse${this.id}'][data-bs-toggle='collapse']`);
      const isClickInsideComponent = !this.$refs.componentRef.contains(event.target);
      const collapseElement = document.getElementById(`toggleCollapse${this.id}`);
      if (dataCollapse && this.showSearch && isClickInsideComponent  && collapseElement.classList.contains('show')) {
        dataCollapse.click();
      }
    },
    onFocus() {
      if (this.showSearch) {
        this.$refs.inputSearch.focus();
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  unmounted() {
    document.removeEventListener("click", this.handleClickOutside);
  }
};
</script>
<style lang="scss" scoped>
.list-hover:hover {
  background-color: $color-gray-blue-300;
  border-radius: 10px;
}

.card {
  border: 1px solid #E3EBF6 !important
}

.list {
  height: 32px;
  line-height: 32px;
  padding: 0 16px !important;
}
</style>
