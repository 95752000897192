<template>
  <div
    class="tab-content"
    v-if="Object.values(sorts).length"
  >
    <div
      class="tab-pane fade show active"
      id="contactsListPane"
      role="tabpanel"
      aria-labelledby="contactsListTab"
    >
      <div class="card">
        <div class="card-header">
          <div class="row align-items-center">
            <div class="col">
              <span class="card-header-title fs-4">
                {{ title }}
              </span>
            </div>

            <div
              class="col-auto"
              v-if="queryParams != undefined"
            >
              <a
                href="#"
                class="btn btn-sm btn-outline-secondary"
                @click="generateCSVModal.show()"
              >Baixar CSV</a>
            </div>
          </div>
        </div>

        <div
          class="table-responsive"
          id="table-manual-calls"
        >
          <table
            class="table table-hover card-table table-striped border"
            :class="{
              'table-nowrap': tableNoWrap
            }"
          >
            <thead>
              <tr>
                <th
                  v-for="(item, key, index) in sorts"
                  :key="index"
                  :class="{
                    'col-fixed bg-body': item.name == 'name',
                    'ps-5': item.name == 'calls',
                    'border-bottom': true
                  }"
                >
                  <sort-link
                    :title="tableHeader[index]"
                    :column="item.name"
                    :dir="item.dir"
                    @sort="sort"
                    @click.prevent
                    class="text-muted fw-normal"
                  />
                  <span
                    v-if="item.name === 'name'"
                    class="position-absolute end-0 top-0 border h-100 hr"
                  />
                </th>
              </tr>
            </thead>
            <tbody class="list fs-base">
              <tr
                v-for="(item, index) in tableContent"
                :key="index"
              >
                <td
                  v-for="(element, position) in item"
                  :key="position"
                  :class="{
                    'col-fixed pe-5': position == 'name',
                    'ps-5': position == 'calls'
                  }"
                >
                  <span v-if="position !== 'name'">
                    <span>{{ getTime(element) }}</span>
                    <span class="ms-1 text-secondary">{{ getPercentage(element) }}</span>
                  </span>
                  <span v-else> {{ element }} </span>
                  <span
                    v-if="position === 'name'"
                    class="position-absolute end-0 top-0 border h-100 hr"
                  />
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td
                  v-for="(element, position, index) in sorts"
                  :key="position"
                  :class="{
                    'col-fixed fs-3 text-center fw-medium': position == 'name',
                    'ps-5': position == 'calls',
                    'py-4 border-top': true
                  }"
                >
                  <div
                    class="avatar rounded-circle align-self-center me-2"
                    v-if="index > 1"
                    :style="{
                      backgroundColor: generateBackground(index),
                      width: '13px',
                      height: '13px'
                    }"
                  />
                  <span>{{ getTime(tableFooter[position]) }}</span>
                  <span class="ms-1 text-secondary">{{ getPercentage(tableFooter[position]) }}</span>
                  <span
                    v-if="position === 'name'"
                    class="position-absolute end-0 top-0 border h-100 hr"
                  />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="modalDownloadCSV"
      tabindex="-1"
      aria-labelledby="modalDownloadCSVLabel"
      aria-hidden="true"
      ref="generateCSVModal"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h3
              class="modal-title"
              id="modalDownloadCSVLabel"
            >
              {{ $t('generate_report') }}
            </h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div class="modal-body">
            <p>
              {{
                $t('generate_report_2')
              }}
            </p>
            <form
              @submit.prevent="generateCSV"
              id="formCSV"
            >
              <div class="mb-3">
                <label
                  for="input-email"
                  class="col-form-label"
                >{{ $t('report_email') }}</label>
                <input
                  type="email"
                  class="form-control"
                  id="input-email"
                  required
                  v-model="email"
                >
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              {{ $t('cancel') }}
            </button>
            <app-button
              form="formCSV"
              :loading="loading"
              style="min-width: 6.5rem;"
            >
              {{ $t('generate_csv') }}
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import SortLink from "@dialer/manager/components/sort-link";
import AppButton from "@/components/app-button.vue";
import { Modal } from "bootstrap";
import { nextTick } from "vue";

export default {
  data() {
    return {
      email: "",
      generateCSVModal: null,
      // TODO Sorts
      sorts: {}
    };
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    tableContent: {
      type: Array,
      default: () => []
    },
    tableHeader: {
      type: Array,
      default: () => []
    },
    tableFooter: {
      type: Object,
      default: () => {}
    },
    downloadTableFunction: {
      type: Function,
      default: val => val
    },
    queryParams: {
      type: Object,
      default: () => {}
    },
    tableNoWrap: {
      type: Boolean,
      default: true
    },
    footerColors: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SortLink,
    AppButton
  },
  computed: {
    ...mapGetters("auth", {
      user: "getUser"
    })
  },
  methods: {
    generateCSV() {
      const payload = Object.assign({}, this.queryParams, { email: this.email });
      this.downloadTableFunction(payload).then(() => this.generateCSVModal.hide());
    },
    sort(data) {
      this.initiateSorts();
      this.sorts[data.column].dir = data.dir;

      // eslint-disable-next-line vue/no-mutating-props
      this.tableContent.sort((a, b) => {
        return a[data.column] < b[data.column] ? -1 : a[data.column] > b[data.column] ? 1 : 0;
      });

      // eslint-disable-next-line vue/no-mutating-props
      if (data.dir === "desc") this.tableContent.reverse();
    },
    initiateSorts() {
      let keys = Object.keys(this.tableContent[0]);
      keys.forEach(el => {
        this.sorts[el] = {
          name: el,
          dir: ""
        };
      });
    },

    generateBackground(index) {
      let background = this.footerColors.find(
        el =>
          el.label.toUpperCase() === this.tableHeader[index].toUpperCase() ||
          el.label.toUpperCase() + "S" === this.tableHeader[index].toUpperCase()
      )?.backgroundColor;
      return background ? background : "#6E84A3";
    },
    getTime(element) {
      let isString = typeof element == "string";
      return isString ? element.split(" ")[0] : element;
    },
    getPercentage(element) {
      let isString = typeof element == "string";
      return isString ? element.split(" ")[1] : "";
    }
  },
  mounted() {
    nextTick().then(() => {
      this.generateCSVModal = new Modal(this.$refs.generateCSVModal);
    });
    this.email = this.user.email;
    this.initiateSorts();
  }
};
</script>

<style scoped lang="scss">
.table > :not(:first-child) {
  border-top: none;
}

.col-fixed {
  position: sticky;
  left: 0;
  z-index: 100;
  width: 100px;
  background-color: white;
}

.table-hover tfoot tr:hover td {
  background-color: white;
}
</style>
