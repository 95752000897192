import _ from 'lodash'

export default {
  setPagination: (state, pagination) => state.pagination = pagination,
  setFunnels: (state, payload) => state.funnels = payload,
  setErrorsMessage: (state, payload) => state.errorsMessage = payload,

  setCurrentFunnelSteps: (state, currentFunnelSteps) => {
    state.currentFunnel.steps = currentFunnelSteps
  },

  setCurrentFunnel: (state, payload) => {
    state.currentFunnel = payload
    state.currentFunnel.steps.map((step) => {
      step.contacts_funnels = _.orderBy(step.contact_funnels, 'step_order')
    })

    state.currentFunnel.steps = _.orderBy(state.currentFunnel.steps, 'order')
  },

  clearContactsSteps(state, payload) {
    let step = state.currentFunnel.steps.find((el) => el.id === payload.step_id)
    step.contacts_funnels = []
  },

  clearContactsSingleStep(state, step_id) {
    const currentStep = state.currentFunnel.steps.find((step) => step.id === step_id)
    if (currentStep) {
      state.currentFunnel.steps.forEach((step) => {
        step.contacts_funnels = []
      })
    }
  },

  preloaderContactSteps(state, contacts) {
    state.currentFunnel.steps.map((step) => {
      contacts.map((contact, index) => {
        if (contact.step_id === step.id) {
          // step.contacts_funnels = []
          step.contacts_funnels[index] = {
            agent_id: contact.agent_id,
            funnel_id: contact.funnel_id,
            step_id: contact.step_id,
            step_order: contact.step_order,
            status: contact.status,
            contacts: [{
              contact_id: contact.contact_id,
              contact_funnel_id: contact.contact_funnel_id,
              name: contact.name,
              company_id: contact.company_id,
              step_id: contact.step_id,
              agent_id: contact.agent_id,
              step_order: contact.step_order,
              status: contact.status,
              tags: [],
              phones: contact.phones,
              emails: contact.emails,
              notes: contact.notes,
              answers: contact.answers,
            }],
          }
        }
      })
    })
  },

  updateContactSteps(state, contacts) {
    state.currentFunnel.steps.forEach((step) => {
      contacts.forEach((contact) => {
        const objectModel = {
          agent_id: contact.agent_id,
          funnel_id: contact.funnel_id,
          step_id: contact.step_id,
          step_order: contact.step_order,
          status: contact.status,
          contacts: [{
            contact_id: contact.contact_id,
            contact_funnel_id: contact.contact_funnel_id,
            name: contact.name,
            company_id: contact.company_id,
            step_id: contact.step_id,
            agent_id: contact.agent_id,
            step_order: contact.step_order,
            status: contact.status,
            tags: [],
            phones: contact.phones,
            emails: contact.emails,
            notes: contact.notes,
            answers: contact.answers,
          }],
        }
        if (contact.step_id === step.id) {
          step.contacts_funnels.push(objectModel)
        }
      })
    })
  },

  moveStepFunnel(state, {removedIndex, addedIndex}) {
    const temp = state.currentFunnel.steps[removedIndex]
    state.currentFunnel.steps[removedIndex] = state.currentFunnel.steps[addedIndex]
    state.currentFunnel.steps[addedIndex] = temp
    state.currentFunnel.steps[addedIndex].order = addedIndex
    state.currentFunnel.steps[removedIndex].order = removedIndex
  },

  updateStepFunnel(state, {step}) {
    const currentStep = state.currentFunnel.steps.find((st) => st.id === step.id)
    const index = state.currentFunnel.steps.indexOf(currentStep)
    state.currentFunnel.steps[index] = step
  },

  updateDeleteStepFunnel(state, {step_id}) {
    const currentStep = state.currentFunnel.steps.find((st) => st.id === step_id)
    const index = state.currentFunnel.steps.indexOf(currentStep)
    state.currentFunnel.steps.splice(index, 1)
  },

  updateCardsColumn: (state, columns) => state.columns = columns,

  setIsDraggingColumn: (state, payload) => state.isDraggingColumn = payload,
  setLockDrag: (state, payload) => state.lockDragCard = payload,

  startLoading: (state, flag) => state.loadingFlags[flag] = true,
  stopLoading: (state, flag) => state.loadingFlags[flag] = false,

  addNewFunnel: (state, funnel) => state.funnels.push(funnel),
  addNewStep: (state, step) => state.currentFunnel.steps.push(step),
  setCurrentStepId: (state, stepId) => state.currentStepId = stepId,
  setSelectedStep: (state, step) => state.selectedStep = step,
}
