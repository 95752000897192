<template>
  <action-button
    :id="`dropdown-${id}`"
    ref="actionButton"
    :icon="icon"
    :blocked="blocked"
    data-bs-toggle="dropdown"
    data-bs-auto-close="outside"
    aria-expanded="false"
    @click="handleCLick"
    v-model="actionButtonRef"
  />
  <div
    :style="`min-width: ${width}px; max-width: ${width}px`"
    :id="`dropdown-menu-${id}`"
    :ref="`dropdown-menu-${id}`"
    class="dropdown-menu"
    :class="{
      'position-fixed': fixed
    }"
    @click="handleCLick"
  >
    <p
      v-if="title"
      style="margin-bottom: 1rem"
      class="tx-text-200 fs-6 fw-medium"
    >
      {{ title }}
    </p>
    <slot name="content" />
  </div>
</template>
<script>
import ActionButton from "@crm/components/action-button.vue";
import {Dropdown} from "bootstrap";

export default {
  name: "ActionDropdown",
  components: {
    ActionButton,
  },
  props: {
    id: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: "fa-regular fa-computer-mouse-scrollwheel"
    },
    blocked: {
      type: Boolean,
      default: false
    },

    fixed: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      default: null
    },

    width: {
      type: [Number, String],
      default: 370
    }
  },

  data() {
    return {
      dropdownInstance: null,
      actionButtonRef: null,
    }
  },

  methods: {
    handleCLick(event) {
      event.preventDefault();
      event.stopPropagation();
    },

    setEventListener(el) {
      if (
        this.$refs[`dropdown-menu-${this.id}`]?.classList?.contains('show')
        && (this.$refs[`dropdown-menu-${this.id}`].getBoundingClientRect().top < el.target.getBoundingClientRect().top)
        || (this.$refs[`dropdown-menu-${this.id}`].getBoundingClientRect().bottom > el.target.getBoundingClientRect().bottom)) {
        this.dropdownInstance.hide();
      }
    },

    toggleDropDOwns(eventListen) {
      const containers = document.querySelectorAll('.dndrop-container.vertical.kanban-category');

      containers.forEach((el) => {
        if (!el.children[0].classList.contains('opportunity-empty')) {
          el[eventListen]("scroll", this.setEventListener);
        }
      })

      return true
    }
  },

  mounted() {
    if (this.fixed) {
      const myDropdown = this.$refs.actionButton.$el;
      this.dropdownInstance = new Dropdown(myDropdown);

      const that = this;
      myDropdown.addEventListener('show.bs.dropdown', () => {
        that.toggleDropDOwns('addEventListener')
      })
      myDropdown.addEventListener('hide.bs.dropdown', () => {
        that.toggleDropDOwns('removeEventListener')
      })
    }
  }
}
</script>

<style scoped lang="scss">
.dropdown-menu {
  padding: 1rem;
  z-index: 1;
}
</style>