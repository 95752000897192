<template>
  <div class="col-12">
    <header-component
      :title="$t('capitalizes.receptive_numbers')"
      redirect-link="/manager/voice-settings"
    />

    <div
      class="card"
      id="receptive-numbers-list"
    >
      <div class="card-header">
        <div class="col">
          <div class="input-group input-group-flush input-group-merge input-group-reverse">
            <input
              class="form-control"
              v-model="term"
              :placeholder="$t('placeholders.search_receptive_number')"
              @keyup="search"
              type="text"
            >
            <div class="input-group-text">
              <span class="fe fe-search" />
            </div>
          </div>
        </div>
      </div>
      <div class="card-body px-0 py-0">
        <div class="table-responsive">
          <table class="table table-hover table-nowrap card-table">
            <thead>
              <tr>
                <th
                  scope="col"
                  class="col-4"
                >
                  <a class="list-sort text-muted">{{ $t("acronym.did") }}</a>
                </th>
                <th
                  scope="col"
                  class="col-4"
                >
                  <a class="list-sort text-muted">{{ $t("uppercases.name") }}</a>
                </th>
                <th
                  scope="col"
                  class="col-4"
                >
                  <a class="list-sort text-muted">{{ $t("uppercases.forwarding") }}</a>
                </th>
                <th />
              </tr>
            </thead>
            <tbody class="list fs-base">
              <tr v-if="list.length === 0 && !loading">
                <td
                  class="text-center text-muted"
                  colspan="8"
                >
                  <span class="fe fe-alert-octagon" /> {{ $t("errors.no_receptive_number_found") }}
                </td>
              </tr>

              <template v-if="loading">
                <loading-table-component
                  :lines="15"
                  :columns="3"
                  :list-width="[50, 100, 150]"
                />
              </template>

              <template
                v-for="item in activeNumbers"
                :key="item.id"
                v-else
              >
                <tr
                  class="cursor-pointer"
                  @click="$router.push(`/manager/receptive-numbers/${item.id}/edit`)"
                  @mouseover="selectItem(item.id)"
                  @mouseleave="unSelectItem()"
                >
                  <td>
                    <span class="item-title"> {{ item.did }} </span>
                  </td>
                  <td>
                    <span class="item-title">{{ item.name }}</span>
                  </td>
                  <td v-if="item.settings">
                    <span class="item-title">
                      {{ item.settings?.receptive_queue?.name || "" }}
                      {{ item.settings?.ring_group?.name || "" }}
                      {{ item.settings?.queue?.name || "" }}
                      {{ item.settings?.receptive_ivr?.name || "" }}
                      {{ item.settings?.callback?.campaign?.name || "" }}
                    </span>
                  </td>
                  <td v-else />
                  <td>
                    <span
                      style="font-weight: bold;"
                      class="fe fe-chevron-right text-primary"
                      :style="{
                        visibility: item.id == selectedItem ? 'visible' : 'hidden'
                      }"
                    />
                  </td>
                </tr>
              </template>

              <template
                v-for="item in inactiveNumbers"
                :key="item.id"
              >
                <tr
                  v-if="list.length"
                  class="cursor-pointer"
                  @click="$router.push(`/manager/receptive-numbers/${item.id}/edit`)"
                  @mouseover="selectItem(item.id)"
                  @mouseleave="unSelectItem()"
                >
                  <td>
                    <span class="item-title"> {{ item.did }} </span>
                  </td>

                  <td>
                    <span class="item-title">{{ item.name }}</span>
                  </td>
                  <td />
                  <td>
                    <span
                      style="font-weight: bold;"
                      class="fe fe-plus-circle text-primary"
                      :style="{
                        visibility: item.id == selectedItem ? 'visible' : 'hidden'
                      }"
                    />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="!loading && list.length">
        <div class="col-lg-12">
          <paginator
            @change-page="changePage"
            :pagination="pagination"
            :callback="changePage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import _ from "lodash";
import paginator from "@/components/app-paginator.vue";
import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";
import HeaderComponent from "@/components/header-component.vue";

export default {
  data() {
    return {
      term: "",
      activeNumbers: [],
      inactiveNumbers: [],
      selectedItem: false
    };
  },
  components: {
    paginator,
    LoadingTableComponent,
    HeaderComponent
  },
  computed: {
    ...mapGetters("manager/receptive_numbers", {
      list: "getList",
      loading: "isLoading",
      pagination: "getPagination"
    })
  },
  methods: {
    ...mapMutations("manager/receptive_numbers", ["clearList"]),

    search: _.debounce(function() {
      this.clearList();
      this.$store
        .dispatch("manager/receptive_numbers/list", {
          search: this.term
        })
        .then(() => this.filterActiveAndInactiveNumbers());
    }, 500),

    changePage(page) {
      this.$store
        .dispatch("manager/receptive_numbers/list", {
          page
        })
        .then(() => this.filterActiveAndInactiveNumbers());
    },
    filterActiveAndInactiveNumbers() {
      this.activeNumbers = this.list.filter(el => el.settings);
      this.inactiveNumbers = this.list.filter(el => !el.settings);
    },
    selectItem(item) {
      this.selectedItem = item;
    },
    unSelectItem() {
      this.selectedItem = false;
    }
  },
  mounted() {
    this.$store.dispatch("manager/receptive_numbers/list").then(() => {
      this.filterActiveAndInactiveNumbers();
    });
  }
};
</script>

<style scoped lang="scss">
.table > :not(:first-child) {
  border-top: none;
}

a:hover {
  cursor: pointer !important;
}
</style>
