<template>
  <div
    id="confirmStatusChange"
    ref="confirmStatusChange"
    aria-hidden="true"
    aria-labelledby="staticBackdropLabel"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered justify-content-center">
      <div class="modal-content">
        <div class="modal-body d-flex flex-column justify-content-center align-items-center py-5 px-0">
          <p>
            {{ $t('unmark_as') }} "<span class="fw-bold">{{ $t('gain') }}</span>"?
          </p>
          <div
            class="d-flex"
            style="gap: 1rem"
          >
            <button
              class="btn btn-outline-secondary rounded-4"
              data-bs-dismiss="modal"
              type="button"
              style="width: 118px"
            >
              {{ $t('cancel_general') }}
            </button>
            <button
              class="btn btn-primary rounded-4"
              type="button"
              style="width: 118px"
              data-bs-dismiss="modal"
              @click="accept"
            >
              {{ $t('unmark') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Modal} from "bootstrap";

export default {
  name: "ConfirmStatusChange",

  emits: ["update:modelValue", "accepted"],

  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
  },

  data () {
    return {
      modal: null
    }
  },

  mounted () {
    this.$nextTick(() => this.modal = new Modal(this.$refs.confirmStatusChange))
  },

  watch: {
    modelValue (value) {
      if (value && this.modal){
        this.show()
      }
    }
  },

  methods: {
    show () {
      this.$emit('update:modelValue', !this.modelValue)
      this.modal.show()
    },

    accept () {
      this.$emit('accepted')
      this.modal.hide()
    },

    close () {
      this.$emit('update:modelValue', this.modelValue)
      this.modal.hide()
    }
  },

  unmounted () {
    if (this.modal !== null) {
      this.modal.dispose()
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-content {
  max-width: 20rem;
}
</style>
