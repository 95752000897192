<template>
  <modal-component
    :center="true"
    :show="showModalDeleteGroup"
    @close="closeModalDeleteGroup"
  >
    <template #content>
      <div class="modal-header">
        <h5 class="modal-title">
          {{ $t('delete_group_omni') }}
        </h5>
        <button
          type="button"
          class="btn-close"
          @click="closeModalDeleteGroup"
        />
      </div>
      <div
        class="modal-body d-flex flex-column align-items-center text-center"
        style="padding: 2.5rem"
      >
        <h2 class="mb-3">
          {{ `${$t('delete_group_title_modal')} "${ groupName }"?` }}
        </h2>
        <p style="margin-bottom: 2.5rem">
          <span class="fw-bold">{{ $t('delete_group_body_p1') }}</span>
          {{ $t('delete_group_body_p2') }}
        </p>
        <div class="d-flex flex-column gap-3 w-50">
          <button
            class="btn btn-danger"
            @click="$emit('deleteGroup')"
          >
            {{ $t('yes') }}
          </button>
          <button
            class="btn tx-gray"
            @click="closeModalDeleteGroup"
          >
            {{ $t('no') }}
          </button>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from "@components/modal-component.vue";

export default {
  emits: ["close", "deleteGroup"],
  name: "ModalDeleteGroup",

  components: { ModalComponent },

  props: {
    showModalDeleteGroup: {
      type: Boolean,
      default: false
    },
    groupName: {
      type: String,
      required: true
    }
  },

  methods: {
    closeModalDeleteGroup() {
      this.$emit("close");
    }
  }
};
</script>