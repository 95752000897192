export default {
  getLoadingFlags: state => state.loading,
  getDownloadCSVParams: state => Object.assign(state.downloadCSVFilters),
  getList: state => state.list,
  getErrorMessage: state => state.errorMessage,
  getErrors: state => state.errors,
  getLoadingContext: state => state.loadingContext,
  getPagination: state => state.pagination,
  getCampaignSchedules: state => state.campaignSchedules,
  getPaginationCampaignSchedules: state => state.paginationCampaignSchedules,
  isLoadingSchedules: state => state.loadingSchedules
};
