<template>
  <div class="start-chat b9 d-flex w-100 py-4 px-3">
    <button
      class="w-100 btn-primary btn"
      data-test="startChat-button"
      @click="handleSubmit"
      :disabled="loadingFlags.acceptQueue || loadingFlags.createNewChat"
    >
      {{ $t('start_chat') }}
    </button>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  props: {
    finalizedByManager: {
      type: Boolean,
      required: false
    },
    chatData: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  computed: {
    ...mapGetters("whatsapp/chat", {
      loadingFlags: "getLoadingFlags",
      emptyChatContact: "getEmptyChatContact",
    }),
    ...mapGetters("auth", {
      user: "getUser"
    })
  },

  methods: {
    ...mapMutations("whatsapp/chat", [
      "addChatToInProgress"
    ]),
    ...mapActions("whatsapp/chat", [
      "acceptQueue",
      "createNewChat",
      "fetchVerifyNotifiedChats",
      "updateTalksTab"
    ]),

    async submit() {
      if(this.$route.path === "/whatsapp/empty-chat"){
        const number =
            this.emptyChatContact.number.length === 12
                ? this.emptyChatContact.number.slice(0, 4) + "9" + this.emptyChatContact.number.slice(4)
                : this.emptyChatContact.number;

        await this.createNewChat({
          fields: {
            number: number,
            instance_id: this.emptyChatContact.instance.id
          }
        }).then(( chatId ) => {
          this.$router.push({
            path:
              "/whatsapp/" + chatId
          });
        });
      }
      else if (this.chatData?.finished) {
        this.createNewChat({
          fields: {
            number: this.chatData.number,
            instance_id: this.chatData.instance_id
          }
        }).then((chatId) => {
          this.$router.push({
            path:
              "/whatsapp/" + chatId
          });
        });
      } else {
        await this.acceptQueue({
          id: this.chatData.id
        }).then(() => {
          this.fetchVerifyNotifiedChats("queue")
          this.addChatToInProgress({chat: this.chatData, agent_id: this.user.id})
            this.$router.push({
              path:
                "/whatsapp/" + this.chatData.id
            });
        });
      }
    },

     handleSubmit() {
      this.submit().then(() => {
          this.updateTalksTab('my');
      })
    }
  }
};
</script>

<style lang="scss">
.start-chat {
  button {
    border-radius: 10px;
    background-color: #3057f2;
    color: #ffffff;
    border: none;
  }
  :hover,
  :active {
    background-color: #274ce3;
  }
}
</style>
