<template>
  <span
    :class="[
      'tag',
      { active: active && texto.toLowerCase() === 'aprovado' },
      { refused: refused && texto.toLowerCase() === 'recusado' },
    ]"
    class="px-3 py-1 cursor-pointer"
  >
    {{ texto }}
  </span>
</template>

<script>
export default {
  props: {
    texto: {type: String, required: true},
    active: {type: Boolean, default: false},
    refused: {type: Boolean, default: false},
  }
};
</script>

<style
  lang="scss"
  scoped
>
.tag {
  border-radius: 8px;
  background-color: $color-gray-blue-300;
  color: $color-text-gray;
}

.tag.active {
  background-color: #D6E9D9;
  color: #015D0D;
}

.tag.refused {
  background-color: #F2C6D1;
  color: #920000;
}
</style>
