<template>
  <main class="w-100 vh-100 overflow-hidden">
    <div class="container-fluid">
      <header-component :title="$t('menu_config.partners.extract')" />
      <div
        style="row-gap: 1.5rem"
        class="row"
      >
        <div class="col-lg-6">
          <div
            :style="`background-image: url(${BG})`"
            class="card mb-0 h-100 rounded border-1 bg-cover"
          >
            <div class="card-body">
              <div
                style="margin-bottom: 1rem"
                class="d-block w-auto"
              >
                <img
                  width="56"
                  height="16"
                  :src="Balloons"
                  alt="Balloons"
                  loading="eager"
                >
              </div>
              <div class="redeem fw-medium">
                <span class="tx-text-200 d-inline-block mb-0">
                  {{ $t("partners_extract.you_have_already_redeemed") }}
                  <card-loading
                    class="mb-0"
                    v-if="loading.fetchRevenue"
                    :height="34"
                  />
                  <span
                    v-else
                    class="tx-blue-300"
                  >
                    {{ formatCurrency(parseFloat(revenue?.total_withdrawn)) }}
                  </span>
                </span>
              </div>
              <span class="tx-text-gray fw-medium">
                {{ $t("partners_extract.being_a_partner", {name: '3C Plus'}) }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <button
            @click="parseFloat(infoPartner.revenue) > 0 ? openWithdrawalRequestModal() : null"
            class="w-100 m-0 p-0 d-flex text-start card mb-0 h-100 rounded border-1 hover-card"
          >
            <span class="card-body w-100">
              <icon-card
                icon-size="20px"
                width="42"
                height="42"
                variant="fa-regular fa-dollar-sign hover-icon"
                style="margin-bottom: 1rem"
              />
              <span class="d-block tx-text-gray mb-0">
                {{ $t("partners_extract.available_for_withdrawal") }}
                <tooltip-component
                  :title="$t('partners_extract.tooltip')"
                  width="419"
                />
              </span>
              <card-loading
                class="mb-0"
                v-if="loading.fetchRevenue"
                :height="33"
              />
              <span
                v-else
                class="d-flex justify-content-between fs-2 tx-text-200 fw-medium mb-0"
              >
                {{ formatCurrency(parseFloat(revenue?.revenue)) }}
                <i class="tx-text-gray fs-5 fa-regular fa-chevron-right" />
              </span>
            </span>
          </button>
        </div>
        <div class="col-12">
          <div class="card mb-0">
            <div class="card-header">
              <span class="tx-text-200 fs-3 fw-medium">
                {{ $t("partners_extract.my_extract") }}
              </span>
              <div class="col-2">
                <input-date-range
                  v-model:start="date.start_date"
                  v-model:end="date.end_date"
                  :default-date-enabled="false"
                  format-date="d/m/Y"
                  data-test="pickExtractDateRange-input"
                />
              </div>
            </div>
            <extract-table v-if="!hasPermissionToImpersonate"
              :items="extract"
              :loading="loading.fetchExtract"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
  <modal-withdrawal-request ref="WithdrawalExtract" />
</template>
<script>
import HeaderComponent from "@admin/pages/3c-partners/partners/components/header-component.vue";
import BG from "@/assets/img/textures.svg"
import Balloons from "@/assets/img/3czinhos.svg"
import {Utils} from "@/utils";
import IconCard from "@components/icon-card.vue";
import TooltipComponent from "@components/tooltip-component.vue";
import ExtractTable from "@partner/pages/component/extract-table.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import CardLoading from "@components/card-loading.vue";
import ModalWithdrawalRequest from "@partner/pages/component/modal-withdrawal-request.vue";
import InputDateRange from "@components/input-date-range.vue";

export default {
  name: "ExtractView",
  components: {
    InputDateRange,
    CardLoading,
    ExtractTable,
    TooltipComponent,
    IconCard,
    HeaderComponent,
    ModalWithdrawalRequest,
  },
  data() {
    return {
      BG,
      Balloons,
      date: {
        start_date: null,
        end_date: null
      }
    }
  },

  beforeMount() {
    this.fetchRevenue();
    this.fetchExtract();
    this.fetchDetailsPartner({id: this.user.id});
  },

  computed: {
    ...mapGetters("partner", {
      loading: "getLoadingFlags",
      revenue: "getRevenue",
      extract: "getExtract"
    }),
    ...mapGetters("partner", {
      detailsPartner: "getDetailsPartner",
    }),
    ...mapGetters("admin/partners", {
      adminDetailPartner: "getDetailsPartners",
    }),
    ...mapGetters("auth", {
      user: "getUser"
    }),

    infoPartner() {
      if (Object.keys(this.detailsPartner).length) {
        return this.detailsPartner;
      }
      return this.adminDetailPartner;
    },
  },

  methods: {
    formatCurrency: Utils.formatCurrency,
    ...mapActions("partner", [
      "fetchRevenue",
      "fetchExtract"
    ]),
    ...mapActions("partner", ["fetchDetailsPartner"]),
    ...mapMutations("system", ["setInfoMessage"]),

    openWithdrawalRequestModal() {
      if (this.detailsPartner?.clicksign_contract.signed === false) {
        this.setInfoMessage(this.$t("you_need_to_sign_contract_to_request_withdraw"));
      } else {
        if (this.detailsPartner && Object.keys(this.detailsPartner).length > 0) {
          return this.$refs.WithdrawalExtract.new();
        }
      }
    },
  },

  watch: {
    date: {
      handler(date) {
        if (date.start_date !== date.end_date) {
          this.fetchExtract(date);
        }
      },
      deep: true
    }
  }
}
</script>
<style scoped lang="scss">
.container-fluid {
  padding-left: 102px;
  padding-right: 36px;
}

.redeem {
  letter-spacing: -0.54px;
  font-size: 1.688rem;
  margin-bottom: 0.5rem;
}

.hover-card {
  transition: all 0.25s ease;


  .icon-card,
  .hover-icon {
    transition: all 0.25s ease;
  }

  &:hover,
  &:focus,
  &:focus-within {
    background-color: $color-gray-blue-300;
    box-shadow: 0 3px 6px rgba(#000, 0.13);

    .icon-card {
      background-color: #fff !important;
      box-shadow: 0 3px 6px rgba(#000, 0.13);
      animation: ping 0.25s ease;
    }
  }
}

@keyframes ping {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
</style>