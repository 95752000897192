<template>
  <header-component
    v-if="step != 2"
    :title="$t('import_list')"
    :pretitle="currentFunnel?.name"
    :redirect-link="previousRoute"
  />
  <alert-import v-if="step < 2" />
  <component :is="stepController" />
  <app-button
    v-if="step > 0 && step != 2"
    color="btn-white border-0"
    class="w-100 mt-3"
    @click="backToUpload"
  >
    {{ $t('back_capitalize') }}
  </app-button>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import HeaderComponent from "@/components/header-component.vue";
import CsvUpload from "./components/steps/csv-upload.vue";
import CsvPreview from "./components/steps/csv-preview.vue";
import CsvValidation from "./components/steps/csv-validation.vue";
import AppButton from "@components/app-button.vue";
import CsvLoadingValidation from "./components/steps/csv-loading-validation.vue";
import AlertImport from "./components/alert-import.vue";

export default {
  name: "CrmImport",

  components: {
    AlertImport,
    AppButton,
    CsvPreview,
    CsvUpload,
    CsvValidation,
    CsvLoadingValidation,
    HeaderComponent,
  },
  data(){
    return {
      previousRoute: ""
    }
  },

  computed: {
    ...mapGetters('crm/funnels', {
      currentFunnel: 'getCurrentFunnel'
    }),
    ...mapGetters('crm/imports', {
      step: 'getStep',
    }),

    stepController() {
      const stepComponents = {
        0: CsvUpload,
        1: CsvValidation,
        2: CsvLoadingValidation,
        3: CsvPreview,
      };

      return stepComponents[this.step] || CsvUpload;
    },
  },

  methods: {
    ...mapActions('crm/imports', ['clearCsvList']),
    ...mapActions("system", ["showConfirmationMessage"]),

    async backToUpload () {
      let response = await this.showConfirmationMessage({
        title: this.$t('back_to_initial_step'),
        text: this.$t('all_progress_will_be_lost'),
        type: "badWarning"
      })

      if (response.isConfirmed) {
        this.clearCsvList()
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if(from.path.includes('home'))
      vm.previousRoute = '/manager/crm/home'
    });
  },
  mounted() {
    if(this.previousRoute === ''){
      this.previousRoute =`/manager/crm/${this.$route.params.id}`
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (this.step === 2) {
      let response = await this.showConfirmationMessage({
        title: this.$t('page_exit'),
        text: this.$t('all_progress_will_be_lost'),
        type: "badWarning"
      })

      if (response.isConfirmed) {
        this.clearCsvList()
        next();
      }
    } else {
      next();
    }
  },
}
</script>
