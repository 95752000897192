import store from "@store";

import smsDashboard from "@sms/pages/dashboards/sms-dashboard.vue";

export default {
    path: "sms-dashboard",
    name: "sms-dashboard",
    components: {
      main: smsDashboard
    },
    meta: {
      "short-side-bar": false
    },
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/isManager"]) next();
      else next({
        name: "not-found",
        params: {
          pathMatch: to.path.split('/').slice(1)
        }
      });
    }
  };
