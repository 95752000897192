<template>
  <single-select
    id="selectInterval"
    ref="selectInterval"
    :list-array="work_break_groups"
    label="name"
    :error="errors && errors[0]"
    :current-option="value"
    @selected-option="$emit('update:modelValue', $event.id)"
    :disabled="loadingFlags.findFilters"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SingleSelect from "@components/single-select.vue";

export default {
  components: { SingleSelect },
  emits: ["update:modelValue"],
  data() {
    return {
      value: "",
      work_break_groups: [],
    }
  },
  mounted() {
    this.setFiltersIncludes(["work_break_groups"]);
    this.findFilters();
  },

  computed: {
    ...mapGetters("manager/filters", {
      filters: "getFilters",
      loadingFlags: "getLoadingFlags",
    }),
  },

  props: {
    modelValue: {
      default: "",
      type: [Number, String],
    },

    errors: {
      type: Array,
      default: null,
    },
  },

  methods: {
    ...mapActions("manager/filters", ["findFilters", "setFiltersIncludes"]),
  },

  watch: {
    filters: {
      handler() {
        if (this.filters && this.filters.work_break_groups) {
          this.work_break_groups = [...this.filters.work_break_groups];
          this.work_break_groups.unshift({
            id: "",
            name: this.$t("none"),
          });
          this.value = this.work_break_groups.find((group) => group.id === this.modelValue)?.name;
        }
      },
      deep: true,
    },
  },
};
</script>
