<template>
  <div class="w-100 vh-100 d-flex flex-column justify-content-between align-items-center pb-6 pt-5">
    <Vue3Lottie
      :animation-data="loading3c"
      :loop="true"
      style="width: 192px"
    />
    <div class="d-flex flex-column align-items-center">
      <h1 style="font-size: 48px">
        {{ $t('validating_yours') }} <span class="tx-blue-300">{{ $t('contacts_lowercase') }}</span>
      </h1>
      <p class="subtitle-loading mb-3">
        {{ $t('this_action_may_take_a_while') }}
      </p>
    </div>
    <p class="fs-3 tx-text-gray">
      {{ loadingMessage }}
    </p>
  </div>
</template>

<script>
import {Vue3Lottie} from "vue3-lottie";
import loading3c from '@assets/lottie/3c-custom-loading.json';

export default {
  name: "CsvLoadingValidation",

  components: {
    Vue3Lottie
  },

  data() {
    return {
      intervalId: null,
      loading3c,
      loadingMessage: '',
      loadingMessages: [
        this.$t('curiosity_3c_1'),
        this.$t('curiosity_3c_2'),
        this.$t('curiosity_3c_2'),
      ],
    }
  },

  mounted() {
    window.addEventListener("beforeunload", this.confirmExit);
    this.loadingMessage = this.loadingMessages[0];
    this.toggleLoadingMessage();
  },

  beforeUnmount() {
    window.removeEventListener("beforeunload", this.confirmExit);
    clearInterval(this.intervalId);
  },

  methods: {
    toggleLoadingMessage() {
      this.intervalId = setInterval(() => {
        const randomIndex = Math.floor(Math.random() * this.loadingMessages.length);
        this.loadingMessage = this.loadingMessages[randomIndex];
      }, 5000);
    },

    confirmExit(event) {
      event.preventDefault();
    },
  },
}
</script>

<style lang="scss" scoped>
.subtitle-loading {
  font-size: 24px;
  color: $color-blue-500;
}

.progress {
  height: 10px;
  background: #D1DAFA;
}

.confirm-btn {
  width: 392px;
  background: #F23F2C;
  color: $color-text-light;
}
</style>