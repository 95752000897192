<template>
  <hover-button
    :icon="muted ? 'fa-regular fa-microphone-slash' : 'fa-regular fa-microphone'"
    :text-color="muted ? '#F23F2C' : '#677C92'"
    :should-show-close-button="false"
    :active="
      status === AgentStatus.ON_CALL &&
        ![
          CallStatus.RECEIVING_INTERNAL_CALL,
          CallStatus.RECEIVING_EXTERNAL_CALL,
          CallStatus.RECEIVING_TRANSFERENCE,
          CallStatus.CONSULT_CONNECTED
        ].includes(callStatus)
    "
    :disabled="
      status === AgentStatus.OFFLINE ||
        (status === AgentStatus.ON_CALL && callStatus === CallStatus.RECEIVING_EXTERNAL_CALL)
    "
    :background-color="getBackgroundColor"
    @click="mute"
  />
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { AgentStatus, CallStatus } from "@/utils";
import HoverButton from "@dialer/agent/components/hover-button.vue";
export default {
  data() {
    return {
      AgentStatus,
      CallStatus
    };
  },

  components: {
    HoverButton
  },

  computed: {
    ...mapGetters("system", {
      muted: "isMuted",
      status: "getStatus",
    }),

    ...mapGetters("agent/ring_group", {
      callStatus: "getCallStatus"
    }),

    ...mapGetters("agent/agent", {
      loggedCampaign: "getCampaign"
    }),

    ...mapGetters("agent/call", {
      call: "getCall"
    }),

    getBackgroundColor() {
      const AGENT_STATUSES = [
        AgentStatus.IDLE,
        AgentStatus.ON_WORK_BREAK,
        AgentStatus.ACW,
        AgentStatus.ON_MANUAL_CALL_ACW
      ];

      if (
        this.loggedCampaign &&
        (AGENT_STATUSES.includes(this.status) || (!this.call && this.status === AgentStatus.CONSULT_CONNECTED))
      ) {
        return "var(--bs-white)";
      }

      return "#E1E9F4";
    }
  },

  methods: {
    ...mapMutations("system", ["setMuted"]),
    mute() {
      if (this.status !== AgentStatus.OFFLINE) {
        this.setMuted(!this.muted);
      }
    }
  }
};
</script>
