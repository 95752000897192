<template>
  <div class="col-12">
    <qualification-manager
      ref="qualificationManager"
      :edit="editQualification"
      :qualification-id="$route.params.id"
      :show-modal="showQualificationManager"
      @close="toggleQualificationManager(false)"
    />

    <leave-page-modal
      :show-modal="confirmationModal.show"
      :text-header="confirmationModal.title"
      :confirmation-message="confirmationModal.confirm"
      :cancel-message="confirmationModal.cancel"
      :title="confirmationModal.content.title"
      :sub-title="confirmationModal.content.subtitle"
      @confirm="removeQualification_"
      @cancel="toggleConfirmationModal(false)"
    />
    <main-loading v-if="!asyncReady" />
    <div v-show="asyncReady">
      <header-component
        :title="fields.name"
        :pretitle="$t('qualifications_uppercase')"
        redirect-link="/manager/qualifications"
      />

      <flash-message />

      <div
        v-if="!qualification_list.length"
        class="alert alert-info fade show"
        role="alert"
      >
        <span class="fe fe-alert-octagon" />&nbsp; <strong>{{ $t('attention') }}</strong>
        {{ $t('no_qualification_added') }}
        <span
          style="text-decoration: underline; cursor: pointer"
          @click="openQualificationModal()"
        >
          {{ $t('click_here_to_add') }}
        </span>
      </div>

      <form>
        <div class="row">
          <div class="col-12 col-md-6 mb-3">
            <div class="form-group mb-4">
              <label class="form-label required text-capitalize">{{ $t("name") }}</label>
              <input
                :class="{
                  'form-control': true,
                  'is-invalid': errors.name,
                }"
                v-model="fields.name"
                :placeholder="$t('capitalizes.name')"
              >
              <div class="invalid-feedback">
                {{ errors.name && errors.name[0] }}
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 mb-3">
            <div class="form-group mb-4">
              <label class="form-label required text-capitalize">{{ $t("type") }}</label>
              <select
                v-model="fields.type"
                class="form-select"
                :disabled="isCRM()"
              >
                <option :value="1">
                  {{ $t("dialer_menu_capitalize") }}
                </option>
                <option :value="2">
                  {{ $t("Omnichannel") }}
                </option>
                <option :value="3">
                  {{ $t("dialer_and_omnichannel_capitalize") }}
                </option>
                <option
                  disabled
                  :value="4"
                >
                  {{ $t("crm") }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </form>

      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="col">
              <search-input
                :placeholder="$t('placeholders.search_qualifications_lists')"
                @search="searchQuery"
              />
            </div>
            <div class="col-auto">
              <button
                style="padding: 0.5rem 1rem;"
                class="btn btn-gray-blue btn-sm lh-1"
                @click="openQualificationModal()"
              >
                {{ $t("add_qualification") }}
              </button>
            </div>
          </div>

          <div class="card-body p-0">
            <div class="table-responsive">
              <div
                class="
                  col-lg-12
                  vertical-center
                  text-center
                  main-loading
                  my-4
                "
                v-if="loading"
              >
                <div class="spinner-border text-muted vertical-cente">
                  <span class="visually-hidden" />
                </div>
                <br>
                <span class="text-muted"> {{ $t('loading_capitalize') }}... </span>
              </div>

              <table
                class="table mb-0 h-100"
                v-else
              >
                <thead style="">
                  <tr>
                    <th scope="col" />
                    <th scope="col">
                      {{ $t("color") }}
                    </th>
                    <th scope="col">
                      {{ $t("acronym.id") }}
                    </th>
                    <th scope="col">
                      {{ $t("name") }}
                    </th>
                    <th>
                      {{ $t("impact") }}
                    </th>
                    <th
                      v-if="!isCRM()"
                      scope="col"
                    >
                      {{ $t("behavior") }}
                    </th>
                    <th scope="col" />
                    <th scope="col" />
                    <th scope="col" />
                  </tr>
                </thead>

                <draggable
                  v-model="qualification_list"
                  tag="tbody"
                  item-key="id"
                  class="list-qualification border-0"
                  @end="reorder"
                  draggable=".dragabble"
                >
                  <template #item="{ element }">
                    <qualification-item
                      :qualification="element"
                      :is-c-r-m="isCRM()"
                      :is-system-native="isSystemNative(element)"
                      @edit="open"
                      @remove="openConfirmationModal"
                    />
                  </template>
                </draggable>
              </table>
              <qualification-form
                ref="qualificationForm"
                :current-page="currentPage"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 mb-3">
        <paginator
          :pagination="pagination"
          @change-page="changePage"
          v-if="!loading"
        />
      </div>

      <div class="col-lg-12">
        <app-button
          :blocked="!isValid()"
          class="fw mb-3"
          @click="submit()"
        >
          {{ $t("save_group") }}
        </app-button>
      </div>

      <div
        class="col-lg-12"
        @click="remove_()"
      >
        <app-button
          class="fw mb-4"
          color="text-danger"
        >
          <i class="fa-regular fa-trash" />
          {{ $t("exclude.group") }}
        </app-button>
      </div>
    </div>
  </div>
</template>

<script>
import FlashMessage from "@components/flash-message";
import appButton from "@/components/app-button.vue";
import mainLoading from "@/components/main-loading.vue";
import {mapGetters, mapMutations, mapActions} from "vuex";
import qualificationForm from "./components/form-qualification.vue";
import draggable from "vuedraggable";
import Paginator from "@/components/app-paginator.vue";
import HeaderComponent from "@/components/header-component.vue";
import QualificationItem from "@dialer/manager/pages/settings/qualifications/components/qualification-item.vue";
import SearchInput from "@components/search-input.vue";
import QualificationManager from "@dialer/manager/pages/settings/qualifications/components/qualification-manager.vue";
import LeavePageModal from "@components/leave-page-modal.vue";
import _ from "lodash";

export default {
  components: {
    LeavePageModal,
    QualificationManager,
    SearchInput,
    QualificationItem,
    appButton,
    mainLoading,
    qualificationForm,
    draggable,
    FlashMessage,
    Paginator,
    HeaderComponent
  },

  data() {
    return {
      drag: false,
      fields: {
        name: "",
        type: 1
      },
      qualification_list: [],
      asyncReady: false,
      currentPage: 1,
      showQualificationManager: false,
      confirmationModal: {
        show: false,
        title: null,
        confirm: null,
        cancel: null,
        content: {
          title: null,
          subtitle: null
        },
        exclude: false,
      },
      editQualification: null,
    };
  },
  async mounted() {
    this.setRegister(null);
    await this.awaitDispatches();
    this.qualification_list = this.qualifications;
    this.asyncReady = true;
  },
  computed: {
    ...mapGetters("manager/qualification", {
      register: "getRegister",
      qualifications: "getQualificationRegister",
      pagination: "getQualificationPagination",
      loading: "isLoading",
      errors: "getErrors",
    }),
  },
  watch: {
    register(val) {
      if (val) {
        this.fields.name = val.name;
        this.fields.type = val.type;
      }
    },
    qualifications() {
      this.qualification_list = this.qualifications;
    },
  },
  methods: {
    ...mapMutations("manager/qualification", [
      "setRegister",
      "setQualificationRegister",
    ]),
    ...mapActions("manager/qualification", [
      "findById",
      "update",
      "remove",
      "removeQualification",
      "findQualificationById",
      "orderQualifications",
    ]),
    ...mapActions("system", ["showConfirmationMessage"]),

    ...mapMutations("system", ["setFlashMessage"]),

    isCRM() {
      return this.fields.type === 4;
    },

    isSystemNative(qualification) {
      return this.isCRM() && qualification.name === 'Ganho';
    },

    isValid() {
      return this.fields.name!== this.register?.name || this.fields.type !== this.register?.type;
    },

    openQualificationModal() {
      if (this.isCRM()) {
        this.toggleQualificationManager(true);
        return
      }

      this.$refs.qualificationForm.new();
    },
    open(item) {
      if (this.isCRM()) {
        this.toggleQualificationManager(true);
        this.editQualification = item;
        return
      }

      this.$refs.qualificationForm.edit(item);
    },

    toggleQualificationManager(toggle) {
      this.showQualificationManager = toggle;

      if (!toggle) {
        this.editQualification = null;
      }
    },

    submit() {
      this.update({
        id: this.register.id,
        fields: this.fields,
      }).then((data) => {
        this.setFlashMessage({
          message: data.message,
          type: "success"
        });
      })
        .catch((data) => {
          this.setFlashMessage({
            message: data.message,
            type: "danger"
          });
        });
    },
    openConfirmationModal(item) {
      this.confirmationModal = {
        show: true,
        title: this.$t('exclude.qualification'),
        confirm: this.$t('yes'),
        cancel: this.$t('no'),
        content: {
          title: `${this.$t('questions.delete_confirmation')} ${item.name}?`,
          subtitle: this.$t('questions_info.delete_confirmation')
        },
        item: item,
      }
    },

    removeQualification_() {
      this.removeQualification({
        list_id: this.$route.params.id,
        id: this.confirmationModal.item.id,
      }).then(() => {
        this.toggleConfirmationModal(false)
        this.findQualificationById({id: this.register.id});
      });
    },

    toggleConfirmationModal(toggle) {
      this.confirmationModal.show = toggle
    },
    async remove_() {
      let response = await this.showConfirmationMessage({
        title: this.$t('delete_qualification_group_question'),
        text: this.$t('delete_qualification_group_confirmation'),
        type: "badWarning"
      })

      if (response.isConfirmed) {
        this.remove({
          id: this.register.id,
        })
          .then((data) => {
            this.$router.push({
              path: `/manager/qualifications`,
            }).then(() => {
              this.setFlashMessage({
                message: data.message,
                type: "success"
              });
            });
          })
          .catch((data) => {
            this.setFlashMessage({
              message: data.message,
              type: "danger"
            });
          });
      }
    },
    reorder() {
      const qualification_ids = {
        list: [],
      };

      this.qualification_list.forEach((qualification) => {
        qualification_ids.list.push(qualification.id.toString());
      });

      this.orderQualifications({
        list_id: this.$route.params.id,
        list: qualification_ids,
      });
    },
    async awaitDispatches() {
      await this.findById({id: this.$route.params.id});
      await this.findQualificationById({id: this.$route.params.id});
    },

    changePage(page) {
      this.currentPage = page;

      const payload = {
        id: this.$route.params.id,
        fields: {
          page: this.currentPage
        }
      }
      this.findQualificationById(payload);
    },

    searchQuery: _.debounce(function (query) {
      this.findQualificationById({
        id: this.$route.params.id,
        fields: {
          qualifications: query
        }
      })
    }, 1000),
  },
};
</script>

<style lang="scss" scoped>
.btn-gray-blue {
  background-color: $color-gray-blue-300;
  color: $color-text-gray;

  &:hover,
  &:focus,
  &:focus-visible {
    background-color: #D2DDEC;
  }
}

.btn-primary {
  &:disabled {
    background-color: $color-gray-blue-300;
    border-color: $color-gray-blue-300;
    color: $color-text-gray;
  }
}
</style>
