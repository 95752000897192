<template>
  <hover-button
    ref="internalCallButton"
    @click="toggleDropdown"
    icon="fas fa-user-group"
    :keep-button-hovered="showListAgentsCard"
  />

  <dropdown-component
    :is-open="showListAgentsCard"
    :button-rect="buttonRect"
    :title="$t('internal_call')"
  >
    <div class="overflow-auto card-internal-call-body">
      <div v-if="ringGroupUsers.length && !loadingFlags.fetchRingGroupUsers">
        <search-input
          class="search-input"
          :search="searchText"
          @search="search => (searchText = search)"
        />
        <ul
          v-for="ringGroup in filteredRingGroups"
          :key="ringGroup.id"
        >
          <li
            class="d-flex align-items-center position-relative ring-group-item rounded"
            v-if="
              searchText === '' ||
                ringGroup.name
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                ringGroup.extension_number.toString().includes(searchText) ||
                ringGroup.users.some(user =>
                  user.name.toLowerCase().includes(searchText.toLowerCase())
                )
            "
            :title="ringGroup.name"
          >
            <span class="tx-text-gray">
              {{ ringGroup.extension_number }}
            </span>
            <div
              class="ms-3 fw-medium text-truncate"
              style="width: 240px;"
            >
              {{ ringGroup.name }}
            </div>

            <div
              v-if="ringGroup.users.length"
              class="position-absolute group-indicator"
            />

            <i
              v-if="ringGroup == hoveredItem"
              class="fa-regular fa-arrow-right fw-normal tx-text-gray pe-3"
            />
          </li>
          <ul
            v-for="user in ringGroup.users"
            :key="user.id"
          >
            <li
              v-if="user.name !== myUser.name"
              class="d-flex align-items-center justify-content-between ring-group-item ps-5"
              :class="{
                'hover-to-call tx-text-200 rounded cursor-pointer':
                  user.is_online_in_ring_group,
                'tx-gray-200': !user.is_online_in_ring_group
              }"
              @mouseenter="hoveredItem = user"
              @mouseleave="hoveredItem = null"
              @click="_startInternalCall(user)"
            >
              <div>
                <span> {{ user.extension_number }} </span>
                <span class="ms-3">{{ user.name }} </span>
              </div>

              <i
                v-if="user == hoveredItem && user.is_online_in_ring_group"
                class="fa-regular fa-arrow-right fw-normal tx-text-gray pe-3"
              />

              <div
                v-if="!user.is_online_in_ring_group"
                class="offline-badge bg-gray-200 tx-gray-300 text-medium fw-medium"
              >
                Offline
              </div>
            </li>
          </ul>
        </ul>
      </div>
      <div
        class="d-flex flex-column text-center mt-6"
        v-if="filteredRingGroups.length === 0 && !loadingFlags.fetchRingGroupUsers"
      >
        <h2 class="my-0">
          Oops...
        </h2>
        <img
          :src="require(`@/assets/img/aloisio/Pluzinha_busca.svg`)"
          class="align-self-center my-4"
          style="width: 68px;"
        >
        <p class="fs-6">
          {{ $t('search_messages.results_not_found') }}
        </p>
      </div>

      <div
        class="h-100 d-flex flex-column justify-content-center align-items-center"
        v-if="loadingFlags.fetchRingGroupUsers"
      >
        <div
          class="spinner-border mb-3 text-primary"
          role="loading"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
        <p>{{ $t('loading_capitalize') }}...</p>
      </div>
    </div>
  </dropdown-component>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { AgentStatus, CallStatus } from "@/utils.js";
import HoverButton from "@dialer/agent/components/hover-button.vue";
import DropdownComponent from "@dialer/agent/components/dropdown-component.vue";
import SearchInput from "@/components/search-input.vue";

export default {
  name: "InternalCallButton",
  data() {
    return {
      CallStatus,
      AgentStatus,
      showListAgentsCard: false,
      buttonRect: null,
      hoveredItem: null,
      searchText: "",
    };
  },

  components: {
    HoverButton,
    DropdownComponent,
    SearchInput
  },

  methods: {
    ...mapActions("agent/ring_group", [
      "startInternalCall",
      "fetchRingGroupUsers"
    ]),
    ...mapMutations("system", ["setStatus"]),
    toggleDropdown() {
      this.showListAgentsCard = !this.showListAgentsCard;
      if (this.showListAgentsCard) {
        this.calculateButtonRect();
        window.addEventListener("resize", this.calculateButtonRect);
        this.fetchRingGroupUsers();
      } else {
        window.removeEventListener("resize", this.calculateButtonRect);
      }
    },

    calculateButtonRect() {
      const rect = this.$refs.internalCallButton.$el.getBoundingClientRect();
      this.buttonRect = rect;
    },

    _startInternalCall(user) {
      const { extension_number, is_online_in_ring_group } = user;
      if (is_online_in_ring_group) {
        this.startInternalCall(`#${extension_number}`);
      }
    }
  },

  computed: {
    ...mapGetters("agent/ring_group", {
      ringGroupUsers: "getRingGroupUsers",
      loadingFlags: "getLoadingFlags"
    }),

    ...mapGetters("auth", {
      myUser: "getUser"
    }),

    filteredRingGroups() {
      return this.ringGroupUsers.reduce((acc, ringGroup) => {
        const shouldShowGroup =
          ringGroup.name.toLowerCase().includes(this.searchText.toLowerCase()) ||
          ringGroup.extension_number.toString().includes(this.searchText);

        const shouldShowGroupBasedOnUsers =
          !(ringGroup.name.toLowerCase().includes(this.searchText.toLowerCase()) ||
          ringGroup.extension_number.toString().includes(this.searchText)) &&
          ringGroup.users.some(user => user.name.toLowerCase().includes(this.searchText.toLowerCase()));

        if (shouldShowGroup) {
          acc.push(ringGroup);
        } else if (shouldShowGroupBasedOnUsers) {
          const matchingUsers = ringGroup.users.filter(user => {
            return user.name.toLowerCase().includes(this.searchText.toLowerCase());
          });
          const groupWithFilteredUsers = { ...ringGroup, users: matchingUsers };
          acc.push(groupWithFilteredUsers);
        }

        return acc;
      }, []);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-internal-call-body {
  height: 345px;
  padding: 0.5rem 1rem;

  .search-input {
    padding: 0 1rem;
    margin-bottom: 16px;
  }

  .spinner-border {
    width: 3.5rem;
    height: 3.5rem;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      &.ring-group-item {
        padding: 4px 0 4px 1rem;

        .group-indicator {
          bottom: -16px;
          left: 20px;
          height: 16px;
          width: 8px;
          border-left: 1.5px solid $color-gray-200;
          border-bottom: 1.5px solid $color-gray-200;
        }

        .offline-badge {
          padding: 4px 8px;
          line-height: 16px;
          border-radius: 6px;
        }
      }

      &.hover-to-call {
        &:hover {
          background-color: $color-gray-blue-300;
        }
      }
    }
  }
}
</style>
