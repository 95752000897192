<script setup>
import {onBeforeMount, ref} from "vue"
import store from "@store";
import moment from "moment/moment";

const dateRange = ref({
  value: "",
  label: "",
  currentLabel: "",
  startDate: "",
  endDate: "",
  unitType: ""
});

function agentChange(agent) {
  if (agent && agent !== "") {
    store.commit("crm/dashboard/setActiveAgent", agent)
    return
  }

  store.commit("crm/dashboard/setActiveAgent", false)
}

function getEndOf(date, range) {
  if (range === 'semester') {
    const month = date.month();
    const year = date.year();

    if (month < 6) {
      return moment([year, 5, 30]).endOf('day');
    } else {
      return moment([year, 11, 31]).endOf('day');
    }
  }
  return moment().endOf(range)
}

function getStartOf(date, range) {
  if (range === 'semester') {
    const month = date.month();
    const year = date.year();

    if (month < 6) {
      return moment([year, 0, 1]).startOf('day');
    } else {
      return moment([year, 6, 1]).startOf('day');
    }
  }

  return moment().startOf(range);
}


function setDate(period) {
  let rangeEnd, rangeStart;

  rangeStart = getStartOf(moment(), period).format('YYYY-MM-DD');

  rangeEnd = getEndOf(moment(), period).format('YYYY-MM-DD');

  if (period?.range) {
    rangeEnd = moment(period.endDate).format('YYYY-MM-DD');
    rangeStart = moment(period.startDate).format('YYYY-MM-DD');
    period = period.range
  }

  dateRange.value.value = period;
  dateRange.value.endDate = rangeEnd;
  dateRange.value.startDate = rangeStart;
  dateRange.value.label = getPreviousLabel(period);
  dateRange.value.currentLabel = getCurrentLabel(period);

  store.commit("crm/dashboard/setDateRange", {
    currentLabel: dateRange.value.currentLabel,
    label: dateRange.value.label,
    value: dateRange.value.value,
    startDate: dateRange.value.startDate,
    endDate: dateRange.value.endDate,
  })
}


function getPreviousLabel(range) {
  const amount = range === 'semester' ? 2 : 1;
  const period = range === 'semester' ? 'quarter' : range;

  if (range === 'days') {
    return "yesterday"
  }

  if (range === 'week') {
    return 'previous'
  }

  if (range === 'month') {
    return moment(dateRange.value.startDate).subtract(1, 'month').format('MMM');
  }

  if (range === 'quarter' || range === 'semester' || range === 'custom') {

    return `${moment(dateRange.value.startDate).subtract(amount, period).format('MMM')}/${moment(dateRange.value.endDate).subtract(amount, period).format('MMM')}`;
  }

  return moment(dateRange.value.startDate).subtract(amount, period).format('YYYY');
}

function getCurrentLabel(range) {
  if (range === 'days') {
    return "today"
  }

  if (range === 'week') {
    return 'current'
  }

  if (range === 'month') {
    return moment(dateRange.value.endDate).format('MMM');
  }

  if (range === 'quarter' || range === 'semester' || range === 'custom') {
    return `${moment(dateRange.value.startDate).format('MMM')}/${moment(dateRange.value.endDate).format('MMM')}`;
  }

  return moment(dateRange.value.endDate).format('YYYY');
}

onBeforeMount(() => {
  setDate('month')
})

</script>

<script>
import EarningsComponent from "@crm/pages/dashboard/components/earnings-component.vue";
import ConversionRate from "@crm/pages/dashboard/components/conversion-rate.vue";
import AverageTicket from "@crm/pages/dashboard/components/average-ticket.vue";
import ProgressRate from "@crm/pages/dashboard/components/progress-rate.vue";
import AverageSalesCycle from "@crm/pages/dashboard/components/average-sales-cycle.vue";
import OpportunitiesChart from "@crm/pages/dashboard/components/opportunities-chart.vue";
import OnGoingOpportunities from "@crm/pages/dashboard/components/on-going-opportunities.vue";
// import ReasonsLoss from "@crm/pages/dashboard/components/reasons-loss.vue";
// import LossesSteps from "@crm/pages/dashboard/components/losses-steps.vue";
import CompletedActivities from "@crm/pages/dashboard/components/completed-activities.vue";
// import TopSellingProducts from "@crm/pages/dashboard/components/top-selling-products.vue";
import FiltersBar from "@crm/pages/dashboard/components/filters-bar.vue";
import NewOpportunitiesChart from "@crm/pages/dashboard/components/new-opportunities-chart.vue";
import LatestOpportunities from "@crm/pages/dashboard/components/latest-opportunities.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
// import _ from "lodash";

export default {
  name: "CrmDashboardIndex",
  components: {
    EarningsComponent,
    ConversionRate,
    AverageTicket,
    ProgressRate,
    AverageSalesCycle,
    OpportunitiesChart,
    OnGoingOpportunities,
    // ReasonsLoss,
    // LossesSteps,
    CompletedActivities,
    // TopSellingProducts,
    FiltersBar,
    NewOpportunitiesChart,
    LatestOpportunities
  },

  computed: {
    ...mapGetters("crm/dashboard", {
      date: "getDateRange",
    }),
    ...mapGetters("crm/funnels", {
      currentFunnel: "getCurrentFunnel"
    }),
    ...mapGetters("whatsapp/instance", {
      allowedAgents: "getAllowedAgents",
      loadingFlags: "getLoadingFlags",
      allowedParams: "getAllowedParams"
    }),
    ...mapGetters("crm/opportunities", {
      opportunityFilter: "getOpportunityFilters",
      contactsList: 'getContactsList'
    }),
    ...mapGetters("auth", {
      user: "getUser"
    })
  },

  created() {
    if (this.currentFunnel.team) {
      this.setAllowedParams({
        team: this.currentFunnel.team.id,
        all_company: 0
      })
      this.updateContactsList();
    }
  },


  methods: {
    ...mapMutations("crm/dashboard", [
      "setDateRangeUnitType",
      "setDateRangeTranslations"
    ]),
    ...mapActions("whatsapp/instance", ["findAllowedAgentsAndInstances"]),
    ...mapMutations("whatsapp/instance", ["setAllowedParams"]),
    ...mapMutations('crm/opportunities', [
      'setContactsList'
    ]),

    search(event) {
      setTimeout(() => {
        this.setAllowedParams({
          search: event,
          all_company: 0
        })
        this.updateContactsList();
      }, 500)
    },

    updateRole(role) {
      let params = {
        team: this.currentFunnel.team.id,
        role,
        all_company: 0
      }

      if (role === 'manager') {
        params.team = null
      }

      this.setAllowedParams(params);
      if (!this.contactsList[this.allowedParams.role].length) {
        this.updateContactsList();
      }
    },

    updateContactsList() {
      this.findAllowedAgentsAndInstances(this.allowedParams).then(() => {
        this.setContactsList({
          role: this.allowedParams.role,
          contactsList: this.allowedAgents
        })
      })
    }
  },


  watch: {
    date: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.setDateRangeUnitType(this.$t(`crm_dashboard.${newVal.value}`));
          this.setDateRangeTranslations({
            label: this.$t(newVal.label),
            currentLabel: this.$t(newVal.currentLabel)
          })
        }
      }
    }
  }
}
</script>

<template>
  <div class="row">
    <div class="col-12">
      <filters-bar
        :active-date-range="dateRange.value"
        @filter-change="setDate"
        @agent-change="agentChange"
        :agents="contactsList[allowedParams.role]"
        :loading="loadingFlags.findAllowedAgentsAndInstances"
        @update-role="updateRole"
        @search="search"
      />
    </div>
    <div class="col-lg-6">
      <div class="row">
        <div class="col-12">
          <earnings-component />
        </div>
        <div class="col-lg-6">
          <new-opportunities-chart />
        </div>
        <div class="col-lg-6">
          <opportunities-chart />
        </div>
        <!--        <div class="col-12">-->
        <!--          <losses-steps />-->
        <!--        </div>-->
        <div class="col-12">
          <average-sales-cycle />
        </div>
        <!--        <div class="col-12">-->
        <!--          <completed-activities />-->
        <!--        </div>-->
      </div>
    </div>
    <div class="col-lg-6">
      <div class="row">
        <div class="col-lg-6">
          <conversion-rate />
        </div>
        <div class="col-lg-6">
          <average-ticket />
        </div>
        <div class="col-12">
          <progress-rate />
        </div>
        <!--        <div class="col-12">-->
        <!--          <average-sales-cycle />-->
        <!--        </div>-->
        <div class="col-12">
          <on-going-opportunities />
        </div>
        <!--        <div class="col-12">-->
        <!--          <reasons-loss />-->
        <!--        </div>-->
        <!--        <div class="col-12">-->
        <!--          <top-selling-products />-->
        <!--        </div>-->
        <div class="col-12">
          <completed-activities />
        </div>
      </div>
    </div>
    <div class="col-12">
      <latest-opportunities :cols="3" />
    </div>
  </div>
</template>

<style scoped lang="scss">

.card {
  margin-bottom: 0;
}

.row {
  row-gap: 1.5rem;
}
</style>