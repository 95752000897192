<template>
  <div class="w-100">
    <label class="fs-3 text-center my-2 b5 w-100">{{ $t('put_on_hold') }}</label>

    <div
      class="d-flex align-items-center b4 mx-3 my-2 px-3 snooze-badge"
      @click="submit(generateNextThreeHoursDate())"
    >
      <div class="col">
        {{ $t('on_hold_later') }}
      </div>
      <small> {{ $t('on_hold_later_time') }} </small>
    </div>
    <div
      class="d-flex align-items-center b4 mx-3 my-2 px-3 snooze-badge"
      @click="submit(generateTomorrowAt9AMDate())"
    >
      {{ $t('time_on_hold_tomorrow') }}
      <div class="col b6" />
      <small class="b9">
        {{ generateTomorrowAt9AMDate().format("ddd. h") }}h
      </small>
    </div>
    <div
      class="d-flex align-items-center b4 mx-3 my-2 px-3 snooze-badge"
      @click="submit(generateNextMondayAt9AMDate())"
    >
      <div class="col b6">
        {{ $t('on_hold_monday') }}
      </div>
      <small class="b9">{{ $t('on_hold_monday_time') }}</small>
    </div>
    <div
      class="d-flex align-items-center b4 mx-3 my-2 px-3 snooze-badge"
      @click="submit(generateNextMonthAt9AMDate())"
    >
      <div class="col b6">
        {{ $t('time_on_hold_month') }}
      </div>
      <small class="b9">
        {{ generateNextMonthAt9AMDate().format("MMM. DD") }}
      </small>
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";
import {switchLocale, Utils} from "@/utils";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "SnoozeChatMenu",

  computed: {
    ...mapGetters("whatsapp/chat", {
      currentChat: "getCurrentChat",
      loadingFlags: "getLoadingFlags",
      snoozedByManager: "getSnoozedByManager",
    }),
  },


  methods: {
    ...mapActions("whatsapp/chat", ["putOnSnooze"]),
    ...mapActions("whatsapp/message", ["addRecentMessage"]),
    ...mapMutations("whatsapp/message", ["setNewMessage"]),
    ...mapMutations("auth", ["trackEvents"]),

    generateNextThreeHoursDate() {
      return moment().add("3", "hours");
    },

    generateTomorrowAt9AMDate() {
      switchLocale(this.$i18n.locale)
      return moment()
        .add("1", "day")
        .set({hours: 9, minute: 0, second: 0});
    },

    generateNextMondayAt9AMDate() {
      return moment()
        .day(8)
        .set({hours: 9, minute: 0, second: 0});
    },

    generateNextMonthAt9AMDate() {
      return moment()
        .add("1", "month")
        .set({hours: 9, minute: 0, second: 0});
    },

    generateTempId: Utils.generateUniqueID,

    submit(date) {
      let tempId = this.generateTempId;
      let note = {
        id: tempId,
        chat_id: this.$route.params.id,
        end_snooze: event.timestamp,
        time: moment().unix(),
        ack: null,
        fromMe: true,
        failed: false,
        type: "snooze-message",
      };
      this.putOnSnooze({
        chat_id: this.currentChat[this.$route.params.id].id,
        note: note,
        fields: {
          end_snooze: date.format("Y-MM-DD HH:mm:00")
        }
      }).then(() => {
        this.trackEvents("[Agent] Snooze Omnichannel Contact")
      });
    }
  },

  watch: {
    snoozedByManager(update) {
      if (update.in_snooze) {
        let note = {
          chat_id: this.$route.params.id,
          id: moment().unix() + "_" + Math.random().toString(16).slice(2),
          type: "snooze-message",
          time: moment().unix(),
          inter_message_data: {
            start_snooze: moment().unix(),
            end_snooze: update.end_snooze,
          },
        };
        this.addRecentMessage(note);
        this.setNewMessage(note)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.btn-dropdown-top-bar {
  margin-left: $whatsapp-spacing-2;
  padding: $whatsapp-button-padding !important;
  color: $color-text-gray !important;
  height: $whatsapp-button-size !important;
  width: $whatsapp-button-size !important;
}

.dropdown {
  display: inline-block;

  .dropdown-menu {
    min-width: 240px;

    .snooze-badge {
      .b9 {
        text-transform: capitalize;
      }

      &:hover {
        background-color: $color-blue-300 !important;

        small {
          color: #e3ebf6 !important;
        }

        color: #e3ebf6 !important;
      }

      background-color: #e3ebf6 !important;
      height: 40px;
      cursor: pointer;
      border-radius: $whatsapp-border-radius;

      .col {
        min-width: 50px;
      }

      small {
        color: #737373;
        font-weight: 200;
      }
    }
  }
}

button, .button-whatsapp {
  min-width: 1.244rem;
  min-height: 1.244rem;
  background-color: #e1e9f4;
  border: none;
  color: #95aac9;
  border-radius: $whatsapp-border-radius;

  &:hover:not(.no-hover), .whatsapp-calling {
    background-color: $color-blue-300 !important;
    color: #e3ebf6 !important;
  }
}

.btn-snooze {
  &.small-active {
    max-width: 32px !important;
    min-width: 32px !important;
    width: 32px !important;
    padding: 0.25rem !important;
    font-size: 0.875rem !important;
  }
}
</style>