<template>
  <modal-component
    @close="closeModal"
    :show="showModal"
    :center="true"
    :background-blur="true"
    size="modal-md"
  >
    <template
      #content
    >
      <div class="modal-header p-4">
        <div class="w-100">
          <h4 class="m-0 modal-title">
            {{ title }}
          </h4>
        </div>
        <button
          type="button"
          class="btn-close mb-3"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div
        class="modal-body text-center"
        style="padding: 32px 48px;"
      >
        <div>
          <h5 class="mb-4">
            {{ $t("blocklist_type") }}
          </h5>
          <div class="d-flex flex-row justify-content-evenly align-items-center">
            <div
              class="form-check"
              @click="showTimeInput = false;"
            >
              <input
                class="form-check-input"
                type="radio"
                name="blockList"
                id="blockList1"
                v-model="fields.radio"
                :value="0"
              >
              <label
                class="form-check-label"
                for="blockList1"
              >
                <h5 class=" h5 fw-normal tx-color-gray m-0">
                  {{ $t("undetermined_time") }}
                </h5>

              </label>
            </div>
            <div
              class="form-check"
              @click="showTimeInput = true;"
            >
              <input
                class="form-check-input"
                type="radio"
                name="blockList"
                id="blockList2"
                v-model="fields.radio"
                :value="1"
              >
              <label
                class="form-check-label"
                for="blockList2"
              >
                <h5 class=" h5 fw-normal tx-color-gray m-0">
                  {{ $t("custom") }}
                </h5>
              </label>
            </div>
          </div>
          <div v-if="enableInput">
            <label class="form-label mt-4 mb-0 fw-normal text-start h5 w-100">
              {{ $t("qty_blocking_days") }}
            </label>
            <input
              type="number"
              class="form-control h5 fw-normal mb-2"
              placeholder="1"
              v-model="fields.days"
              @input="preventZeroValue"
            >
            <h6 class="text-start fw-normal m-0">
              {{ $t("blocklist_warning") }}
            </h6>
          </div>
        </div>
        <div
          class="row justify-content-center"
          style="margin-top: 40px;"
        >
          <div class="col-12">
            <button
              class="btn btn-primary w-100 confirmed-button text-capitalize"
              :disabled="disableButton"
              @click="closeModal"
            >
              {{ $t("confirm") }}
            </button>
            <app-button
              class="w-100 pt-4"
              color="tx-text-gray"
              @click="closeModal"
            >
              {{ $t("cancel") }}
            </app-button>
          </div>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from "@/components/modal-component.vue";
import AppButton from "@/components/app-button.vue";

export default {
  emits: ['update:modelValue'],

  components: {
    ModalComponent,
    AppButton,
  },

  props: {
    title: {
      type: String,
      default: ""
    },

    modelValue: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      showModal: false,
      fields: {
        radio: 0,
        days: 0,
      },
      showTimeInput: false
    };
  },

  watch: {
    modelValue(newValue) {
      if (newValue === 0) {
        this.fields.radio = 0;
      } else {
        this.fields.radio = 1;
      }
      this.fields.days = newValue
      if (this.fields.radio > 0) {
        this.showTimeInput = true;
      }
    },
  },

  computed: {
    disableButton() {
      if (this.fields.radio == 0) {
        return false;
      }

      if (this.fields.radio == 1 && this.fields.days > 0) {
        return false;
      }

      return true;
    },

    enableInput() {
      if (this.fields.radio > 0) {
        return true;
      }
      return false;
    }
  },

  methods: {
    openModal() {
      this.showModal = true;
    },

    closeModal() {
      if (this.fields.radio == 0) {
        this.$emit('update:modelValue', 0);
      } else {
        this.$emit('update:modelValue', this.fields.days);
      }
      this.showModal = false;
    },

    preventZeroValue(event) {
      if (event.target.value === "0") {
        this.showTimeInput = false;
      }

    },
  }
};
</script>

<style lang="scss" scoped>

.modal-header .btn-close {
  margin-right: -0.9rem;
}

.confirmed-button {
  &:disabled {
    border: none;
    background-color: $color-gray-blue-300;
  }
}

</style>
