import store from "@/store";
import { playMediaStreamAudio } from "@/utils";

/**
 * Watch events triggered from the JsSip library
 *
 * @param { Object } session
 */
export function dialer(session) {
  const debug = localStorage.getItem("debug-front-end");

  session.on("accepted", data => {
    if (debug) console.log("[DIALER] - accepted: ", data);

    let mediaRecorder = null;
    let chunksLocal = [];

    store.commit("system/setJsSipStatus", "in-call");
    console.log("[JsSip] In Call!");
    try {
      const audioCtx = new AudioContext();
      const source1 = audioCtx.createMediaStreamSource(
        session._localMediaStream
      );
      const source2 = audioCtx.createMediaStreamSource(
        session.remoteMediaStream
      );
      const destination = audioCtx.createMediaStreamDestination();

      source1.connect(destination);
      source2.connect(destination);

      mediaRecorder = new MediaRecorder(destination.stream, {
        mimeType: "audio/webm;codecs=opus"
      });

      store.commit("system/setMediaRecorder", mediaRecorder);

      // Download the audio after the user has stopped
      mediaRecorder.onstop = () => {
        // Create an audio element without appending it to the DOM
        const audio = document.createElement("audio");
        audio.setAttribute("controls", "");

        const { phone, sid } = store.getters["agent/call/getCall"];
        const manualCallAcw = store.getters["agent/call/getManualCallAcw"];
        let fileName = manualCallAcw ? manualCallAcw.phone : phone;
        fileName = fileName.toString().concat("-", sid);
        const blob = new Blob(chunksLocal, {
          type: "audio/wav"
        });

        chunksLocal = [];

        const url = URL.createObjectURL(blob);

        // Create a download link dynamically
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${fileName}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);

        // Clean up the created elements
        document.body.removeChild(a);
      };

      // Inserts the blob object in the chunks array
      mediaRecorder.ondataavailable = e => {
        chunksLocal.push(e.data);
      };
    } catch (error) {
      console.error("[ERROR IN DIALER]:", error);
    }
  });

  session.on("peerconnection", data => {
    if (debug) console.log("[DIALER] - peerconnection: ", data);

    data.peerconnection.addEventListener("addstream", data => {
      playMediaStreamAudio(data.stream);
      store.commit("system/setJsSipStream", data.stream);
      session.remoteMediaStream = data.stream;
    });
  });

  session.on("connecting", data => {
    if (debug) console.log("[DIALER] - connecting: ", data);
  });

  session.on("sending", data => {
    if (debug) console.log("[DIALER] - sending: ", data);
  });

  session.on("progress", data => {
    if (debug) console.log("[DIALER] - progress", data);
  });

  session.on("confirmed", data => {
    if (debug) console.log("[DIALER] - confirmed: ", data);
  });

  session.on("ended", data => {
    if (debug) console.log("[DIALER] - ended: ", data);
  });

  session.on("failed", data => {
    if (debug) console.log("[DIALER] - failed: ", data);
  });

  session.on("newDTMF", data => {
    if (debug) console.log("[DIALER] - newDTMF: ", data);
  });

  session.on("newInfo", data => {
    if (debug) console.log("[DIALER] - newInfo: ", data);
  });

  session.on("hold", data => {
    if (debug) console.log("[DIALER] - hold: ", data);
  });

  session.on("unhold", data => {
    if (debug) console.log("[DIALER] - unhold: ", data);
  });

  session.on("muted", data => {
    if (debug) console.log("[DIALER] - muted: ", data);
  });

  session.on("unmuted", data => {
    if (debug) console.log("[DIALER] - unmuted: ", data);
  });

  session.on("reinvite", data => {
    if (debug) console.log("[DIALER] - reinvite: ", data);
  });

  session.on("update", data => {
    if (debug) console.log("[DIALER] - update: ", data);
  });

  session.on("refer", data => {
    if (debug) console.log("[DIALER] - refer: ", data);
  });

  session.on("replaces", data => {
    if (debug) console.log("[DIALER] - replaces: ", data);
  });

  session.on("sdp", data => {
    if (debug) console.log("[DIALER] - sdp: ", data);
  });

  session.on("icecandidate", data => {
    if (debug) console.log("[DIALER] - icecandidate: ", data);
  });

  session.on("getusermediafailed", data => {
    if (debug) console.log("[DIALER] - getusermediafailed: ", data);
  });

  session.answer({
    mediaConstraints: {
      audio: true,
      video: false
    }
  });
}
