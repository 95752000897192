<template>
  <div
    :id="id"
    :ref="`componentRef${id}`"
    :style="{ 'z-index': showSearch || animation ? 1 : '' }"
    class="position-relative mb-0  shadow-none"
    style="height: 40px;"
  >
    <div
      class="card position-absolute w-100 mb-0 shadow-none"
      :class="{ 'border-error': error }"
    >
      <div
        :class="{ 'p-0 pe-1': showSearch }"
        class="card-header border-bottom-0"
        style="height: 40px; padding: 12px 16px !important;"
      >
        <div
          class="w-100"
          :disabled="animation"
        >
          <a
            v-show="!showSearch"
            aria-expanded="false"
            class="justify-content-between align-items-center cursor-pointer tx-text-200"
            :class="{
              'd-flex': !showSearch
            }"
            data-bs-toggle="collapse"
            @click="toggle"
            @focus="toggle"
            tabindex="0"
          >
            <span
              class="w-100 text-truncate overflow-ellipsis"
              :class="{
                'text-muted': disabled
              }"
              :title="optionSelected || currentOption || initialText || $t('select')"
            >
              {{ optionSelected || currentOption || initialText || $t("select") }}
            </span>
            <i
              class="far fa-chevron-down m-0 fs-5"
              :class="{
                'text-muted': disabled
              }"
            />
          </a>
          <div
            v-show="showSearch"
            class="input-group"
          >
            <span class="input-group-text border-0  bg-white rounded-0 rounded-top p-0">
              <i class="far fa-search m-0 fs-5" />
            </span>
            <input
              :ref="`input_${this.id}`"
              v-model="termValue"
              class="form-control border-0"
              :placeholder="$t('type_here_placeholder')"
              type="text"
              @keydown.tab.exact="closeToggle"
              @keydown.shift.tab="closeToggle"
              @keydown.down="handleArrowDown"
              @keydown.up="handleArrowUp"
              @keydown.enter="selectFocusedOption"
            >
            <span
              :href="`#toggleCollapse${id}`"
              class="input-group-text border-0 rounded-0 rounded-top  bg-white cursor-pointer p-0"
              data-bs-toggle="collapse"
              @click="toggle"
            >
              <i class="far fa-chevron-up m-0 fs-5" />
            </span>
          </div>
        </div>
      </div>

      <div
        :id="`toggleCollapse${id}`"
        :ref="`toggleCollapse${id}`"
        class="collapse"
      >
        <div
          class="card-body overflow-auto py-2"
          style="max-height: 240px;padding: 6px 16px !important;"
        >
          <ul
            class="p-0 m-0 transition list-unstyled"
            role="listbox"
          >
            <template v-if="filterList.length">
              <li
                v-for="(item, index) in filterList"
                :key="index"
                :class="{ 'list-hover': focusedOptionIndex === index,'disabled': item[showBadge] }"
                :ref="`option_${id}_${index}`"
                :href="`#toggleCollapse${id}`"
                class="m-0 list  gap-2 d-flex justify-content-between align-items-center"
                data-bs-toggle="collapse"
                @click="selected(item)"
                @mouseenter="focusedOptionIndex = index"
                @keydown.enter="selected(item)"
                :title="item[label]"
                role="option"
              >
                <p class="m-0 text-truncate overflow-ellipsis">
                  {{ item[label] }}
                </p>
                <slot
                  name="badge"
                  v-if="item[showBadge]"
                />
              </li>
            </template>
            <li
              v-else
              class="text-center w-100 pb-2"
            >
              {{ message || $t("search_messages.results_not_found") }}
            </li>
          </ul>
          <div
            v-if="notFound"
            class="text-center w-100 pb-2"
          >
            {{ message }}
          </div>
        </div>
      </div>
    </div>
    <div
      class=""
      style="padding-top: 40px"
    >
      <small class="text-danger"> {{ error }}</small>
    </div>
  </div>
</template>

<script>
import { Collapse } from "bootstrap";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    listArray: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      default: "",
    },
    currentOption: {
      type: [String, Number],
      default: "",
    },
    initialText: {
      type: String,
      default: "Selecione",
    },
    error: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showBadge:{
      type: String,
      default: ''
    }
  },
  emits: ["selectedOption"],
  data() {
    return {
      focusedOptionIndex: -1,
      showSearch: false,
      animation: false,
      termValue: "",
      optionSelected: "",
      notFound: false,
      collapse: null,
    };
  },
  computed: {
    filterList() {
      return this.listArray.filter(item => item[this.label].toLowerCase().includes(this.termValue.toLowerCase()));
    },
  },
  methods: {
    handleArrowDown() {
      if (this.focusedOptionIndex < this.filterList.length - 1) {
        this.focusedOptionIndex += 1;
        this.scrollToFocusedOption();
      }
    },
    handleArrowUp() {
      if (this.focusedOptionIndex > 0) {
        this.focusedOptionIndex -= 1;
        this.scrollToFocusedOption();
      }
    },
    selectFocusedOption() {
      if (this.focusedOptionIndex >= 0) {
        const selectedOption = this.filterList[this.focusedOptionIndex];
        this.selected(selectedOption);
      }
    },
    toggle() {
      if (!this.animation && !this.disabled) {
        this.animation = true;
        this.showSearch = !this.showSearch;
        this.showSearch ? this.collapse.show() : this.collapse.hide();
        setTimeout(() => {
          this.$refs[`input_${this.id}`].focus();
          this.animation = false;
        }, 400);
      }
    },
    closeToggle() {
      setTimeout(() => {
        this.showSearch = false;
        this.collapse.hide();
      });
    },
    selected(item) {
      this.closeToggle()
      this.$emit("selectedOption", item);
      this.optionSelected = item[this.label];
    },
    handleClickOutside(event) {
      const isClickInsideComponent = this.$refs[`componentRef${this.id}`].contains(event.target);
      if (this.showSearch && !isClickInsideComponent) {
        this.toggle();
        this.collapse.hide();
      }
    },
    closeCollapse() {
      this.optionSelected = "";
      this.collapse.hide();
    },
    scrollToFocusedOption() {
      const focusedOption = this.$refs[`option_${this.id}_${this.focusedOptionIndex}`];
      if (focusedOption) {
        focusedOption.scrollIntoView({
          block: "nearest",
        });
      }
    },
  },
  watch: {
    currentOption: {
      handler() {
        this.optionSelected = this.currentOption;
      },
      deep: true,
    },
  },
  mounted() {
    this.collapse = new Collapse(this.$refs[`toggleCollapse${this.id}`], {
      toggle: false,
    });
    document.addEventListener("click", this.handleClickOutside);
  },
  unmounted() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>
<style
    lang="scss"
    scoped
>
.list-hover {
  background-color: $color-gray-blue-300;
  border-radius: 10px;
}

.card {
  border-color: #d2ddec !important;
}

.list {
  line-height: 32px;
  padding: 0 16px !important;
}

.border-error {
  border-color: $color-red-300 !important;
}
.disabled{
  pointer-events: none!important;
  color: #37375366 !important;
}
</style>
