<template>
  <div class="col-12 col-lg-10 col-xl-8">
    <main-loading v-if="!asyncReady" />
    <div v-if="asyncReady">
      <header-component
        :title="$t('create_user')"
        :pretitle="$t('users')"
        redirect-link="/manager/users"
      />

      <div class="col-12 col-md-12">
        <label class="form-label text-capitalize">
          {{ $t("role") }}
        </label>
        <div class="form-group row">
          <div
            @click="updateRole('agent')"
            class="col-12 col-lg-6 col-xl cursor-pointer"
          >
            <div class="card card-fill">
              <div class="card-body">
                <div class="row align-items-center gx-0">
                  <div class="col-auto">
                    <span class="h2 fe fe-headphones text-muted me-3" />
                  </div>
                  <div class="col">
                    <h4 class="text-capitalize">
                      {{ $t("operator") }}
                    </h4>
                    <span class="text-muted text-medium d-block fw-medium">
                      {{ $t("make_and_receive_calls") }}
                    </span>
                  </div>
                  <div class="col-auto">
                    <input
                      v-model="fields.role"
                      value="agent"
                      class="form-check-input"
                      type="radio"
                      id="checklistOne"
                      name="group"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            @click="updateRole('supervisor')"
            class="col-12 col-lg-6 col-xl cursor-pointer"
          >
            <div class="card card-fill">
              <div class="card-body">
                <div class="row align-items-center gx-0">
                  <div class="col-auto">
                    <span class="h2 fe fe-clipboard text-muted me-3" />
                  </div>
                  <div class="col">
                    <h4>Supervisor</h4>
                    <span class="text-muted text-medium d-block fw-medium">
                      {{ $t("supervisor_operation") }}
                    </span>
                  </div>
                  <div class="col-auto">
                    <input
                      v-model="fields.role"
                      value="supervisor"
                      class="form-check-input"
                      type="radio"
                      id="checklistOne"
                      name="group"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            @click="updateRole('manager')"
            class="col-12 col-lg-6 col-xl cursor-pointer"
          >
            <div class="card card-fill">
              <div class="card-body">
                <div class="row align-items-center gx-0">
                  <div class="col-auto">
                    <span class="h2 fe fe-clipboard text-muted me-3" />
                  </div>
                  <div class="col">
                    <h4 class="text-capitalize">
                      {{ $t("manager") }}
                    </h4>
                    <span class="text-muted text-medium d-block fw-medium">
                      {{ $t("manage_operation") }}
                    </span>
                  </div>
                  <div class="col-auto">
                    <input
                      v-model="fields.role"
                      value="manager"
                      class="form-check-input"
                      type="radio"
                      id="checklistOne"
                      name="group"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="invalid-feedback">
          {{ errors.role && errors.role[0] }}
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <div class="row">
              <div class="col">
                <label class="form-label text-capitalize">
                  {{ $t("name") }}
                </label>

                <input
                  type="text"
                  class="form-control"
                  :class="{
                    'form-control': true,
                    'is-invalid': errors.name,
                  }"
                  v-model="fields.name"
                >
                <div class="invalid-feedback">
                  {{ errors.name && errors.name[0] }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <div class="row">
              <div class="col">
                <label class="form-label text-capitalize">
                  {{ $t("extension") }}
                </label>

                <input
                  type="number"
                  class="form-control"
                  v-model="fields.extension_number"
                  v-maska
                  data-maska="#########"
                  :class="{
                    'form-control': true,
                    'is-invalid': errors.extension_number,
                  }"
                >
                <div class="invalid-feedback">
                  {{ errors.extension_number && errors.extension_number[0] }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-md-6"
          v-if="fields.role == 'supervisor' || fields.role == 'manager'"
        >
          <div class="form-group">
            <div class="row">
              <div class="col">
                <label class="form-label">
                  Email
                </label>

                <input
                  type="email"
                  class="form-control"
                  v-model="fields.email"
                  :class="{
                    'form-control': true,
                    'is-invalid': errors.email,
                  }"
                >
                <div class="invalid-feedback">
                  {{ errors.email && errors.email[0] }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :class="[!user.company.ring_group_licenses && fields.role === 'agent' ? 'col-12' : 'col-6']">
          <div class="form-group">
            <div class="row">
              <div class="col">
                <label class="form-label text-capitalize">
                  {{ $t("password") }}
                </label>

                <div class="input-group input-group-merge">
                  <input
                    id="password"
                    :type="showPassword ? 'text' :'password'"
                    class="form-control"
                    :class="{
                      'form-control': true,
                      'is-invalid': errors.password,
                    }"
                    v-model="fields.password"
                  >
                  <div
                    @click="showPassword = !showPassword"
                    class="input-group-text"
                  >
                    <i
                      class="fe fe-eye"
                      v-if="!showPassword"
                    />
                    <i
                      class="fe fe-eye-off"
                      v-else
                    />
                  </div>
                </div>
                <small
                  v-if="fields.password.length > 0"
                  class="text-muted"
                >
                  {{ $t("characters_validation") }}
                </small>
                <small class="text-danger">
                  {{ errors.password && errors.password[0] }}
                </small>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="user.company.ring_group_licenses && fields.role === 'agent'"
          class="col-6"
          style="margin-top: 30px;"
        >
          <div class="d-flex align-items-center justify-content-between mb-2">
            <div class="form-check form-switch">
              <input
                v-model="ring_group"
                @input="updateUsedRingGroupLicenses"
                id="ringGroup"
                type="checkbox"
                class="form-check-input"
                :disabled="user.company.ring_group_licenses - used_ring_group_licenses <= 0 && !ring_group"
              >
              <label
                class="form-check-label"
                for="ringGroup"
              >
                {{ $t('extension_function') }}
              </label>
              <tooltip-component
                icon="info"
                class="ms-2 tx-text-gray"
                title="Esta função habilitada, torna disponível o uso da função
                  ramal para ligações internas e externas.
                  Ao ativar será consumida uma licença do tipo ramal."
              />
            </div>
            <div>
              <span class="tx-text-gray">{{ $t('available_licenses') }}</span>
              {{ user.company.ring_group_licenses - used_ring_group_licenses }}/{{ user.company.ring_group_licenses }}
            </div>
          </div>
          <small class="form-text text-muted text-medium">
            {{ $t('function_external_and_internal_calls') }}
          </small>

          <small class="form-text text-danger text-medium">
            {{ errors.permissions && errors.permissions[0] }}
          </small>
        </div>

        <div
          class="col-12 col-md-12"
          v-if="fields.role == '' || fields.role == 'supervisor'"
        >
          <div class="form-group">
            <label class="form-label">
              {{ $t("teams_capitalize") }} <small class="text-danger">*</small>
            </label>
            <VueMultiselect
              v-model="fields.teams"
              label="name"
              track-by="id"
              :placeholder="$t('select_a_team')"
              :select-label="$t('click_or_enter_to_select')"
              :deselect-label="$t('click_or_enter_to_remove')"
              :selected-label="$t('selected')"
              :multiple="true"
              :close-on-select="false"
              :preserve-search="true"
              :options="list.teams"
              :class="{
                'is-invalid': errors.teams,
              }"
            />

            <div class="invalid-feedback">
              {{ errors.teams && errors.teams[0] }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-12">
        <div
          class="col-lg-12 d-flex align-items-center justify-content-center cursor-pointer text-primary mb-4"
          @click="showAdvancedOptions = !showAdvancedOptions"
        >
          <span
            class="fs-3 me-2"
            v-if="!showAdvancedOptions"
          >
            +
          </span>
          <span
            class="fs-3"
            v-else
          >
            -
          </span>
          {{ $t("advanced_options") }}.
        </div>
        <template v-if="showAdvancedOptions">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="form-label">
                  CPF
                </label>

                <input
                  type="text"
                  class="form-control"
                  :class="{
                    'form-control': true,
                    'is-invalid': errors.user_document,
                  }"
                  v-model="fields.user_document"
                  v-maska
                  data-maska="###.###.###-##"
                >

                <div class="invalid-feedback">
                  {{ errors.user_document && errors.user_document[0] }}
                </div>
              </div>
            </div>
            <div
              class="col-12 col-md-6"
              v-if="fields.role == 'agent' || fields.role == ''"
            >
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <label class="form-label">
                      Email
                    </label>

                    <input
                      type="email"
                      class="form-control"
                      v-model="fields.email"
                      :class="{
                        'form-control': true,
                        'is-invalid': errors.email,
                      }"
                    >
                    <div class="invalid-feedback">
                      {{ errors.email && errors.email[0] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12"
              v-if="fields.role == 'agent' || fields.role == ''"
            >
              <div class="form-group">
                <label class="mb-1">
                  {{ $t("campaign_capitalize") }} <small class="text-danger">*</small>
                </label>

                <VueMultiselect
                  v-model="fields.campaigns"
                  label="name"
                  track-by="id"
                  :placeholder="$t('select_campaigns')"
                  :select-label="$t('click_or_enter_to_select')"
                  :deselect-label="$t('click_or_enter_to_remove')"
                  :selected-label="$t('selected')"
                  :multiple="true"
                  :close-on-select="false"
                  :preserve-search="true"
                  :options="list.campaigns.active"
                />
              </div>
            </div>
            <div
              class="col-12"
              v-if="fields.role == 'agent' || fields.role == ''"
            >
              <div class="form-group">
                <label class="mb-1 text-capitalize">
                  {{ $t("receptive_queues").toLowerCase().replace(/(?<!\p{L})\p{L}(?=\p{L}{2})/gu, m => m.toUpperCase()) }} <small class="text-danger">*</small>
                </label>
                <VueMultiselect
                  v-model="fields.queues"
                  label="name"
                  track-by="id"
                  :placeholder="$t('select_receptive_queues')"
                  :select-label="$t('click_or_enter_to_select')"
                  :deselect-label="$t('click_or_enter_to_remove')"
                  :selected-label="$t('selected')"
                  :multiple="true"
                  :close-on-select="false"
                  :preserve-search="true"
                  :options="list.queues"
                />
              </div>
            </div>
            <div
              class="col-12 col-md-6"
              v-if="fields.role == 'agent' || fields.role == ''"
            >
              <div class="form-group">
                <label class="form-label text-capitalize">
                  {{ $t("team") }} <small class="text-danger">*</small>
                </label>
                <VueMultiselect
                  v-model="fields.teams"
                  label="name"
                  track-by="id"
                  :placeholder="$t('select_teams')"
                  :select-label="$t('click_or_enter_to_select')"
                  :deselect-label="$t('click_or_enter_to_remove')"
                  :selected-label="$t('selected')"
                  :multiple="true"
                  :close-on-select="false"
                  :preserve-search="true"
                  :options="list.teams"
                  :class="{
                    'is-invalid': errors.teams,
                  }"
                />

                <div class="invalid-feedback">
                  {{ errors.teams && errors.teams[0] }}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="form-label text-capitalize">
                  {{ $t("time_zone") }} <small class="text-danger">*</small>
                </label>
                <select
                  class="form-select"
                  :aria-label="$t('time_zone_capitalize')"
                  :class="{
                    'form-control': true,
                    'is-invalid': errors.timezone,
                  }"
                  v-model="fields.timezone"
                >
                  <option
                    v-for="timezone in timezones"
                    :key="timezone.value"
                    :value="timezone.value"
                  >
                    {{ timezone.text_value }}
                  </option>
                </select>
                <div class="invalid-feedback">
                  {{ errors.timezone && errors.timezone[0] }}
                </div>
              </div>
            </div>

            <div
              class="col-12 col-md-12"
              v-if="['supervisor', 'manager'].includes(fields.role)"
            >
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-6">
                      <div class=" mb-2 d-flex align-items-center">
                        <div class="col-auto form-check form-switch">
                          <input
                            id="externalSIP"
                            v-model="external_sip"
                            class="form-check-input"
                            type="checkbox"
                          >
                          <label
                            class="form-check-label"
                            for="externalSIP"
                          >
                            {{ $t("connect_sip") }}
                          </label>
                        </div>
                      </div>
                      <small class="form-text text-muted text-medium">{{ $t("connect_sip_description") }}</small>
                    </div>

                    <div
                      class="col-6"
                      v-if="fields.role === 'supervisor'"
                    >
                      <div class="mb-2 d-flex align-items-center">
                        <div class="col-auto form-check form-switch">
                          <input
                            id="allowMailingVisualization"
                            v-model="isVisualizationAllowed"
                            class="form-check-input"
                            type="checkbox"
                          >
                          <label
                            class="form-check-label"
                            for="allowMailingVisualization"
                          >
                            {{ $t("show_campaign_list") }}
                          </label>
                        </div>
                      </div>
                      <small class="form-text text-muted text-medium">{{ $t("show_campaign_list_description") }}</small>
                    </div>

                    <div class="col-6">
                      <div class="d-flex mb-2 align-items-center">
                        <div class="col-auto form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="webphone"
                            v-model="webphone"
                          >
                          <label
                            class="form-check-label"
                            for="webphone"
                          >
                            {{ $t("enable_webphone") }}
                          </label>
                        </div>
                      </div>
                      <small class="form-text text-muted text-medium">{{ $t("enable_webphone_description") }}</small>
                    </div>

                    <div
                      class="col-6"
                      v-if="fields.role === 'supervisor'"
                    >
                      <div class="mb-2 d-flex align-items-center">
                        <div class="col-auto form-check form-switch">
                          <input
                            v-model="isCampaignListManagementAllowed"
                            id="manageLists"
                            type="checkbox"
                            class="form-check-input"
                          >
                          <label
                            class="form-check-label"
                            for="manageLists"
                          >
                            {{ $t("manage_campaign_list") }}
                          </label>
                        </div>
                      </div>
                      <small class="form-text text-muted text-medium">{{ $t("manage_campaign_list_description") }}</small>
                    </div>

                    <div
                      v-if="fields.role == 'supervisor' || fields.role == 'manager'"
                      class="col-6"
                    >
                      <div class="mb-2 d-flex align-items-center">
                        <div class="col-auto form-check form-switch">
                          <input
                            id="blockSpy"
                            v-model="isSpyModeAllowed"
                            class="form-check-input"
                            type="checkbox"
                          >
                          <label
                            class="form-check-label"
                            for="blockSpy"
                          >
                            {{ $t("block_spy_on_user") }}
                          </label>
                        </div>
                      </div>
                      <small class="form-text text-muted text-medium">{{ $t("block_spy_on_user_description") }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="col-lg-12">
          <app-button
            class="fw mb-4 text-capitalize"
            :loading="loading"
            @click="submit()"
          >
            {{ $t("save") }}
          </app-button>
        </div>

        <div
          class="col-lg-12"
          @click="cancel()"
        >
          <app-button
            class="fw mb-4"
            color="text-danger text-capitalize"
          >
            <span class="fe fe-user-x" />
            {{ $t("cancel") }}
          </app-button>
        </div>
        <br>
        <br>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import appButton from "@/components/app-button.vue";
import MainLoading from "@/components/main-loading.vue";

import VueMultiselect from 'vue-multiselect';
import { timezones } from "@/utils";
import HeaderComponent from "@/components/header-component.vue";
import TooltipComponent from "@/components/tooltip-component.vue";

export default {
  data() {
    return {
      asyncReady: false,
      timezones: [],
      languages: [],
      showPassword: false,
      showAdvancedOptions: false,
      externalSip: false,
      isCampaignListManagementAllowed: false,
      isSpyModeAllowed: true,
      isVisualizationAllowed: false,
      ring_group: false,
      used_ring_group_licenses: 0,
      external_sip: false,
      webphone: false,
      fields: {
        name: "",
        email: "",
        extension_number: "",
        password: "",
        role: "agent",
        timezone: "America/Sao_Paulo",
        web_extension: true,
        user_document: "",
        campaigns: [],
        queues: [],
        teams: [],
        permissions: [],
      }
    };
  },
  components: {
    appButton,
    MainLoading,
    VueMultiselect,
    HeaderComponent,
    TooltipComponent
  },
  computed: {
    ...mapGetters("manager/user", {
      list: "getList",
      loading: "isLoading",
      register: "getRegister",
      errors: "getErrors",
    }),
        ...mapGetters("auth", {
      user: "getUser"
    }),
    ...mapGetters("crm/tour",{
      tourSteps: "getTourSteps"
    }),
  },
  methods: {
    ...mapActions("manager/user", ["create"]),
    ...mapActions("system", ["showConfirmationMessage"]),
    ...mapActions("auth", ["me"]),
    ...mapActions("crm/tour", ["updateTourSteps"]),

    submit() {
      let fields = {...this.fields};

      fields.campaigns = fields.campaigns.map(({id}) => id);
      fields.queues = fields.queues.map(({id}) => id);
      fields.teams = fields.teams.map(({id}) => id);
      fields.user_document = fields.user_document.replace(/[^0-9]/g,'');
      fields.web_extension = !this.external_sip;

      if(this.isCampaignListManagementAllowed)
        this.fields.permissions.push("manage.lists.in.campaign");
      if(this.isVisualizationAllowed)
        this.fields.permissions.push("view.lists.in.campaign");
      if(this.ring_group)
        this.fields.permissions.push("ring.group.access");
      if (this.webphone)
        this.fields.permissions.push("users.features.webphone");

      // All users are enable to use spy mode, but there's a permission to remove this feature for the user
      if (!this.isSpyModeAllowed)
        this.fields.permissions.push("users.features.blockspy");

        this.create({ fields: fields })
          .then(() => {
            if(!this.tourSteps?.new_users){
              this.updateTourSteps({ new_users: true });
            }
            this.me();
            this.$router.push({
              path: `/manager/users`,
            });
          })
          .catch(() => {
            this.fields.permissions = []
          });
    },
    updateRole(el){
      this.fields.role = el
    },
    async cancel() {
      let response = await this.showConfirmationMessage({
        title: this.$t('cancel_user_create'),
        text: "",
        type: "badWarning"
      })

      if(response.isConfirmed) {
        this.$router.push({
          path: `/manager/users`,
        });
      }
    },
    async awaitDispatches() {
      await this.$store.dispatch('manager/user/listFilters');
    },

    updateUsedRingGroupLicenses() {
      if(!this.ring_group) {
        this.used_ring_group_licenses++;
      } else {
        this.used_ring_group_licenses--;
      }
    }
  },
  async mounted() {
    await this.awaitDispatches();
    this.asyncReady = true;
    this.timezones = timezones;
    this.used_ring_group_licenses = this.user.company.used_ring_group_licenses;
  },

  watch: {
    isCampaignListManagementAllowed(newVal) {
      newVal && !this.isVisualizationAllowed ? this.isVisualizationAllowed = true : ''
    },
    isVisualizationAllowed(newVal) {
      !newVal && this.isCampaignListManagementAllowed ? this.isCampaignListManagementAllowed = false : ""
    }
  }
};
</script>
