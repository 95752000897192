import axios from "axios";

export default {
  loadMetrics({ commit }, payload) {
    commit("startLoading");

    return new Promise((resolve, reject) => {
      axios.get(`/receptive_queues/${payload.id}/metrics`)
        .then(response => {
          commit("setMetrics", response.data.data);
          resolve();
        })
        .catch(response => {
          reject(response);
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },
  clearMetrics({ commit }) {
    commit("setMetrics", []);
  },

  loadQualifications({ commit }, payload) {
    commit("startLoading");

    return new Promise((resolve, reject) => {
      axios.get(`/receptive_queues/${payload.id}/metrics/qualifications`)
        .then(response => {
          commit("setQualifications", response.data.data);
          resolve();
        })
        .catch(response => {
          reject(response);
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },
  clearQualifications({ commit }) {
    commit("setQualifications", []);
  },
};
