import axios from "@crm/plugins/axios-config";

export default {
  fetchTourSteps ({commit}) {
    commit('startLoading', 'fetchTourSteps');
    return new Promise((resolve, reject) => {
      axios.get(`/guided-tour`)
        .then((response) => {
          commit('setTourSteps', response.data.data[0])
          resolve(response.data.data[0]);
        })
        .catch(() => {
          commit('stopLoading', 'fetchTourSteps');
          reject();
        })
        .finally(() => {
          commit('stopLoading', 'fetchTourSteps');
        });
    })
  },
  updateTourSteps ({commit}, payload) {
    commit('startLoading', 'updateTourSteps');
    return new Promise((resolve, reject) => {
      axios.patch(`/guided-tour`, payload)
        .then((response) => {
          commit('setTourSteps', response.data.data[0])
          resolve();
        })
        .catch(() => {
          commit('stopLoading', 'updateTourSteps');
          reject();
        })
        .finally(() => {
          commit('stopLoading', 'updateTourSteps');
        });
    })
  }
}
