<template>
  <vue-multiselect
    v-model="selectedManager"
    :options="managers"
    :track-by="'id'"
    :label="'name'"
    :placeholder="$t('select_manager')"
    :select-label="$t('to_select')"
    :selected-label="$t('selected')"
    :deselect-label="$t('remove_capitalize')"
    @select="updateValue"
  >
    <template #noResult>
      {{ $t('no_element_found') }}
    </template>
  </vue-multiselect>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import VueMultiselect from "vue-multiselect";

export default {
  data() {
    return {
      selectedManager: { id: 0, name: "Todos" },
      managers: [],
    }
  },
  /**
   *
   */
  emits: ["update:modelValue"],

  /**
   *
   */
  mounted() {
    this.setIncludes(["managers"]);
    this.findFilters();
  },

  /**
   *
   */
  computed: {
    ...mapGetters("manager/filters", {
      filters: "getFilters",
    }),
  },

  props: {
    /**
     *
     */
    modelValue: {
      type: Number,
      default: 0,
    },

    /**
     *  if no select return 0 and print "Todos"
     */
    noSelectReturnZero: {
      default: false,
      type: Boolean,
    },
  },

  /**
   *
   */
  components: {
    VueMultiselect
  },

  /**
   *
   */
  methods: {
    ...mapActions("manager/filters", ["findFilters"]),
    ...mapMutations("manager/filters", ["setIncludes"]),

    /**
     *
     */
     updateValue(value) {
      this.$emit('update:modelValue', value.id);
    }
  },
  watch: {
    filters: {
      handler(data) {
        if(data.managers) {
          this.managers = Object.values(data.managers);

          if(this.noSelectReturnZero)
            this.managers.unshift({ id: 0, name: "Todos" });
        }
      },
      deep: true,
    }
  }
};
</script>
