<template>
  <div class="card mb-0">
    <div class="card-header">
      <slot name="header" />
    </div>
    <div class="card-body p-0">
      <slot name="emptyState" />
      <div class="table-responsive">
        <slot name="table" />
        <table
          v-if="!slotTable"
          class="table mb-0"
          :class="{ 'table-hover': tableHover }"
        >
          <thead>
            <slot name="tableHead" />
          </thead>
          <tbody>
            <slot name="tableBody" />
          </tbody>
        </table>
      </div>
    </div>
    <div
      class="card-footer"
      v-if="settingsPagination"
    >
      <app-paginator
        :pagination="settingsPagination.pagination"
        @change-page="settingsPagination.change_page"
      />
    </div>
  </div>
  <slot name="component" />
</template>

<script>
import AppPaginator from "@components/app-paginator.vue";

export default {
  name: "TableIntervalsReport",
  components: { AppPaginator },
  props: {
    tableHover: {
      type: Boolean,
      default: false
    },
    emptyState: {
      type: Object,
      default: () => {}
    },
    settingsPagination: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      slotTable: false
    };
  },
  mounted() {
    this.checkSlots();
  },
  watch: {
    $slots: {
      handler() {
        this.checkSlots();
      },
      deep: true
    }
  },
  methods: {
    checkSlots() {
      if (this.$slots.table) {
        this.slotTable = true;
      }
    }
  }
};
</script>
