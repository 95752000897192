export default {
  setInstances: (state, instance) => {
    if(instance?.teams?.length || instance?.company?.instances) {
      const instancesParent = instance?.teams || instance?.company;
      let temp = [];
      temp = Array.isArray(instancesParent) ? instancesParent.map((team) => team.instances).flat() : instancesParent.instances.data;
      state.instances = temp
    }
  },
  startLoading: (state, flag) => state.loadingFlags[flag] = true,
  stopLoading: (state, flag) => state.loadingFlags[flag] = false,
  setAllowedAgents: (state, allowedAgents) => state.allowedAgents = allowedAgents,
  addAllowedAgents: (state, allowedAgents) => state.allowedAgents = [...state.allowedAgents, ...allowedAgents],
  setAllowedInstances: (state, allowedInstances) => state.allowedInstances = allowedInstances,
  setQualifications: (state, qualifications) => state.qualifications = qualifications,
  setGroupChannel: (state, groupChannel) => state.groupChannel = groupChannel,
  setGroupChannels: (state, groupChannels) => state.groupChannels = groupChannels,
  setAllowedParams: (state, params) => {
    state.allowedParams = Object.assign(state.allowedParams, params)
  },
}
