export default {
  getLoadingFlag: state => state.loadingFlag,
  getCategories: state => state.categories,
  getTotalProducts: state => state.totalProducts,
  getShowModal: state => state.showModal,
  getCategoryData: state => state.categoryData,
  getCategoryModal: state => state.categoryModal,
  getProductData: state => state.productData,
  getErrors: state => state.errors,
  getEdit: state => state.edit,
  getExclude: state => state.exclude,
  getActiveList: state => state.activeList,
  getSynchronizedProducts: state => state.synchronizedProducts,
  getOpportunityTotalAmount: state => state.opportunityTotalAmount,
  getProducts: state => state.listProducts
}
