import store from "@store";

import IndexProductsAndServices
    from "@dialer/manager/pages/settings/products-and-services/index-products-and-services.vue";
import ListProductsAndServices
    from "@dialer/manager/pages/settings/products-and-services/list-products-and-services.vue";

export default {
    path: "products-and-services",
    name: "products-and-services",
    components: {
        main: IndexProductsAndServices
    },
    children: [
        {
            path: "",
            components: {
                "products-and-services": ListProductsAndServices
            }
        },
    ],
    beforeEnter: (to, from, next) => {
        if (store.getters["auth/isManager"]) next();
        else next({
            name: "not-found",
            params: {
                pathMatch: to.path.split('/').slice(1)
            }
        });
    }
};
