<template>
  <div class="main-content">
    <div class="row my-4">
      <div
        class="col-xl-3 col-lg-4 col-md-6 col-sm-12"
      >
        <div
          class="card card-new"
          @click="openRoutesModal()"
        >
          <div
            class="card-body cursor-pointer"
          >
            <div class="row align-items-center gx-0">
              <div
                class="col overflow-ellipsis"
                style="line-height: 36.19px"
              >
                <span class="h4 mb-0"> Adicionar nova rota </span>
              </div>
              <div class="col-auto">
                <span class="h2 fe fe-plus text-primary mb-0" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-xl-3 col-lg-4 col-md-6 col-sm-12"
        v-for="(item, i) in list"
        :key="i"
      >
        <div
          class="card card-list-item"
          style="cursor: pointer"
          @click="editRoutesModal(item)"
        >
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col overflow-ellipsis one-line d-flex flex-column">
                <span class="h4 mb-1">
                  {{ item.name }}
                </span>
                <small
                  v-if="item['telephony-rates'].length"
                  class="text-muted"
                > {{ item['telephony-rates'][0].minimum_duration + '/' + item['telephony-rates'][0].minimum_duration_charged + '/' + item['telephony-rates'][0].cadence }}
                  <small
                    v-if="checkCompanyPlan(item)"
                    class="ps-2 fe fe-alert-triangle text-warning"
                  />
                </small>
              </div>
              <div class="col-auto">
                <span class="h2 fe fe-chevron-right text-primary mb-0" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <app-paginator
          :pagination="pagination"
          @change-page="changePage"
          v-if="!loadingFlags.findCompanyRoutes"
        />
      </div>
      <edit-routes
        :route-settings="routeSettings"
        ref="editRoutesModal"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import EditRoutes from "./components/edit-routes-companies.vue";
import AppPaginator from "@/components/app-paginator.vue";

export default {
  data() {
    return {
      routeSettings: []
    };
  },

  mounted() {
    this.findCompanyRoutes({id: this.$route.params.id});
    this.setFiltersIncludes(["route_settings"]);
    this.findFilters();
  },

  components: {
    EditRoutes,
    AppPaginator
  },

  computed: {
    ...mapGetters('admin/companies', {
      list: "getCompanyRoutesList",
      register: "getRegister",
      pagination: "getCompanyRoutesPagination",
      loadingFlags: "getLoadingFlags"
    }),

    ...mapGetters("manager/filters", {
      filters: "getFilters"
    }),
  },

  methods: {
    ...mapActions('admin/companies', ["findCompanyRoutes"]),
    ...mapActions("manager/filters", ["findFilters", "setFiltersIncludes"]),
    ...mapMutations('admin/companies', ['setCompanyRoutesCurrentPage']),
    ...mapMutations("auth", ["trackEvents"]),

    openRoutesModal() {
      this.$refs.editRoutesModal.new();
    },

    editRoutesModal(item) {
      this.$refs.editRoutesModal.edit(item);
      this.trackEvents("[Admin] Edit Route");
    },

    checkCompanyPlan(item) {
      let is3CEndpoint = item.endpoint.toLowerCase().includes("3cplus");

      if(is3CEndpoint &&
        ((this.register.plan == 1 && !item.should_not_debit) ||
        (this.register.plan == 2 && item.should_not_debit)) ||
        !is3CEndpoint && !item.should_not_debit && this.register.plan < 3
      )
        return true;

      return false;
    },

    changePage(page) {
      this.setCompanyRoutesCurrentPage(page);
      this.findCompanyRoutes({id: this.$route.params.id});
    },
  },

  watch: {
    filters: {
      handler(value) {
        if (value.route_settings) {
          this.routeSettings = value.route_settings;
        }
      },
      deep: true
    }
  }
}
</script>
