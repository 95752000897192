<template>
  <history-accordion
    :index="index"
  >
    <template #header>
      <div class="w-100 row align-items-center">
        <div class="col-lg-5">
          {{ changeTitle(change.change_type) }}
        </div>
        <div class="col-lg-7">
          <div class="d-flex justify-content-between">
            <div>
              <span v-if="change.previous_value != null">
                {{ change.previous_value }} licenças
                <i
                  class="fe fe-repeat"
                  style="color: #3057F2;"
                />
              </span>
              {{ change.current_value }} licenças
            </div>
            <div class="me-4 text-small">
              <span class="text-muted d-block">Realizado em:</span>
              {{ timestampToDate(change.created_at, "DD/MM/YYYY [às] HH:mm") }}
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #body>
      <div class="row text-small">
        <div class="col-lg-2">
          <span class="d-block tx-text-200 fw-bold">Realizado por:</span>
          {{ change.user_name }}
        </div>
        <div class="col-lg-10">
          <span class="d-block tx-text-200 fw-bold">Motivo da alteração</span>
          {{ change.change_reason }}
        </div>
      </div>
    </template>
  </history-accordion>
</template>

<script>
import { Utils } from "@/utils";
import HistoryAccordion from "./history-accordion.vue";

export default {
  components: {
    HistoryAccordion
  },

  props: {
    change: {
      type: Object,
      default: () => {}
    },

    index: {
      type: Number,
      default: null
    }
  },

  methods: {
    timestampToDate: Utils.timestampToDate,

    changeTitle(change) {
      if (change == "max_agents_login_change") return "Alteração de agentes logados";

      if (change == "limit_call_per_agent_change") return "Alteração de chamadas por agente";

      if (change == "webphone_licenses_change") return "Alteração de licenças webphone";

      if (change == "ring_group_licenses_change") return "Alteração de licenças de ramais";

      if (change == "ura_licenses_change") return "Alteração de licenças URA";
    }
  }
}
</script>
