export default {
    isLoading: state => state.loading,
    getLoadingFlags: state => state.loadingFlags,
    getLoadingContext: state => state.loadingContext,
    getRingGroup: state => state.ringGroup,
    getList: state => state.list,
    getFilters: state => state.filters,
    getPagination: (state) => state.pagination,
    getErrors: state => state.errors,
    getRegister: (state) => state.register,
    getParams: (state) =>
    Object.assign(
      {
        'page': state.pagination.current_page,
        'per_page': state.pagination.per_page
      } , state.filters),
    getDataSelect: (state) => state.dataSelect,
    getConsultMetrics: (state) => state.consultMetrics,
    getQualificationsMetrics: (state) => state.qualificationMetrics,
    getAgentsStatus: (state) => state.agentsStatus,
    getCalls: (state) => state.calls,
    getStatusCount: (state) => state.statusCount,
    getStatusCallsCount: (state) => state.statusCallsCount,
    getRingGroups: (state) => state.ringGroups,
    getTotal: (state) => state.total,
    getActiveAgents: (state) => state.activeAgents,
    getActiveCalls: (state) => state.activeCalls,
    getMetrics: (state) => state.metrics,
  }
