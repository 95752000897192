<template>
  <div
    ref="canvas"
    class="offcanvas offcanvas-end"
  >
    <div>
      <div
        class="offcanvas-header"
        style="border: none"
      >
        <button
          aria-label="Close"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          type="button"
        />
      </div>

      <div class="offcanvas-body">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h2 style="font-weight: 100">
              {{ $t('capitalizes.criterion') }}
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group">
              <label for="form-label required">{{ $t('criterion_name') }}</label>
              <input
                v-model="fields.name"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.createCriteria.name ||
                    errors.editCriteria.name
                }"
                inputmode="numeric"
                :placeholder="$t('capitalizes.criterion')"
                type="text"
              >
              <div class="invalid-feedback">
                {{
                  errors.createCriteria.name &&
                    errors.createCriteria.name[0] ||
                    errors.editCriteria.name &&
                    errors.editCriteria.name[0]
                }}
              </div>
            </div>
          </div>
          <div
            class="col-lg-12"
          >
            <label class="form-label required">{{ $t('audio_capitalize') }}</label>
            <div
              :class="{
                'card mb-0': true,
                'is-invalid': errors.createCriteria.audio ||
                  errors.editCriteria.audio
              }"
            >
              <div class="card-body">
                <div
                  v-if="fields.preEditAudio != null"
                  class="text-center my-1 text-muted"
                >
                  <small> {{ fields.originalAudioName }}</small>
                  <audio
                    class="w-100 mb-4"
                    controls
                  >
                    <source
                      :src="fields.preEditAudio"
                      type="audio/mpeg"
                    >
                  </audio>
                </div>
                <file-upload
                  filter="audio/mp3"
                  icon="fe fe-headphones"
                  @select-file="selectFile"
                />
              </div>
            </div>
            <div class="invalid-feedback">
              {{
                errors.createCriteria.audio &&
                  errors.createCriteria.audio[0] ||
                  errors.editCriteria.audio &&
                  errors.editCriteria.audio[0]
              }}
            </div>
          </div>
          <div class="col-lg-12">
            <div class="form-group 0">
              <label class="form-label required">{{ $t('valid_keys') }}</label>

              <div
                aria-label="First group"
                class="btn-group mr-2 w-100"
                role="group"
              >
                <button
                  v-for="(i, index) in 10"
                  :key="index"
                  :class="{
                    'btn px-1': true,
                    'btn-outline-secondary': !fields.keys[index],
                    'btn-primary': fields.keys[index],
                  }"
                  type="button"
                  @click="fields.keys[index] = !fields.keys[index]"
                >
                  {{ index }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <app-button
          :disabled="loadingFlags.removeCriteria"
          :loading="loadingFlags.createCriteria || loadingFlags.editCriteria"
          class="col-lg-12 btn btn-primary"
          @click="submit()"
        >
          {{ mode === 'new' ? $t('create') : $t('capitalizes.save') }}
        </app-button>
        <div
          v-if="mode == 'edit'"
          class="text-center mt-4"
        >
          <span
            class="btn p-0 px-4 text-danger"
            @click="!loadingFlags.removeCriteria && _removeCriteria()"
          >
            <span class="fe fe-trash-2 me-1" />
            {{ $t('delete_criterion') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fileUpload from "@components/file-uploader";
import {Offcanvas} from "bootstrap";
import {mapActions, mapGetters} from "vuex";
import AppButton from "@components/app-button";

export default {
  data() {
    return {
      fields: {
        id: "",
        name: "",
        originalAudioName: "",
        audio: null,
        keys: Array(10).fill(false),
        preEditAudio: null,
      },
      addedNewAudio: false,
      offcanvas: null,
      mode: "",
    }
  },
  mounted() {
    this.offcanvas = new Offcanvas(this.$refs.canvas);
  },
  components: {
    fileUpload,
    AppButton,
  },
  computed: {
    ...mapGetters("manager/ivr-after-call", {
      loadingFlags: "getLoadingFlags",
      errors: "getErrors"
    }),

    ...mapGetters("auth", {
      users: "getUser"
    })
  },
  methods: {
    ...mapActions("manager/ivr-after-call", [
      "createCriteria",
      "editCriteria",
      "clearErrors",
      "removeCriteria"
    ]),
    ...mapActions("system", ["showConfirmationMessage"]),

    new() {
      this.clearErrors();
      this.mode = "new";
      this.fields = {
        id: "",
        name: "",
        originalAudioName: "",
        audio: null,
        keys: Array(10).fill(false),
        preEditAudio: null,
      };
      this.offcanvas.show();
    },
    edit(item) {
      this.clearErrors();
      this.mode = "edit";
      this.fields.preEditAudio = null;
      this.fields.keys = Array(10).fill(false);

      this.fields.name = item.name;
      this.fields.originalAudioName = item.original_audio_name;
      this.fields.id = item.id;

      setTimeout(() => {
        for (let i of item.keys) this.fields.keys[i.key] = true;
        this.fields.preEditAudio =
          process.env.VUE_APP_BA12AS.replace("/api/v1", "") +
          item.audio +
          "&api_token=" +
          this.users.api_token;
      }, 1000)

      this.offcanvas.show();
    },
    selectFile(file) {
      this.addedNewAudio = !this.addedNewAudio
      this.fields.audio = file;
    },

    submit() {
      let formData = new FormData();
      formData.append("name", this.fields.name);
      for (let item of this.fields.keys) {
        formData.append("keys[]", item ? "1" : "0");
      }

      if (this.mode === "new") {
        formData.append("audio", this.fields.audio);
        this.createCriteria({
          id: this.$route.params.id,
          formData
        })
        .then(() => {
          window.location.reload()
        })
      }

      if (this.mode === "edit") {
        formData.append("_method", "PUT")
        this.addedNewAudio === true ? formData.append("audio", this.fields.audio) : '';
        this.editCriteria({
          id: this.$route.params.id,
          criteriaId: this.fields.id,
          formData
        })
        .then(() => {
          window.location.reload();
        })
      }
    },

    async _removeCriteria() {
      let response = await this.showConfirmationMessage({
        title: this.$t('delete_criterion_question'),
        type: "badWarning",
        text: this.$t('delete_criterion_confirm')
      });

      if (response.isConfirmed) {
        this.removeCriteria({
          id: this.$route.params.id,
          criteriaId: this.fields.id,
        }).then(() => this.offcanvas.hide())
      }
    }
  }
}
</script>

