<template>
  <span
    v-if="text.length >= maxTextLength && !showingFullText"
    :class="{ 'text-decoration-line-through': isDeleted }"
    style="margin-right: 55px"
  >
    <span class="text-msg">
      {{ text.slice(0, TextLengthShow).trim() }}...
    </span>
    <span
      class="tx-blue-300 cursor-pointer fw-medium text-msg"
      @click="handleShowMore()"
      data-test="showMore-button"
    >
      {{ $t('see_more_uppercase') }}
    </span>
  </span>
  <span
    v-else-if="itsLinkTag"
    :class="{ 'text-decoration-line-through': isDeleted }"
    style="margin-right: 55px"
  >
    <a
      class="text-msg"
      :href="text"
      target="_blank"
    >
      {{ text }}
    </a>
  </span>
  <span
    v-else
    :class="{ 'text-decoration-line-through': isDeleted }"
    class="text-msg"
    style="margin-right: 55px"
  >
    {{ text }}
  </span>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },

    isDeleted: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      showingFullText: false,
      maxTextLength: 845,
      TextLengthShow: 768
    }
  },

  computed: {
    itsLinkTag(){
      let pattern = new RegExp(/^(https?:\/\/[^\s]+)/);
      return pattern.test(this.text)
    }
  },

  methods: {
    handleShowMore() {
      this.showingFullText = !this.showingFullText;
    }
  }

};
</script>

<style scoped lang="scss">
@media only screen and (max-width: 1199.999px) {
  .text-msg {
    font-size: 0.688rem;
  }
}
</style>
