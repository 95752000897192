<template>
  <div
    ref="handleOffCanvas"
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="offcanvasManageAccess"
    aria-labelledby="offcanvasManageAccessLabel"
    style="width: 702px !important;"
  >
    <div class="offcanvas-header d-block border-0 pb-0">
      <div
        class="d-flex align-items-center mb-4"
        style="height: 40px;"
      >
        <button
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          class="btn btn-rounded-circle btn-white float-start me-3 p-0 tx-text-200"
          style="width: 40px;height: 40px;"
        >
          <span class="fe fe-chevron-left" />
        </button>
        <h1 class="ms-2 mb-0 tx-text-200">
          {{ $t("manage_access") }}
        </h1>
      </div>
      <div>
        <alert-tip
          :button-close-alert="false"
          :text="$t('ips_exceptions')"
        />
      </div>
    </div>
    <div class="offcanvas-body">
      <div class="card">
        <div class="card-header">
          {{ $t("exceptions") }}
        </div>
        <div class="card-body">
          <p class="mb-0">
            {{ $t("users_capitalize") }}
          </p>
          <select-group
            v-if="isOffcanvasOpen"
            id="userAccess"
            label="name"
            :list="usersException"
            :message="$t('search_not_found')"
            :loading="loadingFlag['listOnlineUsers']"
            @select="listUser = $event"
          />
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button
          @click="updateUsers"
          class="btn btn-primary"
          style="width: 261px;"
        >
          {{ $t("capitalizes.save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Offcanvas } from "bootstrap";
import AlertTip from "@dialer/manager/pages/settings/users/components/ip-allowed/alert-tip.vue";
import { mapActions, mapGetters } from "vuex";
import SelectGroup from "@components/select-group.vue";

export default {
  components: { SelectGroup, AlertTip },
  data() {
    return {
      canvas: null,
      closeAlert: true,
      listUser: [],
      isOffcanvasOpen: false
    };
  },
  mounted() {
    this.listOnlineUsers({ allUser: true });
    this.canvas = new Offcanvas(this.$refs.handleOffCanvas);
    this.$refs.handleOffCanvas.addEventListener("hidden.bs.offcanvas", this.hideOffCanvas);
    this.$refs.handleOffCanvas.addEventListener("show.bs.offcanvas", this.showOffCanvas);
  },
  beforeUnmount() {
    this.$refs.handleOffCanvas.removeEventListener("hidden.bs.offcanvas", this.hideOffCanvas);
    this.$refs.handleOffCanvas.removeEventListener("show.bs.offcanvas", this.showOffCanvas);
  },
  computed: {
    ...mapGetters("manager/user", {
      onlineUser: "getOnlineUsers",
      offlineUser: "getOfflineUsers",
      loadingFlag: "getLoadingFlag"
    }),
    ...mapGetters("manager/filters", {
      filters: "getFilters"
    }),
    usersException() {
      const allUsers = this.onlineUser;
      const users = allUsers.map(user => {
        return {
          id: user.id,
          name: user.name,
          allowed_ip_exception: user.allowed_ip_exception,
          checked: user.allowed_ip_exception
        };
      });
      return users;
    }
  },
  methods: {
    ...mapActions("manager/user", ["updateUserIpAllowedVerification", "listOnlineUsers"]),
    openOffCanvas() {
      this.canvas.show();
    },
    updateUsers() {
      const list = this.listUser.map(user => user.id);
      const payload = {
        users_ids: list
      };
      this.updateUserIpAllowedVerification(payload).then(() => {
        this.listOnlineUsers({ allUser: true });
        this.canvas.hide();
      });
    },
    showOffCanvas() {
      this.isOffcanvasOpen = true;
    },
    hideOffCanvas() {
      this.isOffcanvasOpen = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
