import axios from "@crm/plugins/axios-config";

export default {
  createStep({commit}, {name, color, order, funnel_id}) {
    commit("startLoading", "createStep")
    return new Promise((resolve, reject) => {
      axios.post(`/funnel/${funnel_id}/steps`, {name, color, order})
        .then((response) => {
          commit('system/setSuccessMessage', "success_message.creat_crm_step", {root: true})
          commit("crm/funnels/addNewStep", response.data.data, {root: true})
          resolve(response.data.data)
        }).catch((e) => {
        if (e.response.data.errors['invalid-step-name']) {
          commit('system/setErrorMessage', e.response.data.errors['invalid-step-name'][0], {root: true})
        } else {
          commit('system/setErrorMessage', 'errors_message.create_crm_step', {root: true})
        }
        reject()
      }).finally(() => {
        commit("stopLoading", "createStep")
      })
    })
  },

  updateStep({commit}, {funnel_id, name, color, order, step_id}) {
    return new Promise((resolve, reject) => {
      axios.put(`/funnel/${funnel_id}/steps/${step_id}`, {name, order, color})
        .then((response) => {
          commit('crm/funnels/updateStepFunnel', {step: response.data.data}, {root: true})
          commit('system/setSuccessMessage', "success_message.update_crm_step", {root: true})
          resolve()
        }).catch(() => {
        commit('system/setErrorMessage', 'errors_message.update_crm_step', {root: true})
        reject()
      })
    })
  },

  moveSteps({commit}, {funnel_id, step_id, addedIndex, removedIndex}) {
    return new Promise((resolve, reject) => {
      axios.post(`/funnels/${funnel_id}/steps/${step_id}/order/${addedIndex}/move`)
        .then(() => {
          commit('crm/funnels/moveStepFunnel', {addedIndex, removedIndex}, {root: true})
          resolve()
        }).catch((response) => {
        commit('system/setErrorMessage', response.response.data, {root: true})
        reject()
      })
    })
  },

  deleteStep({commit}, {step_id, funnel_id}) {
    axios.delete(`/funnel/${funnel_id}/steps/${step_id}`)
      .then(() => {
        commit('crm/funnels/updateDeleteStepFunnel', {step_id}, {root: true})
        commit('system/setSuccessMessage', "success_message.delete_crm_step", {root: true})
      })
      .catch((errors) => {
        if (errors.response.data.errors['invalid-step']) {
          commit('system/setErrorMessage', 'errors_message.cannot_delete_step', {root: true})
        } else {
          commit('system/setErrorMessage', 'errors_message.delete_crm_step', {root: true})
        }
      })
  }
}
