export default {
  startLoading: (state, flag) => state.loadingFlags[flag] = true,
  stopLoading: (state, flag) => state.loadingFlags[flag] = false,

  setErrors: (state, {errorFlag, errors}) => state.errors[errorFlag] = errors,
  clearErrors: (state) => {
    state.errors = {
      createActivity: {},
      updateActivity: {},
      createNote: {}
    }
    state.errorMessage = ""
  },

  setPendingActivities: (state, pendingActivities) => state.pendingActivities = pendingActivities,
  setPendingActivitiesPagination: (state, pendingActivitiesPagination) => state.pendingActivitiesPagination = pendingActivitiesPagination,
  setPendingActivitiesCurrentPage: (state, currentPage) => state.pendingActivitiesPagination.current_page = currentPage,
  setPendingActivitiesFilter: (state, filter) => {
    filter.name ?
      state.pendingActivitiesFilters[filter.name] = filter.value
      : state.pendingActivitiesFilters = filter
  },

  setPendingActivitiesOfDay: (state, pendingActivitiesOfDay) => state.pendingActivitiesOfDay = pendingActivitiesOfDay,
  setPendingActivitiesOfDayPagination: (state, pendingActivitiesOfDayPagination) => state.pendingActivitiesOfDayPagination = pendingActivitiesOfDayPagination,
  setPendingActivitiesOfDayCurrentPage: (state, currentPage) => state.pendingActivitiesOfDayPagination.current_page = currentPage,
  setPendingActivitiesOfDayFilter: (state, filter) => {
    filter.name ?
      state.pendingActivitiesOfDayFilters[filter.name] = filter.value
      : state.pendingActivitiesOfDayFilters = filter
  },

  setFutureActivities: (state, futureActivities) => state.futureActivities = futureActivities,
  setFutureActivitiesPagination: (state, futureActivitiesPagination) => state.futureActivitiesPagination = futureActivitiesPagination,
  setFutureActivitiesCurrentPage: (state, currentPage) => state.futureActivitiesPagination.current_page = currentPage,
  setFutureActivitiesFilter: (state, filter) => {
    filter.name ?
      state.futureActivitiesFilters[filter.name] = filter.value
      : state.futureActivitiesFilters = filter
  },

  setStatisticsActivitiesFilters: (state, filter) => {
    filter.name ?
      state.statisticsActivitiesFilters[filter.name] = filter.value
      : state.statisticsActivitiesFilters = filter
  },

  setStatisticsActivities: (state, statisticsActivities) => state.statisticsActivities = statisticsActivities,

  setPinNotes: (state, pinNotes) => state.pinNotes = pinNotes,
  setActivityCard: (state, activity) => state.activityCard = activity,
  setOpportunities: (state, opportunities) => state.opportunities = opportunities,
  setOpportunitiesPagination:(state, pagination) => state.opportunityPagination = pagination,
  setOpportunityFilters: (state, {filter, value}) => state.opportunityFilters[filter] = value,
}
