<template>
  <main-loading v-if="!asyncReady" />
  <div
    class="col-12"
    v-if="asyncReady"
  >
    <div>
      <header-component
        :pretitle="$t('uppercases.receptive_numbers')"
        :title="fields.name"
        redirect-link="/manager/receptive-numbers"
      />
      <div class="card">
        <div class="card-header">
          <h4 class="header-title">
            {{ $t("reference") }}
          </h4>
        </div>
        <div class="card-body">
          <div class="row p-2">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <div class="col">
                  <label class="form-label">
                    {{ $t("acronym.did") }}
                  </label>
                </div>

                <div class="input-group input-group-merge">
                  <input
                    class="form-control"
                    type="text"
                    :value="fields.did"
                    disabled
                  >
                  <span class="input-group-text lock-icon">
                    <i class="fe fe-lock" />
                  </span>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="row">
                <div class="col">
                  <label class="form-label">
                    {{ $t("capitalizes.name") }}
                  </label>
                </div>
              </div>
              <div class="input-group input-group-merge">
                <input
                  class="form-control"
                  type="text"
                  :value="fields.name"
                  disabled
                >
                <span class="input-group-text lock-icon">
                  <i class="fe fe-lock" />
                </span>
              </div>
            </div>

            <div class="col-12 ">
              <label class="form-label required">
                {{ $t("capitalizes.service_hours") }}
              </label>
              <vue-multiselect
                v-model="fields.receptive_hours"
                track-by="id"
                label="name"
                class="index-select"
                :selected-label="$t('selected')"
                :options="list.hours"
                :placeholder="$t('select_service_hours')"
                :select-label="$t('click_or_press_enter_to_select')"
                :deselect-label="$t('click_or_press_enter_to_remove')"
              />
            </div>
          </div>
        </div>
        <div class="my-4 p-0 mx-0">
          <h4 class="mx-4">
            {{ $t("out_service_hours_audio") }}
          </h4>
          <hr>
          <div class="mx-4">
            <p>
              {{ $t("audio_played_after_service_hours") }}
            </p>
            <div
              :class="{
                'is-invalid': errors.audio
              }"
            >
              <div>
                <div
                  class="text-center my-1 text-muted"
                  v-if="fields?.receptive_hours?.original_audio_name && !hideAudio"
                >
                  <small> {{ fields.receptive_hours.original_audio_name }}</small>
                  <audio
                    controls
                    class="w-100 mb-4"
                  >
                    <source
                      :src="baseURL + fields.receptive_hours.audio + '&api_token=' + user.api_token"
                      type="audio/mpeg"
                    >
                  </audio>
                  <span
                    class="btn p-0 px-4 tx-red-300"
                    @click="removeAudio()"
                  >
                    <span class="fe fe-trash-2 me-1" />
                    {{ $t("exclude_audio_capitalize") }}
                  </span>
                </div>
                <file-upload
                  v-else
                  @select-file="selectFile"
                  @removed-file="() => (fields.audio = undefined)"
                  icon="fe fe-headphones"
                  filter="audio/mp3,audio/mpeg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <h4 class="card-header-title">
            {{ $t("referrals") }}
          </h4>
        </div>

        <div class="card-body">
          <div class="row gap-4 mt-5">
            <div class="col-lg-4 row align-items-center d-flex mb-5 border-end cursor-pointer">
              <input
                v-model="fields.role"
                value="none"
                class="form-check-input ms-5 me-4"
                type="radio"
                id="none"
                :class="'pe-0'"
              >
              <div
                @click="updateRole('none')"
                class="col-10 col-xl cursor-pointer"
              >
                <h4>
                  {{ $t("capitalizes.none") }}
                </h4>
                <span class="text-muted d-block fs-6">
                  {{ $t("incoming_calls_will_not_be_forwarded") }}
                </span>
              </div>
            </div>

            <div class="col-lg-4 row align-items-center d-flex mb-5 border-end cursor-pointer">
              <input
                v-model="fields.role"
                value="ura"
                class="form-check-input ms-5 me-4"
                type="radio"
                id="ura"
                :class="'pe-0'"
              >

              <div
                @click="updateRole('ura')"
                class="col-10 col-xl cursor-pointer"
              >
                <h4>
                  {{ $t("ivr_uppercase") }}
                </h4>
                <span class="text-muted d-block fs-6">
                  {{ $t("incoming_calls_forwarded_to_selected_address") }}
                </span>
              </div>
            </div>

            <div class="col-lg-4 d-flex row align-self-center align-items-center mb-5 border-end cursor-pointer">
              <input
                v-model="fields.role"
                value="ringGroup"
                class="form-check-input ms-5 me-4 pe-0"
                type="radio"
                id="ringGroup"
              >

              <div
                @click="updateRole('ringGroup')"
                class="col-12 col-xl cursor-pointer"
              >
                <h4>
                  {{ $t("extensions_group") }}
                </h4>
                <span class="text-muted text-small d-block">
                  {{ $t("incoming_calls_forwarded_to_selected_group") }}
                </span>
              </div>
            </div>

            <div class="col-lg-4 d-flex row align-self-center align-items-center mb-5 cursor-pointer border-end">
              <input
                v-model="fields.role"
                value="receptiveQueues"
                class="form-check-input ms-5 me-4 pe-0"
                type="radio"
                id="receptiveQueues"
              >

              <div
                @click="updateRole('receptiveQueues')"
                class="col-12 col-xl cursor-pointer"
              >
                <h4>
                  {{ $t("receptive_queue_capitalize") }}
                </h4>
                <span class="text-muted text-small d-block">
                  {{ $t("incoming_calls_forwarded_to_selected_queue") }}
                </span>
              </div>
            </div>

            <div class="col-lg-4 d-flex row align-self-center align-items-center mb-5 cursor-pointer">
              <input
                v-model="fields.role"
                value="callback"
                class="form-check-input d-flex ms-5 me-4 pe-0 "
                type="radio"
                id="callback"
              >

              <div
                @click="updateRole('callback')"
                class="col-12 col-xl cursor-pointer"
              >
                <h4>
                  {{ $t("callback") }}
                </h4>
                <span class="text-muted text-small d-block">
                  {{ $t("incoming_calls_forwarded_to_selected_campaign") }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          class="card-footer"
          v-if="fields.role !== 'none'"
        >
          <div v-show="fields.role == 'ura'">
            <label class="form-label required">
              {{ $t("select_ivr") }}
            </label>
            <select-receptive-ivr
              v-model="fields.receptive_ivr"
              :errors="errors?.receptiveNumber?.receptive_ivr_id"
            />
          </div>

          <div v-show="fields.role == 'ringGroup'">
            <label class="form-label required">
              {{ $t("select_extension_group") }}
            </label>
            <select-ring-group
              v-model="fields.ring_group"
              :only-active-groups="true"
            />
          </div>

          <div v-show="fields.role == 'receptiveQueues'">
            <label class="form-label required">
              {{ $t("select_receptive_queue") }}
            </label>
            <select-receptive-queues v-model="fields.receptive_queue" />
          </div>
          <div v-show="fields.role == 'callback'">
            <label class="form-label required">
              {{ $t("campaign_select") }}
            </label>
            <select-campaign
              v-model="fields.campaign"
              :errors="errors?.receptiveNumber?.campaign_id"
              :only-active-campaigns="true"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <app-button
        style="z-index: 3;"
        class="fw mb-4"
        :loading="loading"
        @click="submit"
        :disabled="isDisabledButton"
      >
        {{ $t("capitalizes.save") }}
      </app-button>
    </div>

    <div>
      <button
        class="btn col-12"
        @click="$router.push('/manager/receptive-numbers')"
      >
        <h4 style="font-weight: 100;">
          {{ $t("back_capitalize") }}
        </h4>
      </button>
    </div>
    <div style="padding: 40px;" />
  </div>
</template>

<script>
import fileUpload from "@/components/file-uploader";
import appButton from "@/components/app-button.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import mainLoading from "@/components/main-loading.vue";
import SelectCampaign from "@dialer/manager/components/select-campaign.vue";
import SelectReceptiveIvr from "@dialer/manager/components/select-receptive-ivr.vue";
import SelectReceptiveQueues from "@dialer/manager/components/select-receptive-queues.vue";
import SelectRingGroup from "@dialer/manager/components/select-ring-group.vue";
import VueMultiselect from "vue-multiselect";
import HeaderComponent from "@/components/header-component.vue";

export default {
  data() {
    return {
      asyncReady: false,
      hideAudio: false,
      hideCallbackAudio: false,
      fields: {
        name: "",
        did: "",
        role: "none",
        receptive_ivr: "",
        ring_group: "",
        receptive_queue: "",
        campaign: "",
        receptive_hours: null,
        audio: undefined,
        warning_audio: undefined,
        warning_audio_delete: 0,
        audio_delete: 0,
      },
    };
  },

  components: {
    appButton,
    mainLoading,
    fileUpload,
    SelectCampaign,
    SelectReceptiveQueues,
    SelectReceptiveIvr,
    VueMultiselect,
    HeaderComponent,
    SelectRingGroup,
  },

  computed: {
    ...mapGetters("manager/receptive_numbers", {
      register: "getRegister",
      loading: "isLoading",
      errors: "getErrorsFlag",
      list: "getList",
    }),
    ...mapGetters("auth", {
      user: "getUser",
    }),

    isDisabledButton() {
      const isAnyForwardingSelected = [
        this.fields.role == "none",
        this.fields.role == "ura" && !!this.fields.receptive_ivr?.id,
        this.fields.role == "ringGroup" && !!this.fields.ring_group,
        this.fields.role == "receptiveQueues" && !!this.fields.receptive_queue,
        this.fields.role == "callback" && !!this.fields.campaign,
      ];

      const isReceptiveHoursSelected = !!this.fields.receptive_hours?.id;

      return !isAnyForwardingSelected.includes(true) || !isReceptiveHoursSelected;
    },

    baseURL() {
      return process.env.VUE_APP_BA12AS.replace("/api/v1", "");
    },
  },
  methods: {
    ...mapMutations("manager/receptive_numbers", ["setRegister"]),
    ...mapActions("manager/receptive_numbers", ["update", "create", "remove"]),
    ...mapActions("system", ["showConfirmationMessage"]),

    submit() {
      this.errorMessage = "";

      const formData = new FormData();

      if (this.fields.audio) formData.append("audio", this.fields.audio);

      formData.append("did", this.fields.did);
      formData.append("receptive_hours_id", this.fields.receptive_hours.id);
      formData.append("audio_delete", this.fields.audio_delete);

      if (this.fields.role == "callback") {
        formData.append("campaign_id", this.fields.campaign.id || this.fields.campaign);
        this.fields.warning_audio && formData.append("warning_audio", this.fields.warning_audio);
        formData.append("warning_audio_delete", this.fields.warning_audio_delete);
        formData.append("forwarding_type", "callback");
      } else if (this.fields.role == "ura") {
        formData.append("receptive_ivr_id", this.fields.receptive_ivr.id);
        formData.append("forwarding_type", "ura");
      } else if (this.fields.role == "receptiveQueues") {
        formData.append("receptive_queue_id", this.fields.receptive_queue.id);
        formData.append("forwarding_type", "receptive_queue");
      } else if (this.fields.role == "ringGroup") {
        formData.append("ring_group_id", this.fields.ring_group.id || this.fields.ring_group);
        formData.append("forwarding_type", "ring_group");
      }

      if (!this.register.settings) {
        this.create({ fields: formData })
            .then(() => {
              this.$router.push({
                path: `/manager/receptive-numbers`,
              });
            })
            .catch(data => {
              this.errorMessage = data.message;
            });
        return;
      }

      this.update(formData)
          .then(() => {
            this.$router.push({
              path: `/manager/receptive-numbers`,
            });
          })
          .catch(data => {
            this.errorMessage = data.message;
          });
    },

    async remove_() {
      let response = await this.showConfirmationMessage({
        title: this.$t("delete_receptive_confirmation"),
        text: `${this.$t("only_receptive_config")} <strong>${this.register.did}</strong> ${this.$t(
            "receptive_will_not_be_excluded",
        )}`,
        type: "badWarning",
      });

      if (response.isConfirmed) {
        this.remove({
          did: this.register.did,
        })
            .then(() => {
              this.$router.push({
                path: `/manager/receptive-numbers`,
              });
            })
            .catch(data => {
              this.errorMessage = data.message;
            });
      }
    },

    updateRole(el) {
      this.fields.role = el;
    },

    async awaitDispatches() {
      await this.$store.dispatch("manager/receptive_numbers/findById", {
        id: this.$route.params.id,
      });
      await this.$store.dispatch("manager/receptive_numbers/listFilters");
    },

    selectFile(file) {
      this.fields.audio = file;
    },

    removeAudio() {
      this.fields.audio = undefined;
      this.fields.audio_delete = 1;
      this.hideAudio = true;
    },
  },
  async mounted() {
    try {
      await this.awaitDispatches();

      const { name, did, settings } = this.register;
      this.fields.name = name;
      this.fields.did = did;
      this.fields.receptive_hours = settings ? settings.receptive_hours : null;

      const fieldMapping = {
        receptive_ivr: "ura",
        ring_group: "ringGroup",
        receptive_queue: "receptiveQueues",
        callback: "callback",
      };

      for (const [key, role] of Object.entries(fieldMapping)) {
        if (settings && settings[key]) {
          this.fields.role = role;
          if (role === "callback") {
            this.fields.campaign = {
              id: settings.callback.campaign.id,
              name: settings.callback.campaign.name,
            };
          } else {
            this.fields[key] = {
              id: settings[key].id,
              name: settings[key].name,
            };
          }
          return;
        }
      }

      this.fields.role = "none";
    } catch (error) {
      console.error("Error in mounted lifecycle hook:", error);
    } finally {
      this.asyncReady = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.lock-icon {
  border-color: #e6ecf5;
  color: $color-blue-300;
}

.index-select {
  z-index: 1000 !important;
}

</style>
