<template>
  <span
    ref="tooltipComponent"
    class="ms-1"
  >
    <i :class="`${icon} ${color}`" />
  </span>
</template>

<script>
import { Tooltip } from 'bootstrap';

export default {
  name: "TooltipComponent",

  data() {
    return {
      tooltipComponent: null
    };
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: "fa-regular fa-circle-info"
    },
    placement: {
      type: String,
      default: "top"
    },
    color: {
      type: String,
      default: "text-primary"
    },
    width: {
      type: String,
      default: '650'
    },
    truncateLine: {
      type: String,
      default: null
    }
  },
  mounted() {
    let tooltip = document.createElement("div");
    if(this.truncateLine){
      tooltip.innerHTML = `
            <div class="text-medium truncating-text" style="
            max-width: ${this.width}px; display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: ${this.truncateLine};
            overflow: hidden;
            padding: 8px 8px 2px;">
              ${this.title}
            </div>`
    }else {

      tooltip.innerHTML = `
            <div class="text-medium" style="max-width: ${this.width}px; padding: 8px;">
              ${this.title}
            </div>`
    }

    this.tooltipComponent = new Tooltip(this.$refs.tooltipComponent, {
      html: true,
      placement: this.placement,
      title: tooltip,
    })
  }
}
</script>
