<template>
  <div class="main-content">
    <div class="container-fluid">
      <div class="col-12 col-lg-12 col-xl-12">
        <header-component
          title="Tags"
        >
          <template #buttons>
            <button
              class="btn btn-primary px-6"
              @click="openTagsModal()"
            >
              Adicionar nova tag
            </button>
            <add-tags
              ref="addTagsModal"
            />
          </template>
        </header-component>
        <div class="card">
          <div class="card-header">
            <search-input
              @search="search"
            />
          </div>
          <div class="card-body">
            <div
              class="row"
            >
              <div
                class="col-xl-3 col-lg-3 col-md-3 col-sm-3"
              >
                <div
                  class="card card-new"
                >
                  <div
                    class="card-body cursor-pointer"
                    @click="openTagsModal()"
                  >
                    <div class="row align-items-center gx-0">
                      <div
                        class="col overflow-ellipsis"
                        style="line-height: 36.19px"
                      >
                        <span class="h4 mb-0"> Adicionar nova tag </span>
                      </div>
                      <div class="col-auto">
                        <span class="h2 fe fe-plus text-primary mb-0" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-xl-3 col-lg-4 col-md-4"
                v-if="loadingFlag.findTags"
              >
                <div class="card-body text-center">
                  <div class="spinner-border text-muted">
                    <span class="visually-hidden" />
                  </div>
                </div>
              </div>
              <add-tags
                ref="addTagsModal"
              />
              <div
                class="col-xl-3 col-lg-3 col-md-3 col-sm-3"
                v-for="(item, i) in list"
                :key="i"
              >
                <div
                  class="card card-list-item"
                  style="cursor: pointer"
                  @click="editTagsModal(item)"
                >
                  <div class="card-body">
                    <div class="row align-items-center gx-0">
                      <div class="col overflow-ellipsis one-line d-flex flex-column">
                        <span class="h4 mb-0">
                          {{ item.name }}
                        </span>
                      </div>
                      <div class="col-auto">
                        <span class="h2 fe fe-chevron-right text-primary mb-0" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <paginator
                :pagination="pagination"
                @change-page="changePage"
                v-if="!loadingFlag.findTags"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import _ from "lodash";
import AddTags from "./components/add-tags.vue";
import HeaderComponent from "@/components/header-component.vue";
import paginator from "@components/app-paginator";
import SearchInput from "@components/search-input";

export default {
  components: {
    AddTags,
    HeaderComponent,
    paginator,
    SearchInput,
  },
  mounted() {
    this.findTags();
  },
  computed: {
    ...mapGetters("admin/tags", {
      list: "getList",
      loadingFlag: "getLoadingFlags",
      pagination: "getPagination",
    }),
  },
  methods: {
    ...mapActions("admin/tags", ["findTags", "setCurrentPage", "setFilters"]),
    ...mapMutations("admin/tags", ["clearList"]),

    search: _.debounce(function (text) {
      this.clearList();
      this.setCurrentPage(1);
      this.setFilters({ search: text });
      this.findTags();
    }, 500),

    openTagsModal() {
      this.$refs.addTagsModal.new();
    },
    editTagsModal(item) {
      this.$refs.addTagsModal.edit(item);
    },

    changePage(page) {
      this.setCurrentPage(page);
      this.findTags();
    },
  },
}
</script>
