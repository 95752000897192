<template>
  <div
    :class="{
      'file-preview-active': file,
      'agent-top-bar-active': isAgent
    }"
    class="d-flex flex-column h-100"
    @dragenter="dragenter"
    @mouseenter="cursorInChat = true"
    @mouseleave="cursorInChat = false"
  >
    <top-bar />
    <div
      class="col d-flex flex-column-reverse overflow-auto position-relative"
      style="padding: 1rem; gap: 0.25rem;"
      ref="panel"
      id="panel"
      data-test="chatPanel-div"
      @scroll="scroll"
    >
      <div v-if="showPreviousLoading">
        <message-loading class="mx-auto mb-3r" />
      </div>
      <template v-if="!currentChatMessagesForSearch">
        <template
          v-for="(dayMessages, day) in currentChatMessages"
          :key="day"
        >
          <whatsapp-message
            v-for="(msg, index) in dayMessages"
            :key="msg.internal_id"
            :msg="msg"
            :previous-msg="dayMessages[index +1] || {}"
            :locked-send-message="lockedSendMessage"
            :company-domain="companyDomain"
            @quote="quote => quoteMessage = currentChatMessages[quote.day].find(msg => msg.internal_id === quote.id)"
            @resend="tryResendFailedMessage"
            @open-modal="$refs.forwardedModal.open(msg)"
          />
          <show-note
            class="text-uppercase my-4"
            :gray="true"
          >
            {{ formatedDay(day) }}
          </show-note>
        </template>
      </template>
      <template
        v-for="(dayMessages, day) in currentChatMessagesForSearch"
        :key="day"
      >
        <whatsapp-message
          v-for="(msg, index) in dayMessages"
          :key="msg.id"
          :msg="msg"
          :previous-msg="dayMessages[index +1] || {}"
          :locked-send-message="lockedSendMessage"
          :company-domain="companyDomain"
          @quote="quote => quoteMessage = currentChatMessagesForSearch[quote.day].find(msg => msg.internal_id === quote.id)"
          @resend="tryResendFailedMessage"
          @open-modal="$refs.forwardedModal.open(msg)"
        />
        <show-note
          class="text-uppercase my-4"
          :gray="true"
        >
          {{ formatedDay(day) }}
        </show-note>
      </template>
      <show-note
        v-if="showClickForNextPage"
        class="cursor-pointer"
        :gray="true"
        @click="loadSearchNextPage"
      >
        Clique neste aviso para carregar mensagens mais antigas.
        <div
          v-if="loadingSearchNextPage"
          class="spinner-border spinner-border-sm tx-blue-300"
        />
      </show-note>
      <div v-if="showNextLoading">
        <message-loading class="mx-auto" />
      </div>
    </div>
    <div class="position-relative">
      <transition name="scale">
        <div
          v-if="showScrollBottom || currentChatMessagesPaginationForSearch"
          class="position-absolute btn-position"
        >
          <div class="position-relative">
            <app-button
              :color="'btn-white-blue'"
              @click="scrollToBotton"
            >
              <span class="far fa-chevron-down" />
            </app-button>
            <div
              v-if="currentChat.unread"
              class="position-absolute text-center text-medium px-1 unread unread-message"
            >
              {{ currentChat.unread }}
            </div>
          </div>
        </div>
      </transition>
      <info-chat-card
        v-if="infoChatCard"
        :img="infoChatCard.img"
        :title="infoChatCard.title"
        :subtitle="infoChatCard.subtitle"
        class="mb-3"
      />
      <remove-group v-if="currentChat.isReadOnly" />
      <chatbot-chat-options v-if="currentChat.chatbot_active" />
      <start-chat
        v-else-if="showStartChat"
        :chat-data="finalizedByManager.id === currentChatId ? finalizedByManager : currentChat"
      />
      <message-input
        v-else-if="!currentChat.isReadOnly"
        :is-locked-send="lockedSendMessage"
        :quote-message="quoteMessage"
        @send-template="sendTemplateMessage"
        @send-text="sendTextMessage"
        @send-internal-note="sendInternalNote"
        @set-file="_file => file = _file"
        @send-voice="sendVoiceMessage"
        @delete-quote="quoteMessage = null"
      />
    </div>
  </div>
  <dragable-area
    v-if="showDragableArea"
    @leave="showDragableArea = false"
    @send-file="_file => file = _file"
  />
  <file-preview
    v-if="file"
    :file="file"
    @close="file = null"
    @send-image="sendImageMessage"
    @send-video="sendVideoMessage"
    @send-audio="sendAudioMessage"
    @send-document="sendDocumentMessage"
  />
  <forward-message
    ref="forwardedModal"
    @send-forward="sendForwardMessage"
  />
</template>

<script>
import moment from "moment/moment";
import { formatMessageDay } from "@/utils"
import { mapActions, mapGetters, mapMutations } from "vuex";
import { messagesOmnichannel } from "@/mixins/messagesOmnichannel";

import {Fancybox} from "@fancyapps/ui";
import DragableArea from "@whatsapp/components/dragable-area.vue";
import FilePreview from "@whatsapp/components/file-preview.vue";
import ForwardMessage from "@whatsapp/components/forward-message.vue";
import TopBar from "@whatsapp/components/top-bar.vue";
import ShowNote from "@whatsapp/components/messages-types/show-note.vue";
import InfoChatCard from "@whatsapp/components/info-chat-card.vue";
import StartChat from "@whatsapp/components/start-chat.vue";
import ChatbotChatOptions from "@whatsapp/components/chatbot-chat-options.vue";
import RemoveGroup from "@whatsapp/components/remove-group.vue";
import MessageInput from "@whatsapp/components/message-input/message-input.vue";
import WhatsappMessage from "@whatsapp/components/whatsapp-message.vue";
import MessageLoading from "@whatsapp/components/messages-types/message-loading.vue";
import AppButton from "@components/app-button.vue";

export default {
  mixins: [messagesOmnichannel],

  components: {
    AppButton,
    MessageLoading,
    WhatsappMessage,
    MessageInput,
    RemoveGroup,
    ChatbotChatOptions,
    StartChat,
    InfoChatCard,
    ShowNote,
    TopBar,
    ForwardMessage,
    FilePreview,
    DragableArea
  },

  data() {
    return {
      companyDomain: "",
      cursorInChat: false,
      file: null,
      loadingNextPage: false,
      loadingSearchPreviousPage: false,
      loadingSearchNextPage: false,
      quoteMessage: null,
      showDragableArea: false,
      showScrollBottom: false,
      SHOW_SCROLL_BOTTOM_HEIGHT: -400
    };
  },

  async mounted() {
    window.addEventListener("paste", this.paste);
    Fancybox.bind("[data-fancybox]", {
      groupAll: true
    });
    this.getCompanyDomain();

    if (this.isAgent || this.isSupervisor) {
      const ids = this.user.teams.map(team => team.id)
      this.fetchFunnels(ids);
    }
    else {
      this.fetchFunnels();
    }

    await this.initialMessagesLoading()
    const scrollY =  this.currentChat.states.scrollY
    if (scrollY >= this.SHOW_SCROLL_BOTTOM_HEIGHT && !this.currentChatMessagesForSearch && this.isOwner) {
      this.updateUnreadIndicators()
    }
    this.$refs.panel.scrollTo(0, scrollY);
  },

  beforeUnmount() {
    Fancybox.unbind();
    window.removeEventListener("paste", this.paste);
  },

  beforeRouteUpdate() {
    if (this.$refs.panel){
      this.setChatState({
        chatId: this.currentChatId,
        chatState: "scrollY",
        value: this.$refs.panel.scrollTop,
      })
    }
  },

  beforeRouteLeave() {
    if (this.$refs.panel){
      this.setChatState({
        chatId: this.currentChatId,
        chatState: "scrollY",
        value: this.$refs.panel.scrollTop,
      })
    }
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser",
      isAgent: "isAgent",
      isSupervisor: "isSupervisor",
      inImpersonate: "inImpersonate",
    }),
    ...mapGetters("whatsapp/chat", {
      transferedByManager: "getTransferedByManager",
      finalizedByManager: "getFinalizedByManager",
      snoozedByManager: "getSnoozedByManager",
      openChat: "getOpenChat"
    }),
    ...mapGetters("whatsapp/message", [
      "getStashedMessages",
      "getMessagesForSearch",
      "getPaginationsForSearch",
      "getNewMessage",
    ]),
    isOwner() {
      return this.currentChat.agent_id === this.user.id && !this.inImpersonate
    },
    newMessage() {
      return this.getNewMessage
    },

    currentChatStashedMessages() {
      return this.getStashedMessages(this.$route.params.id);
    },
    currentChatMessagesForSearch() {
      return this.getMessagesForSearch(this.$route.params.id);
    },
    currentChatMessagesPaginationForSearch() {
      return this.getPaginationsForSearch(this.$route.params.id);
    },

    showClickForNextPage() {
      if (!this.currentChatMessagesPaginationForSearch){
        return false
      }
      const {current_page, total_pages, context } = this.currentChatMessagesPaginationForSearch.next
      return current_page < total_pages || context === "current"
    },
    showNextLoading() {
      if (this.currentChatMessagesPaginationForSearch) {
        return false
      }
      if (!this.currentChatMessagesPagination) {
        return true
      }
      const {current_page, total_pages, context } = this.currentChatMessagesPagination
      return context === "current" || current_page < total_pages
    },
    showPreviousLoading() {
      return this.currentChatMessagesPaginationForSearch
    },

    expiredCycle() {
      return this.currentChat?.waba_message_received?.end_message_cycle < Date.now() / 1000 || !this.currentChat?.waba_message_received?.end_message_cycle;
    },

    infoChatCard() {
      if (this.transferedByManager?.id === this.$route.params.id) {
        return {
          img: require("@assets/img/transferedChat.svg"),
          title: this.$t('transferred_chat.title'),
          subtitle: this.$t('transferred_chat.subtitle')
        }
      }
      if (this.finalizedByManager?.id === this.$route.params.id) {
        return {
          img: require("@assets/img/transferedChat.svg"),
          title: this.$t('finalized_chat.title'),
          subtitle: this.$t('finalized_chat.subtitle')
        }
      }
      if (this.snoozedByManager.id === this.$route.params.id || this.currentChat.in_snooze) {
        return {
          img: require("@assets/img/snoozedChat.svg"),
          title: this.$t('snooze_chat.title'),
          subtitle: this.$t('snooze_chat.subtitle')
        }
      }
      if (this.currentChat.by_active_ivr && !this.currentChat.agent_id) {
        return {
          img: require("@assets/img/aloisio/Pluzinha_Espera.svg"),
          title: 'Cliente incluso na fila via URA Receptiva.',
          subtitle: null
        }
      }
      if (this.currentChat.states.channel.type === "waba" && !this.currentChat.finished){

        if (this.expiredCycle) {
          return {
            img: require("@assets/img/plusinha-waba.svg"),
            title: this.$t('waba_start_chat_template_request'),
            subtitle: null
          }
        }

        if (!this.currentChat.waba_message_received.message_received) {
          return {
            img: require("@assets/img/plusinha-waba.svg"),
            title: `<div class="fs-5" >${this.$t('waba_after_template_send_message')}</div>`,
            subtitle: null
          }
        }
      }
      return null
    },

    lockedSendMessage (){
      const isGroup = this.currentChat.is_group
      const wabaIsSelected = this.channelTypeSelected && this.channelTypeSelected.type === 'waba'

      return !isGroup && wabaIsSelected && this.expiredCycle
    },

    showStartChat() {
      if (this.currentChat.is_group) {
        return false;
      }
      if (this.finalizedByManager.id === this.$route.params.id) {
        return true;
      }
      if (!this.currentChat.agent_id) {
        return true;
      }
      else if (this.currentChat.finished) {
        return true;
      }
      return false;
    },
  },

  watch: {
    currentChatMessagesPaginationForSearch(val) {
      this.setShowScrollBottom(!!val)
    },

    newMessage(message) {
      if (!this.showScrollBottom && message?.chat_id === this.currentChatId) {
        this.scrollToBotton()
      }
    },

    currentChatMessagesPagination(val) {
      if (!val) {
        this.initialMessagesLoading()
      }
    },
  },

  methods: {
    formatMessageDay,
    ...mapMutations("whatsapp/message", ["clearChatSearchMessages"]),
    ...mapMutations("whatsapp/chat", [
      "decrementUnreadConversation",
      "decrementUnreadGroupsConversation",
      "setChatState",
      "setOpenChat",
    ]),
    ...mapMutations("whatsapp/waba", ["setSelectedMessageTemplate"]),
    ...mapActions("whatsapp/chat", [
      "fetchVerifyNotifiedChats",
      "clearUnreadMessageCount",
    ]),
    ...mapActions("whatsapp/message", [
      "sendImage",
      "sendVideo",
      "sendAudio",
      "sendDocument",
      "sendMessage",
      "sendVoice",
      "sendForward",
      "sendInternal",
      "fetchSecondPageForInitialLoading",
      "mergeSearchMessagesToMessages",
      "addRecentMessage",
      "removeMessageById",
    ]),
    ...mapActions("whatsapp/waba", ["sendMessageTemplates"]),
    ...mapActions("whatsapp/crm", ["fetchFunnels"]),

    formatedDay(day) {
      const formatedDay = this.formatMessageDay(day)
      const isTranslatable = ["today", "yesterday"].includes(formatedDay) || formatedDay.startsWith("days_of_week")
      return isTranslatable? this.$t(formatedDay) : formatedDay
    },

    generateTempId() {
      return (
        moment().unix() +
        "_" +
        Math.random()
          .toString(16)
          .slice(2)
      );
    },

    getCompanyDomain() {
      let arr = window.location.host.split(".");
      let subdomain = arr[0];
      this.companyDomain = process.env.VUE_APP_BA12AS.replace(
        "app",
        subdomain
      ).replace("/api/v1", "");
    },

    paste(e) {
      if (e.clipboardData.files.length) {
        this.file = e.clipboardData.files[0];
      }
    },

    scrollToBotton() {
      if (this.currentChatMessagesPaginationForSearch) {
        this.$refs.panel.scrollTo(0,0)
        this.clearChatSearchMessages(this.currentChatId)
      }
      this.$nextTick(()=> {
        this.$refs.panel.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      })
    },

    sendImageMessage(text) {
      const tempId = this.generateTempId();
      const tempTime = moment().unix()

      let tempMessage = {
        id: tempId,
        internal_id: tempId,
        ack: null,
        agent_id: this.currentChat.agent_id,
        backupToTryResend: this.file,
        body: text,
        chat_id: this.currentChatId,
        failed: false,
        fromMe: true,
        media: URL.createObjectURL(this.file),
        time: tempTime,
        type: 'image',
        instance: {
          type: this.currentChat.states.channel.type
        },
        agent: {
          id: this.user.id,
          name: this.user.name
        }
      };

      let formData = new FormData();
      formData.append("chat_id", this.currentChatId);
      formData.append('image', this.file);
      formData.append("instance_id", this.currentChat.states.channel.id);
      formData.append("caption", text);
      formData.append("body", text);

      if (this.quoteMessage) {
        tempMessage["quoted_msg"] = {
          id: this.quoteMessage.id,
          body: this.quoteMessage.body,
          media: this.quoteMessage.media,
          type: this.quoteMessage.type
        };
        formData.append("message_id", this.quoteMessage.id);
      }

      this.addRecentMessage(tempMessage);

      const payload = {
        formData: formData,
        tempId: tempId,
        tempTime: tempTime,
        chat: this.currentChat,
      }

      this.sendImage(payload).then(() => {
        this.fetchVerifyNotifiedChats("in_progress");
      })

      this.file = null;
      this.quoteMessage = null;
      this.scrollToBotton()
    },

    sendVideoMessage(text) {
      const tempId = this.generateTempId();
      const tempTime = moment().unix()

      let tempMessage = {
        id: tempId,
        internal_id: tempId,
        ack: null,
        backupToTryResend: this.file,
        body: text,
        chat_id: this.currentChatId,
        failed: false,
        fromMe: true,
        media: URL.createObjectURL(this.file),
        time: tempTime,
        type: 'video',
        instance: {
          type: this.currentChat.states.channel.type
        },
        agent: {
          id: this.user.id,
          name: this.user.name
        }
      };

      let formData = new FormData();
      formData.append("chat_id", this.currentChatId);
      formData.append('video', this.file);
      formData.append("instance_id", this.currentChat.states.channel.id);
      formData.append("caption", text);
      formData.append("body", text);

      if (this.quoteMessage) {
        tempMessage["quoted_msg"] = {
          id: this.quoteMessage.id,
          body: this.quoteMessage.body,
          media: this.quoteMessage.media,
          type: this.quoteMessage.type
        };
        formData.append("message_id", this.quoteMessage.id);
      }


      this.addRecentMessage(tempMessage);

      const payload = {
        formData: formData,
        tempId: tempId,
        tempTime: tempTime,
        chat: this.currentChat,
      }

      this.sendVideo(payload).then(() => {
        this.fetchVerifyNotifiedChats("in_progress");
      })

      this.file = null;
      this.quoteMessage = null;
      this.scrollToBotton()
    },

    sendAudioMessage() {
      const tempId = this.generateTempId();
      const tempTime = moment().unix()

      let tempMessage = {
        id: tempId,
        internal_id: tempId,
        ack: null,
        backupToTryResend: this.file,
        chat_id: this.currentChatId,
        failed: false,
        fromMe: true,
        media: URL.createObjectURL(this.file),
        time: tempTime,
        type: "audio",
        instance: {
          type: this.currentChat.states.channel.type
        },
        agent: {
          id: this.user.id,
          name: this.user.name
        }
      };

      let formData = new FormData();
      formData.append("chat_id", this.currentChatId);
      formData.append("audio", this.file);
      formData.append("instance_id", this.currentChat.states.channel.id);

      if (this.quoteMessage) {
        tempMessage["quoted_msg"] = {
          id: this.quoteMessage.id,
          body: this.quoteMessage.body,
          media: this.quoteMessage.media,
          type: this.quoteMessage.type
        };
        formData.append("message_id", this.quoteMessage.id);
      }

      this.addRecentMessage(tempMessage);

      const payload = {
        formData: formData,
        tempId: tempId,
        tempTime: tempTime,
        chat: this.currentChat,
      }

      this.sendAudio(payload).then(() => {
        this.fetchVerifyNotifiedChats("in_progress");
      })

      this.file = null;
      this.quoteMessage = null;
      this.scrollToBotton()
    },

    sendDocumentMessage() {
      const tempId = this.generateTempId();
      const tempTime = moment().unix()

      let tempMessage = {
        id: tempId,
        internal_id: tempId,
        ack: null,
        backupToTryResend: this.file,
        body: this.file.name,
        chat_id: this.currentChatId,
        failed: false,
        fromMe: true,
        media: URL.createObjectURL(this.file),
        time: tempTime,
        type: "document",
        instance: {
          type: this.currentChat.states.channel.type
        },
        agent: {
          id: this.user.id,
          name: this.user.name
        }
      };

      let formData = new FormData();
      formData.append("chat_id", this.currentChatId);
      formData.append("file", this.file);
      formData.append("instance_id", this.currentChat.states.channel.id);

      if (this.quoteMessage) {
        tempMessage["quoted_msg"] = {
          id: this.quoteMessage.id,
          body: this.quoteMessage.body,
          media: this.quoteMessage.media,
          type: this.quoteMessage.type
        };
        formData.append("message_id", this.quoteMessage.id);
      }

      this.addRecentMessage(tempMessage);

      const payload = {
        formData: formData,
        tempId: tempId,
        tempTime: tempTime,
        chat: this.currentChat,
      }

      this.sendDocument(payload).then(() => {
        this.fetchVerifyNotifiedChats("in_progress");
      })

      this.file = null;
      this.quoteMessage = null;
      this.scrollToBotton()
    },

    sendVoiceMessage(blob) {
      const tempId = this.generateTempId();
      const tempTime = moment().unix()

      let tempMessage = {
        id: tempId,
        internal_id: tempId,
        ack: null,
        backupToTryResend: blob,
        chat_id: this.currentChatId,
        failed: false,
        fromMe: true,
        media: URL.createObjectURL(blob),
        time: tempTime,
        type: "voice",
        instance: {
          type: this.currentChat.states.channel.type
        },
        agent: {
          id: this.user.id,
          name: this.user.name
        }
      };

      let formData = new FormData();
      formData.append("chat_id", this.currentChatId);
      formData.append("audio", blob);
      formData.append("instance_id", this.currentChat.states.channel.id);

      if (this.quoteMessage) {
        tempMessage["quoted_msg"] = {
          id: this.quoteMessage.id,
          body: this.quoteMessage.body,
          media: this.quoteMessage.media,
          type: this.quoteMessage.type
        };
        formData.append("message_id", this.quoteMessage.id);
      }

      this.addRecentMessage(tempMessage);
      this.scrollToBotton()

      const payload = {
        formData: formData,
        tempId: tempId,
        tempTime: tempTime,
        chat: this.currentChat,
      }

      this.sendVoice(payload).then(() => {
        this.fetchVerifyNotifiedChats("in_progress");
      })

      this.quoteMessage = null;
    },

    sendTextMessage({text, instance_id}) {
      if (text === "") return;
      const tempId = this.generateTempId();
      const tempTime = moment().unix()

      let tempMessage = {
        id: tempId,
        internal_id: tempId,
        ack: null,
        body: text,
        chat_id: this.currentChatId,
        failed: false,
        fromMe: true,
        time: tempTime,
        type: "chat",
        instance: {
          type: this.currentChat.states.channel.type
        },
        agent: {
          id: this.user.id,
          name: this.user.name
        }
      };

      let fields = {
        chat_id: this.currentChatId,
        body: text,
        instance_id: this.quoteMessage ? this.quoteMessage.instance_id : instance_id
      };

      if (this.quoteMessage) {
        fields["message_id"] = this.quoteMessage.id || this.quoteMessage.internal_id;
        tempMessage["quoted_msg"] = {
          id: this.quoteMessage.id || this.quoteMessage.internal_id,
          body: this.quoteMessage.body,
          type: this.quoteMessage.type,
          media: this.quoteMessage.media
        };
      }

      this.addRecentMessage(tempMessage);
      this.scrollToBotton()

      this.sendMessage({
        fields: fields,
        tempId: tempId,
        tempTime: tempTime,
        chat: this.currentChat
      }).then(() => {
        this.fetchVerifyNotifiedChats("in_progress")
      });

      this.quoteMessage = null;
    },

    sendForwardMessage(selectedChat, fields) {
      let tempId = this.generateTempId();

      let tempMessage = {
        id: tempId,
        internal_id: tempId,
        ack: null,
        body: fields.body,
        chat_id: selectedChat.id,
        failed: false,
        fromMe: true,
        media_original_name: fields.media_original_name,
        number: selectedChat.number,
        size: fields.size,
        isForwarded: true,
        time: moment().unix(),
        type: fields.type,
        agent: {
          id: this.user.id,
          name: this.user.name
        },
        instance: {
          type: this.currentChat.states.channel.type
        }
      };

      this.addRecentMessage(tempMessage);

      if(fields?.media) {
        tempMessage.media = fields?.media;
        tempMessage.media_name = fields?.media_name;
        tempMessage.media_original_name = fields?.media_original_name;
      }

      this.addRecentMessage({message: tempMessage});

      let formData = new FormData();
      formData.append("chat_id", this.currentChatId);
      formData.append("message_id", fields.internal_id);

      const payload = {
        data: {
          chat_ids: [selectedChat.id],
          message_id: fields.internal_id,
          instance_id: this.currentChat.states.channel.id
        },
        chat: selectedChat,
        message: fields,
        tempId : tempId,
        formData:formData
      }

      this.sendForward(payload).then(() => {
        this.$refs.forwardedModal.close();
        this.fetchVerifyNotifiedChats("in_progress")
      });
    },

    tryResendFailedMessage(message) {
      if (message.quoted_msg) {
        this.quoteMessage = message.quoted_msg;
      }
      this.removeMessageById(message);

      switch (message.type) {
        case "chat":
          if(message.isForwarded) {
            this.sendForwardMessage(this.currentChat, message);
          }
          else {
            this.sendTextMessage({
              text:message.body,
              instance_id: this.currentChat.states.channel.id
            });
          }
          break;
        case "image":
          this.file = message.backupToTryResend;
          this.sendImageMessage(message.body);
          break;
        case "document":
          this.file = message.backupToTryResend;
          this.sendDocumentMessage();
          break;
        case "audio":
          this.file = message.backupToTryResend;
          this.sendAudioMessage();
          break;
        case "voice":
          this.sendVoiceMessage(message.backupToTryResend);
          break;
        case "video":
          this.file = message.backupToTryResend;
          this.sendVideoMessage();
          break;
        case "ptt":
          this.sendVoiceMessage(message.backupToTryResend);
          break;
        case "template":
          this.sendTemplateMessage(message.backupToTryResend);
          this.sendMessageTemplates({
            instance_id: this.currentChat.states.channel.id,
            chat_id: this.currentChatId,
            template_name: message.backupToTryResend.name,
            template_language: message.backupToTryResend.language,
            template_id: message.backupToTryResend.id,
            tempTime: message.backupToTryResend.time
          });
          break;
      }
    },

    sendTemplateMessage (template) {
      let tempMessage = {
        id: template.id,
        internal_id: template.id,
        ack: null,
        body: null,
        chat_id: this.currentChatId,
        backupToTryResend: template,
        failed: false,
        fromMe: true,
        time: template.time,
        type: "template",
        template: {
          components: template.components
        },
        agent: {
          id: this.user.id,
          name: this.user.name
        },
        instance: {
          type: 'waba'
        }
      }

      let fields = {
        chat_id: this.currentChatId,
        body: template.components
      };

      if (this.quoteMessage) {
        tempMessage["quoted_msg"] = {
          id: this.quoteMessage.id,
          body: this.quoteMessage.body,
          type: this.quoteMessage.type,
          media: this.quoteMessage.media
        };
        fields["message_id"] = this.quoteMessage.id;
        this.quoteMessage = null;
      }

      this.addRecentMessage(tempMessage);
      this.scrollToBotton()

      if (this.currentChat.is_group) {
        fields.instance_id = this.currentChat.states.channel.id
      }
    },

    sendInternalNote({text, instance_id}) {
      if (text === "") return;
      const tempId = this.generateTempId();
      const tempTime = moment().unix()

      let note = {
        id: tempId,
        internal_id: tempId,
        chat_id: this.currentChatId,
        body: text,
        time: tempTime,
        ack: null,
        fromMe: true,
        failed: false,
        type: "internal-message",
        agent: {
          id: this.user.id,
          name: this.user.name
        },
      };
      this.addRecentMessage(note);
      this.scrollToBotton()

      let fields = {
        chat_id: this.currentChatId,
        body: text,
        instance_id: instance_id
      };

      if (this.currentChat.is_group){
        fields.instance_id = this.currentChat.instance_id
      }

      this.sendInternal({
        tempId: tempId,
        tempTime: tempTime,
        fields: fields,
      });
    },

    dragenter() {
      if (!this.cursorInChat) {
        this.showDragableArea = true;
      }
    },

    updateUnreadIndicators() {
      const { agent_id, is_group, unread, finished } = this.currentChat
      if (finished || unread <= 0) {
        return
      }
      if (is_group || agent_id) {
        this.clearUnreadMessageCount({
          id: this.currentChatId
        });
      }

      if (is_group) {
        this.decrementUnreadGroupsConversation();
        return
      }

      if (agent_id) {
        this.decrementUnreadConversation();
      }
    },

    async initialMessagesLoading() {
      if (!this.currentChatMessagesPagination) {
        this.loadingNextPage = true
        this.fetchCurrentMessages({
          page: 1,
          chat_id: this.currentChatId,
          instance_id: this.currentChat.instance_id,
        }).then(()=> {
          this.fetchSecondPageForInitialLoading({
            chat_id: this.currentChatId,
            instance_id: this.currentChat.instance_id,
            number: this.currentChat.number,
          }).then(() => {
            this.loadNextPageIfNecessary()
          }).finally(() => {
            this.loadingNextPage = false
          })
        })
      } else {
        this.loadingNextPage = true
        await this.loadNextPage()
        this.loadingNextPage = false

        this.loadNextPageIfNecessary()
      }
    },

    async loadNextPageIfNecessary() {
      const isScrollAvailable = this.$refs.panel.scrollHeight > this.$refs.panel.clientHeight;
      if (!isScrollAvailable) {
        this.loadingNextPage = true
        await this.loadNextPage()
        this.loadingNextPage = false
      }
    },

    setShowScrollBottom(showScrollBottom) {
      this.showScrollBottom = showScrollBottom
      this.setOpenChat({...this.openChat, hasBottomButton: showScrollBottom})

      if (!showScrollBottom && this.isOwner) {
        this.updateUnreadIndicators()
      }
    },

    async scroll(event) {
      const {scrollTop, scrollHeight,  clientHeight} = event.target

      if (this.currentChatMessagesPaginationForSearch) {
        if (scrollTop < 0) return
        if (this.loadingSearchPreviousPage) return

        this.loadingSearchPreviousPage = true
        await this.loadSearchPreviousPage()
        this.loadingSearchPreviousPage = false
        return
      }

      const showScrollBottom = scrollTop < this.SHOW_SCROLL_BOTTOM_HEIGHT
      if (showScrollBottom !== this.showScrollBottom) {
        this.setShowScrollBottom(showScrollBottom)
      }

      if (this.loadingNextPage) return
      if ((scrollHeight - clientHeight + scrollTop) > 1) return
      if (!this.currentChatStashedMessages) return

      this.loadingNextPage = true
      await this.loadNextPage()
      this.loadingNextPage = false
    },

    async loadSearchPreviousPage() {
      const { current_page, context } = this.currentChatMessagesPaginationForSearch.previous;
      const { current_page: chatCurrentPage, context: chatContext } = this.currentChatMessagesPagination;

      if (context === chatContext && (current_page - 1) === chatCurrentPage ) {
        const prevScrollHeight = this.$refs.panel.scrollHeight
        await this.mergeStashedMessagesToMessages(this.currentChatId)
        await this.mergeSearchMessagesToMessages(this.currentChatId)
        this.$refs.panel.scrollTo(0, prevScrollHeight - this.$refs.panel.scrollHeight)
        this.loadNextPage()
        return
      }

      const payload = {
        chatId: this.currentChatId,
        instanceId: this.currentChat.instance_id,
        number: this.currentChat.number,
        type: 'previous'
      }
      const prevScrollHeight = this.$refs.panel.scrollHeight

      if (context === "history" && current_page === 1) {
        await this.fetchMessagesForSearch({
          ...payload,
          context: "current",
          page: 999999,
        })
        await this.fetchMessagesForSearch({
          ...payload,
          context: "current",
          page: this.currentChatMessagesPaginationForSearch.previous.total_pages,
        })
      }
      else {
        await this.fetchMessagesForSearch({
          ...payload,
          context: context,
          page: current_page - 1,
        })
      }
      if (this.$refs.panel.scrollTop >= 0) {
        this.$refs.panel.scrollTo(0, prevScrollHeight - this.$refs.panel.scrollHeight)
      }
    },

    async loadSearchNextPage() {
      if (this.loadingSearchNextPage) return

      this.loadingSearchNextPage = true
      const { current_page, total_pages, context } = this.currentChatMessagesPaginationForSearch.next;
      const payload = {
        chatId: this.currentChatId,
        instanceId: this.currentChat.instance_id,
        number: this.currentChat.number,
      }

      if (current_page < total_pages) {
        await this.fetchMessagesForSearch({
          ...payload,
          context: context,
          page: current_page + 1,
          type: 'next'
        })
      }
      else if (context === "current") {
        await this.fetchMessagesForSearch({
          ...payload,
          context: "history",
          page: 1,
          type: 'previous'
        })
      }
      this.loadingSearchNextPage = false
    }
  }
};
</script>

<style scoped lang="scss">
.file-preview-active {
  display: none !important;
}

.agent-top-bar-active {
  height: calc(100vh - 56px);
}

.btn-position {
  left: calc(50% - 22px); top: -56px
}
.scale-enter-active, .scale-leave-active {
  transition: transform 0.3s ease;
}
.scale-enter-from, .scale-leave-to {
  transform: scale(0);
}
.scale-enter-to, .scale-leave-from {
  transform: scale(1);
}

.mb-3r {
  margin-bottom: 3rem
}

.unread {
  height: 1.25rem;
  min-width: 1.25rem;
  width: min-content;
  top: -0.5rem;
  left: 1.75rem;
  border-radius: 0.625rem
}

.unread-message {
  background-color: #3057f2;
  color: #FFFFFF;
}
</style>