<template>
  <main>
    <section>
      <h5
        class="txt-text-100 mt-4"
        style="font-size: 12px"
      >
        3C PARTNERS
      </h5>
      <h1>Financeiro</h1>
    </section>
    <section>
      <div class="card">
        <div class="card-header gap-4">
          <search-input
            :search="searchValue"
            @search="search"
          />
          <ul class="nav nav-tabs nav-tabs-sm card-header-tabs ps-5">
            <li class="nav-item">
              <button
                ref="analysis-tab"
                aria-controls="analysis"
                aria-selected="true"
                class="nav-link two-lines active"
                data-bs-target="#analysis"
                data-bs-toggle="tab"
                data-test="analysisTab-button"
                role="tab"
                @click="activeTab = 'analysis'"
              >
                <span class="text-muted"> EM ANÁLISE </span>
                <br>
                <span class="subtitle">
                  <template v-if="loadingFlags.loadWithdrawalAnalysisPartners">
                    <span class="fa-regular fa-clock" />
                  </template>
                  <template v-else>
                    {{ withdrawalAnalysisPartnersPagination.total }}
                  </template>
                </span>
              </button>
            </li>
            <li class="nav-item">
              <button
                ref="finalized-tab"
                aria-controls="finalized"
                aria-selected="true"
                class="nav-link two-lines"
                data-bs-target="#finalized"
                data-bs-toggle="tab"
                data-test="finalizedTab-button"
                role="tab"
                @click="activeTab = 'finalized'"
              >
                <span class="text-muted"> FINALIZADOS </span>
                <br>
                <span class="subtitle">
                  <template v-if="loadingFlags.loadWithdrawalFinalizedPartners">
                    <span class="fa-regular fa-clock" />
                  </template>
                  <template v-else>
                    {{ withdrawalFinalizedPartnersPagination.total }}
                  </template>
                </span>
              </button>
            </li>
          </ul>
        </div>
        <div class="card-body p-0">
          <div class="tab-content overflow-auto">
            <div
              id="analysis"
              aria-labelledby="analysis"
              class="tab-pane fade show active"
              role="tabpanel"
            >
              <table-partners-finance-analysis :search-value="searchValue" />
            </div>
            <div
              id="finalized"
              aria-labelledby="finalized"
              class="tab-pane fade show"
              role="tabpanel"
            >
              <table-partners-finance-finalized :search-value="searchValue" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import TablePartnersFinanceAnalysis
  from "@admin/pages/3c-partners/partners/components/table-partners-finance-analysis.vue";

import SearchInput from "@components/search-input.vue";
import {mapGetters} from "vuex";
import _ from "lodash";
import TablePartnersFinanceFinalized
  from "@admin/pages/3c-partners/partners/components/table-partners-finance-finalized.vue";

export default {
  name: "FinancePartners",
  components: {TablePartnersFinanceFinalized, SearchInput, TablePartnersFinanceAnalysis},

  data() {
    return {
      searchValue: "",
      activeTab: "analysis",
      tabs: {
        analysis: null,
        finalized: null,
      },
    }
  },
  computed: {
    ...mapGetters("admin/partners", {
      loadingFlags: "getLoadingFlags",
      withdrawalAnalysisPartnersPagination: "getWithdrawalAnalysisPartnersPagination",
      withdrawalFinalizedPartnersPagination: "getWithdrawalFinalizedPartnersPagination"
    })
  },
  methods: {
    search: _.debounce(function (term) {
      this.searchValue = term;
    }, 300),
  },

}
</script>
