import QualificationsReports from "@dialer/manager/pages/reports/qualifications/qualifications-reports";

export default {
  path: "qualifications-report",
  name: "qualifications-report",
  components: {
    main: QualificationsReports
  },
  meta: {
    "short-side-bar": true
  }
};
