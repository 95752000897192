import axios from "axios";

export default {
  loadActivePartners({ commit, getters }) {
    commit("startLoadingFlag", "loadActivePartners");
    commit("setActivePartners", []);

    return new Promise((resolve, reject) => {
      axios
        .get("/partners-admin", { params: getters.getActivePartnersParams })
        .then(response => {
          commit("setActivePartners", response.data.data);
          commit("setActivePartnersPagination", response.data.meta.pagination);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, {
            root: true
          });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "loadActivePartners");
        });
    });
  },

  loadPartnersMetrics({ commit }) {
    commit("startLoadingFlag", "loadPartnersMetrics");

    return new Promise((resolve, reject) => {
      axios
        .get("/partners-admin/header")
        .then(response => {
          commit("setDashboardPartnerMetrics", response.data.data);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, {
            root: true
          });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "loadPartnersMetrics");
        });
    });
  },

  loadPartnerById({ commit, dispatch }, id) {
    commit("startLoadingFlag", "loadNewPartner");
    commit("setPartner", {});

    return new Promise((resolve, reject) => {
      axios
        .get(`/partners-admin/${id}`)
        .then(response => {
          commit("setPartner", response.data.data);
          dispatch("checkCnpj", response.data.data.cnpj.replace(/\D/g, ""));
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, {
            root: true
          });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "loadNewPartner");
        });
    });
  },

  setActivePartnersFilters({ commit }, filters) {
    commit("setActivePartnersFilters", filters);
  },

  setActivePartnersCurrentPage({ commit }, page) {
    commit("setActivePartnersCurrentPage", page);
  },

  releaseAccessToTrashedPartner({ commit }, id) {
    commit("startLoadingFlag", "loadTrashedPartners");

    return new Promise((resolve, reject) => {
      axios
        .put(`/partners-admin/restore/${id}`)
        .then(() => {
          commit("system/setSuccessMessage", "Partner reativado com sucesso!", {
            root: true
          });
          resolve();
        })
        .catch(() => {
          commit("system/setErrorMessage", "Não foi possível processar essa requisição.", {
            root: true
          });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "loadTrashedPartners");
        });
    });
  },

  loadTrashedPartners({ commit, getters }) {
    commit("startLoadingFlag", "loadTrashedPartners");
    commit("setTrashedPartners", []);

    return new Promise((resolve, reject) => {
      axios
        .get("/partners-admin", { params: getters.getTrashedPartnersParams })
        .then(response => {
          commit("setTrashedPartners", response.data.data);
          commit("setTrashedPartnersPagination", response.data.meta.pagination);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.data.data, {
            root: true
          });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "loadTrashedPartners");
        });
    });
  },

  setTrashedPartnersFilters({ commit }, filters) {
    commit("setTrashedPartnersFilters", filters);
  },

  setTrashedPartnersCurrentPage({ commit }, page) {
    commit("setTrashedPartnersCurrentPage", page);
  },

  async checkCnpj({ commit }, cnpj) {
    commit("clearCnpjQueryData");
    commit("clearErrors");
    commit("startLoadingFlag", "loadNewPartner");
    commit("startLoadingFlag", "checkCnpj");
    try {
      const response = await fetch(`https://comercial.cnpj.ws/cnpj/${cnpj}?token=${process.env.VUE_APP_EF23GH}`);

      const data = await response.json();

      if (data.status === 400) {
        throw new Error(data.detalhes);
      }
      if (data.status === 404) {
        throw new Error("Insira um CNPJ válido.");
      }

      const fields = {
        commercial_name: data.estabelecimento.nome_fantasia || data.razao_social,
        cnpj_status: data.estabelecimento.situacao_cadastral
      };

      commit("setCnpjQueryData", fields);

      if (data.estabelecimento.situacao_cadastral !== "Ativa") {
        throw new Error(`Insira um CNPJ ativo.`);
      }
    } catch (error) {
      const fields = {
        commercial_name: "",
        cnpj_status: 'Inválido'
      };
      commit("setCnpjQueryData", fields);

      commit("setErrors", {
        cnpj: [error.message]
      });
    } finally {
      commit("stopLoadingFlag", "loadNewPartner");
      commit("stopLoadingFlag", "checkCnpj");
    }
  },

  async zipCode({ commit }, zipCode) {
    commit("clearZipCode");
    try {
      const response = await fetch(`https://viacep.com.br/ws/${zipCode}/json/`);
      const data = await response.json();
      commit("setZipCode", data);
    } catch (error) {
      if (error) {
        commit("system/setErrorMessage", "Erro ao consultar o CEP, tente novamente.", {
          root: true
        });
      }
    }
  },

  clearPartnerForm({ commit }) {
    commit("clearCnpjQueryData");
    commit("clearErrors");
  },

  createPartner({ commit }, payload) {
    commit("clearErrors");
    commit("startLoadingFlag", "loadNewPartner");
    return new Promise((resolve, reject) => {
      axios
        .post("/partners-admin", payload)
        .then(() => {
          commit("system/setSuccessMessage", "Partner criado com sucesso!", {
            root: true
          });
          commit("clearErrors");
          commit("clearCnpjQueryData");
          resolve();
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          error.response.status == 422 && error.response.data.errors && commit("setErrors", error.response.data.errors);

          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "loadNewPartner");
        });
    });
  },

  updatePartner({ commit }, payload) {
    commit("startLoadingFlag", "updatePartner");
    return new Promise((resolve, reject) => {
      axios
        .put(`/partners-admin/${payload.id}`, payload.data)
        .then(() => {
          commit("system/setSuccessMessage", "Partner atualizado com sucesso!", {
            root: true
          });
          resolve();
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, {
            root: true
          });
          error.response.status == 422 && error.response.data.errors && commit("setErrors", error.response.data.errors);
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "updatePartner");
        });
    });
  },

  deletePartner({ commit }, id) {
    commit("startLoadingFlag", "updatePartner");
    return new Promise((resolve, reject) => {
      axios
        .delete(`/partners-admin/${id}`)
        .then(() => {
          commit("system/setSuccessMessage", "Partner excluído com sucesso!", {
            root: true
          });
          resolve();
        })
        .catch(() => {
          commit("system/setErrorMessage", "Erro ao excluir partner.", {
            root: true
          });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "updatePartner");
        });
    });
  },

  loadWithdrawalAnalysisPartners({ commit, getters }) {
    commit("startLoadingFlag", "loadWithdrawalAnalysisPartners");
    commit("setWithdrawalAnalysisPartners", []);

    return new Promise((resolve, reject) => {
      axios
        .get("/withdraw", { params: getters.getWithdrawalAnalysisPartnersParams })
        .then(response => {
          commit("setWithdrawalAnalysisPartners", response.data.data);
          commit("setWithdrawalAnalysisPartnersPagination", response.data.meta.pagination);
          commit("setTotalAnalysisWithdraw", response.data.meta.pagination.total);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "loadWithdrawalAnalysisPartners");
        });
    });
  },

  detailsPartners({ commit }, id) {
    commit("startLoadingFlag", "loadDetailsPartners");
    commit("setDetailsPartners", {});
    return new Promise((resolve, reject) => {
      axios
        .get(`/partners-admin/${id}/dashboard/level`)
        .then(response => {
          commit("setDetailsPartners", response.data.data);
          resolve();
        })
        .catch(() => reject())
        .finally(() => {
          commit("stopLoadingFlag", "loadDetailsPartners");
        });
    });
  },

  companiesListPartners({ commit }) {
    commit("startLoadingFlag", "loadListPartners");
    commit("setListPartners", []);
    return new Promise((resolve, reject) => {
      axios
        .get(`/partners-admin`, {
          params: {
            per_page: -1
          }
        })
        .then(response => {
          commit("setListPartners", response.data.data);
          resolve();
        })
        .catch(() => reject())
        .finally(() => {
          commit("stopLoadingFlag", "loadListPartners");
        });
    });
  },

  listPartnersAdmin({ commit, getters }, id) {
    commit("startLoadingFlag", "loadListPartners");
    commit("setListPartners", []);
    return new Promise((resolve, reject) => {
      axios
        .get(`/partners-admin/${id}/dashboard/companies`, {
          params: {
            page: getters.getPartnerDetailsPagination.current_page
          }
        })
        .then(response => {
          commit("setListPartners", response.data.data);
          commit("setPartnerDetailsPagination", response.data.meta.pagination);
          resolve();
        })
        .catch(() => reject())
        .finally(() => {
          commit("stopLoadingFlag", "loadListPartners");
        });
    });
  },

  setAdminPartnerDetailsCurrentPage({ commit }, page) {
    commit("setAdminPartnerDetailsCurrentPage", page);
  },

  updateWithdraw({ commit }, { withdrawId, withdraw }) {
    commit("startLoadingFlag", "updateWithdraw");

    return new Promise((resolve, reject) => {
      axios
        .put(`/withdraw/${withdrawId}`, withdraw)
        .then(response => {
          commit("setWithdrawPartners", response.data.data);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          commit("startLoadingFlag", "updateWithdraw");
        });
    });
  },

  setWithdrawalAnalysisPartnersFilters({ commit }, filter) {
    commit("setWithdrawalAnalysisPartnersFilters", filter);
  },

  setWithdrawalAnalysisPartnersCurrentPage({ commit }, page) {
    commit("setWithdrawalAnalysisPartnersCurrentPage", page);
  },

  loadWithdrawalFinalizedPartners({ commit, getters }) {
    commit("startLoadingFlag", "loadWithdrawalFinalizedPartners");
    commit("setWithdrawalFinalizedPartners", []);

    return new Promise((resolve, reject) => {
      axios
        .get("/withdraw", { params: getters.getWithdrawalFinalizedPartnersParams })
        .then(response => {
          commit("setWithdrawalFinalizedPartners", response.data.data);
          commit("setWithdrawalFinalizedPartnersPagination", response.data.meta.pagination);
          commit("setTotalFinalizedWithdraw", response.data.meta.pagination.total);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "loadWithdrawalFinalizedPartners");
        });
    });
  },

  setWithdrawalFinalizedPartnersFilters({ commit }, filter) {
    commit("setWithdrawalFinalizedPartnersFilters", filter);
  },

  setWithdrawalFinalizedPartnersCurrentPage({ commit }, page) {
    commit("setWithdrawalFinalizedPartnersCurrentPage", page);
  },

  listCompanyDetailsAdmin({ commit }, id) {
    commit("startLoadingFlag", "companyDetails");
    commit("setCompanyDetails", {});
    return new Promise((resolve, reject) => {
      axios
        .get(`/partners-admin/company/${id}/details`)
        .then(response => {
          const subscriptions = response.data.data.subscriptions.map(subscription => ({
            ...subscription,
            product_items: subscription.product_items.data.filter(product => product.status === "ACTIVE")
          }));
          commit("setCompanyDetails", {
            ...response.data.data,
            subscriptions
          });
          resolve();
        })
        .catch(() => reject())
        .finally(() => {
          commit("stopLoadingFlag", "companyDetails");
        });
    });
  }
};
