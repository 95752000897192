import store from "@store";
import IndexInsights from "@dialer/manager/pages/insights-ai/index-insights.vue";
import DashboardAI from "@dialer/manager/pages/insights-ai/dashboard/dashboard-ai.vue";
import SettingsAI from "@dialer/manager/pages/insights-ai/settings/settings-ai.vue";
import CampaignAI from "@dialer/manager/pages/insights-ai/components/campaign-insights-ai.vue";

export default {
  path: "insights-ai",
  name: "insights-ai",
  components: {
    main: IndexInsights
  },
  children: [
    {
      path: "",
      components: {
        insights: DashboardAI
      }
    },
    {
      path: "campaign-ai/:id",
      name: "campaign-ai",
      components: {
        insights: CampaignAI
      },
      meta: {
        "short-side-bar": true
      }
    },
    {
      path: "settings",
      components: {
        insights: SettingsAI
      },
      meta: {
        "short-side-bar": true
      }
    }
  ],
  beforeEnter: (to, from, next) => {
    if (store.getters["auth/isManager"]) next();
    else
      next({
        name: "not-found",
        params: {
          pathMatch: to.path.split("/").slice(1)
        }
      });
  }
};
