import axios from "@crm/plugins/axios-config";

export default {
  fetchTags({commit}) {
    commit("startLoading", "fetchTags");
    return new Promise((resolve, reject) => {
      axios.get("/tags", {params: { per_page: -1}}).then((response) => {
        commit("setTags", response.data.data)
        resolve()
      }).catch((response) => {
        commit('system/setErrorMessage', response.response.data, {root: true})
        reject()
      }).finally(() => {
        commit("stopLoading", "fetchTags");
      })
    })
  },

  createTag({commit, getters, rootGetters}, {payload, error}) {
    payload.company_id  = rootGetters['auth/getUser'].company.id

    commit("startLoading", "createTag");
    return new Promise((resolve, reject) => {
      axios.post("/tags", payload).then((response) => {
        const newTag = response.data.data
        let tags = getters.getTags
        tags.unshift(newTag)

        commit('setTags', tags)
        resolve(newTag)
      }).catch(() => {
        commit('system/setErrorMessage', error, {root: true})
        reject()
      }).finally(() => {
        commit("stopLoading", "createTag");
      })
    })
  },

  createTagAttach({commit, rootGetters}, {payload, tag}) {
    commit("startLoading", "createTagAttach");
    return new Promise((resolve, reject) => {
      axios.post("/tags/attach", payload).then(() => {
        const contact_tag = {
          tag_id: tag.id,
          color: tag.color,
          name: tag.name,
        }
        let contact = rootGetters['whatsapp/contact/getContact']
        contact.contact_tags.unshift(contact_tag)

        commit('whatsapp/contact/setContact', contact, {root: true})
        resolve()
      }).catch((response) => {
        commit('system/setErrorMessage', response.response.data, {root: true})
        reject()
      }).finally(() => {
        commit("stopLoading", "createTagAttach");
      })
    })
  },

  createTagDetach({commit, rootGetters}, {payload, contactTag}) {
    commit("startLoading", "createTagDetach");
    return new Promise((resolve, reject) => {
      axios.post("/tags/detach", payload).then(() => {
        let contact = rootGetters['whatsapp/contact/getContact']
        const tagIndex = contact.contact_tags.findIndex(item => item.tag_id === contactTag.tag_id)
        contact.contact_tags.splice(tagIndex, 1)

        commit('whatsapp/contact/setContact', contact, {root: true})
        resolve()
      }).catch((response) => {
        commit('system/setErrorMessage', response.response.data, {root: true})
        reject()
      }).finally(() => {
        commit("stopLoading", "createTagDetach");
      })
    })
  },
}
