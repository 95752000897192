export default {
  getList: state => state.list,
  getAlert: state => state.alert,
  getFilters: (state) => state.filters,
  getParams: (state) => Object.assign(
    { page: state.pagination.current_page },
    state.filters,
  ),
  getRegister: (state) => state.register,
  getPagination: (state) => state.pagination,
  getDataSelect: state => state.dataSelect,
  getErrorFlags: (state) => state.errorFlags,
  getLoadingFlags: (state) => state.loadingFlags,
}
