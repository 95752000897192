<template>
  <modal-component
    @close="showModal = false"
    :show="showModal"
    :center="true"
    size="modal-md"
  >
    <template
      #content
    >
      <div class="modal-header p-4">
        <div class="w-100">
          <h2 class="m-0 modal-title text-center">
            {{ title }}
          </h2>
        </div>
        <button
          type="button"
          class="btn-close mb-3 mr-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div
        class="modal-body text-center"
        style="padding: 0px 48px 24px;"
      >
        <img
          src="@/assets/img/sad-pluszinha-red.svg"
          alt="Pluszinha triste"
          style="padding-bottom: 16px; width: 138px;"
        >
        <h5
          class="fw-normal m-0"
          style="padding-bottom: 16px;"
        >
          {{ changeMessage }}
        </h5>
        <div
          class="mb-4"
        >
          <label class="form-label text-start w-100 h5 fw-normal">Motivo da alteração</label>
          <textarea
            placeholder="Descreva o motivo da alteração"
            v-model="reason"
            class="form-control rounded-4 p-3 h5 fw-normal"
            id="textarea"
            rows="5"
            required
          />
        </div>
        <div class="row justify-content-center">
          <div class="col-12">
            <button
              class="btn btn-danger w-100 delete-button"
              :disabled="enableButton"
              @click="submit"
            >
              Confirmar
            </button>
            <app-button
              class="w-100"
              color="tx-gray-400"
              @click="showModal = false"
            >
              Cancelar
            </app-button>
          </div>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from "@/components/modal-component.vue";
import AppButton from "@/components/app-button.vue";
import { mapActions } from "vuex";

export default {
  emits: ['update:modelValue'],

  components: {
    ModalComponent,
    AppButton
  },

  props: {
    title: {
      type: String,
      default: ""
    },

    modelValue: {
      type: Number,
      default: 0,
    },

    licenseType: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      showModal: false,
      showReasonInput: false,
      reason: '',
      submitParam: {}
    };
  },

  beforeUpdate() {
    this.showReasonInput = false;
    this.reason = '';
  },

  computed: {
    enableButton() {
      if(this.reason) {
        return false;
      }
      if (this.licenseType == 'limit_call_per_agent') {
        return false;
      }
      return true;
    },

    changeMessage() {
      if (this.licenseType == 'max_agents_login') return "Todos os agentes logados serão desabilitados independente da solicitação do gestor. O processo de reversão será feito manualmente.";

      if (this.licenseType == 'limit_call_per_agent') return "Todss as licenças de chamadas de agentes serão desabilitadas independente da solicitação do gestor. O processo de reversão será feito manualmente.";

      if (this.licenseType == 'webphone_licenses') return "Todas as licenças de webphone serão desabilitadas independente da solicitação do gestor. O processo de reversão será feito manualmente.";

      if (this.licenseType == 'ring_group_licenses') return "Todos os ramais serão desabilitados independente da solicitação do gestor. O processo de reversão será feito manualmente.";

      if (this.licenseType == 'ura_licenses') return "Todas as licenças de ura serão desabilitadas independente da solicitação do gestor. O processo de reversão será feito manualmente.";

      return null;
    }
  },

  methods: {
    ...mapActions("admin/companies", ["findById", "updateLicense"]),

    openModal() {
      this.showModal = true;
    },

    submit() {
      this.submitParam[this.licenseType] = 0
      this.submitParam["change_reason"] = this.reason
      this.updateLicense({
        id: this.$route.params.id,
        params: this.submitParam
      }).then(() => {
        this.findById({ id: this.$route.params.id });
        this.showModal = false;
        this.reason = '';
      })
    }
  }
};
</script>

<style lang="scss" scoped>

.modal-header .btn-close {
  margin-right: -0.9rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

textarea {
  border: 1px solid $color-gray-blue-300;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $color-text-gray !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $color-text-gray !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: $color-text-gray !important;
}

.delete-button {
  &:disabled {
    color: #D1D8DE;
    background-color: #F6F8FC;
    border: none;
  }
}

</style>
