import chatsAgentsReport from "@dialer/manager/pages/reports/chats-agents/chats-agents-report.vue";

export default {
  path: "chats-agents-report",
  name: "chats-agents-report",
  components: {
    main: chatsAgentsReport,
  },
  meta: {
    "short-side-bar": true
  }
};
