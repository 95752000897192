import axios from "axios";

export default {
  fetchWorkBreakGroups({ commit }, payload) {
    commit("startLoadingFlag", "fetchWorkBreakGroups");
    commit("setWorkBreakGroups", []);

    return new Promise((resolve, reject) => {
      axios
        .get("/work_break_group", { params: payload })
        .then(response => {
          commit("setWorkBreakGroups", response.data.data);
          commit("setPagination", response.data.meta.pagination);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => commit("stopLoadingFlag", "fetchWorkBreakGroups"));
    });
  },

  create({ commit }, payload) {
    commit("clearErrors");
    commit("startLoadingFlag", "create");

    return new Promise((resolve, reject) => {
      axios
        .post("/work_break_group", payload.fields)
        .then(response => {
          commit("system/setSuccessMessage", "success_message.create_interval_group", { root: true });
          resolve({ id: response.data.data.id });
        })
        .catch(response => {
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => commit("stopLoadingFlag", "create"));
    });
  },

  update({ commit }, payload) {
    commit("clearErrors");
    commit("startLoadingFlag", "update");

    return new Promise((resolve, reject) => {
      axios
        .put("/work_break_group/" + payload.id, payload.fields)
        .then(response => {
          commit("system/setSuccessMessage", "success_message.update_interval_group", { root: true });
          commit("setWorkBreakGroup", response.data.data);
          resolve();
        })
        .catch(response => {
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => commit("stopLoadingFlag", "update"));
    });
  },

  remove({ commit }, payload) {
    commit("startLoadingFlag", "remove");

    return new Promise((resolve, reject) => {
      axios
        .delete("/work_break_group/" + payload.id)
        .then(() => {
          commit("system/setSuccessMessage", "success_message.remove_interval_group", { root: true });
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => commit("stopLoadingFlag", "remove"));
    });
  },

  findWorkBreakGroupById({ commit }, payload) {
    commit("startLoadingFlag", "findWorkBreakGroupById");
    commit("setWorkBreakGroup", null);

    const { id, include } = payload;

    return new Promise((resolve, reject) => {
      axios
        .get(`/work_break_group/${id}`, { params: { include }})
        .then(response => {
          commit("setWorkBreakGroup", response.data.data);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => commit("stopLoadingFlag", "findWorkBreakGroupById"));
    });
  },

  findIntervalsByWorkBreakGroupId({ commit }, payload) {
    commit("startLoadingFlag", "findIntervalsByWorkBreakGroupId");
    commit("setIntervals", []);
    const { page } = payload;

    return new Promise((resolve, reject) => {
      axios
        .get("/work_break_group/" + payload.id + "/intervals", { params: { page } })
        .then(response => {
          commit("setIntervals", response.data.data);
          commit("setIntervalsPagination", response.data.meta.pagination);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => commit("stopLoadingFlag", "findIntervalsByWorkBreakGroupId"));
    });
  },

  updateInterval({ commit }, payload) {
    commit("clearErrorsInterval");
    commit("startLoadingFlag", "updateInterval");

    return new Promise((resolve, reject) => {
      axios
        .put("/work_break_group/" + payload.list_id + "/intervals/" + payload.id, payload.fields)
        .then(() => {
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrorsInterval", response.response.data.errors);
          reject();
        })
        .finally(() => commit("stopLoadingFlag", "updateInterval"));
    });
  },

  addInterval({ commit }, payload) {
    commit("clearErrorsInterval");
    commit("startLoadingFlag", "addInterval");

    return new Promise((resolve, reject) => {
      axios
        .post("/work_break_group/" + payload.list_id + "/intervals", payload.fields)
        .then(() => {
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrorsInterval", response.response.data.errors);
          reject();
        })
        .finally(() => commit("stopLoadingFlag", "addInterval"));
    });
  },
  removeInterval({ commit }, payload) {
    commit("clearErrors");
    commit("startLoadingFlag", "removeInterval");

    return new Promise((resolve, reject) => {
      axios
        .delete("/work_break_group/" + payload.list_id + "/intervals/" + payload.id)
        .then(() => {
          commit("system/setSuccessMessage", "success_message.remove_interval", { root: true });
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => commit("stopLoadingFlag", "removeInterval"));
    });
  },

  startWorkBreak({ commit }, payload) {
    commit("startLoadingFlag", "startWorkBreak");

    return new Promise((resolve, reject) => {
      axios
        .put("/agents/" + payload.agent_id + "/work_break", {
          work_break_id: payload.work_break_id
        })
        .then(() => {
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })

        .finally(() => {
          commit("stopLoadingFlag", "startWorkBreak");
        });
    });
  }
};
