import PerformanceReport from "@dialer/manager/pages/reports/performance/performance-report.vue";

export default {
  path: "performance-report",
  name: "performance-report",
  components: {
    main: PerformanceReport
  },
  meta: {
    "short-side-bar": true
  }
};
