export default {
  loading: false,
  qualifications: {
    calls: 0,
    qualifications: []
  },
  metrics: {
    abandoned: 0,
    acw: 0,
    connected: 0,
    queue_id: 0,
    speaking: 0,
    speaking_with_agent: 0,
    waiting_time: 0,
    awt: 0,
    ast: 0,
    abandonedPercentage: 0,
    connectedPercentage: 0
  },
};
