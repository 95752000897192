<template>
  <div class="card">
    <div class="card-header">
      <div class="d-flex align-items-center justify-content-between">
        <div class="">
          <p class="card-header-title">
            {{ $t("qualifications_list") }}
          </p>
        </div>
        <div class="d-flex px-4">
          {{ totalQualifications }}
          <app-button
            v-if="(chatsReportList && chatsReportList.length > 0) && false"
            class="btn-sm btn-export tx-text-gray col-12 ms-3"
            @click="show = true"
          >
            {{ $t("download_CSV") }}
          </app-button>
        </div>
      </div>
    </div>
    <div class="card-body p-0">
      <div class="table-responsive">
        <table class="table table-nowrap my-0">
          <thead>
            <tr>
              <th scope="col">
                {{ $t("name") }}
              </th>
              <th scope="col">
                {{ $t("capitalizes.total") }}
              </th>
              <th scope="col">
                {{ $t("capitalizes.percent") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="loadingFlags.fetchQualificationChatsReportList">
              <loading-table-component
                :lines="5"
                :columns="8"
              />
            </template>
            <tr
              v-else
              v-for="(item, id) in chatsReportList"
              :key="id"
            >
              <td>
                <dot-status
                  :color="item.color || '#000000'"
                  size="10px"
                />
                {{ item.name || '-' }}
              </td>
              <td>
                {{ item.total }}
              </td>
              <td>
                <progressbar-multiple
                  hover-effect
                  style="height: 6px"
                  :data="[{
                    color: item.color,
                    percentage: item.percentage || 0,
                    count: item.count,
                    title: item.name
                  }]"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <table-empty v-if="!chatsReportList && !loadingFlags.fetchQualificationChatsReportList" />
  </div>
  <modal-download-csv
    v-model="show"
    :loading="loadingFlags.fetchSendCsvEmail"
    @send="(data) => sendCsvDownload(data)"
  />
</template>

<script>
import ModalDownloadCsv from "../../components/modal-download-csv.vue";
import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";
import AppButton from "@components/app-button.vue";
import TableEmpty from "@dialer/manager/pages/reports/components/table-empty.vue";

import {mapActions, mapGetters} from "vuex";
import DotStatus from "@components/dot-status.vue";
import ProgressbarMultiple from "@components/progressbar-multiple.vue";

export default {
  name: "QualificationChatsReportTable",

  emits: ["openOffcanvas"],

  components: {
    ProgressbarMultiple,
    DotStatus,
    TableEmpty,
    AppButton,
    LoadingTableComponent,
    ModalDownloadCsv,
  },

  data() {
    return {
      show: false,
      indexOpennedRow: -1,
    }
  },

  computed: {
    ...mapGetters("manager/qualification_chats_report", {
      loadingFlags: "getLoadingFlags",
      chatsReportList: "getQualificationList",
    }),
    totalQualifications() {
      return this.chatsReportList && this.chatsReportList?.length > 0 ?
        this.chatsReportList.reduce((acc, curr) => acc + curr.count, 0) : 0
    },
  },

  methods: {
    ...mapActions("manager/qualification_chats_report", [
      "fetchQualificationChatsReportList",
      "fetchSendCsvEmail"
    ]),

    sendCsvDownload(item) {
      this.fetchSendCsvEmail({
        id: item.chat_id,
        instance_id: item.instance_id,
        number: item.number
      }).then(() => {
        this.show = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-export {
  background: #E1E9F4;
  border: none;

  &:hover {
    background-color: #D2DDEC;
  }
}
</style>
