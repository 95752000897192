<template>
  <li class="d-flex align-items-center justify-content-between cursor-pointer item-channel">
    <div class="d-flex align-items-center gap-2 fw-medium text-medium">
      <span
        class="fa-kit tx-text-gray"
        :class="icon[channel.type]"
      />
      {{ $t(channel.type) }}
    </div>
    <p class="m-0 text-small">
      {{ formatPhoneNumber(channel.phone) || "-" }}
    </p>
  </li>
</template>

<script>
import { formatPhoneNumber } from "@/utils";

export default {
  name: "ItemChannel",

  props: {
    channel: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      icon: {
        ultramsg: "fa-whatsapp",
        waba: "fa-waba"
      }
    };
  },

  methods: {
    formatPhoneNumber
  }
};
</script>

<style lang="scss" scoped>
.item-channel {
  padding: 0.5rem 1rem;
  border-radius: 10px;

  &:hover {
    background-color: $color-gray-blue-300;
  }
}
</style>
