<template>
  <tr
    class="mailing-list-item"
    :class="{ 'tx-gray-400': item.pro_mode !== user.company.pro_mode }"
  >
    <td
      class="px-0"
      :class="{ 'border-0': index === 1 }"
    >
      <VDropdown
        :distance="30"
        placement="top-start"
        :skidding="-15"
        :triggers="['hover']"
        v-if="item.recycled_times > 0"
      >
        <div class="position-relative">
          <img
            src="@assets/img/icons/recycle-thin-secondary.svg"
            class="recycle-icon"
          >
          <span class="position-absolute bottom-0 mb-2 recycled-times-text text-small tx-gray-500">
            {{ item.recycled_times }}</span>
        </div>
        <template #popper>
          <div
            style="max-width: 705px; padding: 1rem;"
            data-test="recycledTimes-tooltip"
          >
            <strong>
              {{ $t("recycle_list") }}
            </strong>
            <br class="mt-2">
            <div class="fs-6 mt-3">
              <strong>
                {{ item.recycle_filters.recycle_type === "status" ? "Status:" : $t("qualifications_capitalize") + ":" }}
              </strong>
              {{ generateRecycleTooltipText(item.recycle_filters) }}
            </div>
          </div>
        </template>
      </VDropdown>
    </td>
    <td
      class="text-truncate text-base"
      :class="{ 'border-0': index === 1 }"
      style="max-width: 150px"
      :title="item.original_name ? `${item.original_name} (${item.total})` : item.name"
      colspan="2"
    >
      <span :title="item.original_name ? item.original_name : item.name">
        {{ item.original_name ? `${item.original_name} (${item.total})` : item.name }}
      </span>
    </td>
    <template v-if="item.recycle_process">
      <td
        colspan="12"
        :class="{ 'border-0': index === 1 }"
      >
        <VDropdown
          :triggers="['hover']"
          :distance="14"
          placement="top"
        >
          <div class="rounded text-center recycle-button">
            <span>{{ $t("recycling") }}</span>
          </div>
          <template #popper>
            <div class="fs-6 p-3">
              {{ $t("recycling_list") }}...
            </div>
          </template>
        </VDropdown>
      </td>
    </template>
    <template v-else>
      <td
        class="text-truncate text-base"
        :class="{ 'border-0': index === 1 }"
        :title="item.ura_name"
        v-if="ura"
      >
        {{ item.ura_name }}
      </td>
      <td :class="{ 'border-0': index === 1 }">
        {{ item.total }}

        <div class="d-flex align-items-center justify-content-between">
          <progressbar-multiple
            :style="barsStyle"
            :data="[
              {
                color: '#008A35',
                percentage: item.completed_percentage,
                count: item.completed,
                icon: 'fe-check',
                title: this.$t('concluded'),
              },
              {
                color: '#C5C5C5',
                percentage: item.redial_percentage,
                icon: 'fe-refresh-ccw',
                count: item.redial,
                title: $t('redial'),
              },
              {
                color: '#DFDFDF',
                percentage: item.dial_percentage,
                icon: 'fe-minus',
                count: item.dial,
                title: $t('dial'),
              },
            ]"
          />
        </div>
      </td>
      <td :class="{ 'border-0': index === 1 }">
        {{ item.dialed }}
        <div class="d-flex align-items-center justify-content-between">
          <progressbar-multiple
            :style="barsStyle"
            :data="[
              {
                color: '#008A35',
                percentage: item.answered_percentage,
                count: item.answered,
                icon: 'fe-users',
                title: this.$t('delivered_in_operation'),
              },
              {
                color: '#E67C0B',
                percentage: item.abandoned_percentage,
                count: item.abandoned,
                icon: 'fe-alert-triangle',
                title: this.$t('capitalizes.abandoned'),
              },
              {
                color: '#E60B42',
                percentage: item.mailbox_percentage,
                count: item.mailbox,
                icon: 'fe-voicemail',
                title: this.$t('post_call_ivr'),
              },
              {
                color: '#EB3C68',
                percentage: item.not_answered_due_progress_amd_percentage || 0,
                count: item.not_answered_due_progress_amd || 0,
                icon: 'fe-phone-off',
                title: this.$t('pre_call_ivr'),
              },
              {
                color: '#CDCACA',
                percentage: item.not_answered_percentage,
                count: item.not_answered,
                icon: 'fe-minus',
                title: $t('not_answer'),
              },
              {
                color: '#EAEAEA',
                percentage: item.failed_percentage,
                count: item.failed,
                icon: 'fe-x',
                title: this.$t('fail'),
              },
            ]"
          />
        </div>
      </td>
      <td :class="{ 'border-0': index === 1 }">
        {{ item.answered }}
        <div class="d-flex align-items-center justify-content-between">
          <progressbar-multiple
            :style="barsStyle"
            :data="[
              {
                color: '#3057F2',
                percentage: item.converted_percentage,
                count: item.converted,
                icon: 'fe-check',
                title: this.$t('cpc_plus_conversion'),
              },
              {
                color: '#008A35',
                percentage: item.dmc_percentage - item.converted_percentage,
                icon: 'fe-user-check',
                count: item.dmc - item.converted,
                title: this.$t('right_person_contact'),
              },
              {
                color: '#F23F2C',
                percentage: item.unknown_percentage,
                icon: 'fe-user-x',
                count: item.unknown,
                title: this.$t('unknown_capitalize'),
              },
              {
                color: '#677C92',
                percentage:
                  answered(item.answered_percentage, item.answered) - item.dmc_percentage - item.unknown_percentage,
                icon: 'fe-users',
                count: item.answered - item.dmc - item.unknown,
                title: this.$t('others_f'),
              },
            ]"
          />
        </div>
      </td>
      <td :class="{ 'border-0': index === 1 }">
        {{ (item.abandoned_percentage + item.answered_percentage).toFixed(1) }}%
        <mailing-score
          class="d-flex align-items-center justify-content-between"
          :completed-percentage="item.abandoned_percentage + item.answered_percentage"
          :data="[
            {
              color: '#008A35',
              percentage: item.answered_percentage,
              count: item.answered,
              icon: 'fe-check',
              title: this.$t('service_fee'),
            },
            {
              color: '#FFBD00',
              percentage: item.abandoned_percentage,
              count: item.abandoned,
              icon: 'fe-alert-triangle',
              title: this.$t('abandon_fee'),
            },
            {
              color: '#AFAFAF',
              percentage: item.abandoned_percentage + item.answered_percentage,
              count: item.answered + item.abandoned,
              icon: 'fe-check-circle',
              title: this.$t('completed_fee'),
            },
          ]"
        />
      </td>
      <td
        v-if="item.completed_percentage < 100"
        class="text-base tx-gray-400 created-at"
        :class="{ 'border-0': index === 1 }"
        :title="formatToHumanDate(item.created_at)"
      >
        {{ daysFromCreation(item.created_at) }}
      </td>
      <td
        v-else
        class="tx-gray-400"
        :class="{ 'border-0': index === 1 }"
        :title="formatToHumanDate(item.created_at)"
      >
        <div class="d-flex flex-column">
          <span class="text-base finalized-date"> {{ finalizedDate(item.created_at) }} </span>
          <small class="weight-info">{{ $t("finalized_in") }}</small>
        </div>
      </td>
      <td :class="{ 'border-0': index === 1 }">
        <div class="d-flex align-items-center">
          <span class="text-base me-3">{{ item.weight }}</span>
          <span class="text-medium weight-info me-2">
            <div
              v-if="listWeightIdUpdating === item.id"
              class="spinner-border spinner-border-sm me-1"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            <span v-else>
              {{ `${weightPercentage(item.weight)}` }}
            </span>
            %
          </span>

          <div
            class="d-flex flex-column"
            v-if="item.pro_mode !== user.company.pro_mode"
          >
            <span class="fe fe-chevron-up cursor-default" />
            <span class="fe fe-chevron-down cursor-default" />
          </div>
          <div
            class="d-flex flex-column update-weight"
            v-else
          >
            <span
              @click="updateListWeight(undefined, item.id, true)"
              v-if="userPermission"
              class="fe fe-chevron-up"
              :style="{
                cursor: 'pointer',
              }"
            />
            <span
              v-if="userPermission"
              @click="updateListWeight(undefined, item.id, false)"
              class="fe fe-chevron-down"
              :style="{
                cursor: 'pointer',
              }"
            />
          </div>
        </div>
      </td>
      <td v-if="user.company.mailing_list_conversion_goals">
        <i
          v-if="item.conversion_goal === 0"
          class="fal fa-minus tx-text-gray"
        />
        <div v-else>
          <span class="tx-text-gray">{{ viewingGoal(item) }}/{{ item.conversion_goal }}</span>
          <div
            class="progress"
            style="height: 8px;width: 88px;"
          >
            <div
              class="progress-bar"
              role="progressbar"
              :style="{ width: calculatePercentage(item.converted, item.conversion_goal) }"
              aria-valuenow="32"
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
        </div>
      </td>
      <td
        class="p-0"
        :class="{ 'border-0': index === 1 }"
      >
        <div
          v-if="true"
          class="mailing-list-options"
        >
          <span
            class="dropdown-toggle fe fe-more-vertical cursor-pointer"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            aria-haspopup="true"
            data-test="mailingListOptions-tooltip"
          />

          <div class="dropdown-menu px-3">
            <div class="tooltip-content d-flex justify-content-start align-items-center flex-column">
              <button
                class="btn btn-hover rounded py-1 p-0 w-100 d-flex align-content-center ps-3"
                data-test="smartDashboard-button"
                @click="downloadReport(item.id, item.name)"
              >
                <span class="fe fe-download-cloud weight-info tx-orange-300" />
                <span style="padding-left: 16px;"> {{ $t("intelligent_filter_report") }}</span>
              </button>
              <VDropdown
                placement="left"
                :triggers="['hover']"
                :distance="20"
                class="w-100"
              >
                <button
                  class="btn btn-hover rounded py-1 pe-0 w-100 d-flex align-content-center ps-3 mt-1"
                  :class="item.completed_percentage > requiredPercentageToRecycle && 'btn-hover'"
                  :style="{
                    opacity: item.completed_percentage < requiredPercentageToRecycle && 0.7,
                    cursor: item.completed_percentage < requiredPercentageToRecycle && 'default',
                  }"
                  data-test="openRecycleModal-button"
                  @click="recycle(item.id, item.completed_percentage)"
                >
                  <img
                    src="@assets/img/icons/recycle-thin-green.svg"
                    class="mt-1"
                    alt="close icon"
                    width="16"
                    height="18"
                  >
                  <span style="padding-left: 16px;"> {{ $t("recycle_mailing") }}</span>
                </button>
                <template #popper>
                  <div
                    style="padding: 1rem;"
                    v-if="item.completed_percentage < requiredPercentageToRecycle"
                  >
                    {{ $t("recycle_list_progress_one_percent") }}
                  </div>
                  <div
                    style="padding: 1rem;"
                    v-else-if="item.completed_percentage < recommendedPercentageToRecycle"
                  >
                    <i class="text-warning fa-regular fa-triangle-exclamation" />
                    {{ $t("recycle_list_recommendation") }}
                  </div>
                </template>
              </VDropdown>
              <div
                v-if="user.company.mailing_list_conversion_goals"
                class="border-bottom w-100 my-2"
              />
              <button
                v-if="user.company.mailing_list_conversion_goals"
                class="btn btn-hover rounded py-1 p-0 w-100 d-flex align-items-center ps-3"
                @click="handleListId(item.id, item)"
              >
                <i class="fa-light fa-bullseye-arrow text-primary" />
                <span style="padding-left: 16px;">
                  {{ item.conversion_goal === 0 ? "Atribuir meta" : "Editar meta" }}</span>
              </button>
              <div class="border-bottom w-100 my-2" />
              <button
                @click="remove(item.id)"
                class="btn btn-hover rounded py-1 p-0 w-100 d-flex align-items-center ps-3"
                data-test="removeList-button"
              >
                <i
                  v-if="userPermission"
                  class="fal fa-trash text-danger m-0 fs-5"
                  style="width: 16px;"
                />
                <span style="padding-left: 16px;"> {{ $t("delete_list") }} </span>
              </button>
            </div>
          </div>
        </div>
      </td>
    </template>
  </tr>
  <company-goal-modal
    v-if="user.company.mailing_list_conversion_goals"
    v-model="open"
    v-bind="{ fields }"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment/moment";
import { CallStatusWithName, SystemQualifications, translateSystemQualificationNames, Utils } from "@/utils";
import MailingScore from "@dialer/manager/pages/dashboards/campaign/components/mailing-score.vue";
import ProgressbarMultiple from "@components/progressbar-multiple.vue";
import CompanyGoalModal from "@dialer/manager/pages/dashboards/campaign/components/company-goal-modal.vue";

export default {
  name: "MailingListItem",
  data() {
    return {
      barsStyle: {
        "min-width": "150px",
        height: 8 + "px",
      },
      statuses: CallStatusWithName,
      systemQualifications: SystemQualifications,
      systemQualificationsTranslatedNames: translateSystemQualificationNames,
      requiredPercentageToRecycle: Number(process.env.VUE_APP_7K9H8R),
      recommendedPercentageToRecycle: Number(process.env.VUE_APP_L2F6P3),
      open: false,
      fields: {},
    };
  },

  emits: ["updateListWeight", "success", "recycle"],

  components: {
    CompanyGoalModal,
    ProgressbarMultiple,
    MailingScore,
  },

  props: {
    item: {
      type: Object,
      default: () => {
      },
    },

    ura: {
      type: Boolean,
      default: false,
    },

    sumWeight: {
      type: Number,
      default: 0,
    },

    userPermission: {
      type: Boolean,
      default: false,
    },

    campaign: {
      type: Object,
      default: () => {
      },
    },

    listWeightIdUpdating: {
      type: Number,
      default: 0,
    },

    /**
     * This index.js is necessary only to hide the upper border
     * of the first inactive list
     */
    index: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser",
    }),

    buildSystemQualificationObject() {
      return Object.keys(this.systemQualifications).map(key => {
        return {
          id: this.systemQualifications[key],
          name: this.systemQualificationsTranslatedNames[key],
          conversion: false,
        };
      });
    },
  },

  methods: {
    format: Utils.formatDate,
    ...mapActions("manager/list", ["removeList", "downloadFilteredNumbers"]),
    ...mapMutations("auth", ["trackEvents"]),
    answered(answeredPercentage, answered) {
      if (answeredPercentage === 0 && answered === 0) return 0;
      return 100;
    },

    formatToHumanDate(date) {
      return moment(date).format("dddd, D MMMM YYYY, HH:mm:ss");
    },

    daysFromCreation(date) {
      return moment(date).fromNow();
    },

    weightPercentage(weight) {
      if (this.sumWeight === 0) return 0;
      return Math.round((weight / this.sumWeight) * 100);
    },

    generateRecycleTooltipText(recycle_filters) {
      let type = recycle_filters.recycle_type;
      let ids = recycle_filters.recycle_type_ids;
      let message = "";

      let qualifications = JSON.parse(JSON.stringify(this.campaign.qualifications));
      qualifications.push(...this.buildSystemQualificationObject);

      if (type === "status") {
        ids.forEach((id, index) => {
          let statusName = this.statuses[id];

          if (index === 0) message += statusName;
          else message += `; ${statusName}`;
        });
      } else {
        ids.forEach((recycleId, index) => {
          if (recycleId !== -1) {
            let qualification = qualifications.find(qualification => qualification.id === recycleId);
            let qualificationName = qualification.name.charAt(0).toUpperCase() + qualification.name.slice(1);

            if (index === 0) message += qualificationName;
            else message += `; ${qualificationName}`;
          }
        });
      }
      if (message[0] == ";") {
        message = message.replace(";", "");
      }
      return message + ".";
    },

    finalizedDate(date) {
      return moment(date).format("DD/MM");
    },

    updateListWeight(event, id, byIncrement) {
      this.$emit("updateListWeight", event, id, byIncrement);
    },

    remove(id) {
      if (window.confirm(this.$t("delete_list_confirmation"))) {
        this.removeList({
          campaign_id: this.campaign.id,
          id,
        }).then(() => {
          this.trackEvents("[Manager] Delete Mailling");
          this.$emit("success");
        });
      }
    },

    downloadReport(id, name) {
      this.downloadFilteredNumbers({
        campaign_id: this.campaign.id,
        list_id: id,
        list_name: name,
      });
    },

    recycle(id, completed_percentage) {
      if (completed_percentage > this.requiredPercentageToRecycle) {
        this.$emit("recycle", id);
      }
    },
    handleListId(id, item) {
      this.open = true;
      let fields = {
        campaign_id: Number(this.$route.params.id),
        list_id: id,
        conversion_goal: item.conversion_goal,
        mode: "new",
      };
      if (item.conversion_goal !== 0) {
        fields.mode = "edit";
      }
      this.fields = fields;
    },
    calculatePercentage(value, total) {
      return (value / total) * 100 + "%";
    },
    viewingGoal(item) {
      if (item.converted >= item.conversion_goal) {
        return item.conversion_goal;
      }
      return item.converted;
    },
  },
};
</script>

<style scoped lang="scss">
.dropdown-toggle:after {
  display: none !important;
}

.finalized-date {
  color: $color-text-solid-black;
}

.created-at:first-letter {
  text-transform: capitalize;
}

.weight-info {
  color: $color-text-gray;
}

.tooltip-content {
  .btn-hover {
    color: $color-text-200;

    &:hover {
      background-color: $color-gray-blue-300;
    }
  }
}

.recycle-button {
  background-color: #e2e6f5;
  height: 21px;

  span {
    color: #1a4a89;
  }
}

.recycle-icon {
  width: 16px;
  margin-top: -7px;
  margin-left: -3px;
}

.recycled-times-text {
  color: $color-gray-500;
  margin-top: -14px;
}

td {
  height: 48px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.mailing-list-item {
  .update-weight {
    visibility: hidden;
  }

  .mailing-list-options {
    visibility: hidden
  }

  &:hover {
    .update-weight {
      visibility: visible;
    }

    .mailing-list-options {
      visibility: visible;
    }
  }
}
</style>
