import {mapActions, mapGetters} from "vuex";

export const messagesOmnichannel = {
  computed: {
    ...mapGetters("whatsapp/message", [
      "getMessages",
      "getPaginations"
    ]),
    ...mapGetters("whatsapp/chat", {
      currentChats: "getCurrentChat",
    }),
    currentChat() {
      return this.currentChats[this.$route.params.id];
    },
    currentChatId() {
      return this.$route.params.id;
    },
    currentChatMessages() {
      return this.getMessages(this.$route.params.id);
    },
    currentChatMessagesPagination() {
      return this.getPaginations(this.$route.params.id);
    },
  },

  methods: {
    ...mapActions("whatsapp/message", [
      "mergeStashedMessagesToMessages",
      "fetchCurrentMessages",
      "fetchHistoryMessages",
      "fetchMessagesForSearch"
    ]),

    async loadNextPage() {
      this.mergeStashedMessagesToMessages(this.currentChatId);

      let {current_page, total_pages, per_page, context} = this.currentChatMessagesPagination;
      const totalMessages = Object.values(this.currentChatMessages).reduce((sum, arr) => sum + arr.length, 0);
      const updatedTotalPages = (totalMessages / per_page) | 0;
      const diffPages = updatedTotalPages - total_pages;

      if (diffPages > 0) {
        current_page = current_page + diffPages;
        total_pages = updatedTotalPages;
      }

      if (current_page < total_pages) {
        context === "current" ?
          await this.fetchCurrentMessages({
            chat_id: this.currentChatId,
            instance_id: this.currentChat.instance_id,
            page: current_page + 1,
          }) :
          await this.fetchHistoryMessages({
            number: this.currentChat.number,
            chat_id: this.currentChatId,
            instance_id: this.currentChat.instance_id,
            page: current_page + 1
          })
      }
      else if (context === "current") {
        await this.fetchHistoryMessages({
          number: this.currentChat.number,
          chat_id: this.currentChatId,
          instance_id: this.currentChat.instance_id,
          page: 1
        })
      }
    },

    async goToMessage(message, scrollFunction) {
      const chatId = this.$route.params.id
      let { context: msgContext, page: msgPage } = message;
      const { context: chatContext, current_page: chatCurrentPage } = this.currentChatMessagesPagination;
      const isAfterPage = chatCurrentPage > msgPage;
      const isCurrentPage = chatCurrentPage === msgPage;
      const isBeforePage = chatCurrentPage < msgPage;
      msgContext = msgContext === "historic" ? "history" : msgContext;

      if (msgContext === "current") {
        if (chatContext === "history" || isAfterPage) {
          scrollFunction()
          return
        }
        if (chatContext === "current") {
          if (isCurrentPage) {
            await this.loadNextPage()
            scrollFunction()
            return
          }
          if (isBeforePage) {
            this.fetchMessagesForSearch({
              chatId : chatId,
              instanceId : this.currentChat.instance_id,
              number : this.currentChat.number,
              context : msgContext,
              page : msgPage,
              type: "initial"
            }).then(()=>{
              scrollFunction()
            })
            return
          }
        }
      }
      if (msgContext === "history") {
        if (chatContext === "current" || isBeforePage) {
          this.fetchMessagesForSearch({
            chatId : chatId,
            instanceId : this.currentChat.instance_id,
            number : this.currentChat.number,
            context : msgContext,
            page : msgPage,
            type: "initial"
          }).then(()=>{
            scrollFunction()
          })
          return
        }
        if (chatContext === "history") {
          if (isAfterPage) {
            scrollFunction()
            return
          }
          if (isCurrentPage) {
            await this.loadNextPage()
            scrollFunction()
            return
          }
        }
      }
    },
  },
};
