<template>
  <dashboard-activities-list
    type="pendingOfDay"
    :owners-ids="ownersIds"
    :key="ownersIds"
  />
  <dashboard-activities-list
    type="future"
    :owners-ids="ownersIds"
    :key="ownersIds"
  />
</template>
<script>
import DashboardActivitiesList from "@crm/components/activity/list/dashboard-activities-list.vue";

export default {

  name: "DashboardActivityList",

  components: {DashboardActivitiesList},

  props: {
    ownersIds: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style scoped lang="scss">

</style>
