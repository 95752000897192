<template>
  <div class="container-fluid">
    <header-component :title="$t('extensions_group')">
      <template #buttons>
        <app-button
          v-if="user.role.name == 'manager'"
          class="btn btn-primary"
          @click="$router.push({ path: '/manager/ring-group/new' })"
        >
          {{ $t('add_new_extension_group') }}
        </app-button>
      </template>
    </header-component>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <search-input
                @search="search"
                :placeholder="$t('search_by_extension_group')"
              />
            </div>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-hover table-nowrap card-table">
                <thead>
                  <tr>
                    <th />

                    <th>
                      <sort-link
                        :title="$t('name_capitalize')"
                        class="text-muted fw-normal"
                      />
                    </th>

                    <th>
                      <sort-link
                        :title="$t('extension_number')"
                        class="text-muted fw-normal"
                      />
                    </th>

                    <th>
                      <sort-link
                        :title="$t('online_extensions')"
                        class="text-muted fw-normal"
                      />
                    </th>

                    <th>
                      <sort-link
                        :title="$t('received_calls')"
                        class="text-muted fw-normal"
                      />
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody class="text-base">
                  <template v-if="ringGroups.length && !loadingFlags.loadRingGroups">
                    <tr
                      class="cursor-pointer"
                      v-for="group in ringGroups"
                      :key="group.id"
                      @mouseover="selectItem(group.id)"
                      @mouseleave="unSelectItem()"
                      @click="redirectToRingGroup(group.id)"
                    >
                      <td>
                        <div
                          class="ring-group-status m-auto"
                          :class="{
                            active: countUsersActive(group.ring_group_users)
                          }"
                        />
                      </td>
                      <td>
                        <div
                          class="text-truncate overflow-elipsis"
                          style="max-width: 200px;"
                        >
                          {{ group.name }}
                        </div>
                      </td>
                      <td>
                        <div
                          class="text-truncate"
                          style="max-width: 200px;"
                        >
                          {{ group.extension.extension_number }}
                        </div>
                      </td>
                      <td>
                        {{ countUsersActive(group.ring_group_users) }}
                      </td>
                      <td>
                        <div
                          class="text-truncate"
                          style="max-width: 200px;"
                        >
                          {{ group.all_calls }}
                        </div>
                      </td>
                      <td>
                        <div @click.stop="remove(group.id)">
                          <span
                            class="fe fe-trash trash-icon"
                            color="text-secondary"
                            :loading="loadingFlags['deleteRingGroup']"
                          />
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
                <div />
              </table>
              <div class="mb-3 ">
                <paginator
                  :pagination="pagination"
                  @change-page="changePage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import _ from "lodash";

import { AgentStatus } from "@/utils.js";

import HeaderComponent from "@/components/header-component.vue";
import SortLink from "@dialer/manager/components/sort-link";
import AppButton from "@/components/app-button.vue";
import SearchInput from "@components/search-input";
import paginator from "@components/app-paginator";

export default {
  name: "RingGroup",
  data() {
    return {
      AgentStatus,
      onlineAgentsChart: {
        title: this.$t('online_agents_capitalize'),
        labels: [""]
      },
      activeMetric: false,
      selectedItem: false,
      term: ""
    };
  },
  components: {
    HeaderComponent,
    SearchInput,
    AppButton,
    paginator,
    SortLink
  },

  methods: {
    ...mapActions("manager/ring_group", [
      "loadRingGroups",
      "loadRingGroupCalls",
      "loadRingGroupMetrics",
      "loadRingGroupAgents",
      "deleteRingGroup",
      "setFilters"
    ]),
    ...mapActions("system", ["showConfirmationMessage"]),
    ...mapMutations("manager/ring_group", ["clearGroups", "setCurrentPage"]),

    changePage(page) {
      this.setCurrentPage(page);
      this.loadRingGroups();
    },
    selectItem(item) {
      this.selectedItem = item;
    },
    unSelectItem() {
      this.selectedItem = false;
    },

    search: _.debounce(function(term) {
      this.setFilters({ search: term });
      this.loadRingGroups({
        per_page: -1
      });
    }, 500),

    redirectToRingGroup(id) {
      this.$router.push(`/manager/ring-group/${id}/edit`);
    },

    countUsersActive(users) {
      const count = users.reduce((acc, user) => {
        if (user.is_online_in_ring_group) {
          acc++;
        }
        return acc;
      }, 0);
      return count;
    },

    async remove(id) {
      let response = await this.showConfirmationMessage({
        title: this.$t('delete_extension_group_confirmation'),
        text: this.$t('group_deletion_cannot_be_undone'),
        type: "badWarning"
      });

      if (response.isConfirmed) {
        this.deleteRingGroup({ id }).then(() => {
          this.initiateRingGroups();
        });
      }
    },

    async initiateRingGroups() {
      await this.loadRingGroups();
      await this.loadRingGroupCalls();
      await this.loadRingGroupMetrics();
      await this.loadRingGroupAgents().then(() => {
        this.activeMetric = this.total;
      });
    }
  },

  computed: {
    ...mapGetters("manager/ring_group", {
      ringGroups: "getRingGroups",
      total: "getTotal",
      pagination: "getPagination",
      loadingFlags: "getLoadingFlags"
    }),
    ...mapGetters("auth", {
      user: "getUser"
    })
  },

  mounted() {
    this.initiateRingGroups();
  }
};
</script>

<style lang="scss" scoped>
.table > :not(:first-child) {
  border-top: none;
}

.trash-icon:hover {
  color: var(--bs-danger);
}

.ring-group-status {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: var(--color-gray-200);

  &.active {
    background-color: var(--color-blue-300);
    animation-name: active-campaign;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
    animation-direction: normal;
  }

  @keyframes active-campaign {
    from {
      box-shadow: none;
    }

    to {
      box-shadow: 0 0 0 3px #7e9cf7;
    }
  }
}
</style>
