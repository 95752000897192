export default {
  startLoadingFlag: (state, flag) => state.loadingFlags[flag] = true,
  stopLoadingFlag: (state, flag) => state.loadingFlags[flag] = false,

  // TODO: Remove loading mutations below, change the mutation
  // name above to startLoading and stopLoading and use only loading flags
  startLoading: state => (state.loading = true),
  stopLoading: state => (state.loading = false),
  stopLoadingContext: state => (state.loadingContext = false),
  setLoadingContext: (state, loadingContext) => state.loadingContext = loadingContext,

  // Instance
  setInstance: (state, instance) => (state.instance = instance),
  setInstanceFilters: (state, instanceFilters) => (state.instanceFilters = instanceFilters),
  setInstanceIncludes: (state, instanceIncludes) => (state.instanceIncludes = instanceIncludes),
  setStrategySettings: (state, strategySettings) => state.strategySettings = strategySettings,
  setErrors: (state, errors) => (state.errors = errors),
  clearErrors: state => state.errors = {},
  clearQueues: state => state.queues = [],
  setList: (state, list) => (state.list = list),
  setTeamsList: (state, teamsList) => (state.teamsList = teamsList),
  setInstanceStatus: (state, instanceStatus) => state.instanceStatus = instanceStatus,
  setWhatsappQrCode: (state, whatsappQrCode) => state.whatsappQrCode = whatsappQrCode,
  setWhatsappMetrics: (state, metrics) => state.metrics = metrics,
  setOfficeHours: (state, officeHours) => state.officeHours = officeHours,
  setInstanceId: (state, instance_id) => state.instance_id = instance_id,
  setPagination: (state, pagination) => {
    state.pagination = pagination;
  },

  setEditTabRef: (state, editTabRef) => state.editTabRef = editTabRef,

  setInstances: (state, {status, instances}) => state.instancesData[status] = instances,
  setInstancesPagination: (state, {status, pagination}) => state.instancesPagination[status] = pagination,
  
  setAgents: (state, agents) =>  state.agents = agents,
  setAgentsPagination: (state, pagination) => state.agentsPagination = pagination,
  
  setGroupChannelAgents: (state, agents) => state.groupChannelAgents = agents,
  setGroupChannelAgentsPagination: (state, pagination) => state.groupChannelAgentsPagination = pagination,
  
  clearDashboard (state) {
    state.agents = []
    state.agentsPagination = []
  },

  setTotalActiveInstances: (state, total) => state.totalActiveInstances = total,
  setOpenAiSettings: (state, payload) => state.openAiSettings = payload,
  setAgentsSelect: (state, agentsSelect) => state.agentsSelect = agentsSelect
};
