<template>
  <div ref="mailingScore">
    <div
      class="d-flex align-items-center justify-content-between"
      style="width: 62px;"
    >
      <div
        class="progress progress-sm"
        :style="{
          'min-width': minWidth,
          height: height
        }"
      >
        <div
          class="progress-bar"
          :class="{
            'bg-amd': completedPercentage >= 0 && completedPercentage <= 4.9,
            'bg-interval': completedPercentage > 4.9 && completedPercentage <= 7.9,
            'bg-call': completedPercentage > 7.9,
          }"
          role="progressbar"
          style="width: 100%"
        />
      </div>

      <div
        class="progress progress-sm"
        :style="{
          'min-width': minWidth,
          height: height
        }"
      >
        <div
          class="progress-bar"
          :class="{
            'bg-gray-100': completedPercentage >= 0 && completedPercentage <= 4.9,
            'bg-interval': completedPercentage > 4.9 && completedPercentage <= 7.9,
            'bg-call': completedPercentage > 7.9,
          }"
          role="progressbar"
          style="width: 100%"
        />
      </div>

      <div
        class="progress progress-sm"
        :style="{
          'min-width': minWidth,
          height: height
        }"
      >
        <div
          class="progress-bar"
          :class="{
            'bg-gray-100':
              (completedPercentage >= 0 && completedPercentage <= 4.9) ||
              (completedPercentage > 4.9 && completedPercentage <= 7.9),
            'bg-call': completedPercentage > 7.9,
          }"
          role="progressbar"
          style="width: 100%"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip } from "bootstrap";

export default {
  data() {
    return {
      minWidth: '18px',
      height: '6px',
    }
  },
  mounted() {
    this.generateMailingScoreTooltip();
  },
  props: {
    completedPercentage: {
      type: Number,
      default: 0,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    generateMailingScoreTooltip() {
      let tooltip = document.createElement("div");
      for (let [index, item] of this.data.entries()) {
        let tooltipItem = document.createElement("div");
        tooltipItem.style.display = 'flex';
        tooltipItem.style.alignItems = 'center';
        if(index !== 0) {
          tooltipItem.style.paddingTop = '8px';
        }
        if(index !== this.data.length -1) {
          tooltipItem.style.paddingBottom = '8px';
          tooltipItem.style.borderBottom = '1px solid #F4F4F4';
        }

        tooltipItem.innerHTML = `
          <div class="d-flex align-items-center justify-content-between flex-grow-1">
            <div class="me-4">
              <span style="color: ${item.color}">
                <span class="fe ${item.icon} me-1"></span>
              </span>
              <span>${item.title}</span>
            </div>
            <span>${item.count} (${parseFloat(item.percentage).toFixed(2)}%)</span>
          </div>
        `;

        tooltip.appendChild(tooltipItem);
      }
      this.tooltip = new Tooltip(this.$refs.mailingScore, {
        html: true,
        container: "body",
        title: tooltip,
      });
    }
  }
}
</script>
