<template>
  <iframe
    v-if="
      integrationURL &&
        (status == AgentStatus.ON_CALL ||
          status == AgentStatus.ACW ||
          status == AgentStatus.ON_MANUAL_CALL_ACW)
    "
    :src="integrationURL"
    class="w-100 iframe-integration m-0"
  />
</template>
<script>
import { AgentStatus } from "@/utils";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      AgentStatus,
    };
  },

  computed: {
    ...mapGetters("system", {
      status: "getStatus",
      integrationURL: "getIntegrationURL",
    }),
  },
};
</script>
<style lang="scss">
.iframe-integration {
  top: 64.58px !important;
  height: calc(100vh - 64.58px) !important;
}
body {
  padding: 0px !important;
}
</style>
