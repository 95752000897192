export default {
  isLoading: state => state.loading,
  getLoadingFlags: state => state.loadingFlags,
  getList: state => state.list,
  getUraList: state => state.uraList,
  getFilters: state => state.filters,
  getPagination: (state) => state.pagination,
  getErrors: state => state.errors,
  getRegister: (state) => state.register,
  getParams: (state) =>
    Object.assign({ "page": state.pagination.current_page }, state.filters),
  getRecycleParams: (state) => Object.assign(state.recycleFilters, { include: state.include.join(",") }),
  getDataSelect: (state) => state.dataSelect,
  getloadingContext: state => state.loadingContext,
  getListDataPreview: (state) => state.listDataPreview,
  getListHeaderPreview: state => state.listHeaderPreview,
  getFileName: state => state.fileName,
}
