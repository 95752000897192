export default {
  getLoadingFlags: state => state.loadingFlags,
  getInstanceParams: state =>
    Object.assign(state.instanceFilters, {
      include: state.instanceIncludes.join(",")
    }),
  isLoading: state => state.loading,
  getGroupChannelInstance: state => state.groupChannelInstance,
  getGroupChannels: state => state.groupChannels,
  getGroupChannelsPagination: state => state.groupChannelsPagination,
  getGroupChannel: state => state.groupChannel,
  getGroupChannelParams: state =>
    Object.assign(state.groupChannelFilters, {
      include: state.groupChannelIncludes.join(",")
    }),
  getPagination: state => state.pagination,
};
