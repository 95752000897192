export default {
    startLoading: state => state.loading = true,
    stopLoading: state => state.loading = false,
    setList: (state, list) => state.list = list,
    setRegister: (state, register) => state.register = register,
    clearList: (state) => state.list = [],
    setAlert: (state, alert) => {
        state.alert = alert
    },

    setErrors: (state, errors) => { state.errors = errors;  },
    clearErrors: (state) => {
        state.errors = {}
        state.errorMessage = ""
    },

    setErrorFlag: (state, { errorFlag, errors }) => state.errorsFlag[errorFlag] = errors,
    clearErrorFlag: (state, errorFlag) => state.errorsFlag[errorFlag] = {},

    setFilters: (state, filters) => {
        state.filters = filters;
    },
    setPagination: (state, pagination) => {
        state.pagination = pagination;
    }

}