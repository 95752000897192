export default {
    loading: false,
    errorMessage: '',
    errors: {},
    list: [],
    campaign: null,
    pagination: {},
    register: null,
    chartData:[],
    fileName: "",
    listDataPreview: [],
    listHeaderPreview: [],
    validatedMailingListData: [],
    loadingContext: '',
    startedList: [],
}
