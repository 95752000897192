import axios from "axios";
import axiosOmni from "@/plugins/axios-omni.js"

export default {
  findInstanceStatus({ commit }, payload) {
    commit("startLoading");
    commit("startLoadingFlag", "findInstanceStatus");
    commit("setInstanceStatus", [])
    return new Promise((resolve, reject) => {
      axiosOmni
        .get(`/whatsapp/instance/${payload.id}/status`,)
        .then(response => {
          commit("setInstanceStatus", response.data.data);

          if(payload?.reactivate) {
            resolve(response.data.data)
            return true
          }

          resolve();
        })
        .catch((response) => {
          if(payload?.reactivate) {
            reject(response.response.data);
            return false
          }
          commit('system/setErrorMessage', response.response.data, {root: true})
          commit('stopLoading');
          reject();
        })
        .finally(() => {
          if(payload?.reactivate) {
            resolve(false)
            return true
          }
          commit("stopLoading");
          commit("stopLoadingFlag", "findInstanceStatus");
        });
    });
  },

  createInstances({ commit }, payload) {
    commit("startLoadingFlag", "createInstances");
    return new Promise((resolve, reject) => {
      axiosOmni
        .post("/whatsapp/instance", payload.formData)
        .then((response) => {
          commit("system/setSuccessMessage", "success_message.create_instance", { root: true})
          resolve(response.data.data);
        })
        .catch((response) => {
          commit('system/setErrorMessage', response.response.data, {root: true})
          commit('stopLoading');
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "createInstances");
        });
    });
  },

  loadTeamsSelect({ commit }) {
    commit('startLoading')
    commit('setTeamsList', []);
    return new Promise((resolve, reject) => {
      axios.get('/filters', {params: {include: 'teams'}})
        .then((response) => {
          commit('setTeamsList', response.data.data);
          resolve();
        }).catch((response) => {
        commit('system/setErrorMessage', response.response.data, {root: true})
        commit('stopLoading');
        reject();
      }).finally(() => {
        commit('stopLoading')
      });
    });
  },

  getWhatsappQrCode({ commit }, payload) {
    commit('startLoading')
    commit("setWhatsappQrCode", "")
    commit("setLoadingContext", 'getting-qrcode')
    commit('startLoadingFlag', "getWhatsappQrCode")
    return new Promise((resolve, reject) => {
      axiosOmni.get(`/whatsapp/instance/${payload.id}/qrcode`)
        .then((response) => {
          commit("setWhatsappQrCode", response.data.data)
          commit("setLoadingContext", '')
          resolve();
        }).catch(() => {
        reject();
      }).finally(() => {
        commit('stopLoading')
        commit('stopLoadingFlag', "getWhatsappQrCode")
      });
    });
  },

  fetchWhatsappDashboard({ commit }, params) {
    params = {...params, per_page: 15}
    commit('startLoadingFlag', "fetchWhatsappDashboard")
    commit('clearDashboard')
    commit("setWhatsappMetrics", {})
    return new Promise((resolve, reject) => {
      axiosOmni.get("/dashboard", {params: params})
        .then((response) => {
          commit("setWhatsappMetrics", response.data.metrics)
          const agents = response.data.agents.data.map((agent) => {
            const { agent_id, ...data } = agent;
            return { id: agent_id, ...data };
          });
          commit("setAgents", agents)

          const pagination = {
            current_page: response.data.agents.meta.pagination.current_page,
            total_pages: response.data.agents.meta.pagination.total_pages
          }
          commit("setAgentsPagination", pagination)
          resolve();
        })
        .catch((error) => {
          commit('system/setErrorMessage', error.response.data, { root: true })
          reject();
        }).finally(() => {
        commit('stopLoadingFlag', "fetchWhatsappDashboard")
      });
    });
  },

  fetchWhatsappDashboardById({ commit }, {group_channel_id, params}) {
    params = {...params, per_page: 15}
    commit('startLoadingFlag', "fetchWhatsappDashboardById")
    commit('clearDashboard')
    commit("setWhatsappMetrics", {})
    commit("setGroupChannelAgents", [])
    return new Promise((resolve, reject) => {
      axiosOmni.get(`/group-channel/dashboard/${group_channel_id}`, {params: params})
        .then((response) => {
          commit("setWhatsappMetrics", response.data.metrics)
          const agents = response.data.agents.data.map((agent) => {
            const { agent_id, ...data } = agent;
            return { id: agent_id, ...data };
          });
          commit("setGroupChannelAgents", agents)
          commit("setGroupChannelAgentsPagination", response.data.agents.meta.pagination)
          resolve();
        })
        .catch((error) => {
          commit('system/setErrorMessage', error.response.data, { root: true })
          reject();
        }).finally(() => {
          commit('stopLoadingFlag', "fetchWhatsappDashboardById")
      });
    });
  },

  updateWhatsappInstance({ commit }, payload) {
    commit('startLoading')
    commit("setLoadingContext", 'updating-instance')
    return new Promise((resolve, reject) => {
      axiosOmni.put(`/whatsapp/instances/${payload.id}`, payload.fields)
        .then((response) => {
          commit('system/setSuccessMessage', response.data.detail, { root: true })
          commit("setLoadingContext", '')
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
          reject();
        }).finally(() => {
          commit('stopLoading')
        });
    });
  },

  deactivateWhatsappInstance({ commit }, payload) {
    commit('startLoadingFlag', "deactivateWhatsappInstance")
    return new Promise((resolve, reject) => {
      axiosOmni
        .post(`/whatsapp/instance/${payload.id}/disconnect`)
        .then(response => {
          commit("system/setSuccessMessage", response.data.detail, {
            root: true
          });
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
          reject();
        }).finally(() => {
          commit('stopLoadingFlag', "deactivateWhatsappInstance")
        });
    });
  },

  reactivateWhatsappInstance({ commit }, payload) {
    commit('startLoading');
    return new Promise((resolve, reject) => {
      axiosOmni
        .put(`/whatsapp/instance/${payload.id}/reconnect`)
        .then(response => {
          commit("system/setSuccessMessage", response.data.detail, {
            root: true
          });
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, {
            root: true
          });
          reject();
        })
        .finally(() => {
          commit('stopLoading')
        });
    });
  },

  /**
   * Bring the data and settings related to the instance
   *
   * @param {Object} param0
   * @param {Object} payload
   * @returns
   */
  findInstanceById({ commit, getters }, payload) {
    commit("startLoadingFlag", "findInstanceById");
    return new Promise((resolve, reject) => {
      axiosOmni.get(`/whatsapp/instance/${payload.id}`, { params: getters.getInstanceParams })
        .then((response) => {
          commit("setInstance", response.data.data);
          resolve();
        })
        .catch((error) => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        }).finally(() => {
          commit("stopLoadingFlag", "findInstanceById");
      });
    });
  },

  /**
   * Updates the whatsapp strategy settings
   *
   * @param {Object} param0
   * @param {Object} payload
   * @returns
   */
  updateStrategySettings({ commit }, payload) {
    commit("startLoadingFlag", "updateStrategySettings");
    return new Promise((resolve, reject) => {
      axiosOmni.post(`/group-channel/${payload.id}/config`, payload.fields)
        .then((response) => {
          commit('system/setSuccessMessage', "success_message.update_settings", { root: true })
          commit("setStrategySettings", response.data.data);
          resolve();
        })
        .catch((error) => {
          commit("setErrors",  error.response.data.errors);
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        }).finally(() => {
          commit("stopLoadingFlag", "updateStrategySettings");
      });
    });
  },

  loadOfficeHours({ commit }) {
    commit('startLoading');
    return new Promise((resolve, reject) => {
      axios.get('/office_hours').
        then((response) => {
          commit('setOfficeHours', response.data.data);
          resolve();
        })
        .catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
          reject();
        })
        .finally(() => {
          commit('stopLoading');
        });
    })
  },

  /**
   * Transfer all the active chats of an agent
   * to another agent or put the chats in the queue.
   *
   * if user id is equal null the chats will be
   * sended to the queue.
   *
   * @param {Object} param0
   * @param {Object} payload
   * @returns
   */
  agentTransferChats({ commit }, {agentId, payload}) {
    commit("startLoading");
    return new Promise((resolve, reject) => {
      axiosOmni.post(`/whatsapp/agent_transfer_chats/agent/${agentId}`, payload)
        .then(() => {
          commit('system/setSuccessMessage', "success_message.transfer_conversations", { root: true });
          resolve();
        })
        .catch((error) => {
          commit('system/setErrorMessage', error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },


  /**
   * Finish all chats binded to a agent
   *
   * @param {Object} param0
   * @param {Object} payload
   * @returns
   */
  finishAgentChat({ commit }, payload) {
    commit("startLoading");
    return new Promise((resolve, reject) => {
      axiosOmni.post(`/whatsapp/finish_agent_chats/agent/${payload.id}`, payload.params )
        .then(() => {
          commit('system/setSuccessMessage', "success_message.end_conversations", { root: true });
          resolve();
        })
        .catch((error) => {
          commit('system/setErrorMessage', error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  addManagerOnWishListWhatsapp({ commit }, payload) {
    commit("startLoading");
    return new Promise((resolve, reject) => {
      axios.post(`/user_data/${payload.id}` )
        .then(() => {
          resolve();
        })
        .catch((error) => {
          commit('system/setErrorMessage', error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  alterEditTabRef({ commit }, payload) {
    commit("setEditTabRef", payload);
  },

  // postLogoutAgent({ commit }, agentId) {
  //   commit('startLoadingFlag', "postLogoutAgent")
  //   return new Promise((resolve, reject) => {
  //     axios.post("/forced-logout", agentId)
  //       .then(() => {
  //         resolve();
  //       }).catch((response) => {
  //       reject();
  //       commit('system/setErrorMessage', response.response.data, { root: true })
  //     }).finally(()=>{
  //       commit('stopLoadingFlag', "postLogoutAgent")
  //     })
  //   });
  // },

  fetchOpenAiSettings({ commit }, groupChannelId) {
    commit('clearErrors');
    commit('startLoading', 'fetchOpenAiSettings');
    return new Promise((resolve, reject) => {
      axiosOmni.get('/group-channel/' + groupChannelId + '/open_ai/settings')
        .then((response) => {
          commit('setOpenAiSettings', response.data.data);
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
          reject();
        })
        .finally(() => {
          commit('stopLoading', 'fetchOpenAiSettings');
        });
    });
  },

  createOpenAiSettings({ commit }, openAiSettings) {
    commit('clearErrors');
    commit('startLoading', 'createOpenAiSettings');
    return new Promise((resolve, reject) => {
      axiosOmni.post('/group-channel/' + openAiSettings.id + '/open_ai/settings', openAiSettings.fields)
        .then(() => {
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
          reject();
        })
        .finally(() => {
          commit('stopLoading', 'createOpenAiSettings');
        });
    });
  },

  fetchWhatsappAgents({ commit }, search) {
    commit('startLoading', 'fetchWhatsappAgents');
    return new Promise((resolve, reject) => {
      axiosOmni.get('/whatsapp/agents', {params: {search: search}})
        .then((response) => {
          commit("setAgentsSelect", response.data.data)
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
          reject();
        }).finally(() => {
          commit('stopLoading', 'fetchWhatsappAgents');
        });
    });
  },

  clearInstance({ commit }, instance) {
    commit('startLoading', 'clearInstance');

    return new Promise((resolve, reject) => {
      axiosOmni.post(`/whatsapp/instance/${instance.id}/clear`)
        .then(() => {
          resolve();
        }).catch((response) => {
        commit('system/setErrorMessage', response.response.data, { root: true })
        reject();
      }).finally(() => {
        commit('stopLoading', 'clearInstance');
      });
    });
  },
};
