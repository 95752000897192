<template>
  <div>
    <alert-tip
      class="mb-4"
      :text="$t('ip_feature')"
      :button-close-alert="true"
    />
    <div class="card">
      <div class="card-header">
        <div class="d-flex justify-content-start col">
          <h2 class="mb-0">
            {{ $t("ip_and_network_range") }}
          </h2>
        </div>

        <button
          v-if="listIpAllowed.ips?.length"
          class="btn px-4 bg-gray-blue-300 tx-text-gray d-flex align-items-center"
          @click="openOffCanvas"
          style="height: 32px;"
        >
          <i
            class="far fa-cog"
            style="margin-right: 8px;"
          />
          {{ $t("manage_access_singular") }}
        </button>
      </div>
      <div class="card-body px-0 py-0">
        <div v-if="loadingFlag.listIpsAllowed">
          <card-loading :height="212" />
        </div>
        <div v-else>
          <div v-if="listIpAllowed.ips?.length === 0">
            <td
              class="d-flex flex-column justify-content-center align-items-center py-4"
              colspan="12"
            >
              <h4 class="fw-medium tx-text-300">
                {{ $t("no_ip") }}
              </h4>
              <img
                src="@/assets/img/aloisio-search.svg"
                class="mb-4"
                style="margin-top: 16px;"
                width="62"
                height="64"
                alt="search"
              >
              <button
                style="height: 32px;line-height: 0;"
                @click="openCreateEditIpModal"
                class="btn bg-gray-blue-300 tx-text-gray px-4"
              >
                {{ $t("add_ip_and_network_range") }}
              </button>
            </td>
          </div>
          <div
            v-else
            class="d-flex justify-content-between align-items-center border"
            style="padding: 16px 24px 24px 24px !important;"
          >
            <div
              class="border rounded d-flex justify-content-between align-items-center w-100"
              style="padding: 16px 24px !important;"
            >
              <div
                class="form-check form-switch pt-1"
                style="line-height: 15px;padding-top: 4px;"
              >
                <input
                  class="form-check-input p-0"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  :checked="isChecked"
                  style="width: 28px !important;height: 16px !important;"
                  @change="toggleNetworkRange"
                >
                <label
                  class="form-check-label tx-text-gray"
                  for="flexSwitchCheckDefault"
                >{{
                  displayRegistrationQuantity
                }}</label>
              </div>
              <button
                class="btn p-0 text-primary"
                @click="editCreateEditIpModal"
              >
                {{ $t("edit") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-edit-ip
      :list-ip="listIpAllowed.ips"
      ref="createEditIpModal"
    />
    <manage-access ref="openOffCanvas" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CreateEditIp from "@dialer/manager/pages/settings/users/components/ip-allowed/create-edit-ip.vue";
import ManageAccess from "@dialer/manager/pages/settings/users/components/ip-allowed/manage-access.vue";
import CardLoading from "@components/card-loading.vue";
import AlertTip from "@dialer/manager/pages/settings/users/components/ip-allowed/alert-tip.vue";

export default {
  name: "IpTableContent",
  components: { AlertTip, CardLoading, ManageAccess, CreateEditIp },
  data() {
    return {
      closeAlert: true
    };
  },
  mounted() {
    this.listIpsAllowed();
    this.closeAlert = true;
  },
  computed: {
    ...mapGetters("manager/user", {
      listIpAllowed: "getListIpAllowed",
      loadingFlag: "getLoadingFlag"
    }),
    displayRegistrationQuantity() {
      if (this.listIpAllowed.quantity > 1) return this.listIpAllowed.quantity + " " + this.$t("registered_plural");
      return this.listIpAllowed.quantity + " " + this.$t("registered");
    },
    isChecked() {
      let active = false;
      if (this.listIpAllowed.ips?.length) {
        active = this.listIpAllowed.ips[0].is_active;
      }
      return active;
    }
  },

  methods: {
    ...mapActions("manager/user", ["listIpsAllowed", "updateIp"]),
    openCreateEditIpModal() {
      this.$refs.createEditIpModal.new();
    },

    editCreateEditIpModal(item) {
      this.$refs.createEditIpModal.edit(item);
    },
    openOffCanvas() {
      this.$refs.openOffCanvas.openOffCanvas();
    },
    toggleNetworkRange() {
      if (this.listIpAllowed.ips.length) {
        const isActive = !this.listIpAllowed.ips[0].is_active;
        this.updateIp({ value: isActive });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.bg-gray-blue-200 {
  background-color: $color-gray-blue-200;
}

.bg-gray-blue-300 {
  background-color: $color-gray-blue-300;
}

.text-tag-blue {
  color: $color-blue-500;
}

.shadow-sm {
  box-shadow: 0 0 2px 0 #e1e9f4 !important;
}
</style>
