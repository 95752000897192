<template>
  <div class="dropdown">
    <button
      aria-expanded="false"
      class="btn p-0 d-flex align-items-center"
      data-bs-toggle="dropdown"
      style="gap: 1rem"
      type="button"
    >
      <h1 class="m-0">
        {{ currentFunnel.name }}
      </h1>
      <div
        v-if="currentFunnel.team"
        class="d-flex align-items-center my-auto gap-1"
      >
        <i
          :style="{'color': currentFunnel.team.color}"
          class="fa-solid fa-circle"
          style="font-size: 0.5rem"
        />
        <p class="m-0 text-small fw-medium lh-sm tx-text-gray">
          {{ currentFunnel.team.name }}
        </p>
      </div>
      <span class="far fa-chevron-down tx-text-gray" />
    </button>
    <div
      class="dropdown-menu select-funnel"
    >
      <h6
        class="mb-0"
        style="padding-bottom: 16px;"
      >
        {{ $t('my_funnels') }}
      </h6>
      <ul
        id="list-group-container"
        class="list-group list-group-flush"
        style="max-height: 450px; overflow-y: scroll;"
      >
        <li
          v-for="item in funnels"
          :key="item.id"
          class="funnels list-group-item cursor-pointer d-flex justify-content-between align-items-center rounded-3"
          style="padding: 16px 12px;"
          @click="selectFunnel(item)"
        >
          <h5 class="fw-normal mb-0">
            {{ item.name }}
          </h5>
          <div
            v-if="item.team"
            class="team-container d-flex align-items-center gap-2 bg-text-light px-3 py-1 rounded-1"
          >
            <i
              :style="{'color': item.team.color}"
              class="fa-solid fa-circle"
            />
            <p class="mb-0">
              {{ item.team.name }}
            </p>
          </div>
        </li>
        <li class="list-group-item cursor-pointer pb-0">
          <button
            v-if="isManager"
            class="btn new-funnel-btn rounded-4 w-100"
            data-bs-target="#newFunnelModal"
            data-bs-toggle="modal"
            @click="$emit('openModal')"
          >
            <i class="fa-regular fa-plus me-2" />
            <span>{{ $t('create_new_funnel') }}</span>
          </button>
        </li>
        <li
          v-if="validationObserver()"
          class="list-group-item border-0 pb-0"
        >
          <infinity-pagination-observer
            :loading="loadingFlags.selectFunnels"
            margin="500px"
            parent-container="list-group-container"
            @reload="nextPage()"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import InfinityPaginationObserver from "@components/infinity-pagination-observer.vue";

export default {
  components: {InfinityPaginationObserver},
  emits: ["openModal"],

  computed: {
    ...mapGetters("auth", {
      isAgent: "isAgent",
      isManager: "isManager",
    }),
    ...mapGetters("crm/funnels", {
      funnels: "getFunnels",
      currentFunnel: "getCurrentFunnel",
      loadingFlags: "getLoadingFlags",
      pagination: "getPagination",
    }),
  },

  methods: {
    ...mapMutations('crm/funnels', [
      'setCurrentFunnel',
    ]),
    ...mapMutations('crm/opportunities', [
      'setOpportunityFilters'
    ]),
    ...mapActions('crm/funnels', [
      'updateLastAccessedFunnel',
      "paginatedFetchFunnels"
    ]),

    selectFunnel(funnel) {
      this.setOpportunityFilters({
        filter: "search",
        value: null
      })
      this.setCurrentFunnel(funnel)
      this.updateLastAccessedFunnel({
        team_id: funnel.team?.id,
        funnel_id: funnel.id
      })
      this.$router.push({
        path:
          (this.isAgent ? `/crm/${funnel.id}` : `/manager/crm/${funnel.id}`)
      })
    },

    nextPage() {
      this.paginatedFetchFunnels({
        funnelId: this.currentFunnel.id,
        page: this.pagination.current_page + 1
      });
    },

    validationObserver() {
      return this.pagination?.current_page <= this.pagination?.last_page && this.pagination?.last_page > 1;
    },
  },

}
</script>

<style lang="scss" scoped>
.select-funnel {
  min-width: 262px;
  max-width: 400px;
  max-height: 600px;
  padding: 16px 21px 19px 21px;
  border: 1px solid #F4F4F4;
  box-shadow: 0 4px 18px 0 rgba(34, 54, 77, 0.25);
  border-radius: 12px 0px 12px 12px;
  left: 2px !important;
}

.btn .btn-secondary {
  background-color: transparent;
  border-color: transparent;

  &:active, &:focus {
    box-shadow: none !important;
  }
}

.dropdown-toggle {
  &:after {
    font-size: 24px;
    color: #677C92;
  }
}

.funnels:hover {
  background-color: $color-gray-blue-300;
}

.team-container > p, h5 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.team-container {
  max-width: 45%;
  overflow: hidden;

  i {
    font-size: 0.5rem
  }
}

h5 {
  max-width: 50%;
}

.new-funnel-btn {
  background-color: $color-gray-blue-300;
  color: $color-text-gray;

  &:hover {
    background-color: $color-gray-200;
  }
}
</style>
