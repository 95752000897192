<template>
  <div class="col-12 col-lg-10 col-xl-8">
    <main-loading v-if="!asyncReady" />
    <div v-if="asyncReady">
      <header-component
        :pretitle="$t('edit_user')"
        :title="fields.name"
        redirect-link="/manager/users"
      />
      <div class="col-12 col-md-12">
        <label class="form-label text-capitalize">
          {{ $t("role") }}
        </label>
        <div class="form-group row">
          <div
            class="col-12 col-lg-6 col-xl cursor-pointer"
            @click="updateRole('agent')"
          >
            <div class="card card-fill">
              <div class="card-body">
                <div class="row align-items-center gx-0">
                  <div class="col-auto">
                    <span class="h2 fe fe-headphones text-muted me-3" />
                  </div>
                  <div class="col">
                    <h4 class="text-capitalize">
                      {{ $t("operator") }}
                    </h4>
                    <span class="text-muted text-medium d-block fw-medium">
                      {{ $t("make_and_receive_calls") }}
                    </span>
                  </div>
                  <div class="col-auto">
                    <input
                      id="checklistOne"
                      v-model="fields.role"
                      class="form-check-input"
                      name="group"
                      type="radio"
                      value="agent"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-lg-6 col-xl cursor-pointer"
            @click="updateRole('supervisor')"
          >
            <div class="card card-fill">
              <div class="card-body">
                <div class="row align-items-center gx-0">
                  <div class="col-auto">
                    <span class="h2 fe fe-clipboard text-muted me-3" />
                  </div>
                  <div class="col">
                    <h4>{{ $t("supervisor_capitalize") }}</h4>
                    <span class="text-muted text-medium d-block fw-medium">
                      {{ $t("supervisor_operation") }}
                    </span>
                  </div>
                  <div class="col-auto">
                    <input
                      id="checklistOne"
                      v-model="fields.role"
                      class="form-check-input"
                      name="group"
                      type="radio"
                      value="supervisor"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-12 col-lg-6 col-xl cursor-pointer"
            @click="updateRole('manager')"
          >
            <div class="card card-fill">
              <div class="card-body">
                <div class="row align-items-center gx-0">
                  <div class="col-auto">
                    <span class="h2 fe fe-clipboard text-muted me-3" />
                  </div>
                  <div class="col">
                    <h4 class="text-capitalize">
                      {{ $t("manager") }}
                    </h4>
                    <span class="text-muted text-medium d-block fw-medium">
                      {{ $t("manage_operation") }}
                    </span>
                  </div>
                  <div class="col-auto">
                    <input
                      id="checklistOne"
                      v-model="fields.role"
                      class="form-check-input"
                      name="group"
                      type="radio"
                      value="manager"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label class="form-label">
                {{ $t("name") }}
              </label>

              <input
                v-model="fields.name"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.name
                }"
                class="form-control"
                type="text"
              >
              <div class="invalid-feedback">
                {{ errors.name && errors.name[0] }}
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <div class="row">
                <div class="col">
                  <label class="form-label text-capitalize">
                    {{ $t("extension") }}
                  </label>

                  <input
                    v-model="fields.extension_number"
                    v-maska
                    :class="{
                      'form-control': true,
                      'is-invalid': errors.extension_number
                    }"
                    class="form-control"
                    data-maska="#########"
                    type="number"
                  >
                  <div class="invalid-feedback">
                    {{ errors.extension_number && errors.extension_number[0] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="fields.role == 'supervisor' || fields.role == 'manager'"
            class="col-12 col-md-6"
          >
            <div class="form-group">
              <div class="row">
                <div class="col">
                  <label class="form-label">
                    Email
                  </label>

                  <input
                    v-model="fields.email"
                    :class="{
                      'form-control': true,
                      'is-invalid': errors.email
                    }"
                    class="form-control"
                    type="email"
                  >
                  <div class="invalid-feedback">
                    {{ errors.email && errors.email[0] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="[!user.company.ring_group_licenses && fields.role === 'agent' ? 'col-12' : 'col-6']">
            <div class="form-group">
              <div class="row">
                <div class="col">
                  <label class="form-label text-capitalize">
                    {{ $t("password") }}
                  </label>

                  <div class="input-group input-group-merge">
                    <input
                      id="password"
                      v-model="fields.password"
                      :class="{
                        'form-control': true,
                        'is-invalid': errors.password
                      }"
                      :type="showPassword ? 'text' : 'password'"
                      class="form-control"
                    >
                    <div
                      class="input-group-text"
                      @click="showPassword = !showPassword"
                    >
                      <i
                        v-if="!showPassword"
                        class="fe fe-eye"
                      />
                      <i
                        v-else
                        class="fe fe-eye-off"
                      />
                    </div>
                  </div>
                  <small
                    v-if="fields.password.length > 0"
                    class="text-muted"
                  >
                    {{ $t("characters_validation") }}
                  </small>
                  <small class="text-danger">
                    {{ errors.password && errors.password[0] }}
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="user.company.ring_group_licenses && fields.role === 'agent'"
            class="col-6"
            style="margin-top: 30px;"
          >
            <div class="d-flex align-items-center justify-content-between mb-2">
              <div class="form-check form-switch">
                <input
                  id="ringGroup"
                  v-model="ring_group"
                  :disabled="user.company.ring_group_licenses - used_ring_group_licenses <= 0 && !ring_group"
                  class="form-check-input"
                  type="checkbox"
                  @input="updateUsedRingGroupLicenses"
                >
                <label
                  class="form-check-label"
                  for="ringGroup"
                >
                  {{ $t("extension_function") }}
                </label>
                <tooltip-component
                  :title="$t('extension_function_definition')"
                  class="ms-2 tx-text-gray"
                  icon="info"
                />
              </div>
              <div>
                <span class="tx-text-gray">{{ $t("available_licenses") }}</span>
                {{ user.company.ring_group_licenses - used_ring_group_licenses }}/{{ user.company.ring_group_licenses }}
              </div>
            </div>
            <small class="form-text text-muted text-medium">
              {{ $t("function_external_and_internal_calls") }}
            </small>

            <small class="form-text text-danger text-medium">
              {{ errors.permissions && errors.permissions[0] }}
            </small>
          </div>

          <div v-if="fields.role == '' || fields.role == 'supervisor'">
            <div class="form-group">
              <label class="form-label"> {{ $t("teams_capitalize") }} <small class="text-danger">*</small> </label>
              <VueMultiselect
                v-model="fields.teams"
                :class="{
                  'is-invalid': errors.teams
                }"
                :close-on-select="false"
                :deselect-label="$t('click_or_enter_to_remove')"
                :multiple="true"
                :options="list.teams"
                :placeholder="$t('select_teams')"
                :preserve-search="true"
                :select-label="$t('click_or_enter_to_select')"
                :selected-label="$t('selected')"
                label="name"
                track-by="id"
              />

              <div class="invalid-feedback">
                {{ errors.teams && errors.teams[0] }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-12 d-flex align-items-center justify-content-center cursor-pointer text-primary mb-4"
          @click="showAdvancedOptions = !showAdvancedOptions"
        >
          <span
            v-if="!showAdvancedOptions"
            class="fs-3 me-2"
          >
            +
          </span>
          <span
            v-else
            class="fs-3"
          >
            -
          </span>
          {{ $t("advanced_options") }}.
        </div>
        <template v-if="showAdvancedOptions">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="form-label">
                  CPF
                </label>

                <input
                  v-model="fields.user_document"
                  v-maska
                  :class="{
                    'form-control': true,
                    'is-invalid': errors.user_document
                  }"
                  class="form-control"
                  data-maska="###.###.###-##"
                  type="text"
                >

                <div class="invalid-feedback">
                  {{ errors.user_document && errors.user_document[0] }}
                </div>
              </div>
            </div>
            <div
              v-if="fields.role == 'agent' || fields.role == ''"
              class="col-12 col-md-6"
            >
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <label class="form-label">
                      Email
                    </label>

                    <input
                      v-model="fields.email"
                      :class="{
                        'form-control': true,
                        'is-invalid': errors.email
                      }"
                      class="form-control"
                      type="email"
                    >
                    <div class="invalid-feedback">
                      {{ errors.email && errors.email[0] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12"
              v-if="fields.role == 'agent' || fields.role == ''"
            >
              <div class="form-group">
                <label class="mb-1"> {{ $t("campaigns") }} <small class="text-danger">*</small> </label>

                <VueMultiselect
                  v-model="fields.campaigns"
                  :close-on-select="false"
                  :deselect-label="$t('click_or_enter_to_remove')"
                  :multiple="true"
                  :options="list.campaigns.active"
                  :placeholder="$t('select_campaigns')"
                  :preserve-search="true"
                  :select-label="$t('click_or_enter_to_select')"
                  :selected-label="$t('selected')"
                  label="name"
                  track-by="id"
                />
              </div>
            </div>
            <div
              v-if="fields.role == 'agent' || fields.role == ''"
              class="col-12"
            >
              <div class="form-group">
                <label class="mb-1">
                  {{ $t("receptive_queues").toLowerCase().replace(/(?<!\p{L})\p{L}(?=\p{L}{2})/gu, m =>
                    m.toUpperCase()) }} <small class="text-danger">*</small>
                </label>
                <VueMultiselect
                  v-model="fields.queues"
                  :close-on-select="false"
                  :deselect-label="$t('click_or_enter_to_remove')"
                  :multiple="true"
                  :options="list.queues"
                  :placeholder="$t('select_receptive_queues')"
                  :preserve-search="true"
                  :select-label="$t('click_or_enter_to_select')"
                  :selected-label="$t('selected')"
                  label="name"
                  track-by="id"
                />
              </div>
            </div>
            <div
              v-if="fields.role == 'agent' || fields.role == ''"
              class="col-12 col-md-6"
            >
              <div class="form-group">
                <label class="form-label text-capitalize">
                  {{ $t("teams") }} <small class="text-danger">*</small>
                </label>
                <VueMultiselect
                  v-model="fields.teams"
                  label="name"
                  track-by="id"
                  :placeholder="$t('select_teams')"
                  :select-label="$t('click_or_enter_to_select')"
                  :deselect-label="$t('click_or_enter_to_remove')"
                  :selected-label="$t('selected')"
                  :multiple="true"
                  :close-on-select="false"
                  :preserve-search="true"
                  :options="list.teams"
                  :class="{
                    'is-invalid': errors.teams
                  }"
                />

                <div class="invalid-feedback">
                  {{ errors.teams && errors.teams[0] }}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="form-label">
                  {{ $t("time_zone_capitalize") }} <small class="text-danger">*</small>
                </label>
                <select
                  v-model="fields.timezone"
                  :aria-label="$t('time_zone_capitalize')"
                  :class="{
                    'form-control': true,
                    'is-invalid': errors.timezone
                  }"
                  class="form-select"
                >
                  <option
                    v-for="timezone in timezones"
                    :key="timezone.value"
                    :value="timezone.value"
                  >
                    {{ timezone.text_value }}
                  </option>
                </select>
                <div class="invalid-feedback">
                  {{ errors.timezone && errors.timezone[0] }}
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label class="mb-1">
                  {{ $t("api_token") }}
                </label>

                <div class="input-group input-group-merge">
                  <input
                    v-model="fields.api_token"
                    class="form-control"
                    disabled
                    type="text"
                  >
                  <button
                    class="btn btn-primary"
                    @click="fields.api_token = generateRandomCharacters(60)"
                  >
                    <span class="fe fe-refresh-ccw" />
                  </button>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="form-label text-capitalize">{{ $t("user") }}</label>
                <div class="mb-2 input-group input-group-merge">
                  <input
                    :value="this.fields.telephony_id"
                    class="form-control"
                    disabled
                    type="text"
                  >
                  <div class="input-group-text list-group-item-light">
                    <label
                      class="fe fe-lock"
                      for=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="form-label">{{ $t("extension_password") }}</label>
                <div class="mb-2 input-group input-group-merge">
                  <input
                    :value="this.fields.extension_password"
                    class="form-control"
                    disabled
                    type="text"
                  >
                  <div class="input-group-text list-group-item-light">
                    <label
                      class="fe fe-lock"
                      for=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <div class=" mb-2 d-flex align-items-center">
                      <div class="col-auto form-check form-switch">
                        <input
                          id="externalSIP"
                          v-model="external_sip"
                          class="form-check-input"
                          type="checkbox"
                        >
                        <label
                          class="form-check-label"
                          for="externalSIP"
                        >
                          {{ $t("connect_sip") }}
                        </label>
                      </div>
                    </div>
                    <small class="form-text text-muted text-medium">{{ $t("connect_sip_description") }}</small>
                  </div>

                  <div
                    v-if="fields.role == 'supervisor'"
                    class="col-6"
                  >
                    <div class="mb-2 d-flex align-items-center">
                      <div class="col-auto form-check form-switch">
                        <input
                          id="allowMailingVisualization"
                          v-model="isVisualizationAllowed"
                          class="form-check-input"
                          type="checkbox"
                        >
                        <label
                          class="form-check-label"
                          for="allowMailingVisualization"
                        >
                          {{ $t("show_campaign_list") }}
                        </label>
                      </div>
                    </div>
                    <small class="form-text text-muted text-medium">{{ $t("show_campaign_list_description") }}</small>
                  </div>

                  <div class="col-6">
                    <div class="d-flex mb-2 align-items-center">
                      <div class="col-auto form-check form-switch">
                        <input
                          id="webphone"
                          v-model="webphone"
                          class="form-check-input"
                          type="checkbox"
                        >
                        <label
                          class="form-check-label"
                          for="webphone"
                        >
                          {{ $t("enable_webphone") }}
                        </label>
                      </div>
                    </div>
                    <small class="form-text text-muted text-medium">{{ $t("enable_webphone_description") }}</small>
                  </div>

                  <div
                    v-if="fields.role == 'supervisor'"
                    class="col-6"
                  >
                    <div class="mb-2 d-flex align-items-center">
                      <div class="col-auto form-check form-switch">
                        <input
                          id="manageLists"
                          v-model="isCampaignListManagementAllowed"
                          class="form-check-input"
                          type="checkbox"
                        >
                        <label
                          class="form-check-label"
                          for="manageLists"
                        >
                          {{ $t("manage_campaign_list") }}
                        </label>
                      </div>
                    </div>
                    <small class="form-text text-muted text-medium">{{ $t("manage_campaign_list_description") }}</small>
                  </div>

                  <div
                    v-if="fields.role == 'supervisor' || fields.role == 'manager'"
                    class="col-6"
                  >
                    <div class="mb-2 d-flex align-items-center">
                      <div class="col-auto form-check form-switch">
                        <input
                          id="blockSpy"
                          v-model="isSpyModeAllowed"
                          class="form-check-input"
                          type="checkbox"
                        >
                        <label
                          class="form-check-label"
                          for="blockSpy"
                        >
                          {{ $t("block_spy_on_user") }}
                        </label>
                      </div>
                    </div>
                    <small class="form-text text-muted text-medium">{{ $t("block_spy_on_user_description") }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div
          v-if="active === 1"
          class="col-lg-12"
        >
          <app-button
            :loading="loading"
            class="fw mb-4 text-capitalize"
            @click="submit()"
          >
            {{ $t("update") }}
          </app-button>
        </div>

        <div
          v-else
          class="col-lg-12"
        >
          <app-button
            :loading="loading"
            class="fw mb-4"
            @click="submit()"
          >
            {{ $t("to_active") }}
          </app-button>
        </div>

        <div
          v-if="user.id != register.id"
          class="col-lg-12"
        >
          <app-button
            class="fw mb-4"
            color="text-danger text-capitalize"
            v-if="active === 1"
            @click="deactivate_"
          >
            <span class="fe fe-user-x" />
            {{ $t("to_deactivate") }}
          </app-button>
        </div>

        <modal-handle-scheduled-calls :name="fields.name" />
        <modal-transfer-whatsapp-chats
          v-if="Object.values(errors).length && errors.agent"
          :errors="errors"
          :team-id="register.teams[0].id"
          :teams-user="register.teams"
          @handle-submit="willDeactivate ? deactivate_() : submit()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import appButton from "@/components/app-button.vue";
import mainLoading from "@/components/main-loading.vue";

import VueMultiselect from "vue-multiselect";
import { timezones } from "@/utils";
import ModalHandleScheduledCalls from "./components/modal-handle-scheduled-calls.vue";
import ModalTransferWhatsappChats
  from "@dialer/manager/pages/settings/teams/components/modal-transfer-whatsapp-chats.vue";
import HeaderComponent from "@/components/header-component.vue";
import TooltipComponent from "@/components/tooltip-component.vue";

export default {
  name: "EditUser",
  data() {
    return {
      timezones: [],
      languages: [],
      fields: {
        name: "",
        email: "",
        extension_number: 0,
        password: "",
        role: "",
        timezone: "",
        user_document: "",
        campaigns: [],
        queues: [],
        teams: [],
        telephony_id: "",
        extension_password: "",
        api_token: "",
        permissions: [],
      },
      active: 0,
      willDeactivate: false,
      webphone: false,
      external_sip: false,
      asyncReady: false,
      showPassword: false,
      showWarningModal: true,
      showAdvancedOptions: false,
      isCampaignListManagementAllowed: false,
      isSpyModeAllowed: true,
      isVisualizationAllowed: false,
      ring_group: false,
      used_ring_group_licenses: 0,
    };
  },

  components: {
    appButton,
    mainLoading,
    VueMultiselect,
    ModalHandleScheduledCalls,
    ModalTransferWhatsappChats,
    HeaderComponent,
    TooltipComponent,
  },

  computed: {
    ...mapGetters("manager/user", {
      list: "getList",
      loading: "isLoading",
      register: "getRegister",
      errors: "getErrors",
    }),
    ...mapGetters("auth", {
      user: "getUser",
    }),
  },

  watch: {
    isCampaignListManagementAllowed(newVal) {
      if (newVal && !this.isVisualizationAllowed) this.isVisualizationAllowed = true;
    },
    isVisualizationAllowed(newVal) {
      if (!newVal && this.isCampaignListManagementAllowed) this.isCampaignListManagementAllowed = false;
    },
  },

  methods: {
    ...mapActions("manager/user", ["update", "findById", "deactivate"]),
    ...mapActions("system", ["showConfirmationMessage"]),
    ...mapActions("auth", ["me"]),
    ...mapMutations("auth", ["trackEvents"]),

    submit() {
      let fields = { ...this.fields };

      fields.campaigns = fields.campaigns.map(({ id }) => id);
      fields.queues = fields.queues.map(({ id }) => id);
      fields.teams = fields.teams.map(({ id }) => id);
      fields.web_extension = !this.external_sip;

      if (this.isCampaignListManagementAllowed) this.fields.permissions.push("manage.lists.in.campaign");
      if (this.isVisualizationAllowed) this.fields.permissions.push("view.lists.in.campaign");
      if (this.webphone) this.fields.permissions.push("users.features.webphone");
      if (this.ring_group) this.fields.permissions.push("ring.group.access");

      // All users are enable to use spy mode, but there's a permission to remove this feature for the user
      if (!this.isSpyModeAllowed) this.fields.permissions.push("users.features.blockspy");

      if (fields.user_document) fields.user_document = fields.user_document.replace(/[^0-9]/g, "");
      else fields.user_document = "";

      this.update({ fields: fields, id: this.register.id }).then(() => {
        this.me();
        this.$router.push({
          path: `/manager/users/`,
        });
      });
    },

    /**
     * Deactivate user
     */
    async deactivate_() {
      this.willDeactivate = true;
      let response = await this.showConfirmationMessage({
        title: this.$t("deactivate_user_question"),
        text: "",
        type: "badWarning",
      });

      if (response.isConfirmed) {
        this.deactivate({ id: this.register.id }).then(() => {
          this.trackEvents("[Manager] User Deleted");
          this.$router.push({ path: "/manager/users" });
        });
      }
    },

    updateRole(el) {
      this.fields.role = el;
    },

    generateRandomCharacters(lenght) {
      var length = lenght,
          charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
          retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }

      return retVal;
    },

    async awaitDispatches() {
      await this.$store.dispatch("manager/user/listFilters");
      await this.$store
          .dispatch("manager/user/findById", {
            id: this.$router.currentRoute._rawValue.params.id,
          })
          .then(() => {
            this.fields.name = this.register.name;
            this.fields.email = this.register.email;
            this.fields.extension_number = this.register.extension.extension_number;
            this.fields.role = this.register.role.name;
            this.fields.timezone = this.register.settings.timezone;
            this.fields.user_document = this.register.user_document;
            this.fields.campaigns = this.register.campaigns;
            this.fields.teams = this.register.teams;
            this.fields.queues = this.register.receptive_queues;
            this.fields.telephony_id = this.register.telephony_id;
            this.fields.extension_password = this.register.extension_password;
            this.fields.api_token = this.register.api_token;
            this.isCampaignListManagementAllowed = !!this.register.permissions.find(
                p => p.name == "manage.lists.in.campaign",
            );
            this.isVisualizationAllowed = !!this.register.permissions.find(p => p.name == "view.lists.in.campaign");
            this.isSpyModeAllowed = this.register.permissions.find(p => p.name == "users.features.blockspy") ? false : true;
            this.active = this.register.active;
            this.webphone = this.register.webphone;

            //  The external sip is only active when the web extension is inactive
            this.external_sip = !this.register.settings.web_extension;
            this.ring_group = !!this.register.permissions.find(p => p.name == "ring.group.access");

            this.used_ring_group_licenses = this.user.company.used_ring_group_licenses;
          });
    },

    updateUsedRingGroupLicenses() {
      if (!this.ring_group) {
        this.used_ring_group_licenses++;
      } else {
        this.used_ring_group_licenses--;
      }
    },
  },

  async mounted() {
    await this.awaitDispatches();
    this.asyncReady = true;
    this.timezones = timezones;
  },
};
</script>
