<template>
  <div class="main-content pb-6 utilization-report">
    <div class="container-fluid">
      <header-component
        :title="$t('manual_calls')"
        :pretitle="$t('uppercases.reports')"
        redirect-link="/manager/reports"
      />

      <form
        @submit.prevent="submit()"
        novalidate
      >
        <div class="row">
          <div class="col-lg-4">
            <div class="form-group">
              <label
                for="start-date"
                class="form-label"
              >
                {{ $t('period') }}
              </label>
              <input-date-range
                preset="month"
                v-model:start="startDate"
                v-model:end="endDate"
                :time-enabled="true"
                :seconds-enabled="true"
                format-date="d/m/Y H:i:S"
              />
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group">
              <label
                for="campaign"
                class="form-label"
              >
                {{ $t('campaign_capitalize') }}
              </label>
              <select-campaign v-model="campaign" />
            </div>
          </div>
          <div class="col-lg-4">
            <label class="form-label">&nbsp;</label>
            <div>
              <app-button
                class="form-control px-3"
                :loading="loading.searchManualCalls"
                :blocked="!campaign"
              >
                {{ $t('search_report') }}
              </app-button>
            </div>
          </div>
        </div>
      </form>

      <div class="tab-content">
        <div
          class="tab-pane fade show active"
          id="contactsListPane"
          role="tabpanel"
          aria-labelledby="contactsListTab"
        >
          <div class="card">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <h4 class="card-header-title fw-normal">
                    {{ $t('manual_calls_report') }}
                  </h4>
                </div>

                <div class="col-auto">
                  <a
                    href="#"
                    class="btn btn-outline-secondary"
                    @click="generateCSVModal.show()"
                  >{{ $t('download_CSV') }}</a>
                </div>
              </div>
            </div>

            <div
              class="table-responsive"
              id="table-manual-calls"
            >
              <table class="table table-hover table-nowrap card-table">
                <thead>
                  <tr>
                    <th>
                      <a
                        class="sort list-sort text-muted fw-normal"
                        data-sort="item-agent"
                        href="#"
                        @click.prevent="sort('agent')"
                      >{{ $t('agent_capitalize') }}</a>
                    </th>
                    <th>
                      <a
                        class="list-sort text-muted fw-normal"
                        data-sort="item-accomplished"
                        href="#"
                        @click.prevent="sort('manual_calls_made')"
                      >{{ $t('made') }}</a>
                    </th>
                    <th>
                      <a
                        class="list-sort text-muted fw-normal"
                        data-sort="item-answered"
                        href="#"
                        @click.prevent="sort('manual_calls_answered')"
                      >{{ $t('answered') }}</a>
                    </th>
                    <th>
                      <a
                        class="list-sort text-muted fw-normal"
                        data-sort="item-answered"
                        href="#"
                        @click.prevent="sort('manual_calls')"
                      >{{ $t('manual_access') }}</a>
                    </th>
                    <th>
                      <a
                        class="list-sort text-muted fw-normal"
                        data-sort="item-total-time"
                        href="#"
                        @click.prevent="sort('manual')"
                      >{{ $t('total_time_capitalize') }}</a>
                    </th>
                    <th>
                      <a
                        class="list-sort text-muted fw-normal"
                        data-sort="item-calling"
                        href="#"
                        @click.prevent="sort('manual_calls_calling')"
                      >{{ $t('calling_capitalize') }}</a>
                    </th>
                    <th>
                      <a
                        class="list-sort text-muted fw-normal"
                        data-sort="item-talking"
                        href="#"
                        @click.prevent="sort('manual_calls_speaking')"
                      >{{ $t('talking_capitalize') }}</a>
                    </th>
                    <th>
                      <a
                        class="list-sort text-muted fw-normal"
                        data-sort="item-total-time-tpa"
                        href="#"
                        @click.prevent="sort('manual_acw')"
                      >{{ $t('total_time_capitalize') }} <br>{{ $t('in_act') }}</a>
                    </th>
                    <th>
                      <a
                        class="list-sort text-muted fw-normal"
                        data-sort="item-calling-tpa"
                        href="#"
                        @click.prevent="sort('manual_calls_acw_calling')"
                      >{{ $t('calling_capitalize') }} <br>{{ $t('in_act') }}</a>
                    </th>
                    <th>
                      <a
                        class="list-sort text-muted fw-normal"
                        data-sort="item-talking-tpa"
                        href="#"
                        @click.prevent="sort('manual_calls_acw_speaking')"
                      >{{ $t('talking_capitalize') }} <br>
                        {{ $t('in_act') }}</a>
                    </th>
                    <th>
                      <a
                        class="list-sort text-muted fw-normal"
                        data-sort="item-accomplished-tpa"
                        href="#"
                        @click.prevent="sort('manual_calls_acw_made')"
                      >{{ $t('calls_made_in_act') }} <br>{{ $t('in_act') }}</a>
                    </th>
                    <th>
                      <a
                        class="list-sort text-muted fw-normal"
                        data-sort="item-answered-tpa"
                        href="#"
                        @click.prevent="sort('manual_calls_acw_answered')"
                      >{{ $t('answered_calls_in_act') }} <br>
                        {{ $t('in_act') }}</a>
                    </th>
                  </tr>
                </thead>
                <tbody
                  class="list fs-base"
                  v-if="tableList.length > 0"
                >
                  <tr
                    v-for="(item, index) in tableList"
                    :key="index"
                  >
                    <td>
                      <span>{{ item.agent.name }}</span>
                    </td>
                    <td>
                      <span>{{ item.manual_calls_made }}</span>
                    </td>
                    <td>
                      <span>{{ item.manual_calls_answered }}</span>
                    </td>
                    <td>
                      <span>{{ item.manual_calls }}</span>
                    </td>
                    <td>
                      <span>{{ convertSecondsToTime(item.manual) }}</span>
                    </td>
                    <td>
                      <span>{{ convertSecondsToTime(item.manual_calls_calling) }}</span>
                    </td>
                    <td>
                      <span>{{ convertSecondsToTime(item.manual_calls_speaking) }}</span>
                    </td>
                    <td>
                      <span>{{ convertSecondsToTime(item.manual_acw) }}</span>
                    </td>
                    <td>
                      <span>{{ convertSecondsToTime(item.manual_calls_acw_calling) }}</span>
                    </td>
                    <td>
                      <span>{{ convertSecondsToTime(item.manual_calls_acw_speaking) }}</span>
                    </td>
                    <td>
                      <span>{{ item.manual_calls_acw_made }}</span>
                    </td>
                    <td>
                      <span>{{ item.manual_calls_acw_answered }}</span>
                    </td>
                  </tr>
                </tbody>
                <tfoot
                  class="list fs-base"
                  v-if="tableList.length > 0"
                >
                  <tr class="py-4">
                    <td
                      v-for="(value, key) in tableFooter"
                      :key="key"
                      class="py-4 fw-bold"
                      :class="key == 'name' && 'ps-4'"
                    >
                      <span v-if="isTime(key)">{{ convertSecondsToTime(value) }}</span>
                      <span v-else> {{ value }} </span>
                    </td>
                  </tr>
                </tfoot>
                <tbody
                  class="list fs-base"
                  v-else-if="tableList.length == 0 && !showSearchMessage"
                >
                  <td colspan="99">
                    <p
                      class="card-body text-center my-3"
                      style="margin: 0;"
                    >
                      {{ $t('no_call_on_period') }}
                    </p>
                  </td>
                </tbody>
                <tbody
                  class="list fs-base"
                  v-else
                >
                  <td colspan="99">
                    <p
                      class="card-body text-center my-3"
                      style="margin: 0;"
                    >
                      {{ $t('search_manual_calls') }}
                    </p>
                  </td>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="modalDownloadCSV"
          tabindex="-1"
          aria-labelledby="modalDownloadCSVLabel"
          aria-hidden="true"
          ref="generateCSVModal"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h3
                  class="modal-title"
                  id="modalDownloadCSVLabel"
                >
                  {{ $t('generate_report') }}
                </h3>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div class="modal-body">
                <p>
                  {{
                    $t('send_report_to_email')
                  }}
                </p>
                <form>
                  <div class="mb-3">
                    <label
                      for="input-email"
                      class="col-form-label"
                    >{{ $t('report_email') }}</label>
                    <input
                      type="email"
                      class="form-control"
                      id="input-email"
                      v-model="email"
                    >
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  {{ $t('cancel_general') }}
                </button>
                <app-button
                  @click="generateCSV"
                  :loading="loading.downloadTable"
                  style="min-width: 6.5rem;"
                >
                  {{ $t('generate_csv') }}
                </app-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import { convertSecondsToTime } from "@/utils.js";

import AppButton from "@/components/app-button.vue";
import InputDateRange from "@/components/input-date-range.vue";
import SelectCampaign from "@dialer/manager/components/select-campaign.vue";
import HeaderComponent from "@/components/header-component.vue";
import { Modal } from "bootstrap";

export default {
  data() {
    return {
      campaigns: [],
      campaign: "",
      showSearchMessage: true,
      email: "",
      dir: "asc",
      startDate: "",
      endDate: "",
      tableList: [],
      tableFooter: {},
      generateCSVModal: null
    };
  },
  components: {
    AppButton,
    InputDateRange,
    SelectCampaign,
    HeaderComponent
  },
  computed: {
    ...mapGetters("manager/filters", {
      filters: "getFilters"
    }),
    ...mapGetters("auth", {
      user: "getUser"
    }),
    ...mapGetters("manager/manual_calls", {
      list: "getList",
      loading: "getLoadingFlags"
    })
  },
  methods: {
    convertSecondsToTime,
    ...mapActions("manager/manual_calls", ["searchManualCalls", "downloadTable"]),
    ...mapMutations("manager/manual_calls", ["sortList", "setList"]),
    ...mapActions("manager/filters", ["findFilters"]),
    ...mapMutations("manager/filters", ["setIncludes"]),
    submit() {
      this.searchManualCalls({
        campaignId: this.campaign,
        startDate: this.startDate,
        endDate: this.endDate
      }).then(() => {
        this.tableList = this.list;
        this.showSearchMessage = false;
        this.generateFooterTotal();
      });
    },
    generateCSV() {
      this.downloadTable({
        campaignId: this.campaign,
        startDate: this.startDate,
        endDate: this.endDate,
        csv: 1,
        email: this.email
      }).then(() => this.generateCSVModal.hide());
    },
    sort(currentSort) {
      this.sortList({
        dir: this.dir,
        currentSort: currentSort
      });
      this.dir = this.dir == "asc" ? "desc" : "asc";
    },
    generateFooterTotal() {
      this.tableFooter = {
        name: "TOTAL",
        manual_calls_made: 0,
        manual_calls_answered: 0,
        manual_calls: 0,
        manual: 0,
        manual_calls_calling: 0,
        manual_calls_speaking: 0,
        manual_acw: 0,
        manual_calls_acw_calling: 0,
        manual_calls_acw_speaking: 0,
        manual_calls_acw_made: 0,
        manual_calls_acw_answered: 0
      };

      let manualCalls = this.tableList;
      manualCalls.forEach(manualCall => {
        for (const key in manualCall) {
          if (this.tableFooter[key] !== undefined) this.tableFooter[key] += manualCall[key];
        }
      });
    },
    isTime(key) {
      let timers = {
        manual: true,
        manual_calls_calling: true,
        manual_calls_speaking: true,
        manual_acw: true,
        manual_calls_acw_calling: true,
        manual_calls_acw_speaking: true
      };

      return timers[key];
    }
  },
  watch: {
    list(data) {
      if (data.length) this.tableList = [];
    },
    filters: {
      handler(data) {
        if (data.campaigns) {
          this.campaigns = data.campaigns;
        }
      },
      deep: true
    }
  },
  mounted() {
    this.findFilters();
    this.email = this.user.email;
    this.generateCSVModal = new Modal(this.$refs.generateCSVModal);
  }
};
</script>

<style scoped lang="scss">
.header-body {
  border-bottom: none !important;
}

.rounded-calendar {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.table > :not(:first-child) {
  border-top: none;
}

.table-hover tfoot tr:hover td {
  background-color: white;
}
</style>
