export default {
  getLoadingFlags: state => state.loadingFlags,
  getList: state => state.list,
  getAlert: state => state.alert,
  getErrors: state => state.errors,
  getErrorFlags: state => state.errorFlags,
  getFilters: (state) => state.filters,
  getPagination: (state) => state.pagination,
  getRoutes: (state) => state.routes,
  getRouteGroups: (state) => state.routeGroups,
  getUser: (state) => state.user,
  getParams: (state) => (flag) => {
    return Object.assign(
      {
        "page": state.pagination[flag].current_page,
        "per_page": state.pagination[flag].per_page,
        "search": state.pagination[flag].search,
      })
  },
}