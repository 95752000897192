import { AgentStatus } from "@/utils.js";
import _ from "lodash";

/**
 *
 * @returns
 */
 const onlineAgentsCount = (status_count) => {
  let total = 0
  _.forEach(status_count, (value, key) => {
    key = parseInt(key)
    if (key !== AgentStatus.ONLINE && key !== AgentStatus.OFFLINE) {
      total += value
    }
  })

  return total
}

export default {
  clearAgentsStatus: (state) => {
    state.status_count = {
      [AgentStatus.ONLINE]: 0,
      [AgentStatus.ON_CALL]: 0,
      [AgentStatus.ACW]: 0,
      [AgentStatus.ON_MANUAL_CALL]: 0,
      [AgentStatus.ON_MANUAL_CALL_ACW]: 0,
      [AgentStatus.IDLE]: 0,
      [AgentStatus.OFFLINE]: 0,
      [AgentStatus.ON_WORK_BREAK]: 0
    };
    state.agents = [];
    state.total = 0;
  },
  setAgentsStatus: (state, agentsStatus) => {
    for (let index in agentsStatus) {
      state.agents[index] = agentsStatus[index];
      const agent = state.agents[index];
      agent.counter = agent.count_status_start_time;
      agent.interval = null;
      state.status_count[agent.status]++;

      if (agent.status !== AgentStatus.OFFLINE) {
        agent.interval = setInterval(() => {
          agent.counter++;
        }, 1000);
      }

      if (agent.status !== AgentStatus.ONLINE) state.total++;
    }
    state.status_count[AgentStatus.ONLINE] = onlineAgentsCount(state.status_count);
  },

  setWorkBreak (state, payload) {
    for (let index in state.agents) {
      const agent = state.agents[index]
      if (agent.id === payload.id) {
        if (payload.work_break) {
          agent.work_break = payload.work_break
          agent.work_break.minutes = payload.work_break.minutes * 60
        } else {
          agent.work_break = null
        }
      }
    }
  },

  setQueueAgentStatus (state, payload) {
    for (let index in state.agents) {
      const agent = state.agents[index];
      if (agent.id === payload.id) {
        state.status_count[agent.status]--
        state.status_count[payload.status]++

        agent.status = payload.status
        agent.call_mode = payload.call_mode;

        switch (payload.connected) {
          case AgentStatus.ON_MANUAL_CALL_CONNECTED:
            agent.manual_call_status = AgentStatus.ON_MANUAL_CALL_CONNECTED
            break
          case AgentStatus.ON_MANUAL_CALL_ACW_CONNECTED:
            agent.manual_call_acw_status = AgentStatus.ON_MANUAL_CALL_ACW_CONNECTED
            break
          default:
            agent.manual_call_status = null
            agent.manual_call_acw_status = null
        }

        if (agent.status !== AgentStatus.OFFLINE) {
          clearInterval(agent.interval)
          agent.counter = 0
          agent.interval = setInterval(() => {
            agent.counter++
          }, 1000)
        }
      }
    }

    state.status_count[AgentStatus.ONLINE] = onlineAgentsCount(state.status_count);
  },
}
