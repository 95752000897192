import store from "@/store";
import Financial from "@admin/pages/3c-partners/partners/finance-partners.vue";

export default {
  path: "financial",
  name: "financial",
  components: {
    main: Financial
  },

  beforeEnter: (to, from, next) => {
    const restrictedAdminLevels =
      store.getters["admin/getRestrictedAdminLevels"];
    if (
      restrictedAdminLevels.includes(
        store.getters["auth/getUser"].settings.access
      )
    )
      next();
    else next("/");
  }
};
