<template>
  <div class="main-content">
    <div class="container-fluid">
      <header-component
        pretitle="OMNICHANNEL 3C+"
        title="Relatório de Aproveitamento"
        redirect-link="/manager/reports"
      />
      <menu-search
        @searched="searched = true"
        hide-empty-days-filter
        enable-empty-days-filter
        :enable-advanced-filters="false"
        @update-empty-days="updatePeriodRange"
        @update-filters="submit"
      />
      <div class="d-flex gap-4">
        <div class="col-12">
          <table-before-search v-if="!searched" />
          <div v-else>
            <chats-in-period-card
              v-if="chatData"
              :chat-data="chatData"
              :period="periodRange"
            />
            <div
              v-if="chatsQualifications"
              class="d-flex gap-4 mt-4"
            >
              <div class="col-8">
                <qualifications-in-period-card
                  :chats-qualifications-data="chatsQualifications?.qualifications_by_date"
                  :period="periodRange"
                />
              </div>
              <div class="col d-flex">
                <total-qualifications-card
                  :chats-qualifications-total="chatsQualifications.total_by_qualification"
                  :total-count="chatsQualifications.total_qualifications"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@components/header-component.vue";
import TableBeforeSearch from "@dialer/manager/pages/reports/components/table-before-search.vue";
import {mapActions, mapGetters, mapMutations} from 'vuex';
import MenuSearch from "@dialer/manager/pages/reports/components/menu-search.vue";
import ChatsInPeriodCard from "@dialer/manager/pages/reports/performance/components/chats-in-period-card.vue";
import QualificationsInPeriodCard
  from "@dialer/manager/pages/reports/performance/components/qualifications-in-period-card.vue";
import TotalQualificationsCard
  from "@dialer/manager/pages/reports/performance/components/total-qualifications-card.vue";

export default {
  name: "PerformanceReport",

  components: {
    TotalQualificationsCard,
    QualificationsInPeriodCard,
    ChatsInPeriodCard,
    MenuSearch,
    TableBeforeSearch,
    HeaderComponent,
  },

  data() {
    return {
      searched: false,
      periodRange: {
        emptyDays: false,
        start_date: null,
        end_date: null
      }
    }
  },

  computed: {
    ...mapGetters("manager/performance_report", {
      loadingFlags: "getLoadingFlags",
      chatsQualifications: "getChatQualifications",
      chatData: 'getChatStatistics'
    }),
  },

  methods: {
    ...mapActions("manager/performance_report", ["fetchPerformanceReportList"]),
    ...mapMutations("manager/performance_report", ["setFilters"]),
    updatePeriodRange(range) {
      this.periodRange = {...range}
    },

    submit (filters) {
      this.setFilters({...filters})
      this.fetchPerformanceReportList()
    }
  },
}
</script>

