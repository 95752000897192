import axiosOmni from "@/plugins/axios-omni.js"
import {Utils} from "@/utils";

export default {
  fetchMetrics({commit}) {
    commit("startLoading", "fetchMetrics")
    return new Promise((resolve, reject)=>{
      axiosOmni.get("/chatbot/metrics")
        .then((response)=>{
          commit("setMetrics", response.data.data)
          resolve()
      }).catch((error)=>{
        commit('system/setErrorMessage', error.response.data, { root: true })
        reject()
      }).finally(()=>{
        commit("stopLoading", "fetchMetrics")
      })
    })
  },

  fetchChatbots({commit, getters}) {
    commit("startLoading", "fetchChatbots")
    return new Promise((resolve, reject)=>{
      axiosOmni.get("/chatbot", {params: getters.getChatbotsParams })
        .then((response)=>{
          commit("setChatbots", response.data.data)
          commit("setChatbotsPagination", response.data.meta.pagination)
          resolve()
        }).catch((error)=>{
        commit('system/setErrorMessage', error.response.data, { root: true })
        reject()
      }).finally(()=>{
        commit("stopLoading", "fetchChatbots")
      })
    })
  },

  createChatbot({commit}, {settings}) {
    commit("startLoading", "createChatbot")
    return new Promise((resolve, reject)=>{
      axiosOmni.post("/chatbot", settings)
        .then((response)=>{
          resolve(response.data.data.id)
        }).catch((error)=>{
          commit('system/setErrorMessage', error.response.data, { root: true })
          reject()
      }).finally(()=>{
        commit("stopLoading", "createChatbot")
      })
    })
  },

  fetchChatbot({commit}, chatbotId) {
    commit("startLoading", "fetchChatbot")
    return new Promise((resolve, reject)=>{
      axiosOmni.get(`/chatbot/${chatbotId}`)
        .then((response)=>{
          commit("setChatbot", response.data.data)

          const actions = response.data.data.actions.data.length ?
            response.data.data.actions.data
            :
            [{
              id: Utils.generateUniqueID(),
              type: "starter",
              chatbot_id: response.data.data.id,
              need_channel: true,
            }]

          commit("chatbot/actions/setActions", actions, {root: true})
          resolve()
        }).catch((error)=>{
          commit('system/setErrorMessage', error.response.data, { root: true })
          reject()
      }).finally(()=>{
        commit("stopLoading", "fetchChatbot")
      })
    })
  },

  updateChatbotSettings({commit}, {chatbotId, settings}) {
    commit("startLoading", "updateChatbotSettings")
    return new Promise((resolve, reject)=>{
      axiosOmni.put(`/chatbot/${chatbotId}`, settings)
        .then((response)=>{
          commit("setChatbot", response.data.data)
          commit("system/setSuccessMessage", "success_message.update_chatbot", { root: true})
          resolve()
        }).catch((error)=>{
          commit('system/setErrorMessage', error.response.data, { root: true })
          reject()
      }).finally(()=>{
        commit("stopLoading", "updateChatbotSettings")
      })
    })
  },

  deleteChatbot({commit}, chatbotId) {
    commit("startLoading", "deleteChatbot")
    return new Promise((resolve, reject)=>{
      axiosOmni.delete(`/chatbot/${chatbotId}`)
        .then(()=>{
          commit("system/setSuccessMessage", "success_message.remove_chatbot", { root: true})
          resolve()
        }).catch((error)=>{
          commit('system/setErrorMessage', error.response.data, { root: true })
          reject()
      }).finally(()=>{
        commit("stopLoading", "deleteChatbot")
      })
    })
  },
}
