import axios from "axios";

export default {
  postCompanyData({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading")
    commit("setList", []);

    return new Promise((resolve, reject) => {
      axios
        .post("/company-data", payload.fields)
        .then((response) => {
          commit("setList", response.data),
            resolve()
        }).catch((response) => {
        if (response.response.status > 500) {
          commit("setOfflineSms")
        }
        commit("system/setErrorMessage", response.response.data.detail, { root: true })
        response.response.status == 422 && response.response.data.errors
        && commit("setErrors", response.response.data.errors);
        reject();
      }).finally(() => {
        commit("startLoading");
      });
    })
  },
  getCompanyDataMounted({ commit }) {
    commit("clearErrors");
    commit("startLoading");
    commit("setList", []);

    return new Promise((resolve, reject) => {
      axios
        .get("/company-data")
        .then((response) => {
          commit("setList", response.response.data.detail),
            resolve()
        }).catch((response) => {
        reject({ message: response.response.data });
        response.response.status == 422 && response.response.data.errors
        && commit("setErrors", response.response.data.errors);
      }).finally(() => {
        commit("stopLoading")
      });
    })
  },
  postTransactionPix({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading");
    commit("setTransactionData", []);

    return new Promise((resolve) => {
      axios
        .post("/payment/pix", payload)
        .then((response) => {
          commit("setTransactionData", response.data.data),
            commit("setPixPaid", false)
          resolve()
        }).catch((response) => {
        commit("setErrors", response.response.data.errors)
        commit("system/setErrorMessage", response.response.data, { root: true })
      }).finally(() => {
        commit("stopLoading")
      });
    })
  },
  postTransactionTicket({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading");
    commit("setTransactionData", []);

    return new Promise((resolve) => {
      axios
        .post("/payment/ticket", payload)
        .then((response) => {
          commit("setTransactionData", response.data.data),
            resolve()
        }).catch((response) => {
        commit("system/setErrorMessage", response.response.data.detail, { root: true })
      }).finally(() => {
        commit("stopLoading")
      });
    })
  },

}
