import axios from "axios";

export default {


  /**
   *
   * @param {*} param0
   * @param {*} payload
   */
  qualify({ commit }, payload) {
    commit('clearErrors')
    commit('startLoadingFlag', 'qualify');

    return new Promise((resolve, reject) => {
      axios.post(
        '/agent/call/' + payload.call_id + '/qualify', payload.fields)
        .then(() => {
          if (payload.fields.qualification_type?.conversion) {
            commit("auth/trackEvents", `[Agent] Qualified DMC`, { root: true })
          }
          if (payload.fields.qualification_type?.dmc) {
            commit("auth/trackEvents", `[Agent] Qualified DMC`, { root: true })
          }
          commit('setCallQualificated', true);
          resolve();
        })
        .catch((response) => {
          commit('setCallQualificated', false);
          commit('system/setErrorMessage', response.response.data, { root: true })
          response.response.status == 422 && response.response.data.errors && commit('setErrors', response.response.data.errors);
          reject();
        })
        .finally(() => {
          commit('stopLoadingFlag', 'qualify');
        })
    });
  },


  /**
     *
     * @param {*} param0
     * @param {*} payload
     */
  manualQualify({ commit }, payload) {
    commit('clearErrors')
    commit('startLoadingFlag', 'manualQualify');
    return new Promise((resolve, reject) => {
      axios.post(
        '/agent/manual_call/' + payload.call_id + '/qualify', payload.fields)
        .then(() => {
          if (payload.fields.qualification_type?.conversion) {
            commit("auth/trackEvents", `[Agent] Qualify as conversion`, { root: true })
          }
          if (payload.fields.qualification_type?.dmc) {
            commit("auth/trackEvents", `[Agent] Qualify as CPC`, { root: true })
          }
          resolve();
        })
        .catch((response) => {
          commit('setCallQualificated', false);
          commit('system/setErrorMessage', response.response.data, { root: true })
          response.response.status == 422 && response.response.data.errors && commit('setErrors', response.response.data.errors);
          reject();
        })
        .finally(() => {
          commit('stopLoadingFlag', 'manualQualify');
        })
    });
  },

  setManualCallACWQualified({ commit }, manualAcwCallQualified) {
    commit('setManualCallACWQualified', manualAcwCallQualified)
  }
}
