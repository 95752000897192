<template>
  <div
    v-if="loadingFlags.detailsPartners || loadingFlags.loadDetailsPartners"
    class="d-flex w-100 gap-4"
  >
    <card-loading
      v-for="index in 3"
      :key="index"
      class="w-100"
      height="190"
    />
  </div>
  <template v-else>
    <card-slot-partner
      icon="fa-regular fa-wallet"
      :text="$t('my_wallet')"
    >
      <template #tooltip>
        <tooltip-component
          color="text-muted"
          icon="fa-regular fa-circle-info"
          :title="$t('my_wallet_definition')"
        />
      </template>
      <template #detail>
        <h2 class="m-0 p-0">
          {{ wallet }}
        </h2>
      </template>
    </card-slot-partner>

    <card-slot-partner
      :active="!admin && !!parseFloat(infoPartner.revenue)"
      :text="admin ? $t('available_manage') : $t('available_withdraw')"
      icon="fa-regular fa-dollar-sign"
      @click="parseFloat(infoPartner.revenue) > 0 ? openWithdrawalRequestModal() : null"
    >
      <template #tooltip>
        <tooltip-component
          color="text-muted"
          icon="fa-regular fa-circle-info"
          :title="`${$t('tooltip_available_withdraw')}`"
        />
      </template>
      <template #detail>
        <h2 class="m-0 p-0">
          {{ parseFloat(infoPartner.revenue) > 0 ? formatCurrency(parseFloat(infoPartner.revenue)) : "-" }}
        </h2>
      </template>
      <template #icon>
        <div>
          <i
            v-if="!admin"
            class="fa-regular fa-arrow-right fs-4 w-100"
          />
        </div>
      </template>
    </card-slot-partner>
    <modal-withdrawal-request ref="WithdrawalModal" />
  </template>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { Utils } from "@/utils";
import CardSlotPartner from "@partner/pages/component/card-slot-partner.vue";
import CardLoading from "@components/card-loading.vue";
import TooltipComponent from "@components/tooltip-component.vue";
import ModalWithdrawalRequest from "@partner/pages/component/modal-withdrawal-request.vue";

export default {
  components: {
    ModalWithdrawalRequest,
    TooltipComponent,
    CardLoading,
    CardSlotPartner
  },

  methods: {
    ...mapMutations("system", ["setInfoMessage"]),
    formatCurrency: Utils.formatCurrency,

    openWithdrawalRequestModal() {
      if (this.detailsPartner.clicksign_contract.signed === false) {
        this.setInfoMessage(this.$t("you_need_to_sign_contract_to_request_withdraw"));
      } else {
        if (this.detailsPartner && Object.keys(this.detailsPartner).length > 0) {
          return this.$refs.WithdrawalModal.new();
        }
      }
    }
  },

  computed: {
    ...mapGetters("partner", {
      detailsPartner: "getDetailsPartner",
      loadingFlags: "getLoadingFlags"
    }),

    ...mapGetters("auth", {
      admin: "isAdmin"
    }),

    ...mapGetters("admin/partners", {
      adminDetailPartner: "getDetailsPartners",
      adminLoadingFlags: "getLoadingFlags"
    }),

    infoPartner() {
      if (Object.keys(this.detailsPartner).length) {
        return this.detailsPartner;
      }
      return this.adminDetailPartner;
    },

    wallet() {
      if (!this.infoPartner.wallet) return "-";
      const balance = this.formatCurrency(this.infoPartner.wallet);
      return `${balance} /${this.$t("month_lowercase")}`;
    }
  }
};
</script>

<style lang="scss" scoped></style>
