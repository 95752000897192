<template>
  <div class="container-fluid">
    <header-component
      :pretitle="$t('report_capitalize')"
      :title="$t('appointment_report')"
      redirect-link="/manager/reports"
    />
    <form @submit.prevent="submit()">
      <div class="row">
        <div class="col-12 col-lg-2 col-md-6">
          <div class="form-group">
            <label
              for="startDate"
              class="form-label"
            >
              {{ $t('period') }}
            </label>
            <input-date-range
              v-model:start="startDate"
              v-model:end="endDate"
            />
          </div>
        </div>
        <div class="col-12 col-lg-4 col-md-6">
          <div class="form-group">
            <label
              for="campaign"
              class="form-label"
            >
              {{ $t('campaign_capitalize') }}
            </label>
            <select-campaign v-model="campaign" />
          </div>
        </div>
        <div class="col-12 col-lg-4 col-md-6">
          <div class="form-group">
            <label
              for="campaign"
              class="form-label"
            >
              {{ $t('agent_capitalize') }}
            </label>
            <select-agent v-model="agent" />
          </div>
        </div>
        <div class="col-12 col-lg-2 col-md-6">
          <div class="form-group">
            <label class="form-label">&nbsp;</label>
            <div>
              <app-button
                class="form-control"
                :loading="loading.searchSchedules"
              >
                {{ $t('search_report') }}
              </app-button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="tab-content">
      <div
        class="tab-pane fade show active"
        id="contactsListPane"
        role="tabpanel"
        aria-labelledby="contactsListTab"
      >
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title fw-normal">
                  {{ $t('appointment_report') }}
                </h4>
              </div>

              <div class="col-auto">
                <a
                  href="#"
                  class="btn btn-outline-secondary"
                  @click="generateCSVModal.show()"
                >{{ $t('download_CSV') }}</a>
              </div>
            </div>
          </div>

          <div
            class="table-responsive"
            id="table-manual-calls"
          >
            <table class="table table-hover table-nowrap card-table">
              <thead>
                <tr>
                  <th />
                  <th>
                    <a class="text-muted fw-normal">{{ $t('uppercases.number') }}</a>
                  </th>
                  <th>
                    <a class="text-muted fw-normal">{{ $t('uppercases.date') }}</a>
                  </th>
                  <th>
                    <a class="text-muted fw-normal">{{ $t('CAMPAIGN') }}</a>
                  </th>
                  <th>
                    <a class="text-muted fw-normal">{{ $t('uppercases.name') }}</a>
                  </th>
                  <th>
                    <a class="text-muted fw-normal">{{ $t('capitalizes.note') }}</a>
                  </th>
                  <th>
                    <a class="text-muted fw-normal">MAILING</a>
                  </th>
                </tr>
              </thead>
              <template v-if="schedules.length">
                <tbody class="list fs-base">
                  <tr
                    v-for="schedule in schedules"
                    :key="schedule.id"
                  >
                    <td class="pe-0">
                      <schedule-status-tooltip :schedule="schedule" />
                    </td>
                    <td>
                      <span>{{ formatPhoneNumber(schedule.phone) }}</span>
                    </td>
                    <td>
                      <span>{{ schedule.date }}</span>
                    </td>
                    <td>
                      <span> {{ schedule.campaign.name }} </span>
                    </td>
                    <td>
                      <span> {{ schedule.agent.name }} </span>
                    </td>
                    <td>
                      <span> {{ schedule.note }} </span>
                    </td>
                    <td>
                      <button
                        type="button"
                        class="btn "
                        data-bs-toggle="modal"
                        data-bs-target="#modalMailing"
                        @click="selectedSchedule = schedule"
                      >
                        <span class="fe fe-clipboard" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </template>
              <tbody
                class="list fs-base"
                v-if="schedules.length === 0 && !showSearchMessage"
              >
                <td colspan="99">
                  <p
                    class="card-body text-center my-3"
                    style="margin: 0;"
                  >
                    {{ $t('no_call_schedule') }}
                  </p>
                </td>
              </tbody>
              <tbody
                class="list fs-base"
                v-if="schedules.length === 0 && showSearchMessage"
              >
                <td colspan="99">
                  <p
                    class="card-body text-center my-3"
                    style="margin: 0;"
                  >
                    {{ $t('schedules_calls') }}
                  </p>
                </td>
              </tbody>
            </table>
          </div>
          <div
            class="card-footer"
            v-if="schedules.length"
          >
            <paginator
              :pagination="pagination"
              @change-page="changePage"
              v-if="!loading.searchSchedules && !showSearchMessage"
            />
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="modalMailing"
        tabindex="-1"
        aria-labelledby="modalMailingLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div
            class="modal-content"
            v-if="selectedSchedule"
          >
            <div class="modal-header">
              <h3
                class="modal-title"
                id="modalMailingLabel"
              >
                {{ $t('mailing_data') }}
              </h3>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div class="card-body">
              <div
                class="d-flex justify-content-between border-bottom py-3"
                v-if="Object.values(selectedSchedule.mailing).length"
              >
                <div>
                  {{ $t('identifier') }}
                </div>
                <div class="text-secondary">
                  {{ selectedSchedule.mailing.identifier }}
                </div>
              </div>
              <div v-else>
                {{ $t('no_mailing_appointment') }}
              </div>

              <div
                v-for="(data, key, index) in selectedSchedule.mailing.data"
                :key="index"
                class="d-flex justify-content-between border-bottom py-3"
              >
                <div class="me-4">
                  {{ key }}
                </div>
                <div class="text-secondary">
                  {{ data }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="modalDownloadCSV"
        tabindex="-1"
        aria-labelledby="modalDownloadCSVLabel"
        aria-hidden="true"
        ref="generateCSVModal"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h3
                class="modal-title"
                id="modalDownloadCSVLabel"
              >
                {{ $t('generate_report') }}
              </h3>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div class="modal-body">
              <p>
                {{ $t('send_report_to_email')
                }}
              </p>
              <form
                @submit.prevent="generateCSV"
                id="formCSV"
              >
                <div class="mb-3">
                  <label
                    for="input-email"
                    class="col-form-label"
                  >{{ $t('report_email') }}</label>
                  <input
                    type="email"
                    class="form-control"
                    id="input-email"
                    required
                    v-model="email"
                  >
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                {{ $t('cancel_general') }}
              </button>
              <app-button
                form="formCSV"
                :loading="loading.downloadCSV"
                style="min-width: 6.5rem;"
              >
                {{ $t('generate_csv') }}
              </app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { formatPhoneNumber } from "@/utils";

import HeaderComponent from "@/components/header-component.vue";
import ScheduleStatusTooltip from "@dialer/manager/pages/reports/components/schedule-status-tooltip.vue";
import SelectCampaign from "@dialer/manager/components/select-campaign.vue";
import SelectAgent from "@dialer/manager/components/select-agent.vue";
import appButton from "@/components/app-button.vue";
import paginator from "@/components/app-paginator.vue";
import inputDateRange from "@/components/input-date-range.vue";
import { Modal } from "bootstrap";

export default {
  data() {
    return {
      startDate: "",
      endDate: "",
      campaigns: [],
      campaign: "",
      agents: [],
      agent: "",
      showSearchMessage: true,
      email: "",
      schedules: [],
      selectedSchedule: null,
      generateCSVModal: null
    };
  },
  components: {
    appButton,
    paginator,
    inputDateRange,
    HeaderComponent,
    ScheduleStatusTooltip,
    SelectCampaign,
    SelectAgent
  },
  methods: {
    ...mapActions("manager/schedules", ["searchSchedules", "downloadCSV", "setDownloadCSVFilters"]),
    ...mapActions("manager/filters", ["findFilters"]),
    ...mapMutations("manager/filters", ["setIncludes"]),
    formatPhoneNumber,
    updateStartDate(date) {
      this.startDate = date;
    },
    updateEndDate(date) {
      this.endDate = date;
    },
    submit() {
      this.searchSchedules({
        startDate: this.startDate,
        endDate: this.endDate,
        campaign_id: this.campaign,
        agent_id: this.agent
      }).then(() => {
        this.schedules = this.list;
        this.showSearchMessage = false;
      });
    },
    changePage(page) {
      this.searchSchedules({
        startDate: this.startDate,
        endDate: this.endDate,
        campaign_id: this.campaign,
        agent_id: this.agent,
        page
      }).then(() => {
        this.schedules = this.list;
      });
    },
    generateCSV() {
      this.setDownloadCSVFilters({
        start_date: this.startDate,
        end_date: this.endDate,
        campaign_id: this.campaign,
        agent: this.agent,
        email: this.email
      });

      this.downloadCSV().then(() => this.generateCSVModal.hide());
    }
  },
  computed: {
    ...mapGetters("manager/filters", {
      filters: "getFilters"
    }),
    ...mapGetters("manager/schedules", {
      pagination: "getPagination",
      list: "getList",
      loading: "getLoadingFlags"
    }),
    ...mapGetters("auth", {
      user: "getUser"
    })
  },
  watch: {
    list(data) {
      if (data.length) this.schedules = [];
    },
    filters: {
      handler(data) {
        if (data.agents) {
          this.campaigns = data.campaigns;
          this.agents = data.agents;
        }
      },
      deep: true
    }
  },
  mounted() {
    this.setIncludes(["agents"]);
    this.findFilters();
    this.email = this.user.email;
    this.generateCSVModal = new Modal(this.$refs.generateCSVModal);
  }
};
</script>

<style scoped lang="scss">
.table > :not(:first-child) {
  border-top: none !important;
}
</style>
