export default {
  agents: [],
  agentsPagination: {},
  teams: [],
  teamsPagination: {},
  groupChannels: [],
  groupChannelsWithQuali: [],
  instances: [],
  instancesPagination: {},
  qualifications: {},
  status: [
    {
      id: "online",
      name: "Online"
    },
    {
      id: "offline",
      name: "Offline"
    }],

  loadingFlags:{
    fetchAgents: false,
    fetchTeams: false,
    fetchInstances: false,
    fetchGroupChannels: false,
    fetchGroupChannelsWithQuali: false,
    fetchQualifications: false,
  }
}
