<template>
  <div class="col-12 col-lg-6 col-xl">
    <div class="card position-relative">
      <div class="card-body d-flex align-items-center justify-content-between">
        <div class="info-wrapper h-100 d-flex flex-column justify-content-between">
          <div class="header-wrapper">
            <h4 class="card-title">
              {{ $t("become_an_expert") }}
            </h4>
            <div class="card-subtitle text-medium tx-text-gray">
              {{ $t("learn_how_to_become_a_successful_partner_and_about_3c_plus") }}
            </div>
          </div>
          <a
            class="d-flex align-items-center gap-2"
            href="https://tools.gomerlin.com.br/chat/0ed1f5b3-c985-4f3f-aafb-7e9429a630f1"
            target="_blank"
          >
            <i class="fa-regular fa-arrow-right" />
            {{ $t("explore_more") }}
          </a>
        </div>
        <img
          class="ellipse position-absolute"
          alt="Ellipse"
          src="@/assets/img/partner/profile-expert.png"
        >
        <img
          alt="Gray octagonal star icon"
          class="gray-octagonal-star position-absolute"
          src="@/assets/img/gray-octagonal-star.svg"
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card {
  height: 190px;
  .card-body {
    .card-subtitle {
      line-height: 16px;
    }

    .info-wrapper {
      width: 160px;
    }

    .ellipse {
      height: 180px;
      right: 0;
      z-index: 1;

      @media (max-width: 1400px) {
        height: 160px;
      }

      @media (max-width: 1350px) {
        height: 140px;
      }

      @media (max-width: 1300px) {
        display: none;
      }

      @media (max-width: 1200px) {
        height: 180px;
        display: block;
      }
    }

    .gray-octagonal-star {
      height: 90px;
      bottom: 20px;
      right: 55px;

      @media (max-width: 1400px) {
        height: 60px;
      }

      @media (max-width: 1350px) {
        height: 50px;
      }

      @media (max-width: 1300px) {
        display: none;
      }

      @media (max-width: 1200px) {
        height: 90px;
        display: block;
      }
    }
  }
}
</style>
