export default {
  getLoadingFlags: state => state.loadingFlags,
  getAgents: state => state.agents,
  getCountAgentsStatuses: state => state.countAgentsStatuses,
  getCallsWorking: state => state.callsWorking,
  getCountCallsWorkingStatuses: state => state.countCallsWorkingStatuses,
  getMailings: state => state.mailings,
  getLastFinalizedCalls: state => state.lastFinalizedCalls,
  getRealTimeCampaignContext: state => state.realTimeCampaignContext,
  isUpdateAgentsRealTimeActive: state => state.updateAgentsRealTimeActive,
  isUpdateCallsInRealTimeActive: state => state.updateCallsInRealTimeActive
}
