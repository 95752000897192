<template>
  <div class="col-12 mb-4">
    <div
      class="card h-100 m-0"
    >
      <h4 class="mb-2 mt-1 card-header">
        {{ $t('distribution_mode') }}
      </h4>
      <div class="card-body d-flex flex-row justify-content-between">
        <div class="col d-flex">
          <div class="col d-flex align-items-start pt-2">
            <input
              id="default"
              v-model="value"
              :checked="modelValue === 'DEFAULT'"
              class="form-check-input col-auto me-3 mt-3"
              type="radio"
              value="DEFAULT"
              @input="emitValue"
            >
            <label
              class="form-check-label col ms-2"
              for="default"
            >
              <h5 style="font-weight: 500;">{{ $t('standard') }}</h5>
              <p
                class="text-muted fs-14px fw-normal mb-0"
              >{{ $t('standard_description') }}</p>
            </label>
          </div>
        </div>
        <div
          class="vr mx-4rem"
          style="background-color: #E1E9F4;"
        />
        <div class="col d-flex">
          <div class="col d-flex align-items-start pt-2">
            <input
              id="balanced"
              v-model="value"
              :checked="modelValue === 'BALANCED'"
              class="form-check-input col-auto me-3 mt-3"
              type="radio"
              value="BALANCED"
              @input="emitValue"
            >
            <label
              class="form-check-label col ms-2"
              for="balanced"
            >
              <h5 style="font-weight: 500;">{{ $t('balanced') }}</h5>
              <p
                class="text-muted fs-14px fw-normal mb-0"
              >{{ $t('balanced_description') }}</p>
            </label>
          </div>
        </div>
        <div
          class="vr mx-4rem"
          style="background-color: #E1E9F4;"
        />
        <div class="col">
          <div
            class="col d-flex align-items-start pt-2"
          >
            <input
              id="sequential"
              v-model="value"
              :checked="modelValue === 'SEQUENTIAL'"
              class="form-check-input col-auto me-3 mt-3"
              type="radio"
              value="SEQUENTIAL"
              @input="emitValue"
            >
            <label
              class="form-check-label col ms-2"
              for="sequential"
            >
              <h5 style="font-weight: 500;">{{ $t('sequential') }}</h5>
              <p
                class="text-muted fs-14px fw-normal mb-0"
              >{{ $t('sequential_description') }}</p>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  emits: ['update:modelValue'],
  name: "DistributionStrategyForm",
  props: {
    modelValue: {type: String, default: 'DEFAULT'},
  },
  data() {
    return {
      value: 'DEFAULT'
    }
  },
  methods: {
    emitValue(e) {
      let value = e.target.value
      this.$emit('update:modelValue', value)
    }
  }
}
</script>
<style lang="scss" scoped>
.card,
.card-header,
.card-body,
.card-footer {
  border-color: #E1E9F4 !important;
}

.mx-4rem {
  margin-right: 4rem;
  margin-left: 4rem;
}

.fs-14px {
  font-size: 0.875rem;
  line-height: 1rem;
}
</style>
