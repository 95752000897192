<template>
  <div class="main-content pb-6 utilization-report">
    <div class="container-fluid">
      <header-component
        :title="$t('login_history_capitalize')"
        :pretitle="$t('uppercases.reports')"
        redirect-link="/manager/reports"
      />

      <form
        @submit.prevent="submit()"
        novalidate
      >
        <div class="row">
          <div class="col-lg-4">
            <div class="form-group">
              <label
                for="startDate"
                class="form-label"
              >
                {{ $t('period') }}
              </label>
              <input-date-range
                v-model:start="startDate"
                v-model:end="endDate"
                :time-enabled="true"
                :seconds-enabled="true"
                format-date="d/m/Y H:i:S"
              />
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group">
              <label
                for="campaign"
                class="form-label"
              >
                {{ $t('campaign_capitalize') }}
              </label>
              <select-campaign v-model="campaign" />
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group">
              <label
                for="agent"
                class="form-label"
              >
                {{ $t('agent_capitalize') }}
              </label>
              <select-agent v-model="agent" />
            </div>
          </div>
          <div class="col-lg-2">
            <label class="form-label">&nbsp;</label>
            <div>
              <app-button
                class="form-control"
                :loading="loading.searchLoginHistory"
              >
                {{ $t('search_report') }}
              </app-button>
            </div>
          </div>
        </div>
      </form>
      <div class="tab-content">
        <div
          class="tab-pane fade show active"
          id="contactsListPane"
          role="tabpanel"
          aria-labelledby="contactsListTab"
        >
          <div class="card">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <h4 class="card-header-title fw-normal">
                    {{ $t('login_history_report') }}
                  </h4>
                </div>

                <div class="col-auto">
                  <a
                    href="#"
                    class="btn btn-outline-secondary"
                    @click="generateCSVModal.show()"
                  >{{ $t('download_CSV') }}</a>
                </div>
              </div>
            </div>

            <div
              class="table-responsive"
              id="table-manual-calls"
            >
              <table class="table table-hover table-nowrap card-table">
                <thead>
                  <tr>
                    <th>
                      <a
                        class="sort list-sort text-muted fw-normal"
                        @click="sort"
                      >{{ $t('agent_capitalize') }}</a>
                    </th>
                    <th>
                      <a class="list-sort text-muted fw-normal">{{ $t('campaign_capitalize') }}</a>
                    </th>
                    <th>
                      <a class="list-sort text-muted fw-normal">{{ $t('start') }}</a>
                    </th>
                    <th>
                      <a class="list-sort text-muted fw-normal">{{ $t('end') }}</a>
                    </th>
                    <th>
                      <a class="list-sort text-muted fw-normal">{{ $t('total_time_capitalize') }}</a>
                    </th>
                  </tr>
                </thead>
                <tbody class="list fs-base">
                  <tr
                    v-for="item in list"
                    :key="item.id"
                  >
                    <td>
                      <span>{{ item.agent.name }}</span>
                    </td>
                    <td>
                      <span>{{ item.campaign.name }}</span>
                    </td>
                    <td>
                      <span> {{ item.login }} </span>
                    </td>
                    <td>
                      <span> {{ item.logout }} </span>
                    </td>
                    <td>
                      <span> {{ totalTime(item.login, item.logout) }} </span>
                    </td>
                  </tr>
                </tbody>
                <tbody
                  class="list fs-base"
                  v-if="list.length === 0 && showLoadingMessage"
                >
                  <td colspan="99">
                    <p
                      class="card-body text-center my-3"
                      style="margin: 0;"
                    >
                      {{ $t('loading_capitalize') }}...
                    </p>
                  </td>
                </tbody>
                <tbody
                  class="list fs-base"
                  v-else-if="list.length === 0 && !showSearchMessage"
                >
                  <td colspan="99">
                    <p
                      class="card-body text-center my-3"
                      style="margin: 0;"
                    >
                      {{ $t('no_history_found') }}
                    </p>
                  </td>
                </tbody>
                <tbody
                  class="list fs-base"
                  v-else-if="list.length === 0 && showSearchMessage"
                >
                  <td colspan="99">
                    <p
                      class="card-body text-center my-3"
                      style="margin: 0;"
                    >
                      {{ $t('search_login_history') }}
                    </p>
                  </td>
                </tbody>
              </table>

              <div class="col-lg-12">
                <paginator
                  :pagination="pagination"
                  @change-page="changePage"
                  v-if="!loading.searchLoginHistory && !showSearchMessage"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="modalDownloadCSV"
          tabindex="-1"
          aria-labelledby="modalDownloadCSVLabel"
          aria-hidden="true"
          ref="generateCSVModal"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h3
                  class="modal-title"
                  id="modalDownloadCSVLabel"
                >
                  {{ $t('generate_report') }}
                </h3>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div class="modal-body">
                <p>
                  {{ $t('send_report_to_email') }}
                </p>
                <form
                  @submit.prevent="generateCSV"
                  id="formCSV"
                >
                  <div class="mb-3">
                    <label
                      for="input-email"
                      class="col-form-label"
                    >{{ $t('report_email') }}</label>
                    <input
                      type="email"
                      class="form-control"
                      id="input-email"
                      required
                      v-model="email"
                    >
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  {{ $t('cancel_general') }}
                </button>
                <app-button
                  form="formCSV"
                  :loading="loading.downloadTable"
                  style="min-width: 6.5rem;"
                >
                  {{ $t('generate_csv') }}
                </app-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import { mapActions, mapGetters, mapMutations } from "vuex";

import { convertSecondsToTime } from "@/utils.js";

import AppButton from "@/components/app-button.vue";
import Paginator from "@/components/app-paginator.vue";
import InputDateRange from "@/components/input-date-range.vue";
import SelectCampaign from "@dialer/manager/components/select-campaign.vue";
import SelectAgent from "@dialer/manager/components/select-agent.vue";
import HeaderComponent from "@/components/header-component.vue";
import { Modal } from "bootstrap";

export default {
  data() {
    return {
      campaigns: [],
      campaign: "",
      agents: [],
      agent: "",
      email: "",
      startDate: "",
      endDate: "",
      showSearchMessage: true,
      showLoadingMessage: false,
      generateCSVModal: null
    };
  },

  components: {
    AppButton,
    Paginator,
    InputDateRange,
    SelectCampaign,
    SelectAgent,
    HeaderComponent
  },

  methods: {
    convertSecondsToTime,
    ...mapActions("manager/login_history", ["searchLoginHistory", "downloadTable"]),
    ...mapActions("manager/filters", ["findFilters"]),
    ...mapMutations("manager/filters", ["setIncludes"]),

    submit() {
      let params = {
        startDate: this.startDate,
        endDate: this.endDate
      };

      if (this.campaign) params.campaign_id = this.campaign;

      if (this.agent) params.agent_id = this.agent;

      this.searchLoginHistory(params).then(() => {
        this.showSearchMessage = false;
        this.showLoadingMessage = false;
      });
    },

    totalTime(login, logout) {
      if (logout == "-") return "-";

      const loginTimesTamp = moment(login, "DD-MM-YYYY hh:mm:ss").unix();
      const logoutTimesTamp = moment(logout, "DD-MM-YYYY hh:mm:ss").unix();

      const totalTime = logoutTimesTamp - loginTimesTamp;

      const str = this.convertSecondsToTime(totalTime);
      return str;
    },

    changePage(page) {
      this.showLoadingMessage = true;
      this.$store.dispatch("manager/login_history/searchLoginHistory", {
        page,
        startDate: this.startDate,
        endDate: this.endDate,
        campaign_id: this.campaign,
        agent_id: this.agent
      });
    },

    generateCSV() {
      this.downloadTable({
        startDate: this.startDate,
        endDate: this.endDate,
        campaign_id: this.campaign,
        email: this.email,
        agent_id: this.agent
      }).then(() => this.generateCSVModal.hide());
    }
  },

  computed: {
    ...mapGetters("manager/filters", {
      filters: "getFilters"
    }),

    ...mapGetters("auth", {
      user: "getUser"
    }),

    ...mapGetters("manager/login_history", {
      pagination: "getPagination",
      loading: "getLoadingFlags",
      list: "getList"
    })
  },

  mounted() {
    this.setIncludes(["agents"]);
    this.findFilters();
    this.email = this.user.email;
    this.generateCSVModal = new Modal(this.$refs.generateCSVModal);
  },

  watch: {
    filters: {
      handler(data) {
        if (data.agents) {
          this.campaigns = data.campaigns;
          this.agents = data.agents;
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
.header-body {
  border-bottom: none;
}

.rounded-calendar {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.table > :not(:first-child) {
  border-top: none !important;
}
</style>
