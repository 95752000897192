export default {
  getLoadingFlags: state => state.loadingFlags,
  getErrors: state => state.errors,

  getPendingActivities: (state) => state.pendingActivities,
  getPendingActivitiesPagination: (state) => state.pendingActivitiesPagination,

  getPendingActivitiesOfDay: (state) => state.pendingActivitiesOfDay,
  getPendingActivitiesOfDayPagination: (state) => state.pendingActivitiesOfDayPagination,

  getFutureActivities: (state) => state.futureActivities,
  getFutureActivitiesPagination: (state) => state.futureActivitiesPagination,

  getPendingActivitiesParams: (state) =>
  Object.assign(
    {
      'page': state.pendingActivitiesPagination.current_page,
      'per_page': state.pendingActivitiesPagination.per_page,
    }
    , state.pendingActivitiesFilters),

  getPendingActivitiesOfDayParams: (state) =>
  Object.assign(
    {
      'page': state.pendingActivitiesOfDayPagination.current_page,
      'per_page': state.pendingActivitiesOfDayPagination.per_page,
    }
    , state.pendingActivitiesOfDayFilters),

  getFutureActivitiesParams: (state) =>
  Object.assign(
    {
      'page': state.futureActivitiesPagination.current_page,
      'per_page': state.futureActivitiesPagination.per_page,
    }
    , state.futureActivitiesFilters),

  getStatisticsActivitiesParams: (state) => state.statisticsActivitiesFilters,
  getActivityStatistics: (state) => state.statisticsActivities,
  getPinNotes: (state) => state.pinNotes,
  getActivityCard: (state) => state.activityCard,
  getOpportunities: (state) => state.opportunities,
  getOpportunitiesPagination: (state) => state.opportunityPagination,
  getOpportunityFilters: (state) => state.opportunityFilters
}
