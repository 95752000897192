<template>
  <div class="d-flex justify-content-center align-items-center h-100 position-relative">
    <canvas ref="gaugeCanvas" />
    <div class="custom-content">
      <div :style="{ color: progressColor }">
        <p
          class="mb-0 fw-medium"
          style="font-size: 30px;"
        >
          {{ value }}<span class="tx-text-gray fs-3">/10</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CircleGauge",
  props: {
    value: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      default: 100
    },
    size: {
      type: Number,
      default: 200
    }
  },
  mounted() {
    this.drawGauge();
  },
  computed: {
    progressColor() {
      const rangeMax = this.max;
      const percentage = (this.value / rangeMax) * 100;

      if (percentage >= 0 && percentage < 30) {
        return "#F23F2C";
      } else if (percentage >= 30 && percentage < 60) {
        return "#FFBD00";
      } else if (percentage >= 60 && percentage <= 100) {
        return "#5CB868";
      } else {
        return "#000000";
      }
    }
  },
  methods: {
    drawGauge() {
      const canvas = this.$refs.gaugeCanvas;
      const ctx = canvas.getContext("2d");
      const radius = this.size / 2;
      const centerX = 140;
      const centerY = radius;
      const gaugeThickness = 10;
      const startAngle = 3.14;
      const endAngle = startAngle + Math.PI;
      const angle = (this.value / this.max) * Math.PI;
      ctx.clearRect(0, 0, this.size, this.size);
      ctx.beginPath();
      ctx.arc(centerX, centerY, radius - gaugeThickness, startAngle, endAngle);
      ctx.strokeStyle = "#E1E9F4";
      ctx.lineWidth = gaugeThickness;
      ctx.lineCap = "round";
      ctx.stroke();
      ctx.beginPath();
      ctx.arc(centerX, centerY, radius - gaugeThickness, startAngle, startAngle + angle);
      if (this.value > 0) {
        ctx.strokeStyle = this.progressColor;
      }
      ctx.lineWidth = gaugeThickness;
      ctx.stroke();
    }
  },
  watch: {
    value() {
      this.drawGauge();
    }
  }
};
</script>

<style scoped>
.custom-content {
  position: absolute;
  top: 68%;
  left: 50%;
  transform: translate(-68%, -50%);
}
</style>
