<template>
  <div>
    <div class="d-flex justify-content-between">
      <label
        class="form-label"
        for="basic-url"
      >Links</label>
      <button
        v-if="!hasContent"
        class="btn d-flex justify-content-center pe-0"
        @click="dropAllList()"
      >
        <i class="fa-solid fa-xmark" />
      </button>
    </div>
    <div
      v-for="(item, index) in linkList"
      :key="index"
      class="d-flex align-items-end gap-4 mb-3"
    >
      <div class="col p-0">
        <div class="input-group">
          <input
            v-model="item.content"
            :readonly="!(index === linkList?.length - 1)"
            class="form-control border-end-0"
            placeholder="Link"
            type="url"
            @focusout="updatedModel()"
          >
          <span
            id="basic-addon1"
            class="input-group-text border-start-0"
          >
            <a
              v-if="!(index === linkList?.length - 1) || linkList.length===1"
              :href="validateUrl(item.content)"
              target="_blank"
              class="m-0"
            >
              <i class="fa-regular fa-link" />
            </a>
            <i
              v-else
              class="fa-regular fa-link"
            />
          </span>
        </div>
      </div>
      <div class="d-flex flex-column justify-content-end">
        <button
          v-if="index === linkList?.length - 1"
          class="btn btn-primary"
          :disabled="!item.content"
          @click="incrementLinkList(index)"
        >
          <i class="fa-solid fa-plus px-1" />
        </button>
        <button
          v-else
          class="btn btn-light"
          @click="dropLinkList(index, item)"
        >
          <i class="fa-solid fa-xmark px-1" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "LinkFieldList",
  emits: ['close', 'update:modelValue', 'delete'],
  inject: ['isActivityFormSubmitted'],
  props: {
    modelValue: { type: Array, required: true }
  },

  data (){
    return {
      linkList: [{type: 'link', content: ''}],
    }
  },

  mounted() {
    if (this.modelValue.length) {
      this.linkList = this.modelValue;
    }
  },

  computed: {
    hasContent (){
      return this.linkList.find((link) => link.content.length > 0)
    },
  },

  methods: {
    incrementLinkList() {
      this.linkList.push({type: 'link', content: ''})
      this.updatedModel()
    },
    dropLinkList(index, item) {
      if (item.id) {
        this.$emit('delete', item.id)
      }
      this.linkList.splice(index, 1)
      this.updatedModel()
    },
    dropAllList () {
      if(this.linkList[0] && this.linkList[0].id) {
        this.$emit('delete', this.linkList[0].id)
      }
      this.linkList = [{type: 'link', content: ''}]
      this.updatedModel()
      this.$emit('close')
    },
    updatedModel () {
      this.$emit('update:modelValue', this.linkList)
    },
    validateUrl(url) {
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        return 'http://' + url;
      }
      return url;
    }
  },

  watch: {
    "isActivityFormSubmitted.value"(value) {
      if (value) {
        this.dropAllList();
      }
    }
  }
})
</script>
