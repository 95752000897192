<template>
  <button
    class="btn btn-white"
    ref="importTooltip"
    data-bs-toggle="tooltip"
  >
    <i class="far fa-arrows-rotate tx-blue-300 arrows-rotate" />
  </button>
</template>

<script>
import {Tooltip} from "bootstrap";

export default {
  name: "InImportingButton",
  data(){
    return{
      tooltip: null
    }
  },
  mounted() {
    const tooltip = this.$refs.importTooltip;
    this.tooltip = new Tooltip(tooltip, {
      placement: "bottom",
      html: true,
      title: `${this.$t('processing_list')}`,
    })
  },
}
</script>

<style scoped lang="scss">
.arrows-rotate {
  animation: 0.75s infinite linear spin;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>