<template>
  <modal-component
    ref="messages-template"
    center
    :show="modelValue"
    @close="close"
    custom-size="1160px"
  >
    <template #content>
      <div class="modal-header border-bottom-0 py-0">
        <div class="d-flex align-items-center gap-4 w-100">
          <div class="d-flex align-items-center col-8">
            <h5
              class="header-title col-8"
              style="padding-top: 1rem"
            >
              {{ $t('active_models') }}
              <span
                v-if="pagination && pagination.total"
                class="text-primary"
              >
                {{ pagination.total }}
              </span>
            </h5>
            <div
              v-if="false"
              class="input-group input-group-merge input-group-reverse align-items-center my-3"
              style="border-color: #D2DDEC;"
            >
              <input
                placeholder="$t('placeholders.search_capitalize')"
                type="search"
                class="form-control bg-transparent rounded-4"
              >
              <div class="input-group-text bg-transparent rounded-4">
                <span
                  class="fe fe-search m-0"
                />
              </div>
            </div>
          </div>
          <div class="col-4 d-flex align-items-center">
            <h5 class="m-0">
              {{ $t('preview') }}
            </h5>
          </div>
        </div>
        <button
          type="button"
          data-bs-dismiss="modal"
          aria-label="Close"
          class="btn-close fs-2 pe-4"
          style="padding: 1rem; padding-top: 2rem"
          @click="close"
        />
      </div>
      <div
        class="modal-body d-flex flex-wrap flex-xl-nowrap items-center gap-3 pt-0 waba-body"
      >
        <div
          id="template-container"
          class="col-12 col-xl-8 overflow-y-auto"
        >
          <p
            v-if="loading.fetchMessageTemplates || globalLoading && templates.length === 0"
            class="card-text placeholder-glow"
          >
            <span
              v-for="i in 4"
              :key="i"
              class="placeholder bg-light col-12 rounded my-2"
              style="padding: 4rem"
            />
          </p>
          <enpty-state-templates v-else-if="!loading.fetchMessageTemplates && templates.length === 0" />
          <div
            v-else-if="templates && templates.length > 0"
            class="d-flex flex-column"
          >
            <message-template-item
              v-for="(template, index) in templates"
              :key="index"
              :template="template"
              @preview="previewTemplate"
              @close="close"
              class="my-2"
            />
          </div>
          <infinity-pagination-observer
            v-if="pagination.current_page < pagination.total_pages"
            :loading="loading.fetchMessageTemplates"
            margin="10px"
            parent-container="template-container"
            @reload="loadFetchMessageTemplate"
          />
        </div>
        <div class="col-12 col-xl-4 preview-background rounded-4 px-3 py-3">
          <ballon-message-preview
            v-if="selectedTemplate"
            :message-data="selectedTemplate"
          />
        </div>
      </div>
    </template>
  </modal-component>
</template>
<script>
import MessageTemplateItem from "@whatsapp/components/messages-templates/message-template-item.vue";
import {mapActions, mapGetters} from "vuex";
import BallonMessagePreview from "@whatsapp/components/messages-templates/ballon-message-preview.vue";
import InfinityPaginationObserver from "@components/infinity-pagination-observer.vue";
import EnptyStateTemplates from "@whatsapp/components/messages-templates/empty-state-templates.vue";
import ModalComponent from "@components/modal-component.vue";

export default {
  name: "MessagesTemplateModal",
  emits: ['close', 'update:modelValue'],
  components: {
    ModalComponent,
    EnptyStateTemplates, InfinityPaginationObserver, BallonMessagePreview, MessageTemplateItem
  },
  props: {
    modelValue: {type: Boolean, required: true},
    wabaChannelId: {type: String, default: null},
    instanceId: {type: [String, Number], required: true}
  },
  data() {
    return {
      modal: null,
      selectedTemplate: null,
      currentPage: 1,
      globalLoading: true
    }
  },
  computed: {
    ...mapGetters('whatsapp/waba', {
      templates: 'getMessageTemplates',
      loading: 'getLoadingFlags',
      pagination: 'getTemplatePagination',
    })
  },

  mounted() {
    this.loadFetchMessageTemplate()
  },
  methods: {
    ...mapActions('whatsapp/waba', ['fetchMessageTemplates']),
    async loadFetchMessageTemplate() {
      await this.fetchMessageTemplates({
        instance_id: this.instanceId,
        waba_channel_id: this.wabaChannelId,
        current_page: this.currentPage,
        per_page: 1000
      }).then(() => {
        this.currentPage++
        this.globalLoading = false
      })
    },
    previewTemplate(item) {
      this.selectedTemplate = item
    },
    close() {
      this.$emit('update:modelValue', false)
    }
  }
}
</script>
<style scoped lang="scss">
.modal-body {
  min-height: 80vh;
  padding: 32px !important;
}

.overflow-y-auto {
  max-height: 80vh !important;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.preview-background {
  background-color: #E8E0D5;
  background-image: url("~@/assets/img/whatsapp-background.svg") !important;
  background-size: 20%;
  background-repeat: repeat;
  background-blend-mode: darken;
}

@media only screen and (max-width: 1199.999px) {
  .waba-body {
    padding: 1rem !important;
  }
}
</style>
