<template>
  <button
    :class="['btn overflow-ellipsis', color, size, icon && 'd-flex align-items-center gap-3']"
    :disabled="loading || blocked"
    :style="'position: relative;' + (notification ? 'overflow:visible !important;' : '')"
    :title="tooltip"
    :form="form"
    ref="container"
  >
    <i
      v-if="icon && !loading"
      :class="'fa-regular fa-' + icon"
    />
    <div
      class="spinner-border spinner-border-sm"
      v-if="loading"
    >
      <span class="visually-hidden" />
    </div>
    <slot v-else />
    <span
      v-if="notification > 0"
      class="notification badge bg-danger"
    >
      {{ formatNotification(notification) }}
    </span>
  </button>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },

    blocked: {
      type: Boolean,
      default: false
    },

    color: {
      type: String,
      default: "btn-primary"
    },

    notification: {
      type: Number,
      default: 0
    },

    tooltip: {
      default: "",
      type: String
    },

    form: {
      default: null,
      type: [String, Object]
    },

    icon: {
      type: String,
      default: ""
    },

    size: {
      type: String,
      default: ""
    }
  },

  methods: {
    formatNotification(notification) {
      if (notification > 20) return "20+";
      else return notification;
    }
  }
};
</script>

<style lang="scss" scoped>
.notification.badge {
  position: absolute !important;
  top: -7px !important;
  right: -7px !important;
}

.btn-gray-blue {
  background-color: #E1E9F4;
  color: #677C92;
  border: none;
  &:hover{
    background-color: #D2DDEC;
  }
}

.btn-white-blue {
  background-color: #FFFFFF;
  color: #3057f2 !important;
  border: none;
  box-shadow: 0 4px 18px 0 rgba(34, 54, 77, 0.12);
}
</style>
