<template>
  <div class="card bar-chart">
    <div class="card-header">
      <h4 class="card-header-title">
        {{ title }}
      </h4>
      <slot name="buttons" />
    </div>
    <div class="card-body">
      <canvas
        class="chart-canvas"
        ref="chart"
      />
    </div>
  </div>
</template>

<script>
import { Chart } from "chart.js";

export default {
  mounted() {
    this.chartInstance = new Chart(this.$refs.chart, this.config);
    this.update();
  },
  data() {
    return {
      chartInstance: null,
      config: {
        type: "bar",
        data: {
          labels: this.labels,
          datasets: this.datasets,
        },
        options: {
          indexAxis: "y",
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              intersect: true,
              position: "nearest",
              displayColors: true,
              boxWidth: 300,
            },
            legend: {
              display: true,
              position: "bottom",
              labels: {
                pointStyle: "circle",
                usePointStyle: true,
                boxHeight: 10,
              },
            },
          },
          scales: {
            x: {
              stacked: true,
              ticks: {
                callback: this.formatTicks,
                stepSize: this.stepSize,
              },
            },
            y: {
              stacked: true,
            },
          },
        },
      },
    };
  },

  props: {
    formatTicks: {
      type: Function,
      default: val => val,
    },
    stepSize: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },

    datasets: {
      type: Array,
      default: () => {
        return [];
      },
    },

    labels: {
      type: Array,
      default: () => {
        return [];
      },
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    update() {
      const totalLabels = this.chartInstance.config.data.labels.length;
      let height = 400;
      if (totalLabels > 10) {
        height = totalLabels * 40;
      }
      this.chartInstance.canvas.parentNode.style.height = height + "px";
    },
  },
  watch: {
    datasets() {
      this.update();
    },
  },
};
</script>
