<template>
  <div class="container-fluid pb-4">
    <main-loading v-if="loadingFlags.findById" />
    <div v-else-if="register">
      <header-component
        :title="register.name"
        :pretitle="$t('active_IVR')"
        redirect-link="/manager/ivrs"
      />
      <form-active-ivr
        :fields="fields"
        @update:fields="fields = $event"
        @submit="submit"
      >
        <template #button>
          <app-button
            :loading="loadingFlags.remove"
            color="text-danger text-capitalize"
            @click="showModalDelete"
          >
            <span class="fal fa-trash" /> {{ $t("delete") }}
          </app-button>
        </template>
      </form-active-ivr>
    </div>
  </div>
  <modal-delete-ivr
    :show-modal="showModal"
    :redirect-link="redirectLink"
    :modal-details="modalDetails"
    @update-show-modal="boolean => showModal = boolean"
    @update-navigation="boolean => LiberationNavigation = boolean"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import MainLoading from "@/components/main-loading.vue";
import AppButton from "@components/app-button";
import HeaderComponent from "@/components/header-component.vue";
import ModalDeleteIvr from "@dialer/manager/pages/settings/ivrs/components/modal-delete-ivr.vue";
import FormActiveIvr from "@dialer/manager/pages/settings/ivrs/form-active-ivr.vue";

export default {
  components: {
    FormActiveIvr,
    ModalDeleteIvr,
    MainLoading,
    AppButton,
    HeaderComponent,
  },
  data() {
    return {
      fields: {
        name: "",
        office_hour: {},
        type_audio: "import",
        audio: null,
        keys: Array(10).fill(false),
        redirect_type: 1,
        redirect_id: "",
        instances: [],
        wait_time: 5,
      },
      selectedChannel: null,
      selectedMessage: null,
      redirectTypes: [
        {
          name: this.$t("dialer_menu_capitalize"),
          id: 1,
        },
        {
          name: this.$t("Omnichannel"),
          id: 2,
        },
      ],
      WABA_INSTANCE_TYPE_ID: 2,
      ULTRAMSG_INSTANCE_TYPE_ID: 1,
      OMNI_REDIRECT_ID: 2,
      showModal: false,
      modalDetails: {
        header: this.$t("capitalizes.exclude"),
        title: this.$t("delete_ivr_title"),
        content: this.$t("delete_ivr_confirmation"),
        buttons: {
          firstText: this.$t("yes"),
          secondText: this.$t("cancel_general"),
          backgroundColor: "bg-danger",
          action: "delete",
        },
      },
      initialFormData: {},
      LiberationNavigation: false,
      redirectLink: "",
    };
  },

  created() {
    this.setRegister(null);
    this.findById({ id: this.$route.params.id }).then(() => {
      this.fields.name = this.register.name;
      this.fields.audio = this.getFullAudioPath()
      this.fields.wait_time = this.register.wait_time ? this.register.wait_time : 5;
      this.fields.office_hour = this.register.office_hour;
      this.fields.redirect_type = this.register.redirect_type;
      this.fields.instances = this.register.instances;
      for (let item of this.register.keys) this.fields.keys[item.key] = true;
      this.initialFormData = JSON.parse(JSON.stringify(this.fields));
    });
  },

  computed: {
    ...mapGetters("manager/ivrs", {
      register: "getRegister",
      errors: "getErrors",
      loadingFlags: "getLoadingFlags",
    }),
    ...mapGetters("auth", {
      user: "getUser",
    }),
  },


  methods: {
    ...mapActions("manager/ivrs", ["update", "remove", "findById"]),
    ...mapMutations("manager/ivrs", ["setRegister"]),

    getFullAudioPath() {
      return (
          process.env.VUE_APP_BA12AS.replace("/api/v1", "") + this.register.audio +
          "&api_token=" + this.user.api_token +
          "&name=" + this.register.original_audio_name
      );
    },
    createFormData() {
      let formData = new FormData();
      if (this.fields.audio !== this.getFullAudioPath()) {
        formData.append("audio", this.fields.audio);
      }
      formData.append("_method", "PUT");
      formData.append("name", this.fields.name);
      formData.append("wait_time", this.fields.wait_time);
      formData.append("office_hour_id", this.fields.office_hour.id ? this.fields.office_hour.id : this.fields.office_hour);
      formData.append("redirect_type", this.fields.redirect_type);
      for (let item of this.fields.keys) {
        if (item === false || item === undefined) {
          formData.append("keys[]", item = false);
          continue;
        }
        formData.append("keys[]", item);
      }

      if (this.fields.redirect_type === this.OMNI_REDIRECT_ID) {
        this.fields.instances.forEach((instance, index) => {
          formData.append(`instances[${index}][instance_id]`, instance.instance_id);
          formData.append(`instances[${index}][group_channel_id]`, instance.group_channel_id);
          formData.append(`instances[${index}][type]`, instance.type);
          if (instance.type === this.ULTRAMSG_INSTANCE_TYPE_ID) {
            formData.append(`instances[${index}][quick_message_id]`, instance.quick_message_id);
            formData.append(`instances[${index}][message]`, instance.message);
          }
          if (instance.type === this.WABA_INSTANCE_TYPE_ID) {
            formData.append(`instances[${index}][template_id]`, instance.template_id);
            formData.append(`instances[${index}][template][]`, []);
          }
        });
      }

      return formData;
    },

    submit() {
      let formData = this.createFormData();
      this.update({ formData, id: this.register.id }).then(() => {
        this.LiberationNavigation = true;
        this.$router.push({ path: `/manager/ivrs` })
      })
    },
    showModalDelete() {
      this.showModal = true;
      this.modalDetails = {
        header: this.$t("capitalizes.exclude"),
        title: this.$t("delete_ivr_title"),
        content: this.$t("delete_ivr_confirmation"),
        buttons: {
          firstText: this.$t("yes"),
          secondText: this.$t("cancel_general"),
          backgroundColor: "bg-danger",
          action: "delete",
        },
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    if (from.fullPath !== to.fullPath) {
      if (JSON.stringify(this.initialFormData) !== JSON.stringify(this.fields) && !this.LiberationNavigation) {
        this.showModal = true;
        this.modalDetails = {
          header: this.$t("attention"),
          title: this.$t("pending_changes"),
          content: this.$t("pending_changes_confirmation"),
          buttons: {
            firstText: this.$t("exit_without_saving"),
            secondText: this.$t("continue_editing"),
            backgroundColor: "bg-primary",
            action: "previous page",
          },
        };
        next(false);
      } else {
        next();
      }
      this.redirectLink = to.fullPath;
    }
  },
};
</script>
