export default {
    startLoading: (state, flag) => state.loading[flag] = true,
    stopLoading: (state, flag) => state.loading[flag] = false,
    setList: (state, list) => state.list = list,
    setRegister: (state, register) => state.register = register,
    clearList: (state) => state.list = [],
    setAlert: (state, alert) => {
        state.alert = alert
    },
    setErrors: (state, errors) => { state.errors = errors;  },
    clearErrors: (state) => {
        state.errors = {}
        state.errorMessage = ""
    },
    setFilters: (state, filters) => {
        state.filters = filters;
    },
    setPagination: (state, pagination) => {
        state.pagination = pagination;
    },
    setDeletedNumber: (state, deletedNumber) => {
        state.deletedNumber = deletedNumber
    }
}