<template>
  <div class="main-content">
    <div class="container-fluid">
      <header-component
        pretitle="OMNICHANNEL 3C+"
        :title="$t('chats_reports')"
        redirect-link="/manager/reports"
      />
      <menu-search
        @searched="searched = true"
        @update-filters="submit"
        enable-advanced-filters
      />
      <table-before-search v-if="!searched && !chatsReportList.length" />
      <table-chats-report
        v-else
        @open-offcanvas="openOffCanvas"
      />
    </div>
    <offcanvas-chats-report
      :data="offCanvasData"
      ref="chatsOffCanvas"
    />
  </div>
</template>

<script>
import HeaderComponent from "@components/header-component.vue";
import TableBeforeSearch from "@dialer/manager/pages/reports/components/table-before-search.vue";
import TableChatsReport from "./components/table-chats-report.vue";
import OffcanvasChatsReport from "./components/offcanvas-chats-report.vue";

import {mapActions, mapGetters, mapMutations} from 'vuex';
import MenuSearch from "@dialer/manager/pages/reports/components/menu-search.vue";

export default {
  name: "ChatsReport",

  components: {
    MenuSearch,
    OffcanvasChatsReport,
    TableChatsReport,
    TableBeforeSearch,
    HeaderComponent,
  },

  data() {
    return {
      searched: false,
      offCanvasData: null,
    }
  },

  computed: {
    ...mapGetters("manager/chats_report", {
      loadingFlags: "getLoadingFlags",
      chatsReportList: "getChatsReportList",
    }),
  },

  methods: {
    ...mapActions("manager/chats_report", ["fetchChatsReportList"]),
    ...mapMutations("manager/chats_report", ["setFilters", "setPagination"]),
    openOffCanvas(data) {
      this.offCanvasData = data;
      this.$refs.chatsOffCanvas.open()
    },

    submit(filters) {
      this.setFilters({...filters})
      this.setPagination({
        per_page: 20,
        current_page: 1
      })
      this.fetchChatsReportList()
    }
  },
}
</script>
