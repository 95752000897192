import moment from "moment";

export function getActivityIcon(activityType) {
  const icons = {
    call: "phone",
    email: "envelope",
    message: "messages",
    "internal-note": "note",
    meeting: "user-group",
    task: "check-circle",
  }

  return icons[activityType] ? `fa-${icons[activityType]}` : "";
}

export function getActivityFormData(params, type) {
  const formData = new FormData();
  if (params.attachments && params.attachments.length > 0) {
    params.attachments.forEach((attachment, index) => {
      if (Object.hasOwnProperty.call(attachment, "id")) {
        formData.append(`attachments[${index}][id]`, attachment.id);
      }
      if (Object.hasOwnProperty.call(attachment, "name")) {
        formData.append(`attachments[${index}][name]`, attachment.name);
      }
      formData.append(`attachments[${index}][content]`, attachment.content);
      formData.append(`attachments[${index}][type]`, attachment.type);
    });
  }

  if (params.attachments_to_delete && params.attachments_to_delete.length > 0) {
    params.attachments_to_delete.forEach((attachment_to_delete, index) => {
      formData.append(`attachments-to-delete[${index}]`, attachment_to_delete);
    });
  }

  if (params.id) {
    formData.append('id', params.id);
    formData.append('_method', 'PUT');
  }

  if (params.opportunity_id) {
    formData.append('opportunity_id', Number(params.opportunity_id));
  }
  formData.append('is_completed', Number(params.is_completed));
  formData.append('type', type);
  formData.append('title', params.title);
  formData.append('due_date', `${moment(params.due_date, 'DD/MM/YYYY').format('YYYY-MM-DD')} ${params.start_time}`);
  formData.append('start_time', params.start_time);
  if (params.funnel_id) {
    formData.append('funnel_id', params.funnel_id);
  }

  if (params.description) {
    formData.append('description', params.description);
  }

  if (type === "call") {
    formData.append('phone', params.phone);
  }

  if (type === "message") {
    formData.append('phone', params.phone);
    formData.append('instance_id', params.instance ? params.instance.id : undefined);
  }

  if (type === "email") {
    formData.append('email', params.email);
  }

  if (type === "meeting") {
    formData.append('local_meeting', params.local_meeting);
    formData.append('type_meeting', params.type_meeting);
    formData.append('end_time', params.end_time);
  }

  return formData
}
