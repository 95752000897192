import axios from "axios";

export default {
  findFilters({commit, getters}) {
    commit("startLoadingFlag", "findFilters");
    commit("setFilters", {});
    return new Promise((resolve, reject) => {
      axios
      .get(`/filters`, {params: getters.getParams})
      .then(response => {
        commit("setFilters", response.data.data);
        resolve();
      })
      .catch(response => {
        commit("system/setErrorMessage", response.response.data, {root: true});
        reject();
      })
      .finally(() => {
        commit("stopLoadingFlag", "findFilters");
      });
    });
  },
  setFiltersIncludes({commit}, includes) {
    commit("setIncludes", includes);
  }
};
