<template>
  <select
    :class="{ 'form-select': true, 'is-invalid': errors }"
    :disabled="loading || disabled"
    @input="$emit('update:modelValue', $event.target.value)"
    :value="modelValue"
    data-test="ivrList-select"
  >
    <option
      selected
      disabled
      value=""
    >
      {{ $t("select_ivr") }}
    </option>
    <option
      :label="item.name"
      v-for="item in dataSelect"
      :key="item.id"
      :value="item.id"
    />
  </select>
  <div class="invalid-feedback">
    {{ errors && errors[0] }}
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  /**
   *
   */
  emits: ["update:modelValue"],

  /**
   *
   */

  /**
   *
   */
  mounted() {
    this.loadDataSelect({
        per_page: this.perPage
    });
  },

  /**
   *
   */
  computed: {
    ...mapGetters("manager/ivr-after-call", {
      dataSelect: "getDataSelect",
      loading: "isLoading",
    }),
  },

  props: {
    /**
     *
     */
    modelValue: {
      default: "",
      type: [Number, String],
    },

    /**
     *
     */
    errors: {
      type: Array,
      default: null,
    },
    /**
     *
     */
    perPage: {
      type: Number,
      default: 15,
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  /**
   *
   */
  methods: {
    ...mapActions("manager/ivr-after-call", ["loadDataSelect"]),
  },
};
</script>
