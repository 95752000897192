<template>
  <div class="main-content h-100">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div
        class="card fa-card"
        style="width: 628px;"
      >
        <div class="card-header p-0">
          <h1 class="text-center m-0">
            Verificação em duas etapas
          </h1>
        </div>
        <div class="card-body p-0">
          <div class="text-center py-6">
            <img
              src="@/assets/img/authenticator.svg"
              width="186"
            >
          </div>
          <h4 class="text-center fw-normal">
            Verifique no App o código de autenticação
          </h4>
          <h5
            class="text-center tx-text-gray"
            style="margin-bottom: 56px;"
          >
            Insira o código de 6 digitos gerado pelo seu app de autenticação
          </h5>
          <form
            id="form"
            @submit.prevent="callback()"
          >
            <div style="margin-bottom: 80px;">
              <label
                for="otp"
                class="form-label h5"
              >Insira o código</label>
              <input
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.otp_code }"
                id="otp"
                v-model="otp_code"
                required
              >
              <div
                class="invalid-feedback h6 m-0 pt-1"
                v-if="errors.otp_code"
              >
                Código incorreto, tente novamente.
              </div>
            </div>
            <div class="text-center">
              <app-button
                class="col-7"
                :blocked="otp_code.length === 0 || loadingFlags.loginTwoFa"
                :loading="loadingFlags.loginTwoFa"
              >
                Avançar
              </app-button>
            </div>
          </form>
          <div class="text-center">
            <button
              class="btn mt-3 col-7 tx-text-gray config-after"
              @click="redirectUserToLoginOrQRCode"
            >
              Voltar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import AppButton from "@/components/app-button.vue";

export default {
  data() {
    return {
      otp_code: ""
    };
  },

  components: {
    AppButton
  },

  computed: {
    ...mapGetters("auth", {
      loadingFlags: "getLoadingFlags",
      hasTwoFa: "hasTwoFa",
      authenticated: "isAuthenticated",
      isAgent: "isAgent",
      isSupervisorOrManager: "isSupervisorOrManager",
      isAdmin: "isAdmin",
      onlyHaveWhatsapp: "onlyHaveWhatsapp",
      isPartner: "isPartner",
      userLogin: "getUserLogin",
      errors: "getErrors"
    })
  },

  beforeMount() {
    this.clearErrors();
  },
  watch: {
    authenticated(authenticated) {
      if (authenticated) {
        if (this.isAgent) return this.$router.push(this.onlyHaveWhatsapp ? "/whatsapp" : "/agent");

        if (this.isSupervisorOrManager) {
          this.$router.push("/manager");
          this.setIncludes(["campaigns-landline-routes", "campaigns-mobile-routes", "company"]);
          return this.loadRoutes();
        }

        if (this.isAdmin) return this.$router.push("/admin");

        if (this.isPartner) return this.$router.push("/partner");
      }
    }
  },

  methods: {
    ...mapActions("auth", ["login", "loginTwoFa", "clearErrors"]),
    ...mapActions("manager/voice_3c", ["loadRoutes"]),
    ...mapMutations("manager/voice_3c", ["setIncludes"]),

    callback() {
      if (!localStorage.getItem("location_identifier")) {
        localStorage.setItem("location_identifier", this.hasTwoFa.location_identifier);
      } else if (this.userLogin.user !== localStorage.getItem("user")) {
        localStorage.setItem("user", this.userLogin.user);
        localStorage.setItem("location_identifier", this.hasTwoFa.location_identifier);
      }
      this.loginTwoFa({
        user: this.userLogin.user,
        password: this.userLogin.password,
        token_type: this.userLogin.token_type,
        location_identifier: localStorage.getItem("location_identifier"),
        secret: this.hasTwoFa.secret,
        otp_code: this.otp_code
      }).then(() => {
        const twoFaStatus = this.hasTwoFa.two_factor_location_status;
        if (twoFaStatus === "otp_required" || twoFaStatus === "expired") {
          this.login({
            user: this.userLogin.user,
            password: this.userLogin.password,
            token_type: this.userLogin.token_type,
            location_identifier: localStorage.getItem("location_identifier")
          });
        } else {
          this.$router.push("/login/two-fa-confirmation");
        }
      });
    },

    redirectUserToLoginOrQRCode() {
      if (this.hasTwoFa.secret) {
        this.$router.push("/login/two-fa-qr-code");
      } else {
        this.$router.push("/login");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.fa-card {
  box-shadow: 0px 4px 18px 0px rgba(34, 54, 77, 0.12);
  z-index: 1050 !important;
  padding: 32px;

  .btn-primary {
    &:disabled {
      background-color: $color-gray-blue-300;
      border-color: $color-gray-blue-300;
      color: $color-text-gray;
    }
  }

  .config-after {
    &:hover {
      background-color: $color-blue-300 !important;
      color: white !important;
    }
  }
}
</style>
