<template>
  <div
    ref="modal"
    class="modal fade"
  >
    <div
      class="modal-dialog justify-content-end"
    >
      <div class="modal-content mb-5">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div class="d-flex justify-content-center">
          <h2
            class="mb-0"
          >
            {{ $t('connecting_number') }}
          </h2>
        </div>

        <div
          v-if="!instanceCreated"
        >
          <div class="row">
            <div class="col-lg-12 text-center">
              <img
                src="@/assets/img/twinkle-pluszinha.svg"
                class=" my-5"
                alt=""
              >
              <!--
              <div
                class="form-check form-switch mb-4 d-flex justify-content-center align-items-center ms-3"
              >
                <input
                  v-model="fields.first_connection"
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="switchChecked"
                  checked
                >
                <label
                  class="form-check-label"
                  style="margin-left: 10px;"
                  for="switchChecked"
                >Importar histórico</label>
              </div>
              -->
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center mb-5">
            <app-button
              v-if="mode === 'new' || mode === 'reactivate'"
              class="button-modal"
              :disabled="loadingFlags.createInstances || reactivateFlag || loading"
              :loading="loadingFlags.createInstances || reactivateFlag || loading"
              @click="submit"
            >
              {{ $t('generate_qr_code') }}
            </app-button>
          </div>
        </div>


        <div v-if="instanceCreated">
          <div>
            <div>
              <div
                class="col-xl-12 col-lg-12 col-md-12 my-5"
                v-if="instanceStatus && instanceStatus.status?.accountStatus.status === 'authenticated'"
              >
                <div class="text-center d-flex justify-content-center align-items-center flex-column">
                  <Vue3Lottie
                    class="img-fluid"
                    :loop="false"
                    :animation-data="Check"
                  />
                  <p>
                    {{ $t('success_connected_number') }}
                  </p>
                </div>
              </div>
              <div
                v-else-if="loadingContext === 'getting-qrcode'"
                class="col-xl-12 col-lg-12 col-md-12 my-5"
              >
                <div class="text-center d-flex justify-content-center align-items-center flex-column">
                  <Vue3Lottie
                    class="img-fluid"
                    :loop="true"
                    :animation-data="Loading"
                    style="height: 200px"
                  />
                  <div class="parent-text-qrcode">
                    <p class="text-qrcode">
                      {{ $t('generating_qr_code') }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="col-xl-12 col-lg-12 col-md-12 h-100"
                v-else-if="instanceStatus && instanceStatus.status?.accountStatus.status === 'loading'"
              >
                <div class="text-center h-100 d-flex justify-content-center align-items-center flex-column">
                  <div
                    class="spinner-border tx-gray-400"
                  >
                    <span class="visually-hidden" />
                  </div>
                  <p class="mt-3">
                    {{ $t('validating_qr_code') }}
                  </p>
                </div>
              </div>
              <div
                class="col-lx-12 col-lg-12 col-md-12 d-flex justify-content-center align-items-center flex-column my-5"
                v-else-if="loadingContext === ''"
              >
                <img
                  :src="'data:image/svg+xml,' + svg"
                  alt=""
                  width="180"
                  height="180"
                >
                <p class="mt-3 mb-0 fw-normal fs-6">
                  {{ $t('qr_code') }}
                </p>
              </div>
            </div>
          </div>
          <div
            v-if="instanceCreated && !(instanceStatus && instanceStatus.status?.accountStatus.status === 'authenticated')"
            class="d-flex justify-content-center align-items-center mb-5"
          >
            <app-button
              :loading="loadingFlags.getWhatsappQrCode || loading || reactivateFlag"
              :disabled="loadingFlags.getWhatsappQrCode || loading || reactivateFlag"
              class="button-modal"
              @click="callWhatsappQrCode"
            >
              {{ $t('update_omni') }}
            </app-button>
          </div>
          <div
            class="d-flex justify-content-center align-items-center"
            v-else-if="instanceCreated && instanceStatus && instanceStatus.status?.accountStatus.status === 'authenticated'"
          >
            <button
              class="btn my-2 button-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="this.findGroupChannelById({id: this.$route.params.id})"
            >
              {{ $t('finalize') }}
            </button>
          </div>
        </div>
        <ul
          v-if="instanceStatus.status?.accountStatus.status !== 'authenticated'"
          class="w-100 list-unstyled px-4"
        >
          <li>
            <small class="tx-text-gray fs-6">
              1. {{ $t('connection_modal_1') }}
              <i class="mx-2 fa-solid fa-ellipsis-vertical" />
              {{ $t("in_screen") }}.
            </small>
          </li>
          <li>
            <small class="tx-text-gray fs-6">2. {{ $t('connection_modal_2') }}</small>
          </li>
          <li>
            <small class="tx-text-gray fs-6">3. {{ $t('connection_modal_3') }}</small>
          </li>
          <li>
            <small class="tx-text-gray fs-6">4. {{ $t('connection_modal_4') }}</small>
          </li>
        </ul>
        <div class="modal-header border-0" />
      </div>
    </div>
  </div>
</template>

<script>
import {Modal} from "bootstrap";
import {mapActions, mapGetters, mapMutations} from "vuex";
import QrImage from "qr-image";
import {Vue3Lottie} from 'vue3-lottie';
import Loading from '@/assets/lottie/loading.json';
import Check from '@/assets/lottie/check.json';
import AppButton from "@components/app-button.vue";

export default {
  data() {
    return {
      fields: {
        id: "",
        name: "",
        team_id: "",
        first_connection: true,
        status: "",
        color: null,
      },
      instanceCreated: false,
      newInstance: null,
      statusInterval: null,
      modal: null,
      qrCodeLoaded: false,
      count: 0,
      svg: "",
      mode: "new",
      Loading,
      Check,
      item: null,
      reactivateFlag: false,
    };
  },

  props: {
    data: {
      type: Object,
      default: () => {
      }
    }
  },

  components: {
    AppButton,
    Vue3Lottie,
  },

  mounted() {
    this.modal = new Modal(this.$refs.modal);
  },

  unmounted() {
    clearInterval(this.statusInterval)
  },

  methods: {
    ...mapActions("manager/whatsapp", [
      "reactivateWhatsappInstance",
      "findInstanceStatus",
      "loadTeamsSelect",
      "createInstances",
      "getWhatsappQrCode",
      "clearInstance"
    ]),

    ...mapActions("manager/whatsapp_group_channel", [
      "findGroupChannelById",
    ]),
    ...mapMutations("manager/whatsapp", [
      "setInstanceStatus",
      "startLoading",
      "stopLoading"
    ]),
    ...mapMutations("auth", ["trackEvents"]),

    submit() {
      if (this.mode === 'new') {
        let formData = new FormData();
        formData.append("name", this.data.name);
        formData.append("type", 'ultramsg');
        formData.append("team_id", this.data.team_id);
        formData.append("group_channel_id", this.$route.params.id)
        this.createInstances({formData}).then((newInstance) => {
          this.instanceCreated = true
          this.newInstance = newInstance
          this.callWhatsappQrCode()
        })
        this.trackEvents("[Manager] New Group Channel");

        return true;
      }

      this.callWhatsappQrCode();
      return true
    },

    async edit(item) {
      this.mode = "edit";
      this.fields = {
        id: item?.id,
        name: item?.name,
        team_id: item?.team_id,
        first_connection: item?.first_connection,
        status: item?.status,
        companies: item?.companies
      };
      this.trackEvents("[Manager] Edit Group Channel")
      this.setInstanceStatus([]);
      await this.clearInstance({id: this.fields.id});
      this.findInstanceStatus({id: item?.id});
      this.instanceCreated = true;
      this.callWhatsappQrCode();
      this.openModal();
    },

    callWhatsappQrCode() {
      if (this.mode === 'reactivate') {
        this.reactivateFlag = true
      }

      clearInterval(this.statusInterval)
      const instanceId = this.newInstance ? this.newInstance.id : this.fields.id

      this.getWhatsappQrCode({id: instanceId}).then(() => {
        this.svg = QrImage.imageSync(this.whatsappQrCode.qrCode, {
          type: "svg",
          size: 11
        });

        if (this.mode === 'reactivate') {
          this.instanceCreated = true;
        }

        this.statusInterval = setInterval(() => {

          this.findInstanceStatus({id: instanceId}).then(() => {
            if (this.instanceStatus.status?.accountStatus.status === 'authenticated') {
              clearInterval(this.statusInterval)

              if (this.fields.first_connection) {
                this.modal.hide()
                this.$router.push("/manager/whatsapp")
              } else {
                this.findGroupChannelById({id: this.$route.params.id}).then(() => {
                  this.modal.hide()
                })
              }
            }
          })
        }, 15000)
      }).catch(() => {

        let interval = setTimeout(() => {
          this.findInstanceStatus({id: instanceId}).then(() => {
            clearInterval(interval)
          }).catch(() => {
            this.callWhatsappQrCode();
          })
        }, 20000);
      }).finally(() => {
        if (this.mode === 'reactivate') {
          this.reactivateFlag = false
        }
      });
    },

    new() {
      this.mode = "new";
      this.fields = {
        id: "",
        name: "",
        team_id: "",
        first_connection: true,
        status: "",
        companies: ""
      };
      this.openModal();
      this.trackEvents("[Manager] New Connection")
    },

    reactivate(item) {
      this.startLoading();
      this.mode = 'reactivate'
      this.newInstance = item;

      const instanceId = this.newInstance ? this.newInstance.id : this.fields.id;
      this.findInstanceStatus({id: instanceId, reactivate: true}).then(() => {
        this.callWhatsappQrCode();
        this.openModal();
      }).catch(() => {
        this.reactivateWhatsappInstance({
          id: item.id
        }).then(() => {
          this.openModal();
          this.stopLoading();
        })
      })
    },

    openModal() {
      this.modal.show();
    }
  },

  computed: {
    ...mapGetters("manager/whatsapp", {
      instanceStatus: "getInstanceStatus",
      list: "getTeamsList",
      loadingContext: "getLoadingContext",
      loadingFlags: "getLoadingFlags",
      loading: "isLoading",
      whatsappQrCode: "getWhatsappQrCode"
    })
  }
};
</script>

<style lang="scss" scoped>

.modal {
  color: #1B1B2B !important;
}

.modal-header {
  border: none;
}

.btn-close {
  color: $color-text-200;
}

.card {
  border: 1px solid lightgray;
}

.form-check-input {
  background-color: #C5C5C5 !important;
  width: 35px;
  height: 18px;
}

.form-check-input:checked {
  background-color: #3057F2 !important;
  border: #3057F2;
}

.form-select {
  background-image: url('~@/assets/img/chevron-down.svg');
  background-size: 1.2em 1.2em;
}

.button-modal {
  border-radius: 8px;
  background-color: #3057F2;
  color: #fff;
  max-width: 100%;
  width: 387px;
}

.parent-text-qrcode {
  position: relative;
  width: 100%;
}

.text-qrcode {
  position: absolute;
  top: -80px;
  width: 100%;
}

.modal-dialog {
  max-width: 529px;
}
</style>
