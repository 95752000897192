export default {
  loadingFlags: {
    sendForward: false,
    openIaSuggest: false,
    openIaImprove: false,
  },
  newMessage: null,
  messages: {},
  paginations: {},
  stashedMessages: {},
  messagesForSearch: {},
  paginationsForSearch: {},

  improvedMessage: "",
  SuggestedMessage: "",
}
