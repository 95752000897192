<template>
  <form
    novalidate
    @submit.prevent="$emit('submitForm')"
    class="row"
    style="column-gap: 0; row-gap: 1.5rem"
  >
    <div
      class="col-12"
    >
      <label
        class="form-label cursor-pointer txt-text-200 required"
        for="productName"
      >
        {{ $t('name_capitalize') }}
      </label>

      <input
        id="productName"
        name="productName"
        type="text"
        class="form-control txt-text-200"
        v-model="productData.name"
        :class="{ 'is-invalid': errors.name }"
        maxlength="250"
      >
      <div class="invalid-feedback">
        {{ errors.name && errors.name[0] }}
      </div>
    </div>
    <div
      class="col-12"
    >
      <label
        class="form-label cursor-pointer txt-text-200"
        for="code"
      >
        {{ $t('product_code') }}
      </label>

      <input
        id="code"
        name="code"
        type="text"
        class="form-control txt-text-200"
        v-model="productData.code"
        :class="{ 'is-invalid': errors.code }"
        maxlength="250"
      >
      <div class="invalid-feedback">
        {{ errors.code && errors.code[0] }}
      </div>
    </div>
    <div
      class="col-12"
    >
      <label
        class="form-label cursor-pointer txt-text-200"
        for="description"
      >
        {{ $t('description') }}
      </label>

      <input
        id="description"
        name="description"
        type="text"
        class="form-control txt-text-200"
        v-model="productData.description"
        :class="{ 'is-invalid': errors.description }"
        maxlength="250"
      >
      <div class="invalid-feedback">
        {{ errors.description && errors.description[0] }}
      </div>
    </div>
    <div
      v-if="false"
      class="col-6"
    >
      <label
        class="form-label cursor-pointer txt-text-200 required"
        for="currency"
      >
        {{ $t('currency') }}
      </label>

      <single-select
        v-model="productData.currency"
        :list-data="currencies"
        label="label"
        custom-label-return="value"
        :placeholder="$t('currency_example')"
        :errors="errors"
        error-label="currency"
      />
    </div>

    <div class="col-6">
      <label
        for="price"
        class="form-label cursor-pointer txt-text-200 required"
      >
        {{ $t('unit_price' ) }}
      </label>
      <currency-input
        :transaction-amount="productData.price || 0"
        :errors="errors.price"
        :locale="locale"
        currency="BRL"
        :placeholder="placeholder"
        max-length="250"
        @currency="handlePrice"
      />
    </div>

    <div
      class="col-6"
    >
      <label
        class="form-label cursor-pointer txt-text-200"
        for="is_recurrent"
      >
        {{ $t('recurrence') }}
      </label>
      <single-select
        v-model="productData.is_recurrent"
        :list-data="is_recurrent"
        label="label"
        custom-label-return="value"
        :placeholder="$t('unique')"
        :errors="errors"
        error-label="is_recurrent"
        :enable-reset-select="false"
        :enable-search="false"
      />
    </div>
    <div
      class="col-6"
    >
      <label
        class="form-label cursor-pointer txt-text-200"
        for="maximum_discount"
      >
        {{ $t('max_discount') }}
      </label>

      <div class="w-100 position-relative">
        <i
          style="right: 1rem; top: .75rem"
          class="fa-regular fa-percent position-absolute"
        />
        <input
          id="maximum_discount"
          name="maximum_discount"
          type="number"
          class="form-control  txt-text-200"
          v-model="productData.maximum_discount"
          @input="handleMaximumDiscount($event.target.value)"
          :class="{ 'is-invalid': errors.maximum_discount }"
          style="padding-right: 2.5rem"
        >
        <div class="invalid-feedback">
          {{ errors.maximum_discount && errors.maximum_discount[0] }}
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CurrencyInput from "@components/currency-input.vue";
import SingleSelect from "@components/select/single-select.vue";

export default {
  name: "ProductForm",
  components: {SingleSelect, CurrencyInput},
  data() {
    return {
      productData: {
        name: "",
        code: "",
        description: null,
        currency: "BRL",
        is_recurrent: false,
        price: 0,
        maximum_discount: 0,
        is_active: true,
        category_id: ""
      },

      currencies: [
        {
          label: this.$t('currency_brl'),
          value: "BRL",
          locale: "pt-BR",
          placeholder: "R$0,00"
        },
        {
          label: this.$t('currency_eur'),
          value: "EUR",
          locale: "en-EN",
          placeholder: "€0.00"
        },
        {
          label: this.$t('currency_usd'),
          value: "USD",
          locale: 'en-US',
          placeholder: "$0.00"
        }
      ],
      is_recurrent: [
        {
          label: this.$t('unique'),
          value: false
        },
        {
          label: this.$t('recurrent'),
          value: true
        }
      ]
    }
  },
  emits: [
    'submitForm',
  ],

  computed: {
    ...mapGetters("manager/teams", {
      teams: "getTeams",
      loading: "isLoading"
    }),
    ...mapGetters("manager/products_and_services", {
      errors: "getErrors",
      showModal: "getShowModal",
      edit: "getEdit",
      productDataState: "getProductData",
      categoryData: "getCategoryData",
    }),

    locale() {
      return this.currencies?.find(currency => currency.value === this.productData.currency)?.locale || "pt-BR"
    },

    placeholder() {
      return this.currencies?.find(currency => currency.value === this.productData.currency)?.placeholder || "R$0,00"
    }
  },

  methods: {
    ...mapActions("manager/products_and_services", [
      "handleProductData",
    ]),

    handlePrice(price) {
      this.productData.price = Number(price);
    },

    handleMaximumDiscount(discount) {
      if (discount > 100 || discount < 0) {
        this.productData.maximum_discount = null
      }
    }
  },

  watch: {
    productData: {
      handler(newVal) {
        if (!this.edit) {
          newVal.category_id = this.productDataState.category_id
          this.handleProductData(newVal)
        }
      },
      deep: true
    },
    showModal: {
      handler(newVal) {
        if(newVal) {
          if (this.edit) {
            this.productData = this.productDataState;
          }
          return
        }

        this.productData = {
          name: "",
          code: "",
          description: null,
          currency: "BRL",
          is_recurrent: false,
          price: 0,
          maximum_discount: 0,
          is_active: true,
          category_id: ""
        }
      },
      immediate: true
    },
  },
}
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
</style>
