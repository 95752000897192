<template>
  <div class="d-flex align-items-center w-100">
    <div
      class="d-flex align-items-center justify-content-center action-type"
      :style="{ backgroundColor: iconBg, color: iconColor}"
    >
      <span
        style="font-size: 1.125rem"
        :class="iconClass"
      />
    </div>
    <p class="m-0 d-flex gap-2 w-100 fw-medium">
      {{ name }}
      <span
        class="tx-text-gray d-flex align-items-center gap-2"
        v-if="subtitle"
      >
        <i class="fa-solid fa-circle circle" />
        {{ subtitle }}
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: "ActionComponent",

  props:{
    name:{
      type: String,
      required: true
    },
    iconBg:{
      type: String,
      default: "#FFFFFF"
    },
    iconColor:{
      type: String,
      required: true
    },
    iconClass:{
      type: String,
      required: true
    },
    subtitle:{
      type:String,
      default: null
    }
  }
}
</script>

<style scoped lang="scss">
.action-type{
  height: 32px;
  width: 32px;
  border-radius: 8px;
  margin-right: 0.5rem
}
.circle{
  font-size: 4px;
}
</style>
