export default {
    loadingFlags: {
      fetchAgents: false,
    },
    agents: [],
    agentsPagination: {
      current_page: 1,
    },
    agentsFilters: {},
}
