<script>
import FlatPickr from "vue-flatpickr-component";
import {Portuguese} from "flatpickr/dist/l10n/pt";
import {english} from "flatpickr/dist/l10n/default";
import {Spanish} from "flatpickr/dist/l10n/es";
import moment from "moment";


export default {
  name: "DateRangeBtn",
  components: {
    FlatPickr
  },
  props: {
    customClass: {
      type: String,
      default: ""
    },

    placeholder: {
      type: String,
      default: ""
    }
  },
  emits: ["update:start", "update:end", "close"],

  data() {
    return {
      showIcon: false,
      date: null,
      rangeStart: null,
      rangeEnd: null,
      availableLocales: {
        pt: {
          lang: Portuguese,
          date: 'd/m/y',
          dateFormat: 'DD-MM-YYYY'
        },
        en: {
          lang: english,
          date: 'y/m/d',
          dateFormat: 'YYYY-MM-DD'
        },
        es: {
          lang: Spanish,
          date: 'y/m/d',
          dateFormat: 'YYYY-MM-DD'
        },
      },
      config: {
        locale: null,
        mode: "range",
        dateFormat: null,
        maxDate: null,
        minDate: null,
        enableTime: false,
        enableSeconds: false,
      }
    }
  },


  methods: {
    setLocale() {

      const locale = this.availableLocales[this.$i18n.locale].lang;
      locale.rangeSeparator = '         '
      this.config.locale = locale;
      this.config.dateFormat = this.availableLocales[this.$i18n.locale].date
    },

    updateDate(dates) {
      this.showIcon = false;

      if (dates.length) {
        const rangeStart = moment(dates[0]).format('YYYY-MM-DD');
        const rangeEnd = moment(dates[1]).format('YYYY-MM-DD');
        this.rangeStart = moment(rangeStart).format(this.availableLocales[this.$i18n.locale].dateFormat);

        this.$emit("update:start", moment(rangeStart).format('YYYY-MM-DD'));
        if (dates.length === 2) {
          this.rangeEnd = moment(rangeEnd).format(this.availableLocales[this.$i18n.locale].dateFormat);
          this.$emit("update:end", moment(rangeEnd).format('YYYY-MM-DD'));
          this.showIcon = true;
          this.$emit('close');
        }
      }
    },
  },


  mounted() {
    this.setLocale();
  },

  watch: {
    customClass: {
      handler(newVal) {
        if (!newVal || newVal !== 'btn-primary') {
          this.date = null;
        }
      }
    }
  }
}
</script>

<template>
  <div class="position-relative">
    <flat-pickr
      style="width: 100%; height: 100%; padding: 0; z-index: 2"
      class="btn btn-sm position-absolute inset-0 opacity-0"
      :class="customClass"
      v-model="date"
      :config="config"
      :placeholder="placeholder"
      @on-change="updateDate"
    />
    <button
      style="padding-left: 1rem; padding-right: 1rem;"
      :class="customClass"
      class="btn btn-sm position-relative"
    >
      <template v-if="customClass && customClass !== 'btn-primary'">
        {{ placeholder }}
      </template>
      <template v-else>
        <span>
          {{ rangeStart }}
        </span>
        <i
          style="font-size: 0.75rem"
          :class="{'tx-text-light': customClass && customClass === 'btn-primary'}"
          class="fa-regular fa-arrows-left-right mx-2"
        />
        <span>
          {{ rangeEnd }}
        </span>
      </template>
    </button>
  </div>
</template>

<style scoped lang="scss">

.btn-primary {
  &::placeholder {
    color: white;
    opacity: 1;
  }

  &::-ms-input-placeholder {
    color: white;
  }
}

.btn-sm {
  border: none;

  &:focus {
    box-shadow: none;
  }
}

.btn-gray-blue {
  background-color: #E1E9F4;
  color: #677C92;
  border: none;

  &:hover {
    background-color: #D2DDEC;
  }
}
</style>