<template>
  <div
    :style="`width: ${width};height: ${height}; min-width: ${width} ; min-height: ${height}; background-color: ${bg};`"
    :class="rounded"
    class="icon-card d-flex align-items-center justify-content-center"
  >
    <i
      :style="`font-size: ${iconSize}; color: ${color}!important;`"
      :class="variant"
      class="tx-blue-300"
    />
  </div>
</template>

<script>
export default {
  name: "IconCard",

  props: {
    variant: {
      type: String,
      required: true,
    },
    rounded: {
      type: String,
      default: "rounded"
    },
    width: {
      type: String,
      default: "40px"
    },
    height: {
      type: String,
      default: "40px"
    },

    iconSize: {
      type: String,
      default: "16px"
    },

    bg: {
      type: String,
      default: "e1e9f4"
    },

    color: {
      type: String,
      default: "3057f2"
    }
  }
}
</script>
<style scoped lang="scss">
.icon-card {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  background-color: $color-gray-blue-300;
}
</style>