<template>
  <div
    :class="colSize"
    class="pe-3"
  >
    <div
      class="input-group border-input py-2 px-3"
      :class="{ 'border-disabled': disableInput}"
    >
      <span
        v-if="showButtons"
        class="input-group-text fe fe-minus my-0 cursor-pointer tx-blue-300 py-0 ps-0 pe-2"
        @click="decrease"
        :class="{'tx-text-gray': disabledMinus}"
      />
      <input
        class="form-control tx-text-gray p-0"
        type="number"
        :value="modelValue"
        @input="updateValue"
        :class="{'text-center': showButtons}"
        :disabled="disableInput"
        @focusout="preventInvalidValue"
        :max="max"
        :min="min"
      >
      <span
        v-if="showButtons"
        class="input-group-text fe fe-plus my-0 cursor-pointer tx-blue-300 py-0 pe-0 ps-2"
        @click="increase"
        :class="{'tx-text-gray': disabledPlus}"
      />
    </div>
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],

  props: {
    colSize: {
      type: String,
      default: 'col-4'
    },

    modelValue: {
      type: Number,
      default: 0,
    },

    showButtons: {
      type: Boolean,
      default: false
    },

    disableInput: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      default: 1
    },
    max:{
      type: [Number,String],
      default: 10,
      validate(value){
        return (typeof value === 'number' && value > 0 ) || value === 'Infinity'
      }
    }
  },

  data() {
    return {
      show: null,
      internalValue: this.modelValue
    }
  },

  watch: {
    modelValue(newValue) {
      this.internalValue = newValue;
    },
  },

  computed: {
    disabledMinus() {
      if (this.showButtons && this.internalValue <= this.min) {
        return true
      }
      return false
    },
    disabledPlus() {
      if (this.showButtons && this.internalValue >= this.max) {
        return true
      }
      return false
    }
  },

  methods: {
    decrease() {
      if (this.showButtons && this.internalValue <= this.min || this.disableInput ) {
        return;
      }
      this.internalValue -= 1;
      this.updateParent();
    },

    increase() {
      if (this.showButtons && this.internalValue >= this.max || this.disableInput) {
        return;
      }
      this.internalValue += 1;
      this.updateParent();
    },

    updateValue(event) {
      this.internalValue = Number(event.target.value);
      if (isNaN(this.internalValue)) {
        this.internalValue = this.min;
      } else if (this.internalValue > this.max && this.max !== Infinity) {
        this.internalValue = this.max;
      }
      this.updateParent();
    },

    updateParent() {
      this.$emit('update:modelValue', this.internalValue);
    },

    preventInvalidValue(event) {
      if (event.target.value < this.min) {
        this.internalValue = this.showButtons ? this.min : 0;
      }
      if (event.target.value > this.max &&  this.max !== Infinity) {
        event.target.value = this.max;
        this.internalValue = this.max;
      }
      this.updateParent();
    }
  }
}
</script>

<style lang="scss" scoped>

  .border-input {
    border: 1px solid $color-gray-blue-300;
    border-radius: $border-radius;
  }
  .input-group-text, .form-control {
    border: none;
  }

  .border-disabled {
    opacity: 0.5;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

</style>
