export default {
  setDetailsPartner: (state, details) => state.detailsPartner = details,
  setListPartners: (state, list) => state.listPartners = list,
  startLoadingFlag: (state, flag) => state.loadingFlags[flag] = true,
  stopLoadingFlag: (state, flag) => state.loadingFlags[flag] = false,

  setCompanyDetails: (state, data) => state.companyDetails = data,
  setRequestWithdraw: (state, payload) => state.requestWithdraw = payload,

  setPartnerDetailsPagination: (state, pagination) =>
    state.partnerDetailsPagination = pagination,

  setPartnerDetailsCurrentPage: (state, page) =>
    state.partnerDetailsPagination.current_page = page,
  setRevenue: (state, revenue) => state.revenue = revenue,
  setExtract: (state, extract) => state.extract = extract,
};
