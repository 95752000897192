<script>
import {AgentStatus} from "@/utils";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AgentLogoutMenu",

  computed: {
    ...mapGetters("system", {
      status: "getStatus",
      userIp: "getUserIp",
    }),
    ...mapGetters("auth", {
      user: "getUser",
    }),
  },

  methods: {
    ...mapActions("auth", [
      "logout"
    ]),
    ...mapActions("agent/agent", {
      exitCampaign: "logout",
    }),

    async _logout() {
      if (this.status !== AgentStatus.OFFLINE) await this.exitCampaign();
      this.logout().then(() => {
        this.$router.push({
          name: "login",
        });
      });
    },
  }
}
</script>

<template>
  <span class="fw-normal">{{ $t("hello") }}, {{ user?.name }}!</span>
  <div class="tx-text-gray">
    {{ $t("your_ip") }}: {{ userIp }}
  </div>
  <hr class="mx-auto mx-lg-4 my-3">
  <div class="d-flex justify-content-center align-items-center col-12">
    <button
      class="btn mx-4 col button-exit d-flex justify-content-start align-items-start ps-4 text-muted"
      @click="_logout()"
    >
      <i class="fe fe-log-out me-3" />
      {{ $t("log_out_omni") }}
    </button>
  </div>
</template>

<style
  lang="scss"
  scoped
>

.button-exit {
  &:hover {
    background-color: $color-gray-blue-300;
  }
}
</style>
