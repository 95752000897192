<template>
  <div class="col-12 col-xl-6">
    <status-card
      class="position-relative"
      :card-title="$t('calls')"
      :is-loading="loadingFlags.startUpdateRealTimeCalls"
      :count="countCallsWorkingStatuses.total"
      :data-status="callsCardData"
    >
      <div
        class="d-flex align-items-center mb-4"
        :class="{
          'position-absolute top-100 start-50 translate-middle': !showCallsChart && lastFinalizedCalls.length == 0,
        }"
      >
        <div class="border-bottom w-100" />
        <div>
          <app-button
            class="show-chart-button text-small"
            @click="showCallsChart = !showCallsChart"
            :icon="showCallsChart ? 'chevron-down' : 'chevron-up'"
          >
            {{ $t("connection_graph") }}
          </app-button>
        </div>
        <div class="border-bottom w-100" />
      </div>

      <div
        class="chart"
        v-if="showCallsChart"
      >
        <campaign-statistics-chart :campaign="campaign" />
      </div>

      <div
        class="table-responsive"
        v-if="lastFinalizedCalls.length > 0"
      >
        <table class="table table-nowrap card-table table-hover">
          <thead class="position-sticky table-head top-0">
            <tr>
              <th scope="col">
                Status
              </th>
              <th scope="col">
                {{ $t("phone") }}
              </th>
              <th scope="col">
                {{ $t("talking_capitalize") }}
              </th>
            </tr>
          </thead>
          <tbody style="border-top: none">
            <tr
              v-if="lastFinalizedCalls.length"
              class="dont-apply-hover"
            >
              <td
                colspan="3"
                class="border-0"
              >
                <div
                  class="empty-row"
                />
              </td>
            </tr>

            <tr
              v-for="(call, i) in lastFinalizedCalls"
              :key="i"
            >
              <td
                :class="{ 'border-0': i === 0 }"
                class="py-0"
              >
                <span
                  :class="getStatusInfo(call.status).icon"
                  :style="'color:' + getStatusInfo(call.status).color"
                />
                {{
                  call.status == CallStatus.FAILED
                    ? getStatusInfo(call.status).text + " - " + hangupCauses[call.code]
                    : getStatusInfo(call.status).text
                }}
              </td>
              <td
                :class="{ 'border-0': i === 0 }"
                class="py-1 text-truncate tx-text-gray"
              >
                <span v-if="isBrazil">
                  {{ formatPhoneNumber(call.phone) }}
                </span>
                <span v-else>
                  {{ call.phone }}
                </span>
              </td>
              <td
                :class="{ 'border-0': i === 0 }"
                class="py-1 tx-text-gray"
              >
                {{ formatSecondsToMMSS(call.speaking_time) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </status-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { CallStatus, Utils, formatPhoneNumber, formatSecondsToMMSS } from "@/utils";
import hangupCauses from "@/utils";
import CampaignStatisticsChart from "./campaign-statistics-chart.vue";
import StatusCard from "./status-card.vue";
import AppButton from "@/components/app-button.vue";

export default {
  data() {
    return {
      statuses: [
        { color: "#f1f2f3", title: "Total" },
        { color: "#D2DDEC", title: "calling" },
        { color: "#b71c1c", title: "amd" },
        { color: "#654112", title: "ivr" },
        { color: "#f2a948", title: "on_hold" },
        { color: "#5cb85c", title: "talking" }
      ],
      countStatuses: [0, 0, 0, 0, 0, 0],
      CallStatus,
      hangupCauses,
      timer: "",
      showCallsChart: false
    };
  },
  components: {
    CampaignStatisticsChart,
    StatusCard,
    AppButton
  },

  mounted() {
    if (this.campaign.ura_limit == 0) this.removeUraStatus();

    if (this.campaign) this.startUpdateRealTimeCalls({ campaign: this.campaign });
  },

  unmounted() {
    this.stopUpdateRealTimeCalls();
  },

  watch: {
    campaign() {
      if (this.campaign) this.startUpdateRealTimeCalls({ campaign: this.campaign });
    },

    countCallsWorkingStatuses: {
      handler(data) {
        this.countStatuses = [data.total, data.trying, data.amd, data.ura, data.waiting, data.in_progress];
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters("manager/real-time", {
      countCallsWorkingStatuses: "getCountCallsWorkingStatuses",
      lastFinalizedCalls: "getLastFinalizedCalls",
      loadingFlags: "getLoadingFlags"
    }),

    ...mapGetters("auth", {
      user: "getUser",
      isBrazil: "isBrazil"
    }),

    callsCardData() {
      return [
        { label: "calling", value: this.countCallsWorkingStatuses.trying, color: "#D2DDEC" },
        { label: "talking", value: this.countCallsWorkingStatuses.in_progress, color: "#008A35" },
        { label: "on_hold", value: this.countCallsWorkingStatuses.waiting, color: "#E67C0B" },
        { label: "amd", value: this.countCallsWorkingStatuses.amd, color: "#E60B42" },
        { label: "ivr", value: this.countCallsWorkingStatuses.ura, color: "#654112" },
      ];
    }
  },

  props: {
    campaign: {
      type: Object,
      default: null,
      required: true
    }
  },

  methods: {
    timeFormat: Utils.timeFormat,
    mask: Utils.mask,
    formatPhoneNumber,
    formatSecondsToMMSS,

    ...mapActions("manager/real-time", ["startUpdateRealTimeCalls", "stopUpdateRealTimeCalls"]),

    getStatusInfo(status) {
      switch (status) {
        case 5:
          return {
            icon: "mx-2 fe fe-minus",
            color: "#989898",
            text: this.$t("not_answer")
          };
        case 6:
          return {
            icon: "mx-2 fe fe-alert-triangle",
            color: "#DE7912",
            text: this.$t("abandoned_capitalize")
          };
        case 7:
          return {
            icon: "mx-2 fe fe-phone",
            color: "#5cb85c",
            text: this.$t("connected_general")
          };
        case 8:
          return {
            icon: "mx-2 fe fe-x",
            color: "#B2B3C1",
            text: this.$t("fail")
          };
        case 9:
          return {
            icon: "mx-2 fe fe-voicemail",
            color: "#ce4c35",
            text: this.$t("post_call_ivr")
          };
        case 15:
          return {
            icon: "mx-2 fe fe-phone-off",
            color: "#ce4c35",
            text: this.$t("pre_call_ivr")
          };
        default:
          return {
            icon: "mx-2 fe fe-help-circle",
            color: "#B2B3C1",
            text: ""
          };
      }
    },

    removeUraStatus() {
      let newStatusList = Object.assign({}, this.statuses);
      delete newStatusList[3];
      this.statuses = newStatusList;
    }
  }
};
</script>

<style scoped lang="scss">
.show-chart-button {
  height: 32px;
  color: var(--color-text-gray);
  background-color: var(--bs-white);
  border: 1px solid var(--color-gray-blue-300);
  padding: 8px 16px;

  &:focus {
    box-shadow: 0 0 0 0.15rem rgba(103, 124, 146, 0.2);
  }
}

.table-responsive {
  max-height: 640px;

  .card-table {
    .table-head {
      z-index: 1;
    }

    tbody {
      tr {
        &:last-child {
          td {
            padding-bottom: 0.75rem !important;
          }
        }

        td {
          &:last-child {
            padding: 0 !important;
          }

          .empty-row {
            height: 16px;
          }
        }

        &.dont-apply-hover {
          &:hover > * {
            --bs-table-hover-bg: none;
          }
        }
      }
    }
  }
}
</style>
