export default {
  actions: [],
  action:[],
  actionToEdit: {},
  prevActionId: null,

  offcanvasEditActionIsOpen: false,
  offcanvasNewActionIsOpen: false,
  offcanvasSelectChannelIsOpen: false,
  selectedAction: null,
  nodesPositions:[],

  groupChannels: [],
  loadingFlags: {
    createAction: false,
    updateAction: false,
    deleteAction: false,
    groupChannels: false,
    updateActionEdge: false,
  },

  updateCoordinates: false,
  zoom: 1.0,
  hoveredEdgeId:null,
}
