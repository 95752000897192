<template>
  <base-select
    :disabled="disabled"
    :error-label="errorLabel"
    :errors="errors"
    :has-tags="false"
    :label="label"
    :multiselect="false"
    :placeholder="placeholder"
    :prefix="prefix"
    :select-height="selectHeight"
    :select-width="selectWidth"
    :selected-item="showExhibitionValue"
    :font-size="fontsize"
    :padding="padding"
    :enable-search="enableSearch"
    @update-term="updateTerm"
  >
    <template #opened-select-content>
      <ul
        v-if="filteredList.length > 0"
        class="overflow-auto list-unstyled tx-text-200 mb-0"
        style="max-height: 180px;"
      >
        <li
          tabindex="0"
          v-if="enableResetSelect"
          class="option mb-0 rounded-1 cursor-pointer"
          :style="`padding: 0 16px 0 8px; line-height: 2; font-size: ${fontsize}`"
          @click="resetSelect"
          @keydown.enter="resetSelect"
        >
          {{ $t('select') }}
        </li>
        <li
          v-for="option in filteredList"
          tabindex="1"
          :key="option.id"
          class="option mb-0 rounded-1 cursor-pointer"
          :style="`padding: 0 16px 0 8px; line-height: 2; font-size: ${fontsize}`"
          @click="selectItem(option)"
          @keydown.enter="selectItem(option)"
        >
          {{ option[label] }}
        </li>
      </ul>
      <p
        v-else
        class="m-0 text-center"
      >
        {{ $t('no_field_found') }}
      </p>
    </template>
  </base-select>
</template>

<script>
import BaseSelect from "@components/select/base/base-select.vue";

export default {
  name: 'SingleSelect',
  components: {
    BaseSelect,
  },

  emits: ['update:modelValue'],

  props: {
    selectHeight: {
      type: String,
      default: '40px',
    },

    selectWidth: {
      type: String,
      default: '100%',
    },

    modelValue: {
      type: [String, Object],
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      required: true,
    },

    customLabelReturn: {
      type: String,
      default: null,
    },

    listData: {
      type: Array,
      required: true,
    },

    placeholder: {
      type: String,
      default:  "Selecione",
    },

    errors: {
      type: Object,
      default: () => ({}),
    },

    errorLabel: {
      type: String,
      default: '',
    },

    prefix: {
      type: String,
      default: ""
    },

    returnAllObject: {
      type: Boolean,
      default: false,
    },

    fontsize: {
      type: String,
      default: "16px",
    },

    padding: {
      type: String,
      default: '8px 16px'
    },

    enableResetSelect: {
      type: Boolean,
      default: true
    },

    enableSearch: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      searchTerm: '',
    }
  },

  computed: {
    filteredList() {
      return this.listData.filter((option) =>
          option[this.label].toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },

    showExhibitionValue() {
      if (this.customLabelReturn && this.modelValue) {
        return this.listData.find((option) => option[this.customLabelReturn] === this.modelValue)[this.label];
      }

      if (this.returnAllObject && this.modelValue) {
        return this.modelValue[this.label];
      }

      return this.modelValue;
    }
  },

  methods: {
    selectItem(item) {
      if (this.returnAllObject) {
        this.$emit('update:modelValue', item);
        return;
      }

      if (this.customLabelReturn) {
        return this.$emit('update:modelValue', item[this.customLabelReturn]);
      }

      this.$emit('update:modelValue', item[this.label]);
    },

    updateTerm(term) {
      this.searchTerm = term;
    },

    resetSelect() {
      if (this.returnAllObject) {
        return this.$emit('update:modelValue', {});
      }

      this.$emit('update:modelValue', this.returnAllObject ? {} : '');
    }
  },

  beforeUnmount() {
    this.resetSelect();
  },

  watch: {
    listData: {
      handler(newVal) {
        if (newVal) {
          this.resetSelect();
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.option {
  &:hover,
  &:focus,
  &:focus-visible{
    background-color: $color-gray-blue-300;
    outline: none;
  }
}
</style>