<template>
  <div class="col-12 col-lg-6 col-xl">
    <div
      :class="{ 'cursor-pointer active': active }"
      class="card"
    >
      <div class="card-body d-flex flex-column justify-content-between">
        <span class="d-flex align-items-center justify-content-center">
          <i
            v-if="icon"
            :class="icon"
            class="icon tx-blue-300"
          />
          <slot
            v-else
            name="primary-icon"
          />
        </span>

        <div>
          <p class="tx-text-gray fw-bold w-100 mb-1">
            {{ text }}
            <slot name="tooltip" />
          </p>

          <div
            :class="{ 'tx-text-gray opacity-50': !user?.clicksign_contract?.signed && !admin }"
            class="d-flex align-items-baseline justify-content-between mb-2"
          >
            <slot name="detail" />
            <slot name="icon" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CardSlotPartner",
  props: {
    icon: { type: String, default: "" },
    text: { type: String, default: "" },
    active: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters("partner", {
      user: "getDetailsPartner"
    }),

    ...mapGetters("auth", {
      admin: "isAdmin"
    })
  }
};
</script>

<style lang="scss" scoped>
.card {
  height: 190px;
  min-width: 221.5px;

  span {
    background-color: $color-gray-blue-300;
    width: 42px;
    height: 42px;
    border-radius: 6px;
    text-align: center;
    line-height: 45px;
  }

  .icon {
    padding: 9px;
    font-size: 20px;
  }
}

.card:hover.active {
  background-color: var(--color-gray-blue-200);
  border-color: var(--color-calling);
}
</style>
