
export default {
  tourSteps: null,
  loadingFlags: {
    fetchTourSteps: false,
    updateTourSteps: false
  },
  currentTab: '',
  startTour: false,
  openModal: false,
}
