import store from "@store";

import UtilizationReport from "@dialer/manager/pages/reports/utilization-report.vue";

export default {
  path: "utilization-report",
  name: "utilization-report",
  components: {
    main: UtilizationReport
  },
  meta: {
    "short-side-bar": true
  },
  beforeEnter: (to, from, next) => {
    if (store.getters["auth/isManager"]) next();
    else next({
      name: "not-found",
      params: {
        pathMatch: to.path.split('/').slice(1)
      }
    });
  }
};
