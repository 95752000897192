export default {
  loading: false,
  errors: {},
  list: [],
  dataSelect: null,

  filters: {},
  include: ['keys'],
  pagination: {
    per_page: 12,
    current_page: 1
  },

}
