import Blocklist from "@dialer/manager/pages/settings/blocklist/blocklist-settings.vue";

export default {
  path: "blocklist",
  name: "blocklist",
  components: {
    main: Blocklist
  },
  meta: {
    "short-side-bar": false
  }
};
