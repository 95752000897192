<template>
  <message-insight
    v-if="msg.type === 'ai-chat-evaluation-message'"
    class="mx-auto my-4"
    :insight="msg.body"
  />
  <show-note
    :id="msg.internal_id"
    v-else-if="getIsNote(msg.type)"
    :gray="getNote(msg).gray"
  >
    <p
      class="mb-0"
      v-html="getNote(msg).text.trim().replace(/\s+/g, ' ')"
    />
  </show-note>
  <ballon-message
    v-else
    :ref="msg.internal_id"
    :message="msg"
    :current-chat="currentChat"
    :previous-msg="previousMsg"
    :locked-send-message="lockedSendMessage"
    @hover="messageId => dashText = messageId"
    @quote="quote => this.$emit('quote', quote)"
    @resend="message => this.$emit('resend', message)"
    @open-modal="this.$emit('open')"
  >
    <template #message="{message}">
      <message-template
        v-if="message.type === 'template'"
        :is-deleted="message.is_deleted && dashText !== message.id"
        :message="message.template"
      />
      <message-text
        v-if="message.type === 'chat'"
        :text="message.body"
        :is-deleted="message.is_deleted && dashText !== message.id"
      />
      <message-video
        v-if="message.type === 'video'"
        :text="message.body"
        :media="message.media"
        :is-deleted="message.is_deleted && dashText !== message.id"
      />
      <message-image
        v-else-if="message.type === 'image'"
        :text="message.body"
        :media="message.media"
        :is-deleted="message.is_deleted && dashText !== message.id"
      />
      <message-audio
        v-else-if="message.type === 'ptt' ||
          message.type === 'audio' ||
          message.type === 'voice'"
        :type="message.type"
        :path="message.media"
        :from-me="message.fromMe"
        :message-ack="message.ack"
      />
      <message-document
        v-else-if="message.type === 'document'"
        :file-name="message.media_original_name"
        :size="message.size"
        :url="message.media"
        :text="message.body"
        :is-deleted="message.is_deleted && dashText !== message.id"
      />
      <message-contact
        v-else-if="message.type === 'vcard'"
        :body="message.body"
        :instance-id="currentChat.instance_id"
      />
      <message-sticker
        v-else-if="message.type === 'sticker'"
        :media="message.media"
      />
    </template>
    <template
      v-if="verifyShowQuoted(msg)"
      #quote="{message}"
    >
      <ballon-quote>
        <div class="col d-flex align-items-center quote-content">
          <div class="col d-flex flex-column px-3 py-4 gap-1">
            <p
              v-if="message.quoted_msg.type === 'chat'"
              class="text-medium lh-1 m-0"
            >
              {{ msg.quoted_msg.body }}
            </p>
            <display-type
              v-else
              :msg-id="message.quoted_msg.id"
              :body="message.quoted_msg.body"
              :media="message.quoted_msg.media"
              :type="message.quoted_msg.type"
            />
          </div>
          <img
            v-if="message.quoted_msg.type === 'image'"
            :src="getQuotedMessageMedia(message)"
            alt="reply-image"
            class="reply-image"
          >
        </div>
      </ballon-quote>
    </template>
  </ballon-message>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

import DisplayType from "@whatsapp/components/display-type.vue";
import MessageDocument from "@whatsapp/components/messages-types/message-document.vue";
import BallonQuote from "@whatsapp/components/ballon-quote.vue";
import BallonMessage from "@whatsapp/components/ballon-message.vue";
import MessageAudio from "@whatsapp/components/messages-types/message-audio.vue";
import MessageText from "@whatsapp/components/messages-types/message-text.vue";
import MessageImage from "@whatsapp/components/messages-types/message-image.vue";
import MessageSticker from "@whatsapp/components/messages-types/message-sticker.vue";
import ShowNote from "@whatsapp/components/messages-types/show-note.vue";
import MessageInsight from "@whatsapp/components/messages-types/message-insight.vue";
import MessageVideo from "@whatsapp/components/messages-types/message-video.vue";
import MessageTemplate from "@whatsapp/components/messages-types/message-template.vue";
import MessageContact from "@whatsapp/components/messages-types/message-contact.vue";

export default {
  name: "WhatsappMessage",

  emits: ["quote", "resend", "open"],

  components: {
    MessageContact,
    MessageTemplate,
    MessageVideo,
    MessageInsight,
    ShowNote,
    MessageSticker,
    MessageImage,
    MessageText,
    MessageAudio,
    BallonMessage,
    BallonQuote,
    MessageDocument,
    DisplayType
  },

  props: {
    msg: {
      type: Object,
      required: true,
    },
    previousMsg: {
      type: Object,
      required: true,
    },
    lockedSendMessage: {
      type: Boolean,
      required: true
    },
    companyDomain: {
      type: String,
      required: true
    }
  },

  data(){
    return {
      dashText: null
    }
  },

  computed: {
    ...mapGetters("whatsapp/chat", {
      currentChats: "getCurrentChat",
    }),

    currentChat() {
      return this.currentChats[this.$route.params.id];
    },
  },

  methods: {
    getIsNote(messageType) {
      return [
        'active-ivr-message',
        'internal-message',
        'transfer',
        'snooze-message',
        'qualification-message',
        'protocol-message'
      ].includes(messageType)
    },

    getNote(message) {
      const { type, body, time, agent, inter_message_data = {}} = message
      const { previous_agent, current_agent, end_snooze, list_id, name  } = inter_message_data

      switch (type) {
        case "active-ivr-message":
          return {
            gray: true,
            text: body
          }
        case "internal-message":
          return {
            gray: false,
            text: `<strong>${this.$t('internal_note')} - ${agent.name}: </strong>${body} - ${this.formatHour(time)}`
          }
        case "transfer":
          return {
            gray: true,
            text: `${this.$t('transferred_from')} ${previous_agent.name}
            ${this.$t('transferred_to.to')} ${current_agent?.name || this.$t('transferred_to.to_queue')}
            ${previous_agent.id !== agent.id ? this.$t('by') + " " + agent.name : " "} - ${this.formatHour(time)}`
          }
        case "snooze-message":
          return {
            gray: true,
            text: `${this.$t('put_on_hold_by')} ${agent?.name} ${this.$t('for')} ${this.formatSnoozeDuration(time, end_snooze)}
            ${this.$t('until')} ${this.formatHour(end_snooze)}`
          }
        case "qualification-message":
          if (!list_id) {
            return {
              gray: false,
              text: `<strong>${this.$t('end_chat_note_no_qualification')} - ${agent.name}: </strong>${body} - ${this.formatHour(time)}`
            }
          }
          return {
            gray: false,
            text: `<strong>${name} . ${this.$t('end_chat_by')} - ${agent.name}: </strong>${body} - ${this.formatHour(time)}`
          }
        case "protocol-message":
          if(!body.includes('Finalizado')){
            if (agent.name) {
              return {
                gray: true,
                text: this.$t('rich_text.internal_notes.protocol', {
                  name: agent.name,
                  protocol: this.formatProtocol(body),
                  time: this.formatHour(time),
                })
              }
            }
            return {
              gray: true,
              text: this.$t('rich_text.internal_notes.started_by_client', {
                protocol: this.formatProtocol(body),
                time: this.formatHour(time),
              })
            }
          }
          return {
            gray: true,
            text: body
          }

        default:
          return {
            gray: false,
            text: message
          }
      }
    },

    formatHour(time) {
      return moment.unix(time).format("HH:mm");
    },

    formatSnoozeDuration(start, end) {
      start = moment.unix(start);
      end = moment.unix(end);
      let hours = start.diff(end, "hours");
      return hours * -1 + 1 + " horas";
    },

    formatProtocol(message) {
      return message.replace("Protocolo do Atendimento: ", "");
    },

    verifyShowQuoted(message) {
      const quotedMessage = message.quoted_msg
      const hasId = !!quotedMessage?.id
      const hasBodyIfNecessary = ['chat', 'template'].includes(quotedMessage?.type)? quotedMessage?.body : true

      if (!quotedMessage || !hasId || !hasBodyIfNecessary) {
        return false
      }
      return true
    },

    getQuotedMessageMedia(message) {
      return message.fromMe?
        this.companyDomain + message.quoted_msg.media :
        message.quoted_msg.media;
    },
  }
}
</script>

<style scoped lang="scss">
.quote-content {
  background-color: #f0f4fa;
  border-radius: $whatsapp-border-radius $whatsapp-border-radius $whatsapp-border-radius 0;

  p {
    max-height: 28px;
    overflow: hidden;
    text-overflow: clip;
  }
}

.reply-image {
  height: 68px;
  width: 68px;
  object-fit: cover;
  border-radius: 0 $whatsapp-border-radius $whatsapp-border-radius 0;
}
</style>
