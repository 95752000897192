<template>
  <div
    class="d-flex flex-wrap gap-05"
  >
    <button
      :class="selectedFilter === filters.me.value ? 'btn-primary' : ' btn-gray-blue'"
      class="btn btn-filter border-radius-05"
      @click="selectMe"
    >
      <i class="fa-regular fa-user" />
      {{ filters.me.label }}
    </button>
    <div class="dropdown">
      <button
        :ref="`dropdownBtn-${id}`"
        class="btn btn-filter btn-primary  dropdown-toggle border-radius-05"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-bs-auto-close="outside"
        @click="selectAll"
      >
        <span class="d-inline-block min-width-152">
          <i
            v-if="!selectedUsers.length"
            class="fa-regular fa-users"
          />
          {{ selectedLabel }}
        </span>
      </button>
      <ul
        :style="`min-width: ${minWidth}px; padding: 1.5rem;`"
        class="dropdown-menu"
      >
        <li>
          <users-tab
            :id="id"
            :key="selectedFilter"
            multi-select
            label="name"
            :current-owner-id="-1"
            :current-user-id="user.id"
            :users="users"
            @role="updateRole"
            v-model="selectedUsers"
            @search="search"
            :loading="loading"
          />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import {Dropdown} from "bootstrap";
import UsersTab from "@whatsapp/components/transfer-form/users-tab.vue";
import {mapGetters} from "vuex";

export default {
  name: "OnlyMeFilters",
  components: {UsersTab},
  emits: ['select-filter', 'update-role', 'search', 'selected-users', 'update:modelValue'],
  props: {
    users: {
      type: Array,
      required: true
    },
    modelValue: {
      type: Array,
      required: true
    },
    minWidth: {
      type: [String, Number],
      default: '340'
    },

    loading: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: 'id'
    }
  },

  data() {
    return {
      dropdownInstance: null,
      selectedFilter: 'all',
      selectedUsers: [],
      filters: {
        all: {
          icon: "fa-regular fa-users",
          label: this.$t('capitalizes.all'),
          value: "all"
        },
        me: {
          icon: "fa-regular fa-user",
          label: this.$t('capitalizes.only_me'),
          value: "me"
        },
      },
      params: {
        page: 1,
        search: "",
        team: null
      },
    }
  },

  mounted() {
    this.dropdownInstance = new Dropdown(this.$refs[`dropdownBtn-${this.id}`], {
      autoClose: 'outside'
    });
    this.params.team = this.currentFunnel?.team?.id;
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser"
    }),
    ...mapGetters("crm/funnels", {
      currentFunnel: "getCurrentFunnel",
    }),

    selectedLabel() {
      if (this.selectedUsers.length > 1) {
        return `${this.selectedUsers.length} ${this.$tc('selected_pluralization', this.selectedUsers.length)}`
      }
      if (this.selectedUsers.length === 1) {
        return this.selectedUsers[0].name;
      }

      return this.filters.all.label;
    }
  },

  methods: {
    selectMe() {
      if (this.selectedFilter !== this.filters.me.value) {
        this.selectedFilter = this.filters.me.value
        this.inactiveBtnMenu();
        this.updateFilter();
        this.selectedUsers = [];

        return true;
      }

      return false;
    },
    selectAll() {
      if (this.selectedFilter !== this.filters.all.value) {
        this.selectedFilter = this.filters.all.value
        this.activeBtnMenu();
        this.updateFilter();

        return true;
      }

      return false
    },

    activeBtnMenu() {
      this.$refs[`dropdownBtn-${this.id}`].classList.add('btn-primary')
      this.$refs[`dropdownBtn-${this.id}`].classList.remove('btn-gray-blue')
    },

    inactiveBtnMenu() {
      this.$refs[`dropdownBtn-${this.id}`].classList.remove('btn-primary')
      this.$refs[`dropdownBtn-${this.id}`].classList.add('btn-gray-blue')
    },

    updateFilter() {
      this.$emit('select-filter', this.selectedFilter);
    },

    updateRole(role) {
      this.$emit('update-role', role)
    },

    search(searchQuery) {
      this.$emit('search', searchQuery)
    }
  },

  watch: {
    selectedUsers(users) {
      this.$emit('update:modelValue', users)
    }
  }
}
</script>
<style scoped lang="scss">
.btn-filter {
  font-size: 0.75rem;
  padding: 0.25rem 1rem;
  line-height: 1;
  text-align: left;
}

.btn {
  &:focus,
  &:focus-visible {
    box-shadow: none !important;
  }
}

.btn-gray-blue {
  background-color: $color-gray-blue-300;
  color: $color-text-gray;

  &:hover,
  &:focus,
  &:focus-visible {
    background-color: #D2DDEC;
  }
}

.gap-05 {
  gap: 0.5rem;
}
.border-radius-05 {
  border-radius: 0.5rem;
}
.min-width-152 {
  min-width: 152px;
}
</style>