<template>
  <div
    :class="{
      'active': collapseOpened
    }"
    class="card mb-0 bordered-card shadow-none rounded"
  >
    <button
      :style="collapseOpened ? 'border-radius: 0.625rem 0.625rem 0 0' : 'border-radius: 0.625rem'"
      class="btn card-header border-0 text-left h-auto option"
      type="button"
      :disabled="blocked"
      @click="open"
    >
      <span class="d-inline-block thumb">
        <img
          width="40"
          height="40"
          loading="lazy"
          :alt="`Thumb-${id}`"
          :src="imageSrc"
          class="avatar-img rounded-circle"
        >
      </span>
      <span class="d-flex flex-wrap flex-column justify-content-start text-start lh-sm">
        <span class="tx-text-200 d-block fw-medium">
          {{ contact?.name || contact.pushname || contact.number }}
        </span>
        <span class="tx-text-gray d-block fs-6">
          {{ formatPhoneNumber(contact.number) }}
        </span>
      </span>
      <span
        v-if="loading"
        class="d-inline-block ms-auto tx-text-300 "
      >
        <i class="fa-regular fa-circle-notch spin" />
      </span>
    </button>

    <div
      style="padding: 0"
      class="card-body"
    >
      <div
        ref="collapseInstance"
        class="collapse"
        :id="id"
      >
        <div
          style="margin: 1rem"
          v-if="allowedInstances[0]?.instances?.length && selectedContact === contact"
        >
          <channels-accordion-item
            v-for="(channel , cIndex) in allowedInstances"
            :key="`channel-${cIndex}`"
            accordion-id="instancesAccordion"
            :dividers="cIndex < allowedInstances?.length - 1"
            :instance="channel"
            @select-instance="selectInstance"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AltImage from "@assets/img/avatar.svg"
import { Collapse } from "bootstrap";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { formatPhoneNumber } from "@/utils";
import ChannelsAccordionItem from "@whatsapp/components/transfer-form/channels-accordion-item.vue";

export default {
  components: {
    ChannelsAccordionItem,
  },

  props: {
    contact: {
      default: null,
      type: Object
    },
    id: {
      type: String,
      default: 'id'
    }
  },
  data() {
    return {
      image: AltImage,
      collapseInstance: null,
      loading: false,
      collapseOpened: false
    };
  },

  computed: {
    ...mapGetters("auth", {
      isAgent: "isAgent",
      isManager: "isManager"
    }),

    ...mapGetters("whatsapp/contact", {
      loadingFlags: "getLoadingFlags",
      selectedContact: "getSelectedContact",
      contactInstances: "getContactInstances"
    }),

    ...mapGetters("whatsapp/instance", {
      groupChannels: "getGroupChannels",
      allowedInstances: "getAllowedInstances"
    }),

    instances() {
      let instances = [];
      if (Object.keys(this.groupChannels).length) {
        for (const groupChannel of this.groupChannels) {
          for (const instance of groupChannel.channels.data) {
            if (instance.status === "connected" ) {
              instances.push(instance)
            }
          }
        }
      }
      return instances
    },

    imageSrc() {
      return this.contact?.image_url || this.image
    },


    blocked() {
      return this.instances.some((instance) => instance.phone === this.contact.number);
    }
  },

  mounted() {
    if (this.instances.length > 1) {
      this.collapseInstance = new Collapse(this.$refs.collapseInstance, { toggle: false });
    }
  },

  watch: {
    selectedContact(contact) {
      if (contact.number !== this.contact.number) {
        this.collapseInstance.hide();
      }
    }
  },

  methods: {
    ...mapActions("whatsapp/contact", ["findContactInstances"]),
    ...mapActions("whatsapp/chat", ["findChat"]),
    ...mapMutations("whatsapp/contact", ["setSelectedContact"]),
    ...mapMutations("whatsapp/chat", ["setEmptyChatContact"]),

    formatPhoneNumber,

    selectInstance(instance) {
      const number =
        this.contact.number.length === 12
          ? this.contact.number.slice(0, 4) + "9" + this.contact.number.slice(4)
          : this.contact.number;

      this.redirectToChat(`${number}${instance.id}`, instance)
    },

    open() {
      if (this.loadingFlags.findContactInstances) return;

      const number =
        this.contact.number.length === 12
          ? this.contact.number.slice(0, 4) + "9" + this.contact.number.slice(4)
          : this.contact.number;

      if (this.instances.length > 1) {
        if (this.selectedContact === this.contact) {
          this.collapseInstance.toggle();
          this.collapseOpened = !this.collapseOpened;
        }
        else {
          this.loading = true;
          this.setSelectedContact(this.contact);
          this.findContactInstances(number).then(() => {
            this.collapseInstance.toggle();
            this.collapseOpened = !this.collapseOpened;
          }).finally(()=>{
            this.loading = false;
          });
        }
      }
      else {
        this.loading = true;
        if(this.isManager) this.redirectToChat(`${number}${localStorage.getItem("instanceId")}`)
        else this.redirectToChat(`${number}${this.instances[0].id}`);
      }
    },

    redirectToChat(chatId, instance = null) {
      if(!instance) {
        instance = this.instances[0]
      }

      this.findChat(chatId)
        .then(chat => {
          this.$router.push("/whatsapp/" + chat.id);
        })
        .catch(status => {
          if (status === 404) {
            let emptyChatContact = this.contact;
            emptyChatContact.instance = instance;

            this.setEmptyChatContact(emptyChatContact);

            this.$router.push("/whatsapp/empty-chat");
          }
        }).finally(()=>{
          this.loading = false;
        })
    },
  }
};
</script>

<style lang="scss" scoped>
@keyframes spin {
  0%{
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 1s infinite linear;
}

.bordered {
  &-card {
    border-color: transparent;

    &.active {
      border-color: #E1E9F4
    }
  }
}

.option {
  padding: 0.875rem 0.5rem;
  line-height: 1.3;
  box-shadow: none;
  background-color: transparent;

  &:hover {
    background-color: $color-gray-blue-300;
  }
}
.thumb {
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  margin-right: 0.5rem
}
</style>
