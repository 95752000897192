<template>
  <div
    class="accordion"
    style="margin: 1rem .5rem 0 .5rem;"
  >
    <div class="accordion-item">
      <div
        class="accordion-header"
        :id="index"
      >
        <button
          class="accordion-button collapsed lh-1 border-bottom-1 py-3"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="'#collapse' + index"
          aria-expanded="false"
          :aria-controls="'collapse' + index"
          style="background-color: white;"
          :data-test="'accordion' + index + '-button'"
        >
          <slot name="header" />
        </button>
      </div>
      <div
        :id="'collapse' + index"
        class="accordion-collapse collapse hide"
        :aria-labelledby="index"
        :data-bs-parent="'#accordion' + index"
      >
        <div class="accordion-body">
          <slot name="body" />
        </div>
      </div>
    </div>
  </div>
</template>

<!-- Data test -->

<script>
export default {
  props: {
    index: {
      type: Number,
      default: null
    }
  }
}
</script>

<style lang="scss">
.accordion-body {
  background-color: #F0F4FA;
}

.accordion-button {
  :not(collapsed) {
    color: #373753;
    box-shadow: unset;
  }
}
</style>
