
import axiosOmni from "@/plugins/axios-omni.js"

export default {
  findActiveGroupChannels({ commit }, page) {
    commit("startLoadingFlag", "findActiveGroupChannels");
    commit("setGroupChannels", []);
    return new Promise((resolve, reject) => {
      axiosOmni
        .get("/group-channel/simplified-list", {params: {per_page: 19, page: page, status: 'active'}})
        .then(response => {
          const pagination = {
            current_page: response.data.data.current_page,
            per_page: response.data.data.per_page,
            total: response.data.data.total,
            total_pages: response.data.data.last_page,
          }
          commit("setGroupChannelsPagination", {status: 'active', groupChannelsPagination: pagination});
          commit("setGroupChannels", {status: 'active', groupChannels: response.data.data.data});
          resolve();
        })
        .catch((response) => {
          commit('system/setErrorMessage', response.response.data, {root: true})
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "findActiveGroupChannels");
        });
    });
  },

  findDeletedGroupChannels({ commit }, page) {
    commit("startLoadingFlag", "findDeletedGroupChannels");
    commit("setGroupChannels", []);
    return new Promise((resolve, reject) => {
      axiosOmni
        .get("/group-channel/simplified-list", {params: {per_page: 20, page: page, status: 'deleted'}})
        .then(response => {
          const pagination = {
            current_page: response.data.data.current_page,
            per_page: response.data.data.per_page,
            total: response.data.data.total,
            total_pages: response.data.data.last_page,
          }
          commit("setGroupChannelsPagination", {status: 'deleted', groupChannelsPagination: pagination});
          commit("setGroupChannels", {status: 'deleted', groupChannels: response.data.data.data});
          resolve();
        })
        .catch((response) => {
          commit('system/setErrorMessage', response.response.data, {root: true})
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "findDeletedGroupChannels");
        });
    });
  },

  findGroupChannelById({ commit, getters }, payload) {
    commit("startLoadingFlag", "findGroupChannelById");
    return new Promise((resolve, reject) => {
      axiosOmni.get(`/group-channel/${payload.id}`, { params: getters.getGroupChannelParams })
        .then((response) => {
          commit("setGroupChannel", response.data.data);
          resolve();
        })
        .catch((error) => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        }).finally(() => {
          commit("stopLoadingFlag", "findGroupChannelById");
      });
    });
  },

  findGroupChannelInstanceById({ commit }, payload) {
    commit("startLoadingFlag", "findGroupChannelInstanceById");
    return new Promise((resolve, reject) => {
      axiosOmni.get(`/group-channel/dashboard/instance/${payload.id}`)
        .then((response) => {
          commit("setGroupChannelInstance", response.data);
          resolve();
        })
        .catch((error) => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        }).finally(() => {
          commit("stopLoadingFlag", "findGroupChannelInstanceById");
      });
    });
  },

  createGroupChannel({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosOmni
        .post("/group-channel", payload.formData)
        .then((response) => {
          const groupChannel = response.data.data
          commit("system/setSuccessMessage", "success_message.create_group_channel", { root: true})
          commit("setGroupChannel", groupChannel);
          resolve(groupChannel.id);
        })
        .catch((response) => {
          commit('system/setErrorMessage', response?.response?.data, {root: true})
          commit('stopLoading');
          reject();
        })
        .finally(() => {
        });
    });
  },

  editGroupChannel({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosOmni
        .put(`/group-channel/${payload.id}`, payload.fields)
        .then(() => {
          commit("system/setSuccessMessage", "success_message.update_group_channel", { root: true})
          resolve();
        })
        .catch((response) => {
          commit('system/setErrorMessage', response.response.data, {root: true})
          commit('stopLoading');
          reject();
        })
        .finally(() => {
        });
    });
  },

  reactivateGroupChannel({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosOmni
        .post(`/group-channel/${payload.id}/active`, payload.fields)
        .then(() => {
          commit("system/setSuccessMessage", "success_message.reactivate_group_channel", { root: true})
          resolve();
        })
        .catch((response) => {
          commit('system/setErrorMessage', response.response.data, {root: true})
          commit('stopLoading');
          reject();
        })
        .finally(() => {
        });
    });
  },

  deleteGroupChannel({ commit }, payload) {
    commit("startLoadingFlag", "deleteGroupChannel")
    return new Promise((resolve, reject) => {
      axiosOmni
        .delete(`/group-channel/${payload.id}`,)
        .then(() => {
          commit("system/setSuccessMessage", "success_message.delete_group_channel", { root: true})
          resolve();
        })
        .catch((response) => {
          commit('system/setErrorMessage', response.response.data, {root: true})
          commit('stopLoading');
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "deleteGroupChannel")
        });
    });
  },

  createStrategySettings({ commit }, payload) {
    // commit("startLoading");
    return new Promise((resolve, reject) => {
      axiosOmni
        .post("/whatsapp/group-channel", payload.formData)
        .then(() => {
          commit("system/setSuccessMessage", "success_message.create_group_channel", { root: true})
          resolve();
        })
        .catch((response) => {
          commit('system/setErrorMessage', response.response.data, {root: true})
          commit('stopLoading');
          reject();
        })
        .finally(() => {
          // commit("stopLoading");
        });
    });
  },
};
