<template>
  <div
    class="text-center main-loading"
    style="height: 100vh; position: relative"
  >
    <div class="row">
      <div class="col-lg-12 vertical-center">
        <div class="spinner-border text-muted vertical-cente">
          <span class="visually-hidden" />
        </div>
        <br>
        <span class="text-muted"> {{ $t('loading_capitalize') }}... </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.main-loading {
  .vertical-center {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    -webkit-transform: translate(0%, -50%);
  }
}
</style>