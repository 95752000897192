
import axiosOmni from "@/plugins/axios-omni.js"

export default {

  /**
   * Retrieve all quick messages from
   * the company
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {Promise}
   */
  loadWhatsappQuickMessageList({ commit }, payload) {
    commit("startLoadingContext", 'quick_message_list');
    return new Promise((resolve, reject) => {
      axiosOmni
        .get(`/group-channel/${payload.id}/quick-messages`, { params: payload.params })
        .then(response => {
          commit("setQuickMessageList", response.data.data);
          commit("setPagination", response.data.meta.pagination);
          resolve();
        })
        .catch((error) => {
          commit('system/setErrorMessage', error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingContext", 'quick_message_list');
        });
    });
  },

  /**
   * Creates a quick message
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {Promise}
   */
  createWhatsappQuickMessage({ commit }, payload) {
    commit("startLoadingContext", 'create_quick_message');

    return new Promise((resolve, reject) => {
      axiosOmni.post( `/group-channel/quick-message`, payload.fields)
      .then(() => {
        commit('system/setSuccessMessage', "success_message.create_quick_message", { root: true });
        resolve();
      })
      .catch((error) => {
        commit("setErrors",  error.response.data.errors);
        reject();
      })
      .finally(() => commit("stopLoadingContext", 'create_quick_message'));
    });
  },

  /**
   * Updates a quick message
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {Promise}
   */
    updateWhatsappQuickMessage({ commit }, payload) {
      commit("startLoadingContext", 'update_quick_message');

      return new Promise((resolve, reject) => {
        axiosOmni.put( `/group-channel/quick-message/${payload.id}`, payload.fields)
        .then(() => {
          commit('system/setSuccessMessage', "success_message.update_quick_message", { root: true });
          resolve();
        })
        .catch((error) => {
          commit("setErrors",  error.response.data.errors);
          reject();
        })
        .finally(() => commit("stopLoadingContext", 'update_quick_message'));
      });
    },

  /**
   * Deletes a quick message
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {Promise}
   */
  deleteQuickMessage({ commit }, payload) {
    commit("startLoadingContext", 'delete_quick_message');

    return new Promise((resolve, reject) => {
      axiosOmni.delete( `/group-channel/quick-message/${payload.id}`)
      .then(() => {
        commit('system/setSuccessMessage', "success_message.delete_quick_message", { root: true });
        resolve();
      })
      .catch((error) => {
        commit('system/setErrorMessage', error.response.data, { root: true });
        reject();
      })
      .finally(() => commit("stopLoadingContext", 'delete_quick_message'));
    });
  },
};

