import axios from "axios";

export default {
  /**
   * Qualification Lists
   */
  list({ commit }, payload) {
    commit("setErrors", {});
    commit("startLoading");
    commit("setList", []);

    const url = (payload && payload.page ? `page=${payload.page}&` : "") +
      (payload && payload.search ? `search=${payload.search}&` : "") +
      (payload && payload.type ? `type=${payload.type}` : "");

    axios.get("/qualification_lists?" + url)
      .then((response) => {
        commit("setPagination", response.data.meta.pagination);
        commit("setList", response.data.data);
      })
      .catch((error) => {
        commit("setAlert", { message: error.response.data.detail, type: "danger" });
        error.response.status == 422 && error.response.data.errors && commit("setErrors", error.response.data.errors);
      })
      .finally(() => {
        commit("stopLoading");
      });
  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  create({ commit }, payload) {
    commit("startLoading");
    commit("setErrors", {});
    commit("setRegister", null);

    return new Promise((resolve, reject) => {
      axios.post("/qualification_lists", payload.fields)
        .then((response) => {
          commit("setRegister", response.data.data);
          resolve({ message: "Qualificação criada com sucesso!" });
        })
        .catch((error) => {
          error.response.status == 422 && error.response.data.errors && commit("setErrors", error.response.data.errors);
          reject({ message: error.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   */
  findById({ commit }, payload) {
    commit("startLoading");
    commit("setErrors", {});
    commit("setRegister", null);

    return new Promise((resolve, reject) => {
      axios.get("/qualification_list/" + payload.id, { params: { include: "qualifications" } })
        .then((response) => {
          commit("setRegister", response.data.data);
          resolve();
        })
        .catch((response) => {
          commit("setRegister", null);
          commit("setAlert", { message: response.response.data.detail, type: "danger" });
          response.response.status == 422 && response.response.data.errors && commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading");
        });
    });

  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  update({ commit }, payload) {
    commit("startLoading");
    commit("setErrors", {});

    return new Promise((resolve, reject) => {
      axios.put("/qualification_lists/" + payload.id, payload.fields).then((response) => {
        commit("setRegister", response.data.data)
        resolve({ message: "Qualificação atualizada com sucesso!" });
      })
        .catch((response) => {
          reject({ message: response.response.data.detail });
          response.response.status == 422 && response.response.data.errors && commit("setErrors", response.response.data.errors);
        })
        .finally(() => {
          commit("stopLoading");
        });
    });

  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  remove({ commit }, payload) {
    commit("startLoading");

    return new Promise((resolve, reject) => {
      axios.delete("/qualification_lists/" + payload.id).then(() => {
        commit("setRegister", null);
        resolve({ message: "Registro removido com sucesso!" });
      })
        .catch((response) => {
          let message = response.response.data.detail + " " + response.response.data.errors.name;
          reject({ message: message });
        })
        .finally(() => {
          commit("stopLoading");
        });

    });
  },

  /**
   *  Qualification
   */

  findQualificationById({ commit }, payload) {
    commit("startLoading");
    !payload.isInfinite && commit("setQualificationRegister", []);

    return new Promise((resolve, reject) => {
      axios.get("/qualification-list/" + payload.id + "/qualifications", { params: payload.fields })
        .then((response) => {
          payload.isInfinite
            ? commit("setInfiniteQualification", response.data.data)
            : commit("setQualificationRegister", response.data.data);
          commit("setQualificationPagination", response.data.meta.pagination);
          resolve();
        })
        .catch((response) => {
          commit("setQualificationRegister", []);
          commit("setAlert", { message: response.response.data.detail, type: "danger" });
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  removeQualification({ commit }, payload) {
    commit("startLoading");

    return new Promise((resolve, reject) => {
      axios.delete("/qualification_lists/" + payload.list_id + "/qualifications/" + payload.id).then(() => {
        resolve();
      })
        .catch((response) => {
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading")
        });
    });

  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  updateQualification({ commit }, payload) {
    commit("startLoading");
    commit("setErrors", {});

    return new Promise((resolve, reject) => {

      axios.put("/qualification_lists/" + payload.list_id + "/qualifications/" + payload.id, payload.fields).then(() => {
        resolve();
      })
        .catch((response) => {
          response.response.status == 422 && response.response.data.errors && commit("setErrors", response.response.data.errors)
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading")
        });

    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  addQualification({ commit }, payload) {
    commit("clearErrorsQualification", {});
    commit("startLoading");

    return new Promise((resolve, reject) => {
      axios.post("/qualification_lists/" + payload.list_id + "/qualifications", payload.fields).then(() => {
        resolve();
      })
        .catch((response) => {
          commit("system/setErrorMessage", response.response.data.detail, { root: true })
          response.response.status == 422 && response.response.data.errors && commit("setErrorsQualification", response.response.data.errors)
          reject();
        })
        .finally(() => {
          commit("stopLoading")
        });
    })
  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  orderQualifications({ commit }, payload) {
    commit("startLoading");
    commit("setErrors", {});

    return new Promise((resolve, reject) => {
      axios.post("/qualification_lists/" + payload.list_id + "/order", payload.list).then(() => {
        resolve();
      })
        .catch((response) => {
          response.response.status == 422 && response.response.data.errors && commit("setErrors", response.response.data.errors)
          reject({ message: response.response.data.detail });

        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },


  /**
   *
   * @param {*} param0
   * @param {*} payload
   */
  loadDataSelect({ commit }) {
    commit("startLoading")
    commit("setDataSelect", []);
    return new Promise((resolve, reject) => {
      axios.get("/filters", { params: { include: "qualifications" } })
        .then((response) => {
          commit("setDataSelect", response.data.data.qualifications);
          resolve();
        }).catch((response) => {
        reject({ message: response.response.data.detail });
      }).finally(() => {
        commit("stopLoading")
      });
    });
  },


  /**
   *
   * @param {*} param0
   * @param {*} payload
   */
  loadQualificationListDataSelect({ commit }) {
    commit("startLoadingFlag", "loadQualificationListDataSelect");
    commit("setQualificationListDataSelect", []);
    return new Promise((resolve, reject) => {
      axios.get("/filters", { params: { include: ["qualification_lists", "system_qualifications", "qualifications"] } })
        .then((response) => {
          commit("setQualificationListDataSelect", response.data.data);
          commit("setEachQualificationListDataSelect", response.data.data.qualification_lists)
          resolve();
        }).catch((response) => {
        reject({ message: response.response.data.detail });
      }).finally(() => {
        commit("stopLoadingFlag", "loadQualificationListDataSelect")
      });
    });
  },

  createCRMQualification({ commit }, payload) {
    commit("clearErrors")
    commit("startLoadingFlag", "crmQualification");

    return new Promise((resolve, reject) => {
      axios.post(`/qualification_lists/${payload.id}/qualifications`, payload.data)
        .then(() => {
          resolve();
        }).catch((error) => {
        commit("system/setErrorMessage", error.response.data, { root: true })
        reject();
      }).finally(() => {
        commit("stopLoadingFlag", "crmQualification");
      });
    });
  },

  updateCRMQualification({ commit }, payload) {
    commit("clearErrors")
    commit("startLoadingFlag", "crmQualification");

    return new Promise((resolve, reject) => {
      axios.put(`/qualification_lists/${payload.list_id}/qualifications/${payload.id}`, payload.data)
        .then(() => {
          commit("system/setSuccessMessage", payload.message, { root: true })
          resolve();
        }).catch((error) => {
        commit("system/setErrorMessage", error.response.data, { root: true })
        reject();
      }).finally(() => {
        commit("stopLoadingFlag", "crmQualification");
      });
    });
  },
}
