<template>
  <canvas
    ref="canvas"
    class="chart-canvas w-100"
    style="display: block; box-sizing: border-box; height: 300px"
  />
</template>

<script>
import moment from "moment";
import { Chart } from "chart.js";
import { mapActions, mapGetters } from "vuex";

export default {
  /**
   *
   */
  data() {
    return {
      startDate: null,
      endDate: null,
      timeInterval: null,
      labels: [],
      data: {
        failed: [],
        abandoned: [],
        not_answered: [],
        answered: [],
        abandoned_due_amd: [],
        not_answered_due_progress_amd: []
      },
      statuses: [
        {
          label: this.$t('connected'),
          color: "#5cb85c",
        },
        {
          label: this.$t('abandoned_label'),
          color: "#66221b",
        },
        {
          label: this.$t('post_call_ivr'),
          color: "#eb5545",
        },
        {
          label: this.$t('pre_call_ivr'),
          color: "#eb5545",
        },
        {
          label: this.$t('not_answered'),
          color: "#585858",
        },
        {
          label: this.$t('fail'),
          color: "#a8a8a8",
        },
      ],
      options: {
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
            suggestedMin: 0,
            suggestedMax: 10,
            stacked: true,
            ticks: {
              color: "#8297b4",
              stepSize: 2,
              maxTicksLimit: 5,
              callback: function (value) {
                return value >= 1000 ? Math.round(value / 1000) + "k" : value;
              },
            },
          },
        },
      },
    };
  },

  /**
   *
   */
  mounted() {
    let datasets = [];
    for (let item of this.statuses) {
      datasets.push({
        label: item.label,
        data: [],
        backgroundColor: item.color,
        borderColor: item.color,
        radius: 0,
        fill: true,
        borderWidth: 1,
      });
    }
    new Chart(this.$refs.canvas, {
      type: "line",
      options: this.options,
      data: {
        labels: [],
        datasets: datasets,
      },
    });
    this.$refs.canvas.parentNode.style.height = '190px';
    this.update();
    this.timeInterval = setInterval(() => {
      this.update();
    }, 600000);
  },

  /**
   *
   */
  beforeUnmount() {
    clearInterval(this.timeInterval);
  },

  /**
   *
   */
  computed: {
    /**
     * Getters
     */
    ...mapGetters("manager/calls-report", {
      callsStatusCountPerTime: "getCallsStatusCountPerTime",
    }),
  },

  /**
   *
   */
  watch: {
    /**
     *
     */
    callsStatusCountPerTime: {
      /**
       *
       */
      handler(data) {
        if (data[this.campaign.id]) {
          this.prepareData(data[this.campaign.id]);
          this.updateChart();
        }
      },
      deep: true,
    },
  },

  /**
   *
   */
  props: {
    campaign: {
      required: true,
      type: Object,
    },
  },

  /**
   *
   */
  methods: {
    /**
     *
     */
    ...mapActions("manager/calls-report", ["findCallsStatusCountPerTime"]),
    /**
     *
     */
    getChartIntance() {
      return Chart.getChart(this.$refs.canvas);
    },

    /**
     *
     */
    update() {
      this.endDate = moment();
      this.startDate = moment().subtract(3, "hours");
      this.prepareLabels();
      this.findCallsStatusCountPerTime({
        startDate: this.startDate.format("YYYY-MM-DD HH:mm:00"),
        endDate: this.endDate.format("YYYY-MM-DD HH:mm:00"),
        interval: 1,
        campaign_id: this.campaign.id,
      });
    },

    /**
     *
     */
    prepareData(data) {
      this.clearData();
      let temp = {};
      for (let item of data)
        temp[moment(item.date).format("YYYY-MM-DD HH:mm:00")] = item;
      for (let label of this.labels) {
        if (temp[label]) {
          for (let key in this.data) {
            this.data[key].push(temp[label][key]);
          }
        } else {
          for (let key in this.data) {
            this.data[key].push(0);
          }
        }
      }
    },

    /**
     *
     */
    updateChart() {
      let chart = this.getChartIntance();
      chart.data.labels = this.labels;
      chart.data.datasets[0].data = this.data.answered;
      chart.data.datasets[1].data = this.data.abandoned;
      chart.data.datasets[2].data = this.data.abandoned_due_amd;
      chart.data.datasets[3].data = this.data.not_answered_due_progress_amd;
      chart.data.datasets[4].data = this.data.not_answered;
      chart.data.datasets[5].data = this.data.failed;
      chart.update();
    },

    /**
     *
     */
    clearData() {
      this.data.failed = [];
      this.data.abandoned = [];
      this.data.not_answered = [];
      this.data.answered = [];
      this.data.abandoned_due_amd = [];
      this.data.not_answered_due_progress_amd = []
    },

    /**
     *
     */
    prepareLabels() {
      this.labels = [];
      let date = this.startDate.clone();
      let endDate = this.endDate;
      for (date; date < endDate; date = date.add(1, "minute"))
        this.labels.push(date.format("YYYY-MM-DD HH:mm:00"));
    },
  },
};
</script>
