<template>
  <form
    class="row"
    @submit.prevent="submit"
  >
    <div class="col-xs-12">
      <div
        class="form-group"
        style="margin-bottom: 16px;"
      >
        <label class="form-label h5">{{ $t('qualifications_omni') }}</label>
        <select
          class="form-select"
          data-test="qualifications-select"
          :disabled="loadingFlags.findQualifications"
          v-model="fields.qualification"
        >
          <option
            :value="undefined"
          >
            {{ $t('select') }}
          </option>
          <option
            :value="qualification.id"
            v-for="(qualification, index) of qualifications"
            :key="index"
          >
            {{ qualification.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-xs-12">
      <div
        class="form-group"
        style="margin-bottom: 16px;"
      >
        <label class="form-label h5">{{ $t('internal_note') }}</label>
        <textarea
          v-model="fields.qualification_note"
          class="form-control fw-light h6 m-0 px-3"
          data-test="internalNote-textarea"
          :placeholder="$t('write_here')"
          rows="6"
          style="resize: none;"
        />
      </div>
    </div>
    <div class="col-xs-12">
      <div class="form-group mb-0">
        <button
          style="height: 34px;"
          type="submit"
          :disabled="loadingFlagsChat.finishChat || loadingFlags.findQualifications"
          class="btn w-100 btn-primary shadown-none tx-text-light bg-blue-300 d-flex justify-content-center align-items-center p-0"
          data-test="submitFinishChat-button"
        >
          <span
            class="fe fe-check-circle pe-2 h3 m-0"
          />
          <span>{{ $t('finalize') }}</span>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "DoneChatForm",

  data() {
    return {
      fields: {
        qualification: undefined,
        qualification_note: ''
      }
    }
  },

  methods: {
    ...mapActions("whatsapp/chat", ["finishChat", "fetchFinished", "resetFinishedCurrentPage"]),
    ...mapMutations("whatsapp/chat", ["removeChatById"]),
    ...mapMutations("whatsapp/message", ["clearChatMessages"]),
    ...mapMutations("system", ["setSuccessMessage", "setCustomizedTitleMessage"]),
    ...mapMutations("auth", ["trackEvents"]),

    submit() {
      const chatId = this.$route.params.id
      this.finishChat({
        id: chatId,
        fields: this.fields,
      }).then(() => {
        this.trackEvents("[Agent] Finish Chat");
        this.clearChatMessages(chatId)
        this.removeChatById({id: chatId});
        this.resetFinishedCurrentPage();
        this.fetchFinished();
        if (this.fields.qualification === undefined) {
          this.setSuccessMessage(this.$t('no_qualification'));
          this.setCustomizedTitleMessage(" ");
        } else {
          this.setSuccessMessage(this.$t('success_finished_chat'));
          this.setCustomizedTitleMessage(this.$t('success'));
        }
        this.$router.push("/whatsapp")
      });
    },
  },

  computed: {
    ...mapGetters("whatsapp/instance", {
      loadingFlags: "getLoadingFlags",
      qualifications: "getQualifications"
    }),

    ...mapGetters("whatsapp/chat", {
      loadingFlagsChat: "getLoadingFlags",
    }),

  },
}
</script>

<style scoped lang="scss">

</style>