<template>
  <div
    ref="talksTooltip"
    id="tooltip"
  >
    <div class="card">
      <div class="d-flex justify-content-between">
        <h5 class="card-title m-0">
          {{ $t('chats') }}
        </h5>
        <h4 class="m-0 text-medium fw-normal">
          Total <strong class="fs-5 fw-normal">{{ metrics.total }}</strong>
        </h4>
      </div>
      <div class="card-body p-0">
        <ul class="list-group list-group-flush">
          <li
            v-for="(item, i) in tooltipData"
            :key="i"
            class="list-group-item d-flex align-items-center"
          >
            <div class="col-auto avatar avatar-xs me-2">
              <div
                class="avatar-title rounded-circle text-small"
                :class="item.styleClass"
              >
                <span :class="item.iconClass" />
              </div>
            </div>
            <div class="col d-flex justify-content-between">
              <div>{{ item.title }}</div>
              <div>{{ item.value }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div
    class="progress cursor-pointer"
    ref="bar"
    style="width: 143px; height: 6px"
    @mouseenter="showTooltip"
    @mouseleave="hideTooltip"
  >
    <div
      v-for="(item, i) in barData"
      :key="i"
      class="progress-bar"
      :style="'width: ' + item.percentage + '%;background-color:' + item.color"
    />
  </div>
</template>

<script>
import {createPopper} from "@popperjs/core";

export default {
  data() {
    return {
      talksTooltip: null,
      popperInstance: null,
    };
  },

  props:{
    metrics:{
      type: Object,
      default: ()=>{}
    }
  },

  computed: {
    barData() {
      return [
        {
          color: '#5CB868',
          percentage: (this.metrics.open / this.metrics.total) * 50,
          value: this.metrics.open,
        },
        {
          color: '#E67C0B',
          percentage: (this.metrics.snooze / this.metrics.total) * 50,
          value: this.metrics.snooze,
        },
      ]
    },

    tooltipData() {
      return [
        {
          styleClass: "open",
          iconClass: "fa-kit fa-whatsapp",
          title: this.$t('in_chat'),
          value: this.metrics.open
        },
        {
          styleClass: "snooze",
          iconClass: "fal fa-snooze",
          title: this.$t('waiting_capitalize'),
          value: this.metrics.snooze
        },
      ]
    }
  },

  mounted() {
    this.createConnectionsTooltip()
  },

  methods: {
    createConnectionsTooltip(){
      const bar = this.$refs.bar;
      this.talksTooltip = this.$refs.talksTooltip;

      this.popperInstance = createPopper(bar, this.talksTooltip, {
        placement: "top"
      });
    },

    showTooltip() {
      this.talksTooltip.setAttribute("data-show", "");
      this.popperInstance.update()
    },

    hideTooltip() {
      this.talksTooltip.removeAttribute("data-show");
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 1rem 1rem 0 1rem;
}

.card-header{
  border-bottom: none;
}

#tooltip {
  display: none;
  width: 240px;
}

#tooltip[data-show] {
  display: block;
}

.open {
  background-color: rgba(92, 184, 104, 0.1);
  color: #015D0D;
}
.snooze {
  background-color: rgba(230, 124, 11, 0.1);
  color: $color-orange-500;
}
</style>
