<template>
  <div class="">
    <div class="d-flex justify-content-between">
      <label
        class="form-label"
        for="basic-url"
      >{{ $t('attachments') }}</label>
      <button
        v-if="attachmentList.length === 0"
        class="btn d-flex justify-content-center pe-0"
        @click="dropAllList()"
      >
        <i class="fa-solid fa-xmark" />
      </button>
    </div>
    <div class="d-flex gap-4">
      <div class="d-flex flex-row align-items-center justify-content-start flex-wrap gap-3 flex-grow-1">
        <button
          v-for="(item, index) in attachmentList"
          :key="index"
          class="col-3 btn btn-white d-flex align-items-center gap-3 p-0"
        >
          <span class="ps-3 w-100 overflow-ellipsis">
            {{ item.name }}
          </span>
          <span
            class="btn btn-light py-2"
            @click="dropAttachmentList(index, item)"
          >
            <i class="fa-solid fa-xmark px-1" />
          </span>
        </button>
        <button
          v-if="false"
          class="col-3 btn btn-light"
          @click="triggerFileInput()"
        >
          <i class="fa-regular fa-paperclip" />
          <span class="ms-2">{{ $t('attach') }}</span>
        </button>
      </div>
      <input
        ref="fileInput"
        type="file"
        style="display: none"
        @change="handleFileUpload"
      >
    </div>
  </div>
</template>
<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "AttachmentFieldList",
  emits: ['close', 'update:modelValue', 'delete'],
  inject: ['isActivityFormSubmitted'],
  props: {
    modelValue: { type: Array, required: true }
  },

  data() {
    return {
      attachmentList: [],
      file: null,
    }
  },

  mounted() {
    if (this.modelValue.length) {
      this.attachmentList = this.modelValue;
    }
  },

  methods: {
    incrementNoteList(file) {
      this.attachmentList.push({
        content: file,
        type: this.getAttachemtsType(file.type),
        name: file.name
      })
      this.$emit('update:modelValue', this.attachmentList)
    },

    getAttachemtsType (type) {
      if (type.startsWith('image')){
        return 'image'
      } else if (type.startsWith('application')) {
            return 'document'
      } else if (type.startsWith('video')) {
          return 'video'
      } else if (type.startsWith('audio')) {
          return 'audio'
      } else return 'file'
    },

    dropAttachmentList(index, item) {
      if (item.id) {
        this.$emit('delete', item.id)
      }
      this.attachmentList.splice(index, 1)
    },
    dropAllList () {
      if(this.attachmentList[0] && this.attachmentList[0].id) {
        this.$emit('delete', this.attachmentList[0].id);
      }
      this.attachmentList = []
      this.file = null
      this.$emit('close')
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.file = file;

        const reader = new FileReader();
        reader.onload = () => {
        };
        reader.readAsDataURL(file);
        this.incrementNoteList(file)
      }
    }
  },

  watch: {
    "isActivityFormSubmitted.value"(value) {
      if (value) {
        this.dropAllList();
      }
    }
  }
})
</script>
<style scoped lang="scss">
</style>
