<template>
  <ul
    class="navbar-nav"
    v-show="expanded || !expand"
  >
    <li
      v-for="(item, i) in menu.bottom"
      :key="i"
      class="nav-item"
      @click="$emit('toggle', i)"
    >
      <router-link
        v-if="item.title === 'reports_capitalize'"
        class="menu-bottom-router"
        :class="{ 'tx-blue-300': item.active_name.includes(getActiveRouteName) }"
        to="/manager/reports"
      >
        <i :class="item.icon" />
        <span v-if="!(expand && !expanded)">
          {{ $t(item.title) }}
        </span>
      </router-link>
      <div
        class="dropup"
        v-if="user && !item.hide && item.roles.includes(user.role.name) && item.title === 'settings_omni'"
      >
        <a
          :ref="`dropdownMenu${i}`"
          :class="{ 'tx-blue-300': item.active_name.includes(getActiveRouteName) }"
          aria-expanded="false"
          class="nav-link dropdown-toggle"
          data-bs-toggle="dropdown"
          role="button"
        >
          <i :class="item.icon" />
          <span
            v-if="!(expand && !expanded)"
            class="w-100"
          >
            {{ $t(item.title) }}
          </span>
        </a>
        <ul
          :style="{
            bottom: 148 - 40 * i + 'px'
          }"
          class="dropdown-menu position-fixed"
          style="left: 240px"
        >
          <li
            v-for="(subitem, j) in item.children"
            :key="j"
          >
            <router-link
              v-if="user && subitem.roles.includes(user.role.name)"
              :class="{
                'tx-text-200': subitem.active_name === getActiveRouteName
              }"
              :to="subitem.path"
              class="dropdown-item"
              @click="$emit('handleIntercom',subitem.title, i)"
            >
              {{ $tc(subitem.title, 2) }}
            </router-link>
          </li>
        </ul>
      </div>
    </li>
    <li>
      <account-balance
        v-if="user && user.company"
        @click="$emit('toggle', 'balance')"
      />
    </li>
    <li>
      <user-profile
        v-if="!isAdmin"
        v-show="!expand || expanded"
        @click="$emit('toggle', 'profile')"
      />
    </li>
  </ul>
</template>

<script>
import AccountBalance from "@dialer/manager/components/account-balance.vue";
import UserProfile from "@dialer/manager/components/user-profile/user-profile.vue";
import {mapGetters} from "vuex";

export default {
  name: "ProfileActionButtons",
  components: {
    UserProfile,
    AccountBalance
  },

  props: {
    expand: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: null
    },
    menu: {
      type: Object,
      default: null
    },
  },

  emits: [
    'toggle',
    'handleIntercom'
  ],

  computed: {
    ...mapGetters("auth", {
      isManager: "isManager",
      isSupervisor: "isSupervisor",
      user: "getUser",
      inImpersonate: "inImpersonate",
      isAdmin: "isAdmin",
      loadingFlags: "getLoadingFlags",
      showPaymentModal: "showPaymentModal",
      isBrazil: "isBrazil",
    }),

    getActiveRouteName() {
      return this.$route.matched && this.$route.matched[1] ? this.$route.matched[1].name : "";
    },
  },
}
</script>

<style scoped lang="scss">
.dropdown-manager-sidebar {
  position: fixed !important;
}

.sidebar.navbar {

  &.expand {
    cursor: pointer;
    border-width: 0 1px 0 0 !important;
    border-top-width: 0px !important;
    border-right-width: 1px !important;
    border-bottom-width: 0px !important;
    border-left-width: 0px !important;

    &.navbar-vertical .navbar-brand {
      text-align: start;
      padding: 0.5rem !important;
      margin-bottom: 1.5rem !important;

      .navbar-brand-img {
        min-height: 35px !important;
      }
    }

    & ~ .main-content {
      padding-left: 66px;
    }
  }

  &.navbar-vertical.navbar-expand-md {
    overflow: hidden !important;
    transition: max-width 0.1s !important;
  }

  &.navbar-vertical.navbar-expand-md:hover {
    overflow-y: auto !important;
  }

  overflow: hidden !important;

  &.navbar-vertical .navbar-brand {
    text-align: start;
    padding: 0.5rem !important;
    margin-bottom: 1.5rem !important;

    .navbar-brand-img {
      height: 35px !important;
    }
  }
}

.overflow-ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.disable-text-select {
  user-select: none;
}

.navbar-nav .nav-link > .far,
.fal {
  display: inline-block;
  width: 1.5rem;
  line-height: 1.5rem;
  font-size: 1.125rem;
  text-align: center;
  margin-right: 0.5rem;
}

.nav-badge-blue {
  height: 20px;
  border-radius: 6px;
  padding: 0 6px;
  background-color: #e1e9f4;
  color: #3057f2;
  line-height: 20px;
}

.nav-badge-gray {
  height: 20px;
  border-radius: 6px;
  padding: 0 6px;
  background-color: #f4f4f4;
  color: #677c92;
  line-height: 20px;
}

.nav-item {
  .dropup {
    .nav-link {
      &.show {
        color: $color-blue-300;
      }

      &:after {
        display: block;
        content: "\e92e";
        font-family: "Feather";
        margin-left: auto;
        transition: transform 0.2s;
        transform: rotate(-90deg);
      }

      // Expanded
      &[aria-expanded="true"] {
        &:after {
          transform: rotate(-270deg);
        }
      }
    }
  }
}

.dropdown-wallet {
  padding: 0.5rem 1rem !important;

  &:after {
    display: none !important;
  }

  .item-wallet {
    background-color: $color-gray-blue-300;
    border-radius: 10px;
    padding: 0.5rem 0.75rem;

    .far,
    .fal {
      width: 1rem;
      line-height: 1rem;
    }
  }
}

.menu-bottom-router {
  padding: 1rem 1.5rem;
  color: #677C92;

  &:hover {
    color: #373753;
  }
}
</style>