<template>
  <div class="col-4">
    <div class="card">
      <div class="text-center py-3">
        <span>Usuário <strong>Nível {{ level }}</strong></span>
      </div>
      <hr class="navbar-divider mx-4 my-0">

      <!-- Repeated checklist item using v-for -->
      <div
        v-for="(item, index) in checklistPermissionItems"
        :key="index"
        class="py-3 mx-4"
        :class="{ 'border-bottom': index < 2 }"
      >
        <div class="d-flex">
          <i
            class="fe fe-check fw-bold fs-2 me-3"
            :class="checklistPermissionItemClass(index)"
          />
          <span class="mt-2">
            {{ item }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    level: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    checklistPermissionItems() {
      return [
        "Visualizar Empresas",
        "Editar Empresas",
        "Configurações",
      ];
    },
  },

  methods: {
    checklistPermissionItemClass(index) {
      return {
        "text-primary": this.level > index,
        "text-muted": this.level <= index,
      }
    },
  },
}
</script>
