<template>
  <button
    ref="recordButton"
    class="btn btn-white p-2"
    @click="toggleRecording"
    v-if="[AgentStatus.ON_CALL].includes(status) && user.company.record_audio_agent"
  >
    <div
      class="record mx-auto"
      :class="{
        'active': isRecording
      }"
    />
  </button>
  <span
    class="text-medium ms-1"
    :class="{
      'text-light': CallStatus.INTERNAL_CALL_ANSWERED === this.callStatus ||
        CallStatus.CONNECTED === this.callStatus && this.callPABX.call_mode !== 'receptive'
    }"
    v-if="isRecording"
  >
    {{ $t('recording') }} ({{ formatSecondsToMMSS(timeRecording) }})
  </span>
</template>

<script>
import { mapGetters } from 'vuex';
import { AgentStatus, CallStatus, formatSecondsToMMSS } from "@/utils.js";
export default {
  data() {
    return {
      AgentStatus,
      CallStatus,
      isRecording: false,
      timeRecording: 0,
      timer: null
    }
  },

  computed: {
    ...mapGetters("system", {
      status: "getStatus",
      mediaRecorder: "getMediaRecorder",
    }),

    ...mapGetters("auth", {
      user: "getUser"
    }),

    ...mapGetters("agent/ring_group", {
      callStatus: "getCallStatus",
      callPABX: "getCallPABX",
    })
  },

  methods: {
    formatSecondsToMMSS,
    toggleRecording() {
      this.isRecording ? this.stopRecord() : this.startRecord();
    },

    startRecord() {
      this.isRecording = true;
      this.mediaRecorder.start();
      this.timer = setInterval(() => {
        this.timeRecording++;
      }, 1000);
    },

    stopRecord() {
      this.isRecording = false;
      this.mediaRecorder.stop();
      this.timeRecording = 0;
      clearInterval(this.timer);
    },
  },

  watch: {
    status(data) {
      if(this.isRecording && data !== AgentStatus.ON_CALL) {
        this.stopRecord();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  height: 30px;
  width: 30px;
  &:hover {
    background-color: rgba(55, 55, 83, 0.5);
    background-blend-mode: multiply;

    .record {
      background-color: var(--color-offline);
    }
  }

  .record {
    width: 14px;
    height: 14px;
    background-color: var(--color-red-300);
    border-radius: 50%;
    transition: all 0.25s;

    &.active {
      border-radius: 0;
      width: 14px;
      height: 14px;
    }
  }
}
</style>
