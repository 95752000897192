<template>
  <table-before-search v-if="!searched" />
  <chart-qualifications v-else />
</template>

<script>
import TableBeforeSearch from "@dialer/manager/pages/reports/components/table-before-search.vue";
import ChartQualifications from "./chart-qualifications.vue";

export default {
  name: "TabQualifications",
  components: {ChartQualifications, TableBeforeSearch},
  props: {
    searched: {
      type: Boolean,
      default: false
    }
  }
}
</script>