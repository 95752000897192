export default {
    startLoadingFlag: (state, flag) => state.loadingFlags[flag] = true,
    stopLoadingFlag: (state, flag) => state.loadingFlags[flag] = false,
    setErrorMessage: (state, errorMesage) => state.errorMessage = errorMesage,
    setErrors: (state, errors) => state.errors = errors,
    clearErrors: (state) => {
        state.errors = {}
        state.errorMessage = ""
    },
    setErrorsInterval: (state, errors) => state.errorsInterval = errors,
    clearErrorsInterval: (state) => {
        state.errorsInterval = {}
    },
    clearList: (state) => state.list = [],
    setPagination: (state, pagination) => state.pagination = pagination,
    setWorkBreakGroups: (state, workBreakGroups) => state.workBreakGroups = workBreakGroups,
    setWorkBreakGroup: (state, workBreakGroup) => state.workBreakGroup = workBreakGroup,
    setIntervals: (state, intervals) => state.intervals = intervals,
    setIntervalsPagination: (state, intervalsPagination) => state.intervalsPagination = intervalsPagination,
}
