<script>
export default {
  name: "IsRamalBuzyComponent",
  props: {
    isRamalBuzy: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template>
  <div
    class="d-flex flex-column align-content-center justify-content-center px-4 text-center tooltip-only-have-whatsapp"
  >
    <img
      alt="Ramal ocupado"
      :src="require(`@/assets/img/aloisio/aloisioThinking.svg`)"
      class="align-self-center my-4"
    >
    <h5>
      {{ $t('already_in_call_description_p1') }}
      {{ isRamalBuzy ? $t('already_in_call_description_p2') : $t('already_in_call_description_p3') }}
    </h5>
    <p class="fs-6">
      {{ isRamalBuzy ? $t('disconnect_call_qualify') : $t('contact_manager') }}
    </p>
  </div>
</template>

<style scoped lang="scss">
.button-top-bar {
  width: 32px;

  .fe-phone-call {
    display: none;
  }

  &:not(.no-hover):hover .fe-phone {
    display: none;
  }

  &:not(.no-hover):hover .fe-phone-call {
    display: inline-block;
  }

  &.whatsapp-calling {
    cursor: default;
    animation: border-increase 1s linear infinite !important;
    background: $color-blue-300 !important;

    .fe-phone {
      display: none;
    }

    .fe-phone-call {
      display: inline-block;
      animation: spin 1s linear infinite;
      color: $color-text-light;
    }
  }

  &.no-hover {
    opacity: 0.65;
    cursor: auto;
  }
}

.tooltip-only-have-whatsapp {
  width: 266px;

  img {
    height: 4.6rem;
    width: 6rem;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(0deg);
  }
}

@keyframes border-increase {
  25% {
    outline: 1.78px solid rgba(126, 156, 247, 1);
  }
  50% {
    outline: 3.56px solid rgba(126, 156, 247, 1);
  }
}

button, .button-whatsapp {
  min-width: 1.244rem;
  min-height: 1.244rem;
  background-color: #e1e9f4;
  border: none;
  color: #95aac9;
  border-radius: $whatsapp-border-radius;

  &:hover:not(.no-hover), .whatsapp-calling {
    background-color: $color-blue-300 !important;
    color: #e3ebf6 !important;
  }
}

.button-top-bar {
  margin-left: 1rem !important;
  padding: $whatsapp-button-padding 0.807rem;
  height: $whatsapp-button-size !important;
  color: $color-text-gray !important;

  &:not(:first-child) {
    padding: $whatsapp-button-padding !important;
    height: $whatsapp-button-size !important;
    width: $whatsapp-button-size !important;
  }
}

.button-whatsapp {
  &.small-active {
    max-width: 32px !important;
    min-width: 32px !important;
    width: 32px !important;
    padding: 0.25rem !important;
    font-size: 0.875rem !important;
  }
}
</style>