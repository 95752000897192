
<template>
  <div class="card m-0">
    <div class="card-header">
      <div class="card-title m-0 d-flex align-items-center">
        <h2 class="m-0">
          {{ $t("all_funnels") }}
        </h2>
        <div
          class="ms-2 d-flex align-items-center justify-content-center bg-light text-primary"
          style="border-radius: 16px; height: 24px; width: 24px; font-size: 12px; line-height: 16px; font-weight: 500"
          v-if="funnels.length"
        >
          {{ funnels.length }}
        </div>
      </div>


      <button
        class="btn text-gray p-0"
        style="background-color: #E1E9F4; height: 32px; width: 240px"
        @click="$emit('open-modal')"
      >
        <span><i class="fa-regular fa-plus me-2" />{{ $t("new_funnel") }}</span>
      </button>
    </div>
    <div class="card-body">
      <div
        v-if="!funnels.length"
        class="text-center"
      >
        <img
          src="@/assets/img/funnelPlusinha.svg"
          class="img-fluid mb-4"
        >
        <div>
          <h4 class="mb-0">
            {{ $t('create_funnels') }}
          </h4>
          <small>{{ $t('you_can_create_as_many_as_you_want') }}</small>
        </div>
      </div>
      <div v-else>
        <div class="grid">
          <div class="row row-cols-lg-5 g-2 g-lg-3">
            <div
              class="col p-2 mt-0"
              v-for="(funnel, index) in funnels.slice(0, 5)"
              :key="index"
            >
              <div
                class="card h-100 buttonsCard"
              >
                <div
                  type="button"
                  @click="goToFunnel(funnel)"
                  class="card-body p-4 d-flex flex-column justify-content-between"
                >
                  <div class="d-flex justify-content-between align-items-center">
                    <p
                      class="tx-text-200 fw-medium m-0"
                    >
                      {{ funnel.name }}
                    </p>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div
                      class="d-flex justify-content-between align-items-center rounded-3 ps-2 pe-2 team"
                    >
                      <button
                        class="btn btn-rounded-circle p-1"
                        style="width: 8px; height: 8px"
                        :style="{ background: funnel.team.color }"
                      />
                      <p class="m-0 ms-3">
                        {{ funnel.team.name }}
                      </p>
                    </div>
                    <button
                      class="btn m-0 p-0"
                      @click="goToFunnel(funnel)"
                    >
                      <i class="fa-regular fa-chevron-right" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="collapse"
          id="collapseFunnels"
        >
          <div class="grid pt-4">
            <div class="row row-cols-lg-5 g-2 g-lg-3">
              <div
                class="col p-2 mt-0"
                v-for="(funnel, index) in funnels.slice(5, funnels.length)"
                :key="index"
              >
                <div
                  class="card h-100 buttonsCard"
                >
                  <div
                    type="button"
                    @click="goToFunnel(funnel)"
                    class="card-body p-4 d-flex flex-column justify-content-between"
                  >
                    <div class="d-flex justify-content-between align-items-center">
                      <p
                        class="tx-text-200 fw-medium m-0"
                      >
                        {{ funnel?.name }}
                      </p>
                    </div>
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div
                        class="d-flex justify-content-between align-items-center rounded-3 ps-2 pe-2 team"
                      >
                        <button
                          class="btn btn-rounded-circle p-1"
                          style="width: 8px; height: 8px"
                          :style="{ background: funnel?.team?.color }"
                        />
                        <p class="m-0 ms-3">
                          {{ funnel?.team?.name }}
                        </p>
                      </div>
                      <button
                        class="btn m-0 p-0"
                        @click="goToFunnel(funnel)"
                      >
                        <i class="fa-regular fa-chevron-right" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="d-flex justify-content-center mb-4"
  >
    <button
      v-if="funnels.length > 5"
      class="btn pt-0 pb-0"
      style="margin-top: -12px; background-color: #E1E9F4; z-index: 1; width: 126px; height:fit-content"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#collapseFunnels"
      :aria-expanded="ariaExpanded"
      aria-controls="collapseFunnels"
      @click="toggleAriaExpanded"
    >
      <i :class="chevronIcon" /> {{ ariaExpanded ? $t("see_less") : $t('see_all') }}
    </button>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "FunnelsList",
  emits: ['open-modal'],
  methods: {
    ...mapActions('crm/funnels', ['fetchFunnels']),
    ...mapActions('crm/contacts', ['fetchUsersByTeam']),
    ...mapMutations('crm/funnels', ['setCurrentFunnel']),
    ...mapActions('crm/funnels', [
      'updateLastAccessedFunnel',
      "paginatedFetchFunnels"
    ]),
    toggleAriaExpanded() {
      this.ariaExpanded = !this.ariaExpanded
    },
    goToFunnel(funnel){
      this.setCurrentFunnel(funnel)
      this.updateLastAccessedFunnel({
        team_id: funnel.team?.id,
        funnel_id: funnel.id
      })
      this.$router.push({
        path: `/manager/crm/${funnel.id}`
      })
    },

  },
  data() {
    return {
      ariaExpanded: false
    }
  },
  computed: {
    ...mapGetters('crm/funnels', {
      funnels: 'getFunnels',
    }),
    chevronIcon() {
      return this.ariaExpanded ? 'fa-regular fa-chevron-up' : 'fa-regular fa-chevron-down';
    },
  },

}
</script>


<style scoped>
.buttonsCard:hover{
  .card-body{
    background-color:#F0F4FA;
  }
  .team{
    background-color: #FFFFFF;
  }
}
.buttonsCard{

  .team{
    background-color: #F0F4FA
  }
}
</style>