import SchedulesReports from "@dialer/manager/pages/reports/schedules-reports";

export default {
  path: "schedules",
  name: "schedules",
  components: {
    main: SchedulesReports
  },
  meta: {
    "short-side-bar": true
  }
};
