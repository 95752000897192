<template>
  <div
    class="card-body d-flex flex-column justify-content-center align-items-center text-center gap-3"
    style="height: 285px"
  >
    <img
      src="@/assets/img/sad-pluszinha.svg"
      :alt="$t('search_messages.results_not_found')"
      style="height: 115px"
    >
    <p> {{ $t('empty_search') }}</p>
  </div>
</template>

<script>
export default {
  name: "TableEmpty"
}
</script>

