<template>
  <VDropdown
    :distance="-4"
    :skidding="-45"
    :arrow-padding="65"
    placement="left"
    :triggers="['click']"
  >
    <button
      id="tooltipButtonInfo"
      class="btn py-0 px-2 rounded-0 rounded-end position-absolute top-0 end-0"
      style="height: 100%;"
      :style="spiedAgent?.id == agent?.id ? 'background-color: #888888' : 'background-color: #3057F2'"
    >
      <i class="fe fe-more-vertical text-light fw-bold" />
    </button>
    <template #popper>
      <div class="p-2 dropdown-wrapper">
        <button
          v-if="canUseSpyMode()"
          class="btn w-100 d-flex align-items-center gap-2"
          @click="startSpy()"
          type="button"
          :disabled="disabledSpyOptions()"
          v-close-popper
          data-test="agentSpyModeTooltip-button"
        >
          <i class="fa-regular fa-eye tx-text-gray" />
          <span class="text-medium"> {{ $t("spy_mode") }}</span>
        </button>

        <VDropdown
          v-if="agent.status !== AgentStatus.ON_WORK_BREAK"
          :distance="15"
          placement="right"
        >
          <button
            class="btn w-100 d-flex align-items-center gap-2"
            data-test="agentIntervalsTooltip-button"
            tabindex="0"
          >
            <i class="fa-regular fa-mug-saucer tx-interval" />
            <span class="text-medium"> {{ $t("capitalizes.intervals") }} </span>
          </button>

          <template #popper>
            <div class="p-3">
              <div v-if="intervals.length > 0">
                <h5 class="text-center">
                  {{ $t("select_interval") }}
                </h5>
                <div class="d-flex flex-column">
                  <template
                    v-for="(interval, i) in intervals"
                    :key="i"
                  >
                    <button
                      v-if="!interval.auto_start"
                      class="btn btn-interval d-flex justify-content-between p-1 px-3"
                      v-close-popper.all
                      data-test="agentIntervalTooltip-button"
                      @click="startWorkBreak({ agent_id: agent.id, work_break_id: interval.id })"
                    >
                      <span class="fs-5">{{ interval.name }} </span>
                      <span class="float-end ms-4 text-muted fs-6">{{ format(interval.minutes) }} </span>
                    </button>
                  </template>
                </div>
              </div>
              <div v-else>
                <h5 class="text-center without-interval p-0 m-0">
                  {{ $t("campaign_not_have_interval") }}
                </h5>
              </div>
            </div>
          </template>
        </VDropdown>

        <button
          v-else
          class="btn w-100 d-flex align-items-center gap-2 bg-yellow-50"
          data-test="agentIntervalsTooltip-button"
          tabindex="0"
          :disabled="loadingFlagsSystem.stopInterval"
          @click="removeAgentFromInterval"
        >
          <i class="fa-regular fa-arrow-left text-yellow-600" />
          <span class="text-medium"> {{ $t("remove_from_interval") }} </span>
        </button>

        <button
          class="btn w-100 d-flex align-items-center gap-2"
          @click="showInfoModal"
          v-close-popper
          data-test="agentInfoTooltip-button"
        >
          <i class="fa-regular fa-search text-primary" />
          <span class="text-medium"> {{ $t("info_client") }} </span>
        </button>
        <button
          class="btn w-100 d-flex align-items-center gap-2"
          @click="logout(agent)"
          v-close-popper
          :disabled="disabledSpyOptions()"
        >
          <i class="fa-regular fa-arrow-right-from-bracket text-danger" />
          <span class="text-medium"> {{ $t("log_out") }} </span>
        </button>
      </div>
    </template>
  </VDropdown>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
import { AgentStatus } from "@/utils";

export default {
  name: "AgentTooltipInfo",
  emits: ["show-info-modal"],

  data() {
    return {
      AgentStatus
    }
  },

  props: {
    agent: {
      type: Object,
      default: null
    },

    intervals: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapGetters("system", {
      spiedAgent: "getSpiedAgent",
      loadingFlagsSystem: "getLoadingFlags",
      jsSipStatus: "getJsSipStatus"
    }),

    ...mapGetters("auth", {
      user: "getUser",
    }),

    ...mapGetters("manager/campaign", {
      campaignLoading: "isLoading"
    }),

    ...mapGetters("manager/real-time", {
      mailings: "getMailings"
    })
  },

  methods: {
    ...mapActions("manager/campaign", ["logoutAgent"]),
    ...mapActions("manager/intervals", ["startWorkBreak"]),
    ...mapActions("manager/campaign/agents", ["setWorkBreakExitPending"]),
    ...mapActions("system", ["showConfirmationMessage", "setSelectedAgentToSpy", "setShowSpy", "setInfoMessage", "stopInterval"]),
    ...mapMutations("auth", ["trackEvents"]),
    disabledSpyOptions() {
      return (
        this.loadingFlagsSystem.JsSIPConnecting ||
        this.loadingFlagsSystem.JsSIPRegistering ||
        this.loadingFlagsSystem.startAgentSpy ||
        this.loadingFlagsSystem.stopAgentSpy ||
        this.loadingFlagsSystem.JsSipUnregistering ||
        this.loadingFlagsSystem.JsSipDisconnecting ||
        this.campaignLoading ||
        this.spiedAgent?.id == this.agent.id
      );
    },

    // Check if the user has the permission to block the spy mode
    canUseSpyMode() {
      if (this.user.permissions.length) {
        return !this.user.permissions.find(permission => permission.name == "users.features.blockspy")
      }
      return true;
    },

    startSpy() {
      this.trackEvents("[Manager] Spy mode Toggle");
      if (this.spiedAgent && this.jsSipStatus === "disconnected") {
        this.setInfoMessage(this.$t("extension_being_used"));
      } else {
        this.setSelectedAgentToSpy(this.agent);
        this.setShowSpy();
      }
    },

    format(num) {
      if (!num) return "-";
      const duration = moment.duration(num, "minutes");

      const hours = Math.floor(duration.asHours());
      const mins = duration.minutes();

      if (hours > 0 && mins > 0) {
        return `${hours}h ${mins}min`;
      } else if (hours > 0) {
        return `${hours}h`;
      } else {
        return `${mins}min`;
      }
    },

    async logout(agent) {
      let response = await this.showConfirmationMessage({
        title: this.$t("logout_agent_confirmation"),
        text:
          this.$t("logout_agent_confirmation") +
          " <strong>" +
          agent.name +
          `</strong> ${this.$t("with_extension")}: <strong>` +
          agent.extension +
          "</strong>?",
        type: "badWarning"
      });

      if (response.isConfirmed) {
        this.logoutAgent({ agent_id: agent.id });
      }
    },

    showInfoModal() {
      this.$emit("show-info-modal", this.agent);
    },

    async removeAgentFromInterval() {
      await this.stopInterval({ agent_id: this.agent.id });
    }
  }
};
</script>
<style lang="scss" scoped>
.dropdown-wrapper {
  width: 190px;
  z-index: 2;

  button {
    &:hover {
      background-color: $color-gray-100;
    }
  }
}

.btn-interval:hover {
  background-color: $color-gray-100;
}
.without-interval {
  max-width: 150px;
}
</style>
