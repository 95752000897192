export default {
    isLoading: state => state.loading,
    getLoadingFlags: state => state.loadingFlags,
    getTeam: state => state.team,
    getList: state => state.list,
    getErrors: state => state.errors,
    getFilters: (state) => state.filters,
    getPagination: (state) => state.pagination,
    getTeams: state => state.teams,
}
