export default {
  getPagination: (state) => state.pagination,
  getFunnels: (state) => state.funnels,
  getCurrentFunnel: (state) => state.currentFunnel,
  getErrorMessages: (state) => state.errorsMessage,

  getLoadingFlags: state => state.loadingFlags,
  getCurrentStepId: state => state.currentStepId,
  getSelectedStep: state => state.selectedStep,
}
