<template>
  <div
    v-if="label.length"
    class="row"
  >
    <div class="col">
      <label
        :class="{
          'form-label': true,
          'text-muted': disabled,
          'required': required
        }"
      >{{ label }}</label>
      <tooltip-component
        v-if="tooltip"
        :title="tooltip"
      />
    </div>
    <div class="col-auto">
      <small
        :class="smallTextColor"
        class="form-text"
      > {{ smallText }} </small>
    </div>
  </div>

  <div
    :class="{
      'form-group': true,
      'input-group': true,
      'input-group-merge': inputGroupMerge,
      'mb-0': true
    }"
  >
    <input
      ref="input"
      :class="{ 'form-control': true, 'is-invalid': errors }"
      :type="type"
      :value="modelValue"
      @focusout="handleBlur"
      @input="$emit('update:modelValue', type == 'number' ? Number($event.target.value) : $event.target.value)"
      :disabled="disabled"
    >
    <div
      v-if="inputGroupMerge"
      class="input-group-text rounded-end"
    >
      <span>{{ groupMergeText }}</span>
    </div>
    <div
      v-if="errors"
      class="invalid-feedback"
    >
      {{ errors && errors[0] }}
    </div>
  </div>
  <small
    v-if="!errors"
    class="form-text text-muted"
  >
    {{ bottomLabel }}
  </small>
</template>

<script>
import TooltipComponent from "@components/tooltip-component.vue";

export default {
  name: "AppInput",
  components: { TooltipComponent },
  mounted() {
    if (this.type == "number") {
      if (this.min !== null) this.$refs.input.setAttribute("min", this.min);
      if (this.max !== null) this.$refs.input.setAttribute("max", this.max);
    }
  },
  methods: {
    handleBlur() {
      this.$refs.input.value = this.modelValue;
    },
  },

  emits: ["update:modelValue"],

  props: {
    type: {
      type: String,
      default: "text",
    },

    modelValue: {
      type: [Number, String],
      default: 0,
    },

    label: {
      default: "",
      type: String,
    },

    required: {
      type: Boolean,
      default: true,
    },

    min: {
      default: null,
      type: Number,
    },

    max: {
      default: null,
      type: Number,
    },

    errors: {
      type: Array,
      default: null,
    },

    inputGroupMerge: {
      type: Boolean,
      default: true,
    },

    groupMergeText: {
      type: String,
      default: null,
    },

    bottomLabel: {
      type: String,
      default: null,
    },
    smallText: {
      type: String,
      default: "",
    },
    smallTextColor: {
      type: String,
      default: "text-muted",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
};
</script>
