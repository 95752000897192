<template>
  <modal-component
    :show="showModal"
    :center="true"
    @close="$emit('updateShowModal', false)"
  >
    <template #content>
      <div class="card mb-0">
        <div class="card-header">
          <h4 class="modal-title">
            {{ modalDetails.header }}
          </h4>
          <button
            class="btn tx-text-gray"
            @click="$emit('updateShowModal', false)"
          >
            <i class="far fa-times" />
          </button>
        </div>
        <div
          class="card-body"
          style="padding: 40px 40px 32px 40px"
        >
          <h2 class="mb-0 tx-text-200 text-center">
            {{ modalDetails.title }}
          </h2>
          <p
            class="mb-0 text-center"
            style="margin-top: 16px;"
          >
            {{ modalDetails.content }}
          </p>
          <div
            class="d-flex flex-column justify-content-center align-items-center"
            style="margin-top: 32px;"
          >
            <button
              @click="_remove(modalDetails.buttons.action)"
              class="btn text-white"
              :class="modalDetails.buttons.backgroundColor"
              style="width: 328px;"
            >
              {{ modalDetails.buttons.firstText }}
            </button>
            <button
              @click="$emit('updateShowModal', false)"
              class="btn tx-text-gray"
            >
              {{ modalDetails.buttons.secondText }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from "@components/modal-component.vue";
import {mapActions, mapMutations} from "vuex";

export default {
  name: "ModalDeleteIvr",

  emits: ['updateShowModal', 'updateNavigation'],

  components: {ModalComponent},

  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    modalDetails: {
      type: Object,
      required: true
    },
    redirectLink: {
      type: String,
      default: ""
    }
  },

  methods: {
    ...mapActions("manager/ivrs", ["remove"]),
    ...mapMutations("system", ["setFlashMessage"]),

    _remove(actionButton) {
      if (actionButton === "previous page") {
        this.$emit('updateNavigation', true)
        this.$router.push(this.redirectLink);
        this.$emit('updateShowModal', true)
        return
      }

      this.remove({
        id: this.$route.params.id
      }).then((data) => {
        this.$router.push({ path: `/manager/ivrs` }).then(() => {
          this.setFlashMessage({
            message: data.message,
            type: "success"
          });
          this.$emit('updateShowModal', false)
        });
      }).catch(data => {
        this.setFlashMessage({
          message: data.message,
          type: "danger"
        });
      });
    },
  },
}
</script>
