export default {
  startLoadingFlag: (state, flag) => (state.loadingFlags[flag] = true),
  stopLoadingFlag: (state, flag) => (state.loadingFlags[flag] = false),
  setErrors: (state, errors) => (state.errors = errors),
  clearErrors: state => (state.errors = {}),
  setCnpjQueryData: (state, data) => (state.cnpjQueryData = data),
  setActivePartners: (state, activePartners) => (state.activePartners = activePartners),
  setDashboardPartnerMetrics: (state, metrics) => (state.dashboardPartnerMetrics = metrics),
  setActivePartnersFilters: (state, filters) => (state.activePartnersFilters.search = filters),
  setActivePartnersPagination: (state, pagination) => (state.activePartnersPagination = pagination),
  setActivePartnersCurrentPage: (state, page) => (state.activePartnersPagination.current_page = page),
  setTrashedPartners: (state, trashedPartners) => (state.trashedPartners = trashedPartners),
  setTrashedPartnersFilters: (state, filters) => (state.trashedPartnersFilters.search = filters),
  setTrashedPartnersPagination: (state, pagination) => (state.trashedPartnersPagination = pagination),
  setTrashedPartnersCurrentPage: (state, page) => (state.trashedPartnersPagination.current_page = page),
  clearCnpjQueryData: state =>
    (state.cnpjQueryData = {
      cnpj_status: "",
      cnpj: ""
    }),
  clearZipCode: state => (state.zipCode = {}),
  setZipCode: (state, zipCode) => (state.zipCode = zipCode),
  setPartner: (state, partner) => (state.partner = partner),
  setWithdrawalAnalysisPartners: (state, withdraw) => (state.withdrawalAnalysisPartners = withdraw),
  setWithdrawalAnalysisPartnersFilters: (state, filters) => (state.withdrawalAnalysisPartnersFilters.search = filters),
  setWithdrawalAnalysisPartnersPagination: (state, pagination) => (state.withdrawalAnalysisPartnersPagination = pagination),
  setWithdrawalAnalysisPartnersCurrentPage: (state, page) =>  (state.withdrawalAnalysisPartnersPagination.current_page = page),
  setWithdrawPartners: (state, withdraw) => (state.withdrawalFinalizedPartners = withdraw),
  setWithdrawalAnalysisPartnersPerPage: (state, number) => (state.withdrawalAnalysisPartnersPagination.per_page = number),
  setTotalAnalysisWithdraw: (state, totalAnalysisWithdraw) => (state.analysisWithdrawTotalPartners = totalAnalysisWithdraw),
  setWithdrawalFinalizedPartners: (state, withdraw) => (state.withdrawalFinalizedPartners = withdraw),
  setWithdrawalFinalizedPartnersFilters: (state, filters) => (state.withdrawalFinalizedPartnersFilters.search = filters),
  setWithdrawalFinalizedPartnersPagination: (state, pagination) => (state.withdrawalFinalizedPartnersPagination = pagination),
  setWithdrawalFinalizedPartnersCurrentPage: (state, page) => (state.withdrawalFinalizedPartnersPagination.current_page = page),
  setWithdrawalFinalizedPartnersPerPage: (state, number) => (state.withdrawalFinalizedPartnersPagination.per_page = number),
  setTotalFinalizedWithdraw: (state, totalFinalizedWithdraw) => (state.finalizedWithdrawTotalPartners = totalFinalizedWithdraw),
  setDetailsPartners: (state, details) => (state.detailsPartner = details),
  setListPartners: (state, list) => (state.listPartners = list),
  setCompanyDetails: (state, data) => (state.companyDetails = data),
  setPartnerDetailsPagination: (state, pagination) => (state.partnerDetailsPagination = pagination),
  setAdminPartnerDetailsCurrentPage: (state, page) => (state.partnerDetailsPagination.current_page = page)
};
