import store from "@store";

import IndexTelephony from "@dialer/manager/pages/settings/telephony/index-telephony";
import TelephonyRoutes from "@dialer/manager/pages/settings/telephony/telephony-routes";

export default {
  path: "telephony",
  name: "telephony",
  components: {
    main: IndexTelephony,
  },
  children: [
    {
      path: "",
      components: {
        telephony: TelephonyRoutes,
      },
    },
  ],
  beforeEnter: (to, from, next) => {
    if (store.getters["auth/isManager"] && store.getters["auth/getUser"].company.plan !== 1 || store.getters["auth/inImpersonate"]) next();
    else next({
      name: "not-found",
      params: {
        pathMatch: to.path.split("/").slice(1),
      },
    });
  },
};
