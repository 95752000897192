<template>
  <a 
    class="btn dropdown-item text-center mt-3 text-logout"
    @click="_logout()"
    href="#"
    data-test="logoutSystem-button"
  >
    <span
      class="fe fe-log-out me-2"
    /> 
    <span>{{ $t('exit_system') }}</span>
  </a>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions("auth", ["logout"]),
    
    _logout() {
      this.logout().then(() => {
        this.$router.push({
          name: "login",
        });
      });
    }
  }
}
</script>