export default {
  isLoading: state => state.loading,
  // getList: state => state.list,
  // getErrorMessage: state => state.errorMessage,
  getErrors: state => state.errors,
  // getCallsPerDay: state => state.callsPerDay,
  getTotalPerTime: state => state.totalPerTime,
  getCallsStatistics: state => state.callsStatistics,
  getDeliveredCalls: state => state.deliveredCalls,
  getQualificationStatistics: state => state.qualificationStatistics,
  getAgentMetrics: state => state.agentMetrics,
  getStatistics: state => state.statistics
}
