<template>
  <button
    class="btn tx-blue-300 mt-5 mb-0"
    data-bs-toggle="collapse"
    data-bs-target="#collapseHistory"
    aria-expanded="false"
    aria-controls="collapseHistory"
  >
    Mostrar histórico de perguntas
    <i class="fa fa-arrow-up m-lg-2" />
  </button>
  <div
    class="collapse history m-0"
    id="collapseHistory"
    ref="collapseHistory"
  >
    <div
      class="card card-body m-0 pb-0"
      style="text-align: left; height: 91vh; border-radius: 16px 16px 0 0;"
    >
      <button
        class="btn tx-blue-300"
        data-bs-toggle="collapse"
        data-bs-target="#collapseHistory"
        aria-expanded="false"
        aria-controls="collapseHistory"
      >
        {{ $t('hide_question_history') }}
        <i class="fa fa-arrow-down m-lg-2" />
      </button>
      <div
        id="overflow-element"
        class="overflow-auto p-2"
        style="max-height: 90%"
      >
        <p
          class="tx-text-200 fw-bolder"
          v-if="thisWeek.length > 0"
        >
          {{ $t('this_week') }}
        </p>
        <div class="grid">
          <div class="row row-cols-lg-4 g-2 g-lg-3">
            <div
              class="col p-2 mt-0"
              v-for="(question, index) in thisWeek"
              :key="index"
            >
              <div
                class="card question-card h-100"
                @click="handleCLick(question)"
              >
                <div
                  class="card-body p-3"
                >
                  <p
                    class="mb-2 tx-text-200 fw-medium question"
                  >
                    {{ question.request }}
                  </p>
                  <p
                    class="answer m-0"
                    style="color: #575778"
                  >
                    {{ question.response }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <p
            class="tx-text-200 fw-bolder mt-4"
            v-if="thisMonth.length > 0"
          >
            {{ $t('this_month') }}
          </p>
          <div class="grid">
            <div class="row row-cols-lg-4 g-2 g-lg-3">
              <div
                class="col p-2 mt-0"
                v-for="(question, index) in thisMonth"
                :key="index"
              >
                <div
                  class="card question-card h-100"
                  @click="handleCLick(question)"
                >
                  <div
                    class="card-body p-3"
                  >
                    <p
                      class="mb-2 tx-text-200 fw-medium question"
                    >
                      {{ question.request }}
                    </p>
                    <p
                      class="answer m-0"
                      style="color: #575778"
                    >
                      {{ question.response }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-for="(monthItems, monthKey) in previousMonths"
          :key="monthKey"
        >
          <p class="tx-text-200 fw-bolder mt-4 text-capitalize">
            {{ monthKey }}
          </p>
          <div class="grid">
            <div class="row row-cols-lg-4 g-2 g-lg-3">
              <div
                class="col p-2 mt-0"
                v-for="(question, index) in monthItems"
                :key="index"
              >
                <div
                  class="card question-card h-100"
                  @click="handleCLick(question)"
                >
                  <div class="card-body p-3">
                    <p class="mb-2 tx-text-200 fw-medium">
                      {{ question.request }}
                    </p>
                    <p
                      class="answer m-0"
                      style="color: #575778"
                    >
                      {{ question.response }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <infinity-pagination-observer
          v-if="validationObserver"
          @reload="nextPage"
          :loading="loading.fetchQuestions"
          parent-container="overflow-element"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import InfinityPaginationObserver from "@components/infinity-pagination-observer.vue";

export default {
  name: "HistoryAiAssistant",
  components: {InfinityPaginationObserver},
  data() {
    return {
      thisWeek: [],
      thisMonth: [],
      previousMonths: [],
      data: [],
      currentPage: 1,
      lastPage: null,
      questionNumber: 0
    };
  },
  emits: ['selectedQuestion'],
  computed: {
    ...mapGetters("crm/funnels", {
      currentFunnel: "getCurrentFunnel"
    }),
    ...mapGetters("crm/ai_assistant", {
      loading: "getLoadingFlags"
    }),
    ...mapGetters("auth",{
      user: "getUser"
    }),
    validationObserver() {
      return this.currentPage <= this.lastPage && this.lastPage !== 1;
    },
  },
  mounted() {
    this.fetchQuestions({funnel_id:this.currentFunnel.id, page:1}).then(response => {
      this.data = response.data;
      this.lastPage = response.meta.last_page;
      this.questionNumber = response.meta.total;
      this.categorizeData();
    });
    if(window.innerHeight > 900 && this.validationObserver) {
      this.currentPage++
      this.fetchQuestions({funnel_id:this.currentFunnel.id, page:2}).then(response => {
        this.data = response.data;
        this.lastPage = response.meta.last_page;
        this.questionNumber = response.meta.total;
        this.categorizeData();
      });
    }
  },
  methods: {
    ...mapActions("crm/ai_assistant", ["fetchQuestions"]),

    handleCLick(question){
      question.fromHistory = true;
      document.getElementById('collapseHistory').classList.remove('show')
      this.$emit('selectedQuestion', question)
    },
    nextPage(){
      if(this.currentPage === this.lastPage){
        return
      }
      this.currentPage++
      this.fetchQuestions({
        funnel_id: this.currentFunnel.id,
        page: this.currentPage
      }).then(response=>{
        this.data = response.data
        this.categorizeData();
      })
    },
    parseDate(dateString) {
      const [datePart, timePart] = dateString.split(' ');
      const [day, month, year] = datePart.split('/').map(Number);
      const [hours, minutes] = timePart.split(':').map(Number);
      return new Date(2000 + year, month - 1, day, hours, minutes);
    },

    getStartOfWeek(date) {
      const dayOfWeek = date.getDay();
      const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
      return new Date(date.setDate(diff));
    },

    getStartOfMonth(date) {
      return new Date(date.getFullYear(), date.getMonth(), 1);
    },

    getLanguage() {
      const languageMap = {
        'pt-br': 'pt-BR',
        'en-us': 'en-US',
        'es-es': 'es-ES',
      };

      return languageMap[this.user?.settings?.language] || 'pt-BR';
    },

    categorizeData() {
      const startOfWeek = this.getStartOfWeek(new Date());
      const startOfMonth = this.getStartOfMonth(new Date());

      this.data.forEach(item => {
        const itemDate = this.parseDate(item.date);

        if (itemDate >= startOfWeek) {

          this.thisWeek.push(item);

        } else if (itemDate >= startOfMonth) {

          this.thisMonth.push(item);

        } else {

          let language = this.getLanguage();
          const monthYearKey = `${itemDate.toLocaleString(language, { month: 'long' })} ${itemDate.getFullYear()}`;

          if (!this.previousMonths[monthYearKey]) {
            this.previousMonths[monthYearKey] = [];
          }
          this.previousMonths[monthYearKey].push(item);
        }
      });

      this.previousMonths = Object.entries(this.previousMonths).reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
    }
  },
}
</script>


<style scoped >
.history {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.answer{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
}

.question{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
}
.question-card:hover{
  border: 1px solid  #B1C2D9;
}
</style>