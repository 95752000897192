<script setup>
import {defineProps} from "vue"

defineProps({
  minHeight: {
    type: String,
    required: true
  },

  backgroundImage: {
    type: [Object, String],
    default: () => {}
  },

  msg: {
    type: String,
    default: null
  }
})
</script>

<script>
export default {
  name: "EmptyChart",
}
</script>

<template>
  <div
    :style="`background-image: url(${backgroundImage}); min-height:${minHeight};`"
    class="w-100 d-flex align-items-center justify-content-center gap-4 flex-column background-image"
  >
    <img
      width="84"
      height="81"
      loading="lazy"
      alt="Chart Not Found"
      src="@/assets/img/twinkle-pluszinha.svg"
    >
    {{ msg || $t('empty_chart') }}
  </div>
</template>

<style scoped lang="scss">
.background-image {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
</style>