import axiosOmni from "@/plugins/axios-omni.js";
import Promise from "lodash/_Promise";

export default {
  fetchAgentsReportList({ commit, getters }) {
    commit("startLoadingFlags", "fetchAgentsReportList");
    commit("setAgentsReportList", []);
    commit("setTotalReportList", []);
    return new Promise((resolve, reject) => {
      axiosOmni
        .get("/whatsapp/reports/agents", { params: getters.getParams })
        .then(response => {
          commit("setAgentsReportList", response.data.data.metrics.data);
          commit("setTotalReportList", response.data.data.totals);
          resolve();
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlags", "fetchAgentsReportList");
        });
    });
  },

  fetchSendChatCsvEmail({ commit, getters }, payload) {
    commit("startLoadingFlags", "fetchSendChatCsvEmail");
    return new Promise((resolve, reject) => {
      axiosOmni
        .get(`/whatsapp/reports/agents/csv?payload=${payload}`, { params: getters.getParams  })
        .then(() => {
          commit("system/setSuccessMessage", "CSV enviado!", { root: true });
          resolve();
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlags", "fetchSendChatCsvEmail");
        });
    });
  },

};
