export default {
  /**
   * TODO: Refactor to loadingFlags pattern and remove these two loadings below
   */
  loading: false,
  loadingContext: '',

  loadingFlags: {
    login: false,
    findLoggedCampaign: false,
    findAgentsOnline: false,
    findAgentFeedback: false,
    fetchCalls: false,
    fetchCallsTime: false,
    consultAgent: false,
    exitConsult: false,
    cancelConsult: false
  },
  errorMessage: '',
  errors: {},
  campaign: null,
  dataSelect: [],
  listAgentsOnline: [],
  agentStats: {},
  agent: {},
  timeCalls: [],
  calls: [],
  activeDropdownLeft: "",
  activeDropdownRight: "",
}
