<template>
  <div>
    <div class="card-footer bg-gray-blue-300 bottom-0 position-sticky">
      <div class="d-flex justify-content-between">
        <div class="d-flex gap-3">
          <button
            v-if="false"
            class="btn bg-gray-blue-300"
            @click="openAttachCollapse"
          >
            <i class="fa-regular fa-paperclip" />
          </button>
          <button
            class="btn bg-gray-blue-300 icon-buttons"
            :title="$t('internal_note')"
            @click="openNotesCollapse"
          >
            <i class="fa-regular fa-note m-0" />
          </button>
          <button
            class="btn bg-gray-blue-300 icon-buttons"
            title="Link"
            @click="openLinkCollapse"
          >
            <i class="fa-regular fa-link" />
          </button>
        </div>
        <div class="d-flex align-items-center gap-3">
          <div
            v-if="shouldShowIsCompleted"
            class="form-check"
          >
            <input
              id="is_completed"
              v-model="is_completed"
              checked
              class="form-check-input"
              type="checkbox"
            >
            <label
              class="form-check-label"
              for="is_completed"
            >
              {{ $t('mark_as_concluded') }}
            </label>
          </div>
          <app-button
            class="px-5 gap-2 d-flex"
            :class="{'bg-gray-blue-100 disabled border-0': !allowSubmit }"
            @click="submit"
            :disabled="!allowSubmit"
          >
            <i class="fa-regular fa-check align-self-center" />
            {{ $t('capitalizes.save') }}
          </app-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {defineComponent} from 'vue'

import AppButton from "@components/app-button.vue";
import moment from 'moment';

export default defineComponent({
  name: "ActivityCardFooter",
  emits: ['submitActivity', 'open'],
  components: {AppButton},


  data() {
    return {
      attachesCollapse: null,
      notesCollapse: null,
      linksCollapse: null,
      is_completed: false,
      shouldShowIsCompleted: true,

      attachmentList: [],
      noteList: [],
      linkList: []
    }
  },

  props: {
    dueDate: {
      type: String,
      default: null
    },
    allowSubmit:{
      type: Boolean,
      default: null
    }

  },


  methods: {
    submit() {
      this.$emit('submitActivity', {
          is_completed: this.is_completed,
          attachments: this.filledAttachments
        }
      )
    },

    openAttachCollapse() {
      this.$emit('open', 'file')
    },
    openNotesCollapse() {
      this.$emit('open', 'note')
    },
    openLinkCollapse() {
      this.$emit('open', 'link')
    },

  },


  watch: {
    dueDate(newVal) {
      const now = moment().startOf('day');
      const newDate = moment(newVal, "DD/MM/YYYY");
      this.shouldShowIsCompleted = newDate.isBefore(now) || newDate.isSame(now);
      this.is_completed = newDate.isBefore(now);
    }
  },
})
</script>
<style lang="scss" scoped>

.form-check-input:not(:checked) {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}
.form-check-input:checked {
  background-color: #3057F2
}
.disabled{
  color: rgba(103, 124, 146, 0.40);
  opacity: 1;
}
.icon-buttons {
  &:hover {
    background-color: $color-gray-200 !important;
  }
}
</style>
