<template>
  <div class="instances-list">
    <template
      v-for="(instance, index) in contactInstances"
      :key="index"
    >
      <template
        v-if="
          isAgent && instance.chat.currentAgent && instance.chat.currentAgent != user.name
        "
      >
        <div
          class="instance d-flex justify-content-between disabled"
          data-bs-html="true"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-title="
            `<div>
                <h5>${$t('chat_already_created')}</h5>
                <span>${$t('agent_capitalize')} ${instance.chat.currentAgent} ${$t('active_chat_number')}<span/>
             <div/> `
          "
        >
          <div class="col text-truncate">
            <dot-status
              :color="instance.team_color"
              :size="'9px'"
              style="margin-right: 10px"
            />{{ instance.name }}
          </div>
          <div class="col-2 text-truncate fw-medium">
            {{ formatInternationalPhoneMask(instance.number) }}
          </div>
          <div class="col-1" />
        </div>
        <hr
          v-if="index != contactInstances.length - 1"
          class="navbar-divider my-2"
        >
      </template>
      <template v-else>
        <div
          class="instance d-flex justify-content-between"
          @click="redirectToChat(instance)"
          @mouseover="hoveredInstance = instance.id"
          @mouseleave="hoveredInstance = null"
        >
          <div class="col text-truncate">
            <dot-status
              :color="instance.team_color"
              :size="'9px'"
              style="margin-right: 10px"
            />{{ instance.name }}
          </div>
          <div class="col text-truncate fw-medium">
            {{ formatInternationalPhoneMask(instance.number) }}
          </div>
          <div class="col-1">
            <div
              v-if="loadingFlags.findChat && selectedInstance?.id == instance.id"
              class="spinner-border spinner-border-sm"
            />
            <span
              v-else
              v-show="hoveredInstance == instance.id"
              class="fe fe-arrow-right tx-blue-300 "
            />
          </div>
        </div>
        <hr
          v-if="index != contactInstances.length - 1"
          class="navbar-divider my-2"
        >
      </template>
    </template>
  </div>
</template>

<script>
import DotStatus from "@/components/dot-status.vue";

import {Tooltip} from "bootstrap";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {formatInternationalPhoneMask} from "@/utils";

export default {
  components: {
    DotStatus
  },

  props: {
    contact:{
      type: Object,
      default: ()=>{}
    }
  },

  data() {
    return {
      hoveredInstance: null,
      selectedInstance: null,
      instanceTooltips: null
    };
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser",
      isAgent: "isAgent"
    }),

    ...mapGetters("whatsapp/contact", {
      contactInstances: "getContactInstances",
    }),

    ...mapGetters("whatsapp/chat", {
      loadingFlags: "getLoadingFlags"
    }),
  },

  mounted() {
    const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    this.instanceTooltips = [...tooltips].map(tooltip => new Tooltip(tooltip));
  },

  methods: {
    ...mapMutations("whatsapp/chat", ["setEmptyChatContact"]),
    ...mapActions("whatsapp/chat", ["findChat"]),

    formatInternationalPhoneMask,

    redirectToChat(instance) {
      if (this.loadingFlags.findChat) return;

      this.selectedInstance = instance;

      let number = this.contact.number;

      if (number.startsWith('55') && number.length === 12) {
        number = number.slice(0, 4) + "9" + number.slice(4);
      }

      const possibleChatIds = [
        `${number}${instance.id}`,
        `${this.contact.number}${instance.id}`
      ];

      const checkChatId = (index = 0) => {
        if (index >= possibleChatIds.length) {
          let emptyChatContact = this.contact;
          emptyChatContact.instance = instance;

          this.setEmptyChatContact(emptyChatContact);

          this.$router.push("/whatsapp/empty-chat");
          this.selectedInstance = null;
          return;
        }

        const chatId = possibleChatIds[index];

        this.findChat(chatId)
            .then(chat => {
              this.$router.push("/whatsapp/" + chat.id);
            })
            .catch(status => {
              if (status == 404) {
                checkChatId(index + 1);
              }
            });
      };

      checkChatId();
    }
  }
};
</script>

<style lang="scss" scoped>
.instances-list {

  h5 {
    margin-top: 0.875rem;
    margin-bottom: 1rem;
  }

  .instance {
    padding: 0.5rem 1rem;
    height: 40px;
    border-radius: 0.375rem;
    cursor: pointer;

    &:hover {
      background-color: $color-gray-blue-300;
    }
  }

  .disabled {
    opacity: 0.6 !important;
    cursor: default !important;
    &:hover {
      background-color: $color-text-light !important;
    }
  }
}
</style>
