import axios from "axios";

export default {
  loadSettingsIA({ commit }) {
    commit("startLoadingFlags", "loadSettingsIA");
    return new Promise((resolve, reject) => {
      axios
        .get("/company/ai_settings")
        .then(response => {
          commit("setSettingsIA", response.data.data);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlags", "loadSettingsIA");
        });
    });
  },

  updateSettingsIA({ commit }, settings) {
    commit("startLoadingFlags", "updateSettingsIA");
    return new Promise((resolve, reject) => {
      axios
        .put("/company/ai_settings", settings)
        .then(response => {
          if (response.status === 204) {
            const message = "success_message.update_settings";
            commit("system/setSuccessMessage", message, { root: true });
          }
          resolve();
        })
        .catch(error => {
          if (error.response.status === 422 && error.response.data.errors) {
            commit("setErros", error.response.data.errors);
          }
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlags", "updateSettingsIA");
        });
    });
  },
  changeStatusAvaliationAI({ commit }, status) {
    return new Promise((resolve, reject) => {
      axios
        .put("/company/ai_settings/status", status)
        .then(response => {
          const message = status.active
            ? "Avaliação por inteligência artificial ativada"
            : "Avaliação por inteligência artificial desativada";
          commit("system/setSuccessMessage", message, { root: true });
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  },
  async tempQualifications({ dispatch, rootGetters }, id) {
    await dispatch("manager/campaign/fetchCampaignById", id, { root: true });
    const { qualification_list_id } = rootGetters["manager/campaign/getCampaign"].dialer_settings;
    await dispatch("manager/qualification/findById", { id: qualification_list_id }, { root: true });
  },
  loadDashboardIA({ commit }) {
    commit("startLoadingFlags", "loadDashboardIA");
    return new Promise((resolve, reject) => {
      axios
        .get("/ai_evaluation")
        .then(response => {
          if (response.data.data) {
            commit("setDashboardIA", response.data.data);
          }
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlags", "loadDashboardIA");
        });
    });
  },
  loadCampaignsIA({ commit, getters }) {
    commit("startLoadingFlags", "loadCampaignsIA");
    return new Promise((resolve, reject) => {
      axios
        .get("/ai_evaluation/campaigns", {
          params: getters.getCampaignParams
        })
        .then(response => {
          commit("setCampaigns", response.data.data);
          commit("setCampaignsPagination", response.data.meta.pagination);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlags", "loadCampaignsIA");
        });
    });
  },
  loadCampaignsAgentsById({ commit }, id) {
    commit("startLoadingFlags", "loadCampaignsAgentsById");
    return new Promise((resolve, reject) => {
      axios
        .get(`/ai_evaluation/campaign/${id}/agents`)
        .then(response => {
          const { campaign } = response.data.data;
          const modifiedCampaign = campaign.data[0];
          commit("setMetricsCampaign", modifiedCampaign);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlags", "loadCampaignsAgentsById");
        });
    });
  },
  campaignsAgents({ commit, getters }, id) {
    commit("startLoadingFlags", "campaignsAgents");
    return new Promise((resolve, reject) => {
      axios
        .get(`/ai_evaluation/campaign/${id}/agents`, {
          params: getters.getCampaignAgentsParams
        })
        .then(response => {
          const { agents } = response.data.data;
          commit("setCampaignsAgents", agents.data);
          commit("setCampaignAgentsPagination", response.data.data.agents.meta.pagination);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlags", "campaignsAgents");
        });
    });
  },
  loadAgentsCalls({ commit, getters }, payloadIds) {
    commit("startLoadingFlags", "loadAgentsCalls");
    return new Promise((resolve, reject) => {
      axios
        .get(`/ai_evaluation/campaign/${payloadIds.campaignId}/agent/${payloadIds.agentId}/calls`, {
          params: getters.getAgentCallsParams
        })
        .then(response => {
          commit("setAgentsCalls", response.data.data);
          commit("setAgentsPagination", response.data.meta.pagination);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlags", "loadAgentsCalls");
        });
    });
  },
  updateAiChat({ commit }, {companyId, params}) {
    commit("startLoadingFlags", "updateAiChat");
    return new Promise((resolve, reject) => {
      axios
        .patch(`/companies/${companyId}`, {}, { params })
        .then(() => {
          commit("system/setSuccessMessage", "success_message.update_settings", { root: true });
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlags", "updateAiChat");
        });
    });
  },
  setCampaignsCurrentPage({ commit }, page) {
    commit("setCampaignsCurrentPage", page);
  },
  setCampaignsFilters({ commit }, filter) {
    commit("setCampaignsFilters", filter);
  },
  setCampaignAgentsCurrentPage({ commit }, page) {
    commit("setCampaignAgentsCurrentPage", page);
  },
  setCampaignAgentsFilters({ commit }, filter) {
    commit("setCampaignAgentsFilters", filter);
  },
  setAgentsCallsCurrentPage({ commit }, page) {
    commit("setAgentsCallsCurrentPage", page);
  },
  setAgentsFilters({ commit }, filter) {
    commit("setAgentsFilters", filter);
  }
};
