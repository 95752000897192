<template>
  <custom-tooltip
    @show="toggleShow(true)"
    @close="toggleShow(false)"
    :placement="placement"
  >
    <template #content>
      <span
        :class="{
          'tx-text-gray': !show,
          'tx-blue-300': show
        }"
      >
        <i :class="`${icon}`" />
      </span>
      <div
        :style="`min-width: ${minWidth}`"
        v-if="show"
        ref="popoverRef"
        class="card position-absolute fs-6 tooltip-card rounded"
      >
        <span class="text-center">{{ title }}</span>
        <div
          v-if="body "
          class="tooltip-body d-flex align-items-center rounded"
        >
          <i class="fa-regular fa-lightbulb-exclamation-on" />
          <span>
            {{ body }}
          </span>
        </div>
      </div>
    </template>
  </custom-tooltip>
</template>

<script setup>
import {defineProps, ref} from 'vue';
import CustomTooltip from "@crm/pages/dashboard/components/custom-tooltip.vue";

const show = ref(false)

defineProps({
  title: {
    type: String,
    default: ""
  },
  icon: {
    type: String,
    default: "fa-regular fa-circle-info"
  },
  placement: {
    type: String,
    default: "top"
  },
  color: {
    type: String,
    default: "text-primary"
  },
  body: {
    type: String,
    required: true
  },

  minWidth: {
    type: String,
    default: '320px'
  }
});


function toggleShow(value) {
  show.value = value;
}
</script>


<style scoped lang="scss">
.tooltip-card {
  box-shadow: 0 4px 18px 0 rgba(34, 54, 77, 0.12);
  z-index: 10;
  padding: 0.5rem;
  margin-top: 0.5rem;
  line-height: 1.2;
}

.tooltip-body {
  margin-top: 0.5rem;
  padding: 0.5rem;
  gap: 0.5rem;
  color: $color-blue-500;
  background-color: $color-gray-blue-200;
}
</style>