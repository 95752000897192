<template>
  <div
    class="card d-flex align-items-center gap-2 flex-row mb-0 bg-transparent shadow-none"
    v-if="audioFileUrl"
  >
    <button
      class="col-auto btn p-2 rounded"
      :class="{
        'bg-blue-300': isPlaying && bgButton,
        'bg-gray-blue-300': !isPlaying && bgButton,
        'bg-transparent': !bgButton,
      }"
      :disabled="!audioDuration"
      @click="togglePlay"
    >
      <i
        class="m-0 fal"
        :class="{
          'fa-pause': isPlaying,
          'fa-play': !isPlaying,
          'tx-blue-300': !bgButton,
          'tx-gray-blue-100': isPlaying && bgButton,
          'tx-text-gray': !isPlaying && bgButton,
        }"
      />
    </button>

    <span class="col-auto tx-text-200">{{ formatSecondsToMMSS(currentTime) }}</span>
    <input
      class="col"
      type="range"
      min="0"
      :max="audioDuration"
      step="0.1"
      v-model="currentTime"
      @input="seekAudio"
    >

    <span class="col-auto tx-text-200">{{ formatSecondsToMMSS(audioDuration) }}</span>
  </div>
</template>
<script>
import { formatSecondsToMMSS } from "../utils";

export default {
  name: "AudioPlayer",
  data() {
    return {
      audioFileUrl: null,
      audioElement: null,
      isPlaying: false,
      currentTime: 0,
      audioDuration: 0,
    };
  },
  props: {
    file: {
      type: File,
      required: true,
    },
    bgButton: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    if (this.isValidUrl(this.file)) {
      this.audioFileUrl = this.file;
    } else {
      this.audioFileUrl = URL.createObjectURL(this.file);
    }
    this.audioElement = new Audio(this.audioFileUrl);
    this.audioElement?.addEventListener("loadedmetadata", () => {
      this.audioDuration = this.audioElement?.duration;
    });
    this.audioElement?.addEventListener("timeupdate", () => {
      this.currentTime = this.audioElement?.currentTime;
    });
    this.audioElement?.addEventListener("ended", () => {
      this.currentTime = 0;
      this.isPlaying = false;
    });
  },

  methods: {
    formatSecondsToMMSS,
    isValidUrl(value) {
      try {
        new URL(value);
        return true;
      } catch (e) {
        return false;
      }
    },
    togglePlay() {
      if (this.isPlaying) {
        this.audioElement.pause();
      } else {
        this.audioElement.play();
      }
      this.isPlaying = !this.isPlaying;
    },
    seekAudio() {
      this.audioElement.currentTime = this.currentTime;
    },
  },

  unmounted() {
    this.audioElement.pause();
    this.audioElement.currentTime = 0;
    this.audioElement = null;
  },
}
</script>
<style scoped lang="scss">
input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.15s ease-in-out;
  border: none;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;
    background: $color-gray-blue-300;
    border-radius: 5px;
  }

  &::-moz-range-track {
    width: 100%;
    height: 6px;
    background: $color-gray-blue-300;
    border-radius: 5px;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 4px;
    height: 16px;
    background: $color-gray-300;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    transform: translateY(-8px);

    &:hover {
      background-color: $color-gray-500;
    }
  }

}
</style>