import axios from "axios";

export default {
    searchLoginHistory({ commit }, payload) {

        commit('clearErrors');
        commit('startLoading', 'searchLoginHistory');
        commit('setList', []);

        return new Promise((resolve, reject) => {
          axios.get(`/agents/login_history`, {
            params: {
              campaign_id: payload.campaign_id,
              start_date: payload.startDate,
              end_date: payload.endDate,
              agent_id: payload.agent_id,
              page: payload.page
            }
          })
            .then((response) => {
                commit('setList', response.data.data);
                commit('setPagination', response.data.meta.pagination);
                resolve();
            })
            .catch((response) => {
                commit('setAlert', { message: response.response.data.detail, type: 'danger' });
                response.response.status == 422 && response.response.data.errors && commit('setErrors', response.response.data.errors);
                reject({ message: response.response.data.detail });
            })
            .finally(() => {
                commit('stopLoading', 'searchLoginHistory');
            });
        });
    },
    downloadTable({commit} , payload) {
      commit('clearErrors');
      commit('startLoading', 'downloadTable');

      return new Promise((resolve, reject) => {
        axios.get(`/agents/login_history/csv`, {
          params: {
            start_date: payload.startDate,
            end_date: payload.endDate,
            campaign_id: payload.campaign_id,
            email: payload.email,
            agent_id: payload.agent_id,
          }
        })
          .then(() => {
              commit("system/setSuccessMessage", "success_message.generate_report", { root: true });
              resolve();
          })
          .catch((response) => {
            commit('system/setErrorMessage', response.response.data, { root: true })
            commit('setAlert', { message: response.response.data.detail, type: 'danger' });
            response.response.status == 422 && response.response.data.errors && commit('setErrors', response.response.data.errors);
            reject({ message: response.response.data });
          })
          .finally(() => {
            commit('stopLoading', 'downloadTable');
          });
      })
    },
}
