<template>
  <div class="card mb-0">
    <div class="card-header">
      <h4 class="modal-title">
        {{ $t("keys_settings") }}
      </h4>
      <button
        class="btn tx-text-gray"
        @click="$emit('closeModal')"
      >
        <i class="far fa-times" />
      </button>
    </div>
    <div
      class="card-body"
    >
      <div class="col align-content-center text-center">
        <img
          src="@/assets/img/empty-spreadsheet.svg"
          alt="default_key"
        >
        <p
          class="text-large pb-4"
          v-html="$t('you_need_to_define_a_target')"
        />
      </div>
      <div class="col">
        <div class="d-flex justify-content-between">
          <label
            class="form-label
                          required"
          >{{ $t("capitalizes.redirection") }}
          </label>
        </div>
        <single-select
          id="redirectIdSelect"
          @selected-option="$emit('update:redirect_id', $event.id)"
          label="name"
          :disabled="loadingFlags.findFilters"
          :list-array="receptiveQueues"
        />
      </div>
      <div class="mt-4 text-center">
        <div>
          <app-button
            color="btn-primary w-50 mb-3"

            class="text-capitalize"
            @click="$emit('submit')"
            :blocked="!redirectId"
          >
            {{ $t("save") }}
          </app-button>
        </div>
        <div>
          <app-button
            color="bg-transparent w-50"
            class="text-capitalize"
            @click="$emit('closeModal')"
          >
            {{ $t("cancel") }}
          </app-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AppButton from "@components/app-button.vue";
import { mapGetters } from "vuex";
import SingleSelect from "@components/single-select.vue";

export default {
  name: "KeyDefaultModal",
  components: { SingleSelect, AppButton },
  props: {
    redirectId: {
      type: Number,
      required: true,
    },
  },
  emits: ["update:redirect_id", "submit", "closeModal"],
  computed: {
    ...mapGetters("manager/filters", {
      receptiveQueues: "getReceptiveQueues",
      loadingFlags: "getLoadingFlags",
    }),
  },
}
</script>