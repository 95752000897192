<template>
  <button
    :id="`action-button-${id}`"
    :ref="`action-button-${id}`"
    :disabled="blocked"
    @click="$emit('click', $event)"
    class="btn action-button fs-6 d-inline-flex justify-content-center align-items-center lh-1"
  >
    <i :class="icon" />
  </button>
</template>
<script>
export default {
  name: "ActionButton",
  emits: ['click', 'update:modelValue'],
  props: {
    icon: {
      type: String,
      default: "fa-regular fa-computer-mouse-scrollwheel"
    },
    blocked: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: "action-button-id"
    },

    modelValue: {
      type: HTMLElement,
      default: null
    }
  },

  data() {
    return {
      model: null,
    }
  },

  mounted() {
    this.initModel();
  },

  methods: {
    initModel() {
      if (!this.modelValue && !this.model) {
        this.model = this.$refs[`action-button-${this.id}`]
        this.$emit('update:modelValue', this.model);
      }
    }
  },
}
</script>
<style scoped lang="scss">
.action-button {
  color: #575778;
  padding: 0.125rem;
  min-width: 18px;
  min-height: 18px;

  &:hover {
    color: #3057F2;
  }
}
</style>