export default {
  loadingFlags: {
    searchIntervals: false,
    downloadTable: false,
  },
  errorMessage: '',
  errors: {},
  list: [],
  campaign: null,
  register: null,
}
