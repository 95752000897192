<template>
  <div class="card">
    <div class="card-body row">
      <div class="col-6">
        <div class="input-group flex-column">
          <label class="form-label required">
            {{ $t('import_to_step') }}
          </label>
          <div class="dropdown-center">
            <button
              aria-expanded="false"
              class="form-select d-flex"
              data-bs-toggle="dropdown"
              type="button"
            >
              <span>{{ stepSelected.name }}</span>
            </button>
            <div
              id="form-inputs-select"
              class="dropdown-menu w-100 static overflow-y-scroll shadow-lg"
            >
              <ul class="teams-list mb-0 px-2">
                <li
                  v-for="(step, i) in steps"
                  :key="i"
                  :value="step.id"
                  class="dropdown-item cursor-pointer"
                  @click="selectStep(step)"
                >
                  {{ step.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="input-group flex-column">
          <label class="form-label required">
            {{ $t('opportunity_distribution') }}
          </label>
          <div class="dropdown-center">
            <button
              aria-expanded="false"
              class="form-select d-flex"
              data-bs-toggle="dropdown"
              type="button"
            >
              <span>{{ selectedDistributionMode? selectedDistributionMode.label : $t('select') }}</span>
            </button>
            <div
              id="form-inputs-select"
              class="dropdown-menu w-100 static overflow-y-scroll shadow-lg"
            >
              <ul class="teams-list mb-0 px-2">
                <li
                  v-for="(mode, i) in availableDistributionModes"
                  :key="i"
                  :value="mode.id"
                  class="dropdown-item cursor-pointer"
                  @click="selectDistributionMode(mode)"
                >
                  {{ mode.label }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="selectedDistributionMode && [1,2].includes(selectedDistributionMode.id)"
        class="col-12 mt-3"
      >
        <div class="input-group flex-column">
          <label class="form-label required">
            {{ $t('cards_responsible') }}
          </label>
          <div
            class="dropdown-center"
            ref="dropdown"
          >
            <button
              aria-expanded="false"
              class="form-select d-flex"
              data-bs-toggle="dropdown"
              type="button"
            >
              <span v-if="selectedResponsible">
                {{ selectedResponsible.name }}
              </span>
              <span
                v-else
                class="text-black-50"
              >
                {{ $t('select_responsible') }}
              </span>
            </button>
            <div
              id="form-inputs-select"
              class="dropdown-menu w-100 static overflow-y-scroll shadow-lg"
            >
              <ul
                v-if="selectedDistributionMode.id === 1"
                class="teams-list mb-0 px-2"
              >
                <li class="dropdown-item search">
                  <div class="input-group input-group-flush input-group-merge">
                    <div class="input-group-text p-0 me-1">
                      <i class="fa-regular fa-magnifying-glass" />
                    </div>
                    <input
                      type="search"
                      class="form-control list-search p-0"
                      :placeholder="$t('placeholders.search_capitalize')"
                      v-model="params.search"
                      @input="search"
                    >
                  </div>
                </li>
              </ul>
              <ul
                v-if="selectedDistributionMode.role === 'agent' && agents.length > 0"
                id="agent-dropdown"
                class="p-3 teams-list pt-0 mb-0 overflow-auto"
              >
                <li
                  v-for="(agent, j) in agents"
                  :key="j"
                  :value="agent.id"
                  class="dropdown-item"
                  @click="selectResponsible(agent)"
                >
                  {{ agent.extension.extension_number }}
                  <span class="fw-bold">{{ agent.name }}</span>
                </li>
              </ul>
              <ul
                v-else-if="selectedDistributionMode.role === 'supervisor' && supervisors.length > 0"
                class="p-3 teams-list mb-0"
              >
                <li
                  v-for="(supervisor, k) in supervisors"
                  :key="k"
                  :value="supervisor.id"
                  class="dropdown-item"
                  @click="selectResponsible(supervisor)"
                >
                  {{ supervisor.extension.extension_number }}
                  <span class="fw-bold">{{ supervisor.name }}</span>
                </li>
              </ul>
              <ul
                v-else
                class="teams-list mb-0 px-2"
              >
                <li class="dropdown-item">
                  {{ $t('no_responsible_found') }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "DistributionCardMode",

  emits: ['selectedStep', 'selectedDistributionMode', 'selectedResponsible'],

  data() {
    return {
      stepSelected: null,
      selectedDistributionMode: null,
      selectedResponsible: null,
      availableDistributionModes: [
        { id: 1, mode: 'one', role: 'agent', label: this.$t('only_for_one_agent') },
        { id: 2, mode: 'one', role: 'supervisor', label: this.$t('only_for_one_supervisor') },
        { id: 3, mode: 'all', role: 'agent', label: this.$t('equal_between_agents') },
        { id: 4, mode: 'all', role: 'supervisor', label: this.$t('equal_between_supervisors') },
        { id: 5, mode: 'one', role: 'manager', label: this.$t('distribute_manually') },
      ],
      params: {
        page: 1,
        per_page: -1,
        search: '',
        status: 'active'
      }
    }
  },
  mounted() {
    this.selectedDistributionMode = this.availableDistributionModes[4]
    this.$emit('selectedDistributionMode', this.selectedDistributionMode)
  },

  created () {
    this.setStepSelected(this.currentFunnel.steps[0])
    this.loadAgents()
    this.loadSupervisors()
  },

  computed: {
    ...mapGetters("crm/funnels", {
      currentFunnel: "getCurrentFunnel"
    }),
    ...mapGetters("crm/contacts", {
      agents: "getTeamAgents",
      agentsPagination: 'getAgentsPagination',
      loadingFlags: "getLoadingFlags"
    }),
    ...mapGetters("manager/supervisor", {
      supervisors: "getList"
    }),

    steps() {
      return this.currentFunnel.steps
    }
  },

  methods: {
    ...mapActions('crm/kanban', ['fetchStepsByFunnelId']),
    ...mapActions('crm/contacts', ['fetchAgentsByTeam', 'fetchAgentsByTeamSearch']),
    ...mapActions("manager/supervisor", ["loadSupervisorList"]),

    loadAgents () {
      this.fetchAgentsByTeam({
        team_id: this.currentFunnel.team.id,
        params: this.params
      });
    },

    search: _.debounce(function () {
      this.loadAgents();
    }, 1000),

    loadSupervisors () {
      this.loadSupervisorList()
    },

    setStepSelected(step) {
      this.stepSelected = step
      this.$emit('selectedStep', this.stepSelected)
    },

    setSelectedDistributionMode(selectedDistributionMode) {
      this.selectedDistributionMode = selectedDistributionMode
      this.$emit('selectedDistributionMode', this.selectedDistributionMode)
    },

    selectStep(step) {
      const stepSelected =  {
        id: step.id,
        name: step.name,
      }
      this.setStepSelected(stepSelected)
    },

    selectDistributionMode (mode) {
      this.selectedResponsible = null
      this.setSelectedDistributionMode(mode)
      this.$emit('selectedResponsible', this.selectedResponsible)
    },

    selectResponsible(agent) {
      this.selectedResponsible = {
        id: agent.id,
        name: agent.name
      }
      this.$emit('selectedResponsible', this.selectedResponsible)
    }
  }
}
</script>
<style scoped lang="scss">
.teams-list {
  min-height: min-content;
}

.overflow-y-scroll {
  overflow-y: auto;
}

.dropdown-item:not(.search) {
  &:hover {
    background-color: $color-gray-200 !important;
    border-radius: $border-radius;
  }
}
</style>
