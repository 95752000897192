<template>
  <button
    class="btn btn-light me-3"
    id="userProfileDropDown"
    data-bs-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    <span
      class="badge bg-danger rounded-pill"
      v-if="register.length && improductiveCallsExceeded > 0"
    >
      {{ improductiveCallsExceeded }}
    </span>
    <span class="text-capitalize">
      {{ $t("unproductive_calls") }}
    </span>
    <span class="fe fe-chevrons-down" />
  </button>
  <div
    class="dropdown-menu p-0 bg-improductive-dropdown"
    aria-labelledby="userProfileDropDown"
  >
    <div class="dropdown-item px-3">
      <div v-if="register.length">
        <div
          v-for="(call, index) in register"
          :key="index"
        >
          <div>
            <span
              class="d-inline-block text-secondary text-truncate"
              style="width: 175px"
            >
              {{ call.route_name }}
            </span>
            <div class="d-flex align-items-center justify-content-between">
              <span
                :title="$t('unproductive_calls_total')"
                :class="{
                  'text-danger':
                    call.total - call.long_calls >= maximum_routes_exceeded ||
                    improductiveCallsPercentage(call) >= maximum_routes_percentage,
                  'text-dark':
                    call.total - call.long_calls < maximum_routes_exceeded &&
                    improductiveCallsPercentage(call) < maximum_routes_percentage,
                  'fw-bold': true
                }"
              >
                {{ call.total }}
              </span>

              <progress-bar
                class="mx-2"
                style="width: 110px; height: 6px"
                :use-icon="false"
                :data="[
                  {
                    color: '#DC9610',
                    percentage: (call.short_calls / call.total) * 100,
                    count: call.short_calls,
                    icon: 'fe-circle',
                    title: $t('unproductive')
                  },
                  {
                    color: '#008A35',
                    percentage: (call.long_calls / call.total) * 100 || 0,
                    count: call.long_calls || 0,
                    icon: 'fe-circle',
                    title: $t('productive')
                  }
                ]"
              />
              <span
                :title="$t('unproductive_calls_percent')"
                :class="{
                  'text-danger':
                    call.total - call.long_calls >= maximum_routes_exceeded ||
                    improductiveCallsPercentage(call) >= maximum_routes_percentage,
                  'text-dark':
                    call.total - call.long_calls < maximum_routes_exceeded &&
                    improductiveCallsPercentage(call) < maximum_routes_percentage,
                  'fw-bold': true
                }"
              >
                ({{ improductiveCallsPercentage(call) }}%)
              </span>
            </div>
          </div>
          <hr
            class="p-0 m-0 my-1"
            v-if="index < register.length - 1"
          >
        </div>
        <hr class="p-0 m-0 my-1">
        <div class="text-center py-2">
          <router-link to="/manager/telephony">
            {{ $t("manage_routes") }}
          </router-link>
        </div>
      </div>
      <div
        v-else
        class="fs-5 "
      >
        {{
          $t("no_call_found")
            .charAt(0)
            .toUpperCase() +
            $t("no_call_found").slice(1) +
            " "
        }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import { initiateDropdowns } from "@/utils";
import { mapActions, mapGetters } from "vuex";

import ProgressBar from "@/components/progressbar-multiple.vue";

export default {
  data() {
    return {
      toggle_icon: false,
      end_date: moment()
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss"),
      start_date: moment()
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss"),
      maximum_routes_exceeded: 75000,
      maximum_routes_percentage: 85
    };
  },
  components: {
    ProgressBar
  },
  mounted() {
    initiateDropdowns();
    this.listUnproductiveStats({
      start_date: this.start_date,
      end_date: this.end_date
    });
  },
  computed: {
    ...mapGetters("auth", {
      user: "getUser"
    }),
    ...mapGetters("manager/unproductive-stats", {
      register: "getRegister"
    }),
    improductiveCallsExceeded() {
      const filtered = this.register.filter(call => {
        return (
          call.total - call.long_calls >= this.maximum_routes_exceeded ||
          this.improductiveCallsPercentage(call) >= this.maximum_routes_percentage
        );
      });

      return filtered.length;
    }
  },
  methods: {
    ...mapActions("manager/unproductive-stats", ["listUnproductiveStats"]),
    improductiveCallsPercentage(call) {
      return (((call.total - call.long_calls) / call.total) * 100).toFixed(2);
    }
  }
};
</script>
