<template>
  <div
    :id="message.internal_id"
    class="ballon-message clearfix card-size p-0"
    :class="{
      'not-from-me': !message.fromMe,
      'from-me justify-content-end': message.fromMe,
      'blink': messageIdFound && messageIdFound === message.internal_id,
      error: message.failed,
    }"
  >
    <div
      v-if="showMsgChannel && !currentChat.is_group"
      class="channel-type d-flex align-items-center tx-text-gray gap-2 mb-2 fw-medium"
      :class="{'justify-content-end': message.fromMe, 'justify-content-start': !message.fromMe}"
    >
      <span
        class="fs-6"
        :class="messageChannel.icon"
      />
      <span>{{ messageChannel.channel }}</span>
    </div>
    <div
      class="d-flex"
      :class="{'justify-content-end': message.fromMe, 'justify-content-start': !message.fromMe}"
    >
      <span
        v-if="message.from_chatbot"
        class="far fa-robot tx-text-gray my-auto"
        style="margin-right: 1rem"
      />
      <div>
        <div
          class="error-options float-end d-flex align-items-center"
          v-if="message.fromMe && message.failed"
        >
          <span
            class="fe fe-refresh-cw"
            v-if="showResend"
            @click="$emit('resend', message)"
            @mouseout="showResend = false"
            data-test="resendMessage-button"
          />
          <span
            @mouseenter="showResend = true"
            class="fe fe-alert-circle"
            v-else
          />
        </div>
        <div
          class="b-content b7 d-flex flex-column"
          :class="{'is-sticker': isSticker}"
          :style="!deletedHover && message.is_deleted ? 'opacity: 50%' : 'opacity: 100%'"
          @mouseover="deletedHover = true"
          @mouseleave="deletedHover = false"
        >
          <span
            v-if="!currentChat?.is_group"
            :style="`color: ${color}`"
            class="role-name fw-medium"
          >{{ getRoleName }}</span>
          <span
            v-if="showDropdown"
            data-test="messageOptions-dropdown"
            class="fe fe-chevron-down ballon-menu text-end px-1"
            :class="{'remove-chevron-sticker': isSticker}"
            data-bs-toggle="dropdown"
          />
          <ul class="dropdown-menu p-3 ballon-options">
            <template
              v-for="item in menuItems"
              :key="item.label"
            >
              <li v-if="item.show">
                <div
                  :class="['dropdown-item fs-5 px-3', item.class, { disabled: item.disabled }]"
                  @click="handleClick(item)"
                  :data-test="item.dataTest"
                >
                  {{ item.label }}
                </div>
              </li>
            </template>
          </ul>
          <div
            v-if="message.is_deleted"
            class="fs-6"
          >
            <span class="fe fe-trash" />
            {{ $t('message_deleted') }}
          </div>
          <div
            v-else-if="message.isExternal"
            class="fs-6"
          >
            <span class="fe fe-send" />
            {{ $t('sent_external_device') }}
          </div>
          <div
            v-else-if="message.isForwarded"
            class="fs-6"
          >
            <span class="fe fe-corner-up-right" />
            {{ $t('forwarded') }}
          </div>
          <div
            v-if="showName"
            class="fw-medium"
            style="margin-bottom: 4px; font-size: 0.75rem"
            :style="'color:' + color"
          >
            {{ name }}
          </div>

          <slot
            name="quote"
            :message="message"
          />

          <slot
            name="message"
            :message="message"
          />
          <div
            v-if="!isSticker && message.type == 'chat'"
            class="chat-bottom"
          >
            {{ formatedTime }}
            <span
              class="ms-1 fw-bold fe fe-clock"
              v-if="message.fromMe && !message.ack"
            />
            <span
              class="ms-1 fw-bold fe fe-check"
              v-else-if="message.fromMe && message.ack === 'server' && !message.isExternal"
            />
            <img
              v-else-if="(message.fromMe && message.ack === 'device') || message.isExternal"
              src="@assets/img/device.svg"
              alt="device"
              class="ms-1"
            >
            <img
              v-else-if="message.fromMe && message.ack == 'read' || message.ack == 'played'"
              src="@assets/img/read.svg"
              alt="read"
              class="ms-1"
            >
          </div>
          <div
            v-if="!isSticker && message.type != 'chat'"
            :class="!message.body && message.type == 'image' ? 'bottom-no-caption' : 'bottom'"
          >
            {{ formatedTime }}
            <span
              v-if="message.fromMe && !message.ack"
              class="ms-1 fw-bold fe fe-clock"
            />
            <span
              v-else-if="message.fromMe && message.ack == 'server'"
              class="ms-1 fw-bold fe fe-check"
            />
            <img
              v-else-if="message.fromMe && message.ack == 'device'"
              src="@assets/img/device.svg"
              alt="device"
              class="ms-1"
            >
            <img
              v-else-if="message.fromMe && message.ack == 'read' || message.ack == 'played'"
              src="@assets/img/read.svg"
              alt="read"
              class="ms-1"
            >
          </div>
          <div v-if="isSticker">
            <div class="time-sticker w-auto d-block">
              {{ formatedTime }}
            </div>
          </div>
          <div
            v-if="['audio', 'ptt'].includes(message.type) && message.audio_transcription"
            class="audio-transcription-bottom"
          >
            {{ transcriptionAudio }}
            <a
              v-if="this.message.audio_transcription.length >= 85"
              href="#"
              @click="showMoreOrSeeLessTranscription"
              class="fw-bold"
            >
              {{ showFullTranscription ? $t('see_less') : $t('see_more_capitalize') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {mapActions, mapGetters} from "vuex";
import {Utils, getMessageDay} from "@/utils";

export default {
  emits: ["resend", "quote", "openModal", "hover"],

  props: {
    message: {type: Object, required: true},
    currentChat: {type: Object, required: true},
    previousMsg: {
      type: Object, default: () => {
      }
    },
    lockedSendMessage: {type: Boolean, default: false},
  },

  data() {
    return {
      showResend: false,
      deletedHover: false,
      ackEnabledOptions: ["server", "device", "read", "played"],
      seedMoreOrSeeLessTranscription: " Ver mais",
      showFullTranscription: false,
      maxWidth: 360,
      authorNameList: [],
    };
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser",
      isManager: "isManager",
      isAgent: "isAgent"
    }),
    ...mapGetters("whatsapp/chat", {
      messageFound: "getMessageFound",
    }),

    isSticker() {
      return this.message.type === 'sticker'
    },
    name() {
      return this.message.agent?.name || this.message.message_from
    },
    previousMsgName() {
      return this.previousMsg.agent?.name || this.previousMsg.message_from
    },
    showName() {
      return !!(this.currentChat?.is_group && this.name !== this.previousMsgName);
    },
    color() {
      if (this.message.from_chatbot) {
        return "#3057F2"
      }
      return this.getAuthorColor(this.name)
    },

    getRoleName() {
      if (this.message.from_chatbot) {
        return "Chatbot"
      }

      if (!this.message?.agent) {
        return this.message.message_from;
      }

      if (this.message?.agent?.role?.data?.name) {
        return `${this.$t(`capitalizes.${this.message?.agent?.role?.data?.name}`)} ${this.message.agent.name}`
      }

      return `${this.$t(`capitalizes.${this.user.role.name}`)} ${this.user.name}`
    },

    showDropdown() {
      return this.menuItems.some(item => item.show) && !this.message.is_deleted && !this.currentChat.isReadOnly;
    },
    menuItems() {
      return [
        {
          label: this.$t('reply_message'),
          dataTest: 'replyMessage-dropdownItem',
          class: '',
          show: this.message?.instance?.type !== 'waba',
          disabled: (!this.message.fromMe && this.ackEnabledOptions.includes(this.message.ack)) || !this.message.internal_id,
          onClick: () => this.$emit('quote', {
            id: this.message.internal_id,
            day: this.getMessageDay(this.message.time)
          }),
        },
        {
          label: this.$t('forward_message'),
          dataTest: 'forwardMessage-dropdownItem',
          class: '',
          show: this.message?.instance?.type !== 'waba',
          disabled: (!this.message.fromMe && this.ackEnabledOptions.includes(this.message.ack)) || !this.message.internal_id,
          onClick: () => this.$emit('openModal'),
        },
        {
          label: this.$t('delete_message'),
          dataTest: 'deleteMessage-dropdownItem',
          class: '',
          show: this.message.fromMe && this.message.instance && this.message.instance.type !== 'waba',
          disabled: this.disableDeleteMessageOption || !this.message.internal_id,
          onClick: () => this.deleteMessage(this.message),
        },
        {
          label: this.$t('download') + ' ' + (this.message.type === 'ptt' ? this.$t('audio') : this.$t('file')),
          dataTest: 'downloadFile-button',
          class: '',
          show: ['ptt', 'document', 'audio', 'video', 'image', 'voice'].includes(this.message.type),
          disabled: !this.message.media,
          onClick: () => window.open(this.message.media, "_blank"),
        },
      ];
    },

    transcriptionAudio() {
      if (this.showFullTranscription || this.message.audio_transcription.length <= 85) {
        return this.message.audio_transcription;
      }
      return this.message.audio_transcription.substring(0, 85) + '...';
    },

    formatedTime() {
      return moment.unix(this.message.time).format("HH:mm");
    },

    messageIdFound() {
      if (!this.messageFound) return
      return this.messageFound.id || this.messageFound._id
    },

    disableDeleteMessageOption() {
      return (
        !this.ackEnabledOptions.includes(this.message.ack) ||
        this.dateDifferenceSoFar(this.message.time) > 48
      );
    },

    showMsgChannel() {
      if (!this.previousMsg) {
        return true
      } else {
        if (this.previousMsg.type && ['transfer', 'internal-message', 'notification-message', 'snooze-message', 'template'].includes(this.previousMsg.type)) {
          return true
        }
        if (this.previousMsg.fromMe !== this.message.fromMe) {
          return true
        }
        return this.previousMsg?.instance?.type !== this.message?.instance?.type;
      }

    },

    messageChannel() {
      if (this.message.instance && this.message.instance.type === 'waba') {
        return {channel: "WABA", icon: "fa-kit fa-waba"}
      }

      if (this.message.instance && this.message.instance.type === 'messenger') {
        return {channel: "Messenger", icon: "fa-brands fa-facebook-messenger"}
      }

      return {channel: "WhatsApp 3C+", icon: "fa-kit fa-whatsapp"}
    },
  },

  watch: {
    deletedHover(value) {
      this.$emit('hover', value ? this.message.internal_id : false)
    },
  },

  methods: {
    dateDifferenceSoFar: Utils.dateDifferenceSoFar,
    getColor: Utils.getColor,
    getMessageDay,
    ...mapActions("whatsapp/message", ["deleteWhatsappMessage"]),

    getAuthorColor(authorName) {
      if (!this.authorNameList.includes(authorName))
        this.authorNameList.push(authorName)
      return this.getColor(this.authorNameList.indexOf(authorName));
    },

    showMoreOrSeeLessTranscription() {
      this.seedMoreOrSeeLessTranscription = this.seedMoreOrSeeLessTranscription === ` ${this.$t('see_more_capitalize')}` ? ` ${this.$t('see_less')}` : ` ${this.$t('see_more_capitalize')}`;
      this.showFullTranscription = !this.showFullTranscription;
    },

    handleClick(item) {
      if (!item.disabled) {
        item.onClick();
      }
    },

    openModal(item) {
      this.$refs.forwardedModal.open(item);
    },

    deleteMessage(message) {
      this.deleteWhatsappMessage(message);
      this.deletedHover = true
    },
  },
};
</script>

<style lang="scss" scoped>
.span-limit {
  display: block;
  word-wrap: break-word;
  white-space: pre-line;
}

.card-size {
  &.from-me {
    margin-left: auto;
  }
}

.ballon-message {
  color: $color-text-solid-black !important;
  max-width: 80%;

  &.error {
    .b-content {
      max-width: calc(100% - 30px) !important;
    }

    .error-options {
      width: 30px;
      text-align: center;
      height: 100%;

      i,
      .fe {
        &.fe-alert-circle {
          color: $color-red-200;
        }

        &.fe-refresh-cw {
          color: $color-blue-300;
        }

        margin: auto !important;
        cursor: pointer;
      }
    }
  }

  .ballon-menu {
    position: absolute;
    top: calc(#{$ballon-padding} - 8px);
    right: calc(#{$ballon-padding} - 2px);
    cursor: pointer;
    opacity: 0;

    &:hover,
    &.show {
      opacity: 1;
    }
  }

  .ballon-options {
    padding: $whatsapp-spacing-1 !important;
    border-radius: $whatsapp-border-radius !important;

    .dropdown-item {
      padding: $whatsapp-spacing-1 !important;
      margin: $whatsapp-spacing-0 0;
      display: block;
      border-radius: $whatsapp-border-radius !important;
      cursor: pointer;

      &:hover {
        background-color: $color-gray-100 !important;
      }
    }
  }

  &.whatsapp-image + .whatsapp-image {
    .b-content {
      padding: 3px !important;
      padding-bottom: 3px !important;
    }
  }

  .is-sticker {
    border: none !important;
    background-color: transparent !important;
  }

  .b-content {
    max-width: 564px;
    word-wrap: break-word;
    white-space: pre-line;
    display: inline-block;
    padding: 6px;
    position: relative;
    font-weight: 400;
    font-size: 14px;

    .caption {
      margin-left: 3px;
    }
  }

  .b-content:has(.message-text, .audio) {
    padding: $ballon-padding !important;
    padding-bottom: $ballon-padding-bottom !important;
  }

  .b-content:has(.message-contact) {
    padding: 14px !important;
  }

  .b-content:has(.file-message) {
    padding: 6px !important;
  }

  &.from-me {
    .b-content:has(.file-message) {
      width: 370px !important;
    }
  }

  &.not-from-me {
    .b-content:has(.file-message) {
      width: 330px !important;
    }
  }

  .time-sticker {
    background: $color-text-light;
    margin-top: 5px;
    right: 12px;
    bottom: 6px;
    font-size: 10px;
    padding: 6px;
    line-height: 10px;
    text-align: right;
    border-radius: $whatsapp-border-radius $whatsapp-border-radius $whatsapp-border-radius 0px !important;
    color: $color-text-100;

    width: fit-content !important;

    i {
      color: white;
      color: $color-text-100;

      &.read {
        color: $color-blue-300;
      }
    }
  }

  .bottom {
    position: absolute;
    right: 12px;
    bottom: 6px;
    font-size: 10px;
    line-height: 10px;
    text-align: right;
    color: $color-text-100;

    .fe {
      color: #888888;
    }
  }

  .audio-transcription-bottom {
    text-align: left;
    color: $color-text-solid-black;
    max-width: 360px;
    background: #F0F4FA;
    border-radius: 0.375rem;
    padding: 0.25rem 0.5rem;
  }

  .bottom-no-caption {
    position: absolute;
    right: 12px;
    bottom: 6px;
    font-size: 10px;
    line-height: 10px;
    text-align: right;
    color: $color-gray-100;

    .fe {
      color: #888888;
    }
  }

  &.from-me {
    .b-content {
      background-color: #D2DDEC;
      float: right !important;
      border: 1px solid #BBC6D5;
      border-radius: $ballon-radius $ballon-radius 0px $ballon-radius;
    }
  }

  &.not-from-me {
    .b-content {
      background-color: #FFFFFF;
      float: left !important;
      border: 1px solid #E1E9F4;
      border-radius: $ballon-radius $ballon-radius $ballon-radius 0px;
    }
  }

  .ballon-menu {
    position: absolute;
    width: 100%;
    max-width: 200px;
    height: 22px;
    right: 0;
    top: 0;
    border-radius: 0px $whatsapp-border-radius 0px 0px;
    cursor: pointer;
    opacity: 0;

    &:hover, &.show {
      background: linear-gradient(195deg, rgba(110, 132, 163, 0.285) 8%, rgba(110, 132, 163, 0) 40%);
      opacity: 1;
    }
  }

  .remove-chevron-sticker {
    background: transparentize(#EAF1FB, 0.1) !important;
    border-radius: 50px !important;
    padding-left: 0px;
    min-width: unset;
    text-align: center;
  }

  .ballon-options {
    .dropdown-item {
      display: block;
      border-radius: $whatsapp-border-radius !important;
      cursor: pointer;

      &.dropdown-item-delete {
        &:hover {
          background-color: $color-hover-danger !important;
        }
      }

      &:hover {
        background-color: $color-gray-100 !important;
      }
    }
  }
}

.is-deleted {
  opacity: 100% !important;
}

.chat-bottom {
  position: absolute;
  padding-left: 8px;
  font-size: 10px;
  line-height: 10px;
  right: 6px;
  bottom: 6px;
  color: $color-text-100;

  .fe {
    color: #888888;
  }
}

.blink {
  animation: blink 3s ease-in-out;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.role-name {
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
}

@media only screen and (max-width: 1199.999px) {
  .ballon-message {
    max-width: 100%;

    .b-content {
      max-width: 100%;
    }
  }

  .role-name {
    font-size: 0.688rem;
  }

  .channel-type {
    font-size: 0.875rem;
  }
}
</style>

