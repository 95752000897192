<template>
  <button
    class="d-flex align-items-center text-start rounded-1 border group cursor-pointer w-100"
    @click="$router.push({ path: `/manager/feedbacks/${group.id}/edit` })"
  >
    <span
      class="d-flex flex-column min-h-40 flex-fill"
    >
      <span
        class="tx-text-100 line-clamp fs-4 fw-medium line-name"
        :title="group.name"
      >
        {{ group.name }}
      </span>
      <span
        class="text-secondary text-small line-count"
      >
        {{ `${group.criteria.length} ${this.$tc("capitalizes.feedbacks", group.criteria.length)}` }}
      </span>
    </span>
    <i class="fa-regular fa-chevron-right tx-text-gray" />
  </button>
</template>

<script>
export default {
  name: "FeedbackGroup",
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
}
</script>
<style scoped lang="scss">
.line-count {
  line-height: 1.333;
}

.min-h-40 {
  min-height: 40px;
}

.line-clamp {
  line-height: 1.333;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.group {
  background-color: var(--bs-white);
  padding: 1rem;
  gap: 1rem;

  &:hover,
  &:focus,
  &:focus-visible {
    background-color: $color-gray-blue-200;
  }
}
</style>