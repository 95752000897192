<template>
  <div class="card mt-4">
    <div class="card-header">
      <h4 class="card-header-title fw-bold">
        {{ $t("default_routes") }}
      </h4>
    </div>
    <div class="card-body d-flex gap-4 justify-content-between">
      <div class="w-100">
        <label class="form-label">
          {{ isBrazil ? $t("route_to_phone") : this.$t("route_capitalize") }}
        </label>
        <select
          class="form-select"
          v-model="landline"
        >
          <optgroup :label="$t('routes_capitalize')">
            <option
              :value="item"
              v-for="item in routes"
              :key="item.id"
            >
              {{ item.name }}
            </option>
          </optgroup>
          <optgroup :label="$t('groups')">
            <template
              v-for="item in routeGroupsSelect"
              :key="item.id"
            >
              <option
                :value="item"
                v-if="item.group_type === 'landline'"
              >
                {{ item.name }}
              </option>
            </template>
          </optgroup>
        </select>
      </div>
      <div
        class="w-100"
        v-if="isBrazil"
      >
        <label class="form-label"> {{ $t("route_to_cellphone") }} </label>

        <select
          class="form-select"
          v-model="mobile"
        >
          <optgroup :label="$t('routes_capitalize')">
            <option
              :value="item"
              v-for="item in routes"
              :key="item.id"
            >
              {{ item.name }}
            </option>
          </optgroup>
          <optgroup :label="$t('groups')">
            <template
              v-for="item in routeGroupsSelect"
              :key="item.id"
            >
              <option
                :value="item"
                v-if="item.group_type === 'mobile'"
              >
                {{ item.name }}
              </option>
            </template>
          </optgroup>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "RoutesTab",
  props: {
    isBrazil: {
      type: Boolean,
      default: false,
      required: true,
    },
    fieldsLandLine: {
      type: Object,
      default: () => {},
      required: true,
    },
    fieldsMobile: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      landline: this.fieldsLandLine,
      mobile: this.fieldsMobile,
      routes: [],
      routeGroupsSelect: [],
    };
  },
  emits: ["update:fieldsLandLine", "update:fieldsMobile"],
  computed: {
    ...mapGetters("manager/filters", {
      filters: "getFilters",
      loadingFilters: "getLoadingFlags",
    }),
    ...mapGetters("auth", {
      user: "getUser",
    }),
  },
  methods: {
    findLandlineRoute(userRoute) {
      this.landline = userRoute.routeId
          ? this.routes.find(el => el.id === userRoute.routeId)
          : this.routeGroupsSelect?.find(el => el.id === userRoute.routeGroupId);
    },
    findMobileRoute(userRoute) {
      this.mobile = userRoute.findRouteMobile
          ? this.routes.find(el => el.id === userRoute.dataCompany.route_mobile_id)
          : this.routeGroupsSelect?.find(el => el.id === userRoute.dataCompany.route_group_mobile_id);
    },
    nacionalRouteOrInternationalRoute() {
      const dataCompany = this.user.company;
      let routeId, routeGroupId, findRoute, findRouteMobile;
      if (dataCompany && (dataCompany.route_landline_id !== 0 || dataCompany.route_group_landline_id !== 0)) {
        routeId = dataCompany.route_landline_id;
        routeGroupId = dataCompany.route_group_landline_id;
        findRoute = dataCompany.route_landline_id;
        findRouteMobile = dataCompany.route_mobile_id;
      } else {
        routeId = dataCompany.international_route_id;
        routeGroupId = dataCompany.international_route_group_id;
        findRoute = dataCompany.international_route_id;
      }
      return {
        routeId,
        routeGroupId,
        findRoute,
        findRouteMobile,
        dataCompany,
      };
    },
  },
  watch: {
    landline: {
      handler() {
        this.$emit("update:fieldsLandLine", this.landline);
      },
      deep: true,
    },
    mobile: {
      handler() {
        this.$emit("update:fieldsMobile", this.mobile);
      },
      deep: true,
    },
    filters: {
      handler(data) {
        if (data.route_groups) {
          this.routeGroupsSelect = Object.values(data.route_groups);
        }
        if (data.routes) {
          this.routes = Object.values(data.routes);
        }
        const userRoute = this.nacionalRouteOrInternationalRoute();
        this.findLandlineRoute(userRoute);
        this.findMobileRoute(userRoute);
      },
      deep: true,
    },
  },
};
</script>
