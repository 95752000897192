import {createStore} from "vuex";
import createPersistedState from "vuex-persistedstate";

import createSocketIOPlugin from "./plugins/socketio";
import createJsSiPlugin from "./plugins/jssip";

import createTimerPlugin from "./plugins/timer";
import easyTimer from "../plugins/timer";

import Campaign from "@dialer/agent/store/campaign";
import Agent from "@dialer/agent/store/agent";
import Qualification from "@dialer/agent/store/qualification";
import WorkBreak from "@dialer/agent/store/work-break";
import Call from "@dialer/agent/store/call";
import Schedule from "@dialer/agent/store/schedule";
import Queue from "@dialer/agent/store/queue";
import RingGroup from "@dialer/agent/store/ring-group";

import QualificationManager from "@dialer/manager/store/qualification";
import Teams from "@dialer/manager/store/teams";
import Integration from "@dialer/manager/store/integration";
import Supervisor from "@dialer/manager/store/supervisor";
import Criterion from "@dialer/manager/store/criterion";
import AgentsDashboard from "@dialer/manager/store/agents";
import AgentManager from "@dialer/manager/store/agent";
import User from "@dialer/manager/store/user";
import Intervals from "@dialer/manager/store/intervals";
import Telephony from "@dialer/manager/store/telephony";
import ReceptiveNumbers from "@dialer/manager/store/receptive-numbers";
import OfficeHours from "@dialer/manager/store/office-hours";
import ReceptiveQueues from "@dialer/manager/store/receptive-queues";
import RingGroupManager from "@dialer/manager/store/ring-group";
import ReceptiveIVRS from "@dialer/manager/store/receptive-ivrs";
import Filters from "@dialer/manager/store/filters";
import Schedules from "@dialer/manager/store/schedules";
import ManualCalls from "@dialer/manager/store/manual-calls";
import CampaignManager from "@dialer/manager/store/campaign";
import ProductsAndServices from "@dialer/manager/store/products-and-services";

import UtilizationReport from "@dialer/manager/store/report/utilization-report";
import AgentsReport from "@dialer/manager/store/agents-report";
import QualificationReport from "@dialer/manager/store/qualification-report";
import intervalsReport from "@dialer/manager/store/intervals-report";
import FeedbackReport from "@dialer/manager/store/report/feedback-report";
import callsReport from "@dialer/manager/store/report/calls-report";
import ChatsReport from "@dialer/manager/store/report/chats-report";
import ChatsAgentsReport from "@dialer/manager/store/report/chats-agents-report";
import GroupChannelsReport from "@dialer/manager/store/report/group-channels-report";
import QualificationChatsReport from "@dialer/manager/store/report/qualification-chats";
import PerformanceReport from "@dialer/manager/store/report/performance-reports";

import LoginHistory from "@dialer/manager/store/login-history";
import ivrs from "@dialer/manager/store/ivrs";
import auth from "@modules/auth";
import system from "@modules/system";
import ListMetrics from "@dialer/manager/store/list-metrics";
import nps from "@store/modules/nps";
import List from "@dialer/manager/store/list";
import Routes from "@dialer/manager/store/routes";
import RealTime from "@dialer/manager/store/real-time";
import UnproductiveStats from "@dialer/manager/store/unproductive-stats";
import userProfile from "@dialer/manager/store/user-profile";
import SmsDashboard from "@sms/store";
import IvrAfterCall from "@dialer/manager/store/ivr-after-call";
import Blocklist from "@dialer/manager/store/blocklist";
import WhatsappManager from "@dialer/manager/store/whatsapp";
import Metrics from "@dialer/manager/store/receptive-queues/metrics";
import Consult from "@dialer/manager/store/receptive-queues/consult";
import Calls from "@dialer/manager/store/receptive-queues/calls";
import Agents from "@dialer/manager/store/receptive-queues/agents";
import QuickMessages from "@dialer/manager/store/whatsapp/quick-messages";
import WhatsappFilters from "@dialer/manager/store/whatsapp/filters";
import WhatsappGroupChannels from "@dialer/manager/store/whatsapp/group-channel";
import Voice3c from "@dialer/manager/store/voice-3c";

import WhatsappCrm from "@whatsapp/store/crm"
import WhatsappChat from "@whatsapp/store/chat"
import WhatsappMessage from "@whatsapp/store/message"
import WhatsappInstance from "@whatsapp/store/instance"
import WhatsappContact from "@whatsapp/store/contact"
import QuickMessage from "@whatsapp/store/quick-message"
import WhatsappWaba from "@whatsapp/store/waba"

import Admin from "@admin/store";
import Partner from "@partner/store";

import crmKanban from "@crm/store/kanban"
import crmFunnels from "@crm/store/funnels"
import crmContactImports from "@crm/store/imports"
import crmIntegrations from "@crm/store/integrations"
import crmTags from "@crm/store/tags"
import crmContacts from "@crm/store/contacts"
import insightsIa from "@dialer/manager/store/insights-ia";
import crmOpportunities from "@crm/store/opportunities"
import crmAiAssistant from "@crm/store/ai-assistant"
import tour from "@crm/store/tour"

import ChatbotChatbots from "@chatbot/store/chatbots";
import ChatbotActions from "@chatbot/store/actions";
import crmActives from "@crm/store/activities";
import crmDashboard from "@crm/store/dashboard"

const plugins = [
  createPersistedState({
    paths: [
      "auth.user",
      "auth.nextDataUpdate",
      "auth.impersonateUserBackup",
      "auth.impersonate",
      "nps",
      "manager.voice_3c.telephonyRouteStatus",
      "system.spiedAgent",
      "system.invoiceNotificationCloseTime"
    ],
  }),
  createSocketIOPlugin(),
  createJsSiPlugin(),
  createTimerPlugin(easyTimer),
];

const store = createStore({
  modules: {
    auth,
    nps,
    system,
    agent: {
      namespaced: true,
      modules: {
        campaign: Campaign,
        agent: Agent,
        qualification: Qualification,
        "work-break": WorkBreak,
        call: Call,
        schedule: Schedule,
        queue: Queue,
        ring_group: RingGroup,

      },
    },
    manager: {
      namespaced: true,
      modules: {
        qualification: QualificationManager,
        teams: Teams,
        integration: Integration,
        supervisor: Supervisor,
        criterion: Criterion,
        agents: AgentsDashboard,
        agent: AgentManager,
        user: User,
        intervals: Intervals,
        telephony: Telephony,
        receptive_numbers: ReceptiveNumbers,
        office_hours: OfficeHours,
        receptive_queues: ReceptiveQueues,
        ring_group: RingGroupManager,
        receptive_ivrs: ReceptiveIVRS,
        filters: Filters,
        schedules: Schedules,
        manual_calls: ManualCalls,
        campaign: CampaignManager,
        products_and_services: ProductsAndServices,
        utilization_report: UtilizationReport,
        agents_report: AgentsReport,
        login_history: LoginHistory,
        qualification_report: QualificationReport,
        ivrs,
        insights: insightsIa,
        intervals_report: intervalsReport,
        list_metrics: ListMetrics,
        "feedback-report": FeedbackReport,
        list: List,
        routes: Routes,
        "calls-report": callsReport,
        "real-time": RealTime,
        "unproductive-stats": UnproductiveStats,
        sms_dashboard: SmsDashboard,
        user_profile: userProfile,
        "ivr-after-call": IvrAfterCall,
        blocklist: Blocklist,
        whatsapp: WhatsappManager,
        receptive_metrics: Metrics,
        receptive_consult: Consult,
        receptive_calls: Calls,
        receptive_agents: Agents,
        quick_messages: QuickMessages,
        whatsapp_filters: WhatsappFilters,
        whatsapp_group_channel: WhatsappGroupChannels,
        voice_3c: Voice3c,
        chats_report: ChatsReport,
        chats_agents_report: ChatsAgentsReport,
        group_channels_report: GroupChannelsReport,
        qualification_chats_report: QualificationChatsReport,
        performance_report: PerformanceReport
      },
    },
    admin: Admin,
    partner: Partner,
    whatsapp: {
      namespaced: true,
      modules: {
        crm: WhatsappCrm,
        chat: WhatsappChat,
        message: WhatsappMessage,
        instance: WhatsappInstance,
        contact: WhatsappContact,
        "quick-message": QuickMessage,
        waba: WhatsappWaba,
      },
    },
    crm: {
      namespaced: true,
      modules: {
        kanban: crmKanban,
        funnels: crmFunnels,
        imports: crmContactImports,
        tags: crmTags,
        contacts: crmContacts,
        integrations: crmIntegrations,
        opportunities: crmOpportunities,
        activities: crmActives,
        ai_assistant: crmAiAssistant,
        tour: tour,
        dashboard: crmDashboard,
      },
    },
    chatbot: {
      namespaced: true,
      modules: {
        chatbots: ChatbotChatbots,
        actions: ChatbotActions,
      },
    },
  },
  plugins,
  mutations: {
    resetState() {
      this.replaceState(JSON.parse(JSON.stringify(this.copyState)));
    },
  },
  actions: {
    resetState({ commit }) {
      localStorage.removeItem("instanceId");
      commit("resetState")
      commit("auth/setUser", null, { root: true });
      commit("system/setSpiedAgent", null, { root: true });
      commit("system/setJsSIPActive", false, { root: true });
      commit("system/setSocketIOActive", false, { root: true });
      commit("system/setInvoiceNotificationCloseTime", 0, { root: true });
    },
  },
});

store.copyState = JSON.parse(JSON.stringify(store.state));

export default store;
