export default {

  getLoadingFlags: state => state.loadingFlags,
  getUnderReviewMailings: state => state.underReviewMailings,
  getFinalizedReviewMailings: state => state.finalizedReviewMailings,

  getUnderReviewPagination: (state) => state.underReviewPagination,
  getFinalizedReviewPagination: (state) => state.finalizedReviewPagination,
  
  getRegister: (state) => state.register,
  getErrors: state => state.errors,

  getTotalUnderReviewMailings: (state) => state.underReviewTotalMailings,
  getTotalFinalizedReviewMailings: (state) => state.finalizedReviewTotalMailings,

  getUnderReviewParams: (state) =>
    Object.assign(
      {
        'page': state.underReviewPagination.current_page,
        'per_page': state.underReviewPagination.per_page
      }
      , state.underReviewFilters, { include: state.includes.join(',') }),

  getFinalizedReviewParams: (state) =>
    Object.assign(
      {
        'page': state.finalizedReviewPagination.current_page,
        'per_page': state.finalizedReviewPagination.per_page
      }
      , state.finalizedReviewFilters, { include: state.includes.join(',') })
}