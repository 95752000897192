import Users from "@admin/pages/settings/users/list-users.vue";
import store from "@/store";

export default {
  path: "users",
  name: "admin_users",
  components: {
    main: Users
  },

  beforeEnter: (to, from, next) => {
    const restrictedAdminLevels = store.getters["admin/getRestrictedAdminLevels"];
    if (restrictedAdminLevels.includes(store.getters["auth/getUser"].settings.access)) next();
    else next("/");
  }
};
