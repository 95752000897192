<template>
  <modal-component
    :show="show"
    @close="closeModal"
    :center="true"
  >
    <template #content>
      <div class="card mb-0">
        <div class="card-header">
          <h3 class="mb-0">
            {{ showPixQrCode ? "QR code gerado" : "Cobrança" }}
          </h3>
          <button
            class="pe-auto btn-close"
            data-test="closePixModal-button"
            @click="closeModal"
          />
        </div>

        <main-loading
          v-if="loadingFlags.createPixPayment"
          style="max-height: 450px;"
        />

        <div
          v-else
          class="card-body"
        >
          <div v-if="showPixQrCode">
            <div
              class="text-center"
              style="margin-bottom: 1rem;"
            >
              <img
                src="~@assets/img/logo.svg"
                width="100"
              >
              <div>
                <img
                  :src="'data:image/jpeg;base64,' + pixInformation.qr_code_base64"
                  width="256"
                >
              </div>
            </div>
            <copy-to-clipboard
              :text-to-copy="pixInformation.copy_paste_pix"
            >
              <template #main>
                <app-button
                  class="w-100 mb-3"
                  data-test="copyPixKey-button"
                >
                  Copiar chave Pix
                </app-button>
              </template>
            </copy-to-clipboard>
            <copy-to-clipboard
              :text-to-copy="generateUrl(pixInformation.payment_id)"
            >
              <template #main>
                <app-button
                  class="w-100"
                  color="btn-outline-primary"
                  data-test="copyUrl-button"
                >
                  Copiar URL
                </app-button>
              </template>
            </copy-to-clipboard>
          </div>

          <div v-else>
            <div class="mb-4">
              <div class="d-flex align-items-center">
                <span
                  class="rounded-circle d-flex align-items-center justify-content-center icon-circle"
                  style="margin-right: .5rem;"
                >
                  <img src="~@assets/img/icons/pix.svg">
                </span>
                <p class="m-0">
                  Pix
                </p>
              </div>
              <p
                class="text-muted mb-0"
                style="margin-top: .5rem;"
              >
                Cobrança em tempo real
              </p>
            </div>
            <form @submit.prevent="submit">
              <div>
                <div class="mb-4">
                  <label
                    for="value"
                    class="form-label required"
                  >
                    Informe o valor
                  </label>
                  <currency-input
                    v-model="fields.transactionAmount"
                    ref="currencyInput"
                    :errors="errors.transaction_amount"
                    :data-test="'transactionAmount-input'"
                  />
                </div>
                <div style="margin-bottom: 2rem;">
                  <label
                    for="value"
                    class="form-label required"
                  >
                    Motivo da cobrança
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    :class="{'is-invalid': errors.reason && errors.reason[0]}"
                    placeholder="Digite aqui..."
                    v-model="fields.reason"
                    data-test="reason-input"
                  >
                  <div class="invalid-feedback">
                    {{ errors.reason && errors.reason[0] }}
                  </div>
                </div>
              </div>
              <app-button
                class="w-100"
                style="margin-bottom: .5rem;"
                :loading="loadingFlags.createPixPayment"
                :disabled="isSubmitDisabled"
                data-test="createPixPayment-button"
              >
                Confirmar
              </app-button>
              <app-button
                class="w-100"
                :color="'btn-link text-muted shadhow-none border-0'"
                @click="closeModal"
                data-test="back-button"
              >
                Voltar
              </app-button>
            </form>
          </div>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from "@components/modal-component";
import AppButton from "@components/app-button";
import CopyToClipboard from '@dialer/manager/pages/dashboards/campaign/components/copy-to-clipboard';
import MainLoading from "@/components/main-loading";
import CurrencyInput from "@components/currency-input"

import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      fields: {
        transactionAmount: null,
        reason: ""
      },
      showPixQrCode: false,
      show: false,
    }
  },

  computed: {
    ...mapGetters("admin/pix", {
      loadingFlags: "getLoadingFlags",
      pixInformation: "getPixInformation",
      errors: "getErrors"
    }),

    isSubmitDisabled() {
      return !this.fields.transactionAmount || !this.fields.reason;
    }
  },

  components: {
    AppButton,
    ModalComponent,
    CopyToClipboard,
    MainLoading,
    CurrencyInput
  },

  methods: {
    ...mapActions("admin/pix", ["createPixPayment", "clearErrors"]),
    ...mapActions("admin/companies", ["findCompanyHistory"]),

    closeModal(event) {
      if(event) {
        event.preventDefault();
      }

      this.show = false;
    },

    submit() {
      this.createPixPayment({
        fields: {
          transaction_amount: this.fields.transactionAmount,
          reason: this.fields.reason,
        },
        id: this.$route.params.id
      }).then(() => {
        this.showPixQrCode = true;
        this.findCompanyHistory({
          id: this.$route.params.id,
        });
      });
    },

    generateUrl(paymentId) {
      const currentURL = window.location.protocol + "//" + window.location.host;
      return currentURL + '/pix/' + paymentId;
    },

    openPixModal() {
      this.clearErrors();
      this.showPixQrCode = false;
      this.$nextTick().then(() => {
        this.$refs.currencyInput.clearValue();
        this.fields = {
          transactionAmount: null,
          reason: "",
        };

        this.show = true;
      })
    }
  }
}
</script>

<style scoped>
.icon-circle {
  width: 2rem;
  height: 2rem;
  background-color: #E1E9F4;
}
</style>
