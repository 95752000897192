import DialerFront from "@/assets/img/empty-state/dialer/dialer-front.svg";
import DialerBack from "@/assets/img/empty-state/dialer/dialer-back.svg";
import PabxFront from "@/assets/img/empty-state/pabx/pabx-front.svg";
import PabxBack from "@/assets/img/empty-state/pabx/pabx-back.svg";
import ReceptiveFront from "@/assets/img/empty-state/receptive/receptive-front.svg";
import ReceptiveBack from "@/assets/img/empty-state/receptive/receptive-back.svg";

const images = {
  "dialerFront": DialerFront,
  "dialerBack": DialerBack,
  "pabxFront": PabxFront,
  "pabxBack": PabxBack,
  "receptiveFront": ReceptiveFront,
  "receptiveBack": ReceptiveBack,
};

export default images;
