<template>
  <modal-component
    @close="closeModal"
    :show="showModal"
    :center="true"
    size="modal-md"
  >
    <template
      #content
    >
      <div class="modal-header p-4">
        <div class="w-100">
          <h2 class="m-0 modal-title text-center">
            {{ title }}
          </h2>
        </div>
        <button
          type="button"
          class="btn-close mb-3"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div
        class="modal-body text-center px-5"
      >
        <div
          class="row justify-content-center gap-3"
          style="margin-bottom: 30px;"
        >
          <div class="col-4">
            <div
              class="input-group py-2 px-3"
            >
              <span
                class="border-0 input-group-text fe fe-minus my-0 cursor-pointer tx-blue-300 py-0 ps-0 pe-2 ms-1"
                style="font-size: 24px;"
                @click="decrease"
              />
              <input
                class="border-0 form-control p-0 h5 text-center tx-text-gray"
                type="number"
                :value="modelValue"
                @input="updateValue"
              >
              <span
                class="border-0 input-group-text fe fe-plus my-0 cursor-pointer tx-blue-300 py-0 pe-0 ps-2"
                style="font-size: 24px;"
                @click="increase"
              />
            </div>
          </div>
          <div class="col-12">
            <button
              class="btn btn-primary w-100"
              :disabled="isSubmitDisabled"
              @click="submit"
            >
              Confirmar
            </button>
            <app-button
              class="w-100"
              color="tx-gray-400"
              @click="closeModal"
            >
              Cancelar
            </app-button>
          </div>
        </div>
      </div>
    </template>
  </modal-component>
</template>
  
  <script>
  import ModalComponent from "@/components/modal-component.vue";
  import AppButton from "@/components/app-button.vue";
  import { mapActions } from 'vuex';
  
  export default {
    name: "EditSystemCallLimitModal",
    emits: ['update:modelValue'],
  
    components: {
      ModalComponent,
      AppButton,
    },
  
    props: {
      modelValue: {
        type: Number,
        default: 0,
      },
    },
  
    data() {
      return {
        title: "Alteração de chamadas por agente",
        showModal: false,
        internalValue: this.modelValue,
        submitParam: {},
        isSubmitDisabled: true,
      };
    },
  
    watch: {
      modelValue(newValue) {
        this.internalValue = newValue;
      },
    },
  
    methods: {
      ...mapActions("admin/system", ["updateSystemLimitCallPerAgent"]),
  
      openModal() {
        this.showModal = true;
      },
  
      closeModal() {
        this.showModal = false;
      },
  
      decrease() {
        this.internalValue = this.internalValue > 0 ? this.internalValue-1 : 0;
        this.updateParent();
      },
  
      increase() {
        this.internalValue += 1;
        this.updateParent();
      },
  
      updateValue(event) {
        this.internalValue = Number(event.target.value);
  
        if (isNaN(this.internalValue)) {
          this.internalValue = 30;
        }
        this.updateParent();
      },
  
      updateParent() {
        this.isSubmitDisabled = false;
        this.$emit('update:modelValue', this.internalValue);
      },
  
      submit() {
        this.updateSystemLimitCallPerAgent({
          max_calls_per_agent: this.internalValue,
        }).then(() => {
          this.showModal = false;
          this.reason = '';
        })
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  
  .modal-header .btn-close {
    margin-right: -0.9rem;
  }
  
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  textarea {
    border: 1px solid $color-gray-blue-300;
  }
  
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $color-text-gray !important;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color-text-gray !important;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: $color-text-gray !important;
  }
  
  </style>
  