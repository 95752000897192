<template>
  <div class="main-content position-relative h-100 login">
    <div
      class="d-flex w-100"
      style="height: 70px"
    >
      <div
        class=" login-card card w-100 rounded-0 m-0"
      >
        <router-link to="/login">
          <img
            alt="Logo Partner"
            class="ps-5 position-absolute my-3"
            src="@/assets/img/partner/partner.svg"
            style="height: 35px;left: 0;"
          >
        </router-link>
      </div>
    </div>
    <div class="d-flex justify-content-center flex-column align-items-center h-100">
      <div
        class="card col-5 login-card"
      >
        <div
          v-if="page === 1"
          class="card-body h-100"
        >
          <div
            class="col-12 align-self-center justify-content-center d-flex flex-column"
          >
            <h1 class="display-4 text-start mb-3 fw-normal mt-5">
              Esqueci minha senha
            </h1>
            <p class="tx-text-gray col-12 text-start mb-0 fs-4">
              Confirme seu email para receber o link para redefinir sua senha
            </p>
            <form
              class="col-12 mt-0"
              @submit.prevent="submit"
            >
              <div class="form-floating form-group">
                <input
                  id="email"
                  v-model="email"
                  :class="{
                    ' is-invalid': errors.email,
                  }"
                  :disabled="loadingFlags.requestPasswordChange"
                  class="form-control form-control-sm input-login margin-forms"
                  required
                  type="email"
                  @blur="inputFocused = false"
                  @focus="inputFocused = true"
                >
                <label
                  :class="{'label-on-border': email || inputFocused }"
                  class="floating-label"
                  for="email"
                > E-mail </label>
                <div class="invalid-feedback">
                  {{ errors.email && errors.email[0] }}
                </div>
              </div>
              <app-button
                :loading="loadingFlags.requestPasswordChange"
                class="w-100 reset-button"
              >
                Redefinir senha
              </app-button>
              <div class="text-center w-100 mt-4">
                <router-link
                  class="tx-text-gray"
                  to="/login"
                >
                  Voltar
                </router-link>
              </div>
            </form>
          </div>
        </div>

        <div
          v-else
          class="card-body h-100"
        >
          <div class="col-12 align-self-center justify-content-center d-flex flex-column">
            <h1 class="display-4 text-start mb-3 fw-normal">
              Quase lá!
            </h1>
            <p class="tx-text-200 col-12 text-start fs-4">
              Caso o e-mail informado esteja cadastrado no 3C Plus, você receberá uma mensagem em <a
                :href="'mailto: ' + email"
                class="text-primary"
              >{{ email }}</a>
              com o
              link
              para
              redefinir sua senha.
            </p>
            <router-link
              class="btn btn-primary my-5"
              to="/login"
            >
              Voltar ao login
            </router-link>
            <p class="tx-text-gray col-12">
              <span class="fw-bold">Não recebeu o email?</span> Verifique se digitou o endereço de e-mail corretamente
              ou se
              caiu na
              caixa
              de spam
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

import AppButton from "@/components/app-button.vue";

export default {
  name: "PartnerForgotPassword",
  data() {
    return {
      email: "",
      page: 1,
      inputFocused: "",
    }
  },
  mounted() {
    this.email = this.userLogin
  },
  methods: {
    ...mapActions("auth", ["requestPasswordChange"]),

    submit() {
      this.requestPasswordChange({
        email: this.email,
        new_front: true,
        domain: window.location.host.split('.')[0]
      }).then(() => {
        this.emailConfirmation = true;
        this.page++;
      });
    },
  },

  computed: {
    ...mapGetters("auth", {
      loadingFlags: "getLoadingFlags",
      errors: "getErrors",
      userLogin: "getUserLogin",
    }),
  },

  components: {
    AppButton
  }
};
</script>
<style
  lang="scss"
  scoped
>
.login {
  height: 100vh;

  #senha {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  #inputGroupInvalid {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .login-card {
    box-shadow: 0px 4px 18px 0px rgba(34, 54, 77, 0.12);
    z-index: 1051 !important;
  }

  form, .display-4, .reset-button {
    margin-top: $whatsapp-spacing-4;
  }

  .card-body {
    padding: 64px 80px
  }

  .form-floating {
    color: $color-text-gray;
    line-height: 30px;
    margin-top: 22px;

    input {
      height: 48px;
    }
  }

  .label-on-border {
    transform: translateY(-18px) !important;
    opacity: 1 !important;
    height: 20px !important;
    padding-top: 2px !important;
    background-color: white !important;
    padding-right: 4px !important;
    padding-left: 4px !important;
  }

  .input-login {
    height: 40px !important;
    padding: 15px !important;
  }

  .input-login.form-control {
    background-color: #ffffff !important;
  }

  .floating-label {
    color: $color-text-200 !important;
  }
}
</style>
