<template>
  <div
    ref="canvas"
    class="offcanvas offcanvas-end"
  >
    <div
      class="offcanvas-header d-flex justify-content-center mx-4"
    >
      <div class="col-lg-12 text-center">
        <h2
          class="my-3"
          style="font-weight: 100"
        >
          {{ mode === "new" ? "Adicionar" : "Editar" }} Usuário
        </h2>
      </div>
      <button
        type="button"
        class="btn-close text-reset mb-4"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      />
    </div>
    <div class="offcanvas-body">
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="form-label required">Nome</label>
            <input
              v-model="fields.name"
              class="form-control"
              inputmode="numeric"
              type="text"
              placeholder=""
              :disabled="editMode === 'activate'"
            >
          </div>
          <div class="form-group">
            <label for="form-label required">Email</label>
            <input
              v-model="fields.email"
              class="form-control"
              inputmode="numeric"
              type="text"
              placeholder=""
              :disabled="editMode === 'activate'"
            >
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col">
                <label class="form-label">
                  Senha
                </label>

                <div class="input-group input-group-merge">
                  <input
                    :type="showPassword ? 'text' :'password'"
                    class="form-control"
                    v-model="fields.password"
                    :disabled="editMode === 'activate'"
                  >
                  <div
                    @click="showPassword = !showPassword"
                    class="input-group-text"
                  >
                    <i
                      class="fe fe-eye"
                      v-if="!showPassword"
                    />
                    <i
                      class="fe fe-eye-off"
                      v-else
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="form-label required">Setor</label>
            <select
              class="form-select"
              v-model="fields.sector"
              :disabled="editMode === 'activate'"
            >
              <template
                v-for="(item, name) in sectors"
                :key="item.id"
              >
                <option :value="item">
                  {{ name }}
                </option>
              </template>
            </select>
          </div>
          <div class="form-group">
            <label for="form-label required">Acesso</label>
            <select
              class="form-select"
              v-model="fields.access"
              :disabled="editMode === 'activate'"
            >
              <template
                v-for="(item, name) in levels"
                :key="item"
              >
                <option :value="item">
                  {{ name }}
                </option>
              </template>
            </select>
          </div>
        </div>
      </div>
      <app-button
        class="col-lg-12 btn btn-primary"
        :loading="loadingFlags.createUser || loadingFlags.editUser || loadingFlags.reactivateUser"
        @click="editMode === 'reactivate' ? userReactivate() : submit()"
      >
        {{ mode === "new" ? "Adicionar" : editMode === "reactivate" ? "Reativar" : "Salvar" }}
      </app-button>
      <app-button
        v-if="editMode === 'desactivate'"
        class="fw my-4"
        color="text-danger"
        :loading="loadingFlags.desactivateUser"
        @click="editMode === 'desactivate' ? userDesactivate() : userReactivate()"
      >
        <span class="fe fe-trash" />
        Desativar
      </app-button>
    </div>
  </div>
</template>
<script>
import { Offcanvas } from "bootstrap";
import appButton from "@/components/app-button.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      mode: "",
      editMode: "",
      offcanvas: null,
      id: null,
      showPassword: false,
      fields: {
        name: null,
        email: null,
        password: null,
        sector: null,
        access: null,
        timezone: "America/Sao_Paulo"
      },
      sectors: {
        Atendimento: "attendance",
        Administrativo: "administrative",
        Board: "board",
        Comercial: "commercial",
        Financeiro: "financial",
        Marketing: "marketing",
        Parceiros: "partnerships",
        "Pessoas e cultura": "people_and_culture",
        Produto: "product",
      },
      levels: {
        "Nível 1": 1,
        "Nível 2": 2,
        "Nível 3": 3,
      }
    }
  },

  /**
   * 
   */
  components: {
    appButton,
  },
  
  /**
   *
   */
  mounted() {
    this.offcanvas = new Offcanvas(this.$refs.canvas);
  },

  /**
   * 
   */
  computed: {
    ...mapGetters("admin/users", {
      loadingFlags: "getLoadingFlags",
    })
  },

  /**
   * 
   */
  methods: {
    ...mapActions("admin/users", [
      "createUser",
      "editUser",
      "desactivateUser",
      "reactivateUser",
      "findActiveUsers",
      "findInactiveUsers"
    ]),
    ...mapActions("system", ["showConfirmationMessage"]),

    /**
     * create or edit user
     */
    submit(){
      if(this.mode == "new") {
        this.createUser({fields: this.fields}).then(() => {
          this.findActiveUsers()
          this.findInactiveUsers()
          this.offcanvas.hide()
        });
      }
      else {
        this.editUser({fields: this.fields, id: this.id}).then(() => {
          this.findActiveUsers()
          this.findInactiveUsers()
          this.offcanvas.hide()
        })
      }
    },

    userDesactivate() {
      this.desactivateUser({id: this.id})
      .then(() => {
        this.findActiveUsers()
        this.findInactiveUsers()
        this.offcanvas.hide()
      })
    },
  
    userReactivate() {
      this.reactivateUser({id: this.id})
      .then(() => {
        this.findActiveUsers()
        this.findInactiveUsers()
        this.offcanvas.hide()
      })
    },

    /**
     * Open Offcanvas for edit user
     */
    edit({ item, editMode }) {
      this.mode = "edit"
      this.editMode = editMode
      this.id = item.id
      this.fields = {
        name: item.name,
        email: item.email,
        password: item.password,
        sector: this.sectors[item.settings.sector],
        access: item.settings.access,
        timezone: item.settings.timezone,
      }
      this.offcanvas.show()
    },
    
    /**
     * open Offcanvas for new user
     */
    new() {
      this.mode = "new"
      this.fields = {
        name: null,
        email: null,
        password: null,
        sector: null,
        access: null,
        timezone: "America/Sao_Paulo"
      }
      this.offcanvas.show()
    }
  },
}
</script>