<template>
  <div
    class="box position-fixed bottom-0 align-items-center flex-column"
    ref="spyBox"
  >
    <info-client-and-agent
      v-if="showInfoClient"
      :agent="lastAgentSpied || agent"
      :campaign="campaign"
    />
    <slide-transition>
      <div
        class="spy-container"
        v-if="showSpy"
      >
        <div
          class="spy bg-white w-100 shadow"
        >
          <div class="d-flex flex-row justify-content-between">
            <div>
              <i class="fe fe-eye me-1 fs-6" />
              <span class="fs-6"> {{ $t("spy_mode_in") }} <strong>{{ lastAgentSpied?.name || agent?.name
              }}</strong></span>
            </div>
            <div
              type="button"
              @click="showInfoClient = !showInfoClient"
              data-test="spyInfo-button"
            >
              <strong
                class="me-1 text-primary fs-6"
              >Ver info</strong>
              <i
                class="fe text-primary fs-6"
                :class="showInfoClient ? 'fe-chevron-down' : 'fe-chevron-up'"
              />
            </div>
          </div>
          <div class="d-flex flex-row justify-content-between col-12 mt-3">
            <div class="col-6 border-end align-self-end">
              <p class="text-secondary m-0 fs-small-400">
                {{ $t("talk_to") }}:
              </p>
              <div
                class="col-11 bg-light rounded-3 position-relative"
              >
                <div
                  class="background-transition bg-primary col-4 rounded-3 position-absolute"
                  ref="speakWithSlider"
                />
                <button
                  class="btn btn-sm col-4"
                  @click="changeSpeakWithOptionAndPosition('onlyHear', 0)"
                >
                  <span
                    style="z-index: 1; position: relative; transition: all 0.4s ease;"
                    :class="speakWith == 'onlyHear' && 'text-light'"
                    class="fs-6"
                    data-test="spyOnlyHear-button"
                  > {{ $t("just_listening") }}</span>
                </button>
                <button
                  class="btn btn-sm col-4"
                  @click="changeSpeakWithOptionAndPosition('agent', 1)"
                  data-test="spyAgent-button"
                >
                  <span
                    style="z-index: 1; position: relative; transition: all 0.4s ease;"
                    :class="speakWith == 'agent' && 'text-light'"
                  > <span class="fe fe-user" />
                    {{ $t("agent_capitalize") }}</span>
                </button>
                <button
                  class="btn btn-sm col-4"
                  @click="changeSpeakWithOptionAndPosition('everyone', 2)"
                  data-test="spyEveryone-button"
                >
                  <span
                    style="z-index: 1; position: relative; transition: all 0.4s ease;"
                    :class="speakWith == 'everyone' && 'text-light'"
                  > <span class="fe fe-users" />
                    {{ $t("all_dropdown") }}</span>
                </button>
              </div>
            </div>
            <div class="col-6 align-self-end d-flex justify-content-end">
              <div class="d-flex col-11 justify-content-center ">
                <audio-summary
                  class="b5 me-4"
                  :size="25"
                  :space="0.5"
                  :item-width="2.5"
                  :percentage="200"
                  :data="summary"
                />
                <button
                  class="btn btn-sm btn-danger rounded-3"
                  @click="exitSpy()"
                  :disabled="disabledSpyOptions()"
                >
                  {{ $t("log_out_omni") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </slide-transition>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import SlideTransition from "@/components/slide-transition.vue";
import AudioSummary from "@/components/audio-summary.vue";
import InfoClientAndAgent from "./info-client-and-agent.vue";


export default {
  data() {
    return {
      waitingForRegister: false,
      analyser: null,
      timer: null,
      summary: [],
      showSpy: false,
      showInfoClient: false,
      speakWith: "onlyHear",
      lastAgentSpied: null,
    };
  },

  props: {
    campaign: {
      type: Object,
      default: null,
    },
  },

  beforeMount() {
    if (this.spiedAgent && this.jsSipStatus === "disconnected") {
      // If there is a agent spied but the SIP is disconnected clear the spied agent
      this.setSpiedAgent(null);
      this.setSelectedAgentToSpy(null);
    }
    this.summary = new Array(64).fill(10);
  },

  async beforeUnmount() {
    if (this.spiedAgent) {
      await this.stopAgentSpy();
      this.setSpiedAgent(null);
      this.setSelectedAgentToSpy(null);
    }
    clearInterval(this.timer);
  },

  computed: {
    ...mapGetters("system", {
      muted: "isMuted",
      spiedAgent: "getSpiedAgent",
      jsSipStatus: "getJsSipStatus",
      loadingFlagsSystem: "getLoadingFlags",
      agentStream: "getJsSipStream",
      agent: "getSelectedAgentToSpy",
      spyErrorMessage: "getSpyError",
    }),

    ...mapGetters("manager/campaign", {
      campaignLoading: "isLoading",
    }),
  },

  components: {
    AudioSummary,
    InfoClientAndAgent,
    SlideTransition,
  },

  watch: {
    jsSipStatus(status) {
      if (status == "registered" && this.waitingForRegister) {
        this.waitingForRegister = false;
        this.startSpy();
      }

      if (status == "in-call" && this.speakWith == "onlyHear") {
        this.setMuted(true)
      }
    },

    agentStream(agentStream) {
      agentStream && this.startFrequencieVisualization()
    },

    agent: {
      handler(agent, lastAgent) {
        // if logout user stop spy
        if (agent?.status == 0) this.stopSpy().then(() => {
          this.setSpiedAgent(null)
          this.setSelectedAgentToSpy(null);
        });

        // when spy box is closing continue with last spiedAgent
        if (agent?.id !== lastAgent?.id) this.lastAgentSpied = lastAgent;
      },
      deep: true,
    },

    spyErrorMessage(spyErrorMessage) {
      // close spy when spy-failed from websocket
      if (spyErrorMessage) this.closeSpyBox()
    },
  },

  methods: {
    ...mapActions("system", ["startAgentSpy", "stopAgentSpy", "setSelectedAgentToSpy"]),

    ...mapMutations("system", ["setMuted", "setJsSIPActive", "setSpyParams", "setSpiedAgent"]),

    disabledSpyOptions() {
      return (
          this.loadingFlagsSystem.JsSIPConnecting ||
          this.loadingFlagsSystem.JsSIPRegistering ||
          this.loadingFlagsSystem.startAgentSpy ||
          this.loadingFlagsSystem.stopAgentSpy ||
          this.loadingFlagsSystem.JsSipUnregistering ||
          this.loadingFlagsSystem.JsSipDisconnecting ||
          this.campaignLoading
      );
    },

    async startSpy() {
      if (this.jsSipStatus == "disconnected") {
        // if not ramal connected, do it
        this.setJsSIPActive(true);
        this.waitingForRegister = true;
      } else if (this.spiedAgent) {
        // if another spy started, stop
        this.stopSpy().then(() => {
          this.setSpiedAgent(null);
          this.setSelectedAgentToSpy(null);
        })
      } else {
        //start spy
        await this.startAgentSpy({ agent_id: this.agent?.id });
        this.lastAgentSpied = null;

        const box = this.$refs.spyBox;
        box.style.display = "flex";
        this.agentStream && this.startFrequencieVisualization();
        this.showSpy = true;
      }
    },

    startFrequencieVisualization() {
      let audioCtx = new AudioContext()
      let audioStream = audioCtx.createMediaStreamSource(this.agentStream)

      this.analyser = audioCtx.createAnalyser()
      audioStream.connect(this.analyser)
      this.analyser.fftSize = 64;
      this.analyser.smoothingTimeConstant = 0.8;

      let frequencyArray = new Uint8Array(this.analyser.frequencyBinCount);

      this.timer = setInterval(() => {
        this.analyser.getByteFrequencyData(frequencyArray);
        let start = [];
        let end = [];
        let max = Math.max(...frequencyArray);
        max = max > 100 ? max : 100;
        for (let i in frequencyArray) {
          let item = Math.floor((frequencyArray[i] * 100) / max);
          item = item < 10 ? 10 : item;
          end.push(item);
          start.unshift(item);
        }
        this.summary = start.concat(end);
      }, 50);
    },

    async stopSpy() {
      await this.stopAgentSpy()
      await this.closeSpyBox()
    },

    async closeSpyBox() {
      if (this.showInfoClient) this.showInfoClient = false
      this.showSpy = false
      const box = this.$refs.spyBox

      await new Promise((resolve) => setTimeout(() => {
        box.style.display = "none"
        this.speakWith = "onlyHear"
        clearInterval(this.timer);
        this.summary = new Array(64).fill(10);
        resolve()
      }, 500))
    },

    async changeSpeakWithOptionAndPosition(speakWith, index) {
      if (speakWith == "onlyHear") {
        this.setSpyParams({
          spy: "both",
          whisper: "none",
        })
        this.setMuted(true)
      }
      if (speakWith == "agent") {
        this.setSpyParams({
          spy: "both",
          whisper: "out",
        })
        this.setMuted(false)
      }
      if (speakWith == "everyone") {
        this.setSpyParams({
          spy: "both",
          whisper: "both",
        })
        this.setMuted(false)
      }

      await this.startAgentSpy({ agent_id: this.agent?.id })

      this.speakWith = speakWith
      let slider = this.$refs.speakWithSlider
      let buttonWidth = 96;
      slider.style.left = (buttonWidth * index) + "px";
    },

    async exitSpy() {
      await this.stopSpy();
      this.setSpiedAgent(null);
      this.setSelectedAgentToSpy(null);
    },
  },
};
</script>
<style lang="scss" scoped>

$box-width: 690px;
$box-height: 160px;

.box {
  left: calc(50% - #{$box-width} / 2);
  z-index: 1050;

  .spy-container {
    height: $box-height;
    width: $box-width;
    padding: 10px;

    .spy {
      border-radius: 0.625rem;
      padding: 1rem;

      .background-transition {
        height: 100%;
        left: 0;
        z-index: 1;
        transition: left 0.4s ease;
      }

      .fs-small-400 {
        font-size: $font-size-sm;
      }
    }
  }
}

</style>
