import axios from "axios";
import { loadLanguage } from "@/i18nHelper";

export default {
  fetchDetailsPartner({commit}, payload) {
    const {id} = payload;
    commit("startLoadingFlag", "fetchDetailsPartner");
    commit("setDetailsPartner", {});
    return new Promise((resolve, reject) => {
      axios
        .get(`/partners/${id}/dashboard/level`)
        .then(response => {
          commit("setDetailsPartner", response.data.data);
          resolve();
        })
        .catch(error => {
          commit('system/setErrorMessage', error.response.data, {root: true});
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "fetchDetailsPartner");
        });
    });
  },

  enterImpersonateMode({ commit, rootGetters }, payload) {
    commit("startLoadingFlag", "enterImpersonateMode");
    commit("auth/setImpersonate", false, { root: true });
    commit("auth/setImpersonateUserBackup", null, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .post(
          "/partners/company/" +
          payload.id +
          "/impersonate?include=company.partner,company.tags,permissions,teams.instances,ring_groups",
        )
        .then(async response => {
          commit("auth/setImpersonateUserBackup", rootGetters["auth/getUser"], { root: true });
          commit("auth/setUser", response.data.data, { root: true });
          commit("auth/setImpersonate", true, { root: true });
          await loadLanguage(rootGetters["auth/getLanguage"]);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "enterImpersonateMode");
        });
    });
  },

  listPartners({commit, getters}, id) {
    commit("startLoadingFlag", "listPartners");
    commit("setListPartners", []);
    return new Promise((resolve, reject) => {
      axios
        .get(`/partners/${id}/dashboard/companies`,
          {
            params: {
              page: getters.getPartnerDetailsPagination.current_page
            }
          })
        .then(response => {
          commit("setListPartners", response.data.data);
          commit("setPartnerDetailsPagination", response.data.meta.pagination);
          resolve();
        })
        .catch(() => reject())
        .finally(() => {
          commit("stopLoadingFlag", "listPartners");
        });
    });
  },

  setPartnerDetailsCurrentPage({commit}, page) {
    commit("setPartnerDetailsCurrentPage", page);
  },

  listCompanyDetails({commit}, id) {
    commit("startLoadingFlag", "companyDetails");
    commit("setCompanyDetails", []);
    return new Promise((resolve, reject) => {
      axios
        .get(`/partners/company/${id}/details`)
        .then(response => {
          const subscriptions = response.data.data.subscriptions.map(subscription => ({
            ...subscription,
            product_items: subscription.product_items.data.filter((product) => product.status === 'ACTIVE')
          }))
          commit("setCompanyDetails", {
            ...response.data.data,
            subscriptions
          });
          resolve();
        })
        .catch(() => reject())
        .finally(() => {
          commit("stopLoadingFlag", "companyDetails");
        });
    });
  },

  requestWithdraw({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/partners/withdraw", payload)
        .then(() => {
          commit("setRequestWithdraw", payload);
          resolve();
        })
        .catch((error) => {
          reject(error.response.data.debug.message);
        })
    })
  },

  fetchRevenue({commit}) {
    commit("startLoadingFlag", "fetchRevenue")
    return new Promise((resolve, reject) => {
      axios
        .get("/partners/revenue")
        .then((response) => {
          commit("setRevenue", response.data.data);
          resolve();
        })
        .catch((error) => {
          commit('system/setErrorMessage', error.response.data.detail, {root: true});
          reject();
        }).finally(() => {
        commit("stopLoadingFlag", "fetchRevenue")
      })
    })
  },

  fetchExtract({commit}, params) {
    commit("startLoadingFlag", "fetchExtract")

    return new Promise((resolve, reject) => {
      axios
        .get(`/partners/extract`, {
          params: params,
        })
        .then((response) => {
          commit("setExtract", response.data.data);
          resolve();
        })
        .catch((error) => {
          commit('system/setErrorMessage', error.response.data.detail, {root: true});
          reject();
        }).finally(() => {
        commit("stopLoadingFlag", "fetchExtract")
      })
    })
  }
};
