export default {
  getLoadingFlags: state => state.loadingFlags,
  getErrors: state => state.errors,
  getRoutes: state => state.routes,
  getRoutesPagination: state => state.routesPagination,
  getRoutesParams: state =>
    Object.assign(
      {
        page: state.routesPagination.current_page,
        per_page: state.routesPagination.per_page
      },
      state.routesFilters,
      { include: state.includes.join(",") }
    ),
  getSpreadsheet: state => state.spreadsheet
};
