<template>
  <div class="dropdown">
    <button
      class="btn tx-blue-300 text-nowrap"
      type="button"
      data-bs-toggle="dropdown"
      data-bs-auto-close="outside"
      aria-expanded="false"
    >
      {{ $t('edit_color') }}
    </button>
    <ul class="dropdown-menu dropdown-colors">
      <div class="m-0 row">
        <h6 class="col-12">
          {{ $t('select_color') }}
        </h6>
        <li
          v-for="(bg, i) in tagBackgrounds"
          :key="i"
          class="col-auto p-02"
        >
          <div
            class="option-border d-flex justify-content-center align-items-center m-0"
            :class="{'selected': modelValue === bg}"
            @click="$emit('update:modelValue', bg)"
          >
            <div
              class="option-color"
              :style="{'background-color': bg}"
            />
          </div>
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
import {tagBackgrounds} from "@/utils";

export default {
  name: "ColorPickerTags",

  emits: ['update:modelValue'],

  props: {
    modelValue: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      tagBackgrounds: tagBackgrounds,
    }
  }
}
</script>

<style scoped lang="scss">
.p-02 {
  padding: 0 2px;
}

.dropdown-colors {
  width: 272px;
  padding: 1rem;
}

.option-border{
  height: 1.875rem;
  width: 1.875rem;
  cursor: pointer;

  &:hover{
    border-radius: 50%;
    border: 2px solid #E1E9F4;
  }

  &.selected {
    border-radius: 50%;
    border: 2px solid #B1C2D9;
  }
}

.option-color{
  height: 1.375rem;
  width: 1.375rem;
  border-radius: 50%;
}
</style>