<template>
  <div
    class="quick-messages"
    v-show="show"
  >
    <div class="box d-flex flex-column text-truncate">
      <div class="tx-blue-300 fw-medium mb-1r">
        {{ $t('quick_messages') }}
      </div>
      <table
        v-if="quickMessages.length"
        class="w-100"
      >
        <tbody>
          <tr
            v-for="message in filtered"
            :key="message.id"
            class="quick-message"
            :class="{'selected': message.id === selectedItem?.id }"
            @click="$emit('sendQuickMessage', message.message)"
          >
            <td
              class="tx-text-gray quick-message-td"
              v-html="'/' + message.highlight"
            />
            <td>
              {{ message.message }}
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-else
        class="w-100 h-100 d-flex align-items-center justify-content-center gap-1-5r"
      >
        <img src="@/assets/img/quick-msg-pluzinha.svg">
        <div>
          <h5>{{ $t('omni_settings_tabs.quick_message.no_quick_message') }}</h5>
          <router-link
            v-if="!isAgent"
            class="mb-0 fs-6 tx-text-200"
            :to="'/manager/whatsapp/' + groupChannelId + '/edit'"
            v-html="$t('quick_message_click_here')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  emits: ["sendQuickMessage", "close"],

  mounted() {
    window.addEventListener("keydown", this.keydown);
    window.addEventListener("click", this.outsideClick);
  },

  beforeUnmount() {
    window.removeEventListener("keydown", this.keydown);
    window.removeEventListener("click", this.outsideClick);
  },

  data() {
    return {
      filtered: [],
      selectedItem: null,
    };
  },

  computed: {
    ...mapGetters("whatsapp/quick-message", {
      quickMessages: "getQuickMessages",
    }),
    ...mapGetters("auth", {
      isAgent: "isAgent"
    })
  },

  props: {
    show: {
      type: Boolean,
      default: true,
    },
    searchTerm: {
      type: String,
      default: "",
    },
    groupChannelId: {
      type: String,
      default: null
    }
  },

  watch: {
    show() {
      if (this.show) {
        this.filter()
      }
    },

    searchTerm() {
      if (!this.quickMessages.length) {
        this.$emit("close")
      }
      this.filter()
    },

    quickMessages(data) {
      if (data) this.filter();
    },
  },

  methods: {
    ...mapMutations("auth", ["trackEvents"]),

    outsideClick() {
      this.$emit("close");
    },

    keydown(e) {
      if (!this.show) {
        return
      }
      if (e.keyCode === 13) {
        e.preventDefault();
        const selectedMessage = this.selectedItem ? this.selectedItem.message : this.filtered[0]?.message

        if (selectedMessage) {
          this.selectedItem = null
          this.trackEvents('[Agent] Quick Message Use');
          this.$emit("sendQuickMessage", selectedMessage);
        }
        return
      }
      if (e.keyCode === 38) {
        e.preventDefault();
        this.selectPrevious();
        return
      }
      if (e.keyCode === 40) {
        e.preventDefault();
        this.selectNext();
      }
    },

    selectPrevious() {
      if (!this.selectedItem) {
        this.selectedItem = this.filtered[this.filtered.length - 1];
        return
      }
      const currentIndex = this.filtered.findIndex(message => message.id === this.selectedItem.id);
      if (currentIndex > 0) {
        this.selectedItem = this.filtered[currentIndex - 1];
      }
    },

    selectNext() {
      if (!this.selectedItem) {
        this.selectedItem = this.filtered[0];
        return
      }
      const currentIndex = this.filtered.findIndex(message => message.id === this.selectedItem.id);
      if (currentIndex < this.filtered.length - 1) {
        this.selectedItem = this.filtered[currentIndex + 1];
      }
    },

    filter() {
      if (!this.quickMessages.length) {
        return
      }
      let temp = [];
      for (let message of this.quickMessages) {
        if (message.shortcut.toLowerCase().includes(this.searchTerm.toLowerCase())) {
          let shortcut = message.shortcut.replace("/", "");
          message.highlight = shortcut.replace(
            this.searchTerm.toLowerCase(),
            "<strong class='highlight'>" + this.searchTerm.toLowerCase() + "</strong>"
          );
          temp.push(message);
        }
      }
      this.selectedItem = null
      temp.length ? this.filtered = temp : this.$emit("close")
    },
  },
};
</script>

<style lang="scss" scoped>
.quick-messages {
  position: relative;
}

.mb-1r {
  margin-bottom: 1rem;
}

.gap-1-5r {
  gap: 1.5rem;
}

.selected {
  background-color: #D2DDEC !important;
}

.box {
  top: -240px;
  left: 32px;
  height: 228px;
  width: calc(100% - 64px);
  border-radius: $whatsapp-border-radius;
  border: 1px solid #e0e6ed;
  padding: 1.5rem;
  background-color: white;
  position: absolute;
  font-weight: 300;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: $scrollbar-width !important;
    height: $scrollbar-width !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b0c5c2 !important;
    border-radius: $scrollbar-radius !important;
  }
}

.quick-message {
  height: 32px;
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid #e0e6ed !important;

  &:hover {
    background-color: #E1E9F4;
  }
}

.quick-message:last-child {
  border: none !important;
}

.quick-message-td {
  padding-left: 0.625rem;
  padding-right: 3rem;
}

@media only screen and (max-width: 1199.999px) {
  .quick-messages {
    .box {
      right: auto;
      top: auto;
      bottom: calc(100% + 50px);
      left: 50%;
      transform: translateX(-50%);
      max-width: calc(100vw - 48px);
      z-index: 1002;
    }
  }
}
</style>
