<script>
import CrmDashboardIndex from "@crm/pages/dashboard/crm-dashboard-index.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CrmDashboardView",
  components: {CrmDashboardIndex},

  created() {
    this.initializeFunnel();
  },
  methods: {
    ...mapActions('crm/funnels', ['fetchFunnels']),
    ...mapActions('crm/contacts', ['fetchUsersByTeam']),
    ...mapActions('crm/tour', ['fetchTourSteps']),

    initializeFunnel() {
      this.fetchFunnels(this.$route.params.id).then((currentFunnel) => {
        if (currentFunnel) {
          this.fetchUsersByTeam({teamId: currentFunnel.team.id, search: ""});
        }
      });
    },
  },

  computed: {
    ...mapGetters('crm/funnels', {
      currentFunnel: "getCurrentFunnel"
    }),
  }
}
</script>

<template>
  <div class="overflow-hidden">
    <crm-dashboard-index v-if="currentFunnel" />
  </div>
</template>

<style scoped lang="scss">

</style>