export default {
  methods: {
    formatLocaleDate(date, options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }) {
      const event = new Date(date);

      const locales = {
        pt: 'pt-BR',
        en: 'en-US',
        es: 'es-ES',
      }

      const locale = locales[this.$i18n.locale];

      return event.toLocaleDateString(locale, options)
    },
  },
}