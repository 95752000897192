<template>
  <div class="header p-0 m-0">
    <div class="header-body border-0">
      <div class="row align-items-center">
        <button
          v-if="redirectLink !== '' || showRedirectLinkButton"
          class="btn btn-rounded-circle btn-white d-flex justify-content-center align-items-center p-3"
          @click="this.$router.push(redirectLink)"
        >
          <i class="fa-regular fa-arrow-left" />
        </button>
        <div class="col">
          <small v-if="pretitle !== ''" class="p-0 m-0" style="color: #575778">
            {{ pretitle }}
          </small>
          <h1 class="header-title text-break mt-1 lh-lg lh-1 fw-medium">
            {{ title }}
          </h1>
        </div>
        <div v-if="buttonName !== '' && redirectLinkBtn !== ''" class="col-auto">
          <button class="btn btn-primary px-6" @click="this.$router.push(redirectLinkBtn)">
            {{ buttonName }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    redirectLink: {
      type: String,
      default: ""
    },
    pretitle: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: "",
      required: true
    },
    buttonName: {
      type: String,
      default: ""
    },
    redirectLinkBtn: {
      type: String,
      default: ""
    },
    showRedirectLinkButton: {
      type: Boolean,
      default: false
    }
  }
};
</script>
