import _ from "lodash";

export default {
  startLoadingFlags: (state, flag) => (state.loadingFlags[flag] = true),
  stopLoadingFlags: (state, flag) => (state.loadingFlags[flag] = false),
  clearErrors: state => {
    state.errors = {};
    state.errorMessage = "";
  },
  setErrors: (state, errors) => (state.errors = errors),
  setFilters: (state, filters) => (state.filters = filters),
  setAgentsReportList: (state, agentsReportList) => (state.agentsReportList = agentsReportList),
  setTotalReportList: (state, totalReportList) => (state.totalReportList = totalReportList),
  sortAgentsReportList: (state, params) => {
    const column_name = params.column;
    const direction = params.dir;
    state.agentsReportList = _.orderBy(
      state.agentsReportList,
      column_name,
      direction
    );
  },
};
