<template>
  <div class="main-content h-100 login">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div class="card col-sm-6 col-md-7 col-xl-5 login-card">
        <div class="card-body position-relative p-0">
          <button
            class="btn btn-back border d-flex justify-content-center align-items-center text-center"
            @click="this.$router.push('/login')"
          >
            <i class="far fa-chevron-left" />
          </button>
          <div
            v-if="!emailConfirmation"
            class="col-12 align-self-center align-items-center justify-content-center d-flex flex-column"
          >
            <div class="text-center margin-forms">
              <img
                class="img-fluid"
                src="@assets/img/logo.svg"
                style="height: 48px !important;"
              >
            </div>
            <div class="margin-forms align-self-center align-items-center justify-content-center d-flex flex-column">
              <h1 class="display-4 text-center mb-3 fw-normal margin-forms">
                Esqueci minha senha
              </h1>
              <p class="tx-text-gray col-7 text-center mb-0 fs-4">
                Confirme seu e-mail para receber o link para redefinir sua senha
              </p>
            </div>
            <form
              class="col-12"
              @submit.prevent="submit"
            >
              <div class="form-floating form-group">
                <input
                  id="email"
                  v-model="email"
                  :class="{
                    ' is-invalid': errors.email,
                  }"
                  :disabled="loadingFlags.requestPasswordChange"
                  class="form-control form-control-sm input-login margin-forms"
                  required
                  type="email"
                  @blur="inputFocused = false"
                  @focus="inputFocused = true"
                >
                <label
                  :class="{'label-on-border': email || inputFocused }"
                  class="floating-label"
                  for="email"
                > E-mail </label>
                <div class="invalid-feedback">
                  {{ errors.email && errors.email[0] }}
                </div>
              </div>
              <app-button
                :loading="loadingFlags.requestPasswordChange"
                class="w-100 reset-button margin-forms"
              >
                Redefinir senha
              </app-button>
            </form>
            <p class="col-10 text-center tx-text-gray">
              <span class="fw-bold">Atenção agente: </span>
              <span>Contate o gestor ou supervisor do sistema 3C PLUS
                na sua operação para receber ajuda.</span>
            </p>
          </div>
          <email-confirmation
            v-else
            :email="email"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

import EmailConfirmation from "./email-confirmation.vue";
import AppButton from "@/components/app-button.vue";

export default {
  data() {
    return {
      inputFocused: false,
      emailConfirmation: false,
      email: "",
    }
  },
  mounted() {
    this.email = this.userLogin
  },
  methods: {
    ...mapActions("auth", ["requestPasswordChange"]),

    submit() {
      this.requestPasswordChange({
        email: this.email,
        new_front: true,
        domain: window.location.host.split('.')[0]
      }).then(() => {
        this.emailConfirmation = true;
      });
    },
  },

  computed: {
    ...mapGetters("auth", {
      loadingFlags: "getLoadingFlags",
      errors: "getErrors",
      userLogin: "getUserLogin",
    }),
  },

  components: {
    EmailConfirmation,
    AppButton
  }
};
</script>
<style
  lang="scss"
  scoped
>
.login {
  height: 100vh;

  #senha {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  #inputGroupInvalid {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .login-card {
    box-shadow: 0px 4px 18px 0px rgba(34, 54, 77, 0.12);
    padding: 56px;
    z-index: 1050 !important;
    width: 504px !important;
  }

  .btn-back {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    position: absolute;
    top: -10px;
  }

  .margin-forms {
    margin-bottom: 32px !important;
  }

  .input-login {
    height: 40px;
    padding: 15px;
  }

  .input-login.form-control {
    background-color: #ffffff !important;
  }

  .floating-label {
    color: $color-text-200 !important;
  }

  .label-on-border {
    transform: translateY(-18px) !important;
    opacity: 1 !important;
    height: 20px !important;
    padding-top: 5px !important;
    background-color: white !important;
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
}
</style>
