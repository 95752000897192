import { AgentStatus } from "@/utils";

export default {
  loadingFlags: {
    startAgentsRealTimeUpdate: false
  },
  realTimeCampaignContext: null,
  /**
   * agent
   */
  updateAgentsRealTimeActive: false,
  agents: {},
  countAgentsStatuses: {
    [AgentStatus.OFFLINE]: 0,
    [AgentStatus.IDLE]: 0,
    [AgentStatus.ON_CALL]: 0,
    [AgentStatus.ACW]: 0,
    [AgentStatus.ON_MANUAL_CALL]: 0,
    [AgentStatus.ON_MANUAL_CALL_CONNECTED]: 0,
    [AgentStatus.ON_WORK_BREAK]: 0,
    [AgentStatus.ON_CONSULT]: 0,
    [AgentStatus.ON_MANUAL_CALL_ACW]: 0,
    [AgentStatus.MANUAL_CALL_CONNECTED]: 0,
    [AgentStatus.CONSULT_HOLD]: 0,
    [AgentStatus.CONSULT_CONNECTED]: 0
  },
  /**
   * calls
   */
  lastFinalizedCalls: [],
  updateCallsInRealTimeActive: false,
  callsWorking: {},
  countCallsWorkingStatuses: {
    total: 0,
    trying: 0,
    amd: 0,
    ura: 0,
    waiting: 0,
    in_progress: 0
  },
  mailings: {}
}
