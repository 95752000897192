<template>
  <action-input
    v-model="phoneField"
    mask="[
    '(##) ####-####',
    '(##) #####-####'
    ]"
    @update:model-value="updateField()"
    enable-delete-action
    @delete-action="destroyField()"
  />
</template>
<script>
import {mapActions} from "vuex";
import ActionInput from "@crm/pages/opportunity/components/action-input.vue";

export default {
  components: {
    ActionInput,
  },

  props: {
    phone: {
      type: Object,
      required: true
    },
    contactId: {
      type: Number,
      default: null
    },
  },

  data() {
    return {
      phoneField: "",
    }
  },

  created() {
    if (this.phone.number.slice(0, 2) === "55") {
      this.phoneField = this.phone.number.slice(2)
    } else this.phoneField = this.phone.number
  },

  methods: {
    ...mapActions('crm/contacts', ['updatePhoneChanel', 'destroyPhoneChanel']),

    updateField() {
      if (!this.phoneField) {
        if (this.phone.number.slice(0, 2) === "55") {
          this.phoneField = this.phone.number.slice(2)
        } else {
          this.phoneField = this.phone.number
        }
        return
      }

      this.updatePhoneChanel({
        channel_id: this.phone.id,
        contact_id: this.contactId,
        number: this.phoneField,
      })
    },

    destroyField() {
      this.destroyPhoneChanel({
        channel_id: this.phone.id,
        contact_id: this.contactId
      });
    },
  }
};
</script>

<style lang="scss">
.blue-hover:hover {
  color: $color-blue-300 !important;
}

.red-hover:hover {
  color: $color-red-400 !important;
}

.enable-input {
  background-color: #F0F4FA;

  & ~ span {
    display: flex !important;
    background-color: #F0F4FA !important;
  }
}

.contact-input {
  span {
    display: none;
  }

  input[disabled] {
    opacity: 1 !important;
  }

  input::placeholder {
    color: $color-text-solid-black;
  }

  &:hover {
    span {
      display: flex;
    }

    input, span {
      background-color: #F0F4FA !important;
    }
  }

  input:focus {
    background-color: #F0F4FA;
  }

  input:focus ~ span {
    display: flex;
    background-color: #F0F4FA;
  }
}

.btn-white-primary {
  aspect-ratio: 1/1;
  background-color: $color-text-light;
  color: $color-text-solid-black;
  border: none;

  &:hover, &:focus {
    background-color: $color-blue-300;
    color: $color-text-light;
  }
}
</style>
