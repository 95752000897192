import axios from "axios";

export default {
  searchIntervals({ commit }, payload) {
    commit("clearErrors");
    commit("startLoadingFlag", "searchIntervals");
    commit("setList", []);
    let params = {
      start_date: payload.startDate,
      end_date: payload.endDate,
      exceeded: payload.exceeded,
      order_by: payload.order_by,
      order_by_type: payload.order_by_type
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/campaigns/${payload.campaign_id}/agents/intervals`, { params }, `order_by_desc=calls`)
        .then(response => {
          commit("setList", response.data.data);
          resolve();
        })
        .catch(response => {
          commit("setAlert", {
            message: response.response.data.detail,
            type: "danger"
          });
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoadingFlag", "searchIntervals");
        });
    });
  },
  downloadTable({ commit }, payload) {
    commit("clearErrors");
    commit("startLoadingFlag", "downloadTable");
    let params = {
      start_date: payload.startDate,
      end_date: payload.endDate,
      campaign: payload.campaign_id,
      exceed: payload.exceeded,
      csv: payload.csv,
      email: payload.email
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/campaigns/${payload.campaign_id}/agents/intervals`, { params })
        .then(() => {
          commit(
            "system/setSuccessMessage",
            "success_message.generate_report",
            { root: true }
          );
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data });
        })
        .finally(() => {
          commit("stopLoadingFlag", "downloadTable");
        });
    });
  }
};
