<template>
  <button
    type="button"
    class="btn btn-secondary  bg-gray-blue-100 timer-btn"
    :class="timerColor"
    ref="timerTooltip"
  >
    <i class="fa-regular fa-stopwatch me-2" />
    {{ currentTime }}
  </button>
</template>

<script>
import {generateTooltip} from "@/utils";
import moment from "moment";
import {mapMutations} from "vuex";

export default {
  name: "WabaTimer",
  props: {
    currentChat: {type: Object, required: true}
  },
  data() {
    return {
      timerTooltip: null,
      end_message_cycle: null,
      currentTime: null,
      countdownInterval: null
    }
  },
  computed:{
    timerColor() {
      const endTime = this.end_message_cycle * 1000;
      const now = Date.now();
      const oneHour = 1000 * 60 * 60;

      return (endTime - now) <= oneHour ? 'danger-timer' : 'tx-text-gray';
    }
  },
  methods: {
    generateTooltip,
    ...mapMutations("whatsapp/chat", {
      setExpiredCycle: "setExpiredWaba"
    }),
    countdown() {
      const endTime = this.end_message_cycle * 1000;
      const now = Date.now();

      const distance = endTime - now;

      if (distance < 0) {
        this.currentTime = null;
        clearInterval(this.countdownInterval);
        this.setExpiredCycle(this.currentChat.id);
        return;
      }
      if (distance < 60000) {
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;
        this.currentTime = `${formattedSeconds}s`;
        return;
      }

      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

      const formattedHours = hours < 10 ? '0' + hours : hours;
      const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

      this.currentTime = `${formattedHours}h${formattedMinutes}`;
    },
    formatTime(timestamp) {
      return moment(timestamp * 1000).format("HH:mm");
    },
    isToday(timestamp) {
      return moment(timestamp * 1000).isSame(new Date(), 'day');
    }
  },
  mounted() {
    this.end_message_cycle = this.currentChat.waba_message_received.end_message_cycle;
    const formattedTime = this.formatTime(this.end_message_cycle);
    this.timerTooltip = this.generateTooltip(
        this.$t('timerTooltip', {
          formattedTime: formattedTime,
          day: this.isToday(this.end_message_cycle) ? this.$t('today').toLowerCase() : this.$t('tomorrow').toLowerCase()
        }),
        this.$refs.timerTooltip
    );
    this.countdown();
    this.countdownInterval = setInterval(this.countdown, 1000);

  },
  beforeUnmount() {
    clearInterval(this.countdownInterval);
  }
}
</script>

<style scoped lang="scss">
.timer-btn {
  border: 1px solid var(--Light-Stroke-Stroke-1, #E1E9F4);
}
.danger-timer{
 color: #DE033A
}
</style>
