import axios from "axios";

export default {
  listRoutes({ commit, getters }) {
    commit("clearErrors");
    commit("startLoading", "listRoutes");
    commit("setRoutes", []);

    return new Promise((resolve, reject) => {
      axios
        .get("/routes", { params: getters.getParams("telephony_routes") })
        .then(response => {
          commit("setPagination", {
            pagination: response.data.meta.pagination,
            flag: "telephony_routes",
          });
          commit("setRoutes", response.data.data);
          resolve();
        })
        .catch(response => {
          commit("setAlert", {
            message: response.response.data.detail,
            type: "danger",
          });
          response.response.status == 422 &&
          response.response.data.errors &&
          commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading", "listRoutes");
        });
    });
  },
  listRouteGroups({ commit, getters }) {
    commit("clearErrors");
    commit("startLoading", "listRouteGroups");
    commit("setRouteGroups", []);

    return new Promise((resolve, reject) => {
      axios
        .get("/route_groups", { params: getters.getParams("telephony_route_groups") })
        .then(response => {
          commit("setPagination", {
            pagination: response.data.meta.pagination,
            flag: "telephony_route_groups",
          });
          commit("setRouteGroups", response.data.data);
          resolve();
        })
        .catch(response => {
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading", "listRouteGroups");
        });
    });
  },
  setCurrentPage({ commit }, { page, flag }) {
    commit("setCurrentPage", { page, flag });
  },
  setRoutesFilters({ commit }, { search, flag }) {
    commit("setRoutesFilters", { search, flag });
  },
  updateUnproductiveCallsLimit({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading", "updateUnproductiveCallsLimit");

    return new Promise((resolve, reject) => {
      axios
        .put("/company/limit-unproductive-calls/change", payload.fields)
        .then(() => {
          commit("system/setSuccessMessage", "success_message.update_limit", { root: true });
          resolve({ message: "Limite atualizado com sucesso!" });
        })
        .catch(response => {
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading", "updateUnproductiveCallsLimit");
        });
    });
  },
  update({ commit, dispatch }, payload) {
    commit("clearErrors");
    commit("startLoading", "update");

    return new Promise((resolve, reject) => {
      axios
        .put("/routes", payload.fields)
        .then(() => {
          commit("system/setSuccessMessage", "success_message.update_routes", { root: true });
          dispatch("auth/me", null, { root: true });
          resolve();
        })
        .catch(response => {
          reject({ message: response.response.data.detail });
          response.response.status == 422 &&
          response.response.data.errors &&
          commit("setErrors", response.response.data.errors);
        })
        .finally(() => {
          commit("stopLoading", "update");
        });
    });
  },

  updateRouteGroup({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading", "updateRouteGroup");

    return new Promise((resolve, reject) => {
      axios
        .put(`/route_groups/${payload.id}`, {
          id: payload.id,
          group_type: payload.group_type,
          name: payload.name,
          route: payload.route,
        })
        .then(() => {
          resolve({ message: "Grupo atualizado com sucesso!" });
        })
        .catch(response => {
          reject({ message: response.response.data.detail });

          response.response.status == 422 &&
          response.response.data.errors &&
          commit("setErrorFlag", { flag: "updateRouteGroup", errors: response.response.data.errors });
        })
        .finally(() => {
          commit("stopLoading", "updateRouteGroup");
        });
    });
  },

  createRouteGroup({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading", "createRouteGroup");

    return new Promise((resolve, reject) => {
      axios
        .post("/route_groups", {
          group_type: payload.group_type,
          name: payload.name,
          route: payload.route,
        })
        .then(() => {
          resolve({ message: "Grupo atualizado com sucesso!" });
        })
        .catch(response => {
          reject({ message: response.response.data.detail });

          response.response.status == 422 &&
          response.response.data.errors &&
          commit("setErrorFlag", { flag: "createRouteGroup", errors: response.response.data.errors });
        })
        .finally(() => {
          commit("stopLoading", "createRouteGroup");
        });
    });
  },

  deleteRouteGroup({ commit }, payload) {
    commit("clearErrors", "deleteRouteGroup");
    commit("startLoading", "deleteRouteGroup");

    return new Promise((resolve, reject) => {
      axios
        .delete(`/route_groups/${payload.id}`)
        .then(() => {
          commit("system/setSuccessMessage", "success_message.delete_group", { root: true });
          resolve({ message: "Grupo removido com sucesso!" });
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading", "deleteRouteGroup");
        });
    });
  },
  updateExtendCpx({ commit, rootGetters }, payload) {
    commit("clearErrors");
    commit("startLoading", "updateExtendCpx");
    const companyId = rootGetters["auth/getUser"].company["company-data"].data.company_id;

    return new Promise((resolve, reject) => {
      axios
        .put(`/company/${companyId}/route/${payload.id}/extends-cxp`, {
          short_call_extends: payload.short_call_extends,
        })
        .then(() => {
          commit("system/setSuccessMessage", "success_message.update_extend_cpx", { root: true });
          resolve();
        })
        .catch(response => {
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading", "updateExtendCpx");
        });
    });
  },
  clearSpecifError({ commit }, flag) {
    flag && commit("setErrorFlag", { flag, errors: {} });
  },
};
