<template>
  <div class="container-fluid container-page">
    <div class="header p-0 m-0 container-header">
      <div class="header-body border-0 p-0">
        <div class="row align-items-start">
          <button
            class="btn btn-rounded-circle btn-white float-start ms-3"
            @click="$router.push('/manager/chatbot')"
          >
            <i class="fa-regular fa-arrow-left" />
          </button>
          <div class="col">
            <h2
              class="header-title text-break mt-1 lh-lg"
              v-if="chatbot?.name"
            >
              {{ chatbot.name }}
            </h2>
          </div>
          <div class="col-auto">
            <button class="btn status me-3 pe-0">
              {{ Object.values(loadingFlags).some(flag => flag === true)? $t('saving') + "..." : $t('saved') }}
            </button>
            <app-button
              v-if="isManager"
              color="btn btn-light me-3"
              @click="modalSettingsIsOpen = true"
            >
              <span class="fa-regular fa-gear" />
            </app-button>
            <app-button
              style="width: 76px"
              @click="$router.push('/manager/chatbot')"
              class="btn btn-light"
            >
              {{ $t('exit') }}
            </app-button>
          </div>
        </div>
      </div>
    </div>
    <div class="container-workflow">
      <workflow-nodes :actions="actions" />
    </div>
  </div>

  <offcanvas-select-channel
    :show="offcanvasSelectChannelIsOpen"
  />
  <offcanvas-new-action :show="offcanvasNewActionIsOpen" />
  <offcanvas-edit-action :show="offcanvasEditActionIsOpen" />
  <modal-settings
    :is-open="modalSettingsIsOpen"
    @close="modalSettingsIsOpen = false"
  />
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

import AppButton from "@components/app-button.vue";
import ModalSettings from "@chatbot/pages/workflow/components/modal-settings.vue";
import OffcanvasSelectChannel from "@chatbot/pages/workflow/components/offcanvas/offcanvas-select-channel.vue";
import OffcanvasNewAction
  from "@chatbot/pages/workflow/components/offcanvas/offcanvas-new-action/offcanvas-new-action.vue";
import OffcanvasEditAction
  from "@chatbot/pages/workflow/components/offcanvas/offcanvas-edit-action/offcanvas-edit-action.vue";
import WorkflowNodes from "@chatbot/pages/workflow/components/workflow-nodes.vue";


export default {
  name: "ChatbotWorkflow",

  components: {
    WorkflowNodes,
    OffcanvasEditAction,
    OffcanvasNewAction,
    OffcanvasSelectChannel,
    ModalSettings,
    AppButton,
  },

  data() {
    return {
      modalSettingsIsOpen: false,
      chatbotChanged: null,
      showExitModal: false,
      nextRoute: null,
    }
  },

  created() {
    this.fetchGroupChannels()
    window.Intercom('update', {
      "hide_default_launcher": true
    });
  },
  mounted() {
    if(!this.$route.params.id){
      this.modalSettingsIsOpen = true
    }
  },

  unmounted() {
    this.setZoom(1.0)
    window.Intercom('update', {
      "hide_default_launcher": false
    });
  },

  watch: {
    actions: {
      handler() {
        if (this.chatbotChanged === null) {
          this.chatbotChanged = false
          return
        }
        this.chatbotChanged = true
      }, deep: true
    }
  },

  computed: {
    ...mapGetters("chatbot/chatbots", {
      chatbot: "getChatbot",
      isNewChatbot: "getNewChatbot",
      deletedChatbot: "getDeletedChatbot",
    }),

    ...mapGetters("chatbot/actions", {
      actions: "getActions",
      loadingFlags: "getLoadingFlags",
      offcanvasEditActionIsOpen: "getOffcanvasEditActionIsOpen",
      offcanvasNewActionIsOpen: "getOffcanvasNewActionIsOpen",
      offcanvasSelectChannelIsOpen : "offcanvasSelectChannelIsOpen"
    }),

    ...mapGetters('auth', {
      isManager: "isManager"
    }),

  },

  methods: {
    ...mapMutations("chatbot/actions", [
      "setOffcanvasNewActionIsOpen",
      "setPrevActionId",
      "setZoom"
    ]),
    ...mapActions("system", ["showConfirmationMessage"]),
    ...mapActions("chatbot/actions", ["fetchGroupChannels"]),
    ...mapMutations("chatbot/chatbots",["setDeletedChatbot", "setNewChatbot", "setChatbot"]),
  },
  beforeUnmount() {
    this.setChatbot(null);
  }
}
</script>

<style scoped>
.container-page {
  height: 100vh;
  padding: 0 !important;
  position: relative;
  background-image: url("~@/assets/img/background-chatbot.svg");
}

.container-workflow{
  height: calc(100vh - 72px);
}

.container-header {
  width: 100%;
  padding:16px 24px!important;
  z-index: 1;
  background-color: white;
}
.status{
  pointer-events: none;
}
.btn{
  height: 40px;
}
.btn-rounded-circle {
  width: 40px;

  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
}
</style>
