import axios from "axios";

export default {
  /**
   * use the benefit of 3c Voice
   * @param {Object} state
   * @returns {void}
   */
  changeRoutesTo3cPlus({ commit }) {
      commit("startLoading", "changeRoutesTo3cPlus");
      return new Promise((resolve, reject) => {
        axios
          .put("/change-routes-to-3c-plus")
          .then(() => {
            commit("setTelephonyRouteStatus", {status: 'exclusive3cVoice'});
            resolve();
          })
          .catch(() => {
            reject();
          })
          .finally(() => {
            commit("stopLoading", "changeRoutesTo3cPlus");
          });
      });
  },
  /**
   *  loads the routes and, according to them, assigns its value to telephonyRouteStatus
   *  verifications:
   * - the benefit of 3cvoice is being used at the moment (exclusive3cVoice)
   * - never used 3c voice benefit and has external routes (neverExclusive3cVoice)
   * - already used 3c voice but returned to using external routes (exExclusive3cVoice)
   * - used before advertising only 3cplus routes (exclusiveBefore3cVoice)
   * -
   * @param {Object} state
   * @returns {void}
   */
  loadRoutes({ commit, getters, rootGetters }) {
    commit("startLoading", "loadRoutes")
    return new Promise((resolve, reject) => {
    axios
      .get("/routes", {
        params: getters.getParams
      })
      .then(response => {
        let { data } = response;
        const { company } = rootGetters["auth/getUser"];

        commit("setTelephonyRouteStatus", { routes: data?.data, company: company });
        resolve();
      })
      .catch(() => {
        reject();
      })
      .finally(() => {
        commit("stopLoading", "loadRoutes");
      });
    });
  }
}
