<template>
  <div
    v-if="loadingFlags.detailsPartners || loadingFlags.loadDetailsPartners"
    class="d-flex w-100"
  >
    <card-loading
      class="w-100"
      height="190"
    />
  </div>

  <div
    v-else
    class="col-12 col-lg-6 col-xl"
  >
    <section v-if="Object.keys(computedDetailsPartner).length ">
      <div class="card">
        <div class="card-body d-flex align-items-center px-4 py-5">
          <img
            :src="partnerInfo[0].image"
            :alt="$t('partner_badge')"
          >
          <div class="w-100 ps-3">
            <h1 class="mb-2">
              {{ $t("partner") }}
            </h1>
            <span class="text-medium">{{ $t("commission") }}: {{ floatPercentage }}%</span>
          </div>
        </div>
      </div>

      <!--
        Os níveis do partners vão ficar desabilitados por enquanto,
        por isso o v-if="false" no card
      -->
      <div
        v-if="false"
        class="card card-hover cursor-pointer"
        data-bs-target="#modalLevel"
        data-bs-toggle="modal"
      >
        <div class="card-body d-flex align-items-center px-4 py-5">
          <img
            :src="infoPartner(parseFloat(computedDetailsPartner.wallet)).image"
            alt="Imagem do seu nível"
          >
          <div class="w-100 ps-3">
            <h1
              class="m-0"
              style="padding-bottom: 8px"
            >
              {{ $t("level") }} {{ computedDetailsPartner.level }}
            </h1>
            <span>{{ progressBar(parseFloat(computedDetailsPartner.wallet)).percentage }}%</span>
            <div
              :class="['progress me-3', infoPartner(parseFloat(computedDetailsPartner.wallet)).progress]"
              style="height: 8px"
            >
              <div
                :aria-valuenow="progressBar(parseFloat(computedDetailsPartner.wallet))?.percentage"
                :class="['progress-bar', infoPartner(parseFloat(computedDetailsPartner.wallet)).level]"
                :style="{
                  width: progressBar(parseFloat(computedDetailsPartner.wallet)).percentage + '%'
                }"
                aria-valuemax="100"
                aria-valuemin="0"
                role="progressbar"
              />
            </div>
          </div>
          <div
            v-if="computedDetailsPartner.custom_benefit_percentage === false"
            class="d-flex flex-column justify-content-end align-items-center h-100"
            style="width: 35px"
          >
            <i
              class="fa-regular fa-chevron-right fs-4"
              style="color: #677C92"
            />
          </div>
        </div>
      </div>

      <div
        id="modalLevel"
        ref="modalLevel"
        aria-hidden="true"
        aria-labelledby="exampleModalLabel"
        class="modal fade"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content">
            <div class="modal-header border-0">
              <button
                aria-label="Close"
                class="btn-close"
                data-bs-dismiss="modal"
                type="button"
              />
            </div>
            <div class="modal-body container-fluid py-0">
              <h2>{{ $t("levels") }}</h2>
              <div
                class="card mt-4 px-4 py-3"
                style="height:86px"
              >
                <span class="text-muted fw-medium ">{{ $t("my_wallet") }}</span>
                <h2 class="mt-2">
                  {{ formatCurrency(parseFloat(computedDetailsPartner.wallet)) }} /{{ $t("month_lowercase") }}
                </h2>
              </div>

              <card-percentage-partner />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CardLoading from "@components/card-loading.vue";
import { Utils } from "@/utils";
import CardPercentagePartner from "./card-percentage-partner.vue";
import listPartners from "@admin/pages/3c-partners/partners/list-partners.vue";

export default {
  name: "CardLevelPartner",
  components: {
    CardLoading,
    CardPercentagePartner
  },

  data() {
    return {
      partnerInfo: [
        {
          level: "level-1",
          progress: "progress-1",
          image: require("@/assets/img/partner/level-1.svg")
        },
        {
          level: "level-2",
          progress: "progress-2",
          image: require("@/assets/img/partner/level-2.svg")
        },
        {
          level: "level-3",
          progress: "progress-3",
          image: require("@/assets/img/partner/level-3.svg")
        }
      ],
      modal: null
    };
  },

  methods: {
    getPercentage(value) {
      const L1_THRESHOLD = this.computedDetailsPartner.partner_l1_threshold;
      const L2_THRESHOLD = this.computedDetailsPartner.partner_l2_threshold;
      const L3_THRESHOLD = this.computedDetailsPartner.partner_l3_threshold;

      if (value <= L1_THRESHOLD) {
        return (value / L1_THRESHOLD) * 100;
      }

      if (value <= L2_THRESHOLD) {
        return ((value - L1_THRESHOLD) / (L2_THRESHOLD - L1_THRESHOLD)) * 100;
      }

      return ((value - L2_THRESHOLD) / (L3_THRESHOLD - L2_THRESHOLD)) * 100;
    },

    progressBar(value) {
      const percentage = this.getPercentage(value);

      const progress = Math.min(Math.floor(percentage), 100);

      return { percentage: progress };
    },

    formatCurrency: Utils.formatCurrency,

    infoPartner(wallet) {
      const L1_THRESHOLD = this.computedDetailsPartner.partner_l1_threshold;
      const L2_THRESHOLD = this.computedDetailsPartner.partner_l2_threshold;

      if (wallet <= L1_THRESHOLD) return this.partnerInfo[0];
      if (wallet <= L2_THRESHOLD) return this.partnerInfo[1];
      if (wallet > L2_THRESHOLD) return this.partnerInfo[2];
    }
  },

  computed: {
    listPartners() {
      return listPartners;
    },
    ...mapGetters("partner", {
      loadingFlags: "getLoadingFlags",
      detailsPartner: "getDetailsPartner"
    }),
    ...mapGetters("admin/partners", {
      adminDetailPartner: "getDetailsPartners",
      adminLoadingFlags: "getLoadingFlags"
    }),
    computedDetailsPartner() {
      if (Object.keys(this.detailsPartner).length === 0) {
        return this.adminDetailPartner;
      }
      return this.detailsPartner;
    },
    floatPercentage() {
      const percentage = this.computedDetailsPartner.benefit_percentage * 100;

      if (Number.isInteger(percentage)) {
        return percentage;
      } else {
        return Math.round((percentage + Number.EPSILON) * 100) / 100;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  height: 190px;
  min-width: 308px;

  img {
    width: 115.129px;
    height: 115.129px;
    -webkit-filter: drop-shadow(0px 0px 4px #12263f);
    filter: drop-shadow(0px 5px 5px rgba(34, 54, 77, 0.12));
  }

  h1 {
    font-family: "Random Grotesque Standard Medium";
    font-weight: 500;
  }

  .level-1 {
    background-color: $color-blue-300;
  }

  .level-2 {
    background-color: var(--color-pink-300);
  }

  .level-3 {
    background-color: $color-yellow-300;
  }

  .progress-1 {
    background-color: #2a57f21f;
  }

  .progress-2 {
    background-color: #ff1f7b1f;
  }

  .progress-3 {
    background-color: #ffbb3a1f;
  }
}

.card-hover:hover {
  background-color: #f0f4fa;
  border-color: #b1c2d9;
}
</style>
