export default {
  startLoadingFlag: (state, loadingFlag) => (state.loadingFlag[loadingFlag] = true),
  stopLoadingFlag: (state, loadingFlag) => (state.loadingFlag[loadingFlag] = false),

  startLoading: state => (state.loading = true),
  stopLoading: state => (state.loading = false),

  setListOnlineUsers: (state, listOnlineUsers) => (state.listOnlineUsers = listOnlineUsers),
  setListIpAllowed: (state, listIpAllowed) => (state.listIpAllowed = listIpAllowed),

  setListOfflineUsers: (state, listOfflineUsers) => (state.listOfflineUsers = listOfflineUsers),
  setRegister: (state, register) => (state.register = register),
  setUserData: (state, userData) => (state.userData = userData),
  setLineOfWork: (state, lineOfWork) => (state.lineOfWork = lineOfWork),
  setPromoter: (state, promoter) => (state.promoter = promoter),
  setCompanyRole: (state, companyRole) => (state.companyRole = companyRole),
  clearList: state => (state.list = []),
  setAlert: (state, alert) => {
    state.alert = alert;
  },
  setErrors: (state, errors) => (state.errors = errors),
  setErrorFlags: (state, { flag, errors }) => (state.errorFlags[flag] = errors),
  clearErrors: state => (state.errors = {}),
  clearErrorFlags: (state, flag) => (state.errorFlags[flag] = {}),
  setFilters: (state, filters) => {
    state.filters = filters;
  },
  setPaginationOnlineUsers: (state, paginationOnlineUsers) => {
    state.paginationOnlineUsers = paginationOnlineUsers;
  },
  setPaginationOfflineUsers: (state, paginationOfflineUsers) => {
    state.paginationOfflineUsers = paginationOfflineUsers;
  },
  setList: (state, list) => (state.list = list),
  setManagerDataSelect: (state, managerDataSelect) => (state.managerDataSelect = managerDataSelect),
  showDataUpdateModal: state => (state.dataUpdateModal = true),
  hideDataUpdateModal: state => (state.dataUpdateModal = false)
};
