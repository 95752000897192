<template>
  <div
    class="modal fade"
    id="modal3cVoice"
    tabindex="-1"
    aria-labelledby="modal3cVoice"
    aria-hidden="true"
    ref="changeRouteModal"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      style="max-width: 530px;"
    >
      <div class="modal-content text-center">
        <div class="modal-header border-0">
          <button
            type="button"
            class="btn-close position-absolute mt-2 fs-1 me-2 end-0"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div
          class="modal-body align-self-center"
          style="max-width: 370px;"
        >
          <img
            class="my-5"
            src="@/assets/img/rating.svg"
            alt="Ampulheta"
            width="150"
          >
          <h1>{{ $t("changing_route") }}...</h1>
          <p class="">
            {{
              $t("all_routes_will_be_transfer")
            }} <strong>{{ $t("settings") }} > {{ $t("telephony_capitalize") }}</strong>
          </p>
          <button
            @click="submit"
            :disabled="loading.changeRoutesTo3cPlus"
            class="btn btn-primary btn-md col-12 fw-bold mt-2"
            data-bs-dismiss="modal"
            style="background-color: #3057F2 !important;"
          >
            {{ version.buttonData }}
          </button>
          <p
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            class="text-secondary mt-3"
          >
            <u>{{ $t("later") }}</u>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Modal } from "bootstrap";

export default {

  props: {
    version: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters("manager/voice_3c", {
      loading: "getLoadingFlag",
    }),
  },
  mounted() {
    this.modal = new Modal(this.$refs.changeRouteModal);
  },

  methods: {
    ...mapActions("manager/voice_3c", ["changeRoutesTo3cPlus"]),

    submit() {
      this.changeRoutesTo3cPlus()
          .then(() => {
            this.$router.push("/manager/3c-voice")
            this.modal.hide()
          })
    },
  },
}

</script>

<style scoped lang="scss">
.modal {
  z-index: 9999 !important;
  background: #00000033;
}
</style>
