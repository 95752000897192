export default {
  loadingFlags: {
    findCallsStatusCountPerTime: false,
    findTotalCallsFromLastDayPerCampaign: false,
    findCallsReports: false,
    findFeedbackByCallId: false,
    applyFeedback: false,
    updateFeedback: false,
    loadHangupCausesDataSelect: false,
    loadListDataSelect: false,
    loadRoutesDataSelect: false,
    loadStatusesDataSelect: false,
    downloadTable: false,
    getCallsTotal: false,
    loadCallById: false,
    fetchAudioBlob: false
  },
  errorsFlag: {
    downloadTable: {}
  },
  downloadTableParams: {},
  loadingContext: '',
  errors: {},
  callsReports: [],
  feedbackRegister: null,
  callsStatusCountPerTime: {},
  totalCallsFromLastDayPerCampaign: {},
  hangupCausesDataSelect: [],
  statusesDataSelect: [],
  filters: {},
  totalFilters: {},
  include: ['campaign_rel'],
  pagination: {
    current_page: 0
  },
  listDataSelect: [],
  totalCallsData: [],
  loadedCall: null,
}
