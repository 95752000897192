<template>
  <div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <header-component
            :title="$t('telephony_capitalize')"
            :pretitle="$t('settings_uppercase')"
            redirect-link="/manager/voice-settings"
          />
          <div>
            <ul
              id="nav-tab"
              class="nav nav-tabs"
              role="tablist"
            >
              <li class="nav-item">
                <button
                  id="nav-routes-tab"
                  ref="tab-routes"
                  aria-controls="routes"
                  aria-selected="true"
                  class="nav-link active"
                  data-bs-target="#routes"
                  data-bs-toggle="tab"
                  role="tab"
                >
                  {{ $t("routes_capitalize") }}
                </button>
              </li>
              <li class="nav-item">
                <button
                  id="nav-routes-group-tab"
                  ref="tab-routes-group"
                  aria-controls="routes-group"
                  aria-selected="false"
                  class="nav-link"
                  data-bs-target="#routes-group"
                  data-bs-toggle="tab"
                  role="tab"
                >
                  {{ $t("routes_group") }}
                </button>
              </li>
            </ul>
          </div>

          <div class="tab-content">
            <div
              id="routes"
              class="tab-pane fade show active"
              role="tabpanel"
            >
              <routes-tab
                v-model:fields-mobile="fields.mobile"
                v-model:fields-land-line="fields.landline"
                :is-brazil="isBrazil"
              />
              <routes-table />
            </div>
            <div
              id="routes-group"
              class="tab-pane fade"
              role="tabpanel"
            >
              <advanced-options
                @limit-calls-updated="updateLimitCalls"
              />
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-6">
              <app-button
                class="fw mb-4"
                :loading="loading.update"
                @click="submit()"
              >
                {{ $t("update_omni") }}
              </app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import appButton from "@/components/app-button.vue";
import AdvancedOptions from "./components/advanced-options.vue";
import HeaderComponent from "@/components/header-component.vue";
import RoutesTab from "@dialer/manager/pages/settings/telephony/components/routes-tab.vue";
import RoutesTable from "@dialer/manager/pages/settings/telephony/components/routes-table.vue";

export default {
  data() {
    return {
      fields: {
        landline: {},
        mobile: {},
      },
      limit_unproductive_calls: 0,
    };
  },
  components: {
    RoutesTable,
    RoutesTab,
    appButton,
    AdvancedOptions,
    HeaderComponent,
  },
  computed: {
    ...mapGetters("manager/telephony", {
      loading: "getLoadingFlags",
    }),
    ...mapGetters("auth", {
      user: "getUser",
    }),
    isBrazil() {
      return this.user.company["company-data"].data.country.country_code === 55;
    },
  },
  methods: {
    ...mapActions("manager/telephony", ["update", "updateUnproductiveCallsLimit"]),
    ...mapActions("manager/voice_3c", ["loadRoutes"]),
    ...mapActions("manager/filters", ["findFilters", "setFiltersIncludes"]),
    ...mapMutations("manager/telephony", ["setRoutes", "setUser", "setRouteGroups"]),
    ...mapMutations("manager/voice_3c", ["setIncludes"]),
    ...mapMutations("auth", ["trackEvents"]),

    submit() {
      let landline_id, landline_type, mobile_id, mobile_type;
      let international_id, international_type;
      let fields = {};

      if (this.isBrazil) {
        landline_id = this.fields.landline.id;
        landline_type = this.fields.landline.group_type ? "group" : "route";
        mobile_id = this.fields.mobile.id;
        mobile_type = this.fields.mobile.group_type ? "group" : "route";
        fields = {
          landline_id,
          landline_type,
          mobile_id,
          mobile_type,
        };
        this.trackEvents("[Manager] Change national route parameter");
      } else {
        international_id = this.fields.landline.id;
        international_type = this.fields.landline.group_type ? "group" : "route";
        fields = {
          international_id,
          international_type,
        };
        this.trackEvents("[Manager] Change international route parameter");
      }
      this.update({
        fields,
      })
          .then(() => {
            this.setIncludes(["campaigns-landline-routes", "campaigns-mobile-routes", "company"]);
            this.loadRoutes();
          });

      this.updateUnproductiveCallsLimit({
        fields: {
          limit_unproductive_calls: this.limit_unproductive_calls,
        },
      });
    },
    updateLimitCalls(limit) {
      this.limit_unproductive_calls = limit;
    },
  },
  mounted() {
    this.setFiltersIncludes(["route_groups", "routes"]);
    this.findFilters();
  },
  unmounted() {
    this.setRoutes([]);
    this.setUser(null);
    this.setRouteGroups([]);
  },
};
</script>
