<template>
  <li
    class="list-group-item d-flex justify-content-between align-items-center border-0 py-2 cursor-pointer rounded"
    :class="{ 'disabled': loading }"
    @click="scrollToMessage"
  >
    <div class="col d-flex flex-column px-2">
      <div class="fs-5 overflow-ellipsis list">
        <span class="ml-3">{{ formatedTime }}</span>
        ·
        <span
          v-if="message.owner"
          class="mr-3"
        >{{ message.owner.name }}
        </span>
      </div>
      <div class="d-flex align-items-center px-1">
        <i
          v-if="message.ack === 'read' || message.ack === 'played'"
          class="fa-kit text-small-2 fa-doble-check text-primary my-0"
        />
        <i
          v-else-if="message.ack === 'device'"
          class="fa-kit text-small-2 fa-doble-check my-0"
        />
        <i
          v-else-if="message.ack === 'server'"
          class="fa-regular text-small-2 fa-check my-0"
        />
        <i
          v-else-if="!message.ack"
          class="fa-regular text-small-2 fa-clock my-0"
        />
        <h4
          v-if="message.body"
          class="fs-5 fw-medium my-0 mx-3 overflow-hidden text-truncate"
          v-html="highlightedSentence"
        />
      </div>
    </div>
    <div
      v-if="loading"
      class="pe-5"
    >
      <div
        class="spinner-border text-primary spinner-border-sm"
        role="status"
      >
        <span class="visually-hidden">{{ $t('loading_capitalize') }}...</span>
      </div>
    </div>
  </li>
</template>

<script>
import {mapMutations} from "vuex";
import {formatTimestamp, Utils} from "@/utils";
import { messagesOmnichannel } from "@/mixins/messagesOmnichannel";

export default {
  mixins: [messagesOmnichannel],

  emits: ["close-canvas"],

  props: {
    search: {type: String, default: ""},
    message: {type: Object, required: true},
  },

  data (){
    return {
      loading: false
    }
  },

  computed: {
    formatedTime() {
      const lastMessageUnix = this.message.time;
      const lastMessageDate = new Date(lastMessageUnix * 1000);

      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      if (lastMessageDate >= today) {
        return formatTimestamp(lastMessageUnix, 'HH:mm');
      }

      if (lastMessageDate >= yesterday) {
        return `${this.$t('yesterday_capitalize')} - ${formatTimestamp(lastMessageUnix, 'HH:mm')}`;
      }

      if (lastMessageDate.getFullYear() < today.getFullYear()) {
        return formatTimestamp(lastMessageUnix, 'DD/MM/YYYY - HH:mm');
      }

      return formatTimestamp(lastMessageUnix, 'DD/MM - HH:mm');
    },

    highlightedSentence (){
      const regex = new RegExp(this.search, "gi");
      const highlightedText = this.highlightSearchResults();
      return highlightedText.replace(
        regex,
        '<span class="text-primary">$&</span>'
      );
    },
  },

  methods: {
    formatTimestamp: Utils.formatTimestamp,
    ...mapMutations("whatsapp/chat", ["setMessageFound"]),

    highlightSearchResults () {
      const message = this.message.body;
      if (message.length <= 45) {
        return message
      }
      else {
        const position = message.indexOf(this.search);
        const sentences = message.split(this.search, position);

        if (sentences && sentences.length > 1){
          const start = sentences[0];
          const end = sentences[1];
          return (
            start.slice(start.length - this.search.length, position) +
            this.search +
            end.slice(0, this.search.length)
          );
        } else {
          return message
        }
      }
    },

    scrollToMessage() {
      this.loading = true
      this.goToMessage(this.message, this.scrollToProtocolAnchor)
    },

    scrollToProtocolAnchor () {
      const messageId = this.message.internal_id;
      const element = document.getElementById(messageId)
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest"
        })
      }
      this.$emit("close-canvas");
      this.setMessageFound(this.message)
      this.loading = false
    }
  },
};
</script>

<style scoped>
.list-group-item:hover {
  background-color: #e1e9f4;
}
</style>
