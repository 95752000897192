<template>
  <div class="container-warmup">
    <div class="container-fluid">
      <header-component :title="$t('public')">
        <template #buttons>
          <app-button
            v-if="!handlerClick"
            @click="handlerButtons"
            style="width: 300px"
          >
            {{ $t('early_access') }}
          </app-button>
          <app-button
            v-else
            disabled
            style="width: 300px"
          >
            {{ $t('access_requested') }}
          </app-button>
        </template>
      </header-component>

      <div class="d-flex bg-img-audience">
        <div class="d-flex flex-column justify-content-center container-illustration">
          <img
            src="@assets/img/3czinhos.svg"
            style="width: 85px; margin-bottom: 40px"
          >
          <h1 style="margin-bottom: 1rem">
            {{ $t('mailing_management_evolution') }} <span class="fal fa-wand-magic-sparkles fw-medium" />
          </h1>
          <h4
            style="margin-bottom: 40px"
            class="fw-normal"
            v-html="$t('enrich_or_generate_new_audience')"
          />
          <app-button
            v-if="!handlerClick"
            @click="handlerButtons"
            style="width: 300px"
          >
            {{ $t('early_access') }}
          </app-button>
          <app-button
            v-else
            disabled
            style="width: 300px"
          >
            {{ $t('access_requested') }}
          </app-button>
        </div>
      </div>

      <div>
        <h2 style="margin-bottom: 1.5rem">
          {{ $t('what_the_tool_offers') }}:
        </h2>

        <div class="row">
          <card-warmup
            :title="$t('enrich_audience')"
            :text="$t('enrich_audience_description')"
            icon-class="fal fa-wand-magic-sparkles fw-medium tx-yellow-300"
          />
          <card-warmup
            :title="$t('generate_your_base')"
            :text="$t('generate_your_base_description')"
            icon-class="fal fa-bullseye-pointer fw-medium tx-blue-300"
          />
          <card-warmup
            :title="$t('segment_your_audience')"
            :text="$t('segment_your_audience_description')"
            icon-class="fal fa-scissors fw-medium tx-pink-300"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardWarmup from "@/components/card-warmup.vue";
import HeaderComponent from "@/components/header-component.vue";
import AppButton from "@/components/app-button.vue";
import { mapMutations } from 'vuex';

export default {
  name: "WarmupAudience",
  components: {AppButton, HeaderComponent, CardWarmup},
  data() {
    return {
      handlerClick: false
    }
  },
  methods: {
    ...mapMutations("auth", ["trackEvents"]),

    handlerButtons() {
      this.handlerClick = !this.handlerClick
      this.trackEvents(`[Manager] Request access warmup audience`)
    }
  },
}
</script>

<style scoped>
.container-warmup{
  max-width: 1670px;
  margin: 0 auto;
}

.container-illustration{
  width: 415px;
  margin-left: 40px;
  margin-top: 40px;
}

.bg-img-audience {
  background-image: url("~@assets/img/bg-warmup-audience.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  margin-bottom: 1.5rem;
  height: 340px;

  @media(min-width:  1280px){
    aspect-ratio: 2.85/1;
    height: auto;
  }
}
</style>
