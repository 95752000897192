export default {
  loadingFlags: {
    fetchWorkBreakGroups: false,
    create: false,
    update: false,
    remove: false,
    findWorkBreakGroupById: false,
    findIntervalsByWorkBreakGroupId: false,
    updateInterval: false,
    addInterval: false,
    removeInterval: false,
    startWorkBreak: false,
  },
  errorMessage: "",
  errors: {},
  errorsInterval: {},
  paginator: {},
  workBreakGroups: [],
  workBreakGroup: null,
  intervals: [],
  intervalsPagination: null,
};
