<template>
  <crm-page-loading
    v-if="loadingFlags.fetchFunnels"
    title="Carregando suas oportunidades"
  />
  <empty-manager v-else-if="isManager" />
  <empty-supervisor v-else />
</template>

<script>
import {mapGetters} from 'vuex';
import CrmPageLoading from "@crm/components/shared/crm-page-loading.vue";
import EmptySupervisor from "@crm/pages/empty-funnel/components/empty-supervisor.vue";
import EmptyManager from "@crm/pages/empty-funnel/components/empty-manager.vue";

export default {
  components: {
    EmptyManager,
    EmptySupervisor,
    CrmPageLoading
  },

  computed: {
    ...mapGetters('auth', {
      isManager: "isManager",
    }),
    ...mapGetters("crm/funnels", {
      loadingFlags: "getLoadingFlags"
    }),
  },
}
</script>
