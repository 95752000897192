<template>
  <div class="card">
    <div
      class="card-header"
      style="border-bottom: none"
    >
      <div class="row align-items-center">
        <div class="col">
          <h4 class="card-header-title">
            {{ $t(title) }}
          </h4>
        </div>
      </div>
    </div>
    <div class="card-body">
      <canvas
        class="chart-canvas"
        ref="chart"
        height="300"
        style="max-height: 300px;"
      />
    </div>
  </div>
</template>


<script>
import { Chart } from "chart.js";
import { mapGetters } from "vuex";
import moment from "moment";

export default {

  /**
   *
   */
  mounted() {
    new Chart(this.$refs.chart, this.chartOptions);
  },

  /**
   *
   */
  data() {
    return {
      labels: [],
      data: {
        failed: [],
        abandoned: [],
        not_answered: [],
        answered: [],
        abandoned_due_amd: [],
        not_answered_due_progress_amd: [],
      },
      chartOptions: {
        type: "line",
        data: {
          labels: [],
          datasets: [
            {
              label: this.$t('connected'),
              data: [],
              backgroundColor: "#5cb85c",
              borderColor: "#5cb85c",
              radius: 0,
              fill: true,
              borderWidth: 1,
            },
            {
              label: this.$t('abandoned_label'),
              data: [],
              backgroundColor: "#eb5545",
              borderColor: "#eb5545",
              radius: 0,
              fill: true,
              borderWidth: 1,
            },
            {
              label: this.$t('post_call_ivr'),
              data: [],
              backgroundColor: "#ce4c35",
              borderColor: "#ce4c35",
              radius: 0,
              fill: true,
              borderWidth: 1,
            },
            {
              label: this.$t('pre_call_ivr'),
              data: [],
              backgroundColor: "#ce4c35",
              borderColor: "#ce4c35",
              radius: 0,
              fill: true,
              borderWidth: 1,
            },
            {
              label: this.$t('not_answered'),
              data: [],
              fill: true,
              backgroundColor: "#bdbdbd",
              borderColor: "#bdbdbd",
              radius: 0,
              borderWidth: 1,
            },
            {
              label: this.$t('fail'),
              data: [],
              fill: true,
              backgroundColor: "#dddddd",
              borderColor: "#dddddd",
              radius: 0,
              borderWidth: 1,
            },
          ],
        },
        options: {
          layout: {
            padding: {
              right: 0,
              top: 0,
              left: 0,
              bottom: 0,
            },
          },
          elements: {
            point: {
              radius: 0,
            },
            line: {
              tension: 0.5,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
          responsive: true,
          scales: {
            x: {
              suggestedMin: 0,
              suggestedMax: 10,
              display: false,
              grid: {
                color: false,
                borderColor: false,
              },
              ticks: {
                display: true,
              },
            },
            y: {
              suggestedMin: 0,
              suggestedMax: 10,
              display: false,
              stacked: true,
              grid: {
                color: false,
                borderColor: false,
              },
              ticks: {
                display: true,
              },
            },
          },
        },
      },
    };
  },

  /**
   *
   */
  watch: {
    /**
     *
     */
    callsStatusCountPerTime: {
      handler(data) {
        if (data[this.campaignId] && data[this.campaignId].length > 0) {
          this.prepareLabels();
          this.prepareData(data[this.campaignId]);
          this.updateChart();
        }
      },
      deep: true,
    },
  },

  /**
   *
   */
  computed: {
    ...mapGetters("manager/calls-report", {
      callsStatusCountPerTime: "getCallsStatusCountPerTime",
    }),
  },

  /**
   *
   */
  props: {
    campaignId: {
      type: Number,
      default: 0,
    },
    /**
     *
     */
    title: {
      type: String,
      default: "connection_graph",
    },

    /**
     *
     */
    startDate: {
      type: String,
      default: "2022-01-01 00:00:00",
    },

    /**
     *
     */
    endDate: {
      type: String,
      default: "2022-01-08 00:00:00",
    },
  },

  /**
   *
   */
  methods: {

    /**
     * fix recursive bug when instance as data()...*
     */
    getChartIntance() {
      return Chart.getChart(this.$refs.chart);
    },

    /**
     *
     */
    prepareLabels() {
      this.labels = [];
      let date = moment(this.startDate);
      let endDate = moment(this.endDate);
      for (date; date < endDate; date = date.add(1, "hours")) {
        this.labels.push(this.formatDate(date));
      }
    },

    /**
     *
     */
    updateChart() {
      let chart = this.getChartIntance();
      chart.data.labels = this.labels;
      chart.data.datasets[0].data = this.data.answered;
      chart.data.datasets[1].data = this.data.abandoned;
      chart.data.datasets[2].data = this.data.abandoned_due_amd;
      chart.data.datasets[3].data = this.data.not_answered_due_progress_amd;
      chart.data.datasets[4].data = this.data.not_answered;
      chart.data.datasets[5].data = this.data.failed;
      chart.update();
    },

    /**
     *
     */
    clearData() {
      this.data.failed = [];
      this.data.abandoned = [];
      this.data.not_answered = [];
      this.data.answered = [];
      this.data.abandoned_due_amd = [];
      this.data.not_answered_due_progress_amd = []
    },

    /**
     *
     */
    prepareData(data) {
      this.clearData();
      let temp = {};
      for (let item of data) temp[this.formatDate(moment(item.date))] = item;

      for (let label of this.labels) {
        if (temp[label]) {
          for (let key in this.data) {
            this.data[key].push(temp[label][key]);
          }
        } else {
          for (let key in this.data) {
            this.data[key].push(0);
          }
        }
      }
    },

    /**
     *
     */
    formatDate(date) {
      return date.format("DD/MM/YYYY HH[h]");
    },
  },
};
</script>
