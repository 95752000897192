<template>
  <div class="d-flex gap-2">
    <search-input
      :focus="focusSearchInput"
      class="flex-grow-1"
      data-test="qualificationSearch-input"
      size-class="text-base"
      @out="focusSearchInput = false"
      @search="search"
    />
    <app-button
      :color=" showNotesInput ? 'btn-primary' : 'btn-white' "
      class="border-0 shadow-none"
      data-test="showNotesInput-button"
      style="min-width: 40px;"
      @click="toggleNoteInput"
    >
      <span
        :class="showNotesInput ? 'fe-x' : 'fe-message-square'"
        class="text-muted fe"
      />
    </app-button>
  </div>

  <textarea
    v-if="showNotesInput"
    ref="qualificationNote"
    v-model="qualificationNote"
    class="form-control shadow-none w-100 mt-2"
    rows="3"
  />

  <div class="qualifications-container">
    <template
      v-for="(qualification, index) in filteredQualifications"
      :key="qualification.id"
    >
      <div
        class="d-flex align-items-center gap-3 item"
        @click="handleClick(qualification)"
      >
        <div>
          <aside
            :style="'background-color:' + qualification.color"
            class="qualification-circle"
          />
        </div>
        <p
          :title="qualification.name"
          class="line-clamp flex-grow-1 m-0"
        >
          {{ qualification.name }}
        </p>
        <span
          v-if="qualification.allow_schedule"
          class="fe fe-calendar text-muted"
        />
      </div>
      <hr
        v-if="index !== filteredQualifications.length - 1"
        class="my-0 mx-3"
      >
    </template>
  </div>
</template>

<script>
import AppButton from "@/components/app-button"
import SearchInput from "@/components/search-input"
import _ from "lodash";

import {mapGetters} from "vuex";

export default {
  emits: ["selectQualification"],

  data() {
    return {
      searchTerm: "",
      qualificationNote: "",
      showNotesInput: false,
      focusSearchInput: false
    }
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    }
  },

  mounted() {
    this.focusSearchInput = true;
  },

  components: {
    AppButton,
    SearchInput
  },

  computed: {
    ...mapGetters("agent/qualification", {
      qualifications: "getList",
    }),

    filteredQualifications() {
      return this.qualifications.filter(qualification => {
        return this.searchTerm === '' || qualification.name.toLowerCase().includes(this.searchTerm.toLowerCase());
      });
    }
  },

  methods: {
    handleClick(qualification) {
      const qualificationInfos = {
        qualification: qualification,
        note: this.qualificationNote
      };
      this.$emit("selectQualification", qualificationInfos);
    },

    search: _.debounce(function (term) {
      this.searchTerm = term;
    }, 300),

    toggleNoteInput() {
      this.showNotesInput = !this.showNotesInput;
      this.focusSearchInput = false;
      if (this.showNotesInput) this.$nextTick(() => this.$refs.qualificationNote.focus());
    }
  },

  watch: {
    show(data) {
      if (data) this.focusSearchInput = true;
    }
  }
}
</script>

<style
  lang="scss"
  scoped
>
textarea {
  resize: vertical;
  max-height: 200px;
}

.qualification-circle {
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
}

.line-clamp {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.qualifications-container {
  border-radius: $border-radius;
  overflow-y: scroll;
}

.item {
  border-radius: $border-radius;
  cursor: pointer;
  padding: 1rem 0.75rem;
  transition: background-color .2s;
}

.item:hover {
  background-color: $color-gray-blue-300;
}
</style>
