import axios from "axios";

export default {
  clearErrors({ commit }) {
    commit("clearErrors");
  },

  /**
   *
   * @param {*} param0
   * @returns
   */
  loadDataSelect({ commit }, payload) {
    commit('startLoading')
    commit('setDataSelect', []);
    return new Promise((resolve, reject) => {
      //TODO use filters
      axios.get('/ivr_after_call', {
          params: {
            per_page: payload.per_page
          }
        }).then((response) => {
          commit('setDataSelect', response.data.data);
          resolve();
        }).catch((response) => {
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('stopLoading')
        });
    });
  },

  getList({ commit, getters }) {
    commit('startLoading')
    commit('setList', []);

    return new Promise((resolve, reject) => {
      axios.get('/ivr_after_call', { params: getters.getParams }).
        then((response) => {
          commit('setPagination', response.data.meta.pagination);
          resolve();
          commit('setList', response.data.data);
        }).catch((response) => {
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('stopLoading')
        });
    });
  },

  createIvrAfterCall({ commit }, payload) {
    commit("startLoadingFlag", "createIvrAfterCall");

    return new Promise((resolve, reject) => {
      axios.post('/ivr_after_call', payload.formData).
        then(() => {
          commit("system/setSuccessMessage", "success_message.create_ivr", { root: true});
          resolve();
        }).catch((error) => {
          error.response.status === 422 && commit("setErrors",
            { errorFlag: "createIvrAfterCall", errors: error.response.data.errors });

          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        }).finally(() => {
          commit("stopLoadingFlag", "createIvrAfterCall");
        });
    });
  },

  editIvrAfterCall({ commit }, payload) {
    commit("startLoadingFlag", "editIvrAfterCall");

    return new Promise((resolve, reject) => {
      axios.post(`/ivr_after_call/${payload.id}`, payload.formData)
        .then(() => {
          commit("system/setSuccessMessage", "success_message.update_ivr", { root: true})
          resolve();
        }).catch((error) => {
          error.response.status === 422 && commit("setErrors",
              { errorFlag: "editIvrAfterCall", errors: error.response.data.errors });

          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        }).finally(() => {
          commit("stopLoadingFlag", "editIvrAfterCall");
      });
    });
  },

  removeIvrAfterCall({ commit }, payload) {
    commit('startLoadingFlag', 'removeIvrAfterCall')
    return new Promise((resolve, reject) => {
      axios.delete(`/ivr_after_call/${payload.id}`, payload.formData)
        .then(() => {
          commit("system/setSuccessMessage", "success_message.remove_ivr", { root: true})
          resolve();
        }).catch((error) => {
          error.response.status === 422 && commit("setErrors",
              { errorFlag: "editIvrAfterCall", errors: error.response.data.errors });

          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        }).finally(() => {
          commit('stopLoadingFlag', 'removeIvrAfterCall')
        });
    });
  },

  loadIvrAfterCallById({ commit }, payload) {
    commit("startLoadingFlag", "loadIvrAfterCallById");
    commit('setRegister', null);
    return new Promise((resolve, reject) => {
      axios.get('/ivr_after_call/' + payload.id).
        then((response) => {
          commit('setRegister', response.data.data);
          resolve();
        }).catch((error) => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        }).finally(() => {
          commit("stopLoadingFlag", "loadIvrAfterCallById");
        });
    });
  },

  createCriteria({ commit }, payload) {
    commit("startLoadingFlag", "createCriteria");

    return new Promise((resolve, reject) => {
      axios.post(`/ivr_after_call/${payload.id}/criteria`, payload.formData).
        then(() => {
          commit("system/setSuccessMessage", "success_message.create_criteria", { root: true})
          resolve();
        }).catch((error) => {
          error.response.status === 422 && commit("setErrors",
            { errorFlag: "createCriteria", errors: error.response.data.errors });

          reject();
        }).finally(() => {
          commit("stopLoadingFlag", "createCriteria");
        });
    });
  },

  editCriteria({ commit }, payload) {
    commit("startLoadingFlag", "editCriteria");

    return new Promise((resolve, reject) => {
      axios.post(`/ivr_after_call/${payload.id}/criteria/${payload.criteriaId}`, payload.formData).
        then(() => {
          commit("system/setSuccessMessage","success_message.update_criteria", { root: true});
          resolve();
        }).catch((error) => {
          error.response.status === 422 && commit("setErrors",
            { errorFlag: "editCriteria", errors: error.response.data.errors });

          reject();
        }).finally(() => {
          commit("stopLoadingFlag", "editCriteria");
      });
    });
  },

  removeCriteria({ commit }, payload) {
    commit('startLoadingFlag', 'removeCriteria')
    return new Promise((resolve, reject) => {
      axios.delete(`/ivr_after_call/${payload.id}/criteria/${payload.criteriaId}`, payload.formData).
        then(() => {
          commit("system/setSuccessMessage", "success_message.delete_criteria", { root: true})
          commit("removeCriteria", payload.criteriaId)
          resolve();
        }).catch((error) => {
          error.response.status === 422 && commit("setErrors",
            { errorFlag: "editCriteria", errors: error.response.data.errors });

          reject();
        }).finally(() => {
          commit('stopLoadingFlag', 'removeCriteria')
        });
    });
  },
}
