import axios from "axios";

export default {

  findUnderReviewMailings({ commit, getters }) {
    commit('startLoading', 'findUnderReviewMailings')
    commit('setUnderReviewMailings', []);
    return new Promise((resolve) => {
      axios.get(`/sms/validation?finished_evaluation=false`, { params: getters.getUnderReviewParams })
        .then((response) => {
          commit('setUnderReviewPagination', response.data.meta.pagination)
          commit('setUnderReviewMailings', response.data.data);
          commit('setTotalUnderReviewMailings', response.data.meta.pagination.total)
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
        }).finally(() => {
          commit('stopLoading', 'findUnderReviewMailings')
        });
    });
  },
  findFinalizedReviewMailings({ commit, getters }) {
    commit('startLoading', 'findFinalizedReviewMailings')
    commit('setFinalizedReviewMailings', []);
    return new Promise((resolve) => {
      axios.get(`/sms/validation?finished_evaluation=true`, { params: getters.getFinalizedReviewParams })
        .then((response) => {
          commit('setFinalizedReviewPagination', response.data.meta.pagination)
          commit('setFinalizedReviewMailings', response.data.data);
          commit('setTotalFinalizedReviewMailings', response.data.meta.pagination.total)
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
        }).finally(() => {
          commit('stopLoading', 'findFinalizedReviewMailings')
        });
    });
  },
  
  findFinalizedReviewMailingsByDate({ commit, getters }, payload) {
    commit('startLoading', 'findFinalizedReviewMailings')
    commit('setFinalizedReviewMailings', []);
    return new Promise((resolve) => {
      axios.get(`/sms/validation?finished=true&start_date=${payload.start_date}&end_date=${payload.end_date}`, { params: getters.getFinalizedReviewParams })
        .then((response) => {
          commit('setFinalizedReviewPagination', response.data.meta.pagination)
          commit('setFinalizedReviewMailings', response.data.data);
          commit('setTotalFinalizedReviewMailings', response.data.meta.pagination.total)
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
        }).finally(() => {
          commit('stopLoading', 'findFinalizedReviewMailings')
        });
    });
  },

  downloadMailingSms({ commit }, payload) {
    commit('startLoading', 'downloadMailingSms')
    return new Promise((resolve) => {
      axios.get(`/sms/validation/list/${payload.id}/csv`)
        .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'text/csv; charset=utf-8'}));
        var fileLink = document.createElement('a');
     
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${payload.id}`);
        document.body.appendChild(fileLink);
     
        fileLink.click();
          commit('system/setSuccessMessage', response.data.detail, { root: true })
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
        }).finally(() => {
          commit('stopLoading', 'downloadMailingSms')
        });
    });
  },
  downloadFinalizedMailingSms({ commit }, payload) {
    commit('startLoading', 'downloadFinalizedMailingSms')
    return new Promise((resolve) => {
      axios.get(`/sms/validation/list/${payload.id}/evaluation/csv`)
        .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data], {type : 'text/csv; charset=utf-8'}));
        var fileLink = document.createElement('a');
     
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${payload.id}`);
        document.body.appendChild(fileLink);
     
        fileLink.click();
          commit('system/setSuccessMessage', response.data.detail, { root: true })
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
        }).finally(() => {
          commit('stopLoading', 'downloadFinalizedMailingSms')
        });
    });
  },
  sendFinalizeMailing({ commit }, payload) {
    commit('startLoading', 'sendFinalizeMailing')
    return new Promise((resolve) => {
      axios.put(`/sms/validation/list/${payload.id}`, payload.fields)
        .then((response) => {
          commit('system/setSuccessMessage', response.data.detail, { root: true })
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
        }).finally(() => {
          commit('stopLoading', 'sendFinalizeMailing')
        });
    });
  },
  blockCompanySMS({ commit }, payload) {
    commit('startLoading', 'blockCompanySMS')
    return new Promise((resolve) => {
      axios.post(`/sms/blocking/company/${payload.id}`, payload.fields)
        .then((response) => {
          commit('system/setSuccessMessage', response.data.detail, { root: true })
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
        }).finally(() => {
          commit('stopLoading', 'blockCompanySMS')
        });
    });
  },
}