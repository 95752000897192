<template>
  <modal-component
    :show="show"
    :center="true"
    @close="$emit('close')"
  >
    <template #content>
      <div class="modal-header mx-4 ps-0">
        <h3 class="modal-title">
          Baixar relatório em CSV
        </h3>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          @click="$emit('close')"
        />
      </div>
      <div class="modal-body d-flex flex-column gap-4">
        <span>O processo de exportação para PDF pode demorar alguns minutos,
          por isso seu arquivo será gerado em segundo plano e enviado para
          o seu e-mail assim que estiver concluído.</span>
        <span>Informe um e-mail para enviarmos o arquivo</span>
        <input
          v-model="fields.email"
          type="text"
          class="form-control"
          placeholder="email@teste.com"
        >
        <div class="col-12 d-flex justify-content-end">
          <div class="d-flex gap-4 col-6">
            <app-button
              @click="$emit('close')"
              class="btn-sm btn-export tx-text-gray col"
            >
              Cancelar
            </app-button>
            <app-button
              class="btn-sm col"
              :loading="loading"
              :disabled="loading"
              @click="$emit('download', fields.email)"
            >
              Enviar por e-mail
            </app-button>
          </div>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from "@components/modal-component.vue";
import AppButton from "@components/app-button.vue";

export default {
  name: "ExportCsvReports",

  emits: ["close", "download"],

  components: {
    AppButton,
    ModalComponent
  },

  props:{
    show: {
      type: Boolean,
      default: false
    },
    loading:{
      type: Boolean,
      default: false
    },

  },

  data() {
    return {
      fields: {
        email: ""
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.modal {
  color: $color-text-200;
}
.btn-export {
  background: #E1E9F4;
  border: none;
  &:hover{
    background-color: #D2DDEC;
  }
}
</style>
