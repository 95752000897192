<template>
  <div>
    <div
      ref="dropdown"
      class="dropdown"
    >
      <button
        id="dropdownMenu"
        ref="dropdownMenu"
        :class="{ 'is-invalid': errors}"
        :style="customStyle"
        aria-expanded="false"
        class="form-control btn btn-white dropend wrapper-dropdown d-flex justify-content-between align-items-center"
        data-bs-toggle="dropdown"
        type="button"
        @click="toggleIconIfClickOutsideDropdown"
      >
        <span
          v-if="!modelValue && hasPlaceholder"
          class="tx-text-gray text-start fs-6"
        >
          {{ $t(placeholder) }}
        </span>
        <span
          v-else
          :style="{'background': modelValue}"
          class="handler-styles"
        />
        <i
          :class="[rotateIcon ? 'far fa-chevron-up ' : 'far fa-chevron-down ']"
          class="m-0 fs-5 tx-text-gray"
        />
      </button>
      <ul
        aria-labelledby="dropdownMenu"
        class="dropdown-menu dropdown-menu-end"
        style="padding: 16px"
      >
        <h6>{{ $t("select_color") }}</h6>
        <li class="d-flex flex-wrap flex-column align-items-center justify-content-between colors-menu">
          <div
            v-for="(item, index) in colors"
            :key="index"
            class="dropdown-item dropdown-colors d-flex justify-content-center align-items-center p-0"
            @click="setColor(item)"
          >
            <div
              :style="{background: item}"
              class="dot mx-0 cursor-pointer"
            />
          </div>
        </li>
      </ul>
      <div class="invalid-feedback">
        {{ errors }}
      </div>
    </div>
  </div>
</template>
<script>

import { colors } from "@/utils";

export default {
  data() {
    return {
      colors: colors,
      rotateIcon: false,
    };
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: { type: String, default: "" },
    errors: { type: String, default: "" },
    hasPlaceholder: { type: Boolean, default: false },
    placeholder: { type: String, default: "select" },
    customStyle: { type: String, default: "padding: 12px 16px;" },
  },

  mounted() {
    document.addEventListener("click", this.returnInitialValueRotate);
  },

  beforeUnmount() {
    document.removeEventListener("click", this.returnInitialValueRotate);
  },

  methods: {
    setColor(color) {
      this.$emit("update:modelValue", color);
    },

    toggleIconIfClickOutsideDropdown() {
      const dropdown = this.$refs.dropdown;
      if (dropdown) {
        this.rotateIcon = !this.rotateIcon;
        dropdown.setAttribute("aria-expanded", this.rotateIcon);
      }
    },

    returnInitialValueRotate(event) {
      const dropdown = this.$refs.dropdown;
      if (dropdown && this.rotateIcon && dropdown.contains(event.target)) {
        this.toggleIconIfClickOutsideDropdown();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-dropdown {
  position: relative;
  width: 100%;
  background: #fff;
  color: #2e2e2e;
  outline: none;
  cursor: pointer;
}

.wrapper-dropdown .dropdown {
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  font-weight: normal;
}

.wrapper-dropdown .dropdown div {
  text-align: center;
  padding: 0.1rem 0 0 1rem;
  cursor: pointer;
}

.dot {
  border-radius: 100%;
  height: 1.5rem;
  width: 1.5rem;
}

.dropdown-colors {
  width: 1.875rem;
  height: 1.875rem;

  :hover {
    width: 1.5rem !important;
    height: 1.5rem !important;
    border: 2px solid $color-gray-300;
    background-clip: content-box !important;
    padding: 1px !important;
  }
}

.colors-menu {
  height: 166px;
  width: 402px;
}

.handler-styles {
  display: inline-block;
  border-radius: 100%;
  height: 1rem;
  width: 1rem;
}
</style>
