export default {
  startLoadingFlags: (state, flag) => state.loadingFlags[flag] = true,
  stopLoadingFlags: (state, flag) => state.loadingFlags[flag] = false,
  setErrorFlag: (state, { errorFlag, errors }) => state.errorsFlag[errorFlag] = errors,
  setLoadingContext: (state, loadingContext) => state.loadingContext = loadingContext,
  clearErrors: (state) => {
    state.errors = {}
    state.errorMessage = ""
  },
  setErrors: (state, errors) => state.errors = errors,
  setCallsReport: (state, callsReports) => {
     state.callsReports = callsReports
  },
  setFeedbackInCallsReport: (state, { callsReportsId, feedbackId }) => {
    for (const call of state.callsReports) {
      if(call.id == callsReportsId) return call.feedback = feedbackId
    }
 },
  setFeedbackRegister: (state, feedbackRegister) => {
    state.feedbackRegister = feedbackRegister
  },
  setPagination: (state, pagination) => state.pagination = pagination,
  setCurrentPage: (state, page) => state.pagination.current_page = page,
  setRegister: (state, register) => state.register = register,
  setFilters: (state, filters) => state.filters = filters,
  setTotalFilters: (state, totalFilters) => state.totalFilters = totalFilters,
  setInclude: (state, include) => state.include = include,
  setCallsStatusCountPerTime: (state, payload) => {
    state.callsStatusCountPerTime[payload.campaign_id] = payload.data
  },
  setTotalCallsFromLastDayPerCampaign: (state, payload) => {
    state.totalCallsFromLastDayPerCampaign[payload.campaign_id] = payload.total
  },
  setHangupCausesDataSelect: (state, setHangupCausesDataSelect) => state.setHangupCausesDataSelect = setHangupCausesDataSelect,
  setRoutesDataSelect: (state, routesDataSelect) => state.routesDataSelect = routesDataSelect,
  setStatusesSelect: (state, statusesDataSelect) => state.statusesDataSelect = statusesDataSelect,
  setListDataSelect: (state, listDataSelect) => state.listDataSelect = listDataSelect,
  setTotalCallsData: (state, totalCallsData) => state.totalCallsData = totalCallsData,
  setLoadedCall: (state, loadedCall) => state.loadedCall = loadedCall,
  setDownloadTableParams: (state, params) => state.downloadTableParams = params
}
