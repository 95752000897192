<template>
  <thead>
    <tr>
      <th
        v-for="(item, index) in headers"
        :key="index"
      >
        <a
          class="list-sort text-muted fw-normal"
        >{{ $t(item) }}</a>
      </th>
      <th />
    </tr>
  </thead>
</template>
<script>
export default {
  data () {
    return {
      headers: ['uppercases.name', 'uppercases.creation_date', 'uppercases.send_date', 'uppercases.status_list', 'uppercases.message_status']
    }
  }
}
</script>
