<script>
export default {
  name: "EllipsisAnimation",

  props: {
    amount: {
      type: Number,
      default: 3
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      ellipsisAmount: 1
    }
  },

  watch: {
    loading: {
      immediate: true,
      handler(value) {
        if (!value) {
          this.ellipsisAmount = 1;
          return;
        }
        setInterval(() => {
          if (this.ellipsisAmount === this.amount) {
            this.ellipsisAmount = 1;
          } else {
            this.ellipsisAmount += 1;
          }
        }, 500);
      }
    }
  }
}
</script>

<template>
  <ul class="ellipsis-animation list-unstyled d-inline-flex mb-0">
    <li
      v-for="item in ellipsisAmount"
      :key="`ellipisis-${item}`"
    >
      .
    </li>
  </ul>
</template>

<style scoped lang="scss">
@keyframes ellipsis {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ellipsis-animation {
  li {
    &.active {
      animation-name: ellipsis;
      animation-duration: 0.35s;
      animation-delay: 1s;
      animation-iteration-count: infinite;
    }
  }
}
</style>