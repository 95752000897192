export default {
  getLoadingFlags: state => state.loadingFlags,
  getErrors: state => state.errors,
  getFilters: state => state.filters,
  getPagination: (state) => state.pagination,
  getParams: (state) => Object.assign(
    {
      'page': state.pagination.current_page,
      'per_page': state.pagination.per_page
    }, state.filters ),
  getChatsReportList: (state) => state.chatsReportList,
  getSpiedChat: (state) => state.spiedChat,
  getChatLogs: (state) => state.chatLogs
}
