import LoginHistory from "@dialer/manager/pages/reports/login-history";

export default {
  path: "login-history",
  name: "login-history",
  components: {
    main: LoginHistory
  },
  meta: {
    "short-side-bar": true
  }
};
