import Tags from "@admin/pages/settings/tags/list-tags.vue";
import store from "@/store";


export default {
  path: "tags",
  name: "tags",
  components: {
    main: Tags
  },

  beforeEnter: (to, from, next) => {
    const restrictedAdminLevels = store.getters["admin/getRestrictedAdminLevels"];
    if (restrictedAdminLevels.includes(store.getters["auth/getUser"].settings.access)) next();
    else next("/");
  }
};
