<template>
  <select
    class="form-select"
    :disabled="loadingFlags.loadListDataSelect || dataSelect.length == 0"
    @input="$emit('update:modelValue', $event.target.value)"
    :value="modelValue"
  >
    <option value="">
      {{ $t('all_f') }}
    </option>
    <option
      :value="item.id"
      v-for="(item, i) of dataSelect"
      :key="i"
    >
      {{ (item.original_name ? item.original_name : item.name) + ` - ${formatDate(item.created_at)}` }}
    </option>
  </select>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { Utils } from "@/utils";

export default {
  /**
   *
   */
  emits: ["update:modelValue"],

  /**
   *
   */
  computed: {
    ...mapGetters("manager/calls-report", {
      dataSelect: "getListDataSelect",
      loadingFlags: "getLoadingFlags",
    }),
  },

  props: {
    /**
     *
     */
    modelValue: {
      default: "",
      type: String,
    },

    /**
     *
     */
    campaignId: {
      type: Number,
      default: 0,
    },
  },

  /**
   *
   */
  watch: {
    /**
     *
     */
    campaignId(campaignId) {
      if (campaignId) {
        this.loadListDataSelect({
          campaign_id: campaignId,
          fields: {
            'trashed[0]': 'campaign',
            'trashed[1]': 'mailing_list'
          }
        });
      } else {
        this.$emit("update:modelValue", "");
        this.setListDataSelect([]);
      }
    },

    /**
     *
     */
    dataSelect(data) {
      if (data.length == 0) {
        this.$emit("update:modelValue", "");
      }
    },
  },

  /**
   *
   */
  methods: {
    /**
     *
     */
    ...mapActions("manager/calls-report", ["loadListDataSelect"]),
    ...mapMutations("manager/calls-report", ["setListDataSelect"]),

    formatDate: Utils.formatDate,
  },
};
</script>
