import store from "@store";

export default {
  path: 'list-metrics',
  name: 'list-metrics',
  components: {
    main: require('@dialer/manager/pages/reports/list-metrics.vue').default,
  },
  meta: {
    'short-side-bar': true
  },
  beforeEnter: (to, from, next) => {
    if (store.getters["auth/isManager"]) next();
    else next({
      name: "not-found",
      params: {
        pathMatch: to.path.split('/').slice(1)
      }
    });
  }
}
