<template>
  <span
    :class="[
      'badge-status', changeStatusColor(status).class
    ]"
    class="px-2 py-1 fw-medium"
  >
    {{ changeStatusColor(status).text }}
  </span>
</template>

<script>
export default {
  name: "BadgeStatus",
  props: {
    status: {
      type: String,
      default: "",
    },
  },
  methods: {
    changeStatusColor(status) {
      const statusMap = {
        PAID: {class: "paid", text: this.$t('uppercases.paid')},
        PENDING: {class: "pending", text: this.$t('uppercases.pending')},
        CANCELED: {class: "canceled", text: this.$t('uppercases.canceled')},
        OVERDUE: {class: "overdue", text: this.$t('uppercases.overdue')},
        SCHEDULED: {class: "scheduled", text: this.$t('uppercases.scheduled')},
      }

      return statusMap[status] || {class: "", text: ""};
    },
  }
}
</script>

<style lang="scss" scoped>
.badge-status {
  border-radius: 4px;
  font-size: 12px;
}

.paid {
  background-color: #D6E9D9;
  color: #008A35;
}

.pending {
  background-color: $color-gray-blue-300;
  color: $color-blue-300;
}

.canceled {
  background-color: #DADEE5;
  color: #283E5D;
}

.overdue {
  background-color: #F2C6D1;
  color: #920000;
}

.scheduled {
  background-color: #F7EAC4;
  color: #754000;
}
</style>
