<template>
  <collapse-card
    id="product"
    :title="$t('products_and_services')"
    @action-button-clicked="openNewProductModal"
    :enable-action-button="opportunity?.status !== 'lost'"
    :label-action-button="opportunityProducts?.length === 0? $t('add_capitalize') : $t('edit')"
    :icon-action-button=" opportunityProducts?.length === 0? 'fa-regular fa-plus' : ''"
    no-padding
    :expand="true"
    :is-loading="isLoading"
  >
    <template #content>
      <div
        v-if="opportunityProducts && opportunityProducts.length === 0"
        class="d-flex flex-column align-items-center gap-4 p-5"
      >
        <img
          :src="require(`@/assets/img/plusinha-money.svg`)"
          alt="plusinha-money"
          style="width: 7rem"
        >
        <h5>{{ $t('no_product_added') }}.</h5>
        <button
          v-if="opportunity?.status !== 'lost'"
          class="btn btn-primary col-6"
          @click="openNewProductModal"
        >
          <i class="fa fa-plus" />
          <span class="ms-2">{{ $t('add_product') }}</span>
        </button>
      </div>
      <div v-else>
        <table class="table table-responsive">
          <thead>
            <tr>
              <th>{{ $t('product') }}</th>
              <th class="text-end">
                {{ $t('quantity_abbreviation') }}
              </th>
              <th class="text-end">
                Valor
              </th>
            </tr>
          </thead>
          <tbody v-if="productsAndServices && productsAndServices.opportunityData.length > 0">
            <tr
              v-for="(item, index) in productsAndServices.opportunityData"
              :key="index"
            >
              <td>{{ item.name }}</td>
              <td class="text-end">
                {{ item?.quantity || '0' }}
              </td>
              <td class="text-end">
                <i
                  v-if="item?.is_recurrent"
                  class="fa-regular fa-arrows-repeat text-small me-2"
                />
                <span>{{ formatLocaleMoney(item?.price, item?.currency) }}</span>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td
                class="fw-bold"
                colspan="2"
              >
                {{ productsAndServices.totalQuantity }}  {{ $tc('product', productsAndServices.totalQuantity).toLowerCase() }}
              </td>
              <td
                class="text-end"
                colspan="2"
              >
                Total
                <span class="ms-2 fw-bold">
                  {{ formatLocaleMoney(productsAndServices.totalProductAmount, productCurrency) }}
                </span>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </template>
  </collapse-card>
  <new-product-modal
    :fetch-products="true"
    :show-modal="showNewProductModal"
    @close="closeNewProductModal"
  />
</template>
<script>
import NewProductModal from "@crm/components/contact/products/new-product-modal.vue";
import {formatLocaleMoney} from "@/utils";
import {mapGetters} from "vuex";
import CollapseCard from "@crm/pages/opportunity/components/collapse-card.vue";

export default {
  name: "ProductCard",
  components: {CollapseCard, NewProductModal},
  props: {
    products: {
      type: Array,
      required: true
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showNewProductModal: false,
      productCurrency: 'BRL',
      productsAndServices: {
        opportunityData: [],
        totalProductAmount: 0,
        totalQuantity: 0
      }
    }
  },

  computed: {
    ...mapGetters("manager/products_and_services", {
      loading: "getLoadingFlag",
      opportunityProducts: "getSynchronizedProducts",
    }),

    ...mapGetters("crm/opportunities", {
      opportunity: "getOpportunity",
    }),
  },

  watch: {
    opportunityProducts: {
      handler() {
        this.setProductsAndServices()
      },
      deep: true
    }
  },

  mounted() {
    this.setProductsAndServices()
  },

  methods: {
    formatLocaleMoney,
    setProductsAndServices() {
      let totalQuantity = 0;
      let totalProductAmount = 0;
      const opportunityData = this.opportunityProducts.filter((product) => product.is_active).map((product) => {
        if (product) {
          totalProductAmount += product.price_per_unit * product.quantity
          totalQuantity += product.quantity
          this.productCurrency = product.currency
          return {
            name: product.name,
            quantity: product.quantity,
            price: product.price_per_unit * product.quantity,
            currency: product.currency,
            is_recurrent: product.is_recurrent
          }
        }
      })
      this.productsAndServices = {
        opportunityData: opportunityData,
        totalProductAmount,
        totalQuantity
      }
    },

    openNewProductModal() {
      this.showNewProductModal = true;
    },

    closeNewProductModal() {
      this.showNewProductModal = false;
    }
  }
}
</script>
<style lang="scss" scoped>
.product-card-collapse-button[aria-expanded="true"] i.fa {
  transform: rotate(360deg);
}

.product-card-collapse-button[aria-expanded="false"].collapsed i.fa {
  transform: rotate(180deg);
}
</style>