<template>
  <div class="d-flex justify-content-center align-items-center gap-4">
    <div class="text-center align-items-center me-5">
      <img
        :src="require('@/assets/img/empty-state/'+icon)"
        alt="empty-state"
        draggable="false"
      >
    </div>
    <div
      class="align-items-start"
      :class="alignText"
    >
      <h1 class="mb-1">
        {{ title }}
      </h1>
      <p class="tx-text-gray text-medium">
        {{ subTitle }}
      </p>
      <slot name="buttons" />
    </div>
  </div>
</template>
<script>
export default {
  name: "EmptyState",
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    alignText: {
      type: String,
      default: "left",
    },
  },
}
</script>