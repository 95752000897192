<template>
  <modal-component
    :backdrop="'static'"
    :show="exclude"
    :center="true"
    class="category-modal"
  >
    <template #content>
      <div class="modal-header">
        <h5 class="modal-title fs-4">
          {{ categoryModal.exclude?.title }}
        </h5>
        <button
          type="button"
          class="float-btn bg-transparent border-0 d-flex justify-content-center align-items-center lh-1"
          aria-label="Close"
          @click="closeModal"
        >
          <i class="fa-regular fa-xmark" />
        </button>
      </div>
      <div class="modal-body text-center">
        <p
          style="color: #373753;"
          class="fs-4 fw-medium"
        >
          {{ $t('deactivate_confirmation') }} “{{ categoryModal.exclude?.name }}”?
        </p>
        <p class="mb-0 ">
          <span v-if="categoryModal.type === 'category'">{{ $t('category_and_all') }} <span
            class="fw-medium"
          >{{ categoryData.products?.length || 0 }}</span> {{ $t('belong_products') }} </span>
          <br v-if="categoryModal.type === 'category'">
          <span :class="{'fw-medium': categoryModal.type === 'category'}">
            {{ $t('all_opportunities_will_be_unlinked') }}
          </span>
        </p>
      </div>
      <div
        style="gap: 1rem;"
        class="modal-footer flex-column p-4 pt-0"
      >
        <app-button
          style="width: 328px"
          type="submit"
          color="btn-danger"
          @click="handleDelete"
        >
          {{ $t('yes') }}
        </app-button>
        <button
          type="button"
          class="btn btn-transparent bg-transparent"
          @click="closeModal"
        >
          {{ $t('no') }}
        </button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from "@components/modal-component.vue";
import {mapActions, mapGetters} from "vuex";
import AppButton from "@components/app-button.vue";


export default {
  name: 'ExcludeModal',
  components: {AppButton, ModalComponent},
  computed: {
    ...mapGetters("manager/products_and_services", {
      exclude: "getExclude",
      categoryData: "getCategoryData",
      productData: "getProductData",
      categoryModal: "getCategoryModal"
    }),
  },


  methods: {
    ...mapActions("manager/products_and_services", [
      "updateExclude",
      "toggleModal",
      "deleteCategory",
      "deleteProduct",
      "handleCategoryData",
      "fetchCategories",
      "toggleEdit",
      "updateCategory",
      "updateProduct",
      "updateExclude"
    ]),

    closeModal() {
      this.updateExclude(false)
    },

    handleDelete() {
      const actionName = this.categoryModal.exclude?.actionName;
      const payload = this[this.categoryModal.dataState]
      this[actionName](payload).then(() => {
        this.fetchCategories()
        this.updateExclude(false)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.category-modal {
  .modal-content {
    box-shadow: 0 4px 18px 0 rgba(34, 54, 77, 0.12);
  }

  .modal-header {
    border-bottom-color: #E1E9F4;
  }

  .modal-body {
    padding: 2.5rem;
  }

  .float-btn {
    width: 24px;
    height: 24px;
    color: #677C92;
    padding: 4px 6px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover,
    &:focus-visible,
    &:focus {
      color: #373753;
    }
  }

  .modal-footer {
    border-top-width: 0;

    > * {
      margin: 0;
    }
  }
}

.btn-transparent {
  width: 237px;
  color: #677C92;

  &:hover,
  &:focus,
  &:focus-visible {
    color: #373753;
  }
}

@media only screen and (min-width: 576px) {
  .category-modal {
    .modal-dialog {
      max-width: 647px;
    }
  }
}
</style>