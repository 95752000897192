<template>
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <h2 class="card-title">
            {{ title }}
          </h2>
          <img
            class="small-icons"
            src="@assets/img/3czinhos.svg"
            alt="Pequenos ícones 3CPlus"
          >
        </div>
        <div class="row gy-4">
          <template
            v-for="setting in settings"
          >
            <card-settings
              v-if="setting.show"
              :key="setting.title"
              :title="setting.title"
              :description="setting.description"
              :redirect-link="setting.redirectLink"
              :icon="setting.icon"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardSettings from "@dialer/manager/pages/settings/voice-settings/card-settings.vue";

export default {
  name: "WrapperCardSettings",
  components: {
    CardSettings,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    settings: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.small-icons {
  height: 16px;
}
</style>
