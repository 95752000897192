<template>
  <doughnut-chart
    title="Qualificações totais"
    :labels="chartLabels"
    :colors="colors"
    :chart-data="datasets"
    class="w-100"
    :custom-options="customOptions"
    :config="config"
  />
</template>
<script>
import doughnutChart from "@dialer/manager/components/doughnut-chart.vue";

export default {
  name: "TotalQualificationsCard",
  components: {doughnutChart},
  props: {
    chatsQualificationsTotal: {type: Array, default: () => []},
    totalCount: {type: Number, default: () => 0},
  },
  data() {
    return {
      chartLabels: [],
      datasets: [],
      colors: [],
      customOptions: [
        {
          borderRadius: 10
        },
        {
          spacing: 0
        },
        {
          hoverOffset: 4,
        }
      ],

      config: [
        {
          cutout: 90
        },
        {
          radius: 100
        }
      ]
    };
  },

  watch: {
    chatsQualificationsTotal: {
      handler (data) {
        if (data){
          this.prepareChartQualificationData(data)
        }
      },
      deep: true,
    },
  },

  methods: {
    prepareChartQualificationData(qualificationsTotal) {
      this.datasets = new Array(Object.keys(qualificationsTotal).length).fill(0);
      this.colors = new Array(Object.keys(qualificationsTotal).length).fill('');
      this.chartLabels = new Array(Object.keys(qualificationsTotal).length).fill('');
      Object.values(qualificationsTotal).map((qualification, index) => {
        this.chartLabels[index] = qualification.name;
        this.datasets[index] = qualification.count;
        this.colors[index] = qualification.color;
      });
    },
  }
}
</script>