export default {
  instances: [],
  loadingFlags: {
    findInstances: false,
    findAllowedAgentsAndInstances: false,
    findQualifications: false,
    fetchGroupChannels: false,
    fetchQualificationsById: false
  },
  qualifications: [],
  groupChannel: {},
  groupChannels: [],
  allowedInstances: [],
  allowedParams: {
    page: 1,
    search: "",
    team: null,
    role: 'agent',
    all_company: 0,
  }
};
