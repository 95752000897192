import store from "@store";

import IndexIntervals from "@dialer/manager/pages/settings/intervals/index-intervals";
import ListWorkBreakGroups from "@dialer/manager/pages/settings/intervals/list-work-break-groups.vue";
import WorkBreakGroupForm from "@dialer/manager/pages/settings/intervals/work-break-group-form.vue";

export default {
  path: "intervals",
  name: "intervals",
  components: {
    main: IndexIntervals
  },
  children: [
    {
      path: "",
      components: {
        intervals: ListWorkBreakGroups
      }
    },
    {
      path: "new",
      components: {
        intervals: WorkBreakGroupForm
      }
    },
    {
      path: ":id/edit",
      components: {
        intervals: WorkBreakGroupForm
      }
    }
  ],
  beforeEnter: (to, from, next) => {
    if (store.getters["auth/isManager"]) next();
    else
      next({
        name: "not-found",
        params: {
          pathMatch: to.path.split("/").slice(1)
        }
      });
  }
};
