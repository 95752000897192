<template>
  <div class="position-absolute end-0 d-lg-flex flex-column top-50 me-4 toolbar">
    <button
      class="btn btn-primary btn-open-action"
      @click="openAction"
      data-bs-toggle="tooltip"
      data-bs-placement="left"
      data-bs-trigger="hover"
      :title="$t('new_step')"
    >
      <i class="fa-regular fa-plus" />
    </button>
    <div class="d-flex flex-column buttons">
      <button
        class="btn btn-primary bg-white border-0 tx-text-gray"
        @click="layoutGraph('LR')"
        data-bs-toggle="tooltip"
        data-bs-placement="left"
        data-bs-trigger="hover"
        :title="$t('automatically_organize')"
      >
        <i class="fa-regular fa-objects-column " />
      </button>
      <button
        class="btn btn-primary bg-white border-0"
        @click="resetTransform"
        data-bs-toggle="tooltip"
        data-bs-placement="left"
        data-bs-trigger="hover"
        :title="$t('show_all')"
      >
        <i class="fa-regular fa-arrow-down-left-and-arrow-up-right-to-center tx-text-gray" />
      </button>
      <button
        class="btn btn-primary bg-white border-0 tx-text-gray"
        @click="zoomIn"
        data-bs-toggle="tooltip"
        data-bs-placement="left"
        data-bs-trigger="hover"
        :title="$t('zoom_in')"
      >
        <i class="fa-regular fa-magnifying-glass-plus" />
      </button>
      <button
        class="btn btn-primary bg-white border-0 tx-text-gray"
        @click="zoomOut"
        data-bs-toggle="tooltip"
        data-bs-placement="left"
        data-bs-trigger="hover"
        :title="$t('zoom_out')"
      >
        <i class="fa-sharp fa-regular fa-magnifying-glass-minus tx-text-gray" />
      </button>
    </div>
  </div>
</template>
<script>
import {mapActions, mapMutations} from "vuex";
import {useVueFlow} from "@vue-flow/core";
import { useLayout } from '@chatbot/Utils';
import {Tooltip} from 'bootstrap';

const { fitView, zoomIn, zoomOut } = useVueFlow( 'chatbot' )

export default {
  emits:['organize'],
  data() {
    return {
         previousDirection: 'LR',
    };
  },
  methods: {
    ...mapActions("chatbot/actions", ["updateCoordinates"]),
    ...mapActions("chatbot/chatbots",["fetchChatbot"]),
    ...mapMutations("chatbot/actions", ["setOffcanvasNewActionIsOpen", "setNodesPositions", "setSelectedAction", "setPrevActionId"]),
    openAction() {
      this.setSelectedAction(null)
      this.setPrevActionId(null)
      this.setOffcanvasNewActionIsOpen(true)
    },
    resetTransform() {
      fitView();
      zoomOut();
    },
    zoomIn() {
      zoomIn();
    },
    zoomOut() {
      zoomOut();
    },
     layoutGraph(direction) {

      const { nodes, edges } = useVueFlow( 'chatbot' );
      const { layout } = useLayout()

       const updatedNodes = layout(nodes.value, edges.value, direction)
       const nodesPositions = updatedNodes.flatMap((node) => {
         if(!['back', 'transfer', 'restart', 'finish'].includes(node.data.content.type) )
         return {
           action_id: parseInt(node.id,10),
           x_coordinate: node.position.x,
           y_coordinate: node.position.y
         };
         return [];
       });
      this.$emit('organize')
       let payload = {
        coordinates:nodesPositions
       }
       this.updateCoordinates({payload}).then(()=>{
         this.fetchChatbot(this.$route.params.id)
       })
    },
    handleKeydown(event) {
      if (event.key === "=" || event.key === "+") {
        this.zoomIn();
      } else if (event.key === "-") {
        this.zoomOut();
      }
    },
  },
  mounted() {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
    window.addEventListener("keydown", this.handleKeydown);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.handleKeydown);
  },
};
</script>

<style scoped lang="scss">
button{
  border-radius: 50%;
  box-shadow: 0 4px 18px 0 rgba(34, 54, 77, 0.12);
}
.toolbar{
  gap: 16px;
  margin-top: -100px;
}
.btn-open-action{
  width: 56px;
  height: 56px;
}
.buttons{
  gap:8px;
  padding-inline: 8px;
}
.buttons{
  button {
    &:hover {
      i {
        color: #3057F2 !important;
      }
    }
  }
}
</style>