export default {
  loadingFlags: {
    fetchGroupChannelsReportList: false,
    fetchSendChatCsvEmail: false,
    fetchSendTimeCsvEmail: false,
  },
  errors: {},
  filters: {},
  include: [],
  chatsReportList: [],
  timeReportList: [],
  qualificationsReportList: [],
}
