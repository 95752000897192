<template>
  <div class="card">
    <div class="card-header">
      <card-loading
        v-if="isLoading"
        margin-bottom="0"
        :height="29"
      />

      <template v-else>
        <a
          class="collapse-btn btn d-flex align-items-center tx-text-200 fs-4 fw-medium text-start p-0"
          data-bs-toggle="collapse"
          :href="`#collapse-${id}`"
          role="button"
          :aria-expanded="expand"
          :aria-controls="`collapse-${id}`"
        >
          <i class="fa-regular fa-chevron-down me-3" />
          <span class="d-block me-2">{{ title }}</span>
          <div
            v-if="errors.length"
            class="invalid-feedback d-inline-block fw-normal"
          >
            {{ "*" + errors[0] }}
          </div>

        </a>
        <button
          v-if="enableActionButton"
          class="btn btn-link fs-6 p-0"
          @click="$emit('actionButtonClicked')"
        >
          <i
            v-if="iconActionButton"
            :class="iconActionButton"
            class="me-1"
          />
          <span>{{ labelActionButton }}</span>
        </button>
        <slot
          v-else
          name="actionButton"
        />
      </template>
    </div>

    <div
      class="collapse"
      :class="expand ? 'show' : ''"
      :id="`collapse-${id}`"
    >
      <div
        :class="{'p-0': noPadding }"
        class="card-body"
      >
        <slot name="content" />
      </div>
    </div>
  </div>
</template>
<script>
import CardLoading from "@components/card-loading.vue";

export default {
  name: 'CollapseCard',
  components: {CardLoading},
  emits: [
    'actionButtonClicked'
  ],

  props: {
    id: {
      type: String,
      default: 'collapse'
    },
    title: {
      type: String,
      default: "Tittle",
    },
    enableActionButton: {
      type: Boolean,
      default: false
    },
    labelActionButton: {
      type: String,
      default: "Action",
    },
    iconActionButton: {
      type: String,
      default: null
    },

    errors: {
      type: Array,
      default: () => []
    },
    noPadding: {
      type: Boolean,
      default: false
    },

    isLoading: {
      type: Boolean,
      default: false
    },
    expand: {
      type: Boolean,
      default: false
    }
  },

}
</script>

<style lang="scss" scoped>
.collapse-btn {
  &[aria-expanded="true"] {
    i {
      transform: rotate(180deg);
    }
  }
}

.loading-card {
  margin-bottom: 0;
}
</style>