<template>
  <history-accordion
    :index="index"
  >
    <template #header>
      <div class="w-100 row align-items-center">
        <div class="col-lg-5">
          Alteração da data de bloqueio
        </div>
        <div class="col-lg-7">
          <div class="d-flex justify-content-between">
            <div>
              <span v-if="change.previous_value === null"> Tempo indeterminado </span>
              <span
                class="me-2"
                v-else
              >
                {{ timestampToDate(change.previous_value, 'DD/MM/YYYY') }}
              </span>
              <i
                class="fe fe-repeat"
                style="color: #3057F2;"
              />
              <span>
                {{ timestampToDate(change.current_value, 'DD/MM/YYYY') }}
              </span>
            </div>
            <div class="me-4 text-small">
              <span class="text-muted d-block">Realizado em:</span>
              {{ timestampToDate(change.created_at, "DD/MM/YYYY [às] HH:mm") }}
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #body>
      <div class="row text-small">
        <div class="col-lg-2">
          <span class="d-block tx-text-200 fw-bold">Nome</span>
          {{ change.user_name }}
        </div>
        <div class="col-lg-10">
          <span class="d-block tx-text-200 fw-bold">Descrição</span>
          {{ change.change_reason }}
        </div>
      </div>
    </template>
  </history-accordion>
</template>

<script>
import { Utils } from "@/utils";
import HistoryAccordion from "./history-accordion.vue";

export default {
  components: {
    HistoryAccordion
  },

  props: {
    change: {
      type: Object,
      default: () => {}
    },

    index: {
      type: Number,
      default: null
    }
  },

  methods: {
    timestampToDate: Utils.timestampToDate,
  }
}
</script>
