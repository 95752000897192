<template>
  <header-component
    :title="$tc('capitalizes.qualifications', 0)"
    :pretitle="$t('uppercases.settings')"
    redirect-link="/manager/voice-settings"
  >
    <template #buttons>
      <button
        class="btn btn-primary px-6"
        @click="$router.push('/manager/qualifications/new')"
      >
        {{ $t('add.new_group') }}
      </button>
    </template>
  </header-component>

  <flash-message />

  <div
    style="margin-top: 34px"
    class="card"
  >
    <div class="card-header gap-4">
      <search-input @search="search" />
      <div class="dropdown">
        <button
          class="btn filter-btn dropdown-toggle"
          type="button"
          id="typeFilter"
          ref="typeFilter"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style="padding: 0.25rem 1rem"
        >
          {{ currentType.label }}
          <i
            class="ms-2 fa-regular"
            :class="showDropdown ? 'fa-chevron-up' : 'fa-chevron-down'"
          />
        </button>
        <ul
          style="padding: 0.5rem; box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.30);"
          class="dropdown-menu"
          aria-labelledby="typeFilter"
        >
          <li
            v-for="(type, typeIndex) in typesList"
            :key="`type-${typeIndex}`"
            :style="typeIndex < typesList.length - 1 ? 'margin-bottom: 0.25rem' : ''"
          >
            <button
              @click="setType(type)"
              class="dropdown-item rounded option"
            >
              {{ type.label }}
            </button>
          </li>
        </ul>
      </div>
    </div>

    <div class="card-body">
      <div
        class="row"
        style="row-gap: 1.5rem"
      >
        <div class="col-lg-5 col-xl-4 col-xxl-3">
          <button
            style="padding: 1rem; background-color: #E1E9F4"
            class="w-100 h-100 border-0 d-flex gap-4 justify-content-between align-items-center  text-start rounded"
            @click="$router.push('/manager/qualifications/new')"
          >
            <span class="tx-text-100">
              {{ $t('add.new_group') }}
            </span>
            <i class="fa-regular fa-plus tx-blue-200" />
          </button>
        </div>
        <template v-if="loading">
          <div
            class="col-lg-5 col-xl-4 col-xxl-3"
            v-for="loader in 15"
            :key="`loader-${loader}`"
          >
            <card-loading
              :height="74"
              class="mb-0"
            />
          </div>
        </template>
        <template v-else>
          <div
            class="col-lg-5 col-xl-4 col-xxl-3"
            v-for="(group, index) in list"
            :key="`group-${index}`"
          >
            <qualification-group
              style="box-shadow:  0 12px 24px 0 rgba(18, 38, 63, 0.03)"
              :group="group"
            />
          </div>
        </template>
      </div>
    </div>

    <div class="card-footer">
      <paginator
        :pagination="pagination"
        @change-page="changePage"
        v-if="!loading"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import _ from "lodash";
import paginator from "@/components/app-paginator.vue";
import FlashMessage from "@components/flash-message";
import HeaderComponent from "@/components/header-component.vue";
import SearchInput from "@components/search-input.vue";
import QualificationGroup from "@dialer/manager/pages/settings/qualifications/components/qualification-group.vue";
import CardLoading from "@components/card-loading.vue";

export default {
  components: {
    CardLoading,
    QualificationGroup,
    SearchInput,
    paginator,
    FlashMessage,
    HeaderComponent
  },

  data() {
    return {
      typesList: [
        {
          label: this.$t('all_dropdown'),
          value: null
        },
        {
          label: this.$t("dialer_menu_capitalize"),
          value: 1
        },
        {
          label: this.$t("Omnichannel"),
          value: 2
        },
        {
          label: this.$t("dialer_and_omnichannel_capitalize"),
          value: 3
        },
        {
          label: this.$t("crm"),
          value: 4
        },
      ],

      currentType: {},

      showDropdown: false,
    };
  },

  mounted: function () {
    this.$store.dispatch("manager/qualification/list");

    this.$refs.typeFilter.addEventListener('shown.bs.dropdown', () => this.toggleIcon(true));
    this.$refs.typeFilter.addEventListener('hidden.bs.dropdown', () => this.toggleIcon(false));
  },
  computed: {
    ...mapGetters("manager/qualification", {
      list: "getList",
      loading: "isLoading",
      pagination: "getPagination",
    }),
  },
  methods: {
    ...mapMutations("manager/qualification", ["clearList"]),
    search: _.debounce(function (search) {
      this.clearList();

      const query = {}
      query.search = search;

      if (this.currentType) {
        query.type = this.currentType.value;
      }

      this.fetchList(query)
    }, 500),

    changePage(page) {
      this.fetchList({page})
    },

    setType(type) {
      this.clearList();
      this.fetchList({type: type.value})
      this.currentType = type;
    },

    fetchList(query) {
      if (!query.page) {
        this.clearList();
      }

      this.$store.dispatch("manager/qualification/list", query);
    },

    toggleIcon(toggle) {
      this.showDropdown = toggle;
    },
  },

  beforeMount() {
    this.setType(this.typesList[0])
  },

  beforeUnmount() {
    this.$refs.typeFilter.removeEventListener('shown.bs.dropdown', () => this.toggleIcon(true));
    this.$refs.typeFilter.removeEventListener('hidden.bs.dropdown', () => this.toggleIcon(false));
  }

};
</script>

<style lang="scss" scoped>
.filter-btn {
  background-color: #E1E9F4;
  color: $color-text-200;

  &.show {
    background-color: $color-blue-300;
    color: #fff;
  }

  &:after {
    display: none;
  }
}

.dropdown-item {
  padding: 0.25rem 1rem;

  &:hover,
  &:focus,
  &:focus-visible,
  &:active {
    background-color: $color-gray-blue-300;

  }
}
</style>
