<template>
  <div
    ref="canvas"
    class="offcanvas offcanvas-end border"
  >
    <div class="offcanvas-header border-0 pb-0 align-items-baseline">
      <h2>{{ title }}</h2>
      <button
        aria-label="Close"
        class="btn"
        data-bs-dismiss="offcanvas"
        type="button"
      >
        <i class="fa-regular fa-xmark fs-3 tx-text-gray" />
      </button>
    </div>
    <div class="offcanvas-body">
      <div
        v-for="(slot, index) in dynamicSlots"
        :key="index"
        :class="slot.className"
      >
        <slot
          :name="slot.name"
        >
          {{ slot.content }}
        </slot>
      </div>
      <div
        v-if="dynamicRequestSlots"
        class="d-flex gap-5"
      >
        <div
          class="w-50"
        >
          <slot
            v-for="(request, index) in dynamicRequestSlots.startRequest"
            :key="index"
            :name="request.name"
          >
            <span
              :class="request.className"
              class="d-block"
            >
              {{ request.content }}
            </span>
          </slot>
        </div>
        <div
          class="w-50"
        >
          <slot
            v-for="(request, index) in dynamicRequestSlots.endRequest"
            :key="index"
            :name="request.name"
          >
            <span
              :class="request.className"
              class="d-block"
            >
              {{ request.content }}
            </span>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Offcanvas} from "bootstrap";
import {mapGetters} from "vuex";

export default {
  name: "OffcanvasPartnerWithdraw",
  data() {
    return {
      offcanvas: null,
    }
  },
  props: {
    title: {
      type: String,
      default: "",
      required: true
    },
    dynamicSlots: {
      type: Array,
      default: () => []
    },
    dynamicRequestSlots: {
      type: Object,
      default: () => ({})
    },
  },
  mounted() {
    this.offcanvas = new Offcanvas(this.$refs.canvas);
  },
  computed: {
    ...mapGetters("admin/users", {
      loadingFlags: "getLoadingFlags",
    }),
  },
  methods: {
    new() {
      this.offcanvas.show()
    },

  },
}
</script>
<style
  lang="scss"
  scoped
>
.offcanvas {
  width: 500px;
  min-width: 30vw;
  background: #F9FAFC;
}

.badge-status {
  border-radius: 8px;
  padding: 2px 16px;
}

.success {
  background-color: #D6E9D9;
  color: #015D0D;
}

.defaulting {
  background-color: #F2C6D1;
  color: #920000;
}

.w {
  max-width: 103px !important;
}

.border {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
</style>
