<template>
  <VueMultiselect
    v-model="selecteds"
    :multiple="multiple"
    :options="ringGroups"
    :group-label="'groupName'"
    :group-values="'values'"
    :placeholder="'Todos'"
    :select-label="$t('click_or_press_enter_to_select')"
    :deselect-label="$t('click_or_press_enter_to_remove')"
    :selected-label="$t('selected')"
    track-by="id"
    label="name"
    @select="select"
    @remove="remove"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueMultiselect from "vue-multiselect";

export default {
  data() {
    return {
      selecteds: "",
      ringGroups: []
    };
  },

  emits: ["update:modelValue"],
  components: {
    VueMultiselect
  },

  mounted() {
    this.setFiltersIncludes(["ring_groups_with_trashed"]);
    this.findFilters();
    if (this.modelValue) this.selecteds = this.modelValue;
  },

  computed: {
    ...mapGetters("manager/filters", {
      filters: "getFilters",
      loadingFlags: "getLoadingFlags"
    })
  },

  props: {
    modelValue: {
      type: [String, Number, Object],
      default: ""
    },

    multiple: {
      type: Boolean,
      default: false
    },

    onlyActiveGroups: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    ...mapActions("manager/filters", ["findFilters", "setFiltersIncludes"]),

    select(value) {
      if (this.multiple) {
        const selecteds = [...this.selecteds, value];
        const ids = selecteds.map((item) => item.id).join(",");
        this.$emit("update:modelValue", ids);
      } else {
        this.$emit("update:modelValue", value.id);
      }
    },

    remove(value) {
      if (this.multiple) {
        const selecteds = this.selecteds.filter((item) => item.id !== value.id);
        const ids = selecteds.map((item) => item.id).join(",");
        this.$emit("update:modelValue", ids);
      } else {
        this.$emit("update:modelValue", "");
      }
    }
  },

  watch: {
    filters: {
      handler(data) {
        if (Object.values(data).length && data.ring_groups_with_trashed) {
          for (let key in data.ring_groups_with_trashed) {
            if (this.onlyActiveGroups && key == "trashed") continue;
            this.ringGroups.push({
              groupName: key == "trashed" ? "Excluídas" : "Ativas",
              values: data.ring_groups_with_trashed[key]
            });
          }

          this.ringGroups.sort((a, b) => {
            if (a.groupName.toUpperCase() < b.groupName.toUpperCase()) return -1;

            if (a.groupName.toUpperCase() < b.groupName.toUpperCase()) return 1;

            return 0;
          });
        }
      },
      deep: true
    }
  }
};
</script>
