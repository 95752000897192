<template>
  <div
    class="dialing-wrapper position-relative d-flex align-items-center justify-content-between gap-3 rounded tx-text-gray"
  >
    <span
      class="cursor-pointer"
      @click="pastBtn"
    >
      <i class="fas fa-copy" />
    </span>
    <div class="number">
      <input
        type="tel"
        class="phone-input tx-text-200"
        :placeholder="$t('type_number_to_call')"
        v-maska:[options]
        v-model="phone"
        @maska="updatePhone"
        @keyup.enter="dial"
      >
    </div>
    <span
      v-if="phone"
      class="phone-button position-absolute cursor-pointer"
      @click="dial"
    >
      <i class="fa-regular fa-phone" />
    </span>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Mask } from "maska";

export default {
  name: "DialingComponent",
  data() {
    return {
      phone: '',
      unmaskedPhoneNumber: '',
      options: {
        mask: [
          '(##) ####-####',
          '(##) #####-####',
          '+## (##) ####-####',
          '+## (##) #####-####'
        ]
      }
    }
  },

  methods: {
  ...mapActions("agent/ring_group", ["makeCallPABX"]),
    pastBtn() {
      navigator.clipboard.readText()
        .then((text) => {
          const mask = new Mask(this.options);
          this.phone = this.phone + text.replace(/\D/g, "");
          this.phone = mask.masked(this.phone);
          this.unmaskedPhoneNumber = mask.unmasked(this.phone);
        })
    },

    dial() {
      this.makeCallPABX(this.unmaskedPhoneNumber);
    },

    updatePhone(event) {
      this.unmaskedPhoneNumber = event.detail.unmasked;
    },
  },
}
</script>

<style lang="scss" scoped>
.dialing-wrapper {
  background-color: #F0F4FA;
  padding: 4px 16px;

  .phone-input {
    width: 200px;
    border: none;
    background-color: #F0F4FA;

    &:focus {
      outline: none;
    }
  }

  .phone-button {
    right: 8px;
  }
}
</style>
