<template>
  <router-view name="main" />
  <update-schedule-modal />
</template>

<script>
import UpdateScheduleModal from './extension/components/update-schedule-modal.vue';

export default {
  components: {
    UpdateScheduleModal,
  },
};
</script>
