<template>
  <div
    class="pt-5"
  >
    <div
      class="align-items-end d-flex justify-content-between"
    >
      <h1 class="m-0">
        {{ $t('funnel') }}
      </h1>
    </div>
    <hr style="color: #B1C2D9">
    <div class="d-flex align-items-center py-5 gap-3 my-3">
      <div
        class="container d-flex flex-column justify-content-center align-items-center my-5"
        style="height: 60vh"
      >
        <img
          alt="empty-funnel"
          class="img-fluid mb-4"
          src="@assets/img/plusinha-thinking.svg"
          width="117"
        >
        <div class="mb-5 text-center">
          <h5 class="mt-2">
            {{ $t('nothing_to_show') }}
          </h5>
          <p class="mb-0 fs-6">
            {{ $t('contact_your_manager') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>
