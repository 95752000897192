import axios from "axios";

export default {

  fetchWebhooks({ commit }) {
    commit('clearErrors');
    commit('startLoadingFlag', 'fetchWebhook');
    commit('setWebhooks', []);

    return new Promise((resolve, reject) => {
      axios.get('/webhook_events').
        then((response) => {
          commit('setWebhooks', response.data.data);
          resolve();
        })
        .catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit('stopLoadingFlag', 'fetchWebhook')
        });
    });
  },

  fetchWebhookById({ commit }, payload) {
    commit('clearErrors')
    commit('startLoadingFlag', 'fetchWebhookById')
    commit('setWebhooks', {});

    return new Promise((resolve, reject) => {
      axios.get('/webhook_events/' + payload).
        then((response) => {
          commit('setWebhooks', response.data.data);
          resolve();
        })
        .catch((response) => {
          commit('setWebhook', []);
          commit('system/setErrorMessage', response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit('stopLoadingFlag', 'fetchWebhookById')
        });
    });
  },

  createWebhook({ commit }, payload) {
    commit('clearErrors');
    commit('startLoadingFlag', 'createWebhook');
    commit('setWebhooks', {});

    return new Promise((resolve, reject) => {
      axios.post('/webhook_events', payload.fields).
        then(() => {
          commit('system/setSuccessMessage', "success_message.create_webhook", { root: true });
          resolve();
        })
        .catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit('stopLoadingFlag', 'createWebhook')
        });
    })
  },

  updateWebhook({ commit, dispatch }, payload) {
    commit('clearErrors');
    commit('startLoadingFlag', 'updateWebhook');

    return new Promise((resolve, reject) => {
      axios.put('/webhook_events/' + payload.id, payload)
        .then(() => {
          commit('system/setSuccessMessage', "success_message.update_webhook", { root: true });
          dispatch('fetchWebhooks');
          resolve();
        })
        .catch((response) => {
          commit("setErrors", response.response.data);
          reject();
        })
        .finally(() => {
          commit('stopLoadingFlag', 'updateWebhook')
        });
    })
  },

  deleteWebhook({ commit, dispatch }, payload) {
    commit('clearErrors')
    commit('startLoadingFlag', 'deleteWebhook');

    return new Promise((resolve, reject) => {
      axios.delete('/webhook_events/' + payload).
        then(() => {
          commit('setWebhook', []);
          dispatch('fetchWebhooks');
          commit('system/setSuccessMessage', "success_message.remove_webhook", { root: true });
        })
        .catch((response) => {
          commit('setErrors', response.response.data);
          reject();
        })
        .finally(() => {
          commit('stopLoadingFlag', 'deleteWebhook')
        });
    });
  }
}
