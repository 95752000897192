<template>
  <VueFlow
    id="chatbot"
    :nodes="nodes"
    :edges="edges"
    @connect-end="onConnectEnd"
    @connect-start="onConnectStart"
    @edge-update="onEdgeUpdate"
    @connect="onConnect"
    @edge-update-start="onEdgeUpdateStart"
    @node-drag-stop="onNodeDragStop"
    @node-mouse-enter="onNodeMouseEnter"
    @node-mouse-leave="onNodeMouseLeave"
    @edge-mouse-enter="onEdgeMouseEnter"
    @edge-mouse-leave="onEdgeMouseLeave"
    @nodes-initialized="layoutGraph"
    connection-mode="Strict"
    :min-zoom="0.01"
    :max-zoom="100"
  >
    <template #node-custom="props">
      <starter-node
        v-bind="props"
        :source-position="props.sourcePosition"
        :target-position="props.targetPosition"
      />
    </template>
    <template #edge-button="buttonEdgeProps">
      <EdgeWithButton
        :id="buttonEdgeProps.id"
        :source-x="buttonEdgeProps.sourceX"
        :source-y="buttonEdgeProps.sourceY"
        :target-x="buttonEdgeProps.targetX"
        :target-y="buttonEdgeProps.targetY"
        :source-position="buttonEdgeProps.sourcePosition"
        :target-position="buttonEdgeProps.targetPosition"
        :marker-end="buttonEdgeProps.markerEnd"
        :style="buttonEdgeProps.style"
      />
    </template>
  </VueFlow>
  <tool-bar @organize="organizeNodesAnimation" />
</template>

<script>
import {VueFlow, MarkerType, useVueFlow} from '@vue-flow/core';
import StarterNode from "@chatbot/pages/workflow/components/nodes/starter-node.vue";
import ToolBar from "@chatbot/pages/workflow/components/tool-bar.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {Utils} from "@/utils";

import EdgeWithButton from "@chatbot/pages/workflow/components/edge-with-button.vue";

import {useLayout} from "@chatbot/Utils";
import {nextTick} from "vue";

const { addEdges, addNodes, project , findNode, removeEdges, fitView} = useVueFlow('chatbot' )

export default {
  components: {
    EdgeWithButton,
    VueFlow,
    StarterNode,
    ToolBar,
  },
  data() {
    return {
      draggingEdgeSource: null,
      connected: false,
      handleId: null,
      organized: false,
      edgeHover: null,
      isAutoLayout: false,
      hoveredNodeId: null,
      vueFlowNodes:[],
      vueFlowEdges:[],
    };
  },
  methods: {
    ...mapActions("chatbot/chatbots", ["fetchChatbot"]),
    ...mapActions("chatbot/actions", ["updateAction", "updateActionEdge", "removeConnection", "updateCoordinates"]),
    ...mapMutations("chatbot/actions", ["setPrevActionId",
      "setNodesPositions",
      "addAction",
      "removeAction",
      "setActions",
      "setHoveredEdgeId",
    ]),

    onEdgeMouseEnter({edge}){
      this.setHoveredEdgeId(edge.id);
    },
    onEdgeMouseLeave(){
      this.setHoveredEdgeId(null);
    },
    onNodeMouseEnter(event) {
      this.hoveredNodeId = event?.node?.id;
    },

    onNodeMouseLeave() {
      this.hoveredNodeId = null;
    },

    organizeNodesAnimation(){
      this.isAutoLayout = true;
    },

    onNodeDragStop({node}) {
      const updatedNodePositions = this.nodesPositions.map((itemNode) => {
        if (itemNode.id === node.id) {
          return {
            id: node.id,
            position: node.position,
          };
        }
        return itemNode;
      });
      this.setNodesPositions(updatedNodePositions);
      let payload={
        x_coordinate: node.position.x,
        y_coordinate: node.position.y
      }
     if(!node.data.content.need_channel && !['back', 'transfer', 'restart', 'finish', 'default'].includes(node.data.content.type)){
        this.updateAction({actionId: node.id, payload})
       }
    },

    onEdgeUpdateStart() {
      this.updatingEdge = true
    },

    onEdgeUpdate({connection}) {
      this.connected = true;
      let payload = {
        source: connection.source,
        target: connection.target,
      }
      this.updateActionEdge(payload).then(() => {
         this.fetchChatbot(this.$route.params.id)
        this.updatingEdge = false
      })
    },

    onConnectStart(event) {
      let eventNode = findNode(event.nodeId);
      if(eventNode.data.content.next_action_id && !this.updatingEdge){
        let eventEdge = this.edges.find(edge => edge.source === event.nodeId);
        this.removeEdge(eventEdge)
      }
      this.handleId = event.handleId;
      this.draggingEdgeSource = event.nodeId;
      this.connected = false;
    },

    onConnect(event) {
      let action = this.actions.find((action) => action.id.toString() === event.source)
      this.connected = true;
      const { source, target, } = event;
      if (!source || !target) {
        return;
      }
      if(event.source.split('-')[0] === event.target){
        return;
      }
      let sourceId = action.type === "multiple_choice" ?   event.sourceHandle.split('-')[1] : event.source.split('-')[0]
      this.updateAction({actionId: sourceId, payload: {next_action_id: event.target}})
    },

    onConnectEnd(event) {
      if(this.hoveredNodeId && this.draggingEdgeSource !== this.hoveredNodeId && !this.updatingEdge){
        let action = this.actions.find((action) => action.id.toString() === this.draggingEdgeSource)
        let sourceId = action.type === "multiple_choice" ?   this.handleId.split('-')[1] : this.draggingEdgeSource
        this.updateAction({actionId: sourceId, payload: {next_action_id: this.hoveredNodeId}})
        return
      }
      if (this.draggingEdgeSource && !this.connected && !this.updatingEdge) {
        this.generateDefaultNode(event);
      }
    },

    removeEdge(deleteEdge){
      let id = deleteEdge.id

        let sourceNode = findNode(deleteEdge.source)
        let targetNode = findNode(deleteEdge.target)


        if(sourceNode.data.content.type === 'multiple_choice'){
          sourceNode.id = deleteEdge.sourceHandle.split("-")[1];
        }

        if(['restart', 'back', 'finish'].includes(targetNode.data.content.type)){
          this.updateAction({
            actionId: sourceNode.id,
            payload: {
              default_next_action: "",
            }
          })
          removeEdges(id)
          return;
        }
        if(targetNode.data.content.type === 'transfer'){
          this.updateAction({
            actionId: sourceNode.id,
            payload: {
              transfer_to_group_channel_id: "",
            }
          })
          removeEdges(id)
          return;
        }

        let payload = {
          previous_action_id: deleteEdge.source,
          next_action_id: deleteEdge.target
        }
        this.removeConnection({payload});
        removeEdges(id)

    },

    generateDefaultNode(event){
      const newNodeId = Utils.generateUniqueID();
      const newNodePosition = project({ x: event.clientX, y: event.clientY });

      let updatedNodePositions = this.nodesPositions || [];

      updatedNodePositions.push({ id: newNodeId, position: newNodePosition });

      this.setNodesPositions(updatedNodePositions);
      const newNode = {
        id: newNodeId,
        data: {
          content: {
            id: newNodeId,
            type: 'default',
            previous_action_id: this.handleId ? this.handleId.split('-')[1]:this.draggingEdgeSource,
            position: newNodePosition
          }
        },
        type: 'custom',
        position: newNodePosition,
      };

      this.nodesPositions.push({ id: newNodeId, position: newNodePosition });
      addNodes(newNode);

      const newEdge = {
        id: `edge-${newNodeId}`,
        source: this.draggingEdgeSource,
        target: newNodeId,
        type: 'default',
        markerEnd: MarkerType.Arrow,
        sourceHandle: this.handleId ? this.handleId : null,
      };
      addEdges(newEdge);
      this.handleId = null;
    },

    generateNodes(){
      return this.actions.flatMap(action => {
        const nodes = [];
        const transitionStyle = this.isAutoLayout ? 'transition-active' : ''
        let actionPosition = null;
        if(action.type === 'multiple_choice_item'){
          actionPosition = {
            x: parseInt(this.actions.find(actionParent => actionParent.id === action.chatbot_action_id)?.x_coordinate,10) || 0,
            y: parseInt(this.actions.find(actionParent => actionParent.id === action.chatbot_action_id)?.y_coordinate,10) + (parseInt(action.expected_message_response) * 100)|| 0,
          }
        }
        else {
         actionPosition = {
            x: parseInt(action?.x_coordinate, 10) || action?.position?.x || 0,
            y: parseInt(action?.y_coordinate, 10) || action?.position?.y || 0
          }
        }

        if (action.type === 'default') {
          nodes.push({
            id: action.id,
            data: {content: action},
            type: 'custom',
            position: action.position,
            class: transitionStyle
          });
        }

        if (action.type !== 'multiple_choice_item') {
          const actionId = action.id ? action?.id.toString() : Utils.generateUniqueID();
          nodes.push({
            id: actionId,
            data: {content: action},
            type: 'custom',
            position: actionPosition,
            class: transitionStyle
          });
        }

        if(action.transfer_to_group_channel_id){
          const transferId = `${action.id}-transfer`;
          nodes.push({
            id: transferId,
            data: {content: {parentId: action.id,
                id: transferId,
                type: "transfer",
                transfer_to_group_channel_id: action.transfer_to_group_channel_id,}},
            type: 'custom',
            position: actionPosition,
            class: transitionStyle
          });
        }

        if (action.default_next_action) {
          const nextActionId = `${action.id}-${action.default_next_action}`;
          const backId = action.default_next_action === 'back' ? action.next_action_id : null;
          nodes.push({
            id: nextActionId,
            data: {content: {parentId: action.id,
                id: `${action.id}-${action.default_next_action}`,
                type: action.default_next_action,
                nextActionId:backId}},
            type: 'custom',
            position: {
              x:actionPosition.x+450,
              y:actionPosition.y
            },
            class: transitionStyle
          });
        }

        return nodes;
      }).filter(node => node);
    },
    generateEdges(){
      return this.actions
          .filter(action => action.next_action_id || action.default_next_action || action.transfer_to_group_channel_id)
          .flatMap(action => {
            const edges = [];

            const source = action.type === "multiple_choice_item"
                ? action.chatbot_action_id?.toString()
                : action.id?.toString();

            const handleSource = action.type === "multiple_choice_item"
                ? `handle-${action.id}`
                : `source-${action.id}`;

            const nextAction = action.default_next_action === "back"
                ? `${action.id}-back`
                : action.default_next_action
                    ? `${action.id}-${action.default_next_action}`
                    : action.transfer_to_group_channel_id
                        ? `${action.id}-transfer`
                        : action.next_action_id?.toString();
            const updatable = action.default_next_action === null && action.transfer_to_group_channel_id === null;

            if (source && nextAction) {
              edges.push({
                id: `edge-${source}-${nextAction}`,
                source: source,
                target: nextAction,
                sourceHandle: handleSource,
                type: 'button',
                markerEnd: {
                  type: MarkerType.Arrow,
                  width: 20,
                  height: 20,
                  strokeWidth: 1.5,
                },
                updatable: updatable,
                style: { strokeWidth: 2, stroke: 'AFAFAF', transition: this.isAutoLayout? 'd 0.5s ease-in-out':''},
                zIndex:1,
              });
            }

            return edges;
          });
    },
    layoutGraph(){
      if(this.starterAction.x_coordinate){
        return;
      }
      if(this.starterAction?.need_channel || this.organized){
        return;
      }
      const {nodes, edges} = useVueFlow('chatbot');
      const {layout} = useLayout()
      const updatedNodes = layout(nodes.value, edges.value, 'LR')
      const nodesPositions = updatedNodes.flatMap((node) => {
        if(!['back', 'transfer', 'restart', 'finish'].includes(node.data.content.type) )
          return {
            action_id: parseInt(node.id,10),
            x_coordinate: node.position.x,
            y_coordinate: node.position.y
          };
        return [];
      });
      let payload = {
        coordinates:nodesPositions
      }
      this.updateCoordinates({payload}).then(()=>{
        this.fetchChatbot(this.$route.params.id);
        this.organized = true;
      })
      nextTick(() => {
        fitView()
      })
    }
  },

  computed: {
    ...mapGetters("chatbot/actions", {
      actions: "getActions",
      prevActionId: "getPrevActionId",
      nodesPositions: "getNodesPositions",
      selectedAction: "getSelectedAction"
    }),
    nodes() {
      return this.vueFlowNodes;
    },

    edges() {
      return this.vueFlowEdges;
    },
    starterAction(){
      return this.actions.find(action=>action.type==='starter') || null;
    }
  },
   created() {
    if(this.$route.params.id) {
      this.fetchChatbot(this.$route.params.id);
    }
  },
  unmounted() {
    this.setNodesPositions([])
    this.setActions([])
  },
  watch:{
    actions:{
      handler(){
        if(this.actions){
          this.vueFlowNodes = this.generateNodes();
          this.vueFlowEdges = this.generateEdges();
          if(this.isAutoLayout) {
            setTimeout(() => {
              this.isAutoLayout = false;
            }, 1000);
          }
        }
      },
      deep:true
    },
    isAutoLayout(){
      if(!this.isAutoLayout) {
        this.vueFlowNodes = this.generateNodes();
        this.vueFlowEdges = this.generateEdges();
      }
    }
  }
};
</script>
<style >
.transition-active {
  transition: transform 0.5s ease-in-out;
}

.vue-flow__node.selected {
  .card-question, .card-choice, .card-message {
    border: 1px solid #5CB868;
  }
}
.vue-flow__node.selected {
  .card-next-action, .card-starter {
    border: 1px solid #3057F2;
  }
}
.vue-flow__node.selected {
  .card-sdr{
    border: 1px solid #7745EB;
  }
}

</style>