<script>
import {Offcanvas} from "bootstrap";
import ProfileActionButtons from "@dialer/manager/components/sidebar/profile-action-buttons.vue";
import menu from "@dialer/manager/menu-config.js";
import {mapGetters} from "vuex";

export default {
  name: "MobileManagerMenu",
  components: {ProfileActionButtons},
  props: {
    menuId: {
      type: String,
      default: "mobileManagerMenu"
    },

    showOffCanvas: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      menu: [],
      offCanvas: null,
      items: [
        {
          id: this.generateId(),
          name: "3C Voice",
          icon: "fa-regular fa-globe",
          path: "/manager/3c-voice",
          external: false
        },
        {
          id: this.generateId(),
          name: "SMS",
          icon: "fa-regular fa-message-dots",
          path: "/manager/sms-dashboard",
          external: false
        },
        {
          id: this.generateId(),
          name: "Públicos",
          icon: "fa-regular fa-chart-user",
          path: "/manager/audience",
          external: false
        },
        {
          id: this.generateId(),
          name: "Chatbot",
          icon: "fa-regular fa-robot",
          path: "/manager/chatbot",
          external: false
        },
        {
          id: this.generateId(),
          name: "Insights IA",
          icon: "fa-regular fa-microchip-ai",
          path: "/manager/insights-ai",
          external: false
        },
        {
          id: this.generateId(),
          name: "Indique e ganhe",
          icon: "fa-regular fa-handshake",
          path: "https://3cplusnow.com/partner",
          external: true
        },
        {
          id: this.generateId(),
          name: "Relatórios",
          icon: "fa-regular fa-chart-pie",
          path: "/manager/reports",
          external: false
        },
      ]
    }
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser"
    }),
    ...mapGetters("system", {
      userRects: "getUserRects"
    })
  },


  methods: {
    generateId() {
      return Math.random().toString(36).substring(2, 9);
    },

    openOffcanvas() {
      this.offCanvas.show();
    },

    closeOffcanvas() {
      this.offCanvas.hide();
    }
  },

  mounted() {
    menu.bottom = menu.bottom.filter(item => item.path !== "/manager/reports");
    this.menu = menu;
    this.offCanvas = new Offcanvas(document.getElementById(this.menuId));
  },

  watch: {
    showOffCanvas: {
      immediate: true,
      handler(show) {
        if (this.offCanvas) {
          if (show) {
            this.openOffcanvas();
            return
          }

          this.closeOffcanvas();
        }
      }
    },
  }
}
</script>

<template>
  <div
    :style="{
      height: `${userRects.height}px`,
      top: 0,
      bottom: 'auto'
    }"
    class="offcanvas offcanvas-bottom"
    tabindex="-1"
    :id="menuId"
    :aria-labelledby="`${menuId}Label`"
  >
    <div class="offcanvas-body d-flex flex-column p-0">
      <ul class="list-unstyled mb-0 col">
        <li>
          <router-link
            class="d-block mobile-menu-list-header"
            to="/"
          >
            <img
              alt="Logo"
              width="65"
              height="35"
              loading="lazy"
              src="@assets/img/logo.svg"
              class="d-inline-block"
            >
          </router-link>
        </li>
        <li
          v-for="(item, index) in items"
          :key="`item-${index}`"
        >
          <template v-if="item.external">
            <a
              class="mobile-menu-list-item external-link"
              :href="item.path"
              rel="noreferrer"
              target="_blank"
            >
              <div class="mobile-menu-icon">
                <i :class="item.icon" />
              </div>
              <span class="d-inline-block">{{ item.name }}</span>
            </a>
          </template>
          <template v-else>
            <router-link
              class="mobile-menu-list-item"
              :to="{
                path: item.path
              }"
            >
              <div class="mobile-menu-icon">
                <i :class="item.icon" />
              </div>
              <span class="d-inline-block">{{ item.name }}</span>
            </router-link>
          </template>
        </li>
      </ul>
      <div class="profile-action-buttons">
        <profile-action-buttons
          class="m-0"
          :menu="menu"
          :user="user"
          expanded
          :expand="false"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.mobile-menu {
  &-list {
    &-header {
      padding: 1.5rem 2rem 1rem 2rem;
    }

    &-item {
      padding: 0.75rem 1.5rem;
      display: flex;
      max-width: 100%;
      width: 100%;
      gap: 0.5rem;
      font-size: 1rem;
      color: $color-text-gray;
      align-items: center;
      line-height: 1;
      transition: all 0.25s ease-in-out;

      //router-link-exact-active
      &.router-link-active,
      &:active,
      &.external-link {
        color: $color-blue-300;
      }
    }
  }

  &-icon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    max-width: 24px;
    aspect-ratio: 1/1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}

:deep(.profile-action-buttons) {
  .nav-link {
    display: flex;
    align-items: center;
    color: $color-text-gray;
    padding: 0.75rem 1.5rem !important;
  }
}
</style>