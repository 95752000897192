<template>
  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="offcanvasSelectChannel"
    aria-labelledby="offcanvasSelectChannelLabel"
    ref="offcanvas"
  >
    <div class="offcanvas-header">
      <h5
        class="offcanvas-title"
        id="offcanvasSelectChannelLabel"
      >
        {{ $t('edit_trigger') }}
      </h5>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        @click="offcanvas.hide()"
      />
    </div>
    <div class="offcanvas-body d-flex flex-column">
      <div
        class="col-auto badge-blue"
      >
        {{ $t('start') }}
      </div>
      <div class="col-auto">
        <label
          for="groupChannel"
          class="form-label"
        >{{ $t('select_channel_group') }}</label>
        <single-select
          id="groupChannel"
          label="name"
          :list-array="groupChannels.filter(group => group.is_connected)"
          :current-option="groupChannel?.name || null"
          v-model="groupChannel"
          :show-badge="'chatbot_id'"
          @selected-option="handleSelectedOption"
        >
          <template #badge>
            <span
              class="m-0 badge-blue-select m-0 d-flex align-items-center justify-content-center fw-medium"
              style=""
            >
              {{ $t('already_have_chatbot') }}
            </span>
          </template>
        </single-select>
      </div>
      <div class="col" />
      <app-button
        :disabled="!groupChannel || loadingFlags.createStarterAction || loadingFlags.updateAction"
        @click="saveGroupChannel"
        class="w-100"
      >
        {{ $t('capitalizes.save') }}
      </app-button>
    </div>
  </div>
</template>

<script>
import AppButton from "@components/app-button.vue";
import { Offcanvas } from "bootstrap";
import {mapActions, mapGetters, mapMutations} from "vuex";
import SingleSelect from "@components/single-select.vue";

export default {
  name: "OffcanvasSelectChannel",

  emits:["close"],

  components: {
    SingleSelect,
    AppButton
  },

  props:{
    show: {
      type: Boolean,
      default: false,
    }
  },

  data(){
    return{
      offcanvas: null,
      groupChannel: ""
    }
  },

  mounted(){
    this.offcanvas = new Offcanvas(this.$refs.offcanvas)
    this.$refs.offcanvas.addEventListener("hide.bs.offcanvas", this.onHideOffcanvas);
  },

  computed:{
    ...mapGetters("chatbot/actions", {
      actions: "getActions",
      groupChannels: "getGroupChannels",
      loadingFlags: "getLoadingFlags"
    }),
    ...mapGetters("chatbot/chatbots", {
      chatbot: "getChatbot",
    }),
  },

  methods:{
    ...mapActions("chatbot/actions", [
      "createStarterAction",
        "updateAction"
    ]),
    ...mapActions("chatbot/chatbots", [
      "fetchChatbot"
    ]),
    ...mapMutations("chatbot/actions", ["setOffcanvasSelectChannelIsOpen"]),

    onHideOffcanvas() {
      this.setOffcanvasSelectChannelIsOpen(false)
      this.$emit("close");
    },

    saveGroupChannel(){
      if(!this.chatbot?.group_channel_name) {
        let payload = this.actions[0]
        payload.group_channel_id = Number(this.groupChannel.id)

        this.createStarterAction(payload).then(() => {
          this.offcanvas.hide()
          this.fetchChatbot(this.$route.params.id);
        })
        return;
      }
      let payload={
        group_channel_id: this.groupChannel.id
      }
      let actionId = this.chatbot.actions.data.find(action => action.type === 'starter').id
      this.updateAction({actionId,payload}).then(()=>{
        this.offcanvas.hide()
        this.fetchChatbot(this.$route.params.id);
      })
    },
    handleSelectedOption(option){
      this.groupChannel=option
    }
  },

  watch: {
    show(value){
      value? this.offcanvas.show() : this.offcanvas.hide()
      if(this.chatbot?.id.toString() === this.$route.params.id){
        this.groupChannel = this.groupChannels.find(group => group.name === this.chatbot.group_channel_name);
      }
      else {
        this.groupChannel= null;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.badge-blue{
  width: 68px;
  padding: 8px 16px;
  border-radius: 10px;
  background-color: #E1E9F4;
  color: #1A4A89;
  margin-bottom: 16px
}
.badge-blue-select{
  font-size: 10px;
  border-radius: 10px;
  background-color: #E1E9F4;
  color: #1A4A89;
  height: 20px;
  min-width: 120px
}
</style>
