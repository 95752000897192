<script setup>
import {computed} from "vue"
import {useStore} from "vuex";
import {useDashboardQuery} from "@/composables/useDashboardQuery";
import DashCard from "@crm/pages/dashboard/components/dash-card.vue";
import {Utils} from "@/utils";
import TootltipInfo from "@crm/pages/dashboard/components/tootltip-info.vue";

const store = useStore();
const dateRange = computed(() => store.getters["crm/dashboard/getDateRange"])
const currentFunnel = computed(() => store.getters["crm/funnels/getCurrentFunnel"] ?? null);
const agent = computed(() => store.getters["crm/dashboard/getActiveAgent"] ?? null);

const {data, loading} = useDashboardQuery(
  currentFunnel,
  dateRange,
  dateRange,
  agent,
  'onGoingOpportunities'
);

</script>

<script>
export default {
  name: "OnGoingOpportunities",
  methods: {
    toggleCurrency() {
      let locale = 'en';
      let currency = 'USD';

      if (this.$i18n.locale === 'pt') {
        locale = 'pt-BR';
        currency = 'BRL';
      }

      return {
        locale,
        currency
      }
    }
  }
}
</script>

<template>
  <dash-card
    :title="$t('on_going_opportunities')"
    :tooltip-title="$t('tooltips.dashboard.on_going_opportunities.title')"
    tooltip-width="172px"
    :loading="loading"
    min-height="72"
  >
    <template #body>
      <div class="d-flex justify-content-between align-items-center">
        <div class="lh-1">
          <span
            style="font-size: 28px"
            class="d-block tx-text-200 fw-medium"
          >
            {{ data?.opportunities }}
          </span>
          <span
            style="font-size: 0.75rem"
            class="tx-text-gray fw-medium text-uppercase"
          >
            Oportunidades
          </span>
        </div>
        <div class="lh-1">
          <span
            style="font-size: 28px"
            class="d-block tx-text-200 fw-medium"
          >
            {{
              Utils.formatCurrency(data?.value, toggleCurrency().locale, toggleCurrency().currency, {
                notation: 'compact',
                compactDisplay: 'short'
              })
            }}
          </span>
          <span
            style="font-size: 0.75rem"
            class="tx-text-gray fw-medium text-uppercase"
          >
            No Funil
          </span>
        </div>
        <div
          style="padding: 1rem"
          class="lh-1 rounded bg-light"
        >
          <tootltip-info
            class="ms-3"
            :title="$t('tooltips.dashboard.on_going_opportunities_opportunities.title')"
            :body="$t('tooltips.dashboard.on_going_opportunities_opportunities.body')"
            min-width="387px"
            placement="left"
          />
          <span
            style="font-size: 28px"
            class="d-block tx-text-200 fw-medium"
          >
            {{ Utils.formatCurrency(data?.estimated_gain, toggleCurrency().locale, toggleCurrency().currency, {
              notation: 'compact',
              compactDisplay: 'short'
            }) }}
          </span>
          <span
            style="font-size: 0.75rem"
            class="tx-text-gray fw-medium text-uppercase"
          >
            Oportunidades
          </span>
        </div>
      </div>
    </template>
  </dash-card>
</template>

<style scoped lang="scss">

</style>