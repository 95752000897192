<template>
  <div class="d-flex align-items-center gap-1 break-word">
    <slot />
    <button 
      v-if="close"
      class="btn"
    >
      <span        
        class="far fa-close"
        @click="$emit('deleteQuote')"
      />
    </button>
  </div>
</template>

<script>
export default {
  emits: ["deleteQuote"],

  props: {
    close: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  width: 2.25rem;
}
.break-word {
  word-break: break-word
}
</style>
