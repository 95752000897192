<template>
  <div class="main-content">
    <div class="container-fluid">
      <validate-sms-off-canvas ref="validateSmsOffCanvas" />
      <header-component :title="$t('sms_validation')">
        <template #buttons>
          <div v-if="activeTab == 'finalized'">
            <input-date-range
              :search-on-close="true"
              @close="submitInputDataRange()"
              v-model:start="startDate"
              v-model:end="endDate"
              format-date="d/m/Y"
            />
          </div>
        </template>
      </header-component>

      <div
        class="tab-pane fade show active"
        id="contactsListPane"
        role="tabpanel"
        aria-labelledby="contactsListTab"
      >
        <div class="col-lg-12">
          <div class="card">
            <div
              class="card-header"
              style="border-bottom: none"
            >
              <search-input
                class="b4"
                @search="search"
              />
              <div>
                <div
                  v-if="this.activeTab == 'underReview'"
                  class="dropdown"
                >
                  <!-- TODO: refatorar style -->
                  <button
                    @click="companySelected = !companySelected"
                    class="btn btn-sm dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    :style="
                      companySelected
                        ? {
                          'background-color':
                            'rgba(44, 123, 229, 1) !important',
                          color: 'white'
                        }
                        : {
                          'background-color':
                            'rgba(227, 235, 246, 0.5) !important'
                        }
                    "
                  >
                    Empresas
                  </button>
                  <div
                    class="dropdown-menu px-2 py-0"
                    style="min-width: 200px"
                  >
                    <div class="dropdown-item px-0">
                      <div
                        class="input-group input-group-merge input-group-reverse"
                      >
                        <input
                          class="form-control list-search"
                          type="search"
                          placeholder="Pesquisar"
                          v-model="termCompany"
                        >
                        <div class="input-group-text">
                          <i class="fe fe-search" />
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="item in this.underReviewCompanyFiltered"
                      :key="item.company.data.id"
                      class="dropdown-item px-3 pb-3"
                      @click="filterByCompany(item.company.data.id)"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="flexRadioDefault"
                        id="radioAgent"
                      >
                      <span
                        class="form-label mx-2"
                        href="#"
                      >{{
                        item.company.data.name
                      }}</span>
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="this.activeTab == 'finalized'"
                  class="dropdown"
                >
                  <button
                    @click="companySelected = !companySelected"
                    class="btn btn-sm dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    :style="
                      companySelected
                        ? {
                          'background-color':
                            'rgba(44, 123, 229, 1) !important',
                          color: 'white'
                        }
                        : {
                          'background-color':
                            'rgba(227, 235, 246, 0.5) !important'
                        }
                    "
                  >
                    Empresas
                  </button>
                  <div
                    class="dropdown-menu w-100 px-2 py-0"
                    style="min-width: 200px"
                  >
                    <div class="dropdown-item px-0">
                      <div
                        class="input-group input-group-merge input-group-reverse"
                      >
                        <input
                          class="form-control list-search"
                          type="search"
                          placeholder="Pesquisar"
                          v-model="termCompany"
                        >
                        <div class="input-group-text">
                          <i class="fe fe-search" />
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="item in this.finalizedReviewCompanyFiltered"
                      :key="item.company.data.id"
                      class="dropdown-item px-3 pb-3 cursor-pointer"
                      @click="filterByCompany(item.company.data.id)"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="flexRadioDefault"
                        id="radioAgent"
                      >
                      <span
                        class="form-label mx-2"
                        href="#"
                      >{{
                        item.company.data.name
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <ul
                class="nav nav-tabs nav-tabs-sm card-header-tabs"
                style="margin-left: 36px"
              >
                <li class="nav-item">
                  <button
                    @click="activeTab = 'underReview'"
                    class="nav-link active two-lines"
                    ref="underReviewTab"
                    role="tab"
                    data-bs-toggle="tab"
                    data-bs-target="#underReview"
                  >
                    <span class="text-muted"> EM ANÁLISE </span>
                    <br>
                    <span class="subtitle">
                      {{ this.totalUnderReview }}
                    </span>
                  </button>
                </li>
                <li class="nav-item">
                  <button
                    @click="activeTab = 'finalized'"
                    class="nav-link two-lines"
                    ref="finalizedTab"
                    role="tab"
                    data-bs-toggle="tab"
                    data-bs-target="#finalized"
                  >
                    <span class="text-muted"> FINALIZADOS </span>
                    <br>
                    <span class="subtitle">
                      {{ this.totalFinalizedReview }}
                    </span>
                  </button>
                </li>
              </ul>
            </div>
            <div class="card-body px-0 py-0">
              <div class="tab-content">
                <div
                  role="tabpanel"
                  id="underReview"
                  class="table-responsive tab-pane active"
                >
                  <table class="table table-hover table-nowrap card-table mb-0">
                    <thead>
                      <tr class="col-12">
                        <th>
                          <a
                            class="list-sort text-muted fw-normal col-2"
                            href="#"
                          >ID</a>
                        </th>
                        <th>
                          <a
                            class="list-sort text-muted fw-normal col-2"
                            href="#"
                          >EMPRESA</a>
                        </th>
                        <th>
                          <a
                            class="list-sort text-muted fw-normal col-2"
                            href="#"
                          >DATA</a>
                        </th>
                        <th>
                          <a
                            class="list-sort text-muted fw-normal col-2"
                            href="#"
                          >TÍTULO</a>
                        </th>
                        <th>
                          <a
                            class="list-sort text-muted fw-normal col-2"
                            href="#"
                          >MAILING</a>
                        </th>
                        <th>
                          <a
                            class="list-sort text-muted fw-normal col-2"
                            href="#"
                          >STATUS</a>
                        </th>
                      </tr>
                    </thead>

                    <tbody class="list fs-base">
                      <tr
                        v-if="
                          underReviewMailings.length == 0 &&
                            !loadingFlags.findUnderReviewMailings
                        "
                      >
                        <td
                          class="text-center text-muted"
                          colspan="6"
                        >
                          <span class="fe fe-alert-octagon" /> Nenhuma lista de
                          Mailing encontrada
                        </td>
                      </tr>
                      <tr v-if="loadingFlags.findUnderReviewMailings">
                        <td
                          class="p-0 loading"
                          v-for="i in 6"
                          :key="i"
                        >
                          <table-loading :rows="6">
                            <div class="cell col" />
                          </table-loading>
                        </td>
                      </tr>

                      <template
                        v-for="item in underReviewMailings"
                        :key="item.id"
                        v-else
                      >
                        <tr class="cursor-pointer">
                          <td class="col-2">
                            {{ item.company.data.id }}
                          </td>
                          <td class="col-2">
                            {{ item.company.data.name }}
                          </td>
                          <td class="col-2">
                            {{ formatDate(item.start_date) }}
                          </td>
                          <td class="col-2">
                            {{ item.name }}
                          </td>
                          <td class="col-2">
                            <button
                              @click="downloadMailing(item.id)"
                              class="btn btn-sm btn-outline-primary"
                              style="border-radius: 10px; min-width: 100px"
                            >
                              <div
                                v-if="
                                  selectedItem == item.id &&
                                    loadingFlags.downloadMailingSms
                                "
                                class="spinner-border spinner-border-sm"
                              >
                                <span class="visually-hidden">Carregando...</span>
                              </div>
                              <div v-else>
                                <i class="fe fe-download" />
                                <span>
                                  Baixar
                                </span>
                              </div>
                            </button>
                          </td>
                          <td class="col-2">
                            <span
                              @mouseover="selectItem(item.id)"
                              @mouseleave="unSelectItem()"
                              @click="openModalAndSetId(item.id)"
                              v-if="selectedItem == item.id"
                              style="width: 8em"
                              class="badge bg-success cursor-pointer  py-2 fs-5"
                            >Finalizar</span>
                            <span
                              @mouseover="selectItem(item.id)"
                              @mouseleave="unSelectItem()"
                              v-else
                              style="width: 8em"
                              class="badge bg-primary-soft py-2 fs-5"
                            >Em análise</span>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                  <div
                    class="card-footer"
                    v-if="
                      !loadingFlags.underReviewMailings &&
                        underReviewMailings.length
                    "
                  >
                    <div class="col-lg-12">
                      <paginator
                        class=""
                        :show-quantity="true"
                        :pagination="underReviewPagination"
                        :recently-quantity="this.underReviewPagination.per_page"
                        @change-page="changePage"
                        @change-quantity="changeQuantityOfPages"
                      />
                    </div>
                  </div>
                </div>

                <div
                  role="tabpanel"
                  id="finalized"
                  class="table-responsive tab-pane"
                >
                  <table class="table table-hover table-nowrap card-table">
                    <thead>
                      <tr>
                        <th>
                          <a
                            class="list-sort text-muted fw-normal col-2"
                            href="#"
                          >ID</a>
                        </th>
                        <th>
                          <a
                            class="list-sort text-muted fw-normal col-2"
                            href="#"
                          >EMPRESA</a>
                        </th>
                        <th>
                          <a
                            class="list-sort text-muted fw-normal col-2"
                            href="#"
                          >INÍCIO</a>
                        </th>
                        <th>
                          <a
                            class="list-sort text-muted fw-normal col-2"
                            href="#"
                          >FIM</a>
                        </th>
                        <th>
                          <a
                            class="list-sort text-muted fw-normal col-2"
                            href="#"
                          >AVALIADOR</a>
                        </th>
                        <th>
                          <a
                            class="list-sort text-muted fw-normal col-2"
                            href="#"
                          >STATUS</a>
                        </th>
                      </tr>
                    </thead>

                    <tbody class="list fs-base">
                      <tr
                        v-if="
                          finalizedReviewMailings.length === 0 &&
                            !loadingFlags.findFinalizedReviewMailings
                        "
                      >
                        <td
                          class="text-center text-muted"
                          colspan="7"
                        >
                          <span class="fe fe-alert-octagon" /> Nenhum SMS
                          Finalizado encontrado
                        </td>
                      </tr>
                      <template v-if="loadingFlags.findFinalizedReviewMailings">
                        <td
                          class="p-0 loading"
                          v-for="i in 6"
                          :key="i"
                        >
                          <table-loading :rows="6">
                            <div class="cell col" />
                          </table-loading>
                        </td>
                      </template>

                      <template
                        v-for="item in finalizedReviewMailings"
                        :key="item.id"
                        v-else
                      >
                        <tr
                          class="cursor-pointer"
                          @mouseover="selectItem(item.id)"
                          @mouseleave="unSelectItem()"
                          @click="openOffCanvas(item)"
                        >
                          <td class="col-2">
                            {{ item.company.data.id }}
                          </td>
                          <td class="col-2">
                            {{ item.company.data.name }}
                          </td>
                          <td class="col-2">
                            {{ formatDate(item.start_date) }}
                          </td>
                          <td class="col-2">
                            {{ formatDate(item.end_date) }}
                          </td>
                          <td class="col-2">
                            {{ item.evaluator?.name }}
                          </td>
                          <td class="col-2">
                            <span
                              v-if="item.status == 2"
                              class="badge bg-success-soft px-4 py-2 fs-5"
                            >Aprovado</span>
                            <span
                              v-if="item.status == 3"
                              class="badge bg-danger-soft px-4 py-2 fs-5"
                            >Recusado</span>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>

                  <div
                    class="col-lg-12"
                    v-if="
                      !loadingFlags.finalizedReviewMailings &&
                        finalizedReviewMailings.length
                    "
                  >
                    <paginator
                      v-if="!loadingFlags.finalizedReviewMailings"
                      :show-quantity="true"
                      :pagination="finalizedReviewPagination"
                      :recently-quantity="
                        this.finalizedReviewPagination.per_page
                      "
                      @change-page="changePage"
                      @change-quantity="changeQuantityOfPages"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      ref="finalizeModal"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content align-items-center">
          <div class="col-12">
            <h1 class="form-label fw-light text-center my-3 ms-4">
              Finalizar análise
              <button
                type="button"
                class="btn-close float-end pt-0"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </h1>
          </div>
          <div class="col-lg-11 col-11">
            <label class="form-label h3 fw-normal mt-5">Status</label>
            <div class="d-flex justify-content-start mb-3">
              <span
                @click="fields.status = 2"
                class="mx-2 px-4 py-2 cursor-pointer fs-5"
                :class="
                  fields.status == 2
                    ? 'badge bg-success-soft'
                    : 'badge bg-secondary-soft'
                "
              >Aprovado</span>
              <span
                @click="fields.status = 3"
                class="mx-2 px-4 py-2 cursor-pointer fs-5"
                :class="
                  fields.status == 3
                    ? 'badge bg-danger-soft'
                    : 'badge bg-secondary-soft'
                "
              >Recusado</span>
            </div>
          </div>
          <div class="col-lg-11 col-11">
            <label class="form-label h3 fw-normal mt-2">Motivo</label>
            <div class="d-flex justify-content-start mb-3">
              <div class="form-floating col-12">
                <div class="card">
                  <textarea
                    class="form-control form-control-flush px-2"
                    v-model="fields.reason"
                    data-autosize
                    :class="{ 'text-danger': this.fields.reason.length > 250 }"
                    rows="3"
                  />
                  <div class="d-flex justify-content-end">
                    <span
                      class=" mx-2 mb-1"
                      :class="
                        this.fields.reason.length > 250
                          ? 'text-danger'
                          : 'text-muted'
                      "
                    >
                      {{ this.fields.reason.length }}/250</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="fields.status == '3'"
            class="col-lg-11 col-11"
          >
            <div class="d-flex ms-3 mb-5">
              <div class="form-check form-switch mx-2">
                <input
                  v-model="fields.blocked_sms"
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  ref="blockedSwitch"
                >
              </div>
              Bloquear SMS da empresa
            </div>
          </div>
          <div class="col-lg-11 col-11 mb-5">
            <button
              @click="submit()"
              class="btn btn-primary col-12"
            >
              Finalizar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from "bootstrap";
import _ from "lodash";
import moment from "moment";

import TableLoading from "@admin/components/table-loading.vue";
import SearchInput from "@components/search-input.vue";
import Paginator from "@components/app-paginator.vue";
import InputDateRange from "@components/input-date-range.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

import ValidateSmsOffCanvas from "./components/validate-sms-offcanvas.vue";
import HeaderComponent from "@/components/header-component.vue";

export default {
  data() {
    return {
      fields: {
        id: null,
        reason: "",
        status: null,
        blocked_sms: false,
      },

      underReviewButton: false,
      activeTab: "underReview",
      selectedItem: false,
      companySelected: "",
      startDate: "",
      endDate: "",
      term: "",
      termCompany: "",
      finalizeModal: null,

      baixarHover: false,

      filteredUnderReviewArray: [],
      filteredFinalizedReviewArray: [],

      underReviewCompanyFiltered: [],
      finalizedReviewCompanyFiltered: [],

      companyArray: [],
    };
  },

  /**
   * mounted
   */
  mounted() {
    this.findUnderReviewMailings().then(() => {
      this.filteredUnderReviewArray = this.removeDuplicates(
          this.underReviewMailings,
      );
      this.underReviewCompanyFiltered = this.removeDuplicates(
          this.underReviewMailings,
      );
    });
    this.findFinalizedReviewMailings().then(() => {
      this.filteredFinalizedReviewArray = this.removeDuplicates(
          this.finalizedReviewMailings,
      );
      this.finalizedReviewCompanyFiltered = this.removeDuplicates(
          this.finalizedReviewMailings,
      );
    });
    this.finalizeModal = new Modal(this.$refs.finalizeModal, {});
  },

  /**
   * computed
   */
  computed: {
    ...mapGetters("admin/sms", {
      loadingFlags: "getLoadingFlags",
      underReviewMailings: "getUnderReviewMailings",
      finalizedReviewMailings: "getFinalizedReviewMailings",
      underReviewPagination: "getUnderReviewPagination",
      finalizedReviewPagination: "getFinalizedReviewPagination",
      totalUnderReview: "getTotalUnderReviewMailings",
      totalFinalizedReview: "getTotalFinalizedReviewMailings",
    }),
  },

  /**
   * components
   */
  components: {
    SearchInput,
    TableLoading,
    Paginator,
    InputDateRange,
    ValidateSmsOffCanvas,
    HeaderComponent,
  },

  /**
   * watch
   */
  watch: {
    termCompany(data) {
      this.searchCompany(data);
    },
  },

  /**
   * methods
   */
  methods: {
    ...mapActions("admin/sms", [
      "findUnderReviewMailings",
      "findFinalizedReviewMailings",
      "downloadMailingSms",
      "sendFinalizeMailing",
      "findFinalizedReviewMailingsByDate",
    ]),
    ...mapMutations("admin/sms", [
      "setUnderReviewFilters",
      "setUnderReviewPage",
      "setUnderReviewPage",
      "setUnderReviewPerPage",
      "setFinalizedReviewFilters",
      "setFinalizedReviewPage",
      "setFinalizedReviewPage",
      "setFinalizedReviewPerPage",
    ]),

    /**
     * search function for search table input
     */
    search: _.debounce(function (term) {
      if (this.activeTab == "underReview") {
        this.setUnderReviewFilters({
          search: term,
        });
        this.setUnderReviewPage(1);
        this.findUnderReviewMailings();
      }
      if (this.activeTab == "finalized") {
        this.setFinalizedReviewFilters({
          search: term,
        });
        this.setFinalizedReviewPage(1);
        this.findFinalizedReviewMailings();
      }
    }, 300),

    /**
     * search function for "Empresas" dropdown search input
     */
    searchCompany(termCompany) {
      if (this.activeTab == "underReview") {
        this.underReviewCompanyFiltered = this.filteredUnderReviewArray.filter(
            el => {
              return (
                  el.company.data.name
                      .toLowerCase()
                      .indexOf(termCompany.toLowerCase()) !== -1
              );
            },
        );
      }
      if (this.activeTab == "finalized") {
        this.finalizedReviewCompanyFiltered = this.filteredFinalizedReviewArray.filter(
            el => {
              return (
                  el.company.data.name
                      .toLowerCase()
                      .indexOf(termCompany.toLowerCase()) !== -1
              );
            },
        );
      }
    },

    /**
     * Select items for table management
     */
    selectItem(item) {
      this.selectedItem = item;
    },
    unSelectItem() {
      this.selectedItem = false;
    },
    /**
     * format date on finalized Review table rows
     */
    formatDate(timeStamp) {
      return moment(timeStamp * 1000)
          .local()
          .format("DD/MM [às] HH:mm");
    },

    /**
     * format date on Under Review table rows
     */
    formatUnderReviewDate(timeStamp) {
      let data = moment(timeStamp).unix();
      return moment(data * 1000)
          .local()
          .format("DD/MM [às] HH:mm");
    },

    /**
     *  download mailing on button "Baixar"
     */
    downloadMailing(el) {
      this.downloadMailingSms({ id: el });
    },

    /**
     * filter companies by radio click on "Empresas" dropdown
     */
    filterByCompany(id) {
      let alreadyInArray = this.companyArray.includes(id);

      if (alreadyInArray)
        this.companyArray.splice(this.companyArray.indexOf(id), 1);
      else this.companyArray.push(id);

      if (this.activeTab == "underReview") {
        this.setUnderReviewFilters({
          companies: this.companyArray,
        });
        this.findUnderReviewMailings();
      }
      if (this.activeTab == "finalized") {
        this.setFinalizedReviewFilters({
          companies: this.companyArray,
        });
        this.findFinalizedReviewMailings();
      }
    },

    /**
     * open finalize modal and set fields.id
     */
    openModalAndSetId(id) {
      this.finalizeModal.toggle();
      this.fields.id = id;
    },

    /**
     * submit function for finalize a mailing list analyze
     */
    submit() {
      this.sendFinalizeMailing({
        id: this.fields.id,
        fields: {
          status: this.fields.status,
          blocked_sms: this.fields.blocked_sms,
          reason: this.fields.reason,
        },
      }).then(() => {
        this.findUnderReviewMailings();
        this.findFinalizedReviewMailings();
        this.finalizeModal.hide();
        this.fields.id = null;
        this.fields.reason = "";
        this.fields.status = null;
        this.fields.blocked_sms = false;
      });
    },

    /**
     * submit function for "input-date-range" component
     */
    submitInputDataRange() {
      this.findFinalizedReviewMailingsByDate({
        start_date: this.startDate + "00:00:00",
        end_date: this.endDate + "23:59:59",
      });
    },

    /**
     * change page on paginator
     */
    changePage(page) {
      if (this.activeTab == "underReview") {
        this.setUnderReviewPage(page);
        this.findUnderReviewMailings();
      }
      if (this.activeTab == "finalized") {
        this.setFinalizedReviewPage(page);
        this.findFinalizedReviewMailings();
      }
    },

    /**
     * change quantity of items will be listed on table
     */
    changeQuantityOfPages(quantity) {
      if (quantity == "all") {
        if (this.activeTab == "underReview") {
          this.setUnderReviewPerPage(this.totalUnderReview);
          this.findUnderReviewMailings();
        }
        if (this.activeTab == "finalized") {
          this.setFinalizedReviewPerPage(this.totalFinalizedReview);
          this.findFinalizedReviewMailings();
        }
      } else {
        if (this.activeTab == "underReview") {
          this.setUnderReviewPerPage(quantity);
          this.findUnderReviewMailings();
        }
        if (this.activeTab == "finalized") {
          this.setFinalizedReviewPerPage(quantity);
          this.findFinalizedReviewMailings();
        }
      }
    },

    /**
     * open finalized mailings offCanvas
     */
    openOffCanvas(item) {
      this.$refs.validateSmsOffCanvas.open(item);
    },

    /**
     * return an array filtered by companies, having only one of each
     */
    removeDuplicates(arr) {
      const array = new Set();
      return arr.filter(item => {
        const dupl = array.has(item.company.data.id);
        array.add(item.company.data.id);
        return !dupl;
      });
    },
  },
};
</script>
