export default {
  startLoading: (state, flag) => state.loadingFlags[flag] = true,
  stopLoading: (state, flag) => state.loadingFlags[flag] = false,
  setErrors: (state, errors) => state.errors = errors,
  clearErrors: (state) => {
    state.errors = {}
  },
  setList: (state, list) => state.list = list,
  setPagination: (state, pagination) => state.pagination = pagination,
  setCurrentPage: (state, page) => state.pagination.current_page = page,
  setFilter: (state, filters) => state.filters = filters,
  setRegister: (state, register) => state.register = register,
  setFilters: (state, filters) => state.filters = filters,
  setInclude: (state, include) => state.include = include,
  setDataSelect: (state, dataSelect) => state.dataSelect = dataSelect,
  setGroupChannels: (state, groupChannels) => state.groupChannels = groupChannels,
  setQuickMessages: (state, quickMessages) => state.quickMessages = quickMessages,
  setTemplates: (state, templates) => state.templates = templates,
}
