<template>
  <div class="col-lg-6 col-xl-4 col-xxl-3">
    <div
      class="card cursor-pointer card-hover"
      style="height: 56px"
      @click="handleStatusEvent(item)"
      data-test="connection-card"
    >
      <div
        class="card-body"
        style="padding: 16px"
      >
        <div class="d-flex justify-content-between">
          <span
            class="fw-medium text-truncate"
            :class="{'text-muted': status === 'disconnected'}"
          >
            {{ item.name }}
          </span>
          <div class="d-flex align-items-center">
            <dot-status
              :color="status?.color"
              size="7px"
            />
            <label
              class="form-label my-0 mx-1 text-small"
              :style="'color: ' + status.color"
            >{{ $t(status.label) }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DotStatus from "@/components/dot-status";
import {formatPhoneNumber} from "@/utils";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    DotStatus
  },

  props: {
    item:{
      type: Object,
      default: null,
    },
  },

  computed: {
    ...mapGetters("manager/whatsapp", {
      loadingFlags: "getLoadingFlags"
    }),

    status() {
      let status = this.item.status
      if (this.item.status === "active") {
        status = this.item.is_connected? "connected" : "disconnected"
      }
      if (status === "connected") {
        return {
          color: "#008A35",
          label: "connected"
        }
      } else if (status === "disconnected") {
        return {
          color: "#1B1B2B",
          label: "disconnected"
        };
      } else if (status === "in_process") {
        return {
          color: "#3057F2",
          label: "in_process"
        };
      } else if (status === "deleted") {
        return {
          color: "#E60B42",
          label: "deleted"
        };
      } else {
        return {
          color: "rgba(125,125,125,0.58)",
          label: "Inativo"
        }
      }
    }
  },

  methods: {
    formatPhoneNumber,

    ...mapActions("system", ["setTimeMessage", "setCustomizedTitleMessage"]),

    handleStatusEvent(item) {
      if (item.status == 'in_process') {
        this.setTimeMessage(this.$t('loading_connection_data'));
        this.setCustomizedTitleMessage(this.$t('wait'));
        return;
      }

      this.$router.push(`/manager/whatsapp/${item.id}`)
    },
  }
}
</script>

<style scoped lang="scss">
.card-hover:hover{
  background-color: #F0F4FA;
}
</style>
