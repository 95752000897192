<template>
  <div
    class="card-tag col-auto d-flex align-items-center fw-medium"
    :style="{
      'background-color': tag.color,
      'color': tagColors[tag.color]
    }"
  >
    <span class="line-clamp">{{ tag.name }}</span>
    <span
      v-if="loading"
      class="spinner-border spinner-border-sm"
    />
    <span
      v-else-if="closeEnabled"
      class="fa-solid fa-xmark ms-2 cursor-pointer"
      @click="$emit('close')"
    />
  </div>
</template>

<script>
import {tagColors} from "@/utils";

export default {
  name: "TagContact",

  emits: ['close'],

  props: {
    tag: {
      type: Object,
      required: true
    },

    closeEnabled: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  data(){
    return{
      tagColors: tagColors,
    }
  },
}
</script>

<style scoped lang="scss">
.card-tag {
  width: fit-content;
  height: 1.5rem;
  border-radius: 0.375rem;
  padding: 0 0.5rem;
  font-size: 0.875rem;
}

.card-tag-sm {
  width: fit-content;
  height: 1.25rem;
  border-radius: 0.375rem;
  padding: 0 0.5rem;
  font-size: 0.675rem;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>