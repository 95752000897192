import _ from "lodash";

export default {
  startLoading: (state, flag) => state.loadingFlags[flag] = true,
  stopLoading: (state, flag) => state.loadingFlags[flag] = false,
  setErrors: (state, errors) => state.errors = errors,
  setOpportunities: (state, {stepId, opportunities}) => state.opportunities[stepId] = opportunities,
  setOpportunitiesPagination: (state, {stepId, pagination}) => state.opportunitiesPagination[stepId] = pagination,
  setOpportunitiesLoading: (state, {stepId, loading}) => state.opportunitiesLoading[stepId] = loading,
  setOpportunity: (state, opportunity) => {
    state.opportunity = opportunity
    if (opportunity) {
      state.lastOpenOpportunity = opportunity
    }
  },

  reorderOpportunitiesInStep: (state, {currentCard, addedIndex, removedIndex, targetStep}) => {
    if (removedIndex !== null) {
      state.opportunities[targetStep.id].splice(removedIndex, 1)
      state.opportunitiesPagination[targetStep.id].total--
    }
    if (addedIndex !== null) {
      state.opportunities[targetStep.id].splice(addedIndex, 0, currentCard)
      state.opportunitiesPagination[targetStep.id].total++
    }
  },

  setFormInputs: (state, formInputs) => {
    if (formInputs.rd_station) {
      state.formInputs.rd_station = _.orderBy(formInputs.rd_station, 'order')
    }
    if (formInputs.CRM) {
      state.formInputs.CRM = _.orderBy(formInputs.CRM, 'order')
    }
  },
  setOpportunityLogs: (state, opportunityLogs) => state.opportunityLogs = opportunityLogs,
  setOpportunityFilters: (state, {filter, value}) => state.opportunityFilters[filter] = value,
  setCurrentTabTimeline: (state, currentTabTimeline) => state.currentTabTimeline = currentTabTimeline,
  setOpportunityId: (state, opportunityId) => state.cardOpportunityId = opportunityId,
  setContactsList: (state, {role, contactsList}) => state.contactsList[role] = contactsList,
}
