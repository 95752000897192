<template>
  <div class="options col d-flex my-1 me-2 gap-3">
    <div v-if="tab === 'whatsapp'">
      <div
        v-if="(chatChannels && chatChannels.length > 1) && !isGroup && !isMessengerChat && !quotedMessageChannel"
        class="dropdown"
      >
        <div
          ref="dropdown"
          aria-expanded="false"
          class="dropdown-toggle cursor-pointer"
          data-bs-offset="-1,20"
          data-bs-toggle="dropdown"
        >
          <span v-if="selectedChannel && selectedChannel.type === 'waba'">
            <i class="fa-kit fa-waba" />
            WABA
          </span>
          <span v-else-if="selectedChannel && selectedChannel.type === 'ultramsg'">
            <i class="fa-kit fa-whatsapp" />
            WhatsApp 3C+
          </span>
        </div>
        <div
          class="dropdown-menu p-3"
          style="width: 195px"
        >
          <h5 class="text-medium">
            {{ $t('channel_select') }}
          </h5>
          <div v-if="chatChannels">
            <div
              v-for="(channel, index) in chatChannels"
              :key="index"
              class="dropdown-item text-gray my-2"
              @click="selectChannel(channel)"
            >
              <p class="m-0">
                <i
                  :class="{
                    'fa-kit fa-waba': channel.type === 'waba',
                    'fa-kit fa-whatsapp': channel.type === 'ultramsg',
                  }"
                  class="me-2"
                />
                <span v-if="channel.type === 'waba'">WABA</span>
                <span v-else-if="channel.type === 'ultramsg'">Whatsapp 3C+</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="isMessengerChat">
        <div class="text-gray">
          <p class="m-0">
            <i class="fa-brands fa-facebook-messenger" />
            Messenger
          </p>
        </div>
      </div>
      <div v-else-if="quotedMessageChannel">
        <div class="text-gray">
          <p
            v-if="quotedMessageChannel.type === 'ultramsg'"
            class="m-0"
          >
            <i class="fa-kit fa-whatsapp" />
            WhatsApp 3C+
          </p>
        </div>
      </div>
      <div v-else-if="chatChannels?.length===1 && chatChannels[0]?.type==='waba'">
        <div class="text-gray">
          <p class="m-0">
            <i class="fa-kit fa-whatsapp" />
            WABA
          </p>
        </div>
      </div>
      <div v-else>
        <div class="text-gray">
          <p class="m-0">
            <i class="fa-kit fa-whatsapp" />
            WhatsApp 3C+
          </p>
        </div>
      </div>
    </div>
    <div
      v-else
      class="option container-loading"
    >
      {{ $t('internal_note') }}
    </div>
  </div>
</template>
<script>
export default {
  name: "ChannelSelectDropdown",
  emits: ['selectedChannel'],
  props: {
    tab: { type: String, required: true, default: 'whatsapp' },
    currentChannel: { type: Object, default: null },
    chatChannels: { type: Array, default: null },
    isGroup: { type: Boolean, default: false },
    isMessengerChat: { type: Boolean, default: false },
    quotedMessageChannel: { type: Object, default: null }
  },
  data() {
    return {
      selectedChannel: {
        type: 'waba'
      }
    }
  },
  mounted() {
    this.selectedChannel = this.currentChannel;
  },
  watch: {
    currentChannel: {
      handler(value) {
        this.selectedChannel = value
      },
      deep: true
    },
    quotedMessageChannel: {
      handler(value) {
        if (this.chatChannels && value)
          this.selectedChannel = this.chatChannels.find(channel => channel.type === value?.type)
      },
      deep: true
    }
  },
  methods: {
    selectChannel(channel) {
      this.selectedChannel = channel
      this.$emit('selectedChannel', channel)
    },

    getChannel() {
      if (this.isMessengerChat || this.chatChannels?.length === 0) {
        return this.currentChannel
      }

      return this.chatChannels.find(channel => channel.type === this.currentChannel?.type  || 'waba');
    }
  }
}
</script>
<style lang="scss" scoped>
.dropdown-toggle {
  color: #677C92;

  &.show {
    color: $color-blue-300 !important;
  }
}

.dropdown-item {
  color: $color-text-solid-black;
  border-radius: 8px !important;
  padding-left: 0.75rem;
  cursor: pointer;

  &:hover {
    background-color: $color-gray-blue-300 !important;
    color: $color-blue-300 !important;
  }

  &.active {
    background-color: $color-blue-300 !important;
    color: $color-text-light !important;
  }
}
</style>
