<template>
  <modal-component
    @close="closeModal"
    :show="showModal"
    :center="true"
    size="modal-md"
  >
    <template
      #content
    >
      <div
        class="modal-header px-4"
        style="padding-top: 16px; padding-bottom: 16px;"
      >
        <div class="w-100">
          <h4 class="m-0 modal-title">
            {{ title }}
          </h4>
        </div>
        <button
          type="button"
          class="btn-close mb-0"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div
        class="modal-body text-center"
        style="padding: 40px 48px 32px;"
      >
        <h2>
          {{ $t('integration_delete_question') }}
        </h2>
        <h4
          class="fw-normal"
          style="margin-bottom: 40px;"
        >
          {{ $t('integration_delete_confirmation') }}
        </h4>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <app-button
            class="col-8"
            @click="removeWebhook"
          >
            {{ $t('yes') }}
          </app-button>

          <button
            class="btn col-2 tx-text-gray mt-4 p-0"
            @click="closeModal"
          >
            {{ $t('no') }}
          </button>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from "@/components/modal-component.vue";
import { mapActions } from "vuex";
import AppButton from "@/components/app-button.vue";

export default {
  data() {
    return {
      id: null,
      showModal: false
    }
  },

  components: {
    ModalComponent,
    AppButton
  },

  props: {
    title: {
      type: String,
      default: ""
    },
  },

  methods: {
    ...mapActions("manager/integration", [
      "deleteWebhook",
    ]),

    openModal(id) {
      this.showModal = true;
      this.id = id;
    },

    closeModal() {
      this.showModal = false;
    },

    removeWebhook() {
      this.closeModal();
      this.deleteWebhook(this.id);
    }
  }
};
</script>

<style lang="scss" scoped>

.modal-header .btn-close {
  margin-right: -0.9rem;
}
</style>
