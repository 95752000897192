<template>
  <h4>Timeline</h4>
  <div class="list-group list-group-flush list-group-activity">
    <div
      v-for="(logs, date, index) in chatLogs"
      :key="index"
    >
      <timeline-card
        :date="date"
      />
      <timeline-card
        v-for="(log, indexTimeline) in logs"
        :key="indexTimeline"
        :log="log"
      />
    </div>
  </div>
</template>

<script>
import TimelineCard from "./timeline-card.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "TimelineChat",

  components: {
    TimelineCard
  },

  props: {
    protocol: {
      type: Number,
      required: true,
    },
    contactId: {
      type: Number,
      required: true
    }
  },

  created() {
    this.fetchChatTimeline({
      contactId: this.contactId,
      protocol: this.protocol
    })
  },

  watch: {
    protocol(){
      this.fetchChatTimeline({
        contactId: this.contactId,
        protocol: this.protocol
      })
    }
  },

  computed:{
    ...mapGetters("manager/chats_report", {
      chatLogs: "getChatLogs"
    })
  },

  methods: {
    ...mapActions("manager/chats_report", ["fetchChatTimeline"])
  }
}
</script>

<style scoped lang="scss">
.list-group-activity .list-group-item:not(:last-child)::before {
  left: 0.5rem !important;
}
</style>
