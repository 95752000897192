export default {
  startLoadingFlags: (state, flag) => state.loadingFlags[flag] = true,
  stopLoadingFlags: (state, flag) => state.loadingFlags[flag] = false,
  clearErrors: (state) => {
    state.errors = {}
    state.errorMessage = ""
  },
  setErrors: (state, errors) => state.errors = errors,
  setCurrentPage: (state, page) => state.pagination.current_page = page,
  setFilters: (state, filters) => state.filters = filters,
  setInclude: (state, include) => state.include = include,
  setChatStatistics: (state, chatStatistics) => state.chatStatistics = chatStatistics,
  setChatQualifications: (state, qualifications) => state.chatQualifications = qualifications,
}
