<template>
  <div>
    <div
      class="header-body mb-4"
      style="backround-color: red"
    >
      <div class="row align-items-end">
        <button
          class="btn btn-rounded-circle btn-white float-start ms-3"
          @click="$router.push('/manager/feedbacks')"
        >
          <span class="fe fe-arrow-left" />
        </button>
        <div class="col">
          <h6 class="header-pretitle">
            Feedback
          </h6>
          <h1 class="header-title">
            {{ $t("new") }}
          </h1>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-8">
        <div class="form-group mb-4">
          <label
            for="exampleInputEmail1"
            class="form-label required"
          >{{ $t("name_data") }}</label>
          <input
            type="text"
            v-model="fields.name"
            :class="{
              'form-control': true,
              'is-invalid': errors.create.name,
            }"
            class="form-control"
          >
          <div class="invalid-feedback">
            {{ errors.create.name && errors.create.name[0] }}
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="form-group mb-4">
          <label
            for="exampleInputEmail1"
            class="form-label required"
          >{{ $t("max_rate") }}</label>
          <input
            type="number"
            min="1"
            max="10"
            :class="{
              'form-control': true,
              'is-invalid': errors.create.high_score,
            }"
            v-model="fields.high_score"
            class="form-control"
          >

          <div class="invalid-feedback">
            {{ errors.create.high_score && errors.create.high_score[0] }}
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <app-button
          class="fw mb-4 w-50"
          :loading="loading.create"
          @click="submit()"
        >
          {{ $t("save_general") }}
        </app-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import appButton from "@/components/app-button.vue";

export default {
  data() {
    return {
      fields: {
        name: "",
        high_score: 1,
      },
      flash: null,
    };
  },

  mounted() {
    this.clearErrors("create");
  },

  components: {
    appButton,
  },

  computed: {
    ...mapGetters("manager/criterion", {
      list: "getList",
      loading: "getLoadingFlags",
      register: "getRegister",
      errors: "getErrorFlags",
    }),
  },

  methods: {
    ...mapActions("manager/criterion", ["create", "clearErrors"]),

    submit() {
      this.create({ fields: this.fields })
          .then(() => {
            this.$router.push({
              path: `/manager/feedbacks/${this.register.id}/edit`,
            });
          });
    },
  },
};
</script>
