export default {
  loadingFlags: {
    updateRingGroupStatus: false,
    fetchRingGroupUsers: false,
    hangup: false,
    consultAgent: false,
    cancelConsultPABX: false,
    exitConsultPABX: false,
    transferToAgentPABX: false
  },
  callPABX: null,
  ringGroupUsers: [],
  consultedAgentId: null,
  internalCallAgent: null,
  showToast: false,
};
