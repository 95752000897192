export default {
  loading: false,
  errors: {},
  officeHours: [],
  register: null,
  filters: {},
  include: [],
  pagination: {
    per_page: 12,
    current_page: 1
  },
}
