// import io from 'socket.io-client'
import agent from '@/websocket/agent';
import manager from '@/websocket/manager';
import router from '@/router';

export default function createWebSocketPlugin() {
  return store => {

    var socket = null;

    // disconnect socket when page unload
    window.addEventListener("beforeunload", function () {
      if (socket && socket.connected) {
        console.log("foi desconectado");
        socket.disconnect();
      }
      socket = null;
    });
    /**
     * Watch mutations
     */
    store.subscribe(mutation => {
      if (mutation.type == "system/setSocketIOActive") {
        if (mutation.payload) {
          if(!socket) {
            const user = store.getters["auth/getUser"];
            const apiToken = user? user.api_token : null;
            if (apiToken) {
              if (store.getters["auth/isManager"] || store.getters["auth/isSupervisor"]) {
                socket = manager(store)
              }
              else if (store.getters["auth/isAgent"]) {
                socket = agent(store)
              }
            } else {
              store.commit("system/setSocketIOActive", false);
            }
          }
          //common event
          if (socket)
            socket.on("force-logout", (event) => {
              if(event.agent && (event.agent.id == store.getters["auth/getUser"].id)) {
                store.dispatch("auth/logout").then(() => {
                  router.push("/").then(() => {
                    store.commit("auth/setLogoutCause", 'force');
                  });
                });
              }
            });
        } else {
          if (socket && socket.connected)
            socket.disconnect();
          socket = null;
        }
      } else if (mutation.type == "auth/endAllRealTimeSessions") {
        if (socket && socket.connected) {
          console.log("[Websocket] - Socket connection ended!");
          socket.disconnect();
          socket = null;
        }
      }
    });
  }
}
