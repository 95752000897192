<template>
  <div class="row mb-3">
    <div class="col-12">
      <a
        href="#"
        @click.prevent="showMoreDetails = !showMoreDetails"
      >
        <small>
          <span v-if="!showMoreDetails">+ </span>
          <span v-else>- </span>
          {{ $t("details") }}
        </small>
      </a>
    </div>
  </div>
  <div
    class="row"
    v-if="showMoreDetails"
  >
    <div
      class="col-12 col-xl-6 col-lg-6 mb-3"
      v-for="(value, label) in callDetails"
      :key="label"
    >
      <small class="text-muted">{{ $t(label) }}</small><br>
      <small>{{ value }}</small>
    </div>
    <div class="col-12 mb-3">
      <small class="text-muted">{{ $t("IVR_after_call") }}</small><br>
      <div
        v-for="(element, key, index) in call.ivr_after_call"
        :key="index"
      >
        <div
          v-if="key !== 'id'"
          class="row"
        >
          <div
            v-if="key === 'name'"
            class="col-12 col-md-6"
          >
            <small>
              {{ $t("name_capitalize") }}: {{ element !== "null" ? element : "null" }}
            </small>
          </div>
          <div
            v-if="key === 'keys_pressed'"
            class="col-12 col-md-6"
          >
            <small>
              {{ $t('capitalizes.Key_pressed') }}: {{ element !== "null" ? element: "null" }}
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMoreDetails: false,
    }
  },

  props: {
    /**
     *
     */
    call: {
      type: Object,
      default: () => null,
    },
  },

  computed: {
    callDetails() {
      return {
        call_id: this.call.id,
        mailing_list: this.call.list,
        call_date_format: this.call.call_date_rfc3339,
        receptive_queue: this.call.queue_name,
        ivr_name: this.call.ivr_name,
        receptive_name: this.call.receptive_name,
        receptive_number: this.call.receptive_phone,
        ivr_time: this.call.ivr_time,
        amd_time: this.call.amd_time,
        talking_to_agent_time: this.call.speaking_with_agent_time,
        ivr_after_call_time: this.call.ivr_after_call_time,
      }
    },
  },
}
</script>