import { AgentStatus } from "@/utils.js";

export default {
  loading: false,
  loadingContext: {},
  ringGroup: null,
  errors: {},
  list: [],
  register: null,
  dataSelect: [],
  consultMetrics: [],
  qualificationsMetrics: null,
  agentsStatus: {},
  currentCalls: [],
  statusCount: [],

  // Refactored
  loadingFlags: {
    loadRingGroups: false,
    loadRingGroupCalls: false,
    loadRingGroupMetrics: false,
    loadRingGroupAgents: false,
    findRingGroupById: false,
    activeRingGroup: false,
    findActiveAgents: false,
    createRingGroup: false,
    updateRingGroup: false,
    deleteRingGroup: false,
    findMetrics: false,
    findConsultMetrics: false,
    findQualificationsMetrics: false,
    findCalls: false
  },
  pagination: {
    per_page: 15,
    current_page: 1
  },

  filters: [],
  ringGroups: [],
  total: {
    hold: 0,
    active_calls: 0,
    abandoned: 0,
    connected: 0,
    awt: 0,
    ast: 0,
    agents: {
      status_count: {
        [AgentStatus.ONLINE]: 0,
        [AgentStatus.ON_CALL]: 0,
        [AgentStatus.ACW]: 0,
        [AgentStatus.ON_MANUAL_CALL]: 0,
        [AgentStatus.ON_MANUAL_CALL_ACW]: 0,
        [AgentStatus.IDLE]: 0,
        [AgentStatus.OFFLINE]: 0,
        [AgentStatus.ON_WORK_BREAK]: 0
      },
      agents: []
    },
    qualifications: {
      calls: 0,
      qualifications: []
    }
  },

  activeAgents: [],
  activeCalls: {},
  statusCallsCount: [],
  metrics: []
};
