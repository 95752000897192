export default {
  startLoading: (state, flag) => state.loading[flag] = true,
  stopLoading: (state, flag) => state.loading[flag] = false,
  setReportList: (state, reportList) => state.reportList = reportList,
  setQualificationList: (state, qualificationList) => state.qualificationList = qualificationList,
  setErrorMessage: (state, errorMesage) => state.errorMessage = errorMesage,
  setErrors: (state, errors) => state.errors = errors,
  clearErrors: (state) => {
      state.errors = {}
      state.errorMessage = ""
  },
  setConnectedCallsData: (state, connectedCallsData) => state.connectedCallsData = connectedCallsData,
}
