<template>
  <div>
    <mobile-manager-menu
      v-if="!isAgent"
      :show-off-canvas="showOffCanvas"
    />
    <ul
      id="mobile-menu"
      class="list-unstyled mobile-menu"
    >
      <li
        v-for="(item, index) in menu"
        :key="`agent-${index}`"
      >
        <router-link
          class="menu-item"
          :class="{
            'agent-item': isAgent,
            'router-link-active': $route.path.includes('whatsapp') && item.route.includes('whatsapp')
          }"
          :to="{
            path: item.route
          }"
        >
          <i :class="item.icon" />
        </router-link>
      </li>
      <li>
        <button
          ref="settings"
          class="menu-item settings"
          type="button"
          @click="handleSettings"
        >
          <i
            class="fa-regular"
            :class="{
              'fa-ellipsis': !showOffCanvas && !isAgent,
              'fa-user': !showOffCanvas && isAgent,
              'fa-xmark': showOffCanvas
            }"
          />
        </button>
        <div class="dropdown-menu agent-menu">
          <agent-logout-menu />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import MobileManagerMenu from "@components/mobile-manager-menu.vue";
import {Dropdown} from "bootstrap";
import AgentLogoutMenu from "@whatsapp/components/agent-logout-menu.vue";

export default {
  name: "MobileMenu",
  components: {AgentLogoutMenu, MobileManagerMenu},

  data() {
    return {
      showOffCanvas: false,
      settingsDropdown: null,
      managerMenu: [
        {
          name: "Dialer",
          icon: "fa-regular fa-phone",
          route: "/manager/voice-panel",
        },
        {
          name: "Omnichannel",
          icon: "fa-regular fa-messages",
          route: "/manager/whatsapp",
        },
        {
          name: "CRM",
          icon: "fa-regular fa-square-kanban",
          route: "/manager/crm",
        },
      ],
      agentMenu: [
        {
          name: "Dialer",
          icon: "fa-regular fa-phone",
          route: "/agent",
        },
        {
          name: "Omnichannel",
          icon: "fa-kit fa-whatsapp",
          route: "/whatsapp",
        },
        {
          name: "CRM",
          icon: "fa-regular fa-square-kanban",
          route: "/crm",
        },
      ],
    }
  },

  methods: {
    handleSettings() {
      if (this.isAgent && this.settingsDropdown) {
        this.settingsDropdown.toggle();
        return
      }

      this.showOffCanvas = !this.showOffCanvas;
    }
  },

  computed: {
    ...mapGetters("auth", {
      isAgent: "isAgent"
    }),

    menu() {
      return this.isAgent ? this.agentMenu : this.managerMenu;
    }
  },

  watch: {
    isAgent: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.settingsDropdown = new Dropdown(this.$refs.settings);
          })

          return
        }

        this.settingsDropdown = null;
      },
      immediate: true,
    },

    $route: {
      handler() {
        this.$nextTick(() => {
          if (this.settingsDropdown) {
            this.settingsDropdown.hide();
            return
          }

          this.showOffCanvas = false;
        })
      },
      immediate: true,
    }
  }

}
</script>


<style scoped lang="scss">
.mobile-menu {
  padding: 0.313rem 1.5rem 0 1.5rem;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  margin: 0;
  width: 100%;
  box-shadow: 0 4px 18px 0 rgba(34, 54, 77, 0.25);
  background-color: #fff;
  border-top: 1px solid $color-gray-blue-300;
  position: relative;
  z-index: 1046;
}

.menu-item {
  color: $color-text-gray;
  transition: all 0.25s ease-in-out;
  position: relative;
  width: 46px;
  height: 50px;
  max-width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: end;
  padding: 0.25rem 0.25rem 1.125rem 0.25rem;
  font-size: 1.25rem;
  background-color: transparent;
  border: 0;

  &:after {
    transition: all 0.25s ease-in-out;
    content: "";
    position: absolute;
    width: 0;
    height: 0.5rem;
    border-radius: 0.5rem 0.5rem 0 0;
    background-color: $color-blue-300;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }


  &.agent-item {
    &:after {
      background-color: $color-yellow-300;
    }
  }

  &.settings {
    background-color: $color-gray-blue-200;
    width: 40px;
    height: 40px;
    padding: 0.25rem;
    align-items: center;
    border-radius: 10px;
    font-size: 1rem;

    &:after {
      display: none;
    }
  }


  //router-link-exact-active
  &.router-link-active {
    color: $color-text-solid-black;

    &:after {
      width: 100%;
    }
  }
}

.offcanvas-bottom {
  height: 100vh;
}

.agent-menu {
  width: 224px;
  max-width: calc(100vw - 48px);
  padding: 1rem;
  font-size: 0.75rem !important;
  text-align: center;
}

:deep(.button-exit ) {
  padding: 0.25rem !important;
  font-size: 0.75rem !important;
  justify-content: center !important;
}
</style>