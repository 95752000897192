<template>
  <hover-button
    ref="scheduleButton"
    @click="toggleDropdown"
    icon="fa-regular fa-calendar"
    :keep-button-hovered="showScheduleCard"
    :notification-counter="pagination.total"
    :background-color="getBackgroundColor"
  />

  <dropdown-component
    :is-open="showScheduleCard"
    :button-rect="buttonRect"
    :title="$t('schedules')"
    direction="right"
  >
    <div
      ref="scrollContainer"
      class="overflow-auto card-schedules-body"
    >
      <div v-if="!loadingFlags.findSchedules && !showDeleteScheduleConfirmation && !showUpdateScheduleForm">
        <div v-if="schedules.length > 0">
          <template v-if="true">
            <div
              v-for="schedule of schedules"
              :key="schedule.id"
              class="schedule-item rounded "
            >
              <div class="schedule d-flex align-items-center justify-content-between pb-1">
                <div>
                  <div>
                    <i class="far fa-calendar tx-blue-300" />
                    <span class="tx-text-200 ms-2"> {{ formatPhoneNumber(schedule.phone) }} </span>
                  </div>
                  <div class="text-medium tx-text-gray">
                    {{ formatDate(schedule.date, "DD/MM [às] HH:mm") }}
                  </div>
                </div>
                <div class="schedule-buttons tx-text-gray">
                  <div
                    @click="removeSchedule(schedule)"
                    class="cursor-pointer"
                  >
                    <i class="fa-regular fa-trash" />
                  </div>

                  <div
                    @click="showUpdateSchedule(schedule)"
                    class="cursor-pointer"
                  >
                    <i class="far fa-pen-to-square" />
                  </div>

                  <div
                    @click="dialToSchedule(schedule)"
                    class="button-call text-medium bg-call cursor-pointer d-flex align-items-center justify-content-center"
                    :class="[callHistoryNumberCalling === schedule.id && 'calling',
                             callHistoryNumberCalling && callHistoryNumberCalling !== schedule.id ? 'loadingFlags' : '']"
                  >
                    <span class="fa-regular fa-phone" />
                    <span class="fa-regular fa-phone-volume" />
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div
            v-if="loadingFlags.findSchedules"
            class="text-center"
          >
            <div class="spinner-border spinner-border-sm tx-blue-200">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        <div
          class="d-flex flex-column text-center mt-6"
          v-else
        >
          <h2 class="my-0">
            Oops...
          </h2>
          <img
            :src="require(`@/assets/img/aloisio/aloisioThinking.svg`)"
            class="align-self-center my-4"
            style="width: 68px;"
          >
          <p class="fs-6">
            {{ $t('you_dont_have_schedules') }}.
          </p>
        </div>
      </div>

      <div v-if="showUpdateScheduleForm">
        <form @submit.prevent="updateSchedule">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label
                  for="phone-number"
                  class="form-label"
                >
                  {{ $t('number') }}
                </label>
                <input
                  id="phone-number"
                  class="form-control"
                  :class="{ 'is-invalid': errors.phone }"
                  :placeholder="$t('number')"
                  v-maska:[options]
                  v-model="fields.phone"
                  @maska="updatePhone"
                  data-test="schedulePhone-input"
                >
                <div class="invalid-feedback">
                  {{ errors.phone && errors.phone[0] }}
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="form-group">
                <label class="form-label">
                  {{ $t('date_capitalize') }}
                </label>
                <flat-pickr
                  class="form-control input-date rounded-start"
                  :class="{ 'is-invalid': errors.date }"
                  placeholder="Data"
                  type="text"
                  data-input
                  :config="config"
                  v-model="fields.date"
                  data-test="scheduleDate-input"
                />
                <div class="invalid-feedback">
                  {{ errors.date && errors.date[0] }}
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="form-group">
                <label class="form-label">
                  {{ $t('time_capitalize') }}
                </label>
                <flat-pickr
                  class="form-control input-date"
                  :class="{ 'is-invalid': errors.date }"
                  placeholder="Horário"
                  data-input
                  :config="timeConfig"
                  v-model="fields.time"
                  data-test="scheduleTime-input"
                />
                <template v-if="errors.date">
                  <div
                    v-for="(error, index) in errors.date"
                    :key="index"
                    class="invalid-feedback"
                  >
                    {{ error }}
                  </div>
                </template>
              </div>
            </div>

            <div class="col-12">
              <div class="form-group">
                <label class="form-label">
                  {{ $t('observations') }}
                </label>
                <textarea
                  rows="1"
                  class="form-control"
                  :class="{ 'is-invalid': errors.note }"
                  v-model="fields.note"
                  :placeholder="$t('observations')"
                  data-test="scheduleNote-input"
                />
                <div class="invalid-feedback">
                  {{ errors.note && errors.note[0] }}
                </div>
              </div>
            </div>

            <div
              v-if="Object.values(selectedSchedule.mailing).length"
              class="col-7 mx-auto"
            >
              <button
                class="btn bg-gray-blue-300 tx-text-gray text-small w-100 mb-4 btn-client-data"
                @click.prevent="showClientData = !showClientData"
              >
                <i
                  class="fa-regular"
                  :class="showClientData ? 'fa-chevron-up' : 'fa-chevron-down'"
                />
                Dados do cliente
              </button>
            </div>

            <div
              v-if="showClientData"
              class="col-12"
            >
              <div class="d-flex flex-column client-data mb-4">
                <div
                  v-if="selectedSchedule.mailing.type"
                  class="schedule-data"
                >
                  <div>{{ $t('type_general') }}</div>
                  <div class="tx-text-gray text-medium">
                    {{ selectedSchedule.mailing.type === CallMode.RECEPTIVE ? $t('receptive_capitalize') : $t('dialer_capitalize') }}
                  </div>
                </div>

                <div
                  v-if="selectedSchedule.mailing.phone"
                  class="schedule-data"
                >
                  <div>{{ $t('phone') }}</div>
                  <div class="tx-text-gray text-medium">
                    {{ selectedSchedule.mailing.phone }}
                  </div>
                </div>

                <div
                  v-if="selectedSchedule.mailing.identifier"
                  class="schedule-data"
                >
                  <div>{{ $t('identifier') }}</div>
                  <div class="tx-text-gray text-medium">
                    {{ selectedSchedule.mailing.identifier }}
                  </div>
                </div>

                <div
                  v-if="selectedSchedule.mailing.sid"
                  class="schedule-data"
                >
                  <div>{{ $t('protocol') }}</div>
                  <div class="tx-text-gray text-medium">
                    {{ selectedSchedule.mailing.sid }}
                  </div>
                </div>

                <div
                  v-for="(value, key) in selectedSchedule.mailing.data"
                  :key="key"
                  class="schedule-data"
                >
                  <div>{{ key }}</div>
                  <div class="tx-text-gray text-medium">
                    {{ value }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6">
              <app-button
                @click.prevent="cancelEditSchedule"
                class="w-100 text-muted"
                :color="'btn-link'"
                data-test="scheduleBack-button"
              >
                {{ $t('back_capitalize') }}
              </app-button>
            </div>

            <div class="col-6">
              <app-button
                class="w-100"
                :loading="loadingFlags.update"
                :disabled="fields.time === '' || loadingFlags.update"
                data-test="scheduleSubmit-button"
              >
                {{ $t('update_omni') }}
              </app-button>
            </div>
          </div>
        </form>
      </div>
      <div
        v-if="showDeleteScheduleConfirmation"
        class="h-100 d-flex flex-column justify-content-between"
      >
        <strong class="h-100 d-flex align-items-center text-center text-large fw-medium">
          {{ $t('delete_schedule_confirmation') }}
        </strong>

        <div class="row">
          <div class="col-6">
            <app-button
              @click="cancelDeleteSchedule"
              class="btn w-100 tx-text-gray"
              :color="'btn-link'"
              data-test="scheduleBack-button"
            >
              {{ $t('back_capitalize') }}
            </app-button>
          </div>

          <div class="col-6">
            <app-button
              class="btn btn-danger w-100"
              :loading="loadingFlags.remove"
              data-test="scheduleDelete-button"
              @click="deleteSchedule"
            >
              {{ $t('capitalizes.exclude') }}
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </dropdown-component>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import { mapActions, mapGetters } from "vuex";
import { formatPhoneNumber, formatDate, AgentStatus } from "@/utils";
import HoverButton from "@dialer/agent/components/hover-button.vue";
import DropdownComponent from "@dialer/agent/components/dropdown-component.vue";
import AppButton from "@/components/app-button";

export default {
  name: "ScheduleComponent",
  data() {
    return {
      showScheduleCard: false,
      buttonRect: null,
      showUpdateScheduleForm: false,
      showDeleteScheduleConfirmation: false,
      selectedSchedule: null,
      unmaskedPhoneNumber: '',
      showClientData: false,
      fields: {
        phone: "",
        note: "",
        date: "",
        time: "",
      },
      config: {
        locale: Portuguese,
        inline: false,
        altInput: true,
        altFormat: 'd/m/Y',
        minDate: 'today',
      },
      timeConfig: {
        locale: Portuguese,
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
      },
      options: {
        mask: [
          '(##) ####-####',
          '(##) #####-####',
          '+## (##) ####-####',
          '+## (##) #####-####'
        ]
      }
    }
  },

  components: {
    HoverButton,
    DropdownComponent,
    AppButton,
    flatPickr
  },

  mounted() {
    this.clearSchedules();
    const filters = { is_ring_group: this.isRingGroupActive ? 1 : 0 };
    this.setFilters(filters);
    this.me();
    this.findSchedules();
  },

  beforeUnmount() {
    this.clearSchedules();
    this.setFilters({ });
    window.removeEventListener("resize", this.calculateButtonRect);
  },

  methods: {
    formatDate,
    formatPhoneNumber,
    ...mapActions("agent/schedule", [
      "findSchedules",
      "setFilters",
      "setCurrentPage",
      "clearSchedules",
      "update",
      "remove"
    ]),
    ...mapActions("auth", ["me"]),
    ...mapActions("agent/call", ["startManualCallAfterAgentEnteredManual", "startManualCall"]),
    ...mapActions("agent/agent", ["setActiveDropdownRight"]),
    toggleDropdown() {
      this.showScheduleCard = !this.showScheduleCard;
      if (this.showScheduleCard) {
        this.setActiveDropdownRight("ScheduleComponent");
        this.calculateButtonRect();
        window.addEventListener('resize', this.calculateButtonRect);
        this.showUpdateScheduleForm = false;
        this.showDeleteScheduleConfirmation = false;
        this.showClientData = false;
        this.selectedSchedule = null;

        this.$nextTick().then(() => {
          this.$refs.scrollContainer.addEventListener("scroll", this.handleScroll);
        });
      } else {
        window.removeEventListener('resize', this.calculateButtonRect);
        this.$refs.scrollContainer.removeEventListener("scroll", this.handleScroll);
      }
    },

    calculateButtonRect() {
      const rect = this.$refs.scheduleButton.$el.getBoundingClientRect();
      this.buttonRect = rect;
    },

    removeSchedule(schedule) {
      this.selectedSchedule = schedule;
      this.showDeleteScheduleConfirmation = true;
    },

    showUpdateSchedule(schedule) {
      this.selectedSchedule = schedule;
      this.unmaskedPhoneNumber = schedule.phone;
      this.fields.phone = schedule.phone;
      this.fields.note = schedule.note;
      this.fields.date = formatDate(schedule.date, "YYYY-MM-DD");
      this.fields.time = formatDate(schedule.date, "HH:mm");
      this.showUpdateScheduleForm = true;
    },

    cancelDeleteSchedule() {
      this.selectedSchedule = null;
      this.showDeleteScheduleConfirmation = false;
    },

    deleteSchedule() {
      this.remove({ id: this.selectedSchedule.id }).then(() => {
        this.showDeleteScheduleConfirmation = false;
        this.selectedSchedule = null;
      });
    },

    cancelEditSchedule() {
      this.selectedSchedule = null;
      this.showUpdateScheduleForm = false;
      this.showClientData = false;
    },

    updateSchedule() {
      const fields = {
        date: this.fields.date + " " + this.fields.time + ":00",
        phone: this.unmaskedPhoneNumber,
        note: this.fields.note,
      }
      this.update({
        id: this.selectedSchedule.id,
        fields
      }).then(() => {
        this.selectedSchedule = null;
        this.showUpdateScheduleForm = false;
      });
    },

    dialToSchedule(schedule) {
      if (!this.loadingFlags.startManualCallAfterAgentEnteredManual && this.mode !== "manual") {
        this.startManualCallAfterAgentEnteredManual({
          phone: schedule.phone,
          _id: schedule.id,
          schedule_id: schedule.id,
        }).then(() => {
          this.toggleDropdown();
        });
        return;
      }

      this.startManualCall({
        phone: schedule.phone,
        _id: schedule.id,
        schedule_id: schedule.id,
      }).then(() => {
        this.toggleDropdown();
      });
    },

    updatePhone(event) {
      this.unmaskedPhoneNumber = event.detail.unmasked;
    },

    handleScroll() {
      const scrollContainer = this.$refs.scrollContainer;

      if (scrollContainer) {
        const scrollHeight = scrollContainer.scrollHeight;
        const scrollTop = scrollContainer.scrollTop;
        const clientHeight = scrollContainer.clientHeight;

        if (
          !this.loadingFlags.findSchedules &&
          !this.showDeleteScheduleConfirmation &&
          !this.showUpdateScheduleForm &&
          scrollHeight - scrollTop <= (clientHeight + 10)
        ) {
          if (this.pagination.current_page < this.pagination.total_pages) {
            let currentPage = this.pagination.current_page;
            this.setCurrentPage(++currentPage);
            this.findSchedules();
          }
        }
      }
    },

    loadMoreSchedules() {
      this.findSchedules();
    }
  },

  computed: {
    ...mapGetters("agent/schedule", {
      schedules: "getSchedules",
      loadingFlags: "getLoadingFlags",
      pagination: "getPagination",
      errors: "getErrors"
    }),

    ...mapGetters("agent/ring_group", {
      isRingGroupActive: "isRingGroupActive",
    }),

    ...mapGetters("system", {
      status: "getStatus",
      mode: "getMode"
    }),

    ...mapGetters("agent/agent", {
      loggedCampaign: "getCampaign",
      activeDropdownRight: "getActiveDropdownRight"
    }),

    ...mapGetters("agent/call", {
      callHistory: "getCallHistory",
      loadingFlags: "getLoadingFlags",
      callHistoryNumberCalling: "getCallHistoryNumberCalling"
    }),

    getBackgroundColor() {
      if (this.loggedCampaign && this.status === AgentStatus.IDLE && this.mode === "dialer") {
        return "var(--bs-white)";
      }

      if (this.loggedCampaign && this.status === AgentStatus.IDLE && this.mode === "manual") {
        return "var(--bs-white)";
      }

      return "#E1E9F4";
    }
  },

  watch: {
    activeDropdownRight(newVal) {
      if (newVal !== "ScheduleComponent" && this.showScheduleCard) {
        this.toggleDropdown();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card-schedules-body {
  height: 380px;
  padding: 1rem;

  .schedule-item {
    padding: 0.5rem 1rem;

    &:hover,
    &:has(.calling) {
      background-color: $color-gray-blue-300;

      .schedule-buttons {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
    }

    &:has(.loadingFlags) {
      cursor: default;
      background-color: white;
      opacity: 0.3;

      .schedule-buttons {
        display: none;
      }
    }

    &:not(:last-child) {
      .schedule {
        border-bottom: 1px solid $color-gray-blue-300;
      }
    }

    .schedule-buttons {
      display: none;

      .button-call {
        color: $color-text-light;
        width: 24px;
        height: 24px;
        border-radius: 8px;

        .fa-phone-volume {
          display: none;
        }
      }

      .calling {
        animation: border-increase 1s linear infinite !important;

        .fa-phone {
          display: none;
        }

        .fa-phone-volume {
          display: inline-block !important;
          animation: spin 1s linear infinite;
          color: $color-text-light;
        }
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }

        25% {
          transform: rotate(10deg);
        }

        50% {
          transform: rotate(0deg);
        }
      }

      @keyframes border-increase {
        25% {
          outline: 1.78px solid rgba(157, 212, 164, 1);
        }

        50% {
          outline: 3.56px solid rgba(141, 187, 146, 1);
        }
      }
    }
  }

  form {
    .btn-client-data {
      padding-top: 4px;
      padding-bottom: 4px;
    }

    .client-data {
      padding: 16px;
      height: 200px;
      overflow-y: auto;
      border: 1px solid var(--color-gray-blue-300);
      border-radius: 10px;

      .schedule-data:not(:first-child) {
        padding-top: 12px;
      }

      .schedule-data:not(:last-child) {
        padding-bottom: 12px;
        border-bottom: 1px solid $color-gray-blue-300;
      }
    }
  }
}
</style>
