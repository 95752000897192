import axios from "axios";

export default {


  /**
   *
   * @returns
   */

  findMetricsPerTime({ commit }, payload) {
    commit('setTotalPerTime', [])
    let params = {
      start_date: payload.start_date,
      end_date: payload.end_date,
      agent_id: payload.agent_id
    }
    commit('startLoading')
    return new Promise((resolve, reject) => {
      axios.get('/campaigns/' + payload.campaign_id + '/agents/metrics/total_per_time', { params })
        .then((response) => {
          commit('setTotalPerTime', response.data.data)
          resolve();
        })
        .catch((response) => {
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit('stopLoading')
        });
    });
  },


  /**
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  findCallsStatistics({ commit }, payload) {
    commit('setCallsStatistics', []);
    let params = {
      startDate: payload.start_date,
      endDate: payload.end_date,
      interval: payload.interval,
      'trashed[0]': 'campaign'
    }
    commit('startLoading')
    return new Promise((resolve, reject) => {
      axios.get('/campaigns/' + payload.campaign_id + '/statistics', { params })
        .then((response) => {
          commit('setCallsStatistics', response.data.data)
          resolve();
        })
        .catch((response) => {
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit('stopLoading')
        });
    });

  },


  /**
   *
   */
  findDeliveredCalls({ commit }, payload) {
    commit('setDeliveredCalls', []);
    commit('startLoading')

    return new Promise((resolve, reject) => {
      axios.get('/agent/statistics', { params: payload })
        .then((response) => {
          commit('setDeliveredCalls', response.data.data)
          resolve();
        })
        .catch((response) => {
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit('stopLoading')
        });
    });
  },

  /**
   *
   */
  findQualificationStatistics({ commit }, payload) {

    commit('setQualificationStatistics', []);
    commit('startLoading')

    let params = {
      start_date: payload.start_date,
      end_date: payload.end_date,
      campaign_id: payload.campaign_id,
      agent_id: payload.agent_id,
      'trashed[0]': 'campaign'
    }
    return new Promise((resolve, reject) => {
      axios.get('/qualification/statistics', { params })
        .then((response) => {
          commit('setQualificationStatistics', response.data.data)
          resolve();
        })
        .catch((response) => {
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit('stopLoading')
        });
    });
  },

  /**
   *
   */
  loadAgentMetrics({commit}, payload) {

    commit('startLoading');
    return new Promise((resolve, reject) => {
      axios.get('/company/agents/metrics/total', { params: payload })
        .then((response) => {
          commit("setAgentMetrics", response.data.data);
          resolve();
        }).catch((response) => {
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('stopLoading');
        });
    });
  },

  /**
   *
   */
  loadStatistics({ commit }, payload) {

    commit('startLoading');

    return new Promise((resolve, reject) => {
      axios.get('/calls/statistics', { params: payload })
        .then((response) => {
          commit("setStatistics", response.data.data);
          resolve();
        }).catch((response) => {
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('stopLoading');
        });
    });
  }
}
