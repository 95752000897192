<template>
  <div class="d-flex align-items-center h-100">
    <div
      class="card mb-0 card-sdr"
      @click="addOrEdit"
    >
      <div
        class="card-body d-flex align-items-center padding-1rem pb-0"
      >
        <action-component
          icon-class="fa-regular fa-sparkles"
          icon-color="#7745EB"
          :name="$t('ask_with_ai')"
        />
      </div>
      <hr class="w-100">
      <div
        class="d-flex flex-column justify-content-center card-sdr-questions"
      >
        <card-text
          v-if="!action?.sdr_questions?.length"
          :text="action.text_content"
          @add-text="addText"
        />
        <div v-else>
          <p class="tx-text-gray">
            {{ $t("qualification_questions") }}
          </p>
          <div class="d-flex gap-2 flex-column">
            <div
              v-for="(question, index) in action.sdr_questions"
              :key="index"
              class="d-flex align-items-center w-100"
            >
              <div
                class="d-flex align-items-center w-100 question"
              >
                <p class="m-0">
                  {{ question }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex w-100 justify-content-end px-4 tx-text-gray next-step-div">
        <p class="mb-2">
          {{ $t("after_answer") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import ActionComponent from "../action-component.vue";
import CardText from "@chatbot/pages/workflow/components/card-text.vue";

export default {
  name: "CardSdr",

  components: {CardText, ActionComponent},

  props: {
    action: {
      type: Object,
      required: true
    },
  },

  methods: {
    ...mapMutations("chatbot/actions", [
      "setOffcanvasEditActionIsOpen",
      "setOffcanvasNewActionIsOpen",
      "setActionToEdit",
        "setSelectedAction",
        "setPrevActionId"
    ]),

    editAction() {
      this.setSelectedAction(this.action)
      this.setActionToEdit(this.action)
      this.setOffcanvasEditActionIsOpen(true)
    },
    addOrEdit(){
      if(!this.action?.sdr_questions?.length){
        this.addText()
      }else{
        this.editAction()
      }
    },
    addText(){
      this.setPrevActionId(this.action.previous_action_id)
      this.setOffcanvasNewActionIsOpen(true)
      this.setSelectedAction({
        id: this.action.id,
        type: "sdr",
        position: this.action.position
      });
    }
  }
};
</script>
<style scoped>
.padding-1rem{
  padding: 1rem;
}
.card-sdr-questions{
  padding: 0 1rem 1rem 1rem;
  width: 350px
}
.question{
  border: 1px solid #E1E9F4;
  background: #F9FBFD;
  border-radius: 10px;
  padding: 8px 8px 8px 16px;
}
.card-sdr{
  &:hover{
    border: 1px solid #7745EB;
  }
}
.next-step-div{
  padding: 0 1rem 1rem 1rem;
}
</style>