<template>
  <button
    class="btn btn-danger hangup-button px-4"
    :class="{
      'btn-internal-call-failed':
        status === AgentStatus.ON_CALL && [CallStatus.INTERNAL_CALL_FAILED].includes(callStatus)
    }"
    :disabled="
      loadingFlags.hangup ||
        [CallStatus.CONSULT_TRYING].includes(callStatus) ||
        !callStatus && !call?.id ||
        dialerLoadingFlags.hangup ||
        isConsultHold
    "
    @click="hangupCall()"
  >
    <i class="fa-regular fa-phone-hangup" />
  </button>
</template>

<script>
import { AgentStatus, CallStatus } from "@/utils";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      AgentStatus,
      CallStatus
    };
  },

  computed: {
    ...mapGetters("system", {
      status: "getStatus",
      isConsultHold: "isConsultHold"
    }),

    ...mapGetters("agent/ring_group", {
      loadingFlags: "getLoadingFlags",
      callStatus: "getCallStatus",
      isRingGroupActive: "isRingGroupActive"
    }),

    ...mapGetters("agent/agent", {
      loggedCampaign: "getCampaign"
    }),

    ...mapGetters("agent/call", {
      call: "getCall",
      manualCallACW: "getManualCallACW",
      dialerLoadingFlags: "getLoadingFlags"
    })
  },

  methods: {
    ...mapActions("agent/ring_group", ["hangupCallPABX"]),

    ...mapActions("agent/call", ["hangup"]),

    hangupCall() {
      if (this.isRingGroupActive) {
        this.hangupCallPABX();
        return;
      }

      if (this.loggedCampaign) {
        let options = {};

        if (
          this.loggedCampaign.ivr_after_call_status &&
          this.loggedCampaign.ivr_after_call_id &&
          this.loggedCampaign.behavior == "all_calls"
        ) {
          options.ivr_after_call_id = this.loggedCampaign.ivr_after_call_id;
        }

        if (this.manualCallACW && this.manualCallACW.id) {
          this.hangup({ id: this.manualCallACW.id, options });
        } else {
          this.hangup({ id: this.call.id, options });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.hangup-button {
  height: 32px;
  line-height: 0.9;

  &.btn-internal-call-failed {
    background-color: #d1220f;
  }
}
</style>
