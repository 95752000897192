<template>
  <div
    style="gap: 0.5rem"
    class="d-flex"
  >
    <div class="slider-container">
      <swiper
        ref="swiperContainer"
        :slides-per-view="7.5"
        :space-between="8"
        :pagination="{
          dynamicBullets: true,
          clickable: true,
        }"
        :modules="modules"
      >
        <swiper-slide

          v-for="(slide, index) in items"
          :key="`item-${index}-${slide.step_id}`"
        >
          <div
            :id="`slide${index}`"
            class="progress-rate-item w-100"
          >
            <span class="tx-text-200 fw-medium d-block">{{ `${slide.progress_percentage}%` }}</span>
            <!--            <div-->
            <!--              :style="`background-image: linear-gradient(to right, ${slide?.color ||colors[index]} 0%, ${slide?.color ||colors[index]} calc(100% - 0.99999rem), transparent calc(100% - 0.99999rem), transparent 100%)`"-->
            <!--              class="shape"-->
            <!--            >-->
            <!--              <div-->
            <!--                :style="`border-left-color: ${slide?.color ||colors[index]};`"-->
            <!--                class="shape-corner"-->
            <!--              />-->
            <!--              -->
            <!--            </div>-->
            <div
              :style="`background-color: ${slide?.color ||colors[index]}`"
              class="shape-arrow"
            >
              <svg
                style="left: -3px"
                class="position-absolute top-50 translate-middle-y"
                xmlns="http://www.w3.org/2000/svg"
                width="3"
                height="16"
                viewBox="0 0 3 16"
                :fill="slide?.color ||colors[index]"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3 0H2C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895432 16 2 16H3V0Z"
                  :fill="slide?.color ||colors[index]"
                />
              </svg>
              <svg
                style="right: -11px"
                class="position-absolute top-50 translate-middle-y"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="16"
                viewBox="0 0 12 16"
                :fill="slide?.color ||colors[index]"
              >
                <path
                  d="M0.248047 16H2.32548C2.77192 16 3.20552 15.8506 3.55724 15.5757L11.2324 9.57568C12.2568 8.77493 12.2568 7.22507 11.2324 6.42432L3.55724 0.424325C3.20552 0.14937 2.77192 0 2.32548 0H0.248047V16Z"
                  :fill="slide?.color ||colors[index]"
                />
              </svg>
            </div>
            <div
              :title="slide.step_name"
              class="progress-rate-item-name text-truncate tx-text-gray"
            >
              <span>
                {{ slide.step_name }}
              </span>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>

    <div class="progress-badge d-flex flex-column align-items-center justify-content-center rounded">
      <i class="fa-regular fa-star" />
      <span>Ganho</span>
    </div>
  </div>
</template>
<script setup>
import {Swiper, SwiperSlide} from 'swiper/swiper-vue.mjs';
import 'swiper/swiper.css';
import 'swiper/modules/pagination.css'
import {Pagination} from 'swiper/modules';
import {
  ref,
  defineProps, onMounted
} from "vue";

const props = defineProps({
  items: {
    type: Array,
    required: true
  }
})
const modules = ref([Pagination]);


const colors = ref([])

function randomColor() {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`
}


onMounted(() => {
  props.items.forEach(() => {
    colors.value.push(randomColor())
  })
})
</script>


<style scoped lang="scss">
.progress-rate-item {
  &-name {
    font-size: 0.75rem;
  }
}

.shape {
  width: 100%;
  height: 1rem;
  background-image: linear-gradient(to right, black 0%, black calc(100% - 0.99999rem), transparent calc(100% - 0.99999rem), transparent 100%);
  position: relative;

  &-corner {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 0.5rem solid transparent;
    border-left: 1rem solid black;
    border-bottom: 0.5rem solid transparent;
  }
}

:global(.swiper) {
  padding-bottom: 1rem;
}

:global(.swiper-pagination) {
  bottom: 0 !important;
}

.progress-badge {
  min-width: 59px;
  aspect-ratio: 59/64;
  gap: 0.5rem;
  font-size: 0.75rem;
  color: #015901;
  background-color: #C9F2CD;
  height: 100%;
}

.slider-container {
  max-width: calc(100% - (59px + 0.5rem));
  width: calc(100% - (59px + 0.5rem));
}

.shape-arrow {
  width: calc(100% - 14px);
  height: 1rem;
  position: relative;
  display: block;
  margin-left: 3px;
}
</style>