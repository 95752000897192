import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import state from "./state";

import CampaignMetrics from "./metrics";
import Agents from "./agents";
import Calls from "./calls";
import Reluctance from "./reluctance";
import WorkBreak from "./work_break";

export default {
  namespaced: true,
  modules: {
    metrics: CampaignMetrics,
    agents: Agents,
    calls: Calls,
    reluctance: Reluctance,
    work_break: WorkBreak
  },
  state,
  actions,
  mutations,
  getters,
}
