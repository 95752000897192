<template>
  <div
    v-if="message.components"
    :class="{ 'text-decoration-line-through': isDeleted }"
  >
    <div class="message-container">
      <div
        v-if="message?.components?.HEADER"
        class="mb-2"
      >
        <span v-if="message.components.HEADER.text?.length">
          <message-text :text="message.components.HEADER.text" />
        </span>
        <img
          v-if="message.components.HEADER?.format === 'IMAGE'"
          alt="image"
          :src="message.components.HEADER.media"
          class="mx-auto w-100"
        >
        <video
          v-if="message.components.HEADER?.format === 'VIDEO'"
          class="w-100"
          controls=""
        >
          <source :src="message.components.HEADER.media">
        </video>
        <div v-if="message.components.HEADER?.format === 'DOCUMENT'">
          <h1 class="text-large">
            <i class="fa fa-file-pdf" />
          </h1>
        </div>
      </div>
      <div class="d-flex">
        <div v-if="message?.components.BODY?.text?.length">
          <message-text
            :text="message.components.BODY.text[0]"
            :is-deleted="isDeleted"
          />
        </div>
      </div>
      <div
        v-if="message?.components.FOOTER?.text?.length"
        class="text-gray fs-6"
      >
        <message-text :text="message.components.FOOTER.text[0]" />
      </div>
      <div
        v-if="message?.components?.BUTTONS"
        class="fs-6 mt-2"
      >
        <div
          v-for="(link, index) in message?.components.BUTTONS"
          :key="index"
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <hr class="border border-1 opacity-50 my-0 w-100">
          <a
            v-if="link.type === 'URL'"
            :href="link.url"
            target="_blank"
            class="btn btn-link d-flex align-items-center"
          >
            <i class="fal fa-arrow-up-right-from-square me-2" />
            {{ link.text }}
          </a>
          <button
            v-else-if="link.type === 'PHONE_NUMBER'"
            :id="link.phone_number"
            class="btn btn-link d-flex align-items-center"
          >
            <i class="fal fa-phone me-2" />
            {{ link.text }}
          </button>
          <button
            v-else-if="link.type === 'COPY_CODE'"
            :id="link.example[0]"
            class="btn btn-link d-flex align-items-center"
            @click="copyToClipboard(link.example[0])"
          >
            <i class="fal fa-copy me-2" />
            {{ link.text }}
          </button>
          <button
            v-else-if="link.type === 'QUICK_REPLY'"
            class="btn btn-link d-flex align-items-center"
          >
            <i class="fal fa-reply me-2" />
            {{ link.text }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MessageText from "@whatsapp/components/messages-types/message-text.vue";

export default {
  components: {MessageText},

  props: {
    message: { type: Object, default: null },
    isDeleted: { type: Boolean, default: false },
  },

  methods: {
    copyToClipboard (buttonName){
      navigator.clipboard.writeText(buttonName);
    }
  }

};
</script>
<style scoped lang="scss">
.message-container {
  opacity: 1;
  max-width: 35rem;
}
</style>
