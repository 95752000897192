import store from "@store";
import VoiceSettings from "@dialer/manager/pages/settings/voice-settings/voice-settings.vue";

export default {
  path: "voice-settings",
  name: "voice-settings",
  components: {
    main: VoiceSettings
  },
  beforeEnter: (to, from, next) => {
    if (store.getters["auth/isManager"]) next();
    else next({
      name: "not-found",
      params: {
        pathMatch: to.path.split('/').slice(1)
      }
    });
  }
}
