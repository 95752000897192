export default {
  isLoading: state => state.loading,
  getOfficeHours: state => state.officeHours,
  getFilters: state => state.filters,
  getPagination: (state) => state.pagination,
  getErrors: state => state.errors,
  getRegister: (state) => state.register,
  getParams: (state) =>
    Object.assign(
      {
        'page': state.pagination.current_page,
        'per_page': state.pagination.per_page
      }
      , state.filters, { include: state.include.join(',') }),
}
