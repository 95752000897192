<script setup>
import {
  computed,
  ref,
  watch,
} from "vue"
import {useStore} from "vuex";
import {useDashboardQuery} from "@/composables/useDashboardQuery";
import DashCard from "@crm/pages/dashboard/components/dash-card.vue";
import EarningsChart from "@crm/pages/dashboard/components/charts/earnings-chart.vue";
import moment from "moment";
import EmptyChart from "@crm/pages/dashboard/components/charts/empty-chart.vue";
import {Utils} from "@/utils";
import emptyEarnings from "@/assets/img/empty-earnings.svg";
import RatingFragment from "@crm/pages/dashboard/components/rating-fragment.vue";

const store = useStore();
const dateRange = computed(() => store.getters["crm/dashboard/getDateRange"])
const currentFunnel = computed(() => store.getters["crm/funnels/getCurrentFunnel"] ?? null);
const agent = computed(() => store.getters["crm/dashboard/getActiveAgent"] ?? null);

const {data, loading} = useDashboardQuery(
  currentFunnel,
  dateRange,
  dateRange,
  agent,
  'earningsChart'
);

const invoicingCurrentData = computed(() => data?.value?.chart_invoicing?.map((invoice) => invoice.value));
const invoicingPreviousData = computed(() => data?.value?.chart_invoicing?.map((invoice) => invoice.previous_value));
const opportunitiesCurrentData = computed(() => data?.value?.chart_earnings_opportunities?.map((invoice) => invoice.value));
const opportunitiesPreviousData = computed(() => data?.value?.chart_earnings_opportunities?.map((invoice) => invoice.previous_value));

const labels = computed(() => data?.value?.chart_invoicing?.map((invoice) => moment(invoice.date).format('DD')));

const activeChart = ref('invoice');

function percentageDiff(actualValue, previousValue) {
  if (previousValue === 0) {
    return 0;
  }

  const diff = (actualValue - previousValue) / previousValue * 100;

  return Math.round(diff * 10) / 10;
}


function updateActiveChart(val) {
  activeChart.value = val;
}

watch([dateRange, currentFunnel, agent], () => {
  activeChart.value = 'invoice'
}, {deep: true})

</script>

<script>
export default {
  name: "EarningsComponent",
  methods: {
    toggleCurrency() {
      let locale = 'en';
      let currency = 'USD';

      if (this.$i18n.locale === 'pt') {
        locale = 'pt-BR';
        currency = 'BRL';
      }

      return {
        locale,
        currency
      }
    }
  }
}
</script>


<template>
  <dash-card
    :title="$t('period_earnings')"
    :tooltip-title="$t('tooltips.dashboard.period_earnings.title')"
    :tooltip-body="$t('tooltips.dashboard.period_earnings.body')"
    tooltip-width="383px"
    :loading="loading"
    min-height="485"
  >
    <template #body>
      <empty-chart
        :background-image="emptyEarnings"
        min-height="485px"
        v-if="dateRange.currentLabel === $t('today') || !invoicingCurrentData || !invoicingPreviousData || !labels"
      />
      <div
        v-else
        id="earningsSlider"
        class="carousel slide h-100"
        data-bs-ride="carousel"
      >
        <div
          style="width: 0; height: 0; opacity: 0;"
          class="carousel-indicators"
        >
          <button
            type="button"
            data-bs-target="#earningsSlider"
            data-bs-slide-to="0"
            class="active bg-dark"
            aria-current="true"
            aria-label="Slide 1"
          />
          <button
            class="bg-dark"
            type="button"
            data-bs-target="#earningsSlider"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          />
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <earnings-chart
              color="invoice"
              :actual-data="invoicingCurrentData"
              :previous-data="invoicingPreviousData"
              :labels="labels"
              :active-chart="activeChart"
            />
          </div>
          <div class="carousel-item">
            <earnings-chart
              color="opportunities"
              :actual-data="opportunitiesCurrentData"
              :previous-data="opportunitiesPreviousData"
              :labels="labels"
              :active-chart="activeChart"
            />
          </div>
        </div>
        <div
          style="margin-top: 26px"
          class="row"
        >
          <div class="col-lg-6">
            <button
              :style="activeChart === 'invoice' ? 'border-color: rgb(48,87,242); ' : ''"
              :class="{'active-card': activeChart === 'invoice'}"
              type="button"
              data-bs-target="#earningsSlider"
              data-bs-slide-to="0"
              class="card indicator-card w-100"
              aria-current="true"
              aria-label="Slide 1"
              @click="updateActiveChart('invoice')"
            >
              <span
                style="padding: 1rem"
                class="card-body text-start w-100 d-block"
              >
                <span
                  style="line-height: 1.5"
                  class="d-block tx-text-200 fw-medium"
                >
                  {{ $t('capitalizes.invoicing') }}
                </span>
                <rating-fragment
                  value-size="2rem"
                  :value-color="activeChart === 'invoice' ? 'rgb(48,87,242)': null"
                  :actual-value="Utils.formatCurrency(data?.total_invoicing, toggleCurrency().locale, toggleCurrency().currency, { notation: 'compact',compactDisplay: 'short'})"
                  :previous-value="Utils.formatCurrency(data?.previous_total_invoicing, toggleCurrency().locale, toggleCurrency().currency, { notation: 'compact',compactDisplay: 'short'})"
                  :difference-value="`${Math.abs(percentageDiff(data?.total_invoicing,data?.previous_total_invoicing ))}%`"
                  :difference-status="percentageDiff(data?.total_invoicing,data?.previous_total_invoicing) > 0"
                />
              </span>
            </button>
          </div>

          <div class="col-lg-6">
            <button
              :style="activeChart === 'opportunities' ? 'border-color: rgb(92,184,104); ' : ''"
              :class="{'active-card': activeChart === 'opportunities'}"
              class="card indicator-card w-100"
              type="button"
              data-bs-target="#earningsSlider"
              data-bs-slide-to="1"
              aria-label="Slide 2"
              @click="updateActiveChart('opportunities')"
            >
              <span
                style="padding: 1rem"
                class="card-body text-start w-100 d-block"
              >
                <span
                  style="line-height: 1.5"
                  class="d-block tx-text-200 fw-medium"
                >
                  {{ $t('capitalizes.earned_opportunities') }}
                </span>
                <rating-fragment
                  value-size="2rem"
                  :value-color="activeChart === 'opportunities' ? 'rgb(92,184,104)': null"
                  :actual-value="data?.total_earnings_opportunities"
                  :previous-value="data?.previous_total_earnings_opportunities"
                  :difference-value="`${Math.abs(percentageDiff(data?.total_earnings_opportunities,data?.previous_total_earnings_opportunities ))}%`"
                  :difference-status="percentageDiff(data?.total_earnings_opportunities,data?.previous_total_earnings_opportunities ) > 0"
                />
              </span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </dash-card>
</template>

<style scoped lang="scss">
.indicator-card {
  border-color: #E1E9F4;
  transition: all 0.35s ease;
  box-shadow: 0 12px 24px 0 rgba(18, 38, 63, 0.03);
  margin-bottom: 0;

  &.active-card {
    box-shadow: 0 4px 18px 0 rgba(34, 54, 77, 0.12);
  }
}
</style>