export default {
  loadingFlags: {
    findActiveGroupChannels: false,
    findDeletedGroupChannels: false,
    findGroupChannelById: false,
    deleteGroupChannel: false,
  },
  pagination: {},
  // TODO: Remove loading and use only loadingFlags
  loading: false,
  loadingContext: "",

  groupChannels: {
    active: [],
    deleted: [],
  },
  groupChannelsPagination: {
    active: {},
    deleted: {},
  },
  groupChannel: null,
  groupChannelFilters: {},
  groupChannelIncludes: ['settings'],
  groupChannelInstance: [],
};
