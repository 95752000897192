<template>
  <section
    v-if="!detailsPartner.clicksign_contract.signed && detailsPartner.clicksign_contract.contract_url"
    class="card position-relative"
  >
    <div class="card-body">
      <div class="mb-2 d-flex justify-content-between w-100">
        <img
          alt="3c"
          height="24"
          src="@/assets/img/3czinhos.svg"
        >
        <img
          alt="3c"
          class="position-absolute top-0"
          height="70"
          src="@/assets/img/comment-pink.svg"
          style="right: 0"
        >
      </div>
      <i18n-t
        class="my-2"
        keypath="pending_contract_partner"
        tag="h1"
      >
        <template #contract_partner>
          <span class="tx-blue-300 parter-contract">{{ $t('partner_contract') }}</span>
        </template>
      </i18n-t>
      <a
        class="btn p-0 tx-text-gray"
        :href="detailsPartner.clicksign_contract.contract_url"
        target="_blank"
      >
        {{ $t("view_contract") }} <i class="fa-regular fa-chevron-right text-medium ms-3" />
      </a>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "ContractPartner",
  computed: {
    ...mapGetters("partner", {
      detailsPartner: "getDetailsPartner",
    })
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-family: 'Random Grotesque Standard Light';
  font-weight: 300;
  line-height: 40px;

  span {
    font-family: 'Random Grotesque Standard Medium';
  }
}
</style>
