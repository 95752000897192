<template>
  <span class="fs-6">{{ moods[mood] }}</span>
</template>

<script>
export default {
  name: "EmojiChatMood",

  props: {
    mood: {
      type: Number,
      required: true,
    }
  },

  data() {
    return {
      moods: {
        "2": "🤩",
        "1": "😊",
        "0": "😐",
        "-1": "😞",
        "-2": "😡"
      }
    }
  }
}
</script>