export default {
    startLoadingFlag: (state, flag) => state.loadingFlags[flag] = true,
    stopLoadingFlag: (state, flag) => state.loadingFlags[flag] = false,
    setManualCallACWQualified:(state, manualCallACWQualified) =>
        state.manualCallACWQualified = manualCallACWQualified,

    setList(state, list) {
        state.list = list
    },

    setCallQualificated(state, callQualificated) {
        state.callQualificated = callQualificated
    },

    setErrorMessage(state, errorMesage) {
        state.errorMessage = errorMesage
    },

    clearErrors(state) {
        state.errors = {}
        state.errorMessage = ""
    },

    setErrors(state, errors) {
        state.errors = errors
    }
}
