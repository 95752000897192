export default {
  loadingFlags: {
    getList: false,
    create: false,
    findById: false,
    update: false,
    remove: false
  },
  errors: {},
  errorMessage: "",
  includes: [],

  activeUsers: [],
  totalActiveUsers: null,
  activeUsersPagination: {
    per_page: 12,
    current_page: 1
  },
  activeUsersFilters: {
    companies: []
  },

  inactiveUsers: [],
  totalInactiveUsers: null,
  inactiveUsersPagination: {
    per_page: 12,
    current_page: 1
  },
  inactiveUsersFilters: {
    companies: []
  },

  register: null,

}
