export default {
  setTeamAgents: (state, agents) => state.teamAgents = [...state.teamAgents, ...agents],
  setTeamUsers: (state, users) => state.teamUsers = users,
  setAgentsPagination: (state, pagination) => state.agentsPagination = pagination,

  clearTeamAgents (state) {
    state.teamAgents = []
    state.agentsPagination = {current_page: 1, last_page: 1, per_page: 15, total: 1}
  },

  updateFormInputs (state, payload) {
    state.formInputs.find((field) => {
      if (field.id === payload.id)
        field.is_required = payload.is_required
    })
  },

  startLoading: (state, flag) => state.loadingFlags[flag] = true,
  stopLoading: (state, flag) => state.loadingFlags[flag] = false,
  setErrors: (state, errors) => state.errors = errors,
}
