<script setup>
import {computed} from "vue"
import {useStore} from "vuex";
import {useDashboardQuery} from "@/composables/useDashboardQuery";
import DashCard from "@crm/pages/dashboard/components/dash-card.vue";
import RatingFragment from "@crm/pages/dashboard/components/rating-fragment.vue";

const store = useStore();
const dateRange = computed(() => store.getters["crm/dashboard/getDateRange"])
const currentFunnel = computed(() => store.getters["crm/funnels/getCurrentFunnel"] ?? null);
const agent = computed(() => store.getters["crm/dashboard/getActiveAgent"] ?? null);

const {data, loading} = useDashboardQuery(
  currentFunnel,
  dateRange,
  dateRange,
  agent,
  'conversionRate'
);

</script>

<template>
  <dash-card
    :title="$t('conversion_rate')"
    :tooltip-title="$t('tooltips.dashboard.conversion_rate.title')"
    :tooltip-body="$t('tooltips.dashboard.conversion_rate.body')"
    tooltip-width="364px"
    :loading="loading"
    min-height="72"
  >
    <template #body>
      <rating-fragment
        :actual-value="`${data?.actual_percentage}%`"
        :previous-value="`${data?.previous_percentage}%`"
        :difference-value="`${Math.abs(data?.percentage_diff)}%`"
        :difference-status="data?.percentage_diff > 0"
      />
    </template>
  </dash-card>
</template>

<style scoped lang="scss">

</style>