<template>
  <!-- Modal -->
  <div 
    class="modal fade"
    aria-hidden="true"
    ref="tooManyRequestsModal"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content text-center">
        <div class="modal-body">
          <img
            class="my-5"
            src="@/assets/img/gifs/hourglass.gif" 
            alt="Ampulheta" 
            width="150"
          >

          <h2>Opa, você está indo rápido demais!</h2>
          
          <p class="text-secondary">
            O limite de requisições foi atingido,
            <br> 
            aguarde para voltar a utilizar o sistema.
          </p>

          <hr class="mx-4 mt-5">
          <p>
            Por favor, aguarde 
            <span class="text-primary">{{ secondsLeftToCloseModal }}s</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';
import { mapMutations, mapGetters } from 'vuex';
import { Timer } from "easytimer.js";


export default {
  /**
   * 
   */
  data() {
    return {
      tooManyRequestsModal: null,
      timer: null,
      secondsLeftToCloseModal: 60
    }
  },
  /**
   * 
   */
  mounted() {
    this.timer = new Timer();
    this.tooManyRequestsModal = new Modal(
      this.$refs.tooManyRequestsModal,
      {
        backdrop: "static",
        keyboard: false
      }
    );
  },
  /**
   * 
   */
  computed: {
    ...mapGetters("system", {
      requestLimitExceeded: "isRequestLimitExceeded",
    })
  },
  /**
   * 
   */
  methods: {
    ...mapMutations("system", ["setJsSIPActive", "setSocketIOActive"]),
  },
  /**
   * 
   */
  watch: {
    /**
     * Watcher to verify if the modal needs to be 
     * displayed.
     * 
     * @param {Boolean} data 
     */
    requestLimitExceeded(data) {
      if(data) {
        this.tooManyRequestsModal.show();
        this.timer.start({
          countdown: true,
          startValues: {
            seconds: this.secondsLeftToCloseModal
          }
        });
        
        this.timer.addEventListener("secondsUpdated", (e) => {
          this.secondsLeftToCloseModal = e.detail.timer.getTimeValues().seconds;
        });

        this.timer.addEventListener("targetAchieved", () => {
          this.setJsSIPActive(false);
          this.setSocketIOActive(false);
          this.tooManyRequestsModal.hide();
          this.$router.go();
        })
      }
    }
  }
}

</script>

<style scoped lang="scss">
.modal {
  z-index: 9999 !important;
  background: #00000033;
}
</style>