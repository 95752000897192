import io from "socket.io-client";
import { AgentStatus, AMDConsts, CallMode, ReceptiveStatus } from "@/utils";
import UserSocket from "@/websocket/userSocket";

export default store => {
  const isAgentOnManualMode = (agent) => {
    return agent.status === AgentStatus.ON_MANUAL_CALL ||
      agent.status === AgentStatus.ON_MANUAL_CALL_CONNECTED;
  }

  const isManualCall = (call) => {
    return call.call_mode === CallMode.MANUAL || call.call_mode === CallMode.MANUAL_ACW;
  }

  const ignoreEvent = (campaign_id) => {

    let active = store.getters["manager/real-time/isUpdateAgentsRealTimeActive"] ||
      store.getters["manager/real-time/isUpdateCallsInRealTimeActive"]

    let campaign = store.getters["manager/real-time/getRealTimeCampaignContext"]

    if (campaign && campaign.total_campaign)
      return !(active && (campaign && campaign_id));
    else
      return !(active && (campaign && campaign_id == campaign.id));
  }


  const checkQueueId = (queue_id) => {
    if (store.getters["manager/receptive_queues/getReceptiveQueue"]) {
      return parseInt(store.getters["manager/receptive_queues/getReceptiveQueue"].id) == parseInt(queue_id);
    }
  }

  const shouldUpdateCounter = (event) => {
    if (store.getters["manager/receptive_queues/getReceptiveQueue"] == null)
      return false;

    return event.queues && event.queues.find(id => id === store.getters["manager/receptive_queues/getReceptiveQueue"].id);
  }

  const socket = io(process.env.VUE_APP_JK78OP, {
    transports: ["websocket"],
    query: {
      token: store.getters["auth/getUser"]
        ? store.getters["auth/getUser"].api_token
        : "",
    },
    forceNew: true,
  });

  const managerSocket = new UserSocket(
    store.getters["auth/getUser"],
    socket,
  );

  socket.on("connect", () => {
    console.log("[socket.io] connected!")
    store.commit("system/setSocketIOStatus", "connected");
  });

  socket.on("disconnect", () => {
    console.log("Disconnect");
    store.commit("system/setSocketIOStatus", "disconnected");
  });

  socket.io.on("reconnect", (attempt) => {
    console.log("[socket.io] reconnected, attemp:" + attempt)
    store.commit("system/setSocketIOStatus", "connected");
    store.dispatch("whatsapp/chat/reconnectWhatsapp")
  });

  /**
   * calls
   */
  socket.on("call-was-created", event => {
    // Campaigns Dashboard
    store.dispatch("manager/campaign/calls/updateOrCreateActiveCall", event);
  });

  socket.on("call-is-trying", event => {
    // Campaigns Dashboard
    store.dispatch("manager/campaign/calls/updateOrCreateActiveCall", event);

    if (ignoreEvent(event.call.campaign_id)) return;
    store.commit("manager/real-time/setTrying", {
      call: event.call,
    });
  });

  socket.on("hold-call", event => {
    if (ignoreEvent(event.call.campaign_id)) return;
    store.commit("manager/real-time/setWaiting", {
      call: event.call,
    });
  });

  socket.on("call-was-amd", event => {
    // Campaigns Dashboard
    store.dispatch("manager/campaign/calls/updateOrCreateActiveCall", event);

    if (ignoreEvent(event.call.campaign_id)) return;
    if (store.getters["manager/real-time/getRealTimeCampaignContext"].amd_enabled) {
      let amd = parseInt(event.call.amd_status) || 0
      if (![AMDConsts.MAILBOX, AMDConsts.SILENCE, AMDConsts.ERROR].includes(amd)) {
        if (event.call.ura)
          store.commit("manager/real-time/setUra", {
            call: event.call,
          });
        else
          store.commit("manager/real-time/setWaiting", {
            call: event.call,
          });
      }
    }
  });

  socket.on("call-was-answered", event => {
    // Campaigns Dashboard
    store.dispatch("manager/campaign/calls/updateOrCreateActiveCall", event);

    if (ignoreEvent(event.call.campaign_id)) return;
    if (!isManualCall(event.call))
      if (store.getters["manager/real-time/getRealTimeCampaignContext"].amd_enabled) {
        store.commit("manager/real-time/setAmd", {
          call: event.call,
        });
      } else {
        if (event.call.ura) {
          store.commit("manager/real-time/setUra", {
            call: event.call,
          });
        } else
          store.commit("manager/real-time/setWaiting", {
            call: event.call,
          });
      }
  });

  socket.on("call-was-connected", event => {
    // Campaigns Dashboard
    store.dispatch("manager/campaign/calls/updateOrCreateActiveCall", event);

    if (event.queues) {
      store.commit("manager/receptive_queues/setAgentStatus", {
        id: event.agent.id,
        status: AgentStatus.ON_CALL,
        queues: event.queues,
      });
    }

    // Receptive agents card
    if (shouldUpdateCounter(event)) {
      if (isManualCall(event.call)) {
        store.commit("manager/receptive_agents/setQueueAgentStatus", {
          id: event.agent.id,
          status: AgentStatus.ON_MANUAL_CALL,
          connected: AgentStatus.ON_MANUAL_CALL_CONNECTED,
        });
      } else {
        store.commit("manager/receptive_agents/setQueueAgentStatus", {
          id: parseInt(event.call.agent),
          status: AgentStatus.ON_CALL,
          connected: false,
          call_mode: event.call.call_mode,
        });
      }
    }

    // Voice Panel
    store.dispatch("manager/agents/updateAgentOnline", event);

    // Campaigns Dashboard
    store.dispatch("manager/campaign/agents/updateOrCreateAgentOnline", event);

    // Show Campaign Dashboard
    store.dispatch("manager/campaign/agents/updateAgentStatusInCampaign", event);

    if (!ignoreEvent(event.campaignId)) {
      if (isManualCall(event.call)) {
        store.commit("manager/real-time/manualWasConnected", { agent: event.agent, call: event.call });
      } else {
        store.commit("manager/real-time/wasConnected", { agent: event.agent, call: event.call });
      }

      const agents = store.getters["manager/campaign/agents/getAgents"];
      if (agents[event.agent.id] && event.mailing) {
        store.commit("manager/real-time/wasConnected", {
          agent: event.agent,
          call: event.call,
          mailing: event.mailing.data,
        });
      }
    }

    if (!ignoreEvent(event.call.campaign_id)) {
      store.commit("manager/real-time/setInProgress", {
        call: event.call,
      });
    }
  });

  socket.on("manual-call-was-qualified", event => {
    // Show Campaign Dashboard
    store.dispatch("manager/campaign/agents/qualifyManualCallFromAgent", event);
    store.dispatch("manager/campaign/agents/removeAgentFromAcwManual", event);
  });

  socket.on("manual-call-acw-connected", event => {
    if (event.queues) {
      store.commit("manager/receptive_queues/setAgentStatus", {
        id: event.agent.id,
        status: AgentStatus.ON_CALL,
        queues: event.queues,
      });
    }

    // Receptive agents card
    if (shouldUpdateCounter(event)) {
      store.commit("manager/receptive_agents/setQueueAgentStatus", {
        id: event.agent.id,
        status: AgentStatus.ON_MANUAL_CALL_ACW,
        connected: AgentStatus.ON_MANUAL_CALL_ACW_CONNECTED,
      });
    }

    // Voice Panel
    store.dispatch("manager/agents/updateAgentOnline", event);

    // Campaigns Dashboard
    store.dispatch("manager/campaign/agents/updateOrCreateAgentOnline", event);

    // Show Campaign Dashboard
    store.dispatch("manager/campaign/agents/updateAgentStatusInCampaign", event);

    if (ignoreEvent(event.call.campaign_id)) return;
    store.commit("manager/real-time/setManualCallAcwConnected", { agent: event.agent });
    store.commit("manager/real-time/setInProgress", {
      call: event.call,
    });
  });

  socket.on("manual-call-acw-disconnected", event => {
    if (event.queues) {
      store.commit("manager/receptive_queues/setAgentStatus", {
        id: event.agent.id,
        status: AgentStatus.ON_MANUAL_CALL_ACW,
        queues: event.queues,
      });
    }

    // Receptive agents card
    if (shouldUpdateCounter(event)) {
      store.commit("manager/receptive_agents/setQueueAgentStatus", {
        id: event.agent.id,
        status: AgentStatus.ON_MANUAL_CALL_ACW,
        connected: false,
      });
    }

    // Voice Panel
    store.dispatch("manager/agents/updateAgentOnline", event);

    // Campaigns Dashboard
    store.dispatch("manager/campaign/agents/updateOrCreateAgentOnline", event);

    // Show Campaign Dashboard
    store.dispatch("manager/campaign/agents/updateAgentStatusInCampaign", event);

    if (ignoreEvent(event.call.campaign_id)) return;
    store.commit("manager/real-time/clearManualCallAcw", { agent: event.agent });
  });

  socket.on("call-was-finished", event => {
    // Campaigns Dashboard
    store.dispatch("manager/campaign/calls/removeActiveCall", event.call);

    if (ignoreEvent(event.call.campaign_id)) return;
    store.commit("manager/real-time/removeCall", {
      call: event.call,
    });
    store.commit("manager/real-time/removeMailing", event.agent.id)
  });

  socket.on("call-was-abandoned", event => {
    // Campaigns Dashboard
    store.dispatch("manager/campaign/calls/removeActiveCall", event.call);

    if (ignoreEvent(event.call.campaign_id)) return;
    store.commit("manager/real-time/removeCall", {
      call: event.call,
    });
  });

  socket.on("call-was-not-answered", event => {
    // Campaigns Dashboard
    store.dispatch("manager/campaign/calls/removeActiveCall", event.call);

    if (ignoreEvent(event.call.campaign_id)) return;
    store.commit("manager/real-time/removeCall", {
      call: event.call,
    });
  });

  socket.on("call-was-failed", event => {
    // Campaigns Dashboard
    store.dispatch("manager/campaign/calls/removeActiveCall", event.call);

    if (ignoreEvent(event.call.campaign_id)) return;
    store.commit("manager/real-time/removeCall", {
      call: event.call,
    });
  });

  socket.on("call-history-was-created", event => {
    const call = {
      id: `call:${event.callHistory.company.id}:${event.callHistory.campaign.id}:${event.callHistory.telephony_id}`,
    }
    // Voice Panel
    store.dispatch("manager/agents/incrementCallsAndConvertedMetrics", event);

    // Campaigns Dashboard
    store.dispatch("manager/campaign/calls/removeActiveCall", call);

    if (ignoreEvent(event.callHistory.campaign.id)) return;
    store.commit("manager/real-time/enqueueFinalizedCalls", {
      hangupCause: event.hangupCause,
      callHistory: event.callHistory,
    });
  })


  /**
   *  Agentes
   */
  socket.on("agent-is-idle", event => {
    // Dashboard Receptive Queues
    if (event.queues) {
      if (isAgentOnManualMode(event.agent)) {
        let agent = store.getters["manager/receptive_queues/getTotal"].agents.agents.find(a => a.id === event.agent.id);
        if (agent && (agent.status !== AgentStatus.OFFLINE)) {
          store.commit("manager/receptive_queues/setAgentStatus", {
            id: event.agent.id,
            status: AgentStatus.ON_MANUAL_CALL,
            queues: event.queues,
          });
        }
      } else {
        store.commit("manager/receptive_queues/setAgentStatus", {
          id: event.agent.id,
          status: AgentStatus.IDLE,
          queues: event.queues,
        });
      }
    }

    // Receptive agents card
    if (shouldUpdateCounter(event)) {
      store.commit("manager/receptive_agents/setWorkBreak", {
        id: event.agent.id,
        work_break: null,
      });

      if (isAgentOnManualMode(event.agent)) {
        let agent = store.getters["manager/receptive_agents/getAgents"].find(a => a.id === event.agent.id);
        if (agent && (agent.status !== AgentStatus.OFFLINE)) {
          store.commit("manager/receptive_agents/setQueueAgentStatus", {
            id: event.agent.id,
            status: AgentStatus.ON_MANUAL_CALL,
            connected: false,
          });
        }
      } else {
        store.commit("manager/receptive_agents/setQueueAgentStatus", {
          id: event.agent.id,
          status: AgentStatus.IDLE,
          connected: false,
        });
      }
    }

    // Voice Panel
    store.dispatch("manager/agents/updateAgentOnline", event);

    // Campaigns Dashboard
    store.dispatch("manager/campaign/insertOnlineAgentInActiveCampaign", event);
    store.dispatch("manager/campaign/agents/updateOrCreateAgentOnline", event);

    // Show Campaign Dashboard
    store.dispatch("manager/campaign/agents/updateAgentStatusInCampaign", event);

    if (ignoreEvent(event.campaignId)) return;
    store.commit("manager/real-time/isIdle", {
      agent: event.agent,
    });
  });

  socket.on("agent-was-logged-out", event => {
    if (event.queues) {
      store.commit("manager/receptive_queues/setAgentStatus", {
        id: event.agent.id,
        status: AgentStatus.OFFLINE,
        queues: event.queues,
      });
    }

    store.commit("manager/campaign/stopLoading");

    // Receptive agents card
    if (shouldUpdateCounter(event)) {
      store.commit("manager/receptive_agents/setQueueAgentStatus", {
        id: event.agent.id,
        status: AgentStatus.OFFLINE,
        connected: false,
      });
    }

    // Voice Panel
    store.dispatch("manager/agents/updateAgentOnline", event);

    // Campaigns Dashboard
    store.dispatch("manager/campaign/removeAgentFromActiveCampaign", event);
    store.dispatch("manager/campaign/agents/removeAgentOnline", event);

    // Show Campaign Dashboard
    store.dispatch("manager/campaign/agents/setAgentStatusInCampaignAsOffline", event);

    if (ignoreEvent(event.campaignId)) return;
    store.commit("manager/real-time/loggedOut", { agent: event.agent });
  });

  socket.on("agent-in-acw", event => {
    if (event.queues) {
      store.commit("manager/receptive_queues/setAgentStatus", {
        id: event.agent.id,
        status: AgentStatus.ACW,
        queues: event.queues,
      });
    }

    // Receptive agents card
    if (shouldUpdateCounter(event)) {
      store.commit("manager/receptive_agents/setQueueAgentStatus", {
        id: event.agent.id,
        status: AgentStatus.ACW,
        connected: false,
      });
    }

    // Voice Panel
    store.dispatch("manager/agents/updateAgentOnline", event);

    // Campaigns Dashboard
    store.dispatch("manager/campaign/agents/updateOrCreateAgentOnline", event);

    // Show Campaign Dashboard
    store.dispatch("manager/campaign/agents/updateAgentStatusInCampaign", event);

    if (ignoreEvent(event.campaignId)) return;
    store.commit("manager/real-time/inAcw", { agent: event.agent });
  });

  socket.on("agent-entered-manual", event => {
    if (event.queues) {
      store.commit("manager/receptive_queues/setAgentStatus", {
        id: event.agent.id,
        status: AgentStatus.ON_MANUAL_CALL,
        queues: event.queues,
      });
    }

    // Receptive agents card
    if (shouldUpdateCounter(event)) {
      store.commit("manager/receptive_agents/setQueueAgentStatus", {
        id: event.agent.id,
        status: AgentStatus.ON_MANUAL_CALL,
        connected: false,
      });
    }

    // Voice Panel
    store.dispatch("manager/agents/updateAgentOnline", event);

    // Campaigns Dashboard
    store.dispatch("manager/campaign/agents/updateOrCreateAgentOnline", event);

    // Show Campaign Dashboard
    store.dispatch("manager/campaign/agents/updateAgentStatusInCampaign", event);

    if (ignoreEvent(event.campaignId)) return;
    store.commit("manager/real-time/enterManual", { agent: event.agent });
  });

  socket.on("agent-left-manual", event => {
    if (event.queues) {
      let agent = store.getters["manager/receptive_queues/getTotal"].agents.agents.find(a => a.id === event.agent.id);
      if (agent && (agent.status !== AgentStatus.OFFLINE)) {
        store.commit("manager/receptive_queues/setAgentStatus", {
          id: event.agent.id,
          status: AgentStatus.IDLE,
          queues: event.queues,
        });
      }
    }

    let agent = store.getters["manager/receptive_agents/getAgents"].find(a => a.id === event.agent.id);
    if (agent && (agent.status !== AgentStatus.OFFLINE)) {
      store.commit("manager/receptive_agents/setQueueAgentStatus", {
        id: event.agent.id,
        status: AgentStatus.IDLE,
        connected: false,
      });
    }
  });

  socket.on("agent-entered-manual-acw", event => {
    if (event.queues) {
      store.commit("manager/receptive_queues/setAgentStatus", {
        id: event.agent.id,
        status: AgentStatus.ON_MANUAL_CALL_ACW,
        queues: event.queues,
      });
    }

    // Receptive agents card
    if (shouldUpdateCounter(event)) {
      store.commit("manager/receptive_agents/setQueueAgentStatus", {
        id: event.agent.id,
        status: AgentStatus.ON_MANUAL_CALL_ACW,
        connected: false,
      });
    }

    // Voice Panel
    store.dispatch("manager/agents/updateAgentOnline", event);

    // Campaigns Dashboard
    store.dispatch("manager/campaign/agents/updateOrCreateAgentOnline", event);

    // Show Campaign Dashboard
    store.dispatch("manager/campaign/agents/updateAgentStatusInCampaign", event);

    if (ignoreEvent(event.campaignId)) return;
    store.commit("manager/real-time/enterManualCallAcw", { agent: event.agent });
  });

  socket.on("agent-left-manual-acw", event => {
    if (event.queues) {
      store.commit("manager/receptive_queues/setAgentStatus", {
        id: event.agent.id,
        status: AgentStatus.ACW,
        queues: event.queues,
      });
    }

    // Voice Panel
    store.dispatch("manager/agents/updateAgentOnline", event);

    // Campaigns Dashboard
    store.dispatch("manager/campaign/agents/updateOrCreateAgentOnline", event);

    // Show Campaign Dashboard
    store.dispatch("manager/campaign/agents/updateAgentStatusInCampaign", event);

    // Receptive agents card
    if (shouldUpdateCounter(event)) {
      store.commit("manager/receptive_agents/setQueueAgentStatus", {
        id: event.agent.id,
        status: AgentStatus.ACW,
        connected: false,
      });
    }

    if (ignoreEvent(event.campaignId)) return;
    store.commit("manager/real-time/leftManualCallAcw", { agent: event.agent });
  });

  socket.on("agent-entered-work-break", event => {
    if (event.queues) {
      store.commit("manager/receptive_queues/setAgentStatus", {
        id: event.agent.id,
        status: AgentStatus.ON_WORK_BREAK,
        queues: event.queues,
      });
    }

    // Receptive agents card
    if (shouldUpdateCounter(event)) {
      store.commit("manager/receptive_agents/setWorkBreak", {
        id: event.agent.id,
        work_break: event.work_break,
      });

      store.commit("manager/receptive_agents/setQueueAgentStatus", {
        id: event.agent.id,
        status: AgentStatus.ON_WORK_BREAK,
        connected: false,
      });
    }

    // Voice Panel
    store.dispatch("manager/agents/updateAgentOnline", event);

    // Campaigns Dashboard
    store.dispatch("manager/campaign/agents/updateOrCreateAgentOnline", event);

    // Show Campaign Dashboard
    store.dispatch("manager/campaign/agents/updateAgentStatusInCampaign", event);
    store.dispatch("manager/campaign/agents/setAgentWorkBreak", event);

    if (ignoreEvent(event.campaignId)) return;
    store.commit("manager/real-time/enteredWorkBreak", { agent: event.agent, work_break: event.work_break });
  });


  /**
   * Filas Receptivas
   */

  socket.on("receptive-entered-queue", event => {
    // Dashboard
    if (event.call.queue_id &&
      store.getters["manager/receptive_queues/getQueues"].length) {
      store.commit("manager/receptive_queues/setStatusQueue", {
        queueId: parseInt(event.call.queue_id),
        status: ReceptiveStatus.IN_QUEUE,
      });
    }

    // Call's Card
    if (checkQueueId(event.call.queue_id)) {
      store.commit("manager/receptive_calls/setCallsStatusHold", event.call);
    }
  });

  socket.on("receptive-connected", event => {
    // Dashboard
    if (event.call.queue_id &&
      store.getters["manager/receptive_queues/getQueues"].length) {
      store.commit("manager/receptive_queues/setStatusQueue", {
        queueId: parseInt(event.call.queue_id),
        status: ReceptiveStatus.CONNECTED,
      });
    }

    // Call's Card
    if (event.call.queue_id !== undefined && checkQueueId(event.call.queue_id)) {
      store.commit("manager/receptive_calls/setCallsStatusConnected", {
        call: event.call,
        agent: event.agent,
        extension: event.extension,
      });
    }
  });

  socket.on("receptive-ended", event => {
    // Dashboard
    if (event.call.queue_id &&
      store.getters["manager/receptive_queues/getQueues"].length) {
      store.commit("manager/receptive_queues/setStatusQueue", {
        queueId: parseInt(event.call.queue_id),
        status: ReceptiveStatus.ENDED,
      });
    }

    // Call's Card
    if (event.call.queue_id !== undefined && checkQueueId(event.call.queue_id)) {
      store.commit("manager/receptive_calls/removeCall", event.call.telephony_id);
    }
  });

  socket.on("receptive-abandoned", event => {
    // Dashboard
    if (event.call.queue_id &&
      store.getters["manager/receptive_queues/getQueues"].length) {
      store.commit("manager/receptive_queues/setStatusQueue", {
        queueId: parseInt(event.call.queue_id),
        status: ReceptiveStatus.ABANDONED,
      });
    }

    // Call's Card
    if (event.call.queue_id !== undefined && checkQueueId(event.call.queue_id)) {
      store.commit("manager/receptive_calls/removeCall", event.call.telephony_id);
    }
  });

  socket.on("consult-connected", event => {
    // Receptive agents card
    if (shouldUpdateCounter(event)) {
      store.commit("manager/receptive_agents/setQueueAgentStatus", {
        id: parseInt(event.call.consultant),
        status: AgentStatus.ON_CALL,
        connected: false,
        call_mode: event.call.call_mode,
      });
    }
  });

  /**
   * PIX
   */
  socket.on("payment-approved", () => {
    store.commit("manager/user_profile/setPixPaid", true)
  });

  /**
   * SPY
   */

  socket.on("spy-started", event => {
    if (event.spy.id != store.getters["auth/getUser"].id)
      return;
    store.commit("system/stopLoadingFlag", "startAgentSpy");
    store.commit("system/setSpiedAgent", event.spied);
    let agentSpied = {};
    if (Object.values(store.getters["manager/campaign/agents/getAgents"]).length) {
      agentSpied = Object.values(store.getters["manager/campaign/agents/getAgents"]).find(a => a.id === event.spied.id)
    } else {
      agentSpied = Object.values(store.getters["manager/agents/getAgents"]).find(a => a.id === event.spied.id)
    }
    store.commit("system/setSelectedAgentToSpy", agentSpied)
    console.log("Spy started! " + event.spied.name);
  });

  socket.on("spy-ended", event => {
    if (event.spy.id != store.getters["auth/getUser"].id)
      return;
    store.commit("system/stopLoadingFlag", "stopAgentSpy");

    // clear spiedAgent in another manager tab if another spy is not started
    store.commit("system/setSpiedAgent", null)
    store.commit("system/setSelectedAgentToSpy", null)
    console.log("Spy stoped! " + event.spied.name);
  });

  socket.on("spy-failed", event => {
    if (event.spy.id != store.getters["auth/getUser"].id)
      return;
    store.commit("system/stopLoadingFlag", "startAgentSpy");
    store.commit("system/stopLoadingFlag", "stopAgentSpy");
    store.commit("system/setSpyError", "Falha ao entrar em modo spy");
    store.commit("system/setSpiedAgent", null);
    store.commit("system/setSelectedAgentToSpy", null)
    store.commit("system/setErrorMessage", "Falha ao entrar em modo spy");
  });

  socket.on("agent-left-work-break", (event) => {
    const { agent } = event;
    store.dispatch("manager/campaign/agents/setWorkBreakExitPending", {
      id: agent.id,
      work_break_exit_pending: false,
    });

    store.dispatch("manager/agents/setWorkBreakExitPending", {
      id: agent.id,
      work_break_exit_pending: false,
    });
  });

  socket.on("agent-requested-work-break", event => {
    store.dispatch("manager/campaign/agents/setWorkBreakExitPending", event.agent);
    store.dispatch("manager/agents/setWorkBreakExitPending", event.agent);
  })

  // Whatsapp
  managerSocket.init();


  socket.on("opportunities-csv-validated", ({ validation }) => {
    setTimeout(() => {
      const importStep = store.getters["crm/imports/getStep"];
      if (importStep !== 2) {
        return
      }
      store.commit("crm/imports/setStep", 3)
      store.commit("crm/imports/setValidationCsv", validation)
    }, 1000)
  })

  socket.on("opportunities-csv-imported", ({ data }) => {
    const step = {
      id: data.step_id,
      funnel_id: data.funnel_id,
    }
    store.dispatch("crm/opportunities/fetchStepOpportunities", { step, page: 1 })

    const currentFunnel = store.getters["crm/funnels/getCurrentFunnel"];
    store.commit("crm/funnels/setCurrentFunnel", { ...currentFunnel, is_importing: false })

    store.commit("system/setCustomizedTitleMessage", "success_message.import_your_list")
    store.commit("system/setSuccessMessage", " ")
    setTimeout(() => {
      store.commit("system/setCustomizedTitleMessage", null)
    }, 1000)
  })

  socket.on("new-form-lead", (opportunity) => {
    const currentFunnel = store.getters["crm/funnels/getCurrentFunnel"];
    const step_oportunity = currentFunnel.steps.find(step => step.id === opportunity.step_id)
    store.commit("crm/opportunities/setOpportunity", { opportunity });
    if (step_oportunity) {
      store.dispatch("crm/opportunities/fetchStepOpportunities", {
        step: step_oportunity,
        page: 1,
      })
    }
  })

  socket.on("fuck-the-dash-event", ({ data }) => {
    if (data?.thread_id) {
      store.commit("crm/ai_assistant/setThreadId", data.thread_id)
    }
    if (data.response) {
      store.commit("crm/ai_assistant/setCurrentResponse", data.response);
    } else {
      store.commit("crm/ai_assistant/setCurrentResponse", "Houve algum erro, tente novamente!");
    }

    store.commit("crm/ai_assistant/stopLoading", "sendQuestion");
  })

  socket.on("new-whatsapp-ai-chat-evaluation-message", ({ chat, message }) => {
    store.dispatch("whatsapp/message/addRecentMessage", message)
    store.commit("whatsapp/message/setNewMessage", message)
    store.commit("whatsapp/chat/updateChatMood", { chatId: chat.id, mood: message.body.mood })
  });

  return socket;
};
