<template>
  <modal-component
    :show="show"
    :center="true"
    @close="closeModal"
  >
    <template #content>
      <div class="modal-header">
        <h2
          id="modalToggleLabel"
          class="modal-title"
        >
          Analisar saque
        </h2>
        <button
          aria-label="Close"
          class="btn"
          data-bs-dismiss="modal"
          type="button"
        >
          <i class="fa-regular fa-xmark fs-3 tx-text-gray" />
        </button>
      </div>
      <div class="modal-body">
        <div
          v-for="(slot, index) in dynamicSlots"
          :key="index"
          :class="slot.className"
        >
          <slot :name="slot.name">
            {{ slot.content }}
          </slot>
        </div>
        <div
          v-if="dynamicRequestSlots"
          class="d-flex gap-5"
        >
          <div class="w-50">
            <slot
              v-for="(request, index) in dynamicRequestSlots.startRequest"
              :key="index"
              :name="request.name"
            >
              <span
                :class="request.className"
                class="d-block"
              >
                {{ request.content }}
              </span>
            </slot>
          </div>
          <div class="w-50">
            <slot
              v-for="(request, index) in dynamicRequestSlots.endRequest"
              :key="index"
              :name="request.name"
            >
              <span
                :class="request.className"
                class="d-block"
              >
                {{ request.content }}
              </span>
            </slot>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="d-block w-100">
          <slot name="status" />
          <div class="d-flex mb-5 pt-3 gap-4">
            <slot name="status-info" />
            <slot name="status-info-2" />
          </div>
          <app-button
            class="w-100"
            :class="enableButton ? 'btn-primary' : 'disabled btn-secondary'"
            :disabled="!approved && !recused || loadingFlags.updateWithdraw || loadingFlags.loadWithdrawalAnalysisPartners || loadingFlags.loadWithdrawalFinalizedPartners"
            :loading="loadingFlags.updateWithdraw || loadingFlags.loadWithdrawalAnalysisPartners || loadingFlags.loadWithdrawalFinalizedPartners"
            @click="handleWithdrawalUpdate"
          >
            Finalizar análise
          </app-button>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalComponent from "@/components/modal-component.vue";
import AppButton from "@/components/app-button.vue";

export default {
  name: "ModalRequestPartner",
  data() {
    return {
      show: false
    };
  },

  components: {
    ModalComponent,
    AppButton
  },

  props: {
    dynamicSlots: {
      type: Array,
      default: () => []
    },
    dynamicRequestSlots: {
      type: Object,
      default: () => ({})
    },
    partner: {
      type: Object,
      default: () => ({})
    },
    approved: {
      type: Boolean,
      default: false
    },
    recused: {
      type: Boolean,
      default: false
    },
    enableButton: {
      type: Boolean,
      default: false
    }
  },
  emits: ["modal-closed"],

  computed: {
    ...mapGetters("admin/partners", {
      loadingFlags: "getLoadingFlags"
    })
  },

  methods: {
    ...mapActions("admin/partners", [
      "updateWithdraw",
      "loadWithdrawalAnalysisPartners",
      "loadWithdrawalFinalizedPartners"
    ]),

    handleWithdrawalUpdate() {
      const partner = { ...this.partner };

      if (this.approved) {
        partner.status = "Aprovado";
        partner.approve = true;
      } else if (this.recused) {
        partner.status = "Recusado";
        partner.approve = false;
      }

      this.updateWithdraw({ withdrawId: partner.id, withdraw: partner }).then(() => {
        this.loadWithdrawalAnalysisPartners();
        this.loadWithdrawalFinalizedPartners();
        this.show = false;
      });
    },

    openModal() {
      this.show = true;
    },

    closeModal() {
      this.show = false;
      this.$emit("modal-closed");
    }
  }
};
</script>
<style lang="scss" scoped>
.index {
  z-index: 20000 !important;
}

.badge-status {
  border-radius: 8px;
  font-size: 16px;
  padding: 2px 16px;
}

.success {
  background-color: #d6e9d9;
  color: #015d0d;
}

.defaulting {
  background-color: #f2c6d1;
  color: #920000;
}
</style>
