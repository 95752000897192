<template>
  <div
    ref="offcanvas"
    class="offcanvas offcanvas-end"
    style="overflow-y: scroll; width: 660px !important"
  >
    <div
      class="offcanvas-header"
    >
      <span
        class="mb-6 cursor-pointer p-2"
        v-if="history.length > 1"
        @click="backHistory"
      >
        <i class="far fa-arrow-left" />
      </span>

      <div
        class="offcanvas-title d-flex flex-column justify-content-center align-items-center w-100"
      >
        <h2 class="m-2">
          {{ $t("call_information") }}
        </h2>
        <span class="text-muted fs-3 text-center">
          {{ $t("mailing_info") }}
          <br>
          {{ $t("call_info") }}
        </span>
      </div>
      <button
        type="button"
        class="btn-close text-reset fs-1 mb-6"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      />
    </div>

    <div
      class="offcanvas-body text-start"
      v-show="!loadingFlags.loadCallById"
    >
      <h3>{{ $t("abstract") }}</h3>
      <div v-if="callCurrent">
        <div class="row mb-5">
          <div class="col-12 col-xl-3 col-lg-6 mb-3">
            <small class="text-muted">{{ $t("agent_capitalize") }}</small><br>
            <small>{{ callCurrent.agent }}</small>
          </div>

          <div class="col-12 col-xl-3 col-lg-6 mb-3">
            <small class="text-muted">{{ $t("number") }}</small><br>
            <small>{{ formatPhoneNumber(callCurrent.number) }}</small>
          </div>

          <div class="col-12 col-xl-3 col-lg-6 mb-3">
            <small class="text-muted">{{ $t("date_capitalize") }}</small><br>
            <small>{{ formatDate(callCurrent.call_date) }}</small>
          </div>

          <div class="col-12 col-xl-3 col-lg-6 mb-3">
            <small class="text-muted">{{ $t("qualification") }}</small><br>
            <small>{{ callCurrent.qualification }}</small>
          </div>

          <div class="col-12">
            <small class="text-muted">
              {{ $t("call") }}
            </small>
            <audio
              v-if="updateAudio"
              preload="none"
              controls
              controlsList="nodownload"
              class="w-100 mt-2"
              ref="audio"
            >
              <source
                :src="callCurrent.recording"
                type="audio/mpeg"
              >
            </audio>
          </div>
        </div>
        <h3 v-if="callCurrent.qualification_note">
          {{ $t("comment") }}
        </h3>
        <div
          class="row mb-3"
          v-if="callCurrent.qualification_note"
        >
          <div class="col-12">
            <div class="card p-3 bg-gray-blue-200">
              <span class="w-100 tx-text-gray m-0">
                {{ callCurrent.qualification_note }}
              </span>
            </div>
          </div>
        </div>
        <h3 v-if="Object.values(callCurrent.mailing_data).length">
          {{ $t("mailing_data") }}
        </h3>
        <div
          class="row mb-5"
          v-if="Object.values(callCurrent.mailing_data).length"
        >
          <div class="col-12 mb-3">
            <small class="text-muted">{{ $t("identifier") }}</small><br>
            <small>{{ callCurrent.mailing_data?.identifier }}</small>
          </div>

          <div
            v-for="(item, key) in callCurrent.mailing_data?.data"
            :key="key"
            class="col-12 mb-3"
          >
            <small class="text-muted">{{ key }}</small><br>
            <small>{{ item }}</small>
          </div>
        </div>

        <h3>{{ $t("call_data") }}</h3>
        <div class="row">
          <div class="col-12 mb-3">
            <small class="text-muted">{{ $t("campaign_capitalize") }}</small><br>
            <small>{{ callCurrent.campaign }}</small>
          </div>
          <div
            class="col-12 col-xl-6 col-lg-6 mb-3"
            v-for="(value, label) in callData"
            :data-test="`call-data-${label}`"
            :key="label"
          >
            <small class="text-muted">{{ $t(label) }}</small><br>
            <small>{{ value }}</small>
          </div>

          <div class="col-12">
            <div
              class="row"
              v-if="callCurrent.consults?.length"
            >
              <div
                class="col-12 col-xl-6 col-lg-6 mb-3"
                v-for="(consult, index) in callCurrent.consults"
                :key="index"
              >
                <small class="text-muted">{{ $t("consult") }} {{ index + 1 }}</small><br>
                <button
                  class="btn btn-outline-secondary btn-sm"
                  @click="openURLOnOffcanvas(callCurrent.consult_ids[index])"
                >
                  {{ $t("access_consult") }}
                </button>
              </div>
            </div>
          </div>

          <div
            class="col-12 col-xl-6 col-lg-6 mb-3"
            v-if="callCurrent.is_transferred"
          >
            <small class="text-muted">{{ $t("transfer_capitalize") }}</small><br>
            <button
              class="btn btn-outline-secondary btn-sm"
              @click="openURLOnOffcanvas(callCurrent.transfer_id)"
            >
              {{ $t("access_transfer") }}
            </button>
          </div>

          <div
            class="col-12 col-xl-6 col-lg-6 mb-3"
            v-if="callCurrent.is_consult"
          >
            <small class="text-muted">{{ $t("original_call") }}</small><br>
            <button
              class="btn btn-outline-secondary btn-sm"
              @click="openURLOnOffcanvas(callCurrent.parent_id)"
            >
              {{ $t("access_previous_call") }}
            </button>
          </div>

          <div
            class="col-12 col-xl-6 col-lg-6 mb-3"
            v-if="callCurrent.is_transfer"
          >
            <small class="text-muted">{{ $t("original_consult") }}</small><br>
            <button
              class="btn btn-outline-secondary btn-sm"
              @click="openURLOnOffcanvas(callCurrent.consult_id)"
            >
              {{ $t("access_consult") }}
            </button>
          </div>

          <div
            class="col-12 col-xl-6 col-lg-6 mb-3"
            v-if="callCurrent.is_transfer"
          >
            <small class="text-muted">{{ $t("original_consult") }}</small><br>
            <button
              class="btn btn-outline-secondary btn-sm"
              @click="openURLOnOffcanvas(callCurrent.parent_id)"
            >
              {{ $t("access_consult") }}
            </button>
          </div>

          <div class="col-12 mb-3 overflow-ellipsis">
            <small class="text-muted">{{ $t("amd_record") }}</small><br>

            <audio
              v-if="updateAudio"
              preload="none"
              controls
              class="w-100"
            >
              <source
                :src="
                  callCurrent.recording_amd
                "
                type="audio/mpeg"
              >
            </audio>
          </div>

          <div
            class="col-12 mb-3 overflow-ellipsis"
            v-if="callCurrent.consult_cancelled"
          >
            <small class="text-muted">{{ $t("after_cancel_call_record") }}</small><br>

            <audio
              preload="none"
              controls
              class="w-100"
            >
              <source
                :src="
                  callCurrent.recording_after_consult_cancel
                "
                type="audio/mpeg"
              >
            </audio>
          </div>
        </div>
        <more-details :call="callCurrent" />
      </div>
    </div>
    <div
      class="p-4"
      v-show="loadingFlags.loadCallById"
    >
      <card-loading
        :height="800"
      />
    </div>
  </div>
</template>

<script>
import { Offcanvas } from "bootstrap";
import { formatPhoneNumber } from "@/utils";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

import MoreDetails from "./more-details.vue";
import { nextTick } from "vue";
import CardLoading from "@components/card-loading.vue";

export default {
  components: {
    CardLoading,
    MoreDetails,
  },
  props: {
    call: {
      type: Object,
      default: () => null,
    },

    show: {
      type: Boolean,
      default: false,
    },
    getCallMode: {
      type: Function,
      default: () => {},
    },

  },
  data() {
    return {
      callCurrent: null,
      offCanvas: null,
      updateAudio: false,
      history: [],
    };
  },

  emits: ["close"],

  computed: {
    ...mapGetters("manager/calls-report", {
      loadedCall: "getLoadedCall",
      loadingFlags: "getLoadingFlags",
    }),
    callData() {
      return {
        detection_time_call: this.callCurrent?.amd_time,
        time_in_waiting: this.callCurrent?.waiting_time,
        conversation_time: this.callCurrent?.speaking_time,
        post_service_time: this.callCurrent?.acw_time,
        type_general: this.callCurrent?.phone_type === "mobile" ? this.$t("mobile") : this.$t("landline"),
        status_omni: this.callCurrent?.readable_status_text,
        billed_time: this.callCurrent?.billed_time,
        value: this.callCurrent?.billed_value,
        qualification: this.callCurrent?.qualification,
        post_call_detection: this.callCurrent?.readable_amd_status_text,
        ending_call_cause: this.callCurrent?.readable_hangup_cause_text,
        call_mode_capitalize: this.getCallMode(this.callCurrent?.mode),
      };
    },
  },

  mounted() {
    this.offCanvas = new Offcanvas(this.$refs.offcanvas);
    this.$refs.offcanvas.addEventListener("hide.bs.offcanvas", this.onHideOffcanvas);
  },

  beforeUnmount() {
    this.offCanvas.hide();
  },

  methods: {
    formatPhoneNumber,

    ...mapActions("manager/calls-report", ["loadCallById"]),

    formatDate(date) {
      return moment(date)
          .format("DD/MM/YYYY [às] HH:mm");
    },

    backHistory() {
      this.history.pop();
      this.callCurrent = this.history[this.history.length - 1];
    },

    openURLOnOffcanvas(id) {
      if (this.history[this.history.length - 2]?.sid?.split("-")[1] === id) return this.backHistory()
      this.loadCallById({ id: id })
    },

    onHideOffcanvas() {
      this.$emit("close");
    },
  },

  watch: {
    show(data) {
      if (data) {
        this.callCurrent = this.call
        this.history.push(this.callCurrent)
        this.offCanvas.show();
        nextTick()
            .then(() => this.updateAudio = true);
      } else {
        this.offCanvas.hide();
        this.updateAudio = false;
        this.callCurrent = null;
        this.history = [];
      }
    },
    loadedCall(data) {
      if (data && this.show) {
        this.callCurrent = data;
        this.history.push(this.callCurrent)
      }
    },
  },
};
</script>
