export default {
    startLoading: state => state.loading = true,
    stopLoading: state => state.loading = false,
    startLoadingFlag: (state, flag) => state.loadingFlags[flag] = true,
    stopLoadingFlag: (state, flag) => state.loadingFlags[flag] = false,

    setErrors: (state, { errorFlag, errors }) => state.errors[errorFlag] = errors,

    clearErrors: (state) => {
        state.errors = {
            createIvrAfterCall: {},
            editIvrAfterCall: {},
            createCriteria: {},
            editCriteria: {}
        }
    },
    setList: (state, list) => state.list = list,
    setRegister: (state, register) => state.register = register,
    removeCriteria: (state, criteriaId) => {
        state.register.criteria = state.register.criteria.filter((criteria) => {
            return criteria.id !== criteriaId 
        })
    },
    clearList: (state) => state.list = [],
    setAlert: (state, alert) => {
        state.alert = alert
    },
    setCurrentPage: (state, page) => state.pagination.current_page = page,
    setFilters: (state, filters) => {
        state.filters = filters;
    },
    setPagination: (state, pagination) => {
        state.pagination = pagination;
    },
    setInclude: (state, include) => state.include = include,
    setDataSelect: (state, dataSelect) => state.dataSelect = dataSelect,
}
