<template>
  <div class="card mb-0">
    <div class="d-flex justify-content-between p-4 align-items-center text-center flex-wrap">
      <div class="d-flex justify-content-between align-items-center text-center gap-3">
        <div class="avatar avatar-sm">
          <div class="avatar-title rounded-circle instance-avatar">
            <i
              v-if="channel == 'WhatsApp 3C+'"
              class="fa-kit fa-whatsapp fa-xl tx-blue-300"
            />
            <i
              v-else-if="channel == 'WABA'"
              class="fa-kit fa-waba fa-xl tx-blue-300"
            />
          </div>
        </div>
        <span class="">
          {{ channel }}
        </span>
      </div>
      <div class="text-center align-items-center justify-content-center d-flex gap-2">
        <dot-status
          color="#008A35"
          size="7px"
        />
        <span style="color: #008A35"> {{ $t('connected') }} </span>
      </div>
    </div>
    <div
      v-if="metricsData"
      class="col-lg-12 d-flex flex-row justify-content-between row p-4"
    >
      <div
        v-for="(itemMetrics, index) in metricsData"
        class="col-6"
        :key="index"
      >
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-auto">
                <div class="avatar avatar-sm">
                  <div
                    class="avatar-title rounded-circle"
                    :class="itemMetrics.name"
                  >
                    <span :class="itemMetrics.iconClass" />
                  </div>
                </div>
              </div>
              <div class="col">
                <h6 class="fw-normal lh-1">
                  {{ itemMetrics.title }}
                </h6>
                <span class="fw-medium text-large">
                  {{ itemMetrics.value || 0 }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import DotStatus from "@/components/dot-status";

export default {
  data() {
    return {

    }
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    channel: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    }
  },

  components: {
    DotStatus
  },

  computed: {
    ...mapGetters("manager/whatsapp", {
      metrics: "getWhatsappMetrics",
    }),

    ...mapGetters("manager/whatsapp_group_channel", {
      groupChannel: "getGroupChannel",
      instances: "getGroupChannelInstance"
    }),

    metricsData(){
      let selectedInstance = this.instances.find((el) => el.instance.id == this.item.id)
      if (!selectedInstance) return []
      return [
        {
          name: "total",
          iconClass: "fe fe-check",
          title: "Total",
          value: selectedInstance?.metrics.total || 0
        },
        {
          name: "active",
          iconClass: "fe fe-message-square",
          title: this.$t('actives_f'),
          value: selectedInstance.metrics.active
        },
        {
          name: "queue",
          iconClass: "fe fe-minus",
          title: this.$t('list_metrics_in_queue'),
          value: selectedInstance.metrics.queue
        },
        {
          name: "snooze",
          iconClass: "fal fa-snooze",
          title: this.$t('list_metrics_on_hold'),
          value: selectedInstance.metrics.snooze
        },
      ]
    }
  },
}
</script>
<style lang="scss" scoped>
.instance-avatar{
  background-color: $color-gray-blue-300;
}
.icon {
  color: $color-blue-300 !important;
}
.total {
  background-color: rgba(136, 136, 136, 0.1);
  color: #888888;
}
.active {
  background-color: rgba(92, 184, 104, 0.1);
  color: #015D0D;
}
.queue {
  background-color: rgba(48, 87, 242, 0.1);
  color: $color-blue-500;
}
.snooze {
  background-color: rgba(230, 124, 11, 0.1);
  color: $color-orange-500;
}
</style>
