<template>
  <a
    class="whatsapp-redirect position-fixed d-flex align-items-center justify-content-center bg-green-400 border-none rounded-circle"
    :href="whatsappURL"
    target="_blank"
  >
    <i class="fa-brands fa-whatsapp text-white" />
  </a>
</template>

<script>
export default {
  name: "WhatsappRedirect",
  data() {
    return {
      whatsappURL: "http://wa.me/5542999198146"
    }
  }
};
</script>

<style lang="scss" scoped>
.whatsapp-redirect {
  bottom: 32px;
  right: 32px;
  width: 60px;
  height: 60px;
  i {
    font-size: 28px;
  }
}
</style>
