import axios from "@crm/plugins/axios-config";

export default {
  createUploadCsv ({ commit }, { formData }) {
    commit("startLoading", "createUploadCsv")
    commit('setErrors', [])
    return new Promise((resolve,reject)=>{
      axios.post('/opportunity/csv/upload', formData).then((response) => {
        commit('setStep', 1)
        commit('setPreAdjustedCsv', response.data)
        resolve()
      }).catch((error) => {
        commit('setErrors', error.response.data.errors.contacts)
        commit('system/setErrorMessage', 'errors_message.invalid_file', {root: true})
        reject()
      }).finally(()=>{
        commit("stopLoading", "createUploadCsv")
      })
    })
  },

  createValidateCsv ({ commit }, { formData }) {
    commit("startLoading", "createValidateCsv")
    return new Promise((resolve,reject)=>{
      axios.post('/opportunity/csv/validate', formData).then(() => {
        commit('setStep', 2)
        commit("setHeaderCsv", formData.header)
      }).catch((error) => {
        const requiredFormInputErrorMessage = error.response.data.errors['required-form-input'] ? error.response.data.errors['required-form-input'][0] : null;
        const errorMessage = requiredFormInputErrorMessage ? { errors: requiredFormInputErrorMessage } : error.response.data

        commit('system/setErrorMessage', errorMessage, {root: true})
        reject()
      }).finally(()=>{
        commit("stopLoading", "createValidateCsv")
      })
    })
  },

  createImportCsv ({commit}, formData){
    commit("startLoading", "createImportCsv")
    return new Promise((resolve, reject) => {
      axios.post('/opportunity/csv', formData).then(() => {
        commit('setErrors', [])
        commit('system/setCustomizedTitleMessage', "processing_list", {root: true})
        commit('system/setInfoMessage',"info_message.wait", {root: true})
        setTimeout(()=>{
          commit('system/setCustomizedTitleMessage', null, {root: true})
        }, 1000)
        resolve()
      }).catch((error) => {
        commit('system/setErrorMessage', error.response.data.errors['invalid-answer'][0], {root: true})
        reject()
      }).finally(()=>{
        commit("stopLoading", "createImportCsv")
      })
    })
  },

  clearCsvList ({commit}){
    commit('clearCsvList')
    commit('setStep', 0)
  }
}
