<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label class="form-label required">
            {{ $t('period') }}
          </label>
          <input-date-range
            :max-interval="31"
            v-model:start="filters.start_date"
            v-model:end="filters.end_date"
            :time-enabled="true"
            :seconds-enabled="true"
            format-date="d/m/Y H:i:S"
            :min-date="minDate"
          />
        </div>
      </div>

      <div
        v-if="avaliableFilters.includes('group-channels')"
        class="col"
      >
        <div class="form-group">
          <label class="form-label">
            {{ $t('group_channel') }}
          </label>
          <select-whatsapp-group-channels
            @select-group-channels="value => (filters.group_channel_ids = value)"
          />
        </div>
      </div>

      <div
        v-if="avaliableFilters.includes('agents')"
        class="col"
      >
        <div class="form-group">
          <label class="form-label">
            {{ $t('agents_capitalize') }}
          </label>
          <select-whatsapp-agents @select-agent="value => (filters.agents_ids = value)" />
        </div>
      </div>

      <div
        v-if="avaliableFilters.includes('teams')"
        class="col"
      >
        <div class="form-group">
          <label class="form-label">
            {{ $t('teams_capitalize') }}
          </label>
          <multi-select
            :list-data="teamsList"
            label="name"
            return-all-object
            v-model="teams"
          />
        </div>
      </div>

      <div
        class="col"
        style="margin-top: 2rem"
      >
        <app-button
          class="w-100"
          @click="submit"
          :disabled="!filters.start_date || !filters.start_date || loading"
        >
          {{ $t('search_report') }}
        </app-button>
      </div>
    </div>

    <div
      v-if="enableEmptyDaysFilter"
      class="col-12 mb-4"
    >
      <div class="form-check form-switch">
        <input
          v-model="filters.emptyDays"
          id="flexSwitchCheckDefault"
          type="checkbox"
          class="form-check-input"
        >
        <label
          class="form-check-label"
          for="flexSwitchCheckDefault"
        >Ocultar dias vazios</label>
      </div>
    </div>

    <div
      v-if="enableAdvancedFilters"
      class="col-12"
      style="margin: 1rem 0"
    >
      <div class="form-group">
        <a
          class="text-primary cursor-pointer"
          @click="showAdvancedFilters = !showAdvancedFilters"
        >
          {{ showAdvancedFilters? '- '+$t('capitalizes.hide') : '+ '+$t('show_capitalizes') }} {{ $t('advanced_filters') }}.
        </a>
      </div>
    </div>

    <div
      v-if="enableAdvancedFilters"
      v-show="showAdvancedFilters"
      class="row"
    >
      <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="form-group">
          <label
            for="number"
            class="form-label"
          >
            {{ $t('number') }}
          </label>
          <input
            v-model="filters.number"
            id="number"
            type="text"
            class="form-control"
            v-maska
            data-maska="[
            '+## (##) ####-####',
            '+## (##) #####-####'
          ]"
            placeholder="+55 (00) 00000-0000"
          >
        </div>
      </div>

      <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="form-group">
          <label class="form-label">
            {{ $t('channel') }}
          </label>
          <vue-multiselect
            v-model="filters.instance_id"
            id="channel"
            label="name"
            track-by="id"
            :placeholder="$t('select_channel')"
            :select-label="$t('click_or_enter_to_select')"
            :deselect-label="$t('click_or_enter_to_remove')"
            :selected-label="$t('selected_capitalize')"
            :multiple="true"
            :close-on-select="false"
            :preserve-search="true"
            :options="channels"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="form-group">
          <label class="form-label">
            {{ $t('status_omni') }}
          </label>
          <select
            class="form-select"
            v-model="filters.status"
          >
            <option
              v-for="(item, index) in status"
              :key="index"
              :value="item.value"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-md-6 col-lg-4 col-xl-3">
        <label class="form-label">
          {{ $t('qualification') }}
        </label>
        <select
          class="form-select"
          v-model="filters.qualification"
          :disabled="filters.group_channel_ids === ''"
        >
          <option value="">
            {{ $t('select_qualification') }}
          </option>
          <option
            v-for="qualification in qualifications"
            :key="qualification.id"
            :value="qualification.id"
          >
            {{ qualification.name }}
          </option>
        </select>
      </div>

      <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="form-group">
          <label class="form-label">
            {{ $t('protocol') }}
          </label>
          <input
            type="text"
            class="form-control"
            v-model="filters.protocol_number"
            placeholder="12345678901234567"
          >
        </div>
      </div>

      <div class="col-md-6 col-lg-4 col-xl-3 d-flex align-items-center">
        <div class="form-check form-switch">
          <input
            type="checkbox"
            class="form-check-input"
            v-model="filters.transferred"
          >
          <label
            class="form-check-label"
          >
            {{ $t('only_transferred_chats') }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputDateRange from "@components/input-date-range.vue";
import SelectWhatsappAgents from "@dialer/manager/components/select-whatsapp-agents.vue";
import AppButton from "@components/app-button.vue";
import VueMultiselect from "vue-multiselect";
import SelectWhatsappGroupChannels from "@dialer/manager/components/select-whatsapp-group-channels.vue";
import MultiSelect from '@components/select/multi-select.vue'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: "MenuSearch",

  emits:["searched", "updateFilters", "updateEmptyDays"],

  props: {
    avaliableFilters: { type: Array, default: () => ["group-channels", "agents"] },
    enableAdvancedFilters: { type: Boolean, default: false },
    enableEmptyDaysFilter: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    minDate: {type: String, default: null},
  },

  components: {
    MultiSelect,
    SelectWhatsappGroupChannels,
    SelectWhatsappAgents,
    AppButton,
    InputDateRange,
    VueMultiselect
  },

  data() {
    return {
      teams: [],
      filters: {
        teams_ids: [],
        start_date: null,
        end_date: null,
        group_channel_ids: "",
        agents_ids: "",
        number: "",
        instance_id: [],
        status: "",
        qualification: "",
        protocol_number: null,
        transferred: false,
        emptyDays: false,
      },
      showAdvancedFilters: false,
    }
  },

  created() {
    this.fetchInstances()
    this.fetchQualifications()
  },
  mounted(){
    this.fillTeamsList()
  },

  watch: {
    'filters': {
      handler(value) {
        this.$emit('updateEmptyDays', {
          emptyDays: value.emptyDays,
          start_date: this.filters.start_date,
          end_date: this.filters.end_date
        })
      }, deep: true
    }
  },

  computed: {
    ...mapGetters("manager/teams", {
      teamsList: "getTeams"
    }),
    ...mapGetters("manager/chats_report", {
      loadingFlags: "getLoadingFlags",
    }),
    ...mapGetters("manager/whatsapp_filters", {
      channels: "getInstances",
      groupChannels: "getGroupChannelsWithQuali",
      qualificationsData: "getQualifications"
    }),

    status() {
      return [
        {
          value: "",
          name: this.$t('all_dropdown')
        },
        {
          value: "IN_PROGRESS",
          name: this.$t('in_progress')
        },
        {
          value: "IN_QUEUE",
          name: this.$t('capitalizes.queue')
        },
        {
          value: "IN_SNOOZE",
          name: this.$t('list_metrics_on_hold')
        },
        {
          value: "FINISHED",
          name: this.$t('compĺeted')
        },
        {
          value: "CHATBOT",
          name: "Chatbot"
        },
      ]
    },

    qualifications() {
      let idsArray = this.filters.group_channel_ids;
      let addedQualificationListsId = [];
      let qualifications = [];

      this.groupChannels.forEach(groupChannel => {
        if (idsArray.includes(groupChannel.id)) {
          const listId = groupChannel.team.data.qualification_list_id;

          if (!addedQualificationListsId.includes(listId)) {
            addedQualificationListsId.push(listId);
            this.qualificationsData[listId].forEach(qualification => {
              qualifications.push(qualification);
            });
          }
        }
      });

      return qualifications;
    }
  },

  methods: {
    ...mapActions("manager/teams", ["fetchTeams"]),
    ...mapActions("manager/whatsapp_filters", [
      "fetchInstances",
      "fetchQualifications"
    ]),

    submit() {
      this.$emit("searched", true);
      this.$emit("updateFilters", this.parseFilters({...this.filters}));
    },

    async fillTeamsList() {
      await this.fetchTeams();
    },

    parseFilters(filters) {
      if (this.enableAdvancedFilters) {
        filters.number = filters.number.replace(/\D/g, "");
      }

      if (filters.instance_id) {
        filters.instance_id = filters.instance_id.map(channel => channel.id).join(",");
      }
      if (this.teams) {
        filters.teams_ids = this.teams.map(team => team.id);
      }

      for (const filter in filters) {
        if (!filters[filter]) {
          delete filters[filter];
        }
      }
      return filters;
    }
  }
};
</script>
