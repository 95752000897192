<template>
  <section>
    <offcanvas-partner-details ref="partnerOffCanvas" />
    <div class="card">
      <div class="card-header">
        <h4 class="card-header-title">
          {{ $t("my_referrals") }}
        </h4>

        <span class="tx-text-gray me-3">{{ $t("active_clients") }}</span>
        <strong class="fs-2"> {{ partner.active_companies }} </strong>
      </div>
      <div class="table-responsive">
        <table class="table table-hover table-nowrap card-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>{{ $t("uppercases.name") }}</th>
              <th v-if="!hasPermissionToImpersonate">{{ $t("uppercases.value") }}</th>
              <th v-if="!hasPermissionToImpersonate">{{ $t("uppercases.payment_status") }}</th>
              <th v-if="!hasPermissionToImpersonate">{{ $t("uppercases.next_payment") }}</th>
              <th v-if="hasPermissionToImpersonate" /> <!-- Empty th for the access button -->
              <th v-if="!hasPermissionToImpersonate" /> <!-- Empty th for the chevron icon -->
            </tr>
          </thead>
          <tbody class="text-base">
            <loading-table-component
              v-if="loadingFlags.listPartners || loadingFlags.loadListPartners"
              :columns="5"
              :lines="15"
              :list-width="[200, 150, 150, 150, 50]"
            />

            <template v-else-if="companies.length">
              <tr
                v-for="(company, index) in companies"
                :key="index"
              >
                <td>
                  {{ company.company_id }}
                </td>
                <td>
                  {{ company.company_name }}
                </td>
                <td @click="openOffCanvas(company.company_id)" v-if="!hasPermissionToImpersonate">
                  {{ formatCurrency(parseFloat(company.value)) }}
                </td>
                <td @click="openOffCanvas(company.company_id)" v-if="!hasPermissionToImpersonate">
                  <badge-status
                    v-if="company.payment_status !== 'waiting'"
                    :status="company.payment_status"
                  />
                  <span
                    v-else
                    class="text-muted"
                  >-</span>
                </td>
                <td
                  :class="!company.next_payment ? 'text-muted' : 'tx-text-gray'"
                  @click="openOffCanvas(company.company_id)"
                  v-if="!hasPermissionToImpersonate"
                >
                  {{ company.next_payment ? formatDate(company.next_payment) : "-" }}
                </td>
                <td style="width: 100px;" v-if="hasPermissionToImpersonate">
                  <button
                    class="btn btn-light px-6 mx-3"
                    @click="impersonate(company.company_id)"
                  >
                    Acessar empresa
                  </button>
                </td>
                <td
                  class="text-muted"
                  @click="openOffCanvas(company.company_id)"
                  v-if="!hasPermissionToImpersonate"
                >
                  <i class="fa-regular fa-chevron-right" />
                </td>
              </tr>
            </template>

            <tr v-else>
              <td
                class="text-center"
                colspan="12"
              >
                <img
                  alt="Imagem nenhum parceiro encontrado"
                  class="mb-4"
                  src="@/assets/img/empty-box.svg"
                >
                <div>
                  <h4>{{ $t("empty_filter") }}</h4>
                  <p class="text-muted fw-medium mb-5">
                    {{ $t("refer_a_new_client") }}
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="!isLoadingPaginator && companies.length">
          <div class="col-lg-12 card-footer">
            <paginator
              v-if="!isLoadingPaginator"
              :pagination="pagination"
              @change-page="changePage"
              @show-quantity="true"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { Utils } from "@/utils";
import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";
import OffcanvasPartnerDetails from "./offcanvas-partner-details.vue";
import BadgeStatus from "@components/badge-status.vue";
import paginator from "@components/app-paginator.vue";

export default {
  name: "TablePartner",
  components: { paginator, BadgeStatus, OffcanvasPartnerDetails, LoadingTableComponent },
  methods: {
    formatCurrency: Utils.formatCurrency,
    ...mapActions("partner", [
      "listCompanyDetails",
      "setPartnerDetailsCurrentPage",
      "listPartners",
      "enterImpersonateMode"
    ]),

    ...mapActions("admin/partners", [
      "listCompanyDetailsAdmin",
      "setAdminPartnerDetailsCurrentPage",
      "listPartnersAdmin"
    ]),

    openOffCanvas(id) {
      if (Object.keys(this.detailsPartner).length === 0) {
        this.listCompanyDetailsAdmin(id).then(() => {
          this.$refs.partnerOffCanvas.new();
        });
      } else {
        this.listCompanyDetails(id).then(() => {
          this.$refs.partnerOffCanvas.new();
        });
      }
    },

    changePage(page) {
      if (this.admin) {
        const id = this.$route.params.id;
        this.setAdminPartnerDetailsCurrentPage(page);
        this.listPartnersAdmin(id);
      } else {
        this.setPartnerDetailsCurrentPage(page);
        this.listPartners(this.user.id);
      }
    },

    impersonate(id) {
      this.enterImpersonateMode({
        id: id,
      }).then(() => {
        this.$router.push("/");
      });
    },
  },
  computed: {
    ...mapGetters("partner", {
      tablePartner: "getListPartners",
      loadingFlags: "getLoadingFlags",
      detailsPartner: "getDetailsPartner",
      partnerDetailsPagination: "getPartnerDetailsPagination",
      hasPermissionToImpersonate: "hasPermissionToImpersonate",
    }),

    ...mapGetters("admin/partners", {
      adminTablePartner: "getListPartners",
      adminLoadingFlags: "getLoadingFlags",
      adminPartnerDetailsPagination: "getPartnerDetailsPagination",
      adminDetailPartner: "getDetailsPartners",
    }),

    ...mapGetters("auth", {
      admin: "isAdmin",
      user: "getUser"
    }),

    formatDate() {
      return function(date) {
        return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY");
      };
    },

    companies() {
      return this.adminTablePartner.concat(this.tablePartner);
    },

    isLoadingPaginator() {
      return this.admin ? this.adminLoadingFlags.listPartners : this.loadingFlags.loadListPartners;
    },

    pagination() {
      return this.admin ? this.adminPartnerDetailsPagination : this.partnerDetailsPagination;
    },

    partner() {
      if (Object.keys(this.detailsPartner).length) {
        return this.detailsPartner;
      }
      return this.adminDetailPartner;
    },
  }
};
</script>

<style lang="scss" scoped>
td {
  cursor: pointer;
}
</style>
