<template>
  <section class="card w-100 border rounded bg-white">
    <div
      class="card-header border-0 p-0"
      style="height: 56px;"
    >
      <search-input
        @search="search"
        :search="term"
        class="w-100 mx-4"
      />
    </div>
    <div class="card-body p-0">
      <div class="table-responsive">
        <table class="table table-hover mb-0">
          <thead>
            <tr>
              <th
                v-for="(head, idx) in tableHead"
                :key="idx"
                :class="head.customClass"
              >
                {{ head.title }}
              </th>
            </tr>
          </thead>
          <tbody class="list fs-base">
            <loading-table-component
              v-if="loadingFlags.campaignsAgents"
              :columns="4"
              :lines="10"
              :list-width="[100, 50, 150, 75]"
              :text-center="true"
            />
            <template v-else>
              <tr
                v-for="(agent, idx) in agents"
                :key="idx"
                class="tx-text-200"
                @click="showOffCanvas(agent)"
                @mouseenter="activeHover = agent.user_id"
                @mouseleave="activeHover = ''"
              >
                <td class="d-flex gap-4">
                  <div class="overflow-ellipsis d-flex align-items-center">
                    <img
                      v-if="agent.ranking_position <= 3"
                      :src="rankingPosition(agent.ranking_position)"
                      alt="third award"
                      class="me-3"
                    >
                    <p
                      v-else
                      class="mb-0 me-3 tx-text-200 fw-medium"
                      style="margin-left: 8px !important;"
                    >
                      {{ agent.ranking_position }}
                    </p>
                    <span>{{ agent.name }}</span>
                    <i
                      v-if="agent.overall_score < 3"
                      class="fa-regular fa-triangle-exclamation tx-yellow-400 ms-3"
                    />
                  </div>
                </td>
                <td class="text-center">
                  <p class="mb-0 tx-text-gray ">
                    <span
                      :class="[metricsNoteColor(agent.overall_score)]"
                      class="fw-medium  tx-yellow-400"
                    >{{
                      scoreFixed(agent.overall_score)
                    }}</span>
                    /10
                  </p>
                </td>
                <td class="pe-0 ps-5">
                  <div class="w-100">
                    <ProgressBarMultiple
                      :data="[
                        {
                          title: this.$t('good'),
                          percentage: calculatePercentage(agent.calls_quantity.good, agent.calls_quantity.total),
                          color: '#34C759',
                          count: agent.calls_quantity.good,
                          icon: 'fas fa-circle text-small'
                        },
                        {
                          title: this.$t('neutral'),
                          percentage: calculatePercentage(agent.calls_quantity.neutral, agent.calls_quantity.total),
                          color: '#FFCC00',
                          count: agent.calls_quantity.neutral,
                          icon: 'fas fa-circle text-small'
                        },
                        {
                          title: this.$t('bad'),
                          percentage: calculatePercentage(agent.calls_quantity.bad, agent.calls_quantity.total),
                          color: '#F23F2C',
                          count: agent.calls_quantity.bad,
                          icon: 'fas fa-circle text-small'
                        }
                      ]"
                      custom-width="100%"
                      style="min-width: 360px !important;"
                    />
                  </div>
                </td>
                <td class="text-center position-relative">
                  {{ agent.calls_quantity.total }}
                  <i
                    v-show="activeHover === agent.user_id"
                    class="far fa-chevron-right position-absolute me-4"
                  />
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div
        v-if="!agents.length && !loadingFlags.campaignsAgents"
        class="text-center my-4"
      >
        <img
          src="@/assets/img/search-pluszinha.svg"
          alt="pluzinha busca"
        >
        <p class="mb-0 tx-text-200">
          {{ $t('no_agent_yet') }}
        </p>
      </div>
    </div>
    <div
      class="card-footer"
      v-if="pagination.total_pages > 1 && !loadingFlags.campaignsAgents"
    >
      <app-paginator
        v-if="!loadingFlags.campaignsAgents"
        :pagination="pagination"
        @change-page="changePage"
      />
    </div>
  </section>
  <OffCanvasIA
    ref="offCanvasIa"
    :agent="metricsAgent"
    :calls="calls"
  />
</template>

<script>
import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";
import ProgressBarMultiple from "@components/progressbar-multiple-lg.vue";
import OffCanvasIA from "@dialer/manager/pages/insights-ai/components/off-canvas-ia.vue";
import { mapActions, mapGetters } from "vuex";
import AppPaginator from "@components/app-paginator.vue";
import SearchInput from "@components/search-input.vue";
import _ from "lodash";

export default {
  name: "TableCampaignsIA",
  components: {
    SearchInput,
    AppPaginator,
    OffCanvasIA,
    ProgressBarMultiple,
    LoadingTableComponent
  },
  props: {
    tableHead: {
      type: Array,
      default: () => []
    },
    customFunction: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      term: "",
      show: false,
      metricsAgent: {},
      imageTable: [
        { id: 1, image: require("@/assets/img/first-award.svg") },
        { id: 2, image: require("@/assets/img/second-award.svg") },
        { id: 3, image: require("@/assets/img/third-award.svg") }
      ],
      activeHover: ""
    };
  },
  mounted() {
    this.setCampaignAgentsCurrentPage(1);
    this.campaignsAgents(this.$route.params.id);
  },
  unmounted() {
    this.setCampaignAgentsFilters({
      search: ""
    });
  },
  computed: {
    ...mapGetters("manager/insights", {
      calls: "getAgentsCalls",
      pagination: "getCampaignsAgentsPagination",
      loadingFlags: "getLoadingFlags",
      agents: "getCampaignsAgentsIA"
    })
  },
  methods: {
    ...mapActions("manager/insights", [
      "loadAgentsCalls",
      "campaignsAgents",
      "setCampaignAgentsCurrentPage",
      "setCampaignAgentsFilters",
      "setAgentsCallsCurrentPage"
    ]),
    showOffCanvas(agent) {
      this.metricsAgent = agent;
      this.setAgentsCallsCurrentPage(1);
      this.loadAgentsCalls({
        campaignId: this.$route.params.id,
        agentId: agent.user_id
      });
      this.$nextTick(() => {
        this.$refs.offCanvasIa.closeModal();
      });
    },
    scoreFixed(value) {
      return value.toFixed(1);
    },
    calculatePercentage(value, total) {
      return (value / total) * 100;
    },
    metricsNoteColor(value) {
      if (value >= 6) {
        return "tx-green-400";
      } else if (value >= 4 && value < 6) {
        return "tx-yellow-400";
      }
      return "tx-red-400";
    },
    rankingPosition(ranking) {
      const imgAward = this.imageTable.filter(img => img.id === ranking);
      return imgAward[0].image;
    },
    changePage(page) {
      const id = this.$route.params.id;
      this.setCampaignAgentsCurrentPage(page);
      this.campaignsAgents(id);
    },
    search: _.debounce(function(term) {
      this.term = term;
      this.setCampaignAgentsFilters({
        search: term
      });
      this.campaignsAgents(this.$route.params.id);
    }, 700)
  }
};
</script>

<style lang="scss" scoped>
.table td {
  padding: 0 16px !important;
  line-height: 40px;
}

.fa-chevron-right {
  right: 0;
  top: 13px;
}
</style>
