<template>
  <action-input
    v-model="emailField"
    @update:model-value="updateField()"
    enable-delete-action
    @delete-action="destroyField()"
    input-type="text"
    :errors="isValidEmail(emailField)? [] : [true]"
  />
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import ActionInput from "@crm/pages/opportunity/components/action-input.vue";

export default {
  components: {ActionInput},
  props: {
    email: {
      type: Object,
      required: true
    },
    contactId: {
      type: Number,
      default: null
    },
  },

  data() {
    return {
      emailField: "",
      confirmedEmail: false,
    }
  },

  created() {
    this.emailField = this.email.email
  },

  computed:{
    ...mapGetters( "crm/contacts", {
      errors:"getErrors"
    })
  },

  emits: ["error"],

  methods: {
    ...mapActions('crm/contacts', ['updateEmailChannel', 'destroyEmailChannel']),

    updateField() {
      if(!this.isValidEmail(this.emailField)){
        return;
      }
      if (!this.emailField) {
        this.emailField = this.email.email
        return
      }

      this.updateEmailChannel({
        channel_id: this.email.id,
        contact_id: this.contactId,
        email: this.emailField,
      }).then(()=>{
        this.confirmedEmail = true;
        this.$emit("error", false)
      })
    },

    destroyField() {
      this.destroyEmailChannel({
        channel_id: this.email.id,
        contact_id: this.contactId
      });
    },
    isValidEmail(email) {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if(!emailRegex.test(email)){
        this.$emit("error", true)
      }
      return emailRegex.test(email);
    },
  }
};
</script>
