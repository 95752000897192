<template>
  <progress-chart
    v-if="agentsReportList && !loadingFlags.fetchAgentsReportList"
    :title="$t('qualifications_by_agent')"
    :labels="agentsLabels"
    :step-size="50"
    :datasets="qualificationDatasets"
  />
  <div
    v-if="!agentsReportList && !agentsReportList.length"
    class="card"
  >
    <table-empty />
  </div>
</template>

<script>
import ProgressChart from "@dialer/manager/components/progress-chart.vue";
import {mapGetters} from "vuex";
import TableEmpty from "../../components/table-empty.vue";

export default {
  name: "ChartQualifications",
  components: {
    TableEmpty,
    ProgressChart
  },
  data() {
    return {
      agentsLabels: [],
      qualificationDatasets: []
    };
  },
  computed: {
    ...mapGetters("manager/chats_agents_report", {
      loadingFlags: "getLoadingFlags",
      agentsReportList: "getAgentsReportList"
    })
  },
  methods: {
    handleQualificationSubmit() {
      this.clearQualificationData();
      this.prepareQualificationDataset();
    },
    clearQualificationData() {
      this.agentsLabels = [];
      this.qualificationDatasets = [];
    },
    prepareQualificationDataset() {
      this.agentsReportList.forEach(agent => {
        this.agentsLabels.push(agent.agent_name);
        if (agent.qualifications.data) {
          agent.qualifications.data.forEach((qualification) => {
            const existingDataset = this.qualificationDatasets.find(q => q.label === qualification.name);
            if (!existingDataset) {
              this.qualificationDatasets.push({
                label: qualification.name,
                color: qualification.color,
                backgroundColor: qualification.color,
                data: new Array(this.agentsReportList.length).fill(0),
                borderRadius: 0
              })
            }
          })
        }
      })

      this.qualificationDatasets.forEach((dataset) => {
        this.agentsReportList.forEach((agent, index) => {
          if (agent.qualifications.data.length > 0) {
            const qualification = agent.qualifications.data.find(q => q.name === dataset.label);
            if (qualification) {
              dataset.data[index] = qualification.amount;
            }
          }
        })
      })
    }
  },
  watch: {
    agentsReportList(data) {
      if (data) {
        this.handleQualificationSubmit();
      }
    }
  }
};
</script>
