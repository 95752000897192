export default {

  startLoading: (state, flag) => state.loadingFlags[flag] = true,
  stopLoading: (state, flag) => state.loadingFlags[flag] = false,
  
  setErrors: (state, errors) => { state.errors = errors; },
  clearErrors: (state) => {
    state.errors = {}
    state.errorMessage = ""
  },

  setActiveUsers: (state, activeUsers) => state.activeUsers = activeUsers,
  setTotalActiveUsers: (state, totalActiveUsers) => state.totalActiveUsers = totalActiveUsers,
  setActiveUsersPagination: (state, activeUsersPagination) => state.activeUsersPagination = activeUsersPagination,
  setActiveUsersPage: (state, page) => state.activeUsersPagination.current_page = page,
  setActiveUsersPerPage: (state, number) => state.activeUsersPagination.per_page = number,
  setActiveUsersFilters: (state, filters) => state.activeUsersFilters = filters,
  
  setInactiveUsers: (state, inactiveUsers) => state.inactiveUsers = inactiveUsers,
  setTotalInactiveUsers: (state, totalInactiveUsers) => state.totalInactiveUsers = totalInactiveUsers,
  setInactiveUsersPagination: (state, inactiveUsersPagination) => state.inactiveUsersPagination = inactiveUsersPagination,
  setInactiveUsersPage: (state, page) => state.inactiveUsersPagination.current_page = page,
  setInactiveUsersPerPage: (state, number) => state.inactiveUsersPagination.per_page = number,
  setInactiveUsersFilters: (state, filters) => state.inactiveUsersFilters = filters,




}