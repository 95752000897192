import axios from "axios";

export default {
  getList({ commit, getters }) {

    commit("startLoadingFlags", "getList")
    commit("setList", []);

    return new Promise((resolve, reject) => {
      axios.get("/receptive_ivr", { params: getters.getParams }).then((response) => {
        commit("setPagination", response.data.meta.pagination);
        resolve();
        commit("setList", response.data.data);
      }).catch((response) => {
        reject({ message: response.response.data.detail });
      }).finally(() => {
        commit("stopLoadingFlags", "getList")
      });
    });
  },
  create({ commit }, payload) {
    const headers = { "Content-Type": "multipart/form-data" };
    commit("startLoadingFlags", "create")
    commit("clearErrors")

    return new Promise((resolve, reject) => {
      axios.post("/receptive_ivr", payload.formData, { headers })
        .then((response) => {
          commit("system/setSuccessMessage", response.data.detail, { root: true });
          resolve();
        })
        .catch((response) => {
          commit("system/setErrorMessage", response.response.data.detail, { root: true });
          response.response.status == 422 && response.response.data.errors && commit("setErrors", response.response.data.errors);

          reject();
        }).finally(() => {
        commit("stopLoadingFlags", "create")
      });
    });
  },

  findById({ commit }, payload) {
    commit("startLoadingFlags", "findById")
    commit("setRegister", null)
    return new Promise((resolve, reject) => {
      axios.get("/receptive_ivr/" + payload.id + "?include=keys").then((response) => {
        commit("setRegister", response.data.data);
        resolve();
      }).catch((response) => {
        reject({ message: response.response.data.detail });
      }).finally(() => {
        commit("stopLoadingFlags", "findById")
      });
    });

  },

  update({ commit }, payload) {
    const headers = { "Content-Type": "multipart/form-data" };
    commit("startLoadingFlags", "update")
    commit("clearErrors")
    return new Promise((resolve, reject) => {
      axios.post("/receptive_ivr/" + payload.id, payload.formData, { headers })
        .then(() => {
          commit("system/setSuccessMessage", "success_message.update_receptive_ivr", { root: true });
          resolve();
        }).catch((response) => {
        response.response.status == 422 && response.response.data.errors && commit("setErrors", response.response.data.errors);
        commit("system/setErrorMessage", response.response.data.detail, { root: true });
        reject();

      }).finally(() => {
        commit("stopLoadingFlags", "update")
      });
    });
  },

  remove({ commit }, payload) {
    commit("startLoadingFlags", "update")
    return new Promise((resolve, reject) => {
      axios.delete("/receptive_ivr/" + payload.id).then(() => {
        commit("system/setSuccessMessage", "success_message.delete_receptive_ivr", { root: true });
        resolve();
      }).catch((response) => {
        commit("system/setErrorMessage", response.response.data.detail, { root: true });
        reject();
      }).finally(() => {
        commit("stopLoadingFlags", "update")
      });
    });
  },
}
