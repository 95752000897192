export default {
  startLoading: (state, flag) => state.loadingFlags[flag] = true,
  stopLoading: (state, flag) => state.loadingFlags[flag] = false,
  setDateRange: (state, dateRange) => state.dateRange = dateRange,
  setDateRangeTranslations: (state, dateRangeTranslations) => {
    state.dateRange.label = dateRangeTranslations.label;
    state.dateRange.currentLabel = dateRangeTranslations.currentLabel;
  },
  setDateRangeUnitType: (state, unitType) => state.dateRange.unitType = unitType,
  setActiveAgent: (state, activeAgent) => {
    state.activeAgent.length = 0;
    if (activeAgent) {
      state.activeAgent.push(activeAgent);
    }
  },
}
