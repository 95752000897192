<template>
  <div
    v-if="isLoading"
    class="card"
  >
    <div class="card-body">
      <card-loading
        :height="275"
        margin-bottom="0"
      />
    </div>
  </div>

  <div
    v-else
    class="card"
    id="activity-timeline"
  >
    <div class="card-header">
      <div class="d-flex align-items-center text-muted">
        <i class="fa-regular fa-magnifying-glass" />
        <input
          type="text"
          :placeholder="$t('search_in_timeline')"
          class="ms-3 form-control form-control-flush"
          v-model="search"
          @keyup="handleSearch"
        >
      </div>
    </div>
    <div class="card-body p-0">
      <div
        class="d-flex flex-column align-items-center "
        v-if="!$route.params.opportunityId"
      >
        <img
          src="@assets/img/empty-box.svg"
          class="mb-3"
        >
        <p class="text-medium">
          {{ $t('nothing_to_show') }}
        </p>
      </div>
      <div v-else>
        <div class="ps-5 ms-4">
          <ul class="nav nav-tabs ps-3 pb-3">
            <li
              class="nav-item"
              v-for="tab in tabs"
              :key="tab.name"
            >
              <button
                ref="tab-activities"
                id="tab-activities"
                type="button"
                role="tab"
                data-bs-toggle="tab"
                :aria-controls="tab.name"
                :data-bs-target="'#' + tab.name"
                :data-test="'tab-' + tab.name"
                class="nav-link"
                :class="tab.name === 'tab-all' ? 'active' : ''"
                @click="currentTabName = tab.name"
              >
                {{ tab.label }}
              </button>
            </li>
          </ul>
        </div>
        <div>
          <div
            class="tab-content mt-3 mb-5"
          >
            <div
              class="tab-pane fade show active"
              :id="currentTabName"
              role="tabpanel"
            >
              <list-group-activity
                :items="logs"
                :loading="loading.fetchOpportunityLogs"
                :items-count-to-loading="2"
              />
              <div
                class="d-flex flex-column align-items-center mt-5"
                v-if="logs.length === 0"
              >
                <img
                  src="@assets/img/empty-state/notes.svg"
                  class="mb-3"
                >
                {{ $t('tabsMessage', {
                  label: tabs.find((tab) => tab.name === currentTabName).label.toLowerCase(),
                  status: currentTabName === 'note-tab' ? '' : $t('concluded_lowercase')
                }) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import _ from "lodash";
import ListGroupActivity from "@crm/components/activity/list-group-activity/list-group-activity.vue";
import CardLoading from "@components/card-loading.vue";
export default {
  name: "ActivityTimeline",

  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  components: {
    CardLoading,
    ListGroupActivity
  },

  data () {
    return {
      currentTabName: 'tab-all',
      currentTab: null,
      tabs : [
        {
          name: 'tab-all',
          label: this.$t('everything')
        },
        {
          name: 'note-tab',
          label: this.$t('internal_notes')
        },
        {
          name: 'task-tab',
          label: this.$t('tasks')
        },
        {
          name: 'call-tab',
          label: this.$t('calls_capitalizes')
        },
        {
          name: 'message-tab',
          label: this.$t('messages')
        },
        {
          name: 'email-tab',
          label: 'E-mails'
        },
        {
          name: 'meeting-tab',
          label: this.$t('meetings')
        },
        {
          name: 'action-tab',
          label: this.$t('actions')
        }
      ],
      search: ''
    }
  },

  computed: {
    ...mapGetters('auth', {
      'user': 'getUser'
    }),

    ...mapGetters('crm/opportunities', {
      opportunityLogs: 'getOpportunityLogs',
      opportunity: 'getOpportunity',
      loading: 'getLoadingFlags'
    }),

    logs() {
      return this.loading.fetchOpportunityLogs ? 15 : this.opportunityLogs
    }
  },

  methods: {
    ...mapActions('crm/opportunities', [
      'fetchOpportunityLogs',
      'updateCurrentTabTimeline'
    ]),

    formatTabName(tabName) {
      if (tabName === 'action-tab') {
        return 'ACTION';
      }

      if (tabName === 'tab-all') {
        return null;
      }

      return ('ACTIVITY_' + tabName.replace('-tab', '')).toUpperCase();
    },

    handleSearch: _.debounce(function () {
      this.fetchOpportunityLogs({
        opportunityId: this.opportunity.id,
        payload: {
          search: this.search.length ? this.search : null,
          type: this.formatTabName(this.currentTabName)
        }
      })
    }, 300),
  },

  watch: {
    currentTabName(newVal, oldVal) {
      if (newVal !== oldVal) {
        const formattedNewVal = this.formatTabName(newVal);
        this.updateCurrentTabTimeline(formattedNewVal);
        this.fetchOpportunityLogs({
          opportunityId: this.opportunity.id,
          payload: {
            contacts_ids: newVal === "action-tab" || newVal === "tab-all" ? this.opportunity.contacts.map(contact => contact.id) : undefined,
            type: formattedNewVal
          }
        })
      }
    },

    opportunity(item) {
      if(item){
        this.fetchOpportunityLogs({
          opportunityId: item.id,
          payload: {
            contacts_ids: item?.contacts?.map(contact => contact.id)
          }
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">
.nav-tabs {
  border-bottom: none;
}
</style>
