export default {

  getLoadingFlags: state => state.loadingFlags,

  getActiveUsers: state => state.activeUsers,
  getActiveUsersPagination: (state) => state.activeUsersPagination,
  getTotalActiveUsers: (state) => state.totalActiveUsers,

  getInactiveUsers: state => state.inactiveUsers,
  getInactiveUsersPagination: (state) => state.inactiveUsersPagination,
  getTotalInactiveUsers: (state) => state.totalInactiveUsers,
  
  getRegister: (state) => state.register,
  getErrors: state => state.errors,


  getActiveUsersParams: (state) =>
    Object.assign(
      {
        'page': state.activeUsersPagination.current_page,
        'per_page': state.activeUsersPagination.per_page
      }
      , state.activeUsersFilters, { include: state.includes.join(',') }),

  getInactiveUsersParams: (state) =>
    Object.assign(
      {
        'page': state.inactiveUsersPagination.current_page,
        'per_page': state.inactiveUsersPagination.per_page
      }
      , state.inactiveUsersFilters, { include: state.includes.join(',') })
}