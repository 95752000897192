

<script>
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

export default {
  props: {
    steps: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      formatedSteps: [],
      driverObj: null
    };
  },
  mounted() {
    this.formatedSteps = this.steps;
    this.formatedSteps.forEach((step, index) => {
      this.addDescription(step, index);
    });
    this.driverObj = driver({
      popoverClass: 'driverjs-theme',
      nextBtnText: this.$t("next_pg"),
      prevBtnText: this.$t("prev_pg"),
      doneBtnText: this.$t("end"),
      showProgress: false,
      steps: this.formatedSteps
    });
    this.driverObj.drive();
  },
  beforeUnmount() {
    if (this.driverObj) {
      this.driverObj.destroy()
    }
  },
  methods: {
    addDescription(step, index) {
      let description = step.popover.description;
      let htmlSteps = this.stepsContent(index);
      this.formatedSteps[index].popover.description = `
        <div style="display: flex; flex-direction: column">
          <div class="driver-popover-steps-wrapper">${htmlSteps}</div>
          <div style="margin-top:24px">${description}</div>
        </div>
      `;
    },
    stepsContent(index) {
      return this.steps.map((item, indexSteps) => `
        <div key="${indexSteps}" >
          ${indexSteps === index
          ? '<div class="driver-popover-current-step"></div>'
          : '<div class="driver-popover-step"></div>'
      }
        </div>
      `).join('');
    }
  }
};
</script>


<style>
.driver-popover-steps-wrapper{
  display: flex;
  gap: 8px;
}
.driver-popover-current-step {
  width: 16px;
  height: 6px;
  background-color: #FFFFFF;
  border-radius: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.driver-popover-step {
  width: 6px;
  height: 6px;
  background-color: #888888;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.driver-popover.driverjs-theme {
  background-color: #2F2F34;
  color: #FFFFFF;
  font-size: 12px;
}

.driver-popover.driverjs-theme .driver-popover-title,
.driver-popover.driverjs-theme .driver-popover-description,
.driver-popover.driverjs-theme .driver-popover-progress-text {
  color: #FFFFFF;
}

.driver-popover.driverjs-theme button {
  color: #ffffff;
}
.driver-popover.driverjs-theme .driver-popover-navigation-btns button {
  text-align: center;
  height: 32px;
  justify-content: center;
  align-items: center;
  background-color: #2F2F34;
  border-radius: 8px;
  border: 1px solid  #FFF;
  text-shadow: none;
  font-size: 14px;
  width: 77px;
}
.driver-popover.driverjs-theme .driver-popover-navigation-btns {
  align-items: flex-end;
}
.driver-popover.driverjs-theme .driver-popover-description{

  margin-bottom: 24px;
}


.driver-popover.driverjs-theme .driver-popover-navigation-btns {
  justify-content: space-between;
  gap: 3px;
}
.driver-popover.driverjs-theme .driver-popover-arrow-side-left.driver-popover-arrow {
  border-left-color: #2F2F34;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-right.driver-popover-arrow {
  border-right-color: #2F2F34;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-top.driver-popover-arrow {
  border-top-color: #2F2F34;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-bottom.driver-popover-arrow {
  border-bottom-color: #2F2F34;
}

</style>

