<template>
  <div>
    <table class="table table-nowrap table-hover app-datatable m-0">
      <thead>
        <tr>
          <th class="ps-4">
            ID
          </th>
          <th
            class="w-25"
          >
            EMPRESAS
          </th>
          <th class="w-25">
            DATA
          </th>
          <th class="w-25">
            VALOR SOLICITADO
          </th>
          <th>
            STATUS
          </th>
          <th class="pe-auto" />
        </tr>
      </thead>
      <tbody>
        <LoadingTable
          v-if="loadingFlags.loadWithdrawalFinalizedPartners"
          :columns="5"
          :lines="15"
          :list-width="[80, 300, 150, 80, 80]"
        />
        <tr v-else-if="!finalized.length">
          <td
            class="text-center"
            colspan="12"
          >
            <img
              alt="Imagem nenhum parceiro encontrado"
              class="mb-4"
              src="@/assets/img/empty-box.svg"
            >
            <div
              v-if="partnersParams.search !== finalized &&
                partnersParams.search != ''"
            >
              <h4>Nenhum solicitação encontrada</h4>
              <p class="text-muted fw-medium mb-5">
                Tente uma nova pesquisa
              </p>
            </div>

            <div v-else-if="partnersParams.search !== finalized">
              <h4>Nenhum solicitação encontrada</h4>
              <p class="text-muted fw-medium mb-5">
                Tente uma nova pesquisa
              </p>
            </div>
          </td>
        </tr>
        <template v-else>
          <tr
            v-for="(partner,index) in finalized"
            :key="partner.id"
            class="cursor-pointer"
            @click="openOffCanvas"
          >
            <td
              class="ps-4 tx-text-gray"
              @click="getPartnerByIndex(index)"
            >
              <span>{{ partner.id }}</span>
            </td>
            <td
              class="tx-text-200"
              @click="getPartnerByIndex(index)"
            >
              <span>{{ partner.requested_by.name }}</span>
            </td>

            <td
              class="tx-text-gray"
              @click="getPartnerByIndex(index)"
            >
              {{ formatDate(partner.created_at) }}
            </td>
            <td
              class="tx-text-200"
              @click="getPartnerByIndex(index)"
            >
              {{ formatCurrency(parseFloat(partner.value)) }}
            </td>
            <td @click="getPartnerByIndex(index)">
              <span :class="['badge-status fs-5',statusPartner(partner.status)]">
                {{ partner.status }}
              </span>
            </td>

            <td @click="getPartnerByIndex(index)">
              <i class="fa-regular fa-chevron-right text-muted" />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>

  <div v-if="!loadingFlags.loadWithdrawalFinalizedPartners && this.finalized.length">
    <div class="col-lg-12 card-footer">
      <paginator
        v-if="!loadingFlags.loadWithdrawalFinalizedPartners"
        :pagination="pagination"
        :recently-quantity="pagination.per_page"
        :show-quantity="true"
        @change-page="changePage"
        @change-quantity="changeQuantityOfPages"
      />
    </div>
  </div>
  <offcanvas-partner-withdraw
    ref="withdrawOffCanvas"
    :dynamic-request-slots="requestSlots"
    :dynamic-slots="modalSlots"
    title="Detalhes da solicitação"
  />
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import paginator from "@components/app-paginator.vue";
import LoadingTable from "@dialer/manager/components/loading-table-component.vue";
import {Utils} from "@/utils";
import moment from "moment/moment";
import OffcanvasPartnerWithdraw from "@admin/pages/3c-partners/partners/components/offcanvas-partner-withdraw.vue";

export default {
  name: "TablePartnersFinanceFinalized",
  data() {
    return {
      statusRequest: [
        {
          status: 'success'
        },
        {
          status: 'defaulting'
        },
      ],
      indexPartner: "",
      activeColorApproved: false,
      activeColorRecused: false
    }
  },
  props: {
    searchValue: {
      type: String,
      default: ""
    }
  },

  mounted() {
    this.loadWithdrawalFinalizedPartners();
  },

  computed: {
    ...mapGetters("admin/partners", {
      finalized: "getWithdrawalFinalizedPartners",
      loadingFlags: "getLoadingFlags",
      pagination: "getWithdrawalFinalizedPartnersPagination",
      partnersParams: "getWithdrawalFinalizedPartnersParams",
      finalizedWithdrawPartners: "getTotalFinalizedWithdrawPartner"
    }),
    modalSlots() {
      const partner = this.indexPartner;
      const currency = this.formatCurrency(parseFloat(partner.value))
      const approveName = partner.finished_by?.name

      return [
        {name: "text", content: "Empresa", className: "fw-medium fs-5 mb-5"},
        {name: "text", content: "Nome", className: "fw-medium fs-6 tx-text-gray"},
        {name: "subtitle", content: partner.requested_by?.name, className: "pt-1 pb-3 tx-text-200"},
        {name: "text", content: "ID", className: "fw-medium fs-6 tx-text-gray"},
        {name: "subtitle", content: partner.id, className: "pt-1 pb-3 tx-text-200"},
        {name: "text", content: "PIX para depósito", className: "fw-medium fs-6 tx-text-gray"},
        {name: "subtitle", content: partner.pix, className: "pt-1 pb-3 tx-text-200"},
        {name: "text", content: "Valor", className: "fw-medium fs-6 tx-text-gray"},
        {name: "subtitle", content: currency, className: "pt-1 pb-4 tx-text-200"},
        {name: "text", content: "Solicitação", className: "fw-medium fs-6 mb-4"},
        {name: "text", content: "Status", className: "fw-medium fs-6 tx-text-gray "},
        {
          name: "subtitle",
          content: partner.status,
          className: [
            'badge-status fs-5 text-center w mb-3 mt-1',
            partner.status === "Aprovado" ? 'success' : 'defaulting'
          ]
        },
        {name: "text", content: "Avaliado por", className: "fw-medium fs-6 tx-text-gray"},
        {name: "subtitle", content: approveName, className: "pt-1 pb-3 tx-text-200"},
      ];
    },
    requestSlots() {
      const partner = this.indexPartner;
      const initialDate = this.formatDate(partner.created_at);
      const finalDate = this.formatDate(partner.finished_at);

      const startRequest = [
        {name: "text", content: "Início da solicitação", className: "fw-medium fs-6 tx-text-gray"},
        {name: "subtitle", content: initialDate, className: "pt-1 pb-3 tx-text-200"}
      ];

      const endRequest = [
        {name: "text", content: "Fim da solicitação", className: "fw-medium fs-6 tx-text-gray"},
        {name: "subtitle", content: finalDate, className: "pt-1 pb-3 tx-text-200"}
      ];

      return {startRequest, endRequest}
    },
  },

  components: {
    OffcanvasPartnerWithdraw,
    paginator,
    LoadingTable,
  },

  methods: {
    ...mapActions("admin/partners", [
      "loadWithdrawalFinalizedPartners",
      "setWithdrawalFinalizedPartnersCurrentPage",
      "setWithdrawalFinalizedPartnersFilters"
    ]),

    ...mapMutations("admin/partners", ["setWithdrawalFinalizedPartnersPerPage"]),

    changePage(page) {
      this.setWithdrawalFinalizedPartnersCurrentPage(page);
      this.loadWithdrawalFinalizedPartners();
    },
    formatCurrency: Utils.formatCurrency,
    formatDate(date) {
      return moment(date).format("DD/MM [às] HH:mm");
    },
    statusPartner(status) {
      switch (status) {
        case 'Aprovado':
          return this.statusRequest[0].status;
        case 'Recusado':
          return this.statusRequest[1].status;
        default:
          return "";
      }
    },
    getPartnerByIndex(index) {
      const partner = this.finalized[index]
      return this.indexPartner = partner;
    },
    openOffCanvas() {
      this.$refs.withdrawOffCanvas.new();
    },
    changeQuantityOfPages(quantity) {
      if (quantity == "all") {
        this.setWithdrawalFinalizedPartnersPerPage(this.finalizedWithdrawPartners);
        this.loadWithdrawalFinalizedPartners();
      } else {
        this.setWithdrawalFinalizedPartnersPerPage(quantity);
        this.loadWithdrawalFinalizedPartners();
      }
    },
  },

  watch: {
    searchValue(search) {
      this.setWithdrawalFinalizedPartnersFilters(search);
      this.setWithdrawalFinalizedPartnersCurrentPage(1);
      this.loadWithdrawalFinalizedPartners();
    }
  },
};
</script>

<style
  lang="scss"
  scoped
>
.badge-status {
  border-radius: 8px;
  padding: 2px 16px;
  width: auto;
}

.success {
  background-color: #D6E9D9;
  color: #015D0D;
}

.defaulting {
  background-color: #F2C6D1;
  color: #920000;
}
</style>
