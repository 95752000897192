import axios from "axios";

export default {
  findSchedules({ commit, getters }) {
    commit("startLoadingFlag", "findSchedules");
    commit("clearErrors");
    return new Promise((resolve, reject) => {
      axios.get("/agent/schedules", { params: getters.getParams }).
        then((response) => {
          commit("addSchedules", response.data.data);
          commit("setPagination", response.data.meta.pagination);
          resolve();
        })
        .catch((response) => {
          commit("system/setErrorMessage", response.response.data, { root: true })
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "findSchedules")
        });
    });
  },

  remove({ commit, dispatch }, payload) {
    commit("startLoadingFlag", "remove");
    commit("clearErrors");
    commit("setLoadingContext", payload.id);
    return new Promise((resolve, reject) => {
      axios.delete("/schedules/" + payload.id).
        then(() => {
          dispatch("clearSchedules");
          dispatch("findSchedules");
          commit("system/setSuccessMessage", "success_message.delete_schedule", { root: true });
          resolve();
        })
        .catch((response) => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "remove");
          commit("setLoadingContext", null);
        });
    });
  },

  update({ commit, dispatch }, payload) {
    commit("startLoadingFlag", "update");
    commit("clearErrors");
    commit("setLoadingContext", payload.id)
    return new Promise((resolve, reject) => {
      axios.put("/schedules/" + payload.id, payload.fields).
        then(() => {
          dispatch("clearSchedules");
          dispatch("findSchedules");
          commit("system/setSuccessMessage", "success_message.update_schedule", { root: true });
          resolve();
        })
        .catch((response) => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          response.response.status == 422 && response.response.data.errors && commit("setErrors", response.response.data.errors);
          reject();
        }).finally(() => {
          commit("setLoadingContext", null);
          commit("stopLoadingFlag", "update");
        });
    });
  },

  showScheduleToUpdate({ commit }, data) {
    commit("setScheduleToUpdateModalData", data)
  },

  hideScheduleToUpdate({ commit }) {
    commit('clearErrors')
    commit("setScheduleToUpdateModalData", null)
  },

  setCurrentPage({ commit }, page) {
    commit("setCurrentPage", page);
  },

  clearSchedules({ commit }) {
    commit("clearSchedules");
  },

  setFilters({ commit }, filters) {
    commit("setFilters", filters);
  }
}
