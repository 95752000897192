<template>
  <div class="col-12 col-xl-4">
    <card-loading
      v-if="loading"
      :height="233"
    />

    <empty-card
      v-else-if="currentState === 'empty'"
      :title="title"
      :icon="icon"
      :empty-text="emptyText"
      :modal-title="modalTitle"
      :modal-description="modalDescription"
      :benefits-list="benefitsList"
      :benefits-link="benefitsLink"
      :images="images"
    />

    <config-card
      v-else-if="currentState === 'config'"
      :title="title"
      :icon="icon"
      :redirect-link="redirectLink"
    />

    <default-card
      v-else-if="currentState === 'default'"
      :title="title"
      :icon="icon"
      :redirect-link="redirectLink"
      :data="data"
    />
  </div>
</template>

<script>
import EmptyCard from "./empty-card.vue";
import DefaultCard from "./default-card.vue";
import ConfigCard from "./config-card.vue";
import CardLoading from "@components/card-loading.vue";

export default {
  name: "DialFeatureCard",
  props: {
    images: {
      type: Object,
      required: true
    },

    emptyText: {
      type: String,
      required: true
    },

    benefitsList: {
      type: Array,
      required: true
    },

    benefitsLink: {
      type: String,
      required: true
    },

    title: {
      type: String,
      required: true
    },

    modalTitle: {
      type: String,
      required: true
    },

    modalDescription: {
      type: String,
      required: true
    },

    icon: {
      type: String,
      required: true
    },

    redirectLink: {
      type: String,
      required: true
    },

    /**
     * The current state of the component.
     * Must be one of 'empty', 'config', or 'default'.
     */
    currentState: {
      type: String,
      required: true
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    },

    data: {
      type: Array,
      required: true,
      validator(value) {
        return value.every(item => {
          const hasId = Object.prototype.hasOwnProperty.call(item, "id") && typeof item.id === "number";
          const hasName = Object.prototype.hasOwnProperty.call(item, "name") && typeof item.name === "string";
          const hasCallsToday = Object.prototype.hasOwnProperty.call(item, "calls_today") && typeof item.calls_today === "number";
          const hasAgentsWithOnlineStatus = Object.prototype.hasOwnProperty.call(item, "agents_with_online_status") && typeof item.agents_with_online_status === "number";
          const correctLength = Object.keys(item).length === 4;

          return hasId && hasName && hasCallsToday && hasAgentsWithOnlineStatus && correctLength;
        });
      }
    }
  },

  components: {
    EmptyCard,
    DefaultCard,
    ConfigCard,
    CardLoading
  }
};
</script>
