export default {
  path: "companies",
  components: {
    main: require("@admin/pages/companies/index-companies.vue").default
  },
  children: [
    {
      path: "",
      name: "companies",
      components: {
        companies: require("@admin/pages/admin-dashboard.vue").default
      }
    },
    {
      path: "new",
      name: "new_companies",
      components: {
        companies: require("@admin/pages/companies/new-company/new-company-index.vue").default
      }
    },
    {
      path: ":id/edit",
      name: "edit_companies",
      components: {
        companies: require("@admin/pages/companies/edit-companies.vue").default
      }
    }
  ]
};
