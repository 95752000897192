<template>
  <div class="main-content">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10 col-xl-10">
          <div
            class="row align-items-center"
            v-if="visibleNavigation"
          >
            <router-link
              class="btn btn-rounded-circle btn-white my-3"
              to="/user/settings"
            >
              <span class="fe fe-arrow-left" />
            </router-link>
            <div class="col">
              <h1 class="header-title">
                Termos de uso
              </h1>
            </div>
          </div>
          <img
            class="img-fluid"
            alt=""
            src="@assets/img/3CHeader.png"
          >
        </div>
        <div class="col-6 my-2">
          <h2 class="text-primary">
            Histórico de versões ::
          </h2>
          <span>
            Versão 03 (V-03), de 20/03/2024: Ajuste em relação às funcionalidades disponíveis através da Plataforma,
            além de valores aplicáveis e outras especificidades.<br>
          </span>
          <small>
            Versão 02 (V-02), de 18/01/2024: Ajuste em relação às funcionalidades disponíveis através da Plataforma,
            além de valores aplicáveis e outras especificidades.<br>
          </small>
          <small>
            Versão 01 (V-01), de 20/10/2022: Definição geral das responsabilidades de cada uma das partes na utilização
            dos serviços e produtos disponibilizados pela 3C+. Para acessar, clique aqui:
            <a
              href="https://docs.google.com/document/d/1MSHe31fLVygcGgHE_ifh6zSm8p9LNr8I/edit?usp=sharing&ouid=109183694489545328179&rtpof=true&sd=true"
            >https://docs.google.com/document/d/1MSHe31fLVygcGgHE_ifh6zSm8p9LNr8I/edit?usp=sharing&ouid=109183694489545328179&rtpof=true&sd=true</a>
          </small>
          <!-- Termos de uso -->
          <div>
            <h2 class="text-primary mt-4 ms-3">
              TERMOS DE USO
              <br>
              V-02 :: 18/01/2024
            </h2>
            <hr
              class="navbar-divider"
              style="color: #E60B42 !important; height: 2px !important;"
            >
            <span>Olá Usuário!
              <br>
              <br>
              Seja bem-vindo(a)!
              <br>
              <br>
              Estes Termos de Uso apresentam o compromisso da 3C+ com os Usuários que realizaram a contratação do
              Software e tem por objetivo informar, de forma transparente, como acontecem as interações entre a 3C+, os
              Clientes e os seus Agentes cadastrados, além de explicar quais são os direitos e responsabilidades de cada
              um, de acordo com a nossa relação.
              <br>
              <br>
              Este documento corresponde à Versão 02 (V-02), validada em
              <span
                class="fw-bold"
                style="background-color: #ffe599"
              >18/01/2024</span>. O histórico de versões pode ser
              consultado no início desta página.
              <br>
              <br>
              Para acesso às seções específicas deste documento, utilize o sumário abaixo:
            </span>
            <hr
              class="navbar-divider"
              style="color: #E60B42 !important; height: 2px !important;"
            >
            <!-- Sumário -->
            <div>
              <h2 class="text-primary mt-4 ms-3">
                SUMÁRIO
              </h2>
              <div class="ms-3">
                <a href="#definitions">
                  DEFINIÇÕES
                </a>
                <br>
                <a href="#whatIs3C+">
                  O QUE É A 3C+?
                </a>
                <br>
                <a href="#aboutPriceAndPaymentMethod">
                  SOBRE O VALOR E A FORMA DE PAGAMENTO
                </a>
                <br>
                <div class="ms-4">
                  <a href="#contractTypes">
                    Tipos de contratação
                  </a>
                  <br>
                  <a
                    href="#prepaid"
                    class="ms-4"
                  >1.Pré-Pago</a>
                  <br>
                  <a
                    href="#pospaid"
                    class="ms-4"
                  >2.Pós-Pago</a>
                  <br>
                  <a href="#funcionalities">
                    Funcionalidades disponíveis
                  </a>
                  <br>
                  <a
                    href="#dialer3C+"
                    class="ms-4"
                  >1.Discador 3C PLus</a>
                  <br>
                  <a
                    href="#whatsapp"
                    class="ms-4"
                  >2.WhatsApp</a>
                  <br>
                  <a
                    href="#ura"
                    class="ms-4"
                  >3.Unidade de Resposta Audível ("URA")</a>
                  <br>
                  <a
                    href="#sms"
                    class="ms-4"
                  >4.Short Message Service (“SMS”)</a>
                  <br>
                  <a
                    href="#crm"
                    class="ms-4"
                  >5.Customer Relationship Management (“CRM”)</a>
                  <br>
                  <a href="#contractFlux">
                    Fluxo de contratação
                  </a>
                  <br>
                </div>
                <a href="#intellectualProperty">
                  PROPRIEDADE INTELECTUAL E LICENCIAMENTO DE SOFTWARE
                </a>
                <br>
                <a href="#servicesAndInfrastructure">
                  SERVIÇOES E INFRAESTRUTURA
                </a>
                <br>
                <a href="#onboarding">
                  IMPLANTAÇÃO DO SISTEMA E ONBOARDING
                </a>
                <br>
                <a href="#default">
                  INADIMPLEMENTO
                </a>
                <br>
                <a href="#techSupport">
                  SUPORTE TÉCNICO E MANUTENÇÃO DO SISTEMA
                </a>
                <br>
                <a href="#serviceLevel">
                  ACORDO DE NÍVEL DE SERVIÇO OU SLA
                </a>
                <br>
                <a href="#responsabilityLimit">
                  QUAL O LIMITE DE RESPONSABILIDADE DA 3C+?
                </a>
                <br>
                <a href="#serviceTermination">
                  RESCISÃO DO SERVIÇO
                </a>
                <a href="#lgpd">
                  PRIVACIDADE E PROTEÇÃO DE DADOS PESSOAIS
                </a>
                <div class="ms-4">
                  <a href="#contractModels">• Do modelo de contratação da Plataforma 3C+</a>
                  <br>
                  <a href="#customerRights">• Dos direitos e obrigações gerais do Cliente</a>
                  <br>
                  <a href="#3CRights">• Dos direitos e obrigações da 3C+</a>
                  <br>
                  <a href="#incidentsCommunication">• Sobre as notificações e resoluções de incidentes</a>
                  <br>
                  <a href="#personData">• Encarregada de dados pessoais</a>
                </div>
                <a href="#foro">
                  LEGISLAÇÃO APLICÁVEL E FORO
                </a>
                <br>
                <a href="#termsOfServiceUpdate">
                  ATUALIZAÇÃO DOS TERMOS DE USO
                </a>
              </div>
            </div>
            <hr
              class="navbar-divider"
              style="color: #E60B42 !important; height: 2px !important;"
            >
          </div>
          <!-- Definições -->
          <div id="definitions">
            <h4 class="text-primary mt-4 ms-3">
              DEFINIÇÕES
            </h4>
            <hr
              class="navbar-divider"
              style="color: #E60B42 !important; height: 2px !important;"
            >
            <span>
              Para que você entenda todas as condições deste documento, veja abaixo algumas definições importantes, em
              ordem alfabética:
            </span>
            <ul class="star">
              <li>
                <span class="text-primary fw-bold">3C+: </span>
                sociedade empresária limitada, inscrita no CNPJ sob o nº. 20.287.691/0001-63, com sede na Rua Marechal
                Floriano Peixoto, 1601 - Centro, Guarapuava - PR, CEP nº. 85010-250.
              </li>
              <li>
                <span class="text-primary fw-bold">Usuário: </span>
                indivíduos que possuem acesso ou detém permissão e acesso ao sistema.
                <ul class="circle">
                  <li>
                    Usuários tipo "<span class="text-primary">Agente</span>": têm o privilégio de originar e receber
                    ligações ou mensagens, realizar gestão dos clientes através do CRM, bem como qualquer tipo de
                    atendimento ou contato através de outros canais disponíveis dentro da plataforma com a ponta final.
                  </li>
                  <li>
                    Usuários tipo "<span class="text-primary">Supervisão</span>": têm acesso a um conjunto de permissões
                    ou privilégios para realizar todas as atividades a nível estratégico e de supervisão, apenas nas
                    equipes que supervisionam, dentro do portal da plataforma vinculada a empresa, não podendo realizar
                    contatos.
                  </li>
                  <li>
                    Usuários tipo "<span class="text-primary">Gestão</span>": têm privilégio de realizar todas as
                    atividades a nível estratégico e de gestão dentro do portal da plataforma vinculada a empresa, não
                    podendo realizar contatos, tendo privilégio de realizar criação, edição e exclusão de usuários,
                    listas, campanhas e outras, podendo também consultar e extrair relatórios da Plataforma.
                  </li>
                </ul>
              </li>
              <li>
                <span class="text-primary fw-bold">Cliente: </span>
                entidade de direito privado que realiza a formalização da contratação dos serviços da 3C+.
              </li>
              <li>
                <span class="text-primary fw-bold">Destinatário: </span>
                pessoas cujas informações para operação telefônica pela Plataforma são cadastradas e endereçadas
                conforme diretrizes exclusivas dos Clientes para seus Usuários, sem interferência da 3C+. Podem ser seus
                próprios clientes, leads, devedores e afins.
              </li>
              <li>
                <span class="text-primary fw-bold">Plano(s): </span>
                diferentes pacotes de serviços ou produtos oferecidos pela 3C+ aos seus usuários para uso através da
                Plataforma, definidos e regulamentados conforme os Anexos deste documento.
              </li>
              <li>
                <span class="text-primary fw-bold">Softphone: </span>
                aplicativo de software que não possui relação direta com a 3C+, que emula funcionalidades de um telefone
                tradicional, permite fazer e receber chamadas de voz e vídeo sobre IP (VoIP) em dispositivos
                computacionais de uso geral em vez de hardware dedicado
              </li>
              <li>
                <span class="text-primary fw-bold">Software/Plataforma: </span>
                refere-se a programas de computador, dados e instruções que permitem o funcionamento de um sistema
                computacional. Esses programas podem incluir aplicativos, sistemas operacionais e outros componentes que
                executam tarefas específicas em dispositivos eletrônicos.
              </li>
            </ul>
          </div>
          <!-- O que é a 3C+ -->
          <div id="whatIs3C+">
            <h4 class="text-primary mt-4 ms-3">
              O QUE É A 3C+?
            </h4>
            <hr
              class="navbar-divider"
              style="color: #E60B42 !important; height: 2px !important;"
            >
            <span>
              A 3C+ é um software abrangente que impulsiona a eficiência e a produtividade em operações de atendimento e
              comunicação de negócios. Incorporando uma variedade de funcionalidades, incluindo discagem automatizada,
              inteligência para eliminação de chamadas improdutivas, ferramenta
              <span class="fst-italic"> omnichannel </span>, URA, SMS E CRM para uma gestão eficiente.
            </span>
          </div>
          <!-- Sobre o valor e forma de pagamento -->
          <div id="aboutPriceAndPaymentMethod">
            <h4 class="text-primary mt-4 ms-3">
              SOBRE O VALOR E A FORMA DE PAGAMENTO
            </h4>
            <hr
              class="navbar-divider"
              style="color: #E60B42 !important; height: 2px !important;"
            >
            <span>
              <!-- Tipos de contratação -->
              <span
                style="text-decoration: underline;"
                class="tx-pink-300"
                id="contractTypes"
              >Tipos de contratação
              </span>
              <br>
              <br>
              Os Planos da 3C+ poderão ser contratados mediante prazo de vigência <span class="fw-bold">mensal</span>,
              <span class="fw-bold">trimestral</span>, <span class="fw-bold">semestral ou anual</span>. As faturas serão
              emitidas em ciclos mensais, a depender de quando houver a aquisição da licença de uso e englobarão as
              funcionalidades escolhidas, número de Agentes contratados e forma de minutagem definidos pelo Cliente.
              <br>
              <br>
              A rescisão do Contrato dentro do prazo de fidelidade, quando motivada pelo Cliente, o sujeitará a uma
              multa equivalente a 50% (cinquenta por cento) dos valores que deveriam ser pagos nos meses que faltam a
              conclusão da fidelidade, sem prejuízo do direito da 3C+ em pleitear as perdas e danos decorrentes da
              rescisão extemporânea do Contrato.
              <br>
              <br>
              Além disso, os planos escolhidos pelo Cliente poderão ser pagos no formato pré-pago ou pós-pago, sendo o
              segundo formato inaceitável na contratação anual. Em quaisquer dos tipos de pagamento escolhidos, o
              Cliente poderá optar por realizar o pagamento via cartão, PIX ou boleto.
              <br>
              <br>
              <span
                id="prepaid"
                class="ms-4 fw-bold"
              > 1. Pré-Pago</span>
              <br>
              As contratações pré-pagas oferecem uma maior previsibilidade de despesas, uma vez que o usuário controla
              quanto gasta. Referem-se aos acordos dos Planos e tipos de contratação nos quais o Cliente faz um
              pagamento antecipado antes de utilizar o serviço, de acordo com as suas especificações previstas para o
              mês ou para o ano.
              <br>
              <br>
              <span
                id="pospaid"
                class="ms-4 fw-bold"
              >2. Pós-Pago</span>
              <br>
              Contratações pós-pagas referem-se a acordos de serviço nos quais o Cliente utiliza o serviço antes de
              efetuar o pagamento. Nesse modelo, será utilizado o serviço e, ao final do mês, enviada uma fatura
              detalhada com base no uso durante o período.
              <br>
              <br>
              O Cliente será responsável pelo pagamento integral da fatura até a data de vencimento estipulada. As
              contratações pós-pagas oferecem maior flexibilidade em relação ao uso dos serviços, permitindo ajustes com
              base nas necessidades do Cliente e não são aplicáveis ao formato de pagamento anual.
              <br>
              <br>
              <!-- Funcionalidades disponíveis -->
              <span
                style="text-decoration: underline;"
                class="tx-pink-300"
                id="funcionalities"
              >Funcionalidades disponíveis
              </span>
              <br>
              A 3C+ oferece uma série de funcionalidades e produtos aos seus Clientes, que poderão escolher conforme o
              que melhor lhes convier. Os Planos são modulares, podendo ser alterados para mais ou para menos, com maior
              ou menor minutagem e maior ou menor número de Agentes. Os Planos oferecidos pela 3C+ são:
              <br>
              <br>
              <span
                id="dialer3C+"
                class="ms-4 fw-bold"
              >1. Discador 3C Plus</span>
              <br>
              O Discador 3C+ (“Discador”) realiza ligações, descarte de contatos improdutivos e acompanhamento de
              métricas em tempo real. Com ele, é possível reduzir custos operacionais, integrar bases com os maiores
              CRMs do mercado, qualificar e gravar chamadas, aumentar as taxas de conversão e oportunidades de negócio e
              garantir uma maior produtividade a partir de uma posição de atendimento.
              <br>
              <br>
              A inteligência artificial do Discador reconhece as chamadas improdutivas e descarta automaticamente
              contatos que frequentemente caem na caixa postal, não atendem ou que não completam a chamada. Assim, você
              não precisa se preocupar com frequentes higienizações dos seus mailings, obtendo as mais altas métricas na
              sua operação.
              <br>
              <br>
              O Discador conta com rotas de telefonia próprias e, também, é capaz de ser integrado com uma rota de sua
              preferência.
              <br>
              <br>
              <span class="fw-bold">1.1. Ilimitada</span>
              <br>
              Trata-se de um serviço fornecido pela 3C+ que permite aos usuários realizar chamadas de maneira ilimitada
              sem restrições quantitativas, para destinos nacionais, utilizando tecnologias como VoIP, os valores dos
              minutos já estão inclusos no valor do plano.
              <br>
              <br>
              Ao contratar uma licença ilimitada o cliente está pagando todo o licenciamento e uso do software já com o
              uso e consumo de telefonia VoIP incluso no valor do plano.
              <br>
              <br>
              <span class="fw-bold">1.2. Minutagem</span>
              <br>
              Trata-se de um serviço fornecido pela 3C+ que permite aos usuários realizar chamadas de maneira tarifada,
              utilizando tecnologias como VoIP. Nesse modelo, o Cliente pagará a parte o valor referente aos minutos
              utilizados.
              <br>
              <br>
              Ao contratar uma licença minutagem o cliente paga pelo licenciamento do software, podendo utilizar a
              telefonia VoIP da 3C+ ou rota externa compatível.
              <br>
              <br>
              Na hipótese do cliente utilizar a rota 3C+ o mesmo será responsável por manter um saldo adequado para
              garantir a continuidade do serviço. Quando o saldo atinge zero ou um valor mínimo, é necessário recarregar
              para continuar usando o serviço. A falta de saldo suficiente pode resultar na interrupção do serviço até
              que uma recarga seja efetuada.
              <br>
              <br>
              <span class="fw-bold">1.3. Rota 3C+</span>
              <br>
              Trata-se de telefonia VoIP onde as chamadas não são transmitidas por meio de linhas telefônicas
              tradicionais, mas sim convertidas em pacotes de dados e enviadas pela internet.
              <br>
              Ao contratar a Discadora na modalidade Minutagem o cliente poderá optar por utilizar a rota de telefonia
              disponibilizada pela 3C+, de tal maneira que não precisará se preocupar em trazer um fornecedor externo.
              <br>
              <br>
              A Rota 3C+ é comercializada dentro da própria plataforma. Ao utilizar a Rota 3C+ o usuário deverá inserir
              créditos dentro da própria plataforma que serão descontados conforme a utilização. O saldo não deve zerar
              ou chegar ao valor mínimo para que não ocorra a interrupção dos serviços.
              <br>
              <br>
              <span
                id="whatsapp"
                class="ms-4 fw-bold"
              >2. WhatsApp</span>
              <br>
              O sistema 3C+ para WhatsApp é uma plataforma avançada projetada para oferecer uma gestão eficiente de
              conexões e equipes, estratégias de atendimento personalizáveis e escalabilidade na nuvem para empresas que
              desejam otimizar sua comunicação por meio do WhatsApp. Esta solução é ideal para empresas que utilizam o
              WhatsApp como canal de comunicação com clientes, fornecedores ou parceiros de negócios.
              <br>
              <br>
              <span
                id="ura"
                class="ms-4 fw-bold"
              >3. Unidade de Resposta Audível ("URA")</span>
              <br>
              <br>
              O Plano de URA facilita e agiliza a comunicação com os Destinatários dos Clientes. Com a URA, o Cliente
              consegue direcionar automaticamente as ligações para os setores corretos de atendimento, ter funcionamento
              por 24h por dia, reproduzir automaticamente o menu gravado, direcionar chamadas para as filas receptivas
              adequadas, gravar as interações entre os Agentes e os Destinatários, otimizar o trabalho da equipe de
              atendimento e reduzir o Tempo Médio de Atendimento (“TMA”).
              <br>
              <br>
              <span
                id="sms"
                class="ms-4 fw-bold"
              >4. Short Message Service ("SMS")</span>
              <br>
              O 3C+ SMS possibilita o envio eficiente de SMS em massa ou individualmente, com a validação rigorosa para
              garantir a confiabilidade das mensagens. A plataforma oferece um relatório detalhado para verificar a
              entrega, respostas e status das mensagens. É possível identificar se as mensagens foram entregues com
              sucesso, rejeitadas pela operadora ou não entregues. Com transparência no processo, os relatórios podem
              ser baixados para uma análise aprofundada, proporcionando controle total sobre a eficácia da comunicação
              via SMS.
              <br>
              <br>
              <span
                id="crm"
                class="ms-4 fw-bold"
              >5. Customer Relationship Management ("CRM")</span>
              <br>
              O CRM 3C+ é uma plataforma de gestão de funis, permitindo a criação e personalização de cada etapa. Os
              funis podem ser configurados com campos obrigatórios, e os cards são totalmente personalizáveis,
              oferecendo a opção de comunicação via WhatsApp 3C+ ou discadora, podendo ser contratadas de forma conjunta
              ou separada. A classificação dos cards proporciona um controle preciso, enquanto a timeline registra todas
              as interações.
              <br>
              <br>
              A vinculação de contatos do WhatsApp ao funil agiliza o atendimento, gerando cards automaticamente com a
              contratação da ferramenta de WhatsApp. Além disso, as ligações diretas do CRM facilitam o contato com os
              clientes, especialmente quando a discadora é contratada. Em resumo, a 3C+ proporciona uma gestão eficaz de
              funis, otimizando todo o processo de atendimento e negociações.
              <br>
              <br>
              <!-- Fluxo de contratação -->
              <span
                id="contractFlux"
                style="text-decoration: underline;"
                class="tx-pink-300"
              >Fluxo de contratação
              </span>
              <br>
              Os valores de mensalidade, números e serviços de telecomunicação são definidos em proposta comercial
              negociada individualmente.
              <br>
              <br>
              Para utilização das funcionalidades, o Cliente deverá pagar conforme o formato da contratação escolhida.
              <br>
              <br>
              Em caso de contratação de novas pessoas ou Usuários pelo Cliente, através dos canais de atendimento da
              3C+, os acessos serão ativados no prazo de até 02 (dois) dias úteis e o valor correspondente será
              incorporado ao preço base da mensalidade.
              <br>
              <br>
              Caso a contratação de novos Usuários ocorra após o pagamento da mensalidade, as respectivas licenças serão
              cobradas de forma “pro rata”, referente aos dias de utilização naquele mês, juntamente com a fatura do mês
              seguinte à inclusão.
              <br>
              <br>
              Caso o Cliente queira reduzir a quantidade de Usuários contratados, deverá solicitar a exclusão através do
              canal de suporte da 3C+. A redução dos valores somente ocorrerá no próximo mês de vigência.
              <br>
              <br>
              A emissão da Nota Fiscal será realizada após a confirmação do pagamento e enviada para o e-mail
              <a href="mailto:financeiro@3cplusnow.com">financeiro@3cplusnow.com</a>.
              <br>
              <br>
              Caso o Cliente não receba o boleto bancário para pagamento em até 03 (três) dias antes do vencimento,
              deverá entrar em contato com a 3C+ através do e-mail
              <a href="mailto:financeiro@3cplusnow.com">financeiro@3cplusnow.com</a>, para a emissão da 2ª via, sob pena
              de suspensão do acesso às licenças contratadas, nos termos do parágrafo sobre rescisão disposto no
              presente documento.
              <br>
              <br>
              A 3C+ realizará o reajuste dos valores por ela cobrados, a cada ciclo anual de vigência do Contrato, com
              aplicação da variação positiva do IGPM mínimo 10% e máximo 15%.
            </span>
          </div>
          <!-- propriedade intelectual -->
          <div id="intellectualProperty">
            <h4 class="text-primary mt-4 ms-3">
              PROPRIEDADE INTELECTUAL E LICENCIAMENTO DE SOFTWARE
            </h4>
            <hr
              class="navbar-divider"
              style="color: #E60B42 !important; height: 2px !important;"
            >
            <span>
              O Software, é de propriedade exclusiva da 3C+, a qual detém todos os direitos sobre ele. O Cliente, ora
              licenciado, reconhece que a propriedade intelectual sobre a tecnologia e todos os bens e direitos de
              propriedade intelectual referentes ao objeto deste Contrato permanecerão sob titularidade única e
              exclusiva da 3C+, sendo proibida a utilização do Software sem a respectiva licença, assim como a
              modificação, cessão, engenharia reversa, distribuição, reprodução, publicação e licenciamento, gratuito ou
              oneroso, a quem quer que seja, estando sujeita também às medidas judiciais, cíveis e penais, aplicáveis à
              espécie.
              <br>
              <br>
              O Software poderá ter sua interface e funcionalidades alteradas a qualquer momento, oportunidade em que
              uma nova versão será disponibilizada à <span class="fw-bold">CONTRATANTE</span>, sem a necessidade de
              prévia autorização desta, desde que preservada a essência do produto objeto deste Contrato.
              <br>
              <br>
              As interfaces e funcionalidades alteradas podem ser acompanhadas pela
              <span class="fw-bold">CONTRATANTE</span> através do link
              <a href="https://headwayapp.co/3c-plus-updates">https://headwayapp.co/3c-plus-updates</a>.
              <br>
              <br>
              O Cliente terá o direito de acesso ao painel de controle web para gestão da configuração do 3C+, consulta
              a relatórios e monitoramento de uso do sistema. O acesso de gestão é exclusivamente para gerenciamento da
              ferramenta. Apenas serão cobradas licenças de Agentes, que poderão conectar e realizar ligações.
            </span>
          </div>
          <!-- serviços e infraestrutura -->
          <div id="servicesAndInfrastructure">
            <h4 class="text-primary mt-4 ms-3">
              SERVIÇOS DE TELECOMUNICAÇÃO EM NUVEM E INFRAESTRUTURA
            </h4>
            <hr
              class="navbar-divider"
              style="color: #E60B42 !important; height: 2px !important;"
            >
            <span>
              Na hipótese do Cliente utilizar a plataforma para efetuar ligações:
              <div class="ms-5">
                a) O Cliente prestará os serviços de telecomunicações em parceria com operadoras de telefonia
                devidamente habilitadas pelos órgãos competentes. Nesse sentido, será de livre escolha por parte do
                Cliente a utilização das rotas de telefonia oferecidas pela 3C+ ou de outras empresas que executem tal
                atividade
                <br>
                b) Caso ocorra a contratação de outra empresa, deverão ser observados os requisitos de conexão em
                formato SIP e compatibilidade de acesso à estrutura em nuvem da 3C+.
              </div>
              <br>
              Os Clientes e, inclusive, seus Usuários, compartilharão da mesma infraestrutura do Software 3C+, porém com
              total isolamento entre os mesmos, garantindo a segurança e impedindo que dados sejam acessados
              indevidamente por terceiros e uns pelos outros.
              <br>
              <br>
              Para a correta utilização do Software, em alguns casos, será necessário também o uso do “Softphone”, que,
              embora não possua relação direta com a 3C+, poderá ser disponibilizado ao Cliente, através de nossos
              canais de suporte. A 3C+ não possui qualquer responsabilidade sobre o licenciamento e utilização do
              Softphone.
              <br>
              <br>
              O Software e o Softphone são programas que dependem dos recursos de memória, processamento, espaço em
              disco e áudio do computador em que vier a ser instalado e/ou utilizado. O Cliente poderá optar pelo uso de
              telefones IP ou ATAs (dispositivos que têm a função de telefone e se conectam diretamente em redes IP) que
              devem ser adquiridos de forma independente.
              <br>
              <br>
              A 3C+ não realiza a venda de aparelhos e não se responsabiliza pela falta de estrutura/compatibilidade dos
              computadores e aparelhos dos clientes no tocante ao funcionamento do programa, sendo responsabilidade do
              Usuário a obtenção de um software que melhor atenda suas necessidades.
              <br>
              <br>
              O Cliente se compromete a utilizar protocolos abertos (SIP/RTP ou outros), de forma que qualquer
              dispositivo padrão de mercado seja compatível com sua Plataforma.
              <br>
              <br>
              Em caso de contratação da ferramenta de gestão do WhatsApp, o Cliente deverá possuir um número de sua
              titularidade para ser conectado na ferramenta, todo uso e responsabilidade será do próprio Cliente e, em
              casos de bloqueios e banimentos, a 3C+ não terá qualquer responsabilidade.
              <br>
              <br>
              A 3C+ oferece toda a documentação explicativa para que o Cliente possa configurar o Softphone, bem como
              alguns modelos de telefones IP, sendo de exclusiva responsabilidade do Cliente configurar seus softwares
              ou telefones IP, e garantir que estes tenham acesso à Internet com total conectividade ao ambiente.
              <br>
              <br>
              O Cliente terá acesso ao Software através da internet e a quantidade de Agentes será disponibilizada nos
              termos contratados. A 3C+ não se responsabiliza pela disponibilidade, qualidade da rede ou acesso à
              internet contratada e mantida pelo Cliente de forma independente. Para o correto funcionamento do
              Software, o Cliente deverá informar à 3C+:
              <ul class="dash">
                <li>
                  Sobre qualquer alteração dos dados cadastrais, sob pena de não o fazendo considerarem-se válidos todos
                  os avisos e/ou notificações enviados para os endereços inicialmente informados.
                </li>
                <li>
                  Disponibilizar conexão com a internet capaz de suportar a quantidade de chamadas simultâneas
                  pretendida, calculada à base de 80 Kbps de conexão com a internet (tanto como velocidade de upload
                  como de download) para cada chamada simultânea.
                </li>
                <li>
                  Utilizar computador, com configuração mínima exigível consistente de processador de Core 2 Duo 1.8 Ghz
                  ou superior, recomendado i3/i5/i7 com 2.1 Ghz ou mais, memória RAM mínimo 4Gb, recomendado 8Gb ou
                  mais, conexão com a internet, mínimo de 1 Mbps para cada 4 agentes, recomendado 1 Mbps para cada 2
                  agentes, velocidade necessária tanto para Upload como Download, placa de som e sistema operacional
                  Windows, OS X e Linux.
                </li>
                <li>
                  Zelar pela correta utilização da senha de administrador que possibilita o acesso ao painel de controle
                  para gerenciamento e administração do serviço, bem como não compartilhar com terceiros, sendo de sua
                  responsabilidade eventual uso indevido.
                </li>
              </ul>
              <br>
              A 3C+ informará ao Cliente atarvés da página
              <a href="https://status.3c.plus/">https://status.3c.plus/</a>, com pelo menos 03 (três) dias de
              antecedência, sobre as interrupções necessárias para ajustes técnicos ou manutenções que demandem mais de
              06 (seis) horas de duração e que possam causar prejuízo à operacionalidade da Plataforma, salvo em casos
              de urgência, assim entendidos como aqueles que coloquem em risco o regular funcionamento dos servidores
              onde está o serviço 3C+ e aqueles determinados por motivo de segurança decorrentes de vulnerabilidades.
              <br>
              <br>
              Nestes casos, as interrupções serão imediatas, sem prévio aviso e não deverão superar a duração de 02
              (duas) horas cada. Qualquer dúvida, a 3C+ irá disponibilizar através do site
              <a href="http://help.3cplus.com.br">http://help.3cplus.com.br</a> as instruções que possibilitem que o
              Software seja utilizado corretamente pelo Cliente.
            </span>
          </div>
          <!-- implantação do sistema e onboarding -->
          <div id="onboarding">
            <h4 class="text-primary mt-4 ms-3">
              IMPLANTAÇÃO DO SISTEMA E ONBOARDING
            </h4>
            <hr
              class="navbar-divider"
              style="color: #E60B42 !important; height: 2px !important;"
            >
            <span>
              Após a contratação do sistema da 3C+ e o aceite destes Termos de uso, a 3C+ terá um prazo de 72 (setenta e
              duas) horas úteis, para dar início ao processo de apresentação e implantação do sistema ao Cliente. Para
              tanto, o Cliente deverá indicar as pessoas e os Agentes responsáveis por participar do treinamento
              inicial, de modo que seja possível o agendamento do dia e horário entre a 3C+ e o Cliente para a
              apresentação remota.
              <br>
              <br>
              Nos casos em que a ferramenta já passou pelo processo de implantação e o Cliente já realizou o período de
              teste, o prazo para a liberação será de até de 24h (vinte e quatro horas) úteis após o pagamento.
              <br>
              <br>
              Em caso de necessidade de alteração do dia e horário previamente agendados, o Cliente deverá formalizar o
              pedido por e-mail, com antecedência mínima de 01 (um) dia útil.
              <br>
              <br>
              Em caso de não comparecimento injustificável pelo Cliente no dia e horário previamente agendados, será
              cobrada <span class="fw-bold">multa no valor de R$750,00 (setecentos e cinquenta reais),</span>
              a ser paga na próxima fatura. Novas solicitações de apresentação do sistema poderão ser agendadas pelo
              Cliente, mediante formalização por e-mail, telefone ou chat, sendo devida uma
              <span class="fw-bold"> taxa adicional no valor de R$750,00 (setecentos e cinquenta reais)</span>
              a cada novo treinamento, a ser paga na próxima fatura.
              <br>
              <br>
              Na hipótese em que o Cliente utilize serviço de telefonia terceirizado, a 3C+ realizará a implantação de
              forma gratuita, a primeira implantação ocorrerá no prazo de até 48h após preenchimento de formulário e
              novas rotas deverão respeitar o prazo de até 72h.
              <br>
              <br>
              É de inteira responsabilidade do Cliente o fornecimento de todos os dados necessários para configuração de
              rotas externas, bem como a intermediação entre o fornecedor e a equipe técnica da 3C+, em caso de dúvidas
              ou problemas no ato ou posterior a implantação da rota.
              <br>
              <br>
              Ressalta-se que o serviço contratado e prestado por terceiros não implica em qualquer responsabilidade
              para a equipe da 3C+ tendo ciência que quaisquer falhas que venham a ocorrer com as chamadas telefônicas
              originadas por essa rota deverão ser tratadas diretamente com o fornecedor.
            </span>
          </div>
          <!-- inadimplemento -->
          <div id="default">
            <h4 class="text-primary mt-4 ms-3">
              INADIMPLEMENTO
            </h4>
            <hr
              class="navbar-divider"
              style="color: #E60B42 !important; height: 2px !important;"
            >
            <span>
              O inadimplemento dos valores contratados autorizará a 3C+, independentemente de notificação, até que
              ocorra o devido pagamento, a:
              <br>
              <br>
              <div class="ms-5">
                a) bloquear a utilização do Software;
                <br>
                b) bloquear o acesso dos usuários ao Sistema;
                <br>
                c) cancelar o atendimento de chamados de suporte;
                <br>
                d) Protestar o título executivo; e
                <br>
                e) realizar a negativação do Cliente nos órgãos de defesa ao crédito
              </div>
              <br>
              <br>
              Caso haja a regularização do pagamento, o acesso ao sistema será restabelecido no prazo de até 02 (dois)
              dias úteis. O adimplemento parcial da parcela não eximirá o Cliente de eventual bloqueio provisório, nos
              termos acima.
              <br>
              <br>
              As parcelas em atraso serão acrescidas de multa de 2% (dois por cento), além de juros mensais compostos de
              1% (um por cento) e correção monetária pelo IPCA.
              <br>
              <br>
              Em caso de inadimplência superior a 30 (trinta) dias consecutivos do vencimento da parcela, o bloqueio se
              tornará definitivo, com o consequente cancelamento da licença do Software e interrupção do serviço de
              telefonia, bem como a rescisão da relação,
              <span class="fw-bold">sem necessidade de aviso prévio.</span>
            </span>
          </div>
          <!-- suporte técnico -->
          <div id="techSupport">
            <h4 class="text-primary mt-4 ms-3">
              SUPORTE TÉCNICO E MANUTENÇÃO DO SISTEMA
            </h4>
            <hr
              class="navbar-divider"
              style="color: #E60B42 !important; height: 2px !important;"
            >
            <span>
              O suporte técnico será realizado por telefone, e-mail ou chamado via sistema, de segunda a sexta (das 9h00
              às 18h00) e aos sábados (das 9h00 às 12h00). Na hipótese do Cliente solicitar ou necessitar de atendimento
              fora dos horários previstos, esses poderão ser cobrados hora/homem. As informações e os dados de acesso ao
              suporte poderão ser acessados no site <a href="www.3cplusnow.com.">www.3cplusnow.com</a>.
              <br>
              <br>
              A interrupção que interfira ou que cause prejuízo à operacionalidade do serviço e seja necessária para a
              manutenção do sistema será realizada, num período não superior a 06 (seis) horas, sempre que possível,
              entre as 00:00 e as 06:00 horas.
            </span>
          </div>
          <!-- acordo de nível de serviço -->
          <div id="serviceLevel">
            <h4 class="text-primary mt-4 ms-3">
              ACORDO DE NÍVEL DE SERVIÇO OU SLA
            </h4>
            <hr
              class="navbar-divider"
              style="color: #E60B42 !important; height: 2px !important;"
            >
            <span>
              Denomina-se acordo de nível de serviço ou SLA (Service Level Agreement), para efeito destes Termos, o
              nível de desempenho técnico do serviço prestado pela 3C+, sendo certo que tal acordo não representa
              diminuição de responsabilidade desta, mas sim indicador de excelência técnica, uma vez que, quando falamos
              de tecnologia da informação não existe garantia integral (100%) de nível de serviço.
              <br>
              <br>
              O não atingimento do acordo de nível aqui descrito gerará para ao Cliente o direito de receber descontos
              sobre os valores das mensalidades devidas, seja no modelo pré ou pós pago, nos percentuais abaixo
              previstos:
              <br>
              <br>
              <div class="ms-5">
                a) 5% se o serviço ficar fora do ar de 2,6% 3,5% do tempo;
                <br>
                b) 7,5% se o serviço ficar fora do ar de 3,6% a 4,5% do tempo;
                <br>
                c) 10% se o serviço ficar fora do ar por tempo superior a 4,6% do tempo.
              </div>
              <br>
              <br>
              Deixa-se esclarecido aqui que a medição do SLA se dará em sua totalidade através do link a seguir:
              <a href="https://status.3c.plus/">https://status.3c.plus/</a>. Destaca-se que por haver as possibilidades
              de desconto pelo não atingimento do acordo de nível a 3C+ se exime de quaisquer perdas e danos que possam
              ser causados.
              <br>
              <br>
              A comunicação de descumprimento do SLA deverá ser formalizada pelo Cliente à 3C+ através do e-mail
              <a href="mailto:suporte@3cplusnow.com">suporte@3cplusnow.com</a>, no prazo máximo de 05 (cinco) dias da
              constatação desse descumprimento, para devida análise pela equipe técnica da 3C+. A resposta se aprovada
              se dará através do mesmo canal de comunicação e o desconto será aplicado na próxima fatura.
              <br>
              <br>
              Não serão computadas para efeito de apuração do SLA:
              <br>
              <br>
              <div class="ms-6">
                <span>
                  a) Falhas nos serviços de telecomunicações dos quais o Cliente se valerá para utilizar o Software;
                </span>
              </div>
              <br>
              <div class="ms-6">
                <span>
                  b) Falhas de configuração e utilização do Software de responsabilidade do Cliente ou sobrecarga do
                  servidor causada por configuração ou utilização inapropriada;
                </span>
              </div>
              <br>
              <div class="ms-6">
                <span>
                  c) Falha nos serviços de infraestrutura (tais como servidores, armazenamento de dados, rede) usados
                  como base para o serviço contratado;
                </span>
              </div>
              <br>
              <div class="ms-6">
                <span>
                  d) As interrupções necessárias para ajustes técnicos e manutenções, que serão informadas com
                  antecedência ao Clientes, conforme definido neste documento, e se realizarão, preferencialmente, fora
                  do período comercial;
                </span>
              </div>
              <br>
              <div class="ms-6">
                <span>
                  e) As interrupções decorrentes de inadimplemento do Cliente;
                </span>
              </div>
              <br>
              <div class="ms-6">
                <span>
                  f) As intervenções emergenciais decorrentes da necessidade de preservar a segurança do servidor,
                  destinadas a evitar ou fazer cessar a atuação de “hackers” ou destinadas a implementar correções de
                  segurança (patches);
                </span>
              </div>
              <br>
              <div class="ms-6">
                <span>
                  g) Suspensão da prestação dos serviços contratados por determinação de autoridades competentes, ou por
                  descumprimento de cláusulas do presente Contrato;
                </span>
              </div>
              <br>
              <div class="ms-6">
                <span>
                  h) Falhas ocasionadas por incompatibilidade entre a 3C+ e eventuais outros programas ou equipamentos
                  utilizados pelo Usuário;
                </span>
              </div>
              <br>
              <div class="ms-6">
                <span>
                  i) Falhas ou interrupções do WhatsApp, tendo em vista que trata-se de um software global, aberto e
                  gratuito, sendo assim a <span class="fw-bold">CONTRATADA</span> não detém de controle direto do mesmo;
                  e
                </span>
              </div>
              <br>
              <div class="ms-6">
                <span>
                  j) Problemas causados por caso fortuito ou força maior.
                </span>
              </div>
              <br>
            </span>
          </div>
          <!-- qual o limite de responsabilidade da 3c+ -->
          <div id="responsabilityLimit">
            <h4 class="text-primary mt-4 ms-3">
              QUAL O LIMITE DE RESPONSABILIDADE DA 3C+?
            </h4>
            <hr
              class="navbar-divider"
              style="color: #E60B42 !important; height: 2px !important;"
            >
            <span>
              A 3C+ é uma empresa de tecnologia que oferece, através de licenciamento aos Clientes, o uso da Plataforma,
              para facilitar e otimizar atividades de telemarketing, call center, discagem telefônica e qualquer
              operação de qualquer natureza que queira melhorar a comunicação com seu cliente final. Explorando todos os
              canais que estão disponíveis dentro da plataforma 3C+.
              <br>
              <br>
              A 3C+ não poderá ser responsabilizada por qualquer indisponibilidade de acesso ou falha do Software 3C
              Plus, além do seu controle razoável, diretamente causadas por: a) manutenção programada ou emergencial do
              Software 3C Plus; b) falhas na infraestrutura do Cliente; c) falhas na prestação de serviços e/ou produtos
              de terceiros contratados diretamente pelo Cliente; d) falta de energia ou falha de telecomunicações nas
              instalações do Cliente; e) eventos de Força Maior.
              <br>
              <br>
              Nosso compromisso é fornecer uma ferramenta eficiente que permita aos nossos Clientes o aprimoramento das
              suas operações de comunicação e marketing, gestão e otimização da interação com seus próprios clientes e
              destinatários das ligações e permitindo o direcionamento de esforços humanos e em massa onde cada
              Contratante precise alocar.
              <br>
              <br>
              A 3C+, entretanto, não possui controle total ou gerenciamento sobre como cada Cliente utiliza a Plataforma
              para se comunicar com terceiros, destinatários das ligações otimizadas pela tecnologia, tampouco como
              configura os dados dos destinatários na Plataforma e orienta seus próprios colaboradores para endereçarem
              as conversas com os referidos destinatários.
              <br>
              <br>
              A 3C+ não possui acesso a qualquer dado ou estratégia de ligação, cada Cliente é responsável por garantir
              que o uso da nossa Plataforma esteja em conformidade com todas as leis e regulamentos aplicáveis. Não há
              acesso, pela 3C+, às informações sobre campanhas criadas pelos Clientes, apenas eventualmente ao conteúdo
              das ligações ou quaisquer outras decorrentes do uso da Plataforma.
              <br>
              <br>
              As informações de cada Contratante são armazenadas em bancos de dados individualizados e sem possibilidade
              de acesso ou controle pela 3C+, mas, tão somente, pelo próprio Cliente.
              <br>
              <br>
              Considerando que a 3C+ simplesmente disponibiliza um discador, através do desenvolvimento de tecnologia
              avançada, para que o Cliente possa aumentar o fluxo de contato com seus Destinatários que já seria
              realizado, independentemente da existência da tecnologia ou não (de maneira menos organizada e efetiva,
              tão somente), não existe o que falar em responsabilidade compartilhada decorrente do mau uso da Plataforma
              pelo Cliente.
              <br>
              <br>
              Reiteramos que a 3C+ não pode ser responsabilizada, direta ou indiretamente, por quaisquer consequências
              legais, financeiras ou reputacionais decorrentes de qualquer tipo de reclamação ou fiscalização realizada
              pelos destinatários das ligações ou por agentes fiscalizadores que tenham capacidade para tanto, em
              virtude do uso indevido da nossa plataforma pelos Clientes e seus operadores.
              <br>
              <br>
              É de dever de cada Cliente e Agente a utilização da Plataforma de maneira ética e legal, respeitando os
              direitos e interesses de terceiros, especialmente dos Destinatários.
              <br>
              <br>
              Dito isso, caso sejam constatadas violações a direitos e liberdades de terceiros, nós da 3C+ nos
              disponibilizamos a receber denúncias com o intuito de auxiliar na investigação de eventuais abusos, junto
              com autoridades competentes e na medida em que esteja ao nosso alcance, tanto técnica quanto civilmente.
              <br>
              <br>
              Ressalta-se, por fim e uma última vez, que não há como caracterizar qualquer hipótese de responsabilidade
              conjunta da 3C+ com seus Clientes no caso de uso impróprio e para fins ilegítimos da Plataforma pelos
              Contratantes e seus Usuários.
              <br>
              <br>
              Em conformidade com as normas e medidas estabelecidas pela Agência Nacional de Telecomunicações (ANATEL),
              a <span class="fw-bold">3C+</span> declara seu compromisso em respeitar integralmente tais
              regulamentações. No caso de identificação de uso inadequado dos serviços ora prestados por parte do
              Cliente, que resulte em violação dessas normas, reservamo-nos o direito de, reduzir a intensidade do
              tráfego agressivo, bem como bloquear os acessos do Cliente de forma automática e sem aviso prévio, com o
              intuito de proteger o Cliente e a 3C+, não sendo aplicável nenhuma forma de reembolso, visando garantir o
              cumprimento das normativas vigentes e a integridade da operação.
              <br>
              <br>
              Além disso, a 3C+ não se responsabiliza por quaisquer problemas referentes ao acesso da internet, ao uso
              inadequado das funcionalidades do Software, erros ou inconsistências na transmissão de dados da rede, além
              dos seus dados que estejam desatualizados, incompletos ou inverídicos.
              <br>
              <br>
              O Cliente declara estar ciente de que pequenos defeitos de programação (“bugs”) ou quedas do sinal da
              internet são comuns a sistemas de tecnologia, isentando-se, assim, de qualquer responsabilidade por
              problemas decorrentes de tais bugs. A responsabilidade da 3C+ limita-se à correção das intercorrências
              eventualmente encontradas durante o cumprimento destes Termos de Uso.
              <br>
              <br>
              Posterior ao momento da contratação a 3C+ não possui quaisquer responsabilidades sobre o gerenciamento dos
              usuários disponibilizados ao Cliente para a utilização da plataforma, bem como exime-se de culpa sobre o
              mau uso desses usuários, caracterizados por criação de novos usuários, edição ou exclusão de usuários
              existentes e principalmente alterações nos níveis de acesso e privilégios.
              <br>
              <br>
              O Cliente assume toda e qualquer responsabilidade pela gestão dos usuários de acesso ao seu portal, bem
              como inativação ou exclusão de usuários de qualquer prestador ou colaborador que não possua mais vínculo
              com o Cliente.
              <br>
              <br>
              O Cliente compromete-se em manter sua base de usuários ativos sempre atualizada, a fim de evitar o mau uso
              dos mesmos.
              <br>
              <br>
              A 3C+ visando manter sua integridade ética e moral poderá eventualmente realizar auditorias internas em
              seus clientes e na hipótese de suspeita de qualquer prática ilegal ou fraude poderá suspender a
              contratação a qualquer tempo e sem aviso prévio. Bem como tomar as medidas legais cabíveis.
            </span>
          </div>
          <!-- recisão do serviço -->
          <div id="serviceTermination">
            <h2 class="text-primary mt-4 ms-3">
              RESCISÃO DO SERVIÇO
            </h2>
            <hr
              class="navbar-divider"
              style="color: #E60B42 !important; height: 2px !important;"
            >
            <span>
              O Cliente fica ciente que não haverá possibilidade de arrependimento, tendo em vista que realizou um
              período de teste e optou pela contratação.
              <br>
              <br>
              Nos primeiros 30 (trinta) dias de utilização dos serviços contratados, o Usuário poderá solicitar a
              rescisão do serviço, oportunidade em que os valores pagos antecipadamente não serão reembolsados.
              <br>
              <br>
              Após os primeiros 30 (trinta) dias de utilização dos serviços contratados, o Cliente ou a 3C+ poderá
              rescindir unilateralmente a qualquer tempo, desde que uma parte comunique a outra, por escrito, com 30
              (trinta) dias de antecedência (“Aviso Prévio”).
              <br>
              <br>
              Durante o período de Aviso Prévio, a 3C+ garantirá todo o suporte necessário ao Cliente e seus Agentes.
              <br>
              <br>
              Findo o período de Aviso Prévio, o acesso ao Software e o serviço de telefonia serão bloqueados, bem como
              todos os direitos sobre a licença serão revogados.
              <br>
              Após a rescisão da contratação dos serviços prestados, o Cliente terá o prazo de 180 (cento e oitenta)
              dias, para retirar e exportar relatórios da plataforma, bem como solicitar backups de gravações. Após este
              prazo todos os dados do Cliente serão excluídos automaticamente.
            </span>
          </div>
          <!-- LGPD -->
          <div id="lgpd">
            <h4 class="text-primary mt-4 ms-3">
              PRIVACIDADE E PROTEÇÃO DE DADOS PESSOAIS
            </h4>
            <hr
              class="navbar-divider"
              style="color: #E60B42 !important; height: 2px !important;"
            >
            <span
              id="contractModels"
              style="text-decoration: underline;"
              class="tx-pink-300"
            >
              Do modelo de contratação da Plataforma 3C+
            </span>
            <br>
            <span>
              O Cliente declara ter ciência e aceita que a 3C+ licencia suas soluções no formato de armazenamento em
              nuvem (Software as a Service - SaaS).
              <br>
              <br>
              Em virtude deste modelo de contratação, o Cliente se enquadra como controlador dos dados pessoais
              coletados e, de modo geral, tratados dos Usuários e Destinatários através do Software, (“Controladora”,
              nos termos da Lei Geral de Proteção de Dados Pessoais – LGPD – Lei nº. 13.709/2018) e que a 3C+ realiza o
              armazenamento das informações na Plataforma na condição de operadora de dados pessoais (“Operadora”, nos
              termos da LGPD).
              <br>
              <br>
              <span
                id="customerRights"
                style="text-decoration: underline;"
                class="tx-pink-300"
              >
                Dos direitos e obrigações gerais do Cliente
              </span>
              Como única e exclusiva Controladora dos dados alvo de tratamento (de Agentes e Destinatários), o Cliente
              deve, sob as penas da lei, garantir: (i) que a finalidade do tratamento de dados é lícita; (ii) que a
              origem dos dados pessoais alvo de tratamento é lícita; e (iii) o devido enquadramento das operações
              realizadas nas hipóteses de tratamento lícito de dados, previstas pela legislação vigente, o que inclui,
              quando necessário, a obtenção do consentimento do titular dos dados pessoais, para tratamento específico
              dos seus dados.
              <br>
              <br>
              Cabe ao Cliente informar aos titulares de dados pessoais sobre as atividades de tratamento de suas
              informações, bem como o fato de que uma parte do tratamento de dados pessoais (armazenamento em nuvem) foi
              transferida à 3C+, através do Software.
              <br>
              <br>
              Ainda, é de responsabilidade do Cliente a prestação de esclarecimentos e respostas às solicitações,
              demandas e exercício de direitos dos titulares de dados pessoais. Caso alguma informação necessária às
              respostas descritas nesta cláusula esteja em posse da 3C+, esta irá cooperar com o Cliente, prestando os
              esclarecimentos cabíveis acerca dos fatos que tiver conhecimento.
              <br>
              <br>

              <span
                id="3CRights"
                style="text-decoration: underline;"
                class="tx-pink-300"
              >
                Dos direitos e obrigações da 3C+
              </span>
              <br>
              A 3C+, por ter expertise na área de sistemas de distribuição de comunicação por voz, possui
              discricionariedade para selecionar e utilizar os meios que considere necessários ou mais adequados à
              execução do contrato firmado entre as partes, especialmente à disponibilização do Software.
              <br>
              <br>
              Todos os dados pessoais tratados em decorrência do negócio jurídico estabelecido entre as partes são
              considerados como confidenciais pela 3C+ e esta instrui todos os seus colaboradores e eventuais
              prestadores de serviços que eventualmente tenham acesso aos dados pessoais sobre o seu caráter
              confidencial.
              <br>
              <br>
              A 3C+ se compromete a cooperar tanto com os Clientes quanto com a Autoridade Nacional de Proteção de Dados
              sempre que houver fiscalizações e a necessidade de entrega de documentos e informações relacionadas às
              atividades de tratamento de dados pessoais decorrentes deste contrato.
              <br>
              <br>
              <span
                id="incidentsCommunication"
                style="text-decoration: underline;"
                class="tx-pink-300"
              >
                Sobre as notificações e resoluções de incidentes
              </span>
              <br>
              Em caso de ocorrência de incidentes de segurança com dados pessoais tratados em virtude deste contrato, a
              3C+ irá informar a situação, no prazo de 02 (dois) dias úteis aos Clientes.
              <br>
              <br>
              Consideram-se incidentes:
              <br>
              <br>
              <div class="ms-5">
                a) Reclamações ou requerimentos formulados pelos titulares dos dados pessoais para exercício de seus
                direitos e liberdades individuais;
                <br>
                b) Investigações ou apreensões de dados pessoais realizadas por órgãos governamentais ou, ainda, uma
                indicação específica que demonstre a possibilidade iminente de uma investigação ou apreensão;
                <br>
                c) Qualquer acesso, alteração, deleção, perda ou tratamento não autorizado ou ilegal aos dados pessoais;
                e
                <br>
                d) Qualquer violação de segurança e/ou confidencialidade que acarrete acesso, alteração, divulgação,
                deleção, perda ou tratamento ilegal ou acidental aos dados pessoais ou, ainda, qualquer indicação de
                possibilidade, futura ou passada, de ocorrência de uma violação.
              </div>
              <br>
              <br>
              Em caso de ocorrência de qualquer uma das violações acima descritas ou, ainda, outras que, porventura, não
              estejam elencadas, a 3C+ irá:
              <br>
              <br>
              <div class="ms-5">
                a) Notificar, o quanto antes, os Clientes sobre o ocorrido; e
                <br>
                b) Cooperar com os Clientes e a Autoridade Nacional de Proteção de Dados.
              </div>
              <br>
              <span
                id="personData"
                style="text-decoration: underline;"
                class="tx-pink-300"
              >
                Encarregado de dados pessoais
              </span>
              <br>
              Em caso de dúvidas, a 3C+ designou um encarregado de dados pessoais que pode ser contatada através do
              seguinte endereço de e-mail: <a href="mailto:privacidade@3cplusnow.com">privacidade@3cplusnow.com</a>.
            </span>
          </div>
          <!-- FORO -->
          <div id="foro">
            <h4 class="text-primary mt-4 ms-3">
              LEGISLAÇÃO APLICÁVEL E FORO
            </h4>
            <hr
              class="navbar-divider"
              style="color: #E60B42 !important; height: 2px !important;"
            >
            <span>
              Estes termos regem a relação da 3C+ com os Clientes que acessam o Software licenciado pela 3C+. A Lei e as
              regulamentações aplicáveis serão sempre as da República Federativa do Brasil e o foro de eleição com
              jurisdição sobre qualquer litígio que eventualmente possa ocorrer relativo a esses Termos de Uso é o da
              comarca da cidade de Guarapuava/PR.
            </span>
          </div>
          <!-- atualização dos termos de Uso -->
          <div id="termsOfServiceUpdate">
            <h2 class="text-primary mt-4 ms-3">
              ATUALIZAÇÃO DOS TERMOS DE USO
            </h2>
            <hr
              class="navbar-divider"
              style="color: #E60B42 !important; height: 2px !important;"
            >
            <span>
              Esses Termos de Uso podem ser alterados a qualquer momento, a critério da 3C+, informando as principais
              alterações a você, por meio de uma nota em destaque no site e no Software.
              <br>
              <br>
              Em caso de alterações significativas ou que reduzam direitos dos Clientes, ao 3C+ enviará previamente um
              e-mail a cada um, indicando a data em que a referida alteração passará a ser vigente.
              <br>
              <br>
              Caso as partes tenham celebrado contrato e estabelecido cláusulas específicas para a contratação estas
              prevalecerão perante ao presente Termos de Uso. A assinatura do contrato é indispensável, considerando que
              a ausência da mesma poderá acarretar no bloqueio de seus acessos, haja vista que o referido Instrumento
              visa garantir a segurança jurídica para ambas as Partes.
              <br>
              <br>
              Se qualquer das disposições destes Termos de Uso for considerada nula ou anulável, o trecho referido será
              desconsiderado do instrumento, permanecendo válidas as demais, em pleno vigor e efeito.
              <br>
              <br>
              Se você leu, compreendeu e está de acordo com os nossos Termos de Uso, pode utilizar nosso Software à
              vontade.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.text-primary {
  color: #4545fd !important;
}
.star li::marker {
  content: "★";
}
.dash li::marker {
  content: "-";
}
.circle li::marker {
  content: "○";
}
li {
  padding-left: 15px !important;
}
</style>
<script>
export default {
  name: "TermsOfUse",
  data() {
    return {
      active: "termsOfService"
    };
  },
  computed: {
    visibleNavigation() {
      if (this.$route.path === "/terms") return false;
      return true;
    }
  }
};
</script>
