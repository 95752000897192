import axios from "axios";
import router from "@/router";
import { AgentStatus, CallStatus } from "@/utils";

export default {
  startManualCall({ commit }, payload) {
    commit("startLoading");
    commit("clearErrors");
    commit("startLoadingFlag", "startManualCall");

    if (payload.schedule_id) {
      commit("setCallHistoryNumberCalling", payload.schedule_id);
    }

    commit("setCall", {
      phone: payload.phone,
      status: CallStatus.DIALING
    });

    return new Promise((resolve, reject) => {
      axios
        .post("/agent/manual_call/dial", payload)
        .then(() => {
          resolve();
        })
        .catch(response => {
          commit("stopLoading");
          commit("stopLoadingFlag", "startManualCall");
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("setCallHistoryNumberCalling", "");
        });
    });
  },

  async startManualCallAfterAgentEnteredManual({ dispatch, commit, getters }, payload) {
    const id = payload?._id;
    dispatch("system/enterManual", null, { root: true })
    commit("setAgentCallStatus", "");
    commit("startLoadingFlag", "startManualCallAfterAgentEnteredManual");
    commit("setCallHistoryNumberCalling", id);

    return new Promise((resolve, reject) => {
      const unsubscribe = this.watch(
        () => getters.getAgentCallStatus,
        async (newStatus) => {
          if (newStatus === "agent-login-failed") {
            reject()
          } else if (newStatus === "agent-entered-manual") {
            unsubscribe()
            try {
              await dispatch("startManualCall", payload)
              resolve()
            } catch (error) {
              reject(error)
            }
          }
          commit("stopLoadingFlag", "startManualCallAfterAgentEnteredManual");
          commit("setCallHistoryNumberCalling", "");
          unsubscribe()
        },
      )
    })
  },

  getCallHistory({ commit }, payload) {
    commit("startLoadingFlag", "getCallHistory");

    return new Promise((resolve, reject) => {
      axios.get("/agent/last_calls", { params: payload })
        .then((response) => {
          commit("setCallHistory", response.data.data?.calls)
          resolve();
        }).catch((error) => {
        commit("system/setErrorMessage", error.response.data, { root: true })
        reject();
      }).finally(() => {
        commit("stopLoadingFlag", "getCallHistory");
      })
    });
  },

  startManualCallACW({ commit }, payload) {
    commit("startLoading")
    commit("clearErrors")
    commit("setLoadingContext", "manual-call-acw");

    return new Promise((resolve, reject) => {

      axios.post("/agent/manual_call_acw/dial", { phone: payload.phone })
        .then(() => {
          resolve();
        })
        .catch((response) => {
          reject();
          commit("system/setErrorMessage", response.response.data, { root: true });
        })
        .finally(() => {
          commit("stopLoading");
        })
    });
  },

  async startWhatsappCall({ rootState, state, dispatch, commit }, payload) {
    const number = payload;
    const campaign = rootState.agent.agent.campaign;
    const isJsSipRegistered = rootState.system.jsSipStatus == "registered"
    const mode = rootState.system.mode;

    commit("startLoadingFlag", "whatsappCall");
    commit("setWhatsappCallCurrentNumber", number);

    async function goBackToInitialState() {
      commit("setWhatsappCallCurrentNumber", "");
      if (!campaign) {
        await dispatch("agent/agent/logout", null, { root: true });
        if (!isJsSipRegistered) commit("system/setJsSIPActive", false, { root: true })
      } else if (mode !== "manual") {
        await dispatch("system/exitManual", null, { root: true });
      }
    }

    const startManualCallAfterAgentEnteredManual = () => {
      commit("setAgentCallStatus", "");

      return new Promise((resolve, reject) => {
        const unsubscribe = this.watch(
          () => state.agentCallStatus,
          async (newstatus) => {
            if (newstatus === "agent-login-failed") {
              reject()
            } else if (newstatus === "agent-entered-manual") {
              unsubscribe()
              try {
                await dispatch("startManualCall", {
                  phone: number,
                })
                resolve()
              } catch (error) {
                reject(error)
              }
            }
            unsubscribe()
          },
        )
      })
    }

    const connectWithJsSip = () => {
      commit("system/setJsSIPActive", true, { root: true });
      return new Promise((resolve, reject) => {
        const unsubscribe = this.watch(
          () => [rootState.system.jsSipStatus, rootState.system.userMediaPermission],
          ([jsSipStatus, userMediaPermission]) => {
            if (userMediaPermission == "not-allowed") {
              commit("system/setInfoMessage", "info_message.allow_mic", { root: true });
              reject();
              unsubscribe();
            } else if (jsSipStatus == "registered") {
              if (userMediaPermission == "allowed") {
                resolve();
                unsubscribe();
              } else {
                commit("system/setInfoMessage", "info_message.allow_mic", { root: true });
                reject();
                unsubscribe();
              }
            }
          },
        )
      })
    }

    try {
      if (!campaign) {
        if (!rootState.system.jsSIPActive) {
          if (rootState.system.userMediaPermission == "not-allowed") {
            commit("system/setInfoMessage", "info_message.allow_mic", { root: true });
            throw ""
          }
          await connectWithJsSip().catch(() => {
            commit("system/setJsSIPActive", false, { root: true });
            throw ""
          })
        }

        await dispatch("agent/campaign/fetchCampaigns", null, { root: true });
        const campaignToLogin = rootState.agent.campaign.campaigns.reduce((prev, cur) => {
          return prev.id > cur.id ? prev : cur
        })

        dispatch("agent/agent/login", {
          campaign: campaignToLogin?.id,
          mode: "manual",
        }, { root: true });

        await startManualCallAfterAgentEnteredManual()
      } else if (mode === "manual") {
        await dispatch("startManualCall", {
          phone: number,
        })
      } else {
        dispatch("system/enterManual", null, { root: true })
        await startManualCallAfterAgentEnteredManual()
      }
    } catch (_) {
      commit("stopLoadingFlag", "whatsappCall");
      goBackToInitialState();
      throw ""
    }

    return new Promise((resolve) => {
      const unsubscribe = this.watch(
        () => rootState.system.status,
        async (newStatus) => {
          if (newStatus === AgentStatus.ON_CALL) {
            commit("setAgentCallStatus", "")
            await new Promise((resolve) => {
              const unsubscribe = this.watch(
                () => state.agentCallStatus,
                async (agentCallStatus) => {
                  const isCallAnswered = (agentCallStatus === "manual-call-was-answered")
                  const isCallInIdle = (agentCallStatus === "agent-is-idle")

                  if (isCallAnswered || isCallInIdle) {
                    resolve();
                  }
                  unsubscribe();
                },
              )
            })

            // ready to redirect to dialer screen
            commit("stopLoadingFlag", "whatsappCall")
            resolve();
          } else if (newStatus === AgentStatus.IDLE) {
            unsubscribe();
            await goBackToInitialState();
            router.push("/whatsapp");
          }
        },
      )
    })
  },

  hangup({ commit }, payload) {
    commit("startLoading");
    commit("clearErrors")
    commit("setLoadingContext", payload.options.ivr_after_call_id ? "ura" : "hangup");
    commit("startLoadingFlag", "hangup");
    return new Promise((resolve, reject) => {
      axios.post("/agent/call/" + payload.id + "/hangup", payload.options)
        .then(() => {
          resolve();
        })
        .catch((response) => {
          commit("system/setErrorMessage", response.response?.data, { root: true });
          commit("stopLoading");
          commit("stopLoadingFlag", "hangup");
          reject();
        });
    });
  },

  updateMailing({ commit }, payload) {
    commit("startLoading");
    return new Promise((resolve, reject) => {
      axios.put("/agents/mailing_data/" + payload.id, payload.fields)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  loadCampaignMetricsChart({ commit }, payload) {
    commit("startLoadingFlag", "loadCampaignMetricsChart");

    return new Promise((resolve, reject) => {
      axios.get("/campaign/metrics/graphic", { params: payload })
        .then((response) => {
          commit("setCampaignMetricsCharts", response.data.data);
          resolve();
        })
        .catch((error) => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "loadCampaignMetricsChart");
        });
    });
  },

  clearDashboard({ commit }) {
    commit("setCampaignMetricsCharts", []);
    commit("setCallHistory", []);
  },
}
