export default {
  getLoadingFlags: state => state.loadingFlags,
  getCallPABX: state => state.callPABX,
  getRingGroupUsers: state => state.ringGroupUsers,
  getConsultedAgentId: state => state.consultedAgentId,
  getInternalCallAgent: state => state.internalCallAgent,
  getShowToast: state => state.showToast,
  getCallStatus: state => {
    if (state.callPABX) {
      return Number(state.callPABX.status);
    }

    return null;
  },
  isRingGroupPermissionEnabled: (state, getters, rootState, rootGetters) => {
    if (rootGetters["auth/getUser"]) {
      return !!rootGetters["auth/getUser"].permissions.find(
        permission => permission.name === "ring.group.access"
      );
    }

    return false;
  },
  isRingGroupActive: (state, getters, rootState, rootGetters) => {
    if (rootGetters["auth/getUser"]) {
      return rootGetters["auth/getUser"].is_ring_group_active;
    }

    return false;
  },
  getRingGroups: (state, getters, rootState, rootGetters) => {
    if (rootGetters["auth/getUser"]) {
      return rootGetters["auth/getUser"].ring_groups;
    }

    return [];
  }
};
