<template>
  <tr 
    v-for="line in lines"
    :key="line"
    :style="{
      height: height + ' !important',
    }"
  >
    <td
      v-for="(column, index) in columns"
      :key="index"
      :class="{
        'text-center': textCenter,
      }"
    >
      <div
        class="spinner-grow loading-table-color"
        role="status"
        style="height: 16px !important; border-radius: 0 !important; background"
        :style="{
          width: listWidth[index] + 'px !important',
        }"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: "LoadingTableComponent",
  props: {
    lines: {
      type: Number,
      default: 15,
    },
    columns: {
      type: Number,
      default: 0
    },
    textCenter: {
      type: Boolean,
      default: false,
    },
    listWidth: {
      type: Array,
      default: () => [],
    },
    height: {
      type: String,
      default: '',
    },
  }
};
</script>

<style scoped>
.loading-table-color {
  background-color: #D9D9D9 !important;
}
</style>