<template>
  <div class="mb-3">
    <label
      class="form-label required"
      for="selectOpportunity"
    >
      {{ $t('opportunity') }}
    </label>
    <vue-multiselect
      ref="multiselect"
      v-model="selectedOpportunity"
      :class="{ 'border-red': showError }"
      :clear-on-select="false"
      :disabled="disabled"
      :hide-selected="true"
      :internal-search="false"
      :limit="3"
      :loading="isLoading"
      :max-height="600"
      :options="allOpportunities"
      :options-limit="300"
      :placeholder="$t('search_opportunity')"
      :searchable="true"
      :show-no-results="false"
      label="name"
      name="selectOpportunity"
      track-by="id"
      @select="updateValue"
      @search-change="search"
      @open="onOpen"
    />
    <small
      v-if="showError"
      class="text-danger"
    >
      {{ $t('opportunity_field_required') }}
    </small>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import _ from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {checkScrollForPagination} from "@/utils";

export default {
  name: "SelectOpportunity",
  emits: ["update:modelValue"],
  components: { VueMultiselect },

  props: {
    modelValue: { type: Object, default: null },
    disabled: { type: Boolean, default: false },
    showError: { type: Boolean, default: false }
  },

  data() {
    return {
      selectedOpportunity: this.modelValue,
      currentPage: 1,
      totalPages: null,
      allOpportunities: []
    }
  },

  computed: {
    ...mapGetters("crm/activities", {
      opportunities: "getOpportunities",
      loading: "getLoadingFlags",
      pagination: "getOpportunitiesPagination",
    }),
    ...mapGetters("crm/funnels", {
      currentFunnel: "getCurrentFunnel",
    }),
  },
  mounted() {
    this.getOpportunityList();
  },

  watch: {
    modelValue: {
      handler(newVal) {
        this.selectedOpportunity = newVal;
      },
      deep: true,
      immediate: true
    },
    pagination(newPagination) {
      this.totalPages = newPagination.pagination.last_page;
    },
    opportunities:{
      handler(newVal) {
        if(newVal){
          this.allOpportunities = this.allOpportunities.concat(
              Object.values(this.opportunities).reduce((acc, opportunityArray) => acc.concat(opportunityArray), [])
          );

        }
      }
    }
  },

  methods: {
    ...mapMutations('crm/activities', ['setOpportunityFilters']),
    ...mapActions('crm/activities', ['fetchOpportunities']),

    search: _.debounce(function (query) {
      this.allOpportunities = []
      this.setOpportunityFilters({
        filter: "search",
        value: query.length ? query : null
      });
      this.resetPagination();
      this.getOpportunityList();
    }, 300),

    getOpportunityList() {
      if (!this.loading.fetchOpportunities) {
        this.fetchOpportunities({ page: this.currentPage, funnelId: this.currentFunnel.id });
      }
    },

    updateValue(value) {
      this.$emit('update:modelValue', value);
    },

    resetPagination() {
      this.currentPage = 1;
      this.totalPages = null;
      this.getOpportunityList();
    },

    onOpen() {
      this.addScrollEventListener();
    },

    addScrollEventListener() {
      this.$nextTick(() => {
        const dropdownMenu = this.$refs.multiselect.$el.querySelector('.multiselect__content-wrapper');

        if (dropdownMenu) {
          dropdownMenu.addEventListener('scroll', this.onScroll);
        }
      });
    },

    removeScrollEventListener() {
      const dropdownMenu = this.$refs.multiselect.$el.querySelector('.multiselect__content-wrapper');

      if (dropdownMenu) {
        dropdownMenu.removeEventListener('scroll', this.onScroll);
      }
    },

    onScroll(event) {
      let check=checkScrollForPagination(event)

      if(check && this.currentPage < this.totalPages){
        this.currentPage++;
        this.getOpportunityList();
      }
    }
  },

  beforeUnmount() {
    this.removeScrollEventListener();
  }
}
</script>

<style lang="scss" scoped>
.border-red {
  border: 1px solid red;
  border-radius: 10px;
}
</style>