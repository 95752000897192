export default {
    startLoading: state => state.loading = true,
    stopLoading: state => {
        state.loading = false
        state.loadingContext = ''
    },
    setLoadingContext: (state,loadingContext) => state.loadingContext = loadingContext,
    setList: (state, list) => state.list = list,
    setErrorMessage: (state, errorMesage) => state.errorMessage = errorMesage,
    setErrors: (state, errors) => state.errors = errors,
    clearErrors: (state) => {
        state.errors = {}
        state.errorMessage = ""
    },
    setIntervalActive: (state, IntervalActive) => state.IntervalActive = IntervalActive,
    setScheduledWorkBreak: (state, scheduledWorkBreak) => state.scheduledWorkBreak = scheduledWorkBreak,
    setExpired: (state, expired) => state.expired = expired,

    startLoadingFlag: (state, flag) => state.loadingFlags[flag] = true,
    stopLoadingFlag: (state, flag) => state.loadingFlags[flag] = false,
    setWorkBreakIntervals: (state, workBreakIntervals) => state.workBreakIntervals = workBreakIntervals,
    setReturnRequested: (state, returnRequested) => state.returnRequested = returnRequested
}
