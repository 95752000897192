<template>
  <div class="row">
    <div
      v-for="(metric, index) in metricsData"
      :key="index"
      class="col-6 col-sm-6 col-md-6 col-lg-3"
    >
      <div class="card">
        <div
          class="card-body d-flex flex-column"
          style="gap: 8px"
        >
          <div
            class="d-flex align-items-center justify-content-center tx-blue-300 card-metric"
          >
            <span :class="metric.icon" />
          </div>
          <h6
            class="tx-text-gray m-0 overflow-ellipsis fs-5"
          >
            {{ metric.title }}
          </h6>
          <span
            class="h2 m-0 fs-3"
          >
            {{ metric.value || 0 }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ChatbotMetrics",

  computed: {
    ...mapGetters("chatbot/chatbots", {
      metrics: "getMetrics",
    }),

    metricsData() {
      return {
        inProgress: {
          icon: "fa-regular fa-robot",
          title: this.$t('in_progress'),
          value: this.metrics.in_progress,
        },
        deliveryToAgents: {
          icon: "fa-regular fa-truck",
          title: this.$t('delivered_to_operation'),
          value: this.metrics.delivery_to_agents,
        },
        averageConversationTime: {
          icon: "fa-regular fa-clock",
          title: this.$t('average_time_in_chat'),
          value: this.metrics.average_conversation_time,
        },
        finished: {
          icon: "fa-regular fa-circle-check",
          title: this.$t('ended'),
          value: this.metrics.finished,
        },
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.card-metric {
  height: 40px;
  width: 40px;
  padding: 9px;
  border-radius: 10px;
  background-color: $color-gray-blue-300;
}
</style>
