<template>
  <div class="d-flex align-items-center justify-content-center rounded-circle message-loading">
    <div class="spinner-border spinner-border-sm tx-blue-300">
      <span class="visually-hidden" />
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageLoading"
}
</script>


<style scoped lang="scss">
.message-loading {
  background-color: #FFFFFF;
  height: 40px;
  width: 40px;
  box-shadow: 0 4px 18px 0 rgba(34, 54, 77, 0.12);
}
</style>