<template>
  <div class="container-fluid">
    <div
      v-if="register"
      class="row"
    >
      <header-component
        :title="register.name"
        redirect-link="/manager/ivr-after-call"
      />
      <flash-message />

      <div class="row">
        <div class="col-lg-12">
          <div class="form-group 0">
            <label class="form-label required">{{ $t('capitalizes.name') }}</label>
            <input
              type="text"
              :class="{
                'form-control': true,
                'is-invalid': errors.editIvrAfterCall.name,
              }"
              v-model="fields.name"
              :placeholder="$t('capitalizes.name') "
            >
            <div class="invalid-feedback">
              {{
                errors.editIvrAfterCall.name &&
                  errors.editIvrAfterCall.name[0]
              }}
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label class="form-label required">{{ $t('capitalizes.starting_audio') }}</label>
            <span class="form-text">{{ $t('upload_a_mp3_file') }}</span>
            <div
              :class="{
                'card mb-0': true,
                'is-invalid': errors.editIvrAfterCall.audio_start
              }"
            >
              <div class="card-body">
                <div class="text-center my-1 text-muted">
                  <small> {{ register?.original_audio_name_start }}</small>
                  <audio
                    class="w-100 mb-4"
                    controls
                  >
                    <source
                      :src="getFullAudioPath('audio_start')"
                      type="audio/mpeg"
                    >
                  </audio>
                </div>
                <file-upload
                  filter="audio/mp3,audio/mpeg"
                  icon="fe fe-headphones"
                  @select-file="selectFileStart"
                  @removed-file="selectFileStart"
                />
              </div>
            </div>
            <div class="invalid-feedback">
              {{
                errors.editIvrAfterCall.audio_start &&
                  errors.editIvrAfterCall.audio_start[0]
              }}
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label class="form-label required">{{ $t('capitalizes.audio_for_no_key_presses') }}</label>
            <span class="form-text">{{ $t('upload_a_mp3_file') }}</span>
            <div
              :class="{
                'card mb-0': true,
                'is-invalid': errors.editIvrAfterCall.audio_null_key
              }"
            >
              <div class="card-body">
                <div class="text-center my-1 text-muted">
                  <small> {{ register?.original_audio_name_null_key }}</small>
                  <audio
                    class="w-100 mb-4"
                    controls
                  >
                    <source
                      :src="getFullAudioPath('audio_null_key')"
                      type="audio/mpeg"
                    >
                  </audio>
                </div>
                <file-upload
                  filter="audio/mp3,audio/mpeg"
                  icon="fe fe-headphones"
                  @select-file="selectFileNullKey"
                  @removed-file="selectFileNullKey"
                />
              </div>
            </div>
            <div class="invalid-feedback">
              {{
                errors.editIvrAfterCall.audio_null_key &&
                  errors.editIvrAfterCall.audio_null_key[0]
              }}
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label class="form-label required">{{ $t('capitalizes.audio_for_invalid_key') }}</label>
            <span class="form-text">{{ $t('upload_a_mp3_file') }}</span>
            <div
              :class="{
                'card mb-0': true,
                'is-invalid': errors.editIvrAfterCall.audio_invalid_key
              }"
            >
              <div class="card-body">
                <div class="text-center my-1 text-muted">
                  <small> {{ register?.original_audio_name_invalid_key }}</small>
                  <audio
                    class="w-100 mb-4"
                    controls
                  >
                    <source
                      :src="getFullAudioPath('audio_invalid_key')"
                      type="audio/mpeg"
                    >
                  </audio>
                </div>
                <file-upload
                  filter="audio/mp3,audio/mpeg"
                  icon="fe fe-headphones"
                  @select-file="selectFileInvalidKey"
                  @removed-file="selectFileInvalidKey"
                />
              </div>
            </div>
            <div class="invalid-feedback">
              {{
                errors.editIvrAfterCall.audio_invalid_key &&
                  errors.editIvrAfterCall.audio_invalid_key[0]
              }}
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label class="form-label required">{{ $t('capitalizes.final_audio') }}</label>
            <span class="form-text">{{ $t('upload_a_mp3_file') }}</span>
            <div
              :class="{
                'card mb-0': true,
                'is-invalid': errors.editIvrAfterCall.audio_end
              }"
            >
              <div class="card-body">
                <div class="text-center my-1 text-muted">
                  <small> {{ register?.original_audio_name_end }}</small>
                  <audio
                    class="w-100 mb-4"
                    controls
                  >
                    <source
                      :src="getFullAudioPath('audio_end')"
                      type="audio/mpeg"
                    >
                  </audio>
                </div>
                <file-upload
                  filter="audio/mp3,audio/mpeg"
                  icon="fe fe-headphones"
                  @select-file="selectFileEnd"
                  @removed-file="selectFileEnd"
                />
              </div>
            </div>
            <div class="invalid-feedback">
              {{
                errors.editIvrAfterCall.audio_end &&
                  errors.editIvrAfterCall.audio_end[0]
              }}
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <label class="form-label required">{{ $t('waiting_time') }}</label>
          <span class="text-muted ">{{ ` ${$t('in_seconds')}` }}</span>
          <div class="form-group 0">
            <input-text-range
              v-model="fields.max_wait_time"
              :max="10"
              :min="5"
              :class="{
                'is-invalid': errors.editIvrAfterCall.max_wait_time,
              }"
            />
            <div class="invalid-feedback">
              {{
                errors.editIvrAfterCall.max_wait_time &&
                  errors.editIvrAfterCall.max_wait_time[0]
              }}
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <label class="form-label required">{{ $t('maximum_number_of_attempts') }}</label>
          <div class="form-group 0">
            <input-text-range
              v-model="fields.attempt_number"
              :max="5"
              :min="1"
              :class="{
                'is-invalid': errors.editIvrAfterCall.attempt_number,
              }"
            />
            <div class="invalid-feedback">
              {{
                errors.editIvrAfterCall.attempt_number &&
                  errors.editIvrAfterCall.attempt_number[0]
              }}
            </div>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
              <label class="form-label">{{ $t('criteria') }}</label>
              <button
                class="btn btn-primary btn-sm px-6"
                @click="openNewCriteriaModal()"
              >
                {{ $t('add.criteria') }}
              </button>
              <new-criteria ref="newCriteria" />
            </div>
            <div class="card-body">
              <ul
                v-for="(item, i) in register.criteria"
                :key="i"
                class="list-group list-group-flush"
              >
                <li
                  class="list-group-item d-flex align-items-center justify-content-between px-0 cursor-pointer"
                  @click="editNewCriteriaModal(item)"
                >
                  <label
                    class="form-label"
                  >
                    {{ item.name }}
                  </label>
                  <span
                    class="fe fe-chevron-right"
                    style="color:#2C7BE5"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-lg-12">
          <app-button
            :blocked="loadingFlags.editIvrAfterCall"
            :loading="loadingFlags.editIvrAfterCall"
            class="w-100"
            color="btn-primary"
            @click="submit()"
          >
            {{ $t('capitalizes.save') }}
          </app-button>
        </div>
        <div
          class="text-center mt-4"
        >
          <span
            class="btn p-0 px-4 text-danger"
            @click="!loadingFlags.removeIvrAfterCall && _removeIvrAfterCall()"
          >
            <span class="fe fe-trash-2 me-1" />
            {{ $t('exclude.post_service_IVR') }}
          </span>
        </div>
      </div>
    </div>
    <main-loading v-else-if="loadingFlags.loadIvrAfterCallById" />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import MainLoading from "@/components/main-loading.vue";
import FileUpload from "@components/file-uploader";
import FlashMessage from "@/components/flash-message";
import InputTextRange from "@components/input-text-range";
import AppButton from "@components/app-button";
import NewCriteria from "./components/new-criteria.vue";
import HeaderComponent from "@/components/header-component.vue";

export default {
  data() {
    return {
      fields: {
        name: "",
        max_wait_time: 5,
        attempt_number: 1,
        audio_start: null,
        audio_null_key: null,
        audio_invalid_key: null,
        audio_end: null,
      },
      addedNewAudio_start: false,
      addedNewAudio_null_key: false,
      addedNewAudio_invalid_key: false,
      addedNewAudio_end: false,

      loaded: false,
    }
  },

  components: {
    FileUpload,
    FlashMessage,
    InputTextRange,
    AppButton,
    NewCriteria,
    MainLoading,
    HeaderComponent
  },
  mounted() {
    this.clearErrors();
    this.loadIvrAfterCallById({id: this.$route.params.id})

  },
  computed: {
    ...mapGetters("manager/ivr-after-call", {
      register: "getRegister",
      errors: "getErrors",
      loadingFlags: "getLoadingFlags"
    }),
    ...mapGetters("auth", {
      user: "getUser",
    }),
  },

  watch: {
    register(register) {
      if (register) {
        this.fields.name = register.name;
        this.fields.max_wait_time = register.max_wait_time;
        this.fields.attempt_number = register.attempt_number;
        this.fields.audio_start = register.audio_start;
        this.fields.audio_null_key = register.audio_null_key;
        this.fields.audio_invalid_key = register.audio_invalid_key;
        this.fields.audio_end = register.audio_end;
      }
    },
  },

  methods: {
    ...mapActions("manager/ivr-after-call", [
      "createIvrAfterCall",
      "loadIvrAfterCallById",
      "editIvrAfterCall",
      "clearErrors",
      "removeIvrAfterCall"
    ]),
    ...mapActions("system", ["showConfirmationMessage"]),

    selectFileStart(file) {
      this.addedNewAudio_start = !this.addedNewAudio_start;
      this.fields.audio_start = file
    },
    selectFileNullKey(file) {
      this.addedNewAudio_null_key = !this.addedNewAudio_null_key;
      this.fields.audio_null_key = file
    },
    selectFileInvalidKey(file) {
      this.addedNewAudio_invalid_key = !this.addedNewAudio_invalid_key;
      this.fields.audio_invalid_key = file
    },
    selectFileEnd(file) {
      this.addedNewAudio_end = !this.addedNewAudio_end;
      this.fields.audio_end = file
    },

    getFullAudioPath(audio) {
      if (audio === 'audio_start') {
        return (
          process.env.VUE_APP_BA12AS.replace("/api/v1", "") +
          this.register.audio_start +
          "&api_token=" +
          this.user.api_token
        );
      }
      if (audio === 'audio_null_key') {
        return (
          process.env.VUE_APP_BA12AS.replace("/api/v1", "") +
          this.register.audio_null_key +
          "&api_token=" +
          this.user.api_token
        );
      }
      if (audio === 'audio_invalid_key') {
        return (
          process.env.VUE_APP_BA12AS.replace("/api/v1", "") +
          this.register.audio_invalid_key +
          "&api_token=" +
          this.user.api_token
        );
      }
      if (audio === 'audio_end') {
        return (
          process.env.VUE_APP_BA12AS.replace("/api/v1", "") +
          this.register.audio_end +
          "&api_token=" +
          this.user.api_token
        );
      }
    },

    openNewCriteriaModal() {
      this.$refs.newCriteria.new();
    },
    editNewCriteriaModal(item) {
      this.$refs.newCriteria.edit(item);
    },

    clear() {
      this.fields.name = "";
      this.fields.max_wait_time = 0;
      this.fields.attempt_number = 0;
      this.fields.audio_start = null;
      this.fields.audio_null_key = null;
      this.fields.audio_invalid_key = null;
      this.fields.audio_end = null;
    },

    submit() {
      let formData = new FormData();
      formData.append('name', this.fields.name);
      formData.append('max_wait_time', this.fields.max_wait_time);
      formData.append('attempt_number', this.fields.attempt_number);
      if (this.addedNewAudio_start === true) formData.append('audio_start', this.fields.audio_start)
      if (this.addedNewAudio_null_key === true) formData.append('audio_null_key', this.fields.audio_null_key);
      if (this.addedNewAudio_invalid_key === true) formData.append('audio_invalid_key', this.fields.audio_invalid_key);
      if (this.addedNewAudio_end === true) formData.append('audio_end', this.fields.audio_end);
      formData.append("_method", "PUT")
      this.editIvrAfterCall({id: this.$route.params.id, formData})
      .then(() => {
        this.$router.push(`/manager/ivr-after-call`);
      })
    },

    async _removeIvrAfterCall() {
      let response = await this.showConfirmationMessage({
        title: this.$t('questions.delete_IVR_after_service'),
        type: "badWarning",
        text: this.$t('questions_info.delete_IVR_after_service')
      });

      if (response.isConfirmed) {
        await this.removeIvrAfterCall({id: this.$route.params.id});
        this.$router.go(-1);
      }
    }
  },
}
</script>
