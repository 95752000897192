export default {

  clearContacts: state => state.contacts = {},

  setContact: (state, contact) => state.contact = contact,

  addContacts: (state, contacts) => {
    //TODO: general imporoves; ignore accents
    let backup = {}
    if (state.pagination.current_page > 1)
      backup = state.contacts;
    for (let contact of contacts) {
      if (contact.name.match(/[a-z]/i)) {
        let char = contact.name.toUpperCase().charAt(0);
        if (!backup[char])
          backup[char] = []
        backup[char].push(contact)
      } else {
        if (!backup['#'])
          backup['#'] = []
        backup['#'].push(contact);
      }
      state.contacts = backup
    }
  },

  clearErrors: state => state.errors = {},
  setPagination: (state, pagination) => state.pagination = pagination,
  setCurrentPage: (state, page) => state.pagination.current_page = page,
  setFilters: (state, filters) => state.filters = filters,
  startLoading: (state, flag) => state.loadingFlags[flag] = true,
  stopLoading: (state, flag) => state.loadingFlags[flag] = false,
  setErrors: (state, errors) => state.errors = errors,
  setSelectedContact: (state, selectedContact) => state.selectedContact = selectedContact,
  setContactInstances: (state, contactInstances) => state.contactInstances = contactInstances,
}
