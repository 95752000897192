export default {
  isDraggingColumn: null,
  lockDragCard: false,

  // ------ STEPS ------ //
  stepPagination: {
    current_page: 1,
    last_page: 1,
    per_page: 15,
    total: 1
  },
  loadingFlags: {
    findSteps: false,
    createStep: false,
  },
  steps: []
}
