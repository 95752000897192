import AdminCompanies from "@admin/store/companies";
import Tags from "@admin/store/tags";
import Partners from "@admin/store/partners";
import Coupons from "@admin/store/coupons";
import AdminUsers from "@admin/store/users";
import AdminSms from "@admin/store/sms";
import Pix from "@admin/store/pix";
import AdminTelecom from "@admin/store/telecom";
import AdminSystem from "@admin/store/system";

export default {
  namespaced: true,
  modules: {
    companies: AdminCompanies,
    partners: Partners,
    tags: Tags,
    coupons: Coupons,
    sms: AdminSms,
    users: AdminUsers,
    pix: Pix,
    telecom: AdminTelecom,
    system: AdminSystem,
  },

  getters: {
    getRestrictedAdminLevels: () => [0,3]
  }
}
