<template>
  <header-component
    pretitle="INSIGHTS IA"
    redirect-link="/manager/insights-ai"
    :title="$t('settings_omni')"
  />

  <ul class="nav nav-tabs">
    <li
      class="nav-item"
      v-for="tab in tabs"
      :key="tab.name"
    >
      <button
        ref="tab-insights"
        :id="'tab-insights-' + tab.name"
        type="button"
        role="tab"
        data-bs-toggle="tab"
        :aria-controls="tab.name"
        :data-bs-target="'#' + tab.name"
        :data-test="'tab-' + tab.name"
        class="nav-link"
        @click="setCurrentTab(tab.name)"
        :class="['nav-link', { active: tab.name === currentTab }]"
        :disabled="tab.name === 'tab-dialer' && !aiCallSettingsEnabled"
      >
        {{ tab.label }}
      </button>
    </li>
  </ul>
  <div class="tab-content">
    <tab-dialer />
    <tab-omni />
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import HeaderComponent from "@admin/pages/3c-partners/partners/components/header-component.vue";
import TabOmni from "./tabs/tab-omni.vue";
import TabDialer from "./tabs/tab-dialer.vue";

export default {
  name: "SettingsInsights",
  components: {TabDialer, TabOmni, HeaderComponent},
  data() {
    return {
      tabs : [
        {
          name: 'tab-dialer',
          label: this.$t('dialer_capitalize')
        },
        {
          name: 'tab-omni',
          label: 'Omnichannel'
        }
      ]
    };
  },

  mounted() {
    let currentTab = this.currentTab;

    if (currentTab === 'tab-dialer' && !this.aiCallSettingsEnabled) {
      this.setCurrentTab('tab-omni')
      currentTab = 'tab-omni'
    }

    const tabElement = document.getElementById(currentTab);
    if (tabElement) {
      tabElement.classList.add('show', 'active');
    }
  },

  computed: {
    ...mapGetters('manager/insights', {
      currentTab: "getCurrentTab"
    }),

    ...mapGetters("auth", {
      auth: "getUser"
    }),

    aiCallSettingsEnabled() {
      const data = this.auth;
      const audio = data.company?.enabled_recordings_in_stereo;
      const aiEvolution = data.company?.ai_call_evaluation;
      return audio && aiEvolution
    },
  },

  methods: {
    ...mapMutations("manager/insights", {
      setCurrentTab: "setCurrentTab"
    }),
  }
};
</script>
