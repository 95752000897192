<template>
  <div class="main-content pb-6">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10">
          <header-component
            :title="$t('add_schedule')"
            :pretitle="$t('office_hours_capitalize')"
            redirect-link="/manager/office-hours"
          />

          <div class="row">
            <div class="col-lg-12">
              <div class="form-group 0">
                <label class="form-label required">{{ $t("name_data") }}</label>
                <input
                  type="email"
                  :class="{
                    'form-control': true,
                    'is-invalid': errors.name
                  }"
                  v-model="fields.name"
                  :placeholder="$t('name_data')"
                >

                <div class="invalid-feedback">
                  {{ errors.name && errors.name[0] }}
                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <div class="">
                <label
                  for="name"
                  class="form-label required"
                >{{ $t("operating_hours") }}</label>
                <div class="form-group">
                  <div
                    class="card"
                    :class="{
                      'card mb-0': true,
                      'is-invalid': errors.day_week
                    }"
                  >
                    <div class="card-body">
                      <div
                        class="row"
                        v-for="(item, i) of this.fields.week_days"
                        :key="i"
                      >
                        <div
                          :class="{
                            'col-md-2 my-2': true,
                            'text-muted': !item.active
                          }"
                          style="padding-top: 8px"
                        >
                          {{ item.title }}
                        </div>

                        <div class="col-md-2 my-2">
                          <div class="form-check form-switch">
                            <input
                              class="form-check-input"
                              style="margin-top: 8px"
                              type="checkbox"
                              id="flexSwitchCheckDefault"
                              v-model="item.active"
                            >
                          </div>
                        </div>

                        <div
                          class="col-lg-8 my-2 b1"
                          v-if="item.active"
                        >
                          <div class="d-flex">
                            <input
                              type="text"
                              class="form-control text-center px-0 mr-2"
                              style="display: inline-block"
                              :value="minutesToHoursFormat(item.start_time)"
                              @change="updateMin($event, item)"
                            >
                            <div class="col-md-8 col-sm-8 text-center b2 px-4">
                              <double-range-select
                                :min="0"
                                :max="1439"
                                :interval="10"
                                class="w-100"
                                style="margin-top: 17px !important"
                                v-model:startInterval="item.start_time"
                                v-model:endInterval="item.end_time"
                              />
                            </div>
                            <input
                              type="text"
                              class="form-control text-center px-0"
                              :value="minutesToHoursFormat(item.end_time)"
                              @change="updateMax($event, item)"
                            >
                          </div>
                        </div>

                        <div
                          class="col-md-3 my-2"
                          style="line-height: 40.5px"
                          v-else
                        >
                          {{ $t("no_service") }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="invalid-feedback">
                    {{ errors.day_week && errors.day_week[0] }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <app-button
                class="btn w-100 btn-primary mb-4"
                type="button"
                :loading="loading"
                @click="submit()"
              >
                {{ $t("save_general") }}
              </app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import appButton from "@/components/app-button";
import doubleRangeSelect from "@/components/double-range-select";
import moment from "moment";
import HeaderComponent from "@/components/header-component.vue";

export default {
  data() {
    return {
      fields: {
        name: "",
        week_days: [
          {
            title: this.$t("sunday"),
            active: false,
            start_time: 480,
            end_time: 1080
          },
          {
            title: this.$t("monday"),
            active: true,
            start_time: 480,
            end_time: 1080
          },
          {
            title: this.$t("tuesday"),
            active: true,
            start_time: 480,
            end_time: 1080
          },
          {
            title: this.$t("wednesday"),
            active: true,
            start_time: 480,
            end_time: 1080
          },
          {
            title: this.$t("thursday"),
            active: true,
            start_time: 480,
            end_time: 1080
          },
          {
            title: this.$t("friday"),
            active: true,
            start_time: 480,
            end_time: 1080
          },
          {
            title: this.$t("saturday"),
            active: false,
            start_time: 480,
            end_time: 1080
          }
        ]
      }
    };
  },

  components: {
    appButton,
    doubleRangeSelect,
    HeaderComponent
  },

  computed: {
    ...mapGetters("manager/office_hours", {
      errors: "getErrors",
      loading: "isLoading"
    })
  },

  methods: {
    ...mapActions("manager/office_hours", ["create"]),
    ...mapMutations("system", ["setFlashMessage"]),

    minutesToHoursFormat(minutes) {
      return moment.utc(minutes * 60 * 1000).format("HH:mm");
    },

    hoursFormatToMinutes(text) {
      return moment.duration(moment(text, "HH:mm").diff(moment("00:00", "HH:mm"))).asMinutes();
    },

    updateMin(event, item) {
      let t = this.hoursFormatToMinutes(event.target.value);
      item.start_time = t >= item.end_time ? item.end_time : t;
    },

    updateMax(event, item) {
      let t = this.hoursFormatToMinutes(event.target.value);
      item.end_time = t <= item.start_time ? item.start_time : t;
    },

    submit() {
      const params = {
        name: this.fields.name,
        day_week: [],
        start_time: [],
        end_time: []
      };

      this.fields.week_days.forEach((item, i) => {
        if (item.active) {
          params.day_week.push(i);
          params.start_time.push(this.minutesToHoursFormat(item.start_time));
          params.end_time.push(this.minutesToHoursFormat(item.end_time));
        }
      });

      this.create(params).then(() => {
        this.$router.push("/manager/office-hours");
      });
    }
  }
};
</script>
