<template>
  <div v-if="loading">
    <div class="row my-4">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <p class="card-header-title fw-bold">
                  Consumo
                </p>
              </div>
              <div class="col-auto">
                <input-date-range
                  class="mx-3"
                  :max-interval="31"
                  @close="submit()"
                  v-model:start="requiredFilters.start_date"
                  v-model:end="requiredFilters.end_date"
                  preset="month"
                  :time-enabled="true"
                  format-date="d/m/Y"
                />
              </div>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table mb-0 table-nowrap">
                <thead>
                  <tr>
                    <th class="col" />
                    <th class="col-3">
                      Ligações
                    </th>
                    <th class="col-3">
                      Tempo Tarifado/Minutos
                    </th>
                    <th class="col-6">
                      Valor Tarifado
                    </th>
                  </tr>
                </thead>
                <tbody v-if="loading.listCompanyBilling">
                  <tr>
                    <td
                      class="p-0 loading"
                      v-for="i in 3"
                      :key="i"
                    >
                      <table-loading :rows="3">
                        <div class="cell col" />
                      </table-loading>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="list.length">
                  <template v-if="isBrazil">
                    <tr
                      v-for="(item, id) in list"
                      :key="id"
                    >
                      <th
                        class="border-end text-center text-muted"
                        style="background-color: #F9FBFD;"
                      >
                        Fixo
                      </th>
                      <td>{{ item.landline.calls }}</td>
                      <td>{{ item.landline.billed_time }}</td>
                      <td>{{ item.landline.billed_value }}</td>
                    </tr>
                    <tr
                      v-for="(item, id) in list"
                      :key="id"
                    >
                      <th
                        class="border-end text-center text-muted"
                        style="background-color: #F9FBFD;"
                      >
                        Móvel
                      </th>
                      <td>{{ item.mobile.calls }}</td>
                      <td>{{ item.mobile.billed_time }}</td>
                      <td>{{ item.mobile.billed_value }}</td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr
                      v-for="(item, id) in list"
                      :key="id"
                    >
                      <th
                        class="border-end text-center text-muted"
                        style="background-color: #F9FBFD;"
                      >
                        Internacional
                      </th>
                      <td>{{ item.outbound_call.calls }}</td>
                      <td>{{ item.outbound_call.billed_time }}</td>
                      <td>{{ item.outbound_call.billed_value }}</td>
                    </tr>
                  </template>
                  <tr
                    v-for="(item, id) in list"
                    :key="id"
                  >
                    <th
                      class="border-end text-center text-muted"
                      style="background-color: #F9FBFD;"
                    >
                      Total
                    </th>
                    <td class="fw-bold">
                      {{ item.totals.calls }}
                    </td>
                    <td class="fw-bold">
                      {{ item.totals.billed_time }}
                    </td>
                    <td class="fw-bold">
                      {{ item.totals.billed_value }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import InputDateRange from "@components/input-date-range";
import TableLoading from "../../components/table-loading.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      requiredFilters: {
        start_date: moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        end_date: moment()
          .endOf("day")
          .format("YYYY-MM-DD")
      }
    };
  },
  /**
   *
   */
  mounted() {
    this.listCompanyBilling({
      start_date: this.requiredFilters.start_date,
      end_date: this.requiredFilters.end_date,
      id: this.$route.params.id
    });
  },
  /**
   *
   */

  components: {
    InputDateRange,
    TableLoading
  },
  /**
   *
   */
  computed: {
    ...mapGetters("admin/companies", {
      list: "getCompanyBilling",
      loading: "getLoadingFlags",
      company: "getRegister"
    }),
    isBrazil() {
      return this.company["company-data"].data.country.country_code === 55;
    }
  },
  /**
   *
   */

  methods: {
    ...mapActions("admin/companies", ["listCompanyBilling"]),
    /**
     *
     */

    submit() {
      this.listCompanyBilling({
        start_date: this.requiredFilters.start_date,
        end_date: this.requiredFilters.end_date,
        id: this.$route.params.id
      });
    }
  }
};
</script>
