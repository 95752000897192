<template>
  <div class="col-12 col-xl-6">
    <div class="card">
      <div class="card-body d-flex flex-column justify-content-between">
        <div>
          <div class="d-flex align-items-center justify-content-between mb-2">
            <h3 class="card-title m-0">
              {{ $t("refer_link") }}
            </h3>
            <i class="fa-regular fa-link fs-1 tx-gray-blue-300" />
          </div>
          <div class="card-subtitle text-medium tx-text-100">
            {{ $t("this_link_is_just_for_you_copy_and_send_it_wherever_you_want") }}
          </div>
        </div>

        <copy-to-clipboard
          :text-to-copy="generateShareLink"
          translate-y="-90px"
          translate-x="-200px"
        >
          <template #main>
            <div
              @click="copyLink"
              class="refer-link d-flex align-items-center gap-3 cursor-pointer"
            >
              <i class="fa-regular fa-copy" />
              <div class="tx-text-200 text-truncate">
                {{ generateShareLink }}
              </div>
            </div>
          </template>
        </copy-to-clipboard>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CopyToClipboard from '@dialer/manager/pages/dashboards/campaign/components/copy-to-clipboard';

export default {
  components: {
    CopyToClipboard
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser"
    }),

    generateShareLink() {
      return ` https://3cplusnow.com/pt/lp/indicacao?ref_id=${this.user.id}&ref_name=${this.user.name}`;
    },
  },

  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.generateShareLink);
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  height: 180px;
  .card-body {
    .refer-link {
      border: 1px solid var(--color-gray-blue-300);
      border-radius: 10px;
      padding: 12px 16px;
    }
  }
}
</style>
