export default {
  getLoadingFlags: state => state.loadingFlags,
  getErrors: state => state.errors,
  getAllList: state => state.allList,
  getList: state => state.list,
  getPagination: (state) => state.pagination,
  getParams: (state) =>
    Object.assign(
      {
        "page": state.pagination.current_page,
        "per_page": state.pagination.per_page,
      }
      , state.filters),
  getTotalLogged: state => state.totalLogged,
  getTotalMaxLogin: state => state.totalMaxLogin,
  getCallStats: state => state.callStats,
  getListCompanies: state => state.listCompanies,
}
