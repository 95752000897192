import axios from "axios";

export default {
  fetchCampaignsWorkBreak({ commit }) {
    commit("startLoading", "fetchCampaignsWorkBreak");

    return new Promise((resolve, reject) => {
      axios.get('/campaigns/work_break')
        .then((response) => {
          commit("setCampaignsWorkBreak", response.data.data);
          resolve();
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "fetchCampaignsWorkBreak");
        });
    })
  }
}
