export default {
  startLoading: state => state.loading = true,
  stopLoading: state => state.loading = false,
  setList: (state, list) => state.list = list,
  clearList(state) {
    state.list = [];
},
  setPagination: (state, pagination) => state.pagination = pagination,
  setCurrentPage: (state, page) => state.pagination.current_page = page,
  setFilters: (state, filters) => state.filters = filters,
  setIncludes: (state, include) => state.includes = include,
  setTotalLogged: (state, totalLogged) => state.totalLogged = totalLogged,
  setMaxLogin: (state, totalMaxLogin) => state.totalMaxLogin = totalMaxLogin,
  setCallStats: (state, callStats) => state.callStats = callStats,
  setListCompanies: (state, listCompanies) => state.listCompanies = listCompanies,
  setListRoutesOfCoupon: (state, listRoutesOfCoupon) => state.listRoutesOfCoupon = listRoutesOfCoupon,
  setCreatedCouponData: (state, createdCouponData) => state.createdCouponData = createdCouponData,
  setRouteData: (state, routeData) => state.routeData = routeData,
}
