<template>
  <div>
    <header-component title="Insights IA">
      <template #buttons>
        <router-link
          v-if="currentTab === 'tab-dialer' && aiCallEnabled"
          class="btn btn-primary btn-users px-6"
          to="/manager/insights-ai/settings"
        >
          <i class="fa-regular fa-gear" />
          {{ $t("settings_omni") }}
        </router-link>
      </template>
    </header-component>

    <ul class="nav nav-tabs mb-4">
      <li
        class="nav-item"
        v-for="tab in tabs"
        :key="tab.name"
      >
        <button
          ref="tab-insights"
          :id="'tab-insights-' + tab.name"
          type="button"
          role="tab"
          data-bs-toggle="tab"
          :aria-controls="tab.name"
          :data-bs-target="'#' + tab.name"
          :data-test="'tab-' + tab.name"
          class="nav-link"
          @click="setCurrentTab(tab.name)"
          :class="['nav-link', { active: tab.name === currentTab }]"
        >
          {{ tab.label }}
        </button>
      </li>
    </ul>

    <div
      class="tab-content"
      id="nav-tabContent"
    >
      <tab-dialer />
      <tab-omni />
    </div>
  </div>
</template>

<script>
import TabDialer from "@dialer/manager/pages/insights-ai/dashboard/tabs/tab-dialer.vue";
import { mapGetters, mapMutations } from "vuex";
import HeaderComponent from "@/components/header-component.vue";
import TabOmni from "@dialer/manager/pages/insights-ai/settings/tabs/tab-omni.vue";

export default {
  name: "DashboardAi",

  components: {
    TabOmni,
    HeaderComponent,
    TabDialer
  },

  data() {
    return {
      tabs: [
        {
          name: "tab-dialer",
          label: this.$t("dialer_capitalize")
        },
        {
          name: "tab-omni",
          label: this.$t("Omnichannel")
        }
      ]
    };
  },

  mounted() {
    const currentTab = this.currentTab;

    if (currentTab) {
      const tabElement = document.getElementById(currentTab);
      if (tabElement) {
        tabElement.classList.add("show", "active");
      }
    }
  },

  computed: {
    ...mapGetters("manager/insights", {
      currentTab: "getCurrentTab"
    }),

    ...mapGetters("auth", {
      auth: "getUser"
    }),

    aiCallEnabled() {
      if (!this.auth || !this.auth.company) {
        return false;
      }
      const {
        enabled_recordings_in_stereo: audio,
        ai_call_evaluation: aiEvolution,
        ai_settings_configured: aiSettings
      } = this.auth.company;

      return audio && aiEvolution && aiSettings;
    }
  },

  methods: {
    ...mapMutations("manager/insights", ["setCurrentTab"])
  }
};
</script>
