<template>
  <div>
    <header-component
      :title="$t('teams_capitalize')"
      :pretitle="$t('settings_uppercase')"
    >
      <template #buttons>
        <button
          class="btn btn-primary px-6"
          @click="$router.push('/manager/teams/new')"
        >
          {{ $t('add_new_team') }}
        </button>
      </template>
    </header-component>
    <flash-message />

    <div class="input-group input-group-merge input-group-reverse mb-4">
      <input
        class="form-control"
        v-model="term"
        :placeholder="$t('search_teams')"
        @keyup="search"
        type="text"
      >
      <div class="input-group-text">
        <span class="fe fe-search" />
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6">
        <div
          class="card card-new"
          @click="$router.push('/manager/teams/new')"
        >
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div
                class="col overflow-ellipsis"
                style="line-height: 36.19px"
              >
                <span class="h5 mb-0"> {{ $t('new_team') }}</span>
              </div>
              <div class="col-auto">
                <span class="h2 fe fe-plus text-primary mb-0" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-xl-3 col-lg-4 col-md-4"
        v-if="loading"
      >
        <div class="card-body text-center">
          <div class="spinner-border text-muted">
            <span class="visually-hidden" />
          </div>
        </div>
      </div>

      <div
        class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
        v-for="(item, i) in list"
        :key="i"
      >
        <div
          class="card card-list-item"
          style="cursor: pointer"
          @click="$router.push({ path: `/manager/teams/${item.id}/edit` })"
        >
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col overflow-ellipsis one-line teams-circle">
                <span
                  class="btn btn-rounded-circle"
                  style="width: 28px; height: 28px"
                  :style="{ background: item.color }"
                />
              </div>
              <div class="col overflow-ellipsis one-line">
                <span class="h5 mb-0">
                  {{ item.name }}
                </span>
              </div>
              <div class="col-auto">
                <span class="h2 fe fe-chevron-right text-primary mb-0" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 mb-4">
        <paginator
          :pagination="pagination"
          @change-page="changePage"
          v-if="!loading"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import _ from "lodash";
import paginator from "@/components/app-paginator.vue";
import HeaderComponent from "@/components/header-component.vue";
import FlashMessage from "@components/flash-message";

export default {
  data() {
    return {
      term: "",
    };
  },
  components: {
    paginator,
    FlashMessage,
    HeaderComponent
  },
  mounted() {
    this.$store.dispatch("manager/teams/list");
  },
  methods: {
    ...mapMutations("manager/teams", ["setFilters", "clearList"]),
    search: _.debounce(function () {
      this.clearList();
      this.$store.dispatch("manager/teams/list", {
        search: this.term,
      });
    }, 500),
    changePage(page) {
      this.$store.dispatch("manager/teams/list", {
        page,
      });
    },
  },
  computed: {
    ...mapGetters("manager/teams", {
      list: "getList",
      loading: "isLoading",
      pagination: "getPagination",
    }),
  },
};
</script>
