<template>
  <div
    @click="handleCurrentCall"
    :style="answerCallBackgroundColor"
    :class="{
      'bg-call': this.callStatus === CallStatus.RECEIVING_EXTERNAL_CALL,
      'bg-receptive': [
        CallStatus.RECEIVING_INTERNAL_CALL,
        CallStatus.RECEIVING_TRANSFERENCE,
        CallStatus.CONSULT_CONNECTED
      ].includes(this.callStatus) ||
        this.status === AgentStatus.CONSULT_CONNECTED,
      disabled: loadingFlags.transferToAgentPABX || agentLoadingFlags.transferToAgent
    }"
    class="position-absolute answer-call cursor-pointer tx-text-light d-flex align-items-center justify-content-center"
  >
    <span class="fa-regular fa-phone-volume me-3" />
    <span> {{ getButtonText }} </span>
  </div>
</template>

<script>
import { AgentStatus, CallStatus } from "@/utils";
import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      AgentStatus,
      CallStatus
    };
  },

  computed: {
    ...mapGetters("agent/ring_group", {
      callStatus: "getCallStatus",
      consultedAgentId: "getConsultedAgentId",
      loadingFlags: "getLoadingFlags",
      isRingGroupActive: "isRingGroupActive"
    }),

    ...mapGetters("agent/agent", {
      loggedCampaign: "getCampaign",
      agentLoadingFlags: "getLoadingFlags"
    }),

    ...mapGetters("system", {
      status: "getStatus",
      agentInConsult: "getAgentInConsult"
    }),

    answerCallBackgroundColor() {
      let customColor = "";

      if (this.isRingGroupActive) {
        if (this.callStatus === CallStatus.RECEIVING_EXTERNAL_CALL) {
          customColor = "#5cb868";
        } else if (
          this.callStatus === CallStatus.RECEIVING_INTERNAL_CALL ||
          this.callStatus === CallStatus.RECEIVING_TRANSFERENCE ||
          this.callStatus === CallStatus.CONSULT_CONNECTED
        ) {
          customColor = "#6FBECE";
        }
      }

      if (this.loggedCampaign && this.status === AgentStatus.CONSULT_CONNECTED) {
        customColor = "#6FBECE";
      }

      return {
        "--answer-call-background-color": customColor,
      };
    },

    getButtonText() {
      if (this.isRingGroupActive) {
        return this.callStatus === CallStatus.CONSULT_CONNECTED ? this.$t('transfer') : this.$t('to_answer');
      }

      if (this.loggedCampaign) {
        return this.status === AgentStatus.CONSULT_CONNECTED && this.$t('transfer');
      }

      return false;
    }
  },

  methods: {
    ...mapActions("agent/ring_group", ["answerCallPABX", "transferToAgentPABX"]),
    ...mapActions("agent/agent", ["transferToAgent"]),
    ...mapMutations("system", ["setStatus"]),
    handleCurrentCall() {
      if (this.isRingGroupActive) {
        if (this.loadingFlags.transferToAgentPABX) return;
        if (this.callStatus === CallStatus.CONSULT_CONNECTED) {
          this.transferToAgentPABX(this.consultedAgentId);
        } else {
          this.answerCallPABX();
        }
      }

      if (this.loggedCampaign) {
        if (this.status === AgentStatus.CONSULT_CONNECTED) {
          this.transferToAgent({
            consultedId: this.agentInConsult.id
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.answer-call {
  right: 0;
  padding: 16px 0;
  width: 360px;
  border-radius: 16px 0 0 16px;
  transition: width 0.3s cubic-bezier(0.25, 0.1, 0.25, 1.5);
  animation-name: flashing;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;

  .fa-phone-volume {
    display: inline-block !important;
    animation: spin 1s linear infinite;
    color: $color-text-light;
  }

  &:hover {
    width: 380px;
  }

  @media (max-width: 1400px) {
    width: 300px;

    &:hover {
      width: 320px;
    }
  }

  @media (max-width: 1300px) {
    width: 260px;

    &:hover {
      width: 280px;
    }
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

@keyframes flashing {
  0% {
    outline: 6px solid var(--answer-call-background-color);
    outline-offset: -6px;
  }

  100% {
    outline: 1px solid var(--answer-call-background-color);
    outline-offset: -1px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(15deg);
  }

  50% {
    transform: rotate(0deg);
  }
}
</style>
