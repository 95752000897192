export default {
  getLoadingFlags: state => state.loadingFlags,
  getAgents: state => state.agents,
  getAgentsPagination: state => state.agentsPagination,
  getTeams: state => state.teams,
  getTeamsPagination: state => state.teamsPagination,
  getGroupChannels: state => state.groupChannels,
  getGroupChannelsWithQuali: state => state.groupChannelsWithQuali,
  getInstances: state => state.instances,
  getInstancesPagination: state => state.instancesPagination,
  getQualifications: state => state.qualifications,
  getStatus: state => state.status,
}
