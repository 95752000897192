export default {
  startLoadingFlag: (state, loadingFlag) => state.loadingFlags[loadingFlag] = true,
  stopLoadingFlag: (state, loadingFlag) => state.loadingFlags[loadingFlag] = false,

  setErrors: (state, errors) => state.errors = errors,
  clearErrors: (state) => state.errors = {},

  setWebhooks: (state, webhooks) => state.webhooks = webhooks,

  setWebhook: (state, webhook) => state.webhook = webhook
}
