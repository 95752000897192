<template>
  <div>
    <modal-component
      :show="showRecycleModal"
      :center="true"
      @close="$emit('close')"
      size="modal-527-sm"
    >
      <template #content>
        <div
          class="d-flex flex-column align-content-center py-4 bg-white rounded"
        >
          <button
            class="btn-close position-absolute end-0 top-0 mt-2 me-3 fs-2"
            @click="$emit('close')"
          />
          <h2 class="text-center mb-3 mt-2">
            {{ $t('recycle_method') }}
          </h2>
          <p class="text-center text-secondary mb-4 fs-6">
            {{ $t('recycle_can_take_up_to_2_hours') }}
          </p>
          <div
            class="align-self-center d-flex flex-column"
            :style="{ width: '387px' }"
          >
            <div
              class="col-12 d-flex flex-row justify-content-between"
              :style="{ padding: '0 2.344rem' }"
            >
              <div class="col-6">
                <input
                  v-model="recycleType"
                  value="status"
                  class="form-check-input"
                  type="radio"
                  id="statusRadio"
                  data-test="recycleByStatus-input"
                >
                <label
                  class="form-label mx-3"
                  for="statusRadio"
                >Status</label>
              </div>
              <div class="col-6 ps-4">
                <input
                  v-model="recycleType"
                  value="qualification"
                  class="form-check-input"
                  type="radio"
                  id="qualificationRadio"
                  data-test="recycleByQualification-input"
                >
                <label
                  class="form-label mx-3"
                  for="qualificationRadio"
                >{{ $t('qualification') }}</label>
              </div>
            </div>
            <div
              class="w-100 mt-3"
              :style="{border: '1px solid #F4F4F4'}"
            />
            <h4
              class="fw-light text-center"
              :style="{marginTop: '16.5px', marginBottom: '22px'}"
            >
              {{ $t('choose') }} {{ recycleType === 'status' ? $t('the_status') : $t('the_qualifications') }}
            </h4>
            <div
              class="col-12 d-flex flex-row justify-content-between flex-wrap"
              :style="{padding: '0 0.344rem'}"
              v-if="recycleType === 'qualification'"
            >
              <div
                v-for="qualification, index in qualifications"
                :key="qualification.id"
                class="col-6 mb-2 d-flex flex-row gap-3"
                :class="{'ps-4': isEven(index)}"
              >
                <div>
                  <input
                    v-model="qualificationsSelecteds"
                    :value="Number(qualification.id)"
                    :disabled="qualification.conversion"
                    class="form-check-input"
                    type="checkbox"
                    :id="qualification.name"
                    data-test="selectQualification-input"
                  >
                </div>
                <div>
                  <label
                    v-if="!qualification.conversion"
                    class="form-label qualification mb-0 pe-3 fs-6"
                    :for="qualification.name"
                    :title="qualification.name.length > 14 ? qualification.name : ''"
                  >
                    {{ qualification.name }}
                  </label>
                  <VDropdown
                    :triggers="['hover']"
                    :distance="14"
                    :skidding="isEven(index) ? -98 : 67"
                    v-else
                  >
                    <p
                      class="form-label qualification mb-0 pe-2 text-muted fs-6"
                    >
                      {{ qualification.name }}
                    </p>
                    <template #popper>
                      <div
                        class="fs-6 p-3"
                      >
                        {{ $t('conversation_conversion') }} <br> {{ $t('cannot_be_listed') }}
                      </div>
                    </template>
                  </VDropdown>
                </div>
              </div>
            </div>
            <div
              class="col-12 d-flex flex-row justify-content-between flex-wrap"
              :style="{ padding: '0 0.344rem' }"
              v-else-if="recycleType === 'status'"
            >
              <div
                v-for="value, key, index in statuses"
                :key="key"
                class="col-6 mb-2"
                :class="{'ps-4': isEven(index)}"
              >
                <input
                  v-model="statusesSelecteds"
                  :value="Number(key)"
                  class="form-check-input"
                  type="checkbox"
                  :id="key"
                  data-test="selectStatus-input"
                >
                <label
                  class="form-label ms-3 fs-6"
                  :for="key"
                >{{ value }}</label>
              </div>
            </div>
            <p
              class="text-center my-4 fs-6"
            >
              <strong>
                Obs:
              </strong>
              <span class="text-secondary">  {{ $t('conversions_will_not_be_recycle') }} </span>
            </p>

            <div
              class="d-flex flex-column align-self-center"
              :style="{width: '350px'}"
            >
              <label
                class="text-secondary form-label fs-6"
                for="recycle-name"
              >
                {{ $t('choose_new_list_name') }}<span class="text-danger">*</span>
              </label>
              <input
                v-model="newListName"
                type="text"
                class="form-control mb-2 fs-6"
                :placeholder="'Nova lista ' + new Date().getFullYear()"
                id="recycle-name"
                data-test="recycleListName-input"
              >
              <button
                class="btn btn-primary mt-3"
                :disabled="!isSendValid()"
                @click="send()"
                data-test="recycleConfirm-button"
              >
                {{ $t('confirm_capitalize') }}
              </button>
              <span
                @click="$emit('close')"
                class="btn p-0 text-center mt-4 text-secondary mb-1"
                data-test="recycleCancel-button"
              > {{ $t('cancel_general') }} </span>
            </div>
          </div>
        </div>
      </template>
    </modal-component>
  </div>
</template>
<script>
import ModalComponent from '@/components/modal-component.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { CallStatusWithName, SystemQualifications, translateSystemQualificationNames } from '@/utils';
export default {
  emits: ["close"],

  data() {
    return {
      recycleType: "status",
      newListName: "",
      qualificationsSelecteds: [],
      statusesSelecteds: [],
      statuses: CallStatusWithName,
      systemQualifications: SystemQualifications,
      systemQualificationsTranslatedNames: translateSystemQualificationNames,
    }
  },

  computed: {
    ...mapGetters("manager/campaign", {
      campaign: "getCampaign"
    }),

    ...mapGetters("manager/list", {
      loadingFlags: "getLoadingFlags",
    }),
    qualifications() {
      let qualifications = this.campaign.qualifications;

      qualifications.sort((qualification) => (qualification.conversion ? 1 : -1));
      const qualificationsCapitalized = qualifications.map(qualification => {
        return {
          ...qualification, name: qualification.name.charAt(0).toUpperCase() + qualification.name.slice(1)
        }
      });

      return qualificationsCapitalized;
    },
  },

  props: {
    showRecycleModal: {
      type: Boolean,
      default: false
    },
    recycleListId: {
      type: Number,
      default: 0
    }
  },

  methods: {
    ...mapActions("manager/list", ["recycle", "findListOfLists"]),
    ...mapMutations("manager/list", ["setRecycleFilters"]),

    send() {
      this.setRecycleFilters({
        name: this.newListName,
        recycle_type: this.recycleType,
        recycle_type_ids: this.recycleType === 'qualification' ? this.qualificationsSelecteds : this.statusesSelecteds,
        list_id: this.recycleListId
      })
      this.recycle({
        campaign_id: this.$route.params.id,
        list_id: this.recycleListId
      }).then(() => {
        this.$emit('close')
        this.findListOfLists({id: this.$route.params.id})

        this.newListName = "";
        this.qualificationsSelecteds = [];
        this.statusesSelecteds = [];
      })
    },

    isSendValid() {
      const isListNameNotEmpty = this.newListName.length > 0;

      const selectedsFieldsWatcheds = this.recycleType === 'qualification' ? this.qualificationsSelecteds : this.statusesSelecteds;
      const isSelectedsFieldsNotEmpty = selectedsFieldsWatcheds.length > 0;

      return !this.loadingFlags.recycle && isListNameNotEmpty && isSelectedsFieldsNotEmpty;
    },

    isEven(index) {
      return (Math.abs(index) + 1) % 2 === 0
    },

  },

  components: {
    ModalComponent
  },

  watch: {
    recycleType() {
      this.qualificationsSelecteds = [];
      this.statusesSelecteds = [];
    }
  }
}
</script>
<style lang="scss">
// colocar scoped
.v-popper__inner {
  border-radius: 1rem !important;
}
.qualification {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  margin-top: 1px;
}

</style>
