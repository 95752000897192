export default {
  setConsultMetrics: (state, consultMetrics) => {
    state.consultMetrics = {
      abandoned: 0,
      acw: 0,
      connected: 0,
      queue_id: 0,
      speaking: 0,
      speaking_with_agent: 0,
      waiting_time: 0,
      awt: 0,
      ast: 0,
      abandonedPercentage: 0,
      connectedPercentage: 0
    };

    if (consultMetrics[0] !== undefined) {
      state.consultMetrics = consultMetrics[0]
      state.consultMetrics.awt = 0;
      state.consultMetrics.ast = 0;
      if (state.consultMetrics.connected > 0) {
        state.consultMetrics.ast = Math.round(state.consultMetrics.speaking_with_agent / state.consultMetrics.connected);
      }

      let total = state.consultMetrics.connected + state.consultMetrics.abandoned

      if (total > 0) {
        state.consultMetrics.awt = Math.round(state.consultMetrics.waiting_time / total)
        state.consultMetrics.abandonedPercentage = Math.round(state.consultMetrics.abandoned / total * 100)
        state.consultMetrics.connectedPercentage = Math.round(state.consultMetrics.connected / total * 100)
      }
    }
  },
};
