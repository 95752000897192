<template>
  <div class="mt-4">
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center gap-2">
        <button
          class="btn btn-xs btn-rounded-circle btn-white float-start"
          @click="$router.push(isAgent ?
            `/crm/${$route.params.id}` :
            `/manager/crm/${$route.params.id}`
          )"
        >
          <i class="fa-regular fa-arrow-left" />
        </button>
        <span class="fs-16 header-title">
          {{ $t('close') }}
        </span>
      </div>
      <card-loading
        v-if="loadingFlagsOpportunities.fetchOpportunity || !currentFunnel"
        :height="42"
      />
      <div
        v-else
        class="d-flex align-items-center gap-3"
      >
        {{ $t("responsible") }}
        <select-responsible-for-contact
          v-if="!loadingFlagsContacts.fetchUsersByTeam && teamUsers.length"
          :team-users="teamUsers"
          :edit="true"
          @contact-selected="changeOwner($event)"
        />
        <opportunity-qualify-actions />
      </div>
    </div>
    <opportunity-card
      :is-loading="loadingFlagsOpportunities.fetchOpportunity || !currentFunnel"
      @select-step="updateStep"
      @name="updateName"
      enable-products
    />
    <div class="row">
      <div class="col-7">
        <new-activity-card :is-loading="loadingFlagsOpportunities.fetchOpportunity || !currentFunnel" />
        <pinned-internal-note-list />
        <div :id="$t('activities_lowercase')" />
        <pending-activities-list :is-loading="loadingFlagsOpportunities.fetchOpportunity || !currentFunnel" />
        <activity-timeline :is-loading="loadingFlagsOpportunities.fetchOpportunity || !currentFunnel" />
      </div>
      <div class="col-5">
        <contact-chanels-list
          :current-funnel="currentFunnel"
          :is-show-opportunity="true"
          :is-loading="loadingFlagsOpportunities.fetchOpportunity || !currentFunnel"
        />
        <data-card :is-loading="loadingFlagsOpportunities.fetchOpportunity || !currentFunnel" />
        <product-card
          :products="products"
          :is-loading="loadingFlagsOpportunities.fetchOpportunity || !currentFunnel"
        />
      </div>
    </div>
  </div>
  <div
    id="deleteModal"
    ref="deleteModal"
    aria-hidden="false"
    aria-labelledby="deleteModal"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header pt-4 pe-5 border-0">
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            data-test="closeDeleteContact-button"
            style="font-size: 24px;"
            type="button"
          />
        </div>
        <div class="modal-body d-flex flex-column justify-content-center text-center gap-4">
          <h2 class="h2">
            {{ $t('delete_card_confirmation') }}
          </h2>
          <span>{{ $t('card_available_in_deleted') }} <br>
            {{ $t('reactivate_in_future') }}
          </span>
          <button
            class="btn btn-danger"
            @click="handleStatus('deleted')"
          >
            {{ $t('capitalizes.exclude') }}
          </button>
          <button
            class="btn btn-primary-outline border-0"
            data-bs-dismiss="modal"
            type="button"
          >
            {{ $t('cancel_general') }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    id="reactivateModal"
    ref="reactivateModal"
    aria-hidden="false"
    aria-labelledby="reactivateModal"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header pt-4 pe-5 border-0">
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            data-test="closeDeleteContact-button"
            style="font-size: 24px;"
            type="button"
          />
        </div>
        <div class="modal-body d-flex flex-column justify-content-center text-center gap-4">
          <h2 class="h2">
            {{ $t('reactivate_card') }}
          </h2>
          <span>{{
            $t('same_step_before_deleted')
          }}
          </span>
          <button
            class="btn btn-primary"
            @click="handleStatus('active')"
          >
            {{ $t('reactivate') }}
          </button>
          <button
            class="btn btn-primary-outline border-0"
            data-bs-dismiss="modal"
            type="button"
          >
            {{ $t('cancel_general') }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <confirm-status-change
    v-model="confirmStatusChange"
    @accepted="undoGain"
  />
</template>
<script>
import {Modal} from "bootstrap";
import {mapActions, mapGetters, mapMutations} from "vuex";
import SelectResponsibleForContact from "../../components/agents/select-responsible-for-contact.vue";
import ContactChanelsList from "../../components/contact/contact-chanels/contact-chanels-list.vue";
import DataCard from "../../components/contact/data-card.vue";
import ConfirmStatusChange from "../../components/contact/confirm-status-change.vue";
import NewActivityCard from "@crm/components/activity/form-activity/new-activity-card.vue";
import PendingActivitiesList from "@crm/components/activity/list/pending-activities-list.vue";
import ActivityTimeline from "@crm/components/activity/timeline/activity-timeline.vue";
import PinnedInternalNoteList from "@crm/components/activity/pinned-internal-note-list.vue";
import ProductCard from "@crm/components/contact/products/product-card.vue";
import OpportunityCard from "@crm/pages/opportunity/components/opportunity-card.vue";
import CardLoading from "@components/card-loading.vue";
import OpportunityQualifyActions from "@crm/pages/opportunity/components/opportunity-qualify-actions.vue";
import {elementObserverById} from "@/utils";

export default {
  components: {
    CardLoading,
    OpportunityCard,
    OpportunityQualifyActions,
    ProductCard,
    ConfirmStatusChange,
    DataCard,
    ContactChanelsList,
    SelectResponsibleForContact,
    NewActivityCard,
    PendingActivitiesList,
    ActivityTimeline,
    PinnedInternalNoteList,
  },

  data() {
    return {
      status: null,
      confirmStatusChange: false,
      agent: null,
      deleteModal: null,
      reactivateModal: null,
      isEditing: false,
      nameField: "",
      isScrollExecuted: false,
    };
  },

  computed: {
    ...mapGetters("crm/contacts", {
      teamUsers: "getTeamUsers",
      loadingFlagsContacts: "getLoadingFlags",
    }),
    ...mapGetters("auth", {
      user: "getUser",
      isAgent: "isAgent"
    }),
    ...mapGetters("crm/funnels", {
      currentFunnel: "getCurrentFunnel",
    }),
    ...mapGetters("crm/opportunities", {
      opportunity: "getOpportunity",
      loadingFlagsOpportunities: "getLoadingFlags"
    }),
    ...mapGetters("manager/products_and_services", {
      products: "getCategories",
    }),
    ...mapGetters("crm/activities", {
      pendingActivities: "getPendingActivities",
      loadingFlagsActivities: 'getLoadingFlags',
    }),
  },

  created() {
    this.fetchOpportunity(this.$route.params.opportunityId).then(() => {
      this.nameField = this.opportunity.name
    });

    this.initializeFunnel();
  },

  mounted() {
    this.handleModals();
    this.loadProducts();
    this.loadSynchronizedProducts();
  },

  beforeUnmount() {
    this.setOpportunity(null)
  },

  methods: {
    ...mapActions("crm/opportunities", [
      "createChangeOwner",
      "updateOpportunity",
      "fetchOpportunity",
      "moveOpportunity"
    ]),

    ...mapActions("manager/products_and_services", [
      "fetchPnS",
      "fetchSynchronizedProducts"
    ]),

    ...mapMutations("crm/opportunities", ["setOpportunity"]),
    ...mapActions("crm/contacts", [
      "fetchUsersByTeam"
    ]),
    ...mapActions('crm/funnels', ['fetchFunnels']),
    elementObserverById,

    initializeFunnel() {
      if (!this.currentFunnel) {
        this.fetchFunnels(this.$route.params.id);
      }
    },

    handleModals() {
      this.deleteModal = new Modal(this.$refs.deleteModal);
      this.reactivateModal = new Modal(this.$refs.reactivateModal);
    },

    loadProducts() {
      this.fetchPnS({
        search: this.search,
        page: 1,
        per_page: 10,
      })
    },

    loadSynchronizedProducts() {
      this.fetchSynchronizedProducts({opportunity_id: this.$route.params.opportunityId})
    },

    undoGain() {
      this.status = 'active'
      this.updateStatus()
    },

    changeOwner(ownerId) {
      if (ownerId) {
        this.createChangeOwner({
          opportunityFunnelId: this.opportunity.opportunity_funnel_id,
          ownerId: ownerId
        }).then(() => {
          if (this.isAgent) {
            this.$router.push("/crm/" + this.$route.params.id)
          }
        })
      }
    },

    handleStatus(status) {
      this.status = status
      this.updateStatus()
      if (status === 'deleted') {
        this.deleteModal.hide()
      }
      if (status === 'active') {
        this.reactivateModal.hide()
      }
    },

    updateName(name) {
      this.updateOpportunity({
        opportunityId: this.opportunity.id,
        payload: {
          name: name,
          company_id: this.user.company.id,
        }
      })
    },

    updateStep(stepId) {
      const opportunity_funnel_id = this.opportunity.opportunity_funnel_id;
      const new_step_id = stepId;
      const new_order = 3;

      const payload = {
        opportunity_funnel_id,
        new_step_id,
        new_order
      }

      this.moveOpportunity(payload)
    }
  },

  watch: {
    currentFunnel: {
      handler(val) {
        if (val) {
          if (!this.teamUsers?.length) {
            this.fetchUsersByTeam({teamId: this.currentFunnel.team.id, search: ""});
          }
        }
      },
      once: true,
      immediate: true
    },

    "loadingFlagsActivities.fetchPendingActivities": {
      handler(isLoading) {
        if (!isLoading && this.$route.hash && this.pendingActivities.length && !this.isScrollExecuted) {
          document.getElementById(this.$route.hash.replace('#', '')).scrollIntoView({
            behavior: "smooth",
          });
          this.isScrollExecuted = true;
        }
      },
    }
  },
};
</script>
<style
  lang="scss"
  scoped
>
.blue-hover:hover {
  color: $color-blue-300 !important;
}

.red-hover:hover {
  color: $color-red-400 !important;
}

.green-hover:hover {
  color: $color-green-300 !important;
}

input[disabled] {
  opacity: 1 !important;
}

.dropdown-menu-contact {
  box-shadow: 0px 4px 18px 0px rgba(34, 54, 77, 0.25);

  .dropdown-item {
    border-radius: 8px !important;
    cursor: pointer;

    &:hover {
      background-color: $color-gray-100 !important;

      .form-check-input {
        background-color: $color-gray-300 !important;
      }
    }
  }

  .delete-item {
    color: $color-red-300 !important;

    &:hover {
      background-color: $color-hover-danger !important;
    }
  }
}

.offcanvas-contact {
  min-width: 80%;
  z-index: 1041 !important;
  background-color: #F9FAFC;
}

.primary-actived.show {
  color: $color-text-light;
  background-color: $color-blue-300;
  border-color: $color-blue-300;
}

.input-name {
  font-family: 'Random Grotesque Standard', sans-serif !important;
  font-weight: 600;
  line-height: 40px;
  border-radius: 0.625rem;

  &:hover, &:focus {
    background-color: #E6E6E6;
  }

  span {
    display: none;
  }

  input[disabled] {
    opacity: 1 !important;
  }

  input::placeholder {
    color: $color-text-solid-black;
  }

  &:hover {
    span {
      display: flex;
    }

    input, span {
      background-color: #E6E6E6 !important;
    }
  }

  input:focus {
    background-color: #E6E6E6;
  }

  input:focus ~ span {
    display: flex;
    background-color: #E6E6E6;
  }
}

.enable-input {
  background-color: #E6E6E6;

  & ~ span {
    display: flex !important;
    background-color: #E6E6E6 !important;
  }
}
</style>
