<template>
  <main class="position-relative h-100 overflow-auto">
    <section
      v-if="page > 1 || page === 4"
      class="d-flex w-100"
      style="height: 70px"
    >
      <div class=" login-card card w-100 rounded-0 m-0">
        <router-link to="/login">
          <img
            alt="Logo Partner"
            class="ps-5 position-absolute my-3 image-link"
            src="@/assets/img/partner/partner.svg"
          >
        </router-link>
      </div>
    </section>
    <section
      v-if="page === 1"
      :class="{
        'mx-3': resizeMobile,
        'h-75 mt-0': resizeHd,
        'h-100 mt-0': higthRezolution
      }"
      class="main-content d-flex justify-content-center align-items-center mt-5"
    >
      <div
        :style="{ marginBottom: resizeHd ? '' : '150px' }"
        class="col-sm-6 col-md-7 col-xl-5 login-card"
      >
        <div class="mb-0 position-relative  w-100 h-100">
          <div class="text-center">
            <img
              alt="Logo partner"
              class="w-100 mb-4"
              src="@/assets/img/partner/partner.svg"
              style="max-width: 500px;"
            >
          </div>
          <div
            :class="{ 'px-3': resize }"
            class="card mb-0 w-100 p-5 mb-5 h-100"
          >
            <div class="card-body p-0 w-100">
              <div class="mb-5">
                <h1 class="fs-1 m-0">
                  Seja um parceiro 3C+
                </h1>
                <span class="text-muted fs-3">Crie sua conta</span>
              </div>

              <div class="form-floating form-group">
                <input
                  id="name"
                  v-model="name"
                  class="form-control form-control-sm input-login"
                  @blur="inputFocused = ''"
                  @focus="inputFocused = 'name'"
                >
                <label
                  :class="{ 'label-on-border': name || inputFocused == 'name' }"
                  class="floating-label"
                  for="name"
                >Nome</label>
                <span
                  v-if="errorMessage.name"
                  class="tx-red-300 ps-2"
                >
                  {{ errorMessage.name }}
                </span>
              </div>
              <div class="form-floating form-group">
                <input
                  id="email"
                  v-model="email"
                  class="form-control form-control-sm input-login"
                  type="email"
                  @blur="inputFocused = ''"
                  @focus="inputFocused = 'email'"
                >
                <label
                  :class="{
                    'label-on-border': email || inputFocused == 'email'
                  }"
                  class="floating-label"
                  for="email"
                >
                  E-mail
                </label>
                <span
                  v-if="errorMessage.email"
                  class="tx-red-300 ps-2"
                >
                  {{ errorMessage.email }}
                </span>
              </div>
              <div
                class="d-flex justify-content-center align-items-center mt-4"
                style="height: 40px"
              >
                <div class="form-floating form-group col-11 my-0">
                  <input
                    id="password"
                    v-model="password"
                    :type="showPass ? 'text' : 'password'"
                    aria-describedby="inputGroupInvalid"
                    aria-label="Input group appended"
                    class="border-end-0 rounded-0 rounded-start form-control form-control-sm input-login"
                    style="border-top-left-radius: 8px!important;border-bottom-left-radius:8px!important;"
                    @blur="inputFocused = ''"
                    @focus="inputFocused = 'password'"
                  >
                  <label
                    :class="{
                      'label-on-border': password || inputFocused == 'password'
                    }"
                    class="floating-label"
                    for="password"
                  >
                    Senha
                  </label>
                </div>
                <div
                  id="inputGroupInvalid"
                  class="d-flex justify-content-center align-items-center text-center input-group-text col border-start-0 rounded-0 rounded-end p-0"
                  style="height: 40px;border-top-right-radius: 8px!important;border-bottom-right-radius:
                  8px!important;"
                  @click="showPass = !showPass"
                >
                  <i
                    v-if="!showPass"
                    class="fe fe-eye"
                  />
                  <i
                    v-else
                    class="fe fe-eye-off"
                  />
                </div>
              </div>
              <p class="d-flex justify-content-between pt-1">
                <small
                  v-if="capsLockOn"
                  class="text-end tx-blue-300"
                >
                  <i class="fal fa-lock" /> Caps lock ativado
                </small>
              </p>
              <password-validate
                :password="password"
                @valid-password="verifyPassword"
              />
              <button
                :disabled="buttonDisabled"
                class="btn btn-primary w-100 mt-4"
                style="height: 40px"
                @click="IsValidatedAccountPartner"
              >
                Criar
              </button>
              <div class="text-center mt-4">
                <span class="text-muted">Ja possui uma conta? <router-link
                  class="tx-text-200"
                  to="/login"
                >Entrar agora</router-link></span>
              </div>
            </div>
            <div
              :class="imageClass"
              class="position-absolute image-position"
            >
              <img
                alt="logo Partner"
                src="@/assets/img/partner/logoPartner.svg"
                style="width: 70%;"
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      v-if="page === 2"
      :class="{ 'mx-3 my-6': resizeMobile }"
      class="main-content d-flex justify-content-center align-items-center h-100"
    >
      <div
        :class="{ 'mx-3': resizeMobile }"
        class="col-10 col-sm-6 col-md-7 col-xl-5 login-card"
      >
        <div
          :class="{ 'px-3': resize }"
          class="card mb-0 position-relative w-100 py-5"
        >
          <div class="card-body p-0">
            <div class="d-flex justify-content-center  mb-5">
              <step-by-step :index="stepNumber" />
            </div>
            <div class="mb-5">
              <h1 class="fs-1 m-0">
                Crie sua conta
              </h1>
              <span class="text-muted fs-3">Dados da empresa</span>
            </div>
            <div class="form-floating form-group">
              <input
                id="cnpjOrCpf"
                v-model="cnpj_or_cpf"
                v-maska
                class="form-control form-control-sm input-login"
                data-maska="['###.###.###-##','##.###.###/####-##']"
                @blur="inputFocused = ''"
                @focus="inputFocused = 'cnpjOrCpf'"
                @input="verifyCnpjOrCpf"
                :disabled="loading.cnpj"
              >
              <label
                :class="{
                  'label-on-border': cnpj_or_cpf || inputFocused == 'cnpjOrCpf'
                }"
                class="floating-label"
                for="cnpjOrCpf"
              >CNPJ ou CPF</label>
              <span
                v-if="errorMessage.cnpj"
                class="
                  tx-red-300 ps-2"
              >
                {{ errorMessage.cnpj }}
              </span>
            </div>
            <div v-if="zipCodeEnabled">
              <div class="form-floating form-group my-0">
                <input
                  id="cep"
                  v-model="cep"
                  v-maska
                  class="form-control form-control-sm input-login"
                  data-maska="#####-###"
                  @blur="inputFocused = ''"
                  @focus="inputFocused = 'cep'"
                  @input="consultZipCode(cep)"
                  :disabled="loading.cep"
                >
                <label
                  :class="{ 'label-on-border': cep || inputFocused == 'cep' }"
                  class="floating-label"
                  for="cep"
                >
                  CEP
                </label>
                <div
                  v-if="zipCodeEnabled"
                  class="mt-2"
                >
                  <span
                    v-if="message"
                    class="tx-blue-300"
                  >
                    O endereco fica na
                    {{ publicPlace }}, {{ neighborhood }}- {{ city }},
                    {{ state }}
                  </span>
                  <span
                    v-if="errorMessage.cep > 0 || getZipCode.erro"
                    class="tx-red-300"
                  >
                    {{ errorMessage.cep }}
                  </span>
                </div>
              </div>
            </div>
            <div
              v-if="state !== undefined && state.trim()"
              class="d-flex gap-4"
            >
              <div class="form-floating form-group">
                <input
                  id="number"
                  v-model="number"
                  v-maska
                  class="form-control form-control-sm input-login"
                  data-maska="#####"
                  @blur="inputFocused = ''"
                  @focus="inputFocused = 'number'"
                >
                <label
                  :class="{
                    'label-on-border': number || inputFocused == 'number'
                  }"
                  class="floating-label"
                  for="number"
                >
                  Número
                </label>
              </div>
              <div class="form-floating form-group">
                <input
                  id="complement"
                  v-model="complement"
                  class="
                  form-control
                  form-control-sm
                  input-login"
                  type="text"
                  @blur="inputFocused = ''"
                  @focus="inputFocused = 'complement'"
                >
                <label
                  :class="{
                    'label-on-border': complement || inputFocused == 'complement'
                  }"
                  class="floating-label"
                  for="complement"
                >
                  Complemento
                </label>
              </div>
            </div>
          </div>
          <app-button
            :disabled="loading.cnpj || loading.cep || buttonDisabled"
            class="btn btn-primary w-100 mt-3"
            style="height: 40px"
            @click="nextStepRegister"
          >
            Proximo
          </app-button>
          <button
            class="btn text-muted w-100 mt-4"
            style="height: 40px"
            @click="prevStepRegister"
          >
            Voltar
          </button>
        </div>
      </div>
    </section>
    <section
      v-if="page === 3"
      :class="{ 'mx-3 my-6': resizeMobile }"
      class="main-content d-flex justify-content-center align-items-center h-100"
    >
      <div
        :class="{ 'px-3': resize }"
        class="col-11 col-sm-6 col-md-7 col-xl-5 login-card"
      >
        <div
          :class="resizeMobile ? 'px-3' : ''"
          class="card mb-0 position-relative w-100 py-5"
        >
          <div
            class="card-body p-0 w-100 overflow-hidden"
          >
            <div class="d-flex justify-content-center w-100 mb-5">
              <step-by-step :index="stepNumber" />
            </div>
            <div class="mb-5">
              <h1 class="fs-1 m-0">
                Crie sua conta
              </h1>
              <span class="text-muted fs-3">Dados da conta</span>
            </div>

            <accordion-register-partner
              @update="handleOffice"
              @child-click="childClicked"
            />
            <div v-if="!accordionIsOpen">
              <div class="form-floating mb-3">
                <input
                  id="whatsapp"
                  v-model="whatsapp"
                  v-maska
                  class="form-control form-control-sm input-login"
                  data-maska="['(##) ####-####','(##) #####-####']"
                  @blur="inputFocused = ''"
                  @focus="inputFocused = 'whatsapp'"
                >
                <label
                  :class="{
                    'label-on-border': whatsapp || inputFocused == 'whatsapp'
                  }"
                  class="floating-label"
                  for="whatsapp"
                >
                  Telefone
                </label>
                <span
                  v-if="errorMessage.whatsapp && whatsapp.length > 11 && whatsapp.length < 14"
                  class="tx-red-300"
                >
                  {{ errorMessage.whatsapp }}
                </span>
              </div>
            </div>
          </div>
          <app-button
            :disabled="buttonDisabled"
            :loading="loading.createUser"
            class="btn btn-primary w-100 mt-5"
            style="height: 40px"
            @click="createdPartner"
          >
            Proximo
          </app-button>
          <button
            class="btn text-muted w-100 mt-5 p-0"
            style="height: 22px"
            @click="prevStepRegister"
          >
            Voltar
          </button>
        </div>
      </div>
    </section>
    <section
      v-if="page === 4"
      :class="{ 'mx-3': resizeMobile }"
      class="main-content d-flex justify-content-center align-items-center h-100"
    >
      <div class="login-card">
        <div
          :class="resizeMobile ? 'px-3' : ''"
          :style="dynamicStyle"
          class="card mb-0 position-relative"
        >
          <div
            :class="{ 'text-center': resize }"
            class="card-body p-0 w-100"
          >
            <div class="d-flex justify-content-center w-100 mb-5">
              <step-by-step :index="stepNumber" />
            </div>
            <div class="mb-5">
              <h1 class="fs-1 m-0">
                Confirme seu email
              </h1>
              <span class="text-muted fs-3">Acesse sua caixa de entrada</span>
              <div
                :class="resize ? 'justify-content-center' : 'justify-content-between'"
                class="d-flex  gap-4 mt-4 align-items-center flex-shrink-1 flex-wrap"
                style="max-width: 522px;"
              >
                <div
                  v-for="(image, idx) in emailImage"
                  :key="idx"
                  class="mb-3"
                >
                  <a
                    :href="image.link"
                    target="_blank"
                  >
                    <button
                      class="btn btn-outline-secondary tx-blue-300 focus-none"
                      style="width: 241px;"
                    >
                      <img
                        :alt="image.name"
                        :src="image.image"
                        class="me-3"
                      >
                      {{ image.text }}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <p :class="{ 'text-center': resize }">
            Não recebeu o e-mail? Verifique sua pasta de spam ou
            <span
              :disabled="resendDisabled"
              class="cursor-pointer tx-blue-300"
              @click="handleResend"
            >
              reenvie o e-mail {{ resendDisabled ? `(${resendTimer}s)` : "" }}
            </span>
            .
          </p>
        </div>
      </div>
    </section>
    <section
      v-if="page === 1"
      class="position-fixed bottom-0 w-100"
      style="z-index: 1200"
    >
      <div class="card login-card card-footer col-12 m-0 p-0 bg-offline">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <a
                class="btn"
                href="https://www.instagram.com/3cplusnow/"
                target="_blank"
              >
                <i class="fab fa-instagram fa-lg footer-icon" />
              </a>
              <a
                class="btn"
                href="https://3cplusnow.com/"
                target="_blank"
              >
                <i class="fal fa-globe fa-lg footer-icon" />
              </a>
            </div>
            <a
              class="tx-text-200 border-0"
              href="https://help.3cplus.com.br/pt-BR/"
              target="_blank"
            >
              Ajuda
            </a>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import AccordionRegisterPartner from "@pages/auth/components/accordion-register-partner.vue";
import { mapActions, mapGetters } from "vuex";
import StepByStep from "@pages/auth/components/step-by-step.vue";
import PasswordValidate from "@pages/auth/components/password-validate.vue";
import AppButton from "@components/app-button.vue";

export default {
  components: {
    AppButton,
    PasswordValidate,
    StepByStep,
    AccordionRegisterPartner
  },

  data() {
    return {
      page: 1,
      cnpj_or_cpf: "",
      cep: "",
      name: "",
      email: "",
      password: "",
      commercial_name: "",
      cnpj_status: "",
      publicPlace: "",
      neighborhood: "",
      number: "",
      complement: "",
      city: "",
      state: "",
      actuation_field: "",
      role: "",
      whatsapp: "",
      resize: false,
      buttonDisabled: true,
      zipCodeEnabled: false,
      message: false,
      errorMessage: {},
      accordionIsOpen: false,
      emailImage: [
        {
          name: "E-mail Google",
          image: require("@/assets/img/icons/gmail.svg"),
          text: "Abrir Gmail",
          link: "https://accounts.google.com/"
        },
        {
          name: "E-mail Outlook",
          image: require("@/assets/img/icons/outlook.svg"),
          text: "Abrir Outlook",
          link: "https://outlook.live.com/mail/about/index_pt.html"
        },
        {
          name: "E-mail Yahoo!",
          image: require("@/assets/img/icons/yahool.svg"),
          text: "Abrir Yahoo!",
          link: "https://mail.yahoo.com/"
        },
        {
          name: "E-mail apple mail",
          image: require("@/assets/img/icons/mail.svg"),
          text: "Abrir Mail",
          link: "https://www.icloud.com/mail"
        }
      ],
      stepNumber: -1,
      resizeMobile: false,
      resizeHd: false,
      higthRezolution: false,
      validPassword: false,
      showPass: false,
      resendDisabled: false,
      resendTimer: 60,
      intervalId: null,
      capsLockOn: null,
      inputFocused: "",
      loading: {
        cnpj: false,
        cep: false,
        createUser: false
      }
    };
  },

  mounted() {
    this.windowResize();
    window.addEventListener("resize", this.windowResize);
    this.watchData();
    this.checkCapsLock();
  },

  beforeUnmount() {
    this.windowResize();
    window.removeEventListener("resize", this.windowResize);
    clearInterval(this.intervalId);
  },

  methods: {
    ...mapActions("admin/partners", ["checkCnpj", "zipCode"]),
    ...mapActions("auth", ["createPartner", "checkIsValidEmail", "resendEmail"]),

    handleOffice({ textSelected, selectedItem }) {
      if (selectedItem.text === "Area de atuação") {
        this.actuation_field = textSelected;
      } else if (selectedItem.text === "Cargo") {
        this.role = textSelected;
      }
    },

    IsValidatedAccountPartner() {
      this.errorMessage = {};
      const account = {
        name: this.name,
        email: this.email,
        password: this.password
      };

      this.checkIsValidEmail(account).then(() => {
        this.nextStepRegister();
      })
    },

    createdPartner() {
      this.loading.createUser = true;
      const infoPartner = {
        cep: this.cep,
        name: this.name,
        email: this.email,
        password: this.password,
        cnpj_or_cpf: this.cnpj_or_cpf,
        commercial_name: this.commercial_name,
        cnpj_status: this.cnpj_status,
        public_place: this.publicPlace,
        neighborhood: this.neighborhood,
        number: this.number,
        complement: this.complement,
        city: this.city,
        state: this.state,
        actuation_field: this.actuation_field,
        role: this.role,
        whatsapp: this.whatsapp,
      };
      if (this.cnpj_status === "") {
        delete infoPartner.commercial_name;
        delete infoPartner.cnpj_status;
      }
      this.createPartner(infoPartner).then(() => {
        this.nextStepRegister();
      }).finally(() => {
        this.loading.createUser = false;
      });
    },

    sendEmail() {
      const accountEmail = {
        email: this.email
      };
      this.resendEmail(accountEmail);
    },

    nextStepRegister() {
      this.buttonDisabled = true;
      this.page++;
      if (this.page > 2) {
        this.stepNumber++;
      }
    },

    prevStepRegister() {
      this.page--;
      this.buttonDisabled = false;
      if (this.page > 1) {
        this.stepNumber--;
      }
      if (this.page === 2) {
        this.stepNumber = -1;
        this.accordionIsOpen = false;
      }
    },

    async verifyCnpjOrCpf() {
      if (this.loading.cnpj) {
        return
      }

      const formattedCnpjOrCpf = this.cnpj_or_cpf.replace(/\D/g, "");

      if (formattedCnpjOrCpf.length === 11) {
        this.zipCodeEnabled = true;
        this.errorMessage.cnpj = "";
        return
      }

      if (formattedCnpjOrCpf.length === 14) {
        this.loading.cnpj = true;
        try {
          await this.checkCnpj(formattedCnpjOrCpf)
          if (this.getCnpj.cnpj === "") {
            throw new Error ('CNPJ inválido*')
          }

          this.errorMessage.cnpj = "";
          this.zipCodeEnabled = true;
          this.commercial_name = this.getCnpj.commercial_name;
          this.cnpj_status = this.getCnpj.cnpj_status;
        }
        catch {
          this.errorMessage.cnpj = "CNPJ inválido*";
        }
        this.loading.cnpj = false;
        return
      }

      this.errorMessage = {};
      this.commercial_name = "";
      this.cnpj_status = "";
      this.zipCodeEnabled = false;
      this.clearZipCode();
    },

    async consultZipCode(zipCode) {
      if (this.loading.cep){
        return
      }

      const formattedZipCode = zipCode.replace(/-/g, "");
      this.errorMessage = {};

      if (formattedZipCode.length === 8) {
        this.loading.cep = true
        await this.zipCode(formattedZipCode);
        this.publicPlace = this.getZipCode.logradouro;
        this.neighborhood = this.getZipCode.bairro;
        this.city = this.getZipCode.localidade;
        this.state = this.getZipCode.uf;

        if (this.state === undefined) {
          this.message = false;
          this.loading.cep = false
          this.errorMessage.cep = "CEP inválido!";
          return;
        }

        this.message = true;
        this.errorMessage = {};
        this.loading.cep = false
      } else {
        this.clearZipCode();
      }
    },

    windowResize() {
      this.resize = window.innerWidth <= 960;
      this.resizeMobile = window.innerWidth <= 768;
      this.resizeHd = window.innerWidth > 1440;
      this.higthRezolution = window.innerWidth > 1920;
    },

    childClicked(boolean) {
      this.accordionIsOpen = boolean;
    },

    verify() {
      const page = this.page;
      const fieldPage = this.getFieldPage(page);
      let isAnyFieldEmpty = false;

      for (const field of fieldPage) {
        if (this[field] === "") {
          isAnyFieldEmpty = true;
          break;
        }
      }

      if (!isAnyFieldEmpty) {
        if (this.fieldVerify()) {
          this.buttonDisabled = false;
        } else {
          this.buttonDisabled = true;
        }
      } else {
        this.buttonDisabled = true;
      }
    },

    verifyPassword(boolean) {
      this.validPassword = boolean;
    },

    fieldVerify() {
      this.errorMessage = {};
      if (this.page === 1) {
        if (!this.isValidEmail(this.email)) {
          this.errorMessage.email = "O e-mail inserido não é válido.";
          return false;
        }
        if (!this.isValidName(this.name)) {
          return false;
        }

        if (!this.validPassword) {
          return false;
        }
      }

      if (this.page === 2) {
        if (this.number === "" && this.complement === "") {
          return false;
        }
      }
      if (this.page === 3) {
        if (this.actuation_field === "" || this.role === "" || this.whatsapp.length < 14) {
          this.errorMessage.whatsapp = "O número de telefone deve ter no mínimo 11 caracteres.";
          return false;
        }
      }

      return true;
    },

    getFieldPage(page) {
      switch (page) {
        case 1:
          return ["name", "email", "password"];
        case 2:
          return ["cnpj_or_cpf", "zipCode", "complement", "number"];
        case 3:
          return ["actuation_field", "role", "whatsapp"];
        default:
          return [];
      }
    },

    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },

    isValidName(name) {
      this.errorMessage.name = null;

      const regex = {
        lastName: /\b\p{L}{2,}(?:-\p{L}+)*\s+\p{L}{2,}(?:-\p{L}+)*\b/u,
        accent: /^[\p{L}\p{M}0-9 ]+$/u,
        numbers: /^\D+$/
      };

      const errorMessages = {
        lastName: "Insira um nome completo",
        accent: "O nome não deve conter caracteres especiais",
        numbers: "O nome não deve conter números"
      };

      Object.keys(errorMessages).forEach(key => {
        if (!regex[key].test(name)) {
          this.errorMessage.name = errorMessages[key];
          return false;
        }
      });

      return true;
    },

    watchData() {
      const keysToWatch = [
        "name",
        "email",
        "validPassword",
        "number",
        "complement",
        "actuation_field",
        "role",
        "whatsapp",
      ];

      for (const key of keysToWatch) {
        this.$watch(key, this.verify, { deep: true });
      }
    },

    clearZipCode() {
      const formattedCnpjOrCpf = this.cnpj_or_cpf.replace(/\D/g, "");

      if (formattedCnpjOrCpf.length <= 10 || (formattedCnpjOrCpf.length > 11 && formattedCnpjOrCpf.length <= 13)) {
        this.cep = "";
      }
      this.message = false;
      this.publicPlace = "";
      this.neighborhood = "";
      this.number = "";
      this.complement = "";
      this.city = "";
      this.state = "";
    },

    handleResend() {
      if (!this.resendDisabled) {
        this.sendEmail();
        this.startResendtimer();
      }
    },

    startResendtimer() {
      this.resendDisabled = true;
      this.intervalId = setInterval(() => {
        if (this.resendTimer > 0) {
          this.resendTimer--;
        } else {
          clearInterval(this.intervalId);
          this.resetResendTimer();
        }
      }, 1000);
    },

    resetResendTimer() {
      this.resendTimer = 60;
      this.resendDisabled = false;
    },

    imageClass() {
      if (this.resizeMobile) {
        return "d-none";
      } else if (this.resize) {
        return "image-position-mobile";
      } else {
        return "";
      }
    },

    dynamicStyle() {
      return {
        height: "680px",
        width: this.resendDisabled ? "725px" : "max-width: 680px"
      };
    },

    checkCapsLock() {
      document.addEventListener("keydown", event => {
        if (typeof event.getModifierState === "function") {
          let capsLockState = event.getModifierState("CapsLock");
          this.capsLockOn = capsLockState;
        }
      });
    }
  },

  computed: {
    ...mapGetters("admin/partners", {
      getCnpj: "getCnpjQueryData",
      getZipCode: "getZipCode"
    })
  }
};
</script>

<style lang="scss" scoped>
.login-card {
  .card {
    box-shadow: 0px 4px 18px 0px rgba(34, 54, 77, 0.12);
    padding: 64px 78px;
  }

  .image-position {
    left: -75px;
    bottom: -50px;
  }

  .image-position-mobile {
    bottom: -30px;
    left: -35px;

    img {
      width: 75px;
    }
  }

  .image-link {
    height: 35px;
    left: 0;
  }

  z-index: 1051 !important;

  .btn-outline-secondary {
    &:hover {
      background: none;
      border-color: $color-gray-200;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .form-floating {
    color: $color-text-gray;
    line-height: 30px;
    margin-top: 22px;

    input {
      height: 48px;
    }
  }

  .password-eye-input {
    border-color: $color-blue-300;
    transition: border-color 0.15s ease-in-out;
  }

  .label-on-border {
    transform: translateY(-18px) !important;
    opacity: 1 !important;
    height: 20px !important;
    padding-top: 2px !important;
    background-color: white !important;
    padding-right: 4px !important;
    padding-left: 4px !important;
  }

  .input-login {
    height: 40px !important;
    padding: 15px !important;
  }

  .input-login.form-control {
    background-color: #ffffff !important;
  }

  .floating-label {
    color: $color-text-200 !important;
  }
}
</style>
