export default {
  
  isLoading: state => state.loading,
  getList: state => state.list,
  getPagination: (state) => state.pagination,
  getParams: (state) =>
    Object.assign(
      {
        'page': state.pagination.current_page,
        'per_page': state.pagination.per_page
      }
      , state.filters, { include: state.includes.join(',') }),
  getTotalLogged: state => state.totalLogged,
  getTotalMaxLogin: state => state.totalMaxLogin,
  getCallStats: state => state.callStats,
  getListCompanies: state => state.listCompanies,
  getListRoutesOfCoupon: state => state.listRoutesOfCoupon,
  getCreatedCouponData: state => state.createdCouponData,
  getRouteData: state => state.routedata,
}
