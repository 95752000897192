<template>
  <div>
    <credits-modal />
  </div>
</template>

<script>
import CreditsModal from "@sms/pages/dashboards/components/modals/credits-modal.vue";

export default {
  components: { CreditsModal },
};
</script>

<style scoped></style>
