<template>
  <div
    id="noBalanceSMSModal"
    ref="noBalanceSMSModal"
    aria-hidden="true"
    aria-labelledby="noBalanceSMSModal"
    class="modal fade"
    tabindex="-1"
  >
    <insufficient-balance-sms
      v-if="insufficientCreditsStep"
      @buy-store-credit="insufficientCreditsStep = false"
    />
    <credits-modal
      v-else
    />
  </div>
</template>
<script>
import InsufficientBalanceSms from "@sms/pages/dashboards/components/insufficient-balance-sms.vue";
import CreditsModal from "@sms/pages/dashboards/components/modals/credits-modal.vue";
import { Modal } from "bootstrap";

export default {
  components: { CreditsModal, InsufficientBalanceSms },
  emits: ["close"],
  props: {
    open: { type: Boolean, default: false },
  },
  data() {
    return {
      insufficientCreditsStep: false,
      noBalanceSMS: null,
    }
  },

  mounted() {
    this.noBalanceSMS = new Modal(this.$refs.noBalanceSMSModal);
    this.$refs.noBalanceSMSModal.addEventListener("hidden.bs.modal", () => this.closeModal())
  },

  watch: {
    open(value) {
      value ? this.openModal() : this.closeModal()
    },
  },

  methods: {
    openModal() {
      this.insufficientCreditsStep = true
      this.noBalanceSMS.show()
    },

    closeModal() {
      this.noBalanceSMS.hide()
      this.$emit("close")
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
