<script setup>
import {defineProps} from "vue"
import TableTooltip from "@crm/pages/dashboard/components/table-tooltip.vue";

const props = defineProps({
  previousLabel: {
    type: String,
    required: true
  },

  currentLabel: {
    type: String,
    required: true
  },

  currentData: {
    type: Array,
    default: () => []
  },

  previousData: {
    type: Array,
    default: () => []
  },

  previousColor: {
    type: Array,
    default: () => ['#C5C5C5']
  },

  currentColor: {
    type: Array,
    default: () => ['#C5C5C5']
  },

  tooltip: {
    type: Boolean,
    default: false
  },

  previous: {
    type: Boolean,
    default: false
  }
});


function getMaxPercent(val) {
  if (val === 0) {
    return 0
  }
  const values = props.currentData.concat(props.previousData)
  return Math.round((val * 100) / Math.max(...values))
}

</script>

<template>
  <table class="w-100">
    <tbody>
      <tr>
        <td
          style="padding-right: 0.25rem"
          class="tx-text-200 fs-6 text-uppercase fw-medium"
        >
          {{ currentLabel }}
        </td>
        <td style="width: 75%; padding-right: 1rem">
          <table-tooltip
            :display="false"
            v-if="tooltip"
            :title="$t('opportunities')"
            :sub-title="currentLabel"
            :on-going="currentData[0]"
            :gained="currentData[1]"
            :lost="currentData[2]"
          >
            <template #label>
              <div class="d-flex">
                <div
                  v-for="(cData, dIndex) in currentData"
                  :key="`data-${dIndex}`"
                  :style="`width: ${getMaxPercent(cData)}%; background-color: ${currentColor[dIndex] || currentColor[0]}; ${getMaxPercent(cData) > 0 ? 'margin-right: 0.25rem;' : ''}`"
                  class="progress-chart"
                />
              </div>
            </template>
          </table-tooltip>
          <div
            v-else
            class="d-flex"
          >
            <div
              v-for="(cData, dIndex) in currentData"
              :key="`data-${dIndex}`"
              :style="`width: ${getMaxPercent(cData)}%; background-color: ${currentColor[dIndex] || currentColor[0]}; ${getMaxPercent(cData) > 0 ? 'margin-right: 0.25rem;' : ''}`"
              class="progress-chart"
            />
          </div>
        </td>
        <td
          v-if="currentData.length === 1"
          class="tx-text-200 fs-6 fw-medium"
        >
          {{ currentData[0] }}
        </td>
      </tr>

      <tr>
        <td
          style="padding-right: 0.25rem"
          class="tx-text-gray fs-6 text-uppercase fw-medium"
        >
          {{ previousLabel }}
        </td>
        <td style="width: 75%; padding-right: 1rem">
          <table-tooltip
            :display="false"
            v-if="tooltip"
            :title="$t('opportunities')"
            :sub-title="previousLabel"
            :gained="previousData[0]"
            :lost="previousData[1]"
          >
            <template #label>
              <div class="d-flex">
                <div
                  v-for="(pData, pIndex) in previousData"
                  :key="`data-${pIndex}`"
                  :style="`width: ${getMaxPercent(pData)}%; background-color: ${previousColor[pIndex] || previousColor[0]}; ${getMaxPercent(pData) > 0 ? 'margin-right: 0.25rem;' : ''}`"
                  class="progress-chart"
                />
              </div>
            </template>
          </table-tooltip>
          <div
            v-else
            class="d-flex"
          >
            <div
              v-for="(pData, pIndex) in previousData"
              :key="`data-${pIndex}`"
              :style="`width: ${getMaxPercent(pData)}%; background-color: ${previousColor[pIndex] || previousColor[0]}; ${getMaxPercent(pData) > 0 ? 'margin-right: 0.25rem;' : ''}`"
              class="progress-chart"
            />
          </div>
        </td>
        <td
          v-if="previousData.length === 1"
          class="tx-text-gray fs-6 fw-medium"
        >
          {{ previousData[0] }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<style scoped lang="scss">

.progress-chart {
  border-radius: 50px;
  background-color: red;
  width: auto;
  transition: all 0.35s ease-in-out;
  min-height: 12px;
  height: auto;
}
</style>