<template>
  <navbar-user v-if="user" />
</template>

<script>
import navbarUser from "./navbar-user.vue";
import { mapGetters } from 'vuex';
export default {
  components: {
    navbarUser,
  },
  computed: {
    ...mapGetters("auth", {
      user: "getUser",
      inImpersonate: "inImpersonate"
    }),
  }
};
</script>
