<template>
  <div class="main-content">
    <div class="input-group input-group-merge input-group-reverse mb-4">
      <input
        class="form-control"
        placeholder="Pesquisar números receptivos"
        v-model="term"
        @keyup="search"
        type="text"
      >
      <div class="input-group-text">
        <span class="fe fe-search" />
      </div>
    </div>

    <div class="row my-4">
      <div
        @click="openReceptiveNumbersModal()"
        class="col-xl-3 col-lg-4 col-md-6 col-sm-12"
      >
        <div
          class="card card-new"
        >
          <div
            class="card-body cursor-pointer"
          >
            <div class="row align-items-center gx-0">
              <div
                class="col overflow-ellipsis"
                style="line-height: 36.19px"
              >
                <span class="h4 mb-0"> Adicionar Número </span>
              </div>
              <div class="col-auto">
                <span class="h2 fe fe-plus text-primary mb-0" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-xl-12 col-lg-12 col-md-12"
        v-if="loadingFlags.findReceptiveNumbers"
      >
        <div class="card-body text-center">
          <div class="spinner-border text-muted">
            <span class="visually-hidden" />
          </div>
        </div>
      </div>
      <div
        class="col-xl-3 col-lg-4 col-md-6 col-sm-12"
        v-for="(item, i) in list"
        :key="i"
      >
        <div
          @click="editReceptiveNumbersModal(item)"
          class="card card-list-item"
          style="cursor: pointer"
        >
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col overflow-ellipsis one-line d-flex flex-column">
                <span class="h4 mb-1">
                  {{ item.phone }}
                </span>
                <small
                  class="text-muted"
                > {{ item.name }}
                </small>
              </div>
              <div class="col-auto">
                <span class="h2 fe fe-chevron-right text-primary mb-0" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <edit-receptive-companies ref="editReceptiveNumbersModal" />
    </div>
    <div class="col-lg-12">
      <paginator
        :pagination="pagination"
        @change-page="changePage"
        v-if="!loadingFlags.findReceptiveNumbers"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import paginator from "@/components/app-paginator.vue";
import EditReceptiveCompanies from "./components/edit-receptive-companies.vue";
import _ from "lodash";

export default {
  data() {
    return {
      term: ""
    }
  },

  mounted() {
    this.findReceptiveNumbers({ id: this.$route.params.id });
  },

  components: {
    EditReceptiveCompanies,
    paginator,
  },

  computed: {
    ...mapGetters('admin/companies', {
      list: "getReceptiveNumbers",
      loadingFlags: "getLoadingFlags",
      pagination: "getReceptiveNumbersPagination",
    })
  },

  methods: {
    ...mapActions('admin/companies', ["findReceptiveNumbers"]),
    ...mapMutations('admin/companies', ["setReceptiveNumbersCurrentPage", "setReceptiveNumbersFilters"]),

    openReceptiveNumbersModal() {
      this.$refs.editReceptiveNumbersModal.new();
    },

    editReceptiveNumbersModal(item) {
      this.$refs.editReceptiveNumbersModal.edit(item);
    },

    changePage(page) {
      this.setReceptiveNumbersCurrentPage(page);
      this.findReceptiveNumbers({ id: this.$route.params.id });
    },

    search: _.debounce(function () {
      this.setReceptiveNumbersCurrentPage(1);
      this.setReceptiveNumbersFilters({ search: this.term });
      this.findReceptiveNumbers({ id: this.$route.params.id });
    }, 300),
  },

}
</script>
