<template>
  <div class="mb-3">
    <label
      for="phone-number"
      class="form-label"
    >
      {{ $t('number') }}
    </label>
    <input
      id="phone-number"
      class="form-control"
      :class="{ 'is-invalid': errors.phone }"
      :placeholder="$t('number')"
      v-model="fields.phone"
      data-test="schedulePhone-input"
    >
    <div class="invalid-feedback">
      {{ errors.phone && errors.phone[0] }}
    </div>
  </div>

  <div class="row mb-3">
    <div class="col">
      <label class="form-label">
        {{ $t('date_capitalize') }}
      </label>
      <flat-pickr
        class="form-control input-date rounded-start"
        :class="{ 'is-invalid': errors.date }"
        :placeholder="$t('date_capitalize')"
        type="text"
        data-input
        :config="config"
        v-model="fields.date"
        data-test="scheduleDate-input"
      />
      <div class="invalid-feedback">
        {{ errors.date && errors.date[0] }}
      </div>
    </div>

    <div class="col">
      <label class="form-label">
        {{ $t('time_capitalize') }}
      </label>
      <flat-pickr
        class="form-control input-date"
        :class="{ 'is-invalid': errors.date }"
        :placeholder="$t('time_capitalize')"
        data-input
        :config="timeConfig"
        v-model="fields.time"
        data-test="scheduleTime-input"
      />
      <div class="invalid-feedback">
        {{ errors.date && errors.date[0] }}
      </div>
    </div>
  </div>

  <div class="form-group mb-3">
    <label class="form-label">
      {{ $t('observations') }}
    </label>
    <textarea
      rows="4"
      class="form-control"
      :class="{ 'is-invalid': errors.note }"
      v-model="fields.note"
      :placeholder="$t('observations')"
      data-test="scheduleNote-input"
    />
    <div class="invalid-feedback">
      {{ errors.note && errors.note[0] }}
    </div>
  </div>

  <div class="row">
    <div class="col">
      <app-button
        class="w-100 text-muted"
        :color="'btn-link'"
        @click="() => $emit('back')"
        data-test="scheduleBack-button"
      >
        {{ $t("back_capitalize") }}
      </app-button>
    </div>
    <div class="col">
      <app-button
        class="w-100"
        @click="submit"
        type="button"
        :loading="loading.qualify || loading.manualQualify"
        :disabled="fields.time === ''"
        data-test="scheduleSubmit-button"
      >
        {{ $t('schedule') }}
      </app-button>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import AppButton from "@/components/app-button"
import { mapGetters } from "vuex";
import { formatTimestamp } from "@/utils";

export default {
  emits: ["schedule", "back"],

  data() {
    return {
      fields: {
        phone: "",
        note: "",
        date: "",
        time: "",
      },
      qualification: null,
      config: {
        locale: Portuguese,
        inline: false,
        altInput: true,
        altFormat: 'd/m/Y',
        minDate: 'today',
      },
      timeConfig: {
        locale: Portuguese,
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
      }
    };
  },

  computed: {
    ...mapGetters("agent/qualification", {
      errors: "getErrors",
      loading: "getLoadingFlags",
    }),

    ...mapGetters("agent/call", {
      call: "getCall",
    }),
  },

  components: {
    flatPickr,
    AppButton
  },

  mounted() {
    this.fields.phone = this.call.phone;
    this.fields.date = formatTimestamp(this.call.connected_time, "Y-M-D");
  },

  methods: {
    submit() {
      this.$emit("schedule", this.fields);
    },
  },
}
</script>
