<template>
  <div class="ballon-message clearfix card-size p-0 not-from-me">
    <div
      class="b-content"
      style="opacity: 1;"
    >
      <div
        v-if="messageData?.components?.HEADER"
        class="mb-2"
      >
        <span v-if="messageData.components.HEADER.text?.length">
          {{ messageData.components.HEADER.text }}
        </span>
        <img
          v-if="messageData.components.HEADER?.format === 'IMAGE'"
          :src="messageData.components.HEADER.media"
          class="mx-auto w-100"
        >
        <video
          v-if="messageData.components.HEADER?.format === 'VIDEO'"
          class="w-100"
          controls=""
        >
          <source :src="messageData.components.HEADER.media">
        </video>
        <div v-if="messageData.components.HEADER?.format === 'DOCUMENT'">
          <h1 class="text-large">
            <i class="fa fa-file-pdf" />
          </h1>
        </div>
      </div>
      <div class="d-flex">
        <div v-if="messageData?.components.BODY?.text?.length">
          {{ messageData.components.BODY.text[0] }}
        </div>
      </div>
      <div
        v-if="messageData?.components.FOOTER?.text?.length"
        class="text-gray fs-6"
      >
        {{ messageData.components.FOOTER.text[0] }}
      </div>
      <div class="d-flex justify-content-end py-2">
        <span class="chat-bottom">
          {{ formatTimestamp(messageData.quality_score.date, 'HH:mm') }}
        </span>
      </div>
      <div
        v-if="messageData?.components?.BUTTONS"
        class="fs-6 mt-2"
      >
        <div
          v-for="(link, index) in messageData?.components.BUTTONS"
          :key="index"
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <hr class="border border-1 opacity-50 my-0 w-100">
          <a
            v-if="link.type === 'URL'"
            :href="link.url"
            target="_blank"
            class="btn btn-link d-flex align-items-center"
          >
            <i class="fal fa-arrow-up-right-from-square me-2" />
            {{ link.text }}
          </a>
          <button
            v-else-if="link.type === 'PHONE_NUMBER'"
            :id="link.phone_number"
            class="btn btn-link d-flex align-items-center"
          >
            <i class="fal fa-phone me-2" />
            {{ link.text }}
          </button>
          <button
            v-else-if="link.type === 'COPY_CODE'"
            :id="link.example[0]"
            class="btn btn-link d-flex align-items-center"
            @click="copyToClipboard(link.example[0])"
          >
            <i class="fal fa-copy me-2" />
            {{ link.text }}
          </button>
          <button
            v-else-if="link.type === 'QUICK_REPLY'"
            class="btn btn-link d-flex align-items-center"
          >
            <i class="fal fa-reply me-2" />
            {{ link.text }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {formatTimestamp} from "@/utils";

export default {
  name: "BallonMessagePreview",
  props: {
    messageData: {type: Object, required: true}
  },
  methods: {
    formatTimestamp,

    copyToClipboard (buttonName){
      navigator.clipboard.writeText(buttonName);
    }
  }
}
</script>
<style lang="scss" scoped>
.card-size {
  &.from-me {
    margin-left: auto;
  }
}
.ballon-message {
  color: $color-text-solid-black !important;
  margin-top: 4px !important;
  .b-content {
    max-width: 100%;
    word-wrap: break-word;
    white-space: pre-line;
    display: inline-block;
    padding: 6px;
    position: relative;
    font-weight: 400;
    font-size: 14px;
    border-radius: 0 8px 8px 8px !important;
    &:after {
      background: url(../../../../assets/img/ballon-message-mask.png) 50% 50% no-repeat;
      background-size: contain;
      content: '';
      height: 31px;
      left: -12px;
      position: absolute;
      top: -6px;
      width: 12px;
    }
    .caption{
      margin-left: 3px;
    }
    .name{
      font-weight: 500 !important;
    }
  }
  &.not-from-me {
    .b-content {
      background-color: #FFFFFF;
      float: left !important;
      border: 1px solid #E1E9F4;
      border-radius: $ballon-radius $ballon-radius $ballon-radius 0px;
    }
  }
}
.chat-bottom {
  position: absolute;
  padding-left: 8px;
  font-size: 10px;
  line-height: 10px;
  right: 6px;
  bottom: 6px;
  color: $color-text-100;
  .fe {
    color: #888888;
  }
}
</style>
