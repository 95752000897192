import QualificationsReport from "@dialer/manager/pages/reports/chat-qualifications/qualifications-report.vue";

export default {
  path: "chats-qualifications-report",
  name: "chats-qualifications-report",
  components: {
    main: QualificationsReport,
  },
  meta: {
    "short-side-bar": true
  }
};
