export default {
  loadingFlags: {
    findInstanceById: false,
    findInstanceStatus: false,
    fetchWhatsappDashboard: false,
    fetchWhatsappDashboardById: false,
    fetchGroupChannels: false,
    fetchDeletedInstances: false,
    postLogoutAgent: false,
    deactivateWhatsappInstance: false,
    createInstances: false,
    getWhatsappQrCode: false,
  },
  pagination: {},
  // TODO: Remove loading and use only loadingFlags
  loading: false,
  loadingContext: "",
  clearInstanceLoading: false,

  // Instance
  instance: null,
  instanceFilters: {},
  instanceIncludes: [],
  strategySettings: {},
  errors: {},

  list: [],
  teamsList: [],
  instanceStatus: [],
  whatsappQrCode: [],
  metrics: {},
  officeHours: [],
  instance_id: 0,

  instancesData: {
    active: [],
    deleted: []
  },
  instancesPagination: {
    active: {},
    deleted: {}
  },
  agents: [],
  agentsPagination: {},
  
  groupChannelAgents: [],
  groupChannelAgentsPagination: {},

  totalActiveInstances: 0,

  openAiSettings: {},
  agentsSelect: []
};
