<template>
  <modal-component
    :center="true"
    :show="showEditActivityModal"
    custom-size="736px"
    @close="closeModalEditActivity"
  >
    <template
      v-if="activity"
      #content
    >
      <div class="modal-header">
        <h5 class="modal-title">
          <i
            :class="getActivityIcon(activity.type)"
            class="fa-regular"
          />
          {{ activity.title }}
        </h5>
        <div class="d-flex align-items-center">
          <div
            v-if="!activity.completed_at"
            class="btn-group dropstart"
          >
            <button
              aria-expanded="false"
              class="btn"
              data-bs-toggle="dropdown"
              type="button"
            >
              <i class="tx-text-gray fa-regular fa-ellipsis-vertical" />
            </button>
            <ul class="dropdown-menu shadow border-0 p-3">
              <li class="dropdown-item p-0">
                <button
                  class="btn btn-exclude text-danger p-0"
                  type="button"
                  @click.stop="deleteActivityFromOpportunity()"
                >
                  <i class="fa-regular fa-trash" />
                  {{ $t('exclude_activity') }}
                </button>
              </li>
            </ul>
          </div>
          <div v-else>
            <div
              v-show="showReopen"
              class="badge bg-text-gray py-2 px-3 rounded-4 cursor-pointer"
              @click="submitActivity('reopen')"
              @mouseleave="showReopen = false"
            >
              <i class="fa-regular fa-arrow-rotate-left me-2" />
              {{ $t('reopen') }}
            </div>
            <div
              v-show="!showReopen"
              class="badge bg-primary py-2 px-3 rounded-4"
              @mouseenter="showReopen = true"
            >
              <i class="fa-regular fa-check me-2" />
              {{ $t('concluded_f') }}
            </div>
          </div>
          <button
            class="btn-close"
            type="button"
            @click="closeModalEditActivity"
          />
        </div>
      </div>
      <div class="modal-body">
        <div class="row">
          <select-opportunity
            v-if="!$route.params.opportunityId && showEditActivityModal"
            v-model="activityPayload.opportunity"
            disabled
          />
          <div
            :class="type === 'meeting' ? 'col-12' : 'col-6'"
            class="mb-3"
          >
            <label
              :class="{'tx-text-100': activity.completed_at}"
              class="form-label required disabled-label"
              for="title"
            >
              {{ $t('title') }}
            </label>
            <input
              id="title"
              v-model="activityPayload.title"
              :class="{
                'is-invalid': errors.title
              }"
              :disabled="activity.completed_at"
              :placeholder="activityTitles[type]"
              class="form-control"
              type="text"
            >
            <div class="invalid-feedback">
              {{ errors.title && errors.title[0] }}
            </div>
          </div>
          <div
            :class="type === 'meeting' ? 'col-7' : 'col-4'"
            class="mb-3"
          >
            <label
              :class="{'tx-text-100': activity.completed_at}"
              class="form-label"
            >
              {{ $t('date_capitalize') }}
            </label>
            <flat-pickr
              v-model="activityPayload.due_date"
              :config="dueDateConfig"
              class="form-control input-date"
              data-input
              data-test="scheduleTime-input"
            />
          </div>
          <div
            v-if="type !== 'meeting'"
            class="col-2 mb-3"
          >
            <label
              :class="{
                'tx-text-100': activity.completed_at
              }"
              class="form-label"
              for="start_time"
            >
              {{ $t('hour') }}
            </label>
            <div class="input-group input-group-merge">
              <flat-pickr
                v-model="activityPayload.start_time"
                :config="timeConfig"
                :disabled="activity.completed_at"
                class="form-control input-date"
                data-input
                data-test="scheduleTime-input"
                placeholder="00:00"
              />
              <div
                class="input-group-text cursor-pointer"
                data-toggle
              >
                <span class="fe fe-clock" />
              </div>
            </div>
          </div>
          <div
            v-if="type === 'meeting'"
            class="col-5 d-flex"
          >
            <div>
              <label
                class="form-label"
                for="start_time"
              >{{ $t('start') }}</label>
              <div class="input-group input-group-merge">
                <flat-pickr
                  v-model="activityPayload.start_time"
                  :config="timeConfig"
                  class="form-control input-date"
                  data-input
                  data-test="scheduleTime-input"
                  placeholder="00:00"
                />
                <div
                  class="input-group-text cursor-pointer"
                  data-toggle
                >
                  <span class="fa-regular fa-clock" />
                </div>
              </div>
            </div>
            <span
              class="fa-regular fa-arrow-right tx-text-gray"
              style="padding: 0 1rem; margin-top: 43px"
            />
            <div>
              <label
                class="form-label"
                for="end_time"
              >{{ $t('end') }}</label>
              <div class="input-group input-group-merge">
                <flat-pickr
                  v-model="activityPayload.end_time"
                  :config="timeConfig"
                  class="form-control input-date"
                  data-input
                  data-test="scheduleTime-input"
                  placeholder="00:00"
                />
                <div
                  class="input-group-text cursor-pointer"
                  data-toggle
                >
                  <span class="fe fe-clock" />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="type === 'meeting'"
            class="col-4"
          >
            <label class="form-label">
              {{ $t('type_general') }}
            </label>
            <div class="d-flex gap-4">
              <div
                v-for="typeMeeting in ['in-person', 'online']"
                :key="typeMeeting"
                class="d-flex"
              >
                <input
                  :id="typeMeeting"
                  v-model="activityPayload.type_meeting"
                  :value="typeMeeting"
                  class="form-check-input me-3"
                  type="radio"
                >
                <label :for="typeMeeting">{{ typeMeeting === "online" ? "Online" : $t('in_person') }}</label>
              </div>
            </div>
          </div>
          <div
            v-if="type === 'meeting'"
            class="col-8"
          >
            <label
              class="form-label"
              for="local_meeting"
            >
              {{ activityPayload.type_meeting === 'in-person' ? $t('local') : 'Link' }}
            </label>
            <div class="position-relative">
              <input
                id="local_meeting"
                v-model="activityPayload.local_meeting"
                :class="{
                  'is-invalid': errors.local_meeting
                }"
                :placeholder="activityPayload.type_meeting === 'in-person' ? $t('select') : 'https://link.com/'"
                class="form-control"
                type="text"
              >
              <div class="position-icon me-3">
                <i
                  :class="{
                    'fa-link': activityPayload.type_meeting === 'online',
                    'fa-location-dot': activityPayload.type_meeting === 'in-person',
                  }"
                  class="fa-regular"
                />
              </div>
            </div>
            <div class="invalid-feedback">
              {{ errors.local_meeting && errors.local_meeting[0] }}
            </div>
          </div>
          <div
            v-if="type === 'email'"
            class="col-12 mb-3"
          >
            <label
              class="form-label required"
              for="email"
            >E-mail </label>
            <select
              id="email"
              v-model="activityPayload.email"
              :class="{
                'is-invalid': errors.email
              }"
              class="form-select"
            >
              <option
                v-if="
                  !currentOpportunity ||
                    currentOpportunity.contacts.reduce((acc, contact) => acc + contact.emails.length, 0) <= 0
                "
                value=""
              >
                {{ $t('no_registered_mail') }}
              </option>
              <template v-else>
                <optgroup
                  v-for="contact in currentOpportunity.contacts"
                  :key="contact.id"
                  :label="contact.name"
                >
                  <option
                    v-for="email in contact.emails"
                    :key="email.email"
                    :value="email.email"
                  >
                    {{ email.email }}
                  </option>
                </optgroup>
              </template>
            </select>
            <div class="invalid-feedback">
              {{ errors.email && errors.email[0] }}
            </div>
          </div>
          <div
            v-if="['message', 'call'].includes(type)"
            :class="type === 'message' ? 'col-6' : 'col-12'"
            class="mb-3"
          >
            <label
              :class="{
                'tx-text-100': activity.completed_at
              }"
              class="form-label required"
              for="phone"
            >{{ $t('number') }} </label>
            <select
              id="phone"
              v-model="activityPayload.phone"
              :class="{
                'is-invalid': errors.phone,
                'tx-text-100': activity.completed_at
              }"
              :disabled="activity.completed_at"
              class="form-select"
            >
              <option
                v-if="
                  !currentOpportunity ||
                    currentOpportunity.contacts.reduce((acc, contact) => acc + contact.phones.length, 0) <= 0
                "
                value=""
              >
                {{ $t('no_registered_phone') }}
              </option>
              <template v-else>
                <optgroup
                  v-for="contact in currentOpportunity.contacts"
                  :key="contact.id"
                  :label="contact.name"
                >
                  <option
                    v-for="phone in contact.phones"
                    :key="phone.number"
                    :value="phone.number"
                  >
                    {{ formatPhoneNumber(phone.number) }}
                  </option>
                </optgroup>
              </template>
            </select>
            <div class="invalid-feedback">
              {{ errors.phone && errors.phone[0] }}
            </div>
          </div>
          <div
            v-if="type === 'message'"
            class="col-6 mb-3"
          >
            <label
              :class="{
                'tx-text-100': activity.completed_at
              }"
              class="form-label required"
            >
              {{ $t('send_from') }}
            </label>
            <select-channel
              :disabled="activity.completed_at"
              @select-channel="channel => (activityPayload.instance = channel)"
            />
          </div>
          <div class="mb-3">
            <label
              class="form-label"
              for="description"
            >{{ $t('description') }}</label>
            <textarea
              id="description"
              ref="description"
              v-model="activityPayload.description"
              :class="{
                'is-invalid': errors.description,
              }"
              class="form-control"
              style="resize: none;"
              @input="autoGrowTextArea"
            />
            <div class="invalid-feedback">
              {{ errors.description && errors.description[0] }}
            </div>
          </div>
        </div>
        <div v-if="showEditActivityModal">
          <div
            ref="linkCollapse"
            class="collapse"
          >
            <link-field-list
              v-model="activityPayload.linkList"
              @close="closeLinkCollapse"
              @delete="deleteAttachment"
            />
          </div>
          <div
            ref="internalNoteCollapse"
            class="collapse mb-3"
          >
            <note-field-list
              v-model="activityPayload.noteList"
              @close="closeNotesCollapse"
              @delete="deleteAttachment"
            />
          </div>
          <div
            ref="attachmentCollapse"
            class="collapse mb-3"
          >
            <attachment-field-list
              v-model="activityPayload.attachmentList"
              @close="closeAttachCollapse"
              @delete="deleteAttachment"
            />
          </div>
        </div>
        <div class="d-flex gap-4">
          <button
            v-if="false"
            class="btn p-0"
            @click="openAttachCollapse"
          >
            <i class="fa-regular fa-paperclip" />
          </button>
          <button
            v-if="isNotesCollapseClose"
            class="btn p-0"
            @click="openNotesCollapse"
          >
            <i class="fa-regular fa-note m-0" />
          </button>
          <button
            v-if="isLinksCollapseClose"
            class="btn p-0"
            @click="openLinkCollapse"
          >
            <i class="fa-regular fa-link" />
          </button>
        </div>
        <div class="d-flex flex-column mt-3">
          <small class="tx-text-gray">
            {{ activity.owner.name }} {{ $t('create_this_task') }} • {{ created_at }}
          </small>
          <small
            v-if="activity.completed_by"
            class="tx-text-gray"
          >
            {{ activity.completed_by.name }} {{ $t('conclude_this_task') }} • {{ completed_at }}
          </small>
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="btn me-auto tx-text-gray"
          @click="closeModalEditActivity"
        >
          {{ $t("cancel_general") }}
        </button>
        <app-button
          v-if="!activity.completed_at && !formChanged"
          @click="submitActivity('complete')"
        >
          <i class="fa-regular fa-check me-2" />
          <span>{{ $t('conclude') }}</span>
        </app-button>
        <app-button
          v-if="!activity.completed_at && formChanged"
          class="tx-text-gray"
          color="bg-secondary-soft"
          @click="submitActivity('complete')"
        >
          <i class="fa-regular fa-check me-2" />
          <span>{{ $t('save_and_conclude') }}</span>
        </app-button>
        <app-button
          v-if="formChanged"
          @click="submitActivity('edit')"
        >
          {{ $t('save_changes') }}
        </app-button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from "@components/modal-component.vue";
import AppButton from "@components/app-button.vue";
import {getActivityFormData, getActivityIcon} from "@crm/utills";
import {autoGrowTextArea, formatPhoneNumber} from "@/utils";
import {mapActions, mapGetters} from "vuex";
import FlatPickr from "vue-flatpickr-component";
import {Portuguese} from "flatpickr/dist/l10n/pt.js";
import SelectChannel from "@components/select-channel.vue";
import LinkFieldList from "@crm/components/activity/activity-fields/link-field-list.vue";
import NoteFieldList from "@crm/components/activity/activity-fields/note-field-list.vue";
import AttachmentFieldList from "@crm/components/activity/activity-fields/attachment-field-list.vue";
import {Collapse} from "bootstrap";
import moment from "moment";
import SelectOpportunity from "@crm/components/activity/form-activity/select-opportunity.vue";

export default {
  emits: ["close", "reopen"],
  name: "ModalEditActivity",
  data() {
    return {
      type: "",
      attachesCollapse: null,
      notesCollapse: null,
      linksCollapse: null,
      isAttachesCollapseClose: true,
      isNotesCollapseClose: true,
      isLinksCollapseClose: true,
      initialFormData: {},
      unwatch: () => {
      },
      formChanged: false,
      activityPayload: {
        opportunity: null,
        email: null,
        title: null,
        phone: null,
        due_date: null,
        instance: null,
        is_completed: false,
        attachments: [],
        type_meeting: "in_person",
        local_meeting: null,
        start_time: null,
        end_time: null,
        description: null,
        attachmentList: [],
        noteList: [],
        linkList: []
      },
      timeConfig: {
        locale: Portuguese,
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i"
      },
      dueDateConfig: {
        locale: Portuguese,
        inline: false,
        dateFormat: "d/m/Y",
        wrap: true,
        allowInput: true,
      },
      activityTitles: {
        task: this.$t('task'),
        call: this.$t('call'),
        message: this.$t('message'),
        email: "E-mail",
        meeting: this.$t('meeting')
      },
      showReopen: false
    };
  },

  components: {
    AttachmentFieldList,
    NoteFieldList,
    LinkFieldList,
    SelectChannel,
    AppButton,
    ModalComponent,
    FlatPickr,
    SelectOpportunity
  },

  props: {
    showEditActivityModal: {
      type: Boolean,
      default: false
    },
    activity: {
      type: Object,
      default: null
    },
    icon: {
      type: String,
      default: "fa-task"
    }
  },
  computed: {
    ...mapGetters("crm/activities", {
      errorsData: "getErrors"
    }),
    ...mapGetters("crm/opportunities", {
      opportunity: "getOpportunity",
      currentTab: "getCurrentTabTimeline",
    }),
    errors() {
      return this.errorsData.updateActivity;
    },
    created_at() {
      return moment(this.activityPayload.created_at).format("DD/MM/YYYY - HH:mm");
    },
    completed_at() {
      return moment(this.activityPayload.completed_at).format("DD/MM/YYYY - HH:mm");
    },
    filledAttachments() {
      let filteredAttachments = [];
      const attachments = [
        ...this.activityPayload.attachmentList,
        ...this.activityPayload.noteList.filter((note) => note.content !== ""),
        ...this.activityPayload.linkList.filter((link) => link.content !== "")
      ];

      const initialFormData = [
        ...this.initialFormData.attachmentList,
        ...this.initialFormData.noteList,
        ...this.initialFormData.linkList
      ];

      attachments.forEach((attachment) => {
        const item = initialFormData.find((att) => JSON.stringify(att) === JSON.stringify(attachment));
        if (!item) {
          filteredAttachments.push(attachment);
        }
      });

      return filteredAttachments;
    },

    currentOpportunity() {
      return this.opportunity || this.activity.opportunity;
    }
  },
  methods: {
    ...mapActions("crm/activities", {
      deleteActivity: "deleteActivity",
      closeModal: "closeModal",
      updateActivity: "updateActivity",
      completeActivity: "completeActivity"
    }),
    ...mapActions('crm/opportunities', ["fetchOpportunityLogs"]),
    getActivityIcon,
    autoGrowTextArea,
    formatPhoneNumber,

    deleteAttachment(id) {
      this.activityPayload.attachments_to_delete.push(Number(id));
    },

    async submitActivity(mode) {
      const opportunityId = this.opportunity ? this.opportunity.id : undefined;

      if (mode === "reopen") {
        this.completeActivity({
          activityId: this.activity.id,
          opportunityId,
          currentTab: this.currentTab,
          activityType: this.activity.type,
          completed_at: this.activity.completed_at
        }).then(activity => {
          this.$emit("reopen", activity)
        })
        return
      }

      if (mode === 'edit' || this.formChanged) {
        this.activityPayload.attachments = this.filledAttachments;
        const formData = getActivityFormData(this.activityPayload, this.activity.type);
        await this.updateActivity({
          activity: formData,
          opportunityId
        });
      }
      if (mode === "complete") {
        await this.completeActivity({
          activityId: this.activity.id,
          opportunityId,
          currentTab: this.currentTab,
          activityType: this.activity.type,
        });
      }

      if (this.activity.completed_at) {
        this.fetchOpportunityLogs({
          opportunityId: opportunityId,
          payload: {
            contacts_ids: this.currentOpportunity.contacts.map(contact => contact.id)
          }
        });
      }
      this.closeModalEditActivity();
    },

    deleteActivityFromOpportunity() {
      this.deleteActivity({
        activityId: this.activity.id,
        opportunityId: this.$route.params.opportunityId
      }).then(() => {
        this.closeModal();
      });
    },
    openAttachCollapse() {
      if (this.attachesCollapse) {
        this.attachesCollapse.show();
        this.isAttachesCollapseClose = false;
      }
    },
    openNotesCollapse() {
      if (this.notesCollapse) {
        this.notesCollapse.show();
        this.isNotesCollapseClose = false;
      }
    },
    openLinkCollapse() {
      if (this.linksCollapse) {
        this.linksCollapse.show();
        this.isLinksCollapseClose = false;
      }
    },
    closeAttachCollapse() {
      if (this.attachesCollapse) {
        this.attachesCollapse.hide();
        this.isAttachesCollapseClose = true;
        this.activityPayload.attachmentList = [];
      }
    },
    closeNotesCollapse() {
      if (this.notesCollapse) {
        this.notesCollapse.hide();
        this.isNotesCollapseClose = true;
        this.activityPayload.noteList = [];
      }
    },
    closeLinkCollapse() {
      if (this.linksCollapse) {
        this.linksCollapse.hide();
        this.isLinksCollapseClose = true;
        this.activityPayload.linkList = [];
      }
    },

    closeModalEditActivity() {
      this.$emit("close");
      this.formChanged = false;
      this.unwatch();
    },

    clearPayload() {
      this.activityPayload = {
        opportunity: null,
        email: null,
        title: null,
        phone: null,
        due_date: null,
        instance: null,
        is_completed: false,
        attachments: [],
        type_meeting: "in_person",
        local_meeting: null,
        start_time: null,
        end_time: null,
        description: null,
        attachments_to_delete: [],
        attachmentList: [],
        noteList: [],
        linkList: []
      };
      this.isAttachesCollapseClose = true;
      this.isNotesCollapseClose = true;
      this.isLinksCollapseClose = true;
    },

    async populateFormData() {
      return new Promise(resolve => {
        this.clearPayload();

        setTimeout(() => {
          this.attachesCollapse = new Collapse(this.$refs.attachmentCollapse, {toggle: false});
          this.notesCollapse = new Collapse(this.$refs.internalNoteCollapse, {toggle: false});
          this.linksCollapse = new Collapse(this.$refs.linkCollapse, {toggle: false});

          if (this.activityPayload.attachmentList.length) {
            this.openAttachCollapse();
          }

          if (this.activityPayload.noteList.length) {
            this.openNotesCollapse();
          }

          if (this.activityPayload.linkList.length) {
            this.openLinkCollapse();
          }

          this.autoGrowTextArea({target: this.$refs.description});
        }, 300);

        this.type = this.activity.type;
        this.activityPayload.id = this.activity.id;
        this.activityPayload.email = this.activity.email;
        this.activityPayload.title = this.activity.title;
        this.activityPayload.phone = this.activity.phone;
        this.activityPayload.instance = this.activity.instance_id;
        this.activityPayload.is_completed = !!this.activity.completed_at;
        this.activityPayload.attachments = this.activity.attachments || [];
        this.activityPayload.type_meeting = this.activity.type_meeting;
        this.activityPayload.local_meeting = this.activity.local_meeting;
        this.activityPayload.description = this.activity.description;

        this.activityPayload.opportunity = this.activity.opportunity;

        this.activityPayload.due_date = moment(this.activity.due_date).format("DD/MM/YYYY");
        this.activityPayload.start_time = this.activity.start_time
          ? moment.duration(this.activity.start_time).format("HH:mm")
          : moment(this.activity.due_date).format("HH:mm");
        this.activityPayload.end_time = moment.duration(this.activity.end_time).format("HH:mm");

        if (this.activity.attachments && this.activity.attachments.length) {
          this.activityPayload.attachmentList = this.activity.attachments.filter(attachment =>
            ["document", "image", "video", "audio"].includes(attachment.type)
          );
          this.activityPayload.noteList = this.activity.attachments.filter(
            attachment => attachment.type === "internal-note"
          );
          this.activityPayload.linkList = this.activity.attachments.filter(attachment => attachment.type === "link");
        }
        this.initialFormData = JSON.parse(JSON.stringify(this.activityPayload));
        resolve();
      });
    }
  },

  watch: {
    async showEditActivityModal(data) {
      if (data) {
        await this.populateFormData();
        this.unwatch = this.$watch(
          "activityPayload",
          () => {
            this.formChanged = JSON.stringify(this.initialFormData) !== JSON.stringify(this.activityPayload);
          },
          {deep: true}
        );
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.position-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%)
}

.btn-exclude {
  &:hover {
    color: #d1220f !important;
  }
}
</style>
