export default {
  loading: {
    searchAgentsReport: false,
    searchQualifications: false,
    downloadTable: false,
    searchConnectedCallsData: false
  },
  errorMessage: '',
  errors: {},
  reportList: [],
  qualificationList: [],

  // calls chart
  connectedCallsData: [],
}
