<template>
  <div class="d-flex justify-content-start my-3">
    <span class="col-4 preview-item">
      {{ item.label }}
      <span
        v-if="item.is_required"
        class="text-danger"
      >*
      </span>
    </span>
    <div
      v-if="lockEdit && enableEdit"
      class="d-flex"
    >
      <input
        v-model="text"
        :class="{ 'is-invalid': errorMessage && errorMessage.length > 0 }"
        class="form-control"
        type="text"
      >
      <div
        v-if="contactId"
        class="d-flex"
      >
        <button
          :disabled="text.length === 0"
          class="btn btn-transparent"
          @click="submitForm()"
        >
          <i class="fal fa-check" />
        </button>
        <button
          class="btn btn-transparent"
          @click="close"
        >
          <i class="fal fa-xmark" />
        </button>
      </div>
    </div>
    <div
      v-else
      class="w-100 cursor-pointer"
      @click="enableEdit = true"
    >
      <span
        v-if="item.answers && item.answers.length > 0 && contactId"
        class="preview-item"
      >
        <span
          v-for="(answ, i) in item.answers"
          :key="i"
          class="text-black-50"
        >
          <span v-if="answ.contact_id === contactId">
            {{ answ.answer }}
          </span>
        </span>
      </span>
      <span
        v-else
        class="preview-item"
      >-
      </span>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AnswersFormInputRow",
  props: {
    item: {type: Object, default: null},
    lockEdit: {type: Boolean, default: false},
    contactId: {type: String, default: null}
  },
  data () {
    return {
      enableEdit: false,
      text: '',
      errorMessage: ''
    }
  },
  computed: {
    ...mapGetters("auth", {user: "getUser"})
  },
  methods: {
    ...mapActions('crm/contacts', ['setAnswers']),

    submitForm () {
      if (this.text.length === 0) {
        this.errorMessage = this.$t('required_field')
      } else {
        this.setAnswers({
          company_id: this.user.company.id,
          answer: this.text,
          contact_id: this.contactId,
          form_input_id: this.item.id
        })
        this.close()
      }
    },
    close () {
      this.enableEdit = false
      this.text = ''
      this.errorMessage = ''
    }
  }
}
</script>