<template>
  <div
    class="main-content pb-6"
    :class="users.company.blocked_sms ? 'blur-effect' : ''"
  >
    <div class="container-fluid">
      <header-component title="SMS">
        <template #buttons>
          <div
            class="col-auto d-flex align-items-center justify-content-end px-0"
          >
            <div>
              <strong>{{ users.company.credit_sms }}</strong>
              <span class="ms-2">{{ users.company.credit_sms > 1 ? $t("available_sms_plural") : $t("available_sms")
              }}</span>
            </div>
            <div style="margin-left: 2rem">
              <button
                class="btn btn-primary px-6"
                @click="openBuyCreditsModal()"
              >
                {{ $t("add_sms") }}
              </button>
              <buy-credits-sms
                ref="buyCreditsSms"
                @bought-sms="updateSms()"
                @no-credits="openSmsCredits = true"
              />
            </div>
          </div>
        </template>
      </header-component>
      <div class="tab-content">
        <div
          v-show="data.length"
          id="metrics"
          class="tab-pane fade show active"
          role="tabpanel"
        >
          <bar-chart
            :title="$t('agents_metrics_per_period')"
            :labels="chartLabel"
            :datasets="[
              { label: $t('not_delivered'), color: '#E63757' },
              { label: $t('delivered'), color: '#5cb85c' },
              { label: $t('not_send'), color: '#B1C2D9' }
            ]"
            :chart-data="chartData"
          />
        </div>
      </div>
      <div
        id="contactsListPane"
        aria-labelledby="contactsListTab"
        class="tab-pane fade show active col-auto"
        role="tabpanel"
      >
        <div
          id="usersList"
          class="card"
        >
          <div class="card-header">
            <h4 class="card-header-title fw-normal">
              {{ $t("uppercases.lists") }}
            </h4>
            <div class="col-auto">
              <mailing-form-sms />
            </div>
          </div>
          <div class="card-body px-0 py-0">
            <div class="tab-content">
              <div
                id="actives"
                class="table-responsive tab-pane active"
                role="tabpanel"
              >
                <table
                  class="table table-hover table-nowrap card-table"
                >
                  <table-header />
                  <table-body
                    :list="list"
                    @open-modal="(payload) => openModal(payload)"
                  />
                </table>
                <div class="card-footer">
                  <div class="col-lg-12">
                    <paginator
                      v-if="!loading"
                      :pagination="pagination"
                      @change-page="changePage"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <report-modal
        v-model="openReportModal"
        :report-type="reportType || ''"
        :list-id="listId"
      />
    </div>
  </div>
  <sms-credits-modal
    :open="openSmsCredits"
    @close="openSmsCredits = false"
  />
  <blocked-sms-modal />
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import BarChart from "@dialer/manager/components/bar-chart";
import BuyCreditsSms from "./components/buy-credits-sms.vue";
import MailingFormSms from "./components/modals/mailing-form-sms.vue";
import moment from "moment";
import Paginator from "@components/app-paginator.vue";
import HeaderComponent from "@/components/header-component.vue";
import TableHeader from "@sms/pages/dashboards/components/mailing-list/table-header.vue";
import TableBody from "@sms/pages/dashboards/components/mailing-list/table-body.vue";
import ReportModal from "@sms/pages/dashboards/components/modals/report-modal.vue";
import SmsCreditsModal from "@sms/pages/dashboards/components/modals/sms-credits-modal.vue";
import BlockedSmsModal from "@sms/pages/dashboards/components/modals/blocked-sms-modal.vue";

export default {
  components: {
    BlockedSmsModal,
    SmsCreditsModal,
    ReportModal,
    TableBody,
    TableHeader,
    BarChart,
    BuyCreditsSms,
    Paginator,
    MailingFormSms,
    HeaderComponent,
  },
  data() {
    return {
      chartLabel: [],
      chartData: [],
      listId: 0,
      openSmsCredits: false,
      openReportModal: false,
      reportType: null,
    };
  },
  computed: {
    ...mapGetters("manager/sms_dashboard", {
      list: "getList",
      data: "getChartData",
      pagination: "getPagination",
      loading: "isLoading",
    }),
    ...mapGetters("auth", {
      users: "getUser",
    }),
  },

  mounted() {
    this.getChartData({
      startDate: moment()
          .subtract(30, "day")
          .format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    }).then(() => {
      this.chartData = [[], [], []];
      this.chartLabel = this.data.map(el => moment(el.date).format("D MMM"));
      this.chartData[0] = this.data.map(el => el.failure);
      this.chartData[1] = this.data.map(el => el.success);
      this.chartData[2] = this.data.map(el => el.not_sent);
    });
  },

  methods: {
    ...mapActions("manager/sms_dashboard", [
      "getMailingList",
      "getChartData",
    ]),

    ...mapActions("auth", ["me"]),

    openModal({ id, type }) {
      this.listId = id
      this.reportType = type
      this.openReportModal = true
    },

    updateSms() {
      this.me()
    },
    openBuyCreditsModal() {
      this.$refs.buyCreditsSms.new();
    },
    changePage(page) {
      this.getMailingList({ page })
    },
  },
};
</script>
<style lang="scss" scoped>
.blur-effect {
  filter: blur(2px) !important;
}
</style>
