<script setup>
import {computed} from "vue"
import {useStore} from "vuex";
import {useDashboardQuery} from "@/composables/useDashboardQuery";
import DashCard from "@crm/pages/dashboard/components/dash-card.vue";
import ProgressRateChart from "@crm/pages/dashboard/components/charts/progress-rate-chart.vue";

const store = useStore();
const dateRange = computed(() => store.getters["crm/dashboard/getDateRange"])
const currentFunnel = computed(() => store.getters["crm/funnels/getCurrentFunnel"] ?? null);
const agent = computed(() => store.getters["crm/dashboard/getActiveAgent"] ?? null);

const {data, loading} = useDashboardQuery(
  currentFunnel,
  dateRange,
  dateRange,
  agent,
  'progressRateWidget'
);

</script>

<template>
  <dash-card
    :title="$t('progress_rate')"
    :tooltip-title="$t('tooltips.dashboard.progress_rate.title')"
    :tooltip-body="$t('tooltips.dashboard.progress_rate.body')"
    tooltip-width="312px"
    :loading="loading"
    min-height="72"
  >
    <template #body>
      <progress-rate-chart
        v-if="data?.progress_rate?.length"
        :items="data?.progress_rate"
      />
    </template>
  </dash-card>
</template>

<style scoped lang="scss">

</style>