<template>
  <div
    v-if="campaign"
    ref="campaignDiv"
    class="card logout-campaign w-auto border mb-0"
    style="max-width: 1200px !important;margin-bottom: 16px !important;"
  >
    <div class="card-body p-2">
      <div class="d-flex flex-row justify-content-center align-items-center w-100">
        <div
          ref="campaignText"
          :class="{ 'overflow-ellipsis': isOverflowActive }"
          class="text-start py-3 d-flex align-items-center gap-2 w-auto"
        >
          <span class="fw-bold h4 mb-0">{{ $t('campaign_capitalize') }}: </span>
          <span class="fw-normal h4 text-truncate mb-0">{{ campaign.name }}</span>
        </div>
        <div
          class="border mx-5"
          style="width: 2px;height:31px;"
        />
        <button
          :disabled="loading"
          class="btn btn-primary btn px-4 h5 mb-0 py-0 d-flex align-items-center justify-content-center"
          style="min-width:200px;max-width: 280px;"
          @click="_logout()"
        >
          <i
            class="fa-solid fa-x m-0 pe-3"
            style="font-size: 13px;"
          />
          <h5 class="m-0">
            {{ $t('exit_campaign') }}
          </h5>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {AgentStatus} from "@/utils";
import {mapActions, mapGetters} from "vuex";

export default {
  emits: ["close"],
  data() {
    return {
      AgentStatus,
      isOverflowActive: false
    };
  },

  computed: {
    ...mapGetters("system", {
      status: "getStatus",
      mode: "getMode",
    }),
    ...mapGetters("agent/agent", {
      campaign: "getCampaign",
      loading: "isLoading",

    }),
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {

    ...mapActions("agent/agent", ["logout"]),
    _logout() {
      this.logout();
      this.$emit("close");
    },
    handleResize() {
      const campaignDivWidth = this.$refs.campaignDiv.clientWidth;
      const campaignTextWidth = this.$refs.campaignText.clientWidth;
      this.isOverflowActive = campaignTextWidth > campaignDivWidth;
    }
  },
};
</script>

<style
    lang="scss"
    scoped
>
.logout-campaign {
  display: inline-block !important;
  padding: 10px 28px;
  width: 980px;

  .btn {
    height: 32px;
  }
}


</style>
