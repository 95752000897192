<template>
  <action-input
    v-model="nameField"
    @update:model-value="updateField()"
    input-type="text"
  />
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import ActionInput from "@crm/pages/opportunity/components/action-input.vue";

export default {
  components: {ActionInput},
  props: {
    contact: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      nameField: "",
    }
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser"
    })
  },

  created() {
    this.nameField = this.contact.name
  },

  methods: {
    ...mapActions('crm/contacts', [
      'updateContact'
    ]),

    updateField() {
      if (!this.nameField) {
        this.nameField = this.contact.name
        return
      }

      this.updateContact({
        contactId: this.contact.id,
        payload: {
          name_alias: this.nameField,
          company_id: this.user.company.id,
        }
      })
    },
  }
};
</script>
