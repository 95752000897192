<template>
  <div class="col-12 col-xl-6">
    <doughnut-chart
      v-if="!loadingFlag?.findQualificationsMetrics"
      :title="$t('capitalizes.qualifications')"
      :chart-data="doughnutChartData"
      :label-on-side="true"
      :labels="chartLabels"
      :colors="chartColors"
    >
      <template #empty-state>
        <empty-state
          class="mx-auto px-5 d-flex"
          icon="qualification.svg"
          :title="$t('empty_qualification')"
          :sub-title="$t('no_qualification_registered')"
        />
      </template>
    </doughnut-chart>
    <card-loading
      v-else
      :height="350"
    />
  </div>
</template>

<script>
import doughnutChart from "@dialer/manager/components/doughnut-chart";
import { mapActions, mapGetters } from "vuex";
import EmptyState from "@components/empty-state.vue";
import CardLoading from "@components/card-loading.vue";

export default {
  data() {
    return {
      doughnutChartData: [],
      chartLabels: [],
      chartColors: [],
    }
  },
  components: {
    CardLoading,
    EmptyState,
    doughnutChart,
  },
  computed: {
    ...mapGetters("manager/receptive_queues", {
      qualificationsMetrics: "getQualificationsMetrics",
      loadingFlag: "getLoadingFlags",
    }),
  },
  methods: {
    ...mapActions("manager/receptive_queues", ["findQualificationsMetrics", "clearQualificationsMetrics"]),
    fillChartData() {
      this.doughnutChartData = [];
      this.chartLabels = [];
      this.chartColors = [];

      if (this.qualificationsMetrics?.[0]?.qualifications.length) {
        this.qualificationsMetrics[0].qualifications.forEach(element => {
          this.doughnutChartData.push(element.count);
          this.chartLabels.push(element.name);
          this.chartColors.push(element.color);
        });
      }
    },
  },
  mounted() {
    this.findQualificationsMetrics({ id: this.$router.currentRoute._rawValue.params.id });
  },
  watch: {
    qualificationsMetrics(data) {
      if (data)
        this.fillChartData();
    },
  },
  beforeUnmount() {
    this.clearQualificationsMetrics();
  },
}
</script>
