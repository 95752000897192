export default {
    isLoading: (state) => state.loading,
    getLoadingContext: state => state.loadingContext,
    getLoadingFlags: state => state.loadingFlags,
    getErrorMessage: (state) => state.errorMessage,
    getErrors: (state) => state.errors,
    getCall: (state) => state.call,
    getManualCallACW: (state) => state.manualCallACW,
    getMailing: (state) => state.mailing,
    getCallHistory: (state) => state.callHistory,
    getAgentCallStatus: (state) => state.agentCallStatus,
    getCallHistoryNumberCalling: (state) => state.callHistoryNumberCalling,
    getWhatsappCallCurrentNumber: (state) => state.whatsappCallCurrentNumber,
    getCampaignMetricsCharts: (state) => state.campaignMetricsCharts
}
