export default {
  funnels: [],
  pagination: {},
  currentFunnel: null,
  loadingFlags: {
    findFunnels: false,
    findFunnel: false,
    selectFunnels: false,
  },
  errorsMessage: [],
  currentStepId: null,
  selectedStep: null,
}
