<template>
  <div
    id="modal"
    ref="modal"
    aria-hidden="true"
    aria-labelledby="modal"
    class="modal fade"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="d-flex flex-column justify-content-center text-center py-4">
          <div class="d-flex justify-content-center my-4">
            <div class="col-5">
              <img
                alt=""
                class="img-fluid"
                src="@assets/img/foraDoAr.svg"
              >
            </div>
          </div>
          <h2 class="form-label my-2">
            SMS bloqueado!
          </h2>
          <div class="d-flex justify-content-center my-2">
            <h4 class="form-label col-6 fw-light">
              Sua empresa teve o sistema SMS bloqueado por violar nossas diretrizes de uso
            </h4>
          </div>
          <hr class="navbar-divider">
          <h5 class="form-label my-2 fw-light">
            Houve um engano? Entre em contato conosco
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Modal} from 'bootstrap'
import {mapGetters} from "vuex";

export default {
  data () {
    return {
      modal: null
    }
  },
  computed: {
    ...mapGetters("auth", {
      users: "getUser"
    })
  },
  mounted () {
    if (this.users.company.blocked_sms) {
      this.createDisableModal()
    }
  },
  methods: {
    createDisableModal () {
      this.modal = new Modal(this.$refs.modal, {
        backdrop: "static",
        keyboard: false
      });
      this.modal.show();
    },
  },
  unmounted () {
    if (this.modal !== null) {
      this.modal.dispose()
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
