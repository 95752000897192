export default {
    getLoadingFlags: state => state.loadingFlags,
    getManualCallACWQualified: state => state.manualCallACWQualified,
    getList(state) {
        return state.list
    },
    getCallQualificated(state) {
        return state.callQualificated;
    },
    getErrorMessage(state) {
        return state.errorMessage;
    },
    getErrors(state) {
        return state.errors;
    },
}
