<template>
  <div class="p-0 file-message d-flex justify-content-start align-items-center flex-row w-100 h-100">    
    <div
      class="image-container h5 mb-0"      
    >
      <i class="fe fe-file rounded-circle d-flex justify-content-center align-items-center h-100 h4 bg-yellow-300 m-0" />
    </div>
        
    <div
      class="content-container"
    >
      <p
        class="h6 mb-0 text-truncate"
        :class="{ 'text-decoration-line-through': isDeleted }"
      >
        {{ fileName }}
      </p>
      <small
        class="fw-light"
        style="font-size: 15px !important;"
      >{{ formatBytes(size) }}</small>
    </div>
    <div
      class="download-container d-flex justify-content-center align-items-center rounded-circle"      
    >
      <img
        class="download"
        src="@/assets/img/download.svg"
        @click="download()"
      >
    </div>
  </div>
  <div
    v-if="!text"
    class="w-100 col-12 d-flex has-no-caption"
  />
  <div
    class="pe-5 b8 b1 caption"
    v-if="text"
  >
    <div
      class="me-3"
      :class="{ 'text-decoration-line-through': isDeleted }"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
import { Utils } from "@/utils";

export default {
  props: {
    fileName: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 0,
    },
    isDeleted: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    }
  },
  methods: {
    formatBytes: Utils.formatBytes,
    download() {
      window.open(this.url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.file-message {
  height: 44px !important;
  .image-container {
    width: 36px;
    height: 36px;
    margin-right: 0.5rem;
    .fe-file {
      color: $color-text-gray;
    }
  }

  .content-container {    
    margin-top: 0.3rem;
    border-right: 1px solid $color-gray-blue-300;
    padding-right: 1rem;
    width: 56%;
  }

  .download-container {
    width: 2rem;
    height: 2rem;
    margin-left: 1rem;
    border: 1px solid $color-text-gray;
    .download {
      width: 15px;
      cursor: pointer;
    }
  }
}
</style>
