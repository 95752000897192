<template>
  <modal-component
    :show="showModal"
    :center="true"
    @close="hideModal()"
  >
    <template #content>
      <div class="modal-body py-3 px-3">
        <div class="row b2 px-3">
          <div class="col-lg-12 px-0">
            <div class="b4 title p-0 mb-3 mt-3 text-center fw-bold">
              {{ $t('update_schedule') }}
              <span
                class="fe fe-x text-primary float-end"
                @click="hideModal()"
              />
            </div>
          </div>
          <div class="col-lg-12 py-0 b3">
            <div class="form-group mb-3">
              <label class="form-label b5">{{ $t('number') }}</label>
              <input
                type="tel"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.phone,
                }"
                v-model="fields.phone"
                :placeholder="$t('number')"
              >
              <div class="invalid-feedback">
                {{ errors.phone && errors.phone[0] }}
              </div>
            </div>
          </div>

          <div class="col-lg-6 b1 py-0">
            <div class="form-group mb-3">
              <label class="form-label b5">{{ $t('date_capitalize') }}</label>
              <div class="input-group input-group-merge">
                <flat-pickr
                  :class="{
                    'is-invalid': errors.date,
                    'form-control input-date rounded-start': true,
                  }"
                  :placeholder="$t('date_capitalize')"
                  type="text"
                  data-input
                  :config="{
                    locale: locale,
                    wrap: true,
                    altInput: true,
                    altFormat: 'd/m/Y',
                    minDate: 'today',
                    defaultDate: new Date()
                  }"
                  v-model="date"
                />
                <div
                  class="input-group-text cursor-pointer rounded-end"
                  data-toggle
                >
                  <span class="fe fe-calendar" />
                </div>
                <div class="invalid-feedback">
                  {{ errors.date && errors.date[0] }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 b2">
            <div class="form-group mb-3">
              <label class="form-label b5">{{ $t('time_capitalize') }}</label>
              <div class="input-group input-group-merge">
                <flat-pickr
                  :class="{
                    'form-control input-date': true,
                    'is-invalid': errors.time,
                  }"
                  :placeholder="$t('time_capitalize')"
                  type="text"
                  data-input
                  :config="{
                    locale: locale,
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'H:i:00',
                    wrap: true
                  }"
                  v-model="time"
                />
                <div 
                  class="input-group-text cursor-pointer"
                  data-toggle
                >
                  <span 
                    class="fe fe-clock" 
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 b3">
            <div class="form-group mb-3">
              <label class="form-label b5">{{ $t('observations') }}</label>
              <textarea
                rows="4"
                type="email"
                :class="{
                  'form-control': true,
                }"
                v-model="fields.note"
                :placeholder="$t('observations')"
              />
              <div class="invalid-feedback">
                {{ errors.note && errors.note[0] }}
              </div>
            </div>
          </div>
          <div class="col-lg-12 b3 mb-3">
            <button
              class="btn btn-primary w-100"
              @click.stop="submit"
              :disabled="loading.update || this.date == null"
              type="button"
            >
              {{ $t('update_schedule') }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import moment from "moment";

import { AgentStatus } from "@/utils.js";
import ModalComponent from '@/components/modal-component.vue';

export default {
  data() {
    return {
      showModal: false,
      fields: {
        phone: "",
        note: "",
        date: "",
      },
      schedule: {},
      date: "",
      time: "",
      locale: Portuguese,
    };
  },

  components: {
    flatPickr,
    ModalComponent,
  },

  computed: {
    ...mapGetters("agent/schedule", {
      errors: "getErrors",
      loading: "getLoadingFlags",
      scheduleToUpdateModal: "getScheduleToUpdateModal"
    }),

     ...mapGetters("system", {
      status: "getStatus",
      mode: "getMode",
    }),
  },

  methods: {
    ...mapActions("agent/schedule", {
      remove: "remove",
      update: "update",
      hideModal: "hideScheduleToUpdate"
    }),

    submit() {
      this.fields.date = this.date + ' ' + this.time;
      this.update({
        id: this.schedule.id,
        fields: this.fields,
      }).then(() => {
        this.hideModal()
      });
    },
  },

  watch: {
    mode(mode) {
      if (mode == "dialer") {
        this.hideModal()
      }
    },

    status(status) {
      if (status == AgentStatus.IDLE) {
        this.hideModal()
      }
    },

    scheduleToUpdateModal: {
      handler({ data }) {
        if(data) {
          let date = moment(data.schedule.date);
        
          this.time = date.format("HH:mm");
          this.date = date.format("Y-MM-D");

          this.fields = {...data.fields, date: date.format("Y-MM-D HH:mm:00")}
          this.schedule = data.schedule

          this.showModal = true
        } else {
          this.showModal = false
        }
      },
      deep: true
    },
  }
};
</script>

<style lang="scss" scoped>
.modal-schedule {
  .title {
    font-weight: 600;
  }

  .fe-x {
    cursor: pointer !important;
    margin-top: 1px;
  }

  .is-invalid ~ .input-date {
    border: 1px solid #e63757 !important;
  }
}
</style>
