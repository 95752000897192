<template>
  <div :style="customStyle">
    <transition :name="transitionName">
      <slot />
    </transition>
  </div>
</template>
<script>
export default {
  computed: {
    transitionName() {
      let transition = "";

      if (this.position == "bottom") transition = "slide-bottom";
      else if (this.position == "top") transition = "slide-top";

      if (this.opacity) transition += "-opacity";

      return transition;
    },
    customStyle() {
      return { "--translateY": this.translateBottomY };
    }
  },

  props: {
    position: {
      type: String,
      default: "bottom"
    },
    opacity: {
      type: Boolean,
      default: false
    },
    translateBottomY: {
      type: String,
      default: "100%"
    }
  }
};
</script>
<style lang="scss" scoped>
// slide top
.slide-top-enter-active,
.slide-top-leave-active {
  transition: all 1s cubic-bezier(0.55, 0, 0.1, 1);
}

.slide-top-enter-from,
.slide-top-leave-to {
  transform: translateY(-100%);
}

// slide top with opacity
.slide-top-opacity-enter-active,
.slide-top-opacity-leave-active {
  transition: all 1s cubic-bezier(0.55, 0, 0.1, 1);
}

.slide-top-opacity-enter-from,
.slide-top-opacity-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

// slide bottom
.slide-bottom-enter-active,
.slide-bottom-leave-active {
  transition: all 1s cubic-bezier(0.55, 0, 0.1, 1);
}

.slide-bottom-enter-from,
.slide-bottom-leave-to {
  transform: translateY(var(--translateY));
}

// slide bottom with opacity
.slide-bottom-opacity-enter-active,
.slide-bottom-opacity-leave-active {
  transition: all 1s cubic-bezier(0.55, 0, 0.1, 1);
}

.slide-bottom-opacity-enter-from,
.slide-bottom-opacity-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
</style>
