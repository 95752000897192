export default {
  startLoading: (state, flag) => (state.loadingFlags[flag] = true),
  stopLoading: (state, flag) => (state.loadingFlags[flag] = false),
  setTelephonyRouteStatus: (state, { routes, status, company }) => {
    if (status) return (state.telephonyRouteStatus = status);
    if (routes.length == 0) return (state.telephonyRouteStatus = "exclusiveBefore3cVoice");

    let version = "";
    const { used_3c_plus_route, plan, route_mobile_id, route_landline_id } = company;

    if (plan == 1) version = "exclusiveBefore3cVoice";
    else
      routes.forEach(route => {
        if (!route.endpoint.includes("PJSIP/3cplus_")) {
          if (route["campaigns-landline-routes"].length > 0 || route["campaigns-mobile-routes"].length > 0) {
            if (used_3c_plus_route) version = "exExclusive3cVoice";
            else version = "neverExclusive3cVoice";
            return version;
          }
        }
      });

    if (!version) {
      let is3cRouteMobileId = routes.find(
        route => route.id == route_mobile_id && route.endpoint.includes("PJSIP/3cplus_")
      );
      let is3cRouteLandlineId = routes.find(
        route => route.id == route_landline_id && route.endpoint.includes("PJSIP/3cplus_")
      );
      if (!is3cRouteLandlineId || !is3cRouteMobileId) {
        if (used_3c_plus_route) version = "exExclusive3cVoice";
        else version = "neverExclusive3cVoice";
      } else {
        if (used_3c_plus_route) version = "exclusive3cVoice";
        else version = "exclusiveBefore3cVoice";
      }
    }

    state.telephonyRouteStatus = version;
  },
  setIncludes: (state, includes) => (state.includes = includes)
};
