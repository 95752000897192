<template>
  <div
    class="tab-pane fade"
    id="integrations-tab-pane"
    role="tabpanel"
    aria-labelledby="integrations-tab"
    tabindex="0"
  >
    <div
      v-if="integrations.length > 0"
      class="card mt-4"
    >
      <div class="card-header">
        <h4 class="card-title">
          {{ $t('active_integrations') }}
        </h4>
      </div>
      <div
        v-for="(integration, index) in integrations"
        :key="index"
        class="card-body"
      >
        <div
          class="card"
          v-if="false"
        >
          <div
            class="card-body d-flex align-items-center"
            style="gap: 2rem"
          >
            <div class="col-auto">
              <img src="@assets/img/meta.svg">
            </div>
            <div class="col d-flex flex-column justify-content-center">
              <p class="m-0">
                {{ $t('meta') }}
              </p>
              <p class="m-0 tx-text-gray">
                {{ $t('facebook_and_instagram') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-4">
      <div class="card-header">
        <h4 class="card-title">
          {{ $t('integration') }}
        </h4>
      </div>
      <div class="card-body">
        <div class="card">
          <div
            class="card-body d-flex align-items-center"
            style="gap: 2rem"
          >
            <div class="col-auto">
              <img
                src="@assets/img/rd-station.svg"
                alt="RD Station"
              >
            </div>
            <div class="col d-flex flex-column justify-content-center">
              <h4 class="m-0 fw-medium">
                {{ $t('rd_station') }}
              </h4>
            </div>
            <button
              class="btn btn-sm btn-light tx-text-gray"
              style="width: 166px"
              @click="clickShowModalRdStation"
            >
              {{ $t('configure') }}
            </button>
          </div>
        </div>
        <div
          v-if="false"
          class="card"
        >
          <div
            class="card-body d-flex align-items-center"
            style="gap: 2rem"
          >
            <div class="col-auto">
              <img src="@assets/img/meta.svg">
            </div>
            <div class="col d-flex flex-column justify-content-center">
              <p class="m-0">
                {{ $t('meta') }}
              </p>
              <p class="m-0 tx-text-gray">
                {{ $t('facebook_and_instagram') }}
              </p>
            </div>
            <button
              class="btn btn-sm btn-light"
              style="width: 166px"
              @click="showModal = true"
            >
              {{ $t('configure') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <integration-modal-rd-station
      v-model="showModalRdStation"
      :integration="integrationRDStation"
      @close="showModalRdStation = false"
    />

    <integration-modal
      :show="showModal"
      custom-size="650px"
      @close="showModal = false"
    />
  </div>
</template>

<script>
import IntegrationModal from "./modals/integration-modal.vue";
import {mapActions, mapGetters} from "vuex";

import IntegrationModalRdStation from "@crm/pages/manager/crm-settings/components/integration-modal-rd-station.vue";

export default {
  name: "IntegrationsTab",

  components: {
    IntegrationModalRdStation,
    IntegrationModal
  },

  data() {
    return {
      showModalRdStation: false,
      showModal: false
    };
  },

  created() {
    this.fetchFunnelIntegrations();
  },

  computed:{
    ...mapGetters("crm/integrations", {
      integrations: "getIntegrations"
    }),
    integrationRDStation(){
      return this.integrations.find((integration) => {
        return integration.type === "rd_station";
      })
    }
  },

  methods: {
    ...mapActions("crm/integrations", ["fetchFunnelIntegrations"]),
    clickShowModalRdStation() {
      this.showModalRdStation = true;
    },
  }
};
</script>

