<template>
  <message-notification
    ref="messageNotification"
    :position="isAgent ? 'bottom' : 'top'"
  />
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import MessageNotification from './message-notification.vue'
export default {
  data() {
    return {
      oldMessage: ""
    }
  },

  computed: {
    ...mapGetters("system", {
      errorMessage: "getErrorMessage",
      successMesage: "getSuccessMessage",
      infoMesage: "getInfoMessage",
      warningMessage: "getWarningMessage",
      timeMessage: "getTimeMessage",
      customMessageType: "getCustomMessageType",
    }),
    ...mapGetters("auth", {
      isAgent: "isAgent",
    }),
  },

  watch: {
    /**
     *
     * @param {*} val
     */
    errorMessage(message) {
      if (message) {
        if (typeof message === 'object') {
          if(message.errors) {
            let messageContent =  Object.entries(message.errors)[0][1]
            if(typeof messageContent === 'object') messageContent = messageContent[0]

            message = messageContent;
          } else message = message.detail;
        }

        /*
        * all requests burst this message when the token expires,
        * in order to not trigger several messages this check was made
        */
        if(this.oldMessage === message && message == "É necessário autenticação para acessar esse recurso.") return

        this.$refs.messageNotification.fire({type : "error", message})
        this.oldMessage = message
        this.setErrorMessage(null);
      }
    },

    successMesage(message ) {
      if (message) {
        this.setSuccessMessage(null);
        this.$refs.messageNotification.fire({type : "success", message})
        this.oldMessage = message
      }
    },

    infoMesage(message ) {
      if (message || message === "") {
        this.setInfoMessage(null);
        this.$refs.messageNotification.fire({type : "info", message})
        this.oldMessage = message
      }
    },

     warningMessage(message ) {
      if (message) {
        this.setWarningMessage(null);
        this.$refs.messageNotification.fire({type : "warning", message})
        this.oldMessage = message
      }
    },

    timeMessage(message) {
      if (message) {
        this.setTimeMessage(null);
        this.$refs.messageNotification.fire({type : "time", message})
        this.oldMessage = message
      }
    },

    customMessageType ({ title, message, type }) {
      if (message || message === "") {
        this.$refs.messageNotification.fire({
          type,
          message,
          custom_title: title
        })
        this.oldMessage = message
      }
    }
  },


  methods: {

    ...mapMutations("system", [
      "setErrorMessage",
      "setSuccessMessage",
      "setInfoMessage",
      "setWarningMessage",
      "setTimeMessage",
      "setCustomMessageType"
    ]),

  },

  components: {
    MessageNotification
  },
}
</script>
