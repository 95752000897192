export default {
  startLoadingFlag: (state, flag) => state.loadingFlags[flag] = true,
  stopLoadingFlag: (state, flag) => state.loadingFlags[flag] = false,
  setList: (state, list) => (state.list = list),
  setErrorMessage: (state, errorMesage) => (state.errorMessage = errorMesage),
  setErrors: (state, errors) => (state.errors = errors),
  clearErrors: state => {
    state.errors = {};
    state.errorMessage = "";
  },
  setCampaign: (state, campaign) => (state.campaign = campaign),
  setRegister: (state, register) => (state.register = register),
};
