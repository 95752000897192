<template>
  <i
    class="fa-solid fa-file-lines text-muted b3"
    v-if="['txt'].includes(extension)"
    :style="'font-size: ' + size + 'px'"
  />
  <i
    class="fa-solid fa-file-pdf text-muted b3"
    v-else-if="['pdf'].includes(extension)"
    :style="'font-size: ' + size + 'px'"
  />
  <i
    class="fa-regular fa-file-image text-muted b3"
    v-else-if="['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'].includes(extension)"
    :style="'font-size: ' + size + 'px'"
  />
  <i
    class="fa-solid fa-file-word text-muted b3"
    v-else-if="['doc', 'docx'].includes(extension)"
    :style="'font-size: ' + size + 'px'"
  />
  <i
    class="fa-solid fa-file-video text-muted b3"
    v-else-if="['mp4', 'm4v', 'mov', 'mpg', 'mpeg', 'wmv', 'mkv'].includes(extension)"
    :style="'font-size: ' + size + 'px'"
  />
  <i
    class="fa-solid fa-file-audio text-muted b3"
    v-else-if="['ogg', 'mp3', 'wav', 'wma'].includes(extension)"
    :style="'font-size: ' + size + 'px'"
  />
  <i
    v-else
    class="fa-solid fa-file text-muted b3"
    :style="'font-size: ' + size + 'px'"
  />
</template>

<script>
export default {
  /**
   *
   */
  data() {
    return {
      extension: "",
    };
  },

  /**
   *
   */
  watch: {
    fileName: {
      immediate: true,
      handler(fileName) {
        this.extractExtension(fileName);
      },
    },
  },

  /**
   *
   */
  methods: {
    extractExtension(fileName) {
      this.extension = fileName?.split(".").pop();
    },
  },

  /**
   *
   */
  props: {
    /**
     *
     */
    fileName: {
      type: String,
      required: true,
    },
    /**
     *
     */
    size: {
      type: Number,
      default: 42,
    },
  },
};
</script>
