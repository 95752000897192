<template>
  <modal-component
    :show="showModal"
    :center="true"
    backdrop="static"
    size="modal-lg"
  >
    <template #content>
      <div class="modal-header">
        <h5 class="modal-title">
          {{ title }}
        </h5>
        <button
          style="width: 1.5rem; height: 1.5rem"
          type="button"
          role="button"
          class="btn p-0 d-flex justify-content-center align-items-center lh-1 tx-text-gray"
          @click="close()"
        >
          <i class="fa-regular fa-xmark" />
        </button>
      </div>
      <div class="modal-body">
        <form
          novalidate
          class="row"
        >
          <div class="col-lg-6 mb-4">
            <label
              for="name"
              class="form-label cursor-pointer tx-text-200 fw-normal"
            >
              {{ $t('capitalizes.name') }}
            </label>
            <input
              type="text"
              class="form-control"
              id="name"
              :placeholder="$t('capitalizes.name')"
              v-model="form.name"
            >
          </div>
          <div class="col-lg-6 mb-4">
            <label class="form-label cursor-pointer tx-text-200 fw-normal">{{ $t('color_capitalize') }} </label>
            <color-picker-dropdown
              :placeholder="$t('color_capitalize')"
              v-model="form.color"
            />
          </div>
          <div class="col-12">
            <p class="tx-text-200 fw-normal">
              {{ $t('questions.qualification_impact') }}
              <tooltip-component
                :title="toolTipText"
                width="338"
              />
            </p>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="impact"
                id="positive"
                disabled
              >
              <label
                class="tx-text-gray fw-normal"
                for="positive"
              >
                {{ $t('positive_capitalize') }}
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="impact"
                id="negative"
                checked
              >
              <label
                class="tx-text-gray fw-normal"
                for="negative"
              >
                {{ $t('negative_capitalize') }}
              </label>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer justify-content-center border-0 pt-0 pb-4">
        <button
          type="submit"
          style="width: 393px"
          class="btn btn-primary"
          :disabled="!formValidation()"
          @click="submitForm()"
        >
          {{ buttonLabel }}
        </button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from "@components/modal-component.vue";
import ColorPickerDropdown from "@components/color-picker-dropdown.vue";
import TooltipComponent from "@components/tooltip-component.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "QualificationManager",
  components: {
    TooltipComponent,
    ColorPickerDropdown,
    ModalComponent
  },

  props: {
    showModal: {
      type: Boolean,
      default: false
    },

    qualificationId: {
      type: String,
      required: true
    },

    edit: {
      type: Object,
      default: () => {
      }
    }
  },
  emits: ['close'],

  data() {
    return {
      form: {
        name: null,
        color: null,
        order: 2,
      },

      toolTipText: this.$t('tooltips.qualification_impact')
    }
  },

  methods: {
    ...mapActions("manager/qualification", [
      "createCRMQualification",
      "updateCRMQualification",
      "findById",
      "findQualificationById"
    ]),
    ...mapActions("crm/tour", [
      "updateTourSteps",
    ]),
    close() {
      this.$emit("close")
    },

    formValidation() {
      return !!this.form.name && !!this.form.color;
    },

    clearForm() {
      this.form = {
        name: null,
        color: null,
        order: 2,
      };
    },

    submitForm() {
      let action = 'createCRMQualification';
      let params = {
        id: this.qualificationId,
        data: this.form,
        message: this.$t('info_message.qualification_group.created', {group: this.form.name})
      }

      if (this.edit) {
        action = 'updateCRMQualification';
        params.list_id = this.edit.list_id;
        params.id = this.edit.id;
        params.message = this.$t('info_message.qualification_group.updated', {group: this.form.name})
      }

      this[action](params).then(() => {
        if(action === 'createCRMQualification' && !this.tourSteps?.add_qualifications){
          this.updateTourSteps({
            add_qualifications: true,
          })
        }
        this.findById({id: this.$route.params.id});
        this.findQualificationById({id: this.$route.params.id});
        this.clearForm()
        this.close();
      })
    }
  },

  computed: {
    ...mapGetters("crm/tour", {
      tourSteps: "getTourSteps"
    }),
    title() {
      return this.edit ? `${this.$t('edit')} ${this.$t('qualification')}` : this.$t('add_new_qualification');
    },
    buttonLabel() {
      return this.edit ? this.$t('capitalizes.save') : this.$t('add_qualification')
    }
  },

  watch: {
    edit(val) {
      if (val) {
        this.form.name = val.name;
        this.form.color = val.color;
        delete this.form.order;

        return
      }

      this.clearForm()
    }
  }
}
</script>

<style scoped lang="scss">
.btn-primary {
  &:disabled {
    background-color: $color-gray-blue-300;
    border-color: $color-gray-blue-300;
    color: $color-text-gray;
  }
}
</style>