<template>
  <div>
    <VueMultiselect
      v-if="multiple"
      v-model="selecteds"
      label="translation"
      track-by="id"
      placeholder=""
      :select-label="$t('click_or_enter_to_select')"
      :deselect-label="$t('click_or_enter_to_remove')"
      :selected-label="$t('selected')"
      :multiple="true"
      :close-on-select="false"
      :preserve-search="true"
      :options="dataSelect"
    />
    <select
      v-else
      class="form-select"
      :disabled="loading"
      @input="$emit('update:modelValue', $event.target.value)"
      :value="modelValue"
    >
      <option value="">
        {{ $t('all_f') }}
      </option>
      <option
        :value="item.id"
        v-for="(item, i) of dataSelect"
        :key="i"
      >
        {{ item.translation }}
      </option>
    </select>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueMultiselect from "vue-multiselect";

export default {
  /**
   *
   */
  emits: ["update:modelValue"],

  /**
   *
   */
  data() {
    return {
      selecteds: [],
    };
  },

  /**
   *
   */
  mounted() {
    this.loadStatusesDataSelect();
  },

  /**
   *
   */
  components: {
    VueMultiselect,
  },

  /**
   *
   */
  computed: {
    ...mapGetters("manager/calls-report", {
      dataSelect: "getStatusesDataSelect",
      loading: "isLoading",
    }),
  },

  /**
   *
   */
  watch: {
    selecteds: {
      handler(data) {
        let ids = [];
        for (let item of data) ids.push(item.id);
        this.$emit("update:modelValue", ids.join(","));
      },
      deep: true,
    },
  },

  props: {
    /**
     *
     */
    modelValue: {
      default: "",
      type: String,
    },

    /**
     *
     */
    multiple: {
      type: Boolean,
      default: false,
    },
  },

  /**
   *
   */
  methods: {
    ...mapActions("manager/calls-report", ["loadStatusesDataSelect"]),
  },
};
</script>
