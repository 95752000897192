import axios from "axios";

export default {
  loadConsultMetrics({ commit }, payload) {

    return new Promise((resolve, reject) => {
      axios.get(`/receptive_queues/${payload.id}/consult/metrics`)
        .then(response => {
          commit("setConsultMetrics", response.data.data);
          resolve();
        })
        .catch(response => {
          reject(response);
        });
    });
  },
  clearConsultMetrics({ commit }) {
    commit("setConsultMetrics", []);
  },
};
