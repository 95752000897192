<template>
  <div class="card mt-5">
    <div class="card-body p-4">
      <div
        class="d-flex justify-content-between flex-warp"
        style="margin-bottom: .5rem;"
      >
        <h3>Histórico e alterações</h3>
        <input-date-range
          @close="submitInputDataRange"
          v-model:start="start_date"
          v-model:end="end_date"
          :search-on-close="true"
          format-date="d/m/Y"
          style="width: 300px;"
          data-test="searchHistoryByDate-input"
        />
      </div>
      <main-loading v-if="loadingFlags.findCompanyHistory" />
      <div v-else>
        <div
          v-if="!companyHistory"
          class="my-4"
        >
          <h4 class="text-center">
            Nenhum histórico no periodo
          </h4>
        </div>

        <div
          v-for="(change, index) in companyHistory"
          :key="index"
        >
          <blocked-at-accordion
            v-if="change.change_type == 'blocked_at_change'"
            :change="change"
            :index="index"
          />
          <pix-accordion
            v-if="change.change_type == 'pix_change'"
            :change="change"
            :index="index"
          />
          <two-factor-accordion
            v-if="change.change_type.includes('two_factor_change')"
            :change="change"
            :index="index"
          />
          <licenses-accordion
            v-if="isLicense(change.change_type)"
            :change="change"
            :index="index"
          />
          <pro-mode-accordion
            v-if="change.change_type == 'pro_mode_change'"
            :change="change"
            :index="index"
          />
        </div>
        <div class="card-footer">
          <app-paginator
            v-if="!loadingFlags.findCompanyHistory"
            :pagination="companyHistorypagination"
            @change-page="changePage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import InputDateRange from "@components/input-date-range";
import AppPaginator from "@components/app-paginator";
import MainLoading from "@/components/main-loading";
import PixAccordion from "./history/pix-accordion";
import BlockedAtAccordion from "./history/blocked-at-accordion";
import LicensesAccordion from "./history/licenses-accordion.vue";
import ProModeAccordion from "./history/pro-mode-accordion.vue";
import TwoFactorAccordion from "./history/two-factor-accordion.vue";

export default {
  data() {
		return {
      start_date: "",
      end_date: ""
		}
	},

  components: {
    InputDateRange,
    AppPaginator,
    BlockedAtAccordion,
    PixAccordion,
    MainLoading,
    LicensesAccordion,
    ProModeAccordion,
    TwoFactorAccordion
  },

  computed: {
    ...mapGetters("admin/companies", {
      companyHistory: "getCompanyHistory",
      companyHistorypagination: "getCompanyHistoryPagination",
      loadingFlags: "getLoadingFlags"
    }),
  },

  methods: {
    ...mapActions("admin/companies", [
      "findCompanyHistory",
      "setCompanyHistoryFilters",
      'setCompanyHistoryCurrentPage'
    ]),

    submitInputDataRange() {
      this.setCompanyHistoryFilters({
        start_date: this.start_date + "00:00:00",
        end_date: this.end_date + "23:59:59",
      });
      this.findCompanyHistory({
        id: this.$route.params.id
      })
    },

    changePage(page) {
      this.setCompanyHistoryCurrentPage(page);
      this.findCompanyHistory({
        id: this.$route.params.id
      });
    },

    isLicense(change) {
      const licencesChange = ["max_agents_login_change", "limit_call_per_agent_change", "webphone_licenses_change", "ring_group_licenses_change", "ura_licenses_change"]
      if (licencesChange.includes(change)) return true;
      return false;
    }
  },

  mounted() {
    this.findCompanyHistory({
      id: this.$route.params.id,
    })
  }
}
</script>
