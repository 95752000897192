<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <main-loading v-if="loadingFlags['findGroupChannelById']" />
      <div class="col-12">
        <header-component
          :title="$t('capitalizes.settings')"
          :pretitle="editConnection ? fields.name : $t('add_channel_groups')"
          :redirect-link="editConnection ? `/manager/whatsapp/${$route.params.id}` : '/manager/whatsapp'"
        />
        <div class="row align-items-center mb-4">
          <div class="col">
            <ul
              @go-validation="goValidation"
              class="nav nav-tabs"
              id="myTab"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="general-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#general"
                  type="button"
                  role="tab"
                  aria-controls="general"
                  aria-selected="true"
                >
                  {{ $t('general_channel_group') }}
                </a>
              </li>
              <li class="nav-item">
                <a
                  :class="{'nav-link ': true, 'disabled': newConnection}"
                  id="strategy-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#strategy"
                  type="button"
                  role="tab"
                  aria-controls="strategy"
                  aria-selected="true"
                >
                  {{ $t('strategy_omni') }}
                </a>
              </li>
              <li class="nav-item">
                <a
                  :class="{'nav-link ': true, 'disabled': newConnection}"
                  id="quick-message-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#quick-message"
                  type="button"
                  role="tab"
                  aria-controls="quick"
                  aria-selected="false"
                >
                  {{ $t('quick_message') }}
                </a>
              </li>
              <li class="nav-item">
                <a
                  :class="{'nav-link ': true, 'disabled': newConnection}"
                  class="nav-link"
                  id="ia-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#ia"
                  type="button"
                  role="tab"
                  aria-controls="quick"
                  aria-selected="false"
                  ref="ia-validation"
                >
                  {{ $t('manage_ai') }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="tab-content">
          <general-tab />
          <strategy-tab v-if="editConnection" />
          <quick-message-tab v-if="editConnection" />
          <ia-tab v-if="editConnection" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import MainLoading from "@/components/main-loading.vue";
import HeaderComponent from "@/components/header-component.vue";
import QuickMessageTab from "./components/quick-message-tab.vue";
import StrategyTab from "./components/strategy-tab.vue";
import GeneralTab from "./components/general-tab.vue"
import IaTab from "./components/ia-tab.vue";

export default {
  data() {
    return {
      fields: {
        name: ""
      }
    }
  },

  mounted() {
    if (this.editConnection) {
      this.findGroupChannelById({id: this.$route.params.id})
    }
  },

  components: {
    QuickMessageTab,
    StrategyTab,
    GeneralTab,
    MainLoading,
    HeaderComponent,
    IaTab
  },

  watch: {
    groupChannel(data) {
      if (data) {
        this.fields.name = data.name
      }
    }
  },

  methods: {
    ...mapActions("system", ["showConfirmationMessage"]),
    ...mapActions("manager/whatsapp_group_channel", ["findGroupChannelById"])
  },

  computed: {
    ...mapGetters("manager/whatsapp", {
      loadingFlags: "getLoadingFlags",
    }),
    ...mapGetters("manager/whatsapp_group_channel", {
      groupChannel: "getGroupChannel"
    }),

    newConnection() {
      return this.$route.name === 'new-connection'
    },

    editConnection() {
      return this.$route.name === 'edit-connection'
    },
  },
};
</script>
