import store from "@store";

export default {
  path: "ivrs",
  name: "ivrs",
  components: {
    main: require("@dialer/manager/pages/settings/ivrs/index-ivrs").default,
  },
  children: [
    {
      path: "",
      components: {
        "ivrs": require("@dialer/manager/pages/settings/ivrs/list-ivrs").default,
      },
    },
    {
      path: "new",
      components: {
        "ivrs": require("@dialer/manager/pages/settings/ivrs/new-ivr").default,
      },
      meta: {
        "short-side-bar": true,
      },
    },
    {
      path: ":id/edit",
      components: {
        "ivrs": require("@dialer/manager/pages/settings/ivrs/edit-ivrs").default,
      },
      meta: {
        "short-side-bar": true,
      },
    },
  ],
  beforeEnter: (to, from, next) => {
    if (store.getters["auth/isManager"]) next();
    else next({
      name: "not-found",
      params: {
        pathMatch: to.path.split("/").slice(1),
      },
    });
  },
}
