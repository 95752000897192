import ValidateSms from "@admin/pages/validation/admin-validate-sms.vue"
import store from "@/store";

export default {
  path: "sms",
  name: "sms",
  components: {
    main: ValidateSms
  },

  beforeEnter: (to, from, next) => {
    const restrictedAdminLevels = store.getters["admin/getRestrictedAdminLevels"];
    if (restrictedAdminLevels.includes(store.getters["auth/getUser"].settings.access)) next();
    else next("/");
  }
};
