<template>
  <div>
    <div class="d-flex justify-content-between">
      <label class="form-label">
        {{ $t('internal_note') }}
      </label>
      <button
        v-if="!hasContent"
        class="btn d-flex justify-content-center pe-0"
        @click="dropAllList()"
      >
        <i class="fa-solid fa-xmark" />
      </button>
    </div>
    <div
      v-for="(item, index) in noteList"
      :key="index"
    >
      <div class="d-flex align-items-center gap-4 mb-3">
        <div class="col p-0 d-flex align-items-center">
          <textarea
            ref="textarea"
            :readonly="!(index === noteList?.length - 1)"
            v-model="item.content"
            class="col px-3 py-2 text-medium rounded-3 note-context w-100"
            data-test="textarea-field"
            @focusout="updateModel()"
          />
        </div>
        <div class="d-flex flex-column justify-content-center">
          <button
            :disabled="!item.content"
            v-if="index === noteList?.length - 1"
            class="btn btn-primary"
            @click="incrementNoteList(index)"
          >
            <i class="fa-solid fa-plus px-1" />
          </button>
          <button
            v-else
            class="btn btn-light"
            @click="dropNoteList(index, item)"
          >
            <i class="fa-solid fa-xmark px-1" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "NoteFieldList",
  emits: ['update', 'close', 'update:modelValue', 'delete'],
  inject: ['isActivityFormSubmitted'],
  props: {
    modelValue: { type: Array, required: true }
  },

  mounted() {
    if (this.modelValue.length) {
      this.noteList = this.modelValue;
    }
  },

  data (){
    return {
      noteList: [{type: 'internal-note', content: ''}],
    }
  },

  computed: {
    hasContent (){
      return this.noteList.find((note) => note.content.length > 0)
    },
  },

  methods: {
    incrementNoteList() {
      this.noteList.push({type: 'internal-note', content: ''})
      this.updateModel()
    },
    dropNoteList(index, item) {
      if (item.id) {
        this.$emit('delete', item.id)
      }
      this.noteList.splice(index, 1)
      this.updateModel()
    },
    dropAllList () {
      if(this.noteList[0] && this.noteList[0].id) {
        this.$emit('delete', this.noteList[0].id)
      }
      this.noteList = [{type: 'internal-note', content: ''}]
      this.updateModel()
      this.$emit('close')
    },
    updateModel () {
      this.$emit('update:modelValue', this.noteList)
    }
  },

  watch: {
    "isActivityFormSubmitted.value"(value) {
      if (value) {
        this.dropAllList();
      }
    }
  }
})
</script>
<style scoped lang="scss">
.note-context, .input-group-text {
  background-color: #FFE9A9 !important;
  color: #754000 !important;
}
textarea {
  background-color: #E1E9F4;
  border: none;
  outline: none;
  resize: none;
  max-height: 144px;

  &::-webkit-scrollbar {
    width: 3px !important;
  }
  &::-webkit-scrollbar-thumb {
    background: #b0c5c2 !important;
    border-radius: 20px !important;
  }
}
</style>
