<template>
  <div>
    <table class="table app-table mb-0">
      <thead>
        <tr>
          <th class="col">
            ID
          </th>
          <th class="col">
            Nome da Empresa
          </th>
          <th class="col">
            Domínio
          </th>
          <th class="text-center w-25">
            Logados(s)/Deslogado(s)
          </th>
          <th class="text-center">
            Total
          </th>
          <th class="text-center w-25">
            Ligações ativas
          </th>
          <th class="text-center">
            Total
          </th>
          <th
            class="text-center"
          />
        </tr>
      </thead>
      <tbody v-if="callStats">
        <tr v-if="loadingFlags.loadActiveCompanies">
          <td
            class="p-0 loading"
            v-for="i in 7"
            :key="i"
          >
            <table-loading :rows="11">
              <div class="cell col" />
            </table-loading>
          </td>
        </tr>

        <tr v-else-if="!companies.length">
          <td
            class="text-center text-muted"
            colspan="8"
          >
            <span class="fe fe-alert-octagon" /> Nenhuma empresa encontrada!
          </td>
        </tr>

        <tr
          v-for="company in companies"
          :key="company.id"
          class="cursor-pointer"
        >
          <td @click="this.$router.push(`/admin/companies/${company.id}/edit`)">
            <span
              href="#"
              style="color: #6E84A3;"
            >{{ company.id }}</span>
          </td>
          <td
            @click="this.$router.push(`/admin/companies/${company.id}/edit`)"
          >
            <div class="d-flex align-items-center">
              <div
                class="limit-company-text overflow-ellipsis text-truncate"
                href="#"
                data-bs-toggle="tooltip"
                :title="company.name"
              >
                {{ company.name }}
              </div>
              <img
                v-if="company.pro_mode"
                class="pro-mode-icon mx-2"
                src="~@assets/img/icons/pro-mode.svg"
              >
            </div>
          </td>
          <td>
            <a
              class="text-center link-hover"
              :href="`https://${company.domain}.3c.plus/login`"
              style="color: #6E84A3;"
            >{{ company.domain }}
              <span class="fe fe-chevron-right fw-bold" />
            </a>
          </td>
          <td @click="this.$router.push(`/admin/companies/${company.id}/edit`)">
            <div class="">
              <div
                class="d-flex justify-content-between mx-3"
                style="color: #6E84A3;"
              >
                <span class="">{{ countAgentsOnline(company["agent-status"]) }}</span>
                <span>{{ company.max_agents_login -
                  countAgentsOnline(company["agent-status"]) }}</span>
              </div>
              <double-progressbar
                class="col mx-3"
                style="margin-top: 3px; height: 8px"
                :left-count="countAgentsOnline(company['agent-status'])"
                :right-count="
                  company.max_agents_login -
                    countAgentsOnline(company['agent-status'])
                "
              />
            </div>
          </td>
          <td
            @click="this.$router.push(`/admin/companies/${company.id}/edit`)"
            class="text-center fs-4"
          >
            {{ company.max_agents_login }}
          </td>
          <td @click="this.$router.push(`/admin/companies/${company.id}/edit`)">
            <div class="">
              <div
                class="d-flex justify-content-between mx-3"
                style="color: #6E84A3;"
              >
                <span class="">{{ company.calls.data.active_calls }}</span>
                <span>{{ callStats.max_calls_per_agent *
                  countAgentsOnline(company["agent-status"]) -
                  company.calls.data.active_calls }}</span>
              </div>
              <double-progressbar
                class="col mx-3"
                style="margin-top: 3px; height: 8px;"
                :left-count="company.calls.data.active_calls"
                :right-count="
                  callStats.max_calls_per_agent *
                    countAgentsOnline(company['agent-status']) -
                    company.calls.data.active_calls
                "
              />
            </div>
          </td>
          <!-- TODO: Fix tag </a> and change push to </tr> -->
          <td
            @click="this.$router.push(`/admin/companies/${company.id}/edit`)"
            class="text-center fs-4"
          >
            {{
              callStats
                ? callStats.max_calls_per_agent *
                  countAgentsOnline(company["agent-status"])
                : 0
            }}
          </td>
          <td
            v-if="user && [0, 2, 3].includes(Number(user.settings.access))"
          >
            <button
              class="btn btn-sm btn-primary"
              @click="impersonate(company)"
            >
              Acessar
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="card-footer"
      v-if="!loadingFlags.loadActiveCompanies && this.companies.length"
    >
      <div class="col-lg-12">
        <paginator
          v-if="!loadingFlags.loadActiveCompanies"
          :pagination="pagination"
          @change-page="changePage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { AgentStatus } from "@/utils";
import DoubleProgressbar from "@admin/components/double-progressbar.vue";
import TableLoading from "@admin/components/table-loading.vue";
import paginator from "@components/app-paginator";

export default {
  data() {
    return {
      AgentStatus: AgentStatus,
    }
  },

  props: {
    searchValue: {
      type: String,
      default: "",
    },

    proModeCompanies: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.loadActiveCompanies();
  },


  computed: {
    ...mapGetters("admin/companies", {
      companies: "getActiveCompanies",
      loadingFlags: "getLoadingFlags",
      pagination: "getActiveCompaniesPagination",
      callStats: "getCallStats",
    }),

    ...mapGetters("auth", {
      user: "getUser",
    }),
  },

  components: {
    DoubleProgressbar,
    TableLoading,
    paginator,
  },

  methods: {
    ...mapMutations("auth", ["trackEvents"]),
    ...mapActions("auth", ["enterImpersonateMode"]),
    ...mapActions("admin/companies", [
      "loadActiveCompanies",
      "setActiveCompaniesFilters",
      "setActiveCompaniesCurrentPage",
      "findById",
    ]),

    fetchActiveCompanies(params) {
      if (this.proModeCompanies === false) {
        delete params.pro_mode
      }
      this.setActiveCompaniesFilters({
        ...params,
      });
      this.setActiveCompaniesCurrentPage(1);
      this.loadActiveCompanies();
    },

    countAgentsOnline(agentStatus) {
      let total = 0;
      for (let agent of agentStatus) total += agent.status != AgentStatus.OFFLINE ? 1 : 0;
      return total;
    },

    async impersonate(company) {
      const { id } = company;
      await this.findById({ id });
      await this.enterImpersonateMode({ id })
      this.trackEvents("[Admin] Impersonate Access");
      this.$router.push("/manager/voice-panel");
    },

    changePage(page) {
      this.setActiveCompaniesCurrentPage(page);
      this.loadActiveCompanies();
    },
  },

  watch: {
    searchValue(search) {
      this.fetchActiveCompanies({
        search,
        actives: true,
        orderByDesc: "max_agents_login",
      })
    },

    proModeCompanies(pro_mode) {
      this.fetchActiveCompanies({
        pro_mode,
        actives: true,
        orderByDesc: "max_agents_login",
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.link-hover:hover {
  color: #2C7BE5 !important;
  text-decoration: underline;
}

.pro-mode-icon {
  width: 16px;
  height: 16px;
  top: 6px;
  left: 187px;
}

.limit-company-text {
  max-width: 200px;
}
</style>
