<template>
  <div>
    <table class="table table-hover app-table mb-0">
      <thead>
        <tr>
          <th style="padding: 0 0 0 24px; width: 50px">
            ID
          </th>
          <th>
            PARTNER
          </th>
          <th>
            CNPJ
          </th>
          <th>
            CPF
          </th>
          <th class="text-center" />
        </tr>
      </thead>
      <tbody>
        <LoadingTable
          v-if="loadingFlags.loadTrashedPartners"
          :columns="4"
          :lines="15"
          :list-width="[80, 300, 150, 80]"
        />

        <tr v-else-if="!trashedPartners.length">
          <td
            class="text-center"
            colspan="12"
          >
            <img
              alt="Imagem nenhum parceiro encontrado"
              class="mb-4"
              src="@/assets/img/empty-box.svg"
            >
            <h4>Nada para mostrar, por enquanto...</h4>
            <p class="text-muted fw-medium mb-5">
              Partners excluídos aparecerão aqui
            </p>
          </td>
        </tr>

        <tr
          v-for="partner in trashedPartners"
          :key="partner.id"
          class="cursor-pointer"
        >
          <td class="ps-4">
            <span
              class="id-field"
              href="#"
            >
              {{ partner.id }}
            </span>
          </td>
          <td>
            <span href="#">{{ partner.commercial_name || partner.name }}</span>
          </td>

          <td>
            {{ partner.cnpj ? partner.cnpj : "-" }}
          </td>
          <td>
            {{ partner.cpf ? partner.cpf : "-" }}
          </td>
          <td style="text-align: right">
            <button
              class="col-8 btn btn-outline-primary"
              @click="restoreTrashedPartner(partner.user_id)"
            >
              Reativar
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="!loadingFlags.loadTrashedPartners && trashedPartners.length">
      <div class="col-lg-12 card-footer">
        <paginator
          v-if="!loadingFlags.loadTrashedPartners"
          :pagination="trashedPartnersPagination"
          @change-page="changePage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import paginator from "@components/app-paginator.vue";
import LoadingTable from "@dialer/manager/components/loading-table-component.vue";

export default {
  name: "TablePartnersTrashed",

  props: {
    searchValue: {
      type: String,
      default: ""
    }
  },

  computed: {
    ...mapGetters("admin/partners", {
      loadingFlags: "getLoadingFlags",
      trashedPartners: "getTrashedPartners",
      trashedPartnersPagination: "getTrashedPartnersPagination"
    })
  },

  components: {
    LoadingTable,
    paginator
  },

  methods: {
    ...mapActions("admin/partners", [
      "loadTrashedPartners",
      "loadActivePartners",
      "setTrashedPartnersCurrentPage",
      "setTrashedPartnersFilters",
      "releaseAccessToTrashedPartner"
    ]),

    changePage(page) {
      this.setTrashedPartnersCurrentPage(page);
      this.loadTrashedPartners();
    },

    async restoreTrashedPartner(id) {
      await this.releaseAccessToTrashedPartner(id).then(() => {
        this.loadTrashedPartners();
        this.loadActivePartners();
      });
    }
  },

  watch: {
    searchValue(search) {
      this.setTrashedPartnersFilters(search);
      this.setTrashedPartnersCurrentPage(1);
      this.loadTrashedPartners();
    }
  }
};
</script>

<style lang="scss" scoped>
.id-field {
  color: $color-gray-500 !important;
}
</style>
