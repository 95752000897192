export default {
  getLoadingFlags: state => state.loadingFlags,
  getErrorsFlag: state => state.errorsFlag,
  isLoading: state => state.loading,
  getLoadingContext: state => state.loadingContext,
  getErrors: state => state.errors,
  getCallsReport: (state) => state.callsReports,
  getFeedbackRegister: (state) => state.feedbackRegister,
  getCallsStatusCountPerTime: (state) => state.callsStatusCountPerTime,
  getHangupCausesDataSelect: (state) => state.setHangupCausesDataSelect,
  getRoutesDataSelect: (state) => state.routesDataSelect,
  getTotalCallsFromLastDayPerCampaign: state => state.totalCallsFromLastDayPerCampaign,
  getStatusesDataSelect: (state) => state.statusesDataSelect,
  getListDataSelect: (state) => state.listDataSelect,
  getFilters: state => state.filters,
  getTotalFilters: state => state.totalFilters,
  getPagination: (state) => state.pagination,
  getTotalCallsData: (state) => state.totalCallsData,
  getLoadedCall: (state) => state.loadedCall,
  getParams: (state) =>
    Object.assign(
      {
        'page': state.pagination.current_page,
      }
      , state.filters, { include: state.include.join(',') }),
  getTotalParams: (state) =>
    Object.assign(state.totalFilters),
  getDownloadTableParams: state => {
    const filters = state.filters;
    // Use all filters except those below
    delete filters.simple_paginate;
    delete filters.include;

    return Object.assign({
      ...state.downloadTableParams,
      ...filters
    })
  },
}
