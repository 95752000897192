<template>
  <div class="card">
    <div
      class="card-header"
      style="height: 56px;"
    >
      <h4 class="mb-0">
        Planilhas de precificação
      </h4>
      <button
        class="btn btn-blue-gray tx-text-gray text-small"
        @click="downloadSpread"
        style="height: 32px;line-height: 0;width:
              168px;"
      >
        Baixar planilha atualizada
      </button>
    </div>
    <div class="card-body p-0">
      <div class="table-responsive">
        <table class="table table-nowrap table-hover mb-0">
          <thead>
            <tr>
              <th>
                REALIZADO POR
              </th>
              <th>
                DATA DE ALTERAÇÃO
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(data, index) in tableData"
              :key="index"
              @mouseenter="activeHover = index"
              @mouseleave="activeHover = ''"
            >
              <td>
                {{ data.name }}
              </td>
              <td>
                {{ formatTimestamp(data.created_at, "DD/MM/YYYY [às] HH:mm") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { formatTimestamp } from "@/utils";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TableInternational",
  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      activeHover: "",
      showModal: false
    };
  },
  computed: {
    ...mapGetters("auth", {
      user: "getUser"
    })
  },
  methods: {
    ...mapActions("admin/telecom", ["downloadSpreadsheet"]),
    formatTimestamp,
    downloadSpread() {
      const { email } = this.user;
      this.downloadSpreadsheet(email);
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-blue-gray {
  background-color: $color-gray-blue-300;
}
</style>
