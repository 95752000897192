<template>
  <main-loading v-if="initialLoading" />
  <empty-state-chatbot
    v-show="!hasChatbots"
    @create-chatbot="createNewChatbot"
  />

  <div
    v-show="hasChatbots"
    class="container-fluid"
  >
    <header-component
      title="Chatbot"
    >
      <template #buttons>
        <app-button
          v-if="isManager"
          style="width: 264px"
          @click="createNewChatbot"
        >
          + {{ $t('new') }} Chatbot
        </app-button>
      </template>
    </header-component>
    <chatbot-metrics />
    <table-chatbots />
  </div>
</template>

<script>
import HeaderComponent from "@/components/header-component.vue";
import AppButton from "@components/app-button.vue";
import ChatbotMetrics from "@chatbot/pages/dashboard/components/chatbot-metrics.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import TableChatbots from "@chatbot/pages/dashboard/components/table-chatbots.vue";
import EmptyStateChatbot from "@chatbot/pages/dashboard/components/empty-state-chatbot.vue";
import mainLoading from "@/components/main-loading.vue";

export default {
  name: "ChatbotDashboard",

  components: {
    mainLoading,
    EmptyStateChatbot,
    TableChatbots,
    ChatbotMetrics,
    HeaderComponent,
    AppButton,
  },

  data(){
    return{
      initialLoading: true,
    }
  },

  created() {
    this.fetchMetrics()
    this.loadChatbots()
  },

  computed: {
    ...mapGetters('auth', {
      isManager: "isManager"
    }),

    ...mapGetters("chatbot/chatbots", {
      loadingFlags: "getLoadingFlags",
      chatbots: "getChatbots"
    }),

    hasChatbots() {
      return this.chatbots.length > 0
    }
  },

  methods: {
    ...mapActions("chatbot/chatbots", [
      "fetchMetrics",
      "fetchChatbots",
      "createChatbot"
    ]),
    ...mapMutations("chatbot/chatbots", ['setDefaultParams', 'setNewChatbot']),
    ...mapMutations("auth", ["trackEvents"]),


    createNewChatbot() {
      this.setNewChatbot(true)
      this.$router.push(`/manager/chatbot/new`)
    },

    loadChatbots() {
      this.setDefaultParams()
      this.fetchChatbots().then(()=>{
        if(this.initialLoading) {
          this.initialLoading = false;
        }
      })
    }
  }
}
</script>
