<template>
  <div class="card">
    <div class="card-header">
      <h4 class="m-0">
        {{ $t("import_preview") }}
      </h4>
    </div>
    <div class="table-responsive">
      <table class="table table-nowrap card-table">
        <thead>
          <tr>
            <th
              v-for="(item, index) in headerLabels"
              :key="index"
            >
              {{ item }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, i) in preAdjustedCsv.data.slice(0,5)"
            :key="i"
          >
            <td
              v-for="(field, j) in item.slice(0, headerLabels.length)"
              :key="j"
            >
              {{ field }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "TableCsvPreview",

  computed: {
    ...mapGetters('crm/imports', {
      preAdjustedCsv: "getPreAdjustedCsv",
      headerLabels: "getHeaderLabels",
    }),
  },
}
</script>
