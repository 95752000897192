<template>
  <div class="card-body d-flex flex-wrap justify-content-center">
    <audio-uploader
      class="col-12"
      @select-file="$emit('update:audio', $event)"
      :filter="['audio/mp3','audio/mpeg']"
      icon="far fa-headphones"
      :url-audio="urlAudio"
    />
    <button
      class="btn px-4 mt-4 py-1"
      :class="urlAudio ?'btn-primary':'btn-light' "
      :disabled="!urlAudio"
      @click="$emit('toggleCollapse')"
    >
      {{ $t("continue_to_key_settings") }}
    </button>
  </div>
</template>
<script>
import AudioUploader from "@components/audio-uploader.vue";

export default {
  name: "AudioSettings",
  components: { AudioUploader },
  props: {
    urlAudio: {
      type: [String, Object],
      required: true,
    },
  },
  emits: ["update:audio", "toggleCollapse"],
}
</script>