import CallsReport from "@dialer/manager/pages/reports/calls-report.vue";

export default {
  path: "calls-report",
  name: "calls-report",
  components: {
    main: CallsReport
  },
  meta: {
    "short-side-bar": true
  }
};
