import intervalsReport from "@dialer/manager/pages/reports/intervals/intervals-report";

export default {
  path: "intervals-report",
  name: "intervals-report",
  components: {
    main: intervalsReport
  },
  meta: {
    "short-side-bar": true
  },
};
