<template>
  <li
    :class="completed ? 'completed' : ''"
    class="pb-4"
  >
    <button
      class="border-0 p-0 icon-progress bg-transparent"

      :disabled="disabled"
      @click="toggleCollapse()"
      :class="{
        'cursor-pointer': !disabled,
        'cursor-not-allowed': disabled,
        'fas fa-check-circle tx-blue-300': completed,
        'far fa-circle-dot tx-blue-300': !completed && show,
        'far fa-circle-dot': !completed && !show
      }"
    />
    <div class="card mb-0">
      <button
        class="btn d-flex justify-content-between cursor-pointer align-items-center custom-header"
        :class="{'border-bottom': show}"
        data-bs-toggle="collapse"
        :data-bs-target="`#accordionStep${id}`"
        aria-expanded="false"
        :aria-controls="`accordionStep${id}`"
        @click="toggleCollapse()"
        :disabled="disabled"
      >
        <span class="h4 d-flex align-items-center gap-3 mb-0">
          <badge-icon
            color="tx-blue-300"
            :icon="icon"
          />
          {{ title }}
        </span>
        <i
          class="fa-solid fs-6 tx-text-gray"
          :class="show ? 'fa-chevron-down' : 'fa-chevron-up'"
        />
      </button>
      <div
        :id="`accordionStep${id}`"
        :ref="`accordionStep${id}`"
        class="collapse padding-body "
      >
        <slot />
      </div>
    </div>
  </li>
</template>
<script>
import { Collapse } from "bootstrap";
import BadgeIcon from "@dialer/manager/pages/settings/ivrs/badge-icon.vue";
import { nextTick } from "vue";

export default {
  name: "AccordionStep",
  components: { BadgeIcon },
  props: {
    completed: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["toggleCollapse"],
  data() {
    return {
      instance: {
        collapse: {},
        show: false,
      },
    }
  },
  mounted() {
    nextTick(() => {
      this.instance.collapse = new Collapse(`#accordionStep${this.id}`, {
        toggle: false,
      });
      if (this.show) {
        this.instance.collapse.show();
      }
    });
  },
  methods: {
    toggleCollapse() {
      this.instance.show = !this.instance.show;
      this.instance.collapse.toggle();
      this.$emit("toggleCollapse");

    },
  },
  watch: {
    show(value) {
      this.instance.show = value;
      value ? this.instance.collapse.show() : this.instance.collapse.hide()
    },
  },
}
</script>

<style scoped lang="scss">
.custom-header {
  padding: 1rem 1.5rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

:deep(.card-body) {
  padding: 1.5rem;
}
</style>