<template>
  <!-- Modal -->
  <div
    ref="buy-credits"
    class="modal-dialog modal-dialog-centered"
    :class="{ 'modal-lg': !showPixBilling, 'modal-sm': isSmsOffline }"
  >
    <div
      class="modal-content form-background"
      style="background-color: #f9fbfd"
    >
      <div
        class="modal-header"
      >
        <h2
          class="header-title overflow-ellipsis"
        >
          {{ showPixBilling ? $t("buy_credit") : $t("add_credit_capitalizes") }}
        </h2>
        <button
          type="button"
          data-bs-dismiss="modal"
          aria-label="Close"
          class="btn-close fs-2 pe-4"
        />
      </div>
      <pix-billing
        v-if="showPixBilling && paymentType === 'pix'"
        @hide-pix-billing="showPixBilling = false"
      />
      <billet-billing
        v-else-if="paymentType === 'billet' && showBilletBilling"
        :ticket-url="ticketUrl"
      />
      <sms-offline v-else-if="isSmsOffline" />
      <div
        v-else
        style="padding: 1rem 2rem;"
        class="modal-body"
      >
        <div class="p-0">
          <div class="row py-1">
            <h3 class="fs-3">
              {{ $t("type_general") }}
            </h3>
          </div>
          <div class="row align-items-start my-3">
            <!-- pix collumn -->
            <div class="col">
              <div
                class="card d-flex flex-row align-items-center py-4 px-4 cursor-pointer"
                @click="paymentType = 'pix'"
              >
                <i class="fa-kit fa-pix" />
                <div class="col-10 d-flex flex-column text-start px-4">
                  <h3 class="my-0">
                    Pix
                  </h3>
                  <small class="text-muted"> {{ $t("real_time_credit") }} </small>
                </div>
                <input
                  v-model="paymentType"
                  data-test="paymentTypeCredits-input"
                  class="form-check-input radio"
                  type="radio"
                  id="paymentType"
                  value="pix"
                >
              </div>
            </div>
            <!-- Billet column -->
            <div class="col">
              <div
                class="card d-flex flex-row align-items-center py-4 px-4 cursor-pointer"
                @click="paymentType = 'billet'"
              >
                <img src="@assets/img/ticket.svg">
                <div class="col-10 d-flex flex-column text-start px-4">
                  <h3 class="my-0">
                    {{ $t("payment_bill") }}
                  </h3>
                  <small class="text-muted">
                    {{ $t("up_to_3_days_compensation") }}
                  </small>
                </div>
                <input
                  v-model="paymentType"
                  data-test="paymentTypeCredits-input"
                  class="form-check-input radio"
                  type="radio"
                  id="paymentType"
                  value="billet"
                >
              </div>
            </div>
          </div>
          <billing-data
            v-if="paymentType === 'billet'"
            @show-billet-billing="showBilletBilling = true"
          />
          <amount-credits
            v-else
            v-model="transactionAmount"
            :amount-values="amountValues"
            :initial-value="50"
            payment-type="pix"
          />
        </div>
      </div>
      <div
        v-if="!isSmsOffline && paymentType !== 'billet'"
        style="padding: 1rem"
        class="modal-footer d-flex flex-column justify-content-center"
      >
        <button
          v-if="showPixBilling"
          type="button"
          data-test="byCredits-button"
          class="btn btn-primary m-0"
          @click="showPixBilling = false"
        >
          <i class="fe fe-arrow-left" />
          {{ $t("back_capitalize") }}
        </button>
        <button
          v-else
          type="button"
          class="btn btn-primary m-0"
          data-test="byCredits-button"
          @click="submit"
          :disabled="loading || !paymentType || !transactionAmount"
        >
          <i class="fe fe-plus" />
          {{ $t("buy_credit") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BillingData from "../billing-data.vue";
import { mapActions, mapGetters } from "vuex";
import PixBilling from "../pix-billing.vue";
import BilletBilling from "../billet-billing.vue";
import SmsOffline from "../sms-offline.vue";
import AmountCredits from "../amount-credits.vue";
import { Offcanvas } from "bootstrap";

export default {

  components: { BillingData, PixBilling, BilletBilling, SmsOffline, AmountCredits },
  data() {
    return {
      amountValues: [
        { value: "50", label: "R$ 50,00" },
        { value: "100", label: "R$ 100,00" },
        { value: "300", label: "R$ 300,00" },
        { value: "500", label: "R$ 500,00" },
        { value: "1000", label: "R$ 1000,00" },
        { value: "2000", label: "R$ 2000,00" },
        { value: "outro", label: "Outro" },
      ],
      transactionAmount: "50",
      paymentType: "pix",
      showPixBilling: false,
      showBilletBilling: false,
      ticketUrl: null,
      modal: null,
    };
  },
  computed: {
    ...mapGetters("manager/user_profile", {
      loading: "isLoading",
      isSmsOffline: "getIsOfflineSms",
    }),
  },
  mounted() {
    this.modal = new Offcanvas(this.$refs["buy-credits"]);
    if (this.modal) {
      this.$refs["buy-credits"].addEventListener("hidden.bs.offcanvas", () => {
        this.$emit("close")
      })
    }
  },
  methods: {
    ...mapActions("manager/user_profile", [
      "postTransactionPix",
    ]),
    submit() {
      this.postTransactionPix({
        transaction_amount: this.transactionAmount,
      }).then(() => {
        this.showPixBilling = true;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.form-background {
  background-color: $color_gray_100;
}

input.radio {
  transform: scale(1.5);
}
</style>
