import { AgentStatus } from "@/utils.js";

export default {
  status_count: {
    [AgentStatus.ONLINE]: 0,
    [AgentStatus.ON_CALL]: 0,
    [AgentStatus.ACW]: 0,
    [AgentStatus.ON_MANUAL_CALL]: 0,
    [AgentStatus.ON_MANUAL_CALL_ACW]: 0,
    [AgentStatus.IDLE]: 0,
    [AgentStatus.OFFLINE]: 0,
    [AgentStatus.ON_WORK_BREAK]: 0
  },
  agents: [],
  total: 0,
};
