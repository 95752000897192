<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div
          class="card-header"
          style="padding: 1rem 1.5rem"
        >
          <search-input
            size-class="fs-5"
            @search="search"
          />
        </div>
        <div class="table-responsive">
          <table class="table table-nowrap card-table">
            <thead>
              <tr>
                <th>{{ $t('name') }}</th>
                <th>{{ $t('in_progress') }}</th>
                <th>{{ $t('delivered_to_operation') }}</th>
                <th>{{ $t('average_time_in_chat') }}</th>
                <th>{{ $t('ended') }}</th>
                <th>{{ $t('channel_group') }}</th>
              </tr>
            </thead>
            <tbody class="list">
              <loading-table-component
                v-if="loadingFlags.fetchChatbots"
                :columns="5"
                :lines="15"
              />
              <tr
                v-for="chatbot in chatbots"
                v-else
                :key="chatbot.id"
                class="cursor-pointer"
                @click="openChatbot(chatbot.id)"
              >
                <td>{{ chatbot.name }}</td>
                <td>{{ chatbot.in_progress }}</td>
                <td class="tx-text-gray">
                  {{ chatbot.delivery_to_agents }}
                </td>
                <td class="tx-text-gray">
                  {{ chatbot.average_conversation_time }}
                </td>
                <td class="tx-text-gray">
                  {{ chatbot.finished }}
                </td>
                <td>
                  <span
                    v-if="chatbot?.group_channel_color"
                    class="btn btn-sm btn-rounded-circle circle-span"
                    :style="`background: ${chatbot.group_channel_color};`"
                  />
                  {{ chatbot.group_channel_name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <app-paginator
          :pagination="chatbotsPagination"
          @change-page="changePage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SearchInput from "@components/search-input.vue";
import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";
import AppPaginator from "@components/app-paginator.vue";

import {mapActions, mapGetters, mapMutations} from "vuex";
import _ from "lodash";

export default {
  name: "TableChatbots",

  components: {
    AppPaginator,
    LoadingTableComponent,
    SearchInput
  },

  computed: {
    ...mapGetters("chatbot/chatbots", {
      chatbots: "getChatbots",
      chatbotsPagination: "getChatbotsPagination",
      loadingFlags: "getLoadingFlags"
    })
  },

  methods: {
    ...mapActions("chatbot/chatbots", ["fetchChatbots"]),
    ...mapMutations("chatbot/chatbots", ["setChatbotsParams"]),

    openChatbot(chatbotId) {
      this.$router.push(`/manager/chatbot/${chatbotId}`)
    },

    search: _.debounce(function (term) {
      this.setChatbotsParams({
        flag: 'search',
        value: term
      })
      this.setChatbotsParams({
        flag: 'page',
        value: 1
      })
      this.fetchChatbots()
    }, 500),

    changePage(page) {
      this.setChatbotsParams({
        flag: 'page',
        value: page
      })
      this.fetchChatbots();
    },
  }
}
</script>
<style scoped>
.circle-span{
  width: 8px;
  height: 8px;
  margin-right: 8px;
}
</style>
