<template>
  <div
    ref="canvas"
    class="offcanvas offcanvas-end"
  >
    <div class="offcanvas-header border-0">
      <span
        style="font-size: 17px;"
        class="fw-normal"
      >{{ $t('group') }}</span>
      <button
        type="button"
        data-test="closeGroupOffCanvas-button"
        class="btn-close text-reset fs-2"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      />
    </div>
    <div class="offcanvas-body">
      <div
        class="col d-flex justify-content-start"
        style="margin-bottom: 29px;"
      >
        <span class="avatar background-loading">
          <img
            v-if="chatImage"
            :src="chatImage"
            class="avatar-img rounded-circle"
            @error="handleImageError"
          >
        </span>
        <div class="col d-flex flex-column justify-content-start">
          <h6
            class="fw-normal m-0"
            style="color: #444;"
          >
            {{ groupParticipants.length }} {{ $t('participants_lowercase') }}
          </h6>
          <h2 class="mt-3 mb-0 group-title">
            {{ fields.name_alias }}
          </h2>
        </div>
      </div>
      <div
        class="col-xs-12"
        style="margin-bottom: 29px;"
        v-if="!isAgent"
      >
        <h5
          class="form-label fw-normal group-subtitle w-100"
          style="margin-bottom: 16px;"
        >
          {{ $t('participants_uppercase') }}
        </h5>
        <responsible-group v-if="groupAgentLoaded" />
      </div>
      <div class="col-xs-12">
        <h5
          class="form-label fw-normal group-subtitle"
          style="margin-bottom: 4px;"
        >
          {{ $t('participants_uppercase') }}
        </h5>
        <contact-group
          v-for="(contact, index) in groupParticipants"
          :key="index"
          :contact="contact"
          :id="`contact-${index}`"
        />
      </div>
    </div>
    <div
      v-if="isManager"
      class="position-absolute bottom-0 w-100"
    >
      <div
        class="d-flex justify-content-center align-items-center px-3 delete-group cursor-pointer"
        @click="showModalDeleteGroup = true"
      >
        <span class="text-red py-4">
          <i class="fa-regular fa-trash" />
          {{ $t('delete_group_omni') }}
        </span>
      </div>
    </div>
  </div>

  <modal-delete-group
    @close="showModalDeleteGroup = false"
    :show-modal-delete-group="showModalDeleteGroup"
    :group-name="groupName"
    @delete-group="deleteGroupFromOmni"
  />
</template>

<script>
import { Offcanvas } from "bootstrap";
import { mapGetters, mapActions } from "vuex";
import { formatPhoneNumber, handleImageError, isValidImageUrl } from "@/utils";
import ContactGroup from "./contacts-types/contact-group.vue";
import ResponsibleGroup from "./responsible-group.vue";
import ModalDeleteGroup from "@whatsapp/components/modal-delete-group.vue";

export default {
  emits: ["close"],

  data() {
    return {
      fields: {
        name_alias: "",
        number: null
      },
      offCanvas: null,
      groupAgentLoaded: false,
      showModalDeleteGroup: false
    };
  },

  mounted() {
    const currentChat = this.currentChat[this.$route.params.id];

    if (currentChat?.contact) {
      this.populate(currentChat.contact);
    } else if (this.contact) {
      this.populate(this.contact);
    }

    if (currentChat.is_group) {
      this.findGroupAgents(currentChat).then(() => {
        this.groupAgentLoaded = true;
      });
      this.findGroupParticipants({
        fields: {
          instance_id: currentChat.instance_id,
          id: currentChat.id
        }
      });
    }

    this.offCanvas = new Offcanvas(this.$refs.canvas);
    this.$refs.canvas.addEventListener("hidden.bs.offcanvas", this.close);
  },

  beforeUnmount() {
    this.$refs.canvas.removeEventListener("hidden.bs.offcanvas", this.close);
  },

  components: {
    ModalDeleteGroup,
    ContactGroup,
    ResponsibleGroup
  },

  computed: {
    ...mapGetters("whatsapp/chat", {
      currentChat: "getCurrentChat",
      groupParticipants: "getGroupParticipants"
    }),

    ...mapGetters("whatsapp/contact", {
      contact: "getContact"
    }),

    ...mapGetters("auth", {
      isManager: "isManager"
    }),

    ...mapGetters("auth", {
      isAgent: "isAgent"
    }),

    groupName(){
      return this.currentChat[this.$route.params.id].name
    },

    chatImage() {
      if (
        this.currentChat[this.$route.params.id] &&
        this.currentChat[this.$route.params.id].contact.image &&
        this.isValidImageUrl(this.currentChat[this.$route.params.id].contact.image)
      )
        return this.currentChat[this.$route.params.id].contact.image;
      else return require("@assets/img/avatars/profiles/nome-user.png");
    }
  },

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    show(show) {
      if (show) this.offCanvas.show();
    }
  },

  methods: {
    ...mapActions("whatsapp/chat", ["findGroupParticipants", "findGroupAgents", "deleteGroup"]),
    handleImageError,
    isValidImageUrl,

    populate(contact) {
      this.fields.name_alias = contact.name_alias ? contact.name_alias : contact.name;
      this.fields.number = contact.number;
      this.fields.id = contact.id;
    },

    close() {
      this.$emit("close");
    },

    deleteGroupFromOmni() {
      this.deleteGroup({
        chatId: this.$route.params.id,
        teamId: this.currentChat[this.$route.params.id].team_id,
        groupName: this.currentChat[this.$route.params.id].name
      }).then((response) => {
        if (response.status === 200) {
          this.$router.back();
        }
      })
    },

    formatPhoneNumber,
  }
};
</script>

<style lang="scss" scoped>
.avatar {
  width: 54px;
  height: 54px;
  margin-right: 1rem;
}

.participant {
  border-radius: $whatsapp-border-radius;
  padding: 16px 8px;

  &:hover {
    background-color: $color-gray-blue-300;
  }
}

.text-red {
  color: $red !important;
}

.avatar-participant {
  width: 40px;
  height: 40px;
}

.group-title {
  font-weight: 500;
  color: $color-text-solid-black;
}

.group-subtitle {
  color: $color-text-200;
}

.delete-group {
  background: white;
  box-shadow: 0 4px 18px 0 rgba(34, 54, 77, 0.25);
}
</style>
