<template>
  <div>
    <header-component
      :title="$t('capitalizes.feedbacks')"
      redirect-link="/manager/voice-settings"
    >
      <template #buttons>
        <button
          class="btn btn-primary px-6"
          @click="$router.push('/manager/feedbacks/new')"
        >
          {{ $t("create_new_feedback") }}
        </button>
      </template>
    </header-component>
    <div
      class="card"
    >
      <div class="card-header">
        <h3
          class="mb-0"
          v-if="!loading.loadFeedbacks && !pagination.total && !params.search"
        >
          {{ $t("capitalizes.feedbacks") }}
        </h3>
        <search-input
          v-else
          @search="search"
        />
      </div>
      <empty-state
        v-if="!loading.loadFeedbacks && !pagination.total && !params.search"
        :title="$t('no_feedback')"
        :sub-title="$t('no_feedback_description')"
        icon="feedback.svg"
      >
        <template #buttons>
          <button
            class="btn btn-primary px-6"
            @click="$router.push('/manager/feedbacks/new')"
          >
            {{ $t("create_new_feedback") }}
          </button>
        </template>
      </empty-state>
      <div
        v-else
        class=" card-body pt-0"
      >
        <div
          class="d-flex row"
        >
          <div class="g-4 col-lg-5 col-xl-4 col-xxl-3">
            <card-button
              :title="$t('create_new_feedback')"
              redirect-link="/manager/feedbacks/new"
            />
          </div>
          <template v-if="loading.loadFeedbacks">
            <div
              class="g-4 col-lg-5 col-xl-4 col-xxl-3"
              v-for="loader in 15"
              :key="`loader-${loader}`"
            >
              <card-loading
                :height="74"
                class="mb-0"
              />
            </div>
          </template>
          <template v-else>
            <div
              class="g-4 col-lg-5 col-xl-4 col-xxl-3"
              v-for="(item, index) in list"
              :key="`group-${index}`"
            >
              <feedback-group :group="item" />
            </div>
          </template>
        </div>
      </div>

      <div
        class="card-footer"
        v-if="!loading.loadFeedbacks && pagination.total_pages > 1"
      >
        <paginator
          :pagination="pagination"
          @change-page="changePage"
        />
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import Paginator from "@/components/app-paginator.vue";
import HeaderComponent from "@/components/header-component.vue";
import CardLoading from "@components/card-loading.vue";
import FeedbackGroup from "@dialer/manager/pages/settings/feedbacks/components/feedback-group.vue";
import CardButton from "@components/card-button.vue";
import SearchInput from "@components/search-input.vue";
import EmptyState from "@components/empty-state.vue";

export default {
  mounted() {
    this.loadFeedbacks();
  },
  components: {
    EmptyState,
    SearchInput,
    CardButton, FeedbackGroup, CardLoading,
    Paginator,
    HeaderComponent,
  },
  computed: {
    ...mapGetters("manager/criterion", {
      list: "getDataSelect",
      loading: "getLoadingFlags",
      pagination: "getPagination",
      params: "getParams",
    }),
  },
  methods: {
    ...mapActions("manager/criterion", ["setFilters", "loadFeedbacks", "setCurrentPage"]),
    search: _.debounce(function (term) {
      this.setFilters({
        search: term,
      });
      this.loadFeedbacks();
    }, 500),
    changePage(page) {
      this.setCurrentPage(page);
      this.loadFeedbacks();
    },
  },
  unmounted() {
    this.setFilters({});
  },
};
</script>
