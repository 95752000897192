<template>
  <div>
    <table class="table table-nowrap table-hover app-datatable m-0">
      <thead>
        <tr>
          <th class="ps-4">
            ID
          </th>
          <th class="w-25">
            EMPRESAS
          </th>
          <th class="w-25">
            DATA
          </th>
          <th class="w-25">
            VALOR SOLICITADO
          </th>
          <th>
            STATUS
          </th>
          <th class="pe-auto" />
        </tr>
      </thead>
      <tbody>
        <LoadingTable
          v-if="loadingFlags.loadWithdrawalAnalysisPartners"
          :columns="5"
          :lines="15"
          :list-width="[80, 300, 150, 80, 80]"
        />
        <tr v-else-if="!partners.length">
          <td
            class="text-center"
            colspan="12"
          >
            <img
              alt="Imagem nenhum parceiro encontrado"
              class="mb-4"
              src="@/assets/img/empty-box.svg"
            >

            <div v-if="partnersParams.search !== partners && partnersParams.search != ''">
              <h4>Nenhum solicitação encontrada</h4>
              <p class="text-muted fw-medium mb-5">
                Tente uma nova pesquisa
              </p>
            </div>

            <div v-else-if="!partners.length">
              <h4>Nada para mostrar aqui</h4>
              <p class="text-muted fw-medium mb-5">
                Aguarde uma nova solicitação
              </p>
            </div>
          </td>
        </tr>
        <template v-else>
          <tr
            v-for="partner in partners"
            :key="partner.id"
            class="cursor-pointer"
            @click="openWithdrawalModal(partner)"
          >
            <td class="ps-4 tx-text-gray">
              <span>{{ partner.requested_by.id }}</span>
            </td>
            <td class="tx-text-200">
              <span>{{ partner.requested_by.name }}</span>
            </td>

            <td class="tx-text-gray">
              {{ formatDate(partner.created_at, "DD/MM [às] HH:mm") }}
            </td>
            <td class="tx-text-200">
              {{ formatCurrency(parseFloat(partner.value)) }}
            </td>
            <td>
              <span :class="['badge-status fs-5', statusPartner(partner.status)]">
                {{ partner.status }}
              </span>
            </td>
            <td>
              <i class="fa-regular fa-chevron-right text-muted" />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
  <div v-if="!loadingFlags.loadWithdrawalAnalysisPartners && this.partners.length">
    <div class="col-lg-12 card-footer">
      <paginator
        v-if="!loadingFlags.loadWithdrawalAnalysisPartners"
        :pagination="pagination"
        :recently-quantity="pagination.per_page"
        :show-quantity="true"
        @change-page="changePage"
        @change-quantity="changeQuantityOfPages"
      />
    </div>
  </div>
  <modal-request-partner
    ref="modalRequestPartner"
    :approved="activeColorApproved"
    :dynamic-slots="modalSlots"
    :enable-button="activeButton"
    :partner="currentPartnerSelected"
    :recused="activeColorRecused"
    @modal-closed="resetActiveColors"
  >
    <template #status>
      Status<span class="tx-red-300">*</span>
    </template>
    <template #status-info>
      <span @click="activeClass('Aprovado')">
        <tag-badge
          :active="activeColorApproved"
          texto="Aprovado"
        />
      </span>
    </template>
    <template #status-info-2>
      <span @click="activeClass('Recusado')">
        <tag-badge
          :refused="activeColorRecused"
          texto="Recusado"
        />
      </span>
    </template>
  </modal-request-partner>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import paginator from "@components/app-paginator.vue";
import LoadingTable from "@dialer/manager/components/loading-table-component.vue";
import { Utils, formatDate } from "@/utils";
import ModalRequestPartner from "@admin/pages/3c-partners/partners/components/modal-request-partner.vue";
import TagBadge from "@admin/pages/3c-partners/partners/components/tag-badge.vue";

export default {
  name: "TablePartnersFinanceAnalysis",
  data() {
    return {
      statusRequest: "pending",
      currentPartnerSelected: {},
      activeColorApproved: false,
      activeColorRecused: false,
      activeButton: false
    };
  },
  props: {
    searchValue: {
      type: String,
      default: ""
    }
  },

  mounted() {
    this.loadWithdrawalAnalysisPartners();
  },

  computed: {
    ...mapGetters("admin/partners", {
      partners: "getWithdrawalAnalysisPartners",
      loadingFlags: "getLoadingFlags",
      pagination: "getWithdrawalAnalysisPartnersPagination",
      partnersParams: "getWithdrawalAnalysisPartnersParams",
      analysisWithdrawPartners: "getTotalAnalysisWithdrawPartner"
    }),
    modalSlots() {
      const partner = this.currentPartnerSelected;
      const currency = this.formatCurrency(parseFloat(partner.value));
      const userName = partner.requested_by?.name;
      return [
        { name: "text", content: "Empresa", className: "fw-medium" },
        { name: "subtitle", content: userName, className: "pt-3 pb-4" },
        { name: "text", content: "PIX para depósito", className: "fw-medium" },
        { name: "subtitle", content: partner.pix, className: "pt-3 pb-4" },
        { name: "text", content: "Valor", className: "fw-medium" },
        { name: "subtitle", content: currency, className: "pt-3 pb-4" }
      ];
    }
  },
  components: {
    TagBadge,
    ModalRequestPartner,
    paginator,
    LoadingTable
  },

  methods: {
    formatCurrency: Utils.formatCurrency,
    formatDate,
    ...mapActions("admin/partners", [
      "loadWithdrawalAnalysisPartners",
      "setWithdrawalAnalysisPartnersCurrentPage",
      "setWithdrawalAnalysisPartnersFilters"
    ]),
    ...mapMutations("admin/partners", ["setWithdrawalAnalysisPartnersPerPage"]),

    changePage(page) {
      this.setWithdrawalAnalysisPartnersCurrentPage(page);
      this.loadWithdrawalAnalysisPartners();
    },

    statusPartner(status) {
      if (status === "Análise pendente") return this.statusRequest;
    },

    openWithdrawalModal(partner) {
      this.currentPartnerSelected = partner;
      this.$refs.modalRequestPartner.openModal();
    },

    changeQuantityOfPages(quantity) {
      if (quantity == "all") {
        this.setWithdrawalAnalysisPartnersPerPage(this.analysisWithdrawPartners);
        this.loadWithdrawalAnalysisPartners();
      } else {
        this.setWithdrawalAnalysisPartnersPerPage(quantity);
        this.loadWithdrawalAnalysisPartners();
      }
    },

    activeClass(status) {
      if (status === "Aprovado") {
        this.activeColorApproved = true;
        this.activeColorRecused = false;
        this.activeButton = true;
      } else {
        this.activeColorRecused = true;
        this.activeColorApproved = false;
        this.activeButton = true;
      }
    },

    resetActiveColors() {
      this.activeColorApproved = false;
      this.activeColorRecused = false;
      this.activeButton = false;
    }
  },

  watch: {
    searchValue(search) {
      this.setWithdrawalAnalysisPartnersFilters(search);
      this.setWithdrawalAnalysisPartnersCurrentPage(1);
      this.loadWithdrawalAnalysisPartners();
    }
  }
};
</script>
<style lang="scss" scoped>
.badge-status {
  border-radius: 8px;
  padding: 2px 16px;
}

.pending {
  background-color: #eaf2fc;
  color: $color-blue-300;
}
</style>
