<template>
  <div class="col d-flex">
    <router-link
      :title="$t('back')"
      v-if="isManager"
      :to="{
        path: '/manager/crm/home'
      }"
      class="btn btn-xs back-btn btn-rounded-circle btn-white d-inline-flex justify-content-center align-items-center me-3 p-0 fs-6"
    >
      <i class="fa-regular fa-arrow-left" />
    </router-link>
    <div class="d-block">
      <h6
        class="header-pretitle mb-1 tx-text-gray"
      >
        {{ $t('funnels') }}
      </h6>
      <select-funnel-dropdown
        :funnels="funnels"
        @open-modal="showModal = true"
      />
    </div>
  </div>
  <new-funnel-modal
    v-if="isManager"
    :show-modal="showModal"
    @close="showModal = false"
  />
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import NewFunnelModal from "../funnel/new-funnel-modal.vue";
import SelectFunnelDropdown from "./select-funnel-dropdown.vue";

export default {
  components: {
    NewFunnelModal,
    SelectFunnelDropdown
  },

  data() {
    return {
      showModal: false
    }
  },

  computed: {
    ...mapGetters("auth", {
      isAgent: 'isAgent',
      isManager: 'isManager',
      user: 'getUser'
    }),
    ...mapGetters("crm/funnels", {
      funnels: "getFunnels",
      currentFunnel: "getCurrentFunnel",
    }),
  },

  methods: {
    ...mapActions('crm/funnels', ['setSelectedCurrentFunnel']),
  }
}
</script>

<style lang="scss" scoped>
.back-btn {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
}
</style>
