<template>
  <nav class="navbar navbar-vertical navbar-vertical-sm fixed-start navbar-expand-md">
    <div class="container-fluid">
      <router-link
        to="/partner/dashboard"
        class="partner-logo"
      >
        <img
          alt="Logo 3C"
          src="@/assets/img/partner/logo-partner.svg"
        >

        <img
          alt="3C Partners Text"
          src="@assets/img/partner/partners-3c.svg"
          class="partners-3c"
        >
      </router-link>
      <div class="collapse navbar-collapse">
        <template
          v-for="(group, i) in menu"
          :key="i"
        >
          <ul class="navbar-nav gap-2">
            <li
              v-for="(item, j) in group"
              :key="j"
              class="nav-item"
            >
              <a
                v-if="isExternalLink(item.route)"
                :href="item.route"
                class="nav-link"
                target="_blank"
              >
                <i :class="`fa-regular ${item.icon}`" />
                <span class="nav-link-name">{{ $t(`menu_config.partners.${item.name}`) }}</span>
              </a>

              <router-link
                v-else
                class="nav-link"
                :class="{ active: getActiveRouteName === item.route }"
                :to="item.route"
              >
                <i :class="`fa-regular ${item.icon}`" />
                <span class="nav-link-name">{{ $t(`menu_config.partners.${item.name}`) }}</span>
              </router-link>
            </li>
          </ul>
          <hr
            v-if="i !== menu.length - 1"
            class="navbar-divider my-3"
          >
        </template>

        <div class="mt-auto" />
        <div>
          <button
            class="logout-button"
            @click="_logout"
          >
            <i class="fa-regular fa-arrow-right-from-bracket" />
          </button>
        </div>

        <button
          @click="_logout"
          class="logout-button-expanded btn tx-text-gray"
        >
          {{ $t("exit") }}
          <i class="fa-regular fa-arrow-right-from-bracket" />
        </button>
      </div>
    </div>
  </nav>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import menu from "@/scopes/partner/menu-config"

export default {
  name: "PartnerSidebar",
  data() {
    return {
      menu,
    };
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    _logout() {
      this.logout().then(() => {
        this.$router.push("login");
      });
    },

    isExternalLink(link) {
      return link.startsWith('http');
    },

    getMenu() {
      if (this.hasPermissionToImpersonate) {
        this.menu = this.menu.map(subMenu =>
          subMenu.filter(item => !['extract', 'promotional_materials'].includes(item.name))
        ).filter(subMenu => subMenu.length > 0);
      }

      return this.menu;
    }
  },

  mounted() {
    this.getMenu();
  },

  computed: {
    ...mapGetters("partner", {
      hasPermissionToImpersonate: "hasPermissionToImpersonate",
    }),

    getActiveRouteName() {
      return this.$route.matched && this.$route.matched[1] ? this.$route.matched[1].name : "";
    },
  }
};
</script>

<style lang="scss" scoped>
.navbar {
  border-color: var(--color-gray-blue-300);
  background-color: var(--bs-white);
  padding-top: 30px;
  padding-bottom: 30px;

  .partner-logo {
    display: flex;
    align-items: center;
  }

  &.navbar-expand-md {
    max-width: 72px;
    overflow: hidden;
    transition: max-width 0.3s ease-in-out;

    .partners-3c {
      margin-left: 8px;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }

    &:hover {
      max-width: 260px;

      .partners-3c {
        opacity: 1;
      }

      .navbar-collapse {
        .navbar-nav {
          .nav-link {
            justify-content: start;

            .nav-link-name {
              opacity: 1;
            }
          }
        }

        .logout-button {
          display: none;
        }

        .logout-button-expanded {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    .navbar-collapse {
      margin-top: 32px;

      .navbar-nav {
        .nav-link {
          height: 40px;
          justify-content: start;

          i {
            margin-left: 16px;
          }

          .nav-link-name {
            white-space: nowrap;
            margin-left: 8px;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
          }
        }
      }

      .logout-button {
        width: 32px;
        height: 32px;
        border-radius: 10px;
        margin-left: 8px;
        border: none;
        background-color: var(--color-gray-blue-300);
        color: var(--color-text-gray);
      }

      .logout-button-expanded {
        display: none;
      }
    }
  }
}
</style>
