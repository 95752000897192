<template>
  <single-select
    id="selectQualification"
    ref="selectQualification"
    :list-array="qualifications"
    label="name"
    :error="errors && errors[0]"
    :current-option="value"
    @selected-option="select"
    :disabled="loadingFlag.loadQualificationListDataSelect || disabled"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SingleSelect from "@components/single-select.vue";

export default {
  components: { SingleSelect },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      default: "",
      type: [String, Number],
    },
    errors: {
      type: Array,
      default: null,
    },
    defaultSelectFirst: {
      type: Boolean,
      default: false,
    },
    showOnlyWhatsappQualifications: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.loadQualificationListDataSelect();
  },
  data() {
    return {
      value: "",
    };
  },
  computed: {
    ...mapGetters("manager/qualification", {
      qualificationListDataSelect: "getQualificationListDataSelect",
      qualificationList: "getEachQualificationListDataSelect",
      loadingFlag: "getLoadingFlag",
    }),
    qualifications() {
      return this.qualificationList.filter((qualification) => {
        this.value = this.qualificationList.find((qualification) => qualification.id === this.modelValue)?.name;
        return (
            !this.showOnlyWhatsappQualifications ||
            (this.showOnlyWhatsappQualifications && qualification.type >= 2)
        );
      });
    },
  },

  watch: {
    qualifications() {
      if (this.qualifications.length > 0 && this.defaultSelectFirst && !this.value) {
        this.$emit("update:modelValue", this.qualifications[0].id);
        this.value = this.qualifications[0].name;
      }
    },
  },
  methods: {
    ...mapActions("manager/qualification", ["loadQualificationListDataSelect"]),
    ...mapMutations("auth", ["trackEvents"]),
    select(event) {
      this.$emit("update:modelValue", event.id);
      this.trackEvents("[Manager] Edit qualification")
    },
  },
};
</script>
