import axios from "axios";

export default {

  findActiveUsers({ commit, getters }) {
    commit('startLoading', 'findActiveUsers')
    commit('setActiveUsers', []);
    return new Promise((resolve) => {
      axios.get(`/administrator?active=true`, { params: getters.getActiveUsersParams })
        .then((response) => {
          commit('setActiveUsersPagination', response.data.meta.pagination)
          commit('setActiveUsers', response.data.data);
          commit('setTotalActiveUsers', response.data.meta.pagination.total)
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
        }).finally(() => {
          commit('stopLoading', 'findActiveUsers')
        });
    });
  },
  findInactiveUsers({ commit, getters }) {
    commit('startLoading', 'findInactiveUsers')
    commit('setInactiveUsers', []);
    return new Promise((resolve) => {
      axios.get(`/administrator?active=false`, { params: getters.getInactiveUsersParams })
        .then((response) => {
          commit('setInactiveUsersPagination', response.data.meta.pagination)
          commit('setInactiveUsers', response.data.data);
          commit('setTotalInactiveUsers', response.data.meta.pagination.total)
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
        }).finally(() => {
          commit('stopLoading', 'findInactiveUsers')
        });
    });
  },
  createUser({ commit }, payload) {
    commit('startLoading', 'createUser')
    return new Promise((resolve) => {
      axios.post(`/administrator`, payload.fields)
        .then((response) => {
          commit('system/setSuccessMessage', response.data.detail, { root: true })
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
        }).finally(() => {
          commit('stopLoading', 'createUser')
        });
    });
  },
  editUser({ commit }, payload) {
    commit('startLoading', 'editUser')
    return new Promise((resolve) => {
      axios.put(`/administrator/${payload.id}`, payload.fields)
        .then((response) => {
          commit('system/setSuccessMessage', response.data.detail, { root: true })
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
        }).finally(() => {
          commit('stopLoading', 'editUser')
        });
    });
  },
  desactivateUser({ commit }, payload) {
    commit('startLoading', 'desactivateUser')
    return new Promise((resolve) => {
      axios.post(`/administrator/${payload.id}/deactivate`)
        .then((response) => {
          commit('system/setSuccessMessage', response.data.detail, { root: true })
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
        }).finally(() => {
          commit('stopLoading', 'desactivateUser')
        });
    });
  },
  reactivateUser({ commit }, payload) {
    commit('startLoading', 'reactivateUser')
    return new Promise((resolve) => {
      axios.post(`/administrator/${payload.id}/reactivate`)
        .then((response) => {
          commit('system/setSuccessMessage', response.data.detail, { root: true })
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
        }).finally(() => {
          commit('stopLoading', 'reactivateUser')
        });
    });
  },
}