<template>
  <ul
    class="transference-option-list overflow-auto  m-0"
    v-if="options.length"
  >
    <li
      v-for="option in options"
      :key="option.id"
      class="transference-option d-flex align-items-center justify-content-between tx-text-200 rounded cursor-pointer"
      @mouseenter="hoveredOption = option"
      @mouseleave="hoveredOption = null"
      @click="$emit('transfer', option)"
    >
      <div class="d-flex">
        <div class="extension-number tx-text-gray">
          {{ option.extension_number }}
        </div>
        <div
          class="ms-3 option-name text-truncate"
          :title="option.name"
        >
          {{ option.name }}
        </div>
      </div>
      <i
        v-show="option == hoveredOption"
        class="fa-regular fa-arrow-right fw-normal tx-text-gray pe-3"
      />
    </li>
  </ul>
  <div
    v-else
    class="d-flex flex-column text-center mt-6"
  >
    <h2 class="my-0">
      Oops...
    </h2>
    <img
      :src="require(`@/assets/img/aloisio/Pluzinha_busca.svg`)"
      class="align-self-center my-4"
      style="width: 68px;"
    >
    <p class="fs-6">
      {{ $t('search_messages.results_not_found') }}
    </p>
  </div>
</template>

<script>
export default {
  name: "TransferenceOptionList",
  emits: ["transfer"],
  data() {
    return {
      hoveredOption: null,
    }
  },

  props: {
    options: {
      type: Array,
      required: true,
      validator: (options) => {
        return options.every((option) => {
          const hasId = Object.prototype.hasOwnProperty.call(option, "id") && typeof option.id === "number";
          const hasName = Object.prototype.hasOwnProperty.call(option, "name") && typeof option.name === "string";
          return hasId && hasName;
        });
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.transference-option-list {
  padding: 0;
  height: 310px;

  .transference-option {
    padding: 4px 0 4px 1rem;
    &:hover {
      background-color: $color-gray-blue-300;
    }

    .extension-number {
      width: 36px;
    }

    .option-name {
      width: 217px;
    }
  }
}
</style>
