export default {
  loading: {
    searchQualifications: false,
    findDeliveredCalls: false,
    downloadTable: false,
  },
  errorMessage: '',
  errors: {},
  list: [],
  campaign: null,
  register: null,
  deliveredCalls: [],
}
