<template>
  <div class="card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <p class="card-header-title">
            {{ $t('time_by_agent') }}
          </p>
        </div>
        <!--<div class="col-auto d-flex">-->
        <!--  <app-button-->
        <!--    v-if="agentsReportList"-->
        <!--    class="btn-sm btn-export tx-text-gray col-12 cursor-pointer"-->
        <!--    @click="show = true"-->
        <!--  >-->
        <!--    Baixar CSV-->
        <!--  </app-button>-->
        <!--</div>-->
      </div>
    </div>
    <div class="card-body p-0">
      <div class="table-responsive">
        <table class="table table-nowrap table-striped my-0 card-table">
          <thead>
            <tr>
              <th scope="col">
                <sort-link
                  :title="$t('uppercases.agent')"
                  column="agent_name"
                  :dir="sorts.agent_name.dir"
                  @sort="sort"
                  @click.prevent
                  class="text-muted fw-normal"
                />
              </th>
              <th scope="col">
                <sort-link
                  :title="$t('uppercases.chats')"
                  column="total_chats"
                  :dir="sorts.total_chats.dir"
                  @sort="sort"
                  @click.prevent
                  class="text-muted fw-normal"
                />
              </th>
              <th scope="col">
                <sort-link
                  title="SLA"
                  :tooltip="$t('tooltips.sla')"
                  column="sla"
                  :dir="sorts.sla.dir"
                  @sort="sort"
                  @click.prevent
                  class="text-muted fw-normal"
                />
              </th>
              <th scope="col">
                <sort-link
                  :title="$t('AST')"
                  :tooltip="$t('tooltips.ast')"
                  column="tma"
                  :dir="sorts.tma.dir"
                  @sort="sort"
                  @click.prevent
                  class="text-muted fw-normal"
                />
              </th>
              <th scope="col">
                <sort-link
                  :title="$t('AFRT')"
                  :tooltip="$t('tooltips.afrt')"
                  column="tmpr"
                  :dir="sorts.tmpr.dir"
                  @sort="sort"
                  @click.prevent
                  class="text-muted fw-normal"
                />
              </th>
            </tr>
          </thead>
          <tbody v-if="loadingFlags.fetchAgentsReportList">
            <loading-table-component
              :lines="5"
              :columns="8"
            />
          </tbody>
          <tbody v-else-if="agentsReportList">
            <tr
              v-for="item in agentsReportList"
              :key="item.id"
            >
              <td>{{ item.agent_name }}</td>
              <td>{{ item.total_chats }}</td>
              <td>{{ item.sla }}</td>
              <td>{{ item.tma }}</td>
              <td>{{ item.tmpr }}</td>
            </tr>
            <tr class="fw-bold">
              <td>Total</td>
              <td>{{ totalReportList.total_chats }}</td>
              <td>{{ totalReportList.sla }}</td>
              <td>{{ totalReportList.tma }}</td>
              <td>{{ totalReportList.tmpr }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <table-empty v-if="!agentsReportList && !loadingFlags.fetchAgentsReportList" />
  </div>
  <export-csv-reports
    :show="show"
    :loading="loadingFlags.fetchSendCsvEmail"
    @close="show = false"
    @download="download"
  />
</template>

<script>
import SortLink from "@dialer/manager/components/sort-link.vue";
import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";
import TableEmpty from "@dialer/manager/pages/reports/components/table-empty.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
//import AppButton from "@/components/app-button.vue";
import ExportCsvReports from "@dialer/manager/pages/reports/components/export-csv-reports.vue";

export default {
  name: "TableChatsAgentsTimeReport",
  components: { ExportCsvReports, /*AppButton,*/ TableEmpty, LoadingTableComponent, SortLink },
  data() {
    return {
      show: false,
      sorts: {
        agent_name: {
          dir: ""
        },
        total_chats: {
          dir: ""
        },
        sla: {
          dir: ""
        },
        tmpr: {
          dir: ""
        },
        tma: {
          dir: ""
        }
      }
    };
  },
  computed: {
    ...mapGetters("manager/chats_agents_report", {
      loadingFlags: "getLoadingFlags",
      agentsReportList: "getAgentsReportList",
      totalReportList: "getTotalReportList"
    }),
    total() {
      return this.agentsReportList.group_channel_total_time_metrics;
    }
  },
  methods: {
    ...mapMutations("manager/group_channels_report", ["sortTimeReportList"]),
    ...mapActions("manager/group_channels_report", ["fetchSendTimeCsvEmail"]),

    download(fieldEmail) {
      this.fetchSendTimeCsvEmail(fieldEmail).then(() => {
        this.show = false;
      });
    },
    sort(params) {
      this.sorts["agent_name"].dir = "";
      this.sorts["total_chats"].dir = "";
      this.sorts["sla"].dir = "";
      this.sorts["tmpr"].dir = "";
      this.sorts["tma"].dir = "";
      this.sorts[params.column].dir = params.dir;
      this.sortTimeReportList(params);
    }
  }
};
</script>

<style scoped lang="scss">
.btn-export {
  background: #e1e9f4;
  border: none;
  padding: 8px 16px;
  &:hover {
    background-color: #d2ddec;
  }
}
</style>
