<template>
  <div
    ref="connections"
    @mouseenter="showTooltip"
    @mouseleave="hideTooltip"
  >
    {{ groupChannels.length }} {{ $t('channel_group') }}
  </div>
  <div
    class="card"
    ref="connectionsTooltip"
    id="tooltip"
    @mouseenter="showTooltip"
    @mouseleave="hideTooltip"
  >
    <ul
      class="list-group"
      style="max-height: 280px; overflow-y: scroll"
    >
      <li
        v-for="groupChannel in groupChannels"
        :key="groupChannel.id"
        class="list-group-item"
        style="border: none"
      >
        <dot-status
          size="8px"
          :color="groupChannel.color"
        />
        <span style="margin-left: 8px">
          {{ groupChannel.name }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import DotStatus from "@/components/dot-status.vue";

export default {
  components: { DotStatus },

  props: {
    groupChannels: {
      type: Object,
      required: true,
    },
  },

  data(){
    return{
      connectionsTooltip: null,
      popperInstance: null,
    }
  },

  mounted(){
    this.configTooltip()
  },

  methods:{
    configTooltip(){
      const connections = this.$refs.connections;
      this.connectionsTooltip = this.$refs.connectionsTooltip;

      this.popperInstance = createPopper(connections, this.connectionsTooltip, {
        placement: "left"
      });
    },

    showTooltip() {
      this.connectionsTooltip.setAttribute("data-show", "");
      this.popperInstance.update()
    },

    hideTooltip() {
      this.connectionsTooltip.removeAttribute("data-show");
    },
  }
};
</script>

<style scoped lang="scss">
#tooltip {
  display: none;
  min-width: 136px;
  z-index: 1080;
}

#tooltip[data-show] {
  display: block;
}
</style>
