<template>
  <modal-component
    :show="modelValue"
    @close="close"
    :center="true"
  >
    <template #content>
      <div class="modal-header">
        <h5 class="modal-title">
          {{ $t('integration_capitalize') }} RD Station
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div
        class="modal-body"
        style="height: 630px"
      >
        <div class="d-flex align-items-center badge">
          <img
            src="@assets/img/rd-station.svg"
            alt="RD Station"
          >
          <p class="m-0">
            RD Station
          </p>
        </div>
        <div>
          <label
            for="inputUrl"
            class="form-label"
          >
            URL
          </label>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control tx-text-gray"
              id="inputUrl"
              v-model="rdStationWebhookData.webhook"
              readonly
            >
            <span
              class="input-group-text"
              @click="copyToClipboard"
              @mouseleave="copyToClipboardInfo = $t('copy_link')"
            >
              <i
                class="fa-copy me-2"
                :class="{
                  'fa-regular': copyToClipboardInfo === $t('copy_link'),
                  'fa-solid': copyToClipboardInfo === $t('copied')
                }"
              />
              {{ copyToClipboardInfo }}
            </span>
          </div>
        </div>
        <ol class="d-flex flex-column pt-4 list-steps">
          <li
            class="m-0 tx-text-gray text-medium"
            v-html="$t('accessProfile')"
          />
          <li
            class="m-0 tx-text-gray text-medium"
            v-html="$t('configure_webhook')"
          />
          <li
            class="m-0 tx-text-gray text-medium"
            v-html="$t('create_webhook')"
          />
          <li
            class="m-0 tx-text-gray text-medium"
            v-html="$t('select_conversion')"
          />
        </ol>
        <div
          class="alert alert-warning text-medium"
          role="alert"
        >
          <i class="fa-regular fa-triangle-exclamation" />
          {{ $t('disconnect_rd_station_integration') }}
        </div>
        <div>
          <div class="d-flex align-items-center">
            <div class="form-switch pb-2">
              <input
                v-model="submitData.select_forms_ids"
                type="checkbox"
                role="switch"
                id="switchFormIds"
                class="form-check-input"
              >
            </div>
            <label
              for="switchFormIds"
              class="form-label"
            >
              {{ $t('select_form_optional') }}
            </label>
            <span
              ref="infoFormIds"
              class="fal fa-circle-info fw-medium fs-5 tx-text-gray ms-3 pb-2"
            />
          </div>
          <input
            v-model="tempIds"
            :disabled="!submitData.select_forms_ids"
            type="text"
            id="inputIds"
            :placeholder="$t('forms_example')"
            :class="{
              'form-control': true,
              'is-invalid': error && submitData.select_forms_ids,
            }"
          >
          <div
            v-if="this.submitData.select_forms_ids"
            class="invalid-feedback d-flex justify-content-end"
          >
            {{ error }}
          </div>
        </div>
        <p
          class="mt-2 tx-text-gray text-medium"
          v-html="$t('copy_paste_form_identifier')"
        />
        <div class="mt-auto d-flex justify-content-center align-content-center position-absolute btn-submit">
          <app-button
            @click="submit"
            :loading="loadingFlags.createRDStationIntegration"
            class="align-self-center"
            style="width: 260px"
          >
            {{ $t('capitalizes.save') }}
          </app-button>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from "@components/modal-component.vue";
export default {
  name: "IntegrationModalRDStation",

  emits: ["close", "update:modelValue"],

  components: {
    ModalComponent,
    AppButton
  },

  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    integration: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      step: "webhook",
      error: "",
      submitData: {
        select_forms_ids: false,
        type: "rd_station",
        rd_station_forms_ids: []
      },
      tempIds: "",
      copyToClipboardInfo: this.$t('copy_link')
    };
  },

  created() {
    this.fetchWebhookData({ type: "rd_station" });
  },

  watch: {
    modelValue(value){
      if(value){
        if (this.integration !== null) {
          this.submitData = {
            id: this.integration.id,
            select_forms_ids: this.integration.select_forms_ids,
            rd_station_forms_ids: this.integration.rd_station_forms_ids,
            type: "rd_station",
          }
          if(this.integration.rd_station_forms_ids){
            this.tempIds = this.integration.rd_station_forms_ids.join();
          }
        }
      }
    },

  },

  mounted() {
    const text = this.$t('rd_leads_feature');
    generateTooltip(text, this.$refs.infoFormIds);

  },
  computed: {
    ...mapGetters("crm/integrations", {
      rdStationWebhookData: "getRDStationWebhookData",
      loadingFlags: "getLoadingFlags",
      errors: "getRDStationErrors"
    }),

  },
  methods: {
    ...mapActions("crm/integrations",
        [
          "fetchWebhookData",
          "createRDStationIntegration",
          "fetchFunnelIntegrations",
          "updateRDStationIntegration"
        ]),
    close() {
      this.$emit("update:modelValue", false);
    },
    submit() {
      if(this.handleSelectForms()){
        if(this.integration){
          this.updateRDStationIntegration(this.submitData).then(() => {
            this.fetchFunnelIntegrations().finally(() => {
              this.close();
              this.error = "";
            });
          });
        } else{
          this.createRDStationIntegration(this.submitData).then(() => {
            this.fetchFunnelIntegrations().finally(() => {
              this.close();
              this.error = "";
            });
          });
        }
      }
    },

    handleSelectForms(){
      if (this.submitData.select_forms_ids) {
        if(this.tempIds.length){
          this.tempIds = this.tempIds.replace(/\s/g, "");
          this.submitData.rd_station_forms_ids = this.tempIds.split(",");
          return true
        }
        else{
          this.error = this.$t('required_field')
          return false
        }
      } else {
        return this.handleSelectFormsFalse()
      }
    },

    handleSelectFormsFalse(){
      if(this.integration){
        this.submitData = {
          id: this.integration.id,
          select_forms_ids: false,
          type: "rd_station",
        }
      } else {
        this.submitData = {
          select_forms_ids: false,
          type: "rd_station",
        }
      }
      this.tempIds = "";
      return true
    },

    copyToClipboard() {
      navigator.permissions.query({ name: "clipboard-write" }).then(result => {
        if (result.state === "granted" || result.state === "prompt") {
          navigator.clipboard.writeText(this.rdStationWebhookData.webhook);
          this.copyToClipboardInfo = this.$t('copied');
        }
      });
    }
  }
};
import AppButton from "@components/app-button.vue";
import { mapActions, mapGetters } from "vuex";

import { generateTooltip } from "@/utils";
</script>

<style scoped lang="scss">
.badge {
  height: 2rem;
  width: 9rem;
  border-radius: 0.625rem;
  padding: 0 1rem;
  background-color: #e1e9f4;
  color: #3057f2;
  margin-bottom: 1.5rem;

  img {
    height: 24px;
    width: auto;
    margin-right: 0.625rem;
  }
}

.alert-warning {
  background: #f7eac4;
  color: #754000;
}

.list-steps {
  list-style-position: inside;
  padding-left: 1rem;
}

.input-group-text {
  cursor: pointer;
  &:hover {
    background: white;
    color: #444444;
  }
}

.btn-submit {
  bottom: 0;
  margin-bottom: 2rem;
  left: 50%;
  transform: translate(-50%);
}
</style>