<template>
  <button
    class="btn btn-danger exit-manual-btn px-4"
    :disabled="loadingFlags.exitManual || loadingFlags.exitManualCallACW"
    @click="exit"
  >
    {{ $t("logout") }}
  </button>
</template>

<script>
import { AgentStatus } from "@/utils.js";
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("system", {
      loadingFlags: "getLoadingFlags",
      status: "getStatus"
    })
  },

  methods: {
    ...mapActions("system", ["exitManual", "exitManualCallACW"]),
    exit() {
      if (this.status === AgentStatus.ON_MANUAL_CALL_ACW) {
        this.exitManualCallACW();
      } else {
        this.exitManual();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.exit-manual-btn {
  height: 32px;
  line-height: 0.9;
}
</style>
