<template>
  <hover-button
    ref="internalTransference"
    class="internal-transference"
    @click="toggleDropdown"
    icon="fa-regular fa-phone-arrow-right"
    background-color="var(--bs-white)"
    :keep-button-hovered="showInternalTransferenceCard"
    :disabled="isTransferenceDisabled"
  />

  <dropdown-component
    :is-open="showInternalTransferenceCard"
    :button-rect="buttonRect"
    :title="$t('transfer_chat')"
  >
    <div class="d-flex align-items-center justify-content-around radio-buttons">
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          name="transferOption"
          value="receptiveQueues"
          id="receptiveQueues"
          v-model="transferOption"
        >
        <label
          class="form-check-label"
          for="receptiveQueues"
        >{{ $t("receptive_queue") }}</label>
      </div>

      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          name="transferOption"
          value="agent"
          id="agent"
          v-model="transferOption"
        >
        <label
          class="form-check-label"
          for="agent"
        >
          {{ $t("agent_capitalize") }}
        </label>
      </div>
    </div>
    <div class="card-transference-body">
      <div>
        <transference-option-list
          v-if="transferOption === 'receptiveQueues' && !queueLoadingFlags.findQueuesOnline"
          :options="listQueuesOnline"
          @transfer="transferToQueue"
        />
        <transference-option-list
          v-else-if="transferOption === 'agent' && !agentLoadingFlags.findAgentsOnline"
          :options="listAgentsOnline.filter(agent => agent.id !== user.id)"
          @transfer="transferToAgent"
        />
      </div>
      <div
        class="h-75 d-flex flex-column justify-content-center align-items-center"
        v-if="queueLoadingFlags.findQueuesOnline || agentLoadingFlags.findAgentsOnline"
      >
        <div
          class="spinner-border mb-3 text-primary"
          role="loading"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
        <p>{{ $t('loading_capitalize') }}...</p>
      </div>
    </div>
  </dropdown-component>
</template>

<script>
import { AgentStatus } from "@/utils";
import HoverButton from "@dialer/agent/components/hover-button.vue";
import DropdownComponent from "@dialer/agent/components/dropdown-component.vue";
import TransferenceOptionList from "./transference-option-list.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "InternalTransference",
  data() {
    return {
      AgentStatus,
      showInternalTransferenceCard: false,
      buttonRect: null,
      hoveredItem: null,
      searchText: "",
      transferOption: "receptiveQueues"
    }
  },

  components: {
    HoverButton,
    DropdownComponent,
    TransferenceOptionList
  },

  methods: {
    ...mapActions("agent/queue", [
      "findQueuesOnline",
      "setQueueInConsult",
      "consultQueue"
    ]),
    ...mapActions("agent/agent", [
      "findAgentsOnline",
      "consultAgent",
      "setActiveDropdownRight"
    ]),
    ...mapActions("system", ["setAgentInConsult"]),
    toggleDropdown() {
      this.showInternalTransferenceCard = !this.showInternalTransferenceCard
      if (this.showInternalTransferenceCard) {
        this.setActiveDropdownRight("InternalTransference");
        this.calculateButtonRect();
        window.addEventListener('resize', this.calculateButtonRect);
        this.findQueuesOnline();
        this.findAgentsOnline();
      } else {
        window.removeEventListener('resize', this.calculateButtonRect);
      }
    },

    calculateButtonRect() {
      const rect = this.$refs.internalTransference.$el.getBoundingClientRect();
      this.buttonRect = rect;
    },

    transferToQueue(queue) {
      this.setQueueInConsult(queue);
      this.consultQueue({
        queue_id: queue.id,
      });
      this.showInternalTransferenceCard = false;
    },

    transferToAgent(agent) {
      this.setAgentInConsult(agent);
      this.consultAgent({
        consultedId: agent.id,
      });
      this.showInternalTransferenceCard = false;
    }
  },

  computed: {
    ...mapGetters("agent/agent", {
      listAgentsOnline: "getListAgentsOnline",
      agentLoadingFlags: "getLoadingFlags",
      activeDropdownRight: "getActiveDropdownRight"
    }),

    ...mapGetters("agent/queue", {
      listQueuesOnline: "getListQueuesOnline",
      queueLoadingFlags: "getLoadingFlags"
    }),

    ...mapGetters("agent/call", {
      call: "getCall"
    }),

    ...mapGetters("auth", {
      user: "getUser"
    }),

    ...mapGetters("system", {
      status: "getStatus"
    }),

    isTransferenceDisabled() {
      const isLoading = this.agentLoadingFlags.consultAgent || this.queueLoadingFlags.consultQueue;
      const isLoggedAgentBeingConsulted = !this.call && this.status === AgentStatus.CONSULT_CONNECTED;

      return isLoading || isLoggedAgentBeingConsulted;
    }
  },

  watch: {
    activeDropdownRight(newVal) {
      if (newVal !== "InternalTransference" && this.showInternalTransferenceCard) {
        this.toggleDropdown();
      }
    }
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.calculateButtonRect);
  }
}
</script>

<style lang="scss" scoped>
.radio-buttons {
  padding: 24px 48px;
  border-bottom: 1px solid var(--color-gray-blue-300);
}

.card-transference-body {
  height: 345px;
  padding: 1rem;

  .search-input {
    padding: 0 1rem;
    margin-bottom: 1rem;
  }

  .horizontal-line {
    top: 74px;
    left: 0px;
    width: 340px;
  }
}
</style>
