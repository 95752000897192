<script setup>
import {computed} from "vue"
import {useStore} from "vuex";
import {useDashboardQuery} from "@/composables/useDashboardQuery";
import DashCard from "@crm/pages/dashboard/components/dash-card.vue";
import IconCard from "@components/icon-card.vue";
import RatingFragment from "@crm/pages/dashboard/components/rating-fragment.vue";

const store = useStore();
const dateRange = computed(() => store.getters["crm/dashboard/getDateRange"])
const currentFunnel = computed(() => store.getters["crm/funnels/getCurrentFunnel"] ?? null);
const agent = computed(() => store.getters["crm/dashboard/getActiveAgent"] ?? null);

const {data, loading} = useDashboardQuery(
  currentFunnel,
  dateRange,
  dateRange,
  agent,
  'completedActivities',
);

</script>

<script>
export default {
  name: "CompleteActivities",
  methods: {
    getLabel(label) {
      if (label === 'yesterday' || label === 'previous') {
        return this.$t(label)
      }

      return label
    }
  }
}
</script>


<template>
  <dash-card
    :title="$t('completed_activities')"
    :tooltip-title="$t('tooltips.dashboard.completed_activities.title')"
    :tooltip-body="$t('tooltips.dashboard.completed_activities.body')"
    tooltip-width="254px"
    :loading="loading"
    min-height="272"
  >
    <template #body>
      <div
        style="row-gap: 1.5rem"
        class="row"
      >
        <div class="col-lg-4">
          <div class="card">
            <div class="card-body">
              <div
                style="margin-bottom: 1rem"
                class="d-flex gap-2"
              >
                <icon-card
                  variant="fa-regular fa-circle-check"
                  rounded="rounded-circle"
                  width="24px"
                  height="24px"
                  icon-size="14px"
                />
                <span class="tx-text-200 fw-medium">{{ $t('capitalizes.tasks') }}</span>
              </div>
              <rating-fragment
                :is-late-count="data?.activities[0]?.task[0]?.is_late_count"
                :is-late-percent="`${data?.activities[0]?.task[0]?.is_late_percent}%`"
                :on-time-count="data?.activities[0]?.task[0]?.on_time_count"
                :on-time-percent="`${data?.activities[0]?.task[0]?.on_time_percent}%`"

                :previous-is-late-count="data?.activities[0]?.task[0]?.previous_is_late_count"
                :previous-is-late-percent="`${data?.activities[0]?.task[0]?.previous_is_late_percent}%`"
                :previous-on-time-count="data?.activities[0]?.task[0]?.previous_on_time_count"
                :previous-on-time-percent="`${data?.activities[0]?.task[0]?.previous_on_time_percent}%`"

                :title="$t('capitalizes.tasks')"
                :sub-title="getLabel(dateRange.currentLabel)"
                :current-sub-title="getLabel(dateRange.label)"
                :tooltip="true"
                :difference-value="`${Math.abs(data?.activities[0]?.task[0]?.percent)}%`"
                :difference-status="data?.activities[0]?.task[0]?.percent > 0"
                :previous-value="data?.activities[0]?.task[0]?.previous_count"
                :actual-value="data?.activities[0]?.task[0]?.count"
                :by-steps="data?.activities_by_step[0]?.task"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card">
            <div class="card-body">
              <div
                style="margin-bottom: 1rem"
                class="d-flex gap-2"
              >
                <icon-card
                  variant="fa-regular fa-phone"
                  rounded="rounded-circle"
                  width="24px"
                  height="24px"
                  icon-size="14px"
                />
                <span class="tx-text-200 fw-medium">{{ $t('calls_capitalizes') }}</span>
              </div>
              <rating-fragment
                :is-late-count="data?.activities[0]?.call[0]?.is_late_count"
                :is-late-percent="`${data?.activities[0]?.call[0]?.is_late_percent}%`"
                :on-time-count="data?.activities[0]?.call[0]?.on_time_count"
                :on-time-percent="`${data?.activities[0]?.call[0]?.on_time_percent}%`"

                :previous-is-late-count="data?.activities[0]?.call[0]?.previous_is_late_count"
                :previous-is-late-percent="`${data?.activities[0]?.call[0]?.previous_is_late_percent}%`"
                :previous-on-time-count="data?.activities[0]?.call[0]?.previous_on_time_count"
                :previous-on-time-percent="`${data?.activities[0]?.call[0]?.previous_on_time_percent}%`"


                :title="$t('calls_capitalizes')"
                :tooltip="true"
                :sub-title="getLabel(dateRange.currentLabel)"
                :current-sub-title="getLabel(dateRange.label)"
                :difference-value="`${Math.abs(data?.activities[0]?.call[0]?.percent)}%`"
                :difference-status="data?.activities[0]?.call[0]?.percent > 0"
                :previous-value="data?.activities[0]?.call[0]?.previous_count"
                :actual-value="data?.activities[0]?.call[0]?.count"
                :by-steps="data?.activities_by_step[0]?.call"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card">
            <div class="card-body">
              <div
                style="margin-bottom: 1rem"
                class="d-flex gap-2"
              >
                <icon-card
                  variant="fa-regular fa-messages"
                  rounded="rounded-circle"
                  width="24px"
                  height="24px"
                  icon-size="14px"
                />
                <span class="tx-text-200 fw-medium">{{ $t('capitalizes.messages') }}</span>
              </div>
              <rating-fragment
                :is-late-count="data?.activities[0]?.message[0]?.is_late_count"
                :is-late-percent="`${data?.activities[0]?.message[0]?.is_late_percent}%`"
                :on-time-count="data?.activities[0]?.message[0]?.on_time_count"
                :on-time-percent="`${data?.activities[0]?.message[0]?.on_time_percent}%`"

                :previous-is-late-count="data?.activities[0]?.message[0]?.previous_is_late_count"
                :previous-is-late-percent="`${data?.activities[0]?.message[0]?.previous_is_late_percent}%`"
                :previous-on-time-count="data?.activities[0]?.message[0]?.previous_on_time_count"
                :previous-on-time-percent="`${data?.activities[0]?.message[0]?.previous_on_time_percent}%`"

                :title="$t('capitalizes.messages')"
                :sub-title="getLabel(dateRange.currentLabel)"
                :current-sub-title="getLabel(dateRange.label)"
                :tooltip="true"
                :difference-value="`${Math.abs(data?.activities[0]?.message[0]?.percent)}%`"
                :difference-status="data?.activities[0]?.message[0]?.percent > 0"
                :previous-value="data?.activities[0]?.message[0]?.previous_count"
                :actual-value="data?.activities[0]?.message[0]?.count"
                :by-steps="data?.activities_by_step[0]?.message"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card">
            <div class="card-body">
              <div
                style="margin-bottom: 1rem"
                class="d-flex gap-2"
              >
                <icon-card
                  variant="fa-regular fa-envelope"
                  rounded="rounded-circle"
                  width="24px"
                  height="24px"
                  icon-size="14px"
                />
                <span class="tx-text-200 fw-medium">{{ $t('capitalizes.emails') }}</span>
              </div>
              <rating-fragment
                :is-late-count="data?.activities[0]?.email[0]?.is_late_count"
                :is-late-percent="`${data?.activities[0]?.email[0]?.is_late_percent}%`"
                :on-time-count="data?.activities[0]?.email[0]?.on_time_count"
                :on-time-percent="`${data?.activities[0]?.email[0]?.on_time_percent}%`"

                :previous-is-late-count="data?.activities[0]?.email[0]?.previous_is_late_count"
                :previous-is-late-percent="`${data?.activities[0]?.email[0]?.previous_is_late_percent}%`"
                :previous-on-time-count="data?.activities[0]?.email[0]?.previous_on_time_count"
                :previous-on-time-percent="`${data?.activities[0]?.email[0]?.previous_on_time_percent}%`"


                :title="$t('capitalizes.emails')"
                :sub-title="getLabel(dateRange.currentLabel)"
                :current-sub-title="getLabel(dateRange.label)"
                :tooltip="true"
                :difference-value="`${Math.abs(data?.activities[0]?.email[0]?.percent)}%`"
                :difference-status="data?.activities[0]?.email[0]?.percent > 0"
                :previous-value="data?.activities[0]?.email[0]?.previous_count"
                :actual-value="data?.activities[0]?.email[0]?.count"
                :by-steps="data?.activities_by_step[0]?.email"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card">
            <div class="card-body">
              <div
                style="margin-bottom: 1rem"
                class="d-flex gap-2"
              >
                <icon-card
                  variant="fa-regular fa-user-group"
                  rounded="rounded-circle"
                  width="24px"
                  height="24px"
                  icon-size="14px"
                />
                <span class="tx-text-200 fw-medium">{{ $t('capitalizes.meetings') }}</span>
              </div>
              <rating-fragment
                :is-late-count="data?.activities[0]?.meeting[0]?.is_late_count"
                :is-late-percent="`${data?.activities[0]?.meeting[0]?.is_late_percent}%`"
                :on-time-count="data?.activities[0]?.meeting[0]?.on_time_count"
                :on-time-percent="`${data?.activities[0]?.meeting[0]?.on_time_percent}%`"

                :previous-is-late-count="data?.activities[0]?.email[0]?.previous_is_late_count"
                :previous-is-late-percent="`${data?.activities[0]?.email[0]?.previous_is_late_percent}%`"
                :previous-on-time-count="data?.activities[0]?.email[0]?.previous_on_time_count"
                :previous-on-time-percent="`${data?.activities[0]?.email[0]?.previous_on_time_percent}%`"


                :title="$t('capitalizes.meetings')"
                :sub-title="getLabel(dateRange.currentLabel)"
                :current-sub-title="getLabel(dateRange.label)"
                :tooltip="true"
                :difference-value="`${Math.abs(data?.activities[0]?.meeting[0]?.percent)}%`"
                :difference-status="data?.activities[0]?.meeting[0]?.percent > 0"
                :previous-value="data?.activities[0]?.meeting[0]?.previous_count"
                :actual-value="data?.activities[0]?.meeting[0]?.count"
                :by-steps="data?.activities_by_step[0]?.meeting"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card">
            <div class="card-body">
              <div style="margin-bottom: 1rem">
                <span class="tx-text-200 fw-medium">{{ $t('uppercases.total') }}</span>
              </div>
              <rating-fragment
                :is-late-count="data?.activities[0]?.total[0]?.is_late_count"
                :is-late-percent="`${data?.activities[0]?.total[0]?.is_late_percent}%`"
                :on-time-count="data?.activities[0]?.total[0]?.on_time_count"
                :on-time-percent="`${data?.activities[0]?.total[0]?.on_time_percent}%`"

                :previous-is-late-count="data?.activities[0]?.total[0]?.previous_is_late_count"
                :previous-is-late-percent="`${data?.activities[0]?.total[0]?.previous_is_late_percent}%`"
                :previous-on-time-count="data?.activities[0]?.total[0]?.previous_on_time_count"
                :previous-on-time-percent="`${data?.activities[0]?.total[0]?.previous_on_time_percent}%`"


                :title="$t('uppercases.total')"
                :sub-title="getLabel(dateRange.currentLabel)"
                :current-sub-title="getLabel(dateRange.label)"
                :tooltip="true"
                :difference-value="`${Math.abs(data?.activities[0]?.total[0]?.percent)}%`"
                :difference-status="data?.activities[0]?.total[0]?.percent > 0"
                :previous-value="data?.activities[0]?.total[0]?.previous_count"
                :actual-value="data?.activities[0]?.total[0]?.count"
                :by-steps="data?.activities_by_step[0]?.total"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </dash-card>
</template>

<style scoped lang="scss">
.card {
  margin-bottom: 0;
}
</style>