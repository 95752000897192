<template>
  <div class="main-content utilization-report">
    <div class="container-fluid">
      <header-component
        :title="$t('feedbacks_report')"
        :pretitle="$t('uppercases.reports')"
        redirect-link="/manager/reports"
      />

      <div class="row">
        <div class="col-lg-3">
          <div class="form-group">
            <label class="form-label required">{{ $t('period') }}</label>
            <input-date-range
              preset="month"
              :max-interval="31"
              v-model:start="minDate"
              v-model:end="maxDate"
            />
          </div>
        </div>

        <div class="col-lg-3">
          <div class="form-group">
            <label class="form-label required">{{ $t('campaign_capitalize') }}</label>
            <select-campaign v-model="campaign_id" />
          </div>
        </div>

        <div class="col-lg-3">
          <div class="form-group">
            <label class="form-label required">{{ $t('manager_capitalizes') }}</label>
            <select-manager
              :no-select-return-zero="true"
              v-model="manager_id"
            />
          </div>
        </div>

        <div class="col-lg-3">
          <app-button
            class="w-100 btn-report"
            :blocked="loading.findFeedbackReports || this.minDate == '' || this.campaign_id == ''"
            @click="search"
            :loading="loading.findFeedbackReports"
          >
            {{ $t('search_report') }}
          </app-button>
        </div>

        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <h4 class="card-header-title fw-normal">
                    {{ $t('feedbacks_report') }}
                  </h4>
                </div>

                <div class="col-auto">
                  <a
                    href="#"
                    class="btn btn-outline-secondary"
                    @click="generateCSVModal.show()"
                  >{{ $t('download_CSV') }}</a>
                </div>
              </div>
            </div>
            <div class="card-body py-0 px-0">
              <table class="table table-nowrap card-table">
                <thead>
                  <tr>
                    <th class="">
                      <sort-link
                        title="Nome"
                        column="agent"
                        :dir="sorts.agent.dir"
                        @sort="sort"
                        class="text-muted fw-normal"
                      />
                    </th>
                    <th class="text-center">
                      <sort-link
                        title="Quant."
                        @sort="sort"
                        :dir="sorts.total_feedbacks.dir"
                        column="total_feedbacks"
                        class="text-muted fw-normal"
                      />
                    </th>
                    <th class="text-center fw-normal">
                      Feedbacks
                    </th>
                    <th />
                  </tr>
                </thead>

                <tbody style="border-top: none">
                  <tr v-if="loading.findAgentFeedbackReports">
                    <td colspan="4">
                      <div class="card-body text-center my-5">
                        <div class="spinner-border spinner-lg text-muted">
                          <span class="visually-hidden" />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr v-else-if="feedbackStatistic.length == 0">
                    <td
                      class="text-center text-muted"
                      colspan="4"
                    >
                      <span class="fe fe-alert-octagon" /> {{
                        $t('no_report_to_show')
                      }}
                    </td>
                  </tr>

                  <template
                    v-for="(item, i) in feedbackStatistic"
                    :key="i"
                    v-else
                  >
                    <tr>
                      <td>
                        <strong>{{ item.agent }}</strong>
                      </td>

                      <td>
                        {{ item.total_feedbacks }}
                      </td>

                      <td class="w-100">
                        <criterions-bar
                          :data="Object.values(item.criterion_json)"
                          :count="item.total_feedbacks"
                        />
                      </td>

                      <td class="py-0">
                        <a
                          href="#"
                          @click="loadAgentFeedbackStatistics(item.agent_id, i)"
                        >
                          <span
                            class="fe fe-chevron-down"
                            v-if="openIndex != i"
                          />
                          <span
                            v-else
                            class="fe fe-chevron-up"
                          />
                        </a>
                      </td>
                    </tr>

                    <template v-if="openIndex == i">
                      <tr v-if="loading.findAgentFeedbackReports">
                        <td
                          colspan="4"
                          class="text-center"
                        >
                          <div
                            class="spinner-border text-muted my-5"
                            role="status"
                          >
                            <span class="visually-hidden" />
                          </div>
                        </td>
                      </tr>

                      <tr
                        class="columnp-0"
                        v-else-if="feedbackAgent[item.agent_id]"
                      >
                        <td
                          colspan="4"
                          class="p-0"
                          style="padding: 0 !important"
                        >
                          <table class="table table-nowrap card-table mx-0">
                            <thead style="border-top: none">
                              <tr>
                                <th>{{ $t('date_capitalize') }}/{{ $t('hour') }}</th>
                                <th>{{ $t('phone') }}</th>
                                <th>{{ $t('qualification') }}</th>
                                <th>Feedbacks</th>
                                <th>{{ $t('obs') }}.</th>
                              </tr>
                            </thead>

                            <tbody style="border-top: none">
                              <tr
                                v-for="(subitem, j) of feedbackAgent[item.agent_id]"
                                :key="j"
                              >
                                <td class="text-muted">
                                  {{ format(subitem.call_history.created_at) }}
                                </td>
                                <td class="text-muted">
                                  {{ formatPhoneNumber(subitem.call_history.number) }}
                                </td>
                                <td class="text-muted">
                                  {{ subitem.call_history.qualification.name }}
                                </td>
                                <td class="w-0">
                                  <criterions-bar
                                    :data="subitem.criterion_json"
                                    :count="1"
                                  />
                                </td>
                                <td>
                                  <div class="d-flex justify-content-between align-items-stretch">
                                    <p
                                      class="mb-0 text-truncate text-wrap"
                                    >
                                      {{ subitem.comment }}
                                    </p>
                                    <button
                                      class="btn text-primary p-0"
                                      style="height: 20px;"
                                      @click="openFeedbackOffCanvas(subitem)"
                                    >
                                      <span class="fe fe-edit" />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="modalDownloadCSV"
      tabindex="-1"
      aria-labelledby="modalDownloadCSVLabel"
      aria-hidden="true"
      ref="generateCSVModal"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h3
              class="modal-title"
              id="modalDownloadCSVLabel"
            >
              {{ $t('generate_report') }}
            </h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div class="modal-body">
            <p>
              {{ $t('send_report_to_email') }}
            </p>
            <form
              @submit.prevent="generateCSV"
              id="formCSV"
            >
              <div class="mb-3">
                <label
                  for="input-email"
                  class="col-form-label"
                >{{ $t('report_email') }}</label>
                <input
                  type="email"
                  class="form-control"
                  id="input-email"
                  required
                  v-model="email"
                >
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              {{ $t('cancel_general') }}
            </button>
            <app-button
              form="formCSV"
              :loading="loading.downloadTable"
              style="min-width: 6.5rem;"
            >
              {{ $t('generate_csv') }}
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <form-feedback
    :show="showFeedbackOffcanvas"
    :call-history-id="agentFeedback.call_history_id"
    :campaign-id="agentFeedback.campaign_id"
    :call="agentFeedback.callItem"
    :recording="agentFeedback.recording"
    @close="showFeedbackOffcanvas = false"
    @update-feedback="updateFeedbacks"
  />
</template>

<script>
import moment from "moment";

import { mapActions, mapGetters, mapMutations } from "vuex";

import { formatPhoneNumber } from "@/utils";

import InputDateRange from "@components/input-date-range";
import AppButton from "@components/app-button";
import SelectCampaign from "@dialer/manager/components/select-campaign";
import SelectManager from "@dialer/manager/components/select-manager";
import CriterionsBar from "./components/criterions_bar.vue";
import SortLink from "@dialer/manager/components/sort-link";
import HeaderComponent from "@/components/header-component.vue";
import { Modal } from "bootstrap";
import FormFeedback from "./components/form-feedback.vue";

export default {
  data() {
    return {
      sorts: {
        agent: {
          dir: ""
        },
        total_feedbacks: {
          dir: ""
        }
      },
      //index.js openned row
      openIndex: -1,
      minDate: "",
      maxDate: "",
      index: 1,
      campaign_id: "",
      manager_id: 0,
      email: "",
      generateCSVModal: null,
      showFeedbackOffcanvas: false,
      agentFeedback: {
        call_history_id: null,
        campaign_id: null,
        callItem: {
          agent: "",
          number: "",
          date: "",
          qualification: "",
        },
        recording: "",
      },
    };
  },

  mounted() {
    this.generateCSVModal = new Modal(this.$refs.generateCSVModal);
  },

  computed: {
    ...mapGetters("manager/feedback-report", {
      feedbackStatistic: "getFeedbackStatistic",
      loading: "getLoadingFlags",
      feedbackAgent: "getFeedbackAgent"
    }),
    ...mapGetters("auth", {
      user: "getUser"
    })
  },

  watch: {
    feedbackStatistic() {
      this.sorts["agent"].dir = "";
      this.sorts["total_feedbacks"].dir = "";
    }
  },

  components: {
    InputDateRange,
    AppButton,
    SelectCampaign,
    CriterionsBar,
    SelectManager,
    SortLink,
    HeaderComponent,
    FormFeedback
  },

  methods: {
    /**
     * appyl mask
     */
    formatPhoneNumber,

    /**
     * format date
     */
    format(date) {
      return moment(date).format("DD/MM/YYYY [às] HH[h]mm[m]ss[s]");
    },

    /**
     * opem sub table and load agent statistcs
     */
    loadAgentFeedbackStatistics(agent_id, rowIndex) {
      if (this.openIndex == rowIndex) {
        this.openIndex = -1;

      } else {
        this.findAgentFeedbackReports({
          start_date: this.minDate,
          end_date: this.maxDate,
          campaign_id: this.campaign_id,
          manager_id: this.manager_id,
          agent_id: agent_id
        });
        this.openIndex = rowIndex;
      }
    },

    /**
     * sort date
     */
    sort(data) {
      this.openIndex = -1;
      this.sorts["agent"].dir = "";
      this.sorts["total_feedbacks"].dir = "";
      this.sorts[data.column].dir = data.dir;
      this.sortFeedbackStatistic(data);
    },

    /**
     * find statistcs
     */
    search() {
      this.openIndex = -1;
      this.findFeedbackReports({
        start_date: this.minDate,
        end_date: this.maxDate,
        campaign_id: this.campaign_id,
        manager_id: this.manager_id
      }).then(() => {
        this.email = this.user.email;
      });
    },

    generateCSV() {
      this.downloadTable({
        campaignId: this.campaign_id,
        campaign: this.campaign_id,
        startDate: this.minDate,
        endDate: this.maxDate,
        email: this.email
      }).then(() => this.generateCSVModal.hide());
    },

    ...mapActions("manager/feedback-report", ["findFeedbackReports", "findAgentFeedbackReports", "downloadTable"]),


    ...mapMutations("manager/feedback-report", [
      "sortFeedbackStatistic",
      "clearFeedbackAgent",
    ]),

    openFeedbackOffCanvas(item) {
      let arr = window.location.host.split(".");
      let subdomain = arr[0];
      this.agentFeedback = {
        call_history_id: item.call_history_id,
        campaign_id: item.campaign_id,
        callItem: {
          agent: item.agent.name,
          number: item.call_history.number,
          date: item.call_history.call_date,
          qualification: item.call_history.qualification.name,
        },
        recording: `${process.env.VUE_APP_BA12AS.replace("app", subdomain )}/calls/${item.call_history_id}/recording`
      };
      this.showFeedbackOffcanvas = true;

    },

    updateFeedbacks({agent_id}) {
      this.findAgentFeedbackReports({
        start_date: this.minDate,
        end_date: this.maxDate,
        campaign_id: this.campaign_id,
        manager_id: this.manager_id,
        agent_id: agent_id,
      });
    }
  },
};
</script>
