import store from "@store";

import IndexRingGroup from "@dialer/manager/pages/dashboards/ring-group/index-ring-group";
import RingGroup from "@dialer/manager/pages/dashboards/ring-group/ring-group";
import ShowRingGroup from "@dialer/manager/pages/dashboards/ring-group/show-ring-group";
import RingGroupForm from "@dialer/manager/pages/dashboards/ring-group/ring-group-form";
import WarmupRingGroup from "@dialer/manager/pages/dashboards/ring-group/warmup-ring-group";

function companyHasAccessToRingGroup() {
  const user = store.getters["auth/getUser"];
  return user && user.company.pabx && user.company.ring_group_licenses;
}

function resolveRingGroupComponent(to, from, next) {
  if (companyHasAccessToRingGroup()) {
    to.matched[2].components.ringGroup = RingGroup;
  } else {
    to.matched[2].components.ringGroup = WarmupRingGroup;
  }
  next();
}


export default {
  path: "ring-group",
  name: "ring-group",
  components: {
    main: IndexRingGroup
  },
  children: [
    {
      path: "",
      components: {
        ringGroup: RingGroup
      },
      beforeEnter: resolveRingGroupComponent
    },
    {
      path: "new",
      components: {
        ringGroup: RingGroupForm,
      },
      beforeEnter: (to, from, next) => {
        if (store.getters["auth/isManager"] && companyHasAccessToRingGroup()) next();
        else next({
          name: "not-found",
          params: {
            pathMatch: to.path.split('/').slice(1)
          }
        });
      }
    },
    {
      path: ":id",
      components: {
        ringGroup: ShowRingGroup
      },
      meta: {
        "short-side-bar": true
      },
      beforeEnter: (to, from, next) => {
        if (companyHasAccessToRingGroup()) next({ path: `/manager/ring-group/${to.params.id}/edit` });
        else next({
          name: "not-found",
          params: {
            pathMatch: to.path.split('/').slice(1)
          }
        });
      }
    },
    {
      path: ":id/edit",
      components: {
        ringGroup: RingGroupForm
      },
      meta: {
        "short-side-bar": true
      },
      beforeEnter: (to, from, next) => {
        if (companyHasAccessToRingGroup()) next();
        else next({
          name: "not-found",
          params: {
            pathMatch: to.path.split('/').slice(1)
          }
        });
      }
    },
  ],
};
