export default {
  isLoading: state => state.loading,
  getLoadingContext: state => state.loadingContext,
  getList: state => state.list,
  getErrorMessage: state => state.errorMessage,
  getErrors: state => state.errors,
  getIntervalActive: state => state.IntervalActive,
  isScheduledWorkBreak: state => state.scheduledWorkBreak,
  isExpired: state => state.expired,
  getLoadingFlags: state => state.loadingFlags,
  getWorkBreakIntervals: state => state.workBreakIntervals,
  getReturnRequested: state => state.returnRequested
}
