<template>
  <div
    class="dragable-area"
    ref="dragable-area"
  >
    <div class="dashed-box">
      <span> {{ $t('draggable_area') }} </span>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["leave", "sendFile"],

  mounted() {
    this.$refs["dragable-area"].addEventListener("dragleave", this.leave);
    this.$refs["dragable-area"].addEventListener("dragover", this.dragover);
    this.$refs["dragable-area"].addEventListener("drop", this.drop);
  },

  beforeUnmount() {
    this.$refs["dragable-area"].removeEventListener("dragleave", this.leave);
    this.$refs["dragable-area"].removeEventListener("dragover", this.dragover);
    this.$refs["dragable-area"].removeEventListener("drop", this.drop);
  },

  methods: {
    leave(e) {
      this.$emit("leave");
      e.preventDefault();
    },

    dragover(e) {
      e.preventDefault();
    },

    drop(e) {
      if (e.dataTransfer && e.dataTransfer.files.length)
        this.$emit("sendFile", e.dataTransfer.files[0]);
      this.$emit("leave");
      e.preventDefault();
    },
  },
};
</script>

<style scoped lang="scss">
.dragable-area {
  background-color: #edf2f9;
  height: 100%;
  width: 100%;
  padding: 36px 36px;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  .dashed-box {
    border: 5px dashed #b5b8ba;
    color: #b5b8ba;
    height: 100%;
    font-size: 25px;
    font-weight: 200;
    background-repeat: no-repeat;
    span {
      pointer-events: none;
      text-align: center;
      position: absolute;
      user-select: none;
      top: 50%;
      left: 50%;
      width: 100%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
}
</style>
