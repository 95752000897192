<template>
  <hover-button
    ref="keypadButton"
    @click="toggleDropdown"
    icon="fa-kit fa-key-mobile"
    :background-color="getColors.backgroundColor"
    :text-color="getColors.textColor"
    :keep-button-hovered="showKeypad"
    :text="getText"
    :disabled="isKeyPadDisabled"
    :active="
      status === AgentStatus.ON_CALL &&
        [
          CallStatus.CONNECTED,
          CallStatus.EXTERNAL_CALL_ANSWERED,
          CallStatus.TRANSFERENCE_CONNECTED
        ].includes(callStatus)
    "
  />

  <dropdown-component
    :is-open="showKeypad"
    :button-rect="buttonRect"
    :custom-width="'272px'"
    direction="right"
    :right-displacement="getRightDisplacement"
    :title="$t('keypad')"
  >
    <template #header>
      <input-countries
        v-if="!isBrazil"
        class="justify-content-end"
        border-color="#E1E9F4"
        style="max-width: 118px;"
        @selected-country="countryISOCode = $event.code"
      />
    </template>

    <div
      class="card-key-pad-body"
      :class="{ 'focus-card': isPhoneNumberFocused }"
      @keyup.enter="dial"
      @keyup.delete="backspace"
      tabindex="0"
      ref="panel"
    >
      <div class="d-flex align-items-center mb-4">
        <div
          v-if="[AgentStatus.OFFLINE, AgentStatus.IDLE].includes(status) && isBrazil"
          class="px-2"
          style="font-size: 16px; line-height: 26.75px; height: 26.75px"
        >
          <span
            class="fa-regular fa-copy tx-text-gray cursor-pointer"
            @click="paste"
          />
        </div>

        <input
          type="tel"
          ref="phoneNumberInput"
          class="phone-number-input tx-text-200 w-100"
          :placeholder="$t('type_number_here')"
          v-model="phoneNumber"
          @input="handlePhoneNumberInput"
          @focus="isPhoneNumberFocused = true"
          @blur="isPhoneNumberFocused = false"
        >

        <div
          class="px-2"
          style="font-size: 11px; margin-top: 5px"
        >
          <span
            class="fa-regular fa-x tx-text-gray cursor-pointer"
            v-if="phoneNumber.length > 0"
            @click="phoneNumber = ''"
          />
        </div>
      </div>
      <div class="keypad-wrapper">
        <div class="keypad">
          <div
            v-for="key in keys"
            :key="key"
            @mousedown="keyDown(key)"
            :ref="'key-' + key"
            class="key"
          >
            {{ key }}
          </div>
        </div>
        <div
          v-if="shouldShowDialButton"
          class="action-buttons mt-4 d-flex align-items-center justify-content-between"
        >
          <button
            @click="dial('from-dial-button')"
            class="btn d-flex align-items-center justify-content-center text-white text-medium call-button"
            :class="{
              'bg-call': !call || call.status !== CallStatus.DIALING,
              'bg-manual dialing-animation': loadingFlags.startManualCall || call && call.status === CallStatus.DIALING
            }"
            :disabled="callLoading || loadingFlags.startManualCall || phoneNumber.length < 1"
          >
            {{ loadingFlags.startManualCall || call && call.status === CallStatus.DIALING ? `${$t('calling_capitalize')}...` : $t('to_call') }}
          </button>
          <span
            @click="phoneNumber = ''"
            class="cursor-pointer"
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 24 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.24961 0.585221L3.12236 4.36492C1.54218 5.5298 1.48814 7.8734 3.01296 9.10987L8.21744 13.3302C8.75184 13.7635 9.41895 14 10.107 14H21C22.6569 14 24 12.6569 24 11V3C24 1.34315 22.6569 0 21 0H10.0297C9.3891 0 8.76528 0.205081 8.24961 0.585221Z"
                fill="#E1E9F4"
              />
              <path
                d="M17.7271 4.48722L15.4545 7.18496L17.7271 9.89737C17.8444 10.044 17.8297 10.2639 17.6831 10.3959C17.5365 10.5132 17.3165 10.4985 17.1846 10.3519L15 7.74211L12.8008 10.3519C12.6688 10.4985 12.4489 10.5132 12.3023 10.3959C12.1556 10.2639 12.141 10.044 12.2583 9.89737L14.5308 7.18496L12.2583 4.48722C12.141 4.3406 12.1556 4.12068 12.3023 3.98872C12.4489 3.87143 12.6688 3.88609 12.8008 4.03271L15 6.64248L17.1846 4.03271C17.3165 3.88609 17.5365 3.87143 17.6831 3.98872C17.8297 4.12068 17.8444 4.3406 17.7271 4.48722Z"
                fill="#677C92"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>
  </dropdown-component>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { AgentStatus, Utils, CallStatus } from "@/utils";
import { Mask } from "maska";
import HoverButton from "@dialer/agent/components/hover-button.vue";
import DropdownComponent from "@dialer/agent/components/dropdown-component.vue";
import InputCountries from "@components/countries-collapse.vue";
import parsePhoneNumber from "libphonenumber-js";

export default {
  emits: ["call"],
  data() {
    return {
      AgentStatus,
      CallStatus,
      showKeypad: false,
      buttonRect: null,
      keys: [1, 2, 3, 4, 5, 6, 7, 8, 9, "*", 0, "#"],
      phoneNumber: "",
      phoneNumberOptions: {
        mask: [
          "(##) ####-####",
          "(##) #####-####",
          "##############9"
        ],
        tokens: {
          "9": {
            pattern: /[0-9]/,
            repeated: true
          }
        }
      },
      phoneNumberBindedObject: {
        masked: "",
        unmasked: ""
      },
      isPhoneNumberFocused: false,
      countryISOCode: "BR",
      activeElement: null
    };
  },

  components: {
    HoverButton,
    DropdownComponent,
    InputCountries
  },

  mounted() {
    window.addEventListener("keydown", this.keyboardDown);
    window.addEventListener("keyup", this.keyboardUp);
    window.addEventListener("paste", this.onPaste);

    if (this.status === AgentStatus.ON_MANUAL_CALL_ACW) {
      this.changePhoneNumberValueToCurrentCallNumber();
      this.toggleDropdown();
    }

    if (this.status === AgentStatus.IDLE && this.mode === "manual" && (!this.call || this.call.status != CallStatus.FAILED)) {
      this.toggleDropdown();
    }
  },

  beforeUnmount() {
    window.removeEventListener("keydown", this.keyboardDown);
    window.removeEventListener("keyup", this.keyboardUp);
    window.removeEventListener("paste", this.onPaste);
    window.removeEventListener("resize", this.calculateButtonRect);
  },

  computed: {
    ...mapGetters("system", {
      status: "getStatus",
      mode: "getMode",
      isConsultHold: "isConsultHold"
    }),

    ...mapGetters("agent/call", {
      callLoading: "isLoading",
      callHistoryNumberCalling: "getCallHistoryNumberCalling",
      call: "getCall",
      loadingFlags: "getLoadingFlags",
      manualCallAcw: "getManualCallACW",
    }),

    ...mapGetters("agent/ring_group", {
      callStatus: "getCallStatus",
      callPABX: "getCallPABX",
      isRingGroupActive: "isRingGroupActive"
    }),

    ...mapGetters("agent/agent", {
      loggedCampaign: "getCampaign"
    }),

    ...mapGetters("auth", {
      isBrazil: "isBrazil",
    }),

    getColors() {
      const colors = {
        backgroundColor: "#E1E9F4",
        textColor: "var(--color-text-gray)"
      };

      if (this.loggedCampaign && this.status !== AgentStatus.CONSULT_CONNECTED) {
        colors.backgroundColor = "var(--bs-white)";
      }

      return colors;
    },

    shouldShowDialButton() {
      if (this.isRingGroupActive) {
        return this.status === AgentStatus.OFFLINE;
      }

      if (this.loggedCampaign) {
        if (this.mode === "manual") {
          return this.status === AgentStatus.IDLE;
        }

        if (this.mode === "dialer") {
          return this.status === AgentStatus.ON_MANUAL_CALL_ACW;
        }
      }

      return false;
    },

    getText() {
      if (this.loggedCampaign) {
        if (this.status === AgentStatus.ON_MANUAL_CALL_ACW) {
          return this.$t("manual_act");
        }
      }

      return "";
    },

    getRightDisplacement() {
      return this.status === AgentStatus.ON_MANUAL_CALL_ACW ?  190 : 288;
    },

    isKeyPadDisabled() {
      const isLoggedAgentBeingConsulted = !this.call && this.status === AgentStatus.CONSULT_CONNECTED;
      const isCallFailed = this.call?.status == CallStatus.FAILED && this.status === AgentStatus.ON_CALL;
      const isManualCallAcwFailed = this.manualCallAcw?.status == CallStatus.FAILED;

      return (
        this.isConsultHold ||
        isLoggedAgentBeingConsulted ||
        isCallFailed ||
        isManualCallAcwFailed ||
        [
          CallStatus.DIALING,
          CallStatus.CONSULT_ANSWERED,
          CallStatus.TRYING_INTERNAL_CALL,
          CallStatus.INTERNAL_CALL_FAILED,
          CallStatus.CONSULT_TRYING
        ].includes(this.callStatus)
      );
    }
  },

  methods: {
    ...mapMutations("system", ["sendDTMF"]),
    ...mapActions("agent/ring_group", ["makeCallPABX"]),
    ...mapActions("agent/call", ["startManualCall", "startManualCallACW"]),

    mask: Utils.mask,

    paste() {
      navigator.clipboard.readText().then(text => {
        this.phoneNumber += text;
        if (this.phoneNumber.startsWith('#')) {
          this.phoneNumberBindedObject.masked = this.phoneNumber;
          this.phoneNumberBindedObject.unmasked = this.phoneNumber.slice(1);
        } else {
          const mask = new Mask(this.phoneNumberOptions);
          this.phoneNumber = mask.masked(this.phoneNumber);
          this.phoneNumberBindedObject.masked = this.phoneNumber;
          this.phoneNumberBindedObject.unmasked = mask.unmasked(this.phoneNumber);
        }
      });
    },

  onPaste(event) {
    this.activeElement = document.activeElement;

    if (this.activeElement == this.$refs.panel && !this.isPhoneNumberFocused) {
      this.phoneNumber += event.clipboardData.getData("text/plain");
      if (this.phoneNumber.startsWith('#')) {
        this.phoneNumberBindedObject.masked = this.phoneNumber;
        this.phoneNumberBindedObject.unmasked = this.phoneNumber.slice(1);
      } else {
        const mask = new Mask(this.phoneNumberOptions);
        this.phoneNumber = mask.masked(this.phoneNumber);
        this.phoneNumberBindedObject.masked = this.phoneNumber;
        this.phoneNumberBindedObject.unmasked = mask.unmasked(this.phoneNumber);
      }
    }
  },

    formatInput(input) {
      if (AgentStatus.ON_CALL != this.status)
        switch (input.length) {
          case 10:
            return this.mask(input, "(##) ####-####");
          case 11:
            return this.mask(input, "(##) #####-####");
          case 12:
            return this.mask(input, "+## (##) ####-####");
          case 13:
            return this.mask(input, "+## (##) #####-####");
        }
      return input;
    },

    keyDown(key) {
      if (this.phoneNumber) {
        this.updatePhoneNumber(key);
      } else {
        this.phoneNumber = key.toString();
      }

      if (this.call || this.callPABX) {
        this.sendDTMF(key);
      }
    },

    keyboardDown(e) {
      if (this.showKeypad && "01234567890#*".includes(e.key)) {
        this.activeElement = document.activeElement;
        if (this.activeElement == this.$refs.panel) {
          this.$refs["key-" + e.key].classList.add("pressed");
          this.keyDown(e.key);
        }
        if (this.call || this.callPABX) {
          this.sendDTMF(e.key);
        }
      }
    },

    keyboardUp(e) {
      this.activeElement = document.activeElement;

      if (this.activeElement == this.$refs.panel) {
        if ("01234567890#*".includes(e.key))
          this.$refs["key-" + e.key].classList.remove("pressed");
      }
    },

    backspace() {
      this.activeElement = document.activeElement;
      if (
        this.phoneNumber.length > 0 &&
        this.activeElement == this.$refs.panel &&
        !this.isPhoneNumberFocused
      ) {
        this.updatePhoneNumber();
      }
    },

    dial(from) {
      let phone = this.phoneNumber.startsWith("#") ? this.phoneNumberBindedObject.masked : this.phoneNumberBindedObject.unmasked;
      if (this.isRingGroupActive) {
        this.activeElement = document.activeElement;

        if (
          (this.activeElement == this.$refs.panel || this.isPhoneNumberFocused || from === 'from-dial-button') &&
          this.status === AgentStatus.OFFLINE
        ) {
          this.makeCallPABX(phone);
        }
      }

      if (this.loggedCampaign) {
        if (this.status === AgentStatus.IDLE && this.mode === "manual") {
          this.startManualCall({ phone });
        }

        if (this.status === AgentStatus.ON_MANUAL_CALL_ACW) {
          if(phone.startsWith("55") && phone.length > 11) {
            phone = phone.slice(2);
          }
          this.startManualCallACW({ phone });
        }
      }
    },

    toggleDropdown() {
      this.showKeypad = !this.showKeypad;
      if (this.showKeypad) {
        this.calculateButtonRect();
        window.addEventListener("resize", this.calculateButtonRect);
        this.$nextTick(() => {
          if (this.$refs["phoneNumberInput"]) {
            this.$refs["phoneNumberInput"].focus();
          }
        });
      } else {
        window.removeEventListener("resize", this.calculateButtonRect);
      }
    },

    calculateButtonRect() {
      const rect = this.$refs.keypadButton.$el.getBoundingClientRect();
      this.buttonRect = rect;
    },

    updatePhoneNumber(key = null) {
      if (key !== null) {
        this.phoneNumber += key.toString();
      } else {
        this.phoneNumber = this.phoneNumber.slice(0, -1);
      }

      if (this.phoneNumber.startsWith('#')) {
        this.phoneNumberBindedObject.masked = this.phoneNumber;
        this.phoneNumberBindedObject.unmasked = this.phoneNumber.slice(1);
      } else {
        const mask = new Mask(this.phoneNumberOptions);
        this.phoneNumber = mask.masked(this.phoneNumber);
        this.phoneNumberBindedObject.masked = this.phoneNumber;
        this.phoneNumberBindedObject.unmasked = mask.unmasked(this.phoneNumber);
      }
    },

    changePhoneNumberValueToCurrentCallNumber() {
      if (this.status === AgentStatus.ON_MANUAL_CALL_ACW) {
        const mask = new Mask(this.phoneNumberOptions);
        this.phoneNumber = mask.masked(this.call.phone);
        this.phoneNumberBindedObject.masked = this.phoneNumber;
        this.phoneNumberBindedObject.unmasked = mask.unmasked(this.phoneNumber);
      }
    },

    handlePhoneNumberInput(event) {
      const input = event.target;
      let value = input.value;

      if (value.startsWith('#')) {
        this.phoneNumber = value;
        this.phoneNumberBindedObject.masked = value;
        this.phoneNumberBindedObject.unmasked = value.slice(1);
        input.value = value;
      } else {
        const mask = new Mask(this.phoneNumberOptions);
        const maskedValue = mask.masked(value);
        this.phoneNumber = maskedValue;
        this.phoneNumberBindedObject.masked = maskedValue;
        this.phoneNumberBindedObject.unmasked = mask.unmasked(maskedValue);
      }
    },
  },

  watch: {
    status(status) {
      if (status == AgentStatus.ON_MANUAL_CALL_ACW || (status == AgentStatus.IDLE && this.mode == "manual")) {
        this.changePhoneNumberValueToCurrentCallNumber();
        this.toggleDropdown();
      } else if (this.showKeypad) {
        this.toggleDropdown();
      }
    },

    mode(mode) {
      if (this.loggedCampaign && mode === "dialer" && this.status === AgentStatus.IDLE) {
        this.toggleDropdown();
      }
    },

    phoneNumberBindedObject: {
      handler(data) {
        const phoneNumber = parsePhoneNumber(data.unmasked, this.countryAcronym);
        if (phoneNumber) {
          const removePlus = phoneNumber.number.replace("+", "");
          this.phoneNumberBindedObject.unmasked = removePlus;
        }
      },
      deep: true
    },

    call(newCall) {
      if (newCall && newCall.status == CallStatus.FAILED && this.showKeypad) {
        this.toggleDropdown();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.card-key-pad-body {
  min-height: 280px;
  opacity: 0.75;
  padding: 1rem;

  &.focus-card {
    opacity: 1;
    outline: none;
  }

  &:focus {
    opacity: 1;
    outline: none;
  }

  .phone-number-input {
    border: none;
    &:focus {
      outline: none;
    }
  }

  .keypad-wrapper {
    width: 140px;
    margin: 0 auto;

    .keypad {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;

      .key {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: #f0f4fa;
        font-size: $h2-font-size;
        cursor: pointer;
        transition: background-color 0.3s;
        margin: 0 auto;

        &:hover {
          background-color: #e6eaf0;
        }

        &.pressed {
          opacity: 0.65;
        }
      }
    }
    .action-buttons {
      .call-button {
        width: 100px;
        height: 32px;
      }

      .dialing-animation {
        animation: pulse 1s infinite;

        &:disabled {
          opacity: 1;
        }
      }

      @keyframes pulse {
        0% {
          outline: 1px solid var(--color-orange-100);
        }
        50% {
          outline: 4px solid var(--color-orange-100);
        }
        100% {
          outline: 1px solid var(--color-orange-100);
        }
      }
    }
  }
}
</style>
