import axios from "axios";

export default {
    searchSchedules({ commit }, payload) {

        commit('clearErrors');
        commit('startLoading', 'searchSchedules');
        commit('setList', []);


        return new Promise((resolve, reject) => {
            axios.get(`/schedules?start_date=${payload.startDate}&end_date=${payload.endDate}&agent=${payload.agent_id}&campaign=${payload.campaign_id}&page=${payload.page}`)
                .then((response) => {
                    commit('setList', response.data.data);
                    commit('setPagination', response.data.meta.pagination);
                    resolve();
                })
                .catch((response) => {
                    commit('setAlert', { message: response.response.data.detail, type: 'danger' });
                    response.response.status == 422 && response.response.data.errors && commit('setErrors', response.response.data.errors);
                    reject({ message: response.response.data.detail });
                })
                .finally(() => {
                    commit('stopLoading', 'searchSchedules');
                });
        });
    },

  downloadCSV({ commit, getters }) {
    commit('clearErrors');
    commit('startLoading', 'downloadCSV');

    return new Promise((resolve, reject) => {
      axios.get(`/schedules/csv`, { params: getters.getDownloadCSVParams })
      .then((response) => {
        commit('system/setSuccessMessage', response.data.detail, { root: true });
        resolve();
      }).catch((error) => {
        error.response.status == 422 && error.response.data.errors && commit('setErrors', error.response.data.errors);
        commit('system/setErrorMessage', error.response.data, { root: true });
        reject();
      }).finally(() => {
        commit('stopLoading', 'downloadCSV');
      });
    })
  },

  setDownloadCSVFilters({ commit }, filters) {
    commit('setDownloadCSVFilters', filters);
  },

    searchSchedulesByCampaignId({ commit }, payload) {
      commit('startLoading', 'searchSchedulesByCampaignId');
        return new Promise((resolve, reject) => {
          axios.get(`/campaigns/${payload.id}/schedules`)
          .then((response) => {
            commit('setCampaignSchedules', response.data.data);
            commit('setPaginationCampaignSchedules', response.data.meta.pagination);
            resolve();
          })
          .catch(() => {
            reject();
          })
          .finally(() => {
            commit('stopLoading', 'searchSchedulesByCampaignId');
          });
      })
    }
}

