<template>
  <card-loading v-if="(loading.fetchPinNotes && notes.length) || isLoading" />
  <div
    v-else-if="notes.length"
    class="card"
  >
    <div class="card-header">
      <h5 class="card-title">
        {{ $t('pin_notes') }}
      </h5>
    </div>
    <div
      class="card-body py-4 px-0"
      v-if="notes.length"
    >
      <list-group-activity
        :items="formattedNotes"
        :loading="loading.fetchPinNotes"
        :items-count-to-loading="15"
      />
    </div>
  </div>
</template>
<script>
import { getActivityIcon } from "@crm/utills";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import CardLoading from "@components/card-loading.vue";
import ListGroupActivity from "@crm/components/activity/list-group-activity/list-group-activity.vue";
export default {
  name: "PinnedInternalNoteList",
  components: { ListGroupActivity, CardLoading },

  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      currentTabName: "tab-all",
      currentTab: null
    };
  },

  mounted() {
    this.fetchPinNotes({
      opportunityId: this.$route.params.opportunityId,
      funnelId: this.$route.params.id,
    });
  },

  computed: {
    ...mapGetters("crm/activities", {
      notes: "getPinNotes",
      loading: "getLoadingFlags"
    }),
    ...mapGetters("crm/opportunities", {
      opportunity: "getOpportunity"
    }),

    formattedNotes() {
      return this.notes.map(note => {
        return {
          ...note,
          event_type: "ACTIVITY_NOTE",
          new_data: note
        };
      });
    }
  },

  methods: {
    getActivityIcon,
    ...mapActions("crm/activities", ["fetchPinNotes", "unpinNote"]),

    getDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    getTime(date) {
      return moment(date).format("HH:mm");
    },

    unpin(note) {
      this.unpinNote({
        noteId: note.id,
        opportunityId: this.$route.params.opportunityId,
        funnelId: this.$route.params.id
      });
    }
  }
};
</script>
<style scoped lang="scss">
.internal-note {
  background: #fff3d3 !important;
}
.circle {
  background: $color-text-200;
  height: 3px;
  width: 3px;
}
.pin-icon {
  width: 26px;
  height: 26px;
  .rotate-45 {
    transform: rotate(45deg);
  }
}
</style>
