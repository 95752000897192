<template>
  <div class="d-flex gap-1">
    <button
      @click="selectStep(index)"
      @mouseenter="toggleHover(index)"
      @mouseleave="toggleHover(false)"
      @focusin="toggleHover(index)"
      @focusout="toggleHover(false)"
      v-for="(step, index) in currentFunnel.steps"
      :key="`step-${index}`"
      type="button"
      role="button"
      class="col text-center step"
      :class="stepCounter(index)"
      :style="`
        background-color: ${setColors(step, index).backgroundColor};
        color: ${setColors(step, index).color};
       `"
      :title="$t('move_to_step')"
    >
      <span
        class="pe-none"
        v-if="!isCurrentStep(index) || (isHovered !== index) || enableChoice || !canNextStep(index)"
      >
        {{ step.name }}
      </span>
      <span
        class="fw-medium pe-none"
        v-if="isHovered === index && isCurrentStep(index) && canNextStep(index) && !enableChoice"
      >
        {{ $t('next_step') }} <i class="ms-3 fa-regular fa-arrow-right" />
      </span>
    </button>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {textColor} from "@/utils";

export default {
  name: "OpportunityStepper",
  emits: ['stepSelected'],

  props: {
    enableChoice: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      selectedStep: null,
      selectedStepIndex: null,
      isHovered: false,
    }
  },

  computed: {
    ...mapGetters("crm/funnels", {
      currentFunnel: "getCurrentFunnel",
      currentStepId: "getCurrentStepId",
    }),
    ...mapGetters("crm/opportunities", {
      opportunity: "getOpportunity",
    }),
  },

  mounted() {
    const currentStep = this.currentStepId || this.opportunity?.step_id;
    this.selectedStep = this.currentFunnel.steps.find((step) => step.id === currentStep) || this.currentFunnel.steps[0];
    this.selectedStepIndex = this.currentFunnel.steps.findIndex((step) => step.id === this.selectedStep.id);
  },

  methods: {
    textColor: textColor,
    stepCounter(step) {
      if (this.currentFunnel.steps.length === 1) {
        return 'only-step';
      }

      if (step === 0) {
        return 'first-step';
      }

      if (step === this.currentFunnel.steps.length - 1) {
        return 'last-step';
      }

      return false
    },

    setColors(step, index) {
      let backgroundColor = null;
      let color = '#677C92';

      if (this.isCurrentStep(index) || (this.isHovered === index && this.enableChoice)) {
        backgroundColor = step.color;
      }

      if (index < this.selectedStepIndex) {
        backgroundColor = '#E1E9F4';
      }

      if (index < this.selectedStepIndex && this.isHovered === index) {
        backgroundColor = '#D2DDEC';
      }

      if (backgroundColor === step.color) {
        color = this.textColor(step.color);
      }

      return {
        backgroundColor,
        color
      }

    },

    isCurrentStep(index) {
      return index === this.selectedStepIndex
    },

    toggleHover(toggle) {
      this.isHovered = toggle;
    },

    canNextStep(step) {
      const maxRange = this.enableChoice ? this.currentFunnel.steps.length : this.currentFunnel.steps.length -1
      return step < maxRange;
    },

    selectStep(stepIndex) {
      if (!this.canNextStep(stepIndex)) {
        return
      }

       stepIndex = this.isCurrentStep(stepIndex) ?  stepIndex + 1 : stepIndex;

      this.selectedStep = this.currentFunnel.steps[stepIndex];
      this.selectedStepIndex = stepIndex;
      this.$emit("stepSelected", this.selectedStep.id)
    },
  }
}
</script>


<style scoped lang="scss">
.step {
  padding: 5px 16px;
  background-color: #F4F4F4;
  outline: none;
  border: none;
  border-radius: 6px;
  font-size: 0.875rem;
  color: #677C92;
  cursor: pointer;
  height: 32px;

  &:disabled {
    cursor: default;
  }

  &.first-step {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
  }

  &.last-step {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
  }

  &.only-step {
    border-radius: 24px;
  }

  &:hover {
    background-color: #DFDFDF;
    color: #575778;
  }
}
</style>
