export default {
  getContactLists: (state) => state.contacts,
  // getCols: (state) => state.cols,
  getIsDraggingColumn: (state) => state.isDraggingColumn,
  getLockDragCard: (state) => state.lockDragCard,
  getTimeline: (state) => state.timeline,

  // ----- STEPS ----- //
  getSteps: (state) => state.steps,
  getStepPagination: (state) => state.stepPagination,

  // STEP CONTACTS
  getContactsStep: (state) => state.contactSteps,

  getLoadingFlags: state => state.loadingFlags,
}
