<template>
  <div
    ref="messageAudio"
    class="p-0 audio my-1 d-flex align-items-center mw-100"
  >
    <div class="flex-row w-100">
      <template v-if="failed">
        <small>
          <i class="fa-solid fa-circle-exclamation text-danger" /> {{ $t('failed_load_audio') }}</small>
      </template>
      <div
        class="spinner-border text-muted loading"
        v-else-if="loading"
      >
        <span class="visually-hidden" />
      </div>
      <div
        class="d-flex justify-content-between align-items-center gap-audio"
        v-else
      >
        <div class="col-auto">
          <span class="avatar">
            <img
              v-if="(type == 'ptt' || type == 'voice') && !playing && !fromMe"
              src="@assets/img/avatars/profiles/nome-user.png"
              class="avatar-img rounded-circle"
            >
            <div
              v-else-if="playing"
              class="btn p-0 d-flex justify-content-center playback-container avatar-img rounded-circle"
              :class="{'not-from-me-playback-container': !fromMe}"
              @click="changeAudioPlaybackRate()"
            >
              <span class="align-middle mt-3 text-center playback-text">
                {{ this.playbackRate }}
              </span>
            </div>
            <div
              v-else-if="type == 'audio'"
              class="fa fa-music avatar-img rounded-circle d-flex justify-content-center align-items-center audio-img"
            />
            <img
              v-else
              src="@assets/img/avatars/profiles/nome-user.png"
              class="avatar-img rounded-circle"
            >
          </span>
        </div>
        <button
          class="shadow-none pause p-0"
          v-if="playing"
          @click="pause"
        >
          <i class="fe fe-pause b3" />
        </button>
        <button
          class="shadow-none play p-0"
          @click="play"
          v-else
        >
          <i class="fe fe-play p-0" />
        </button>
        <div
          class="show-time b5 text-center"
        >
          {{ playing ? currentTime : duration }}
        </div>
        <div class="col">
          <div
            class="d-flex align-items-center w-75"
            @mousedown="barClicked = true"
            @mouseup="mouseup"
            ref="bar"
          >
            <input
              :style="{ background: `linear-gradient(to right, #3057f2 ${percentage}%, #E1E9F4 ${percentage}%)` }"
              type="range"
              min="0"
              max="100"
              v-model="percentage"
              id="range"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  emits: ["audio-duration"],

  data() {
    return {
      audio: null,
      duration: "00:00",
      currentTime: "00:00",
      playbackRate: "1.0x",
      percentage: 0,
      playing: false,
      timer: null,
      loading: false,
      failed: false,
      barClicked: false,
      observer: null,
    };
  },

  mounted() {
    this.createObserver();
    this.audio = new Audio();
    this.audio.addEventListener("canplay", this.canplay);
    this.audio.addEventListener("ended", this.ended);
    this.audio.addEventListener("pause", this.paused);
    this.audio.addEventListener("timeupdate", this.timeUpdate);
  },

  unmounted() {
    this.audio.removeEventListener("canplay", this.canplay);
    this.audio.removeEventListener("ended", this.ended);
    this.audio.removeEventListener("pause", this.paused);
    this.audio.removeEventListener("timeupdate", this.timeUpdate);
    this.pause();
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser",
      inImpersonate: "inImpersonate",
    }),
  },

  props: {
    path: {
      type: String,
      required: true,
    },
    fromMe: {
      default: true,
      type: Boolean,
    },
    type: {
      type: String,
      required: true
    },
    messageAck: {
      type: String,
      default: null
    },
  },

  methods: {
    createObserver() {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.load();
          }
        });
      });
      this.observer.observe(this.$refs.messageAudio);
    },

    load() {
      this.observer.disconnect();
      let audioContext = new AudioContext();
      this.loading = true;
      let url = this.path;
      if (this.user && this.user.company && !this.inImpersonate && !this.messageAck) {
        url = url.replace("://app.", "://" + this.user.company.domain + ".");
      }
      fetch(url)
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => {
          const blob = new Blob([arrayBuffer], {type: "audio/mp3"});
          this.audio.src = window.URL.createObjectURL(blob);
          audioContext.decodeAudioData(arrayBuffer).then(() => {
            this.loading = false;
          });
        })
        .catch(() => {
          this.failed = true;
        });
    },

    normalize(data) {
      let temp = [];
      for (let item of data) {
        let t = (item * 10000) % 100;
        if (this.allowZero) {
          temp.push(t);
        } else {
          temp.push(t > 10 ? t : 10);
        }
      }
      return temp;
    },

    canplay() {
      let minutes = Math.floor(this.audio.duration / 60);
      let seconds = Math.round(this.audio.duration % 60);
      this.duration =
        minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
      minutes = Math.floor(this.audio.currentTime / 60);
      seconds = Math.round(this.audio.currentTime % 60);
      this.currentTime =
        minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
    },

    changeAudioPlaybackRate() {
      switch (this.audio.playbackRate) {
        case 1:
          this.audio.playbackRate = 1.5
          this.playbackRate = "1.5x"
          break;
        case 1.5:
          this.audio.playbackRate = 2
          this.playbackRate = "2.0x"
          break;
        case 2:
          this.audio.playbackRate = 1
          this.playbackRate = "1.0x"
          break;
      }
    },

    paused() {
      this.playing = false;
    },

    timeUpdate() {
      let minutes = Math.floor(this.audio.currentTime / 60);
      let seconds = Math.round(this.audio.currentTime % 60);
      this.currentTime =
        minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
      if (!this.barClicked) this.percentage = this.audio.currentTime / (this.audio.duration / 100);
    },

    ended() {
      this.playing = false;
      this.currentTime = "00:00";
      this.audio.currentTime = 0;
      this.percentage = 0;
      clearInterval(this.timer);
    },

    play() {
      this.audio.play();
      this.playing = true;
      //TODO: use event
    },

    pause() {
      this.audio.pause();
    },

    selectTime(percentage) {
      this.audio.currentTime = this.audio.duration * (percentage / 100);
      this.percentage = percentage;
    },

    mouseup(e) {
      this.selectTime((e.offsetX / this.$refs.bar.clientWidth) * 100)
      this.barClicked = false
    }
  },
};
</script>

<style lang="scss" scoped>
.audio {
  width: 360px;
  height: 48px !important;

  .gap-audio {
    gap: $whatsapp-spacing-1 !important;
  }

  .play,
  .pause {
    width: 31.5px;
    height: 25px;
    padding: 0px !important;
    background-color: transparent;
    border: none;
    font-size: 20px;
    line-height: 20px;
  }

  .play {
    color: $color-gray-500;
  }

  .pause {
    color: $color-gray-500;
  }

  .bar {
    width: 50px;
    height: 17px;
    margin-top: 11px;
  }

  .show-time {
    line-height: 25px;
    font-size: 12px;
    font-variant-numeric: tabular-nums;
  }

  .loading {
    width: 20px;
    height: 20px;
    margin-top: 5px;
    color: #eaf2fd !important;
  }

  .playback-container {
    background-color: #FFFFFF;

    &:hover {
      background-color: $color-blue-300;
      color: white;
    }
  }

  .not-from-me-playback-container {
    background-color: $color-gray-blue-300;
  }

  .fe-play, .fe-pause {
    &:hover {
      color: $color-blue-300;
    }
  }

  .audio-img {
    background-color: #FFBB3A;
    color: #754000;
  }
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 5px;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 11px;
  height: 11px;
  background-color: $color-blue-300;
  border: 3px solid rgba(128, 176, 239, 0.8);
  border-radius: 50%;
  transition-duration: 0.1s !important;
}

@media only screen and (max-width: 1199.999px) {
  .audio {
    width: 100%;
  }
}
</style>
