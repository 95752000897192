import moment from "moment";
import axiosOmni from "@/plugins/axios-omni.js"
import {getMessageDay} from "@/utils";

export default {
  sendMessage({ commit, dispatch, rootGetters}, {fields, tempId, tempTime, chat}) {
    return new Promise((resolve, reject) => {
      axiosOmni.post('/whatsapp/message/send_chat', fields)
        .then((response) => {
          let message = response.data.data;

          dispatch("replaceTemporaryMessage", {
            tempId: tempId,
            tempTime: tempTime,
            message: message
          });

          if (chat.lag_to_response.response_is_late || chat.queue_response_is_late.response_is_late) {
            commit("whatsapp/chat/setRemoveUnanswerMark", chat.id, { root: true });
          }

          if (chat.in_snooze || rootGetters["whatsapp/chat/getSnoozedByManager"]?.id === chat.id) {
            commit("whatsapp/chat/removeChatFromSnooze", chat, { root: true });
          }

          if (chat.is_group) {
            commit("whatsapp/chat/updateGroupTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "groups",
              message: message
            }, { root: true });
          }
          else {
            commit("whatsapp/chat/updateInProgressTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "inProgress",
              message: message
            }, { root: true });
          }
          resolve();
        }).catch(() => {
          dispatch("setMessageFailed", {tempId: tempId, chatId: fields.chat_id })
          reject();
        });
    });
  },

  sendDocument({ commit, dispatch, rootGetters }, {formData, tempId, tempTime, chat}) {
    return new Promise((resolve, reject) => {
      axiosOmni.post('/whatsapp/message/send_document', formData)
        .then((response) => {
          let message = response.data.data;

          dispatch("replaceTemporaryMessage", {
            tempId: tempId,
            tempTime: tempTime,
            message: message
          });

          if (chat.lag_to_response.response_is_late || chat.queue_response_is_late.response_is_late) {
            commit("whatsapp/chat/setRemoveUnanswerMark", chat.id, { root: true });
          }

          if (chat.in_snooze || rootGetters["whatsapp/chat/getSnoozedByManager"]?.id === chat.id) {
            commit("whatsapp/chat/removeChatFromSnooze", chat, { root: true });
          }

          if (chat.is_group) {
            commit("whatsapp/chat/updateGroupTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "groups",
              message: message
            }, { root: true });
          }
          else {
            commit("whatsapp/chat/updateInProgressTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "inProgress",
              message: message
            }, { root: true });
          }

          resolve();
        }).catch(() => {
          dispatch("setMessageFailed", {tempId: tempId, chatId: formData.get("chat_id") })
          reject();
        });
    });
  },

  sendImage({ commit, dispatch, rootGetters }, {formData, tempId, tempTime, chat}) {
    return new Promise((resolve, reject) => {
      axiosOmni.post('/whatsapp/message/send_image', formData)
        .then((response) => {
          let message = response.data.data;

          dispatch("replaceTemporaryMessage", {
            tempId: tempId,
            tempTime: tempTime,
            message: message
          });

          if (chat.lag_to_response.response_is_late || chat.queue_response_is_late.response_is_late) {
            commit("whatsapp/chat/setRemoveUnanswerMark", chat.id, { root: true });
          }

          if (chat.in_snooze || rootGetters["whatsapp/chat/getSnoozedByManager"]?.id === chat.id) {
            commit("whatsapp/chat/removeChatFromSnooze", chat, { root: true });
          }

          if (chat.is_group) {
            commit("whatsapp/chat/updateGroupTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "groups",
              message: message
            }, { root: true });
          }
          else {
            commit("whatsapp/chat/updateInProgressTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "inProgress",
              message: message
            }, { root: true });
          }

          resolve();
        }).catch(() => {
          dispatch("setMessageFailed", {tempId: tempId, chatId: formData.get("chat_id") })
          reject();
        });
    });
  },

  sendVideo({ commit, dispatch, rootGetters }, {formData, tempId, tempTime, chat}) {
    return new Promise((resolve, reject) => {
      axiosOmni.post('/whatsapp/message/send_video', formData)
        .then((response) => {
          let message = response.data.data;

          dispatch("replaceTemporaryMessage", {
            tempId: tempId,
            tempTime: tempTime,
            message: message
          });

          if (chat.lag_to_response.response_is_late || chat.queue_response_is_late.response_is_late) {
            commit("whatsapp/chat/setRemoveUnanswerMark", chat.id, { root: true });
          }

          if (chat.in_snooze || rootGetters["whatsapp/chat/getSnoozedByManager"]?.id === chat.id) {
            commit("whatsapp/chat/removeChatFromSnooze", chat, { root: true });
          }

          if (chat.is_group) {
            commit("whatsapp/chat/updateGroupTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "groups",
              message: message
            }, { root: true });
          }
          else {
            commit("whatsapp/chat/updateInProgressTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "inProgress",
              message: message
            }, { root: true });
          }

          resolve();
        }).catch(() => {
          dispatch("setMessageFailed", {tempId: tempId, chatId: formData.get("chat_id") })
          reject();
        });
    });
  },

  sendVoice({ commit, dispatch, rootGetters }, {formData, tempId, tempTime, chat}) {
    return new Promise((resolve, reject) => {
      axiosOmni.post('/whatsapp/message/send_voice', formData)
        .then((response) => {
          let message = response.data.data;

          dispatch("replaceTemporaryMessage", {
            tempId: tempId,
            tempTime: tempTime,
            message: message
          });

          if (chat.lag_to_response.response_is_late || chat.queue_response_is_late.response_is_late) {
            commit("whatsapp/chat/setRemoveUnanswerMark", chat.id, { root: true });
          }

          if (chat.in_snooze || rootGetters["whatsapp/chat/getSnoozedByManager"]?.id === chat.id) {
            commit("whatsapp/chat/removeChatFromSnooze", chat, { root: true });
          }

          if (chat.is_group) {
            commit("whatsapp/chat/updateGroupTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "groups",
              message: message
            }, { root: true });
          }
          else {
            commit("whatsapp/chat/updateInProgressTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "inProgress",
              message: message
            }, { root: true });
          }

          resolve();
        }).catch(() => {
          dispatch("setMessageFailed", {tempId: tempId, chatId: formData.get("chat_id") })
          reject();
        });
    });
  },

  sendAudio({ commit, dispatch, rootGetters }, {formData, tempId, tempTime, chat}) {
    return new Promise((resolve, reject) => {
      axiosOmni.post('/whatsapp/message/send_audio', formData)
        .then((response) => {
          let message = response.data.data;

          dispatch("replaceTemporaryMessage", {
            tempId: tempId,
            tempTime: tempTime,
            message: message
          });

          if (chat.lag_to_response.response_is_late || chat.queue_response_is_late.response_is_late) {
            commit("whatsapp/chat/setRemoveUnanswerMark", chat.id, { root: true });
          }

          if (chat.in_snooze || rootGetters["whatsapp/chat/getSnoozedByManager"]?.id === chat.id) {
            commit("whatsapp/chat/removeChatFromSnooze", chat, { root: true });
          }

          if (chat.is_group) {
            commit("whatsapp/chat/updateGroupTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "groups",
              message: message
            }, { root: true });
          }
          else {
            commit("whatsapp/chat/updateInProgressTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "inProgress",
              message: message
            }, { root: true });
          }

          resolve();
        }).catch(() => {
          dispatch("setMessageFailed", {tempId: tempId, chatId: formData.get("chat_id") })
          reject();
        });
    });
  },

  sendInternal({ commit, dispatch }, {fields, tempId, tempTime}) {
    return new Promise((resolve, reject) => {
      axiosOmni.post('/whatsapp/message/send_internal_chat', fields)
        .then((response) => {
          let message = response.data.data;
          message.time = moment().unix()
          message.ack = null
          dispatch("replaceTemporaryMessage", {
            tempId: tempId,
            tempTime: tempTime,
            message: message
          });
          resolve();
        }).catch(() => {
          commit('system/setErrorMessage', "errors_message.send_message", { root: true })
          reject();
        });
    })
  },

  sendForward({ commit, dispatch }, {data, chat, message, tempId, formData}) {
    commit('startLoading', 'sendForward')
    return new Promise((resolve, reject) => {
      axiosOmni.post('/whatsapp/message/forward_message', data)
        .then(() => {
          // FIXME: back return the message on the endpoint
          // let message = response.data.data;
          //
          // dispatch("replaceTemporaryMessage", {
          //   tempId: tempId,
          //   tempTime: tempTime,
          //   message: message
          // });

          message.chat_id = chat.id
          message.id = tempId

          if (chat.is_group) {
            commit("whatsapp/chat/updateGroupTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "groups",
              message: message
            }, { root: true });
          }
          else {
            commit("whatsapp/chat/updateInProgressTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "inProgress",
              message: message
            }, { root: true });
          }

          commit('system/setSuccessMessage', "message_forwarded", { root: true })
          resolve();
        })
        .catch( () => {
          dispatch("setMessageFailed", {tempId: tempId, chatId: formData.get("chat_id") })
          reject();
        })
        .finally(() => {
          commit("stopLoading", "sendForward");
        })
      ;
    });
  },

  deleteWhatsappMessage({ commit, dispatch }, message) {
    return new Promise((resolve, reject) => {
      axiosOmni.delete(`/whatsapp/message/delete?chat_id=${message.chat_id}&message_id=${message.internal_id}`)
        .then(() => {
          dispatch('markMessageAsDeleted', message)
          commit('system/setSuccessMessage', "success_message.delete_message", { root: true })
          resolve();
        }).catch(() => {
        commit('system/setErrorMessage', "errors_message.delete_message", { root: true })
        reject();
      });
    });
  },

  openIaImprove({ commit }, payload) {
    commit('startLoading', 'openIaImprove')
    return new Promise((resolve, reject) => {
      axiosOmni.post('/whatsapp/message/improve', payload)
        .then((response) => {
          commit('setImprovedMessage', response.data.message)
          resolve();
        }).catch((response) => {
        commit('system/setErrorMessage', response.response.data, { root: true })
        reject();
      }).finally(() => {
        commit("stopLoading", "openIaImprove")
      })
    });
  },

  openIaSuggest({ commit }, payload) {
    commit('startLoading', 'openIaSuggest')
    return new Promise((resolve, reject) => {
      axiosOmni.post('/whatsapp/message/suggest', payload)
        .then((response) => {
          commit('setSuggestedMessage', response.data.message)
          resolve();
        }).catch((response) => {
        commit('system/setErrorMessage', response.response.data, { root: true })
        reject();
      }).finally(() => {
        commit("stopLoading", "openIaSuggest")
      })
    });
  },

  fetchCurrentMessages({ commit }, {chat_id, instance_id, page }) {
    const params =  {
      per_page: 150,
      page: page,
      chat_id: chat_id,
      instance_id: instance_id
    }

    return new Promise((resolve, reject) => {
      axiosOmni.get(`/whatsapp/chats/${ chat_id }/messages`, { params }).then(response => {
        commit("setPagination", { chatId: chat_id, pagination: { ...response.data.meta.pagination, context: 'current' } });
        const messages = groupMessagesByDay(response.data.data)

        if (page === 1) {
          commit("setMessages", { chatId: chat_id, messages: messages });
        }
        else {
          commit("setStashedMessages", { chatId: chat_id, stashedMessages: messages });
        }
        resolve();
      }).catch(response => {
        commit("system/setErrorMessage", response.data, { root: true });
        reject();
      })
    });
  },

  fetchHistoryMessages({ commit, getters, dispatch }, {number, chat_id, page, instance_id}) {
    const params =  {
      per_page: 150,
      page,
      instance_id
    }

    return new Promise((resolve, reject) => {
      axiosOmni.get("/whatsapp/message/" + number + `/history`, { params }).then(response => {
        commit("setPagination", { chatId: chat_id, pagination: { ...response.data.meta.pagination, context: 'history' } });
        const messages = groupMessagesByDay(response.data.data)
        const currentIsEmpty = Object.keys(getters.getMessages(chat_id)).length === 0

        if (page === 1 && currentIsEmpty) {
          commit("setMessages", { chatId: chat_id, messages: messages });
          dispatch("fetchHistoryMessages", {
            number,
            chat_id,
            instance_id,
            page: 2
          });
        }
        else {
          commit("setStashedMessages", { chatId: chat_id, stashedMessages: messages })
        }
        resolve();
      }).catch(response => {
        commit("system/setErrorMessage", response.data, { root: true })
        reject();
      })
    })
  },

  fetchSecondPageForInitialLoading({ dispatch, getters }, {chat_id, instance_id, number }) {
    const pagination = getters.getPaginations(chat_id)

    if (pagination.total_pages > 1) {
      return dispatch("fetchCurrentMessages", {
        chat_id: chat_id,
        instance_id: instance_id,
        page: 2,
      })
    }
    else {
      return dispatch("fetchHistoryMessages", {
        chat_id,
        number,
        instance_id,
        page: 1
      })
    }
  },

  fetchMessagesForSearch({ commit, dispatch }, { chatId, instanceId, number, context, page, type }) {
    let params =  {
      per_page: 150,
      page: page,
      instance_id: instanceId
    }

    if (context === "current") {
      params.chat_id = chatId;

      return new Promise((resolve, reject) => {
        axiosOmni.get(`/whatsapp/chats/${ chatId }/messages`, { params }).then(response => {
          const messages = groupMessagesByDay(response.data.data)
          if (type === "initial") {
            commit("setMessagesForSearch", { chatId: chatId, messagesForSearch: messages });
            commit("setPaginationForSearch", { chatId: chatId, type: 'next', paginationForSearch: { ...response.data.meta.pagination, context: 'current' } });
            commit("setPaginationForSearch", { chatId: chatId, type: 'previous', paginationForSearch: { ...response.data.meta.pagination, context: 'current' } });
          } else {
            dispatch("mergeSearchMessages", { chatId: chatId, messages: messages, type: type });
            commit("setPaginationForSearch", { chatId: chatId, type: type, paginationForSearch: { ...response.data.meta.pagination, context: 'current' } });
          }

          resolve();
        }).catch(() => {
          commit("system/setErrorMessage", "Não foi possível localizar a mensagem, tente novamente mais tarde.", { root: true });
          reject();
        })
      });
    }
    else if (context === "history"){
      return new Promise((resolve, reject) => {
        axiosOmni.get("/whatsapp/message/" + number + `/history`, { params }).then(response => {
          const messages = groupMessagesByDay(response.data.data)
          if (type === "initial") {
            commit("setMessagesForSearch", { chatId: chatId, messagesForSearch: messages })
            commit("setPaginationForSearch", { chatId: chatId, type: "next", paginationForSearch: { ...response.data.meta.pagination, context: "history" } });
            commit("setPaginationForSearch", { chatId: chatId, type: "previous", paginationForSearch: { ...response.data.meta.pagination, context: "history" } });
          }
          else {
            dispatch("mergeSearchMessages", { chatId: chatId, messages: messages, type: type });
            commit("setPaginationForSearch", { chatId: chatId, type: type, paginationForSearch: { ...response.data.meta.pagination, context: "history" } });
          }
          resolve();
        }).catch(() => {
          commit("system/setErrorMessage", "Não foi possível localizar a mensagem, tente novamente mais tarde.", { root: true })
          reject();
        })
      });
    }
  },

  mergeSearchMessages({ getters, commit }, {chatId, messages, type}) {
    let messagesForSearch = { ...getters.getMessagesForSearch(chatId) }

    if (type === "next") {
      for (const day in messages) {
        if (messagesForSearch[day]) {
          messagesForSearch[day] = [...messagesForSearch[day], ...messages[day]]
        } else {
          messagesForSearch[day] = messages[day];
        }
      }
      commit("setMessagesForSearch", { chatId: chatId, messagesForSearch: messagesForSearch })
      return
    }
    if (type === "previous") {
      let newMessagesObject = { ...messages }
      for (const day in messagesForSearch) {
        if (newMessagesObject[day]) {
          newMessagesObject[day] = [...newMessagesObject[day], ...messagesForSearch[day]]
        } else {
          newMessagesObject[day] = messagesForSearch[day];
        }
      }
      commit("setMessagesForSearch", { chatId: chatId, messagesForSearch: newMessagesObject })
    }
  },

  mergeSearchMessagesToMessages({getters, commit}, chatId) {
    const messagesForSearch = { ...getters.getMessagesForSearch(chatId) }
    let messages = { ...getters.getMessages(chatId) }

    for (const day in messagesForSearch) {
      if (messages[day]) {
        messages[day] = [...messages[day], ...messagesForSearch[day]]
      } else {
        messages[day] = messagesForSearch[day];
      }
    }

    const pagination = {...getters.getPaginationsForSearch(chatId).next }
    commit("clearChatSearchMessages", chatId)
    commit("setPagination", { chatId: chatId, pagination: pagination })
    commit("setMessages", { chatId: chatId, messages: messages })
  },

  mergeStashedMessagesToMessages({ getters, commit }, chatId) {
    const stashedMessages = {...getters.getStashedMessages(chatId)}
    let messages = {...getters.getMessages(chatId)}

    for (const day in stashedMessages) {
      if (messages[day]) {
        messages[day] = [...messages[day], ...stashedMessages[day]]
      } else {
        messages[day] = stashedMessages[day];
      }
    }

    commit("setMessages", { chatId: chatId, messages: messages })
    commit("setStashedMessages", { chatId: chatId, stashedMessages: null })
  },

  addRecentMessage({ getters, commit }, message) {
    const pagination = getters.getPaginations(message.chat_id)
    if (!pagination) {
      return
    }

    const chatId = message.chat_id
    let messages = {...getters.getMessages(chatId)}
    const day = getMessageDay(message.time)
    if (!messages[day]) {
      messages = { [day]: [message], ...messages }
    }
    else {
      for (let i = 0; i < messages[day].length; i++) {
        if (message.time > messages[day][i].time) {
          messages[day].splice(i, 0, message);
          return
        }
      }
    }

    commit("setMessages", { chatId: chatId, messages: messages })
  },

  replaceTemporaryMessage({ getters, commit, dispatch }, {tempId, tempTime, message}) {
    const chatId = message.chat_id
    let messages = {...getters.getMessages(chatId)}
    const day = getMessageDay(tempTime)

    const index = messages[day].findIndex(msg => msg.internal_id === tempId)
    messages[day].splice(index, 1)
    if (messages[day].length === 0) {
      delete messages[day]
    }
    commit("setMessages", { chatId: chatId, messages: messages })
    dispatch("addRecentMessage", message)
  },

  setMessageFailed({ getters, commit }, {tempId, chatId}) {
    let messages = {...getters.getMessages(chatId)}
    const day = getMessageDay(moment().unix())

    const index = messages[day].findIndex(msg => msg.internal_id === tempId)
    messages[day][index].failed = true
    commit("setMessages", { chatId: chatId, messages: messages })
  },

  removeMessageById({ getters, commit }, message) {
    const chatId = message.chat_id
    let messages = {...getters.getMessages(chatId)}
    const day = getMessageDay(message.time)
    messages[day] = messages[day].filter(msg => msg.internal_id !== message.internal_id)
    commit("setMessages", { chatId: chatId, messages: messages })
  },

  updateMessageACK({ getters, commit }, message) {
    const chatId = message.chat_id
    let messages = {...getters.getMessages(chatId)}
    if (!messages || Object.keys(messages).length === 0) {
      return
    }

    const day = getMessageDay(message.time)
    const index = messages[day].findIndex(msg => msg.internal_id === message._id)
    if (index !== -1) {
      messages[day][index].ack = message.ack
      commit("setMessages", { chatId: chatId, messages: messages })
    }
  },

  markMessageAsDeleted({ getters, commit }, message) {
    const chatId = message.chat_id
    let messages = {...getters.getMessages(chatId)}
    const day = getMessageDay(message.time)

    const index = messages[day].findIndex(msg => msg.internal_id === message.internal_id)
    if (index !== -1) {
      messages[day][index].is_deleted = true
      commit("setMessages", { chatId: chatId, messages: messages })
    }
  }
}

function groupMessagesByDay(messages) {
  const groupedMessages = {};

  messages.forEach(message => {
    const day = getMessageDay(message.time)

    if (!groupedMessages[day]) {
      groupedMessages[day] = [];
    }
    groupedMessages[day].push(message);
  });

  return groupedMessages
}