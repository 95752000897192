<template>
  <div
    :id="'selectCheck' + id"
    class="accordion"
  >
    <div
      :class="{ 'border-0': showSelect }"
      class="accordion-item position-relative"
    >
      <div class="accordion-header">
        <button
          v-show="!showSelect"
          :aria-controls="'selectChecks' + id"
          :data-bs-target="'#selectChecks' + id"
          aria-expanded="true"
          class="btn p-0 collapsed bg-white align-items-center w-100 justify-content-between"
          :class="{ 'd-flex': !showSelect }"
          data-bs-toggle="collapse"
          style="height: 40px;padding: 8px !important;"
          type="button"
          @click="toggleSelect"
        >
          <div
            v-if="selectedOptions.length"
            class="overflow-ellipsis"
          >
            <p class="mb-0">
              {{
                selectedOptions.length > 1
                  ? `${$t('selected_plural')} ${selectedOptions.length}`
                  : `${$t('selected')} ${selectedOptions.length}`
              }}
            </p>
          </div>
          <span
            v-else
            class="tx-text-gray"
          >{{ $t('select') }}</span>
          <i class="fal fa-chevron-down tx-text-gray" />
        </button>
        <div
          v-show="showSelect"
          class="input-group border border-bottom-0 rounded-0 rounded-top"
          style="height: 41px;"
        >
          <span class="input-group-text border-0 rounded-0 pe-0 rounded-top h-100"><i class="fal fa-search" /></span>
          <input
            v-model="term"
            class="form-control ps-0 border-0 h-100"
            :placeholder="$t('search_report')"
            ref="inputSearch"
            type="text"
          >
          <span
            :data-bs-target="'#selectChecks' + id"
            class="input-group-text border-0 rounded-0 rounded-top h-100"
            data-bs-toggle="collapse"
            @click="toggleSelect"
          ><i class="fal fa-chevron-up cursor-pointer tx-text-gray fs-3" /></span>
        </div>
      </div>
      <div
        :id="'selectChecks' + id"
        :data-bs-parent="'#selectCheck' + id"
        class="accordion-collapse collapse position-absolute w-100 border border-top-0 rounded-bottom"
        style="z-index: 3 !important"
      >
        <div class="accordion-body bg-white rounded-bottom pt-0">
          <div v-if="groupOptions.length">
            <ul
              class="overflow-auto px-3 mb-0 list-unstyled"
              style="max-height: 176px;"
            >
              <template
                v-for="(group, index) in groupOptions"
                :key="group.id"
              >
                <li v-if="group.options.some(option => filterOptions(option[label]))">
                  <div class="my-2">
                    <input
                      :id="`group${index}`"
                      :checked="group.checked"
                      class="form-check-input"
                      type="checkbox"
                      @change="checkGroup($event, group)"
                    >
                    <label
                      class="ps-2 fw-medium"
                      for="group"
                    >
                      {{ group[label] }}
                    </label>
                  </div>
                  <template
                    v-for="option in group.options"
                    :key="option.id"
                  >
                    <div
                      v-if="filterOptions(option[label])"
                      :class="{ active: active }"
                      class="d-flex ps-3 search-hover rounded m-0"
                      @mouseenter="active = true"
                      @mouseleave="active = false"
                      style="padding-top: 4px;"
                    >
                      <input
                        :id="'option_' + option.id"
                        :checked="option?.checked"
                        class="form-check-input"
                        :class="{ 'bg-secondary': allChecked && group.checked }"
                        type="checkbox"
                        @change="selectedField(option)"
                      >
                      <label
                        :for="'option_' + option.id"
                        class="ps-2 m-0 w-100 tx-text-200 "
                      >
                        {{ option[label] }}
                      </label>
                    </div>
                  </template>
                </li>
              </template>
            </ul>
          </div>
          <div
            v-else
            class="text-center"
          >
            {{ $t('nothing_to_show_') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Collapse } from "bootstrap";

export default {
  name: "SelectGroup",
  props: {
    id: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    currentSelected: {
      type: [Array, Object],
      default: null
    }
  },
  emits: ["selected"],
  data() {
    return {
      term: "",
      showSelect: false,
      active: false,
      animation: false,
      groupOptions: [],
      selectedOptions: [],
      allChecked: false,
      collapse: null
    };
  },
  methods: {
    closeWindowSelect(event) {
      const box = document.getElementById(`selectCheck${this.id}`);
      const isSelfClick = box.contains(event.target);
      if (this.showSelect && !isSelfClick) {
        this.collapse.hide();
        setTimeout(() => {
          this.showSelect = false;
          this.term = "";
        }, 300);
      }
    },

    removeAccents(value) {
      return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    filterOptions(termToFilter) {
      return this.removeAccents(termToFilter)
        .toString()
        .toLowerCase()
        .includes(this.removeAccents(this.term).toLowerCase());
    },
    toggleSelect() {
      if (!this.animation) {
        this.animation = true;
        this.showSelect = !this.showSelect;
        setTimeout(() => {
          this.animation = false;
          this.$nextTick(() => {
            this.onFocus();
          });
        }, 400);
      }
    },
    selectedField(item) {
      item.checked = !item.checked;
      this.updateSelectedOptions(item);
    },
    checkGroup($event, group) {
      group.checked = $event.target.checked;
      group.options.forEach(option => {
        option.checked = group.checked;
        this.allChecked = group.checked;
        this.updateSelectedOptions(option);
      });
    },
    updateSelectedOptions(item) {
      const existingOption = this.selectedOptions.find(option => option.id === item.id);

      if (item.checked && !existingOption) {
        this.selectedOptions.push(item);
      } else if (!item.checked && existingOption) {
        this.selectedOptions = this.selectedOptions.filter(option => option.id !== item.id);
      }
      this.$emit("selected", this.selectedOptions);
      this.term = "";
    },
    fillGroupOptions() {
      this.groupOptions = this.options.map(item => {
        return {
          id: item.id,
          name: item.name,
          checked: false,
          options: item.qualifications.map(qualification => {
            return {
              checked: false,
              ...qualification
            };
          })
        };
      });
    },
    startSelectedOptions() {
      this.selectedOptions = this.currentSelected || [];
    },
    verifySelectedCurrentOptions() {
      this.groupOptions.forEach(group => {
        group.options.forEach(option => {
          option.checked = this.selectedOptions.some(selected => selected.id === option.id);
        });
        group.checked = group.options.every(option => option.checked);
        if (group.checked) {
          this.allChecked = true;
        }
      });
    },
    onFocus() {
      if (this.showSelect) {
        this.$refs.inputSearch.focus();
      }
    }
  },
  mounted() {
    this.startSelectedOptions();
    this.fillGroupOptions();
    this.collapse = new Collapse(document.getElementById(`selectChecks${this.id}`), {
      toggle: false
    });
    document.addEventListener("click", this.closeWindowSelect);
  },
  unmounted() {
    document.removeEventListener("click", this.closeWindowSelect);
  },
  watch: {
    currentSelected: {
      handler() {
        this.startSelectedOptions();
      },
      deep: true
    },
    options: {
      handler() {
        this.fillGroupOptions();
      },
      deep: true
    },
    groupOptions: {
      handler: "verifySelectedCurrentOptions",
      deep: true
    },
    selectedOptions: {
      handler() {
        this.verifySelectedCurrentOptions();
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.search-hover.active:hover {
  background-color: $color-gray-blue-300;
  color: $color-text-300 !important;
}
</style>
