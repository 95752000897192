<template>
  <div
    class="modal fade"
    ref="removeCompanyModal"
    tabindex="-1"
  >
    <div
      class="modal-dialog modal-dialog-centered"
    >
      <div class="modal-content align-items-center p-5 px-6 position-relative">
        <i
          type="button"
          @click="this.removeCompanyModal.hide()"
          class="btn-close position-absolute top-0 end-0 pt-4 pe-4"
        />
        <img
          src="@assets/img/icons/x-circle-thin.svg"
          alt="close icon"
        >
        <h2 class="form-label fw-bold text-center my-3 fs-1">
          Excluir empresa
        </h2>
        <div class="d-flex flex-column col-12">
          <p class="text-center">
            A empresa <strong> {{ companyName }} </strong> com domínio
            <strong> {{ companyDomain }} </strong> será excluida. Digite o
            domínio da empresa para confirmar a exclusão:
          </p>
          <input
            type="text"
            :class="{
              'form-control': true,
              'is-invalid': errors.domain,
            }"
            placeholder="domínio"
            v-model="removeCompanyDomain"
            data-test="removeCompany-input"
          >
          <div class="invalid-feedback">
            {{ errors.domain && errors.domain[0] }}
          </div>
        </div>
        <app-button
          color="btn-primary"
          class="col-12 my-3 fw-bold mb-3"
          @click="_removeCompany"
          :blocked="!(removeCompanyDomain === companyDomain)"
          :loading="loadingFlags.removeCompany"
          data-test="removeCompany-button"
        >
          Excluir
        </app-button>
        <span
          type="button"
          class="pt-3 text-decoration-underline text-secondary"
          @click="this.removeCompanyModal.hide()"
        > Cancelar </span>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from 'bootstrap';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import AppButton from "@/components/app-button.vue";

export default {
  data() {
    return {
      removeCompanyModal: null,
      removeCompanyDomain: ''
    }
  },
  /**
   *
   */
  props: {
    companyName: {
      type: String,
      default: ''
    },
    companyDomain: {
      type: String,
      default: ''
    },
  },
  /**
   *
   */
  mounted() {
    this.removeCompanyModal = new Modal (this.$refs.removeCompanyModal, {})
  },
  /**
   *
   */
  computed: {
    ...mapGetters("admin/companies", {
      loadingFlags: "getLoadingFlags",
      errors: "getRemoveCompanyErrors"
    }),
  },
  /**
   *
   */
  methods: {
    ...mapActions("admin/companies", ["removeCompany"]),
    ...mapMutations("admin/companies", ["setRemoveCompanyDomain", "clearRemoveCompanyErrors"]),
    ...mapActions("auth", ["setCurrentCompany"]),
    ...mapMutations("auth", ["trackEvents"]),
    /**
     *
     */
    _removeCompany() {
      this.setRemoveCompanyDomain(this.removeCompanyDomain)
      this.removeCompany({
        id: this.$route.params.id
      }).then(() => {
        this.setCurrentCompany().then(() => {
          this.trackEvents(`[Admin] Cancel account`)
        })
        this.$router.go(-1)
        this.removeCompanyModal.hide()
      })
    },
    open() {
      this.clearRemoveCompanyErrors()
      this.removeCompanyDomain = '';
      this.removeCompanyModal.show()
    }
  },
  /**
   *
   */
  components: {
    AppButton
  }
}
</script>
<style lang="scss" scoped>

.modal-content {
  max-width: 526px;
}
</style>
