<template>
  <section class="d-flex col-12">
    <div class="w-100 mt-5">
      <div
        v-if="allowChildrenComponents"
        class="row mb-3"
      >
        <card-level-partner />
        <card-partner />
      </div>
      <div v-if="listPartner">
        <TablePartner />
      </div>
    </div>
  </section>
</template>

<script>
import CardLevelPartner from "@partner/pages/component/card-level-partner.vue";
import CardPartner from "@partner/pages/component/card-partner.vue";
import TablePartner from "@partner/pages/component/table-partner.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      allowChildrenComponents: null
    };
  },
  name: "PartnerDashboardAdmin",
  components: {
    CardPartner,
    CardLevelPartner,
    TablePartner
  },
  methods: {
    ...mapActions("admin/partners", ["detailsPartners", "listPartnersAdmin"])
  },
  computed: {
    ...mapGetters("admin/partners", {
      listPartner: "getListPartners",
      partner: "getPartner"
    })
  },
  created() {
    const id = this.$route.params.id;

    this.detailsPartners(id).then(() => {
      this.allowChildrenComponents = true;
    });
    this.listPartnersAdmin(id);
  }
};
</script>
