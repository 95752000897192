import axios from "axios";

export default {
  downloadTable({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading", "downloadTable");
    let params = {
      email: payload.email,
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/blacklist/csv`, { params })
        .then(() => {
          commit("system/setSuccessMessage", "success_message.generate_report", { root: true });
          resolve();
        })
        .catch(response => {
          commit('system/setErrorMessage', 'Houve um problema ao gerar o relatório. Verifique a campanha e tente novamente mais tarde', { root: true })
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading", "downloadTable");
        });
    });
  },
  addCsvInBlocklist({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading", "addCsvInBlocklist");
    return new Promise((resolve, reject) => {
      axios
        .post(`/blacklist/csv`, payload.formData)
        .then(() => {
          commit("system/setSuccessMessage", "success_message.import_list", { root: true });
          resolve();
        })
        .catch(response => {
          commit('system/setErrorMessage', 'O campo Mailing deverá conter um arquivo do tipo: text/csv, text/plain, application/vnd.ms-excel, application/octet-stream.,O arquivo não parece ser um csv válido. Caso tenha dúvidas sobre a formatação valide seu arquivo no site https://csvlint.io', { root: true })
          commit("setAlert", {
            message: response.response.data.detail,
            type: "danger"
          });
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading", "addCsvInBlocklist");
        });
    });
  },
  addNumberToBlocklist({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading", "addNumberToBlocklist");
    return new Promise((resolve, reject) => {
      axios
        .post(`/blacklist/number`, payload.addFormData)
        .then(() => {
          commit("system/setSuccessMessage", "success_message.update_blocklist", { root: true });
          resolve();
        })
        .catch(response => {
          commit('system/setErrorMessage', response.response.data, { root: true })
          commit("setAlert", {
            message: response.response.data.detail,
            type: "danger"
          });
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading", "addNumberToBlocklist");
        });
    });
  },
  removeCsvOfBlocklist({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading", "removeCsvOfBlocklist");
    return new Promise((resolve, reject) => {
      axios
        .post(`/blacklist/remove/csv`, payload.formData)
        .then(() => {
          commit('system/setSuccessMessage', "success_message.import_list", { root: true });
          resolve();
        })
        .catch(response => {
          commit('system/setErrorMessage', 'O campo Mailing deverá conter um arquivo do tipo: text/csv, text/plain, application/vnd.ms-excel, application/octet-stream. O arquivo não parece ser um csv válido. Caso tenha dúvidas sobre a formatação valide seu arquivo no site https://csvlint.io', { root: true })
          commit("setAlert", {
            message: response.response.data.detail,
            type: "danger"
          });
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading", "removeCsvOfBlocklist");
        });
    });
  },
  removeNumberOfBlocklist({ commit }, payload) {
    commit("startLoading", "removeNumberOfBlocklist");
    commit("setDeletedNumber", [])
    return new Promise((resolve, reject) => {
      axios
        .delete(`/blacklist?numbers=${payload.numbers}`)
        .then((response) => {
          commit("system/setSuccessMessage", "success_message.remove_from_blocklist", { root: true })
          commit("setDeletedNumber", response.data.data);
          resolve();
        })
        .catch(response => {
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);

          commit("system/setErrorMessage", response.response.data.detail, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "removeNumberOfBlocklist");
        });
    });
  },

  getIsNumberOnBlocklist({ commit }, payload) {
    commit("startLoading", "getIsNumberOnBlocklist");
    return new Promise((resolve, reject) => {
      axios
        .get(`/blacklist/number`, {params: payload})
        .then(() => {
          commit("system/setSuccessMessage", "success_message.is_in_blocklist", { root: true });
          resolve();
        })
        .catch(response => {
          const message = response.response.status == 404 ? "Número não encontrado na lista de bloqueio" : response.response.data;
          commit("system/setErrorMessage", message, { root: true });
          reject({ message: response.response.data.detail});
        })
        .finally(() => {
          commit("stopLoading", "getIsNumberOnBlocklist");
        });
    });
  },
};
