<template>
  <div
    id="requestModal"
    ref="WithdrawalRequestModal"
    aria-hidden="true"
    aria-labelledby="requestModalLabel"
    class="modal fade"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div
        v-if="this.modalPagination === 1"
        class="modal-content p-4"
      >
        <div class="d-flex justify-content-between">
          <h2
            id="requestModalLabel"
            class="modal-title mb-4 tx-text-200"
          >
            {{ $t("request_withdraw") }}
          </h2>
          <button
            aria-label="Close"
            class="btn-close m-0"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div>
          <small class="text-muted">
            {{ $t("available_manage_uppercase") }}
          </small>
          <h1 class="p-0 mb-4 tx-text-200">
            {{ formatCurrency(parseFloat(detailsPartner.revenue)) }}
          </h1>
          <hr>
          <form @submit.prevent="!errorBoolean ? submitWithdraw() : null">
            <p class="mt-4 mb-2 tx-text-200">
              {{ $t("deposit_pix") }}
            </p>
            <input
              v-model="transactionData.pix"
              class="form-control rounded-end"
              :placeholder="$t('type_here_placeholder')"
              type="text"
            >
            <p class="mt-4 mb-2 tx-text-200">
              {{ $t("value") }}
            </p>
            <currency-input
              ref="currency"
              v-model="transactionData.amount"
              :error="error"
              @currency="this.verifyAmount"
            />
            <button
              :class="errorBoolean ? 'disabled btn-secondary' : 'btn-primary'"
              class="btn w-100 mb-3"
              style="margin-top: 48px"
            >
              {{ $t("request") }}
            </button>
          </form>
        </div>
      </div>
      <div
        v-if="modalPagination === 2"
        class="modal-content p-4"
        style="min-height: 600px"
      >
        <div class="d-flex justify-content-between align-items-baseline">
          <h2
            id="requestModalLabel"
            class="modal-title mb-4 tx-text-200"
          >
            {{ $t("request_withdraw") }}
          </h2>
          <button
            aria-label="Close"
            class="btn m-0"
            data-bs-dismiss="modal"
            type="button"
          >
            <i class="fa-regular fa-times fs-5" />
          </button>
        </div>
        <div class="d-flex justify-content-center align-items-center flex-column flex-grow-1 position-relative">
          <Vue3Lottie
            :animation-data="this.LoadingJson"
            class="m-0 p-0"
            style="max-width: 200px"
          />
          <p class="position-absolute top-50 mt-4 tx-text-200">
            {{ $t("requesting") }}
          </p>
        </div>
      </div>
      <div
        v-if="modalPagination === 3"
        class="modal-content p-4"
        style="min-height: 600px"
      >
        <div class="d-flex justify-content-between">
          <h2
            id="requestModalLabel"
            class="modal-title mb-4 tx-text-200"
          >
            {{ $t("request_withdraw") }}
          </h2>
          <button
            aria-label="Close"
            class="btn-close m-0"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div class="d-flex justify-content-end align-items-center flex-grow-1 flex-column pb-4">
          <Vue3Lottie
            :animation-data="this.CheckJson"
            :loop="false"
            class="m-5"
            style="height: 180px"
          />
          <p class="my-5 tx-text-200">
            {{ $t("withdraw_request_success") }}
          </p>
          <button
            class="btn btn-primary d-block w-100 mt-5"
            type="button"
            @click.prevent="initialPage"
          >
            {{ $t("request_again") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <modal-component
    :show="showPendingContractModal"
    :center="true"
    @close="closeModal"
  >
    <template #content>
      <div class="modal-body">
        <button
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />

        <div class="row justify-content-center">
          <div class="col-11">
            <div class="d-flex flex-column align-items-center">
              <img
                class="mb-3"
                alt="Pluzinha Info"
                src="@assets/img/notification-pluszinha/info.svg"
                width="125"
              >

              <h2 class="text-center">
                {{ $t("oops_seems_like_your_contract_is_pending") }}
              </h2>
              <p
                class="text-center text-large"
                v-html="$t('sign_contract_to_withdraw')"
              />

              <button
                class="btn btn-primary close-pending-contract-modal mt-4"
                @click="closeModal"
              >
                {{ $t("close") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import { Modal } from "bootstrap";
import { Utils } from "@/utils";
import { mapActions, mapGetters } from "vuex";
import CurrencyInput from "@components/currency-input.vue";
import { Vue3Lottie } from "vue3-lottie";
import CheckJson from "@/assets/lottie/check.json";
import LoadingJson from "@/assets/lottie/loading.json";
import ModalComponent from "@/components/modal-component.vue";

export default {
  name: "ModalWithdrawalRequest",

  components: {
    Vue3Lottie,
    CurrencyInput,
    ModalComponent
  },

  data() {
    return {
      WithdrawalModal: null,
      transactionData: {
        pix: "",
        amount: null
      },
      error: "",
      modalPagination: 1,
      errorBoolean: true,
      CheckJson,
      LoadingJson,
      showPendingContractModal: false
    };
  },

  mounted() {
    this.WithdrawalModal = new Modal(this.$refs.WithdrawalRequestModal);
    this.$refs.WithdrawalRequestModal.addEventListener("hide.bs.modal", this.resetModal);
  },

  beforeUnmount() {
    this.WithdrawalModal.hide();
  },

  methods: {
    ...mapActions("partner", ["requestWithdraw", "detailsPartners"]),

    new() {
      this.WithdrawalModal.show();
    },

    resetModal() {
      if (this.modalPagination == 1) this.$refs.currency.clearValue();
      this.transactionData = {
        pix: "",
        amount: 0
      };
      this.resetError();
      this.modalPagination = 1;
    },

    formatCurrency: Utils.formatCurrency,

    verifyAmount(data) {
      this.transactionData.amount = data;
      this.errors();
    },

    errors() {
      if (this.transactionData.amount <= parseFloat(this.detailsPartner.revenue)) {
        this.error = "";
        this.errorBoolean = false;
        this.disable();
      } else {
        this.error = this.$t("error_withdraw_value");
        this.errorBoolean = true;
      }
    },

    resetError() {
      this.error = "";
      this.errorBoolean = true;
    },

    disable() {
      if (this.transactionData.amount === 0 || this.transactionData.pix === "") {
        this.errorBoolean = true;
      }
    },

    initialPage() {
      this.modalPagination = 1;
      this.transactionData = {
        pix: "",
        amount: 0
      };
      this.disable();
    },

    submitWithdraw() {
      const request = {
        pix: this.transactionData.pix,
        value: this.transactionData.amount
      };

      this.modalPagination = 2;

      this.requestWithdraw(request)
        .then(() => {
          this.modalPagination = 3;

          const payload = {
            hasLoading: false,
            id: this.user.id
          };
          this.detailsPartners(payload);
        })
        .catch(errorMessage => {
          this.WithdrawalModal.hide();
          if (errorMessage === "O contrato de parceiros deve ter sido assinado para prosseguir com a operação.") {
            this.showPendingContractModal = true;
          }
        });
    },

    closeModal() {
      this.showPendingContractModal = false;
    }
  },

  computed: {
    ...mapGetters("partner", {
      detailsPartner: "getDetailsPartner"
    }),

    ...mapGetters("auth", {
      user: "getUser"
    })
  },
  watch: {
    "transactionData.pix": function() {
      this.verifyAmount(this.transactionData.amount);
    }
  }
};
</script>

<style lang="scss" scoped>
.close-pending-contract-modal {
  width: 330px;
}
</style>
