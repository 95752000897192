<template>
  <div
    class="offcanvas-body d-flex flex-column overflow-scroll gap-1rem"
  >
    <action-component
      icon-class="fa-regular fa-message-check"
      icon-color="#5CB868"
      :name="$t('multiple_choice_question')"
    />
    <div class="col-auto">
      <label
        class="form-label"
        for="message"
      >
        {{ $t('message_text') }}
      </label>
      <textarea-with-count
        id="message"
        v-model="textContent"
        :is-invalid="hasErrors && !textContent"
        :placeholder="$t('what_is_your_message')"
        :max-length="255"
        :max-height="264"
      />
    </div>
    <p class="m-0 fw-medium">
      {{ $t('options') }}
    </p>
    <div
      v-for="(item, index) in multiple_choice_items"
      :key="index"
      class="col-auto"
    >
      <label
        class="form-label"
        for="optionTitle"
      >{{ item.expected_message_response }}
      </label>
      <div class="input-group">
        <textarea-with-count
          id="optionTitle"
          v-model="multiple_choice_items[index].text_content"
          :is-invalid="hasErrors && !multiple_choice_items[index].text_content"
          :placeholder="$t('option') + ' ' + item.expected_message_response"
          :max-length="255"
          :max-height="264"
        />
        <span
          v-if="index > 1"
          class="input-group-text fe fe-x cursor-pointer"
          @click="multiple_choice_items.splice(index, 1)"
        />
      </div>
    </div>
    <hr class="w-100">
    <button
      v-if="multiple_choice_items.length < 12"
      class="btn btn-sm btn-light align-self-center btn-add-choice"
      @click="addNewChoiceItem"
    >
      + {{ $t('new_option') }}
    </button>
    <div class="mt-auto" />
    <app-button
      :disabled="hasErrors && (!textContent || Object.values(multiple_choice_items).some(el => !el.text_content)) || loadingFLags.createAction"
      class="col-auto"
      @click="createMultiChoice"
    >
      {{ $t('create') }}
    </app-button>
    <app-button
      class="col-auto"
      color="btn-outline-secondary border-0"
      @click="$emit('return')"
    >
      {{ $t('back_capitalize') }}
    </app-button>
  </div>
</template>

<script>
import ActionComponent from "@chatbot/pages/workflow/components/action-component.vue";
import AppButton from "@components/app-button.vue";

import {mapActions, mapGetters, mapMutations} from "vuex";
import TextareaWithCount from "@components/textarea-with-count.vue";

export default {
  name: "CreateChoice",

  emits: ["return", "close"],

  components: {
    TextareaWithCount,
    AppButton,
    ActionComponent
  },

  data() {
    return {
      textContent: "",
      multiple_choice_items: [
        {
          text_content: "",
          expected_message_response: "1",
          default_next_action: "",
        }, {
          text_content: "",
          expected_message_response: "2",
          default_next_action: "",
        }
      ],
      hasErrors: false,
    }
  },

  computed: {
    ...mapGetters("chatbot/actions", {
      prevActionId: "getPrevActionId",
      selectedAction: "getSelectedAction",
      loadingFLags: "getLoadingFlags",
    })
  },

  methods: {
    ...mapActions("chatbot/actions", [
      "setNewAction",
      "createAction"
    ]),
    ...mapActions("chatbot/chatbots", ["fetchChatbot"]),
    ...mapMutations("chatbot/actions", ["removeAction", "setSelectedAction","addNodesPosition"]),
    ...mapMutations("auth", ["trackEvents"]),
    ...mapMutations("chatbot/actions", ["setAction"]),

    addNewChoiceItem() {
      if (this.multiple_choice_items.length == 12) {
        return
      }

      this.multiple_choice_items.push({
        text_content: "",
        expected_message_response: String(this.multiple_choice_items.length + 1),
        default_next_action: "",
      })
    },

    createMultiChoice() {
      if (!this.textContent || Object.values(this.multiple_choice_items).some(el => !el.text_content)) {
        this.hasErrors = true
        return
      } else {
        this.hasErrors = false
        this.trackEvents("[Manager] Create Chatbot Multi Choice")
      }

      let payload = {
        type: "multiple_choice",
        need_chat_response: false,
        text_content: this.textContent,
        chatbot_id: this.$route.params.id,
        multiple_choice_items: this.multiple_choice_items,
        x_coordinate: this.selectedAction?.position?.x || 0,
        y_coordinate: this.selectedAction?.position?.y || 0
      }
      if(this.prevActionId){
        payload.previous_action_id = this.prevActionId
      }
      this.createAction(payload).then((newAction) => {
        this.textContent = "";

        const newPosition = {
          id: `${newAction.id}`,
          position: {
            x: this.selectedAction?.position?.x || 0,
            y: this.selectedAction?.position?.y || 0
          }
        };

        this.addNodesPosition(newPosition);

        if (this.selectedAction) {
          this.removeAction(this.selectedAction.id);
          this.setSelectedAction(null);
        }

        this.$emit("close");
        this.trackEvents("[Manager] Create Chatbot Multi Choice");
      });
    }
  }
}
</script>
<style scoped>
.gap-1rem {
  gap: 1rem;
}
.btn-add-choice{
  width: 141px;
}
</style>
