<template>
  <div>
    <div
      class="card"
    >
      <div class="card-header">
        <h4 class="m-0">
          Webhooks
        </h4>
      </div>
      <div class="card-body px-0 py-0">
        <table class="table table-hover card-table mb-0">
          <thead>
            <tr>
              <th class="col-1" />
              <th class="col-auto text-muted fw-normal">
                URL
              </th>
              <th class="col-1 text-muted fw-normal">
                {{ $t('events_uppercase') }}
              </th>
              <th class="col-2" />
            </tr>
          </thead>

          <tbody class="list fs-base">
            <tr v-if="webhooks.length === 0 && !loadingFlags.fetchWebhooks">
              <td
                class="text-center text-muted"
                colspan="5"
              >
                <span class="fe fe-alert-octagon" /> {{ $t('no_integration_found') }}
              </td>
            </tr>

            <template v-if="loadingFlags.fetchWebhooks">
              <loading-table-component
                :lines="15"
                :columns="4"
                :text-center="false"
                :list-width="[50,150,50,50]"
              />
            </template>

            <template
              v-else-if="webhooks.length"
            >
              <tr
                v-for="item in webhooks"
                :key="item.id"
              >
                <td>
                  <div class="form-check form-switch d-flex justify-content-center align-items-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      :checked="item.active"
                      :actived="item.active"
                      @change="activeWebhook(item)"
                    >
                  </div>
                </td>
                <td>{{ item.url }}</td>
                <td
                  class="text-center"
                >
                  {{ item.events.split(',').length }}
                </td>
                <td class="text-end">
                  <i
                    class="fa-regular fa-pen-to-square me-3 cursor-pointer"
                    @click="
                      $router.push({ path: `/manager/integration/${item.id}/edit` })
                    "
                  />
                  <i
                    class="fa-light fa-trash fw-normal cursor-pointer"
                    @click="$refs.deleteModal.openModal(item.id)"
                  />
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <delete-modal
          ref="deleteModal"
          :title="$t('delete_integration')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";
import DeleteModal from "@dialer/manager/pages/settings/integration/components/delete-modal.vue"

export default {
  name: "IntegrationTableContent",

  components: {
    LoadingTableComponent,
    DeleteModal
  },

  beforeMount() {
    this.fetchWebhooks()
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser",
    }),

    ...mapGetters("manager/integration", {
      webhooks: "getWebhooks",
      loadingFlags: "getLoadingFlags",
    }),
  },

  methods: {
    ...mapActions("manager/integration", [
      "fetchWebhooks",
      "updateWebhook"
    ]),

    activeWebhook(webhook) {
      const {active, events, ...rest} = webhook;
      let updatedWebhook = {
        active: !active,
        events: events.split(","),
        ...rest
      };
      this.updateWebhook(updatedWebhook)
    }
  },
};
</script>

<style scoped lang="scss">

.table-hover> tbody> tr > td {
  i {
    display: none;
  }
}

.table-hover> tbody> tr:hover > td > .fa-pen-to-square {
  display: inline-block;
  color: $color-blue-300;
}
.table-hover> tbody> tr:hover > td > .fa-trash {
  display: inline-block;
  color: #F23F2C;
}

</style>
