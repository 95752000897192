<template>
  <history-accordion
    v-if="change.change_type !== 'two_factor_change_setup_deadline'"
    :index="index"
  >
    <template #header>
      <div class="w-100 row align-items-center">
        <div
          class="col-lg-5"
          v-if="change.change_type === 'two_factor_change_status'"
        >
          Autenticação de Dois Fatores
        </div>
        <div
          class="col-lg-5"
          v-if="change.change_type === 'two_factor_change_interval_days'"
        >
          Alteração da frequência da autenticação de dois fatores
        </div>
        <div class="col-lg-7">
          <div class="d-flex justify-content-between">
            <div v-if="change.change_type === 'two_factor_change_status'">
              <span v-if="change.previous_value != null">
                {{ change.previous_value ? "Habilitado" : "Desabilitado" }}
                <i
                  class="fe fe-repeat"
                  style="color: #3057F2;"
                />
              </span>
              {{ change.current_value ? "Habilitado" : "Desabilitado" }}
            </div>
            <div v-if="change.change_type === 'two_factor_change_interval_days'">
              <span v-if="change.previous_value != null">
                {{ change.previous_value }} {{ change.previous_value > 1 ? "dias" : "dia" }}
                <i
                  class="fe fe-repeat"
                  style="color: #3057F2;"
                />
              </span>
              {{ change.current_value }} {{ change.current_value > 1 ? "dias" : "dia" }}
            </div>
            <div class="me-4 text-small">
              <span class="text-muted d-block">Realizado em:</span>
              {{ timestampToDate(change.created_at, "DD/MM/YYYY [às] HH:mm") }}
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #body>
      <div class="row text-small">
        <div class="col-lg-2">
          <span class="d-block tx-text-200 fw-bold">Realizado por:</span>
          {{ change.user_name }}
        </div>
      </div>
    </template>
  </history-accordion>
</template>

<script>
import { Utils } from "@/utils";
import HistoryAccordion from "./history-accordion.vue";

export default {
  components: {
    HistoryAccordion
  },

  props: {
    change: {
      type: Object,
      default: () => {}
    },

    index: {
      type: Number,
      default: null
    }
  },

  methods: {
    timestampToDate: Utils.timestampToDate
  }
};
</script>
