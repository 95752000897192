export default {
  startLoading: (state, loadingContext = '') => {
    state.loading = true
    state.loadingContext = loadingContext
  },

  stopLoading: state => {
    state.loading = false
    state.loadingContext = ''
  },

  startLoadingFlag: (state, flag) => state.loadingFlags[flag] = true,
  stopLoadingFlag: (state, flag) => state.loadingFlags[flag] = false,

  setLoadingContext: (state, loadingContext) => state.loadingContext = loadingContext,

  setListAgentsOnline: (state, listAgentsOnline) => state.listAgentsOnline = listAgentsOnline,

  setErrorMessage: (state, errorMesage) => state.errorMessage = errorMesage,
  setErrors: (state, errors) => state.errors = errors,
  clearErrors: (state) => {
    state.errors = {}
    state.errorMessage = ""
  },

  setCampaign: (state, campaign) => state.campaign = campaign,
  setDataSelect: (state, dataSelect) => state.dataSelect = dataSelect,
  setAgentStats: (state, agentStats) => state.agentStats = agentStats,
  setAgent: (state, agent) => state.agent = agent,
  setTimeCalls: (state, timeCalls) => state.timeCalls = timeCalls,
  setCalls: (state, calls) => state.calls = calls,
  setActiveDropdownLeft: (state, activeDropdownLeft) => state.activeDropdownLeft = activeDropdownLeft,
  setActiveDropdownRight: (state, activeDropdownRight) => state.activeDropdownRight = activeDropdownRight,
}
