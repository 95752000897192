<template>
  <modal-component
    center
    :show="modelValue"
    @close="$emit('update:modelValue', false)"
    size="w-100 px-0"
    custom-size="600px"
  >
    <template #content>
      <div class="modal-header">
        <div class="d-flex align-items-center">
          <i
            class="far fa-bullseye-arrow text-primary fs-5"
            style="margin-right: 16px;"
          />
          <h2 class="modal-title">
            {{ modal(mode).header }}
          </h2>
        </div>
        <button
          class="btn p-0"
          @click="$emit('update:modelValue', false)"
        >
          <i class="fal fa-xmark tx-text-gray" />
        </button>
      </div>
      <div class="modal-body">
        <div
          class="d-flex"
          :class="[mode !== 'delete' ? 'align-items-center' : 'align-items-stretch']"
        >
          <h5 class="mb-0">
            {{ modal(mode).title }}
          </h5>
          <img
            style="margin-left: 32px;"
            src="@/assets/img/3czinhos.svg"
            alt="Balões 3C"
            height="19"
          >
        </div>
        <input
          v-if="mode !== 'delete'"
          class="form-control"
          type="number"
          min="0"
          v-maska
          data-maska="#0"
          v-model="goal"
          data-maska-tokens="0:[0-9]:repeated"
          style="margin-top: 32px"
        >
        <p
          class="mb-0 tx-text-gray"
          :style="{ marginTop: mode === 'delete' ? '16px' : '' }"
        >
          {{ modal(mode).text }}
        </p>
        <div
          class="d-flex flex-column justify-content-center"
          style="margin-top: 40px;"
        >
          <app-button
            @click="submit()"
            :color="mode === 'delete' ? 'bg-danger text-white' : 'bg-primary text-white'"
          >
            {{ modal(mode).confirmButton }}
          </app-button>
          <app-button
            size="justify-content-center"
            :color="mode === 'edit' ? 'text-danger' : 'tx-text-gray'"
            @click="removeOrCancel(mode)"
          >
            <i
              v-if="mode === 'edit'"
              class="fal fa-trash"
            />
            {{ modal(mode).cancelButton }}
          </app-button>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from "@components/modal-component.vue";
import AppButton from "@components/app-button.vue";
import { mapActions } from "vuex";

export default {
  name: "CompanyGoalModal",
  components: { AppButton, ModalComponent },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    fields: {
      type: Object,
      default: () => {}
    }
  },
  emits: ["update:modelValue"],
  data() {
    return {
      goal: 0,
      mode: ""
    };
  },
  watch: {
    fields: {
      handler() {
        this.mode = this.fields.mode;
        this.goal = this.fields.conversion_goal;
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("manager/list", ["updateGoalList", "findListOfLists"]),
    modal(mode) {
      const isEditMode = mode === "new" || mode === "edit";
      return {
        header: mode === "new" ? this.$t('assign_goal') : mode === "edit" ? this.$t('edit_goal') : this.$t('delete_goal'),
        title: isEditMode
          ? this.$t('edit_goal_title')
          : this.$t('delete_goal_title'),
        text: isEditMode
          ? this.$t('edit_goal_text')
          : this.$t('delete_goal_text'),
        confirmButton: isEditMode ? this.$t('capitalizes.save') : this.$t('yes'),
        cancelButton: mode === "edit" ? this.$t('delete_goal') : this.$t('cancel_general')
      };
    },

    removeOrCancel(mode) {
      const typeAction = {
        new: () => {
          this.$emit("update:modelValue", false);
        },
        edit: () => {
          this.mode = "delete";
          this.modal("delete");
        },
        delete: () => {
          this.$emit("update:modelValue", false);
        }
      };
      return typeAction[mode]();
    },
    submit() {
      let fields = {
        campaign_id: this.fields.campaign_id,
        list_id: this.fields.list_id,
        conversion_goal: this.goal ? this.goal : 0
      };
      if (this.mode === "delete") {
        fields.conversion_goal = 0;
      }
      this.updateGoalList(fields);
      this.findListOfLists({ id: fields.campaign_id }).then(() => {
        this.$emit("update:modelValue", false);
      });
    }
  }
};
</script>