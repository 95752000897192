<template>
  <div
    v-if="date"
    class="list-group-item"
    style="padding: 0.5rem 0"
  >
    <div
      class="d-flex"
      style="gap: 0.5rem"
    >
      <div class="col-auto">
        <div class="avatar avatar-mini">
          <div class="avatar-title bg-gray-200 rounded-circle" />
        </div>
      </div>
      <p class="m-0 text-small lh-sm">
        {{ date }}
      </p>
    </div>
  </div>

  <div
    v-else-if="showLog"
    class="list-group-item"
    style="padding: 0.5rem 0"
  >
    <div
      class="d-flex"
      style="gap: 0.5rem"
    >
      <div class="col-auto">
        <div class="avatar avatar-mini">
          <div class="avatar-title avatar-green rounded-circle" />
        </div>
      </div>
      <div class="col">
        <p class="m-0 text-medium lh-1">
          {{ title }}
        </p>
        <div
          class="d-flex align-items-center"
          style="gap: 0.5rem"
        >
          <p class="m-0 text-small lh-sm">
            {{ formatDate(time, "HH:mm") }}
          </p>
          <span
            class="fas fa-circle tx-text-gray"
            style="font-size: 3px"
          />
          <p class="m-0 text-small lh-sm text-truncate">
            {{ author }}
          </p>
        </div>
        <div
          v-if="showQualification"
          class="qualification lh-sm text-small mb-1"
        >
          <strong class="fw-meidum">{{ $t('qualification') }}</strong>: {{ qualification }}
        </div>
        <div
          v-if="showNote"
          class="internal-note lh-sm text-small"
        >
          <strong class="fw-meidum">{{ $t('internal_note') }}</strong> - {{ internalNote }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate, formatTimestamp } from "@/utils";

export default {
  name: "TimelineCard",

  props: {
    date : {
      type: String,
      default: ""
    },
    log: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    showLog() {
      if (this.log.event_type === "UPDATED") {
        if (!this.log.new_data.in_snooze && this.log.old_data.in_snooze) {
          return true
        }
        return false
      }

      const checkType = ["CREATED"].includes(this.log.event_type)
      const checkNewDataType = !(["protocol-message"].includes(this.log.new_data.type))

      return checkType && checkNewDataType
    },

    showQualification() {
      return this.log.new_data.type === "qualification-message"
    },

    showNote() {
      return this.log.new_data.type === 'internal-message' || this.log.new_data.type === "qualification-message"
    },

    title() {
      if (this.log.new_data.type === "chat") {
        return this.$t('started_chat')
      }
      if (this.log.new_data.type === "snooze-message") {
        const snooze = this.log.new_data.inter_message_data.end_snooze - this.log.new_data.inter_message_data.start_snooze
        const hours = Math.round(snooze / 3600)
        return `${this.$t('waiting_for')} ${hours} ${this.$t('hours')} `
      }
      if (this.log.new_data.type === "transfer") {
        return this.$t('transferred_chat_capitalize')
      }
      if (this.log.new_data.type === "internal-message") {
        return this.$t('internal_note')
      }
      if (this.log.new_data.type === "qualification-message") {
        return this.$t('end_chat')
      }
      if (!this.log.new_data.in_snooze && this.log.old_data.in_snooze) {
        return this.$t('waiting_chat_time_ended_early')
      }
      return ""
    },

    time() {
      return this.log.new_data.created_at
    },

    author() {
      if (this.log.new_data.type === 'chat') {
        if (this.log.event_type === "CREATED") {
          return this.log.new_data.internal_message.client_initiated_chat ? this.$t('by_client') : this.log.new_data.internal_message.agent_name
        }
        return this.log.user_name
      }
      if (this.log.new_data.type === "snooze-message") {
        return this.log.new_data.agent.name
      }
      if (this.log.new_data.type === "transfer") {
        if (this.log.user_name == this.log.new_data.inter_message_data.previous_agent.name) {
          return `${this.$t('from_capitalize')} ${this.log.user_name} ${this.$t('transferred_to.to')} ${this.log.new_data.inter_message_data.current_agent.name}`
        }
        return `${this.$t('by')} ${this.log.user_name} ${this.$t('from')} ${this.log.user_name} ${this.log.new_data.inter_message_data.previous_agent.name} ${this.$t('transferred_to.to')}  ${this.log.new_data.inter_message_data.current_agent.name}`
      }
      if (this.log.new_data.type === "internal-message") {
        return this.log.user_name
      }
      if (this.log.new_data.type === "qualification-message") {
        return this.log.user_name
      }
      if (!this.log.new_data.in_snooze) {
        return this.log.new_data.last_message ? this.$t('client'): this.log.user_name
      }
      return ""
    },

    qualification() {
      if (this.showQualification) {
        return this.log.new_data.inter_message_data.name
      }
      return ""
    },

    internalNote() {
      if (this.showNote) {
        if (this.log.new_data.type === "internal-message") {
          return `${this.log.user_name}: ${this.log.new_data.body}`
        }
        if (this.log.new_data.type === "qualification-message") {
          return `${this.log.user_name}: ${this.log.new_data.body}`
        }
      }
      return ""
    }
  },

  methods: {
    formatDate,
    formatTimestamp
  }
}
</script>

<style scoped lang="scss">
.avatar-mini {
  width: 1rem;
  height: 1rem;
}

.avatar-micro {
  width: 0.5rem;
  height: 0.5rem;
}

.avatar-green {
  background-color: #D6E9D9;
}

.internal-note {
  background-color: #FFE9A9;
  border-radius: 0.625rem;
  padding: 0.75rem 0.5rem;
}

.qualification {
  background-color: #E1E9F4;
  border-radius: 0.625rem;
  padding: 0.75rem 0.5rem;
}

</style>
