import termsOfUse from "@dialer/manager/components/terms-of-use.vue";

export default {
    path: "/terms-of-use",
    name: "terms-of-use",
    components: {
      main: termsOfUse
    },
    meta: {
      "short-side-bar": false
    },
  };
