<template>
  <div :class="{'position-relative': notificationCounter}">
    <button
      class="transparency-hover-button"
      :class="{
        'hovered-style': keepButtonHovered,
        'active': active,
        'px-2': text
      }"
      :style="styleVariables"
      :disabled="disabled"
    >
      <!-- Icons only -->
      <template v-if="icon">
        <div
          v-if="!showCloseButton"
          class="custom-icon d-flex align-items-center justify-content-center"
        >
          <i :class="`${icon} fs-5`" />
        </div>

        <div
          v-else
          class="custom-icon d-flex align-items-center justify-content-center"
        >
          <i class="fe fe-x fs-5" />
        </div>
      </template>

      <!-- Plain text -->
      <span
        v-if="text"
        class="mx-2"
      >
        {{ text }}
      </span>
    </button>


    <!-- Notification counter -->
    <Transition name="fade">
      <div
        v-if="notificationCounter && !showCloseButton"
        class="position-absolute notification d-flex align-items-center justify-content-center text-medium"
      >
        {{ notificationText }}
      </div>
    </Transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AgentStatus } from "@/utils";

export default {
  name: "HoverButton",
  data() {
    return {
      AgentStatus,
      hover: false,
      showCloseButton: false,
    }
  },
  props: {
    icon: {
      type: String,
      default: "",
    },

    text: {
      type: String,
      default: ""
    },

    shouldShowCloseButton: {
      type: Boolean,
      default: true,
    },

    hoverColor: {
      type: String,
      default: "rgba(55, 55, 83, 0.3)",
    },

    textColor: {
      type: String,
      default: "#677C92"
    },

    backgroundColor: {
      type: String,
      default: "#E1E9F4"
    },

    keepButtonHovered: {
      type: Boolean,
      default: false,
    },

    notificationCounter: {
      type: Number,
      default: 0
    },

    disabled: {
      type: Boolean,
      default: false
    },

    active: {
      type: Boolean,
      default: false
    },

    buttonWidth: {
      type: String,
      default: "auto"
    }
  },

  computed: {
    ...mapGetters("system", {
      status: "getStatus",
    }),
    styleVariables() {
      let customColors = {
        '--hover-color': this.hoverColor,
        '--text-color': this.textColor,
        '--background-color': this.backgroundColor,
        '--opacity-multiplier': 0.3,
        '--button-width': this.buttonWidth
      };

      return customColors;
    },

    notificationText() {
      return this.notificationCounter < 10 ? this.notificationCounter : '9+';
    }
  },

  methods: {
    toogle(toogle) {
      this.hover = toogle;
    },
    verifyIfCloseButtonShouldBeDisplayed() {
      if(this.shouldShowCloseButton) {
        this.showCloseButton = !this.showCloseButton;
      }
    }
  },

  watch: {
    keepButtonHovered() {
      this.verifyIfCloseButtonShouldBeDisplayed();
    }
  }
}
</script>

<style lang="scss" scoped>
.transparency-hover-button {
  border: 0;
  line-height: 15px;
  padding: 0 4px;
  height: 32px;
  width: var(--button-width);
  border-radius: $border-radius;
  background-color: var(--background-color);
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    background-color: $color-text-light;
  }

  &.hovered-style {
    color: var(--color-text-light);
    background-color: var(--hover-color);
    background-blend-mode: multiply;
  }

  &:hover {
    color: var(--color-text-light);
    background-color: var(--hover-color);
    background-blend-mode: multiply;
  }

  &:disabled {
    color: var(--text-color);
    opacity: var(--opacity-multiplier);
    background-color: var(--background-color);
    mix-blend-mode: normal;
  }

  .custom-icon {
    width: 24px;
    height: 24px;
  }
}

.notification {
  top: -10px;
  right: -10px;
  width: 22px;
  height: 22px;

  background-color: var(--color-blue-300);
  color: $color-text-light;
  border-radius: 50%;
  line-height: 16px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
