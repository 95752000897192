<template>
  <div
    class="login"
  >
    <router-view name="main" />
    <div class="bg-cover" />
    <div class="card main-card" />
    <div class="first-retangle" />
    <div class="second-retangle" />
    <div class="third-retangle" />
    <div
      id="draggable-live-region"
      aria-atomic="true"
      aria-live="assertive"
      aria-relevant="additions"
      role="log"
      style="position: fixed; width: 1px; height: 1px; top: -1px; overflow: hidden"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  mounted() {},

  data() {
    return {
      fields: {
        user: "",
        password: "",
        token_type: "jwt",
      },

      emailToResetPassword: "",
    };
  },

  computed: {
    ...mapGetters("auth", {
      authenticated: "isAuthenticated",
    }),
  },

  methods: {
    ...mapActions("auth", ["login", "requestPasswordChange"]),

    reset() {
      this.requestPasswordChange({
        email: this.emailToResetPassword,
        new_front: true,
        domain: window.location.host.split('.')[0]
      });
    },

    submit() {
      this.$store.dispatch("auth/login", this.fields);
    },
  },
};
</script>

<style lang="scss" scoped>
  @keyframes firstMovement{
    0%{
      transform: translate(-10%, -5%) rotate(90deg) ;
    }
    50%{
      transform: translate(-40%, -60%) rotate(180deg) ;
    }
    100%{
      transform: translate(-10%, -5%) rotate(90deg) ;
    }
  }
  @keyframes secondMovement{
    0%{
      transform: translate(-10%, -5%) rotate(90deg) ;
    }
    50%{
      transform: translate(-10%, -40%) rotate(225deg) ;
    }
    100%{
      transform: translate(-10%, -5%) rotate(90deg) ;
    }
  }
  @keyframes thirdMovement{
    0%{
      transform: translate(-10%, -5%) rotate(90deg) ;
    }
    50%{
      transform: translate(20%, -80%) rotate(0deg) ;
    }
    100%{
      transform: translate(-10%, -5%) rotate(90deg) ;
    }
  }

.login {
  height: 100vh;
  .first-retangle, .second-retangle
  ,.third-retangle{
    position: fixed;
    z-index: 1048 !important;
    width: 30%;
    height: 30%;
  }
  .first-retangle {
    animation: firstMovement 10s linear infinite;
    top: -10%;
    left: -5%;
    border-radius: 16% 84% 87% 13% / 53% 20% 80% 47%;
    background: #2f81ed;
    background-image: linear-gradient(90deg, #56ccf280 9.2%, #2f80ed80 89.42%);
  }
  .second-retangle{
    animation: secondMovement 10s linear infinite;
    top: -10%;
    right: -5%;
    border-radius: 32% 68% 17% 83% / 0% 83% 17% 100% ;
    background: #ff1f7c;
    background-image: linear-gradient(90deg, #ff1f7b80 2.51%, #ff1f7b1f 70.03%)
  }
  .third-retangle{
    animation: thirdMovement 10s linear infinite;
    bottom: -10%;
    right: -5%;
    border-radius:32% 68% 31% 69% / 31% 62% 38% 69% ;
    background: #ffa851;
    background-image: linear-gradient(90deg, #ffe25980 2.51%, #ffa75180 70.03%)
  }
  .bg-cover, .main-card{
    position: fixed;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
  .bg-cover {
    z-index: 1048 !important;
    background-position: center center, 50%;
    background-repeat: no-repeat, no-repeat;
    background-color: #FFFFFF !important;
    background-image: linear-gradient(360deg, #FFFFFF 50%,#2f81ed34 100%);
  }
  .main-card{
    z-index: 1049 !important;
    border-radius: 0px !important;
    backdrop-filter: blur(84px) saturate(200%);
    -webkit-backdrop-filter: blur(84px) saturate(200%);
    background-color: rgba(255, 255, 255, 0.75);
    border: none;
  }
}
</style>
