export default {
  getLoadingFlags: state => state.loadingFlags,
  getAgents: state => state.agents,
  getAgentsPagination: state => state.agentsPagination,
  getAgentsParams: state =>
    Object.assign(
      {
        page: state.agentsPagination.current_page
      },
      state.agentsFilters
    )
};
