<template>
  <agent-side-bar v-if="user && isAgent && (currentWindowWidth >= 1200)" />
  <agent-top-bar v-if="user && isAgent && (currentWindowWidth >= 1200 || (!$route.path.startsWith('/whatsapp')) ) " />
  <div
    ref="view"
    class="main-view"
  >
    <router-view
      name="base"
    />
  </div>
  <mobile-menu
    v-if="showMobileMenu"
  />
  <show-message />
  <confirmation-modal />
  <too-many-requests429 />
  <nps-toast v-if="user && !isAdmin" />
  <defaulter-client-modal v-if="(isAgent || isSupervisor) && !isAdmin" />
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ShowMessage from "@components/show-message";
import TooManyRequests429 from "@/pages/error/too-many-requests-429.vue";
import AgentSideBar from "@whatsapp/components/agent-side-bar.vue";
import AgentTopBar from "@dialer/agent/components/top-bar/top-bar.vue";
import intercom from "@/mixins/intercom";
import NpsToast from "@/components/nps-toast.vue";
import ConfirmationModal from "./components/confirmation-modal.vue";
import DefaulterClientModal from "@components/defaulter-client-modal.vue";
import MobileMenu from "@components/mobile-menu.vue";
import resizeListener from "@/mixins/resizeListener";
import { elementObserverById } from "@/utils";

export default {
  name: "App",

  data() {
    return {
      pinger: null,
      currentWindowWidth: null,
    };
  },


  computed: {
    ...mapGetters("auth", {
      user: "getUser",
      isAuthenticated: "isAuthenticated",
      isAdmin: "isAdmin",
      isAgent: "isAgent",
      isSupervisor: "isSupervisor",
      isManager: "isManager",
    }),
    ...mapGetters("system", {
      socketIOActive: "getSocketIOActive",
    }),
    showMobileMenu() {
      if (this.currentWindowWidth && this.currentWindowWidth < 1200) {
        if (this.isAgent && !Object.keys(this.$route.params)?.length) {
          return true;
        }
        if (this.isAgent && Object.keys(this.$route.params)?.length) {
          return false
        }
        return !this.isAgent && !this.$route.path.startsWith("/whatsapp")
      }
      return false;
    },
  },

  components: {
    MobileMenu,
    DefaulterClientModal,
    ShowMessage,
    TooManyRequests429,
    NpsToast,
    ConfirmationModal,
    AgentTopBar,
    AgentSideBar,
  },

  mixins: [
    // Contains the intercom function that implements the customer service
    intercom,
    resizeListener,
  ],

  created() {
    this.fetchUserIp();
  },

  mounted() {
    this.setResizeCallback(this.handleResize);
    window.addEventListener("offline", this.setOffline);
    window.addEventListener("online", this.setOnline);
  },

  beforeUnmount() {
    clearInterval(this.pinger);
    window.removeEventListener("offline", this.setOffline);
    window.removeEventListener("online", this.setOnline);
  },

  methods: {
    ...mapActions("crm/funnels", ["fetchFunnels"]),
    ...mapMutations("system", ["setSocketIOActive"]),
    ...mapActions("crm/tour", ["fetchTourSteps"]),
    ...mapActions("auth", ["ping", "me", "fetchMyLicenses"]),
    ...mapActions("system", ["fetchUserIp"]),
    ...mapMutations("system", ["setIsOnline"]),

    setOffline() {
      this.setIsOnline(false)
    },
    setOnline() {
      this.setIsOnline(true)
    },
    ...mapMutations("system", [
      "setUserRects",
    ]),

    elementObserverById,

    async handleResize(e) {
      this.currentWindowWidth = e?.target?.outerWidth || e?.outerWidth;
      this.setUserRects({
        flag: "width",
        userRects: this.currentWindowWidth,
      });
      await this.setHeight();
    },

    async setHeight() {
      this.$refs.view.removeAttribute("style");
      const device = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|touch/i.test(navigator.userAgent);

      if (device || this.currentWindowWidth < 768) {

        const vh = window.innerHeight;
        const mobileMenu = await this.elementObserverById("mobile-menu");
        const mobileMenuHeight = mobileMenu?.clientHeight || 0;
        const finalHeight = vh - mobileMenuHeight;

        const value = `height: ${finalHeight}px !important; max-height: ${finalHeight}px !important; min-height: ${finalHeight}px !important; overflow: auto`
        this.setUserRects({
          flag: "height",
          userRects: finalHeight,
        });
        this.$refs.view.setAttribute("style", value);
      }
    },
  },

  watch: {
    isAuthenticated: {
      handler(val) {
        clearInterval(this.pinger)
        if (val) {
          this.me();
          if (this.isAgent) {
            if (!this.socketIOActive) this.setSocketIOActive(true);
            this.fetchFunnels();
            this.pinger = setInterval(() => {
              this.ping();
            }, 50000);
          } else if (this.isManager) {
            this.fetchTourSteps();
            this.fetchMyLicenses();
          }
        }
      },
      immediate: true,
    },

    $route: {
      handler(val) {
        this.$nextTick(async () => {
          if (val.path.startsWith("/whatsapp")) {
            this.$refs.view.removeAttribute("style");
            return;
          }

          if (!this.$refs.view.getAttribute("style")) {
            await this.setHeight();
          }
        });
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
.modal-backdrop,
.offcanvas-backdrop {
  background-color: rgba(68, 68, 68, 0.4) !important;
}

.modal {
  z-index: 1056 !important;
}

@import "./assets/scss/_variables.scss";

//dashkit vars
$path-to-fonts: "./assets/fonts";
$path-to-img: "./assets/img";

@import "./assets/scss/Dashkit-2.0.0/theme-3c.scss";
@import "./assets/scss/style.scss";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.intercom-lightweight-app {
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
}

.main-view {
  max-height: 100%;
  height: 100%;

  > * {
    max-height: 100%;
    height: 100%;
  }
}

</style>
