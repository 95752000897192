import store from "@store";

import IndexOfficeHours from "@dialer/manager/pages/settings/office-hours/index-office-hours";
import ListOfficeHours from "@dialer/manager/pages/settings/office-hours/list-office-hours";
import NewOfficeHours from "@dialer/manager/pages/settings/office-hours/new-office-hours";
import EditOfficeHpurs from "@dialer/manager/pages/settings/office-hours/edit-office-hours";

export default {
  path: "office-hours",
  name: "office-hours",
  components: {
    main: IndexOfficeHours
  },
  children: [
    {
      path: "",
      components: {
        "office-hours": ListOfficeHours
      }
    },
    {
      path: "new",
      components: {
        "office-hours": NewOfficeHours
      }
    },
    {
      path: ":id/edit",
      components: {
        "office-hours": EditOfficeHpurs
      }
    }
  ],
  beforeEnter: (to, from, next) => {
    if (store.getters["auth/isManager"]) next();
    else next({
      name: "not-found",
      params: {
        pathMatch: to.path.split('/').slice(1)
      }
    });
  }
};

