export default {
    startLoading: (state, flag) => state.loading[flag] = true,
    stopLoading: (state, flag) => state.loading[flag] = false,
    setDownloadCSVFilters: (state, filters) => state.downloadCSVFilters = filters,

    setList: (state, list) => state.list = list,
    setErrorMessage: (state, errorMesage) => state.errorMessage = errorMesage,
    setErrors: (state, errors) => state.errors = errors,
    clearErrors: (state) => {
        state.errors = {}
        state.errorMessage = ""
    },

    setPagination: (state, pagination) => state.pagination = pagination,
    setCampaignSchedules: (state, campaignSchedules) => state.campaignSchedules = campaignSchedules,

    setPaginationCampaignSchedules: (state, paginationCampaignSchedules) => state.paginationCampaignSchedules = paginationCampaignSchedules,
    startLoadingSchedules: state => state.loadingSchedules = true,
    stopLoadingSchedules: state => state.loadingSchedules = false,
}



