<template>
  <div
    class="summary-bar"
    :style="'height: ' + size + 'px;'"
    ref="bar"
    @click="click"
  >
    <div
      @click.prevent
      :class="{
        item: true,
        complet: i * (50 / 21) <= percentage && percentage > 0,
      }"
      v-for="(item, i) in data"
      :key="i"
      :style="
        'height: ' +
          (item / 100) * size +
          'px;  margin-top: ' +
          (((100 - item) / 100) * size) / 2 +
          'px;    width: ' +
          itemWidth +
          'px;margin-right: ' +
          space +
          'px;margin-left: ' +
          space +
          'px;'
      "
    />
  </div>
</template>

<script>
export default {

  /**
   *
   */
  emits: ["selectTime"],

  /**
   *
   */
  props: {

    /**
     *
     */
    size: {
      type: Number,
      default: 25,
    },

    /**
     *
     */
    itemWidth: {
      type: Number,
      default: 3,
    },

    /**
     *
     */
    space: {
      type: Number,
      default: 1,
    },

    /**
     *
     */
    data: {
      type: Array,
      default: () => new Array(42).fill(0),
    },

    /**
     *
     */
    percentage: {
      type: Number,
      default: 0,
    },
  },

  /**
   *
   */
  methods: {
    /**
     *
     */
    click(e) {
      var rect = e.currentTarget.getBoundingClientRect();
      var x = e.clientX - rect.left;
      this.$emit("selectTime", Math.floor(x / (this.$refs.bar.clientWidth / 100)));
    },
  },
};
</script>

<style lang="scss">
.summary-bar {
  background-color: transparent;
  cursor: pointer;
  .item {
    border-radius: 10px;
    float: left;
    background-color: #B1C2D9;
    &.complet {
      background-color: #2c7be5 !important;
    }
  }
}
</style>
