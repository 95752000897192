import axios from "axios";

export default {

  /**
   * Brings the general value from campaign metrics
   */
  loadCampaignsMetrics({ commit, getters }) {
    commit("startLoading", "loadCampaignsMetrics");

    return new Promise((resolve, reject) => {
      axios.get("/campaign/metrics/general", { params: getters.getCampaignsMetricsParams })
        .then((response) => {
          commit("setCampaignsMetrics", response.data.data);
          resolve();
        })
        .catch((error) => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "loadCampaignsMetrics");
        });
    });
  },

  setCampaignsMetricsFilters({ commit }, campaignsMetricsFilters) {
    commit("setCampaignsMetricsFilters", campaignsMetricsFilters);
  },

  /**
   * Brings the campaign metrics from today and yesterday
   * to populate the chart data
   */
  loadCampaignsMetricsChart({ commit, getters }) {
    commit("startLoading", "loadCampaignsMetricsCharts");

    return new Promise((resolve, reject) => {
      axios.get("/campaign/metrics/graphic", { params: getters.getCampaignsMetricsChartsParams })
        .then((response) => {
          commit("setCampaignsMetricsCharts", response.data.data);
          resolve();
        })
        .catch((error) => {
          commit('system/setErrorMessage', error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "loadCampaignsMetricsCharts");
        });
    });
  },

  setCampaignsMetricsChartsFilters({ commit }, campaignsMetricsChartsFilters) {
    commit("setCampaignsMetricsChartsFilters", campaignsMetricsChartsFilters);
  },
}
