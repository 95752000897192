<template>
  <div class="card-body d-flex flex-wrap justify-content-center">
    <ul
      class="p-0 col-12 d-flex flex-wrap"
    >
      <li
        class="col-12  border-bottom"
        v-for="(item, i) of copy_keys"
        :key="i"
      >
        <div class="d-flex flex-wrap col-12 bg-gray-blue-200 rounded card-key">
          <div class="col-lg-12 col-xl-7 pe-4 align-items-end d-flex flex-wrap">
            <label
              class="form-label
                          required"
            >{{ $t("capitalizes.keyboard_keys") }}</label>

            <div
              class="col-12 d-flex flex-wrap gap-2"
              :class="{
                'is-invalid': errors.hasOwnProperty(
                  'keys.' + i + '.key'
                ),
              }"
            >
              <button
                class="btn text-small btn-key col"
                type="button"
                v-for="(key, j) in keys_map"

                :class="{
                  'btn-key-selected': item.key === key.key,
                  'opacity-25': !key.avaliable && item.key !== key.key
                }"
                :disabled="!key.avaliable && item.key !== key.key"
                :key="j"
                @click="selectKey(i, j)"
              >
                {{ key.key }}
              </button>
            </div>

            <div class="invalid-feedback">
              {{
                errors.hasOwnProperty("keys." + i + ".key") &&
                  errors["keys." + i + ".key"][0]
              }}
            </div>
          </div>
          <div class="col">
            <div class="d-flex justify-content-between">
              <label
                class="form-label
                          required"
              >{{ $t("target_location") }}
              </label>

              <span
                class="fe fe-trash text-danger"
                v-if="i > 0"
                @click="removeKey(i)"
              />
            </div>
            <single-select
              :current-option="receptiveQueues.find((queue) => queue.id === item.id)?.name"
              :id="`keys${i}`"
              v-model="item.id"
              @selected-option="selectRedirect(i, $event.id)"
              label="name"
              :list-array="receptiveQueues"
            />

            <div class="invalid-feedback">
              {{
                errors.hasOwnProperty("keys." + i + ".id") &&
                  errors["keys." + i + ".id"][0]
              }}
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="d-flex align-items-center col-12">
      <div class="col p-0 pe-3">
        <hr>
      </div>

      <div class="col-auto">
        <button
          class="btn-light btn btn-sm px-5 tx-text-gray "
          :disabled="keys.length >= keys_map.length || disableNext"
          @click="addKey"
        >
          <i class="fa-solid fa-plus pe-2" />{{ $t("add_key") }}
        </button>
      </div>

      <div class="col p-0 ps-3">
        <hr>
      </div>
    </div>
  </div>

  <div class="footer-custom d-flex justify-content-center">
    <button
      class="btn px-4 col-6 h-auto py-1"
      :class="!disableNext ?'btn-primary':'btn-light' "
      :disabled="disableNext"
      @click="$emit('toggleCollapse')"
    >
      {{ $t("continue_to_advanced_settings") }}
    </button>
  </div>
</template>
<script>

import { mapGetters } from "vuex";
import SingleSelect from "@/components/single-select.vue"

export default {
  name: "KeysSettings",
  components: { SingleSelect },
  props: {
    keys: {
      type: Array,
      required: true,
    },
    redirectId: {
      type: Number,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    disableNext: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      copy_keys: this.keys,
      keys_map: [
        {
          key: this.$t("capitalizes.none_key"),
          avaliable: false,
        },
        {
          key: "1",
          avaliable: true,
        },
        {
          key: "2",
          avaliable: true,
        },
        {
          key: "3",
          avaliable: true,
        },
        {
          key: "4",
          avaliable: true,
        },
        {
          key: "5",
          avaliable: true,
        },
        {
          key: "6",
          avaliable: true,
        },
        {
          key: "7",
          avaliable: true,
        },
        {
          key: "8",
          avaliable: true,
        },
        {
          key: "9",
          avaliable: true,
        },
        {
          key: "0",
          avaliable: true,
        },
        {
          key: "*",
          avaliable: true,
        },
        {
          key: "#",
          avaliable: true,
        },
      ],
    }
  },
  computed: {
    ...mapGetters("manager/filters", {
      receptiveQueues: "getReceptiveQueues",
      loadingFlags: "getLoadingFlags",
    }),
  },
  methods: {
    selectKey(index, index_keys_map) {
      if (this.copy_keys[index].key != this.keys_map[index_keys_map].key)
        this.keys_map.map((key) => {
          if (key.key == this.copy_keys[index].key) {
            key.avaliable = true;
          }
        });
      this.keys_map[index_keys_map].avaliable = false;

      this.copy_keys[index].key = this.keys_map[index_keys_map].key;
      this.$emit("update:keys", this.copy_keys);

      if (index_keys_map === 0 || this.keys_map[0].avaliable) {
        this.$emit("update:redirect-id", this.keys_map[0].avaliable ? "" : this.copy_keys[index].id);
      }
    },
    selectRedirect(index, value) {
      this.copy_keys[index].id = value;
      this.$emit("update:keys", this.copy_keys);

      if (this.copy_keys[index].key === this.$t("capitalizes.none_key") || this.keys_map[0].avaliable) {
        this.$emit("update:redirect-id", this.keys_map[0].avaliable ? "" : value);
      }
    },
    removeKey(index) {
      const keyToRemove = this.copy_keys[index].key;

      if (keyToRemove) {
        this.keys_map.forEach(key => {
          if (key.key === keyToRemove) key.avaliable = true;
        });
      }
      this.copy_keys.splice(index, 1);
      this.$emit("update:keys", this.copy_keys);
      if (this.keys_map[0].avaliable) {
        this.$emit("update:redirect-id", "")
      }
    },
    addKey() {
      this.copy_keys.push({
        id: "",
        key: "",
        type: "ReceptiveQueue",
      });
      this.$emit("update:keys", this.copy_keys);
    },
  },
  emits: ["update:keys", "update:redirect-id", "toggleCollapse"],


  watch: {
    keys: {
      handler(val) {
        this.copy_keys = val;

        this.keys_map.forEach((key) => {
          val.map((item) => {
            if (key.key == item.key) {
              key.avaliable = false;
            }
          });
        });
      },
      immediate: true,
    },
  },
}
</script>

<style scoped lang="scss">
.btn-key {
  background-color: $color-text-light;
  border-color: $color-gray-200;
  border-radius: 8px;
  padding: 0.5rem;

  &:hover {
    background-color: $color-blue-100;
    border-color: $color-blue-100;
    color: $color-text-light;
  }
}

ul {
  list-style-type: none;
}

ul li:first-child {
  padding-top: 0;
}

ul li:last-child {
  border-bottom: none !important;
}

li {
  list-style-type: none;
  padding: 1rem 0 1rem 0;
}

.btn-key-selected {
  background-color: $color-blue-200;
  border-color: $color-blue-200;
  color: $color-text-light;
}

.card-key {
  padding: 1rem 1.5rem 1rem 1.5rem;
}

.card-key .fe-trash {
  visibility: hidden;
  cursor: pointer;
}

.card-key:hover .fe-trash {
  visibility: visible;
}

.footer-custom {
  padding: 1.5rem 0 1.5rem 0;
  border-top: 1px solid $color-gray-blue-300 !important;
}

hr {
  border-top: 1px solid $color-gray-blue-300 !important;
}

.border-bottom {
  border-bottom: 1px solid $color-gray-blue-300 !important;
}

.btn-light:hover {
  background-color: $color-gray-200;
}
</style>