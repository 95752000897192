import axios from "axios";

export default {
  metrics({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading", "metrics");
    commit("setListMetrics", []);

    return new Promise((resolve, reject) => {
      axios
        .get(`/campaigns/${payload.campaignId}/lists/metrics`, {
          params: {
            start_date: payload.startDate,
            end_date: payload.endDate,
            agent_id: payload.agentId,
            page: payload.page,
            'trashed[0]': 'campaign'
          }
        })
        .then(response => {
          commit("setListMetrics", response.data.data);
          commit('setPagination', response.data.meta.pagination);
          resolve();
        })
        .catch(response => {
          response.response.status == 422 &&
          response.response.data.errors &&
          commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading", "metrics");
        });
    });
  },
  metricsTotal({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading", "metricsTotal");
    commit("setTotalMetrics", []);

    return new Promise((resolve, reject) => {
      axios
        .get(`/campaigns/${payload.campaignId}/lists/total_metrics`, {
          params: {
            start_date: payload.startDate,
            end_date: payload.endDate,
            page: payload.page,
            'trashed[0]': 'campaign'
          }
        })
        .then(response => {
          commit("setTotalMetrics", response.data.data);
          resolve();
        })
        .catch(response => {
          response.response.status == 422 &&
          response.response.data.errors &&
          commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading", "metricsTotal");
        });
    });
  },
  searchQualifications({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading", "searchQualifications");
    commit("setQualificationList", []);

    return new Promise((resolve, reject) => {
      axios
        .get(`/campaigns/${payload.campaignId}/lists/qualifications`, {
          params: {
            start_date: payload.startDate,
            end_date: payload.endDate,
            page: payload.page,
            'trashed[0]': 'campaign'
          }
        })
        .then(response => {
          commit("setQualificationList", response.data.data);
          resolve();
        })
        .catch(response => {
          response.response.status == 422 &&
          response.response.data.errors &&
          commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading", "searchQualifications");
        });
    });
  },
  downloadTable({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading", "downloadTable");

    return new Promise((resolve, reject) => {
      axios
        .get(`/campaigns/${payload.campaignId}/lists/metrics`, {
          params: {
            start_date: payload.startDate,
            end_date: payload.endDate,
            csv: payload.csv,
            email: payload.email,
          }
        })
        .then(() => {
          commit("system/setSuccessMessage", "success_message.generate_report", { root: true });
          resolve();
        })
        .catch(response => {
          commit('system/setErrorMessage', 'Houve um problema ao gerar o relatório. Verifique a campanha e tente novamente mais tarde', { root: true })
          response.response.status == 422 &&
          response.response.data.errors &&
          commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading", "downloadTable");
        });
    });
  }
};
