export default {
    errorsFlag: {
        receptiveNumber: {}
    },
    loading: false,
    alert: null,
    errors: {},
    list: [],
    filters: {},
    register: null,
    paginator: {}
}
