export default {
  loadingFlags: {
    findUnderReviewMailings: false,
    findFinalizedReviewMailings: false,
    downloadMailingSms: false,
    sendFinalizeMailing: false,
    downloadFinalizedMailingSms: false,
    blockCompanySMS: false,
  },
  errors: {},
  errorMessage: "",
  includes: [],
  underReviewMailings: [],
  finalizedReviewMailings: [],
  
  underReviewTotalMailings: null,
  finalizedReviewTotalMailings: null,

  underReviewFilters: {
    companies: []
  },
  finalizedReviewFilters: {
    companies: []
  },

  underReviewPagination: {
    per_page: 12,
    current_page: 1
  },
  finalizedReviewPagination: {
    per_page: 12,
    current_page: 1
  },

  register: null,

}