<template>
  <div
    class="progress progressbar-multiple"
    ref="bar"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
    :class="{
      'overflow-visible': isHovered
    }"
  >
    <div
      v-for="(item, i) in data"
      :key="i"
      class="progress-bar"
      :style="styles(item)"
    />
  </div>
</template>
<script>
import { Tooltip } from "bootstrap";

import {darkenColor} from "@/utils";

export default {
  mounted() {
    this.generateTootlip();
  },

  data() {
    return {
      tooltip: null,
      hovered: false,
    };
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
    useIcon: {
      type: Boolean,
      default: true,
    },

    hoverEffect: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    isHovered() {
      return this.hoverEffect && this.hovered;
    }
  },

  methods: {
    generateTootlip() {
      let tooltip = document.createElement("div");
      for (let [index, item] of this.data.entries()) {
        let tooltipItem = document.createElement("div");
        tooltipItem.style.display = "flex";
        tooltipItem.style.alignItems = "center";
        if (index !== 0) {
          tooltipItem.style.paddingTop = "8px";
        }
        if (index !== this.data.length - 1) {
          tooltipItem.style.paddingBottom = "8px";
          tooltipItem.style.borderBottom = "1px solid #F4F4F4";
        }

        if (this.useIcon) {
          tooltipItem.innerHTML = `
            <div class="d-flex align-items-center justify-content-between flex-grow-1">
              <div class="me-4">
                <span style="color: ${item.color}">
                  <span class="fe ${item.icon} me-1"></span>
                </span>
                <span>${item.title}</span>
              </div>
              <span>${item.count} (${parseFloat(item.percentage).toFixed(2)}%)</span>
            </div>
          `;
        } else {
          tooltipItem.innerHTML = `
            <div class="d-flex align-items-center justify-content-between">
              <div class="me-4">
                <span class="me-1" style='color:${item.color}'>
                  <span style='display: inline-block; background-color: ${item.color}; width: 10px; height: 10px; border-radius: 50%;'></span>
                </span>
                <span>
                  ${item.title}
                </span>
              </div>

              <span>
                ${item.count} (${parseFloat(item.percentage).toFixed(2)}%)
              </span>
            </div>
          `
        }
        tooltip.appendChild(tooltipItem);
      }
      this.tooltip = new Tooltip(this.$refs.bar, {
        html: true,
        container: "body",
        title: tooltip,
      });
    },

    styles(item) {
      const customStyles = {
        width: `${item.percentage}%`,
        backgroundColor: item.color,
      }
      if(this.isHovered) {
        customStyles.transform = `scale(1.03)`;
        customStyles.backgroundColor = darkenColor(item.color, 20);
      }

      return customStyles;
    }
  },
};
</script>


<style lang="scss">
.tooltip-inner {
  max-width: 1000px !important; //define whatever width you want
  background: #FFFFFF !important;
  border: 1px solid #E3EBF6 !important;
  border-radius: 6px !important;
  box-shadow: 0px 8px 18px rgba(31, 45, 61, 0.07);
  font-size: 14px;
  padding: 16px;
}

.progress {
  &.progressbar-multiple {
    .progress-bar {
      transition: all 0.25s ease;
    }
  }
}
</style>
