<template>
  <vue-multiselect
    v-model="hangupCause"
    :options="options"
    :track-by="'id'"
    :label="'text'"
    :placeholder="$t('select_ending_cause')"
    :select-label="$t('to_select')"
    :selected-label="$t('selected')"
    :deselect-label="$t('remove_capitalize')"
    :disabled="loadingFlags.loadHangupCausesDataSelect"
    :custom-label="hangupCauseLabel"
    @select="updateValue"
    @remove="removeValue"
  />
</template>

<script>
import { mapActions, mapGetters  } from "vuex";
import VueMultiselect from "vue-multiselect";

export default {
  data() {
    return {
      hangupCause: "",
      options: [],
    }
  },

  /**
   *
   */
  emits: ["update:modelValue"],

  /**
   *
   */
  mounted() {
    this.loadHangupCausesDataSelect().then(() => {
      this.options = Object.values(this.dataSelect);
    });
  },

  /**
   *
   */
  computed: {
    /**
     *
     */
    ...mapGetters("manager/calls-report", {
      dataSelect: "getHangupCausesDataSelect",
      loadingFlags: "getLoadingFlags",
    }),
  },

  /**
   *
   */
  props: {
    /**
     *
     */
    modelValue: {
      type: [Number, String],
      default: 0,
    },
  },

  /**
   *
   */
  components: {
    VueMultiselect
  },

  /**
   *
   */
  methods: {
    /**
     *
     */
    ...mapActions("manager/calls-report", ["loadHangupCausesDataSelect"]),

    /**
     *
     */
     updateValue(value) {
      this.$emit('update:modelValue', value.id);
    },

    /**
     *
     */
    removeValue() {
      this.$emit('update:modelValue', '');
    },

    /**
     *
     */
    hangupCauseLabel({id, text}) {
      return `${id} - ${text}`;
    }
  }
};
</script>
