export default {
    loading: false,
    loadingContext: "", //list, schedule, exit
    list: [],
    errorMessage: "",
    errors: {},
    IntervalActive: null,
    scheduledWorkBreak: false,
    expired: false,

    loadingFlags: {
      fetchWorkBreakIntervals: false,
      scheduleWorkBreak: false,
      requestIntervalReturn: false
    },
    workBreakIntervals: [],
    returnRequested: false
  };
