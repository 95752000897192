<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="modalHandleScheduledCalls"
    ref="modalHandleScheduledCalls"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="modalHandleScheduledCallsLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4
            class="modal-title"
            id="modalHandleScheduledCallsLabel"
          >
            {{ $t('attention') }}
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div class="modal-body">
          {{ $t('the_user') }} {{ name }} {{ $t('have') }} {{ scheduledCalls }} {{ scheduledCalls > 1 ? $t('scheduled_calls') : $t('scheduled_call') }}
          <div class="form-check mt-4">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              value="discard"
              v-model="scheduleAction"
            >
            <label
              class="form-check-label"
              for="flexRadioDefault1"
            >
              {{ $t('discard_user') }} {{ name }}
            </label>
          </div>
          <div class="form-check mb-3">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              value="transfer"
              v-model="scheduleAction"
            >
            <label
              class="form-check-label"
              for="flexRadioDefault2"
            >
              {{ $t('transfer_appointment') }}
            </label>
          </div>

          <div v-if="scheduleAction === 'transfer'">
            <label class="form-label">{{ $t('select_user') }}:</label>
            <VueMultiselect
              v-model="userToTransfer"
              :options="availableUsers"
              :placeholder="''"
              track-by="name"
              label="name"
              open-direction="bottom"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            {{ $t('cancel_general') }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="disable_()"
          >
            {{ $t('disable_user') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import VueMultiselect from "vue-multiselect";
import { Modal } from "bootstrap";

export default {
  data() {
    return {
      scheduleAction: "discard",
      userToTransfer: "",
      scheduledCalls: 0,
      availableUsers: [],
      modal: null
    };
  },
  props: {
    name: {
      type: String,
      required: true,
      default: "",
    },
  },
  components: {
    VueMultiselect,
  },
  methods: {
    ...mapActions("manager/user", ["disable"]),
    disable_() {
      let remove_schedules = this.scheduleAction === "discard" ? true : false;
      let user_id = this.userToTransfer.id && this.userToTransfer.id;

      this.disable({
        remove_schedules: remove_schedules,
        user_id: user_id,
        id: this.register.id,
      }).then(() => {
        this.modal.toggle();
        this.$router.push({
          path: `/manager/users/`,
        });
      });
    },
  },
  computed: {
    ...mapGetters("manager/user", {
      errors: "getErrors",
      register: "getRegister",
    }),
  },
  watch: {
    errors({ schedules, users }) {
      if (schedules && users.length) {
        this.scheduledCalls = schedules;
        this.availableUsers = users.filter((el) => el.name !== this.name );

        this.modal = new Modal(this.$refs.modalHandleScheduledCalls);
        this.modal.toggle();
      }
    },
    scheduleAction(action) {
      if (action === "discard") this.userToTransfer = "";
    },
  },
};
</script>

<style>
</style>
