<template>
  <div class="col-12">
    <header-component
      :title="$route.params.id ? $t('edit') + ' webhook' : $t('new') +' webhook'"
      :pretitle="$route.params.id ? $t('edit_uppercase') +' WEBHOOK' : $t('edit_uppercase') +' WEBHOOK'"
      :redirect-link="'/manager/integration'"
    />
    <div class="mb-3">
      <label
        for="exampleFormControlInput1"
        class="form-label"
      >URL</label>
      <input
        class="form-control mb-1"
        id="exampleFormControlInput1"
        :placeholder="$t('complete_url')"
        v-model="fields.url"
        :class="{
          'invalid-border': errors
        }"
        @focusout="handleUrlValidation"
      >
      <p
        class="text-muted"
        :class="{
          'invalid-color': errors
        }"
      >
        {{ $t("url_events") }}
      </p>
    </div>

    <div class="card">
      <div class="card-header">
        <h4 class="m-0">
          {{ $t("events_capitalize") }}
        </h4>
      </div>
      <div class="card-body px-0 py-0">
        <ul class="list-group">
          <li
            class="list-group-item"
          >
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="callWasConnected"
                v-model="fields.events"
                value="call-was-connected"
              >
              <label
                class="form-check-label"
                for="callWasConnected"
              >
                call-was-connected
              </label>
            </div>
          </li>
          <li
            class="list-group-item"
          >
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="callHistoryWasConnected"
                v-model="fields.events"
                value="call-history-was-created"
              >
              <label
                class="form-check-label"
                for="callHistoryWasConnected"
              >
                call-history-was-created
              </label>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <app-button
      class="col-5"
      @click="createNewWebhook"
      v-if="!$route.params.id"
    >
      {{ $t("capitalizes.save") }}
    </app-button>

    <app-button
      class="col-5"
      @click="update"
      v-else
    >
      {{ $t("capitalizes.save") }}
    </app-button>

    <button
      class="btn col-3 tx-text-gray mt-3"
      @click="$router.push('/manager/integration')"
    >
      {{ $t("back_capitalize") }}
    </button>
  </div>
</template>

<script>
import HeaderComponent from "@/components/header-component.vue";
import AppButton from "@/components/app-button.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    HeaderComponent,
    AppButton,
  },

  data() {
    return {
      fields: {
        url: "",
        events: [],
        active: true,
      },
      errors: false,
    }
  },

  mounted() {
    if (this.$route.params.id) {
      this.fetchWebhookById(this.$route.params.id)
    }
  },

  computed: {
    ...mapGetters("manager/integration", {
      webhooks: "getWebhooks",
      loadingFlag: "getLoadingFlag",
    }),
  },

  methods: {
    ...mapActions("manager/integration", [
      "createWebhook",
      "fetchWebhookById",
      "fetchWebhooks",
      "updateWebhook",
    ]),

    createNewWebhook() {
      this.createWebhook({ fields: this.fields }).then(() => {
        this.$router.push("/manager/integration")
      })
    },

    update() {
      this.fields.id = this.$route.params.id;
      this.updateWebhook(this.fields).then(() => {
        this.$router.push("/manager/integration")
      })
    },

    handleUrlValidation() {
      this.errors = false;

      const pattern = /^(https:\/\/|http:\/\/)/;
      let isUrl = pattern.test(this.fields.url);

      if (!isUrl) {
        this.errors = true;
      } else this.errors = false
    },
  },

  watch: {
    webhooks(value) {
      if (value.url !== undefined) {
        this.fields.url = value.url;
        this.fields.events = value.events.split(",");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.form-check-input[type="checkbox"] {
  border-radius: 0.3rem;
}

.invalid-border {
  border-color: red;
}

.invalid-color {
  color: red !important;
}
</style>
