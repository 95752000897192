<template>
  <ul
    class="list-group funnels-list"
    style="padding: 12px"
  >
    <li
      v-if="!funnels"
      class="list-group-item"
    >
      {{ $t('team_not_any_funnel') }}
    </li>
    <li
      v-for="funnel in funnels"
      :key="funnel.id"
      class="list-group-item funnel-item d-flex justify-content-between align-items-center"
      @click="attachOnFunnel(funnel.id)"
    >
      <span>{{ funnel.name }}</span>
      <div
        v-if="selectedFunnel === funnel.id && loadingFlags.createFunnelAttach"
        class="spinner-border spinner-border-sm"
      />
    </li>
  </ul>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
  data(){
    return{
      selectedFunnel: null,
    }
  },

  computed:{
    ...mapGetters("whatsapp/crm", {
      funnels: "getFunnels",
      loadingFlags: "getLoadingFlags"
    }),
    ...mapGetters("whatsapp/chat", {
      openChat: "getOpenChat"
    })
  },

  methods:{
    ...mapActions("whatsapp/crm", ["createFunnelAttach"]),

    attachOnFunnel(funnelId){
      if(this.loadingFlags.createFunnelAttach) return
      this.selectedFunnel = funnelId
      this.createFunnelAttach({
        phoneNumber: this.openChat.number,
        funnelId: funnelId,
      }).finally(()=> {
        this.selectedFunnel = null
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .funnel-item {
    cursor: pointer;
    border: none;
    border-radius: 0;
    padding: 5px 0 5px 20px;
    border-left: 2px solid $color-gray-blue-300;
    &:hover{
      border-left: 2px solid $color-blue-300;
      color: $color-blue-300;
    }
  }

  .funnels-list {
    overflow: hidden auto;
    max-height: 300px;
  }
</style>
