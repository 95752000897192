<template>
  <table-intervals-report>
    <template #header>
      <div class="row align-items-center">
        <div class="col">
          <h4 class="card-header-title">
            {{ $t("breaks_list") }}
          </h4>
        </div>
        <div class="col-auto">
          <button
            class="btn-primary btn text-small"
            @click="$emit('openNew')"
            style="height: 32px;line-height:0;padding: 16px;"
          >
            {{ $t("add_break") }}
          </button>
        </div>
      </div>
    </template>
    <template #tableHead>
      <tr>
        <th
          v-for="item in tableHeader"
          :key="item"
        >
          {{ item }}
        </th>
      </tr>
    </template>
    <template #tableBody>
      <template v-if="intervals.length">
        <tr
          v-for="item in intervals"
          :key="item.id"
          :id="item.id"
        >
          <td>
            <div class="d-flex align-items-center justify-content-center">
              <dot-status
                :color="item.color"
                size="12px"
              />
            </div>
          </td>
          <td>
            {{ item.name }}
          </td>
          <td class="tx-text-gray">
            {{ item.id }}
          </td>
          <td class="tx-text-gray">
            {{ item.minutes }}
          </td>
          <td class="tx-text-gray">
            {{ item.limit }}
          </td>
          <td class="tx-text-gray">
            {{ convertType(item.type) }}
          </td>
          <td>
            <i
              @click="$emit('openEdit', item)"
              class="fa-regular fa-pen-to-square me-3 cursor-pointer"
            />
            <i
              @click="remove(item)"
              class="fa-regular fa-trash text-danger cursor-pointer"
            />
          </td>
        </tr>
      </template>
      <tr v-else>
        <td
          class="text-center text-muted"
          colspan="12"
        >
          <span class="fe fe-alert-octagon" />
          {{ $t("no_break") }}
        </td>
      </tr>
    </template>
  </table-intervals-report>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DotStatus from "@/components/dot-status";
import TableIntervalsReport from "@dialer/manager/pages/reports/intervals/components/table-intervals-report.vue";

export default {
  emits: ["openNew", "openEdit"],
  components: {
    TableIntervalsReport,
    DotStatus
  },
  props: {
    intervals: {
      default: () => {
        return [];
      },
      type: Array
    }
  },
  data() {
    return {
      tableHeader: [
          this.$t("uppercases.color"),
          this.$t("uppercases.name"),
          this.$t("acronym.id"),
          this.$t("minutes_uppercase"),
          this.$t("limit_per_dar_uppercase"),
          this.$t("uppercases.interval_type"),
          ""
        ]
    };
  },
  computed: {
    ...mapGetters("manager/intervals", {
      workBreakGroup: "getWorkBreakGroup"
    })
  },
  methods: {
    ...mapActions("manager/intervals", ["removeInterval", "findWorkBreakGroupById"]),
    ...mapActions("system", ["showConfirmationMessage"]),

    convertType(type) {
      const types = {
        productive: this.$t("productive"),
        unproductive: this.$t("unproductive"),
        NR17: this.$t("NR17")
      };
      return types[type] || this.$t("capitalizes.undefined");
    },

    async remove(item) {
      let response = await this.showConfirmationMessage({
        title: this.$t("delete_breaks_question"),
        text: this.$t("delete_breaks_confirmation"),
        type: "badWarning"
      });

      if (response.isConfirmed) {
        await this.removeInterval({
          list_id: this.$route.params.id,
          id: item.id
        });

        this.findWorkBreakGroupById({ id: this.workBreakGroup.id, include: ["intervals"] });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
th {
  &:first-child {
    text-align: center;
  }
}

td {
  height: 40px !important;
  padding: 8px 16px !important;

  .fa-pen-to-square {
    color: var(--color-text-gray);
    &:hover {
      color: var(--color-blue-300);
    }
  }
}
</style>
