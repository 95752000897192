<template>
  <div class="icon-card d-flex align-items-center justify-content-center rounded">
    <i
      class="tx-blue-300"
      :class="variant"
    />
  </div>
</template>

<script>
export default {
  name: "IconCard",

  props: {
    variant: {
      type: String,
      required: true,
    }
  }
}
</script>
<style scoped lang="scss">
.icon-card {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  background-color: $color-gray-blue-300;
}
</style>