<template>
  <div
    class="select-image"
    v-show="show"
    @click.stop
  >
    <div class="emojiBox">
      <emoji-picker
        class="light"
        data-source="/static/emojis-pt-BR.json"
        ref="emojiBox"
      />
    </div>
  </div>
</template>
<script>
import {EmojiPicker} from "emoji-picker-element";
import ptBr from "emoji-picker-element/i18n/pt_BR";
import en from "emoji-picker-element/i18n/en";
import es from "emoji-picker-element/i18n/es";

export default {
  /**
   *
   */
  emits: ["setEmoji", "close"],

  /**
   *
   */
  mounted() {
    this.$refs.emojiBox.addEventListener("emoji-click", this.emojiClick);
    window.addEventListener("click", this.outsideClick);
    switch (this.$i18n.locale) {
      case 'pt':
        this.$refs.emojiBox.i18n = ptBr;
        break;
      case 'en':
        this.$refs.emojiBox.i18n = en;
        break;
      case 'es':
        this.$refs.emojiBox.i18n = es;
        break;
      default:
        break;
    }
    //change border radius
    // this.$refs.emojiBox.i18n = ptBr;
    let style = document.createElement("style");
    style.textContent = `.picker { border-radius: 10px }`;
    this.$refs.emojiBox.shadowRoot.appendChild(style);
  },

  /**
   *
   */
  beforeUnmount() {
    window.removeEventListener("click", this.outsideClick);
  },

  /**
   * props
   */
  props: {
    /**
     * modal show prop
     */
    show: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    EmojiPicker,
  },

  /**
   *
   */
  methods: {
    /**
     *
     */
    outsideClick() {
      this.$emit("close");
    },

    /**
     *
     */
    emojiClick(event) {
      this.$emit("setEmoji", event.detail.unicode);
    },
  },
};
</script>

<style lang="scss" scoped>

emoji-picker {
  --border-color: #d2ddec;
}

.select-image {
  position: relative;

  .emojiBox {
    z-index: 999;
    overflow: hidden;
    position: absolute;
    top: -412px;
    right: 92px;
  }
}

.quote-open {
  .emojiBox {
    top: -312px;
  }
}

@media only screen and (max-width: 1199.999px) {
  .select-image {
    .emojiBox {
      right: auto;
      top: auto;
      bottom: calc(100% + 6px);
      left: 50%;
      transform: translateX(-50%) scale(0.8);
      z-index: 1002;
    }
  }
}
</style>
