<template>
  <div
    ref="tooltip_teams"
    class="cursor-pointer d-inline-block"
  >
    <button
      class="btn tooltip-button"
      @click="removeTooltip"
    >
      {{ data.length }} {{ $t("teams") }}
    </button>
  </div>
</template>

<script>
import { Tooltip } from "bootstrap";

export default {
  data() {
    return {
      tooltip: null
    };
  },

  mounted() {
    this.update();
  },

  props: {
    count: {
      type: Number,
      default: 1
    },

    data: {
      type: Array,
      default: () => []
    }
  },

  watch: {
    data() {
      this.update();
    }
  },
  methods: {
    update() {
      let tooltip = document.createElement("div");
      for (let item of this.data) {
        let tooltipItem = document.createElement("div");
        tooltipItem.innerHTML = `
            <div>
              <span class="d-flex align-items-center">
                <span
                  style='
                    display: inline-block;
                    background-color: ${item.color};
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                  '
                />
              </span>
              <strong class='ms-1'>${item.name}</strong>
            </div>
            `;

        tooltip.appendChild(tooltipItem);
        this.tooltip = new Tooltip(this.$refs.tooltip_teams, {
          html: true,
          container: "body",
          title: tooltip,
          placement: "auto",
          trigger: "hover",
        });
      }
    },
    removeTooltip() {
      let tooltip = document.querySelector("[role='tooltip']");
      tooltip.remove();
    }
  }
};
</script>

<style lang="scss">
.tooltip-button {
  padding: 0 !important;
}
</style>
