<template>
  <modal-component
    @close="showModal = false"
    :show="showModal"
    :center="true"
    size="modal-md"
  >
    <template
      #content
    >
      <div
        class="modal-dialog modal-dialog-centered m-0"
        style="max-width: 100%;"
      >
        <div class="modal-content">
          <div
            class="modal-header border-0 p-0"
            style="margin: 16px;"
          >
            <h4 class="w-100 text-start m-0">
              Excluir rota
            </h4>
            <button
              type="button me-2"
              class="close-button"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i
                class="fa-solid fa-x pb-3 tx-text-gray"
                style="font-size: 10px;"
              />
            </button>
          </div>
          <hr
            class="m-0"
            style="color:$color-gray-blue-300;"
          >
          <div
            class="modal-body text-center"
            style="padding: 40px;"
          >
            <h2>Tem certeza que deseja excluir a rota?</h2>
            <h4
              class="fw-normal m-0 tx-text-200"
              style="padding-bottom: 40px;"
            >
              A rota será excluída permanentemente
            </h4>

            <button
              class="btn btn-danger mb-4"
              style="background-color: #F23F2C !important; padding: 8px 180px;"
              @click="removeRoute"
            >
              Sim
            </button>
            <h5
              @click="showModal = false"
              class="cursor-pointer fw-normal tx-text-gray"
            >
              Cancelar
            </h5>
          </div>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ModalComponent from "@/components/modal-component.vue";

export default {
  components: {
    ModalComponent
  },

  data() {
    return {
      route: null,
      showModal: false,
    };
  },

  computed: {
    ...mapGetters("admin/telecom", {
      loadingFlags: "getLoadingFlags",
      errors: "getErrors"
    }),
  },

  methods: {
    ...mapActions("admin/telecom", [
      "deleteRoute"
    ]),

    ...mapMutations("auth", ["trackEvents"]),

    openModal(route) {
      this.route = route;
      this.showModal = true;
    },

    removeRoute() {
      this.deleteRoute(this.route);
      this.showModal = false;
      this.trackEvents("[Admin] Excluded Telephony");
    },
  },
};
</script>

<style lang="scss" scoped>

.close-button {
  border: none;
  background-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}


</style>
