export default {
  startLoading: (state, flag) => state.loadingFlags[flag] = true,
  stopLoading: (state, flag) => state.loadingFlags[flag] = false,

  // Active Companies
  setActiveCompanies: (state, activeCompanies) => state.activeCompanies = activeCompanies,
  setActiveCompaniesFilters: (state, filters) => state.activeCompaniesFilters = filters,
  setActiveCompaniesPagination: (state, pagination) => state.activeCompaniesPagination = pagination,
  setActiveCompaniesIncludes: (state, includes) => state.activeCompaniesIncludes = includes,
  setActiveCompaniesCurrentPage: (state, page) => state.activeCompaniesPagination.current_page = page,
  setProModeCompanies: (state, proModeCompanies) => state.proModeCompanies = proModeCompanies,

  // Blocked Companies
  setBlockedCompanies: (state, blockedCompanies) => state.blockedCompanies = blockedCompanies,
  setBlockedCompaniesFilters: (state, filters) => state.blockedCompaniesFilters = filters,
  setBlockedCompaniesPagination: (state, pagination) => state.blockedCompaniesPagination = pagination,
  setBlockedCompaniesIncludes: (state, includes) => state.blockedCompaniesIncludes = includes,
  setBlockedCompaniesCurrentPage: (state, page) => state.blockedCompaniesPagination.current_page = page,

  // Trashed Companies
  setTrashedCompanies: (state, trashedCompanies) => state.trashedCompanies = trashedCompanies,
  setTrashedCompaniesFilters: (state, filters) => state.trashedCompaniesFilters = filters,
  setTrashedCompaniesPagination: (state, pagination) => state.trashedCompaniesPagination = pagination,
  setTrashedCompaniesIncludes: (state, includes) => state.trashedCompaniesIncludes = includes,
  setTrashedCompaniesCurrentPage: (state, page) => state.trashedCompaniesPagination.current_page = page,

  setListOfCompaniesWithoutPagination: (state, listOfCompaniesWithoutPagination) => state.listOfCompaniesWithoutPagination = listOfCompaniesWithoutPagination,
  setErrors: (state, errors) => { state.errors = errors; },
  clearErrors: (state) => {
    state.errors = {}
    state.errorMessage = ""
  },
  setRemoveCompanyErrors: (state, removeCompanyErrors) => { state.removeCompanyErrors = removeCompanyErrors },
  clearRemoveCompanyErrors: (state) => {
    state.removeCompanyErrors = {}
  },

  setRemoveCompanyDomain: (state, domain) => state.removeCompanyDomain = domain,
  setCurrentTrashedCompanyPage: (state, trashedPage) => state.trashedCompaniesPagination.current_page = trashedPage,
  setTrashedFilters: (state, trashedFilters) => state.trashedFilters = trashedFilters,
  setCompanyCreditFilters: (state, companyCreditFilters) => state.companyCreditFilters = companyCreditFilters,
  setRegister: (state, register) => state.register = register,
  setCompanyHistory: (state, companyHistory) => state.companyHistory = companyHistory,
  setCompanyHistoryFilters: (state, companyHistoryFilters) => state.companyHistoryFilters = companyHistoryFilters,
  setCompanyHistoryPagination: (state, companyHistoryPagination) => state.companyHistoryPagination = companyHistoryPagination,
  setCompanyHistoryCurrentPage: (state, page) => state.companyHistoryPagination.current_page = page,
  setBlockCompaniesParams: (state, blockCompaniesParams) => state.blockCompaniesParams = blockCompaniesParams,
  setTotalLogged: (state, totalLogged) => state.totalLogged = totalLogged,
  setMaxLogin: (state, totalMaxLogin) => state.totalMaxLogin = totalMaxLogin,
  setCallStats: (state, callStats) => state.callStats = callStats,

  // Receptive Numbers
  setReceptiveNumbersList: (state, receptiveNumbersList) => state.receptiveNumbersList = receptiveNumbersList,
  setReceptiveNumbersCurrentPage: (state, page) => state.receptiveNumbersPagination.current_page = page,
  setReceptiveNumbersPagination: (state, receptiveNumbersPagination) => state.receptiveNumbersPagination = receptiveNumbersPagination,
  setReceptiveNumbersFilters: (state, receptiveNumbersFilters) => state.receptiveNumbersFilters = receptiveNumbersFilters,
  setReceptiveNumbersErrors: (state, receptiveNumbersErrors) => state.receptiveNumbersErrors = receptiveNumbersErrors,
  clearReceptiveNumbersErrors: (state) => {state.receptiveNumbersErrors = {}},
  setCompanyBilling: (state, companyBilling) => state.companyBilling = companyBilling,

  // Route
  setCompanyRoutesList: (state, companyRoutesList) => state.companyRoutesList = companyRoutesList,
  setCompanyRoutesCurrentPage: (state, page) => state.companyRoutesPagination.current_page = page,
  setCompanyRoutesPagination: (state, companyRoutesPagination) => state.companyRoutesPagination = companyRoutesPagination,
  setCompanyRoutesErrors: (state, companyRoutesErrors) => state.companyRoutesErrors = companyRoutesErrors,

  // Telephony
  setTelephonyRatesErrors: (state, telephonyRatesErrors) => state.telephonyRatesErrors = telephonyRatesErrors,
  setCompanyCredit: (state, companyCredit) => state.companyCredit = companyCredit,
  setCompanyCreditCurrentPage: (state, page) => state.companyCreditPagination.current_page = page,
  setCompanyCreditPagination: (state, pagination) => state.companyCreditPagination = pagination,
  setNewCompanyDataFeedback: (state, newCompanyDataFeedback) => state.newCompanyDataFeedback = newCompanyDataFeedback
}
