<template>
  <div class="d-flex flex-row input-search">
    <div class="col">
      <div class="input-group input-group-flush input-group-merge input-group-reverse">
        <input
          class="form-control list-search"
          :class="sizeClass"
          ref="searchInput"
          @focusout="$emit('out')"
          type="search"
          v-model="term"
          :placeholder="computedPlaceholder"
          @keyup="$emit('search', term)"
        >
        <span class="input-group-text py-0">
          <i class="fa-regular fa-magnifying-glass" />
        </span>
      </div>
    </div>
    <div v-if="term != ''">
      <div
        class="clear"
        @mouseup="$emit('search', '')"
        @click="term = ''"
      >
        <i class="fa-regular fa-xmark" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchInput",
  data() {
    return {
      term: "",
    };
  },

  emits: ["search", "out"],

  props: {
    search: {
      type: String,
      default: ""
    },

    placeholder: {
      type: String,
      default: null
    },

    focus: {
      type: Boolean,
      default: false
    },

    sizeClass: {
      type: String,
      default: "text-medium"
    }
  },

  mounted() {
    if (this.search) this.term = this.search;
    if (this.focus) this.$refs.searchInput.focus();
  },

  computed: {
    computedPlaceholder() {
      if (!this.placeholder) {
        return this.$t('placeholders.search_capitalize')
      }

      return this.placeholder
    }
  },

  watch: {
    focus(data) {
      if (data) this.$refs.searchInput.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
.input-search {
  .clear {
    line-height: 1;
    width: 28px;
    display: flex;
    justify-content: end;
    align-items: center;
    height: 100%;
    cursor: pointer;
    color: $color-text-gray !important;
  }
}

.input-group-text {
  color: $color-text-gray !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $color-text-gray !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $color-text-gray !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: $color-text-gray !important;
}
</style>
