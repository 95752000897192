<template>
  <div class="card">
    <div class="card-header">
      <div class="d-flex align-items-center">
        <div class="custom-icon d-flex align-items-center justify-content-center rounded-1 me-3">
          <i :class="['fa-regular', icon, 'text-primary']" />
        </div>
        <h2 class="card-title m-0">
          {{ title }}
        </h2>
      </div>
    </div>
    <div class="card-body empty-body">
      <div class="d-flex flex-column">
        <h4 class="card-title">
          {{ $t('shall_we_begin') }}
        </h4>
        <p class="mb-4">
          {{ $t('click_on_configure_to_adjust_your_preferences_and_activate_the_product') }}
        </p>
        <div class="align-self-end">
          <router-link
            :to="`/manager/${redirectLink}/new`"
            class="btn p-0 text-primary text-medium"
          >
            <i class="fa-regular fa-gear" />
            {{ $t('configure') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyCard",
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    redirectLink: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.card-header {
  .custom-icon {
    width: 40px;
    height: 40px;
    background-color: $color-gray-blue-300;
  }
}
</style>
