<template>
  <div class="main-content pb-6">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10 col-xl-8">
          <header-component
            :title="$t('create_campaign')"
            redirect-link="/manager/campaign"
          />
          <flash-message />
          <div class="col-lg-12">
            <ul
              class="nav nav-tabs"
              id="myTab"
              role="tablist"
            >
              <li
                class="nav-item"
                role="presentation"
              >
                <button
                  data-test="General-button"
                  class="nav-link active"
                  id="geral-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#geral"
                  type="button"
                  role="tab"
                  aria-controls="geral"
                  aria-selected="true"
                  ref="tab-general"
                >
                  {{ $t("general_capitalize") }}
                </button>
              </li>
              <li
                class="nav-item"
                role="presentation"
              >
                <button
                  data-test="agents-button"
                  class="nav-link"
                  id="aa-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#agents"
                  type="button"
                  role="tab"
                  aria-controls="aa"
                  aria-selected="false"
                  ref="tab-agents"
                >
                  {{ $t("agents_capitalize") }}
                </button>
              </li>
            </ul>
          </div>

          <div class="col-lg-12">
            <div class="tab-content">
              <div
                class="tab-pane fade show active"
                id="geral"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div class="row mt-5">
                  <div class="col-lg-12">
                    <app-input
                      v-model="fields.name"
                      :label="$t('campaign_name').charAt(0).toUpperCase() + $t('campaign_name').slice(1)"
                      :errors="errors.name"
                    />
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-label required">{{ $t("initial_hour")
                        .charAt(0)
                        .toUpperCase() + $t("initial_hour")
                          .slice(1) }}</label>
                      <div class="input-group input-group-merge">
                        <input
                          data-test="startTime-field"
                          :class="{
                            'form-control': true,
                            'is-invalid': errors.start_time
                          }"
                          type="text"
                          ref="start_time"
                          v-model="fields.start_time"
                          aria-label="Input group appended"
                        >
                        <div class="input-group-text">
                          <span class="fe fe-clock" />
                        </div>
                      </div>
                      <div class="invalid-feedback">
                        {{ errors.start_time && errors.start_time[0] }}
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-label required">
                        {{ $t("final_hour")
                          .charAt(0)
                          .toUpperCase() + $t("final_hour")
                            .slice(1) }}</label>
                      <div class="input-group input-group-merge">
                        <input
                          :class="{
                            'form-control': true,
                            'is-invalid': errors.end_time
                          }"
                          data-test="endTime-field"
                          type="text"
                          ref="end_time"
                          v-model="fields.end_time"
                          aria-label="Input group appended"
                        >
                        <div class="input-group-text">
                          <span class="fe fe-clock" />
                        </div>
                      </div>
                      <div class="invalid-feedback">
                        {{ errors.end_time && errors.end_time[0] }}
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-label required text-capitalize">{{ $t("qualifications") }}</label>
                      <select-qualification-list
                        :errors="errors.qualification_list"
                        v-model="fields.qualification_list"
                        :default-select-first="true"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-label required text-capitalize">{{ $t("break") }}</label>
                      <select-interval-list
                        :errors="errors.work_break_group_id"
                        v-model="fields.work_break_group_id"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-label required">{{ $t("list_of_criteria")
                        .charAt(0)
                        .toUpperCase() + $t("list_of_criteria")
                          .slice(1) }}</label>
                      <select-criterion-list
                        :errors="errors.criterion_list"
                        v-model="fields.criterion_list"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <app-input
                      type="number"
                      v-model="fields.acw_timeout"
                      :label="$t('qualification_time_CTA')"
                      :min="0"
                      :errors="errors.acw_timeout"
                      :small-text="$t('seconds_unlimited')"
                    />
                  </div>

                  <div :class="[isBrazil ? 'col-lg-6' : 'col-12']">
                    <div class="form-group">
                      <label class="form-label required">{{ isBrazil ? "Rotas para fixo" : "Rotas" }}</label>
                      <select-route
                        v-model="landline"
                        :allow-mobile="false"
                        :errors="[
                          errors.landline_id,
                          errors.landline_type,
                          errors.international_type,
                          errors.international_type
                        ]"
                      />
                    </div>
                  </div>

                  <div
                    v-if="isBrazil"
                    class="col-lg-6"
                  >
                    <div class="form-group">
                      <label class="form-label required">{{ $t("routes_to_cellphone")
                        .charAt(0)
                        .toUpperCase() + $t("routes_to_cellphone")
                          .slice(1) }}</label>
                      <select-route
                        v-model="mobile"
                        :allow-landline="false"
                        :errors="[errors.mobile_id, errors.mobile_type]"
                      />
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="form-label d-flex align-items-center">
                        {{ $t("integration_url") }}

                        <button
                          type="button"
                          class="btn btn-link p-0"
                          ref="moreInfoURL"
                        >
                          <span
                            ref="moreInfoURL"
                            class="fe fe-info text-primary ms-2 cursor-pointer"
                          />
                        </button>

                      </label>
                      <input
                        data-test="url-field"
                        :class="{
                          'form-control': true,
                          'is-invalid': errors.url || errorsFront.url,
                        }"
                        v-model="fields.url"
                        placeholder="https://"
                        class="form-control"
                      >
                      <div class="invalid-feedback">
                        {{ errors.url ? errors.url[0] : errorsFront.url && errorsFront.url[0] }}
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <app-button
                      color="btn-primary"
                      class="w-100"
                      @click="redirectToTheNextPage"
                    >
                      {{ $t("next_capitalize") }}
                    </app-button>
                  </div>
                </div>
              </div>

              <div
                class="tab-pane fade show"
                id="agents"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div class="row mt-5">
                  <div class="col-lg-12 b1 mb-5">
                    <label class="form-label text-capitalize">{{ $t("team") }}<span
                      class="text-lowercase"
                    >(s)</span></label>

                    <button
                      type="button"
                      class="btn btn-link p-0"
                      ref="teamsInfo"
                    >
                      <span class="fe fe-info text-primary ms-2 cursor-pointer" />
                    </button>
                    <small class="form-text text-muted">{{ $t("agent_access_description") }}</small>
                    <VueMultiselect
                      label="name"
                      track-by="id"
                      :placeholder="$t('select_more_teams')"
                      :select-label="$t('click_or_enter_to_select')"
                      :deselect-label="$t('click_or_enter_to_remove')"
                      :selected-label="$t('selected')"
                      open-direction="bottom"
                      v-model="fields.teams"
                      :multiple="true"
                      :close-on-select="false"
                      :preserve-search="true"
                      :options="teams"
                    />
                  </div>

                  <div class="col-lg-12 b1 mb-5">
                    <label class="form-label text-capitalize">{{ $t("agent") }}<span
                      class="text-lowercase"
                    >(s)</span></label>
                    <button
                      type="button"
                      class="btn btn-link p-0"
                      ref="agentsInfo"
                    >
                      <span class="fe fe-info text-primary ms-2 cursor-pointer" />
                    </button>
                    <small class="form-text text-muted">{{ $t("no_team_agent") }}</small>
                    <VueMultiselect
                      label="name"
                      track-by="id"
                      :placeholder="$t('select_more_agents')"
                      :select-label="$t('click_or_enter_to_select')"
                      :deselect-label="$t('click_or_enter_to_remove')"
                      :selected-label="$t('selected')"
                      open-direction="bottom"
                      v-model="agents"
                      :multiple="true"
                      :close-on-select="false"
                      :preserve-search="true"
                      :options="agentOptions"
                    />
                  </div>

                  <div class="col-lg-12">
                    <app-button
                      color="btn-primary"
                      class="w-100 text-capitalize"
                      @click="submit()"
                      :loading="loading"
                    >
                      {{ $t("save") }}
                    </app-button>
                  </div>
                  <div class="col-lg-3 mt-3 mx-auto">
                    <app-button
                      class="w-100 tx-text-gray"
                      color="btn"
                      @click="tab_general.show()"
                    >
                      {{ $t("back_capitalize") }}
                    </app-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { Tab, Tooltip } from "bootstrap";
import flatpickr from "flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import VueMultiselect from "vue-multiselect";

import { mapActions, mapGetters, mapMutations } from "vuex";
import { generateTooltip } from "@/utils.js";

import selectQualificationList from "@dialer/manager/components/select-qualification-list";
import SelectIntervalList from "@dialer/manager/components/select-interval-list";
import SelectCriterionList from "@dialer/manager/components/select-criterion-list";
import SelectRoute from "@dialer/manager/components/select-route";
import AppButton from "@components/app-button";
import AppInput from "@components/app-input";
import FlashMessage from "@components/flash-message";
import HeaderComponent from "@/components/header-component.vue";

export default {
  /**
   *
   */
  data() {
    return {
      fields: {
        acw_timeout: 0,
        check_amd: true,
        criterion_list: "",
        end_time: "18:30",
        extension_number: "",
        landline_id: "",
        landline_type: "",
        mobile_id: "",
        mobile_type: "",
        name: "",
        qualification_list: "",
        start_time: "08:00",
        url: "",
        work_break_group_id: "",
        teams: [],
        check_smart_filter: true,
        check_blacklist: true,
        check_dnd: true,
      },
      mobile: {
        id: null,
        type: null,
      },
      landline: {
        id: null,
        type: null,
      },
      agents: [],
      agentOptions: [],
      teams: [],

      tab_general: null,
      tab_agents: null,
      showAdvancedOptions: false,
      minTimeFlatpicker: null,
      maxTimeFlatpicker: null,
      planType: 0,

      teamsInfoTooltip: null,
      agentsInfoTooltip: null,

      moreInfoURLTooltip: null,

      // validations from front-end
      errorsFront: {
        url: null,
      },
    };
  },

  /**
   *
   */
  mounted() {
    this.planType = this.user.company.plan;
    this.tab_general = new Tab(this.$refs["tab-general"]);
    this.tab_agents = new Tab(this.$refs["tab-agents"]);

    this.minTimeFlatpicker = flatpickr(this.$refs.start_time, {
      locale: Portuguese,
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
    });
    this.maxTimeFlatpicker = flatpickr(this.$refs.end_time, {
      locale: Portuguese,
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
    });

    this.setIncludes(["teams"]);
    this.findFilters();

    this.loadActiveDataSelect();

    this.generateTooltips();
  },

  /**
   *
   */
  components: {
    selectQualificationList,
    SelectIntervalList,
    SelectCriterionList,
    SelectRoute,
    AppButton,
    FlashMessage,
    AppInput,
    VueMultiselect,
    HeaderComponent,
  },

  /**
   *
   */
  computed: {
    /**
     * Getters
     */
    ...mapGetters("manager/campaign", {
      errors: "getErrors",
      loading: "isLoading",
      createdCampaignData: "getCreateData",
    }),
    ...mapGetters("auth", {
      user: "getUser",
      isBrazil: "isBrazil",
    }),
    ...mapGetters("manager/receptive_queues", {
      listAgents: "getActiveAgents",
    }),
    ...mapGetters("manager/filters", {
      filters: "getFilters",
    }),

    ...mapGetters("manager/agent", {
      activeDataSelect: "getActiveDataSelect",
    }),
  },

  /**
   *
   */
  methods: {
    /**
     *
     */
    ...mapActions("manager/campaign", ["create", "addAgentsInCampaign"]),
    ...mapActions("manager/filters", ["findFilters"]),
    ...mapActions("manager/agent", ["loadActiveDataSelect"]),

    /**
     *
     */
    ...mapMutations("system", ["setFlashMessage"]),
    ...mapMutations("manager/filters", ["setIncludes"]),
    generateTooltip,

    /**
     *
     */
    submit() {
      const validations = this.handleUrlValidation();
      if (!validations) {
        return this.tab_general.show();
      }

      let fields = JSON.parse(JSON.stringify(this.fields));
      if (this.isBrazil) {
        if (this.landline.id) {
          fields.landline_id = this.landline.id;
          fields.landline_type = this.landline.type;
        }
        if (this.mobile.id) {
          fields.mobile_id = this.mobile.id;
          fields.mobile_type = this.mobile.type;
        }
      } else {
        fields.international_id = this.landline.id;
        fields.international_type = this.landline.type;
      }

      fields.teams = fields.teams.map(team => team.id);

      this.create({
        fields: fields,
      })
          .then(() => {
            if (this.agents.length) {
              this.addAgentsInCampaign({
                campaign_id: this.createdCampaignData.id,
                agents: this.agents.map(agent => agent.id),
              });
            }
            this.$router.push({
              path: `/manager/campaign/${this.createdCampaignData.id}`,
            });
          })
          .catch(() => {
            this.tab_general.show();
          });
    },

    redirectToTheNextPage() {
      const validations = this.handleUrlValidation();
      if (validations) {
        this.tab_agents.show();
      }
    },

    handleUrlValidation() {
      this.errorsFront.url = [];

      const domains = [".3c.plus", ".3c.fluxoti", ".3cplus"];
      let is3cDomain = domains.find(domain => this.fields.url.includes(domain)) && true;

      // regex to url validation
      const pattern = /^(https:\/\/|http:\/\/)/;

      let isUrl = pattern.test(this.fields.url);

      if (!isUrl) {
        this.errorsFront.url.push(this.$t("errors.invalid_url"))
      } else if (is3cDomain) {
        this.errorsFront.url.push(this.$t("errors.invalid_3c_url"))
      } else this.errorsFront.url = ""

      if (!this.fields.url || !this.errorsFront.url) {
        this.errorsFront.url = "";
        return true;
      }
      return false;
    },

    generateTooltips() {
      this.generateMoreInfoURLTooltip();

      this.teamsInfoTooltip = this.generateTooltip(`${this.$t("tooltips.teams_info")}`, this.$refs.teamsInfo);

      this.agentsInfoTooltip = this.generateTooltip(`${this.$t("tooltips.agents_info")}`, this.$refs.agentsInfo);
    },

    generateMoreInfoURLTooltip() {
      let tooltip = document.createElement("div");
      tooltip.innerHTML = `
        <div style="text-align: left; width: 550px;">
          <span style="font-size: 1rem">
              ${this.$t("tooltips.generate_more_info_url")}
              https://minhaurldeintegracao.com.br?variavel1=[variavel1]&amp;telefone=[telefone]&amp;ramal=[ramal]
              &amp;protocolo=[protocolo]&amp;identificador=[identificador]&amp;id_campanha=[id_campanha]
          </span>
        </div>`;

      this.moreInfoURLTooltip = new Tooltip(this.$refs.moreInfoURL, {
        html: true,
        placement: "top",
        title: tooltip,
      });
    },
  },
  watch: {
    filters: {
      handler(data) {
        if (data.teams) this.teams = data.teams;
      },
      deep: true,
    },

    /**
     *
     */
    activeDataSelect(activeDataSelect) {
      this.agentOptions = [];
      for (let agent of activeDataSelect) {
        this.agentOptions.push({
          name: agent.extension.extension_number + " - " + agent.name,
          id: agent.id,
        });
      }
    },
  },
};
</script>
