<template>
  <div class="chat-main background-chat d-flex flex-column">
    <top-bar />
    <div class="d-flex justify-content-center align-items-center h-100">
      <div class="text-center">
        <img
          src="@/assets/img/empty-chat.svg"
          class="mb-1-5r"
        >
        <h1 class="mb-1r">
          {{ $t('start_a_conversation') }}
        </h1>
        <p
          class="mb-0 fs-4"
          v-html="$t('start_a_conversation_text')"
        />
      </div>
    </div>
    <start-chat :chat-data="emptyChatContact" />
  </div>
</template>

<script>
import TopBar from "../top-bar.vue";
import StartChat from "../start-chat.vue";

import {mapGetters} from "vuex";

export default {
  components: {
    TopBar,
    StartChat
  },

  computed: {
    ...mapGetters("whatsapp/chat", {
      emptyChatContact: "getEmptyChatContact",
    }),
  }
};
</script>

<style lang="scss" scoped>
.chat-main {
  height: 100vh;
  width: 100%;
  position: relative;
}

.mb-1r {
  margin-bottom: 1rem;
}

.mb-1-5r {
  margin-bottom: 1.5rem;
}
</style>
