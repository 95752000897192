<template>
  <div class="vh-100 d-flex flex-column justify-content-center align-items-center">
    <Vue3Lottie
      :animation-data="LogoJson"
      class="logo-lottie"
    />
    <h2
      v-if="title"
      class="fs-24"
    >
      {{ title }}
    </h2>
  </div>
</template>

<script>
import LogoJson from "@/assets/lottie/logo.json";
import {Vue3Lottie} from "vue3-lottie";
export default {
  name: "CrmPageLoading",
  components: {Vue3Lottie},

  props: {
    title: {
      type: String,
      default: null
    }
  },

  data(){
    return{
      LogoJson
    }
  }
}
</script>

<style scoped lang="scss">
.logo-lottie{
  height: 80px !important;
  margin-bottom: 40px;
}

.fs-24{
  font-size: 1.5rem;
}
</style>