<template>
  <div ref="tooltipComponent">
    <slot
      name="activator"
    />
  </div>
</template>
<script>
import {Tooltip} from "bootstrap";
import {formatLocaleMoney} from "@/utils";

export default {
  name: "CustomTooltipComponent",

  props: {
    items: {type: Array, default: () => []},
    placement: {type: String, default: 'top'},
  },

  data() {
    return {
      tooltipComponent: null
    }
  },

  watch: {
    items() {
      this.tooltipComponent.dispose();
      this.loadTooltip();
    }
  },
  mounted() {
    this.loadTooltip()
  },

  methods: {
    formatLocaleMoney,
    loadTooltip() {
      let tooltip = document.createElement("div");
      this.items.map((item) => {
        let tooltipItem = document.createElement("div");
        tooltipItem.innerHTML = `
          <div>
            <ul class="overflow-auto list-unstyled tx-text-200 mb-0">
              <li class="option mb-0 rounded-1 cursor-pointer d-flex justify-content-between gap-3" style="padding: 0 16px 0 8px; line-height: 2">
              <span>${this.$tc(item.label, 0)}</span>
              <span class="fw-bold">${formatLocaleMoney(item.value, 'BRL')}</span>
              </li>
            </ul>
          </div>
        `
        tooltip.appendChild(tooltipItem)
      })
      this.tooltipComponent = new Tooltip(this.$refs.tooltipComponent, {
        html: true,
        placement: this.placement,
        title: tooltip,

      })
    }
  },

  beforeUnmount() {
    this.tooltipComponent.dispose();
  }
}
</script>

<style lang="scss">
.tooltip-inner {
  border-radius: $border-radius !important;
  padding: $spacing-2 !important;
}
</style>