<template>
  <div
    ref="canvas"
    class="offcanvas offcanvas-end"
  >
    <div class="offcanvas-body">
      <div class="row">
        <div class="col-12">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div class="col-12">
          <h2
            class="text-center"
            v-if="this.mode == 'edit'"
          >
            Número Receptivo
          </h2>
          <h2
            class="text-center"
            v-else
          >
            Criar número
          </h2>
        </div>

        <div class="col-12">
          <flash-message />
        </div>

        <div class="col-12">
          <div class="form-group">
            <label class="form-label">Nome</label>
            <input
              v-model="fields.name"
              class="form-control"
              :class="{
                'is-invalid': receptiveNumbersErrors.name
              }"
              placeholder="Nome"
              :disabled="this.mode == 'edit'"
            >
            <div
              class="invalid-feedback"
              v-if="receptiveNumbersErrors.name"
            >
              {{ receptiveNumbersErrors.name[0] }}
            </div>
          </div>
          <div class="form-group">
            <label class="form-label">Did</label>
            <input
              v-model="fields.did"
              class="form-control"
              :class="{
                'is-invalid': receptiveNumbersErrors.did
              }"
              placeholder="Did"
              :disabled="this.mode == 'edit'"
            >
            <div
              class="invalid-feedback"
              v-if="receptiveNumbersErrors.did"
            >
              {{ receptiveNumbersErrors.did[0] }}
            </div>
          </div>
          <div class="form-group">
            <label class="form-label">Número</label>
            <input
              v-model="fields.phone"
              class="form-control"
              :class="{
                'is-invalid': receptiveNumbersErrors.phone
              }"
              placeholder="Número"
              :disabled="this.mode == 'edit'"
            >
            <div
              class="invalid-feedback"
              v-if="receptiveNumbersErrors.phone"
            >
              {{ receptiveNumbersErrors.phone[0] }}
            </div>
          </div>
        </div>
      </div>
      <app-button
        v-if="this.mode == 'new'"
        @click="submit()"
        :loading="loadingFlags.createReceptiveNumbers"
        class="btn btn-primary w-100 my-4 col-12"
      >
        {{ $t('capitalizes.save') }}
      </app-button>
      <div
        v-if="this.mode == 'edit'"
        class="d-flex justify-content-center my-0"
      >
        <button
          @click="deleteReceptiveNumber()"
          class="btn btn-outline-danger border-0 text-center py-2"
        >
          <span class="fe fe-trash" />
          <label class="form-label my-0 cursor-pointer">Excluir</label>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Offcanvas } from "bootstrap";

import appButton from "@components/app-button";
import FlashMessage from "@components/flash-message";
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  data() {
    return {
      fields: {
        name: "",
        id: null,
        did: null,
        phone: null,
      },
      mode: "",
      offcanvas: null,
    }
  },

  computed: {
    ...mapGetters("admin/companies", {
      loadingFlags: "getLoadingFlags",
      receptiveNumbersErrors: "getReceptiveNumbersErrors",
    }),
  },

  components: {
    appButton,
    FlashMessage
  },

  mounted() {
    this.offcanvas = new Offcanvas(this.$refs.canvas);
  },

  methods: {
    ...mapActions("admin/companies", [
      "createReceptiveNumbers",
      "findReceptiveNumbers",
      "deleteReceptiveNumbers",
    ]),
    ...mapMutations("admin/companies", ["clearReceptiveNumbersErrors"]),
    ...mapMutations("system", ["setFlashMessage", "setSuccessMessage"]),

    edit(item) {
      this.clearReceptiveNumbersErrors()
      this.mode = "edit";

      for (const key in this.fields) {
        if (Object.hasOwnProperty.call(this.fields, key)) {
          this.fields[key] = item[key];
        }
      }
      this.offcanvas.show();
    },

    new() {
      this.clearReceptiveNumbersErrors()
      this.mode = "new";
      this.fields = {
        name: "",
        id: null,
        did: null,
        phone: null,
      }
      this.offcanvas.show();
    },

    submit() {
      this.fields['company_id'] = this.$route.params.id
      this.createReceptiveNumbers({
        fields: this.fields,
      }).then(() => {
        this.offcanvas.hide();
        this.findReceptiveNumbers({ id: this.$route.params.id });
      })
    },

    deleteReceptiveNumber() {
      this.deleteReceptiveNumbers({ id: this.fields.id })
        .then(() => {
          this.findReceptiveNumbers({ id: this.$route.params.id });
          this.offcanvas.hide();
        })
    },
  },
}
</script>
