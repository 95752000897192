<template>
  <div class="card col-7">
    <div class="card-header d-flex justify-content-between col-12">
      <h4 class="d-inline mb-0">
        {{ $t('edit_opportunities_fields') }}
      </h4>
      <button
        class="btn btn-outline tx-blue-300"
        @click="showNewFieldForm = !showNewFieldForm"
      >
        + {{ $t('new_field') }}
      </button>
    </div>
    <div>
      <table class="table table-nowrap card-table mb-0">
        <thead>
          <tr>
            <th class="col-2">
              {{ $t('uppercases.position') }}
            </th>
            <th class="col-2">
              {{ $t('uppercases.type') }}
            </th>
            <th class="col-5">
              {{ $t('uppercases.name') }}
            </th>
            <th class="col-1">
              {{ $t('uppercases.required') }}
            </th>
            <th class="col-2" />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              class="px-0"
              colspan="12"
            >
              <div>
                <add-field-form
                  v-if="showNewFieldForm"
                  :field-types="typesOfMasksAvailable"
                  :total-fields-length="fields ? fields.length : 0"
                  @close="showNewFieldForm = false"
                />
                <empty-form-inputs v-if="!fields || fields.length === 0 && !showNewFieldForm" />
                <Container
                  v-else
                  :get-child-payload="getChildPayload"
                  :get-ghost-parent="getGhostParent"
                  drag-handle-selector=".column-drag-handler"
                  @drop="onDrop"
                >
                  <Draggable
                    v-for="(item, index) in fields"
                    :key="item.id"
                  >
                    <div class="row py-2 align-items-center">
                      <span class="col-1 fw-light">
                        {{ index + 1 }}
                      </span>
                      <div class="col-10">
                        <edit-field-contact-row :item="item" />
                      </div>
                      <button
                        class="col-1 btn"
                        @click="deleteField(item)"
                      >
                        <i class="fal fa-trash fw-medium" />
                      </button>
                    </div>
                  </Draggable>
                </Container>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import {Container, Draggable} from "vue-dndrop";
import {applyDrag, Utils} from "@/utils";
import AddFieldForm from "./add-field-form.vue";
import {mapActions, mapGetters} from "vuex";
import EmptyFormInputs from "./empty-form-inputs.vue";
import EditFieldContactRow from "./edit-field-contact-row.vue";

export default {
  name: "EditFieldContact",

  components: {
    EditFieldContactRow,
    EmptyFormInputs,
    AddFieldForm,
    Container,
    Draggable
  },

  props: {
    inputs: {type: Array, default: null}
  },

  data() {
    return {
      items: [
        {id: '1', title: this.$t('values'), type: 'currency', required: true},
        {id: '2', title: 'CNPJ', type: 'string', required: true},
        {id: '3', title: this.$t('company_role'), type: 'string', required: false},
        {id: '4', title: this.$t('company'), type: 'string', required: false},
        {id: '5', title: this.$t('city'), type: 'string', required: true},
        {id: '6', title: this.$t('value'), type: 'currency', required: true},
        {id: '7', title: 'CNPJ', type: 'string', required: false},
      ],
      typesOfMasksAvailable: [
        {value: 'string', label: this.$t('text'), icon: 'far fa-align-left'},
        {value: 'date', label: this.$t('date_capitalize'), icon: 'far fa-calendar'},
        {value: 'cpf', label: this.$t('cpf_or_rg'), icon: 'far fa-file-lines'},
        {value: 'link', label: 'Link', icon: 'far fa-arrow-up-right-from-square'},
        {value: 'currency', label:  this.$t('value'), icon: 'far fa-dollar-sign'},
      ],
      showNewFieldForm: false
    };
  },

  mounted() {
    this.loadFields()
  },

  computed: {
    ...mapGetters('crm/opportunities', {fields: 'getFormInputs'}),
    ...mapGetters('crm/funnels', {currentFunnel: 'getCurrentFunnel'}),
    ...mapGetters('auth', {user: "getUser",})
  },

  methods: {
    ...mapActions('crm/opportunities', ['fetchFormInputs', 'deleteFormInput']),
    ...mapActions("system", ["showConfirmationMessage"]),

    applyDrag: Utils.applyDrag,
    generateItems: Utils.generateItems,

    loadFields() {
      this.fetchFormInputs(this.$route.params.id)
    },

    async deleteField (form) {
      let response = await this.showConfirmationMessage({
        title: `${this.$t('delete_field_title')} "${form.label}"?`,
        text: this.$t('delete_field_confirmation'),
        type: "badWarning"
      })

      if (response.isConfirmed) {
        this.deleteFormInput({
          id: form.id,
          funnel_id: this.$route.params.id
        })
      }
    },

    onDrop(dropResult) {
      this.items = applyDrag(this.items, dropResult);
    },

    getGhostParent() {
      return document.body;
    },

    getChildPayload(index) {
      return this.items[index];
    },
  },
}
</script>

<style lang="scss" scoped>
th {
  font-size: $font-size-sm-2 !important;
  color: $color-text-solid-black !important;
}

.form-check-input {
  height: $spacing-2;
  width: 28px;
}

.card-body::-webkit-scrollbar, .simple-page::-webkit-scrollbar {
  display: none !important;
}

.card-body {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
</style>