<template>
  <div
    v-if="!isPartner"
    :class="{ 'mx-3': resizeMobile }"
    class="main-content d-flex align-items-center justify-content-center h-100"
  >
    <div
      v-if="false"
      class="info-alert top-0 w-100 position-fixed alert alert-primary d-flex align-items-center justify-content-center mb-auto py-5"
      role="alert"
    >
      <div class="col-5">
        <h2 class="my-0">
          Atualização obrigatória de senha
        </h2>
        <p class="my-0">
          Altere sua senha para acessar sua conta
        </p>
      </div>
      <div>
        <button
          class="btn btn-block btn-white py-1 px-5"
          @click="goToForgotPassword()"
        >
          Alterar senha
        </button>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center h-100 col">
      <div
        :class="{ 'px-5 py-3 sm-mb': resizeMobile }"
        class="card col-sm-6 col-md-7 col-xl-5 login-card"
      >
        <div class="card-body p-0">
          <div class="col-12 align-self-center align-items-center justify-content-center d-flex flex-column">
            <div class="text-center">
              <Vue3Lottie
                :animation-data="LogoJson"
                style="height: 80px !important"
              />
            </div>
            <h1 class="display-4 tx-text-200 text-center mb-3 fw-normal">
              Sua jornada começa aqui
            </h1>
            <p class="tx-text-gray text-center h4 fs-5 fw-light">
              Um único login para todos os produtos da 3C Plus
            </p>
            <form
              class="col-12"
              @submit.prevent="submit"
            >
              <div
                v-if="isAppDomain"
                class="form-floating form-group"
              >
                <input
                  id="domain"
                  ref="domain"
                  v-model="fields.company_domain"
                  :class="{
                    ' is-invalid': errors.login
                  }"
                  :disabled="loadingFlags.login"
                  class="form-control form-control-sm input-login"
                  @blur="inputFocused = ''"
                  @focus="inputFocused = 'company_domain'"
                >
                <label
                  :class="{ 'label-on-border': fields.company_domain || inputFocused == 'company_domain' }"
                  class="floating-label"
                  for="domain"
                >
                  Domínio
                </label>

              </div>
              <div class="form-floating form-group">
                <input
                  id="user"
                  ref="login"
                  v-model="fields.user"
                  :class="{
                    ' is-invalid': errors.login
                  }"
                  :disabled="loadingFlags.login"
                  class="form-control form-control-sm input-login"
                  @blur="inputFocused = ''"
                  @focus="inputFocused = 'user'"
                >

                <div class="invalid-feedback">
                  {{ errors.user && errors.user[0] }}
                </div>
                <label
                  :class="{ 'label-on-border': fields.user || inputFocused == 'user' }"
                  class="floating-label"
                  for="user"
                >E-mail ou Ramal</label>
              </div>
              <div class="d-flex justify-content-center align-items-start">
                <div class="form-floating form-group col-11 mb-0">
                  <input
                    id="password"
                    v-model="fields.password"
                    :class="{
                      ' is-invalid': errors.login
                    }"
                    :disabled="loadingFlags.login"
                    :type="showPass ? 'text' : 'password'"
                    aria-describedby="inputGroupInvalid"
                    aria-label="Input group appended"
                    class="border-end-0 form-control form-control-sm input-login"
                    @blur="inputFocused = ''"
                    @focus="inputFocused = 'password'"
                  >
                  <div class="invalid-feedback">
                    {{ errors.login }}
                  </div>
                  <label
                    :class="{ 'label-on-border': fields.password || inputFocused == 'password' }"
                    class="floating-label"
                    for="password"
                  >
                    Senha
                  </label>
                </div>
                <div
                  id="inputGroupInvalid"
                  :class="{ 'password-eye-input': inputs.password, ' input-border-danger': errors.login }"
                  class="d-flex justify-content-center align-items-center text-center input-group-text col border-start-0 rounded-end p-0"
                  style="height: 40px"
                  @click="showPass = !showPass"
                >
                  <i
                    v-if="!showPass"
                    class="fe fe-eye"
                  />
                  <i
                    v-else
                    class="fe fe-eye-off"
                  />
                </div>
              </div>
              <p class="d-flex justify-content-between pt-1">
                <small
                  class="text-start"
                  @mouseenter="iconHovered = 'forgetPassword'"
                  @mouseleave="iconHovered = ''"
                >
                  <a
                    :class="[iconHovered === 'forgetPassword' ? 'tx-blue-300' : 'tx-text-gray']"
                    class="cursor-pointer fs-6"
                    @click="goToForgotPassword()"
                  >Esqueceu sua senha?</a>
                </small>
                <small
                  v-if="capsLockOn"
                  class="text-end tx-blue-300"
                >
                  <i class="fal fa-lock" /> Caps lock ativado
                </small>
              </p>
              <button
                :disabled="loadingFlags.login"
                class="btn btn-lg w-100 btn-primary mb-3 submit-button"
                type="submit"
              >
                <template v-if="loadingFlags.login">
                  <div
                    class="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  Entrar
                </template>
                <template v-else>
                  Entrar
                </template>
              </button>
            </form>
          </div>
        </div>
      </div>
      <div class="card card-footer shadow col-12 m-0">
        <div class="card-body p-0">
          <div
            class="d-flex align-items-center justify-content-between"
            style="height: 24px;"
          >
            <div class="d-flex align-items-center">
              <a
                class="btn"
                href="https://www.instagram.com/3cplusnow/"
                target="_blank"
                @mouseenter="iconHovered = 'instagram'"
                @mouseleave="iconHovered = ''"
              >
                <i
                  :class="{ 'tx-blue-300': iconHovered == 'instagram' }"
                  class="fab fa-instagram fa-lg footer-icon"
                />
              </a>
              <a
                class="btn tx-text-gray"
                href="https://www.tiktok.com/@3cplusnow?_t=8mLHaRJrc3C&_r=1"
                target="_blank"
                @mouseenter="iconHovered = 'tiktok'"
                @mouseleave="iconHovered = ''"
              >
                <i
                  :class="{ 'tx-blue-300': iconHovered == 'tiktok' }"
                  class="fab fa-tiktok"
                />
              </a>
              <a
                class="btn tx-text-gray"
                href="https://3cplusnow.com/"
                target="_blank"
                @mouseenter="iconHovered = 'note'"
                @mouseleave="iconHovered = ''"
              >
                <i
                  :class="{ 'tx-blue-300': iconHovered == 'note' }"
                  class="far fa-memo"
                />
              </a>
              <router-link
                class="btn tx-text-gray"
                to="/terms"
                target="_blank"
                @mouseenter="iconHovered = 'term'"
                @mouseleave="iconHovered = ''"
              >
                <p
                  class="mb-0"
                  :class="{ 'tx-blue-300': iconHovered == 'term' }"
                >
                  Termos de uso
                </p>
              </router-link>
              <a
                class="tx-text-gray border-0"
                href="https://help.3cplus.com.br/pt-BR/"
                target="_blank"
              >
                Ajuda
              </a>
            </div>

            <div class="d-flex align-items-center">
              <status-services
                class="iframe"
              />
              <div class="tx-text-gray">
                IP: {{ userIp }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <modal-conflit
        :delaying="retryDelaying"
        :show="showConflitModal"
        @close="showConflitModal = false"
        @force="force()"
      />
      <logout-info
        :show="showLogoutInfoModal"
        @close="showLogoutInfoModal = false"
      />
    </div>
  </div>

  <div
    v-else
    :class="{ 'mx-3 pt-6 mt-4': resizeMobile }"
    class="main-content d-flex align-items-center justify-content-center h-100"
  >
    <div class="d-flex justify-content-center flex-column align-items-center pb-6 col">
      <div
        class="col-md-5 col-12"
        style="z-index: 1051 !important;"
      >
        <div class="text-center">
          <img
            alt="logo Partner"
            class="w-100"
            src="@/assets/img/partner/partner.svg"
            style="margin-bottom: 24px; max-width: 500px;"
          >
        </div>
      </div>
      <div
        :class="{ 'px-5 py-0': resizeMobile }"
        class="card col-sd-5 col-md-7 col-xl-5 col-11 login-card py-3"
      >
        <div class="card-body p-0">
          <div class="col-12 my-5">
            <div class="text-start">
              <h1 class="display-4 mb-3 fw-normal">
                Olá, partner!
              </h1>
              <p class="tx-text-gray h4 fw-light">
                Sua jornada começa aqui
              </p>
            </div>
            <div class="flex-column">
              <form
                class="col-12"
                @submit.prevent="submit"
              >
                <div class="form-floating form-group">
                  <input
                    id="user"
                    ref="login"
                    v-model="fields.user"
                    :class="{
                      ' is-invalid': errors.login
                    }"
                    :disabled="loadingFlags.login"
                    class="form-control form-control-sm input-login"
                    @blur="inputFocused = ''"
                    @focus="inputFocused = 'user'"
                  >

                  <div class="invalid-feedback">
                    {{ errors.user && errors.user[0] }}
                  </div>
                  <label
                    :class="{ 'label-on-border': fields.user || inputFocused == 'user' }"
                    class="floating-label"
                    for="user"
                  >E-mail</label>
                </div>
                <div class="d-flex justify-content-center align-items-start">
                  <div class="form-floating form-group col-11 mb-0">
                    <input
                      id="password"
                      v-model="fields.password"
                      :class="{
                        ' is-invalid': errors.login
                      }"
                      :disabled="loadingFlags.login"
                      :type="showPass ? 'text' : 'password'"
                      aria-describedby="inputGroupInvalid"
                      aria-label="Input group appended"
                      class="border-end-0 form-control form-control-sm input-login"
                      @blur="inputFocused = ''"
                      @focus="inputFocused = 'password'"
                    >
                    <div class="invalid-feedback">
                      {{ errors.login }}
                    </div>
                    <label
                      :class="{ 'label-on-border': fields.password || inputFocused == 'password' }"
                      class="floating-label"
                      for="password"
                    >
                      Senha
                    </label>
                  </div>
                  <div
                    id="inputGroupInvalid"
                    :class="{ 'password-eye-input': inputs.password, ' input-border-danger': errors.login }"
                    class="d-flex justify-content-center align-items-center text-center input-group-text col border-start-0 rounded-end p-0"
                    style="height: 40px"
                    @click="showPass = !showPass"
                  >
                    <i
                      v-if="!showPass"
                      class="fe fe-eye"
                    />
                    <i
                      v-else
                      class="fe fe-eye-off"
                    />
                  </div>
                </div>
                <p class="d-flex justify-content-between pt-1">
                  <small
                    class="text-start"
                    @mouseenter="iconHovered = 'forgetPassword'"
                    @mouseleave="iconHovered = ''"
                  >
                    <a
                      :class="[iconHovered === 'forgetPassword' ? 'tx-blue-300' : 'tx-text-gray']"
                      class="cursor-pointer fs-6"
                      @click="goToForgotPasswordPartner()"
                    >Esqueceu sua senha?</a>
                  </small>
                  <small
                    v-if="capsLockOn"
                    class="text-end tx-blue-300"
                  >
                    <i class="fal fa-lock" /> Caps lock ativado
                  </small>
                </p>
                <button
                  :disabled="loadingFlags.login"
                  class="btn w-100 btn-primary mb-3 submit-button"
                  style="height: 40px"
                  type="submit"
                >
                  <template v-if="loadingFlags.login">
                    <div
                      class="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    Entrar
                  </template>
                  <template v-else>
                    Entrar
                  </template>
                </button>
                <p class="text-center mb-0 mt-3 tx-text-gray">
                  Ainda não é um parceiro?
                  <a
                    :class="[iconHovered === 'register' ? 'tx-blue-300' : 'tx-text-200']"
                    class="cursor-pointer"
                    @click="goToRegisterPartner()"
                    @mouseenter="iconHovered = 'register'"
                    @mouseleave="iconHovered = ''"
                  >
                    Cadastrar
                  </a>
                </p>
              </form>
            </div>
          </div>
        </div>
        <div
          :class="{ 'd-none': resizeMobile }"
          class="position-absolute image-position"
        >
          <img
            alt="logo Partner"
            src="@/assets/img/partner/logoPartner.svg"
            style="width: 70%;"
          >
        </div>
      </div>
      <div class="card card-footer shadow col-12 m-0">
        <div class="card-body p-0">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <a
                class="btn"
                href="https://www.instagram.com/3cplusnow/"
                target="_blank"
                @mouseenter="iconHovered = 'instagram'"
                @mouseleave="iconHovered = ''"
              >
                <i
                  :class="{ 'tx-blue-300': iconHovered == 'instagram' }"
                  class="fab fa-instagram fa-lg footer-icon"
                />
              </a>
              <a
                class="btn"
                href="https://3cplusnow.com/"
                target="_blank"
                @mouseenter="iconHovered = 'globe'"
                @mouseleave="iconHovered = ''"
              >
                <i
                  :class="{ 'tx-blue-300': iconHovered == 'globe' }"
                  class="fal fa-globe fa-lg footer-icon"
                />
              </a>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <status-services
                class="iframe"
              />
              <a
                class="tx-text-200 border-0"
                href="https://help.3cplus.com.br/pt-BR/"
                target="_blank"
              >
                Ajuda
              </a>
            </div>
          </div>
        </div>
      </div>
      <modal-conflit
        :delaying="retryDelaying"
        :show="showConflitModal"
        @close="showConflitModal = false"
        @force="force()"
      />
      <logout-info
        :show="showLogoutInfoModal"
        @close="showLogoutInfoModal = false"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ModalConflit from "./components/modal-conflit";
import logoutInfo from "./components/logout-info";

import { Vue3Lottie } from "vue3-lottie";
import LogoJson from "@/assets/lottie/logo.json";
import { window } from "mixpanel-browser/src/utils";
import StatusServices from "@components/status-services.vue";

export default {
  data() {
    return {
      showPass: false,
      fields: {
        user: "",
        password: "",
        token_type: "jwt",
      },
      inputs: {
        password: false,
      },
      showConflitModal: false,
      showLogoutInfoModal: false,
      retryDelaying: false,
      capsLockOn: null,
      resizeMobile: false,
      resizeHd: false,
      higthRezolution: false,
      isPartner: false,
      mobile: false,
      LogoJson,
      inputFocused: "",
      iconHovered: "",
      isAppDomain: false,
    };
  },

  mounted() {
    this.windowResize();
    window.addEventListener("resize", this.windowResize);
    this.checkCapsLock();
    this.clearErrors();
    this.autoFocus();
    const currentUrl = window.location.href;
    this.isPartner = /^(https?:\/\/)?(www\.)?partner\..+/i.test(currentUrl);
    this.isAppDomain = /^(https?:\/\/)?(www\.)?app\..+/i.test(currentUrl);
  },
  beforeUnmount() {
    this.windowResize();
    window.removeEventListener("resize", this.windowResize);
  },
  computed: {
    ...mapGetters("auth", {
      logoutCause: "getLogoutCause",
      loadingFlags: "getLoadingFlags",
      errors: "getErrors",
      authenticated: "isAuthenticated",
      isAgent: "isAgent",
      isSupervisorOrManager: "isSupervisorOrManager",
      isAdmin: "isAdmin",
      onlyHaveWhatsapp: "onlyHaveWhatsapp",
      twoFa: "hasTwoFa",
    }),

    ...mapGetters("system", {
      userIp: "getUserIp",
    }),
  },
  components: {
    StatusServices,
    ModalConflit,
    logoutInfo,
    Vue3Lottie,
  },

  watch: {
    authenticated(authenticated) {
      if (authenticated) {
        if (this.isAgent) return this.$router.push(this.onlyHaveWhatsapp ? "/whatsapp" : "/agent");

        if (this.isSupervisorOrManager) {
          this.$router.push("/manager");
          this.setIncludes(["campaigns-landline-routes", "campaigns-mobile-routes", "company"]);
          return this.loadRoutes();
        }

        if (this.isAdmin) return this.$router.push("/admin");
        location.reload();
      }
    },

    logoutCause(cause) {
      switch (cause) {
        case "force":
          this.showLogoutInfoModal = true;
          break;
      }
    },
  },

  methods: {
    ...mapActions("auth", ["login", "logout", "notifyLogout"]),
    ...mapMutations("auth", ["setUserLogin", "clearErrors"]),
    ...mapMutations("manager/voice_3c", ["setIncludes"]),
    ...mapActions("manager/voice_3c", ["loadRoutes"]),

    goToForgotPassword() {
      this.setUserLogin(this.fields.user);
      this.$router.push("/login/forgot-password");
    },
    goToForgotPasswordPartner() {
      this.setUserLogin(this.fields.user);
      this.$router.push("/login/partner/forgot-password");
    },
    goToRegisterPartner() {
      this.$router.push("/login/register-partner");
    },
    checkCapsLock() {
      document.addEventListener("keydown", event => {
        if (typeof event.getModifierState === "function") {
          let capsLockState = event.getModifierState("CapsLock");
          this.capsLockOn = capsLockState;
        }
      });
    },
    submit() {
      if (localStorage.getItem("user") == this.fields.user && localStorage.getItem("location_identifier")) {
        this.fields.location_identifier = localStorage.getItem("location_identifier");
      }

      if (this.fields.company_domain && this.fields.company_domain.trim().length) {
        this.fields.company_domain = this.fields.company_domain.toLowerCase().trim();
      } else {
        this.fields.company_domain = null;
      }

      this.login(this.fields).catch(data => {
        if (data?.conflict) this.showConflitModal = true;
      });
    },

    force() {
      this.notifyLogout(this.fields).then(() => {
        this.retryDelaying = true;
        setTimeout(() => {
          this.retryDelaying = false;
          this.showConflitModal = false;
          this.submit();
        }, 5000);
      });
    },

    autoFocus() {
      this.$refs.login.focus();
    },
    windowResize() {
      this.resizeMobile = window.innerWidth <= 768;
      this.resizeHd = window.innerWidth > 1440;
      this.higthRezolution = window.innerWidth >= 1920;
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  height: 100vh;

  .password-eye-input {
    border-color: $color-blue-300;
    transition: border-color 0.15s ease-in-out;
  }

  .input-border-danger {
    border-color: #e63757;
  }

  #password {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  #inputGroupInvalid {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .card-footer {
    height: 56px !important;
    padding: 16px 32px !important;
    background-color: $color-text-light !important;
    position: fixed;
    bottom: 0;
    z-index: 1050 !important;
  }

  .login-card {
    box-shadow: 0px 4px 18px 0px rgba(34, 54, 77, 0.12);
    padding: 56px;
    z-index: 1050 !important;
  }

  form,
  .display-4,
  .submit-button {
    margin-top: $whatsapp-spacing-4;
  }

  .footer-icon {
    color: $color-text-gray;
  }

  .input-group-text {
    cursor: pointer !important;
  }

  .image-position {
    left: -78px;
    bottom: -50px;
  }

  .input-login {
    height: 40px;
    padding: 15px;
  }

  .input-login.form-control {
    background-color: #ffffff !important;
  }

  .floating-label {
    color: $color-text-200 !important;
  }

  .label-on-border {
    transform: translateY(-18px) !important;
    opacity: 1 !important;
    height: 20px !important;
    padding-top: 2px !important;
    background-color: white !important;
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
}

.info-alert {
  z-index: 1053 !important;
  border-radius: 0 !important;
}

.form-floating {
  color: $color-text-gray;
  line-height: 30px;
}

.sm-mb {
  margin-bottom: 60px !important;
}

.iframe {
  max-height: 30px;
  max-width: 240px;

}
</style>
