<template>
  <div class="main-content">
    <div class="container-fluid">
      <header-component
        :title="$t('users_capitalize')"
        :pretitle="$t('settings_uppercase')"
      >
        <template #buttons>
          <div class="d-flex align-items-center gap-3">
            <div v-if="user.company.ring_group_licenses">
              <span class="tx-text-gray">{{ $t('extension_licences') }}:</span>
              {{ user.company.ring_group_licenses - user.company.used_ring_group_licenses }}/{{ user.company.ring_group_licenses }}
            </div>
            <router-link
              to="/manager/users/new"
              class="btn btn-primary btn-users"
            >
              {{ $t("create_user") }}
            </router-link>
          </div>
        </template>
      </header-component>
      <ul class="nav nav-tabs mb-4 ">
        <li class="nav-item">
          <a
            class="nav-link active cursor-pointer"
            data-bs-toggle="tab"
            @click="activeTab = 'UsersTableContent'"
          >{{ $t('agents_capitalize') }}</a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link cursor-pointer"
            data-bs-toggle="tab"
            @click="activeTab = 'IpTableContent'"
          >{{ $t('access_control') }}</a>
        </li>
      </ul>
      <transition
        name="fade"
        mode="out-in"
      >
        <component :is="activeTab" />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderComponent from "@/components/header-component.vue";
import UsersTableContent from "@dialer/manager/pages/settings/users/components/users-table-content.vue";
import IpTableContent from "@dialer/manager/pages/settings/users/components/ip-allowed/ip-table-content.vue";

export default {
  components: {
    IpTableContent,
    HeaderComponent,
    UsersTableContent
  },

  data() {
    return {
      activeTab: "UsersTableContent"
    }
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser"
    })
  }
};
</script>

<style scoped lang="scss">
.btn-users {
  width: 300px;
}
.nav-link.active {
  font-weight: 500;
  border-width: 2px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
