export default {
  startLoading: (state) => state.loading = true,
  stopLoading: (state) => state.loading = false,
  setMetrics: (state, metrics) => {
    state.metrics = {
      abandoned: 0,
      abandoned_out_service: 0,
      acw: 0,
      connected: 0,
      queue_id: 0,
      speaking: 0,
      speaking_with_agent: 0,
      waiting_time: 0,
      awt: 0,
      ast: 0,
      abandonedPercentage: 0,
      abandonedOutServicePercentage: 0,
      connectedPercentage: 0
    };

    if (metrics[0] !== undefined) {
      state.metrics = metrics[0];
      state.metrics.awt = 0;
      if (state.metrics.connected > 0) {
        state.metrics.ast = Math.round(state.metrics.speaking_with_agent / state.metrics.connected);
      }

      let total = state.metrics.connected + state.metrics.abandoned + state.metrics.abandoned_out_service;

      if (total > 0) {
        state.metrics.awt = Math.round(state.metrics.waiting_time / total);
        state.metrics.abandonedPercentage = Math.round(state.metrics.abandoned / total * 100);
        state.metrics.connectedPercentage = Math.round(state.metrics.connected / total * 100);
        state.metrics.abandonedOutServicePercentage = Math.round(state.metrics.abandoned_out_service / total * 100);
      }
    }
  },
  setQualifications: (state, qualifications) => {
    state.qualifications = {
      calls: 0,
      qualifications: []
    };

    if (qualifications[0] !== undefined) {
      state.qualifications = qualifications[0];
    }
  },
};
