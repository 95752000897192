<template>
  <hover-button
    icon="fa-regular fa-arrow-left"
    :background-color="isConsultHold ? 'var(--bs-white)' : 'var(--color-receptive)'"
    :text-color="isConsultHold ? '#677C92' : 'var(--color-text-light)'"
    :should-show-close-button="false"
    :disabled="loadingFlags.cancelConsult || loadingFlags.exitConsult"
    @click="cancelCurrentConsult"
  />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import HoverButton from "@dialer/agent/components/hover-button.vue";
export default {
  components: {
    HoverButton
  },

  computed: {
    ...mapGetters("system", {
      isConsultHold: "isConsultHold"
    }),

    ...mapGetters("agent/ring_group", {
      isRingGroupActive: "isRingGroupActive"
    }),

    ...mapGetters("agent/agent", {
      loggedCampaign: "getCampaign",
      loadingFlags: "getLoadingFlags"
    }),
  },

  methods: {
    ...mapActions("agent/ring_group", ["cancelConsultPABX"]),
    ...mapActions("agent/agent", ["cancelConsult", "exitConsult"]),
    cancelCurrentConsult() {
      if (this.isRingGroupActive) {
        this.cancelConsultPABX();
        return;
      }

      if (this.loggedCampaign) {
        if (this.isConsultHold) {
          this.cancelConsult();
          return;
        }
        this.exitConsult();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
