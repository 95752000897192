<template>
  <crm-page-loading v-if="loadingFlags.fetchFunnels" />
  <div
    v-else
    class="container-fluid mt-4"
  >
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center gap-2">
        <button
          class="btn btn-xs btn-rounded-circle btn-white float-start"
          @click="$router.back()"
        >
          <i class="fe fe-arrow-left" />
        </button>
        <span class="fs-16 header-title">
          {{ $t('close') }}
        </span>
      </div>
      <div class="d-flex align-items-end gap-3">
        <select-responsible-for-contact
          v-if="!isAgent && !loadingFlagsUsers.fetchUsersByTeam && teamUsers.length"
          :key="resetFields"
          :team-users="teamUsers"
          @contact-selected="(id) => fields.owner_id = id"
        />
        <div id="create-opportunity-button">
          <app-button
            :blocked="loadingFlagsOpportunity.createOpportunity"
            @click="createNewOpportunity"
            style="width: 208px;"
          >
            {{ $t('create_opportunity') }}
          </app-button>
        </div>
      </div>
    </div>
    <div
      id="opportunity-card"
    >
      <opportunity-card
        @select-step="selectStep"
        enable-choice
        @name="setName"
        :errors="errors?.name || ''"
      />
    </div>
    <div class="d-flex cards-container">
      <div
        class="col-7"
        style="gap: 24px"
      >
        <new-activity-card
          disabled
          id="new-activity-card"
        />
        <pending-activities-list />
        <activity-timeline />
      </div>
      <div
        class="col"
        style="margin-left: 24px"
      >
        <div id="contact-card">
          <contact-chanels-list
            :is-creation="true"
            :key="resetFields"
            @update-contact-inputs="fields.contacts = $event"
          />
        </div>
        <div id="data-card">
          <data-card
            :is-creation="true"
            :key="resetFields"
            @update-inputs="fields.form_input_answers = $event"
          />
        </div>
      </div>
    </div>
    <tooltip-guided-tour
      :steps="steps"
      v-if="!this.tourSteps?.add_first_opportunity && !isAgent"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import SelectResponsibleForContact from "../../components/agents/select-responsible-for-contact.vue";
import ContactChanelsList from "../../components/contact/contact-chanels/contact-chanels-list.vue";
import DataCard from "../../components/contact/data-card.vue";
import CrmPageLoading from "@crm/components/shared/crm-page-loading.vue";
import NewActivityCard from "@crm/components/activity/form-activity/new-activity-card.vue";
import PendingActivitiesList from "@crm/components/activity/list/pending-activities-list.vue";
import ActivityTimeline from "@crm/components/activity/timeline/activity-timeline.vue";
import AppButton from "@components/app-button.vue";
import OpportunityCard from "@crm/pages/opportunity/components/opportunity-card.vue";
import TooltipGuidedTour from "@components/tooltip-guided-tour.vue";



export default {
  components: {
    TooltipGuidedTour,
    OpportunityCard,
    AppButton,
    CrmPageLoading,
    DataCard,
    ContactChanelsList,
    SelectResponsibleForContact,
    NewActivityCard,
    PendingActivitiesList,
    ActivityTimeline
  },

  data() {
    return {
      fields: {
        company_id: null,
        name: "",
        owner_id: null,
        step_id: null,
        contacts: [],
        form_input_answers: [],
        note: '-'
      },
      resetFields: false,
      steps: [
        { element: '#opportunity-card', popover: { description: 'Adicione um nome para a nova oportunidade.' } },
        { element: '#contact-card', popover: { description: 'Adicione um telefone ou e-mail de contato.' } },
        { element: '#data-card', popover: {description: 'Preencha os dados obrigatórios configurados anteriormente.'  } },
        //{ element: '.footer', popover: { description: 'Adicione produtos na oportunidade.' } },
        { element: '#create-opportunity-button', popover: { description: 'Tudo pronto! Agora basta criar a oportunidade.' } },
      ]
    };
  },

  computed: {
    ...mapGetters("crm/contacts", {
      teamUsers: "getTeamUsers",
      loadingFlagsUsers: "getLoadingFlags",
    }),
    ...mapGetters("auth", {
      user: "getUser",
      isAgent: "isAgent"
    }),
    ...mapGetters("crm/funnels", {
      currentFunnel: "getCurrentFunnel",
      loadingFlags: "getLoadingFlags",
      currentStepId: "getCurrentStepId",
    }),
    ...mapGetters("crm/opportunities", {
      errors: "getErrors",
      loadingFlagsOpportunity: "getLoadingFlags"
    }),
    ...mapGetters('crm/tour', {
      tourSteps: 'getTourSteps'
    }),
  },

  mounted() {
    this.populateFields();
  },

  beforeUnmount() {
    this.setCurrentStepId(null);
  },

  watch: {
    currentFunnel() {
      this.populateFields()
    }
  },

  methods: {
    ...mapActions('crm/opportunities', [
      'createOpportunity',
      'fetchStepOpportunities'
    ]),
    ...mapActions('crm/tour',{
      updateTourSteps: 'updateTourSteps'
    }),
    ...mapMutations('crm/opportunities', [
      'setOpportunity'
    ]),
    ...mapMutations('crm/funnels', ['setCurrentStepId']),

    populateFields() {
      this.fields.company_id = this.user.company.id
      this.fields.owner_id = this.user.id
      if(this.currentStepId) {
        this.fields.step_id = this.currentStepId
      }
      else if (this.currentFunnel?.steps.length) {
        this.fields.step_id = this.currentFunnel.steps[0].id
      }
      this.setOpportunity(null)
    },

    createNewOpportunity() {
      this.filterContacts()
      this.createOpportunity({
        ...this.fields, funnel_id: this.currentFunnel.id
      }).then((opportunityId) => {
        if(!this.tourSteps?.add_first_opportunity && !this.isAgent){
          this.updateTourSteps({
            add_first_opportunity: true,
          })
        }
        this.$router.push(`opportunity/${opportunityId}`)
        this.fetchStepOpportunities({
          step: this.currentFunnel.steps.find(step => step.id === this.fields.step_id),
          page: 1
        })
      })
    },

    filterContacts() {
      this.fields.contacts = this.fields.contacts.filter(contact => contact.value ? true : false)
    },

    selectStep(id) {
      this.fields.step_id = id;
    },

    setName(name) {
      this.fields.name = name;
    }
  },
};
</script>
