import axios from "axios";

export default {
  findById({ commit }, payload) {
    commit("clearErrors", "findById");
    commit("startLoadingFlag", "findById");
    commit("setRegister", null);

    axios.get("/criterion_list/" + payload.id)
      .then((response) => {
        commit("setRegister", response.data.data);
      })
      .catch((response) => {
        commit("setRegister", null);
        commit("setAlert", { message: response.response.data.detail, type: "danger" });
        response.response.status == 422 &&
        response.response.data.errors &&
        commit("setErrorFlags", { flag: "findById", errors: response.response.data.errors });
      })
      .finally(() => {
        commit("stopLoadingFlag", "findById");
      });
  },

  clearErrors({ commit }, errorFlag) {
    commit("clearErrors", errorFlag);
  },

  create({ commit }, payload) {
    commit("clearErrors", "create");
    commit("startLoadingFlag", "create");
    commit("setRegister", null);

    return new Promise((resolve, reject) => {
      axios.post("/criterion_lists", payload.fields)
        .then((response) => {
          commit("system/setSuccessMessage", "success_message.create_register", { root: true });
          commit("setRegister", response.data.data);
          resolve();
        })
        .catch((response) => {
          response.response.status == 422 &&
          response.response.data.errors &&
          commit("setErrorFlags", { flag: "create", errors: response.response.data.errors });
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "create");
        });
    });
  },

  update({ commit }, payload) {
    commit("clearErrors", "update");
    commit("startLoadingFlag", "update");

    return new Promise((resolve, reject) => {
      axios.put("/criterion_lists/" + payload.id, payload.fields)
        .then((response) => {
          commit("system/setSuccessMessage", "success_message.update_register", { root: true });
          commit("setRegister", response.data.data);
          resolve();
        })
        .catch((error) => {
          error.response.status == 422 &&
          error.response.data.errors &&
          commit("setErrorFlags", { flag: "update", errors: error.response.data.errors });
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "update");
        });
    });
  },

  remove({ commit }, payload) {
    commit("clearErrors", "remove");
    commit("startLoadingFlag", "remove");

    return new Promise((resolve, reject) => {
      axios.delete("/criterion_lists/" + payload.id)
        .then(() => {
          commit("setRegister", null);
          commit("system/setSuccessMessage", "success_message.remove_register", { root: true });
          resolve();
        })
        .catch((response) => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "remove");
        });
    });
  },

  updateCriteria({ commit, dispatch }, payload) {
    commit("clearErrors", "updateCriteria");
    commit("startLoadingFlag", "updateCriteria");

    return new Promise((resolve, reject) => {
      axios.put("/criterion_lists/" + payload.list_id + "/criteria/" + payload.id, payload.fields)
        .then(() => {
          dispatch("clearErrors", "update");
          dispatch("findById", { id: payload.list_id });
          commit("system/setSuccessMessage", "success_message.update_feedback", { root: true });
          resolve();
        })
        .catch((error) => {
          error.response.status == 422 &&
          error.response.data.errors &&
          commit("setErrorFlags", { flag: "updateCriteria", errors: error.response.data.errors });
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "updateCriteria");
        });
    });
  },

  addCriteria({ commit, dispatch }, payload) {
    commit("clearErrors", "addCriteria");
    commit("startLoadingFlag", "addCriteria");

    return new Promise((resolve, reject) => {
      axios.post("/criterion_lists/" + payload.list_id + "/criteria", payload.fields)
        .then(() => {
          dispatch("clearErrors", "update");
          dispatch("findById", { id: payload.list_id });
          commit("auth/trackEvents", `[Manager] Feedback applied`, { root: true });
          commit("system/setSuccessMessage", "success_message.remove_feedback", { root: true });
          resolve();
        })
        .catch((response) => {
          response.response.status == 422 &&
          response.response.data.errors &&
          commit("setErrorFlags", { flag: "addCriteria", errors: response.response.data.errors });
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "addCriteria");
        });
    });
  },

  removeCriteria({ commit }, payload) {
    commit("clearErrors", "removeCriteria");
    commit("startLoadingFlag", "removeCriteria");

    return new Promise((resolve, reject) => {
      axios.delete("/criterion_lists/" + payload.list_id + "/criteria/" + payload.id)
        .then(() => {
          commit("system/setSuccessMessage", "success_message.remove_feedback", { root: true });
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "removeCriteria");
        });
    });
  },

  setFilters({ commit }, filters) {
    commit("setFilters", filters);
  },

  setCurrentPage({ commit }, page) {
    commit("setCurrentPage", page);
  },

  loadFeedbacks({ commit, getters }) {
    commit("startLoadingFlag", "loadFeedbacks");
    commit("setDataSelect", []);

    return new Promise((resolve, reject) => {
      axios.get("/criterion_lists", { params: getters.getParams })
        .then((response) => {
          commit("setDataSelect", response.data.data);
          commit("setPagination", response.data.meta.pagination);
          resolve();
        }).catch((response) => {
        reject({ message: response.response.data.detail });
      }).finally(() => {
        commit("stopLoadingFlag", "loadFeedbacks");
      });
    });
  },
}
