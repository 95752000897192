<template>
  <history-accordion
    :index="index"
  >
    <template #header>
      <div class="w-100 row align-items-center">
        <div class="col-lg-5">
          Cobrança PIX
        </div>
        <div class="col-lg-7">
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center gap-3">
              {{ formatCurrency(Number(change.transaction_amount)) }}
              <div class="text-small-2">
                <span
                  v-if="change.payment_status == 'expired'"
                  class="py-1 custom-badge"
                  style="color: #920000; background-color: #F2C6D1;"
                >
                  Expirado
                </span>
                <span
                  v-if="change.payment_status == 'approved'"
                  class="py-1 custom-badge"
                  style="color: #015D0D; background-color: #D6E9D9;"
                >
                  Pago
                </span>
                <span
                  v-if="change.payment_status == 'pending'"
                  class="py-1 custom-badge"
                  style="background-color: #F7EAC4; color: #754000;"
                >
                  Não Pago
                </span>
              </div>
            </div>
            <div class="me-4 text-small">
              <span class="text-muted d-block">Gerado em:</span>
              {{ timestampToDate(change.created_at, "DD/MM/YYYY [às] HH:mm") }}
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #body>
      <div class="row text-small">
        <div class="col-lg-2">
          <span class="d-block tx-text-200 fw-bold">Realizado por</span>
          {{ change.user_name }}
        </div>
        <div class="col-lg-10">
          <div class="d-flex justify-content-between">
            <div>
              <span class="d-block tx-text-200 fw-bold">Motivo da alteração</span>
              {{ change.change_reason }}
            </div>
            <div style="margin-right: 50px;">
              <span class="d-block tx-text-200 fw-bold">
                {{ change.payment_status === "pending" ? "Link do QR code" :
                  change.payment_status === "approved" ? "Pago em" : 'Expirado em' }}
              </span>
              <a
                v-if="change.payment_status === 'pending'"
                :href="'/pix/' + change.payment_id"
                target="_blank"
                :data-test="'pixPublic' + index + '-link'"
                class="btn-open-link"
              >
                Clique aqui para abrir
              </a>
              <span v-else>
                {{ timestampToDate(change.updated_at, "DD/MM/YYYY [às] HH:mm") }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </history-accordion>
</template>

<script>
import HistoryAccordion from './history-accordion';
import { Utils } from "@/utils";

export default {
  components: {
    HistoryAccordion,
  },

  props: {
    change: {
      type: Object,
      default: () => {}
    },

    index: {
      type: Number,
      default: null
    }
  },

  methods: {
    timestampToDate: Utils.timestampToDate,
    formatCurrency: Utils.formatCurrency
  }
}
</script>

<style lang="scss" scoped>
.custom-badge {
  padding-right: .5rem;
  padding-left: .5rem;
  border-radius: .375rem;
  line-height: .75rem;
}
.btn-open-link {
  color: #575778;

  &:hover {
    text-decoration: underline;
  }
}
</style>
