import axios from "axios";

export default {
  loadSupervisorList({ commit }) {
        commit('clearErrors')
        commit('startLoading')
        commit('setList', []);

        return new Promise((resolve, reject) => {
            axios.get('/users/role/supervisor')
                .then((response) => {
                    commit('setList', response.data.data);
                    resolve();
                })
                .catch((response) => {
                    commit('setAlert', { message: response.response.data.detail, type: 'danger' });
                    response.response.status == 422 && response.response.data.errors && commit('setErrors', response.response.data.errors)
                    reject({ message: response.response.data.detail });
                })
                .finally(() => {
                    commit('stopLoading')
                });
        })
    },
}
