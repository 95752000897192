<template>
  <div
    class="col-6"
    style="height: 176px;"
  >
    <div class="card card-hover h-100">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h4
          class="m-0 p-0 cerebri-sans"
          style="padding-top: 16px; padding-bottom: 16px;"
        >
          {{ $t('manager_feedback') }}
        </h4>
        <div>
          <button
            :disabled="!Object.values(agentStats).length"
            class="btn small tx-text-gray py-2 px-3"
            @click="$refs.showReview.openModal()"
          >
            {{ $t('see_rate') }}
          </button>
        </div>
      </div>
      <div
        v-if="!Object.values(agentStats).length"
        class="card-body d-flex justify-content-center align-items-center flex-column"
        style="padding-top: 16px; padding-bottom: 16px;"
      >
        <img
          alt="Pluszinha pesquisando"
          src="@/assets/img/search-pluszinha.svg"
          style="margin-bottom: 8px"
          width="56"
        >
        <h5 class="m-0 fw-normal cerebri-sans">
          {{ $t('no_rating_yet') }}
        </h5>
      </div>
      <div
        v-else
        class="card-body py-4 d-flex justify-content-center align-items-center"
        style="height: 80px;"
      >
        <div class="col">
          <div
            class="d-flex align-items-baseline"
            style="margin-bottom: 14px;"
          >
            <span class="h1 m-0">
              {{ averageGrades }}
            </span>
            <span class="h5 m-0 ps-1 fw-normal ">/10</span>
            <span
              class="score ms-2"
              style="vertical-align: baseline;"
            >
              <div class="score-wrap">
                <span
                  :style="`width: ${fillStars}%`"
                  class="stars-active"
                >
                  <i
                    aria-hidden="true"
                    class="fa fa-star"
                  />
                  <i
                    aria-hidden="true"
                    class="fa fa-star"
                  />
                  <i
                    aria-hidden="true"
                    class="fa fa-star"
                  />
                  <i
                    aria-hidden="true"
                    class="fa fa-star"
                  />
                  <i
                    aria-hidden="true"
                    class="fa fa-star"
                  />
                </span>
                <span
                  class="stars-inactive"
                  style="color: #FFBD00;"
                >
                  <i
                    aria-hidden="true"
                    class="fa fa-star-o"
                  />
                  <i
                    aria-hidden="true"
                    class="fa fa-star-o"
                  />
                  <i
                    aria-hidden="true"
                    class="fa fa-star-o"
                  />
                  <i
                    aria-hidden="true"
                    class="fa fa-star-o"
                  />
                  <i
                    aria-hidden="true"
                    class="fa fa-star-o"
                  />
                </span>
              </div>
            </span>
          </div>
          <h5 class="m-0 fw-normal tx-text-gray cerebri-sans">
            {{ $t('feedback_general_rate') }}
          </h5>
        </div>
      </div>
    </div>
    <show-review
      ref="showReview"
      :title="$t('rating')"
    />
  </div>
</template>

<script>
import ShowReview from "./show-review.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "StatusFeedback",

  components: {
    ShowReview,
  },

  computed: {
    ...mapGetters("agent/agent", {
      agentStats: "getAgentStats",
      loadingFlags: "getLoadingFlags",
    }),

    averageGrades() {
      if (this.agentStats) {
        return this.averageCalc() > 0 ? this.averageCalc().toFixed(1) : 0;
      }
      return 0
    },

    fillStars() {
      if (this.agentStats) {
        return this.averageCalc() > 0 ? this.averageCalc().toFixed(1) * 0.5 * 20 : 0;
      }
      return 0
    },
  },

  mounted() {
    this.findAgentFeedback()
  },

  methods: {
    ...mapActions("agent/agent", [
      "findAgentFeedback",
    ]),

    totalGradeSum() {
      return Object.values(this.agentStats?.criterion_json).reduce(
          (accumulator, curr) => accumulator + curr.total_grade, 0
      );

    },

    totalCountSum() {
      return Object.values(this.agentStats?.criterion_json).reduce(
          (accumulator, curr) => accumulator + curr.total_count, 0
      );
    },

    averageCalc() {
      return this.totalGradeSum() / this.totalCountSum()
    }
  }
}

</script>

<style
    lang="scss"
    scoped
>

.card {
  border: 1px solid $color-gray-blue-300;

  button {
    background-color: $color-gray-blue-300;
    border-radius: $border-radius;
  }
}

.card-hover {
  transition: 0.2s ease-in-out;

  &:hover {
    box-shadow: 2px 2px 15px 2px rgba(18, 38, 63, 0.13);
  }
}

.score {
  margin: auto;
  font-size: 16px;
  overflow: hidden;
}

.score-wrap {
  position: relative;
  margin: auto;
}

.score .stars-active {
  color: $color-yellow-300;
  z-index: 10;
  display: inline-block;
  vertical-align: bottom;
  overflow: hidden;
  white-space: nowrap;
}

.score .stars-inactive {
  color: grey;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-text-stroke: initial;
}

.fa {
  font-size: 1.5rem;
}

.cerebri-sans {
  font-family: 'Cerebri Sans', sans-serif;
}
</style>
