import axios from "axios";

export default {
  fetchRoutes({ commit, getters }) {
    commit("startLoading", "fetchRoutes");
    commit("setRoutes", []);
    return new Promise((resolve, reject) => {
      axios
        .get("/routes-settings", { params: getters.getRoutesParams })
        .then(response => {
          commit("setRoutes", response.data.data);
          commit("setRoutesPagination", response.data.meta.pagination);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "fetchRoutes");
        });
    });
  },

  createRoute({ commit, dispatch }, payload) {
    commit("startLoading", "createRoute");
    commit("clearErrors");
    return new Promise((resolve, reject) => {
      axios
        .post("/routes-settings", payload)
        .then(() => {
          dispatch("fetchRoutes");
          commit("system/setSuccessMessage", "A nova rota foi criada", { root: true });
          resolve();
        })
        .catch(response => {
          commit("setErrors", response.response.data.errors);
          commit("system/setErrorMessage", "Preencha os campos com valores válidos", { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "createRoute");
        });
    });
  },

  deleteRoute({ commit, dispatch }, payload) {
    commit("startLoading", "deleteRoute");
    commit("clearErrors");
    return new Promise((resolve, reject) => {
      axios
        .delete(`/routes-settings/${payload.endpoint}`, payload)
        .then(() => {
          dispatch("fetchRoutes");
          commit("system/setSuccessMessage", "A rota foi excluída", { root: true });
          resolve();
        })
        .catch(response => {
          commit("setErrors", response.response.data.errors);
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "deleteRoute");
        });
    });
  },

  clearParams({ commit }) {
    commit("clearParams");
  },
  spreadsheet({ commit }) {
    commit("setSpreadsheet", {});
    return new Promise((resolve, reject) => {
      axios
        .get("/route-pricing-table/change-logs")
        .then(response => {
          commit("setSpreadsheet", response.data.data);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        });
    });
  },
  downloadSpreadsheet({ commit }, email) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/route-pricing-table/download?email=${email}`)
        .then(response => {
          commit("system/setSuccessMessage", response.data.detail, { root: true });
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        });
    });
  },
  createSpreadsheet({ commit }, payload) {
    commit("startLoading", "createSpreadsheet");
    return new Promise((resolve, reject) => {
      axios
        .post("/route-pricing-table/upload", payload)
        .then(() => {
          commit("system/setSuccessMessage", "Os novos preços foram atualizados com sucesso.", {
            root: true
          });
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "createSpreadsheet");
        });
    });
  }
};
