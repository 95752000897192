export default {
  getLoadingFlags: state => state.loadingFlags,
  getList: state => state.list,
  getFilters: state => state.filters,
  getPagination: (state) => state.pagination,
  getErrors: state => state.errors,



  getRegister: (state) => state.register,
  getParams: (state) =>
    Object.assign(
      {
        'page': state.pagination.current_page,
        'per_page': state.pagination.per_page
      }
      , state.filters, { include: state.include.join(',') }),
  getDataSelect: (state) => state.dataSelect,
}
