<template>
  <div class="main-content">
    <div class="container-fluid">
      <div class="col-12 col-lg-12 col-xl-12">
        <header-component title="Cupons">
          <template #buttons>
            <button
              class="btn btn-primary px-6"
              @click="openCouponsModal()"
            >
              Adicionar novo cupom
            </button>
            <add-Coupons ref="addCouponsModal" />
          </template>
        </header-component>

        <div class="row">
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
            <div class="card card-new">
              <div
                class="card-body cursor-pointer"
                @click="openCouponsModal()"
              >
                <div class="row align-items-center gx-0">
                  <div
                    class="col overflow-ellipsis"
                    style="line-height: 36.19px"
                  >
                    <span class="h4 mb-0"> Adicionar novo cupom </span>
                  </div>
                  <div class="col-auto">
                    <span class="h2 fe fe-plus text-primary mb-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-xl-3 col-lg-4 col-md-4"
            v-if="loading"
          >
            <div class="card-body text-center">
              <div class="spinner-border text-muted">
                <span class="visually-hidden" />
              </div>
            </div>
          </div>
          <add-Coupons ref="addCouponsModal" />

          <div
            class="col-xl-3 col-lg-3 col-md-3 col-sm-3"
            v-for="(item, i) in list"
            :key="i"
          >
            <div
              class="card card-list-item"
              style="cursor: pointer"
              @click="editCouponsModal(item)"
              @mouseover="selectedItem = item.id"
            >
              <div class="card-body">
                <div class="row align-items-center gx-0">
                  <div class="col overflow-ellipsis one-line d-flex flex-column">
                    <span class="h4 mb-0">
                      {{ item.name }}
                    </span>
                  </div>
                  <div class="col-auto">
                    <span class="h2 fe fe-chevron-right text-primary mb-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import AddCoupons from "./components/add-coupons.vue";
import HeaderComponent from "@/components/header-component.vue";

export default {
  data() {
    return {
      term: "",
      modalMailing: undefined,
      modalDelimiter: undefined,
      modalHas_header: undefined,
      modalSeparator: undefined,
      selectedItem: "",
    }
  },

  mounted() {
    this.$store.dispatch("admin/coupons/findCoupons")
  },

  components: {
    AddCoupons,
    HeaderComponent
  },

  computed: {
    ...mapGetters("admin/coupons", {
      list: "getList"
    })
  },

  methods: {
    ...mapActions("admin/coupons", [
      "findCoupons"
    ]),
    ...mapMutations("admin/coupons", ["clearList"]),

    openCouponsModal() {
      this.$refs.addCouponsModal.new();
    },
    editCouponsModal(item) {
      this.$refs.addCouponsModal.edit(item);
    },

  },
}
</script>
