<template>
  <div class="col-12 col-xl-6">
    <div class="card">
      <div class="card-body d-flex align-items-center justify-content-between">
        <div class="refer-wrapper h-100 d-flex flex-column justify-content-between">
          <div class="header-wrapper">
            <h3 class="card-title">
              {{ $t("refer_now") }}
            </h3>
            <div class="card-subtitle text-medium tx-text-100">
              {{ $t("register_your_new_referral_right_now") }}
            </div>
          </div>
          <a
            class="btn btn-primary"
            :href="partnersURL"
            target="_blank"
          >
            <i class="fa-regular fa-plus me-2" />
            {{ $t("new_referral") }}
          </a>
        </div>
        <img
          alt="Stacked coins partner"
          src="@assets/img/partner/stacked-coins-partner.svg"
        >
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CardReferNow",
  data() {
    return {
      partnersURL: "https://lp.3cplusnow.com/3c-partners/",
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  height: 180px;
  .card-body {
    .refer-wrapper {
      a {
        width: 250px;
      }
    }
  }
}
</style>
