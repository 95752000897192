<template>
  <bar-chart
    :title="$t('qualifications_in_the_period')"
    :format-ticks="secondsToHoursFormat"
    :labels="chartLabels"
    :chart-data="chartData"
    :datasets="datasets"
    :loading="false"
  />
</template>
<script>
import {mapGetters} from "vuex";
import barChart from "@dialer/manager/components/bar-chart.vue";
import moment from "moment";

export default {
  name: "QualificationsInPeriodCard",
  components: {barChart},
  props: {
    chatsQualificationsData: {type: Array, default: () => []},
    period: { type: Object, default: () => ({})}
  },
  data() {
    return {
      mainContent: {},
      chartLabels: [],
      datasets: [],
      chartData: []
    };
  },
  mounted (){
    this.preparePeriodRange()
  },
  computed: {
    ...mapGetters("manager/performance_report", {
      loadingFlags: "getLoadingFlags",
    }),
  },
  watch: {
    chatsQualificationsData: {
      handler (data) {
        if (data){
          this.preparePeriodRange()
        }
      },
      deep: true,
    },
  },
  methods: {
    secondsToHoursFormat(value) {
      return value
    },

    preparePeriodRange() {
      if (this.period.emptyDays) {
        this.chartLabels = Object.values(this.chatsQualificationsData).map((day, index) => this.chartLabels[index] = moment(day.date).format('DD MMM'))
        this.prepareChartQualificationData(this.chatsQualificationsData);
      } else {
        this.chartLabels = this.createDateArray(this.period.start_date, this.period.end_date);
        this.prepareEmptyDaysMode()
      }
    },
    createDateArray(start_date, end_date) {
      const startDate = moment(start_date);
      const endDate = moment(end_date);
      const dateArray = [];

      while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
        dateArray.push(startDate.format('DD MMM'));
        startDate.add(1, 'days');
      }

      return dateArray;
    },
    prepareEmptyDaysMode() {
      this.chartLabels.map((date) => this.mainContent[date] = [])
      const qualifications = {};
      Object.entries(this.mainContent).forEach(([day], index) => {
        Object.entries(this.chatsQualificationsData).forEach((chatData) => {
          if (moment(chatData[0]).format('DD MMM') === day) {
            Object.values(chatData[1]).forEach((qualification) => {
              const name = qualification.name || '-';
              const count = qualification.count || 0;
              const color = qualification.color || '#c4c4c4';

              if (!qualifications[name]) {
                qualifications[name] = {
                  label: name,
                  color,
                  data: new Array(this.chartLabels.length).fill(0),
                  borderRadius: 10,
                  borderSkipped: 'middle'
                };
              }
              qualifications[name].data[index] += count;
            });
          }
        });
      });
        this.datasets = Object.values(qualifications).map(({ color, data, label, borderRadius, borderSkipped }) => {
          return { label, data, color, borderRadius, borderSkipped }
        });
        this.chartData = this.datasets.map(dataset => dataset.data);
    },

    prepareChartQualificationData() {
      const qualifications = {};
      const qualData = Object.entries(this.chatsQualificationsData);
      qualData.forEach((chatData, index) => {
        Object.values(chatData[1]).forEach((qualification) => {
          const name = qualification.name || '-';
          const count = qualification.count || 0;
          const color = qualification.color || '#c4c4c4';

          if (!qualifications[name]) {
            qualifications[name] = {
              label: name,
              color,
              data: new Array(this.chartLabels.length).fill(0),
              borderRadius: 10,
              borderSkipped: 'middle'
            };
          }
          qualifications[name].data[index] += count;
        });
      })
      this.chartData = [];
      this.chartData = new Array(Object.entries(qualifications).length).fill(new Array(this.chartLabels.length).fill(0));
      this.datasets = Object.values(qualifications).map(({ color, data, label, borderRadius, borderSkipped }) => {
        return { label, data, color, borderRadius, borderSkipped }
      });
      this.chartData = this.datasets.map(dataset => dataset.data);
    }
  },
}
</script>