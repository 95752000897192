<template>
  <modal-component
    :show="show"
    @close="close"
    :center="true"
  >
    <template #content>
      <div class="modal-header">
        <h5 class="modal-title">
          {{ $t('crm_integrations.integrations_modal.new_integration') }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div
        :class="{
          'd-none': step != 'webhook',
          'd-flex': step === 'webhook'
        }"
        class="modal-body flex-column"
        style="gap: 2rem; height: 502px"
      >
        <div class="d-flex align-items-center badge-meta">
          <img src="@assets/img/meta-blue.svg">
          <p class="m-0">
            Meta
          </p>
        </div>
        <div>
          <label
            for="inputUrl"
            class="form-label"
          >
            {{ $t('crm_integrations.integrations_modal.url_callback') }}
          </label>
          <input
            type="text"
            readonly
            class="form-control tx-text-gray"
            id="inputUrl"
            v-model="metaWebhookData.webhook"
          >
        </div>
        <div>
          <label
            for="inputToken"
            class="form-label"
          >
            {{ $t('crm_integrations.integrations_modal.activation_token') }}
          </label>
          <input
            type="text"
            readonly
            class="form-control tx-text-gray"
            id="inputToken"
            v-model="metaWebhookData.token"
          >
        </div>
        <div class="d-flex flex-column">
          <p class="m-0 tx-text-gray text-medium">
            1. Acesse a aba <strong class="fw-medium">Webhooks</strong> em <a
              href="https://developers.facebook.com"
              target="_blank"
              class="fw-medium"
            >developers.facebook.com;</a>
          </p>
          <p class="m-0 tx-text-gray text-medium">
            2. Selecione o modo <strong class="fw-medium">Page</strong>, crie uma nova inscrição e cole a URL e Token acima;
          </p>
          <p class="m-0 tx-text-gray text-medium">
            3. E assine o evento <strong class="fw-medium">Leadgen</strong>
          </p>
        </div>
        <div class="mt-auto" />
        <app-button
          @click="step = 'form-ids'"
          class="align-self-center"
          style="width: 260px"
        >
          Próximo
        </app-button>
      </div>
      <div
        :class="{
          'd-none': step != 'form-ids',
          'd-flex': step === 'form-ids'
        }"
        class="modal-body d-flex flex-column"
        style="gap: 2rem; height: 502px"
      >
        <div class="d-flex align-items-center badge-meta">
          <img src="@assets/img/meta-blue.svg">
          <p class="m-0">
            Meta
          </p>
        </div>
        <div>
          <div class="d-flex">
            <div class="form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="switchFormIds"
                v-model="submitData.select_forms_ids"
              >
            </div>
            <label
              for="inputIds"
              class="form-label"
            >
              {{ $t('crm_integrations.integrations_modal.form_select_oprional') }}
            </label>
            <span
              ref="infoFormIds"
              class="fal fa-circle-info fw-medium fs-5 tx-text-gray ms-3"
            />
          </div>
          <input
            @input.prevent="parseInputValue"
            :disabled="!submitData.select_forms_ids"
            type="email"
            class="form-control"
            id="inputIds"
            placeholder="Ex: 12345678901, 34567890123, 12312312312, ..."
            v-model="tempIds"
          >
        </div>
        <p class="m-0 tx-text-gray text-medium">
          1. Cole vários formulários os separando por vírgula.
        </p>
        <div class="mt-auto" />
        <app-button
          @click="submit"
          :loading="loadingFlags.postMetaIntegration"
          class="align-self-center"
          style="width: 260px"
        >
          Integrar
        </app-button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from "@components/modal-component.vue";
import AppButton from "@components/app-button.vue";

import {generateTooltip} from "@/utils.js";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "IntegrationModal",

  emits: ["close"],

  components: {
    ModalComponent,
    AppButton
  },

  props:{
    show:{
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      showModal: false,
      step: "webhook", //form-ids
      checkedFormIds: false,
      infoTooltip: null,
      submitData:{
        select_forms_ids: false,
        meta_forms_ids: [],
      },
      tempIds: "",
    }
  },

  created() {
    this.fetchMetaWebhookData()
  },

  mounted(){
    const text = "Com esta funcionalidade ativada, todos os formulários serão salvos no CRM";
    this.infoTooltip = generateTooltip(text, this.$refs.infoFormIds)
  },

  computed: {
    ...mapGetters("crm/integrations", {
      metaWebhookData: "getMetaWebhookData",
      loadingFlags: "getLoadingFlags"
    })
  },

  methods: {
    ...mapActions("crm/integrations", [
      "fetchMetaWebhookData",
      "postMetaIntegration",
      "fetchFunnelIntegrations"
    ]),

    close() {
      this.step = "webhook"
      this.$emit('close')
    },

    submit(){
      let numbersArray = this.tempIds.split(/[,\s]+/)
      numbersArray = numbersArray.map(item => Number(item));
      this.submitData.meta_forms_ids = numbersArray

      this.postMetaIntegration(this.submitData).then(()=>{
        this.submitData = {
          select_forms_ids: false,
          meta_forms_ids: [],
        }
        this.fetchFunnelIntegrations().finally(()=>{
          this.close()
        })
      })
    },

    parseInputValue(event){
      const parsedValue = event.target.value.replace(/[^\d,\s]/g, '')
      this.tempIds = parsedValue
    }
  }
}
</script>

<style scoped lang="scss">
.badge-meta {
  height: 32px;
  width: min-content;
  border-radius: 0.625rem;
  padding: 0 1rem;
  background-color: #E1E9F4;
  color: #3057F2;

  img {
    height: 14px;
    width: auto;
    margin-right: 0.625rem;
  }
}
</style>

