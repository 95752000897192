<template>
  <div class="card">
    <div class="card-header">
      <h4 class="mb-0">
        Meta
      </h4>
    </div>
    <div class="card-body">
      <div class="d-flex justify-content-end align-items-center">
        <p class="mb-0 w-100 fs-2">
          <span class="text-primary fw-medium">{{ viewingGoal(meta) }}</span>
          {{ $t('from') }} {{ meta.conversion_goal }} {{ $t('conversions') }}
        </p>
        <h2
          class="mb-0"
          style="font-size: 28px;"
        >
          {{ calculatePercentage(viewingGoal(meta), meta.conversion_goal) }}
        </h2>
      </div>
      <div class="d-flex justify-content-between">
        <div
          class="progress"
          :style="{
            width: calculatePercentage(meta.converted, meta.conversion_goal)
          }"
          style="height: 8px;margin: 16px 4px  16px 0;"
        >
          <div
            class="progress-bar"
            role="progressbar"
            style="width: 100%"
            aria-valuenow="100"
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
        <div
          v-if="meta.converted !== meta.conversion_goal"
          class="progress"
          :style="{ width: calculateWidth(meta.converted, meta.conversion_goal) }"
          style="height: 8px;margin: 16px 0"
        >
          <div
            class="progress-bar bg-gray-200"
            role="progressbar"
            style="width: 100%"
            aria-valuenow="100"
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <dot-status
            size="7px"
            color="var(--color-blue-300)"
            style="margin-right: 4px;margin-bottom: 1px"
          />
          <small class="mb-0">
            {{ $t('uppercases.concluded') }}
          </small>
        </div>
        <div class="d-flex align-items-center">
          <dot-status
            style="margin-right: 4px;margin-bottom: 1px"
            size="7px"
            color="var(--color-gray-200)"
          />
          <small class="mb-0">
            {{ $t('uppercases.pending') }}
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DotStatus from "@components/dot-status.vue";

export default {
  name: "ListMeta",
  components: { DotStatus },
  props: {
    meta: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    calculatePercentage(value, total) {
      return ((value / total) * 100 || 0).toFixed(0) + "%";
    },
    calculateWidth(value, total) {
      const amount = this.calculatePercentage(value, total);
      let formatted = amount.replace("%", "");
      return (100 - formatted).toFixed(0) + "%";
    },
    viewingGoal(item) {
      if (item.converted >= item.conversion_goal) {
        return item.conversion_goal;
      }
      return item.converted;
    }
  }
};
</script>

<style lang="scss" scoped></style>