<template>
  <VDropdown
    :distance="10"
    placement="bottom"
    :triggers="[trigger]"
    v-if="isAgent"
  >
    <div
      class="button-whatsapp btn btn-primary shadow-none background-loading button-top-bar ms-0 d-flex justify-content-center align-items-center"
      :class="{
        'whatsapp-calling': isWhatsappCalling,
        'no-hover': isRamalBuzy,
        'small-active': small
      }"
      data-test="makeCall-button"
      @click="canStartWhatsappCall && _startWhatsappCall(currentChat[this.$route.params.id].number)"
    >
      <span class="fe fe-phone" />
      <span
        class="fe fe-phone-call"
      />
    </div>
    <template
      #popper
      v-if="onlyHaveWhatsapp || isRamalBuzy"
    >
      <is-ramal-buzy-component :is-ramal-buzy="isRamalBuzy" />
    </template>
  </VDropdown>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import IsRamalBuzyComponent from "@dialer/manager/components/topbar/is-ramal-buzy-component.vue";

export default {
  components: {IsRamalBuzyComponent},
  props: {
    small: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters("auth", {
      onlyHaveWhatsapp: "onlyHaveWhatsapp",
      isAgent: "isAgent"
    }),

    ...mapGetters("whatsapp/chat", {
      currentChat: "getCurrentChat",
    }),

    ...mapGetters("agent/call", {
      loadingFlagsCall: "getLoadingFlags",
      whatsappCallCurrentNumber: "getWhatsappCallCurrentNumber"
    }),

    trigger() {
      if (this.onlyHaveWhatsapp) return 'click'
      else if (this.isRamalBuzy) return 'hover'

      return ''
    },

    isRamalBuzy() {
      const isAnotherClientInCall = this.whatsappCallCurrentNumber != this.currentChat[this.$route.params.id].number && this.whatsappCallCurrentNumber != ''
      const isInCall = this.whatsappCallCurrentNumber == this.currentChat[this.$route.params.id].number && !this.loadingFlagsCall.whatsappCall

      return isAnotherClientInCall || isInCall
    },

    isWhatsappCalling() {
      return this.loadingFlagsCall.whatsappCall && this.whatsappCallCurrentNumber == this.currentChat[this.$route.params.id].number
    },

    canStartWhatsappCall() {
      return !this.onlyHaveWhatsapp && !this.loadingFlagsCall.whatsappCall && !this.isRamalBuzy
    }
  },

  methods: {
    ...mapActions("agent/call", ["startWhatsappCall"]),
    ...mapMutations("auth", ["trackEvents"]),

    _startWhatsappCall(number) {
      this.startWhatsappCall(number)
        .then(() => {
          this.$router.push('/agent')
          this.trackEvents("[Agent] Omni Dialer Call")
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.button-top-bar {
  width: 32px;

  .fe-phone-call {
    display: none;
  }

  &:not(.no-hover):hover .fe-phone {
    display: none;
  }

  &:not(.no-hover):hover .fe-phone-call {
    display: inline-block;
  }

  &.whatsapp-calling {
    cursor: default;
    animation: border-increase 1s linear infinite !important;
    background: $color-blue-300 !important;

    .fe-phone {
      display: none;
    }

    .fe-phone-call {
      display: inline-block;
      animation: spin 1s linear infinite;
      color: $color-text-light;
    }
  }

  &.no-hover {
    opacity: 0.65;
    cursor: auto;
  }
}

.tooltip-only-have-whatsapp {
  width: 266px;

  img {
    height: 4.6rem;
    width: 6rem;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(0deg);
  }
}

@keyframes border-increase {
  25% {
    outline: 1.78px solid rgba(126, 156, 247, 1);
  }
  50% {
    outline: 3.56px solid rgba(126, 156, 247, 1);
  }
}

button, .button-whatsapp {
  min-width: 1.244rem;
  min-height: 1.244rem;
  background-color: #e1e9f4;
  border: none;
  color: #95aac9;
  border-radius: $whatsapp-border-radius;

  &:hover:not(.no-hover), .whatsapp-calling {
    background-color: $color-blue-300 !important;
    color: #e3ebf6 !important;
  }
}

.button-top-bar {
  padding: $whatsapp-button-padding 0.807rem;
  height: $whatsapp-button-size !important;
  color: $color-text-gray !important;

  &:not(:first-child) {
    padding: $whatsapp-button-padding !important;
    height: $whatsapp-button-size !important;
    width: $whatsapp-button-size !important;
  }
}

.button-whatsapp {
  &.small-active {
    max-width: 32px !important;
    min-width: 32px !important;
    width: 32px !important;
    padding: 0.25rem !important;
    font-size: 0.875rem !important;
  }
}
</style>