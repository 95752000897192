<template>
  <div class="agent position-relative">
    <iframe-integration />
    <deactivated-extension v-if="!['dialer', 'manual'].includes(mode) && isRingGroupPermissionEnabled && !isRingGroupActive" />
    <not-logged-in-campaign v-if=" !isRingGroupPermissionEnabled && (registered || isExternalSipActive) && !campaign && status === AgentStatus.OFFLINE" />
    <allow-mic-toast />
    <agent-dashboard
      v-if="
        !loadingFlags.findLoggedCampaign &&
          AgentStatus.IDLE == status &&
          mode == 'dialer' &&
          campaign?.agent_dashboard
      "
    />
    <card-interval />
    <no-internet-connection v-if="showDisconnectionWarning" />

    <div
      v-if="AgentStatus.IDLE == status && mode == 'dialer' && campaign"
      class="position-fixed position bottom-0 d-flex flex-column align-items-center"
    >
      <div
        class=" position-fixed  bottom-0 container-overflow"
        style="z-index: 1"
      >
        <slide-transition
          position="bottom"
          translate-bottom-y="200%"
        >
          <exit-campaign
            v-if="show"
            @close="show = false"
          />
        </slide-transition>
      </div>
      <button
        class="btn btn-primary text-center focus-out rounded-0 rounded-top position-relative"
        style="width: 72px; height: 32px;"
        @click="toggleShow"
      >
        <i
          class="fa-regular fw-medium m-0 position-absolute translate-middle"
          :class="show ? 'fa-chevron-double-down' : 'fa-chevron-double-up'"
          style="top: 58% !important;"
        />
      </button>
    </div>
  </div>

  <div
    class="position-fixed top-50 start-50 translate-middle"
    style="z-index: 11"
  >
    <div
      ref="refusedCallToast"
      id="refusedCallToast"
      class="toast align-items-center bg-warning border-0"
      style="width: 400px"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="d-flex align-items-center justify-content-center">
        <div class="toast-body">
          {{ $t('refused_call_or_occupied_extension') }}
        </div>
        <button
          type="button"
          class="btn text-small-2 mt-1 ms-3"
          data-bs-dismiss="toast"
          aria-label="Close"
        >
          <i class="fa-regular fa-x" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { AgentStatus } from "@/utils";
import { Toast } from "bootstrap";

import CardInterval from "./extension/components/card-interval.vue";
import ExitCampaign from "./extension/components/exit-campaign.vue";
import IframeIntegration from "./extension/components/iframe-integration.vue";
import AgentDashboard from "./extension/components/agent-dashboard.vue";
import SlideTransition from "@components/slide-transition.vue";
import DeactivatedExtension from "@dialer/agent/pages/pabx/deactivated-extension.vue";
import NotLoggedInCampaign from "@dialer/agent/pages/campaign/not-logged-in-campaign.vue";
import AllowMicToast from "@dialer/agent/components/allow-mic-toast.vue";
import NoInternetConnection from "@dialer/agent/components/top-bar/offline/no-internet-connection.vue";


export default {
  data() {
    return {
      AgentStatus,
      show: false,
      transitioning: false,
      showDisconnectionWarning: false,
    };
  },

  computed: {
    ...mapGetters("agent/agent", {
      campaign: "getCampaign",
      loadingFlags: "getLoadingFlags",
    }),

    ...mapGetters("system", {
      registered: "isRegistered",
      mode: "getMode",
      status: "getStatus",
      socketIOStatus: "getSocketIOStatus",
    }),

    ...mapGetters("auth", {
      isExternalSipActive: "isExternalSipActive"
    }),

    ...mapGetters("agent/ring_group", {
      isRingGroupPermissionEnabled: "isRingGroupPermissionEnabled",
      isRingGroupActive: "isRingGroupActive",
      showToast: "getShowToast"
    })
  },

  watch: {
    registered(status) {
      if (status) this.clearErrors();
    },

    showToast(value) {
      if (value) {
        const toastEl = this.$refs.refusedCallToast;
        const toast = new Toast(toastEl, {
          delay: 3000
        });

        toast.show();
        this.setShowToast(false);
      }
    },
    socketIOStatus(value) {
      this.showDisconnectionWarning = value === "disconnected";
    }
  },

  components: {
    SlideTransition,
    CardInterval,
    IframeIntegration,
    AgentDashboard,
    ExitCampaign,
    DeactivatedExtension,
    NotLoggedInCampaign,
    AllowMicToast,
    NoInternetConnection,
  },

  methods: {
    ...mapMutations("system", ["clearErrors"]),
    ...mapActions("system", ["connect"]),
    ...mapActions("agent/ring_group", ["setShowToast"]),
    toggleShow() {
      if (!this.transitioning) {
        this.transitioning = true;
        this.show = !this.show;
        setTimeout(() => {
          this.transitioning = false;
        }, 1000);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.agent {
  padding-left: 64px;
  height: calc(100vh - 56px);
  font-family: "Cerebri Sans", sans-serif !important;
}

.position {
  bottom: 0;
  left: calc(50% + 63px);
  transform: translateX(calc(-50% - 33px));
}

.focus-out {
  outline: none !important;
  box-shadow: none !important;
  transition: 0.3s ease-in-out;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;

  &:hover {
    scale: 1.08;
  }
}

.container-overflow {
  margin-bottom: 32px;
}

@media (min-width: 1600px) {
  .position {
    left: calc(50% + 63px);
    transform: translateX(calc(-50% - 30px));
  }
}
</style>
