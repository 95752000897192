<template>
  <div class="d-flex">
    <div
      v-for="(step, idx) in steps"
      :key="idx"
      :class="[stepClasses(step, idx), customClass]"
      class="rounded-5 d-flex align-items-center justify-content-center"
    >
      <i :class="step.completed ? 'fas fa-check-circle blue_300' : 'far fa-dot-circle'" />
      <span
        v-if="step.showSpan"
        :class="step.completed ? 'blue_300 span' : 'tx-text-gray'"
      >{{
        step.completed ? "" : "-------"
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepByStep",
  props: {
    index: {
      type: Number,
      required: true
    },
    customClass: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      steps: [
        { color: "blue_300", completed: false, showSpan: true },
        { color: "gray", completed: false, showSpan: true },
        { color: "gray", completed: false, showSpan: false }
      ]
    };
  },
  methods: {
    stepClasses(step) {
      return ["step", step.color, { completed: step.completed }];
    },
    markStepCompleted(newValue) {
      for (let i = 0; i < this.steps.length; i++) {
        if (i <= newValue) {
          this.steps[i].completed = true;

          if (i < this.steps.length - 1) {
            this.steps[i + 1].color = "blue_300";
          }

          if (i === 0) {
            this.steps[i + 1].showSpan = true;
          }

          if (newValue === 1) {
            this.steps[2].completed = true;
          }
        } else {
          this.steps[i].completed = false;

          if (i < this.steps.length - 1) {
            this.steps[i + 1].color = "gray";
          }

          if (i === 0) {
            this.steps[i + 1].showSpan = true;
          }
        }
      }
    }
  },
  mounted() {
    this.markStepCompleted(this.index);
  },
  watch: {
    index(newValue) {
      this.markStepCompleted(newValue);
    }
  }
};
</script>

<style lang="scss" scoped>
.step {
  display: inline-block;
}

.blue_300 {
  color: $color-blue-300 !important;
}

.gray {
  color: $color-gray-500;
}

.completed {
  color: #0b8ad9;
  font-weight: bold;
}

.span {
  border: 1px solid $color-blue-300;
  height: 1px !important;
  display: inline-flex;
  width: 60px;
}
</style>
