    export default {
  startLoading: state => state.loading = true,
  stopLoading: state => state.loading = false,
  startLoadingFlag: (state, flag) => state.loadingFlags[flag] = true,
  stopLoadingFlag: (state, flag) => state.loadingFlags[flag] = false,

  // Active Campaigns
  setActiveCampaigns: (state, activeCampaigns) => {
    state.activeCampaigns = activeCampaigns.reduce((activeCampaignsById, campaign) => {
      activeCampaignsById[campaign.id] = campaign;

      return activeCampaignsById;
    }, {});
  },
  setActiveCampaignsPagination: (state, activeCampaignsPagination) =>
    state.activeCampaignsPagination = activeCampaignsPagination,
  setActiveCampaignsCurrentPage: (state, page) => state.activeCampaignsPagination.current_page = page,

  // Paused Campaigns
  setPausedCampaigns: (state, pausedCampaigns) => state.pausedCampaigns = pausedCampaigns,
  setPausedCampaignsPagination: (state, pausedCampaignsPagination) =>
    state.pausedCampaignsPagination = pausedCampaignsPagination,
  setPausedCampaignsCurrentPage: (state, page) => state.pausedCampaignsPagination.current_page = page,

  // Dashboard Show Campaign
  setCampaign: (state, campaign) => state.campaign = campaign,

  // Real Time Handling
  insertOnlineAgentInActiveCampaign: (state, event) => {
    let { campaignId, agent } = event;

    if(!state.activeCampaigns[campaignId].campaign_information.quantity_of_agents_with_online_status[agent.id]) {
      state.activeCampaigns[campaignId].
          campaign_information.quantity_of_agents_with_online_status[agent.id] = agent;
    }
  },

  removeAgentFromActiveCampaign: (state, event) => {
    let { campaignId, agent } = event;

    if(state.activeCampaigns[campaignId].campaign_information.quantity_of_agents_with_online_status[agent.id]) {
      delete state.activeCampaigns[campaignId].campaign_information.quantity_of_agents_with_online_status[agent.id];
    }
  },

  clearCampaignsData: (state) => {
    state.activeCampaigns = [];
    state.activeCampaignsPagination = {
      current_page: 1,
    };

    state.pausedCampaigns = [];
    state.pausedCampaignsPagination = {
      current_page: 1
    };

    state.campaign = null;
  },

  setFilters: (state, filters) => state.filters = filters,
  setLoadingContext: (state, loadingContext) => state.loadingContext = loadingContext,
  setLoadingActive: (state, loading) => state.loadingActive = loading,
  setLoadingPaused: (state, loading) => state.loadingPaused = loading,
  setListActive: (state, list) => state.listActive = list,
  setListPaused: (state, list) => state.listPaused = list,
  setPaginationActive: (state, pagination) => state.paginationActive = pagination,
  setPaginationPaused: (state, pagination) => state.paginationPaused = pagination,
  setRegister: (state, register) => state.register = register,
  setErrors: (state, errors) => { state.errors = errors; },
  setCreateData: (state, createData) => state.createData = createData,
  clearErrors: (state) => {
    state.errors = {}
    state.errorMessage = ""
  },
  setPagination: (state, pagination) => {
    state.pagination = pagination;
  },
  setFilter: (state, filters) => state.filters = filters,
  setInclude: (state, include) => state.include = include,
  setDataSelect: (state, dataSelect) => state.dataSelect = dataSelect,

  setAgetsPerCampagin: (state, agetsPerCampagin) => { state.agetsPerCampagin = agetsPerCampagin },

  setTotalAgentsPerCampagin: (state) => {
    state.totalAgentsPerCampagin = {}
  },

  // setAgetsPerCampagin: (state) => {
  //   state.agetsPerCampagin = {}
  // },

  addTotalAgentsPerCampaign: (state, payload) => {
    state.totalAgentsPerCampagin[payload.campaign_id] = payload.agents;
  },
  setCompanyUraLimit: (state, companyUraLimit) => state.companyUraLimit = companyUraLimit
}
