<template>
  <div class="container-fluid">
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-lg-10 col-xl-8">
        <main-loading v-if="!asyncReady" />
        <div v-if="asyncReady">
          <header-component
            :title="$t('my_account_capitalized')"
            :pretitle="$t('profile')"
            redirect-link="/manager/users"
          />

          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="form-label">
                  {{ $t('name_capitalize') }}
                </label>

                <input
                  type="text"
                  class="form-control"
                  :class="{
                    'form-control': true,
                    'is-invalid': errors.name
                  }"
                  v-model="fields.name"
                >
                <div class="invalid-feedback">
                  {{ errors.name && errors.name[0] }}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <label class="form-label">
                      {{ $t('extension_capitalize') }}
                    </label>

                    <input
                      type="number"
                      class="form-control"
                      v-model="fields.extension_number"
                      v-maska
                      data-maska="#########"
                      :class="{
                        'form-control': true,
                        'is-invalid': errors.extension_number,
                      }"
                    >
                    <div class="invalid-feedback">
                      {{ errors.extension_number && errors.extension_number[0] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-md-6"
              v-if="fields.role == 'supervisor' || fields.role == 'manager'"
            >
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <label class="form-label">
                      Email
                    </label>

                    <input
                      type="email"
                      class="form-control"
                      v-model="fields.email"
                      :class="{
                        'form-control': true,
                        'is-invalid': errors.email,
                      }"
                    >
                    <div class="invalid-feedback">
                      {{ errors.email && errors.email[0] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12"
              :class="{
                'col-md-6': fields.role == 'supervisor' || fields.role == 'manager'
              }"
            >
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <label class="form-label">
                      {{ $t('password_capitalizes') }}
                    </label>

                    <div class="input-group input-group-merge">
                      <input
                        id="password"
                        :type="showPassword ? 'text' :'password'"
                        class="form-control"
                        :class="{
                          'form-control': true,
                          'is-invalid': errors.password,
                        }"
                        v-model="fields.password"
                      >
                      <div
                        @click="showPassword = !showPassword"
                        class="input-group-text"
                      >
                        <i
                          class="fe fe-eye"
                          v-if="!showPassword"
                        />
                        <i
                          class="fe fe-eye-off"
                          v-else
                        />
                      </div>
                    </div>
                    <small
                      v-if="fields.password.length > 0"
                      class="text-muted"
                    >
                      {{ $t('characters_validation') }}
                    </small>
                    <small class="text-danger">
                      {{ errors.password && errors.password[0] }}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 d-flex justify-content-center fs-3">
            <div class="form-group">
              <a
                class="cursor-pointer"
                @click="showAdvancedOptions = !showAdvancedOptions"
              ><small class=" fs-3">
                <span
                  class=" fs-3"
                  v-if="!showAdvancedOptions"
                >+</span>
                <span
                  class=" fs-3"
                  v-else
                >-</span>
                {{ $t('advanced_options') }}.</small></a>
            </div>
          </div>
          <template v-if="showAdvancedOptions">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label">
                    {{ $t('language') }}
                  </label>
                  <single-select
                    v-model="fields.language"
                    :list-data="languages"
                    label="name"
                    custom-label-return="locale"
                    :placeholder="$t('select')"
                    :errors="errors.language"
                    :error-label="$t('language')"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label class="form-label">
                    CPF
                  </label>

                  <input
                    type="text"
                    class="form-control"
                    :class="{
                      'form-control': true,
                      'is-invalid': errors.user_document,
                    }"
                    v-model="fields.user_document"
                    v-maska
                    data-maska="###.###.###-##"
                  >

                  <div class="invalid-feedback">
                    {{ errors.user_document && errors.user_document[0] }}
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label class="form-label">
                    {{ $t('time_zone_capitalize') }} <small class="text-danger">*</small>
                  </label>
                  <select
                    class="form-select"
                    :aria-label="$t('time_zone_capitalize')"
                    :class="{
                      'form-control': true,
                      'is-invalid': errors.timezone,
                    }"
                    v-model="fields.timezone"
                  >
                    <option
                      v-for="timezone in timezones"
                      :key="timezone.value"
                      :value="timezone.value"
                    >
                      {{ timezone.text_value }}
                    </option>
                  </select>
                  <div class="invalid-feedback">
                    {{ errors.timezone && errors.timezone[0] }}
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label class="mb-1">
                    {{ $t('api_token') }}
                  </label>

                  <div class="input-group input-group-merge">
                    <input
                      type="email"
                      class="form-control"
                      v-model="fields.api_token"
                      disabled
                    >
                    <button
                      class="btn btn-primary"
                      @click="fields.api_token = generateRandomCharacters(60)"
                    >
                      <span class="fe fe-refresh-ccw" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-auto form-check form-switch">
                    <input
                      v-model="external_sip"
                      type="checkbox"
                      class="form-check-input"
                    >
                  </div>
                  <div class="col">
                    <div class="row align-items-center g-0">
                      <div class="col-auto">
                        <span class="h2 me-2 mb-0 fw-normal">{{ $t('connect_sip') }}</span>
                        <small class="form-text text-muted">{{ $t('connect_sip_description') }}</small>
                      </div>
                    </div>
                  </div>
                  <div
                    class="spinner-border text-secondary"
                    role="status"
                    v-if="loading"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <div
                  v-if="external_sip"
                  class="row align-items-center pt-3"
                >
                  <div class="col-6 col-md-6 ">
                    <label
                      class="form-label"
                      for="userRamal"
                    >{{ $t('extension_user') }}</label>
                    <div class="mb-2 input-group input-group-merge">
                      <input
                        class="form-control"
                        id="userRamal"
                        type="text"
                        :value="this.fields.telephony_id"
                        disabled
                      >
                      <div class="input-group-text list-group-item-light">
                        <label
                          for=""
                          class="fe fe-lock"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-6">
                    <label
                      class="label-control"
                      for="passwordRamal"
                    >{{ $t('extension_password') }}</label>
                    <div class="input-group input-group-merge">
                      <input
                        class="form-control"
                        id="passwordRamal"
                        type="text"
                        :value="this.fields.extension_password"
                        disabled
                      >
                      <div class="input-group-text list-group-item-light">
                        <label
                          for=""
                          class="fe fe-lock"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-12"
              >
                <div class="card">
                  <div class="card-body py-2">
                    <div class="row align-items-center">
                      <div class="col-auto form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="switchOne"
                          v-model="webphone"
                        >
                      </div>
                      <div class="col">
                        <div class="row align-items-center g-0">
                          <div class="col-auto">
                            <span class="h2 me-2 fw-normal">
                              {{ $t('enable_webphone') }}
                            </span>
                            <small class="form-text text-muted">
                              {{ $t('enable_webphone_description') }}
                            </small>
                          </div>
                        </div>
                      </div>


                      <div
                        class="spinner-border text-secondary"
                        role="status"
                        v-if="loading"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div
            class="col-lg-12"
            v-if="active === 1"
          >
            <app-button
              class="fw mb-4"
              :loading="loading"
              @click="submit()"
            >
              {{ $t('update_omni') }}
            </app-button>
          </div>
          <div class="text-center text-muted mb-5">
            <span> {{ $t('license_1') }} <router-link to="/terms-of-use">{{ $t('license_2') }} </router-link></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

import { timezones, countryCodeToUnicodeFlag } from "@/utils";

import AppButton from "@/components/app-button.vue";
import MainLoading from "@/components/main-loading.vue";
import HeaderComponent from "@/components/header-component.vue";
import SingleSelect from "@/components/select/single-select.vue";

export default {
  name: "AddUser",
  components: {
    AppButton,
    MainLoading,
    HeaderComponent,
    SingleSelect,
  },
  data() {
    return {
      timezones: [],
      languages: [],
      countries: [
        { locale: 'pt', localeCode: 'pt-br', code: 'BR', name: 'Português (BR)' },
        { locale: 'en', localeCode: 'en-us', code: 'US', name: 'English (USA)' },
        { locale: 'es', localeCode: 'es-es', code: 'ES', name: 'Español (ES)' },
      ],
      fields: {
        name: "",
        email: "",
        extension_number: 0,
        password: "",
        role: "",
        timezone: "",
        user_document: "",
        campaigns: [],
        queues: [],
        teams: [],
        telephony_id: "",
        extension_password: "",
        api_token: "",
        permissions: [],
        language: ""
      },
      active: 0,
      webphone: false,
      external_sip: null,
      asyncReady: false,
      showPassword: false,
      showWarningModal: true,
      showAdvancedOptions: false,
      isCampaignListManagementAllowed: false
    };
  },
  computed: {
    ...mapGetters("manager/user", {
      list: "getList",
      loading: "isLoading",
      register: "getRegister",
      errors: "getErrors"
    }),
    ...mapGetters("auth", {
      user: "getUser"
    }),
  },
  methods: {
    countryCodeToUnicodeFlag,
    ...mapActions("manager/user", [
      "update",
      "findById",
      "switchWebPhone",
      "switchExtension",
    ]),

    ...mapActions("auth", ["me"]),

    /**
     * submit
     */
    submit() {
      this.errorMessage = "";
      let fields = { ...this.fields };

      fields.campaigns = fields.campaigns.map(({ id }) => id);
      fields.queues = fields.queues.map(({ id }) => id);
      fields.teams = fields.teams.map(({ id }) => id);

      if (this.isCampaignListManagementAllowed)
        this.fields.permissions.push("manage.lists.in.campaign");

      if (fields.user_document)
        fields.user_document = fields.user_document.replace(/[^0-9]/g, "");
      else delete fields.user_document;

      fields.language = this.countries.find(country => country.locale === fields.language).localeCode;

      //update Exteral SIP
      this.switchExtension({
        id: this.register.id,
        enable: this.external_sip ? 0 : 1
      });

      // update Webphone
      this.switchWebPhone({ id: this.register.id });

      //Update user
      this.update({ fields: fields, id: this.register.id })
          .then(() => {
            this.$root.$i18n.locale = this.fields.language;
            this.me();
            this.$router.push({
              path: `/manager/users/`
            });
          })
          .catch(data => {
            this.errorMessage = data.message;
          });
    },

    /**
     * generate random characteres for API Token
     */
    generateRandomCharacters(lenght) {
      var length = lenght,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }

      return retVal;
    },

    /**
     * populate fields
     */
    async awaitDispatches() {
      await this.$store.dispatch("manager/user/listFilters");
      await this.$store
          .dispatch("manager/user/findById", {
            id: this.user.id
          })
          .then(() => {
            this.fields.name = this.register.name;
            this.fields.email = this.register.email;
            this.fields.extension_number = this.register.extension.extension_number;
            this.fields.role = this.register.role.name;
            this.fields.timezone = this.register.settings.timezone;
            this.fields.user_document = this.register.user_document;
            this.fields.campaigns = this.register.campaigns;
            this.fields.teams = this.register.teams;
            this.fields.queues = this.register.receptive_queues;
            this.fields.telephony_id = this.register.telephony_id;
            this.fields.extension_password = this.register.extension_password;
            this.fields.api_token = this.register.api_token;
            this.fields.language = this.$i18n.locale || this.countries[0].locale;

            this.isCampaignListManagementAllowed = Object.values(this.register.permissions).length > 0;

            this.active = this.register.active;
            this.webphone = this.register.webphone;

            //  The external sip is only active when the web extension is inactive
            this.external_sip = !this.register.settings.web_extension;
          });

      this.languages = this.countries.map(country => ({
        ...country,
        name: `${this.countryCodeToUnicodeFlag(country.code)} ${country.name}`,
      }));
    },
    getCountryByLocale(locale) {
      return this.countries.find(country => country.locale === locale);
    }
  },
  async mounted() {
    await this.awaitDispatches();
    this.asyncReady = true;
    this.timezones = timezones;
  },
};
</script>
