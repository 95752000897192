<template>
  <div
    class="d-flex"
    style="padding: 0.5rem 1.5rem 1.5rem 1.5rem; gap: 1.5rem"
  >
    <app-button
      class="col"
      @click="finish"
    >
      <span
        class="far fa-check-circle"
        style="margin-right: 0.5rem"
      />
      {{ $t('finalize') }} Chatbot
    </app-button>
    <app-button
      class="col"
      @click="toggleOpenTransferForm()"
    >
      <span
        class="far fa-arrow-right-arrow-left"
        style="margin-right: 0.5rem"
      />
      {{ $t('transfer_chat') }}
    </app-button>
  </div>
</template>

<script>
import AppButton from "@components/app-button.vue";
import {mapActions, mapMutations} from "vuex";

export default {
  name: "ChatbotChatOptions",

  components: {
    AppButton
  },

  methods: {
    ...mapActions("whatsapp/chat", ["finishChat"]),
    ...mapMutations("whatsapp/chat", [
      "removeChatById",
      "toggleOpenTransferForm"
    ]),
    ...mapMutations("system", [
      "setSuccessMessage",
      "setCustomizedTitleMessage"
    ]),

    finish() {
      this.finishChat({
        id: this.$route.params.id,
        fields: {
          qualification: undefined,
          qualification_note: "",
        }
      }).then(() => {
        this.removeChatById({ id: this.$route.params.id });
        this.$router.push("/whatsapp").then(() => {
          this.setSuccessMessage(this.$t('success_finished_chat'));
          this.setCustomizedTitleMessage(this.$t('success'));
        });
      });
    },
  }
}
</script>
