import groupChatsReport from "@dialer/manager/pages/reports/group-channels/group-channels-report.vue";

export default {
    path: "group-channels-report",
    name: "group-channels-report",
    components: {
        main: groupChatsReport,
    },
    meta: {
        "short-side-bar": true
    }
};
