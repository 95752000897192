export default {
    getLoadingFlags: state => state.loadingFlags,
    getList: state => state.list,
    getErrorMessage: state => state.errorMessage,
    getErrors: state => state.errors,
    getLoadingContext: state => state.loadingContext,
    getCampaign(state) {
        return state.campaign
    },
    getRegister: state => state.register,
    getDeliveredCalls: state => state.deliveredCalls,
}
