export default {
  loadingFlags: {
    getList: false,
    createIvr: false,
    findById: false,
    update: false,
    remove: false,
    getDataSelect: false,
  },
  errors: {},
  list: [],
  register: null,
  filters: {},
  include: ['keys'],
  pagination: {
    per_page: 12,
    current_page: 1
  },
  dataSelect: [],
  groupChannels: [],
  quickMessages: [],
  templates: [],
}
