<template>
  <form
    novalidate
    @submit.prevent="$emit('submitForm')"
    class="row flex-column gap-4"
  >
    <div
      class="col-12"
    >
      <label
        class="form-label cursor-pointer txt-text-200"
        for="name"
      >
        {{ $t('name_capitalize') }}
      </label>

      <input
        id="name"
        name="name"
        type="text"
        class="form-control txt-text-200"
        v-model="categoryData.name"
        :class="{ 'is-invalid': errors.name }"
        maxlength="250"
      >
      <div class="invalid-feedback">
        {{ errors.name && errors.name[0] }}
      </div>
    </div>
    <div
      class="col-12"
    >
      <label
        class="form-label cursor-pointer txt-text-200"
        for="team_ids"
      >
        {{ $t('teams_capitalize') }}
      </label>

      <template v-if="enableFlag">
        <multi-select
          :list-data="teams"
          label="name"
          custom-label-return="id"
          v-model="categoryData.team_ids"
          :errors="errors"
          error-label="team_ids"
          :block-reset="true"
        />
      </template>
    </div>
  </form>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import MultiSelect from "@components/select/multi-select.vue";

export default {
  name: "CategoryForm",
  components: {MultiSelect},
  data() {
    return {
      categoryData: {
        name: "",
        is_active: true,
        team_ids: [],
      },
      enableFlag: false,
    }
  },
  emits: [
    'submitForm',
  ],

  computed: {
    ...mapGetters("manager/teams", {
      teams: "getList",
      pagination: "getPagination",
      loading: "isLoading"
    }),
    ...mapGetters("manager/products_and_services", {
      errors: "getErrors",
      showModal: "getShowModal",
      product: 'getProductData',
      edit: "getEdit",
      categoryDataState: "getCategoryData"
    }),
  },

  methods: {
    ...mapActions("manager/products_and_services", [
      "handleCategoryData",
      "handleProductData"
    ]),

    ...mapActions("manager/teams", [
      "list",
    ]),

    checkEdit(state) {
      return (this.categoryData.name !== state.name || this.categoryData.is_active !== state.is_active || this.categoryData.team_ids !== state.team_ids);
    },

    infiniteFetch() {
      this.list().then(() => {
        const per_page = this.pagination.total;
        this.list({per_page}).then(() => {
          this.enableFlag = true;
        });
      })
    }
  },

  watch: {
    edit: {
      handler(val) {
        if (val) {
          const data = {};
          data.team_ids = [];
          data.name = this.categoryDataState.name;
          data.is_active = this.categoryDataState.is_active;
          data.id = this.categoryDataState.id;
          data.company_id = this.categoryDataState.category_id;
          data.products = this.categoryDataState?.products;

          if (this.categoryDataState.team_ids.length > 0) {
            data.team_ids = this.categoryDataState.team_ids;
          }

          if (this.categoryDataState.teams) {
            const ids = this.categoryDataState.teams.map(team => team.id);
            data.team_ids = ids;
            this.categoryData = data;
          }
        }
      },
      immediate: true,
    },

    categoryData: {
      handler(newVal) {
        if (this.checkEdit(this.categoryDataState)) {
          this.handleCategoryData(newVal)
        }
      },
      deep: true
    },

    showModal: {
      handler(val) {
        if (val) {
          this.infiniteFetch();
          return
        }

        this.categoryData = {
          name: "",
          team_ids: [],
          is_active: true
        }
        this.enableFlag = false;
      },
      immediate: true
    }
  },

}
</script>
