export default [
  [
    {
      name: "home",
      icon: "fa-house-blank",
      route: "dashboard"
    },
  ],
  [
    {
      name: "extract",
      icon: "fa-file-chart-column",
      route: "extract"
    }
  ],
  [
    {
      name: "promotional_materials",
      icon: "fa-bullhorn",
      route: "https://drive.google.com/drive/folders/1Yd70CM5CdqxO19QBaMZ2kp5JV-BkCrA5"
    }
  ]
]