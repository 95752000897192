<template>
  <div class="gap-4 row px-3">
    <div
      v-for="(detail, index) in partnerInfo"
      :key="detail"
      class="card p-4 col"
      style="height:280px"
    >
      <div>
        <div class="d-flex justify-content-between">
          <h2>{{ detail.level }}</h2>
          <p class="text-muted fs-6">
            {{ detail.commission }}
          </p>
        </div>

        <locked-level-partner
          :amount="parseFloat(computedDetailsPartner.wallet)"
          :level-partner="detail"
          :position="index"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import LockedLevelPartner from "./locked-level-partner.vue";

export default {
  name: "CardPercentagePartner",
  components: {LockedLevelPartner},

  data() {
    return {
      partnerInfo: [],
      cardIndex: null,
    };
  },

  computed: {
    ...mapGetters("partner", {
      loadingFlags: "getLoadingFlags",
      detailsPartner: "getDetailsPartner",
    }),
    ...mapGetters("admin/partners", {
      adminDetailPartner: "getDetailsPartners",
      adminLoadingFlags: "getLoadingFlags"
    }),
    computedDetailsPartner() {
      if (!Object.keys(this.detailsPartner).length) {
        return this.adminDetailPartner;
      }
      return this.detailsPartner;
    },
  },

  mounted() {
    this.partnerInfo = [
      {
        level: this.$t('level')+" 1",
        commission: `${this.computedDetailsPartner.partners_l1_commission_percent}% ${this.$t('of_wallet')}`,
        image: require("@/assets/img/partner/level-1.svg"),
        check: "check-1",
        maxBilling: parseFloat(this.computedDetailsPartner.partner_l1_threshold),
      },
      {
        level: this.$t('level')+" 2",
        commission: `${this.computedDetailsPartner.partners_l2_commission_percent}% ${this.$t('of_wallet')}`,
        image: require("@/assets/img/partner/level-2.svg"),
        check: "check-2",
        maxBilling: parseFloat(this.computedDetailsPartner.partner_l2_threshold),
      },
      {
        level: this.$t('level')+" 3",
        commission: `${this.computedDetailsPartner.partners_l3_commission_percent}% ${this.$t('of_wallet')}`,
        image: require("@/assets/img/partner/level-3.svg"),
        check: "check-3",
        maxBilling: parseFloat(this.computedDetailsPartner.partner_l3_threshold),
      },
    ];
  },

};
</script>
