<template>
  <div class="d-flex justify-content-center">
    <div
      class="card rounded-3 px-6 py-5 col-10 col-xl-8 col-xxl-6"
      ref="card"
    >
      <div class="d-flex mb-3">
        <img
          src="@assets/img/aloisio/happyAloisio.svg"
          alt="aloísio feliz"
        >
        <p class="fs-2 align-self-center m-0 ms-3">
          Empresa <strong> {{ newCompanyDataFeedback.name }} </strong>  criada com sucesso!
        </p>
      </div>
      <hr class="mt-0">
      <h5>
        Dados do Gestor
      </h5>
      <div class="col-12 d-flex flex-wrap">
        <div
          v-for="value, key, index in personalData"
          :key="key"
          :class="index % 2 === 1 ? 'col-7' : 'col-5'"
        >
          <p class="text-secondary fs-6 mb-0">
            {{ value.title }}
          </p>
          <p
            class="fs-5"
          >
            {{ value.value }}
          </p>
        </div>
      </div>
      <hr class="mt-0">
      <h5>
        Dados da empresa
      </h5>
      <div class="col-12 d-flex flex-wrap">
        <template
          v-for="value, key in companyData"
          :key="key"
        >
          <div
            v-if=" value.title == 'Tag'"
            class="col-5"
          >
            <p class="text-secondary fs-6 mb-0">
              {{ value.title }}
            </p>
            <p
              class="fs-5"
            >
              {{ findTags() }}
            </p>
          </div>
          <div
            v-else
            class="col-5"
          >
            <p class="text-secondary fs-6 mb-0">
              {{ value.title }}
            </p>
            <p
              class="fs-5"
            >
              {{ value.value }}
            </p>
          </div>
        </template>
      </div>
      <button
        @click="redirectToCompanyPage()"
        class="btn btn-primary btn-sm rounded-3 mt-3"
        data-test="newAccountFeedbackGoToTheCompanyPage-button"
      >
        Ir para página da empresa
      </button>
      <button
        class="btn btn-outline-primary btn-sm rounded-3 mt-4"
        @click="clipBoardClick"
        data-test="newAccountFeedbackCopyEmailAndPassword-button"
      >
        Copiar email e senha
      </button>
      <div
        class="border-group overflow-hidden d-flex position-absolute bottom-0 start-0"
        ref="border"
      >
        <img
          src="@assets/img/border/border.svg"
          v-for="border in borderQtd"
          :key="border"
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      borderQtd: 0,
      companyData: {
        name: {
          title: "Empresa",
          value: ""
        },
        domain: {
          title: "Domínio",
          value: ""
        },
        plan: {
          title: "Plano",
          value: ""
        },
        tag: {
          title: "Tag",
          value: ""
        },
        company_id: {
          title: "ID",
          value: ""
        },
        coupon: {
          title: "Possui Cupom?",
          value: ""
        },
      },
      personalData: {
        username: {
          title: "Nome",
          value: ""
        },
        email: {
          title: "Email",
          value: ""
        },
        phone: {
          title: "Telefone",
          value: ""
        },
        password: {
          title: "Senha",
          value: ""
        },
      },
    }
  },

  computed: {
    ...mapGetters("admin/companies", {
      newCompanyDataFeedback: "getNewCompanyDataFeedback"
    }),
    ...mapGetters("admin/tags", {
      tags: "getList"
    }),
  },

  mounted() {
    this.updateValues();
    this.generateBorder();

    window.addEventListener('resize', this.generateBorder);
  },

  unmounted() {
    window.removeEventListener('resize', this.generateBorder);
  },

  methods: {
    updateValues() {
      for (const [key, value] of Object.entries(this.newCompanyDataFeedback)) {
        let dataToUpdate = this.personalData[key] || this.companyData[key]
        if(dataToUpdate) {
          if(dataToUpdate.title === 'Possui Cupom?') {
            dataToUpdate.value = value.trim() ? 'Sim' : 'Não'
          }
          else dataToUpdate.value = value;
        }
      }
    },

    clipBoardClick() {
      let text = `Email: ${this.personalData.email.value}\nSenha: ${this.personalData.password.value}`
      navigator.clipboard.writeText(text)
    },

    generateBorder() {
      let cardWidth = this.$refs.card.clientWidth;
      let borderWidth = 27;

      this.$refs.border.style.width = cardWidth + 'px';
      this.borderQtd = Math.ceil(cardWidth / borderWidth);
    },

    redirectToCompanyPage() {
      let companyId = this.companyData.company_id.value

      this.$router.push(`/admin/companies/${companyId}/edit`)
    },

    findTags() {
      let companyTags = [];

      if (this.companyData.tag.value) {
        this.tags.forEach((elem) => {
          this.companyData.tag.value.find((el) => {
            if(el === elem.id) {
              companyTags.push(elem.name)
            }
          })
        })
      }
      return companyTags.toString();
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-color: $color-gray-100;
}
.border-group {
  margin-bottom: -9px;
}
</style>
