<template>
  <div
    class="extension-integration"
  >
    <router-view name="main" />
  </div>
</template>

<style lang="scss" scoped>
.extension-integration {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ebe1e4;
}
</style>
