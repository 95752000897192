export default {
  startLoadingFlag: (state, flag) => state.loadingFlags[flag] = true,
  stopLoadingFlag: (state, flag) => state.loadingFlags[flag] = false,

  setAgents: (state, agents) => state.agents = agents,
  setAgentsPagination: (state, pagination) => state.agentsPagination = pagination,
  setTeams: (state, teams) => state.teams = teams,
  setTeamsPagination: (state, pagination) => state.teamsPagination = pagination,
  setGroupChannels: (state, groupChannels) => state.groupChannels = groupChannels,
  setGroupChannelsWithQuali: (state, groupChannels) => state.groupChannelsWithQuali = groupChannels,
  setInstances: (state, instances) => state.instances = instances,
  setInstancesPagination: (state, pagination) => state.instancesPagination = pagination,
  setQualifications: (state, qualifications) => state.qualifications = qualifications
}
