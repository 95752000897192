<template>
  <div>
    <table class="table app-table mb-0">
      <thead>
        <tr>
          <th class="col-1">
            ID
          </th>
          <th class="col-3">
            Nome da empresa
          </th>
          <th class="col-2">
            Domínio
          </th>
          <th class="col-2">
            Limite de Agentes
          </th>
          <th
            class="col-2 sort-link cursor-pointer"
            @click="orderBy === 'desc' ? orderBy = 'asc' : orderBy = 'desc'"
          >
            Data da suspensão
          </th>
          <th class="col-2" />
        </tr>
      </thead>
      <tbody>
        <tr v-if="loadingFlags.loadBlockedCompanies">
          <td
            class="p-0 loading"
            v-for="i in 6"
            :key="i"
          >
            <table-loading :rows="12">
              <div class="cell col" />
            </table-loading>
          </td>
        </tr>

        <tr v-else-if="!blockedCompanies.length">
          <td
            class="text-center text-muted"
            colspan="5"
          >
            <span class="fe fe-alert-octagon" /> Nenhuma empresa encontrada!
          </td>
        </tr>

        <tr
          v-for="company in blockedCompanies"
          :key="company.id"
          class="cursor-pointer"
        >
          <td>
            <span href="#">
              {{ company.id }}
            </span>
          </td>
          <td>
            <span
              href="#"
            >
              {{ company.name }}
              <img
                v-if="company.pro_mode"
                class="pro-mode-icon mx-2"
                src="~@assets/img/icons/pro-mode.svg"
              >
            </span>
          </td>
          <td>
            <span
              href="#"
            >
              {{ company.domain }}
            </span>
          </td>
          <td>
            <span
              href="#"
            >
              {{ company.max_agents_login }}
            </span>
          </td>
          <td>
            <span>
              {{ convertTimeStamp(company.blocked_at) }}
            </span>
          </td>
          <td>
            <button
              class="col-12 btn btn-sm btn-primary"
              @click="reactivateCompany(company.id)"
            >
              Reativar temporariamente
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="card-footer"
      v-if="!loadingFlags.loadBlockedCompanies && blockedCompanies.length"
    >
      <div class="col-lg-12">
        <paginator
          v-if="!loadingFlags.loadBlockedCompanies"
          :pagination="blockedCompaniesPagination"
          @change-page="changeBlockedCompanyPage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import TableLoading from "@admin/components/table-loading.vue";
import paginator from "@components/app-paginator";
import moment from "moment";
import { nextTick } from "vue";
export default {
  data() {
    return {
      orderBy: 'desc',
    }
  },

  props: {
    blockedStartDate: {
      type: String,
      default: ''
    },
    blockedEndDate: {
      type: String,
      default: ''
    },
    searchValue: {
      type: String,
      default: ''
    },
    proModeCompanies: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    this.loadBlockedCompanies();
  },

  watch: {
    searchValue(search) {
      this.fetchBlockedCompanies({search,
        blocked: true,
        orderByDesc: "blocked_at"})
        this.setBlockedCompaniesCurrentPage(1);
        this.searchBlockedCompanies();
    },

    proModeCompanies(pro_mode) {
      this.fetchBlockedCompanies({pro_mode,
        blocked: true,
        orderByDesc: "blocked_at"})
    },

    orderBy(data) {
      this.searchBlockedCompanies(data);
    },

  },

  computed: {
    ...mapGetters("admin/companies", {
      loadingFlags: "getLoadingFlags",
      blockedCompanies: "getBlockedCompanies",
      blockedCompaniesPagination: "getBlockedCompaniesPagination",
    }),
  },

  components: {
    TableLoading,
    paginator,
  },

  methods: {
    ...mapMutations("admin/companies", ["setBlockCompaniesParams"]),
    ...mapActions("admin/companies", [
      "loadBlockedCompanies",
      "releaseAccessToBlockedCompany",
      "setBlockedCompaniesFilters",
      "setBlockedCompaniesCurrentPage"
    ]),
    ...mapActions("auth", ["setCurrentCompany"]),
    ...mapMutations("auth", ["trackEvents"]),

    async searchBlockedCompanies(orderBy = "desc") {
      await nextTick();
      const filters = {
          search: this.searchValue,
          blocked: true,
          start_date: this.blockedStartDate,
          end_date: this.blockedEndDate,
        };

        if (orderBy === "desc")
          filters.orderByDesc = "blocked_at";
        else
          filters.orderBy = "blocked_at";

        this.setBlockedCompaniesFilters(filters);
        this.loadBlockedCompanies();
    },

    fetchBlockedCompanies(params) {
      if (this.proModeCompanies === false){
        delete params.pro_mode}
        this.setBlockedCompaniesFilters({
          ...params
        });
        this.setBlockedCompaniesCurrentPage(1);
        this.loadBlockedCompanies();
    },

    convertTimeStamp(unix) {
      return moment(unix*1000).format("DD/MM/YYYY")
    },

    reactivateCompany(id) {
      this.setBlockCompaniesParams({
        blocked_at: Number(moment().add(1, "days").format("X")),
        change_reason: 'Empresa reativada temporariamente'
      });
      this.releaseAccessToBlockedCompany({id: id})
      .then(() => {
        this.loadBlockedCompanies();
      });
      this.setCurrentCompany().then(() => {
        this.trackEvents(`[Admin] Manual Liberation`)
      })
    },

    changeBlockedCompanyPage(page) {
      this.setBlockedCompaniesCurrentPage(page);
      this.loadBlockedCompanies();
    },
  },
}
</script>
<style lang="scss" scoped>
.pro-mode-icon {
  width: 16px;
  height: 16px;
  top: 6px;
  left: 187px;
}
</style>
