export default {
  startLoading: (state, loadingContext = '') => {
    state.loading = true
    state.loadingContext = loadingContext
  },

  stopLoading: state => {
    state.loading = false
    state.loadingContext = ''
  },

  setLoadingContext: (state, loadingContext) => state.loadingContext = loadingContext,

  setList: (state, list) => state.list = list,
  setErrorMessage: (state, errorMesage) => state.errorMessage = errorMesage,
  setErrors: (state, errors) => state.errors = errors,
  clearErrors: (state) => {
    state.errors = {}
    state.errorMessage = ""
  },

  setCampaign: (state, campagin) => state.campagin = campagin,

  setDataSelect: (state, dataSelect) => state.dataSelect = dataSelect,
  setActiveDataSelect: (state, activeDataSelect) => state.activeDataSelect = activeDataSelect
}

