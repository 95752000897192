export default {
    startLoading: (state, loadingContext = '') => {
        state.loading = true
        state.loadingContext = loadingContext
    },

    stopLoading: state => {
        state.loading = false
        state.loadingContext = ''
    },

    setloadingContext: (state, loadingContext) => state.loadingContext = loadingContext,

    setList: (state, list) => state.list = list,
    setErrorMessage: (state, errorMesage) => state.errorMessage = errorMesage,
    setErrors: (state, errors) => state.errors = errors,
    clearErrors: (state) => {
        state.errors = {}
        state.errorMessage = ""
    },

    setCampaign: (state, campaign) => state.campaign = campaign,
    setPagination: (state, pagination) => state.pagination = pagination,
    setRegister: (state, register) => state.register = register,
    setChartData: (state, chartData) => state.chartData = chartData,
    setListDataPreview: (state, listDataPreview) => state.listDataPreview = listDataPreview,
    setListHeaderPreview: (state, listHeaderPreview) => state.listHeaderPreview = listHeaderPreview,
    setFileName: (state, fileName) => state.fileName = fileName,
    setValidatedMailingListData: (state, validatedMailingListData) => state.validatedMailingListData = validatedMailingListData,
    setStartedList: (state, startedList) => state.startedList = startedList,
}



