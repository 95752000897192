<template>
  <div
    class="tab-pane fade show active"
    id="general"
    role="tabpanel"
    aria-labelledby="general-tab"
  >
    <div class="card mt-5">
      <h4 class="card-header">
        {{ $t('general_config') }}
      </h4>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <div class="row">
                <div class="col">
                  <app-input
                    :label="$t('group_name')"
                    v-model="ringGroupName"
                    :errors="errors.name"
                  />
                  <div class="invalid-feedback">
                    {{ errors.name && errors.name[0] }}
                  </div>
                </div>
                <div class="col">
                  <app-input
                    :label="$t('extension_capitalize')"
                    type="number"
                    :errors="errors.extension_number"
                    :min="0"
                    v-model="ringGroupExtension"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-fill">
          <h4 class="card-header">
            {{ $t('audio_on_hold_capitalize') }}
          </h4>
          <div class="row card-body">
            <div class="form-group">
              <div>
                <div class="card-body">
                  <label>{{
                    $t('on_hold_audio_description')
                  }}
                  </label>
                  <div
                    v-if="originalAudioName"
                    class="text-center my-1 text-muted"
                  >
                    <small>
                      {{ originalAudioName }}
                    </small>
                    <audio
                      controls
                      class="w-100 mb-4"
                    >
                      <source
                        :src="getFullAudioPath"
                        type="audio/mpeg"
                      >
                    </audio>
                  </div>
                  <file-uploader
                    v-if="!audioPath || !originalAudioName"
                    @select-file="selectFile"
                    @removed-file="removedFile"
                    filter="audio/mp3"
                    icon="fe fe-refresh-ccw text-primary"
                  />
                  <small
                    class="text-danger"
                    v-if="errors.audio"
                  >
                    {{ errors.audio[0] }}
                  </small>
                  <app-button
                    v-if="originalAudioName"
                    color="btn-outline-danger mt-4"
                    class="w-100 d-flex align-items-center justify-content-center"
                    @click="_audioDelete"
                  >
                    {{ $t('exclude_audio_capitalize') }}
                    <span class="fe fe-trash ms-1" />
                  </app-button>
                </div>
              </div>
              <div
                class="invalid-feedback"
              >
                {{ errors.audio && errors.audio[0] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AppInput from "@components/app-input";
import AppButton from "@components/app-button";
import FileUploader from "@/components/file-uploader";
import { mapGetters } from "vuex";
import { convertSecondsToTime } from "@/utils.js";

export default {
  data() {
    return {
      ringGroupName: "",
      ringGroupExtension: 0
    };
  },

  components: {
    AppInput,
    FileUploader,
    AppButton
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser"
    }),

    ...mapGetters("manager/ring_group", {
      errors: "getErrors"
    }),

    getFullAudioPath() {
      return (
        process.env.VUE_APP_BA12AS.replace("/api/v1", "") +
        this.audioPath +
        "&api_token=" +
        this.user.api_token
      );
    }
  },

  props: {
    audioPath: {
      type: String,
      default: ""
    },

    originalAudioName: {
      type: String,
      default: ""
    },

    audio: {
      type: Object,
      default: null
    },
    acwTimeout: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    extensionNumber: {
      type: Number,
      default: 0
    },
    audioDelete: {
      type: Number,
      default: 0
    }
  },

  mounted() {
    this.ringGroupName = this.name;
    this.ringGroupExtension = this.extensionNumber;
  },

  emits: [
    "update:name",
    "update:extensionNumber",
    "update:audioPath",
    "update:originalAudioName",
    "update:audio",
    "update:qualificationListId",
    "update:acwTimeout",
    "update:audioDelete"
  ],

  methods: {
    convertSecondsToTime,
    selectFile(file) {
      this.$emit("update:audio", file);
      this.$emit("update:audioDelete", 0);
    },
    removedFile() {
      this.$emit("update:audio", null);
    },
    _audioDelete() {
      this.$emit("update:originalAudioName", null);
      this.$emit("update:audioPath", null);
      this.$emit("update:audio", null);
      this.$emit("update:audioDelete", 1);
    },
  },

  watch: {
    ringGroupName(data) {
      this.$emit("update:name", data);
    },
    ringGroupExtension(data) {
      this.$emit("update:extensionNumber", data);
    },
    audioFile(data) {
      this.$emit("update:audioPath", data);
    },
    originalAudioName(data) {
      this.$emit("update:originalAudioName", data);
    },
    audioPath(data) {
      this.$emit("update:audio", data);
    },
    audioRemove(data) {
      this.$emit("update:audioDelete", data);
    }
  }
};
</script>
