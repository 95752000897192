<template>
  <VTooltip :distance="15">
    <div
      ref="bar"
      :style="{ width: customWidth }"
      class="progress progressbar-multiple cursor-pointer"
    >
      <div
        v-for="(item, i) in data"
        :key="i"
        :style="'width: ' + item.percentage + '%;background-color:' + item.color"
        class="progress-bar"
      />
    </div>
    <template #popper>
      <ul
        class="list-group list-group-flush agent-options"
        v-if="data.length"
      >
        <li
          v-for="(item, i) in data"
          :key="i"
          class="list-group-item py-2 d-flex align-items-center justify-content-between"
        >
          <div class="d-flex align-items-center gap-3">
            <span
              v-if="item.icon"
              :style="'color:' + item.color"
            >
              <i :class="item.icon" />
            </span>
            <div
              v-else
              :style="{
                '--background-color': item.color,
              }"
              class="item"
            />
            <span>{{ item.title }}:</span>
          </div>

          <span class="tx-text-gray">
            {{ item.count }} ({{ parseFloat(item.percentage).toFixed(toFixed) }}%)
          </span>
        </li>
      </ul>
      <div
        v-else
        class="text-medium"
      >
        Sem dados
      </div>
    </template>
  </VTooltip>
</template>
<script>
export default {
  name: "ProgressbarMultipleLg",
  props: {
    data: {
      type: Array,
      default: () => []
    },
    customWidth: {
      type: String,
      default: "8rem"
    },

    toFixed: {
      type: Number,
      default: 2
    }
  }
};
</script>

<style lang="scss">
.progress {
  height: 0.5rem;
}
.agent-options  {
  width: 250px;
  .list-group-item {
    .item {
      width: 20px;
      height: 6px;
      background-color: var(--background-color);
      border-radius: 10px;
    }
  }
}

.v-popper--theme-tooltip {
  .v-popper__wrapper {
    .v-popper__inner {
      color: $color-text-200;
      background-color: $color-text-light;
      box-shadow: 0px 4px 18px 0px rgba(77, 72, 34, 0.12);
    }
  }

  .v-popper__arrow-container {
    display: none;
  }
}
</style>
