<template>
  <div class="main-content pb-6 utilization-report">
    <div class="container-fluid">
      <header-component
        :title="$t('metric_list_report')"
        :pretitle="$t('uppercases.reports')"
        redirect-link="/manager/reports"
      />

      <form class="row align-items-center">
        <div class="col-lg-4">
          <label
            for="start-date"
            class="form-label"
          >
            {{ $t('period') }}
          </label>
          <input-date-range
            preset="month"
            v-model:start="startDate"
            v-model:end="endDate"
          />
        </div>
        <div class="col-lg-5">
          <label
            for="campaign"
            class="form-label"
          >
            {{ $t('campaign_capitalize') }}
          </label>
          <select-campaign v-model="campaign" />
        </div>
        <div class="col-lg-3">
          <label class="form-label">&nbsp;</label>
          <div>
            <app-button
              class="form-control px-3"
              :blocked="!canSearch()"
              @click="submit()"
              :loading="loading.metricsTotal || loading.metrics || loading.searchQualifications"
            >
              {{ $t('search_report') }}
            </app-button>
          </div>
        </div>
      </form>

      <ul
        class="nav nav-tabs mb-4"
        id="nav-tab"
        role="tablist"
      >
        <li class="nav-item">
          <button
            class="nav-link active"
            id="nav-list-tab"
            data-bs-toggle="tab"
            data-bs-target="#list"
            role="tab"
            aria-controls="list"
            aria-selected="true"
          >
            {{ $t('lists_capitalizes') }}
          </button>
        </li>
        <li class="nav-item">
          <button
            class="nav-link"
            id="nav-qualifications-tab"
            data-bs-toggle="tab"
            data-bs-target="#qualifications"
            role="tab"
            aria-controls="qualifications"
            aria-selected="false"
          >
            {{ $tc('capitalizes.qualifications', 0) }}
          </button>
        </li>
      </ul>
      <div class="tab-content">
        <div
          class="tab-pane fade show active"
          id="list"
          role="tabpanel"
        >
          <list-meta
            v-if="Object.keys(goalDetails).length && goalDetails.conversion_goal !== 0"
            :meta="goalDetails"
          />
          <div v-if="Object.keys(totalMetrics).length && listMetrics.length && !showSearchMessage">
            <div class="row">
              <div class="col-12 col-xl-4 mi">
                <div class="card card-fill">
                  <div class="card-header">
                    <h4 class="card-header-title fw-normal">
                      {{ $t('metrics_list') }}
                    </h4>
                  </div>
                  <div class="card-body">
                    <div class="row text-center align-items-center border-bottom">
                      <h1>
                        {{ ((totalMetrics.phones.completed / totalMetrics.phones.total) * 100 || 0).toFixed(1) + "%" }}
                      </h1>
                      <h2>
                        {{ $t('concluded') }}
                      </h2>
                    </div>
                    <div class="d-flex justify-content-between border-bottom py-3">
                      <span>
                        {{ $t('list_metrics_total') }}
                      </span>
                      <span class="text-muted">
                        {{ totalMetrics.phones.total }}
                      </span>
                    </div>
                    <div class="d-flex justify-content-between border-bottom py-3">
                      <span>
                        {{ $t('dialed') }}
                      </span>
                      <span class="text-muted">
                        {{ totalMetrics.phones.dialed }}
                      </span>
                    </div>
                    <div class="d-flex justify-content-between py-3">
                      <span>
                        {{ $t('concluded_plural') }}
                      </span>
                      <span class="text-muted">
                        {{ totalMetrics.phones.completed }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="card card-fill">
                  <div class="card-header">
                    <h4 class="card-header-title fw-normal">
                      {{ $t('calls_metrics') }}
                    </h4>
                  </div>
                  <div class="card-body">
                    <div class="row d-flex text-center align-items-center border-bottom">
                      <h1>
                        {{ ((totalMetrics.connected.total / totalMetrics.calls.total) * 100 || 0).toFixed(1) + "%" }}
                      </h1>
                      <h2>
                        {{ $t('connected_capitalize') }}
                      </h2>
                    </div>
                    <div class="d-flex justify-content-between border-bottom py-3">
                      <span>
                        {{ $t('list_metrics_total') }}
                      </span>
                      <span class="text-muted">
                        {{ totalMetrics.calls.total }}
                      </span>
                    </div>
                    <div class="d-flex justify-content-between border-bottom py-3">
                      <span>
                        {{ $t('not_answered_capitalize') }}
                      </span>
                      <span class="text-muted">
                        {{ totalMetrics.calls.not_answered }}
                      </span>
                    </div>
                    <div class="d-flex justify-content-between border-bottom">
                      <div class="d-flex justify-content-between border-end py-3 col">
                        <span>
                          {{ $t('abandoned_metrics') }}
                        </span>
                        <span class="text-muted me-2">
                          {{ totalMetrics.calls.abandoned }}
                        </span>
                      </div>
                      <div class="d-flex justify-content-between py-3 col">
                        <span class="ms-2">
                          {{ $t('fails') }}
                        </span>
                        <span class="text-muted">
                          {{ totalMetrics.calls.failed }}
                        </span>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between">
                      <div class="d-flex justify-content-between border-end py-3 col">
                        <span>
                          {{ $t('post_call_ivr') }}
                        </span>
                        <span class="text-muted me-2">
                          {{ totalMetrics.calls.mailbox }}
                        </span>
                      </div>
                      <div class="d-flex justify-content-between py-3 col">
                        <span class="ms-2">
                          {{ $t('pre_call_ivr') }}
                        </span>
                        <span class="text-muted">
                          {{ totalMetrics.calls.not_answered_due_progress_amd || 0 }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="card card-fill">
                  <div class="card-header">
                    <h4 class="card-header-title fw-normal">
                      {{ $t('connected_with_operation') }}
                    </h4>
                  </div>
                  <div class="card-body">
                    <div class="row d-flex text-center align-items-center border-bottom">
                      <h1>
                        {{
                          ((totalMetrics.connected.converted / totalMetrics.connected.total) * 100 || 0).toFixed(1) +
                            "%"
                        }}
                      </h1>
                      <h2>
                        {{ $t('converted') }}
                      </h2>
                    </div>
                    <div class="d-flex justify-content-between border-bottom py-3">
                      <span>
                        {{ $t('list_metrics_total') }}
                      </span>
                      <span class="text-muted">
                        {{ totalMetrics.connected.total }}
                      </span>
                    </div>
                    <div class="d-flex justify-content-between border-bottom py-3">
                      <span>
                        {{ $t('crp') }}
                      </span>
                      <span class="text-muted">
                        {{ totalMetrics.connected.dmc - totalMetrics.connected.converted }}
                      </span>
                    </div>
                    <div class="d-flex justify-content-between py-3">
                      <span>
                        {{ $t('cpc_plus_conversion') }}
                      </span>
                      <span class="text-muted">
                        {{ totalMetrics.connected.converted }}
                      </span>
                    </div>
                    <div class="d-flex justify-content-between py-3">
                      <span>
                        {{ $t('unknown_capitalize') }}
                      </span>
                      <span class="text-muted">
                        {{ totalMetrics.connected.unknown }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-content">
              <div class="card">
                <div class="card-header">
                  <div class="row align-items-center">
                    <div class="col">
                      <p class="card-header-title">
                        {{ $t('metric_list_report') }}
                      </p>
                    </div>

                    <div class="col-auto">
                      <a
                        href="#"
                        class="btn btn-outline-secondary"
                        @click="generateCSVModal.show()"
                      >{{ $t('download_CSV') }}</a>
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table table-hover table-nowrap card-table">
                    <thead>
                      <tr>
                        <th>
                          <sort-link
                            :title="$t('uppercases.list')"
                            column="list"
                            :dir="sorts.list.dir"
                            @sort="sort"
                            @click.prevent
                            class="text-muted fw-normal"
                          />
                        </th>
                        <th>
                          <sort-link
                            :title="$t('uppercases.import')"
                            column="created_at"
                            :dir="sorts.created_at.dir"
                            @sort="sort"
                            @click.prevent
                            class="text-muted fw-normal"
                          />
                        </th>
                        <th>
                          <sort-link
                            :title="$t('uppercases.phones')"
                            column="phones"
                            :dir="sorts.phones.dir"
                            @sort="sort"
                            @click.prevent
                            class="text-muted fw-normal"
                          />
                        </th>
                        <th>
                          <sort-link
                            :title="$t('uppercases.calls_made')"
                            column="calls"
                            :dir="sorts.calls.dir"
                            @sort="sort"
                            @click.prevent
                            class="text-muted fw-normal"
                          />
                        </th>
                        <th>
                          <sort-link
                            :title="$t('uppercases.connected_with_operation')"
                            column="connected"
                            :dir="sorts.connected.dir"
                            @sort="sort"
                            @click.prevent
                            class="text-muted fw-normal"
                          />
                        </th>
                        <th>
                          <sort-link
                            title="METAS"
                            column="connected"
                            :dir="sorts.connected.dir"
                            @sort="sort"
                            @click.prevent
                            class="text-muted fw-normal"
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody class="list fs-base">
                      <tr
                        v-for="item in listMetrics"
                        :key="item.id"
                      >
                        <td
                          class="overflow-ellipsis"
                          style="max-width: 169px;"
                        >
                          {{ item.list.name }}
                        </td>
                        <td style="max-width: 169px;">
                          {{ item.list.created_at }}
                        </td>
                        <td>
                          {{ item.phones.total }}
                          <progressbar-multiple
                            style="height: 6px; width: 150px"
                            :use-icon="false"
                            :data="[
                              {
                                color: '#5cb85c',
                                percentage: item.phones.total ? (item.phones.dialed / item.phones.total) * 100 : 0,
                                count: item.phones.dialed,

                                title: this.$t('dialed')
                              },
                              {
                                color: '#ffd037',
                                percentage: item.phones.total ? (item.phones.completed / item.phones.total) * 100 : 0,
                                count: item.phones.completed,

                                title: this.$t('concluded_plural')
                              }
                            ]"
                          />
                        </td>
                        <td>
                          {{ item.calls.total }}
                          <progressbar-multiple
                            style="height: 6px; width: 150px"
                            :use-icon="false"
                            :data="[
                              {
                                color: '#cdcaca',
                                percentage: item.calls.total ? (item.calls.failed / item.calls.total) * 100 : 0,
                                count: item.calls.failed,
                                title: this.$t('fails')
                              },
                              {
                                color: '#eaeaea',
                                percentage: item.calls.total ? (item.calls.not_answered / item.calls.total) * 100 : 0,
                                count: item.calls.not_answered,
                                title: this.$t('not_answered_capitalize')
                              },
                              {
                                color: '#eb3c68',
                                percentage: item.calls.total ? (item.calls.mailbox / item.calls.total) * 100 : 0,
                                count: item.calls.mailbox,
                                title: this.$t('post_call_ivr')
                              },
                              {
                                color: '#e60b42',
                                percentage: item.calls.total
                                  ? ((item.calls.not_answered_due_progress_amd || 0) / item.calls.total) * 100
                                  : 0,
                                count: item.calls.not_answered_due_progress_amd || 0,
                                title: this.$t('pre_call_ivr')
                              },
                              {
                                color: '#e67c0b',
                                percentage: item.calls.total ? (item.calls.abandoned / item.calls.total) * 100 : 0,
                                count: item.calls.abandoned,
                                title: this.$t('abandoned_metrics')
                              }
                            ]"
                          />
                        </td>
                        <td>
                          {{ item.connected.total }}
                          <progressbar-multiple
                            style="height: 6px; width: 150px"
                            :use-icon="false"
                            :data="[
                              {
                                color: '#5cb85c',
                                percentage: item.connected.total
                                  ? (item.connected.converted / item.connected.total) * 100
                                  : 0,
                                count: item.connected.converted,
                                title: this.$t('cpc_plus_conversion')
                              },
                              {
                                color: '#2c7be5',
                                percentage: item.connected.total
                                  ? ((item.connected.dmc - item.connected.converted) / item.connected.total) * 100
                                  : 0,
                                count: item.connected.dmc - item.connected.converted,
                                title: this.$t('crp')
                              },
                              {
                                color: '#E60B42',
                                percentage: item.connected.total
                                  ? (item.connected.unknown / item.connected.total) * 100
                                  : 0,
                                count: item.connected.unknown,
                                title: this.$t('unknown_capitalize')
                              },
                              {
                                color: '#989898',
                                percentage: item.connected.total
                                  ? ((item.connected.total - item.connected.dmc - item.connected.unknown) /
                                    item.connected.total) *
                                    100
                                  : 0,
                                count: item.connected.total - item.connected.dmc - item.connected.unknown,
                                title: this.$t('others_f')
                              }
                            ]"
                          />
                        </td>
                        <td>
                          <i
                            v-if="item.connected.conversion_goal === 0"
                            class="fal fa-minus tx-text-gray"
                          />
                          <div v-else>
                            <span class="tx-text-gray">{{ item.connected.converted }}/{{ item.connected.conversion_goal }}</span>
                            <div
                              class="progress"
                              style="height: 8px;width: 150px;"
                            >
                              <div
                                class="progress-bar"
                                role="progressbar"
                                :style="{
                                  width: calculatePercentage(item.connected.converted, item.connected.conversion_goal)
                                }"
                                aria-valuenow="32"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="col-lg-12">
                    <paginator
                      :pagination="pagination"
                      @change-page="changePage"
                      v-if="!loading.metricsTotal || !loading.metrics || !loading.searchQualifications"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="modal fade"
              id="modalDownloadCSV"
              tabindex="-1"
              aria-labelledby="modalDownloadCSVLabel"
              aria-hidden="true"
              ref="generateCSVModal"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h3
                      class="modal-title"
                      id="modalDownloadCSVLabel"
                    >
                      {{ $t('generate_report') }}
                    </h3>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div class="modal-body">
                    <p>
                      {{ $t('send_report_to_email') }}
                    </p>
                    <form
                      @submit.prevent="generateCSV"
                      id="formCSV"
                    >
                      <div class="mb-3">
                        <label
                          for="input-email"
                          class="col-form-label"
                        >{{ $t('report_email') }}</label>
                        <input
                          type="email"
                          class="form-control"
                          id="input-email"
                          required
                          v-model="email"
                        >
                      </div>
                    </form>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      {{ $t('cancel_general') }}
                    </button>
                    <app-button
                      form="formCSV"
                      :loading="loading.downloadTable"
                      style="min-width: 6.5rem;"
                    >
                      {{ $t('generate_csv') }}
                    </app-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card py-4"
            v-if="listMetrics.length == 0 && !showSearchMessage"
          >
            <div class="card-body text-center">
              {{ $t('no_metrics_found') }}
            </div>
          </div>
          <div
            class="card py-4"
            v-if="showSearchMessage"
          >
            <div class="card-body text-center">
              {{ $t('search_metrics') }}
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="qualifications"
          role="tabpanel"
        >
          <progress-chart
            v-if="qualificationList.length && !showSearchMessage"
            :title="$t('qualification_graph')"
            :labels="qualificationLabels"
            :datasets="qualificationDatasets"
          />
          <div
            class="card py-4"
            v-if="!qualificationList.length && !showSearchMessage"
          >
            <div class="card-body text-center">
              {{ $t('no_qualification_in_period') }}
            </div>
          </div>
          <div
            class="card py-4"
            v-if="showSearchMessage"
          >
            <div class="card-body text-center">
              {{ $t('search_see_qualification') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { convertSecondsToTime } from "@/utils.js";

import AppButton from "@/components/app-button.vue";
import InputDateRange from "@/components/input-date-range.vue";
import Paginator from "@/components/app-paginator.vue";
import ProgressChart from "@dialer/manager/components/progress-chart.vue";
import ProgressbarMultiple from "@components/progressbar-multiple";
import SortLink from "@dialer/manager/components/sort-link";
import SelectCampaign from "@dialer/manager/components/select-campaign.vue";
import HeaderComponent from "@/components/header-component.vue";
import { Modal } from "bootstrap";
import { nextTick } from "vue";
import ListMeta from "@dialer/manager/pages/reports/components/list-meta.vue";

export default {
  data() {
    return {
      email: "",
      startDate: "",
      endDate: "",
      showSearchMessage: true,

      campaigns: [],
      campaign: "",
      qualificationLabels: [],
      qualificationDatasets: [],
      generateCSVModal: null,

      sorts: {
        list: {
          dir: ""
        },
        created_at: {
          dir: ""
        },
        phones: {
          dir: ""
        },
        calls: {
          dir: ""
        },
        connected: {
          dir: ""
        }
      },
      goalDetails: {}
    };
  },
  components: {
    ListMeta,
    AppButton,
    InputDateRange,
    ProgressChart,
    Paginator,
    ProgressbarMultiple,
    SortLink,
    SelectCampaign,
    HeaderComponent
  },
  computed: {
    ...mapGetters("auth", {
      user: "getUser"
    }),
    ...mapGetters("manager/filters", {
      filters: "getFilters"
    }),
    ...mapGetters("manager/list_metrics", {
      pagination: "getPagination",
      listMetrics: "getListMetrics",
      totalMetrics: "getTotalMetrics",
      qualificationList: "getQualificationList",
      loading: "getLoadingFlags"
    })
  },
  methods: {
    convertSecondsToTime,
    ...mapActions("manager/list_metrics", ["metrics", "metricsTotal", "searchQualifications", "downloadTable"]),
    ...mapMutations("manager/list_metrics", ["sortListMetrics"]),
    ...mapActions("manager/filters", ["findFilters"]),
    canSearch() {
      return this.startDate && this.endDate && this.campaign;
    },
    updateStartDate(date) {
      this.startDate = date;
    },
    updateEndDate(date) {
      this.endDate = date;
    },
    submit() {
      this.showSearchMessage = false;
      this.handleSubmit(1);
    },
    handleQualificationSubmit() {
      this.clearQualificationData();
      this.prepareQualificationDataset();
      this.populateQualificationDataset();
    },
    clearQualificationData() {
      this.qualificationLabels = [];
      this.qualificationDatasets = [];
    },
    prepareQualificationDataset() {
      this.qualificationList.forEach(el => {
        this.qualificationLabels.push(el.list.name);

        for (const key in el.qualifications) {
          if (!this.qualificationDatasets[key]) {
            this.qualificationDatasets[key] = {
              label: el.qualifications[key].name,
              color: el.qualifications[key].color,
              backgroundColor: el.qualifications[key].color,
              borderRadius: 0
            };
            this.qualificationDatasets[key].data = [];
          }
        }
      });
    },
    populateQualificationDataset() {
      this.qualificationList.forEach(el => {
        for (const key in this.qualificationDatasets) {
          let total = el.qualifications[key] ? el.qualifications[key].count : 0;
          this.qualificationDatasets[key].data.push(total);
        }
      });
    },
    generateCSV() {
      this.downloadTable({
        campaignId: this.campaign,
        startDate: this.startDate,
        endDate: this.endDate,
        csv: 1,
        email: this.email
      }).then(() => this.generateCSVModal.hide());
    },
    changePage(page) {
      this.handleSubmit(page);
    },
    handleSubmit(page) {
      let promises = [];

      promises.push(
        this.metricsTotal({
          campaignId: this.campaign,
          startDate: this.startDate,
          endDate: this.endDate,
          page: page
        })
      );

      promises.push(
        this.metrics({
          campaignId: this.campaign,
          startDate: this.startDate,
          endDate: this.endDate,
          page: page
        })
      );

      promises.push(
        this.searchQualifications({
          campaignId: this.campaign,
          startDate: this.startDate,
          endDate: this.endDate,
          page: page
        })
      );

      Promise.all(promises).then(() => {
        nextTick().then(() => {
          this.generateCSVModal = new Modal(this.$refs.generateCSVModal);
          this.populateGoal();
        });
      });
    },
    sort(data) {
      this.sorts["list"].dir = "";
      this.sorts["created_at"].dir = "";
      this.sorts["phones"].dir = "";
      this.sorts["calls"].dir = "";
      this.sorts["connected"].dir = "";
      this.sorts[data.column].dir = data.dir;
      this.sortListMetrics(data);
    },
    calculatePercentage(value, total) {
      return ((value / total) * 100 || 0).toFixed(0) + "%";
    },
    populateGoal() {
      this.goalDetails = this.listMetrics[0]?.connected;
    },
    clearGoal() {
      this.goalDetails = {};
    }
  },
  watch: {
    qualificationList(data) {
      if (data.length) {
        this.handleQualificationSubmit();
      }
    },
    listMetrics() {
      this.sorts["list"].dir = "";
      this.sorts["created_at"].dir = "";
      this.sorts["phones"].dir = "";
      this.sorts["calls"].dir = "";
      this.sorts["connected"].dir = "";
    },
    filters: {
      handler(data) {
        if (data.campaigns) this.campaigns = data.campaigns;
      },
      deep: true
    }
  },
  mounted() {
    this.findFilters();
    this.email = this.user.email;
  },
  beforeRouteLeave(to, from, next) {
    this.clearGoal();
    next();
  }
};
</script>

<style scoped lang="scss">
.table {
  &:not(:first-child) {
    border-top: none;
  }
  td {
    padding: 8px 24px;
  }
}

.header-body {
  border-bottom: none;
}
</style>
