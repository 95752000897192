<template>
  <div class="d-flex align-items-center gap-3">
    <div
      :class="[bgColor]"
      :style="{ 'background-color': backgroundColor, width: width, height: height, 'border-radius': radius }"
    >
      <div
        v-if="icon.length"
        class="d-flex align-items-center justify-content-center h-100"
      >
        <i :class="icon" />
      </div>
      <p
        :class="fontSize"
        class="mb-0"
      >
        {{ text }}
      </p>
    </div>
    <p
      v-if="title.length"
      class="mb-0"
    >
      {{ title }}
    </p>
  </div>
</template>

<script>
export default {
  name: "BadgeIcon",
  props: {
    icon: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    text: {
      type: [String, Number],
      default: ""
    },
    backgroundColor: {
      type: String,
      default: "#E1E9F4"
    },
    bgColor: {
      type: String,
      default: ""
    },
    width: {
      type: String,
      default: "32px"
    },
    height: {
      type: String,
      default: "32px"
    },
    fontSize: {
      type: String,
      default: "text-base"
    },
    radius: {
      type: String,
      default: "50%"
    }
  }
};
</script>
