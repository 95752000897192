export default {
  questions: [],
  currentResponse: null,
  loadingFlags: {
    sendQuestion: false,
    fetchQuestions: false,
    fetchQuestionById: false
  },
  errorsMessage: [],
  threadId: null
}
