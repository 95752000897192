<template>
  <div class="card-body d-flex flex-wrap">
    <div class="col-lg-12">
      <div class="form-group">
        <input-text-range
          v-if="waitTime"
          :ura="true"
          :title="$t('press_key_waiting_time')"
          color="#2c7be5"
          :model-value="waitTime"
          @update:model-value="$emit('update:waitTime', $event)"
          :class="{
            'is-invalid': errors.wait_time,
          }"
          :min="5"
          :max="10"
        />
      </div>
    </div>
  </div>
</template>
<script>
import InputTextRange from "@components/input-text-range.vue";

export default {
  name: "AdvancedConfig",
  components: { InputTextRange },
  props: {
    waitTime: {
      type: Number,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:waitTime"],
}
</script>