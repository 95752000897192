<template>
  <span
    ref="lastLoginTooltip"
    class="ms-3"
  >
    <i
      class="fe fe-info text-primary"
      @click="closeTooltip"
    />
  </span>
</template>

<script>
import { Tooltip } from 'bootstrap';

export default {
  data() {
    return {
      lastLoginTooltip: null
    }
  },

  mounted() {
    this.lastLoginTooltip = new Tooltip(this.$refs.lastLoginTooltip, {
      title: this.$t('waiting_user_login')
    })
  },

  methods: {
    closeTooltip() {
      if (this.lastLoginTooltip) {
        this.lastLoginTooltip.hide();
      }
    }
  }
}
</script>
