
<template>
  <div
    class="d-flex flex-column p-4 w-100"
    style="height: 90vh; gap: 1rem"
  >
    <div
      style="width: fit-content;gap: 8px"
      class="d-flex align-items-center ms-4"
    >
      <button
        class="btn btn-xs btn-rounded-circle btn-white float-start"
        @click="$emit('newQuestion')"
      >
        <i class="fe fe-arrow-left" />
      </button>
      <h3 class="mb-0">
        {{ $t('back_capitalize') }}
      </h3>
    </div>
    <div
      class="card mx-4 my-0 h-100"
    >
      <div
        class="card-body justify-content-between d-flex flex-column "
      >
        <div
          class="d-flex flex-column-reverse overflow-auto chat flex-grow-1"
          id="list-group"
        >
          <div
            class="d-flex justify-content-between flex-column"
            v-for="(question, index) in data"
            :key="index"
          >
            <div
              class="question-balloon d-flex mb-4"
              style="width: fit-content"
            >
              <p class="m-0">
                {{ question.request }}
              </p>
            </div>
            <div
              class="d-flex d-inline-flex"
              style="text-align: left"
              v-if="loading.sendQuestion && index === 0"
            >
              <i class="fa-regular fa-sparkles me-3" /><strong>{{ $t('ai_assistant') }}:</strong>
              <div
                class="d-flex ms-2"
              >
                {{ $t('analysing_data') }} <div class="dot-typing ms-4 mt-3" />
              </div>
            </div>
            <div
              style="text-align: left"
              v-else
            >
              <i class="fa-regular fa-sparkles me-3" /><strong>{{ $t('ai_assistant') }}:</strong><markdown-renderer :content="question.response" />
            </div>
            <infinity-pagination-observer
              v-if="validationObserver && currentQuestion?.fromHistory"
              @reload="nextPage"
              :loading="loading.fetchQuestions"
              parent-container="list-group"
            />
          </div>
        </div>
        <div id="suggestions">
          <suggestions-ai-assistant
            :is-chat="true"
            @suggestion="addNewQuestion"
            :current-question="currentQuestion"
          />
        </div>
      </div>
    </div>

    <div
      class="d-flex justify-content-center align-items-center mx-4 my-0"
    >
      <input-ai-assistant
        :row="1"
        @question="addNewQuestion"
      />
    </div>
  </div>
</template>

<script>
import InputAiAssistant from "@crm/components/ai-assistant/input-ai-assistant.vue";
import SuggestionsAiAssistant from "@crm/components/ai-assistant/suggestions-ai-assistant.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import InfinityPaginationObserver from "@components/infinity-pagination-observer.vue";
import MarkdownRenderer from "@components/markdown-renderer.vue";

export default {
  name: "ChatAiAssistant",
  components: {MarkdownRenderer, InfinityPaginationObserver, SuggestionsAiAssistant, InputAiAssistant},
  emits: ['newQuestion'],
  props: {
    currentQuestion: {
      type: Object,
      required: true
    },
    page: {
      type: Number,
      default: 1
    }
  },
  computed: {
    ...mapGetters("crm/ai_assistant", {
      currentResponse: "getCurrentResponse",
      loading: "getLoadingFlags"
    }),
    ...mapGetters("crm/funnels", {
      currentFunnel: "getCurrentFunnel"
    }),
    validationObserver() {
      return this.currentPage <= this.lastPage && this.lastPage !== 1;
    },
  },
  data() {
    return {
      currentPage: 1,
      lastPage: 1,
      data: [],
    }
  },
  mounted() {
    this.setThreadId(null)
    if(this.currentQuestion?.fromHistory){
      this.fetchQuestionById({
        message_id: this.currentQuestion.id,
        page: 1
      }).then(response => {
        let question = response.data.find(question => question.id === this.currentQuestion.id);
        this.data = response.data.slice(response.data.indexOf(question));
        this.lastPage = response.meta.last_page;
      });
    }else {
      let question = {
        request: this.currentQuestion,
        response: this.currentResponse
      };
      this.data.push(question);
    }
  },
  methods: {
    ...mapActions("crm/ai_assistant", ["fetchQuestionById"]),
    ...mapMutations("crm/ai_assistant",["setCurrentResponse", "setThreadId", "stopLoading"]),
    nextPage(){
      if(this.currentPage === this.lastPage){
        return
      }
      this.currentPage++
      this.fetchChatHistory()
    },
    async fetchChatHistory(){
      await this.fetchQuestionById({
        message_id: this.currentQuestion.id,
        page: this.currentPage
      }).then(response => {
        this.data = this.data.concat(response.data)
      });
    },
    addNewQuestion(question){
      if(this.data[0]?.response || this.currentQuestion?.fromHistory){

        let newQuestion = {
          request: question,
          response: ''
        };
        this.data.unshift(newQuestion);
      }
    }
  },
  watch: {
    data: {
      handler(value){
        this.data = value;
      },
      deep: true
    },
    currentResponse: {
      handler(value){
        if(value) {
          this.data[0].response = value;
        }
        this.setCurrentResponse(null)
      },
      deep: true

    }
  },
  beforeUnmount() {
    this.setThreadId(null);
    this.stopLoading('sendQuestion');
  }
}
</script>


<style scoped>
.question-balloon{
  border-radius: 10px 10px 2px 10px;
  background-color: #F0F4FA;
  padding: 8px 16px;
  width: fit-content;
  align-self: end;
}
.dot-typing {
  position: relative;
  left: -9999px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #677C92;
  box-shadow: 9984px 0 0 0 #677C92, 9999px 0 0 0 #677C92, 10014px 0 0 0 #677C92;
  animation: dot-typing 1.5s infinite linear;
}
.chat{
  height: calc(100vh - 600px);
}
@keyframes dot-typing {
  0% {
    box-shadow: 9984px 0 0 0 #677C92, 9999px 0 0 0 #677C92, 10014px 0 0 0 #677C92;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 #677C92, 9999px 0 0 0 #677C92, 10014px 0 0 0 #677C92;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 #677C92, 9999px 0 0 0 #677C92, 10014px 0 0 0 #677C92;
  }
  50% {
    box-shadow: 9984px 0 0 0 #677C92, 9999px -10px 0 0 #677C92, 10014px 0 0 0 #677C92;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 #677C92, 9999px 0 0 0 #677C92, 10014px 0 0 0 #677C92;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 #677C92, 9999px 0 0 0 #677C92, 10014px -10px 0 0 #677C92;
  }
  100% {
    box-shadow: 9984px 0 0 0 #677C92, 9999px 0 0 0 #677C92, 10014px 0 0 0 #677C92;
  }
}
</style>