<template>
  <main-loading v-show="loadingContext['find_receptive_queue_by_id']" />
  <div
    v-show="!loadingContext['find_receptive_queue_by_id']"
    class="container-fluid"
  >
    <div class="row justify-content-center">
      <div class="col-12">
        <header-component
          :pretitle="$route.params.id ? $t('configure_receptive_queue_uppercase') : $t('create_receptive_queue_uppercase')"
          :title="$route.params.id ? receptiveQueue?.name : $t('add_new_receptive_queue')"
          :redirect-link="redirectUser"
        />

        <div class="col-lg-12">
          <ul
            id="nav-tab"
            class="nav nav-tabs"
            role="tablist"
          >
            <li class="nav-item">
              <button
                id="nav-general-tab"
                ref="tab-general"
                aria-controls="general"
                aria-selected="true"
                class="nav-link active"
                data-bs-target="#general"
                data-bs-toggle="tab"
                role="tab"
              >
                {{ $t('general_capitalize') }}
              </button>
            </li>
            <li class="nav-item">
              <button
                id="nav-agents-tab"
                ref="tab-agents"
                aria-controls="agents"
                aria-selected="false"
                class="nav-link"
                data-bs-target="#agents"
                data-bs-toggle="tab"
                role="tab"
              >
                {{ $t('agents_capitalize') }}
              </button>
            </li>
          </ul>
        </div>

        <div class="tab-content">
          <div
            id="general"
            class="tab-pane fade show active"
            role="tabpanel"
          >
            <div class="row mt-4">
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label class="form-label required"> {{ $t('name_capitalize') }}</label>
                  <input
                    v-model="fields.name"
                    :class="{
                      'is-invalid': errors.name
                    }"
                    class="form-control"
                    type="text"
                  >
                  <div class="invalid-feedback">
                    {{ errors.name && errors.name[0] }}
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label class="form-label required">
                    {{ $t('extension_capitalize') }}
                  </label>
                  <input
                    v-model="fields.extension_number"
                    :class="{
                      'is-invalid': errors.extension_number
                    }"
                    class="form-control"
                    min="0"
                    type="number"
                  >
                  <div class="invalid-feedback">
                    {{
                      errors.extension_number &&
                        errors.extension_number[0]
                    }}
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label class="form-label">
                    {{ $t('maximum_waiting_time_capitalize') }}
                  </label>

                  <div class="input-group input-group-merge">
                    <flat-pickr
                      v-model="fields.wait_time"
                      :config="config"
                      aria-describedby="inputGroup"
                      aria-label="Input group appended"
                      class="form-control"
                      type="text"
                    />
                    <div class="input-group-text">
                      <span class="fe fe-clock" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label class="form-label">
                    {{ $t('act_time_capitalize') }}
                  </label>

                  <div class="input-group input-group-merge">
                    <flat-pickr
                      v-model="fields.acw_timeout"
                      :config="config"
                      aria-describedby="inputGroup"
                      aria-label="Input group appended"
                      class="form-control"
                      placeholder="00:00:00"
                      type="text"
                    />
                    <div class="input-group-text">
                      <span class="fe fe-clock" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label class="form-label"> {{ $t('priority_capitalize') }}</label>
                  <input
                    v-model="fields.priority"
                    :class="{
                      'is-invalid': errors.priority
                    }"
                    class="form-control"
                    min="0"
                    type="number"
                  >
                  <div class="invalid-feedback">
                    {{ errors.priority && errors.priority[0] }}
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label class="form-label"> {{ $t('time_limit_per_call_capitalize') }}</label>
                  <input
                    v-model="fields.limit_call_time"
                    :class="{
                      'is-invalid': errors.limit_call_time
                    }"
                    class="form-control"
                    min="0"
                    type="number"
                  >
                  <div class="invalid-feedback">
                    {{ errors.limit_call_time && errors.limit_call_time[0] }}
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label class="form-label required">
                    {{ $t('qualifications_capitalize') }}
                  </label>

                  <select
                    v-model="fields.qualification_list_id"
                    :class="{
                      'is-invalid': errors.qualification_list_id
                    }"
                    class="form-select"
                  >
                    <option
                      v-for="item in qualifications"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <div class="invalid-feedback">
                    {{
                      errors.qualification_list_id &&
                        errors.qualification_list_id[0]
                    }}
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label class="form-label">
                    {{ $t('ivr_after_service') }}
                  </label>

                  <select
                    v-model="fields.ivr_after_call_id"
                    :class="{
                      'is-invalid': errors.ivr_after_call_id
                    }"
                    class="form-select"
                  >
                    <option
                      v-for="item in ivr_after_call"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <div class="invalid-feedback">
                    {{
                      errors.ivr_after_call_id &&
                        errors.ivr_after_call_id[0]
                    }}
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-12">
                <div class="form-group">
                  <label class="form-label">
                    {{ $t('audio_on_hold_capitalize') }}
                    <span class="form-text">{{ $t('upload_a_mp3_file') }}.</span>
                  </label>
                  <div
                    :class="{
                      'card mb-0': true
                    }"
                  >
                    <div class="card-body">
                      <div
                        v-if="fields.original_audio_name"
                        class="text-center my-1 text-muted"
                      >
                        <small>
                          {{ fields.original_audio_name }}
                        </small>

                        <audio
                          class="w-100 mb-4"
                          controls
                        >
                          <source
                            :src="getFullAudioPath()"
                            type="audio/mpeg"
                          >
                        </audio>
                      </div>
                      <file-uploader
                        v-if="fields.audio_path === null || fields.audio_path === ''"
                        filter="audio/mp3"
                        icon="fe fe-refresh-ccw text-primary"
                        @select-file="selectFile"
                        @removed-file="removedFile"
                      />
                      <small
                        v-if="errors.audio"
                        class="text-danger"
                      >
                        {{ errors.audio[0] }}
                      </small>
                      <app-button
                        v-if="fields.original_audio_name"
                        class="w-100 d-flex align-items-center justify-content-center"
                        color="btn-outline-danger mt-4"
                        @click="audioDelete"
                      >
                        {{ $t('exclude_audio_capitalize') }}
                        <span class="fe fe-trash ms-1" />
                      </app-button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12">
                <app-button
                  class="w-100 d-flex align-items-center justify-content-center"
                  color="btn-primary"
                  @click="tab_agents.show()"
                >
                  {{ $t('next_capitalize') }}
                  <span class="fe fe-arrow-right ms-1" />
                </app-button>
              </div>
            </div>
          </div>
          <div
            id="agents"
            class="tab-pane fade"
            role="tabpanel"
          >
            <div
              v-for="(field_agents, index) in fields.agent_skills"
              :key="index"
            >
              <div class="card mt-4">
                <div class="card-header">
                  <div class="d-flex align-items-center ">
                    <h4 class="card-header-title">
                      {{ index + 1 }}º {{ $t('agent_preference') }}
                    </h4>
                    <span
                      v-show="(fields.agent_skills.length - 1 == index) && (index != 0)"
                      class="fe fe-trash text-primary cursor-pointer ps-2"
                      @click="removePreference()"
                    />
                  </div>

                  <ul class="nav nav-tabs nav-tabs-sm card-header-tabs">
                    <li class="nav-item">
                      <button
                        class="nav-link active two-lines"
                        data-bs-target="#actives"
                        data-bs-toggle="tab"
                      >
                        <span class="text-muted"> {{ $t('total_uppercase') }} </span>
                        <br>
                        <span class="subtitle">
                          {{ field_agents.agents.length }}
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
                <div class="card-body">
                  <VueMultiselect
                    v-model="field_agents.agents"
                    :placeholder="$t('select_agents_capitalize')"
                    :track-by="'id'"
                    :select-label="$t('click_or_press_enter_to_select')"
                    :deselect-label="$t('click_or_press_enter_to_remove')"
                    :selected-label="$t('selected_capitalize')"
                    :label="'name'"
                    :multiple="true"
                    :close-on-select="false"
                    :preserve-search="true"
                    :options="activeAgents"
                  />
                </div>
              </div>
            </div>

            <div class="row justify-content-between">
              <div class="col-lg-12 mb-4">
                <button
                  class="col-12 btn btn-outline-primary"
                  @click="addPreference"
                >
                  <span class="fe fe-plus" />
                  {{ $t('new_preference_capitalize') }}
                </button>
              </div>

              <div class="col-12 mb-4">
                <app-button
                  :loading="
                    loadingContext['create_receptive_queue'] ||
                      loadingContext['update_receptive_queue']
                  "
                  class="w-100 d-flex align-items-center justify-content-center"
                  color="btn-primary"
                  @click="submit"
                >
                  {{ this.$route.params.id ? $t('capitalizes.save') : $t('create') }}
                </app-button>
              </div>

              <div
                class="col-12 mb-2"
                v-if="this.$route.params.id"
              >
                <app-button
                  :loading="
                    loadingContext['delete_receptive_queue']
                  "
                  class="w-100 d-flex align-items-center justify-content-center text-danger"
                  color="btn"
                  @click="remove"
                >
                  <span class="fe fe-trash me-2 mb-1" />
                  {{ $t('delete_receptive_queue_capitalize') }}
                </app-button>
              </div>

              <div class="col-3 mx-auto">
                <app-button
                  class="w-100 d-flex align-items-center justify-content-center tx-text-gray"
                  color="btn"
                  @click="tab_general.show()"
                >
                  {{ $t('back_capitalize') }}
                </app-button>
              </div>
            </div>
          </div>
        </div>
        <br>
        <br>
      </div>
    </div>
  </div>
</template>

<script>
import FlatPickr from "vue-flatpickr-component";
import {Tab} from "bootstrap";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {convertSecondsToTime} from "@/utils.js";

import MainLoading from "@/components/main-loading.vue";
import AppButton from "@components/app-button";
import VueMultiselect from "vue-multiselect";
import FileUploader from "@/components/file-uploader";
import HeaderComponent from "@/components/header-component.vue";

export default {
  data() {
    return {
      qualifications: [],
      ivr_after_call: [],
      fields: {
        name: '',
        extension_number: '',
        wait_time: "00:00:30",
        acw_timeout: "00:00:00",
        qualification_list_id: '',
        priority: 0,
        limit_call_time: 0,
        ivr_after_call_id: 0,
        audio_path: '',
        original_audio_name: '',
        audio: null,
        agent_skills: [{
          skill: 1,
          agents: []
        }],
      },
      audio_delete: 0,
      config: {
        enableSeconds: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i:S",
        time_24hr: true
      },
      components: [],

      tab_general: null,
      tab_agents: null,
    }
  },
  components: {
    AppButton,
    VueMultiselect,
    FileUploader,
    FlatPickr,
    MainLoading,
    HeaderComponent
  },
  computed: {
    ...mapGetters("manager/filters", {
      filters: "getFilters"
    }),
    ...mapGetters("manager/receptive_queues", {
      receptiveQueue: "getReceptiveQueue",
      activeAgents: "getActiveAgents",
      errors: "getErrors",
      loadingContext: "getLoadingContext"
    }),
    ...mapGetters("auth", {
      user: "getUser",
    }),
    redirectUser() {
      if (this.$route.params.id)
        return `/manager/receptive-queue/${this.$route.params.id}`;

      return '/manager/receptive-queue';
    },
  },
  mounted() {
    this.createTabs();
    this.setIncludes(["qualification_lists", "ivr_after_call"]);
    this.findActiveAgents();
    this.findFilters().then(() => {
      this.qualifications = this.filters.qualification_lists;
      this.ivr_after_call = this.filters.ivr_after_call;

      this.qualifications.unshift({id: '', name: this.$t('select_qualification')});
      this.ivr_after_call.unshift({id: 0, name: this.$t('no_ivr_post_call')});
    });

    if (this.$route.params.id)
      this.findReceptiveQueueById({id: this.$route.params.id});
  },
  unmounted() {
    this.clearErrors();
  },
  methods: {
    ...mapActions("manager/receptive_queues", [
      "createReceptiveQueue",
      "findReceptiveQueueById",
      "updateReceptiveQueue",
      "deleteReceptiveQueue",
      "findActiveAgents",
    ]),
    ...mapActions("manager/filters", ["findFilters"]),
    ...mapActions("system", ["showConfirmationMessage"]),

    ...mapMutations("manager/filters", ["setIncludes"]),
    ...mapMutations("manager/receptive_queues", ["clearErrors"]),
    convertSecondsToTime,
    createTabs() {
      this.tab_general = new Tab(this.$refs["tab-general"]);
      this.tab_agents = new Tab(this.$refs["tab-agents"]);
    },
    getFullAudioPath() {
      return (
        process.env.VUE_APP_BA12AS.replace("/api/v1", "") +
        this.fields.audio_path +
        "&api_token=" +
        this.user.api_token
      );
    },
    formatHour(date) {
      let splited = date.split(":");
      return +splited[0] * 60 * 60 + +splited[1] * 60 + +splited[2];
    },
    addPreference() {
      const skill = this.fields.agent_skills.length + 1

      this.fields.agent_skills.push({
        agents: [],
        skill
      })
    },
    removePreference() {
      this.fields.agent_skills.pop();
    },
    selectFile(file) {
      this.fields.audio = file;
    },
    removedFile(file) {
      this.fields.audio = file;
    },
    audioDelete() {
      this.fields.original_audio_name = null;
      this.fields.audio_path = null;
      this.fields.audio = null;
      this.audio_delete = 1;
    },
    getFormData() {
      const formData = new FormData();
      formData.append("name", this.fields.name);
      formData.append("extension_number", this.fields.extension_number);
      formData.append("wait_time", this.formatHour(this.fields.wait_time));
      formData.append("acw_timeout", this.formatHour(this.fields.acw_timeout));
      formData.append("qualification_list_id", this.fields.qualification_list_id);
      formData.append('priority', this.fields.priority);
      formData.append('limit_call_time', this.fields.limit_call_time);

      if (this.fields.ivr_after_call_id !== 0 && this.fields.ivr_after_call_id !== null) {
        formData.append('ivr_after_call_id', this.fields.ivr_after_call_id);
      }

      if (this.fields.audio) {
        formData.append('audio', this.fields.audio);
        this.audio_delete = 0;
      }
      formData.append('audio_delete', this.audio_delete);

      const totalAgentsCount = this.fields.agent_skills.reduce(
        (previous, current) => {
          return previous + current.agents.length;
        },
        0
      );

      if (totalAgentsCount) {
        this.fields.agent_skills.forEach(agent_skill => {
          agent_skill.agents.forEach(({id}) => {
            formData.append(`agents[${agent_skill.skill}][]`, id);
          })
        });
      }
      return formData;
    },
    submit() {
      if (this.$route.params.id)
        this.edit();
      else
        this.create();
    },
    create() {
      let formData = this.getFormData();

      this.createReceptiveQueue({formData}).then((response) => {
        this.$router.push(`/manager/receptive-queue/${response.id}`);
      })
      .catch(() => this.tab_general.show())
      .finally(() => this.fields.audio = null);
    },
    edit() {
      let formData = this.getFormData();
      formData.append("_method", "PUT");

      this.updateReceptiveQueue({
        formData,
        id: this.$route.params.id
      }).then(() => {
        this.tab_general.show();
        this.findReceptiveQueueById({
          id: this.$route.params.id
        });
      })
      .catch(() => this.tab_general.show())
      .finally(() => this.fields.audio = null);
    },
    async remove() {
      let response = await this.showConfirmationMessage({
        title: this.$t('questions.delete_receptive_queue'),
        text: this.$t('questions_info.delete_receptive_queue'),
        type: "badWarning"
      })

      if (response.isConfirmed) {
        this.deleteReceptiveQueue({id: this.$route.params.id}).then(() => {
          this.$router.push("/manager/receptive-queue");
        });
      }
    }
  },
  watch: {
    receptiveQueue: {
      handler(data) {
        if (data) {
          // Fill fields
          this.fields.name = data.name;
          this.fields.extension_number = data.extension.extension_number;
          this.fields.wait_time = this.convertSecondsToTime(data.wait_time);
          this.fields.acw_timeout = this.convertSecondsToTime(data.acw_timeout);
          this.fields.qualification_list_id = data.qualification_list_id;
          this.fields.priority = data.priority;
          this.fields.limit_call_time = data.limit_call_time;
          this.fields.limit_call_time = data.limit_call_time;
          this.fields.ivr_after_call_id = data.ivr_after_call ? data.ivr_after_call.id : 0;
          this.fields.audio_path = data.audio_path;
          this.fields.original_audio_name = data.original_audio_name;

          // Handle agents preferences
          if (data.agents.length) {
            let agentSkills = [];
            const createSkills = (skill) => {
              for (let i = agentSkills.length; i < skill; i++) {
                agentSkills.push({
                  skill: i + 1,
                  agents: []
                })
              }
            }

            for (let agent of data.agents) {
              createSkills(agent.pivot_skill);
              agentSkills[agent.pivot_skill - 1].agents.push(agent);
            }

            this.fields.agent_skills = agentSkills;
          }
        }
      },
      deep: true,
    }
  }
}
</script>
