<template>
  <modal-component
    :center="true"
    :keyboard="false"
    :show="showModal"
    backdrop="static"
    size="modal-lg"
    @close="showModal = false"
  >
    <template #content>
      <div class="modal-content border-0">
        <div class="d-flex justify-content-start">
          <div
            class="rounded-start ps-0 ms-0 bg-primary border-customized"
          />
          <div class="col m-4">
            <div class="text-start">
              <img
                class="img-fluid"
                src="@assets/img/aloisio.svg"
                style="width: 57px"
              >
              <span>{{ $t('keep_your_account_safe') }}</span>
            </div>
            <hr class="m-2 mb-3 w-100 d-flex mx-auto">
            <span class="mt-1">{{ $t('update_your_password') }}</span>
            <form
              class="row g-3 needs-validation mt-1"
              novalidate
              @submit.prevent="submit"
            >
              <div class="col-md-6">
                <label
                  class="form-label fs-5"
                >{{ $t('new_password') }} </label>
                <div class="input-group input-group-merge rounded">
                  <input
                    v-model="fields.password"
                    :class="{
                      'form-control': true,
                      ' is-invalid': errors.password && errors.password[0],
                    }"
                    :disabled="loading.updatePassword"
                    :type="showPass ? 'text' : 'password'"
                    data-test="newUpdatePassword-input"
                    :placeholder="$t('password_capitalizes')"
                    required
                    autocomplete="on"
                    @input="handleAllValidations"
                  >
                  <div
                    id="inputGroupInvalid"
                    class="input-group-text rounded-end"
                    @click="showPass = !showPass"
                  >
                    <i
                      v-if="!showPass"
                      class="fe fe-eye"
                    />
                    <i
                      v-else
                      class="fe fe-eye-off"
                    />
                  </div>
                  <div class="invalid-feedback">
                    {{ errors.password && errors.password[0] }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <label
                  class="form-label"
                  for="validationCustom01"
                >{{ $t('repeat_password') }}</label>
                <div class="input-group input-group-merge">
                  <input
                    v-model="fields.checkPassword"
                    :class="{
                      'form-control': true,
                      ' is-invalid': errors.checkPassword && errors.checkPassword,
                    }"
                    :disabled="loading.updatePassword"
                    :type="showCheckPass ? 'text' : 'password'"
                    data-test="checkUpdatePassword-input"
                    :placeholder="$t('password_capitalizes')"
                    required
                    autocomplete="on"
                    @input="handlePasswordsEqualsValidation"
                  >
                  <div
                    class="input-group-text rounded-end"
                    @click="showCheckPass = !showCheckPass"
                  >
                    <i
                      v-if="!showCheckPass"
                      class="fe fe-eye"
                    />
                    <i
                      v-else
                      class="fe fe-eye-off"
                    />
                  </div>
                  <div class="invalid-feedback">
                    {{ errors.checkPassword && errors.checkPassword[0] }}
                  </div>
                </div>
              </div>
              <div class="fs-5">
                <p class="p-0 mb-1 mt-2">
                  {{ $t('capitalizes.criteria') }}:
                </p>
                <div class="g-2 row text-secondary mb-3">
                  <div>
                    <span
                      :class="validations.isLengthValid ? 'text-primary fa-check' : 'text-danger fa-x'"
                      class="fa-solid"
                    />
                    <span class="p-2">{{ $t('number_of_char_password') }} </span>
                  </div>
                  <div>
                    <span
                      :class="(validations.isStringAndNumberValid && validations.isOneLetterUppercase && validations.isOneSpecialCharacter) ? 'text-primary fa-check' : 'text-danger fa-x'"
                      class="fa-solid"
                    />
                    <span class="p-2">{{ $t('at_least_uppercase_especial_char_password') }}</span>
                  </div>
                  <div>
                    <span
                      :class="validations.isSequenceValid ? 'text-primary fa-check' : 'text-danger fa-x'"
                      class="fa-solid"
                    />
                    <span class="p-2">{{ $t('not_have_sequence_password') }} </span>
                  </div>
                  <div>
                    <span
                      :class="validations.isPasswordsEquals ? 'text-primary fa-check' : 'text-danger fa-x'"
                      class="fa-solid"
                    />
                    <span class="p-2">{{ $t('same_password_fields') }} </span>
                  </div>
                  <span>{{ $t('password_hint') }} </span>
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <app-button
                  :disabled="!handleAllValidations()"
                  :loading="loading.updatePassword"
                  class="btn btn-primary btn-sm col"
                  data-test="updatePassword-button"
                  style="border-radius: 12px;"
                  type="submit"
                >
                  {{ $t('capitalizes.save') }}
                </app-button>
              </div>
              <user-logout />
            </form>
          </div>
        </div>
      </div>
    </template>
  </modal-component>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

import moment from "moment";

import AppButton from "@components/app-button";
import ModalComponent from '@/components/modal-component.vue';
import UserLogout from './user-logout.vue';

export default {
  data() {
    return {
      name: "PasswordUpdate",
      showModal: false,

      validations: {
        isLengthValid: false,
        isStringAndNumberValid: false,
        isOneLetterUppercase: false,
        isOneSpecialCharacter: false,
        isPasswordsEquals: false,
        isSequenceValid: false,
      },

      showPass: false,
      showCheckPass: false,

      fields: {
        password: '',
        checkPassword: ''
      },
    }
  },

  mounted() {
    this.verifyIfShowModal()
  },

  watch: {
    user(user) {
      user && this.verifyIfShowModal()
    }
  },

  components: {
    AppButton,
    ModalComponent,
    UserLogout,
  },

  computed: {
    ...mapGetters("manager/user", {
      errors: "getErrors",
      loading: "getLoadingFlag",
    }),

    ...mapGetters("auth", {
      user: "getUser",
    }),
  },

  methods: {
    ...mapActions("manager/user", ["updatePassword"]),

    verifyIfShowModal() {
      const currDate = moment();
      const diffLastPasswordUpdated = currDate.diff(this.user.password_updated_at, 'months');

      const diffUserUpdatedAt = moment().diff(moment(this.user.user_updated_data?.updated_at * 1000), "months");
      const showUpdateModal = diffUserUpdatedAt >= 6 || !this.user.user_updated_data?.updated_at

      if ((!this.user.password_updated_at || diffLastPasswordUpdated >= 6) && !showUpdateModal) {
        this.showModal = true
      }
    },

    /**
     * Password validations
     */
    handlePasswordLengthValidation() {
      const validation = this.fields.password && this.fields.password.length >= 8 && this.fields.password.length <= 16

      this.validations.isLengthValid = validation
      return validation
    },

    handleLetterAndNumberValidation() {
      const regExp = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
      const validation = regExp.test(this.fields.password)

      this.validations.isStringAndNumberValid = validation
      return validation
    },

    handlePasswordsEqualsValidation() {
      const validation = this.fields.password === this.fields.checkPassword && this.fields.password.trim().length > 0

      this.validations.isPasswordsEquals = validation
      return validation
    },

    handleSequenceValidation() {
      const validation = this.validateSequence(this.fields.password) && this.fields.password.trim().length > 0

      this.validations.isSequenceValid = validation
      return validation
    },

    validateSequence(str) {
      for (let i = 1; i < str.length; i++) {
        const currentChar = str.charAt(i);
        const previousChar = str.charAt(i - 1);
        const nextChar = str.charAt(i + 1);

        if (currentChar === previousChar && (nextChar && currentChar === nextChar && currentChar !== ' ')) {
          return false;
        }

        const currentCharCode = currentChar.charCodeAt(0);
        const previousCharCode = previousChar.charCodeAt(0);
        const nextCharCode = nextChar && nextChar.charCodeAt(0);

        if (currentCharCode === previousCharCode + 1 && currentCharCode === nextCharCode - 1) {
          return false;
        }

        if (currentCharCode === previousCharCode - 1 && currentCharCode === nextCharCode + 1) {
          return false;
        }
      }

      return true;
    },

    handleOneLetterUppercaseValidation() {
      const regExp = /[A-Z]/;
      const validation = regExp.test(this.fields.password)

      this.validations.isOneLetterUppercase = validation
      return validation
    },

    handleSpecialCharacterValidation() {
      const regExp = /[!|@|$|!|%|*|#|?|&]/;
      const validation = regExp.test(this.fields.password)

      this.validations.isOneSpecialCharacter = validation
      return validation
    },

    /**
     * Handle all validations to update indicators while users types
     */
    handleAllValidations() {
      const validations = [
        'handlePasswordLengthValidation',
        'handleLetterAndNumberValidation',
        'handlePasswordsEqualsValidation',
        'handleSequenceValidation',
        'handleOneLetterUppercaseValidation',
        'handleSpecialCharacterValidation'
      ];

      let isValid = true;
      validations.forEach((validation) => {
        let verification = this[validation]();

        if (!verification) isValid = false;
      });

      return isValid;
    },

    submit() {
      let userData = {
        name: this.user.name,
        email: this.user.email,
        extension_number: this.user.extension.extension_number,
        role: this.user.role.name,
        timezone: this.user.settings.timezone
      };

      let fields = {
        ...userData,
        password: this.fields.password,
        check_password: this.fields.checkPassword
      }

      this.updatePassword({id: this.user.id, fields}).then(() => this.showModal = false);
    }
  },
}
</script>
<style
  lang="scss"
  scoped
>
.border-customized {
  width: 13px;
  min-height: 100%;
}

.fa-check {
  color: #C5C5C5;
}

.fa-x {
  margin-right: 5px;
}
</style>
