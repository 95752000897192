import AgentsReport from "@dialer/manager/pages/reports/agents-report";

export default {
  path: "agents-report",
  name: "agents-report",
  components: {
    main: AgentsReport,
  },
  meta: {
    "short-side-bar": true
  }
};
