<template>
  <div class="main-content">
    <div class="container-fluid">
      <header-component
        :title="$t('blocklist_capitalize')"
        :pretitle="$t('settings_uppercase')"
        redirect-link="/manager/voice-settings"
      >
        <template #buttons>
          <button
            class="btn btn-outline-secondary px-6 rounded-4"
            @click="generateCSVModal.show()"
          >
            <div>
              <i class="fe fe-download" />
              {{ $t('download_CSV') }}
            </div>
          </button>
        </template>
      </header-component>

      <div class="row">
        <p style="margin-bottom: 1.125rem;">
          {{ $t('block_list_explanation') }}
        </p>
        <div class="col-lg-6">
          <div class="card">
            <div class="card-header">
              <h4>
                {{ $t('add_numbers') }}
              </h4>
            </div>
            <div class="card-body">
              <input
                v-model="this.addNumbers"
                type="text"
                class="form-control rounded-3"
                :placeholder="$t('placeholder_add_numbers')"
              >
              <div class="d-flex gap-4 mt-4">
                <app-button
                  class="w-100 btn rounded-4"
                  :class="!this.addNumbers ? 'btn-light' : 'btn-primary'"
                  :loading="loading.addNumberToBlocklist"
                  @click="addBlocklistNumber()"
                  :disabled="!this.addNumbers"
                >
                  {{ $t('update_omni') }}
                </app-button>
                <a
                  href="#"
                  class="w-100 btn btn-outline-primary rounded-4"
                  @click="openCsvModal('add')"
                >
                  <i class="fe fe-upload" />
                  {{ $t('add_csv_list') }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card">
            <div class="card-header">
              <h4>
                {{ $t('remove_numbers') }}
              </h4>
            </div>
            <div class="card-body">
              <input
                v-model="this.removeNumbers"
                type="text"
                class="form-control rounded-3"
                :placeholder="$t('placeholder_add_numbers')"
              >
              <div class="d-flex gap-4 mt-4">
                <app-button
                  class="w-100 btn rounded-4"
                  :class="!this.removeNumbers ? 'btn-light' : 'btn-primary'"
                  :loading="loading.removeNumberOfBlocklist"
                  @click="removeBlocklistNumber()"
                  :disabled="!this.removeNumbers"
                >
                  {{ $t('update_omni') }}
                </app-button>
                <a
                  href="#"
                  class="w-100 btn btn-outline-primary rounded-4"
                  @click="openCsvModal('remove')"
                >
                  <i class="fe fe-upload" />
                  {{ $t('add_csv_list') }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <h4>
                {{ $t('search_number') }}
              </h4>
            </div>
            <div class="card-body d-flex gap-4">
              <input
                v-model="searchNumber"
                type="text"
                class="form-control rounded-3"
                :placeholder="$t('placeholder_add_numbers')"
              >
              <app-button
                @click="checkNumber()"
                :loading="loading.getIsNumberOnBlocklist"
                class="btn col-2"
                :class="!this.searchNumber ? 'btn-light' : 'btn-primary'"
                :disabled="!searchNumber"
              >
                {{ $t('search_number') }}
              </app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      ref="generateCSVModal"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h3
              class="modal-title"
              id="modalDownloadCSVLabel"
            >
              {{ $t('generate_report') }}
            </h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div class="modal-body">
            <p>
              {{ $t('generate_report_2') }}
            </p>
            <form
              id="formCSV"
              @submit.prevent="generateCSV()"
            >
              <div class="mb-3">
                <label
                  for="input-email"
                  class="col-form-label"
                >{{ $t('report_email') }}</label>
                <input
                  type="email"
                  class="form-control"
                  id="input-email"
                  required
                  v-model="email"
                >
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              {{ $t('cancel_general') }}
            </button>
            <app-button
              @click="generateCSV"
              :loading="loading.downloadTable"
            >
              {{ $t('generate_csv') }}
            </app-button>
          </div>
        </div>
      </div>
    </div>
    <modal-component
      :show="displayModal"
      :size="'modal-lg'"
      @close="displayModal = false"
    >
      <template #content>
        <i
          type="button"
          @click="displayModal = false"
          class="btn-close position-absolute top-0 end-0 pt-4 pe-4"
        />
        <div style="margin: 2.25rem 6rem;">
          <h6
            class="header-pretitle m-0 overflow-ellipsis"
            style="font-weight: 100 !important"
            name="body"
          >
            {{ $t('blocklist') }}
          </h6>
          <h2
            v-if="mode == 'add'"
            class="header-title overflow-ellipsis mt-1 b6 lh-lg"
          >
            {{ $t('include_blocklist') }}
          </h2>
          <h2
            v-else-if="mode == 'remove'"
            class="header-title overflow-ellipsis mt-1 b6 lh-lg"
          >
            {{ $t('remove_blocklist') }}
          </h2>
          <div style="margin: 2rem 0 2.375rem 0;">
            <label class="form-label">
              {{ $t('phones_list') }}
              <span class="form-text">{{ $t('send_csv_file') }}</span>
            </label>
            <span class="form-text fw-normal fs-6">
              {{ $t('only_phone_numbers') }}
            </span>
          </div>
          <file-upload
            @select-file="selectFile"
            filter="text/csv,text/plain"
            icon="fe fe-database"
          />
          <div class="form-group mt-5">
            <label class="form-label">
              {{ $t('split_by') }}
            </label>
            <select
              class="form-select"
              v-model="this.separator"
            >
              <option
                value="."
                class="text-muted"
              >
                . ({{ $t('dot') }})
              </option>
              <option
                value=","
                class="text-muted"
              >
                , ({{ $t('comma') }})
              </option>
              <option
                value=";"
                class="text-muted"
              >
                ; ({{ $t('semicolon') }})
              </option>
              <option
                value="tab"
                class="text-muted"
              >
                {{ $t('tab') }}
              </option>
            </select>
          </div>
          <app-button
            class="w-100 btn btn-primary"
            @click="(mode == 'add') ? addCsvNumbersToBlocklist() : removeCsvNumbersFromBlocklist()"
            :disabled="loading.removeCsvOfBlocklist || loading.addCsvInBlocklist"
          >
            {{ $t('register_blocklist') }}
          </app-button>
        </div>
      </template>
    </modal-component>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import ModalComponent from "@components/modal-component";
import FileUpload from "@components/file-uploader";
import AppButton from "@components/app-button";
import HeaderComponent from "@/components/header-component.vue";
import { Modal } from "bootstrap";

export default {
 data() {
    return {
        file: null,
        email: '',
        separator: ',',

        searchNumber: "",

        addNumbers: "",
        removeNumbers: "",

        swalled: false,

        displayModal: false,
        mode: "",
        generateCSVModal: null,
    }
 },

 components: {
    FileUpload,
    AppButton,
    ModalComponent,
    HeaderComponent,
 },

 methods: {
 ...mapMutations("system", ['setErrorMessage']),
 ...mapMutations("system", ["setErrorMessage", "setSuccessMessage"]),

 ...mapActions("manager/blocklist", [
    "downloadTable",
    "addCsvInBlocklist",
    "addNumberToBlocklist",
    "removeNumberOfBlocklist",
    "getIsNumberOnBlocklist",
    "removeCsvOfBlocklist",
 ]),

    selectFile(file){
        this.file = file
    },
    generateCSV() {
      this.downloadTable({
        email: this.email,
      }).then(() => this.generateCSVModal.hide());
    },
    openCsvModal(content) {
      this.displayModal = true;
      this.mode = content;
    },
    async addCsvNumbersToBlocklist(){
        let formData = new FormData();
        formData.append("mailing", this.file)
        formData.append("separator", this.separator)
        await this.addCsvInBlocklist({ formData })
        this.displayModal = false
    },

    async removeCsvNumbersFromBlocklist() {
      let formData = new FormData();
      formData.append("mailing", this.file);
      formData.append("separator", this.separator);
      await this.removeCsvOfBlocklist({ formData });
      this.displayModal = false
    },

    addBlocklistNumber() {
      let addFormData = new FormData();
      addFormData.append("numbers", this.addNumbers);
      this.addNumberToBlocklist({ addFormData })
      .then(() => {
        this.addNumbers = "";
      })
    },

    removeBlocklistNumber() {
      let addFormData = new FormData();
      addFormData.append("numbers", this.removeNumbers);
      this.removeNumberOfBlocklist({ numbers: this.removeNumbers })
      .then(() => {
        this.removeNumbers = "";
      });
    },

    checkNumber() {
      this.getIsNumberOnBlocklist({ number: this.searchNumber })
    }
 },

 computed: {
    ...mapGetters("auth", {
      users: "getUser"
    }),
    ...mapGetters("manager/blocklist", {
        errors: "getErrors",
        deletedNumber: "getDeletedNumber",
        loading: "getLoadingFlags",
    })
 },

 mounted() {
    this.email = this.users.email;
    this.generateCSVModal = new Modal(this.$refs.generateCSVModal);
 }
}
</script>

<style lang="css" scoped>
.btn-outline-primary{
    background: white !important;
    border: 0;
}
.btn-outline-primary:hover{
    background: #007bff !important;
}

h4 {
  margin-bottom: 0;
}

h6 {
  color: #373753;
  font-weight: 100 !important;
}
</style>
