<template>
  <hover-button
    class="ms-auto"
    ref="callHistoryButton"
    @click="toggleDropdown"
    icon="far fa-clock"
    :keep-button-hovered="showCallHistoryCard"
    :background-color="getBackgroundColor"
  />

  <dropdown-component
    :is-open="showCallHistoryCard"
    :button-rect="buttonRect"
    :title="$t('call_history')"
    direction="right"
  >
    <div class="search-number d-flex align-items-center justify-content-between">
      <i
        class="fa-regular fa-clone tx-text-gray cursor-pointer"
        @click="paste"
      />

      <input
        v-maska:[phoneNumberOptions]="phoneNumberBindedObject"
        class="form-control search-number-input border-0 p-0"
        :placeholder="$t('type_number_here')"
      >

      <button
        class="button-call-search align-self-center bg-call"
        :disabled="!phoneNumberBindedObject.unmasked"
        @click="dial({ number: phoneNumberBindedObject.unmasked })"
      >
        <span class="fa-regular fa-phone text-medium" />
      </button>
    </div>
    <div class="overflow-auto card-call-history-body">
      <div v-if="!loadingFlags.getCallHistory || callHistory.length > 0">
        <div
          v-if="callHistory.length > 0"
          class="mt-3"
        >
          <template
            v-for="call of callHistory"
            :key="call?._id"
          >
            <div
              v-if="phoneNumberBindedObject.unmasked == '' || call?.number.includes(phoneNumberBindedObject.unmasked)"
              class="btn-call-content rounded"
            >
              <div class="py-2 d-flex justify-content-between">
                <div>
                  <div class="d-flex align-items-center gap-2 ">
                    <span
                      class="fa-regular"
                      :class="call.status === 7 ? 'fa-phone-arrow-up-right tx-call' : 'fa-phone-xmark tx-red-300'"
                    />
                    <p
                      class="ps-1 mb-0"
                    >
                      {{ formatPhoneNumber(call?.number) }}
                    </p>
                  </div>
                  <span class="text-secondary fs-6">
                    {{ moment(call.call_date).format("DD/MM [às] HH:mm") }}
                  </span>
                </div>
                <button
                  v-show="!loadingFlags.startManualCallAfterAgentEnteredManual || (callHistoryNumberCalling === call._id
                    && loadingFlags.startManualCallAfterAgentEnteredManual)"
                  class="button-call align-self-center"
                  :class="{
                    'calling': callHistoryNumberCalling === call._id,
                    'loadingFlags': callHistoryNumberCalling && callHistoryNumberCalling !== call._id ? 'loadingFlags' : '',
                    'bg-call': call.status === 7,
                    'bg-red-300': call.status !== 7
                  }"
                  @click="dial(call)"
                >
                  <span class="fa-regular fa-phone" />
                  <span class="fa-regular fa-phone-volume" />
                </button>
              </div>
              <hr class="m-0">
            </div>
          </template>
        </div>
        <div
          class="d-flex flex-column text-center mt-6"
          v-else
        >
          <h2 class="my-0">
            Oops...
          </h2>
          <img
            :src="require(`@/assets/img/aloisio/aloisioThinking.svg`)"
            class="align-self-center my-4"
            style="width: 68px;"
          >
          <p class="fs-6">
            {{ $t("no_calls_made") }}
          </p>
        </div>
      </div>
    </div>
  </dropdown-component>
</template>
<script>
import moment from "moment";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { Mask } from "maska";
import { AgentStatus, formatPhoneNumber } from "@/utils";
import HoverButton from "@dialer/agent/components/hover-button.vue";
import DropdownComponent from "@dialer/agent/components/dropdown-component.vue";

export default {
  name: "CallHistory",
  data() {
    return {
      showCallHistoryCard: false,
      hover: false,
      AgentStatus,
      buttonRect: null,
      phoneNumberOptions: {
        mask: ["(##) ####-####", "(##) #####-####", "##############9"],
        tokens: {
          "9": {
            pattern: /[0-9]/,
            repeated: true,
          },
        },
      },
      phoneNumberBindedObject: {
        masked: "",
        unmasked: "",
      },
    }
  },

  components: {
    HoverButton,
    DropdownComponent,
  },

  mounted() {
    this.getCallHistory({
      start_date: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      end_date: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      agent_id: this.user.id,
    });
  },

  computed: {
    ...mapGetters("agent/call", {
      callHistory: "getCallHistory",
      loadingFlags: "getLoadingFlags",
      callHistoryNumberCalling: "getCallHistoryNumberCalling",
    }),

    ...mapGetters("auth", {
      user: "getUser",
    }),

    ...mapGetters("agent/agent", {
      loggedCampaign: "getCampaign",
      activeDropdownRight: "getActiveDropdownRight",
    }),

    ...mapGetters("system", {
      status: "getStatus",
      mode: "getMode",
    }),

    ...mapGetters("agent/ring_group", {
      isRingGroupActive: "isRingGroupActive",
    }),

    ...mapGetters("agent/agent", {
      loggedCampaign: "getCampaign",
    }),

    getBackgroundColor() {
      if (this.loggedCampaign && this.status === AgentStatus.IDLE && this.mode === "dialer") {
        return "var(--bs-white)";
      }

      return "#E1E9F4";
    },
  },

  methods: {
    formatPhoneNumber,
    moment: moment,
    ...mapActions("agent/call", ["getCallHistory", "startManualCallAfterAgentEnteredManual"]),
    ...mapActions("agent/ring_group", ["makeCallPABX"]),
    ...mapActions("agent/agent", ["setActiveDropdownRight"]),
    ...mapMutations("system", ["setMuted"]),

    toogle(toogle) {
      this.hover = toogle
    },

    toggleDropdown() {
      this.showCallHistoryCard = !this.showCallHistoryCard
      if (this.showCallHistoryCard) {
        this.setActiveDropdownRight("CallHistory");
        this.calculateButtonRect();
        window.addEventListener("resize", this.calculateButtonRect);
      } else {
        window.removeEventListener("resize", this.calculateButtonRect);
      }
    },

    calculateButtonRect() {
      const rect = this.$refs.callHistoryButton.$el.getBoundingClientRect();
      this.buttonRect = rect;
    },

    dial(call) {
      if (this.isRingGroupActive) {
        this.makeCallPABX(call.number).then(() => {
          this.showCallHistory = !this.showCallHistory;
        });
      }

      if (this.loggedCampaign) {
        if (!this.loadingFlags.startManualCallAfterAgentEnteredManual) {
          this.startManualCallAfterAgentEnteredManual({ phone: call.number, _id: call._id }).then(() => {
            this.toggleDropdown();
          })
        }
      }
    },
    paste() {
      navigator.clipboard.readText().then(text => {
        const mask = new Mask(this.phoneNumberOptions);
        this.phoneNumberBindedObject.masked = mask.masked(text.replace(/\D/g, ""));
        this.phoneNumberBindedObject.unmasked = mask.unmasked(this.phoneNumber);
      });
    },
  },

  watch: {
    activeDropdownRight(newVal) {
      if (newVal !== "CallHistory" && this.showCallHistoryCard) {
        this.toggleDropdown();
      }
    },
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.calculateButtonRect);
  },
};
</script>

<style lang="scss" scoped>
.search-number {
  padding: 24px 16px;
  border-bottom: 1px solid var(--color-gray-blue-300);

  .fa-clone {
    rotate: 90deg;
  }

  .search-number-input {
    width: unset;
    border-radius: 0;

    &::placeholder {
      color: var(--color-text-gray);
      opacity: 1;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .button-call-search {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-text-light;
    border: none;
    border-radius: 9px;
    width: 48px;
    height: 24px;

    &:disabled {
      background-color: var(--color-gray-200);
      color: var(--color-text-200);
      opacity: 30%;
    }
  }
}

.card-call-history-body {
  height: 345px;

  > div:first-child {
    height: 320px;
    overflow-y: scroll;
  }

  .btn-call-content {
    margin: 0 10px;
    padding: 0 13px 0 1rem;

    &:hover,
    &:has(.calling) {
      background-color: $color-gray-100;

      .button-call {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &:has(.loadingFlags) {
      cursor: default;
      background-color: white;
      opacity: 0.3;

      .button-call {
        display: none;
      }
    }

    .button-call {
      display: none;
      color: $color-text-light;
      border: none;
      border-radius: 9px;
      width: 48px;
      height: 24px;

      .fa-phone {
        display: block;
      }

      .fa-phone-volume {
        display: none;
      }

      &.calling {
        animation: border-increase 1s linear infinite !important;

        .fa-phone {
          display: none;
        }

        .fa-phone-volume {
          display: inline-block !important;
          animation: spin 1s linear infinite;
          color: $color-text-light;
        }
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        25% {
          transform: rotate(10deg);
        }
        50% {
          transform: rotate(0deg);
        }
      }

      @keyframes border-increase {
        25% {
          outline: 1.78px solid rgba(157, 212, 164, 1);
        }
        50% {
          outline: 3.56px solid rgba(141, 187, 146, 1);
        }
      }
    }
  }
}
</style>
