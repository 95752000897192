<template>
  <div class="mb-0">
    <div
      class="d-flex align-items-center"
      :class="{ 'mb-2': description }"
    >
      <span
        class="badge bg-tag sizing rounded-circle d-flex align-items-center justify-content-center"
        style="margin-right: 8px;"
      >
        <i :class="icon" />
      </span>
      <h5 class="mb-0">
        {{ title }}
      </h5>
      <span class="tx-red-300">*</span>
    </div>
    <p
      v-if="description"
      class="tx-text-gray mb-4 fs-6"
    >
      {{ description }}
      <router-link
        v-if="link"
        to="/manager/feedbacks"
        class="tx-blue-300 fw-medium"
      >
        {{ $t("here") }}.
      </router-link>
    </p>

    <div
      v-if="selectField"
      class="mt-4"
    >
      <span v-if="label">
        {{ label }}
      </span>
      <selects-components
        v-if="!loading && listArray?.length"
        :id="id"
        :current-selected="currentSelected"
        :list-array="listArray"
        :multi-select-check="multiSelectCheck"
        :multiple-select="multiSelected"
        :search-name="searchName"
        :single-select="singleSelect"
        :text-select="textSelect"
        @selected="handleSelected"
      />
    </div>
    <slot name="body" />
  </div>
</template>

<script>
import SelectsComponents from "@dialer/manager/pages/insights-ai/components/selects-component.vue";

export default {
  name: "FieldSelector",
  components: { SelectsComponents },
  props: {
    id: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ""
    },
    selectField: {
      type: Boolean,
      default: true
    },
    searchName: {
      type: String,
      required: true
    },
    listArray: {
      type: [Array, Object],
      default: null
    },
    textSelect: {
      type: String,
      default: "Selecione"
    },
    typeSelect: {
      type: String,
      default: "multi-selected"
    },
    loading: {
      type: Boolean,
      default: false
    },
    multiSelectCheck: {
      type: Boolean,
      default: false
    },
    currentSelected: {
      type: [Array, Object, String],
      default: null
    },
    label: {
      type: String,
      default: ""
    },
    link:{
      type: String,
      default: ""
    }
  },
  emits: ["selected"],
  data() {
    return {
      active: false,
      multiSelected: false,
      singleSelect: false
    };
  },
  methods: {
    handleSelected($event) {
      this.$emit("selected", $event);
    },
    handlerSelect() {
      const option = this.typeSelect;
      switch (option) {
        case "multi-selected":
          return (this.multiSelected = true);
        case "single-selected":
          return (this.singleSelect = true);
        default:
          return false;
      }
    }
  },
  mounted() {
    this.handlerSelect();
  }
};
</script>

<style lang="scss" scoped>
.bg-tag {
  background-color: $color-gray-blue-300;
}

.sizing {
  width: 32px;
  height: 32px;
}
</style>
