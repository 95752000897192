<template>
  <div
    :class="{
      'd-inline-block': display
    }"
    class="position-relative"
    ref="tooltipRef"
    @mouseover="showTooltip"
    @mouseleave="hideTooltip"
  >
    <slot name="content" />
  </div>
</template>

<script setup>
import {ref, onBeforeUnmount, defineProps, defineEmits} from 'vue';
import {createPopper} from '@popperjs/core';

const emits = defineEmits([
  "show",
  "close"
])

const props = defineProps({
  placement: {
    type: String,
    default: "top"
  },

  display: {
    type: Boolean,
    default: true
  }
});

const tooltipRef = ref(null);
const popoverRef = ref(null);
const show = ref(false);

let popperInstance = null;

const showTooltip = () => {
  show.value = true;
  emits('show')
  createPopperInstance();
}

const hideTooltip = () => {
  show.value = false;
  emits('close')
  destroyPopperInstance();
}

const createPopperInstance = () => {
  if (tooltipRef.value && popoverRef.value) {
    popperInstance = createPopper(tooltipRef.value, popoverRef.value, {
      placement: props.placement,
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            mainAxis: false,
          },
        },
      ],
    });
  }
}
const destroyPopperInstance = () => {
  if (popperInstance) {
    popperInstance.destroy();
    popperInstance = null;
  }
}

onBeforeUnmount(() => {
  destroyPopperInstance();
});

</script>

<style scoped lang="scss">
</style>