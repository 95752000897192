<template>
  <div class="modal-body pt-3 border-0">
    <div class="col card mx-2 cursor-pointer">
      <div class="card-body">
        <div class="row align-items-center justify-content-center">
          <div class="col-auto">
            <img
              src="@assets/img/logo.svg"
              class="d-flex justify-content-center col-md-4 mx-auto mb-2"
            >
            <h2 class="d-flex justify-content-center form-label">
              {{ $t('payment_slip') }}
            </h2>
          </div>
        </div>
        <a
          :href="tickeyData.ticket_url"
          data-test="openBilletLink-link"
          target="_blank"
          class="col-lg-12 btn btn-primary"
        >{{ $t('open_payment_slip') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters("manager/user_profile", {
      tickeyData: 'getTransactionData'
    }),
  }
};
</script>
