import axios from "axios";

export default {

  findCoupons({ commit }) {
    commit('startLoading')
    commit('setList', []);
    return new Promise((resolve, reject) => {
      axios.get(`/coupons?per_page=-1`)
        .then((response) => {
          commit('setPagination', response.data.meta.pagination)
          commit('setList', response.data.data);
          resolve();
        }).catch((response) => {
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('stopLoading')
        });
    });
  },
  findRoutesOfCoupon({ commit }, payload) {
    commit('startLoading')
    commit('setListRoutesOfCoupon', []);
    return new Promise((resolve, reject) => {
      axios.get(`/coupons/${payload.id}/routes?per_page=-1`)
        .then((response) => {
          commit('setListRoutesOfCoupon', response.data.data);
          resolve();
        }).catch((response) => {
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('stopLoading')
        });
    });
  },

  editTags({ commit }, payload) {
    commit('startLoading')
    return new Promise((resolve, reject) => {
      axios.put(`/tags/${payload.id}`, {
        color: payload.color,
        companies: payload.companies,
        name: payload.name,
      })
        .then(() => {
          resolve();
        }).catch((response) => {
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('stopLoading')
        });
    });
  },
  createCoupons({ commit }, payload) {
    commit('startLoading')
    commit('setCreatedCouponData', [])
    return new Promise((resolve, reject) => {
      axios.post(`/coupons`, {
          credit: payload.credit,
          dialer_licenses: payload.dialer_licenses,
          name: payload.name,
          partner_id: payload.partner_id,
          webphone_licenses: payload.webphone_licenses,
      })
        .then((response) => {
          commit('system/setSuccessMessage', 'Cupom criado com sucesso!', { root: true })
          commit('setCreatedCouponData', response.data.data)
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('stopLoading')
        });
    });
  },
  editCoupons({ commit }, payload) {
    commit('startLoading')
    commit('setCreatedCouponData', [])
    return new Promise((resolve, reject) => {
      axios.put(`/coupons/${payload.id}`, {
          credit: payload.credit,
          dialer_licenses: payload.dialer_licenses,
          name: payload.name,
          partner_id: payload.partner_id,
          webphone_licenses: payload.webphone_licenses,
      })
        .then((response) => {
          commit('system/setSuccessMessage', 'Informações do cupom editadas com sucesso!', { root: true })
          commit('setCreatedCouponData', response.data.data)
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('stopLoading')
        });
    });
  },
  createRoutes({ commit }, payload) {
    commit('startLoading')
    commit('setRouteData', [])
    return new Promise((resolve, reject) => {
      axios.post(`/coupons/${payload.id}/routes`, {
          allow_landline: payload.allow_landline,
          allow_mobile: payload.allow_mobile,
          endpoint: payload.endpoint,
          host: payload.host,
          name: payload.name,
          route: payload.route,
          uses_country_code: payload.uses_country_code,
      })
        .then((response) => {
          commit('setRouteData', response.data.data)
          resolve();
        }).catch((response) => {
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('stopLoading')
        });
    });
  },
  deleteRoutes({ commit }, payload) {
    commit('startLoading')
    return new Promise((resolve, reject) => {
      axios.delete(`/coupons/${payload.id}/routes/${payload.route_id}`)
        .then(() => {
          commit('system/setSuccessMessage', 'Rota deletada com sucesso!', { root: true })
          resolve();
        }).catch((response) => {
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('stopLoading')
        });
    });
  },
  deleteCoupons({ commit }, payload) {
    commit('startLoading')
    return new Promise((resolve, reject) => {
      axios.delete(`/coupons/${payload.id}`)
        .then(() => {
          commit('system/setSuccessMessage', 'Cupom deletado com sucesso!', { root: true })
          resolve();
        }).catch((response) => {
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('stopLoading')
        });
    });
  },
}
