export default {
    startLoading: (state, flag) => state.loadingFlags[flag] = true,
    stopLoading: (state, flag) => state.loadingFlags[flag] = false,
    setAllActiveCalls: (state, allActiveCalls) => {
        const auxActiveCalls = {};

        for (const statusKey in allActiveCalls) {
            const callsArray = allActiveCalls[statusKey];
            const statusObject = {};

            for (const call of callsArray) {
                statusObject[call.id] = call;
            }

            auxActiveCalls[statusKey] = statusObject;
        }

        state.allActiveCalls = auxActiveCalls;
    },
    updateCallStatus: (state, call) => {
        state.allActiveCalls[call.status][call.id].status = call.status;
    },
    createActiveCall: (state, call) => {
        if(!state.allActiveCalls[call.status])
            state.allActiveCalls[call.status] = {};

        state.allActiveCalls[call.status][call.id] = call;
    },
    removeActiveCall: (state, call) => {
        delete state.allActiveCalls[call.status][call.id];
    }
}
