export default {
  loadingFlags: {
    loadSettingsIA: false,
    updateSettingsIA: false,
    loadDashboardIA: false,
    loadCampaignsIA: false,
    loadCampaignsAgents: false,
    loadCampaignsAgentsById: false,
    campaignsAgents: false,
    updateAiChat: false
  },
  errors: {},
  settingsIA: {},
  dashboardIA: [],

  campaigns: [],
  campaignsPagination: {},

  metricsCampaign: {},

  campaignsAgents: [],
  campaignAgentsPagination: {},

  agentsCalls: [],
  agentCallsPagination: {},

  campaignAgentsFilters: {
    search: ""
  },
  agentsCallsFilters: {
    search: ""
  },
  campaignsFilters: {
    search: ""
  },
  currentTab: "tab-dialer"
};
