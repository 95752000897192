<template>
  <div class="col-12">
    <header-component
      :title="$t('add_new_qualifications')"
      :pretitle="$t('qualifications_uppercase')"
      redirect-link="/manager/qualifications"
    />

    <flash-message />
    <form>
      <div class="row">
        <div class="col-12 col-md-6 mb-3">
          <div class="form-group mb-4">
            <label
              class="form-label required text-capitalize"
            >{{ $t("name") }}</label>
            <input
              type="text"
              v-model="fields.name"
              :class="{
                'form-control': true,
                'is-invalid': errors.name,
              }"
              class="form-control"
            >
            <div class="invalid-feedback">
              {{ errors.name && errors.name[0] }}
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 mb-3">
          <div class="form-group mb-4">
            <label class="form-label required text-capitalize">{{ $t("type") }}</label>
            <select
              v-model="fields.type"
              class="form-select text-capitalize"
            >
              <option :value="1">
                {{ $t("dialer_menu_capitalize") }}
              </option>
              <option :value="2">
                {{ $t("Omnichannel") }}
              </option>
              <option :value="3">
                {{ $t("dialer_and_omnichannel_capitalize") }}
              </option>
              <option :value="4">
                {{ $t("crm") }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-lg-12">
          <app-button
            class="fw mb-4 text-capitalize"
            :loading="loading"
            :blocked="!isValid()"
            @click="submit()"
          >
            {{ $t('create_group') }}
          </app-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from "vuex";
import AppButton from "@/components/app-button.vue";
import FlashMessage from "@components/flash-message";
import HeaderComponent from "@/components/header-component.vue";

export default {
  data() {
    return {
      fields: {
        name: "",
        type: 1,
      },
      errorMessage: "",
    };
  },
  components: {
    AppButton,
    FlashMessage,
    HeaderComponent
  },
  computed: {
    ...mapGetters("manager/qualification", {
      list: "getList",
      loading: "isLoading",
      register: "getRegister",
      errors: "getErrors",
    }),
  },
  methods: {
    ...mapActions("manager/qualification", ["create"]),
    ...mapMutations("system", ["setFlashMessage"]),

    isValid() {
      return this.fields.name;
    },

    submit() {
      this.errorMessage = "";

      this.create({fields: this.fields})
        .then((data) => {
          this.$router.push({
            path: `/manager/qualifications/${this.register.id}/edit`,
          }).then(() => {
            this.setFlashMessage({
              message: data.message,
              type: "success"
            });
          });
        })
        .catch((data) => {
          this.setFlashMessage({
            message: data.message,
            type: "danger",
          });
          this.errorMessage = data.message;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-primary {
  &:disabled {
    background-color: $color-gray-blue-300;
    border-color: $color-gray-blue-300;
    color: $color-text-gray;
  }
}
</style>
