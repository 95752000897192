import store from "@store";

import IndexUsers from "@dialer/manager/pages/settings/users/index-users";
import ListUsers from "@dialer/manager/pages/settings/users/list-users";
import NewUser from "@dialer/manager/pages/settings/users/new-user";
import EditUser from "@dialer/manager/pages/settings/users/edit-user";

export default {
  path: "users",
  name: "users",
  components: {
    main: IndexUsers
  },
  children: [
    {
      path: "",
      components: {
        users: ListUsers
      }
    },
    {
      path: "new",
      components: {
        users: NewUser
      }
    },
    {
      path: ":id/edit",
      components: {
        users: EditUser
      }
    }
  ],
  beforeEnter: (to, from, next) => {
    if (store.getters["auth/isManager"]) next();
    else next({
      name: "not-found",
      params: {
        pathMatch: to.path.split('/').slice(1)
      }
    });
  }
};
