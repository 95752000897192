<template>
  <div
    class="bg vh-100 d-flex justify-content-center align-items-center"
  >
    <div
      v-if="showBanner"
      class="empty-state d-flex flex-column overflow-hidden rounded-2 bg-text-light"
    >
      <div class="d-flex bg-textures position-relative align-items-center">
        <div
          class="d-flex flex-column gap-4"
          style="margin-left: 68px; max-width: 30%"
        >
          <img
            src="@assets/img/3czinhos.svg"
            style="width: 85px"
          >
          <h1
            class="m-0"
            style="line-height: 1.2"
          >
            {{ $t('simplify_customer_management') }}
            <span class="tx-blue-300">{{ $t('its_free') }}</span>
          </h1>
        </div>
        <img
          alt="Chatbot screens"
          class="position-absolute chatbot-screens"
          src="@assets/img/crm-screens.png"
        >
      </div>
      <div
        class="flex-grow-1 d-flex align-items-center rounded-2"
        style="padding: 40px"
      >
        <div class="d-flex align-items-end justify-content-between gap-4 w-100">
          <ul class="m-0">
            <li class="fs-5">
              {{ $t('create_and_track_activities') }}
            </li>
            <li class="fs-5">
              {{ $t('seamless_interaction') }}
            </li>
            <li class="fs-5">
              {{ $t('integration_partners') }}
            </li>
          </ul>

          <app-button
            style="width: 269px;"
            @click="initTour"
          >
            {{ $t('discover_crm') }}
          </app-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "@components/app-button.vue";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "EmptyManager",

  components: { AppButton},

  emits: ['create-chatbot'],

  data() {
    return {
      showModal: false,
      showBanner: true
    }
  },

  computed: {
    ...mapGetters('auth', {
      isWhatsappAllowed: "isWhatsappAllowed"
    }),
  },

  methods: {
    ...mapMutations('crm/tour', {
      setStartTour: "setStartTour"
    }),
    initTour() {
      this.setStartTour(true);
      this.$router.push({path: `/manager/crm/home`});
    },

  }
}
</script>

<style lang="scss" scoped>
.bg, .bg-textures {
  background-repeat: no-repeat;
  background-size: cover;
}

.bg {
  background-image: url("~@assets/img/crm-empty-background.png");
  background-position: top right;
}

.empty-state {
  width: 80%;
  height: 54%;
}

.bg-textures {
  background-image: url("~@assets/img/textures.svg");
  background-position: top center;
  height: 65%;
}

.chatbot-screens {
  right: 0;
  max-width: 65%;
  height: 100%;
  object-fit: contain;
  object-position: bottom;
}

@media (max-width: 1366px) {
  h1 {
    font-size: 1.5rem;
  }

  li {
    font-size: 0.875rem;
  }
}

@media (min-width: 1920px) {
  .empty-state {
    max-width: 1298px;
    max-height: 584px;
  }

  h1 {
    font-size: 2.5rem;
  }

  li {
    font-size: 1.5rem;
  }
}
</style>