export default {
  loading: {
    metrics: false,
    metricsTotal: false,
    searchQualifications: false,
    downloadTable: false,
  },
  errorMessage: '',
  errors: {},
  totalMetrics: {},
  listMetrics: [],
  qualificationList: [],
  pagination: {},
}
