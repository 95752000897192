export default {
  data() {
    return {
      callBack: null
    }
  },

  methods: {
    setResizeCallback(callback) {
      this.callBack = callback
    }
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.callBack)
  },

  watch: {
    callBack: {
      immediate: true,
      handler(val) {
        if (val) {
          this.callBack(window);
          window.removeEventListener('resize', this.callBack)
          window.addEventListener('resize', this.callBack)
        }
      }
    }
  }
}
