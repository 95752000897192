import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client/core";
import {setContext} from "@apollo/client/link/context";
import {onError} from "@apollo/client/link/error";
import store from "@store";
import router from "@/router";


const httpLink = createHttpLink({
  // uri: process.env.CRM_GRAPHQL_API,
  uri: `${process.env.VUE_APP_ST12UV}/graphql`,
})

const authLink = setContext((_, {headers}) => {

  const token = store.getters['auth/getUser'].api_token;

  return {
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      authorization: token ? `Bearer ${token}` : "",
    }
  };
});

const errorLink = onError(({networkError}) => {
  if (networkError && networkError.statusCode === 401) {
    if (store.getters['auth/getUser'] && !store.getters['auth/getloadingFlags'].logout)
      store.dispatch('auth/logout');
    router.push({
      name: 'login'
    });
    return Promise.reject(networkError);
  }

  if (networkError && networkError.statusCode === 429) {
    store.commit("system/setRequestLimitExceeded", true);
    return Promise.reject(networkError)
  }
});

const link = errorLink.concat(authLink.concat(httpLink));

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
  link: link,
  cache: cache,
});

export default apolloClient;