<template>
  <div 
    v-if="!loading.fetchAudioBlob"
    class="d-flex align-items-center gap-3" 
  >
    <audio
      controls
      :title="fileName"
      controlsList="nodownload"
      class="w-100"
      style="height: 40px;"
    >
      <source
        :src="source"
        type="audio/mpeg"
      >
    </audio>
    <a 
      :href="source"
      :download="fileName"
    >
      <i class="fe fe-download fs-2" />
    </a>
  </div>
  <div
    class="text-center"
    v-else
  >
    <div
      class="spinner-border"
      role="status"
    />
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      source: ""
    }
  },

  emits: ['newBlob'],

  props: {
    audio: {
      type: String,
      default: ""
    },

    fileName: {
      type: String,
      default: ""
    },

    map: {
      type: Object,
      default: () => {}
    }
  },

  mounted() {
    this.mountAudio();
  },

  computed: {
    ...mapGetters("manager/calls-report", {
      loading: "getLoadingFlags"
    })
  },

  methods: {
    ...mapActions("manager/calls-report", ["fetchAudioBlob"]),

    mountAudio() {
      this.source = this.map[this.fileName];

      if (!this.source) {
        this.fetchAudioBlob(this.audio).then((response) => {
          this.source = response;
          this.$emit("newBlob", this.source);
        });
      }
    },
  },

  watch: {
    audio() {
     this.mountAudio();
    }
  }
}
</script>
