<template>
  <modal-component
    :background-blur="true"
    :center="true"
    :show="showModal"
    size="modal-lg"
    @close="closeModal"
  >
    <template #content>
      <div class="modal-header pt-4 pe-5 border-0">
        <div class="modal-title d-flex justify-content-between align-items-center w-100">
          <p class="tx-text-200 mb-0 fw-medium">
            {{ $t('new_funnel') }}
          </p>
          <button
            aria-label="Close"
            class="btn p-0"
            data-test="closeNewFunnelModalWindow-button"
            type="button"
            @click="closeModal"
          >
            <i class="fa-regular fa-close tx-text-gray" />
          </button>
        </div>
      </div>
      <div class="modal-body pb-5 px-5">
        <div class="mb-4">
          <label class="form-label h5 fw-light required">
            {{ $t('name_data') }}
          </label>
          <input
            id="funnelName"
            v-model="form.name"
            :class="{ 'is-invalid': hasErrors && errors && errors.name && errors.name.length }"
            aria-describedby="nameHelp"
            class="form-control"
            data-test="funnelName-input"
            type="text"
          >
          <small
            v-if="errors && errors.name"
            id="emailHelp"
            class="text-danger"
          >{{ errors.name.toString() }}
          </small>
        </div>
        <div class="mb-4">
          <label
            class="form-label h5 fw-light required"
            for="teams"
          >
            {{ $t('responsible_team') }}
          </label>
          <div>
            <div class="dropdown-center">
              <button
                :class="{ 'is-invalid': hasErrors && errors && errors.team_id && errors.team_id.length }"
                aria-expanded="false"
                class="form-select d-flex text-black-50"
                data-bs-toggle="dropdown"
                type="button"
              >
                <span
                  v-if="form.team"
                  :class="{ 'text-danger': hasErrors && errors && errors.team_id?.length === 0 }"
                >
                  {{ form.team.name }}
                </span>
                <span
                  v-else
                  :class="{ 'text-danger': hasErrors && errors && errors.team_id && errors.team_id.length }"
                >
                  {{ $t('select') }}
                </span>
              </button>
              <div
                id="teams-select"
                class="dropdown-menu overflow-y-scroll w-80 shadow-lg"
              >
                <ul
                  class="p-3 teams-list mb-0"
                >
                  <li
                    v-for="(item, index) in teams"
                    :key="index"
                    :value="item.id"
                    class="dropdown-item rounded-1"
                    @click="selectItem(item, 'team')"
                  >
                    {{ item?.name }}
                  </li>
                  <li class="py-0">
                    <infinity-pagination-observer
                      :loading="loadingTeams"
                      :threshold="0.5"
                      margin="100px"
                      parent-container="teams-select"
                      @reload="teamsCurrentPage++"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <small
            v-if="errors && errors.team_id"
            id="emailHelp"
            class="text-danger"
          >{{ errors.team_id.toString() }}
          </small>
        </div>

        <div class="mb-4">
          <h5 class="form-label fw-light required">
            {{ $t('qualifications_group') }}
          </h5>
          <div class="dropdown-center">
            <button
              :class="{ 'is-invalid': hasErrors && errors && errors.qualification_list_id && errors.qualification_list_id.length }"
              aria-expanded="false"
              class="form-select d-flex text-black-50"
              data-bs-toggle="dropdown"
              type="button"
            >
              <span
                v-if="form.qualification"
                :class="{ 'text-danger': hasErrors && errors && errors.qualification_list_id.length === 0 }"
              >
                {{ form.qualification.name }}
              </span>
              <span
                v-else
                :class="{ 'text-danger': hasErrors && errors && errors.qualification_list_id && errors.qualification_list_id.length }"
              >
                {{ $t('select') }}
              </span>
            </button>
            <div
              id="qualifications-select"
              class="dropdown-menu shadow-lg w-80"
            >
              <ul
                class="p-3 mb-0 overflow-y-scroll"
                style="max-height: 300px"
              >
                <li
                  v-for="(item, index) in qualifications"
                  :key="index"
                  :value="item.id"
                  class="dropdown-item rounded-1"
                  @click="selectItem(item, 'qualification')"
                >
                  {{ item?.name }}
                </li>
              </ul>
            </div>
          </div>
          <small
            v-if="errors && errors.qualification_list_id"
            id="emailHelp"
            class="text-danger"
          >
            {{ errors.qualification_list_id.toString() }}
          </small>
        </div>
        <app-button
          :blocked="disabled"
          :loading="loadingFlags.postFunnel"
          data-test="submitNewFunnel-button"
          size="w-100"
          @click="createNewFunnel"
        >
          {{ $t('create') }}
        </app-button>
        <button
          class="btn cancel-btn w-100"
          data-test="closeNewFunnelModal-button"
          style="margin-top: 16px"
          @click="closeModal"
        >
          {{ $t('cancel_general') }}
        </button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from "@components/modal-component.vue";
import AppButton from "@components/app-button.vue";
import InfinityPaginationObserver from "@components/infinity-pagination-observer.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "FunnelModal",
  components: {InfinityPaginationObserver, ModalComponent, AppButton},
  emits: ['close'],
  props: {
    showModal: {type: Boolean, default: false}
  },
  data() {
    return {
      form: {
        name: '',
        team: null,
        qualification: null,
      },
      selectedTeam: null,
      teamSearch: '',
      hasErrors: null,
      teamsCurrentPage: 1,
      qualificationsCurrentPage: 1,
      CRM_QUALIFICATION: 4
    }
  },

  mounted() {
    this.clearTeamPaginateList()
    this.clearList()
    this.loadTeams()
    this.loadQualifications()
  },

  computed: {
    ...mapGetters('manager/teams', {
      teams: 'getList',
      loadingTeams: "isLoading",
      teamsPagination: "getPagination"
    }),
    ...mapGetters("manager/qualification", {
      qualifications: "getList",
      loadingQualification: "isLoading",
      qualificationsPagination: "getPagination",
    }),
    ...mapGetters("crm/funnels", {
      errors: "getErrorMessages",
      currentFunnel: "getCurrentFunnel",
      loadingFlags: "getLoadingFlags"
    }),
    ...mapGetters("auth", {
      user: "getUser",
      isManager: "isManager"
    }),
    ...mapGetters("crm/tour",
    {
      tourSteps: "getTourSteps"
    }),
    disabled() {
      return !this.form.name || !this.form.team || !this.form.qualification
    }
  },

  watch: {
    errors(value) {
      this.hasErrors = Object.keys(value).length > 0
    },
    teamsCurrentPage(value) {
      if (this.teamsPagination.total_pages >= value)
        this.loadTeams()
    }
  },

  methods: {
    ...mapMutations('manager/teams', ['clearTeamPaginateList']),
    ...mapMutations('manager/qualification', ['clearList']),
    ...mapMutations('auth', ['trackEvents']),
    ...mapActions('manager/teams', ['fetchTeamPaginate']),
    ...mapActions('crm/funnels', ['postFunnel']),
    ...mapActions('manager/qualification', ['list']),
    ...mapActions('crm/tour', ['updateTourSteps']),

    loadTeams() {
      if (this.isManager) {
        this.fetchTeamPaginate({
          page: this.teamsCurrentPage,
          per_page: 15,
        })
      }
    },

    loadQualifications() {
      if (this.isManager) {
        this.list({
          page: this.qualificationsCurrentPage,
          per_page: 50,
          type: this.CRM_QUALIFICATION
        })
      }
    },

    closeModal() {
      this.$emit('close')
    },

    selectItem(item, flag) {
      this.form[flag] = item
    },

    createNewFunnel() {
      this.postFunnel({
        name: this.form.name,
        team_id: this.form.team.id,
        company_id: this.user.company.id,
        qualification_list_id: this.form.qualification.id,
      }).then((funnelId) => {
        if (Object.keys(this.errors).length === 0) {
          if(!this.tourSteps?.funnel) {
            this.updateTourSteps({funnel: true})
          }
          this.closeModal()
          this.$router.push({path: `/manager/crm/${funnelId}`})
          this.form.name = "";
          this.form.team = {};
          this.form.qualification = {};
        }
        this.trackEvents(`[Manager] Create New Funnel`);
      })
    },
  },
}
</script>


<style lang="scss" scoped>
.teams-list {
  max-height: 35vh;
  min-height: min-content;
}

.overflow-y-scroll {
  overflow-y: auto;
}

.w-80 {
  min-width: 91%;
}

.dropdown-item {
  &:hover {
    background-color: $color-gray-200 !important;
  }
}
</style>