import _ from "lodash";

export default {
  startLoadingFlags: (state, flag) => (state.loadingFlags[flag] = true),
  stopLoadingFlags: (state, flag) => (state.loadingFlags[flag] = false),
  clearErrors: state => {
    state.errors = {};
    state.errorMessage = "";
  },
  setErrors: (state, errors) => (state.errors = errors),
  setFilters: (state, filters) => (state.filters = filters),
  setChatsReportList: (state, chatsReportList) => (state.chatsReportList = chatsReportList),
  setTimeReportList: (state, timeReportList) => (state.timeReportList = timeReportList),
  setQualificationsReportList: (state, qualificationsReportList) =>
    (state.qualificationsReportList = qualificationsReportList),
  sortChatsReportList: (state, params) => {
    const column_name = params.column;
    const direction = params.dir;
    state.chatsReportList.group_channels_metrics = _.orderBy(
      state.chatsReportList.group_channels_metrics,
      column_name,
      direction
    );
  },
  sortTimeReportList: (state, params) => {
    const column_name = params.column;
    const direction = params.dir;
    state.timeReportList.group_channel_time_metrics = _.orderBy(
      state.timeReportList.group_channel_time_metrics,
      column_name,
      direction
    );
  }
};
