<template>
  <div
    id="canvas"
    ref="offcanvas"
    aria-labelledby="canvasLabel"
    class="offcanvas offcanvas-end text-start"
    data-bs-scroll="true"
    style="width: 1030px !important;background-color: #F9FAFC!important;"
    tabindex="-1"
  >
    <div class="offcanvas-header border-0 pb-0">
      <div class="d-flex align-items-center gap-3">
        <button
          class="btn btn-rounded-circle btn-white float-start"
          data-bs-dismiss="offcanvas"
          style="width: 40px;height: 40px;"
        >
          <span class="far fa-chevron-left" />
        </button>
        <p class="m-0">
          {{ $t('back_capitalize') }}
        </p>
      </div>
    </div>
    <div class="offcanvas-body">
      <div
        v-if="Object.values(agent).length"
        class="row mb-4"
        style="height: 334px;"
      >
        <div class="col-7 h-100">
          <div class="d-flex gap-4">
            <card-details
              class="w-50"
              style-title="fs-7 text-start"
              :title="$t('uppercases.agent')"
              :badge-none="true"
            >
              <template #content>
                <h3 class="mb-0 tx-text-200">
                  {{ agent.name }}
                </h3>
              </template>
            </card-details>
            <card-details
              :badge="metricsNoteColor().bgColor"
              class="w-50"
              :icon="`fa-regular ${metricsNoteColor().icon}`"
              style-title="fs-7 text-start"
              :title="$t('uppercases.average')"
            >
              <template #content>
                <p class="mb-0 tx-text-gray">
                  <span
                    class="fw-medium fs-3"
                    :class="[metricsNoteColor().txtColor]"
                  >{{
                    scoreFixed(agent.overall_score)
                  }}</span>
                  /10
                </p>
              </template>
            </card-details>
          </div>
          <div style="height: 210px;">
            <card-geral-avaliation :metrics="agent" />
          </div>
        </div>
        <div class="col-5 h-100">
          <template v-if="loadingFlags.loadAgentsCalls">
            <card-loading :height="335" />
          </template>
          <template v-else>
            <card-criteria :metrics="agent" />
          </template>
        </div>
      </div>
      <div class="w-100">
        <table-i-a
          :calls="calls"
          :agent-id="agent.user_id"
          :table-head="tableHeadNames"
          @open-modal="openModal"
          @close-modal="closeModal"
        />
      </div>
    </div>
  </div>
  <modal-component
    :center="true"
    :show="show"
    custom-size="1217px !important"
    @close="closeModal"
  >
    <template #content>
      <div class="card mb-0 px-0">
        <div class="card-header">
          <h5 class="mb-0">
            Feedback
          </h5>
          <button
            class="btn fs-6 tx-text-gray"
            type="button"
            @click="closeModal"
          >
            <i class="far fa-close" />
          </button>
        </div>
        <div
          v-if="Object.values(dataModal).length"
          class="card-body px-0"
        >
          <div class="padding-x">
            <circle-gauge
              :max="10"
              :size="228"
              :value="dataModal.overall_score"
            />

            <h1 class="tx-text-200 text-center ">
              {{ agent.name }} em: {{ dataModal.call_history.data.campaign }}
            </h1>
            <p class="tx-text-gray text-center">
              {{ dataModal.resume }}
            </p>
            <div style="height: 72px;background-color: #F0F4FA; padding: 16px;border-radius: 16px;">
              <audio
                class="w-100"
                controls
                type="audio/mpeg"
                :src="dataModal.call_history.data.recording"
                style="height:40px;"
              />
            </div>
          </div>
          <div
            class="border-bottom"
            style="margin: 40px 0;"
          />
          <div
            class="d-flex flex-wrap gap-4 padding-x"
            style="margin-bottom: 40px;"
          >
            <div class="col-12 col-lg">
              <div class="d-flex align-items-center mb-3">
                <i class="fas fa-check-circle tx-green-300 fs-1" />
                <h4
                  class="mb-0"
                  style="margin-left: 16px;"
                >
                  {{ $t('doing_well') }}
                </h4>
              </div>
              <p class="tx-text-gray mb-0">
                {{ dataModal.encouragement_points }}
              </p>
            </div>
            <div class="col-12 col-lg">
              <div class="d-flex align-items-center mb-3">
                <i class="fas fa-times-circle text-danger fs-1" />
                <h4
                  class="mb-0"
                  style="margin-left: 16px;"
                >
                  {{ $t('can_be_better') }}
                </h4>
              </div>
              <p class="tx-text-gray mb-0">
                {{ dataModal.improvement_points }}
              </p>
            </div>
          </div>
          <accordion-i-a
            :avaliation="[dataModal.criteria_json, dataModal.general_evaluation]"
            :call-history="dataModal.call_history.data"
            class="padding-x"
          />
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import { mapGetters } from "vuex";
import CardDetails from "@dialer/manager/pages/insights-ai/components/card-details.vue";
import { Offcanvas } from "bootstrap";
import TableIA from "@dialer/manager/pages/insights-ai/components/table-ai.vue";
import CardCriteria from "@dialer/manager/pages/insights-ai/components/card-criteria.vue";
import CardGeralAvaliation from "@dialer/manager/pages/insights-ai/components/card-geral-avaliation.vue";
import ModalComponent from "@components/modal-component.vue";
import CircleGauge from "@dialer/manager/pages/insights-ai/components/circle-gauge.vue";
import AccordionIA from "@dialer/manager/pages/insights-ai/components/accordion-ai.vue";
import CardLoading from "@components/card-loading.vue";

export default {
  name: "OffCanvasIA",
  components: {
    AccordionIA,
    CircleGauge,
    ModalComponent,
    CardGeralAvaliation,
    CardCriteria,
    TableIA,
    CardDetails,
    CardLoading
  },
  props: {
    agent: {
      type: Object,
      default: () => {}
    },
    calls: {
      type: Array,
      default: () => []
    }
  },
  emits: ["close-modal", "open-modal"],
  data() {
    return {
      offCanvas: null,
      show: false,
      tableHeadNames: [
        {
          title: this.$t('uppercases.number')
        },
        {
          title: this.$t('uppercases.evaluation')
        },
        {
          title: this.$t('call_duration'),
          customClass: "text-center"
        },
        {
          title: this.$t('date_capitalize')
        }
      ],
      dataModal: {}
    };
  },
  mounted() {
    this.offCanvas = new Offcanvas(this.$refs.offcanvas);
  },
  computed: {
    ...mapGetters("manager/insights", {
      loadingFlags: "getLoadingFlags"
    })
  },
  methods: {
    closeModal() {
      this.dataModal = {};
      this.show = false;
      this.offCanvas.show();
    },
    openModal(event) {
      this.dataModal = event;
      this.show = true;
      this.offCanvas.hide();
    },
    scoreFixed(value) {
      if (value) {
        return value.toFixed(1);
      }
    },
    metricsNoteColor() {
      const value = this.agent.overall_score;
      let txtColor = "";
      let bgColor = "";
      let icon = "";
      if (value >= 6) {
        txtColor = "tx-green-400";
        bgColor = "good";
        icon = "fa-thumbs-up";
      } else if (value >= 4 && value < 6) {
        txtColor = "tx-yellow-400";
        bgColor = "alert";
        icon = "fa-meh";
      } else {
        txtColor = "tx-red-400";
        bgColor = "danger";
        icon = "fa-thumbs-down";
      }
      return { txtColor, bgColor, icon };
    }
  }
};
</script>

<style lang="scss" scoped>
.padding-x {
  padding-left: 64px !important;
  padding-right: 64px !important;
}

audio::-webkit-media-controls-panel {
  background-color: #ffffff;
  border-radius: 16px;
}
</style>
