<template>
  <div class="d-flex justify-content-center align-items-center">
    <Transition name="fadeBottom">
      <button
        v-if="show"
        @click="scrollToTop"
        class="scroll-top btn btn-primary"
      >
        <i class="fa-regular fa-chevron-up" />
      </button>
    </Transition>
  </div>
</template>
<script>

export default {
  name: 'ScrollToTop',

  data() {
    return {
      show: false
    }
  },

  methods: {
    scrollToTop() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    },

    calculateScrollHeight() {
      if (window.scrollY >= window.innerHeight) {
        this.show = true;
        return
      }
      this.show = false;
    }
  },


  mounted() {
    window.addEventListener("scroll", this.calculateScrollHeight);
  }
}
</script>

<style lang="scss" scoped>
.fadeBottom-enter-active {
  animation: fadeBottom-in 0.35s ease-in-out;
}

.fadeBottom-leave-active {
  animation: fadeBottom-in 0.35s ease-in-out reverse;
}

@keyframes fadeBottom-in {
  0% {
    bottom: -4rem;
    opacity: 0;
  }
  100% {
    bottom: 1.5rem;
    opacity: 1;
  }
}

.scroll-top {
  position: fixed;
  bottom: 1.5rem;
}
</style>