<template>
  <div class="d-flex flex-row align-items-center gap-3">
    <div
      v-for="(item, index) in options"
      :key="index"
    >
      <button
        :class="selected.value === item.value ? 'btn-primary fw-medium' : 'secondary-btn'"
        class="btn"
        @click="setCheckedStatus(item)"
      >
        {{ item.label }}
      </button>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  emits: ['selectStatus'],

  data() {
    return {
      selected: {
        label: 'Ativo',
        value: 'active'
      },
      options: [
        {label: this.$t('active_singular'), value: 'active'},
        {label: this.$t('gain'), value: 'gain'},
        {label: this.$t('lost'), value: 'lost'},
        {label: this.$t('deleted'), value: 'deleted'},
      ]
    }
  },

  created() {
    const status = this.options.find(option => option.value === this.opportunityFilters.status)
    if (status) {
      this.selected = status
    }
  },

  computed: {
    ...mapGetters("crm/opportunities", {
      opportunityFilters: "getOpportunityFilters",
    })
  },

  methods: {
    setCheckedStatus(option) {
      this.selected = option
      this.$emit('selectStatus', option.value)
    },
  }
}
</script>

<style lang="scss" scoped>
.btn {
  padding: 4px 16px;
  font-size: 12px;

  &:focus {
    outline: none;
    box-shadow: none;
    background-color: $color-blue-300;
  }
}

.secondary-btn {
  background-color: $color-gray-blue-300;
  color: $color-text-gray;

  &:hover {
    background-color: $color-gray-200;
  }
}
</style>
