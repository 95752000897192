<template>
  <modal-component
    :show="modelValue"
    center
    @close="$emit('update:modelValue', false)"
  >
    <template #content>
      <div class="modal-header mx-4 ps-0">
        <h3 class="modal-title">
          {{ $t('download_csv_report') }}
        </h3>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          @click="$emit('update:modelValue', false)"
        />
      </div>
      <div class="modal-body d-flex flex-column gap-4">
        <span>{{ $t('send_report_to_email') }}</span>
        <span>{{ $t('provide_an_email') }}</span>
        <input
          v-model="fields.email"
          type="text"
          class="form-control"
          placeholder="email@teste.com"
        >
        <div class="col-12 d-flex justify-content-end">
          <div class="d-flex gap-4 col-6">
            <app-button
              @click="$emit('update:modelValue', false)"
              class="btn-sm btn-export tx-text-gray col"
            >
              {{ $t('cancel_general') }}
            </app-button>
            <app-button
              class="btn-sm col"
              :loading="loading"
              :disabled="loading"
              @click="download"
            >
              {{ $t('send_email') }}
            </app-button>
          </div>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from "@components/modal-component.vue";
import AppButton from "@components/app-button.vue";

export default {
  name: "ModalDownloadCsv",
  emits: ["update:modelValue", "send", "close"],
  components: { AppButton, ModalComponent },

  props:{
    modelValue: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },

  data() {
    return {
      fields: {
        email: ""
      },
    }
  },

  methods: {
    download() {
      this.$emit('send', this.fields)
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  color: $color-text-200;
}
.btn-export {
  background: #E1E9F4;
  border: none;
  &:hover{
    background-color: #D2DDEC;
  }
}
</style>
