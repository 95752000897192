import axios from "axios";

export default {

  list({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading");
    commit("setList", []);

    return new Promise((resolve, reject) => {
      axios
        .get(
          "/company/receptive_numbers?" +
            (payload && payload.page ? "page=" + payload.page : "") +
            (payload && payload.search ? "search=" + payload.search : "")
        )
        .then(response => {
          commit("setPagination", response.data.meta.pagination);
          commit("setList", response.data.data);
          resolve();
        })
        .catch(response => {
          commit("setAlert", {
            message: response.response.data.detail,
            type: "danger"
          });
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  listFilters({ commit }) {
    commit("clearErrors");
    commit("startLoading");
    commit("setList", []);

    return new Promise((resolve, reject) => {
      axios
        .get(
          "/filters?include[]=queues&include[]=ivrs&include[]=hours"
        )
        .then(response => {
          commit("setList", response.data.data);
          resolve();
        })
        .catch(response => {
          commit("setAlert", {
            message: response.response.data.detail,
            type: "danger"
          });
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  findById({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading");
    commit("setRegister", null);

    return new Promise((resolve, reject) => {
      axios
        .get("/company/receptive_numbers/" + payload.id)
        .then(response => {
          commit("setRegister", response.data.data);
          resolve();
        })
        .catch(response => {
          commit("setRegister", null);
          commit("setAlert", {
            message: response.response.data.detail,
            type: "danger"
          });
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  create({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading");

    return new Promise((resolve, reject) => {
      axios
        .post("/receptive_number_settings", payload.fields)
        .then(response => {
          commit("system/setSuccessMessage", "success_message.edit", { root: true})
          commit("setRegister", response.data.data);
          resolve({ message: "Registro atualizado com sucesso!" });
        })
        .catch(error => {
          error.response.status === 422 && commit("setErrorFlag",
            { errorFlag: "receptiveNumber", errors: error.response.data.errors });
          reject()
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  remove({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading");

    return new Promise((resolve, reject) => {
      axios
        .delete("/receptive_number_settings" + payload.did)
        .then(() => {
          commit("setRegister", null);

          resolve({ message: "Registro removido com sucesso!" });
        })
        .catch(response => {
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  update({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading");

    return new Promise((resolve, reject) => {
      axios
        .post(`/receptive_number_settings/${payload.get("did")}`, payload)
        .then(response => {
          commit("system/setSuccessMessage", "success_message.activate_receptive_number", { root: true})
          commit("setRegister", response.data.data);
          resolve({ message: "Registro criado com sucesso!" });
        })
        .catch(response => {
          reject({ message: response.response.data.detail });
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },
};
