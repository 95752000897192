import store from "@store";

export default {
  path: 'campaign',
  name: 'campaign',
  components: {
    main: require('@dialer/manager/pages/dashboards/campaign/index-campaign').default,
  },
  children: [
    {
      path: '',
      components: {
        campaign: require('@dialer/manager/pages/dashboards/campaign/campaign-dashboard').default
      }
    },
    {
      path: 'new',
      components: {
        campaign: require('@dialer/manager/pages/dashboards/campaign/new-campaign').default
      },
      beforeEnter: (to, from, next) => {
        if (store.getters["auth/isManager"]) next();
        else next({
          name: "not-found",
          params: {
            pathMatch: to.path.split('/').slice(1)
          }
        });
      }
    },
    {
      path: ':id/edit',
      components: {
        campaign: require('@dialer/manager/pages/dashboards/campaign/edit-campaign').default
      },
      meta: {
        "short-side-bar": true
      },
      beforeEnter: (to, from, next) => {
        if (store.getters["auth/isManager"]) next();
        else next({
          name: "not-found",
          params: {
            pathMatch: to.path.split('/').slice(1)
          }
        });
      }
    },
    {
      path: ':id',
      components: {
        campaign: require('@dialer/manager/pages/dashboards/campaign/show-campaign').default
      },
      meta: {
        "short-side-bar": true
      }
    },
  ]
}
