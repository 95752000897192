<template>
  <div class="main-content position-relative h-100 login">
    <div
      class="d-flex w-100"
      style="height: 70px"
    >
      <div
        class=" login-card card w-100 rounded-0 m-0"
      >
        <router-link to="/login">
          <img
            alt="Logo Partner"
            class="ps-5 position-absolute my-3 image-link"
            src="@/assets/img/partner/partner.svg"
          >
        </router-link>
      </div>
    </div>
    <div class="d-flex justify-content-center flex-column align-items-center h-100 position-relative">
      <div
        class="card col-11 col-md-8 col-xxl-5 login-card"
      >
        <div
          :style="{'height: 330px;' : reseted}"
          class="card-body"
        >
          <div
            v-if="!reseted"
            class="col-12 my-5 align-self-center justify-content-center d-flex flex-column"
          >
            <h1 class="display-4 mb-3 fw-normal">
              Redefinir sua senha
            </h1>
            <p class="text-muted fs-4">
              Digite sua nova senha para acessar o 3C Plus
            </p>

            <form
              class="col-12"
              @submit.prevent="reset"
            >
              <div class="d-flex justify-content-center align-items-start mb-4">
                <div class="form-floating form-group col-11 mb-0">
                  <input
                    v-model="fields.password"
                    :class="{
                      'is-invalid': errors.password,
                    }"
                    :type="showPass ? 'text': 'password'"
                    class="border-end-0 form-control first-input"
                    placeholder="Nova Senha"
                    @blur="inputs.password = false"
                    @focus="inputs.password = true"
                    @input="handleAllValidations"
                  >
                  <label class="form-label"> Nova Senha</label>
                </div>
                <div
                  :class="{'first-eye-input': inputs.password}"
                  class="d-flex justify-content-center align-items-center input-group-text col border-start-0 rounded-end p-0 last-input"
                  style="height: 58px"
                  @click="showPass = !showPass"
                >
                  <i
                    v-if="!showPass"
                    class="fe fe-eye"
                  />
                  <i
                    v-else
                    class="fe fe-eye-off"
                  />
                </div>
                <div class="invalid-feedback">
                  {{ errors.password && errors.password[0] }}
                </div>
              </div>
              <div class="d-flex justify-content-center align-items-start">
                <div class="form-floating form-group col-11 mb-0">
                  <input
                    v-model="fields.checkPassword"
                    :disabled="loadingFlags.resetPassword"
                    :type="showConfirmationPass ? 'type' : 'password'"
                    class="border-end-0 form-control first-input"
                    placeholder="Confirmação de Senha"
                    @blur="inputs.checkPassword = false"
                    @focus="inputs.checkPassword = true"
                    @input="handlePasswordsEqualsValidation"
                  >
                  <label class="form-label"> Repita a Senha</label>
                </div>
                <div
                  id="inputGroupInvalid"
                  :class="{'last-eye-input': inputs.checkPassword}"
                  class="d-flex justify-content-center align-items-center input-group-text col border-start-0 rounded-end p-0 last-input"
                  style="height: 58px"
                  @click="showConfirmationPass = !showConfirmationPass"
                >
                  <i
                    v-if="!showConfirmationPass"
                    class="fe fe-eye"
                  />
                  <i
                    v-else
                    class="fe fe-eye-off"
                  />
                </div>
              </div>
              <div class="fs-5">
                <p class="p-0 mb-1 mt-2">
                  Critérios:
                </p>
                <div class="g-2 fs-6 row text-secondary mb-3">
                  <div>
                    <span
                      :class="validations.isLengthValid ? 'text-primary' : 'text-muted'"
                      class="fa-solid fa-check"
                    />
                    <span class="p-2">Possuir de 8 a 16 caracteres </span>
                  </div>
                  <div>
                    <span
                      :class="(validations.isStringAndNumberValid && validations.isOneLetterUppercase && validations.isOneSpecialCharacter) ? 'text-primary' : 'text-muted'"
                      class="fa-solid fa-check"
                    />
                    <span class="p-2">Possuir pelo menos uma letra maiúscula, um número e um caractere especial</span>
                  </div>
                  <div>
                    <span
                      :class="validations.isSequenceValid ? 'text-primary' : 'text-muted'"
                      class="fa-solid fa-check"
                    />
                    <span class="p-2">Não possuir repetições ou sequencias iguais (111, 123, abc) </span>
                  </div>
                  <div>
                    <span
                      :class="validations.isPasswordsEquals ? 'text-primary' : 'text-muted'"
                      class="fa-solid fa-check"
                    />
                    <span class="p-2">Os campos possuem a mesma senha </span>
                  </div>
                  <span>Dica: Evite datas de nascimento, documentos pessoais ou email </span>
                </div>
              </div>

              <app-button
                :disabled="!handleAllValidations()"
                :loading="loadingFlags.resetPassword"
                class="w-100 reset-button"
              >
                Redefinir senha
              </app-button>
            </form>
          </div>
          <div
            v-else
            class="d-flex col-12 align-self-center align-items-center justify-content-center flex-column mt-5 position-relative"
          >
            <h1
              class="display-4 mb-3 mt-0 fw-normal"
              style="z-index: 5;"
            >
              Senha redefinida com
              <span class="tx-blue-300">sucesso!</span>
            </h1>
            <p
              class="text-muted fs-4 mb-0"
              style="z-index: 5;"
            >
              Sua jornada começa agora
            </p>
            <Vue3Lottie
              :animation-data="LoadingJson"
              class="mt-5 p-0 position-absolute top-0 teste"
              style="z-index: 0;width: 180px;"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {Vue3Lottie} from "vue3-lottie";
import AppButton from "@/components/app-button.vue";
import LoadingJson from '@/assets/lottie/loading.json'

export default {
  /**
   *
   */
  mounted() {
    this.token = this.$route.params.resetToken;
  },

  /**
   * data
   */
  data() {
    return {
      showPass: false,
      showConfirmationPass: false,
      token: "",
      fields: {
        password: '',
        checkPassword: ''
      },
      validations: {
        isLengthValid: false,
        isStringAndNumberValid: false,
        isOneLetterUppercase: false,
        isOneSpecialCharacter: false,
        isPasswordsEquals: false,
        isSequenceValid: false,
      },
      inputs: {
        password: false,
        checkPassword: false,
      },
      reseted: false,
      LoadingJson
    };
  },

  computed: {
    ...mapGetters("auth", {
      loadingFlags: "getLoadingFlags",
      errors: "getErrors",
    }),


  },

  components: {
    AppButton,
    Vue3Lottie,
  },

  methods: {
    ...mapActions("auth", ["resetPassword"]),

    reset() {
      this.resetPassword({
        password: this.fields.password,
        password_confirmation: this.fields.checkPassword,
        reset_token: this.token,
      }).then(() => {
        this.reseted = true;
        setTimeout(() => {
          this.$router.push("/login").then(() => {
            location.reload();
          });
        }, 3000);
      });
    },

    handlePasswordLengthValidation() {
      const validation = this.fields.password && this.fields.password.length >= 8 && this.fields.password.length <= 16

      this.validations.isLengthValid = validation
      return validation
    },

    handleLetterAndNumberValidation() {
      const regExp = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
      const validation = regExp.test(this.fields.password)

      this.validations.isStringAndNumberValid = validation
      return validation
    },

    handlePasswordsEqualsValidation() {
      const validation = this.fields.password === this.fields.checkPassword && this.fields.password.trim().length > 0

      this.validations.isPasswordsEquals = validation
      return validation
    },

    handleSequenceValidation() {
      const validation = this.validateSequence(this.fields.password) && this.fields.password.trim().length > 0

      this.validations.isSequenceValid = validation
      return validation
    },

    validateSequence(str) {
      for (let i = 1; i < str.length; i++) {
        const currentChar = str.charAt(i);
        const previousChar = str.charAt(i - 1);
        const nextChar = str.charAt(i + 1);

        if (currentChar === previousChar && (nextChar && currentChar === nextChar && currentChar !== ' ')) {
          return false;
        }

        const currentCharCode = currentChar.charCodeAt(0);
        const previousCharCode = previousChar.charCodeAt(0);
        const nextCharCode = nextChar && nextChar.charCodeAt(0);

        if (currentCharCode === previousCharCode + 1 && currentCharCode === nextCharCode - 1) {
          return false;
        }

        if (currentCharCode === previousCharCode - 1 && currentCharCode === nextCharCode + 1) {
          return false;
        }
      }

      return true;
    },

    handleOneLetterUppercaseValidation() {
      const regExp = /[A-Z]/;
      const validation = regExp.test(this.fields.password)

      this.validations.isOneLetterUppercase = validation
      return validation
    },

    handleSpecialCharacterValidation() {
      const regExp = /[!|@|$|!|%|*|#|?|&]/;
      const validation = regExp.test(this.fields.password)

      this.validations.isOneSpecialCharacter = validation
      return validation
    },

    handleAllValidations() {
      const validations = [
        'handlePasswordLengthValidation',
        'handleLetterAndNumberValidation',
        'handlePasswordsEqualsValidation',
        'handleSequenceValidation',
        'handleOneLetterUppercaseValidation',
        'handleSpecialCharacterValidation'
      ];

      let isValid = true;
      validations.forEach((validation) => {
        let verification = this[validation]();

        if (!verification) isValid = false;
      });

      return isValid;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.login {
  height: 100vh;

  .first-input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .first-eye-input, .last-eye-input {
    border-color: $color-blue-300;
    transition: border-color 0.15s ease-in-out
  }

  .last-input {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .login-card {
    box-shadow: 0px 4px 18px 0px rgba(34, 54, 77, 0.12);
    z-index: 1051 !important;
  }

  .card-body {
    padding: 64px 80px;
  }

  form, .display-4, .reset-button {
    margin-top: $whatsapp-spacing-4;
  }

  .input-group-text {
    cursor: pointer !important;
  }
}

.image-link {
  height: 35px;
  left: 0;
}
</style>
