<template>
  <modal-component
    :show="showModal"
    @close="onHideModal"
    :center="true"
  >
    <template
      #content
      v-if="message"
    >
      <div class="modal-content p-5 pb-4 shadow position-relative d-flex align-content-center justify-content-between">
        <button
          class="btn-close position-absolute top-0 end-0 m-2 fs-2"
          @click="showModal = false"
        />
        <h2 class="text-center">
          {{ message.title }}
        </h2>
        <img
          :src="require(`@/assets/img/aloisio/${message.type}.svg`)"
          class="align-self-center my-4"
          style="height: 4.6rem; width: 6rem;"
        >
        <p
          class="text-center px-4"
          v-if="message.text"
        >
          <span
            v-for="text in message.text"
            :key="text"
          >
            <strong v-if="text.isBold">
              {{ text.data }}
            </strong>
            <span v-else> 
              {{ text.data }}
            </span>
          </span>
        </p>
        <div class="d-flex flex-column">
          <button
            class="btn mx-6 rounded-3 fs-4 text-light"
            :class="message.type == 'badWarning' ? 'bg-red-300' : 'bg-blue-300'"
            @click="confirm"
          >
            {{ $t('confirm_capitalize') }}
          </button>
          <button
            class="btn mx-8 mt-4 p-0 text-secondary fs-4"
            @click="cancel"
          >
            {{ $t('cancel_general') }}
          </button>
        </div>
      </div>
    </template>
  </modal-component>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { Utils } from '../utils';
import ModalComponent from './modal-component.vue';

export default {
  data() {
    return {
      showModal: false,

      /**
       * @typedef  {object} message
       * @property {string} title 
       * @property {"goodWarning" | "badWarning"} type 
       * @property {array<{ data: string, isBold: Boolean } >} text 
      */
      message: null,
    }
  },

  computed: {
    ...mapGetters("system", {
      confirmationMessage: "getConfirmationMessage",
    }),
  },

  watch: {
    confirmationMessage(confirmationMessage) {
      if(confirmationMessage) {
        this.showModal = true

        const text = confirmationMessage.text && this.removeStrongHtmlTag(confirmationMessage.text)
      
        this.message = {
          title: confirmationMessage.title,
          type: confirmationMessage.type,
          text: text
        };
      }
    }
  },

  components: { 
    ModalComponent 
  },

  methods: {
    removeStrongHtmlTag: Utils.removeStrongHtmlTag,

    ...mapActions("system", ["setConfirmationMessageResponse", "setConfirmationMessage"]),
    
    onHideModal() {
      this.setConfirmationMessageResponse("cancel")
      this.showModal = false;
    },

    confirm() {
      this.setConfirmationMessageResponse("confirm")
      this.showModal = false;
    },

    cancel() {
      this.setConfirmationMessageResponse("cancel")
      this.showModal = false;
    }
  },
}
</script>
<style lang="scss" scoped>
.btn:hover {
  opacity: 0.9 !important;
}
.modal-content {
  min-height: 25rem;
}
</style>