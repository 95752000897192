export default {
  // TODO: replace below loading to loadingFLags pattern
  loadingFlags: {
    loadActiveCampaigns: false,
    loadPausedCampaigns: false,
    loadAllAgentsOnline: false,
    fetchCampaignById: false,
  },
  activeCampaigns: [],
  activeCampaignsPagination: {
    current_page: 1,
  },

  pausedCampaigns: [],
  pausedCampaignsPagination: {
    current_page: 1,
  },

  campaign: null,
  campaignInclude: ["extension", "teams.agents","qualifications"],

  loading: false,
  loadingContext: '',
  errors: {},
  list: [],
  listActive: [],
  listPaused: [],
  paginationActive: {
    per_page: -1,
    current_page: 1
  },
  paginationPaused: {
    per_page: -1,
    current_page: 1
  },
  loadingActive: false,
  loadingPaused: false,
  register: null,
  filters: {},

  /**
   * The include below is necessary because it brings the quantity of
   * agents with online status and calls today
   */
  include: ['campaign_information'],
  pagination: {
    per_page: 12,
    current_page: 1
  },
  dataSelect: [],
  agetsPerCampagin: [],
  totalAgentsPerCampagin: {},
  createData: [],
  companyUraLimit: 0,
}
