export default {
  isLoading: state => state.loading,
  getList: state => state.list,
  getIsPaid: state => state.isPaid,
  getErrorMessage: state => state.errorMessage,
  getErrors: state => state.errors,
  getLoadingContext: state => state.loadingContext,
  getCEPData: state => state.CEPData,
  getTransactionData: state => state.transactionData,
  getIsOfflineSms: state => state.offlineSms,
}
