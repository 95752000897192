import crmApiAxios from "@crm/plugins/axios-config";

export default {
  fetchCategories({commit}, loading = true) {
    commit('clearErrors');

    if (loading) {
      commit('startLoadingFlag', 'fetchCategories');
    }

    commit('setCategories', []);

    crmApiAxios.get('/categories').then((response) => {
      commit('setCategories', response.data.data);
      commit('setTotalProducts', response.data.total_products);
      commit('setOpportunityTotalAmount', response.data.opportunityTotal);
    }).catch((response) => {
      commit('system/setErrorMessage', response.response.data, {root: true});
      if (response.response.status === 422 || response.response.status === 401) {
        commit('setErrors', response.response.data.errors);
      }
    }).finally(() => {
      if (loading) {
        commit('stopLoadingFlag', 'fetchCategories');
      }
    });
  },

  fetchPnS({commit, dispatch}, params) {
    commit('clearErrors')
    const categories = []
    commit('startLoadingFlag', 'fetchCategories')
    commit('setCategories', []);
    commit('setProducts', []);

    crmApiAxios.get('/products', {params})
      .then((response) => {
        if (response.data.data.length > 0) {
          response.data.data.forEach(item => {
            if (item.category) {
              const data = {
                id: item.category.id,
                name: item.category.name,
                teams: item.category.teams,
                is_active: item.category.is_active,
                company_id: item.category.company_id,
                products: [{
                  id: item.id,
                  name: item.name,
                  code: item.code,
                  description: item.description,
                  currency: item.currency,
                  is_recurrent: item.is_recurrent,
                  price: item.price,
                  maximum_discount: item.maximum_discount,
                  category_id: item.category.id,
                  company_id: item.company_id,
                  created_at: item.created_at,
                  updated_at: item.updated_at,
                  is_active: item.is_active
                }],
              }

              const duplicatedCategory = categories?.find((category) => category.id === item.category.id)

              if (!duplicatedCategory) {
                categories.push(data);
              } else {
                const index = categories.indexOf(duplicatedCategory)
                categories[index].products.push({
                  id: item.id,
                  name: item.name,
                  code: item.code,
                  description: item.description,
                  currency: item.currency,
                  is_recurrent: item.is_recurrent,
                  price: item.price,
                  maximum_discount: item.maximum_discount,
                  category_id: item.category.id,
                  company_id: item.company_id,
                  created_at: item.created_at,
                  updated_at: item.updated_at,
                  is_active: item.is_active
                })
              }
            }

            commit('setProducts', response.data.data);
            commit('setCategories', categories);
            dispatch('calculateActiveList')
          })
        }
      })
      .catch((error) => {
        commit('system/setErrorMessage', error.response.data, {root: true})
        error.response.data.status === 422 && commit('setErrors', error.response.data.errors)
      })
      .finally(() => {
        commit('stopLoadingFlag', 'fetchCategories')
      });
  },

  createCategory({commit}, payload) {
    commit('clearErrors')
    commit("startLoadingFlag", "createCategory");

    return new Promise((resolve, reject) => {
      crmApiAxios.post('/categories', payload)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          commit('system/setErrorMessage', error.response.data, {root: true})

          if (error.response.data.status === 422) {
            commit('setErrors', error.response.data.errors)
          }
          reject();
        })
        .finally(() => {
          commit('stopLoadingFlag', 'createCategory');
        })
    });
  },


  updateCategory({commit}, payload) {
    commit('clearErrors')
    commit("startLoadingFlag", "updateCategory");
    return new Promise((resolve, reject) => {
      crmApiAxios.put(`/categories/${payload.id}`, payload)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          commit('system/setErrorMessage', error.response.data, {root: true})

          if (error.response.data.status === 422) {
            commit('setErrors', error.response.data.errors)
          }
          reject();
        })
        .finally(() => {
          commit('stopLoadingFlag', 'updateCategory');
        })
    });
  },

  deleteCategory({commit}, category_id) {
    commit('clearErrors')
    commit("startLoadingFlag", "deleteCategory");

    return new Promise((resolve, reject) => {
      crmApiAxios.delete(`/categories/${category_id}`)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          commit('system/setErrorMessage', error.response.data, {root: true})

          if (error.response.data.status === 422) {
            commit('setErrors', error.response.data.errors)
          }
          reject();
        })
        .finally(() => {
          commit('stopLoadingFlag', 'deleteCategory');
        })
    });
  },

  createProduct({commit}, payload) {
    commit('clearErrors')
    commit("startLoadingFlag", "createProduct");

    return new Promise((resolve, reject) => {
      crmApiAxios.post('/products', payload)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          commit('system/setErrorMessage', error.response.data, {root: true})

          if (error.response.data.status === 422) {
            commit('setErrors', error.response.data.errors)
          }
          reject();
        })
        .finally(() => {
          commit('stopLoadingFlag', 'createProduct');
        })
    });
  },

  updateProduct({commit}, payload) {
    commit('clearErrors')
    commit("startLoadingFlag", "updateProduct");
    return new Promise((resolve, reject) => {
      crmApiAxios.put(`/products/${payload.id}`, payload)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => {
          commit('system/setErrorMessage', error.response.data, {root: true})

          if (error.response.data.status === 422) {
            commit('setErrors', error.response.data.errors)
          }
          reject();
        })
        .finally(() => {
          commit('stopLoadingFlag', 'updateProduct');
        })
    });
  },

  deleteProduct({commit}, category_id) {
    commit('clearErrors')
    commit("startLoadingFlag", "deleteProduct");

    return new Promise((resolve, reject) => {
      crmApiAxios.delete(`/products/${category_id}`)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          commit('system/setErrorMessage', error.response.data, {root: true})

          if (error.response.data.status === 422) {
            commit('setErrors', error.response.data.errors)
          }
          reject();
        })
        .finally(() => {
          commit('stopLoadingFlag', 'deleteProduct');
        })
    });
  },

  /* SYNC PRODUCTS*/
  syncProductOpportunity({commit, dispatch}, {opportunity_id, products}) {
    commit('startLoadingFlag', 'syncProductOpportunity');
    return new Promise((resolve, reject) => {
      crmApiAxios.post(`/products/opportunity/${opportunity_id}`, {
        products
      }).then(async (response) => {
        await dispatch('fetchSynchronizedProducts', {opportunity_id})
        commit('system/setSuccessMessage', "success_message.sync_products", {root: true})
        resolve(response.data)
      }).catch(() => {
        commit('system/setErrorMessage', 'Erro ao sincronizar oportunidades', {root: true})
        reject()
      }).finally(() => {
        commit('stopLoadingFlag', 'syncProductOpportunity');
      })
    })
  },
  fetchSynchronizedProducts({commit}, {opportunity_id}) {
    commit('startLoadingFlag', 'fetchSynchronizedProducts');
    commit('setSynchronizedProducts', [])
    return new Promise((resolve, reject) => {
      crmApiAxios.get(`/products/opportunity/${opportunity_id}`).then((response) => {
        commit('setSynchronizedProducts', response.data.products);
        commit('setOpportunityTotalAmount', response.data.opportunityTotal);
        resolve(response.data)
      }).catch(() => {
        commit('system/setErrorMessage', 'Erro ao sincronizar oportunidades', {root: true})
        reject()
      }).finally(() => {
        commit('stopLoadingFlag', 'fetchSynchronizedProducts');
      })
    })
  },

  handleCategoryData({commit}, data) {
    if (data.teams && !data.team_ids?.length) {
      data.team_ids = data.teams.map(item => item.id)
    }

    commit('clearErrors')
    commit('setCategoryData', data);
  },

  handleProductData({commit}, data) {
    if (data.description === null || data.description === "") {
      delete data.description
    }
    commit('clearErrors')
    commit('setProductData', data);
  },

  toggleModal({commit}, payload) {
    commit('setShowModal', payload);
  },

  toggleEdit({commit}, payload) {
    commit('setEdit', payload);
  },

  toggleCategoryModal({commit}, payload) {
    commit('setCategoryModal', payload);
  },

  updateExclude({commit}, payload) {
    commit('setExclude', payload);
  },

  updateActiveList({commit}, payload) {
    commit('setActiveList', payload)
  },

  calculateActiveList({getters, dispatch}) {
    if (getters.getCategories >= getters.getCategories) {
      dispatch("updateActiveList", 'actives');
      return
    }
    dispatch("updateActiveList", 'inactives');
  }
}


