<template>
  <div
    class="audio-record"
    v-if="active && mediaRecorder"
  >
    <div class="d-flex align-items-center gap-2">
      <button
        class="p-0 btn trash"
        @click="cancel"
      >
        <span class="fe fe-trash-2" />
      </button>
      <audio-record-preview
        :path="preview"
        v-if="state == 'paused'"
      />
      <template v-if="state == 'recording'">
        <small class="mx-2 recording-time">
          {{ recordingTimeText }}
        </small>
        <audio-summary
          class="b5 mx-2"
          :size="23"
          :space="0.5"
          :item-width="2"
          :percentage="100"
          :data="summary"
        />
      </template>
      <button
        class="btn p-0"
        v-if="state == 'recording'"
        @click="pauseButton()"
      >
        <span class="fe fe-pause" />
      </button>
      <button
        class="btn p-0"
        v-if="state == 'paused'"
        @click="resumeButton()"
      >
        <span class="fe fe-mic" />
      </button>
    </div>
  </div>
</template>

<script>
import AudioSummary from "@/components/audio-summary";
import MediaRecorder from "opus-media-recorder";
import EncoderWorker from "worker-loader!opus-media-recorder/encoderWorker.js";
import AudioRecordPreview from "./audio-record-preview.vue";

export default {
  /**
   * emits
   */
  emits: ["disable", "setAudio"],

  /**
   * data
   */
  data() {
    return {
      state: "inactive",
      canceled: false,
      preview: "",
      mediaRecorder: null,
      analyser: null,
      timer: null,
      audioBlob: null,
      audioBlobs: [],
      summary: [],
      recordingTime: 0,
      recordingTimeText: "00:00",
    };
  },

  /**
   * watch
   */
  watch: {
    /**
     *
     * @param {*} active
     */
    active(active) {
      if (active) {
        this.canceled = false;
        this.mediaRecorder.start();
      } else {
        this.mediaRecorder.stop();
      }
    },
  },

  /**
   * props
   */
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },

  /**
   * start audio summary
   */
  beforeMount() {
    this.summary = new Array(32).fill(10);
  },

  /**
   *
   */
  mounted() {
    const workerOptions = {
      encoderWorkerFactory: () => new EncoderWorker(),
      OggOpusEncoderWasmPath:
        "https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/OggOpusEncoder.wasm",
      WebMOpusEncoderWasmPath:
        "https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/WebMOpusEncoder.wasm",
    };
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      this.mediaRecorder = new MediaRecorder(
        stream,
        { mimeType: "audio/ogg" },
        workerOptions
      );
      this.mediaRecorder.addEventListener("dataavailable", this.dataavailableEvent);
      this.mediaRecorder.addEventListener("start", this.startRecordEvent);
      this.mediaRecorder.addEventListener("stop", this.stopRecordEvent);
      this.mediaRecorder.addEventListener("pause", this.pauseRecordEvent);
      this.mediaRecorder.addEventListener("resume", this.resumeRecordEvent);
      var audioContent = new AudioContext();
      var audioStream = audioContent.createMediaStreamSource(stream);
      this.analyser = audioContent.createAnalyser();
      audioStream.connect(this.analyser);
      this.analyser.fftSize = 32;
      this.analyser.smoothingTimeConstant = 0.8;
      this.frequencyArray = new Uint8Array(this.analyser.frequencyBinCount);
    });
  },

  /**
   * unregister events
   */
  beforeUnmount() {
    if (this.mediaRecorder) {
      this.mediaRecorder.removeEventListener("dataavailable", this.dataavailableEvent);
      this.mediaRecorder.removeEventListener("start", this.startRecordEvent);
      this.mediaRecorder.removeEventListener("stop", this.stopRecordEvent);
      this.mediaRecorder.removeEventListener("pause", this.pauseRecordEvent);
      this.mediaRecorder.removeEventListener("resume", this.resumeRecordEvent);
    }
    clearInterval(this.timer);
  },

  /**
   *
   */
  components: {
    AudioSummary,
    AudioRecordPreview,
  },

  /**
   * methods
   */
  methods: {
    /**
     * click pause button
     */
    pauseButton() {
      this.mediaRecorder.pause();
    },

    /**
     *  click resume button
     */
    resumeButton() {
      this.mediaRecorder.resume();
    },

    /**
     * start recording event
     */
    startRecordEvent() {
      this.state = "recording";
      this.recordingTime = 0;
      this.startFrequencieVisualizationAndRecordingTimeCount();
    },

    /**
     * pause recording event
     */
    pauseRecordEvent() {
      this.state = "paused";
      this.mediaRecorder.requestData();
      this.stopFrequencieVisualizationAndRecordingTimeCount();
    },

    /**
     * resume recording event
     */
    resumeRecordEvent() {
      this.state = "recording";
      this.startFrequencieVisualizationAndRecordingTimeCount();
    },

    /**
     * stop recording event
     */
    stopRecordEvent() {
      this.state = "inactive";
      this.stopFrequencieVisualizationAndRecordingTimeCount();
    },

    /**
     * dataavailable event
     *
     * @param {*} e
     */
    dataavailableEvent(e) {
      //abort
      if (this.canceled) {
        this.audioBlobs = [];
        return;
      }
      this.audioBlobs.push(e.data);
      if (this.mediaRecorder.state == "paused") {
        this.preview = URL.createObjectURL(
          new Blob(this.audioBlobs, {
            type: "audio/ogg",
          })
        );
      } else if (this.mediaRecorder.state == "inactive") {
        this.$emit(
          "setAudio",
          new Blob(this.audioBlobs, {
            type: "audio/ogg",
          })
        );
        this.audioBlobs = [];
      }
    },

    /**
     * format recording time
     */
    formatTime() {
      let minutes = Math.floor(this.recordingTime / 60);
      let seconds = Math.round(this.recordingTime % 60);
      this.recordingTimeText =
        minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
    },

    /**
     * active/resume frequencie visualization and recording time count
     */
    startFrequencieVisualizationAndRecordingTimeCount() {
      this.timer = setInterval(() => {
        this.analyser.getByteFrequencyData(this.frequencyArray);
        let start = [];
        let end = [];
        let max = Math.max(...this.frequencyArray);
        max = max > 100 ? max : 100;
        for (let i in this.frequencyArray) {
          let item = Math.floor((this.frequencyArray[i] * 100) / max);
          item = item < 10 ? 10 : item;
          end.push(item);
          start.unshift(item);
        }
        this.summary = start.concat(end);
        this.recordingTime += 0.05;
        this.formatTime();
      }, 50);
    },

    /**
     * stop frequencie visualization and recording time count
     */
    stopFrequencieVisualizationAndRecordingTimeCount() {
      clearInterval(this.timer);
      this.summary = new Array(32).fill(10);
    },

    /**
     * cancel recording and discard rocorded data
     */
    cancel() {
      this.canceled = true;
      this.audioBlobs = [];
      this.$emit("disable");
    },
  },
};
</script>

<style lang="scss">
.audio-record {
  display: inline-block;

  .recording-time {
    font-weight: 300;
    font-variant-numeric: tabular-nums;
  }

  .trash{
    &:hover{
      color: #920000 !important;
    }
  }

  .btn{
    &:hover{
      color: #3057f2;
    }
  }
}
</style>
