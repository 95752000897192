<template>
  <section class="position-relative main-content h-100">
    <div
      class="d-flex w-100"
      style="height: 70px"
    >
      <div
        class=" login-card card w-100 rounded-0 m-0"
      >
        <router-link to="/login">
          <img
            alt="Logo Partner"
            class="ps-5 position-absolute my-3 image-link"
            src="@/assets/img/partner/partner.svg"
          >
        </router-link>
      </div>
    </div>
    <div class="d-flex justify-content-center flex-column align-items-center h-100 position-relative">
      <div
        class="card col-11 col-md-8 col-xxl-5 login-card"
        style="height: 400px;"
      >
        <div class="card-body mt-3 w-auto position-relative">
          <div class="text-center">
            <img
              alt="Logo Partner"
              class="width-image"
              src="@/assets/img/partner/partner.svg"
            >
          </div>
          <div
            class="d-flex col-12 align-self-center align-items-center justify-content-center flex-column mt-5"
          >
            <h1
              class="display-4 mb-3 mt-0 fw-normal"
              style="z-index: 5;"
            >
              Email confirmado com
              <span class="tx-blue-300">sucesso!</span>
            </h1>
            <p
              class="text-muted fs-4 mb-0"
              style="z-index: 5;"
            >
              Sua jornada começa agora
            </p>
            <div class="p-0 position-absolute bottom-0 teste">
              <Vue3Lottie
                :animation-data="LoadingJson"
                class="w-100 animation-loading"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {Vue3Lottie} from "vue3-lottie";
import LoadingJson from '@/assets/lottie/loading.json'
import {mapActions} from "vuex";

export default {
  components: {Vue3Lottie},
  data() {
    return {
      LoadingJson
    }
  },
  methods: {
    ...mapActions("auth", ["confirmationEmail"])
  },
  mounted() {
    const idValue = this.$route.query.id;
    const confirmation = {
      hash: this.$route.query.hash
    }
    this.confirmationEmail({id: idValue, payload: confirmation}).then(() => {
      setTimeout(() => {
        this.$router.push("/login")
      }, 10000)
    });
  }
}
</script>

<style
  lang="scss"
  scoped
>
.login-card {
  box-shadow: 0px 4px 18px 0px rgba(34, 54, 77, 0.12);
  z-index: 1051 !important;
}

.card-body {
  padding: 64px 80px;
}

.image-link {
  height: 35px;
  left: 0;
}

.width-image {
  max-width: 390px;
  width: 100%
}

.animation-loading {
  z-index: 0;
  max-width: 180px;
}
</style>
