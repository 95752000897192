import axios from "axios";

export default {
  createPixPayment({ commit, dispatch }, payload) {
    commit("startLoadingFlags", "createPixPayment");

    return new Promise((resolve) => {
      axios.post(`/company/${payload.id}/pix_payment/create`, payload.fields)
        .then((response) => {
          commit("setPixInformation", response.data.data);
          dispatch("clearErrors");

          resolve();
        })
        .catch((response) => {
          response.response.status == 422 &&
          response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
        })
        .finally(() => {
          commit("stopLoadingFlags", "createPixPayment");
        })
    });
  },

  clearErrors({ commit }) {
    commit("setErrors", {});
  },

  fetchPaymentData({ commit }, payload) {
    commit("startLoadingFlags", "fetchPaymentData");

    return new Promise((resolve) => {
      axios.get(`/get-payment-qrcode/${payload.payment_id}`)
        .then((response) => {
          commit("setPaymentData", response.data.data);
          resolve();
        })
        .finally(() => {
          commit("stopLoadingFlags", "fetchPaymentData");
        })
    });
  }
}
