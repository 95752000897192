<template>
  <div
    class="position-relative h-40p"
    :style="{ 'z-index': open ? 1 : '' }"
  >
    <div
      class="card card-select-channel position-absolute w-100"
      :class="{'border-error': error}"
      ref="selectChannelContainer"
    >
      <div
        class="card-header border-0 justify-content-between cursor-pointer h-40p py-05-px-1r"
        @click="toggleOpen"
        aria-expanded="false"
        aria-controls="selectChannel"
      >
        <p
          v-if="!open"
          class="mb-0"
          :class="{'tx-text-gray': !modelValue}"
        >
          {{ modelValue ? $t(modelValue.type) : $t("select") }}
        </p>
        <div
          v-else
          class="d-flex align-items-center gap-2 tx-text-gray"
        >
          <span class="far fa-search" />
          <input
            class="form-control border-0 text-medium"
            :placeholder="$t('placeholders.search_capitalize')"
            v-model="search"
            maxlength="50"
          >
        </div>
        <span
          class="far fa-chevron-down"
          id="chevron"
          :class="open? 'chevron-open' : 'chevron-closed'"
        />
      </div>
      <div
        class="card-body collapse py-0-px-1r"
        id="selectChannel"
        ref="selectChannel"
      >
        <ul class="list-group list-group-flush overflow-scroll mh-314p">
          <li
            v-if="list.length === 0"
            class="list-group-item text-center"
          >
            {{ $t("no_group_channels_found") }}
          </li>
          <item-group-channel
            v-for="groupChannel in list"
            :key="groupChannel.id"
            :group-channel="groupChannel"
            @select-channel="setSelectedChannel"
          />
        </ul>
      </div>
    </div>
    <div class="pt-40p">
      <small class="text-danger"> {{ error }}</small>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Collapse } from "bootstrap";
import ItemGroupChannel from "@dialer/manager/pages/settings/ivrs/components/select-channel/item-group-channel.vue";

export default {
  name: "SelectChannel",

  emits: ["update:modelValue"],

  components: { ItemGroupChannel },

  props: {
    modelValue: {
      type: Object,
      default: null,
    },
    error: {
      type: String,
      default: "",
    },
    initialChannel: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      search: "",
      open: false,
      collapse: null,
    }
  },

  created() {
    this.fetchGroupChannels().then(() => {
      if (this.initialChannel) {
        const groupChannel = this.groupChannels.find(gc => gc.id === this.initialChannel.group_channel_id);
        const channel = groupChannel.instances.find(channel => channel.id === this.initialChannel.instance_id);
        this.$emit("update:modelValue", channel)
      }
    })
  },

  computed: {
    ...mapGetters("manager/ivrs", {
      groupChannels: "getGroupChannels",
    }),

    list() {
      const gcWithChannels = this.groupChannels.filter(gc => !(gc.instances.length === 0))
      return gcWithChannels.filter(gc => gc.name.toLowerCase().includes(this.search.toLowerCase()))
    },
  },

  mounted() {
    this.collapse = new Collapse(this.$refs.selectChannel, { toggle: false })
    this.$refs.selectChannel.addEventListener("hide.bs.collapse", this.hideHandler)
    this.$refs.selectChannel.addEventListener("show.bs.collapse", this.showHandler)

    document.addEventListener("click", this.closeDropdown);
  },

  beforeUnmount() {
    this.$refs.selectChannel.removeEventListener("hide.bs.collapse", this.hideHandler)
    this.$refs.selectChannel.removeEventListener("show.bs.collapse", this.showHandler)

    document.removeEventListener("click", this.closeDropdown);
  },

  methods: {
    ...mapActions("manager/ivrs", ["fetchGroupChannels"]),

    toggleOpen(e) {
      if (this.open && e.target.id !== "chevron") {
        return
      }
      this.collapse.toggle()
    },

    showHandler(e) {
      if (e.target.id === "selectChannel") {
        this.open = true
      }
    },

    hideHandler(e) {
      if (e.target.id === "selectChannel") {
        setTimeout(() => {
          this.open = false
        }, 300)
      }
    },

    closeDropdown(event) {
      if (!this.$refs.selectChannelContainer.contains(event.target)) {
        this.collapse.hide()
      }
    },

    setSelectedChannel(channel) {
      this.$emit("update:modelValue", channel)
      this.collapse.hide()
    },
  },
}
</script>

<style scoped lang="scss">
.h-40p {
  height: 40px;
}

.card-select-channel {
  min-width: 331px;
  max-height: 354px;
  border-color: #d2ddec
}

.py-05-px-1r {
  padding: 0.5rem 1rem;
}

.py-0-px-1r {
  padding: 0 1rem;
}

.mh-314p {
  max-height: 314px
}

.pt-40p {
  padding-top: 40px
}

.chevron-open {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.chevron-closed {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.border-error {
  border-color: $color-red-300 !important;
}
</style>