import store from "@store";

import IndexChatbot from "@chatbot/pages/index-chatbot.vue";
import ChatbotDashboard from "@chatbot/pages/dashboard/chatbot-dashboard.vue";
import ChatbotWorkflow from "@chatbot/pages/workflow/chatbot-workflow.vue";

export default {
  path: "chatbot",
  name: "chatbot",
  components: {
    main: IndexChatbot
  },
  children: [
    {
      path: "",
      name: "chatbot",
      components: {
        chatbot: ChatbotDashboard
      }
    },
    {
      path: ":id",
      name: "chatbot-workflow",
      components: {
        chatbot: ChatbotWorkflow
      },
      meta: {
        "short-side-bar": true
      }
    },
    {
      path: "new",
      name: "chatbot-workflow-new",
      components: {
        chatbot: ChatbotWorkflow
      },
      meta: {
        "short-side-bar": true
      }
    },
  ],
  beforeEnter: (to, from, next) => {
    if (store.getters["auth/isSupervisorOrManager"]) next();
    else next({
      name: "not-found",
      params: {
        pathMatch: to.path.split('/').slice(1)
      }
    });
  }
};
