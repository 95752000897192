export default {
  isLoading: state => state.loading,
  getSchedules: state => state.schedules,
  getErrorMessage: state => state.errorMessage,
  getErrors: state => state.errors,
  getLoadingFlags: state => state.loadingFlags,
  getPagination: (state) => state.pagination,
  getParams: (state) =>
    Object.assign(
      {
        'page': state.pagination.current_page,
        'per_page': state.pagination.per_page
      }
      , state.filters, { include: state.include.join(',') }),
  getScheduleToUpdateModal: (state) => state.scheduleToUpdateModal
}
