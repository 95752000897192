export default {
    isLoading: state => state.loading,
    getLoadingContext: state => state.loadingContext,
    getErrorMessage: state => state.errorMessage,
    getErrors: state => state.errors,
    getErrorsInterval: state => state.errorsInterval,
    isScheduledWorkBreak: state => state.scheduledWorkBreak,
    getPagination: state => state.pagination,
    getWorkBreakGroups: state => state.workBreakGroups,
    getLoadingFlags: state => state.loadingFlags,
    getWorkBreakGroup: state => state.workBreakGroup,
    getIntervals: state => state.intervals,
    getIntervalsPagination: state => state.intervalsPagination,
}
