import store from "@store";

export default {
  path: "ivr-after-call",
  name: "ivr-after-call",
  components: {
    main: require('@dialer/manager/pages/settings/ivr-after-call/index-ivr-after-call').default,
  },
  children: [
    {
      path: '',
      components: {
        'ivr-after-call': require('@dialer/manager/pages/settings/ivr-after-call/list-ivr-after-call.vue').default
      },
    },
    {
      path: ':id/edit',
      components: {
        'ivr-after-call': require('@dialer/manager/pages/settings/ivr-after-call/edit-ivr-after-call.vue').default
      },
    },
    {
      path: 'new',
      components: {
        'ivr-after-call': require('@dialer/manager/pages/settings/ivr-after-call/new-ivr-after-call.vue').default
      }
    },
  ],
  beforeEnter: (to, from, next) => {
    if (store.getters["auth/isManager"]) next();
    else next({
      name: "not-found",
      params: {
        pathMatch: to.path.split('/').slice(1)
      }
    });
  }
};
