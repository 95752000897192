<template>
  <div class="main-content pb-6 ">
    <div class="container-fluid ">
      <div class="row justify-content-center">
        <div class="col-12">
          <header-component
            :title="$t('capitalizes.receptive_IVR')"
            :pretitle="$t('uppercases.settings')"
            redirect-link="/manager/voice-settings"
          >
            <template #buttons>
              <router-link
                class="header-button btn btn-primary float-end px-6"
                to="/manager/receptive-ivrs/new"
              >
                {{ $t("add.receptive_IVR") }}
              </router-link>
            </template>
          </header-component>
          <card-tip
            class="mb-4"
            :text="$t('info_receptive_ivr')"
          />
          <div class="col-lg-12 ">
            <div
              class="card border-custom"
            >
              <div
                v-if="!loading.getList && pagination.total === 0 && !term"
                class="d-flex justify-content-center"
              >
                <empty-state
                  class="col-12 col-lg-10 col-xl-8"
                  :title="$t('empty_receptive_ivr')"
                  icon="ivr.svg"
                  sub-title=" "
                >
                  <template #buttons>
                    <button
                      class="btn btn-primary px-6"
                      @click="$router.push('/manager/receptive-ivrs/new')"
                    >
                      <i class="fal fa-plus" /> {{ $t("add.receptive_IVR") }}
                    </button>
                  </template>
                </empty-state>
              </div>
              <template
                v-else
              >
                <div class="card-header ">
                  <search-Input
                    :placeholder="$t('search_receptive_ivr')"
                    @search="search"
                  />
                </div>
                <div class="card-body  p-0">
                  <table
                    class="table m-0 "
                  >
                    <thead>
                      <tr>
                        <th>{{ $t("uppercases.name") }}</th>
                        <th />
                      </tr>
                    </thead>

                    <tbody>
                      <template v-if="loading.getList">
                        <loading-table-component
                          :columns="1"
                          :lines="15"
                          :list-width="[300]"
                          :text-center="false"
                        />
                      </template>

                      <tr v-else-if="!list?.length">
                        <td
                          class="text-center text-muted"
                          colspan="4"
                        >
                          <span class="fe fe-alert-octagon" /> {{ $t("errors.no_receptive_ivr_found") }}
                        </td>
                      </tr>
                      <tr
                        v-else
                        v-for="item of list"
                        :key="item.id"
                        class="cursor-pointer"
                        @click="
                          $router.push(`/manager/receptive-ivrs/${item.id}/edit`)
                        "
                      >
                        <td>{{ item.name }}</td>

                        <td
                          class="text-right py-0"
                          style="text-align: right"
                        >
                          <span
                            class="h4 far fa-chevron-right text-primary my-0"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div
                  v-if=" pagination.total_pages > 1"
                  class="card-footer"
                >
                  <div class="col-lg-12">
                    <paginator
                      :pagination="pagination"
                      @change-page="changePage"
                    />
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import searchInput from "@/components/search-input";
import paginator from "@/components/app-paginator";
import _ from "lodash";

import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";
import HeaderComponent from "@/components/header-component.vue";
import CardTip from "@components/card-tip.vue";
import EmptyState from "@components/empty-state.vue";

export default {
  data() {
    return {
      term: "",
    };
  },
  mounted() {
    this.setFilters({
      search: "",
    });
    this.getList();
  },
  components: {
    EmptyState,
    CardTip,
    searchInput,
    paginator,
    LoadingTableComponent,
    HeaderComponent,
  },
  computed: {
    ...mapGetters("manager/receptive_ivrs", {
      list: "getList",
      loading: "getLoadingFlags",
      pagination: "getPagination",
    }),
    ...mapGetters("auth", {
      user: "getUser",
    }),
  },

  methods: {
    ...mapActions("manager/receptive_ivrs", ["getList"]),
    ...mapMutations("manager/receptive_ivrs", ["setFilters", "setCurrentPage"]),
    changePage(page) {
      this.setCurrentPage(page);
      this.getList();
    },
    search: _.debounce(function (search) {
      this.setFilters({ search });
      this.setCurrentPage(1);
      this.getList();
      this.term = search
    }, 300),
  },
};
</script>

<style lang="scss">
tr:hover td span.h4.far.fa-chevron-right {
  visibility: visible;
}

td span.h4.far.fa-chevron-right {
  visibility: hidden;
}

.border-custom {
  border: 1px solid $color-gray-blue-300 !important;
  border-radius: 0.625rem;
}
</style>
