<template>
  <div class="modal-body">
    <div class="card-body">
      <div class="row d-flex flex-column align-items-center text-center">
        <img
          src="@assets/img/robo.svg"
          class="d-flex justify-content-center col-md-4 text-center mb-3"
        >
        <h1>
          {{ $t('sms_out') }}
        </h1>
        <p>
          {{ $t('use_new_sms_version') }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {}
</script>