export default {
  loadingFlags: {
    createUploadCsv: false,
    createValidateCsv: false,
    createImportCsv: false
  },
  errors: [],
  step: 0,
  preAdjustedCsv: [],
  headerCsv: [],
  validationCsv: [],
  headerLabels: []
}
