import axios from "axios";

export default {
  searchManualCalls({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading", "searchManualCalls");
    commit("setList", []);

    return new Promise((resolve, reject) => {
      axios
        .get(`/campaigns/${payload.campaignId}/agents/metrics/total?start_date=${payload.startDate}&end_date=${payload.endDate}`)
        .then(response => {
          commit("setList", response.data.data);
          resolve();
        })
        .catch(response => {
          response.response.status == 422 &&
          response.response.data.errors &&
          commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading", "searchManualCalls");
        });
    });
  },
  downloadTable({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading", "downloadTable");
    return new Promise((resolve, reject) => {
      axios
        .get(`/campaigns/${payload.campaignId}/agents/manual/metrics/csv`,
        {params: {
          start_date: payload.startDate,
          end_date: payload.endDate,
          csv: payload.csv,
          email: payload.email
        }})
        .then(() => {
          commit("system/setSuccessMessage", "success_message.generate_report", { root: true });
          resolve();
        })
        .catch(response => {
          commit('system/setErrorMessage',  response.response.data, { root: true })
          response.response.status == 422 &&
          response.response.data.errors &&
          commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data });
        })
        .finally(() => {
          commit("stopLoading", "downloadTable");
        });
    });
  }
};
