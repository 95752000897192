<template>
  <div class="progress 90">
    <div
      class="progress-bar bg-success"
      role="progressbar"
      :style="'width: ' + leftPercentage + '%'"
      aria-valuenow="10"
      aria-valuemin="0"
      aria-valuemax="100"
    />
    <div
      class="progress-bar bg-secondary"
      role="progressbar"
      :style="'width: ' + rightPercentage + '%'"
      aria-valuenow="10"
      aria-valuemin="0"
      aria-valuemax="100"
    />
  </div>
</template>

<script>
export default {
  /**
   *
   */
  mounted() {
    this.render();
  },
  /**
   *
   */
  data() {
    return {
      rightPercentage: 50,
      leftPercentage: 50,
    };
  },

  /**
   *
   */
  props: {
    /**
     *
     */
    leftCount: {
      type: Number,
      default: 0,
    },

    /**
     *
     */
    rightCount: {
      type: Number,
      default: 0,
    },
  },

  watch: {
    /**
     *
     */
    leftCount() {
      this.render();
    },

    /**
     *
     */
    rightCount() {
      this.render();
    },
  },
  /**
   *
   */
  methods: {
    render() {
      this.total = this.leftCount + this.rightCount;
      this.leftPercentage = this.total < 1 ? 0 : (this.leftCount * 100) / this.total;
      this.rightPercentage = this.total < 1 ? 0 : (this.rightCount * 100) / this.total;
    },
  },
};
</script>
