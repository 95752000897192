export default {
  loading: false,
  loadingFlags: {
    fetchWebhooks: false,
    fetchWebhookById: false,
    createWebhook: false,
    updateWebhook: false,
    deleteWebhook: false,
  },

  errors: {},

  webhooks: [],

  webhook: [],
}
