export default [
  {
    type: "link",
    title: "Empresas",
    icon: "fe fe-star",
    path: "/admin/companies",
    active_name: "companies",
    allowedLevels: [0, 1, 2, 3]
  },
  {
    type: "group",
    title: "Configurações",
    icon: "fe fe-settings",
    id: "config",
    allowedLevels: [0, 3],
    children: [
      {
        title: "Cupons",
        active_name: "coupons",
        path: "/admin/coupons",
        allowedLevels: [0, 3]
      },

      {
        title: "Tags",
        active_name: "tags",
        path: "/admin/tags",
        allowedLevels: [0, 3]
      },
      {
        title: "Usuários",
        active_name: "admin_users",
        path: "/admin/users",
        allowedLevels: [0, 3]
      }
    ]
  },
  {
    type: "link",
    title: "Validação SMS",
    icon: "fe fe-message-square",
    active_name: "sms",
    path: "/admin/sms",
    allowedLevels: [0, 3]
  },
  {
    type: "group",
    title: "3C Partners",
    icon: "fa-regular fa-handshake",
    id: 'partners',
    allowedLevels: [0, 3],
    children: [
      {
        title: "Partners",
        active_name: "partners",
        path: "/admin/partners",
        allowedLevels: [0, 3]
      },
      {
        title: "Financeiro",
        active_name: "financial",
        path: "/admin/financial",
        allowedLevels: [0, 3]
      }
    ]
  },
  {
    type: "group",
    title: "Telecom",
    icon: "fa-regular fa-globe",
    id: "telecom",
    allowedLevels: [0, 3],
    children: [
      {
        title: "Tronco SIP",
        active_name: "telecom",
        path: "/admin/telecom",
        allowedLevels: [0, 3]
      },
      {
        title: "Rota internacional",
        active_name: "international",
        path: "/admin/telecom/international",
        allowedLevels: [0, 3]
      }
    ]
  },
  {
    type: "group",
    title: "System",
    icon: "fa-regular fa-wrench",
    id: "system",
    allowedLevels: [0],
    children: [
      {
        title: "Limite de ligações",
        active_name: "system",
        path: "/admin/system",
        allowedLevels: [0]
      },
    ]
  }
];
