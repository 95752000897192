import axios from "axios";

export default {
    updateSystemLimitCallPerAgent({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`/system/limit_call_per_agent/change`, {
                    max_calls_per_agent: payload.max_calls_per_agent,
                })
                .then(() => {
                    commit("system/setSuccessMessage", "Modificado com sucesso", { root: true });
                    resolve();
                })
                .catch(response => {
                    commit("system/setErrorMessage", response.response.data, { root: true });
                    reject();
                })
        });
    },

    getSystemLimitCallPerAgent() {
        return new Promise((resolve, reject) => {
            axios
                .get(`/system/limit_call_per_agent`)
                .then((response) => {
                    resolve(response.data.data.max_calls_per_agent) 
                })
                .catch(response => {
                    reject(response.response.data)
                })
        });
    }
}