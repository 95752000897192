export default {
  selectedMessageTemplate: null,
  messageTemplates: [],
  templatesPagination: {
    total: 0,
    per_page: 5,
    current_page: 1,
    total_pages: 1,
  },

  loadingFlags: {
    fetchMessageTemplates: false,
    sendMessageTemplates: false,
  }
}
