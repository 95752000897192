<template>
  <div>
    <select-emoji
      :class="{ 'quote-open': quoteMessage }"
      @set-emoji="(emoji) => (text += emoji)"
      @close="showThisModal = null"
      :show="showThisModal === 'emoji'"
    />
    <quick-messages
      :show="showThisModal === 'quick-messages'"
      @send-quick-message="(quickmessage) => (text = quickmessage)"
      :search-term="quickMessageFilter"
      @close="showThisModal = null"
      :group-channel-id="groupChannelId"
    />
    <div class="d-flex flex-column gap-3 pt-3 shadow-sm new-message-input">
      <ballon-quote
        @delete-quote="$emit('deleteQuote')"
        :close="true"
        v-if="quoteMessage && tab === 'whatsapp'"
      >
        <div class="col d-flex align-items-center quote-content">
          <div class="col d-flex flex-column px-3 py-4 gap-1">
            <div class="text-small fw-medium">
              {{ quoteMessage.agent?.name || quoteMessage.message_from }}
            </div>
            <p
              class="text-medium lh-1 m-0"
              v-if="quoteMessage.type == 'chat'"
            >
              {{ quoteMessage.body }}
            </p>
            <display-type
              v-else
              :type="quoteMessage.type"
              :body="quoteMessage.body"
              :media="quoteMessage.media"
            />
          </div>
          <img
            v-if="quoteMessage.type === 'image'"
            :src="quoteMessage.media"
            alt="image"
            class="img-quote"
          >
        </div>
      </ballon-quote>
      <div class="d-flex input-bar">
        <channel-select-dropdown
          v-if="currentChat && currentChat.instance"
          :tab="tab"
          :chat-channels="chatChannels"
          :quoted-message-channel="quoteMessage?.instance"
          :current-channel="selectedChannel"
          :is-group="currentChat.is_group"
          :is-messenger-chat="isMessengerChat"
          @selected-channel="setSelectedChannel"
        />
        <!--   ACTION BUTTONS   -->
        <div
          v-if="currentWindowWidth < 1024"
          class="dropup-center dropup"
        >
          <button
            class="btn btn-more tx-text-gray dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-regular fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <message-actions
                v-model:show-this-modal="showThisModal"
                v-model:tab="tab"
                v-model:text="text"
                v-model:is-open-messages-templates-modal="openMessagesTemplatesModal"
                :locked="isLockedSend"
                :actived-audio-record="activedAudioRecord"
                :current-chat="currentChat"
                :selected-chat-type="selectedChannel?.type"
                :loading-flags="loadingFlags"
                :selected-channel="selectedChannel"
                @set-file="(file) => $emit('setFile', file)"
              />
            </li>
          </ul>
        </div>
        <message-actions
          v-else
          v-model:show-this-modal="showThisModal"
          v-model:tab="tab"
          v-model:text="text"
          v-model:is-open-messages-templates-modal="openMessagesTemplatesModal"
          :locked="isLockedSend"
          :actived-audio-record="activedAudioRecord"
          :current-chat="currentChat"
          :selected-chat-type="selectedChannel?.type"
          :loading-flags="loadingFlags"
          :selected-channel="selectedChannel"
          @set-file="(file) => $emit('setFile', file)"
        />
      </div>
      <div
        v-if="showTemplateButton && tab !== 'internal-notes' && !selectedMessageWabaTemplate"
        class="d-flex justify-content-center"
      >
        <app-button
          @click="openMessagesTemplatesModal = true"
          :loading="loading.changeCredits || loadingFlagsWaba.sendMessageTemplates"
          color="btn-primary"
          class="col-lg-12"
        >
          {{ $t("select_message_template") }}
        </app-button>
      </div>
      <waba-block
        v-else-if="blockWabaInput && tab !== 'internal-notes'"
        :current-chat="currentChat"
      />
      <div
        class="d-flex align-items-end gap-2 input-container skeleton-loading"
        v-else
      >
        <waba-timer
          v-if="showTimer && tab !== 'internal-notes'"
          :current-chat="currentChat"
        />
        <message-template-item
          v-if="selectedMessageWabaTemplate"
          :template="selectedMessageWabaTemplate"
          enable-cancel
          @close="removeTemplate()"
        />
        <div
          v-else
          :class="{
            'note-context': tab === 'internal-notes',
            'background-loading': loadingFlags.openIaSuggest || loadingFlags.openIaImprove,
            'justify-content-center': activedAudioRecord && currentWindowWidth < 1200,
            'justify-content-end': activedAudioRecord && currentWindowWidth >= 1200
          }"
          class="col d-flex align-items-center rounded-3 px-3 content"
        >
          <textarea
            v-show="!activedAudioRecord"
            :disabled="activedAudioRecord || isLockedSend"
            v-model="text"
            ref="textarea"
            :placeholder="activedAudioRecord ? '' : 'Mensagem'"
            data-test="textarea-field"
            :class="{
              'note-context': tab === 'internal-notes',
              'background-loading': loadingFlags.openIaSuggest || loadingFlags.openIaImprove
            }"
            class="col text-medium lh-1 rounded-3"
            @keydown="keydown"
          />
          <audio-record
            v-show="activedAudioRecord"
            :active="activedAudioRecord"
            @set-audio="setAudio"
            @disable="activedAudioRecord = activedAudioRecord = false"
          />
        </div>
        <button
          v-if="text || tab === 'internal-notes' || selectedMessageWabaTemplate"
          data-test="sendMessage-button"
          :disabled="disableSendButton"
          :class="{
            'btn p-0 send': true,
            'send-note': tab === 'internal-notes',
            'background-loading': loadingFlags.openIaSuggest || loadingFlags.openIaImprove
          }"
          @click="send"
        >
          <i class="fal fa-paper-plane-top fw-medium m-0" />
        </button>
        <button
          v-else
          class="btn button-message-input p-0 send"
          :class="{ 'background-loading': loadingFlags.openIaSuggest || loadingFlags.openIaImprove }"
          :disabled="isLockedSend"
          @click="activedAudioRecord = !activedAudioRecord"
          data-test="audioRecord-button"
        >
          <i
            class="fe fe-mic m-0"
            v-if="!activedAudioRecord && tab === 'whatsapp'"
          />
          <i
            v-else
            class="fal fa-paper-plane-top fw-med m-0"
          />
        </button>
      </div>
    </div>
  </div>
  <messages-template-modal
    v-if="wabaChannel"
    v-model="openMessagesTemplatesModal"
    :waba-channel-id="wabaChannel?.id"
    :instance-id="instanceData.id"
  />
</template>

<script>
import SelectEmoji from "../select-emoji.vue";
import AudioRecord from "../audio-record.vue";
import QuickMessages from "../quick-messages.vue";
import BallonQuote from "../ballon-quote.vue";
import DisplayType from "../display-type.vue";

import {Utils} from "@/utils";
import {mapActions, mapGetters, mapMutations} from "vuex";
import MessagesTemplateModal from "@whatsapp/components/messages-templates/messages-template-modal.vue";
import MessageTemplateItem from "@whatsapp/components/messages-templates/message-template-item.vue";
import ChannelSelectDropdown from "@whatsapp/components/message-input/channel-select-dropdown.vue";
import MessageActions from "@whatsapp/components/message-input/message-actions.vue";
import moment from "moment/moment";
import resizeListener from "@/mixins/resizeListener";
import WabaTimer from "@whatsapp/components/message-input/waba-timer.vue";
import AppButton from "@components/app-button.vue";
import WabaBlock from "@whatsapp/components/message-input/waba-block.vue";

export default {
  emits: ["sendVoice", "sendTemplate", "sendText", "sendInternalNote", "setFile", "deleteQuote"],
  components: {
    WabaBlock,
    AppButton,
    WabaTimer,
    MessageActions,
    ChannelSelectDropdown,
    MessageTemplateItem,
    MessagesTemplateModal,
    SelectEmoji,
    AudioRecord,
    BallonQuote,
    DisplayType,
    QuickMessages,
  },
  props: {
    quoteMessage: {type: Object, default: null},
    isLockedSend: {type: Boolean, default: false},
  },

  mixins: [resizeListener],

  data() {
    return {
      showThisModal: null,  // 'emoji' | 'quick-messages'
      activedAudioRecord: false,
      quickMessageFilter: "",
      tab: "whatsapp", // 'internal-notes'
      text: "",
      dropdown: null,
      selectedChannel: null,
      openMessagesTemplatesModal: false,
      wabaChannel: null,
      chatChannels: [],
      ultramsgChannel: null,
      currentWindowWidth: null
    };
  },

  mounted() {
    this.setResizeCallback(this.handleResize)

    if (this.$refs.textarea){
      this.adjustTextareaHeight()
      this.$refs.textarea.focus();
    }

    this.fetchGroupChannels()
    this.fetchQuickMessages(this.groupChannelId)
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser",
      isAgent: "isAgent",
      isManager: "isManager"
    }),
    ...mapGetters("whatsapp/chat", {
      chats: "getCurrentChat",
    }),
    ...mapGetters("whatsapp/waba", {
      selectedMessageWabaTemplate: "getSelectedMessageTemplate",
      loadingFlagsWaba: "getLoadingFlags"
    }),
    ...mapGetters("whatsapp/message", {
      loadingFlags: "getLoadingFlags"
    }),
    ...mapGetters("whatsapp/instance", {
      groupChannels: "getGroupChannels",
      instances: "getInstances",
      loading: "getLoadingFlags",
    }),
    ...mapGetters("whatsapp/quick-message", {
      quickMessages: "getQuickMessages"
    }),

    currentChat() {
      return this.chats[this.$route.params.id]
    },

    isMessengerChat() {
      return this.currentChat?.instance && this.currentChat?.instance.type === 'messenger'
    },

    instanceData() {
      return this.currentChat.instance.data || this.currentChat.instance
    },

    disableSendButton() {
      return this.selectedChannel?.type === 'waba' ? (this.selectedMessageWabaTemplate === null && this.text.length === 0) : false
    },

    showTemplateButton() {
      return this.selectedChannel?.type === 'waba'
          &&  (this.currentChat?.waba_message_received?.end_message_cycle < Date.now() / 1000
              || !this.currentChat?.waba_message_received?.end_message_cycle)
    },
    blockWabaInput() {
      return this.selectedChannel?.type === 'waba'
          && this.currentChat?.waba_message_received?.end_message_cycle
          && !this.currentChat?.waba_message_received?.message_received
          && this.currentChat?.waba_message_received?.end_message_cycle > Date.now() / 1000
    },
    showTimer() {
      return this.selectedChannel?.type === 'waba'
          && this.currentChat?.waba_message_received?.end_message_cycle
          && this.currentChat?.waba_message_received?.end_message_cycle > Date.now() / 1000
    },
    groupChannelId() {
      return this.currentChat.instance.data?.group_channel_id || this.currentChat.instance.group_channel_id
    }
  },

  watch: {
    quoteMessage(data) {
      if (data) {
        this.tab = "whatsapp";
        this.$refs.textarea.focus();
        if (this.chatChannels)
          this.setSelectedChannel(this.chatChannels.find((channel) => channel.id === data.instance_id))
      }
    },
    text(value) {
      this.$nextTick(() => {
        if (this.$refs.textarea) {
          this.$refs.textarea.focus();
          this.adjustTextareaHeight()
        }
      })
      if (value.match(/^\/[a-z\d]{0,9}$/)) {
        this.quickMessageFilter = value.replace("/", "");
        this.showThisModal = 'quick-messages';
      } else {
        this.quickMessageFilter = "";
        this.showThisModal = null;
      }
    },

    'selectedChannel.type'(value) {
      if (value === 'waba') {
        this.activedAudioRecord = false
      } else {
        this.setSelectedMessageTemplate(null)
      }
    },
  },

  methods: {
    getColor: Utils.getColor,
    ...mapActions("whatsapp/waba", ["sendMessageTemplates"]),
    ...mapMutations('whatsapp/chat', ['setChatState']),
    ...mapMutations("whatsapp/waba", ["setSelectedMessageTemplate"]),
    ...mapActions("whatsapp/instance", ["findGroupChannel"]),
    ...mapActions("whatsapp/quick-message", ["fetchQuickMessages"]),

    async send() {
      if (this.tab === "whatsapp" && !this.disableSendButton) {
        if (this.selectedMessageWabaTemplate) {
          const tempTime = moment().unix()
          this.$emit("sendTemplate", {...this.selectedMessageWabaTemplate, time: tempTime});
          await this.sendTemplateMessage(tempTime)
        } else {
          this.$emit("sendText", {
            text: this.text,
            instance_id: this.selectedChannel ? this.selectedChannel.id : this.currentChat.instance_id
          });
        }
      } else {
        this.$emit("sendInternalNote", {
          text: this.text,
          instance_id: this.selectedChannel ? this.selectedChannel.id : this.currentChat.instance_id
        });
      }
      this.text = "";
      if (!this.wabaChannel) {
        this.$refs.textarea.style.height = "20px";
      }
    },

    fetchGroupChannels () {
      const chatChannel = this.currentChat.instance.data || this.currentChat.instance
      const groupChannelId = chatChannel.group_channel_id
      const groupChannel = this.groupChannels.find((groupChannel) => groupChannel.id === groupChannelId);

      this.chatChannels = groupChannel ? groupChannel.instances.data : [chatChannel];
      this.wabaChannel = this.chatChannels.find((channel) => channel.type === 'waba')
      this.selectedChannel = this.chatChannels.find((channel) => channel.id === this.currentChat.states.channel.id)
    },

    setSelectedChannel(channel) {
      this.selectedChannel = channel
      this.setChatState({
        chatId: this.currentChat.id,
        chatState: 'channel',
        value: {
          type: channel.type,
          id: channel.id
        }
      })
    },

    sendTemplateMessage(tempTime) {
      this.sendMessageTemplates({
        instance_id: this.wabaChannel.id,
        chat_id: this.currentChat.id,
        template_name: this.selectedMessageWabaTemplate.name,
        template_language: this.selectedMessageWabaTemplate.language,
        template_id: this.selectedMessageWabaTemplate.id,
        tempTime: tempTime
      });
    },

    removeTemplate() {
      this.setSelectedMessageTemplate(null)
    },

    keydown(e) {
      if (e.keyCode === 13) {
        if (e.ctrlKey || e.shiftKey) {
          this.$refs.textarea.scrollTop = this.$refs.textarea.scrollHeight;
        } else if (this.text.trim() === "") {
          e.preventDefault()
          this.text = "";
        } else {
          e.preventDefault();
          if (this.showThisModal != "quick-messages") this.send();
        }
      }
    },

    setAudio(data) {
      this.$emit("sendVoice", data);
    },

    adjustTextareaHeight() {
      this.$refs.textarea.style.height = "20px";
      this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight}px`;
    },

    handleResize(e) {
      this.currentWindowWidth = e?.target?.outerWidth || e?.outerWidth;
    }
  },
};
</script>
<style lang="scss" scoped>
.skeleton-loading {
  .background-loading {
    border-radius: 8px;
    user-select: none;
    color: transparent !important;

    &.option {
      text-decoration: none !important;
      color: transparent !important;
      border: none !important;
    }
  }

  .container-loading {
    border-radius: 0.5rem !important;
  }
}

.new-message-input {
  background-color: $color-text-light;
  padding: 0 $whatsapp-spacing-3 $whatsapp-spacing-3;
  border-top: 1px solid #DFDFDF;

  .quote-content {
    background-color: #E1E9F4;
    border-radius: $whatsapp-border-radius $whatsapp-border-radius $whatsapp-border-radius 0;

    p {
      max-height: 28px;
      overflow: hidden;
      text-overflow: clip;
      word-break: break-all;
    }

    .img-quote {
      height: 84px;
      width: 84px;
      object-fit: cover;
      border-radius: 0 $whatsapp-border-radius $whatsapp-border-radius 0;
    }
  }

  .input-bar {
    height: 40px;
  }

  .input-container {
    .content {
      min-height: 40px;
      background-color: #E1E9F4;

      textarea {
        background-color: #E1E9F4;
        border: none;
        outline: none;
        resize: none;
        max-height: 144px;

        &::-webkit-scrollbar {
          width: 3px !important;
        }

        &::-webkit-scrollbar-thumb {
          background: #b0c5c2 !important;
          border-radius: 20px !important;
        }
      }
    }

    .send {
      color: #677C92;
      background-color: #E1E9F4;
      width: 40px;
      height: 40px;

      &:hover,
      &.active {
        background-color: #3057f2 !important;
        color: $color-text-light !important;
      }
    }

    .send-note {
      background-color: #FFE9A9 !important;
      color: #754000 !important;

      &:hover,
      &.active {
        background-color: #FFBB3A !important;
        color: #754000 !important;
      }
    }

    .note-context {
      background-color: #FFE9A9 !important;
      color: #754000 !important;
    }
  }
}

.button-message-input {
  border-radius: $whatsapp-border-radius;

  &:not(:last-child) {
    padding: $whatsapp-button-padding !important;
    height: $whatsapp-button-size !important;
    width: $whatsapp-button-size !important;
  }

  &:hover,
  &.active {
    background-color: #3057f2 !important;
    color: $color-text-light !important;
  }
}

.button-message-input-note {
  border-radius: $whatsapp-border-radius;

  &:not(:last-child) {
    padding: $whatsapp-button-padding !important;
    height: $whatsapp-button-size !important;
    width: $whatsapp-button-size !important;
  }

  &.active {
    background-color: #FFBB3A !important;
    color: #754000 !important;

    &:hover {
      background-color: #FFE9A9 !important;
      color: #754000 !important;
    }
  }

  &:hover {
    background-color: #3057f2 !important;
    color: $color-text-light !important;
  }
}

.btn-more {
  width: 32px;
  min-width: 32px;
  height: 32px;
  padding: 0.25rem;

  &:after {
    content: none;
    display: none;
  }

  &:active,
  &.show {
    background-color: $color-blue-300;
    color: #fff !important;
  }
}

@media only screen and (max-width: 1199.999px) {
  .new-message-input {
    padding: 0.5rem 1rem 1rem 1rem !important;

    .input-bar {
      height: auto;
    }
  }
}
.div-block-waba{
  height: 40px;
}
</style>
