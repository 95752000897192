export default {
  top: [
    {
      type: "group",
      title: "voice_menu_title",
      icon: "far fa-phone",
      path: "/manager/voice-panel",
      id: "voice",
      hide: false,
      active_name: [
        "voice-panel",
        "campaign",
        "receptive-queue",
        "ring-group",
        "voice-settings"
      ],
      roles: ["manager", "supervisor"],
      children: [
        {
          title: "dialer_menu_capitalize",
          active_name: "campaign",
          path: "/manager/campaign",
          roles: ["manager", "supervisor"]
        },
        {
          title: "receptive_capitalize",
          active_name: "receptive-queue",
          path: "/manager/receptive-queue",
          roles: ["manager", "supervisor"]
        },
        {
          title: "PABX",
          active_name: "ring-group",
          path: "/manager/ring-group",
          roles: ["manager", "supervisor"]
        },
        {
          title: "settings",
          active_name: "voice-settings",
          path: "/manager/voice-settings",
          roles: ["manager"]
        }
      ]
    },
    {
      type: "link",
      title: "3c_voice",
      icon: "fal fa-globe fw-medium",
      path: "/manager/3c-voice",
      hide: false,
      active_name: "3c-voice",
      roles: ["manager"]
    },
    {
      type: "link",
      title: "Omnichannel",
      icon: "fal fa-messages fw-medium",
      id: "whatsapp",
      path: "/manager/whatsapp",
      hide: false,
      active_name: "whatsapp",
      roles: ["manager", "supervisor"]
    },
    {
      type: "link",
      title: "crm",
      icon: "fal fa-square-kanban fw-medium",
      id: "crm",
      path: "/manager/crm",
      hide: false,
      active_name: "crm",
      roles: ["manager", "supervisor"]
    },
    {
      type: "separator"
    },
    {
      type: "link",
      title: "sms",
      icon: "fal fa-message-dots fw-medium",
      path: "/manager/sms-dashboard",
      hide: false,
      active_name: "sms-dashboard",
      roles: ["manager"]
    },
    {
      type: "separator"
    },
    {
      type: "link",
      title: "public",
      icon: "fal fa-chart-user fw-medium",
      path: "/manager/audience",
      hide: false,
      active_name: "audience",
      roles: ["manager"]
    },
    {
      type: "link",
      title: "chatbot",
      icon: "fal fa-robot fw-medium",
      path: "/manager/chatbot",
      hide: false,
      active_name: "chatbot",
      roles: ["manager"]
    },
    {
      type: "link",
      title: "ia_insights",
      icon: "fal fa-microchip-ai fw-medium",
      active_name: "insights",
      path: "/manager/insights-ai",
      hide: false,
      roles: ["manager"]
    },
    {
      type: "separator"
    },
    {
      type: "redirect",
      title: "refer_and_earn",
      icon: "far fa-handshake",
      id: "refer",
      path: "https://3cplusnow.com/partner/",
      active_name: "refer",
      roles: ["manager"]
    }
  ],
  bottom: [
    {
      type: "link",
      title: "reports_capitalize",
      icon: "fal fa-pie-chart fw-medium",
      active_name: [
        "reports",
        "calls-report",
        "agents-report",
        "qualifications-report",
        "utilization-report",
        "list-metrics",
        "schedules",
        "manual-calls",
        "feedback-report",
        "intervals-report",
        "login-history",
        "chats-report",
        "chats-agents-report",
        "group-channels-report"
      ],
      path: "/manager/reports",
      hide: false,
      roles: ["manager", "supervisor"]
    },
    {
      type: "group",
      title: "settings_omni",
      icon: "fal fa-sliders-simple fw-medium",
      id: "config",
      hide: false,
      roles: ["manager"],
      active_name: [
        "users",
        "teams",
        "products-and-services",
        "qualifications",
        "feedbacks",
        "integration",
        "office-hours"
      ],
      children: [
        {
          title: "users_capitalize",
          active_name: "users",
          path: "/manager/users",
          roles: ["manager"]
        },
        {
          title: "teams_capitalize",
          active_name: "teams",
          path: "/manager/teams",
          roles: ["manager"]
        },
        {
          title: "products_and_services",
          active_name: "products-and-services",
          path: "/manager/products-and-services",
          roles: ["manager"]
        },
        {
          title: "qualifications_capitalize",
          active_name: "qualifications",
          path: "/manager/qualifications",
          roles: ["manager"]
        },
        {
          title: "capitalizes.feedbacks",
          active_name: "feedbacks",
          path: "/manager/feedbacks",
          roles: ["manager"]
        },
        {
          title: "integrations_capitalize",
          active_name: "integration",
          path: "/manager/integration",
          roles: ["manager"]
        },
        {
          title: "office_hours_capitalize",
          active_name: "office-hours",
          path: "/manager/office-hours",
          roles: ["manager"]
        }
      ]
    }
  ]
};
