export default {
  startLoading: state => state.loading = true,
  stopLoading: state => state.loading = false,
  startLoadingFlag: (state, flag) => state.loadingFlags[flag] = true,
  stopLoadingFlag: (state, flag) => state.loadingFlags[flag] = false,
  setTeam: (state, team) => state.team = team,
  setList: (state, list) => state.list = list,
  clearList: (state) => state.list = [],
  setErrors: (state, errors) => { state.errors = errors; },
  clearErrors: (state) => {
    state.errors = {}
    state.errorMessage = ""
  },
  setFilters: (state, filters) => {
    state.filters = filters;
  },
  setPagination: (state, pagination) => {
    state.pagination = pagination;
  },
  setTeams: (state, teams) => state.teams = teams,
  clearTeamPaginateList: (state) => state.list = [],
  setTeamPaginateList: (state, teams) => {
    state.list = [...state.list, ...teams]
  },
}
