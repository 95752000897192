import moment from "moment"

export default {
  startLoading: (state, flag) => state.loading[flag] = true,
  stopLoading: (state, flag) => state.loading[flag] = false,
  setTotalMetrics: (state, totalMetrics) => state.totalMetrics = totalMetrics,
  setListMetrics: (state, listMetrics) => state.listMetrics = listMetrics,
  setQualificationList: (state, qualificationList) => state.qualificationList = qualificationList,
  setErrorMessage: (state, errorMesage) => state.errorMessage = errorMesage,
  setErrors: (state, errors) => state.errors = errors,
  clearErrors: (state) => {
    state.errors = {}
    state.errorMessage = ""
  },
  setPagination: (state, pagination) => state.pagination = pagination,
  sortListMetrics: (state, sorting) => {
    state.listMetrics.sort((a, b) => {
      if (sorting.column === 'created_at') {
        const date1 = moment(a['list'].created_at, 'DD-MM-YYYY HH:mm:ss').unix();
        const date2 = moment(b['list'].created_at, 'DD-MM-YYYY HH:mm:ss').unix();

        return date1 < date2 ? -1 : (date1 > date2 ? 1 : 0);
      }
      if (sorting.column === 'list') {
        return a[sorting.column].name < b[sorting.column].name ? -1 :
          (a[sorting.column].name > b[sorting.column].name ? 1 : 0);
      } else {
        return a[sorting.column].total < b[sorting.column].total ? -1 :
          (a[sorting.column].total > b[sorting.column].total ? 1 : 0);
      }
    });
    if (sorting.dir === 'desc') state.listMetrics.reverse();
  }
}
