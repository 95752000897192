export default {
    loading: false,
    loadingContext: '', 
    errorMessage: '',
    isPaid: false,
    errors: {},
    list: [],
    CEPData: [],
    transactionData: [],
    offlineSms: false,
}
