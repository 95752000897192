<template>
  <div
    class="d-flex align-items-center flex-column position-relative"
    style="overflow-x: hidden;"
  >
    <div class="header w-100 pt-3 ps-5 pe-4">
      <div class="header-body col-12 row m-06 d-flex align-items-center justify-content-between">
        <div class="col-6 d-flex ">
          <h1 class="m-0">
            3C Voice
          </h1>
          <div
            v-if="currentVersion !== versions.exclusive3cVoice"
            class="btn-sm btn-primary rounded"
            style="transform: scale(0.68); background-color: rgba(128, 176, 239, 0.2);"
          >
            <strong class="fs-7 text-primary"> R$ <span class="fs-3">0,06</span> min </strong>
          </div>
        </div>
        <button
          data-bs-toggle="modal"
          :data-bs-target="currentVersion === versions.exclusive3cVoice ? '#modalCredit' : '#modal3cVoice'"
          class="btn btn-primary btn-md col-6 fw-bold"
          style="max-width: 300px;"
          data-test="use3cVoice-button"
        >
          {{ currentVersion.buttonData }}
        </button>
      </div>
    </div>
    <div
      class="d-flex flex-column col align-items-center px-5 position-relative"
      :style="{width: currentVersion === versions.exclusive3cVoice ? 'auto' : '100%'}"
    >
      <h1 class="text-center mb-3">
        {{ currentVersion.title }}
      </h1>
      <p
        class="text-center px-4"
        style="max-width: 540px;"
      >
        {{ currentVersion.subTitle }}
      </p>

      <div v-if="currentVersion === versions.exclusive3cVoice">
        <div class="bg-white p-3 border rounded-pill d-flex gap-2 fs-1 mb-5">
          <span
            v-for="index in solutions"
            class="fa fa-star"
            :key="index"
            style="color: #0CBC8B;"
          />
        </div>
      </div>
      <img
        src="@/assets/img/pinkOctagonalStar.svg"
        class="position-absolute"
        v-if="currentVersion === versions.exclusive3cVoice"
        :style="{
          height: '130px',
          top: '5px',
          left: '-200px'
        }"
      >
    </div>
    <div class="mx-6">
      <div
        v-if="currentVersion !== versions.exclusive3cVoice"
        class="card d-flex flex-column my-4 px-4 justify-content-end position-relative"
      >
        <img
          src="@/assets/img/pinkOctagonalStar.svg"
          class="position-absolute pinkStar"
        >
        <div class="d-flex flex-column flex-xl-row mt-4 px-xl-4 justify-content-end">
          <div class="col-12 col-xl-5 d-flex flex-column py-5">
            <h4 class="py-4">
              {{ $t('big_call_centers_tech') }}
            </h4>
            <ul
              v-for="qualification, index in qualifications"
              :key="index"
              class="list-group list-group-flush d-flex"
            >
              <li
                class="list-group-item py-1 lh-2 border-customized"
              >
                {{ qualification }}
              </li>
            </ul>
            <img
              src="@/assets/img/3cVoiceGroup.svg"
              class="mt-5 align-self-start"
              style="height: 54px;"
            >
          </div>
          <div class="d-flex col-12 col-xl-7 row py-5 pe-xl-4 justify-content-center justify-xl-content-end">
            <div
              class="col-6"
              style="max-width: 260px;"
            >
              <div
                class="d-flex flex-column align-items-center py-4 justify-content-center"
              >
                <img
                  src="@/assets/img/aloisio/rival.svg"
                  class="mb-3"
                  style="height: 36px;"
                >
                <h4> {{ $t('concurrent') }} </h4>
                <ul
                  v-for="_, index in qualifications"
                  :key="index"
                  class="list-group list-group-flush d-flex w-25 text-center"
                >
                  <li class="list-group-item py-1 border-customized align-self-center px-3">
                    <span
                      :class="{
                        'fa fa-circle-check': index === 0,
                        'fa fa-circle-xmark': index > 0,
                        'fs-2': true
                      }"
                      :style="{
                        color: index === 0 ? '#7BC16A' : '#AAAAAA',
                      }"
                    />
                  </li>
                </ul>
                <div class="d-flex align-items-center justify-content-center flex-column mt-5">
                  <div class="d-flex card-responsive align-items-center justify-content-center">
                    <div class="d-flex flex-column align-items-center">
                      <p class="fs-4 m-0">
                        {{ $t('mobile_plan') }}
                      </p>
                      <p class="fs-3 m-0 text-decoration-line-through">
                        R$ 0,077
                      </p>
                    </div>
                    <hr
                      class="my-0 mx-3 hr"
                      style="width: 1px; height: 50px;"
                    >
                    <hr class="hr-responsive">
                    <div class="d-flex flex-column align-items-center">
                      <p class="fs-4 m-0 fs-4">
                        {{ $t('landline_plan') }}
                      </p>
                      <p class="fs-3 m-0 text-decoration-line-through">
                        R$ 0,05
                      </p>
                    </div>
                  </div>
                  <span class="fs-4">{{ $t('per_minute') }}</span>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div
                class="d-flex flex-column align-items-center border border-primary border-2 rounded py-4 justify-content-center"
                style="background-color: #F8FBFF; max-width: 260px;"
              >
                <img
                  src="@/assets/img/logo3c/logo.svg"
                  class="mb-3"
                  style="height: 36px;"
                >
                <h4> 3C Voice </h4>
                <ul
                  v-for="_, index in qualifications"
                  :key="index"
                  class="list-group list-group-flush d-flex w-25 text-center"
                >
                  <li class="list-group-item py-1 border-customized align-self-center px-3">
                    <span
                      class="fa fa-circle-check fs-2"
                      style="color: #7BC16A"
                    />
                  </li>
                </ul>
                <div class="d-flex align-items-center justify-content-center flex-column mt-5">
                  <div class="d-flex card-responsive align-items-center justify-content-center">
                    <div class="d-flex flex-column align-items-center">
                      <p class="fs-4 m-0">
                        {{ $t('mobile_plan') }}
                      </p>
                      <p class="fs-3 m-0 text-primary">
                        R$ <span class="fw-bold"> 0,06 </span>
                      </p>
                    </div>
                    <hr
                      class="my-0 mx-3 hr"
                      style="width: 1px; height: 50px;"
                    >
                    <hr class="hr-responsive">
                    <div class="d-flex flex-column align-items-center">
                      <p class="fs-4 m-0">
                        {{ $t('landline_plan') }}
                      </p>
                      <p class="fs-3 m-0 text-primary">
                        R$ <span class="fw-bold"> 0,035 </span>
                      </p>
                    </div>
                  </div>
                  <span class="fs-4">{{ $t('per_minute') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="test px-5 d-flex align-items-center flex-column mb-4">
          <p
            class="text-center px-4 mb-5"
            style="max-width: 540px;"
          >
            {{ $t('all_routes_will_be_transfer') }}
            <strong>{{ $t('settings') }} > {{ $t('telephony_capitalize') }}</strong>
          </p>
          <button
            data-bs-toggle="modal"
            :data-bs-target="currentVersion === versions.exclusive3cVoice ? '#modalCredit' : '#modal3cVoice'"
            class="btn btn-primary btn-md col-6 fw-bold w-100 mb-4"
            data-test="use3cVoice-button"
          >
            {{ currentVersion.buttonData }}
          </button>
        </div>
      </div>
    </div>
    <img
      src="@assets/img/voice-3c-video-header.svg"
      class="mt-7"
      alt=""
      style="margin-bottom: -38px;"
    >
    <div
      class="w-100 p-4 text-center d-flex flex-column align-items-center pb-2 pt-3 position-relative"
      style="background-color: #FFBB3A;"
    >
      <img
        src="@/assets/img/plus.svg"
        class="position-absolute plus"
      >

      <div
        class="d-flex position-absolute"
        style="top: -50px"
      />

      <img
        src="@/assets/img/whiteOctagonalStar.svg"
        class="position-absolute whiteOctagonalStar"
      >

      <h1
        style="max-width: 400px;"
      >
        {{ $t('what_makes_3c_advantageous') }}:
      </h1>
      <div
        class="ratio mb-6 mt-4"
        style="min-height: 400px; max-width: 708px;"
      >
        <iframe
          width="710"
          height="400"
          :src="videoSource"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        />
        <img
          src="@/assets/img/plus.svg"
          style="width: 100px; margin-left: -80px; margin-top: 150px;"
        >
      </div>

      <img
        src="@/assets/img/whiteOctagonalStar.svg"
        class="position-absolute whiteOctagonalStar"
      >
    </div>
    <img
      src="@/assets/img/plus.svg"
      class="align-self-end mb-5 me-1 mt-4"
      style="width: 100px;"
    >
    <div
      class="px-5 d-flex align-items-center flex-column"
      style="max-width: 760px;"
    >
      <h1
        class="text-center px-6"
        style="max-width: 760px;"
      >
        {{ $t('3c_voice_quality_calls') }}
      </h1>
      <p>{{ $t('what_we_fix') }}:</p>

      <div
        class="w-100"
        v-for="solution, index in solutions"
        :key="index"
      >
        <div class="card w-100 px-5 py-3">
          <div
            class="d-flex flex-row justify-content-between align-items-center"
            id="headingOne"
          >
            <h4> {{ solution.title }} </h4>
            <span
              class="fs-2 text-primary"
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="'#'+ 'collapse' + index"
              aria-expanded="false"
              :aria-controls="'collapse' + index"
              @click="solution.expanded = !solution.expanded"
              data-test="solutions3cVoice-button"
            >
              <i
                :class="solution.expanded ? 'fe fe-arrow-up-circle' : 'fe fe-arrow-down-circle'"
              />
            </span>
          </div>
          <div
            :id="'collapse' + index"
            class="collapse"
            :class="index === 0 && 'show'"
            aria-labelledby="headingOne"
            data-bs-parent="#accordion"
          >
            <div class="card-body px-0 py-2">
              {{ solution.description }}
            </div>
          </div>
        </div>
      </div>
      <button
        data-bs-toggle="modal"
        :data-bs-target="currentVersion === versions.exclusive3cVoice ? '#modalCredit' : '#modal3cVoice'"
        class="btn btn-primary btn-md col-6 fw-bold w-100 mb-6 mt-3"
        data-test="use3cVoice-button"
      >
        {{ currentVersion.buttonData }}
      </button>
    </div>
    <change-route-modal :version="currentVersion" />
  </div>
</template>
<script>
import changeRouteModal from "./components/change-route-modal.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      versions: {
        neverExclusive3cVoice: {
          buttonData: this.$t('free_test_and_earn'),
          title: this.$t('how_about_testing_3c_voice'),
          subTitle: this.$t('more_accessible_tec')
        },
        exclusive3cVoice: {
          buttonData: this.$t('add_credit'),
          title: this.$t('you_are_already_in_best_route'),
          subTitle: this.$t('enjoy')
        },
        exExclusive3cVoice: {
          buttonData: this.$t('use_3c_route'),
          title: this.$t('how_about_returning_to_3c_voice'),
          subTitle: this.$t('the_lowest_cost_telephony')
        }
      },
      currentVersion: null,
      qualifications: [
        this.$t('intelligent_bina'),
        this.$t('specific_support_team'),
        this.$t('lowest_market_cost'),
        this.$t('calls_classifier'),
        this.$t('best_calls_performance'),
        this.$t('whatsapp_support'),
        this.$t('multiple_routes')
      ],
      solutions: [
        {
          title: this.$t('connection'),
          description: this.$t('connection_explanation'),
          expanded: true,
        },
        {
          title: this.$t('accessibility'),
          description: this.$t('accessibility_explanation'),
          expanded: false
        },
        {
          title: this.$t('strategical_intelligence'),
          description: this.$t('strategical_intelligence_explanation'),
          expanded: false
        },
        {
          title: this.$t('budget'),
          description: this.$t('budget_explanation'),
          expanded: false
        },
        {
          title: this.$t('facility'),
          description: this.$t('facility_explanation'),
          expanded: false
        },
      ],

      videoSource: process.env.VUE_APP_LMN90Q
    }
  },

  components: {
    changeRouteModal
  },

  computed: {
    ...mapGetters('manager/voice_3c', {
      telephonyRouteStatus: "getTelephonyRouteStatus"
    })
  },

  created() {
    this.currentVersion = this.versions[this.telephonyRouteStatus]
  },

  watch: {
    telephonyRouteStatus(telephonyRouteStatus) {
      this.currentVersion = this.versions[telephonyRouteStatus]
    }
  }
}
</script>
<style lang="scss" scoped>
iframe {
  border-radius: 6px;
  border: 3px solid;
  border-color: $color-blue-300;
  -webkit-box-shadow: 0px 14px 20px -9px rgba(0,0,0,0.8);
  -moz-box-shadow: 0px 14px 20px -9px rgba(0,0,0,0.8);
  box-shadow: 0px 14px 20px -9px rgba(0,0,0,0.8);
  z-index: 1;
}

.pinkStar {
  height: 130px;
  top: -58px;
  left: -58px;
  z-index: -10
}

img.whiteOctagonalStar {
  height: 130px;
  top: -45px;
  left: 20px;
}

img.whiteOctagonalStar:nth-of-type(2) {
  height: 130px;
  top: auto;
  left: auto;
  bottom: -30px;
  right: -45px;
}

.plus {
  height: 40px;
  top: -90px;
  right: 0;
}

.card-responsive {
  display: flex;
  flex-direction: row;
}

.border-customized {
  border-bottom: 1px solid #E6E6E6;
  height: 33px;
  display: flex;
  align-items: end;
}

@media (max-width: 1275px) and (min-width: 1200px) {
  .card-responsive {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    .hr {
      display: none
    }
    .hr-responsive {
      width: 100%;
      height: 1px;
    }
  }
}
</style>
