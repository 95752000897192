<template>
  <div>
    <span
      class="form-text fs-6"
      style="margin-bottom: 1rem"
    >
      {{ $t('verify_column_data') }}
    </span>
    <table-csv-preview />
    <list-status-card />
    <distribution-card-mode
      @selected-step="selectStep"
      @selected-distribution-mode="selectDistMode"
      @selected-responsible="selectResponsible"
    />
    <app-button
      class="w-100"
      :loading="loadingFlags.createImportCsv"
      @click="submit"
      :disabled="isDisabled"
    >
      {{ $t('finalize') }}
    </app-button>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import ListStatusCard from "../list-status-card.vue";
import DistributionCardMode from "../distribution-card-mode.vue";
import AppButton from "@components/app-button.vue";
import TableCsvPreview from "../table-csv-preview.vue";

export default {
  name: "CsvPreview",

  components: {
    TableCsvPreview,
    AppButton,
    DistributionCardMode,
    ListStatusCard
  },

  data (){
    return {
      stepPage: 1,
      funnelStep: null,
      distMode: { id: 5, mode: 'all', role: 'manager', label: this.$t('distribute_manually') },
      responsible: null,
    };
  },

  created (){
    this.loadFunnel()
  },

  computed: {
    ...mapGetters('crm/imports', {
      preAdjustedCsv: "getPreAdjustedCsv",
      headerCsv: "getHeaderCsv",
      loadingFlags: "getLoadingFlags"
    }),
    ...mapGetters("crm/funnels", {
      currentFunnel: "getCurrentFunnel",
    }),
    ...mapGetters("auth", {
      user: "getUser",
    }),
    ...mapGetters("crm/tour", {
      tourSteps: "getTourSteps"
    }),

    isDisabled () {
      const isResponsibleRequiredButEmpty = this.distMode?.mode === 'one' && this.distMode?.role !== 'manager' && !this.responsible
      return isResponsibleRequiredButEmpty || !this.distMode
    }
  },

  methods: {
    ...mapMutations('crm/funnels', ['setCurrentFunnel']),
    ...mapActions('crm/funnels', ['updateCurrentFunnel']),
    ...mapActions('crm/imports', ['createImportCsv', "clearCsvList"]),

    loadFunnel() {
      this.updateCurrentFunnel(this.$route.params.id)
    },

    selectStep(step) {
      this.funnelStep = step
    },

    selectDistMode(dist) {
      this.distMode = dist
    },

    selectResponsible(responsible) {
      this.responsible = responsible
    },


    submit () {
      const dataToSend = {
        separator: ',',
        delimiter: 'quotes',
        has_header: true,
        original_name: this.preAdjustedCsv.original_name,
        file_name: this.preAdjustedCsv.file_name,
        header: this.headerCsv,
        funnel_id: this.$route.params.id,
        step_id: this.funnelStep.id || undefined,
        cards_distribution: {
          role: this.distMode.role,
          mode: this.distMode.mode,
        }
      }

      if (this.distMode.role === 'manager') {
        dataToSend.cards_distribution.owner_id = this.user?.id
      }
      else if (this.distMode.mode === 'one') {
        dataToSend.cards_distribution.owner_id = this.responsible?.id
      }

      this.createImportCsv(dataToSend).then(()=> {
        this.setCurrentFunnel({...this.currentFunnel, is_importing: true})
        this.$router.push(`/manager/crm/${this.$route.params.id}`)
        this.clearCsvList()
      })
    }
  }
}
</script>