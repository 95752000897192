export default {
  loadingFlags: {},
  errors: [],
  list: [],
  allList: [],
  filters: {},
  pagination: {
    per_page: 15,
    current_page: 1,
  },
  totalLogged: 0,
  totalMaxLogin: 0,
  callStats: null,
  listCompanies: [],
  createdTag: [],
}
