<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs">
            <li
              class="nav-item"
              role="presentation"
            >
              <button
                class="nav-link active fw-medium fs-4"
                data-bs-toggle="tab"
                type="button"
                role="tab"
                aria-controls="geral"
                aria-selected="true"
                @click="listWithoutURA"
              >
                {{ $t("lists") }}
              </button>
            </li>

            <li
              class="nav-item"
              role="presentation"
            >
              <button
                v-if="campaign.ura_limit > 0"
                class="nav-link fw-medium fs-4"
                data-bs-toggle="tab"
                type="button"
                role="tab"
                aria-controls="geral"
                aria-selected="true"
                @click="listWithURA"
              >
                {{ $t("ivr_lists") }}
              </button>
            </li>
          </ul>
          <div
            class="col-auto"
            v-if="checkUserPermission()"
          >
            <mailing-form
              :open-modal-form="this.openModal"
              :campaign="campaign"
              @success="updateListOfList"
              :with-ura="ura"
              :user-permission="checkUserPermission()"
            />
          </div>
        </div>

        <div class="table-responsive">
          <table
            class="table table-hover table-nowrap card-table"
            :class="{ 'mb-4': list.length && !showInactiveLists }"
          >
            <thead>
              <tr>
                <th />
                <th colspan="2">
                  <span> {{ $t("name") }}</span>
                </th>
                <th
                  colspan="1"
                  v-if="ura"
                >
                  {{ $t("uppercases.active_ivr") }}
                </th>
                <th>
                  {{ $t("mailing_scheduling") }}
                </th>
                <th>
                  {{ $t("calls_made") }}
                </th>
                <th>
                  {{ $t("online_operation") }}
                </th>
                <th>
                  {{ $t("completion") }}
                </th>
                <th>
                  {{ $t("date") }}
                </th>
                <th>
                  {{ $t("weight") }}
                </th>
                <th v-if="user.company.mailing_list_conversion_goals">
                  {{ $t("goal") }}
                </th>
                <th />
              </tr>
            </thead>
            <tbody class="text-medium">
              <tr v-if="loadingFlags['findListOfLists']">
                <td colspan="99">
                  <div class="card-body text-center my-5">
                    <div class="spinner-border spinner-lg text-muted">
                      <span class="visually-hidden" />
                    </div>
                  </div>
                </td>
              </tr>

              <mailing-list-item
                v-else
                v-for="(item, i) in activeMailingLists"
                :key="i"
                :item="item"
                :ura="ura"
                :sum-weight="sumWeight"
                :user-permission="checkUserPermission()"
                :campaign="campaign"
                @update-list-weight="updateListWeight"
                :list-weight-id-updating="listWeightIdUpdating"
                @success="updateListOfList"
                @recycle="recycle"
              />

              <tr v-if="showInactiveLists">
                <td
                  colspan="12"
                  class="border-0"
                >
                  <div class="d-flex align-items-center gap-3">
                    <hr class="w-100">
                    <button
                      class="btn text-nowrap d-flex align-items-center show-cards py-0 px-3"
                      @click="showInactiveLists = !showInactiveLists"
                      :class="!showInactiveLists ? 'btn-white btn-sm' : 'btn-primary btn-sm'"
                      data-test="showCards-button"
                      style="height: 32px;"
                    >
                      <i
                        :class="!showInactiveLists ? 'fe fe-chevron-down' : 'fe fe-chevron-up'"
                        class="me-2 fs-5"
                      />
                      {{ $t("hide") }} {{ inactiveMailingLists.length }} {{ $t("inactive_lists") }}
                    </button>
                    <hr class="w-100">
                  </div>
                </td>
              </tr>

              <template v-if="showInactiveLists">
                <mailing-list-item
                  v-for="(item, i) in inactiveMailingLists"
                  :key="i"
                  :item="item"
                  :ura="ura"
                  :sum-weight="sumWeight"
                  :user-permission="checkUserPermission()"
                  :campaign="campaign"
                  @update-list-weight="updateListWeight"
                  :list-weight-id-updating="listWeightIdUpdating"
                  @success="updateListOfList"
                  :index="i + 1"
                  @recycle="recycle"
                />
              </template>
            </tbody>
          </table>

          <div
            v-if="list.length < 1 && !loadingFlags['findListOfLists']"
            class="card-bottom"
          >
            <div class="text-center text-muted my-3">
              <span class="fe fe-alert-octagon" /> {{ $t("no_listings_found") }}
            </div>
          </div>

          <button
            v-if="!showInactiveLists && inactiveMailingLists.length"
            class="btn text-nowrap d-flex align-items-center show-cards py-0 inactive-list-button"
            @click="showInactiveLists = !showInactiveLists"
            :class="!showInactiveLists ? 'btn-white btn-sm' : 'btn-primary btn-sm'"
            data-test="seeInactiveLists-button"
          >
            <i
              :class="!showInactiveLists ? 'fe fe-chevron-down' : 'fe fe-chevron-up'"
              class="me-2 fs-5"
            />
            {{ $t("show") }} {{ inactiveMailingLists.length }} {{ $t("inactive_lists") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <recycle-modal
    :show-recycle-modal="showRecycleModal"
    @close="showRecycleModal = false"
    :recycle-list-id="recycleListId"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import MailingForm from "./mailing-form.vue";
import MailingListItem from "@dialer/manager/pages/dashboards/campaign/components/mailing-list-item.vue";
import RecycleModal from "./recycle-modal.vue";

export default {
  data() {
    return {
      ura: false,
      passwordStrength: 1,
      count: 0,
      sumWeight: 0,
      refreshIntervalId: 0,
      activeMailingLists: [],
      inactiveMailingLists: [],
      showInactiveLists: false,
      listWeightIdUpdating: 0,
      recycleListId: 0,
      showRecycleModal: false,
    };
  },
  mounted() {
    this.setTotalWeight();
    this.refreshMailingData();
  },

  unmounted() {
    clearInterval(this.refreshIntervalId);
  },
  components: {
    MailingListItem,
    MailingForm,
    RecycleModal,
  },

  computed: {
    ...mapGetters("manager/list", {
      getList: "getList",
      getUraList: "getUraList",
      loadingFlags: "getLoadingFlags",
    }),
    ...mapGetters("auth", {
      user: "getUser",
      isSupervisor: "isSupervisor",
    }),
    ...mapGetters("manager/campaign", {
      campaign: "getCampaign",
    }),

    list() {
      return this.ura ? this.getUraList : this.getList;
    },
  },

  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    ...mapActions("manager/list", ["findListOfLists", "removeList", "updateWeight"]),

    ...mapMutations("manager/list", ["setFilters"]),

    listWithURA() {
      this.showInactiveLists = false;
      this.setFilters({ ura: 1 });
      this.ura = true;
      this.findListOfLists({ id: this.campaign.id })
          .then(() => this.setTotalWeight());
    },

    listWithoutURA() {
      this.showInactiveLists = false;
      this.setFilters({ ura: 0 });
      this.ura = false;
      this.findListOfLists({ id: this.campaign.id })
          .then(() => this.setTotalWeight());
    },

    updateListOfList() {
      this.setFilters({ ura: this.ura ? 1 : 0 });
      this.findListOfLists({ id: this.campaign.id });
    },

    checkUserPermission() {
      return (
          (this.user.role.name == "supervisor" &&
              this.user.permissions.length &&
              this.user.permissions.find(permission => permission.name == "manage.lists.in.campaign")) ||
          this.user.role.name == "manager"
      );
    },

    setTotalWeight() {
      this.sumWeight = Object.values(this.list)
          .reduce((totalWeight, element) => totalWeight + element.weight, 0);
    },

    updateListWeight(event, id, byIncrement) {
      const index = this.list.findIndex(list_item => list_item.id === id);
      if (!event && !byIncrement && this.list[index].weight === 0) {
        return;
      }

      if (event && event.target.value < 0) {
        return;
      }

      if (event) {
        this.list[index].weight = Number(event.target.value);
      } else {
        byIncrement ? this.list[index].weight++ : this.list[index].weight--;
      }

      this.listWeightIdUpdating = id;
      this.updateWeight({
        campaign_id: this.campaign.id,
        id,
        weight: this.list[index].weight,
      })
          .then(() => this.setTotalWeight())
          .finally(() => (this.listWeightIdUpdating = 0));
    },

    /**
     * This method refresh the list metric inserted every 5 minutes.
     */
    refreshMailingData() {
      this.refreshIntervalId = setInterval(() => {
        this.findListOfLists({ id: this.campaign.id });
      }, 60 * 5 * 1000);
    },

    recycle(id) {
      this.recycleListId = id;
      this.showRecycleModal = true;
    },
  },

  watch: {
    list: {
      handler(data) {
        this.activeMailingLists = data.filter(mailing_list => mailing_list.weight > 0);
        this.inactiveMailingLists = data.filter(
            mailing_list => mailing_list.weight <= 0 && !mailing_list.recycle_process,
        );
        let recycle_list = data.filter(mailing_list => mailing_list.recycle_process);
        recycle_list.forEach(list => {
          this.activeMailingLists.unshift(list);
        });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style
    lang="scss"
    scoped
>
.inactive-list-button {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
  height: 2rem;
  padding: 0 1rem;
}

.card-table thead th:first-child,
.card-table tbody td:last-child {
  padding: 0 !important;
}
</style>
