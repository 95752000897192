<template>
  <div class="card-body row m-0">
    <div class="col-12 col-md-6 ps-0">
      <div class="form-group mb-0">
        <label class="form-label required">{{ $t("capitalizes.name") }}</label>
        <input
          :value="name"
          :class="{ 'is-invalid': errors.name }"
          class="form-control"
          :placeholder="$t('capitalizes.name')"
          type="email"
          @input="$emit('update:name', $event.target.value)"
        >
        <div class="invalid-feedback">
          {{ errors.name && errors.name[0] }}
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 pe-0">
      <div class="form-group mb-0">
        <label class="form-label required">{{ $t("attendance_schedule") }}</label>
        <single-select
          :current-option="officeHour?.name"
          :error="errors.office_hour_id && errors.office_hour_id[0]"
          id="uraSelect"
          :list-array="officeHours"
          label="name"
          @selected-option="$emit('update:officeHour', $event.id )"
        />
      </div>
    </div>
  </div>
  <div class="card-body border-top d-flex flex-wrap justify-content-center">
    <div class="w-100">
      <span class="d-inline-block margin-custom">
        {{ $t("how_do_you_want_to_add_audio") }}
      </span>
      <div class="col-12 d-flex">
        <div
          class="d-flex justify-content-between col-12 padding-custom border rounded"
          v-for="(option, key) in optionsSound"
          :key="key"
        >
          <h5
            class="w-100 mb-0"
            :for="option.id"
          >
            <i
              class="icons-custom"
              :class="option.icon"
            />
            {{ option.label }}
          </h5>
          <input
            type="radio"
            :id="option.id"
            :checked="option.id === typeAudio"
            :value="option.id"
            @input="$emit('update:typeAudio', option.id)"
          >
        </div>
      </div>
    </div>
    <button
      class="align-self-center btn px-4 mt-4 col-6 h-auto py-1"
      @click="$emit('toggleCollapse')"
      :class="disableNext ?'btn-light ':'btn-primary' "
      :disabled="disableNext"
    >
      {{ $t("continue_to_upload") }}
    </button>
  </div>
</template>
<script>
import SingleSelect from "@components/single-select.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "GeneralSettings",
  components: { SingleSelect },
  props: {
    name: {
      type: String,
      required: true,
    },
    officeHour: {
      type: Number,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    typeAudio: {
      type: String,
      required: true,
    },
    disableNext: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      optionsSound: [
        {
          id: "import",
          label: this.$t("import_audio"),
          icon: "far fa-up-from-bracket tx-blue-300",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("manager/office_hours", {
      officeHours: "getOfficeHours",
    }),
  },
  emits: ["update:name", "update:typeAudio", "update:officeHour", "toggleCollapse"],

  methods: {
    ...mapActions("manager/office_hours", ["findOfficeHours"]),
  },

  created() {
    this.findOfficeHours();
  },
}
</script>
<style scoped lang="scss">
.card-body {
  padding: 1rem 1.5rem 1.5rem 1.5rem;
}

.padding-custom {
  padding: 0.5rem 1rem;
}

.margin-custom {
  margin-bottom: 0.5rem;
}

.icons-custom {
  padding-right: 0.5rem;
}

</style>