<template>
  <div class="card">
    <div class="card-header empty p-3">
      <div class="image-wrapper rounded-1">
        <img
          :src="images.empty2"
          alt="Empty state"
          class="image-2"
        >
        <img
          :src="images.empty1"
          alt="Empty state"
          class="image-1"
        >
      </div>
    </div>
    <div class="card-body empty-body">
      <div class="d-flex flex-column">
        <div>
          <i :class="['fa-regular', icon, 'text-primary fs-4 me-3']" />
          <span class="fw-bold text-large">{{ title }}</span>
        </div>

        <p class="m-0">
          {{ emptyText }}
        </p>

        <div class="align-self-end">
          <button
            @click="showModal = true"
            class="btn p-0 text-primary text-medium"
          >
            {{ $t('know_more') }}
            <i class="fa-regular fa-arrow-right text-small" />
          </button>
        </div>
      </div>
    </div>
  </div>

  <modal-component
    :show="showModal"
    @close="closeModal"
    custom-size="1000px"
    center
  >
    <template #content>
      <div class="card m-0">
        <div class="row g-0">
          <div class="col-md-6 empty-background">
            <div class="p-5 d-flex flex-column gap-4 h-100">
              <img
                width="76"
                class="small-icons"
                src="@assets/img/3czinhos.svg"
                alt="Pequenos ícones 3CPlus"
              >
              <h1 class="card-title">
                {{ modalTitle }}
              </h1>
              <p class="card-text">
                {{ modalDescription }}
              </p>
              <div class="position-relative flex-grow-1">
                <img
                  class="front-image position-absolute"
                  :src="images.empty1"
                  alt="Dialer front image"
                >
                <img
                  class="back-image position-absolute"
                  :src="images.empty2"
                  alt="Dialer back image"
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card-body h-100 d-flex flex-column justify-content-between">
              <h3 class="card-title">
                {{ $t('benefits_for_your_business', { product: title }) }}
              </h3>
              <ul class="ps-4 mb-5 d-flex flex-column gap-3">
                <li
                  v-for="benefit in benefitsList"
                  :key="benefit"
                  v-html="benefit"
                />
              </ul>

              <div class="row">
                <div class="col-8">
                  <app-button
                    color="btn-secondary"
                    class="text-medium w-100"
                    @click="redirectToBenefitsLink"
                  >
                    {{ $t('see_all_benefits') }}
                    <i class="fa-regular fa-arrow-up-right-from-square ms-2" />
                  </app-button>
                </div>
                <!-- Código comentado até existir a tela de planos -->
                <!-- <div class="col-4">
                  <app-button class="text-medium w-100">
                    {{ $t('view_plans') }}
                    <i class="fa-regular fa-arrow-right ms-2" />
                  </app-button>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import AppButton from "@/components/app-button.vue";
import ModalComponent from "@/components/modal-component.vue";

export default {
  name: "EmptyCard",
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    emptyText: {
      type: String,
      required: true
    },
    modalTitle: {
      type: String,
      required: true
    },
    modalDescription: {
      type: String,
      required: true
    },
    benefitsList: {
      type: Array,
      required: true
    },
    benefitsLink: {
      type: String,
      required: true
    },
    images: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showModal: false
    }
  },
  components: {
    ModalComponent,
    AppButton
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    redirectToBenefitsLink() {
      if (this.benefitsLink) {
        window.open(this.benefitsLink, "_blank");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.card {
  &:hover {
    .image-1 {
      transform: rotate(-4deg);
      scale: 1.1;
    }
    .image-2 {
      transform: rotate(4deg);
      scale: 1.1;
    }
  }

  .card-body {
    &.empty-body {
      padding: 1rem;
    }
  }

  .card-header {
    &.empty {
      height: unset;
      .image-wrapper {
        position: relative;
        overflow: hidden;
        height: 75px;
        background-color: $color-gray-blue-200;
        .image-1 {
          position: absolute;
          width: 200px;
          top: 24px;
          left: 20px;
          transition: all 0.2s ease-in-out;
        }

        .image-2 {
          position: absolute;
          width: 200px;
          top: 3px;
          left: 118px;
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }

  .empty-background {
    height: 588px;
    background-image: url("~@assets/img/covers/empty-background.png");
    background-size: cover;
    background-position: center;

    h1 {
      font-family: 'Random Grotesque Spacious Bold';
    }

    .front-image {
      width: 370px;
      z-index: 1;
      bottom: -14px;
      left: 0;
    }

    .back-image {
      width: 370px;
      top: -4px;
      right: -28px;
    }
  }
}

.btn-secondary {
  background-color: $color-gray-blue-200;
  color: $color-text-gray;
  border-color: $color-gray-blue-200;
}

@media (max-width: 1200px) {
  .card-header {
    &.empty {
      .image-wrapper {
        .image-1 {
          left: 200px !important;
        }

        .image-2 {
          left: 350px !important;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .card-header {
    &.empty {
      .image-wrapper {
        .image-1 {
          left: 125px !important;
        }

        .image-2 {
          left: 225px !important;
        }
      }
    }
  }
}
</style>
