<template>
  <div class="card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <p class="card-header-title">
            {{ $t('time_by_group_channels') }}
          </p>
        </div>
        <!--<div class="col-auto d-flex">-->
        <!--  <app-button v-if="true" class="btn-sm btn-export tx-text-gray col-12 cursor-pointer" @click="show = true">-->
        <!--    Baixar CSV-->
        <!--  </app-button>-->
        <!--</div>-->
      </div>
    </div>
    <div class="card-body p-0">
      <div class="table-responsive">
        <table class="table table-nowrap table-striped my-0 card-table">
          <thead>
            <tr>
              <th scope="col">
                <sort-link
                  :title="$t('uppercases.group_channels')"
                  column="group_channel_name"
                  :dir="sorts.group_channel_name.dir"
                  @sort="sort"
                  @click.prevent
                  class="text-muted fw-normal"
                />
              </th>
              <th scope="col">
                <sort-link
                  :title="$t('uppercases.chats')"
                  column="total_group_channel_chats"
                  :dir="sorts.total_group_channel_chats.dir"
                  @sort="sort"
                  @click.prevent
                  class="text-muted fw-normal"
                />
              </th>
              <th scope="col">
                <sort-link
                  title="SLA"
                  :tooltip="$t('tooltips.sla')"
                  column="total_sla_time"
                  :dir="sorts.total_sla_time.dir"
                  @sort="sort"
                  @click.prevent
                  class="text-muted fw-normal"
                />
              </th>
              <th scope="col">
                <sort-link
                  title="TMA"
                  :tooltip="$t('tooltips.ast')"
                  column="total_tma_time"
                  :dir="sorts.total_tma_time.dir"
                  @sort="sort"
                  @click.prevent
                  class="text-muted fw-normal"
                />
              </th>
              <th scope="col">
                <sort-link
                  title="TMPR"
                  :tooltip="$t('tooltips.afrt')"
                  column="total_tmpr_time"
                  :dir="sorts.total_tmpr_time.dir"
                  @sort="sort"
                  @click.prevent
                  class="text-muted fw-normal"
                />
              </th>
            </tr>
          </thead>
          <tbody v-if="loadingFlags.fetchGroupChannelsReportList">
            <loading-table-component
              :lines="5"
              :columns="8"
            />
          </tbody>
          <tbody v-else-if="timeReportList && timeReportList.group_channel_time_metrics">
            <tr
              v-for="item in timeReportList.group_channel_time_metrics"
              :key="item.group_channel_id"
            >
              <td>{{ item.group_channel_name }}</td>
              <td>{{ item.group_channel_chats }}</td>
              <td>{{ item.sla }}</td>
              <td>{{ item.tma }}</td>
              <td>{{ item.tmpr }}</td>
            </tr>
            <tr class="fw-bold">
              <td>Total</td>
              <td>{{ total.group_channel_chats }}</td>
              <td>{{ total.total_sla_time }}</td>
              <td>{{ total.total_tma_time }}</td>
              <td>{{ total.total_tmpr_time }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <table-empty v-if="!timeReportList && !loadingFlags.fetchGroupChannelsReportList" />
  </div>
  <export-csv-reports
    :show="show"
    :loading="loadingFlags.fetchSendTimeCsvEmail"
    @close="show = false"
    @download="download"
  />
</template>

<script>
import SortLink from "@dialer/manager/components/sort-link.vue";
import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";
import TableEmpty from "@dialer/manager/pages/reports/components/table-empty.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
//import AppButton from "../../../../../../../components/app-button.vue";
import ExportCsvReports from "@dialer/manager/pages/reports/components/export-csv-reports.vue";

export default {
  name: "TableGroupChannelsTimeReport",
  components: { ExportCsvReports, /*AppButton,*/ TableEmpty, LoadingTableComponent, SortLink },
  data() {
    return {
      show: false,
      sorts: {
        group_channel_name: {
          dir: ""
        },
        total_group_channel_chats: {
          dir: ""
        },
        total_sla_time: {
          dir: ""
        },
        total_tmpr_time: {
          dir: ""
        },
        total_tma_time: {
          dir: ""
        }
      }
    };
  },
  computed: {
    ...mapGetters("manager/group_channels_report", {
      loadingFlags: "getLoadingFlags",
      timeReportList: "getTimeReportList"
    }),
    total() {
      return this.timeReportList.group_channel_total_time_metrics;
    }
  },
  methods: {
    ...mapMutations("manager/group_channels_report", ["sortTimeReportList"]),
    ...mapActions("manager/group_channels_report", ["fetchSendTimeCsvEmail"]),

    download(fieldEmail) {
      this.fetchSendTimeCsvEmail(fieldEmail).then(() => {
        this.show = false;
      });
    },
    sort(params) {
      this.sorts["group_channel_name"].dir = "";
      this.sorts["total_group_channel_chats"].dir = "";
      this.sorts["total_sla_time"].dir = "";
      this.sorts["total_tmpr_time"].dir = "";
      this.sorts["total_tma_time"].dir = "";
      this.sorts[params.column].dir = params.dir;
      this.sortTimeReportList(params);
    }
  }
};
</script>

<style scoped lang="scss">
.btn-export {
  background: #e1e9f4;
  border: none;
  padding: 8px 16px;
  &:hover {
    background-color: #d2ddec;
  }
}
</style>
