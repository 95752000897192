export default {
  loading: {
    searchLoginHistory: false,
    downloadTable: false,
  },
  errorMessage: '',
  errors: {},
  list: [],
  campaing: null,
  pagination: {},
}
