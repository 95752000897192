import axios from "axios";

export default {
  findAgentStatus({ commit }, payload) {
    commit("clearAgentsStatus");

    return new Promise((resolve, reject) => {
      axios
        .get(`/receptive_queues/${payload.id}/agents_status`)
        .then(response => {
          commit("setAgentsStatus", response.data.data);
          resolve();
        })
        .catch(response => {
          reject(response);
        });
    });
  }
};
