import axios from "@crm/plugins/axios-config";

export default {
  fetchFunnelIntegrations({ commit, rootGetters }) {
    commit("startLoading", "fetchFunnelIntegrations")
    commit('setIntegrations', []);
    const funnelId = rootGetters["crm/funnels/getCurrentFunnel"].id

    return new Promise((resolve, reject) => {
      axios.get(`/funnels/${funnelId}/integrations`)
        .then((response) => {
          commit('setIntegrations', response.data.data);
          resolve();
        })
        .catch((response) => {
          commit('system/setErrorMessage', response.response.data.errors, {root: true})
          reject();
        })
        .finally(() => {
          commit("stopLoading", "fetchFunnelIntegrations")
        });
    });
  },
  
  fetchMetaWebhookData({ commit }) {
    commit("startLoading", "fetchMetaWebhookData")
    const defaultData = {
      webhook: "",
      token: ""
    }
    commit("setMetaWebhookData", defaultData)
    
    return new Promise((resolve, reject) => {
      axios.get("/meta-webhook-data")
        .then((response) => {
          commit("setMetaWebhookData", response.data)
          resolve();
        })
        .catch((response) => {
          commit('system/setErrorMessage', response.response.data.errors, {root: true})
          reject();
        })
        .finally(() => {
          commit("stopLoading", "fetchMetaWebhookData")
        });
    });
  },
  
  postMetaIntegration({ commit, rootGetters }, integrationData) {
    commit("startLoading", "postMetaIntegration")
    integrationData.funnel_id = rootGetters["crm/funnels/getCurrentFunnel"].id
    
    return new Promise((resolve, reject) => {
      axios.post("/meta-integration", integrationData)
        .then(() => {
          commit('system/setSuccessMessage', "Integração realizado com sucesso!", {root: true})
          resolve();
        })
        .catch((response) => {
          commit('system/setErrorMessage', response.response.data.errors, {root: true})
          reject();
        })
        .finally(() => {
          commit("stopLoading", "postMetaIntegration")
        });
    });
  },
  
  fetchWebhookData({ commit }, payload) {
    commit("startLoading", "fetchWebhookData")
    const defaultData = {
      webhook: ""
    }
    commit("setRDStationWebhookData", defaultData)
    
    return new Promise((resolve, reject) => {
      axios.get("/webhook-data", { params: payload })
        .then((response) => {
          commit("setRDStationWebhookData", response.data)
          resolve();
        })
        .catch((response) => {
          commit('system/setErrorMessage', response.response.data.errors, {root: true})
          commit('setRDStationErrors', response.response.data.errors)
          reject();
        })
        .finally(() => {
          commit("stopLoading", "fetchWebhookData")
        });
    });
  },
  
  createRDStationIntegration({ commit, rootGetters }, integrationData) {
      commit("startLoading", "createRDStationIntegration")
      integrationData.funnel_id = rootGetters["crm/funnels/getCurrentFunnel"].id
      return new Promise((resolve, reject) => {
        axios.post("/integrations",  integrationData)
          .then(() => {
            commit('system/setSuccessMessage', "success_message.integration", {root: true})
            resolve();
          })
          .catch((response) => {
            commit('system/setErrorMessage', response.response.data.detail, {root: true})
            reject();
          })
          .finally(() => {
            commit("stopLoading", "createRDStationIntegration")
          });
      });
    },

  updateRDStationIntegration({ commit, rootGetters }, integrationData) {
    commit("startLoading", "updateRDStationIntegration")
    integrationData.funnel_id = rootGetters["crm/funnels/getCurrentFunnel"].id
    return new Promise((resolve, reject) => {
      axios.put(`/integrations/${integrationData.id}`, integrationData)
        .then(() => {
          commit('system/setSuccessMessage', "success_message.integration", {root: true})
          resolve();
        })
        .catch((response) => {
          commit('system/setErrorMessage', response.response.data.detail, {root: true})
          reject();
        })
        .finally(() => {
          commit("stopLoading", "updateRDStationIntegration")
        });
    });
  },
}
