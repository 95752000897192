<template>
  <div class="dropdown">
    <button
      class="d-flex justify-content-center align-items-center btn btn-snooze"
      :class="{
        'small-active': small
      }"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      data-test="snooze-button"
    >
      <div
        class="spinner-border spinner-border-sm"
        v-show="loadingFlags.putOnSnooze"
      />
      <span
        class="far fa-bell"
        v-show="!loadingFlags.putOnSnooze"
      />
      <span
        class="far fa-close"
        v-show="!loadingFlags.putOnSnooze"
      />
    </button>
    <div class="dropdown-menu">
      <snooze-chat-menu />
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import SnoozeChatMenu from "@dialer/manager/components/topbar/snooze-chat-menu.vue";

export default {
  components: {SnoozeChatMenu},
  props: {
    loading: {
      default: false,
      type: Boolean
    },
    small: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters("whatsapp/chat", {
      loadingFlags: "getLoadingFlags",
    }),
  },
};
</script>

<style lang="scss" scoped>
.btn-snooze {
  height: 32px;
  width: 32px;
  background-color: #E1E9f4;
  color: #677C92;

  &.show, &:hover {
    background-color: #3057f2;
    color: white;
  }

  .fa-close {
    display: none;
  }

  &.show {
    .fa-bell {
      display: none;
    }
    .fa-close {
      display: inline-block;
    }
  }

  &.small-active {
    max-width: 32px !important;
    min-width: 32px !important;
    width: 32px !important;
    padding: 0.25rem !important;
    font-size: 0.875rem !important;
  }
}

.dropdown {
  display: inline-block;

  .dropdown-menu {
    min-width: 240px;

    .snooze-badge {
      .b9 {
        text-transform: capitalize;
      }

      &:hover {
        background-color: $color-blue-300 !important;

        small {
          color: #e3ebf6 !important;
        }

        color: #e3ebf6 !important;
      }

      background-color: #e3ebf6 !important;
      height: 40px;
      cursor: pointer;
      border-radius: $whatsapp-border-radius;

      .col {
        min-width: 50px;
      }

      small {
        color: #737373;
        font-weight: 200;
      }
    }
  }
}
</style>
