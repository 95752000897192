<template>
  <whatsapp-warmup v-if="!isWhatsappAllowed" />
  <div
    v-else
    class="main-content"
  >
    <div class="container-fluid">
      <header-component
        title="Omnichannel"
      >
        <template #buttons>
          <div
            v-if="!loadingFlags.fetchGroupChannels"
            class="d-flex align-items-center"
          >
            <connections-tooltip
              :licenses="user.company.whatsapp_licenses"
            />
            <div class="ms-4 d-flex justify-content-end justify-content-lg-between flex-wrap gap-2">
              <app-button
                v-if="
                  isManager &&
                    user.company.whatsapp_licenses > totalActive
                "
                color="btn-gray-blue action-btn"
                class="action-btn"
                @click="$router.push('/manager/whatsapp/new')"
                data-test="newConnection-button"
              >
                <i class="fa-regular fa-plus me-1" />
                <span class="d-none d-lg-inline-block">{{ $t('new_group_channels') }}</span>
              </app-button>
              <app-button
                v-if="
                  isSupervisorOrManager &&
                    user.company.whatsapp_licenses > totalActive 
                "
                class="action-btn"
                @click="$router.push('/whatsapp')"
              >
                <i class="fa-regular fa-messages me-1" />
                <span class="d-none d-lg-inline-block">{{ $t('access_chats') }}</span>
              </app-button>
            </div>
          </div>
        </template>
      </header-component>
      <list-metrics />
      <list-connections @tab="(tab) => connectionsTab = tab" />
      <list-agents v-show="connectionsTab !== 'deleted'" />
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

import WhatsappWarmup from "./whatsapp-warmup.vue";
import ConnectionsTooltip from "./components/connections-tooltip.vue";
import HeaderComponent from "@/components/header-component.vue";
import ListMetrics from "@whatsapp/manager/pages/dashboards/connection/components/list-metrics.vue";
import ListConnections from "@whatsapp/manager/pages/dashboards/connections-list/components/list-connections.vue";
import ListAgents from "@whatsapp/manager/pages/dashboards/connection/components/list-agents.vue";
import AppButton from "@components/app-button.vue";

export default {
  components: {
    AppButton,
    ListMetrics,
    ListConnections,
    ListAgents,
    ConnectionsTooltip,
    HeaderComponent,
    WhatsappWarmup
  },

  data() {
    return {
      connectionsTooltip: null,
      connectionsTab: null
    };
  },

  created() {
    this.fetchWhatsappDashboard()
    localStorage.removeItem("instanceId");
  },

  computed: {
    ...mapGetters("manager/whatsapp", {
      loadingFlags: "getLoadingFlags",
      totalActive: "getTotalActiveInstances",
    }),
    ...mapGetters("whatsapp/instance", {
      groupChannel: "getGroupChannel"
    }),

    ...mapGetters("auth", {
      user: "getUser",
      isManager: "isManager",
      isSupervisorOrManager: "isSupervisorOrManager",
      isWhatsappAllowed: "isWhatsappAllowed"
    })
  },

  methods: {
    ...mapActions("manager/whatsapp", ["fetchWhatsappDashboard"]),
  },
};
</script>

<style scoped lang="scss">
.action-btn {
  min-width: 312px;
}

@media only screen and (max-width: 1024px) {
  .action-btn {
    min-width: auto;
    width: auto;
  }
}
</style>
