<template>
  <div class="accordion-item rounded-0 p-0 border-0 bg-transparent">
    <div
      class="accordion-header"
      :class="{
        'border-bottom': dividers
      }"
      :style="dividers ? 'margin-bottom: 0.25rem; padding-bottom: 0.25rem' : ''"
      :id="`instance-labelled-by-${instance.id}`"
    >
      <button
        class="accordion-button fs-6 collapsed mw-100 w-100 btn rounded option d-flex align-items-center"
        type="button"
        @click="toggleAccordion()"
        :disabled="blocked"
      >
        <span
          class="col-5 d-block text-start text-truncate tx-text-300"
        >
          <dot-status
            :color="instance.color"
            size="9px"
            style="margin-right: 10px"
          />
          {{ instance.name }}
        </span>
        <span
          class="col d-block text-end text-truncate t-text-300 fw-medium"
        >
          {{ getAccordionLabel(instance.instances) }}
        </span>
      </button>
    </div>
    <div
      :ref="`instance-${instance.id}`"
      class="accordion-collapse collapse"
      :data-bs-parent="`#${accordionId}`"
    >
      <div class="accordion-body bg-white p-0">
        <ul
          style="padding-top: 0.25rem"
          class="list-unstyled mb-0"
        >
          <li
            v-for="(channel, cIndex) in instance.instances"
            :style="cIndex < instance.instances.length - 1? 'margin-bottom: 0.25rem' : ''"
            :key="`channel-${cIndex}`"
            class="d-block"
          >
            <button
              :disabled="blocked"
              @click="selectInstance(channel)"
              class="btn  w-100 d-flex option rounded"
            >
              <span class="col-6 text-start tx-text-100 text-truncate">
                <i
                  class="fa-kit"
                  :class="{
                    'fa-whatsapp': channel.type === 'ultramsg',
                    'fa-waba': channel.type === 'waba',
                  }"
                />
                {{ channel.name }}
              </span>
              <span class="col-6 text-end tx-text-100 text-truncate fw-medium">
                {{ formatPhoneNumber(channel.phone) }}
              </span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import DotStatus from "@components/dot-status.vue";
import {formatPhoneNumber} from "@/utils";
import {Collapse} from "bootstrap";

export default {
  name: "ChannelsAccordionItem",
  components: {DotStatus},
  props: {
    instance: {
      type: Object,
      required: true
    },
    accordionId: {
      type: String,
      required: true
    },
    blocked: {
      type: Boolean,
      default: false
    },
    dividers: {
      type: Boolean,
      default: false
    }
  },
  emits: ['select-instance'],

  data() {
    return {
      collapseInstance: null
    }
  },

  mounted() {
    this.collapseInstance = new Collapse(this.$refs[`instance-${this.instance.id}`], {
      toggle: false
    })
  },

  methods: {
    formatPhoneNumber,
    getAccordionLabel(instances) {
      if (instances?.length > 1) {
        return `${instances.length} ${this.$tc('lowercases.channel', instances.length)}`
      }

      return instances?.length ? this.formatPhoneNumber(instances[0].phone) : false
    },

    selectInstance(channel) {
      this.$emit('select-instance', channel)
    },

    toggleAccordion() {
      if (this.instance.instances.length > 1) {
        if (this.$refs[`instance-${this.instance.id}`].classList.contains('show')) {
          this.collapseInstance.hide()
        } else {
          this.collapseInstance.show()
        }
        return
      }
      this.selectInstance(this.instance.instances[0])
    }
  }
}
</script>

<style scoped lang="scss">
.option {
  padding: 0.25rem 0.5rem;
  line-height: 1.3;
  box-shadow: none;
  background-color: white;

  &:after {
    display: none;
  }

  &:hover {
    background-color: $color-gray-blue-300;
  }
}
</style>
