import axios from "axios"
import store from "@/store"
import router from "@/router"

let source = axios.CancelToken.source();

axios.interceptors.request.use(config => {
  if (source.token.reason) {
    source = axios.CancelToken.source();
  }
  config.cancelToken = source.token;

  let arr = window.location.host.split(".");
  let subdomain = arr[0];
  let urlAPI = process.env.VUE_APP_BA12AS.replace("app", subdomain);

  var user = store.getters["auth/getUser"];

  if (user != null && user.api_token !== undefined) {
    config.headers["Authorization"] = "Bearer " + user.api_token;

    const inImpersonate = store.getters["auth/inImpersonate"];
    const impersonateUserBackup = store.getters["auth/getImpersonateUserBackup"];

    if (user?.company) {
      config.headers['log-user'] = `${user?.company.id}-${user.id}`;
    }

    if (inImpersonate || user?.role?.name === "admin") {
      const admin = inImpersonate ? impersonateUserBackup : user;
      config.headers['log-admin'] = `${admin.id}`;
    }
  }

  config.url = urlAPI + config.url
  if (config.params === undefined) {
    config.params = {}
  }

  config.headers["Access-Control-Allow-Origin"] = "*"
  return config;
});

axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response?.status === 401) {
    source.cancel()
    store.dispatch("resetState", null);
    router.push({ name: "login" });
    return Promise.reject(error);
  }

  if (error.response && error.response?.status === 429) {
    store.commit("system/setRequestLimitExceeded", true);
    /**
     * Cancel all the pending requests after the system
     * retrieves an 429 error.
     */
    source.cancel();
  }
  return Promise.reject(error)
})
