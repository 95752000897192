<script setup>
import {computed} from "vue"
import {useStore} from "vuex";
import {useDashboardQuery} from "@/composables/useDashboardQuery";
import DashCard from "@crm/pages/dashboard/components/dash-card.vue";
import AvarageSalesChart from "@crm/pages/dashboard/components/charts/avarage-sales-chart.vue";

const store = useStore();
const dateRange = computed(() => store.getters["crm/dashboard/getDateRange"])
const currentFunnel = computed(() => store.getters["crm/funnels/getCurrentFunnel"] ?? null);
const agent = computed(() => store.getters["crm/dashboard/getActiveAgent"] ?? null);

const {data, loading} = useDashboardQuery(
  currentFunnel,
  dateRange,
  dateRange,
  agent,
  'averageSalesCycle'
);

</script>

<template>
  <dash-card
    :title="$t('average_sales_cycle')"
    :tooltip-title="$t('tooltips.dashboard.average_sales_cycle.title')"
    :tooltip-body="$t('tooltips.dashboard.average_sales_cycle.body')"
    tooltip-width="341px"
    :loading="loading"
    min-height="72"
  >
    <template #body>
      <avarage-sales-chart
        v-if="data?.average_sales_cycle?.length"
        :items="data?.average_sales_cycle"
        :total-time="data?.average_sales_cycle_total"
      />
    </template>
  </dash-card>
</template>

<style scoped lang="scss">

</style>