<template>
  <div
    v-if="list.length"
    class="card mb-0"
  >
    <div class="card-header">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-0">
          {{ $t('consolidated_intervals') }}
        </h4>
      </div>
    </div>

    <div class="card-body p-0">
      <div class="table-responsive">
        <table class="table table-hover mb-0">
          <thead>
            <tr>
              <th style="min-width: 150px">
                {{ $t('agents_capitalize') }}
              </th>
              <th style="min-width: 150px">
                {{ $t('uppercases.total_time') }}
              </th>
              <th
                v-for="(interval, index) in intervalNames"
                :key="index"
                style="min-width: 150px"
              >
                {{ interval }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(agent, i) in agents"
              :key="i"
            >
              <td
                class="text-truncate"
                :title="agent.name"
              >
                {{ agent.name }}
              </td>
              <td>{{ agent.totalTime }}</td>
              <td
                v-for="(interval, j) in intervalNames"
                :key="j"
                class="tx-text-gray"
              >
                {{ agent.intervals[interval] || '-' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div
    class="card card-empty-state"
    v-if="!loadingFlags.searchIntervals && !list.length"
  >
    <div class="card-body d-flex justify-content-center align-items-center tx-text-gray">
      <i class="fe fe-alert-octagon me-2" />
      <span>
        {{ noDataMessage }}
      </span>
    </div>
  </div>

  <card-loading
    v-if="loadingFlags.searchIntervals"
    :height="200"
  />
</template>

<script>
import { mapGetters } from "vuex";
import { formatSecondsToMMSS } from "@/utils.js";
import CardLoading from "@/components/card-loading.vue";

export default {
  name: "ConsolidatedIntervals",
  data() {
    return {
      agents: [],
      intervalNames: []
    }
  },

  props: {
    showSearchMessage: {
      type: Boolean,
      default: true
    }
  },

  components: {
    CardLoading
  },

  computed: {
    ...mapGetters("manager/intervals_report", {
      list: "getList",
      loadingFlags: "getLoadingFlags"
    }),

    noDataMessage() {
      return this.showSearchMessage ? this.$t("search_to_show_intervals") : this.$t("no_info_found");
    },
  },

  methods: {
    formatSecondsToMMSS,

    handleTable() {
      let intervalsSet = new Set();

      const processedAgents = this.list.map(agent => {
        let totalTime = 0;
        let intervals = {};

        agent.intervals.forEach(interval => {
          intervals[interval.name] = `${interval.occurrences} (${this.formatSecondsToMMSS(interval.duration)})`;
          totalTime += interval.duration;
          intervalsSet.add(interval.name);
        });

        const extension_number = agent.extension_number ? `${agent.extension_number} - ` : "";
        return {
          name: `${extension_number} ${agent.name}`,
          totalTime: this.formatSecondsToMMSS(totalTime),
          intervals,
        };
      });

      this.agents = processedAgents;
      this.intervalNames = Array.from(intervalsSet);
    },

    openModal() {
      this.$refs.modalCsv.openModal();
    }
  },

  watch: {
    list() {
      this.handleTable();
    }
  }
}
</script>

<style lang="scss" scoped>
.card-empty-state {
  height: 200px;
}
</style>
