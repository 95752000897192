<template>
  <div
    class="tab-pane fade mb-4"
    id="form-inputs-tab-pane"
    role="tabpanel"
    aria-labelledby="form-inputs-tab"
    tabindex="0"
  >
    <div class="d-flex justify-content-between gap-4 mt-4">
      <edit-field-contact />
      <div class="col-5">
        <div
          :class="{ 'h-25': crmInputs.length === 0 }"
          class="card"
        >
          <div class="card-header px-0 mx-4 preview-header">
            <h4
              aria-expanded="false"
              aria-haspopup="true"
              class="header-title"
              data-bs-toggle="dropdown"
              data-toggle="dropdown"
              role="button"
            >
              {{ $t('preview') }}
            </h4>
          </div>
          <div class="card-body card-data">
            <div
              v-for="(item, key) in crmInputs"
              :key="key"
            >
              <answers-form-input-row :item="item" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnswersFormInputRow from "../components/answers-form-input-row.vue";
import EditFieldContact from "../components/edit-field-contact.vue";
import {mapGetters} from "vuex";

export default {
  name: "FormInputsTab",

  components: {
    EditFieldContact,
    AnswersFormInputRow
  },

  computed: {
    ...mapGetters('crm/opportunities', {
      formInputs: 'getFormInputs'
    }),

    crmInputs() {
      return this.formInputs || []
    }
  },
}
</script>

<style lang="scss" scoped>
.card-body {
  height: auto !important;
}

.card-header {
  border-bottom: 0 !important;

  &.preview-header {
    border-bottom: 1px solid $color-gray-blue-300 !important
  }
}

.form-check-input {
  height: $spacing-2;
  width: 28px;
}

.card-body::-webkit-scrollbar, .simple-page::-webkit-scrollbar {
  display: none !important;
}

.card-body {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.simple-page, .card-data {
  overflow: scroll;
  height: 100% !important;
}
</style>
