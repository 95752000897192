export default {
  loading: false,
  loadingFlags: {
    fetchTeamById: false,
    createTeam: false,
    updateTeam: false,
    deleteTeam: false,
  },
  team: null,
  teams: [],
  errors: {},
  list: [],
  filters: {},
  pagination: {}
};
