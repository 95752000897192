<template>
  <div class="main-content pb-1">
    <div>
      <div>
        <header-component
          :button-name="$t('new_partner')"
          pretitle="3C PARTNERS"
          redirect-link-btn="/admin/partners/new"
          title="Partners"
        />
        <div class="d-flex justify-content-between gap-3">
          <div
            v-if="loadingFlags.loadPartnersMetrics"
            class="d-flex w-100 gap-3"
          >
            <card-loading
              v-for="i in 4"
              :key="i"
              :height="148"
              class="w-100"
            />
          </div>
          <card-metrics
            v-for="(partner, index) in partnerInfo"
            v-else
            :key="index"
            :icon="partner.icon"
            :type="partner.type"
            :value="formatField(index, partner.value)"
            class="w-100"
          />
        </div>
        <div class="card">
          <div class="card-header gap-4">
            <search-input
              :search="searchValue"
              @search="search"
            />
            <ul class="nav nav-tabs nav-tabs-sm card-header-tabs ps-5">
              <li class="nav-item">
                <button
                  ref="active-partners-tab"
                  aria-controls="active-partners"
                  aria-selected="true"
                  class="nav-link two-lines active"
                  data-bs-target="#active-partners"
                  data-bs-toggle="tab"
                  role="tab"
                  @click="activeTab = 'activePartners'"
                >
                  <span
                    :class="
                      activeTab === 'activePartners'
                        ? 'tx-text-200'
                        : 'text-muted'
                    "
                  >
                    ATIVOS
                  </span>
                  <br>
                  <span class="subtitle">
                    <template v-if="loadingFlags.loadActivePartners">
                      <span class="fa-regular fa-clock" />
                    </template>
                    <template v-else>
                      {{ activePartnersPagination.total }}
                    </template>
                  </span>
                </button>
              </li>
              <li class="nav-item">
                <button
                  ref="trashed-tab"
                  aria-controls="trashed"
                  aria-selected="true"
                  class="nav-link two-lines"
                  data-bs-target="#trashed"
                  data-bs-toggle="tab"
                  role="tab"
                  @click="activeTab = 'trashed'"
                >
                  <span
                    :class="
                      activeTab === 'trashed' ? 'tx-text-200' : 'text-muted'
                    "
                  >
                    EXCLUÍDOS
                  </span>
                  <br>
                  <span class="subtitle">
                    <template v-if="loadingFlags.loadTrashedPartners">
                      <span class="fa-regular fa-clock" />
                    </template>
                    <template v-else>
                      {{ trashedPartnersPagination.total }}
                    </template>
                  </span>
                </button>
              </li>
            </ul>
          </div>

          <div class="card-body p-0">
            <div class="tab-content overflow-auto">
              <div
                id="active-partners"
                aria-labelledby="active-partners"
                class="tab-pane fade show active"
                role="tabpanel"
              >
                <table-partners-active :search-value="searchValue" />
              </div>
              <div
                id="trashed"
                aria-labelledby="trashed"
                class="tab-pane fade show"
                role="tabpanel"
              >
                <table-partners-trashed :search-value="searchValue" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "./components/header-component.vue";
import {Utils} from "@/utils";
import TablePartnersActive from "@admin/pages/3c-partners/partners/components/table-partners-active.vue";
import SearchInput from "@components/search-input.vue";
import _ from "lodash";
import TablePartnersTrashed from "@admin/pages/3c-partners/partners/components/table-partners-trashed.vue";
import {mapActions, mapGetters} from "vuex";
import CardMetrics from "@admin/pages/3c-partners/partners/components/card-metrics.vue";
import CardLoading from "@components/card-loading.vue";

export default {
  data() {
    return {
      searchValue: "",
      activeTab: "activePartners",
      tabs: {
        activePartners: null,
        trashed: null
      },
    };
  },

  beforeMount() {
    const { search } = this.activePartnersParams;
    this.searchValue = search;
    this.loadActivePartners();
    this.loadTrashedPartners();
    this.loadPartnersMetrics();
  },

  methods: {
    ...mapActions('admin/partners', [
      'loadActivePartners',
      'loadTrashedPartners',
      'loadPartnersMetrics',
    ]),

    formatMoney: Utils.formatCurrency,
    search: _.debounce(function (term) {
      this.searchValue = term;
    }, 300),

    formatField(index, amount) {
      if (index === 1) {
        return `${this.formatMoney(parseFloat(amount))} / mês`;
      } else if (index === 3) {
        return this.formatMoney(parseFloat(amount));
      }
      return amount.toString();
    }
  },

  components: {
    CardLoading,
    TablePartnersTrashed,
    SearchInput,
    TablePartnersActive,
    CardMetrics,
    HeaderComponent
  },

  computed: {
    ...mapGetters("admin/partners", {
      loadingFlags: "getLoadingFlags",
      activePartnersPagination: "getActivePartnersPagination",
      trashedPartnersPagination: "getTrashedPartnersPagination",
      dashboardPartnerMetrics: "getDashboardPartnerMetrics",
      activePartnersParams :"getActivePartnersParams"
    }),

    partnerInfo() {
      return [
        {
          icon: 'fa-regular fa-handshake',
          type: "Partners ativos",
          value: this.dashboardPartnerMetrics.active_partners,
        },
        {
          icon: 'fa-regular fa-wallet',
          type: "Carteira total",
          value: parseFloat(this.dashboardPartnerMetrics.total_wallet).toFixed(2)
        },
        {
          icon: 'fa-regular fa-link',
          type: "Clientes vinculados",
          value: this.dashboardPartnerMetrics.active_companies
        },
        {
          icon: 'fa-regular fa-money-bill',
          type: "Saldo total partners",
          value: parseFloat(this.dashboardPartnerMetrics.total_revenue).toFixed(2)
        }
      ]
    }
  }
};
</script>
