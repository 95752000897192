export default {
  loading: false,
  list: [],
  filters: {},
  pagination: {
    per_page: 12,
    current_page: 1
  },
  totalLogged: 0,
  totalMaxLogin: 0,
  callStats: null,
  listCompanies: [],
  listRoutesOfCoupon: [],
  createdCouponData: [],
  routedata: [],
}
