<template>
  <tbody class="list fs-base">
    <tr
      v-for="(item, index) in smsList"
      :key="index"
      @mouseleave="unselectItem()"
      @mouseover="selectItem(item.id)"
    >
      <td
        class="truncate-text"
        :title="item.name"
      >
        {{ item.name }}
      </td>
      <td>{{ listCreatedAt(item.created_at) }}</td>
      <td>
        <div
          v-if="item.schedule && item.status === smsStatus.APPROVED && (!item.paused || isScheduled(item.schedule) >= 1)"
          class="d-flex flex-column"
        >
          <span>{{ listCreatedAt(item.schedule) }}</span>
          <span
            v-if="isScheduled(item.schedule) >= 1"
            class="tx-text-gray text-small text-s"
          >{{
            $t("uppercases.scheduled")
          }}</span>
        </div>
        <span
          v-else
          class="visually-hidden"
        >
          ------
        </span>
      </td>
      <td
        v-if="item.paused || isScheduled(item.schedule) >= 1"
        colspan="2"
      >
        <span
          v-if="item.status === smsStatus.UNDER_ANALYSIS"
          class="w-100 py-2 fs-5 badge bg-primary-soft"
          style=""
        >
          {{ $t("in_analysis") }}
        </span>
        <span
          v-if="item.status === smsStatus.REFUSED"
          class="w-100 py-2 fs-5 badge bg-danger-soft"
        >
          {{ $t("refused") }}
        </span>
        <span
          v-if="item.status === smsStatus.APPROVED && item.paused && users.company.credit_sms < item.total"
          class="w-100 py-2 fs-5 badge bg-secondary-soft"
        >
          {{ $t("no_balance") }} {{ users.company.credit_sms }} / {{ item.total }}
        </span>
        <span
          v-if="item.status === smsStatus.APPROVED && (item.paused && users.company.credit_sms >= item.total) ||
            (!item.paused && isScheduled(item.schedule) >= 1)"
          class="w-100 py-2 fs-5 badge bg-success"
        >
          {{ $t("approved") }}
        </span>
      </td>
      <td v-if="!item.paused && !(isScheduled(item.schedule) >= 1)">
        <div
          class="d-flex align-items-center"
        >
          <span style="width: 50px">
            {{ item.total }}
          </span>
          <progressbar-multiple
            :data="mailingStatusData(item)"
            :use-icon="false"
            style="height: 6px; width: 120px"
          />
        </div>
      </td>
      <td v-if="!item.paused && !(isScheduled(item.schedule) >= 1)">
        <div class="d-flex align-items-center">
          <span style="width: 50px">
            {{ item.sent }}
          </span>
          <progressbar-multiple
            :data="smsStatusData(item)"
            :use-icon="false"
            style="height: 6px; width: 120px"
          />
        </div>
      </td>
      <td class="col-1">
        <button
          v-if="item.status === smsStatus.APPROVED && (item.paused && users.company.credit_sms >= item.total) ||
            (!item.paused && isScheduled(item.schedule) >= 1)"
          class="btn bg-gray-blue-300 mb-2 mx-3 cursor-pointer p-0 px-3 tx-text-gray py-2"
          @click="startList(item.id)"
        >
          {{ $t("to_start") }}
        </button>
        <button
          v-if="!item.paused"
          class="btn bg-gray-blue-300 mb-2 mx-3 cursor-pointer p-0 px-3 tx-text-gray py-2"
          @click="openModal({ id: item.id, type: 'responses' })"
        >
          <span class="fa-regular fa-message" />
          {{ $t("responses") }}
        </button>
        <button
          v-if="!item.paused"
          class="btn bg-gray-blue-300 mb-2 mx-3 cursor-pointer p-0 px-3 tx-text-gray py-2"
          @click="openModal({ id: item.id, type: 'reports' })"
        >
          <span class="fa-regular fa-square-kanban" />
          {{ $t("report_capitalize") }}
        </button>
        <button
          class="btn fe fe-trash text-primary px-3 cursor-pointer"
          @click="remove_({ id: item.id, listName: item.name, index })"
        />
      </td>
    </tr>
  </tbody>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ProgressbarMultiple from "@components/progressbar-multiple.vue";
import { formatDate } from "@/utils";
import moment from "moment";

export default {
  components: { ProgressbarMultiple },
  emits: ["openModal"],
  data() {
    return {
      selectedItem: null,
      mailingList: [],
      smsStatus: {
        UNDER_ANALYSIS: 1,
        APPROVED: 2,
        REFUSED: 3,
      },
      confirmDestroyModal: null,
    };
  },
  computed: {
    ...mapGetters("auth", {
      users: "getUser",
    }),
    ...mapGetters("manager/sms_dashboard", {
      smsList: "getList",
    }),
  },
  mounted() {
    this.getMailingList({})
        .then(() => {
          this.mailingList = this.smsList;
        });
  },
  methods: {
    ...mapActions("manager/sms_dashboard", ["deleteMailingList", "startMailingList", "getMailingList"]),
    ...mapActions("system", ["showConfirmationMessage"]),
    ...mapMutations("system", ["setInfoMessage"]),

    selectItem(id) {
      this.selectedItem = id;
    },
    unselectItem() {
      this.selectedItem = null;
    },
    listCreatedAt(date) {
      return formatDate(date, "DD/MM/YYYY");
    },
    isScheduled(date) {
      const currentDate = moment()
          .startOf("day");
      const targetDate = moment(date)
          .startOf("day");
      const diffInDays = targetDate.diff(currentDate, "days");
      return diffInDays;
    },
    mailingStatusData(item) {
      return [
        {
          color: "#5cb85c",
          percentage: (item.sent / item.total) * 100,
          count: item.sent,
          title: "Enviado",
        },
        {
          color: "#E63757",
          percentage: (item.not_sent / item.total) * 100,
          count: item.not_sent,
          title: "Não enviado",
        },
        {
          color: "#B1C2D9",
          percentage: ((item.total - item.sent - item.not_sent) / item.total) * 100,
          count: item.total - item.sent - item.not_sent,
          title: "A enviar",
        },
      ];
    },
    smsStatusData(item) {
      return [
        {
          color: "#5cb85c",
          percentage: item.sent > 0 ? (item.success / item.sent) * 100 : 0,
          count: item.success,
          title: "Entregue",
        },
        {
          color: "#E63757",
          percentage: item.sent > 0 ? (item.failure / item.sent) * 100 : 0,
          count: item.failure,
          title: "Não entregue",
        },
        {
          color: "#B1C2D9",
          percentage: item.sent > 0 ? ((item.sent - item.success - item.failure) / item.sent) * 100 : 0,
          count: item.sent - item.success - item.failure,
          title: "Sem status",
        },
      ];
    },

    openModal({ id, type }) {
      this.$emit("openModal", { id, type });
    },

    async startList(id) {
      let response = await this.showConfirmationMessage({
        title: "Você realmente deseja iniciar essa lista de SMS?",
        text: "",
        type: "goodWarning",
      });

      if (response.isConfirmed) {
        await this.startMailingList({ id: id });
        window.location.reload();
      } else {
        this.setInfoMessage("Lista de SMS não foi iniciada.");
      }
    },
    async remove_({ id, listName, index }) {
      let response = await this.showConfirmationMessage({
        title: `${this.$t("delete_list")} ${listName}?`,
        text: this.$t("delete_list_confirmation"),
        type: "badWarning",
      });

      if (response.isConfirmed) {
        this.deleteMailingList({ id })
            .then(() => {
              this.mailingList.splice(index, 1);
              this.confirmDestroyModal = null;
            });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

</style>
