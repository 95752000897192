export default {
  loadingFlags: {
    loadActivePartners: false,
    loadTrashedPartners: false,
    loadWithdrawalAnalysisPartners: false,
    loadWithdrawalFinalizedPartners: false,
    loadNewPartner: false,
    updatePartner: false,
    loadDetailsPartners: false,
    loadListPartners: false,
    companyDetails: false,
    checkCnpj: false,
    updateWithdrawal: false,
  },

  dashboardPartnerMetrics: {
    active_partners: 0,
    total_wallet: 0,
    linked_partners: 0,
    total_revenue: 0,
  },

  errors: {},

  activePartners: [],

  activePartnersFilters: {
    search: "",
    trashed: 0,
  },

  activePartnersPagination: {
    per_page: 15,
    current_page: 1
  },

  trashedPartners: [],

  trashedPartnersFilters: {
    search: "",
    trashed: 1,
  },

  trashedPartnersPagination: {
    current_page: 1
  },

  cnpjQueryData: {
    commercial_name: "-",
    cnpj_status: "-"
  },
  zipCode: {},
  partner: {},

  withdrawalAnalysisPartners: [],
  withdrawalAnalysisPartnersFilters: {
    search: "",
    finished: 0,
  },
  withdrawalAnalysisPartnersPagination: {
    current_page: 1
  },

  withdrawalFinalizedPartners: [],
  withdrawalFinalizedPartnersFilters: {
    search: "",
    finished: 1,
  },
  withdrawalFinalizedPartnersPagination: {
    per_page: 15,
    current_page: 1
  },

  analysisWithdrawTotalPartners: null,
  finalizedWithdrawTotalPartners: null,

  detailsPartner: {},
  listPartners: [],
  companyDetails: {},

  partnerDetailsPagination: {
    per_page: 15,
    current_page: 1
  },
};
