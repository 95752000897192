<template>
  <div>
    <main-loading v-if="loading?.findById" />
    <template v-if="register">
      <header-component
        :title="register.name"
        pretitle="FEEDBACK"
        redirect-link="/manager/feedbacks"
      />
      <div class="card">
        <div class="row card-body">
          <div class="col-lg-6">
            <div class="form-group mb-0">
              <label
                for="exampleInputEmail1"
                class="form-label required"
              >{{ $t("name_data") }}</label>
              <input
                :class="{
                  'form-control': true,
                  'is-invalid': errors.update.name,
                }"
                v-model="fields.name"
                placeholder="Nome"
              >
              <div class="invalid-feedback">
                {{ errors.update.name && errors.update.name[0] }}
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group mb-0">
              <label
                for="exampleInputEmail1"
                disabled
                class="form-label required"
              >{{ $t("max_rate") }}</label>
              <input
                type="number"
                v-model="fields.high_score"
                in="0"
                max="10"
                class="form-control"
                disabled
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header gap-4">
            <search-input @search="search" />
            <div class="col-auto">
              <button
                class="btn-light btn btn-sm px-5"
                @click="openCriteriaModal()"
              >
                {{ $t("add_feedback") }}
              </button>

              <criteria-form ref="criteriaForm" />
            </div>
          </div>

          <div class="card-body py-0 px-0">
            <list-criteria
              :criterials="feedbacks"
              :show-empty-state="!register.criteria.length"
            />
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <app-button
          class="fw mb-3"
          @click="submit()"
          :loading="loading.update"
        >
          {{ $t("save_general") }}
        </app-button>
      </div>

      <div
        class="col-lg-12"
        @click="remove_()"
      >
        <app-button
          class="fw mb-4"
          color="text-danger"
          :loading="loading.remove"
        >
          <span class="fe fe-trash" />
          {{ $t("capitalizes.exclude") }}
        </app-button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import AppButton from "@/components/app-button.vue";
import MainLoading from "@/components/main-loading.vue";
import ListCriteria from "./components/list-criteria.vue";
import CriteriaForm from "./components/form-criteria.vue";
import HeaderComponent from "@/components/header-component.vue";
import SearchInput from "@components/search-input.vue";

export default {
  data() {
    return {
      fields: {
        name: "",
        high_score: 0,
      },
      feedbacks: [],
    };
  },

  mounted() {
    this.clearErrors("update");
    this.$store.dispatch("manager/criterion/findById", { id: this.$route.params.id });
  },

  computed: {
    ...mapGetters("manager/criterion", {
      register: "getRegister",
      loading: "getLoadingFlags",
      errors: "getErrorFlags",
    }),
  },

  watch: {
    register(val) {
      if (val) {
        this.fields.name = val.name;
        this.fields.high_score = val.high_score;
        this.feedbacks = val.criteria;
      }
    },
  },

  components: {
    SearchInput,
    AppButton,
    MainLoading,
    CriteriaForm,
    ListCriteria,
    HeaderComponent,
  },

  methods: {
    ...mapActions("manager/criterion", ["update", "remove", "clearErrors"]),
    ...mapActions("system", ["showConfirmationMessage"]),

    updateCriteria(criteria) {
      alert(JSON.stringify(criteria));
    },

    openCriteriaModal() {
      this.$refs.criteriaForm.new();
    },

    submit() {
      this.update({
        id: this.register.id,
        fields: {
          name: this.fields.name,
        },
      })
          .then(() => {
            this.$router.push({
              path: `/manager/feedbacks`,
            });
          });
    },

    async remove_() {
      let response = await this.showConfirmationMessage({
        title: this.$t("delete_feedback_question"),
        text: this.$t("delete_feedback_confirmation"),
        type: "badWarning",
      });

      if (response.isConfirmed) {
        this.remove({
          id: this.register.id,
        })
            .then(() => {
              this.$router.push({
                path: `/manager/feedbacks`,
              });
            });
      }
    },

    search(val) {
      this.feedbacks = this.register.criteria.filter((item) => {
        return item.name.toLowerCase().includes(val.toLowerCase());
      });
    },
  },
};
</script>
