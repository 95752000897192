<template>
  <div
    class="d-flex align-items-center"
  >
    <div
      class="card mb-0 card-question"
      @click="addOrEdit"
    >
      <div class="card-body p-0">
        <div
          class="d-flex align-items-center card-question-body"
        >
          <action-component
            icon-class="fa-regular fa-message-question"
            icon-color="#5CB868"
            :name="$t('make_a_question')"
          />
        </div>
        <hr class="w-100">
        <div
          class="d-flex flex-column justify-content-center card-question-text"
        >
          <card-text :text="action.text_content" />
        </div>
        <div class="d-flex w-100 justify-content-end px-4 tx-text-gray next-step-div">
          <p class="mb-2">
            {{ $t("after_answer") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

import ActionComponent from "@chatbot/pages/workflow/components/action-component.vue";
import CardText from "@chatbot/pages/workflow/components/card-text.vue";

export default {
  name: "CardQuestion",

  props: {
    action: {
      type: Object,
      required: true
    }
  },

  components: {
    CardText,
    ActionComponent
  },

  methods: {
    ...mapMutations("chatbot/actions", [
      "setOffcanvasEditActionIsOpen",
      "setOffcanvasNewActionIsOpen",
      "setActionToEdit",
      "setSelectedAction",
      "setPrevActionId",
    ]),

    editAction() {
      this.setSelectedAction(this.action)
      this.setActionToEdit(this.action)
      this.setOffcanvasEditActionIsOpen(true)
    },
    addOrEdit(){
      if(!this.action?.text_content){
        this.addText()
      }else{
        this.editAction()
      }
    },
    addText(){
      this.setPrevActionId(this.action.previous_action_id)
      this.setOffcanvasNewActionIsOpen(true)
      this.setSelectedAction({
        id: this.action.id,
        type: "question",
        need_chat_response: true,
        position: this.action.position
      });
    }
  }
}
</script>
<style scoped>
.card-question-body{
  padding: 1rem 1rem 0 1rem;
  width: 350px
}
.card-question-text{
  padding: 0 1rem 1rem 1rem;
  width: 350px
}
.card-question{
  &:hover{
    border: 1px solid #5CB868;
  }
}
.next-step-div{
  padding: 0 1rem 1rem 1rem;
}
</style>