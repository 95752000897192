<template>
  <bar-chart
    :title="$t('conversations_in_the_period')"
    :format-ticks="secondsToHoursFormat"
    :labels="chartDataFiltered.labels"
    :chart-data="chartDataFiltered.chartData"
    :datasets="[
      { label: $t('agent_started'), color: '#C5C5C5', borderRadius: 10, borderSkipped: 'middle'},
      { label: $t('customer_started'), color: '#E67C0B', borderRadius: 10, borderSkipped: 'middle' },
      { label: $t('ended'), color: '#3057F2', borderRadius: 10, borderSkipped: 'middle' },
    ]"
    :loading="false"
  />
</template>
<script>
import {mapGetters} from "vuex";
import barChart from "@dialer/manager/components/bar-chart.vue";
import {formatDate} from "@/utils";
import moment from "moment";
import _ from 'lodash'

export default {
  name: "ChatsInPeriodCard",
  components: {barChart},
  props: {
    chatData: {type: Object, default: () => null},
    period: {type: Object, default: () => ({})}
  },
  data() {
    return {
      chartLabels: [],
      chartDataFiltered: [],
    };
  },
  computed: {
    ...mapGetters("manager/performance_report", {
      loadingFlags: "getLoadingFlags",
    }),
  },

  watch: {
    chatData: {
      handler(data) {
        this.preparePeriodRange(data)
      },
      deep: true,
    },
  },

  methods: {
    formatDate,
    secondsToHoursFormat(day) {
      return day
    },
    preparePeriodRange() {
      if (this.period.emptyDays) {
        this.chartLabels = Object.keys(this.chatData)
        this.prepareChartData();
      } else {
        this.chartLabels = this.createDateArray(this.period.start_date, this.period.end_date);
        this.prepareEmptyDaysMode()
      }
    },
    createDateArray(start_date, end_date) {
      const startDate = moment(start_date);
      const endDate = moment(end_date);
      const dateArray = [];

      while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
        startDate.add(1, 'days');
        dateArray.push(startDate.format('DD MMM'));
      }
      return dateArray;
    },

    prepareEmptyDaysMode() {
      this.chartDataFiltered = {
        labels: this.chartLabels,
        chartData: new Array(3).fill(0).map(() => new Array(this.chartLabels.length).fill(0))
      }

      this.chartLabels.forEach((date, index) => {
        const chatDate = moment(date, 'DD MMM').format('YYYY-MM-DD');

        const chatData = this.chatData[chatDate];

        if (chatData?.length) {
          this.chartDataFiltered.chartData[0][index] = chatData[0]?.chats_initiated_by_agent || 0;
          this.chartDataFiltered.chartData[1][index] = chatData[0]?.chats_initiated_by_client || 0;
          this.chartDataFiltered.chartData[2][index] = chatData[0]?.finished_chats_count || 0;
        }
      });
    },

    prepareChartData() {
      let emptyDays = [];
      const orderData = _.orderBy(Object.entries(this.chatData));
      this.chartLabels = orderData.map((chatData) => chatData[0]);
      let prepareData = Object.values(orderData).map((chatData) => {

        const chats_initiated_by_agent = chatData[1][0].chats_initiated_by_agent || 0;
        const chats_initiated_by_client = chatData[1][0].chats_initiated_by_client || 0;
        const finished_chats_count = chatData[1][0].finished_chats_count || 0;

        if ((chats_initiated_by_agent + chats_initiated_by_client + finished_chats_count) < 1) {
          emptyDays.push(chatData[0])
        }

        return {
          chats_initiated_by_agent,
          chats_initiated_by_client,
          finished_chats_count,
        }
      });

      emptyDays.forEach(day => {
        this.chartLabels = this.chartLabels.filter((label) => label !== day)
      })
      prepareData = prepareData.filter((data) => (data.chats_initiated_by_agent + data.chats_initiated_by_client + data.finished_chats_count) > 0);

      this.chartDataFiltered = {
        labels: this.chartLabels.map((date) => moment(date).format('DD MMM')),
        chartData: [
          {
            label: 'chats_initiated_by_agent',
            data: new Array(Object.keys(prepareData).length).fill(0),
          },
          {
            label: 'chats_initiated_by_client',
            data: new Array(Object.keys(prepareData).length).fill(0),
          },
          {
            label: 'finished_chats_count',
            data: new Array(Object.keys(prepareData).length).fill(0),
          },
        ],
      }
      this.chartDataFiltered.chartData.map((dataset, index) => {
        this.chartDataFiltered.chartData[index] = Object.values(prepareData).map((chatData) => chatData[Object.keys(chatData)[index]] || 0)
      })

    },
  }
}
</script>
<style
  scoped
  lang="scss"
>
</style>