<template>
  <div class="main-content pb-6 utilization-report">
    <main-loading v-if="loadingContext['find_receptive_queue_by_id']" />
    <div
      v-else
      class="container-fluid"
    >
      <header-component
        :title="receptiveQueue.name"
        :pretitle="$t('uppercases.receptive_queues')"
        redirect-link="/manager/receptive-queue/"
      >
        <template #buttons>
          <button
            v-if="user.role.name === 'manager'"
            class="btn btn-primary px-6"
            @click="$router.push(`/manager/receptive-queue/${receptiveQueue.id}/edit`)"
          >
            {{ $t('capitalizes.configure_receptive_queue') }}
          </button>
        </template>
      </header-component>

      <div class="row">
        <MetricsComponent />
        <QualificationsChartComponent />
      </div>
      <div class="row">
        <agents-component />
        <calls-component />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import MainLoading from "@/components/main-loading.vue";
import MetricsComponent from "./components/metrics-component.vue";
import QualificationsChartComponent from "./components/qualifications-chart-component.vue";
import AgentsComponent from "./components/agents-component.vue";
import CallsComponent from "./components/calls-component.vue";
import HeaderComponent from "@/components/header-component.vue";

export default {
  data() {
    return {
      agents: [],
      available_agents: [],

      qualifications: [],
      selectedAgents: [],
      unableAgents: [],
      count: 0,

      timer: null
    };
  },
  beforeMount() {
    this.findReceptiveQueueById({ id: this.$route.params.id });
  },
  components: {
    MainLoading,
    MetricsComponent,
    QualificationsChartComponent,
    AgentsComponent,
    CallsComponent,
    HeaderComponent
  },
  computed: {
    ...mapGetters("auth", {
      user: "getUser",
    }),
    ...mapGetters("manager/receptive_queues", {
      receptiveQueue: "getReceptiveQueue",
      loadingContext: "getLoadingContext"
    }),
  },
  methods: {
    ...mapActions("manager/receptive_queues", ["findReceptiveQueueById"]),
  }
};
</script>
