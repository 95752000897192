<template>
  <vue-multiselect
    class="form-select p-0"
    :class="{ 'is-invalid': errors }"
    v-model="selectedReceptiveIvr"
    track-by="id"
    label="name"
    :options="receptiveIvrs"
    placeholder="Selecione uma URA Receptiva"
    :select-label="$t('click_or_press_enter_to_select')"
    :deselect-label="$t('click_or_press_enter_to_remove')"
    @select="$emit('update:modelValue', $event)"
    @remove="$emit('update:modelValue', '')"
  />
  <div class="invalid-feedback">
    {{ errors && errors[0] }}
  </div>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
import VueMultiselect from "vue-multiselect";

export default {
  data() {
    return {
      selectedReceptiveIvr: "",
      receptiveIvrs: []
    }
  },

  emits: ["update:modelValue"],
  components: {
    VueMultiselect
  },

  mounted() {
    this.setFiltersIncludes(["ivrs"])
    this.findFilters();
    if (this.modelValue) this.selectedReceptiveIvr = this.modelValue
  },

  computed: {
    ...mapGetters("manager/filters", {
      filters: "getFilters",
    }),
  },

  props: {

    modelValue: {
      default: "",
      type: [Number, String, Object],
    },
    errors: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    ...mapActions("manager/filters", ["findFilters", "setFiltersIncludes"])
  },

  watch: {
    filters: {
      handler(data) {
        if (data.ivrs) {
          this.receptiveIvrs = data.ivrs;
        }
      },
      deep: true,
    },

    modelValue(data) {
        this.selectedReceptiveIvr = data;
    },
  },
}
</script>
