export default {
  loadingFlag: {
    fetchCategories: false,
    listProducts: false,
    createCategory: false,
    updateCategory: false,
    deleteCategory: false,
    createProduct: false,
    updateProduct: false,
    deleteProduct: false,
    syncProductOpportunity: false,
    fetchSynchronizedProducts: false
  },
  errors: {},
  categories: [],
  totalProducts: {},
  listProducts: [],
  showModal: false,
  categoryModal: {
    title: "",
    button: "",
    type: "",
    actionName: "",
    handleName: "",
    data: {},
    dataState: "",
    exclude: {
      title: "",
      name: "",
      actionName: ""
    }
  },
  categoryData: {
    name: "",
    team_ids: [],
    is_active: true
  },
  productData: {
    name: "",
    code: "",
    description: null,
    currency: "",
    is_recurrent: false,
    price: 0,
    maximum_discount: 0,
    is_active: true,
    category_id: ""
  },
  edit: false,
  exclude: false,
  activeList: "actives",
  
  synchronizedProducts: [],
  opportunityTotalAmount: 0
}
