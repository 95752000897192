<template>
  <div class="col-lg-12">
    <div class="card mb-0">
      <div class="card-header">
        <search-input
          @search="search"
          :placeholder="$t('search_campaign')"
        />
        <ul class="nav nav-tabs nav-tabs-sm card-header-tabs ms-4">
          <li
            class="nav-item"
            role="presentation"
          >
            <button
              class="nav-link two-lines active"
              data-bs-toggle="tab"
              data-bs-target="#activeCampaigns"
              @click="activeTab = 'activeCampaigns'"
              type="button"
              role="tab"
              aria-controls="geral"
              aria-selected="true"
            >
              <small class="campaign-status text-uppercase"> {{ $t("active") }} </small>
              <br>
              <span class="subtitle">
                <template v-if="!loadingFlags.loadActiveCampaigns">
                  {{ activeCampaignsPagination.total }}
                </template>
                <template v-else>
                  <span class="fe fe-clock" />
                </template>
              </span>
            </button>
          </li>
          <li
            class="nav-item"
            role="presentation"
          >
            <button
              class="nav-link two-lines"
              data-bs-toggle="tab"
              data-bs-target="#paused"
              type="button"
              role="tab"
              @click="activeTab = 'paused'"
              aria-controls="geral"
              aria-selected="true"
            >
              <small class="campaign-status text-uppercase"> {{ $t("paused") }} </small>
              <br>
              <span class="subtitle">
                <template v-if="!loadingFlags.loadPausedCampaigns">
                  {{ pausedCampaignsPagination.total }}
                </template>
                <template v-else>
                  <span class="fe fe-clock" />
                </template>
              </span>
            </button>
          </li>
        </ul>
      </div>
      <div class="card-body p-0">
        <div class="tab-content">
          <div
            class="tab-pane fade show table-responsive active"
            id="activeCampaigns"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <table class="table table-nowrap app-datatable m-0">
              <thead>
                <tr>
                  <th
                    class="text-center"
                    style="max-width: 5rem;"
                  >
                    STATUS
                  </th>
                  <th> {{ $t("name") }}</th>
                  <th> {{ $t("today_cal") }}</th>
                  <th> {{ $t("online_agents") }}</th>
                  <th> {{ $t("activity") }}</th>
                  <th />
                </tr>
              </thead>
              <tbody class="text-base">
                <loading-table-component
                  v-if="loadingFlags.loadActiveCampaigns"
                  :lines="15"
                  :columns="6"
                  :text-center="false"
                  :list-width="[20,100,20,20,150,20]"
                />

                <template v-else-if="Object.keys(activeCampaigns).length">
                  <tr
                    v-for="campaign of sortedActiveCampaigns"
                    :key="campaign.id"
                    class="cursor-pointer"
                    :class="{
                      'text-muted': !Object.values(campaign.campaign_information.quantity_of_agents_with_online_status).length
                    }"
                    @click="$router.push('/manager/campaign/' + campaign.id)"
                  >
                    <td>
                      <div
                        class="campaign-status m-auto"
                        :class="{
                          'active': Object.values(campaign.campaign_information.quantity_of_agents_with_online_status).length
                        }"
                      />
                    </td>

                    <td
                      class="text-truncate overflow-ellipsis"
                      style="max-width: 16rem;"
                      :title="campaign.name"
                    >
                      {{ campaign.name }}
                    </td>

                    <td>
                      {{ campaign.campaign_information.quantity_of_calls_today }}
                    </td>

                    <td>
                      {{ Object.values(campaign.campaign_information.quantity_of_agents_with_online_status).length }}
                    </td>

                    <td>
                      <bar-chart :campaign="campaign" />
                    </td>

                    <td class="text-end">
                      <span class="fs-3 fe fe-chevron-right text-primary my-0" />
                    </td>
                  </tr>
                </template>

                <tr v-else>
                  <td
                    class="text-center text-muted"
                    colspan="6"
                  >
                    <span class="fe fe-alert-octagon" /> {{ $t("no_active_campaign") }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="card-footer"
              v-if="!loadingFlags.loadActiveCampaigns"
            >
              <app-paginator
                :pagination="activeCampaignsPagination"
                @change-page="changePage"
              />
            </div>
          </div>

          <div
            class="tab-pane fade show"
            id="paused"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <table class="table app-datatable m-0">
              <thead>
                <tr>
                  <th class="text-capitalize">
                    {{ $t("name") }}
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <loading-table-component
                  v-if="loadingFlags.loadPausedCampaigns"
                  :lines="15"
                  :columns="1"
                  :text-center="false"
                  :list-width="[160]"
                />

                <template v-else-if="pausedCampaigns.length">
                  <tr
                    class="cursor-pointer"
                    v-for="campaign of pausedCampaigns"
                    :key="campaign.id"
                    @click="$router.push('/manager/campaign/' + campaign.id)"
                  >
                    <td>{{ campaign.name }}</td>
                    <td
                      class="text-right"
                      style="text-align: right"
                    >
                      <router-link :to="`/manager/campaign/${campaign.id}`">
                        <span
                          class="fs-3 fe fe-chevron-right text-primary my-0"
                          style="cursor: pointer"
                        />
                      </router-link>
                    </td>
                  </tr>
                </template>

                <tr v-else>
                  <td
                    class="text-center text-muted"
                    colspan="2"
                  >
                    <span class="fe fe-alert-octagon" /> {{ $t("no_paused_campaign").charAt(0)
                      .toUpperCase() + $t("no_paused_campaign").slice(1) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="card-footer"
              v-if="!loadingFlags.loadPausedCampaigns"
            >
              <app-paginator
                :pagination="pausedCampaignsPagination"
                @change-page="changePage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";

import { mapActions, mapGetters } from "vuex";

import SearchInput from "@components/search-input";
import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";
import BarChart from "./bar-chart";
import AppPaginator from "@components/app-paginator";

export default {
  data() {
    return {
      activeTab: "activeCampaigns",
      totalCampaign: {
        loading_all_campaigns: true,
        name: this.$t("all_campaigns"),
        total_campaign: true,
      },
      start_date: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      end_date: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      sortedActiveCampaigns: [],
    }
  },

  beforeMount() {
    this.setFilters({
      start_date: this.start_date,
      end_date: this.end_date,
    });

    this.loadCampaigns("active");
    this.loadCampaigns("paused");
  },

  components: {
    SearchInput,
    LoadingTableComponent,
    BarChart,
    AppPaginator,
  },

  computed: {
    ...mapGetters("manager/campaign", {
      loadingFlags: "getLoadingFlags",
      activeCampaigns: "getActiveCampaigns",
      activeCampaignsPagination: "getActiveCampaignsPagination",
      pausedCampaigns: "getPausedCampaigns",
      pausedCampaignsPagination: "getPausedCampaignsPagination",
    }),
  },

  methods: {
    ...mapActions("manager/campaign", [
      "loadCampaigns",
      "setActiveCampaignsCurrentPage",
      "setPausedCampaignsCurrentPage",
      "setFilters",
      "findTotalAgentsPerCampaign",
    ]),

    changePage(page) {
      if (this.activeTab === "activeCampaigns") {
        this.setActiveCampaignsCurrentPage(page);
        this.loadCampaigns("active");
      } else if (this.activeTab === "paused") {
        this.setPausedCampaignsCurrentPage(page);
        this.loadCampaigns("paused");
      }
    },

    search: _.debounce(function (term) {
      this.setFilters({
        start_date: this.start_date,
        end_date: this.end_date,
        search: term,
      });

      this.setActiveCampaignsCurrentPage(1);
      this.loadCampaigns("active");

      this.setPausedCampaignsCurrentPage(1);
      this.loadCampaigns("paused");
    }, 300),

    hideDropDown(event, campaign_id) {
      let dropdown = document.getElementById(campaign_id);
      if (dropdown.classList.contains("show")) {
        dropdown.style.opacity = 0;

        /**
         *  This timeout is necessary for the transition to work properly,
         *  the attributes will be removed after the timeout is triggered.
         *  The transition wouldn't work without it.
         */
        setTimeout(() => {
          dropdown.removeAttribute("data-popper-placement");
          dropdown.classList.remove("show");
          dropdown.removeAttribute("style");
        }, 500);

        let campaignNameElement = document.querySelector("." + event.target.classList.toString().split(" ").join("."));
        campaignNameElement.classList.remove("show");
      }
    },
  },

  selectedCampaign(campaign) {
    this.stopUpdateRealTimeCalls();
    this.stopAgentsRealTimeUpdate();
    if (campaign) {
      campaign.total_campaign ? this.startUpdateAllRealTimeCalls({ campaign }) : this.startUpdateRealTimeCalls({ campaign });

      campaign.total_campaign ? this.startAllAgentsRealTimeUpdate({
        campaign,
        start_date: this.start_date,
        end_date: this.end_date,
      }) : this.startAgentsRealTimeUpdate({ campaign });
    }
  },

  watch: {
    activeCampaigns: {
      handler(data) {
        this.sortedActiveCampaigns = Object.values(data).sort((campaignA, campaignB) => {
          const agentOnlineCampaignA = Object.values(campaignA.campaign_information.quantity_of_agents_with_online_status).length;
          const agentOnlineCampaignB = Object.values(campaignB.campaign_information.quantity_of_agents_with_online_status).length;
          return agentOnlineCampaignB - agentOnlineCampaignA;
        });
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.campaign-status {
  color: $color-text-200 !important;
}

tr {
  td {
    .dropdown-toggle:after {
      display: none !important;
    }

    .dropdown-menu {
      transition: .25s opacity, .25s height;
      transform: translate3d(-75px, -40px, 0px) !important;
    }

    padding-top: 6px;
    padding-bottom: 6px;
  }

  // Class added when a campaign does not have online agents
  &.not-active-campaign {
    color: $color-text-gray;
  }

  span.fe.fe-chevron-right {
    visibility: hidden;
  }

  &:hover {
    background-color: #eaf2fd;

    span.fe.fe-chevron-right {
      visibility: visible;
    }
  }

  .campaign-status {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background-color: var(--color-gray-200);


    &.active {
      background-color: var(--color-blue-300);
      animation-name: active-campaign;
      animation-duration: 0.8s;
      animation-iteration-count: infinite;
      animation-direction: normal;
    }

    @keyframes active-campaign {
      from {
        box-shadow: none;
      }

      to {
        box-shadow: 0 0 0 3px #7E9CF7;
      }
    }
  }
}
</style>
