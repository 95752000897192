<template>
  <modal-component
    :show="showModal"
    @close="closeModal"
    :center="true"
    size="modal-751-lg"
  >
    <template #content>
      <div class="px-4">
        <div
          class="modal-header p-0 py-4 position-relative d-flex flex-row text-center align-items-center justify-content-center"
        >
          <h3
            class="modal-title"
            id="modalDownloadCSVLabel"
          >
            {{ $t("generate_report") }}
          </h3>
          <button
            ref="closeModalButton"
            type="button"
            class="btn-close position-absolute mt-2 fs-2 top-0 end-0"
            aria-label="Close"
            @click="closeModal"
            data-test="generateCsvModalClose-button"
          />
        </div>
        <div class="modal-body p-0 pt-4">
          <p class="p-0 text-center">
            {{ $t("send_report_to_email") }}
          </p>
          <form
            @submit.prevent="generateCSV"
            id="formCSV"
          >
            <div
              class="d-flex justify-content-around"
              style="margin: 2rem 0;"
            >
              <div>
                <input
                  class="form-check-input mx-2"
                  type="radio"
                  name="flexRadioDefault"
                  id="simplificado"
                  :value="1"
                  v-model="reportOption"
                  data-test="generateCsvOption1-input"
                >
                <label
                  class="form-check-label text-large fw-medium"
                  for="simplificado"
                >
                  {{ $t("simplified") }}
                </label>
              </div>
              <div>
                <input
                  class="form-check-input mx-2"
                  type="radio"
                  name="flexRadioDefault"
                  id="customizado"
                  :value="2"
                  v-model="reportOption"
                  data-test="generateCsvOption2-input"
                >
                <label
                  class="form-check-label text-large fw-medium"
                  for="customizado"
                >
                  {{ $t("customized") }}
                </label>
              </div>
              <div>
                <input
                  class="form-check-input mx-2"
                  type="radio"
                  name="flexRadioDefault"
                  id="completo"
                  :value="3"
                  v-model="reportOption"
                  data-test="generateCsvOption3-input"
                >
                <label
                  class="form-check-label text-large fw-medium"
                  for="completo"
                >
                  {{ $t("completed") }}
                </label>
              </div>
            </div>
            <div
              class="border rounded"
              :class="reportOption === 2 ? 'p-4 pb-0' : 'align-items-center d-flex'"
              :style="{
                margin: '2rem 0',
                padding: reportOption === 2 ? '' : '8px 16px',
                minHeight: '40px'
              }"
            >
              <div v-if="reportOption === 1">
                <h5>
                  {{ $t("simplified") }}
                </h5>
                <p class="m-0 fs-6">
                  {{ $t("report_options_simplified") }}
                </p>
              </div>
              <div v-if="reportOption === 2">
                <div
                  v-for="(field, index) in reportCustomizedFields"
                  :key="field"
                  class="mb-4"
                >
                  <h5
                    class="gap-2 d-flex"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#' + 'collapse' + index"
                    :aria-controls="'collapse' + index"
                    @click="field.isExpandeed = !field.isExpandeed"
                  >
                    {{ field.title }}
                    <i
                      :class="field.isExpandeed ? 'fe fe-chevron-up' : 'fe fe-chevron-down'"
                      class="fs-3 text-secondary"
                    />
                  </h5>
                  <div
                    class="d-flex col-12 collapse show"
                    :id="'collapse' + index"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordion"
                  >
                    <div class="d-flex col-12">
                      <div
                        v-for="column in 3"
                        :key="column"
                        class="col-4 d-flex flex-column gap-2"
                      >
                        <div
                          v-for="fieldSliced in reportCustomizedFieldsSliced({
                            currentColumn: column,
                            arrayToSlice: field.options
                          })"
                          :key="fieldSliced"
                        >
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="flexRadioDefault"
                              :id="fieldSliced.key"
                              v-model="fieldSliced.value"
                              :data-test="`generateCsvOption2Field${fieldSliced.key}-input`"
                            >
                            <label
                              class="form-check-label fs-6"
                              :for="fieldSliced.key"
                            >
                              {{ fieldSliced.title }}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="w-100"
                v-if="reportOption === 3"
              >
                <div
                  class="w-100 d-flex align-content-between justify-content-between cursor-pointer"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse"
                  aria-expanded="false"
                  :aria-controls="'collapse'"
                  @click="showReportOption3 = !showReportOption3"
                >
                  <h5 class="m-1 d-flex gap-3">
                    {{ $t("completed") }}
                  </h5>
                  <i :class="showReportOption3 ? 'fe fe-chevron-up' : 'fe fe-chevron-down'" />
                </div>
                <div
                  :id="'collapse'"
                  class="collapse"
                  :class="true ? '' : 'show'"
                >
                  <p class="m-0 mt-2 fs-6">
                    {{ $t("report_options_completed") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="mb-4">
              <label
                for="input-email"
                class="col-form-label pt-0"
              >{{ $t("report_email") }}</label>
              <input
                class="form-control"
                :class="{
                  'is-invalid': errorsGenerateCsv?.email
                }"
                id="input-email"
                v-model="email"
                data-test="generateCsvEmail-input"
              >
              <div class="invalid-feedback">
                {{ errorsGenerateCsv?.email && errorsGenerateCsv?.email[0] }}
              </div>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="withMailing"
                v-model="withMailing"
                data-test="generateCsvWithMailing-input"
              >
              <label
                class="form-check-label"
                for="withMailing"
              >
                {{ $t("include_mailing_data") }}
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="withNotAnsweredCalls"
                v-model="withNotAnsweredCalls"
                data-test="generateCsvWithNotAnsweredCalls-input"
              >
              <label
                class="form-check-label"
                for="withNotAnsweredCalls"
              >
                {{ $t("include_missing_calls") }}
              </label>
            </div>
            <div
              class="form-check d-flex align-items-center gap-2"
              v-if="user?.company?.enabled_download_audios_in_batch"
            >
              <div>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="withDownloadAudios"
                  v-model="withDownloadAudios"
                  data-test="generateCsvwithDownloadAudios-input"
                  :disabled="!enableDownloadAudioButton()"
                >
                <label
                  class="form-check-label"
                  for="withDownloadAudios"
                >
                  {{ $t("download_audios") }}
                </label>
              </div>
              <span
                ref="tooltip"
                class="fe fe-info tx-blue-300"
              />
            </div>
            <app-button
              type="submit"
              class="w-100"
              form="formCSV"
              :loading="loading['downloadTable']"
              style="margin-top: 2rem;"
              data-test="generateCsvSubmit-button"
            >
              {{ $t("generate_csv") }}
            </app-button>
            <div class="w-100 text-center my-3">
              <app-button
                type="button"
                class="text-muted"
                :color="'btn-link'"
                @click="closeModal"
                data-test="generateCsvCancel-button"
              >
                {{ $t("cancel_general") }}
              </app-button>
            </div>
          </form>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalComponent from "@/components/modal-component.vue";
import AppButton from "@components/app-button";
import { Tooltip } from "bootstrap";
import { left } from "@popperjs/core";

export default {
  emits: ["close", "prepare-filters"],

  data() {
    return {
      email: "",
      withMailing: false,
      withNotAnsweredCalls: false,
      withDownloadAudios: false,
      reportOption: 1,
      showReportOption3: false,
      tooltip: null,
      reportCustomizedFields: [
        {
          title: this.$t("main"),
          isExpanded: true,
          options: [
            {
              key: "agent_name",
              title: this.$t("agent_capitalize"),
              value: true,
            },
            {
              key: "campaign_name",
              title: this.$t("campaign_capitalize"),
              value: true,
            },
            {
              key: "behavior",
              title: this.$t("behavior"),
              value: true,
            },
            {
              key: "call_date",
              title: this.$t("initial_date"),
              value: true,
            },
            {
              key: "mailing_identifier",
              title: this.$t("identifier"),
              value: true,
            },
            {
              key: "recording",
              title: this.$t("record_link"),
              value: true,
            },
            {
              key: "list_name",
              title: this.$t("list"),
              value: true,
            },
            {
              key: "mode",
              title: this.$t("call_mode"),
              value: true,
            },
            {
              key: "sid_info",
              title: this.$t("protocol"),
              value: true,
            },
            {
              key: "qualification_name",
              title: this.$t("qualification"),
              value: true,
            },
            {
              key: "readable_status_text",
              title: this.$t("call_status"),
              value: true,
            },
            {
              key: "number_info",
              title: this.$t("phone"),
              value: true,
            },
            {
              key: "speaking_with_agent_time",
              title: this.$t("time_talking_to_agent"),
              value: true,
            },
          ],
        },
        {
          title: this.$t("calls_capitalizes"),
          isExpandeed: true,
          options: [
            {
              key: "readable_hangup_cause_text",
              title: this.$t("hangup_reason"),
              value: false,
            },
            {
              key: "hangup_cause_status",
              title: this.$t("hangup_reason_id"),
              value: false,
            },
            {
              key: "call_date_rfc3339",
              title: this.$t("final_date_call"),
              value: false,
            },
            {
              key: "ended_by_agent",
              title: this.$t("hangup_by_agent"),
              value: false,
            },
            {
              key: "waiting_time",
              title: this.$t("list_metrics_on_hold"),
              value: false,
            },
            {
              key: "acw_time",
              title: this.$t("acronym.act"),
              value: false,
            },
            {
              key: "feedback",
              title: "Feedback",
              value: false,
            },
            {
              key: "recording_amd",
              title: this.$t("amd_record"),
              value: false,
            },
            {
              key: "recorded",
              title: this.$t("record"),
              value: false,
            },
            {
              key: "id",
              title: this.$t("system_identifier"),
              value: false,
            },
            {
              key: "campaign_id",
              title: this.$t("campaign_id"),
              value: false,
            },
            {
              key: "status_id",
              title: this.$t("status_call_id"),
              value: false,
            },
            {
              key: "qualification_note",
              title: this.$t("observation"),
              value: false,
            },
            {
              key: "readable_amd_status_text",
              title: this.$t("amd_status"),
              value: false,
            },
            {
              key: "speaking_time",
              title: this.$t("conversation_time"),
              value: false,
            },
            {
              key: "amd_time",
              title: this.$t("detection_time"),
              value: false,
            },
            {
              key: "readable_behavior_text",
              title: this.$t("behavior_name"),
              value: false,
            },
            {
              key: "phone_type",
              title: this.$t("phone_type"),
              value: false,
            },
          ],
        },
        {
          title: this.$t("receptive_capitalize"),
          isExpandeed: true,
          options: [
            {
              key: "receptive_did",
              title: this.$t("receptive_bina"),
              value: false,
            },
            {
              key: "consults",
              title: this.$t("consult"),
              value: false,
            },
            {
              key: "queue_name",
              title: this.$t("list"),
              value: false,
            },
            {
              key: "recording_consult",
              title: this.$t("consult_recording"),
              value: false,
            },
            {
              key: "recording_transfer",
              title: this.$t("transfer_recording"),
              value: false,
            },
            {
              key: "consult_ids",
              title: this.$t("consult_id"),
              value: false,
            },
            {
              key: "transfer_id",
              title: this.$t("transfer_id"),
              value: false,
            },
            {
              key: "receptive_name",
              title: this.$t("receptive_queue_name"),
              value: false,
            },
            {
              key: "receptive_phone",
              title: this.$t("receptive_phone"),
              value: false,
            },
            {
              key: "transfer",
              title: this.$t("transfer_capitalize"),
              value: false,
            },
            {
              key: "ring_group_name",
              title: this.$t("ring_group_name"),
              value: false,
            },
          ],
        },
        {
          title: this.$t("ivr_uppercase"),
          isExpandeed: true,
          options: [
            {
              key: "recording_after_consult_cancel",
              title: this.$t("post_service_IVR"),
              value: false,
            },
            {
              key: "ivr_digit_pressed",
              title: this.$t("ivr_key"),
              value: false,
            },
            {
              key: "ivr_name",
              title: this.$t("receptive_IVR_capitalize"),
              value: false,
            },
            {
              key: "ivr_after_call",
              title: this.$t("IVR_after_call_capitalize"),
              value: false,
            },
            {
              key: "ivr_time",
              title: this.$t("ivr_time"),
              value: false,
            },
          ],
        },
        {
          title: this.$t("route_capitalize"),
          isExpandeed: true,
          options: [
            {
              key: "route_id",
              title: this.$t("route_id"),
              value: false,
            },
            {
              key: "route_name",
              title: this.$t("route_name"),
              value: false,
            },
            {
              key: "billed_time",
              title: this.$t("billed_time"),
              value: false,
            },
            {
              key: "billed_value",
              title: this.$t("billed_value"),
              value: false,
            },
          ],
        },
      ],
    };
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser",
    }),

    ...mapGetters("manager/calls-report", {
      loading: "getLoadingFlags",
      errorsFlag: "getErrorsFlag",
    }),

    errorsGenerateCsv() {
      return this.errorsFlag.downloadTable;
    },
  },

  mounted() {
    this.email = this.user.email;
    if (this.user.company.enabled_download_audios_in_batch) {
      this.generateTooltip();
    }
  },

  components: {
    ModalComponent,
    AppButton,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    enableDownloadAudioButton: {
      type: Function,
      default() {
        return false;
      },
    },
  },

  methods: {
    ...mapActions("manager/calls-report", ["downloadTable", "setDownloadTableParams"]),

    closeModal() {
      this.$emit("close");
    },

    reportCustomizedFieldsSliced({ currentColumn, arrayToSlice }) {
      const colums = 3;
      const reportOptionsTotal = arrayToSlice.length;
      const itemsPerSection = Math.ceil(reportOptionsTotal / colums);

      let initialSlice = 0;

      if (currentColumn > 1) initialSlice = (currentColumn - 1) * itemsPerSection;

      const endSlice = currentColumn * itemsPerSection;

      let arraySliced = arrayToSlice.slice(initialSlice, endSlice);

      if (arraySliced.length * currentColumn >= reportOptionsTotal && currentColumn < colums) {
        const newArray = arraySliced;
        newArray.pop();
        arraySliced = newArray;
      } else if (!arraySliced.length) arraySliced = [arrayToSlice[arrayToSlice.length - 1]];

      return arraySliced;
    },

    generateCSV() {
      this.$emit("prepare-filters");
      let params = {
        report_option: this.reportOption,
        email: this.email,
        with_mailing: this.withMailing,
        with_not_answered_calls: this.withNotAnsweredCalls,
        download_audio_in_batch: Number(this.withDownloadAudios),
      };

      if (this.reportOption === 2) {
        let optionsHash = {};
        this.reportCustomizedFields.forEach(reportOption => {
          reportOption.options.forEach(option => {
            optionsHash[option.key] = option.value ? 1 : 0;
          });
        });
        params = { ...params, ...optionsHash };
      }

      this.setDownloadTableParams(params);

      this.downloadTable()
          .then(() => this.closeModal());
    },

    generateTooltip() {
      let tooltip = document.createElement("div");
      tooltip.innerHTML = `
        <div>
          ${this.$t("tooltips.calls_report_modal_tooltip")}
        </div>`;
      tooltip.style.textAlign = left;
      this.tooltip = new Tooltip(this.$refs.tooltip, {
        html: true,
        placement: "top",
        title: tooltip,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-check-input[type="checkbox"] {
  border-radius: 6px !important;
}

.collapse:not(.show) {
  display: none !important;
}
</style>
