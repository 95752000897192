import axios from "axios";
import { AgentStatus, CallStatus, CallMode } from "@/utils";

export default {
  updateRingGroupStatus({ commit, dispatch }, isActive) {
    commit("startLoadingFlag", "updateRingGroupStatus");

    return new Promise((resolve, reject) => {
      axios
        .patch("/ring-groups/activation-control", { is_active: isActive })
        .then(async () => {
          await dispatch("auth/me", {}, { root: true });
          resolve();
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => commit("stopLoadingFlag", "updateRingGroupStatus"));
    });
  },

  fetchRingGroupUsers({ commit }) {
    commit("startLoadingFlag", "fetchRingGroupUsers");

    return new Promise((resolve, reject) => {
      axios
        .get("/ring-groups/users")
        .then(response => {
          commit("setRingGroupUsers", response.data.data);
          resolve();
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => commit("stopLoadingFlag", "fetchRingGroupUsers"));
    });
  },

  updateCallToDialingStatus({ commit }, session) {
    // Create a minimum call object to show only the phone number to the user
    let phone = session.remote_identity.uri.user;
    if (phone.startsWith("#")) {
      phone = phone.substring(1);
    }

    const call = {
      phone,
      status: CallStatus.DIALING,
      call_mode: "manual"
    };

    commit("system/setStatus", AgentStatus.ON_CALL, { root: true });
    commit("setCallPABX", call);
  },

  handlePABXCallInProgress({ commit }, session) {
    const call = {
      phone: session.remote_identity.uri.user
    };

    call.status = CallStatus.RECEIVING_EXTERNAL_CALL;

    commit("system/setStatus", AgentStatus.ON_CALL, { root: true });
    commit("system/setMode", CallMode.RECEPTIVE, { root: true });
    commit("setCallPABX", call);
  },

  updateCallStatusAfterCallPABXWasAnswered({ commit }, event) {
    const { call } = event;
    commit("setCallPABX", call);
  },

  handlePABXCallEnded({ commit }) {
    commit("system/setMode", "", { root: true });
    commit("system/setStatus", AgentStatus.OFFLINE, { root: true });
    commit("system/setMuted", false, { root: true });
    commit("setConsultedAgentId", null);
    commit("setInternalCallAgent", null);
    commit("setCallPABX", null);
  },

  /**
   * Triggers
   */

  /**
   * This action just resolve a promise immediately because it needs
   * to perform something to trigger the subscribeAction in the jssip file.
   * In this case this action will answer the incoming call.
   * @returns {Promise}
   */
  answerCallPABX() {
    return new Promise(resolve => {
      resolve();
    });
  },

  makeCallPABX(store, phoneNumber) {
    return new Promise(resolve => {
      resolve(phoneNumber);
    });
  },

  hangupCallPABX() {
    return new Promise(resolve => {
      resolve();
    });
  },

  startInternalCall({ commit, getters }, extension_number) {
    let user;

    for (const ringGroup of getters.getRingGroupUsers) {
      user = ringGroup.users.find(user => user.extension_number == extension_number.replace("#", ""));
      if (user) break;
    }

    commit("setInternalCallAgent", user);

    return new Promise(resolve => {
      resolve(extension_number);
    });
  },

  consultAgent({ commit }, consultedId) {
    commit("startLoadingFlag", "consultAgent");
    commit("setCallStatus", CallStatus.CONSULT_TRYING);
    commit("setConsultedAgentId", consultedId);

    return new Promise((resolve, reject) => {
      axios
        .post("/agent/consult", { consultedId, is_ring_group: true })
        .then(() => {
          resolve();
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          commit("setCallStatus", CallStatus.CONNECTED);
          commit("setConsultedAgentId", null);
          commit("stopLoadingFlag", "consultAgent");
          reject();
        });
    });
  },

  cancelConsultPABX({ commit }) {
    commit("startLoadingFlag", "cancelConsultPABX");
    return new Promise((resolve, reject) => {
      axios
        .post("/agent/consult/cancel", { is_ring_group: true })
        .then(() => {
          resolve();
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => commit("stopLoadingFlag", "cancelConsultPABX"));
    });
  },

  exitConsultPABX({ commit }) {
    commit("startLoadingFlag", "exitConsultPABX");
    return new Promise((resolve, reject) => {
      axios
        .post("/agent/consult/exit", { is_ring_group: true })
        .then(() => {
          resolve();
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          commit("stopLoadingFlag", "exitConsultPABX");
          reject();
        });
    });
  },

  transferToAgentPABX({ commit }, consultedId) {
    commit("startLoadingFlag", "transferToAgentPABX");
    return new Promise((resolve, reject) => {
      axios
        .post("/agent/consult/transfer", { consultedId, is_ring_group: true })
        .then(() => {
          commit("system/setMode", "", { root: true });
          commit("system/setStatus", AgentStatus.OFFLINE, { root: true });
          commit("system/setMuted", false, { root: true });
          commit("setConsultedAgentId", null);
          commit("setCallPABX", null);
          resolve();
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => commit("stopLoadingFlag", "transferToAgentPABX"));
    });
  },

  setShowToast({ commit }, showToast) {
    commit("setShowToast", showToast);
  }
};
