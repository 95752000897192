import jsSip from "jssip";
import { pabx } from "./pabx";
import { dialer } from "./dialer";
import timer from "@/plugins/timer";
import store from "@/store";
import { Utils } from "@/utils";

export default function createJsSipPlugin() {
  const startTimer = (seconds) => {
    let options = {
      startValues: {
        seconds,
      },
    };

    store.commit("system/setTimeAsString", Utils.timeFormat(seconds));
    timer.stop();
    timer.start(options)
  }


  return store => {
    var ua = null;
    var session = null;

    // disconnect SIP when page unload
    window.addEventListener("beforeunload", function () {
      if (session) session.terminate();
      if (ua) {
        ua.unregister();
        ua.stop();
      }
      ua = null;
      session = null;
    });

    const userHasAccessToPABX = () => {
      return (
        store.getters["agent/ring_group/isRingGroupPermissionEnabled"] &&
        store.getters["agent/ring_group/isRingGroupActive"]
      );
    };

    var connect = () => {

      let user = store.getters["auth/getUser"]
      let host = user.company.domain + "." + process.env.VUE_APP_BA34ZX;
      let socket = new jsSip.WebSocketInterface("wss://" + host + ":4443")

      let wait_time_on_call = 60;
      if (user.ring_groups && user.ring_groups.length > 0) {
        wait_time_on_call = user.ring_groups[0].wait_time_on_call;
      }

      let configuration = {
        sockets: [socket],
        uri: "sip:" + user.telephony_id + "@" + host,
        password: user.extension_password,
        register: true,
        register_expires: 30,
        session_timers: false,
        no_answer_timeout: wait_time_on_call,
      };

      ua = new jsSip.UA(configuration);

      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(() => {
          store.commit("system/setUserMediaPermission", "allowed");
          console.log("[UserMedia] Permission is granted!");
          ua.start();
        })
        .catch(() => {
          store.commit("system/setUserMediaPermission", "not-allowed");
          console.log("[UserMedia] Not allowed");
          store.commit("system/setFlashMessage", {
            type: "danger",
            message:
              "Você não possui microfone ou o seu navegador não possui permissão para utiliza-lo.",
          });
        });

      //attemp to connect
      ua.on("connecting", () => {
        store.commit("system/setJsSipStatus", "disconnected");
        store.commit("system/startLoadingFlag", "JsSIPConnecting");
        console.log("[JsSip] Connecting...");
      });

      //connect event
      ua.on("connected", () => {
        store.commit("system/setJsSipStatus", "connected");
        store.commit("system/stopLoadingFlag", "JsSIPConnecting");
        store.commit("system/startLoadingFlag", "JsSIPRegistering");
        console.log("[JsSip] Connected!");
      });

      //disconnect
      ua.on("disconnected", () => {
        store.commit("system/setJsSipStatus", "disconnected");
        store.commit("system/stopLoadingFlag", "JsSipDisconnecting");
        console.log("[JsSip] Disconnected!");
      });

      //unregister
      ua.on("unregistered", () => {
        store.commit("system/setJsSipStatus", "connected");
        store.commit("system/stopLoadingFlag", "JsSipUnregistering");
        store.commit("system/startLoadingFlag", "JsSipDisconnecting");

        // remove the agent when the manager leaves the page without closing the spy
        store.commit("system/setSpiedAgent", null);
        store.commit("system/setSelectedAgentToSpy", null)
        console.log("[JsSip] Unregistered!");
      });

      ua.on("registered", () => {
        store.commit("system/setJsSipStatus", "registered");
        store.commit("system/stopLoadingFlag", "JsSIPRegistering");
        store.commit("system/setRegistrationAttemp", 0);
        console.log("[JsSip] Registered!");
      });

      ua.on("registrationFailed", e => {
        console.log("[JsSip] registrationFailed", e);
        setTimeout(() => {
          if (ua.isConnected() && !ua.isRegistered()) {
            ua.register();
            console.log("trying register...");
          }
        }, 5000);
        store.commit("system/incRegistrationAttemp");
        store.commit(
          "system/setJsSipStatus",
          ua.isConnected() ? "connected" : "disconnected",
        );
      });

      ua.on("newRTCSession", data => {
        console.log("[JsSip] newRTCSession", data);
        session = data.session;

        if (
          ["dialer", "manual"].includes(store.getters["system/getMode"]) ||
          store.getters["auth/isManager"] ||
          store.getters["auth/isSupervisor"]
        ) {
          dialer(session);
          return;
        }

        if (userHasAccessToPABX()) {
          pabx(session);
        }
      });

      ua.on("ended", () => {
        console.log("[JsSip] ended");
      });
    };

    /**
     * Watch mutations
     */
    store.subscribe(mutation => {
      if (mutation.type == "system/setJsSIPActive") {
        if (store.getters["auth/isExternalSipActive"]) return;

        if (mutation.payload) {
          connect();
        } else {
          if (store.getters["system/getSpiedAgent"])
            store.dispatch("system/stopAgentSpy");

          if (session && session.isEstablished()) session.terminate();
          if (ua) {
            if (ua.isRegistered())
              store.commit("system/startLoadingFlag", "JsSipUnregistering");
            else store.commit("system/startLoadingFlag", "JsSipDisconnecting");
            ua.stop();
          }
        }
      } else if (mutation.type == "system/setMuted") {
        if (session) {
          if (mutation.payload) {
            session.mute();
          } else {
            session.unmute();
          }
        } else {
          store.commit("system/setJsSipStatus", "disconnected");
        }
      } else if (mutation.type == "system/sendDTMF") {
        session.sendDTMF(mutation.payload, {
          duration: 100,
          interToneGap: 70,
        });
      } else if (mutation.type == "auth/endAllRealTimeSessions") {
        if (ua) {
          ua.unregister();
          ua.stop();
        }
      } else if (mutation.type == "agent/ring_group/setCallPABX" || mutation.type === "agent/ring_group/setCallStatus") {
        startTimer(0);
      }
    });

    store.subscribeAction(action => {
      if (action.type === "agent/ring_group/makeCallPABX" || action.type === "agent/ring_group/startInternalCall") {
        const extensionNumber = action.payload;
        if (extensionNumber) {
          let user = store.getters["auth/getUser"];
          const targetUri = `sip:${extensionNumber}@${user.company.domain}.${process.env.VUE_APP_BA34ZX}`;

          ua.call(targetUri, {
            mediaConstraints: { audio: true, video: false },
          });
        }
      }


      if (action.type === "agent/ring_group/answerCallPABX") {
        session.answer({
          mediaConstraints: {
            audio: true,
            video: false,
          },
        });
      }

      if (action.type === "agent/ring_group/hangupCallPABX") {
        session.terminate();
      }
    });
  };
}
