<template>
  <modal-component
    @close="showModal = false"
    :show="showModal"
    :center="true"
    size="modal-lg"
  >
    <template
      #content
    >
      <div
        class="modal-dialog modal-dialog-centered m-0"
        style="max-width: 100%;"
      >
        <div class="modal-content">
          <div class="modal-header border-0 m-4 p-0">
            <h2 class="w-100 text-center m-0">
              Adicionar Tronco SIP
            </h2>
            <button
              type="button me-2"
              class="close-button"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i
                class="fa-solid fa-x pb-3 tx-text-gray"
                style="font-size: 10px;"
              />
            </button>
          </div>
          <hr
            class="m-0"
            style="color:$color-gray-blue-300;"
          >
          <div
            class="modal-body"
            style="padding: 32px;"
          >
            <form
              class="row"
              @submit.prevent="submit"
            >
              <div class="col-12">
                <select
                  class="form-select"
                  v-model="authentication_type"
                >
                  <option
                    disabled
                    value=""
                  >
                    Tipo de Autenticação
                  </option>
                  <option
                    :key="index"
                    :value="authentication.value"
                    v-for="(authentication, index) in authentications"
                  >
                    {{ authentication.text }}
                  </option>
                </select>
              </div>
              <div class="col-xs-12">
                <div class="form-group m-0">
                  <div
                    class="mt-5"
                    v-if="authentication_type === 'ip_tech'"
                  >
                    <div class="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        class="form-label required"
                        placeholder="Nome_da_rota"
                      >Nome da rota <span class="tx-text-gray">(domínio_fornecedor)</span></label>
                      <input
                        class="form-control"
                        id="exampleFormControlInput1"
                        v-model="ipTech.endpoint"
                        :class="{ 'is-invalid': errors.endpoint || whiteSpace }"
                        placeholder="domínio_fornecedor"
                        @change="hasWhiteSpace($event)"
                      >
                      <small
                        v-if="errors.endpoint"
                        class="text-danger"
                      >
                        {{ errors.endpoint[0] }}
                      </small>
                      <div
                        v-if="whiteSpace"
                        class="text-end"
                      >
                        <small
                          class="text-danger"
                        >
                          Espaçamento apenas por “_”. Ex: dominio_fornecedor
                        </small>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <div class="col">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label required"
                        >IP <span class="tx-text-gray">(Servidor SIP)</span></label>
                        <input
                          class="form-control"
                          id="exampleFormControlInput1"
                          v-model="ipTech.ip"
                          v-maska
                          data-maska="#00.#00.#00.#00"
                          data-maska-tokens="0:[0-9]:optional"
                          :class="{ 'is-invalid': errors.ip }"
                        >
                        <small
                          v-if="errors.ip"
                          class="text-danger"
                        >
                          {{ errors.ip[0] }}
                        </small>
                      </div>
                      <div class="col">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label required"
                        >Porta <span class="tx-text-gray">(Servidor SIP)</span></label>
                        <input
                          class="form-control"
                          id="exampleFormControlInput1"
                          v-model="ipTech.port"
                          v-maska
                          data-maska="####"
                          :class="{ 'is-invalid': errors.port }"
                        >
                        <small
                          v-if="errors.port"
                          class="text-danger"
                        >
                          {{ errors.port[0] }}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div
                    class="mt-5"
                    v-if="authentication_type == 'user_pass'"
                  >
                    <div class="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        class="form-label required"
                      >Nome da rota <span class="tx-text-gray">(domínio_fornecedor)</span></label>
                      <input
                        class="form-control"
                        id="exampleFormControlInput1"
                        v-model="userPass.endpoint"
                        :class="{ 'is-invalid': errors.endpoint || whiteSpace }"
                        placeholder="domínio_fornecedor"
                        @change="hasWhiteSpace($event)"
                      >
                      <small
                        v-if="errors.endpoint"
                        class="text-danger"
                      >
                        {{ errors.endpoint[0] }}
                      </small>
                      <div
                        v-if="whiteSpace"
                        class="text-end"
                      >
                        <small
                          class="text-danger"
                        >
                          Espaçamento apenas por “_”. Ex: dominio_fornecedor
                        </small>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <div class="col">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label required"
                        >IP <span class="tx-text-gray">(Servidor SIP)</span></label>
                        <input
                          class="form-control"
                          id="exampleFormControlInput1"
                          v-model="userPass.ip"
                          v-maska
                          data-maska="#00.#00.#00.#00"
                          data-maska-tokens="0:[0-9]:optional"
                          :class="{ 'is-invalid': errors.ip }"
                        >
                        <small
                          v-if="errors.ip"
                          class="text-danger"
                        >
                          {{ errors.ip[0] }}
                        </small>
                      </div>
                      <div class="col">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label required"
                        >Porta <span class="tx-text-gray">(Servidor SIP)</span></label>
                        <input
                          class="form-control"
                          id="exampleFormControlInput1"
                          v-model="userPass.port"
                          v-maska
                          data-maska="####"
                          :class="{ 'is-invalid': errors.port }"
                        >
                        <small
                          v-if="errors.port"
                          class="text-danger"
                        >
                          {{ errors.port[0] }}
                        </small>
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <div class="col">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label required"
                        >Usuário SIP</label>
                        <input
                          class="form-control"
                          id="exampleFormControlInput1"
                          v-model="userPass.sip_username"
                          :class="{ 'is-invalid': errors.sip_username }"
                        >
                        <small
                          v-if="errors.sip_username"
                          class="text-danger"
                        >
                          {{ errors.sip_username[0] }}
                        </small>
                      </div>
                      <div class="col">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label required"
                        >Senha</label>
                        <div class="input-group input-group-merge">
                          <input
                            class="form-control"
                            :type="showPass ? 'text' : 'password'"
                            id="exampleFormControlInput1"
                            v-model="userPass.sip_password"
                            :class="{ 'is-invalid': errors.sip_password }"
                          >
                          <div
                            class="input-group-text rounded-end"
                            id="inputGroupInvalid"
                            @click="showPass = !showPass"
                          >
                            <i
                              class="fe fe-eye"
                              v-if="!showPass"
                            />
                            <i
                              class="fe fe-eye-off"
                              v-else
                            />
                          </div>
                        </div>
                        <small
                          v-if="errors.sip_password"
                          class="text-danger"
                        >
                          {{ errors.sip_password[0] }}
                        </small>
                      </div>
                    </div>
                  </div>

                  <div class="text-center">
                    <button
                      class="btn btn-primary h5 mb-0 mt-4"
                      :disabled="disableButton"
                      style="border: none; border-radius: 10px; width: 237px;"
                    >
                      Adicionar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import ModalComponent from "@/components/modal-component.vue";

export default {

  components: {
    ModalComponent
  },

  data() {
    return {
      showModal: false,
      authentications: [
        { text: 'IP + TECH', value: 'ip_tech' },
        { text: 'Usuário e senha', value: 'user_pass' }
      ],
      authentication_type: '',
      ipTech: {
        endpoint: '',
        ip: '',
        port: 5060
      },
      userPass: {
        endpoint: '',
        ip: '',
        port: 5060,
        sip_username: '',
        sip_password: ''
      },
      whiteSpace: false,
      showPass: false,
    };
  },

  computed: {
    ...mapGetters("admin/telecom", {
      loadingFlags: "getLoadingFlags",
      errors: "getErrors"
    }),

    disableButton() {
      if (this.ipTech.endpoint && this.ipTech.ip && this.ipTech.port) {
        return false
      } else if (this.userPass.endpoint && this.userPass.ip && this.userPass.port && this.userPass.sip_username && this.userPass.sip_password) {
        return false
      } else {
        return true
      }
    },
  },

  methods: {
    ...mapActions("admin/telecom", [
      "createRoute"
    ]),

    ...mapMutations("admin/telecom", [
      "clearErrors"
    ]),

    ...mapMutations("auth", ["trackEvents"]),

    submit() {
      if (this.authentication_type == 'ip_tech') {
        this.fields = this.ipTech;
      } else {
        this.fields = this.userPass;
      }
      this.fields['authentication_type'] = this.authentication_type;

      this.createRoute(this.fields).then(() => {
        this.ipTech = {};
        this.userPass = {}
        this.showModal = false;
      })
      this.trackEvents("[Admin] Added Telephony");
    },

    openModal() {
      this.showModal = true;
    },

    hasWhiteSpace(event) {
      let routeName = event.target.value.trim()
      if (routeName.match(/\s/g)){
        return this.whiteSpace = true
      }
      return this.whiteSpace = false
    }
  },
};
</script>

<style lang="scss" scoped>

.close-button {
  border: none;
  background-color: transparent;
}

.auth-item {
  padding: 10px 16px;
  border-radius: $border-radius;
  &:hover {
    background-color: $color-gray-blue-300;
  }
  &.active {
    background-color: var(--color-gray-100);
  }
}

button {
  &:disabled {
    color: $color-text-gray;
    background-color: $color-gray-blue-300;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}


</style>
