<template>
  <div class="col-12">
    <div class="card p-0">
      <div
        class="card-body d-flex flex-column justify-content-center align-items-center text-center gap-3"
        style="height: 463px"
      >
        <img
          src="@/assets/img/aloisio-search.svg"
          :alt="$t('search_to_view_chats')"
        >
        <p>{{ $t('search_to_view_chats') }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TableBeforeSearch"
}
</script>
