export default {
  getDetailsPartner: state => state.detailsPartner,
  getListPartners: state => state.listPartners,
  getLoadingFlags: state => state.loadingFlags,

  getCompanyDetails: state => state.companyDetails,
  getPartnerDetailsPagination: state => state.partnerDetailsPagination,
  getRevenue: state => state.revenue,
  getExtract: state => state.extract,

  hasPermissionToImpersonate: (state, getters, rootState, rootGetters) => {
    if (rootGetters["auth/getUser"]) {
      return !!rootGetters["auth/getUser"].permissions.find(
        permission => permission.name === "users.features.partner-impersonate"
      );
    }

    return false;
  },
};
