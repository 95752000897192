import axios from "axios";

export default {
  /**
   *
   * @param {*} param0
   */
  findFeedbackReports({ commit }, payload) {
    commit('startLoading', 'findFeedbackReports');
    commit("setFeedbackStatistic", []);
    let params = {
      start_date: payload.start_date,
      end_date: payload.end_date,
      manager_id: payload.manager_id,
      'trashed[0]': 'campaign'
    };

    return new Promise((resolve, reject) => {
      axios.get('/feedbacks/' + payload.campaign_id + '/total', { params })
        .then((response) => {
          commit("setFeedbackStatistic", response.data.data);
          resolve()
        }).catch((response) => {
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit('stopLoading', 'findFeedbackReports')
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  findAgentFeedbackReports({ commit }, payload) {
    commit('startLoading', 'findAgentFeedbackReports');
    let params = {
      start_date: payload.start_date,
      end_date: payload.end_date,
      manager_id: payload.manager_id,
      agent_id: payload.agent_id,
    };
    return new Promise((resolve, reject) => {
      axios.get('/feedbacks/' + payload.campaign_id + '/agent', { params })
        .then((response) => {
          commit('addFeedbackAgent', { id: payload.agent_id, data: response.data.data });
          resolve();
        })
        .catch((response) => {
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit('stopLoading', 'findAgentFeedbackReports');
        });
    });
  },
  downloadTable({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading", "downloadTable");

    return new Promise((resolve, reject) => {
      axios
        .get(`/feedbacks/${payload.campaignId}/total/csv`, {
          params: {
            start_date: payload.startDate,
            end_date: payload.endDate,
            email: payload.email,
            campaign: payload.campaignId,
          }
        })
        .then(() => {
          commit("system/setSuccessMessage", "success_message.generate_report", { root: true });
          resolve();
        })
        .catch(response => {
          commit('system/setErrorMessage', 'Houve um problema ao gerar o relatório. Verifique a campanha e tente novamente mais tarde', { root: true })
          response.response.status == 422 &&
          response.response.data.errors &&
          commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading", "downloadTable");
        });
    });
  }
}
