<template>
  <div
    class="card ps-2"
    style="height: 148px;"
  >
    <div class="card-body">
      <div
        class="icon-container d-flex justify-content-center align-items-center mb-3"
      >
        <i
          :class="icon"
          class="tx-blue-300"
        />
      </div>
      <div
        :class="{
          loading: loading
        }"
        class="text-truncate fw-medium tx-text-gray ls-sm"
      >
        <span>{{ type }}</span>
      </div>
      <div class="d-flex tx-text-200 fw-medium">
        <div class="fs-2 me-2 text-truncate">
          <p>{{ value }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardMetrics",
  props: {
    loading: {
      type: Boolean,
      default: true
    },

    icon: {
      type: String,
      default: ""
    },

    type: {
      type: String,
      default: ""
    },

    value: {
      type: String,
      default: "0"
    }
  }
};
</script>

<style lang="scss" scoped>
.card-body {
  padding: 16px !important;

  .icon-container {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background: $color-gray-blue-300;
  }
}
</style>
