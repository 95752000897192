<template>
  <img
    data-fancybox
    :src="media"
    class="whatsapp-image cursor-pointer"
    loading="lazy"
  >
</template>

<script>
export default {
  props: {
    media: {
      type: String,
      required: true,
    },
    isDeleted: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  border-radius: $whatsapp-border-radius !important;
  aspect-ratio: 1 / 1;
  width: 116px;
}
</style>
