<template>
  <div
    class="d-flex align-items-center"
    ref="nodeElement"
  >
    <div class="card mb-0">
      <div class="card-body p-0">
        <div
          class="d-flex align-items-center justify-content-between div-new-action"
        >
          <p class="m-0">
            {{ $t('new_action') }}
          </p>
          <i
            class="fa-regular fa-xmark cursor-pointer"
            @click="deleteNode"
          />
        </div>
        <hr class="w-100">
        <div
          class="d-flex flex-column justify-content-center actions"
        >
          <action-component
            v-if="[64, 1, 3283].includes(user.company.id)"
            class="cursor-pointer"
            icon-bg="#FFFFF"
            icon-class="fa-regular fa-sparkles"
            icon-color="#7745EB"
            :name="$t('ask_with_ai')"
            :subtitle="$t('sdr_ai_qualification')"
            @click="updateNodeData('sdr')"
          />
          <action-component
            class="cursor-pointer"
            icon-class="fa-regular fa-message"
            icon-color="#5CB868"
            :name="$t('send_a_message')"
            @click="updateNodeData( 'message')"
          />
          <action-component
            class="cursor-pointer"
            icon-class="fa-regular fa-message-question"
            icon-color="#5CB868"
            :name="$t('make_a_question')"
            @click="updateNodeData('question') "
          />
          <action-component
            class="cursor-pointer"
            icon-class="fa-regular fa-message-check"
            icon-color="#5CB868"
            :name="$t('multiple_choice_question')"
            @click="updateNodeData( 'multiple_choice')"
          />
          <action-component
            class="cursor-pointer"
            icon-class="fa-regular fa-arrow-right-arrow-left"
            icon-color="#3057F2"
            :name="$t('transfer_to_wait_queue')"
            @click="updateNodeData( 'transfer')"
          />
          <action-component
            class="cursor-pointer"
            icon-class="fa-regular fa-backward"
            icon-color="#3057F2"
            :name="$t('back_capitalize')"
            @click="createNextAction( 'back')"
          />
          <action-component
            class="cursor-pointer"
            icon-class="fa-regular fa-rotate-left"
            icon-color="#3057F2"
            :name="$t('restart_chatbot')"
            @click="createNextAction( 'restart')"
          />
          <action-component
            class="cursor-pointer"
            icon-class="fa-regular fa-x"
            icon-color="#3057F2"
            :name="$t('finalize_chatbot')"
            @click="createNextAction( 'finish')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionComponent from "@chatbot/pages/workflow/components/action-component.vue";
import { useVueFlow } from "@vue-flow/core";
import {mapActions, mapGetters, mapMutations} from "vuex";

const { removeNodes  } = useVueFlow("chatbot" );
export default {
  name: "CardDefault",
  components: { ActionComponent },
  props: {
    action: {
      type: Object,
      default: null
    },
    nodeId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      actions: "chatbot/actions/getActions"
    }),
    ...mapGetters("auth", {
      user: "getUser",
    })
  },
  data() {
    return {
      temp: false,
    };
  },
  methods: {
    ...mapActions("chatbot/actions", ["updateAction"]),
    ...mapMutations("chatbot/actions", ["setSelectedAction", "setOffcanvasNewActionIsOpen", "setPrevActionId", "removeAction", "addAction", "addNodesPosition"]),
    deleteNode() {
      this.removeAction(this.nodeId);
      removeNodes(this.nodeId);
    },
    updateNodeData(newType) {
      this.setPrevActionId(this.action.previous_action_id)
      this.setOffcanvasNewActionIsOpen(true)
      this.setSelectedAction({
        id: this.nodeId,
        type: newType,
        need_chat_response: newType === 'question',
        position: this.action.position
      });
    },
    createNextAction(newType) {
      const body = {
        payload: {
          default_next_action: newType
        },
        actionId: this.action.previous_action_id
      }
      this.updateAction(body).then(() => {
        const newPosition = {
          id: `${this.action.previous_action_id}-${newType}`,
          position: {
            x: this.action?.position?.x || 0,
            y: this.action?.position?.y || 0
          }
        };
        this.addNodesPosition(newPosition)
        this.removeAction(this.nodeId)
      })
    },
    handleClickOutside(event) {
      if (!this.$refs.nodeElement.contains(event.target)) {
        this.deleteNode();
      }

    },
  },
  mounted() {
    document.addEventListener("mousedown", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  },

};
</script>

<style scoped lang="scss">
.div-new-action{
  padding: 1rem 1rem 0 1rem;
  width: 350px
}
.actions{
  padding: 0 1rem 1rem 1rem;
  width: 350px
}
</style>