<template>
  <div
    ref="modal"
    class="modal prevent-multiples-connections"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center b9">
          <img
            src="@assets/img/abduction.svg"
            class="mb-4"
          >
          <strong class="title">
            <p>Você já esta conectado em outro lugar</p>
          </strong>
          <p
            class="b3"
            style="font-size: 14px"
          >
            Seu usuário está conectado em outro computador ou outra aba do navegador.
            Clique em “Usar aqui” para encerrar outras sessões e logar nesta janela.
          </p>
          <div class="row options my-4 mx-5 b1 pt-4">
            <div class="col-5 b5">
              <button
                class="w-100 py-3 close"
                @click="$emit('close')"
              >
                Fechar
              </button>
            </div>
            <div class="col-5 offset-2 b5">
              <button
                class="btn w-100 py-3 ok"
                @click="$emit('force')"
                :disabled="loadingFlags.notifyLogout || delaying"
              >
                Usar aqui
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import { mapGetters } from "vuex";

export default {
  /**
   *
   */
  emits: ["force", "close"],

  /**
   *
   */
  data() {
    return {
      modal: null,
    };
  },

  /**
   *
   */
  computed: {
    ...mapGetters("auth", {
      loadingFlags: "getLoadingFlags",
    }),
  },

  /**
   * props
   */
  props: {
    /**
     * modal show prop
     */
    show: {
      type: Boolean,
      default: false,
    },

    /**
     * modal show prop
     */
    delaying: {
      type: Boolean,
      default: false,
    },
  },

  /**
   * watch
   */
  watch: {
    /**
     * watch whem prop "show" is true to open modal and load agents
     *
     * @param {*} show
     */
    show(show) {
      if (show) this.modal.show();
      else this.modal.hide();
    },
  },

  /**
   *
   */
  mounted() {
    this.modal = new Modal(this.$refs.modal);
  },
};
</script>

<style lang="scss" scoped>
.prevent-multiples-connections {
  color: $color-text-300;
  .options {
    border-top: 1px solid $color-gray-100;
    button {
      border-radius: 16px;
      &.close {
        border: 1px solid $color-blue-300;
        background-color: white;
        color: $color-blue-300;
      }
      &.ok {
        background-color: $color-blue-300;
        border: 1px solid $color-blue-300;
        color: white;
        font-weight: 300;
      }
    }
  }
}
</style>
