<template>
  <div
    ref="canvas"
    class="offcanvas offcanvas-end"
  >
    <div
      class="offcanvas-header"
      style="border: none"
    >
      <button
        aria-label="Close"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        type="button"
      />
    </div>

    <div class="offcanvas-body">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h3>
            {{ mode == "new" ? `${$t("add_new_qualification")}` : `${$t("update_qualification")}` }}
          </h3>
          <p class="text-muted">
            {{ $t("qualification_offcanvas") }}
          </p>
        </div>

        <div class="col-lg-12">
          <div
            v-if="errorMessage"
            class="alert alert-danger fade show"
            role="alert"
          >
            <span class="fe fe-alert-octagon" />&nbsp; {{ errorMessage }}
          </div>
        </div>

        <div class="col-lg-12 text-center">
          <hr>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8">
          <div class="form-group">
            <label class="form-label required text-capitalize">{{ $t("name") }}</label>
            <input
              v-model="fields.name"
              :class="{
                'form-control': true,
                'is-invalid': errorsQualification.name,
              }"
              :placeholder="$t('name_data')"
            >
            <div class="invalid-feedback">
              {{ errorsQualification.name && errorsQualification.name[0] }}
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-group">
            <label class="form-label required">{{ $t("color_general") }}</label>
            <color-picker-dropdown v-model="fields.color" />
          </div>
        </div>

        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-label required">{{ $t("behavior") }}</label>
            <select
              v-model="fields.behavior"
              :placeholder="$t('behavior')"
              :class="{
                'form-select': true,
                'is-invalid': errorsQualification.behavior,
              }"
            >
              <option value="" />
              <option value="1">
                {{ $t("not_dial_phone_again") }}
              </option>
              <option value="2">
                {{ $t("not_dial_client_again") }}
              </option>
              <option value="3">
                {{ $t("repeat") }}
              </option>
            </select>
            <div class="invalid-feedback">
              {{ errorsQualification.behavior && errorsQualification.behavior[0] }}
            </div>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="form-group">
            <label class="mb-1">
              {{ $t("is_conversion") }}
            </label>

            <p class="form-text text-muted text-medium">
              {{ $t("conversion_enable") }}
            </p>

            <div class="row">
              <div class="col-auto">
                <div class="form-check form-switch">
                  <input
                    id="switch-conversion"
                    v-model="fields.conversion"
                    class="form-check-input"
                    type="checkbox"
                  >
                  <label
                    class="form-check-label text-muted"
                    for="switch-conversion"
                  >
                    {{ fields.conversion ? `${$t("actived_function")}` : `${$t("inactived_function")}` }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="form-group">
            <label class="mb-1">
              {{ $t("contact_right_person") }}
            </label>

            <p class="form-text text-muted text-medium">
              {{ $t("contact_right_person_enable") }}
            </p>

            <div class="row">
              <div class="col-auto">
                <div class="form-check form-switch">
                  <input
                    id="switch-dmc"
                    v-model="fields.dmc"
                    :disabled="fields.conversion"
                    class="form-check-input"
                    type="checkbox"
                  >
                  <label
                    class="form-check-label text-muted"
                    for="switch-dmc"
                  >
                    {{ fields.dmc ? `${$t("actived_function")}` : `${$t("inactived_function")}` }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="form-group">
            <label class="mb-1 text-capitalize">
              {{ $t("unknown") }}
            </label>

            <p class="form-text text-muted text-medium">
              {{ $t("unknown_enable") }}
            </p>

            <div class="row">
              <div class="col-auto">
                <div class="form-check form-switch">
                  <input
                    id="switch-unknown"
                    v-model="fields.unknown"
                    class="form-check-input"
                    type="checkbox"
                  >
                  <label
                    class="form-check-label text-muted"
                    for="switch-unknown"
                  >
                    {{ fields.unknown ? `${$t("actived_function")}` : `${$t("inactived_function")}` }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="form-group">
            <label class="mb-1 text-capitalize">
              {{ $t("scheduling") }}
            </label>

            <p class="form-text text-muted text-medium">
              {{ $t("scheduling_enable") }}
            </p>

            <div class="row">
              <div class="col-auto">
                <div class="form-check form-switch">
                  <input
                    id="switch-allow-schedule"
                    v-model="fields.allow_schedule"
                    class="form-check-input"
                    type="checkbox"
                  >
                  <label
                    class="form-check-label text-muted"
                    for="switch-allow-schedule"
                  >
                    {{ fields.allow_schedule ? `${$t("actived_function")}` : `${$t("inactived_function")}` }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="fields.allow_schedule"
          class="col-lg-12"
        >
          <div class="form-group">
            <label class="mb-1">
              {{ $t("scheduling_other_number") }}
            </label>

            <div class="row">
              <div class="col-auto">
                <div class="form-check form-switch">
                  <input
                    id="switch-allow-schedule-to-another-number"
                    v-model="fields.allow_schedule_to_another_number"
                    class="form-check-input"
                    type="checkbox"
                  >
                  <label
                    class="form-check-label text-muted"
                    for="switch-allow-schedule-to-another-number"
                  >
                    {{ fields.allow_schedule_to_another_number ? `${$t("actived_function")}` : `${$t("inactived_function")}`
                    }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="fields.allow_schedule"
          class="col-lg-12"
        >
          <div class="form-group">
            <label
              class="form-label"
            >{{ $t("scheduling_deadlines") }}</label>
            <input
              v-model="fields.days_limit"
              :class="{
                'is-invalid': errorsQualification.days_limit,
              }"
              class="form-control"
              type="number"
            >
            <div class="invalid-feedback">
              {{ errorsQualification.days_limit && errorsQualification.days_limit[0] }}
            </div>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="form-group">
            <label class="mb-1">
              {{ $t("add_blocklist") }}
            </label>

            <p class="form-text text-muted text-medium">
              {{ $t("add_blocklist_enable") }}
            </p>

            <div class="row">
              <div class="col d-flex align-items-center justify-content-between">
                <div class="form-check form-switch">
                  <input
                    id="switch-should-insert-blocklist"
                    v-model="fields.should_insert_blacklist"
                    class="form-check-input"
                    type="checkbox"
                  >
                  <label
                    class="form-check-label text-muted"
                    for="switch-should-insert-blocklist"
                  >
                    {{ fields.should_insert_blacklist ? `${$t("actived_function")}` : `${$t("inactived_function")}` }}
                  </label>
                </div>
                <div
                  v-if="fields.should_insert_blacklist"
                  class="block-display"
                >
                  <h6
                    class="my-2 mx-3 tx-blue-300 fw-normal pt-1 cursor-pointer"
                    @click="$refs.editBlacklist.openModal()"
                  >
                    {{ fields.blacklist_blocking_days > 0 ? `${$t("blocked_for")} ${fields.blacklist_blocking_days} ${$t("days")}` : `${$t("undetermined_time")}`
                    }}
                    <i class="fa-light fa-pencil ms-2" />
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12">
          <app-button
            v-if="mode == 'new'"
            :loading="loading"
            class="w-100 text-capitalize"
            @click="create()"
          >
            {{ $t("save") }}
          </app-button>
          <app-button
            v-else-if="mode == 'edit'"
            :loading="loading"
            class="w-100 text-capitalize"
            @click="update()"
          >
            {{ $t("update") }}
          </app-button>
        </div>

        <automatic-blocking
          ref="editBlacklist"
          v-model="this.fields.blacklist_blocking_days"
          :title="$t('add_blocklist')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Offcanvas } from "bootstrap";
import { colors } from "@/utils";
import AppButton from "@/components/app-button.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ColorPickerDropdown from "@/components/color-picker-dropdown.vue";
import AutomaticBlocking from "./automatic-blocking.vue";

export default {
  data() {
    return {
      offcanvas: null,
      qualification: null,
      colors: [],
      fields: {
        allow_schedule: false,
        allow_schedule_to_another_number: false,
        behavior: "",
        color: "",
        conversion: false,
        days_limit: 7,
        dmc: false,
        emoji: "",
        name: "",
        should_insert_blacklist: false,
        blacklist_blocking_days: 0,
        unknown: false,
      },
      mode: "new",
      errorMessage: "",
    };
  },

  mounted() {
    this.colors = colors;
    this.errorMessage = "";
    this.offcanvas = new Offcanvas(this.$refs.canvas);
  },

  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
  },

  components: {
    AppButton,
    ColorPickerDropdown,
    AutomaticBlocking,
  },
  computed: {
    ...mapGetters("manager/qualification", {
      list: "getRegister",
      loading: "isLoading",
      errorsQualification: "getErrorsQualification",
    }),
    conversionSwitcher() {
      return this.fields.conversion;
    },
  },

  methods: {
    ...mapActions("manager/qualification", [
      "updateQualification",
      "addQualification",
      "findById",
      "findQualificationById",
    ]),

    ...mapMutations("manager/qualification", [
      "clearErrorsQualification",
    ]),

    edit(item) {
      this.qualification = item;
      this.fields = {
        allow_schedule: Boolean(item.allow_schedule),
        allow_schedule_to_another_number: Boolean(item.allow_schedule_to_another_number),
        behavior: item.behavior,
        color: item.color.toLowerCase(),
        conversion: item.conversion,
        days_limit: item.days_limit,
        dmc: item.dmc,
        emoji: item.emoji,
        name: item.name,
        should_insert_blacklist: item.should_insert_blacklist,
        blacklist_blocking_days: item.blacklist_blocking_days ? item.blacklist_blocking_days : 0,
        unknown: item.unknown,
      };
      this.mode = "edit";
      this.offcanvas.show();
    },

    new() {
      this.mode = "new";
      this.fields = {
        allow_schedule: false,
        allow_schedule_to_another_number: false,
        behavior: "",
        color: "",
        conversion: false,
        days_limit: 7,
        dmc: false,
        emoji: "",
        name: "",
        should_insert_blacklist: false,
        blacklist_blocking_days: 0,
        unknown: false,
      };
      this.offcanvas.show();
    },

    create() {
      if (!this.fields.should_insert_blacklist) {
        delete this.fields.blacklist_blocking_days
      }
      this.clearErrorsQualification();
      this.addQualification({
        list_id: this.$route.params.id,
        fields: {
          ...this.fields,
          days_limit: this.fields.days_limit ? this.fields.days_limit : 0,
        },
      })
          .then(() => {
            this.findQualificationById({ id: this.list.id });
            this.offcanvas.hide();
          });
    },
    update() {
      if (!this.fields.should_insert_blacklist) {
        delete this.fields.blacklist_blocking_days
      }
      this.offcanvas.hide();
      this.clearErrorsQualification();
      this.updateQualification({
        list_id: this.$route.params.id,
        id: this.qualification.id,
        fields: {
          ...this.fields,
          days_limit: this.fields.days_limit ? this.fields.days_limit : 0,
        },
      })
          .then(() => {
            const payload = {
              id: this.$route.params.id,
              fields: {
                page: this.currentPage,
              },
            }
            this.findQualificationById(payload);
            this.offcanvas.hide();
          })
    },
  },
  watch: {
    conversionSwitcher(newConversion) {
      if (newConversion) {
        this.fields.dmc = true;
      } else {
        this.fields.dmc = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.offcanvas-end {
  width: 450px !important;
  z-index: 2147483004;
}

.block-display {
  border: 1px solid $color-blue-300;
  border-radius: 8px;
}
</style>
