<template>
  <base-select
    :disabled="disabled"
    :error-label="errorLabel"
    :errors="errors"
    :has-tags="false"
    :label="label"
    :multiselect="false"
    :placeholder="placeholder"
    :prefix="prefix"
    :select-height="selectHeight"
    :select-width="selectWidth"
    :selected-item="showExhibitionValue"
    @update-term="updateTerm"
  >
    <template #opened-select-content>
      <ul
        v-if="filteredList.length > 0"
        class="overflow-auto list-unstyled tx-text-200 mb-0"
        style="max-height: 180px;"
      >
        <li
          class="option mb-0 rounded-1 cursor-pointer tx-text-gray"
          style="padding: 0 16px 0 8px; line-height: 2"
          @click="resetSelect"
        >
          {{ $t('select') }}
        </li>
        <div
          v-for="(group, index) in filteredList"
          :key="`${group.title}-${index}`"
          class="pe-2"
        >
          <h5
            class="m-0"
            style="line-height: 2"
          >
            {{ group[titleKey] }}
          </h5>

          <li
            v-for="option in group.data"
            :key="option.id"
            class="option mb-0 rounded-1 cursor-pointer"
            style="padding: 0 16px 0 8px; line-height: 2"
            @click="selectItem(option)"
          >
            {{ option[label] }}
            <span
              v-if="enableRequiredOptions"
              :class="{'required': option[requiredOptionKey]}"
              class="form-label"
            />
          </li>
        </div>
      </ul>
      <p
        v-else
        class="m-0 text-center"
      >
        {{ $t('no_field_found') }}
      </p>
    </template>
  </base-select>
</template>

<script>
import BaseSelect from "@components/select/base/base-select.vue";

export default {
  name: 'SingleSelectWithHierarchy',
  components: {
    BaseSelect,
  },

  emits: ['update:modelValue'],

  props: {
    modelValue: {default: null, type: [Object, String]},
    selectHeight: {type: String, default: '40px'},
    selectWidth: {type: String, default: '100%'},
    disabled: {type: Boolean, default: false},
    groupKey: {type: String, required: true},
    label: {type: String,required: true},
    customLabelReturn: {type: String, default: null},
    titleKey: {type: String,required: true,},
    enableRequiredOptions: {type: Boolean, default: false},
    disableItemSelected: {type: Boolean, default: false},
    requiredOptionKey: {type: String, default: ''},
    listData: {type: Array, required: true},
    placeholder: {type: String, default:  "Selecione"},
    errors: {type: Object, default: () => ({})},
    errorLabel: {type: String, default: ''},
    prefix: {type: String, default: ""},
    returnAllObject: {type: Boolean, default: false}
  },

  data() {
    return {
      searchTerm: '',
    }
  },

  computed: {
    filteredList() {
      return this.listData.map(group => ({
        ...group,
        data: group[this.groupKey].filter(option => option[this.label].toLowerCase().includes(this.searchTerm))
      })).filter(group => group.data.length > 0);
    },

    showExhibitionValue() {
      if (this.returnAllObject && this.modelValue) {
        return this.modelValue[this.label];
      }

      if (this.customLabelReturn && this.modelValue) {
        for (const group of this.listData) {
          const foundOption = group[this.groupKey].find(option => option[this.customLabelReturn] === this.modelValue);
          if (foundOption) {
            return foundOption[this.label];
          }
        }
      }

      return this.modelValue;
    }
  },

  methods: {
    selectItem(item) {
      if (this.returnAllObject) {
        this.$emit('update:modelValue', item);
        return;
      }

      if (this.customLabelReturn) {
        this.$emit('update:modelValue', item[this.customLabelReturn]);
        return
      }

      this.$emit('update:modelValue', item[this.label]);
    },

    updateTerm(term) {
      this.searchTerm = term;
    },

    resetSelect() {
      this.$emit('update:modelValue', this.returnAllObject ? {} : '');
    }
  },

  watch: {
    listData: {
      handler(newVal) {
        if (newVal) {
          this.resetSelect();
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.option:hover {
  background-color: $color-gray-blue-300;
}
</style>