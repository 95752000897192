<template>
  <div class="d-flex align-items-center justify-content-between gap-3 tag px-3 py-1">
    <p class="m-0 fs-6">
      {{ option[label] }}
    </p>
    <i
      class="fa-regular fa-xmark cursor-pointer"
      @click="$emit('remove', option.id)"
    />
  </div>
</template>

<script>
export default {
  name: "SelectTag",

  emits: ['remove'],

  props: {
    option: {
      type: Object,
      required: true,
    },

    label: {
      type: String,
      required: true,
    }
  }
}
</script>

<style lang="scss" scoped>
.tag, .fa-xmark {
  color: $color-blue-500;
}

.tag {
  background-color: $color-gray-blue-300;
  border-radius: 6px;
  max-width: 30%;

  p {
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.fa-xmark {
  font-size: 12px;
}
</style>