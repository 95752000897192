<template>
  <main
    v-if="!loadingFlags.detailsPartner && Object.keys(detailsPartner).length"
    class="d-flex col-12"
  >
    <div class="container-fluid overflow-auto">
      <header-component
        :button-name="$t('refer_new_client')"
        :title="$t('3c_plus_partners')"
      >
        <template #buttons>
          <a
            class="btn btn-primary mt-2 px-6"
            href="https://lp.3cplusnow.com/3c-partners/"
            target="_blank"
          >
            <i class="fa-regular fa-plus me-2" />
            {{ $t("new_referral") }}
          </a>
        </template>
      </header-component>

      <contract-partner v-if="!hasPermissionToImpersonate" />
      <div class="row mb-3" v-if="!hasPermissionToImpersonate" >
        <card-level-partner />
        <card-partner />
        <card-expert />
      </div>
      <div class="row" v-if="!hasPermissionToImpersonate">
        <card-refer-now />
        <card-referral-link />
      </div>
      <table-partner />
    </div>
  </main>
</template>

<script>
import CardLevelPartner from "@partner/pages/component/card-level-partner.vue";
import CardPartner from "@partner/pages/component/card-partner.vue";
import TablePartner from "@partner/pages/component/table-partner.vue";
import {mapActions, mapGetters} from "vuex";
import HeaderComponent from "@/components/header-component.vue";
import ContractPartner from "@partner/pages/component/card-contract-partner.vue";
import CardExpert from "@partner/pages/component/card-expert.vue";
import CardReferralLink from "@partner/pages/component/card-referral-link.vue";
import CardReferNow from "@partner/pages/component/card-refer-now.vue";

export default {
  components: {
    ContractPartner,
    HeaderComponent,
    CardPartner,
    CardLevelPartner,
    TablePartner,
    CardExpert,
    CardReferralLink,
    CardReferNow
  },

  methods: {
    ...mapActions("partner", ["fetchDetailsPartner", "listPartners"]),
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser"
    }),

    ...mapGetters("partner", {
      detailsPartner: "getDetailsPartner",
      loadingFlags: "getLoadingFlags",
      partnerLink: "getCompanyDetails",
      hasPermissionToImpersonate: "hasPermissionToImpersonate",
    }),

    ...mapGetters("admin/partners", {
      partnerLink: "getPartner"
    }),

    modalSlots() {
      return [
        {
          name: "text",
          tag: "h3",
          content: this.$t("make_your_first_customer_referral"),
          className: "fw-medium tx-text-200 mb-0 fs-2"
        },
        {
          name: "image",
          tag: "img",
          contentImage: require("@/assets/img/aloisio/Pluzinha_Espera.svg"),
          contentAlt: "Alosio espera",
          classImage: "my-3"
        },
        { name: "subtitle", tag: "p", content: this.$t("become_a_3c_partner"), className: "tx-text-200 fs-4" }
      ];
    }
  },

  mounted() {
    this.fetchDetailsPartner({ id: this.user.id });
    this.listPartners(this.user.id);
  }
};
</script>

<style lang="scss" scoped>
.container-fluid {
  margin-left: 102px;
  margin-right: 36px;
}
</style>
