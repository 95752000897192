export default {
  loadingFlags: {
    loadCampaignsMetricsCharts: false,
    loadCampaignsMetrics: false
  },
  campaignsMetricsCharts: {},
  campaignsMetricsChartsFilters: {},

  campaignsMetrics: {},
  campaignsMetricsFilters: {}
}
