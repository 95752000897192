export default {
    startLoading: state => state.loading = true,
    stopLoading: state => state.loading = false,
    clearList: (state) => state.list = [],
    setErrors: (state, errors) => state.errors = errors,
    clearErrors: (state) => state.errors = {},
    setPixPaid: (state, isPaid) => state.isPaid = isPaid,
    setList: (state, list) => state.list = list,
    setCEPData: (state, CEPData) => state.CEPData = CEPData,
    setTransactionData: (state, transactionData) => state.transactionData = transactionData,
    setOfflineSms: (state, offlineSms) => state.offlineSms = offlineSms,
}
