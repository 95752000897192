<template>
  <progress-chart
    v-if="qualificationsList && qualificationLength && !loadingFlags.fetchGroupChannelsReportList"
    :title="$t('qualifications_by_group_channels')"
    :labels="groupChannelsLabels"
    :step-size="50"
    :datasets="qualificationDatasets"
  />
  <div
    v-if="!qualificationsList || !qualificationLength"
    class="card"
  >
    <table-empty />
  </div>
</template>

<script>
import ProgressChart from "@dialer/manager/components/progress-chart.vue";
import {mapGetters} from "vuex";
import TableEmpty from "../../components/table-empty.vue";

export default {
  name: "ChartQualifications",
  components: {
    TableEmpty,
    ProgressChart
  },
  data() {
    return {
      groupChannelsLabels: [],
      qualificationDatasets: []
    };
  },
  computed: {
    ...mapGetters("manager/group_channels_report", {
      loadingFlags: "getLoadingFlags",
      qualificationsList: "getQualificationsReportList",
      chatsReportList: "getChatsReportList"
    }),
    qualificationLength(){
      return Object.keys(this.qualificationsList).length;
    }
  },
  methods: {
    handleQualificationSubmit() {
      this.clearQualificationData();
      this.prepareQualificationDataset();
    },
    clearQualificationData() {
      this.groupChannelsLabels = [];
      this.qualificationDatasets = [];
    },
    prepareQualificationDataset() {
      const datasetsMap = {};

      this.chatsReportList.group_channels_metrics.forEach(group_channel => {
        this.groupChannelsLabels.push(group_channel.group_channel_name);
      })

      for (const group_channel in this.qualificationsList) {
        const qualifications = Object.keys(this.qualificationsList[group_channel]);

        for (const qualification of qualifications) {
          if (!datasetsMap[qualification]) {
            datasetsMap[qualification] = {
              label: qualification.length > 30 ? qualification.slice(0, 15) + '...' : qualification,
              backgroundColor: this.qualificationsList[group_channel][qualification].color,
              color: this.qualificationsList[group_channel][qualification].color,
              data: Array(this.groupChannelsLabels.length).fill(0),
              borderRadius: 0
            };
          }
        }
      }

      this.groupChannelsLabels.forEach((group_channel, groupIndex) => {
        if (this.qualificationsList[group_channel]) {
          const qualifications = this.qualificationsList[group_channel];
          for (const [qualification, values] of Object.entries(qualifications)) {
            datasetsMap[qualification].data[groupIndex] = values.quantity;
          }
        }
      });

      this.qualificationDatasets = Object.values(datasetsMap);
    }
  },
  watch: {
    qualificationsList(data) {
      if (Object.keys(data).length) {
        this.handleQualificationSubmit();
      }
    }
  }
};
</script>
