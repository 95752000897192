<template>
  <div
    v-for="(teamStartTimeCard, index) in teamsStartTimeCards"
    :key="index"
    class="card position-relative"
  >
    <i
      v-if="index >= 1"
      class="trash-icon fa-regular fa-trash cursor-pointer position-absolute"
      @click="teamsStartTimeCards.splice(index, 1)"
    />
    <div class="card-body bg-gray-blue-200">
      <div class="row">
        <div class="col-6">
          <div class="form-group m-0">
            <label class="form-label"> {{ $t("select_teams") }} </label>
            <selects-components
              :id="`selectTeams${index}`"
              ref="selectTeams"
              :list-array="listTeams"
              :multi-select-check="true"
              :current-selected="teamStartTimeCard.teams"
              search-name="name"
              @selected="teamStartTimeCard.teams = $event"
            />
          </div>
        </div>

        <div class="col-lg-6">
          <div class="form-group m-0">
            <label class="form-label">{{ $t("initial_hour") }}</label>
            <div class="input-group input-group-merge">
              <flat-pickr
                v-model="teamStartTimeCard.startTime"
                :config="config"
                class="form-control"
              />
              <div class="input-group-text">
                <span class="fe fe-clock" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-button
    @click="addNewTeamStartTimeCard"
    class="mx-auto text-small"
    icon="plus"
    color="bg-gray-blue-300 tx-text-gray"
  >
    {{ $t("add_new_automation") }}
  </app-button>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FlatPickr from "vue-flatpickr-component";
import SelectsComponents from "@dialer/manager/pages/insights-ai/components/selects-component.vue";
import AppButton from "@/components/app-button.vue";

export default {
  emits: ["sendTeamsStartTimeCards"],
  props: {
    teamsAutoBreakIntervals: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      teams: [],
      teamsStartTimeCards: [
        {
          teams: [],
          startTime: "00:00",
        }
      ],
      config: {
        enableTime: true,
        noCalendar: true,
        time_24hr: true,
        allowInput: true
      }
    };
  },

  mounted() {
    this.setFiltersIncludes(["teams"]);
    this.findFilters();
    this.teamsStartTimeCards = this.transformBreakIntervalsToStartTimeCards(this.teamsAutoBreakIntervals);
  },

  components: {
    FlatPickr,
    SelectsComponents,
    AppButton,
  },

  computed: {
    ...mapGetters("manager/filters", {
      filters: "getFilters"
    }),
    listTeams() {
      return [
        {
          id: 1,
          name: "Selecionar todos",
          qualifications: this.teams
        }
      ]
    }
  },

  methods: {
    ...mapActions("manager/filters", ["findFilters", "setFiltersIncludes"]),
    addNewTeamStartTimeCard() {
      this.teamsStartTimeCards.push({
        teams: [],
        startTime: "00:00",
      });
    },

    transformTeamsStartTimeCards() {
      const teamStartTimesMap = {};

      this.teamsStartTimeCards.forEach(card => {
        card.teams.forEach(team => {
          if (team.checked) {
            if (!teamStartTimesMap[team.id]) {
              teamStartTimesMap[team.id] = {
                id: team.id,
                start_times: []
              };
            }
            teamStartTimesMap[team.id].start_times.push(card.startTime);
          }
        });
      });

      return Object.values(teamStartTimesMap);
    },

    transformBreakIntervalsToStartTimeCards(breakIntervals) {
      if (breakIntervals.length === 0) return [
        {
          teams: [],
          startTime: "00:00",
        }
      ];
      const startTimeCardsMap = {};

      breakIntervals.forEach(interval => {
        const { start_time, team_id } = interval;

        if (!startTimeCardsMap[start_time]) {
          startTimeCardsMap[start_time] = {
            startTime: start_time,
            teams: []
          };
        }
        startTimeCardsMap[start_time].teams.push({
          checked: true,
          id: team_id
        });
      });

      return Object.values(startTimeCardsMap);
    }
  },

  watch: {
    teamsStartTimeCards: {
      handler() {
        this.$emit("sendTeamsStartTimeCards", this.transformTeamsStartTimeCards());
      },
      deep: true
    },

    teamsAutoBreakIntervals: {
      handler() {
        this.teamsStartTimeCards = this.transformBreakIntervalsToStartTimeCards(this.teamsAutoBreakIntervals);
      },
      deep: true
    },

    filters: {
      handler(data) {
        if (data.teams) {
          this.teams = this.filters.teams;
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  .card-body {
    padding: 1rem;
  }

  .trash-icon {
    top: 8px;
    right: 16px;
    color: var(--color-text-gray);

    &:hover {
      color: var(--bs-danger);
    }
  }
}
</style>
