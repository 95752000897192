<template>
  <button
    class="btn p-0"
    data-bs-placement="right"
    ref="scheduleStatusTooltip"
    :title="scheduleTitle"
  >
    <span
      class="fa-regular"
      :class="scheduleIcon"
    />
  </button>
</template>

<script>
import moment from "moment";
import { Tooltip } from "bootstrap";

export default {
  data() {
    return {
      tooltip: null,
    }
  },

  props: {
    schedule: {
      type: Object,
      default: () => {}
    }
  },

  mounted() {
    this.tooltip = new Tooltip(this.$refs.scheduleStatusTooltip, {
      template:
        `<div class='tooltip fs-4' role='tooltip'>
          <div class='tooltip-inner'>
          </div>
        </div>`,
    });
  },

  computed: {
    scheduleStatus() {
      const { status, date } = this.schedule;
      if (status === "completed") {
        return { icon: "fa-circle-check text-speaking", titleKey: "schedule_made" };
      }
      if (moment().isBefore(moment(date, "YYYY-MM-DD HH:mm:ss"))) {
        return { icon: "fa-clock-rotate-left tx-yellow-400", titleKey: "schedule_pending" };
      }
      return { icon: "fa-circle-xmark text-abandoned", titleKey: "schedule_not_made" };
    },
    scheduleIcon() {
      return this.scheduleStatus.icon;
    },
    scheduleTitle() {
      return this.$t(this.scheduleStatus.titleKey);
    }
  }
}
</script>
