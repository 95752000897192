<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="col">
          <form>
            <div class="input-group input-group-flush input-group-merge input-group-reverse">
              <input
                class="form-control list-search"
                type="search"
                :placeholder="$t('search_user')"
                v-model="term"
                @keyup="search"
              >
              <div class="input-group-text">
                <i class="fe fe-search" />
              </div>
            </div>
          </form>
        </div>
        <div class="d-flex justify-content-end col">
          <div class="mx-3">
            <a
              href="#"
              class="btn btn-sm btn-outline-secondary"
              data-bs-toggle="modal"
              data-bs-target="#modalDownloadCSV"
            >{{ $t("download_CSV") }}
            </a>
          </div>
          <div class="mx-4">
            <div class="dropdown">
              <button
                @click="cargoSelected = !cargoSelected"
                class="btn btn-sm dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                :style="
                  cargoSelected
                    ? {
                      'background-color': 'rgba(44, 123, 229, 1) !important',
                      color: 'white'
                    }
                    : {
                      'background-color': 'rgba(227, 235, 246, 0.5) !important'
                    }
                "
              >
                {{ $t("company_role") }}
              </button>
              <div class="dropdown-menu">
                <div class="dropdown-item">
                  <input
                    v-model="role"
                    value="agent"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                  >
                  <span
                    class="form-label mx-2"
                    href="#"
                  >{{ $t("agent") }}</span>
                </div>
                <div class="dropdown-item">
                  <input
                    v-model="role"
                    value="supervisor"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                  >
                  <span
                    class="form-label mx-2"
                    href="#"
                  >{{ $t("supervisor") }}</span>
                </div>
                <div class="dropdown-item">
                  <input
                    v-model="role"
                    value="manager"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                  >
                  <span
                    class="form-label mx-2"
                    href="#"
                  >{{ $t("manager") }}</span>
                </div>
                <div class="dropdown-item">
                  <input
                    v-model="role"
                    value=""
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    checked
                  >
                  <span
                    class="form-label mx-2"
                    href="#"
                  >{{ $t("all") }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ul class="nav nav-tabs nav-tabs-sm card-header-tabs">
          <li class="nav-item">
            <button
              @click="is_active_users = true"
              class="nav-link active two-lines"
              data-bs-toggle="tab"
              data-bs-target="#actives"
            >
              <span class="text-muted fw-medium text-uppercase"> {{ $t("active_ml") }} </span>
              <br>
              <span class="subtitle">
                {{ totalOnlineUsers }}
              </span>
            </button>
          </li>
          <li class="nav-item">
            <button
              @click="is_active_users = false"
              class="nav-link two-lines"
              data-bs-toggle="tab"
              data-bs-target="#inactives"
            >
              <span class="text-muted fw-medium text-uppercase"> {{ $t("inactive") }} </span>
              <br>
              <span class="subtitle">
                {{ totalOfflineUsers }}
              </span>
            </button>
          </li>
        </ul>
      </div>
      <div class="card-body px-0 py-0">
        <div class="tab-content">
          <div
            role="tabpanel"
            id="actives"
            class="table-responsive tab-pane active"
          >
            <table class="table table-hover table-nowrap card-table mb-0">
              <thead>
                <tr>
                  <th>
                    <a
                      class="list-sort text-muted fw-normal"
                      href="#"
                    >{{ $t("name") }}</a>
                  </th>
                  <th>
                    <a
                      class="list-sort text-muted fw-normal"
                      href="#"
                    >{{ $t("role") }}</a>
                  </th>
                  <th>
                    <a
                      class="list-sort text-muted fw-normal"
                      href="#"
                    >{{ $t("extension") }}</a>
                  </th>
                  <th v-if="user.company.ring_group_licenses">
                    <a
                      class="list-sort text-muted fw-normal"
                      href="#"
                    >{{ $t("extension_function") }}</a>
                  </th>
                  <th>
                    <a
                      class="list-sort text-muted fw-normal"
                      href="#"
                    >{{ $t("last_login") }}</a>
                  </th>
                  <th>
                    <a
                      class="list-sort text-muted fw-normal"
                      href="#"
                    >{{ $t("team") }}</a>
                  </th>
                  <th />
                </tr>
              </thead>

              <tbody class="list fs-base">
                <tr v-if="onlineUsers.length === 0 && !loading.listOnlineUsers">
                  <td
                    class="text-center text-muted"
                    :colspan="user.company.ring_group_licenses ? 7 : 6"
                  >
                    <span class="fe fe-alert-octagon" /> {{ $t("no_user_found") }}
                  </td>
                </tr>

                <template v-if="loading.listOnlineUsers">
                  <loading-table-component
                    :lines="15"
                    :columns="user.company.ring_group_licenses ? 7 : 6"
                    :text-center="false"
                    :list-width="[50, 50, 50, 50, 50, 50]"
                  />
                </template>

                <template
                  v-for="item in onlineUsers"
                  :key="item.id"
                  v-else
                >
                  <tr
                    class="cursor-pointer"
                    @click="$router.push({ path: `/manager/users/${item.id}/edit` })"
                    @mouseover="selectItem(item.id)"
                    @mouseleave="unSelectItem()"
                    style="height: 40px;"
                  >
                    <td>{{ item.name }}</td>
                    <td>{{ item.role.readable_name }}</td>
                    <td>{{ item.extension.extension_number }}</td>
                    <td v-if="user.company.ring_group_licenses">
                      <span
                        v-if="item.role.name === 'agent'"
                        :class="{ 'text-muted': !item.permissions.find(p => p.name == 'ring.group.access') }"
                      >
                        {{
                          !!item.permissions.find(p => p.name == "ring.group.access")
                            ? $t("activated_masc")
                            : $t("deactivated_masc")
                        }}
                      </span>
                      <span v-else>
                        -
                      </span>
                    </td>
                    <td v-if="item.last_login">
                      {{ this.formatLastLogin(item.last_login) }}
                    </td>
                    <td v-else>
                      <div class="d-flex align-items-center">
                        <div>-</div>
                        <last-login-tooltip />
                      </div>
                    </td>
                    <td v-if="item.teams.length == 1">
                      <div class="d-flex align-items-center">
                        <span
                          class="btn btn-sm btn-rounded-circle"
                          :style="{ background: item.teams[0].color }"
                          style="width: 8px; height: 8px"
                        />
                        <span
                          class="text-wrap fs-5 tx-text-200"
                          style="margin-left: 8px;"
                        >
                          {{ item.teams[0].name }}
                        </span>
                      </div>
                    </td>
                    <td v-else-if="item.teams.length > 1">
                      <tooltip-teams :data="item.teams" />
                    </td>
                    <td
                      v-else
                      class="text-muted"
                    >
                      {{ $t("no_team") }}
                    </td>
                    <td>
                      <span
                        style="font-weight: bold;"
                        class="fe fe-chevron-right text-primary"
                        :style="{
                          visibility: item.id == selectedItem ? 'visible' : 'hidden'
                        }"
                      />
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <div
              class="card-footer"
              v-if="!loading.listOnlineUsers && onlineUsers.length"
            >
              <div class="col-lg-12">
                <paginator
                  class=""
                  :pagination="paginationOnlineUsers"
                  @change-page="changePageOnlineUsers"
                />
              </div>
            </div>
          </div>

          <div
            role="tabpanel"
            id="inactives"
            class="table-responsive tab-pane"
          >
            <table class="table table-hover table-nowrap card-table">
              <thead>
                <tr>
                  <th>
                    <a
                      class="list-sort text-muted fw-normal"
                      href="#"
                    >{{ $t("uppercases.name") }}</a>
                  </th>
                  <th>
                    <a
                      class="list-sort text-muted fw-normal"
                      href="#"
                    >{{ $t("uppercases.role") }}</a>
                  </th>
                  <th>
                    <a
                      class="list-sort text-muted fw-normal"
                      href="#"
                    >{{ $t("uppercases.extension") }}</a>
                  </th>
                  <th>
                    <a
                      class="list-sort text-muted fw-normal"
                      href="#"
                    >EMAIL</a>
                  </th>
                  <th>
                    <a
                      class="list-sort text-muted fw-normal"
                      href="#"
                    >{{ $t("uppercases.team") }}</a>
                  </th>
                  <th />
                  <th />
                </tr>
              </thead>

              <tbody class="list fs-base">
                <tr v-if="offlineUsers.length === 0 && !loading.offlineUsers">
                  <td
                    class="text-center text-muted"
                    colspan="7"
                  >
                    <span class="fe fe-alert-octagon" /> {{ $t("no_user_found") }}
                  </td>
                </tr>

                <template v-if="loading.offlineUsers">
                  <loading-table-component
                    :lines="15"
                    :columns="7"
                    :text-center="false"
                    :list-width="[50, 50, 50, 50, 50, 50, 50]"
                  />
                </template>

                <template
                  v-for="item in offlineUsers"
                  :key="item.id"
                  v-else
                >
                  <tr
                    class="cursor-pointer"
                    @click="$router.push({ path: `/manager/users/${item.id}/edit` })"
                    @mouseover="selectItem(item.id)"
                    @mouseleave="unSelectItem()"
                  >
                    <td>
                      {{ item.name }}
                    </td>
                    <td>{{ item.role.readable_name }}</td>
                    <td>{{ item.extension.extension_number }}</td>
                    <td v-if="item.last_login">
                      {{ this.formatLastLogin(item.last_login) }}
                    </td>
                    <td
                      v-else
                      class="d-flex align-items-center"
                    >
                      -
                      <last-login-tooltip />
                    </td>
                    <td v-if="item.teams.length == 1">
                      <span
                        class="btn btn-sm btn-rounded-circle"
                        :style="{ background: item.teams[0].color }"
                        style="width: 20px; height: 20px"
                      />
                      <span class="mx-1 text-wrap">
                        {{ item.teams[0].name }}
                      </span>
                    </td>
                    <td v-else-if="item.teams.length > 1">
                      <tooltip-teams :data="item.teams" />
                    </td>
                    <td
                      v-else
                      class="text-muted"
                    >
                      {{ $t("no_team") }}
                    </td>
                    <td>
                      <div
                        class="text-primary cursor-pointer"
                        @click="
                          $router.push({
                            path: `/manager/users/${item.id}/edit`
                          })
                        "
                      >
                        {{ $t("activate") }}
                      </div>
                    </td>
                    <td>
                      <span
                        style="font-weight: bold;"
                        class="fe fe-chevron-right text-primary"
                        :style="{
                          visibility: item.id == selectedItem ? 'visible' : 'hidden'
                        }"
                      />
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>

            <div v-if="!loading.offlineUsers && offlineUsers.length">
              <div class="col-lg-12">
                <paginator
                  v-if="!loading.offlineUsers"
                  :pagination="paginationOfflineUsers"
                  @change-page="changePageOfflineUsers"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="modalDownloadCSV"
      tabindex="-1"
      aria-labelledby="modalDownloadCSVLabel"
      aria-hidden="true"
      ref="generateCSVModal"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h3
              class="modal-title"
              id="modalDownloadCSVLabel"
            >
              {{ $t("generate_report") }}
            </h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div class="modal-body">
            <p>
              {{ $t("send_report_to_email") }}
            </p>
            <form
              @submit.prevent="generateCSV"
              id="formCSV"
            >
              <div class="mb-3">
                <label
                  for="input-email"
                  class="col-form-label"
                >{{ $t("report_email") }}</label>
                <input
                  type="email"
                  class="form-control"
                  id="input-email"
                  required
                  v-model="email"
                >
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              {{ $t("cancel_general") }}
            </button>
            <app-button
              form="formCSV"
              data-bs-dismiss="modal"
              :loading="loading.downloadCSV"
              style="min-width: 6.5rem;"
            >
              {{ $t("generate_csv") }}
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import _ from "lodash";
import moment from "moment";
import paginator from "@/components/app-paginator.vue";
import tooltipTeams from "./tooltip-teams.vue";
import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";
import LastLoginTooltip from "./last-login-tooltip.vue";
import AppButton from "@/components/app-button.vue";
import { Modal } from "bootstrap";

export default {
  name: "UsersTableContent",
  data() {
    return {
      fields: {
        id: 0,
        webphone: false,
      },
      email: "",
      cargoSelected: false,
      term: "",
      totalOnlineUsers: 0,
      totalOfflineUsers: 0,
      tooltip: null,
      selectedItem: false,
      is_active_users: true,
      role: "",
      generateCSVModal: null,
    };
  },
  components: {
    paginator,
    tooltipTeams,
    LoadingTableComponent,
    LastLoginTooltip,
    AppButton,
  },
  computed: {
    ...mapGetters("auth", {
      user: "getUser",
    }),

    ...mapGetters("manager/user", {
      onlineUsers: "getOnlineUsers",
      offlineUsers: "getOfflineUsers",
      loading: "getLoadingFlag",
      paginationOnlineUsers: "getPaginationOnlineUsers",
      paginationOfflineUsers: "getPaginationOfflineUsers",
      errors: "getErrors",
    }),
  },
  methods: {
    ...mapActions("manager/user", [
      "switchWebPhone",
      "switchExtension",
      "downloadCSV",
      "listOnlineUsers",
      "listOfflineUsers",
      "updateUserIpAllowedVerification",
    ]),
    ...mapMutations("manager/user", ["clearList"]),
    ...mapActions("auth", ["me"]),

    changePageOnlineUsers(page) {
      this.$store.dispatch("manager/user/listOnlineUsers", {
        page,
        search: this.term,
        role: this.role,
      });
    },

    formatLastLogin(data) {
      return moment(data)
          .format("DD/MM/YY [-] hh:mm");
    },

    changePageOfflineUsers(page) {
      this.$store.dispatch("manager/user/listOfflineUsers", {
        page,
        search: this.term,
        role: this.role,
      });
    },

    generateCSV() {
      this.downloadCSV({
        is_active_users: this.is_active_users,
        email: this.email,
      })
          .then(() => this.generateCSVModal.hide());
    },

    search: _.debounce(function () {
      this.clearList();
      this.$store.dispatch("manager/user/listOnlineUsers", {
        search: this.term,
        role: this.role,
      });

      this.$store.dispatch("manager/user/listOfflineUsers", {
        search: this.term,
        role: this.role,
      });
    }, 500),

    selectItem(item) {
      this.selectedItem = item;
    },

    unSelectItem() {
      this.selectedItem = false;
    },

    changeAllowedIpVerificationEnabled(event, userId = null) {
      this.updateUserIpAllowedVerification({
        user_id: userId,
        value: event.target.checked,
      })
          .then(() => {
            if (userId == null) {
              this.listOnlineUsers({
                search: this.term,
                role: this.role,
              });

              this.me();
            }
          });
    },
  },

  beforeMount() {
    this.listOnlineUsers();
    this.listOfflineUsers();
  },
  mounted() {
    this.email = this.user.email;
    this.generateCSVModal = new Modal(this.$refs.generateCSVModal);
  },

  watch: {
    paginationOnlineUsers() {
      this.totalOnlineUsers = this.paginationOnlineUsers.total;
    },

    paginationOfflineUsers() {
      this.totalOfflineUsers = this.paginationOfflineUsers.total;
    },

    role(role) {
      this.$store.dispatch("manager/user/listOnlineUsers", {
        role: role,
        search: this.term,
      });
      this.$store.dispatch("manager/user/listOfflineUsers", {
        role: role,
        search: this.term,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.table > :not(:first-child) {
  border-top: none;
}

td {
  height: 40px;
  padding: 8px 24px !important;
}

.bold {
  font-weight: bold;
}
</style>
