<template>
  <div class="container-warmup">
    <div class="container-fluid">
      <header-component title="PABX">
        <template #buttons>
          <app-button
            v-if="!handlerClick"
            @click="handlerButtons"
            style="width: 300px"
          >
            {{ $t('early_access') }}
          </app-button>
          <app-button
            v-else
            disabled
            style="width: 300px"
          >
            {{ $t('access_requested') }}
          </app-button>
        </template>
      </header-component>

      <div class="d-flex bg-img-ring-group">
        <div class="d-flex flex-column justify-content-center container-illustration">
          <img
            src="@assets/img/3czinhos.svg"
            style="width: 85px; margin-bottom: 40px"
          >
          <h1 style="margin-bottom: 1rem">
            {{ $t('max_production_with') }}
          </h1>
          <h4
            style="margin-bottom: 40px"
            class="fw-normal"
          >
            {{ $t('pabx_advantages') }}
          </h4>
          <app-button
            v-if="!handlerClick"
            @click="handlerButtons"
            style="width: 300px"
          >
            {{ $t('early_access') }}
          </app-button>
          <app-button
            v-else
            disabled
            style="width: 300px"
          >
            {{ $t('access_requested') }}
          </app-button>
        </div>
      </div>

      <div>
        <h2 style="margin-bottom: 1.5rem">
          {{ $t('what_the_tool_offers') }}:
        </h2>

        <div class="row">
          <card-warmup
            :title="$t('efficient_communication')"
            :text="$t('efficient_communication_description')"
            icon-class="fal fa-comment-check fw-medium tx-yellow-300"
          />
          <card-warmup
            :title="$t('adaptive_scalability')"
            :text="$t('adaptive_scalability_description')"
            icon-class="fal fa-arrow-up-right-and-arrow-down-left-from-center fw-medium tx-blue-300"
          />
          <card-warmup
            :title="$t('remote_management')"
            :text="$t('remote_management_description')"
            icon-class="fal fa-bullseye-pointer fw-medium tx-pink-300"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardWarmup from "@/components/card-warmup.vue";
import HeaderComponent from "@/components/header-component.vue";
import AppButton from "@/components/app-button.vue";
import { mapMutations } from "vuex";

export default {
  name: "WarmupRingGroup",
  components: { AppButton, HeaderComponent, CardWarmup },
  data() {
    return {
      handlerClick: false
    };
  },
  methods: {
    ...mapMutations("auth", ["trackEvents"]),

    handlerButtons() {
      this.handlerClick = !this.handlerClick;
      this.trackEvents(`[Manager] Request access warmup PABX`);
    }
  }
};
</script>

<style scoped>
.container-warmup {
  max-width: 1670px;
  margin: 0 auto;
}

.container-illustration {
  width: 415px;
  margin-left: 40px;
  margin-top: 40px;
}

.bg-img-ring-group {
  background-image: url("~@assets/img/bg-warmup-ring-group.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  margin-bottom: 1.5rem;
  height: 340px;

  @media (min-width: 1280px) {
    aspect-ratio: 2.85/1;
    height: auto;
  }
}
</style>
