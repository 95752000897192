<template>
  <div
    v-if="countryName !== 'Brasil'"
    class="input-group w-100"
    :class="{ 'box-shadow rounded': showSearch }"
    ref="countryFlag"
  >
    <div v-show="!showSearch">
      <div
        class="border bg-white d-flex align-items-center justify-content-between box-collapse rounded-start"
        type="button"
        @click="showSearch = true"
        :class="{ 'border-0': notInput }"
        :style="{ 'border-color': `${borderColor} !important` }"
        data-bs-toggle="collapse"
        data-bs-target="#collapseCountry"
        aria-expanded="false"
        aria-controls="collapseCountry"
      >
        <p class="mb-0 text-start">
          {{ selectedCountry.icon }} {{ selectedCountry.ddi }}
        </p>
        <i
          class="fal fa-chevron-down tx-text-gray"
          style="width: 24px;"
        />
      </div>
    </div>
    <div v-show="showSearch">
      <div
        class="border border-bottom-0 bg-white d-flex rounded-0 align-items-center box-collapse"
        :class="{ 'rounded-top box-shadow': notInput }"
        :style="{ 'border-color': `${borderColor} !important` }"
      >
        <input
          id="input"
          type="number"
          @focus="focus = true"
          @focusout="focus = false"
          :placeholder="[focus ? `${selectedCountry.ddi}` : `${this.selectedCountry.icon} ${this.selectedCountry.ddi}`]"
          class="form-control p-0 border-0"
          v-model="search"
        >
        <div
          class="cursor-pointer"
          data-bs-toggle="collapse"
          data-bs-target="#collapseCountry"
          @click="closeSearch"
          style="width: 24px;"
        >
          <i class="fal fa-chevron-up tx-text-gray" />
        </div>
      </div>
    </div>
    <div
      class="collapse position-absolute box-shadow"
      id="collapseCountry"
      ref="collapseCountry"
      style="z-index: 10;width:114px;"
      :style="[notInput ? { margin: '42px 4px 0 0' } : { 'margin-top': '42px' }]"
    >
      <div
        class="border bg-white px-0 pt-1 border-top-0 rounded-0 rounded-bottom"
        style="max-height:164px; width: 118px;"
        :style="{ 'border-color': `${borderColor} !important` }"
      >
        <div
          class="overflow-auto"
          style="max-height: 150px;"
        >
          <ul
            class="mb-0 p-0"
            style="padding: 0 8px !important;"
          >
            <template v-if="countriesIcons().length">
              <li
                class="tx-text-gray text-start"
                v-for="country in countriesIcons()"
                :key="country.name"
                data-bs-toggle="collapse"
                data-bs-target="#collapseCountry"
                @click="handleCountry(country)"
              >
                {{ country.icon }} {{ country.ddi }}
              </li>
            </template>
            <li
              v-else
              class="text-center"
            >
              Nada
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Collapse } from "bootstrap";

export default {
  name: "CountriesCollapse",
  props: {
    notInput: {
      type: Boolean,
      default: true,
    },
    borderColor: {
      type: String,
      default: "#D2DDEC",
    },
  },
  emits: ["selectedCountry", "disabled"],
  data() {
    return {
      countries: [],
      selectedCountry: {},
      showSearch: false,
      search: "",
      focus: false,
      collapse: null,
      countryName: "",
    };
  },
  mounted() {
    if (this.user) {
      this.accountCountry();
      if (this.countryName !== "Brasil") {
        this.setFiltersIncludes(["countries"]);
        this.findFilters();
        document.addEventListener("click", this.setCollapse);

        this.collapse = new Collapse(this.$refs.collapseCountry, {
          toggle: false,
        });
      }
    }
  },
  unmounted() {
    document.removeEventListener("click", this.setCollapse);
  },
  computed: {
    ...mapGetters("manager/filters", {
      filters: "getFilters",
    }),
    ...mapGetters("auth", {
      user: "getUser",
    }),
  },
  methods: {
    ...mapActions("manager/filters", ["findFilters", "setFiltersIncludes"]),
    countryCodeToUnicodeFlag(locale) {
      return [...locale]
          .map(letter => {
            const code = letter.codePointAt(0);
            return code ? (code % 32) + 0x1_f1_e5 : undefined;
          })
          .map(n => String.fromCodePoint(n))
          .join("");
    },

    countriesIcons() {
      let flags = [];
      for (const country of this.countries) {
        flags.push({
          icon: this.countryCodeToUnicodeFlag(country.code),
          ddi: `+${country.country_code}`,
          code: country.code,
        });
      }

      return flags.filter(flag => flag.ddi.includes(this.search));
    },
    handleCountry(country) {
      this.selectedCountry = country;
      this.closeSearch();
      const removePlus = country.ddi.replace("+", "");
      this.search = "";
      this.focus = false;
      this.$emit("selectedCountry", { icon: country.icon, ddi: removePlus, code: country.code });
    },
    closeSearch() {
      setTimeout(() => {
        this.showSearch = false;
      }, 300);
    },
    setCollapse(event) {
      const show = this.$refs.countryFlag;
      const eventClick = show.contains(event.target);
      if (this.showSearch && show && !eventClick) {
        this.focus = false;
        this.collapse.hide();
        this.closeSearch();
        this.search = "";
      }
    },
    accountCountry() {
      if (!this.user.company) return
      const account = this.user.company["company-data"].data.country;
      this.countryName = account.name;
      this.selectedCountry = {
        icon: this.countryCodeToUnicodeFlag(account.code),
        ddi: `+${account.country_code}`,
        code: account.code,
      };
    },
  },
  watch: {
    filters: {
      handler(value) {
        if (value.countries) {
          this.countries = this.filters.countries;
          if (!this.user.company) return
          const country = this.user.company["company-data"].data.country;
          if (country.name !== "Brasil") {
            this.$emit("disabled", { enabled: true });
          }
          const initSelect = {
            icon: this.countryCodeToUnicodeFlag(country.code),
            ddi: `+${country.country_code}`,
            code: country.code,
          };
          this.handleCountry(initSelect);
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: none;
}

.box-collapse {
  height: 42px;
  width: 118px;
  padding: 0 8px;
  border-top-left-radius: 10px !important;
}

.box-shadow {
  box-shadow: 0 9px 12px 8px #12263f07 !important;
}

#input::-webkit-outer-spin-button,
#input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

#input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
