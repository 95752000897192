export default {
  startLoading: (state, flag) => state.loadingFlags[flag] = true,
  stopLoading: (state, flag) => state.loadingFlags[flag] = false,
  setCampaignsMetricsChartsFilters: (state, campaignsMetricsChartsFilters) =>
    state.campaignsMetricsChartsFilters = campaignsMetricsChartsFilters,
  setCampaignsMetricsCharts: (state, campaignsMetricsCharts) =>
    state.campaignsMetricsCharts = campaignsMetricsCharts,
  setCampaignsMetrics: (state, campaignsMetrics) =>
    state.campaignsMetrics = campaignsMetrics,
  setCampaignsMetricsFilters: (state, campaignsMetricsFilters) =>
    state.campaignsMetricsFilters = campaignsMetricsFilters
}
