import store from "@/store";

export default {
  path: "partners",
  components: {
    main: require("@admin/pages/3c-partners/partners/index-partners.vue").default
  },
  children: [
    {
      path: "",
      name: "partners",
      components: {
        partners: require("@admin/pages/3c-partners/partners/list-partners.vue").default
      }
    },
    {
      path: "new",
      components: {
        partners: require("@admin/pages/3c-partners/partners/new-partner.vue").default
      }
    },
    {
      path: ":id",
      components: {
        partners: require("@admin/pages/3c-partners/partners/partner-details.vue").default
      }
    }
  ],

  beforeEnter: (to, from, next) => {
    const restrictedAdminLevels = store.getters["admin/getRestrictedAdminLevels"];
    if (restrictedAdminLevels.includes(store.getters["auth/getUser"].settings.access)) next();
    else next("/");
  }
};
