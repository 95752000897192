<template>
  <div class="col-4">
    <div
      class="card card-hovered"
      :style="{ height }"
    >
      <div class="card-body">
        <span
          :class="iconClass"
          style="margin-bottom: 2.5rem;line-height: 0;font-size: 1.5rem"
        />
        <h5 class="card-title">
          {{ title }}
        </h5>
        <p class="card-text tx-text-gray">
          {{ text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardWarmup",

  props: {
    iconClass: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    height: {
      type: String,
      default: "192px"
    }
  }
};
</script>

<style scoped lang="scss">
.card-hovered:hover {
  background-color: $color-gray-blue-200;
}
</style>
