<template>
  <!-- Modal -->
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div
        class="modal-header border-0 p-4"
        style="padding-left: 1rem"
      >
        <button
          type="button"
          class="btn-close text-base"
          data-bs-dismiss="modal"
          aria-label="Close"
          style="padding-right: 1rem"
        />
      </div>
      <div class="modal-body">
        <div class="d-flex flex-column justify-content-center align-items-center w-100">
          <img
            src="@assets/img/card-expired.png"
            class="w-25 mb-4"
          >
          <h2>{{ $t('not_enough_credit') }}...</h2>
          <p class="text-base my-0">
            {{ $t('to_add_credit') }}
          </p>
        </div>
      </div>
      <div class="modal-footer d-flex flex-column justify-content-center py-4">
        <button
          type="button"
          class="btn btn-primary d-flex align-items-center m-0"
          @click="buyCredit"
        >
          <i class="fe fe-plus" />
          {{ $t('buy_credit') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["buy-store-credit"],
  methods: {
    buyCredit() {
      this.$emit("buy-store-credit");
    },
  },
};
</script>
