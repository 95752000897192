<template>
  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="offcanvasEditAction"
    aria-labelledby="offcanvasEditActionLabel"
    ref="offcanvas"
  >
    <div class="offcanvas-header">
      <h5
        class="offcanvas-title"
        id="offcanvasEditActionLabel"
      >
        {{ $t('edit_step') }}
      </h5>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        @click="offcanvas.hide()"
      />
    </div>
    <edit-sdr
      v-if="actionToEdit.type === 'sdr'"
    />
    <edit-choice
      v-else-if="actionToEdit.type === 'multiple_choice'"
    />
    <edit-transfer
      v-else-if="actionToEdit.type === 'transfer'"
    />
    <edit-message
      v-else-if="actionToEdit.need_chat_response == false"
    />
    <edit-question
      v-else-if="actionToEdit.need_chat_response"
    />
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import { Offcanvas } from "bootstrap";

import EditMessage from "./edit-message.vue";
import EditQuestion from "./edit-question.vue";
import EditChoice from "./edit-choice.vue";
import EditTransfer from "./edit-transfer.vue";
import EditSdr from "@chatbot/pages/workflow/components/offcanvas/offcanvas-edit-action/edit-sdr.vue";

export default {
  name: "OffcanvasEditAction",

  components: {
    EditSdr,
    EditTransfer,
    EditChoice,
    EditQuestion,
    EditMessage,
  },

  props:{
    show: {
      type: Boolean,
      default: false,
    },
  },

  data(){
    return{
      offcanvas: null,
    }
  },

  mounted(){
    this.offcanvas = new Offcanvas(this.$refs.offcanvas)
    this.$refs.offcanvas.addEventListener("hide.bs.offcanvas", this.onHideOffcanvas);
  },

  computed:{
    ...mapGetters("chatbot/actions", {
      actionToEdit: "getActionToEdit"
    })
  },

  methods:{
    ...mapMutations("chatbot/actions", ["setOffcanvasEditActionIsOpen"]),

    onHideOffcanvas() {
      this.setOffcanvasEditActionIsOpen(false)
    }
  },

  watch: {
    show(value){
      value? this.offcanvas.show() : this.offcanvas.hide()
    }
  }
}
</script>

