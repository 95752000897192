<template>
  <vue-multiselect
    v-model="selectedQualifications"
    :options="options"
    :group-label="'groupName'"
    :group-values="'values'"
    :group-select="false"
    :track-by="'id'"
    :label="'name'"
    :placeholder="$t('select_qualification')"
    :select-label="$t('to_select')"
    :selected-label="$t('selected')"
    :deselect-label="$t('remove_capitalize')"
    :multiple="multiple"
    :close-on-select="closeOnSelect"
  > 
    <template #noResult>
      {{ $t('no_element_found') }}
    </template>
  </vue-multiselect>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueMultiselect from "vue-multiselect";

export default {
  data() {
    return {
      options:[],
      selectedQualifications: []
    }
  },

  /**
   *
   */
  emits: ["update:modelValue"],

  /**
   *
   */
  mounted() {
    this.loadQualificationListDataSelect().then(() => {

      for(let key in this.qualificationListDataSelect.qualification_lists) {
        const qualification_list = this.qualificationListDataSelect.qualification_lists[key];
        if(this.qualificationListDataSelect.qualifications[qualification_list.id]) {
          this.options.push({
            groupName: qualification_list.name,
            values: this.qualificationListDataSelect.qualifications[qualification_list.id]
          });
        }
      }

      if(this.qualificationListDataSelect.system_qualifications) {
        this.options.push({
          groupName: this.$t('system_qualifications'),
          values: Object.values(this.qualificationListDataSelect.system_qualifications)
        });
      }
    });

  },
  /**
   *
   */
  computed: {
    ...mapGetters("manager/qualification", {
      qualificationListDataSelect: "getQualificationListDataSelect",
      loading: "isLoading",
    }),
  },

  props: {
    /**
     *
     */
    modelValue: {
      type: [Number, String],
      default: 0
    },

    /**
     * 
     */
    multiple: {
      type: Boolean,
      default: false
    },

    /**
     * 
     */
     closeOnSelect: {
      type: Boolean,
      default: true
     }
  },

  /**
   * 
   */
  components: {
    VueMultiselect
  },

  /**
   *
   */
  methods: {
    /**
     * 
     */
    ...mapActions("manager/qualification", [
      "loadQualificationListDataSelect",
    ])
  },
  
  watch: {
    selectedQualifications(data) {
      if(data) {
        const qualifications = data.map(({ id }) => id);
        this.$emit('update:modelValue', qualifications.join(","));
      }

    }
  }
};
</script>
