<template>
  <div
    class="card p-5 card-interval text-center position-absolute "
    style="top: 50%; left: 50%; transform: translate(-50%, -50%);"
    v-if="status === AgentStatus.ON_WORK_BREAK && intervalActive"
  >
    <div class="card-body p-0 overflow-ellipsis">
      <h2 class="mb-5">
        {{ readableIntervalReturnType }}
      </h2>
      <p>
        {{ intervalActive.name }} {{ intervalActive.minutes === 0 ? $t('inlimited_plan') : format(intervalActive.minutes * 60) }}
      </p>
      <h1 :class="{ 'fw-light pb-5 px-3 display': true, expired: expired }">
        {{ (expired ? "+" : "") + timerAsString }}
      </h1>
      <button
        v-if="!intervalActive.auto_start && !showRequestIntervalReturnButton"
        class="btn btn-danger text-white w-100 d-flex align-items-center justify-content-center gap-3"
        :loading="systemLoadingFlags.stopInterval"
        :disabled="systemLoadingFlags.stopInterval"
        @click="stopInterval"
      >
        <i class="fa-regular fa-right-from-bracket" />
        {{ $t('exit_interval') }}
      </button>
      <app-button
        v-if="showRequestIntervalReturnButton"
        class="text-center w-100 justify-content-center"
        color="bg-gray-blue-300 tx-text-gray"
        :disabled="loadingFlags.requestIntervalReturn || returnRequested"
        :icon="returnRequested ? 'clock' : 'rotate-left'"
        @click="requestIntervalReturn({ id: intervalActive.id })"
      >
        {{ returnRequested ? $t("return_requested") : $t("request_return") }}
      </app-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { AgentStatus, Utils, IntervalsReturnType } from "@/utils";
import AppButton from "@/components/app-button";
import timer from "@/plugins/timer";

export default {
  data() {
    return {
      AgentStatus,
    };
  },

  components: {
    AppButton,
  },

  computed: {
    ...mapGetters("system", {
      timerAsString: "getTimeAsString",
      timerTargetAchieved: "isTimerTargetAchieved",
      status: "getStatus",
      systemLoadingFlags: "getLoadingFlags",
    }),

    ...mapGetters("agent/work-break", {
      loadingFlags: "getLoadingFlags",
      expired: "isExpired",
      intervalActive: "getIntervalActive",
      returnRequested: "getReturnRequested"
    }),

    readableIntervalReturnType() {
      const { return_type } = this.intervalActive;
      switch (return_type) {
        case IntervalsReturnType.FLEXIBLE_RETURN:
          return this.$t("flexible_return");
        case IntervalsReturnType.AUTOMATIC_RETURN:
          return this.$t("automatic_return");
        case IntervalsReturnType.REQUEST_RETURN:
          return this.$t("request_return");
        default:
          return this.$t("remaining_time");
      }
    },

    showRequestIntervalReturnButton() {
      return this.intervalActive.return_type === IntervalsReturnType.REQUEST_RETURN && this.expired;
    }
  },

  watch: {
    timerTargetAchieved(isAchieved) {
      if (isAchieved && this.status === AgentStatus.ON_WORK_BREAK) {
        this.handleTimerTargetAchieved();
      }
    },
  },

  methods: {
    format: Utils.timeFormat,
    ...mapActions("agent/work-break", ["requestIntervalReturn"]),
    ...mapActions("system", ["stopInterval"]),
    ...mapMutations("agent/work-break", ["setExpired"]),
    handleTimerTargetAchieved() {
      this.setExpired(true);
      if (this.intervalActive.auto_start || this.intervalActive.return_type === IntervalsReturnType.AUTOMATIC_RETURN) {
        this.stopInterval();
        return;
      }

      this.startTimer();
    },

    startTimer() {
      timer.start({
        startValues: {
          seconds: 0,
        },
        countdown: false
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.card-interval {
  margin: auto !important;
  display: inline-block !important;
  min-width: 410px !important;
  box-shadow: 0px 4px 18px 0px #22364D1F;


  .display {
    font-size: 65px;

    &.expired {
      color: $red;
    }
  }
}
</style>
