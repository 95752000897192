export default {
  getLoadingFlag: state => state.loadingFlag,
  isLoading: state => state.loading,
  getList: state => state.list,
  getCallQualificated: state => state.callQualificated,
  getErrors: state => state.errors,
  getErrorsQualification: state => state.errorsQualification,
  getPagination: state => state.pagination,
  getAlert: state => state.alert,
  getRegister: state => state.register,
  getQualificationRegister: state => state.qualificationRegister,
  getQualificationPagination: state => state.qualificationPagination,
  getDataSelect: state => state.dataSelect,
  getQualificationListDataSelect: state => state.qualificationListDataSelect,
  getEachQualificationListDataSelect: state => state.eachQualificationListDataSelect,
}
