<template>
  <div>
    <VueMultiselect
      v-if="multiple"
      v-model="selecteds"
      label="name"
      track-by="name"
      :placeholder="$t('select_agent')"
      :select-label="$t('click_or_enter_to_select')"
      :select-group-label="$t('click_or_enter_to_select')"
      :deselect-label="$t('click_or_enter_to_remove')"
      :deselect-group-label="$t('click_or_enter_to_remove')"
      :selected-label="$t('selected')"
      :multiple="true"
      :close-on-select="false"
      :preserve-search="true"
      :options="ordenedAgents"
      group-values="agents"
      group-label="type"
      :group-select="true"
    >
      <template #selection="{ values, isOpen }">
        <span
          class="multiselect__single"
          v-if="values.length == 1"
          v-show="!isOpen"
        >{{ values.length }} {{ $t('selected_agent') }}</span>
        <span
          class="multiselect__single"
          v-if="values.length > 1"
          v-show="!isOpen"
        >{{ values.length }} {{ $t('selected_agents') }}</span>
      </template>
    </VueMultiselect>

    <vue-multiselect
      v-else
      v-model="agent"
      :options="ordenedAgents"
      :track-by="'id'"
      :label="'name'"
      :placeholder="$t('select_agent')"
      :select-label="$t('to_select')"
      :selected-label="$t('selected')"
      :deselect-label="'Remover'"
      :disabled="loading"
      :group-label="'type'"
      :group-values="'agents'"
      :group-select="false"
      @select="updateValue"
      @remove="removeValue"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueMultiselect from "vue-multiselect";

export default {
  name: "SelectAgent",

  data() {
    return {
      selecteds: [],
      agent: "",
      ordenedAgents: [
        {
          type: this.$t('actives'),
          agents: [
          ]
        },
        {
          type: this.$t('inactives_capitalize'),
          agents: [
          ]
        }
      ],
    };
  },

  emits: ["update:modelValue"],

  mounted() {
    this.loadDataSelect().then(() => {
      this.dataSelect.forEach(agent => {
        if (agent.active) {
          this.ordenedAgents[0].agents.push(agent)
        } else {
          this.ordenedAgents[1].agents.push(agent)
        }
      });
    })
  },

  components: {
    VueMultiselect,
  },
  watch: {
    selecteds: {
      handler(data) {
        let ids = [];
        for (let item of data) ids.push(item.id);
        this.$emit("update:modelValue", ids.join(","));
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters("manager/agent", {
      dataSelect: "getDataSelect",
      loading: "isLoading",
    }),
  },

  props: {
    modelValue: {
      type: [String, Array, Number],
      default: "",
    },

    multiple: {
      type: Boolean,
      default: false,
    },
  },

  methods: {

    ...mapActions("manager/agent", ["loadDataSelect"]),

    updateValue(value) {
      this.$emit('update:modelValue', value.id);
    },

    removeValue() {
      this.$emit('update:modelValue', "");
    },
  },
};
</script>
