<template>
  <div class="card mb-4">
    <div class="card-header">
      <search-input
        @search="search"
        :placeholder="$t('placeholders.search_capitalize')"
      />
    </div>
    <div class="card-body p-0">
      <div class="tab-content">
        <div
          class="tab-pane fade show table-responsive active"
          id="activeCampaigns"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <table class="table m-0">
            <thead>
              <tr>
                <th>{{ $t("route_name") }}</th>
                <th>IP</th>
                <th>{{ $t("authentication_type") }}</th>
                <th>TECHPREFIX</th>
                <th>{{ $t("unproductive_call_today") }}</th>
                <th>
                  {{ $t("extend_cpx") }}
                  <tooltip-component :title="$t('tooltips.extend_cpx')" />
                </th>
              </tr>
            </thead>
            <tbody class="text-base">
              <loading-table-component
                v-if="loadingFlags.listRoutes"
                :lines="15"
                :columns="6"
                :text-center="false"
                :list-width="[20, 100, 20, 20, 150, 20]"
              />

              <tr
                v-else-if="routes.length"
                v-for="(route, index) of routes"
                :key="index"
              >
                <td
                  class="text-truncate overflow-ellipsis route-name"
                  :title="route.name"
                >
                  {{ route.name }}
                </td>

                <td class="text-muted">
                  {{ route.host.split(":")[0] }}
                </td>
                <td class="text-muted">
                  {{ route.authentication_type }}
                </td>
                <td class="text-muted">
                  {{ route.host.split(":")[1] }}
                </td>
                <td
                  class="text-muted"
                  v-if="route['route-daily-statistics']"
                >
                  <div class="d-flex align-items-center gap-3">
                    <div>
                      {{ route["route-daily-statistics"][0]?.short_calls || 0 }}
                    </div>
                    <progress-bar
                      class="flex-fill"
                      style="min-width: 100px; height: 6px"
                      :use-icon="false"
                      :data="[
                        {
                          color: 'var(--color-yellow-400)',
                          percentage: percentageUnproductive(route),
                          count: route['route-daily-statistics'][0]?.short_calls || 0,
                          icon: 'fa-circle',
                          title: $t('unproductive')
                        },
                        {
                          color: 'var(--color-green-400)',
                          percentage: percentageProductive(route),
                          count: route['route-daily-statistics'][0]?.long_calls || 0,
                          icon: 'fa-circle',
                          title: $t('productive')
                        }
                      ]"
                    />
                    <div>
                      ({{ percentageUnproductive(route) }}%)
                    </div>
                  </div>
                </td>
                <td
                  class="text-muted"
                  v-else
                />
                <td class="text-muted">
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      :disabled="route.endpoint.includes('PJSIP/3cplus_')"
                      type="checkbox"
                      id="switchOne"
                      v-model="route.short_call_extends"
                      @change="updateExtendCpx({
                        id: route.id,
                        short_call_extends: route.short_call_extends,
                      })"
                    >
                  </div>
                </td>
              </tr>

              <tr v-else>
                <td
                  class="text-center text-muted"
                  colspan="6"
                >
                  <span class="fa fa-alert-octagon" /> {{ $t("no_routes_available") }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      class="card-footer"
      v-if="pagination.telephony_routes && pagination.telephony_routes.total_pages > 1"
    >
      <app-paginator
        v-if="!loadingFlags.listRoutes"
        :pagination="pagination.telephony_routes"
        @change-page="changePage"
      />
    </div>
  </div>
</template>

<script>
import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";
import SearchInput from "@components/search-input.vue";
import { mapActions, mapGetters } from "vuex";
import ProgressBar from "@components/progressbar-multiple.vue";
import TooltipComponent from "@components/tooltip-component.vue";
import AppPaginator from "@components/app-paginator.vue";
import _ from "lodash";

export default {
  name: "RoutesTable",
  components: { AppPaginator, TooltipComponent, ProgressBar, SearchInput, LoadingTableComponent },
  computed: {
    ...mapGetters("manager/telephony", {
      loadingFlags: "getLoadingFlags",
      routes: "getRoutes",
      pagination: "getPagination",
    }),
  },
  methods: {
    ...mapActions("manager/telephony", ["setCurrentPage", "listRoutes", "updateExtendCpx", "setCurrentPage", "setRoutesFilters"]),
    search: _.debounce(function (event) {
      this.setRoutesFilters({
        search: event,
        flag: "telephony_routes",
      });
      this.changePage(1)
    }, 300),
    percentageUnproductive(route) {
      return (((route["route-daily-statistics"][0]?.short_calls || 0) / route["route-daily-statistics"][0]?.total) * 100 || 0).toFixed(2);
    },
    percentageProductive(route) {
      return (((route["route-daily-statistics"][0]?.long_calls || 0) / route["route-daily-statistics"][0]?.total) * 100 || 0).toFixed(2);

    },
    changePage(page) {
      this.setCurrentPage({ page, flag: "telephony_routes" });
      this.listRoutes();
    },
  },
  mounted() {
    this.listRoutes();
  },
};
</script>

<style scoped lang="scss">
.route-name {
  max-width: 14rem;
}
</style>
