<template>
  <div
    v-if="message && !closed"
    :class="{
      'alert alert-dismissible fade show mb-5': true,
      'alert-danger': type == 'danger',
      'alert-success': type == 'success',
      'alert-info': type == 'info',
    }"
  >
    <span class="fe fe-alert-octagon" />&nbsp;{{ message }}
    <button
      type="button"
      class="btn-close"
      @click="closed = true"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  /**
   *
   */
  data() {
    return {
      message: null,
      type: "",
      closed: true,
    };
  },

  /**
   *
   */

  computed: {
    ...mapGetters("system", {
      flashMessage: "getFlashMessage",
    }),
  },

  /**
   *
   */
  watch: {
    flashMessage(val) {
      if (val) {
        this.message = val.message;
        this.type = val.type;
        this.closed = false;
        window.scrollTo(0, 0);
        this.setFlashMessage(null);
      }
    },
  },

  /**
   *
   */
  methods: {
    ...mapMutations("system", ["setFlashMessage"]),
  },
};
</script>
