<template>
  <div>
    <div
      class="alert bg-gray-blue-200 d-flex justify-content-between align-items-center text-tag-blue border shadow-sm mb-0"
      role="alert"
      v-if="closeAlert"
    >
      <div class="d-flex align-items-center">
        <i
          class="far fa-lightbulb-on"
          style="margin-right: 16px;"
        />
        <p class="mb-0">
          {{ text }}
        </p>
      </div>
      <i
        v-if="buttonCloseAlert"
        class="fal fa-times cursor-pointer"
        @click="closeAlert = false"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showAlert: {
      type: Boolean,
      default: true
    },
    buttonCloseAlert: {
      type: Boolean,
      default: true
    },
    text: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      closeAlert: true
    };
  }
};
</script>

<style lang="scss" scoped>
.bg-gray-blue-200 {
  background-color: $color-gray-blue-200;
}
.text-tag-blue {
  color: $color-blue-500;
}
.shadow-sm {
  box-shadow: 0 0 2px 0 #e1e9f4 !important;
}
</style>
