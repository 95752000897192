<template>
  <div
    class="d-flex flex-wrap"
    :class="{
      'gap-4': showAudio || showKeysConfig && showAdvancedConfig,
    }"
  >
    <div
      class="card mb-0 col-12"
      :class="{
        'border-custom': showAudio,
      }"
    >
      <div
        class="card collapse resume p-4 mb-0 "
        id="receptiveIvrAudio"
      >
        <div class="d-flex pb-0">
          <h4>{{ $t("abstract") }}</h4>
        </div>
        <div>
          <audio-player
            v-if="fields.audio"
            :file="fields?.audio"
            :bg-button="false"
            class="bg-gray-blue-100 p-custom rounded border-custom"
          />
        </div>
      </div>
      <div
        class="card-body collapse card-keys"
        id="receptiveIvrResumeKeys"
      >
        <h4 class="tx-text-200 h4-custom ">
          {{ $t("keys_settings") }}
        </h4>
        <div
          class="d-flex flex-wrap gap-custom"
        >
          <template
            v-for="(key, i) in fields.keys"
            :key="i"
          >
            <div
              v-if="key.key !== $t('capitalizes.none_key') && (key.id && key.key)"
              class="d-flex gap-2 align-items-center"
            >
              <h5 class="flex-shrink-0 mb-0">
                {{ $t("key") }} {{ key.key }}:
              </h5>
              <span
                :title="getNameRedirect(key.id)"
                class="text-truncate"
              >{{ getNameRedirect(key.id) }}</span>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div
      class="card collapse col-12 "

      :class="{
        'border-custom': showAdvancedConfig,
      }"
      id="receptiveIvrResumeAdvanced"
    >
      <div class="card-body">
        <h4 class="tx-text-200 h4-custom">
          {{ $t("advanced_settings") }}
        </h4>
        <div
          class="d-flex flex-wrap gap-custom"
        >
          <div
            v-if=" fields.timeout"
            class="d-flex gap-2 align-items-center"
          >
            <h5 class=" mb-0">
              {{ $t("waiting_time") }}:
            </h5>
            <span>{{ fields.timeout }}s</span>
          </div>
          <div
            v-if="fields.redirect_id"
            class="d-flex gap-2 align-items-center"
          >
            <h5 class="mb-0 text-nowrap">
              {{ $t("no_key_pressed") }}:
            </h5>
            <span
              :title="getNameRedirect(fields.redirect_id)"
              class="text-truncate"
            >{{ getNameRedirect(fields.redirect_id) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Collapse } from "bootstrap";
import AudioPlayer from "@components/audio-player.vue";
import { mapGetters } from "vuex";

export default {
  name: "ResumeProgress",
  components: {
    AudioPlayer,
  },
  props: {
    fields: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      receptiveIvrAudio: null,
      receptiveIvrResumeKeys: null,
      receptiveIvrResumeAdvanced: null,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.receptiveIvrAudio = new Collapse("#receptiveIvrAudio", {
        toggle: false,
      });
      this.receptiveIvrResumeKeys = new Collapse("#receptiveIvrResumeKeys", {
        toggle: false,
      });
      this.receptiveIvrResumeAdvanced = new Collapse("#receptiveIvrResumeAdvanced", {
        toggle: false,
      });
      this.$nextTick(() => {
        this.showAudio ? this.receptiveIvrAudio.show() : this.receptiveIvrAudio.hide();
        this.showKeysConfig ? this.receptiveIvrResumeKeys.show() : this.receptiveIvrResumeKeys.hide();
        this.showAdvancedConfig ? this.receptiveIvrResumeAdvanced.show() : this.receptiveIvrResumeAdvanced.hide();
      });
    });
  },

  methods: {
    getNameRedirect(id) {
      return this.receptiveQueues.find(queue => queue.id == id)?.name;
    },
  },
  computed: {
    ...mapGetters("manager/filters", {
      receptiveQueues: "getReceptiveQueues",
      loadingFlags: "getLoadingFlags",
    }),

    showAudio() {
      return !!this.fields.audio;
    },

    showKeysConfig() {
      return !!this.fields.keys.some(key => key.key !== this.$t("capitalizes.none_key") && (key.id && key.key));
    },

    showAdvancedConfig() {
      return !!(this.fields.timeout || this.fields.keys.some(key => key.key === this.$t("capitalizes.none_key") && key.id));
    },
  },

  watch: {
    showAudio(value) {
      value ? this.receptiveIvrAudio?.show() : this.receptiveIvrAudio?.hide();
    },
    showKeysConfig(value) {
      value ? this.receptiveIvrResumeKeys?.show() : this.receptiveIvrResumeKeys?.hide();
    },
    showAdvancedConfig(value) {
      value ? this.receptiveIvrResumeAdvanced?.show() : this.receptiveIvrResumeAdvanced?.hide();
    },
  },
}
</script>
<style scoped lang="scss">
.resume {
  background: url("~@/assets/img/covers/grainy-cover.png") 0px 0px / 100% 100% no-repeat;
  border-bottom: 1px solid $color-gray-blue-300;
  border-radius: 0.625rem;
}

.h4-custom {
  padding-bottom: 1rem;
  margin-bottom: 1rem !important;
  border-bottom: 1px solid $color-gray-blue-300;
}

.gap-custom {
  gap: 0.5rem;
}

.p-custom {
  padding: 0 1rem 0 0.5rem;
}
</style>

