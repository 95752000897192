<template>
  <hover-button
    icon="fas fa-power-off"
    :should-show-close-button="false"
    @click="_updateRingGroupStatus"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import HoverButton from '@dialer/agent/components/hover-button.vue';

export default {
  name: "ToggleRingGroupButton",
  components: {
    HoverButton,
  },
  computed: {
    ...mapGetters("agent/ring_group", {
      isRingGroupActive: "isRingGroupActive",
    }),
  },
  methods: {
    ...mapActions("agent/ring_group", ["updateRingGroupStatus"]),
    _updateRingGroupStatus() {
      this.updateRingGroupStatus(!this.isRingGroupActive);
    }
  }
}
</script>
