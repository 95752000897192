
export default {
    isLoading: state => state.loading,
    getLoadingFlags: state => state.loadingFlags,
    getListAgentsOnline: state => state.listAgentsOnline,
    getErrorMessage: state => state.errorMessage,
    getErrors: state => state.errors,
    getLoadingContext: state => state.loadingContext,
    getCampaign: state => state.campaign,
    getDataSelect: (state) => state.dataSelect,
    getAgentStats: (state) => state.agentStats,
    getAgent: (state) => state.agent,
    getTimeCalls: (state) => state.timeCalls,
    getCalls: (state) => state.calls,
    getActiveDropdownLeft: (state) => state.activeDropdownLeft,
    getActiveDropdownRight: (state) => state.activeDropdownRight,
}
