<template>
  <main-loading v-if="loadingFlags.findGroupChannelById" />
  <div v-else>
    <!-- <connect-number-modal
      v-if="
        isManager && groupChannel &&
          (groupChannel.status == 'disconnected' ||
            (groupChannel.status == 'deleted' &&
              user.company.whatsapp_licenses > totalActive))
      "
      :groupChannel-id="groupChannel.id"
      :status="groupChannel.status"
    /> -->
    <div class="main-content">
      <div class="container-fluid">
        <header-component
          :title="groupChannel.name"
          pretitle="OMNICHANNEL"
          redirect-link="/manager/whatsapp"
        >
          <template #buttons>
            <div
              v-if="isManager"
              class="d-flex gap-4"
            >
              <router-link
                v-if="groupChannel.status !== 'deleted'"
                :to="'/manager/whatsapp/' + $route.params.id + '/edit'"
                class="btn btn-gray-blue px-5"
              >
                <i
                  class="fa-regular fa-gear me-2"
                />
                {{ $t('settings_omni') }}
              </router-link>
              <app-button
                color="btn-gray-blue px-5"
                @click="reactivate"
                v-else
              >
                {{ $t('reactivate') }}
              </app-button>
              <router-link
                style="min-width: 259px;"
                class="btn btn-primary"
                v-if="
                  isSupervisorOrManager &&
                    user.company.whatsapp_licenses > totalActive
                "
                :to="{
                  path: '/whatsapp'
                }"
              >
                <i class="fa-regular fa-messages me-1" />
                {{ $t('access_chats') }}
              </router-link>
            </div>
          </template>
        </header-component>
        <div
          v-if="groupChannel?.status !== 'deleted'"
          class="col-12 col-lg-12 col-xl-12"
        >
          <list-metrics v-if="groupChannel.status !== 'deleted'" />
          <div
            v-if="instancesStatus &&
              !loadingFlags.findGroupChannelById &&
              !loadingFlags.findGroupChannelInstanceById"
            class="row"
          >
            <div
              class="col-12 col-sm-6 col-md-6 mb-4"
              v-for="(item, id) in groupChannel.channels.data"
              :key="id"
            >
              <instances-metrics
                :item="item"
                :channel="item.type === 'ultraListAgentsmsg' ?
                  'WhatsApp 3C+' :
                  'WABA'"
              />
            </div>
          </div>
          <list-agents />
        </div>
        <div
          v-else
          class="col-12"
        >
          <div class="card shadow-card">
            <div class="card-body">
              <div class="d-flex gap-4 flex-wrap justify-content-center align-items-center">
                <img
                  alt="Empty"
                  width="106"
                  height="88"
                  loading="lazy"
                  :src="PluzinhaXMark"
                  class="d-inline-block"
                >
                <div style="width: 250px; max-width: 100%;">
                  <p class="mb-0 fw-medium">
                    {{ titleMsg }}
                    <ellipsis-animation :loading="reactivateLoading" />
                  </p>
                  <p class="mb-0 h6 fw-normal">
                    {{ subTitleMsg }}
                  </p>
                  <router-link
                    style="margin-top: 1rem"
                    class="btn btn-primary"
                    v-if="
                      isSupervisorOrManager &&
                        user.company.whatsapp_licenses > totalActive &&
                        !reactivateLoading
                    "
                    :to="{
                      path: '/whatsapp'
                    }"
                  >
                    <i class="fa-regular fa-messages me-1" />
                    {{ $t('access_chats') }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainLoading from "@/components/main-loading.vue";
import ListMetrics from "./components/list-metrics.vue";
import ListAgents from "./components/list-agents.vue";
import InstancesMetrics from "./components/instances-metrics.vue"
import HeaderComponent from "@/components/header-component.vue";

import {mapGetters, mapActions, mapMutations} from "vuex";
import AppButton from "@components/app-button.vue";
import PluzinhaXMark from "@/assets/img/pluzinha-x-mark.svg";
import EllipsisAnimation from "@components/ellipsis-animation.vue";

export default {
  components: {
    EllipsisAnimation,
    AppButton,
    MainLoading,
    ListAgents,
    ListMetrics,
    InstancesMetrics,
    // NotificationCard,
    // ConnectNumberModal,
    HeaderComponent
  },

  data() {
    return {
      PluzinhaXMark,
      reactivateLoading: false
    }
  },

  created() {
    this.fetchWhatsappDashboardById({group_channel_id: this.$route.params.id})
    this.findGroupChannelById({id: this.$route.params.id}).then(() => {
      this.groupChannel.channels.data.forEach((item) => {
        this.findGroupChannelInstanceById({id: item.id})
      })
    })
  },

  mounted() {
    this.clearGroupChannelInstance()
    // this.setInstanceId(this.$route.params.id);
    localStorage.setItem("instanceId", this.$route.params.id);
  },

  computed: {
    ...mapGetters("manager/whatsapp", {
      metrics: "getWhatsappMetrics",
      instance: "getInstance",
      activeInstances: "getActiveInstances",
      totalActive: "getTotalActiveInstances"
    }),
    ...mapGetters("manager/whatsapp_group_channel", {
      groupChannel: "getGroupChannel",
      loadingFlags: "getLoadingFlags",
    }),
    ...mapGetters("auth", {
      user: "getUser",
      isManager: "isManager",
      isSupervisorOrManager: "isSupervisorOrManager",
    }),

    instancesStatus() {
      if (this.groupChannel.channels.data.length < 2)
        return false
      return this.groupChannel.channels.data.every((el) => el.status === 'connected')
    },

    titleMsg() {
      return !this.reactivateLoading ? this.$t('group_channel_messages.deleted') : this.$t('group_channel_messages.reactivating');
    },

    subTitleMsg() {
      return !this.reactivateLoading ? this.$t('group_channel_messages.see') : this.$t('group_channel_messages.wait');
    }
  },

  methods: {
    ...mapActions("system", [
      "showConfirmationMessage"
    ]),
    ...mapActions("manager/whatsapp_group_channel", [
      "findGroupChannelById",
      "findGroupChannelInstanceById"
    ]),
    ...mapMutations("manager/whatsapp_group_channel", [
      "clearGroupChannelInstance",
    ]),
    ...mapActions("manager/whatsapp_group_channel", [
      "reactivateGroupChannel",
    ]),
    ...mapActions("manager/whatsapp", [
      "fetchWhatsappDashboardById",
    ]),

    ...mapMutations("manager/whatsapp", [
      "setInstanceId"
    ]),

    async reactivate() {
      let response = await this.showConfirmationMessage({
        title: this.$t('reactivate_connection'),
        text: "",
        type: "goodWarning"
      });

      if (response.isConfirmed) {
        this.reactivateLoading = true
        this.reactivateGroupChannel({id: this.$route.params.id}).then(() => {
          this.$router.push({path: '/manager/whatsapp'})
          this.reactivateLoading = false;
        })
      }
    },

  }
};
</script>

<style lang="scss" scoped>
.btn-gray-blue {
  background-color: #E1E9F4;
  color: #677C92;
  border: none;

  &:hover,
  &:focus,
  &:focus-visible,
  &:active {
    background-color: #D2DDEC;
  }
}

.shadow-card {
  box-shadow: 0 12px 24px 0 rgba(18, 38, 63, 0.03);
  border-color: #E1E9F4;

  .card-body {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
</style>
