<template>
  <div class="d-flex align-items-center">
    <div
      class="card m-0 card-choice"
      @click="addOrEdit"
    >
      <div class="card-body p-0">
        <div
          class="d-flex align-items-center card-body-choice"
        >
          <action-component
            icon-class="fa-regular fa-message-check"
            icon-color="#5CB868"
            :name="$t('multiple_choice_question')"
          />
        </div>
        <hr class="w-100">
        <div
          v-if="!action.text_content"
          class="d-flex align-items-center justify-content-center mb-3"
        >
          <card-text text="" />
        </div>
        <div
          v-else
          class="d-flex flex-column justify-content-center choices"
        >
          <card-text :text="action.text_content" />

          <div
            class="d-flex flex-column mt-3 gap-3"
          >
            <div
              v-for="(item, index) in choices"
              :key="index"
            >
              <div class="position-relative">
                <div class="d-flex align-items-center bolder div-option">
                  <p class="m-0 px-2 fw-medium">
                    {{ index + 1 }}
                  </p>
                  <card-text
                    class="w-100"
                    :text="item.text_content"
                    :is-choice-item="true"
                  />
                </div>
                <handle
                  type="source"
                  :id="`handle-${item.id}`"
                  connectable="single"
                  :position="Position.Right"
                  class="handle"
                  :class="{'disconnected-handle':isDisconnected(item)}"
                  @click.stop
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

import ActionComponent from "@chatbot/pages/workflow/components/action-component.vue";
import CardText from "@chatbot/pages/workflow/components/card-text.vue";
import {Handle, Position} from "@vue-flow/core";

export default {
  name: "CardChoice",

  props: {
    action: {
      type: Object,
      required: true
    },
    choices: {
      type: Object,
      required: true
    }
  },

  components: {
    Handle,
    CardText,
    ActionComponent
  },
  computed: {
    Position() {
      return Position
    }
  },

  methods: {
    ...mapMutations("chatbot/actions", [
      "setOffcanvasEditActionIsOpen",
      "setOffcanvasNewActionIsOpen",
      "setSelectedAction",
      "setPrevActionId",
      "setActionToEdit"
    ]),

    editAction() {
      this.setSelectedAction(this.action)
      this.setActionToEdit(this.action);
      this.setOffcanvasEditActionIsOpen(true);
    },
    addOrEdit(){
      if(!this.action?.text_content){
        this.addText()
      }else{
        this.editAction()
      }
    },
    addText(){
      this.setPrevActionId(this.action.previous_action_id)
      this.setOffcanvasNewActionIsOpen(true)
      this.setSelectedAction({
        id: this.action.id,
        type: "multiple_choice",
        position: this.action.position
      });
    },
    isDisconnected(item){
      return !item.next_action_id && !item.default_next_action
    }
  }
};
</script>
<style scoped lang="scss">
.handle{
  z-index: 1;
  width: 12px;
  height: 12px;
  right: 15px;
  background-color:#AFAFAF;
}
.card-choice{
  min-width: 350px;
  height: min-content;
  &:hover{
    border: 1px solid #5CB868;
  }
}
.card-body-choice{
  padding: 1rem 1rem 0 1rem;
  width: 350px
}
.choices{
  padding: 0 1rem 1rem 1rem;
  width: 350px
}
.div-option{
  background-color: #F0F4FA;
  color: #677C92;
  border-radius: 10px;
}
.disconnected-handle{
  background-color: #FFF;
  border: 2px solid #AFAFAF;
}
</style>
