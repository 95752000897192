<template>
  <div class="main-content h-100">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div
        class="card fa-card"
        style="width: 628px;"
      >
        <div class="card-header p-0">
          <h1 class="text-center m-0">
            Verificação em duas etapas
          </h1>
        </div>
        <div class="card-body p-0">
          <div
            style="margin: 32px 0;"
            class="text-center"
          >
            <span
              class="text-center m-0 py-2"
              style="background-color: #F7EAC4;"
              v-if="hasTwoFa.allow_two_factor_setup_skip"
            >
              {{
                `Você tem até o dia ${timestampToDate(
                  hasTwoFa.allow_two_factor_setup_skip_until,
                  "DD/MM/YYYY"
                )} para ativar a Verificação em duas etapas`
              }}
            </span>
          </div>
          <div
            class="text-center"
            style="padding-bottom: 32px;"
          >
            <img
              :src="hasTwoFa.qr_code_image"
              width="174"
            >
            <div
              class="cursor-pointer mt-2 tx-blue-300 pt-0"
              style="padding-bottom: 10px;"
              @click.stop="copyTextToClipboard(hasTwoFa.secret)"
            >
              <i class="fa-solid fa-copy me-3" />
              <span>Copiar código</span>
            </div>
            <p class="tx-text-gray h5">
              {{ hasTwoFa.secret.match(/.{1,4}/g).join(" ") }}
            </p>
          </div>
          <div style="padding-bottom: 32px;">
            <div class="mb-4 h4">
              <span class="fw-normal m-0 pe-1">
                1. Baixe o app
              </span>
              <span>
                <img
                  src="@/assets/img/google-authenticator.svg"
                  alt=""
                >
              </span>
              <span class="m-0 ps-1">
                Google authenticator
              </span>
            </div>
            <h4 class="fw-normal mb-4">
              2. Faça a leitura do QR code ou copie a chave para ativar o app de autenticação
            </h4>
            <h4 class="fw-normal mb-4">
              3. Clique em avançar e insira o código de 6 dígitos fornecido pelo app
            </h4>
          </div>
          <div class="text-center">
            <app-button
              color="btn-primary"
              class="col-7 my-3 text-center"
              @click="
                $router.push({
                  name: 'two-fa-code'
                })
              "
            >
              Avançar
            </app-button>
          </div>
          <div
            class="text-center"
            v-if="hasTwoFa.allow_two_factor_setup_skip"
          >
            <button
              class="btn mt-3 col-7 tx-text-gray config-after"
              @click="skip"
            >
              Configurar depois
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import AppButton from "@/components/app-button.vue";
import { Utils } from "@/utils";

export default {
  components: {
    AppButton
  },

  computed: {
    ...mapGetters("auth", {
      hasTwoFa: "hasTwoFa",
      authenticated: "isAuthenticated",
      isSupervisorOrManager: "isSupervisorOrManager",
      isAdmin: "isAdmin",
      userLogin: "getUserLogin"
    })
  },

  mounted() {
    window.addEventListener("keydown", this.handleKeyDown);
  },

  beforeUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
  },

  watch: {
    authenticated(authenticated) {
      if (authenticated && this.hasTwoFa.two_factor_location_status == "register_required") {
        if (this.isAgent) return this.$router.push(this.onlyHaveWhatsapp ? "/whatsapp" : "/agent");

        if (this.isSupervisorOrManager) {
          this.$router.push("/manager");
          this.setIncludes(["campaigns-landline-routes", "campaigns-mobile-routes", "company"]);
          return this.loadRoutes();
        }

        if (this.isAdmin) return this.$router.push("/admin");
        if (this.isPartner) return this.$router.push("/partner");
      }
    }
  },

  methods: {
    ...mapActions("auth", ["login"]),
    ...mapActions("manager/voice_3c", ["loadRoutes"]),
    ...mapMutations("manager/voice_3c", ["setIncludes"]),

    timestampToDate: Utils.timestampToDate,

    skip() {
      this.login({
        user: this.userLogin.user,
        password: this.userLogin.password,
        token_type: this.userLogin.token_type,
        two_factor_setup_skip: true,
        location_identifier: this.hasTwoFa.location_identifier
      });
    },

    copyTextToClipboard(textToCopy) {
      navigator.permissions.query({ name: "clipboard-write" }).then(result => {
        if (result.state === "granted" || result.state === "prompt") {
          navigator.clipboard.writeText(textToCopy);
          this.copyToClipboardInfo = "Copiado com sucesso";
        }
      });
    },

    handleKeyDown(event) {
      if (event.key === "Enter") {
        this.$router.push({ name: "two-fa-code" });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.fa-card {
  box-shadow: 0px 4px 18px 0px rgba(34, 54, 77, 0.12);
  z-index: 1050 !important;
  padding: 32px;

  .config-after {
    &:hover {
      background-color: $color-blue-300 !important;
      color: white !important;
    }
  }
}
</style>
