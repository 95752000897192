<template>
  <div :class="{ 'table-loading': true, transparent: transparent }">
    <div
      class="d-flex flex-row"
      v-for="i in rows"
      :key="i"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rows: {
      type: Number,
      default: 1,
    },
    transparent: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
td.loading {
  pointer-events: none;
}

.table-loading {
  &.transparent {
    mask-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(0, 0, 0, 1)),
      to(rgba(0, 0, 0, 0))
    ) !important;
  }

  .flex-row {
    border-bottom: 1px solid rgb(237, 242, 249) !important;
    .cell {
      height: 26px;
      margin: 13px;
      background: linear-gradient(110deg, #F0F4FA 8%, #F9FBFD 18%, #F0F4FA 33%);
      background-size: 500px 100px;
      animation-name: moving-gradient;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }
  }

  @keyframes moving-gradient {
    0% {
      background-position: -250px 0;
    }
    100% {
      background-position: 250px 0;
    }
  }
}
</style>
