<template>
  <div
    ref="canvas"
    class="offcanvas offcanvas-end"
  >
    <div
      class="offcanvas-header"
      style="border: none"
    >
      <button
        aria-label="Close"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        type="button"
      />
    </div>
    <div class="offcanvas-body">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h2 style="font-weight: 100">
            {{ mode == "edit" ? "Editar" : "Criar" }} tag
          </h2>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-7">
          <app-input
            label="Nome"
            v-model="fields.name"
            :type="'text'"
            :errors="errors.name"
          />
        </div>
        <div class="col-lg-5">
          <label class="form-label required">{{ $t("color_general") }}</label>
          <color-picker-dropdown
            v-model="fields.color"
            :errors="errors.color && errors.color[0]"
          />
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-label">
              Empresas
            </label>
            <VueMultiselect
              v-model="fields.companies"
              :close-on-select="false"
              :deselect-label="$t('click_or_press_enter_to_remove')"
              :limit="10"
              :multiple="true"
              :options="listCompanies"
              :preserve-search="true"
              :select-label="$t('click_or_press_enter_to_select')"
              :selected-label="$t('selected')"
              class="teste"
              label="name"
              open-direction="bottom"
              placeholder="Selecione as empresas"
              track-by="id"
              :loading="loadingFlag.findCompaniesWithTags"
            />
          </div>
        </div>
      </div>
      <button
        class="col-12 btn btn-primary"
        @click="submit()"
      >
        {{ $t("capitalizes.save") }}
      </button>
      <div
        v-if="mode == 'edit'"
        class="d-flex justify-content-center my-3"
      >
        <button
          class="btn btn-outline-danger border-0 text-center py-2"
          @click="removeTag()"
        >
          <span
            class="fa-regular fa-trash"
          />
          <label class="form-label my-0 cursor-pointer">Excluir Tag</label>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { Offcanvas } from "bootstrap";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { colors } from "@/utils";
import VueMultiselect from "vue-multiselect";

import ColorPickerDropdown from "@/components/color-picker-dropdown.vue";
import AppInput from "@components/app-input.vue";

export default {
  data() {
    return {
      fields: {
        id: "",
        name: "",
        color: "",
        companies: [],
      },
      colors: [],
      instanceCreated: false,
      offcanvas: null,
      mode: "",
    }
  },
  mounted() {
    this.colors = colors
    this.$store.dispatch("admin/tags/findCompanies")
    this.offcanvas = new Offcanvas(this.$refs.canvas)
    this.findCompaniesWithTags()
  },
  watch: {
    companiesInTags() {
      this.register()
    },
  },

  components: {
    AppInput,
    VueMultiselect,
    ColorPickerDropdown,
  },

  methods: {
    ...mapActions("admin/tags", ["createTags", "editTags", "deleteTags", "findCompaniesWithTags", "findTags", "setErrors"]),
    ...mapActions("system", ["showConfirmationMessage"]),
    ...mapMutations("system", ["setSuccessMessage"]),

    submit() {
      if (this.mode == "edit") {
        this.editTags({
          id: this.fields.id,
          color: this.fields.color,
          name: this.fields.name,
          companies: this.fields?.companies?.map((company) => company.id),
        })
            .then((id) => {
              this.offcanvas.hide();
              this.findTags({ id: id });
              this.findCompaniesWithTags()
            })
            .catch((data) => {
              this.errorMessage = data.message;
            });
      }
      if (this.mode == "new") {
        this.createTags({
          color: this.fields.color,
          name: this.fields.name,
          companies: this.fields?.companies?.map((company) => company.id),
        })
            .then((id) => {
              this.offcanvas.hide();
              this.findTags({ id: id });
              this.findCompaniesWithTags()
            })
            .catch((data) => {
              this.errorMessage = data.message;
            });
      }
    },
    edit(item) {
      this.setErrors([])
      this.mode = "edit";
      this.fields = {
        id: item.id,
        name: item.name,
        color: item.color.toLowerCase(),
      };
      this.register()
      this.offcanvas.show();
    },
    new() {
      this.setErrors([])
      this.mode = "new";
      this.fields = {
        name: "",
        color: "",
      };
      this.offcanvas.show();
    },

    register() {
      const companyArray = [];
      this.companiesInTags.map((company) => {
        company.tags.map((tag) => {
          if (tag.id === this.fields.id) {
            companyArray.push(company)
          }
        })
      })
      this.fields.companies = companyArray
    },

    async removeTag() {
      let id = this.fields.id

      let response = await this.showConfirmationMessage({
        title: "Tem certeza que deseja excluir esta tag?",
        text: "A exclusão da tag não poderá ser desfeita.",
        type: "badWarning",
      })

      if (response.isConfirmed) {
        await this.deleteTags({
          id: id,
        })
        this.offcanvas.hide();
        this.findTags({ id: id });
      }
    },
  },

  computed: {
    ...mapGetters("admin/tags", {
      listCompanies: "getListCompanies",
      companiesInTags: "getAllList",
      errors: "getErrors",
      loadingFlag: "getLoadingFlags",
    }),
  },
}
</script>
<style>
.offcanvas-end {
  width: 500px !important;
}
</style>
