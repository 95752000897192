<template>
  <div
    class="progress progress-sm criterions_bar"
    ref="bar"
  >
    <div
      class="progress-bar"
      v-for="(item, i) in progressData"
      :key="i"
      :style="'width: ' + item.percentage + '%;background-color:' + item.color"
    />
  </div>
</template>


<script>
import { Tooltip } from "bootstrap";

export default {
  /**
   *
   */
  data() {
    return {
      progressData: [
        {
          color: "red",
          percentage: "10",
        },
      ],
      tooltip: null,
    };
  },

  /**
   *
   */
  mounted() {
    if(this.data.length)
      this.update();
  },

  /**
   *
   */
  props: {
    count: {
      type: Number,
      default: 1,
    },

    data: {
      type: Array,
      default: () => [],
    },
  },

  /**
   *
   */
  watch: {
    data(value) {
      if(value.length)
        this.update();
    },
  },

  /**
   *
   */
  methods: {
    /**
     *
     */
    update() {
      let tooltip = document.createElement("div");
      this.progressData = [];
      let max = 100 / this.data.length;
      for (let item of this.data) {
        let grade = item.total_grade ? item.total_grade : item.grade;
        let percentage = ((max * grade) / item.high_score) / this.count;
        this.progressData.push({
          color: item.color, 
          percentage: percentage,
        });
        let tooltipItem = document.createElement("div");
        tooltipItem.innerHTML =
          "<span style='color:" +
          item.color +
          "'>&#9632;</span>&nbsp;" +
          "<strong>" +
          item.name +
          "</strong>" +
          ": " +
          grade +
          " (" +
          percentage.toFixed(2) +
          "%)";
        tooltip.appendChild(tooltipItem);
        this.tooltip = new Tooltip(this.$refs.bar, {
          html: true,
          container: "body",
          title: tooltip,
        });
      }
    },
  },
};
</script>



<style lang="scss">
.criterions_bar {
  cursor: pointer;
}
</style>
