<template>
  <router-view name="main" />
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  mounted() {
    if (!this.socketIoStatus)
      this.setSocketIOActive(true);

    if(window?.intercomSettings?.vertical_padding) {
      window.intercomSettings.vertical_padding = 500;
    }
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser",
    }),
    ...mapGetters("system", {
      socketIoStatus: "getSocketIOActive"
    }),
  },

  methods: {
    ...mapMutations("system", ["setSocketIOActive"])
  },

};
</script>

<style lang="scss"></style>
