<template>
  <div class="card d-flex flex-row align-items-center ">
    <img
      class="rounded"
      src="@/assets/img/illustrations/pluzinha-edit.svg"
      alt="progress"
    >
    <div class="col">
      <h5 class="mb-1">
        {{ $t("shall_we_begin") }}
      </h5>
      <p
        class="text-break tx-text-gray"
      >
        {{ $t("fill_fields_to_create_summary") }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "EmptyProgress",
}
</script>