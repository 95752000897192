<template>
  <div class="header py-3 my-3">
    <div class="row">
      <div class="col-lg-12 d-flex justify-content-between">
        <div>
          <h6 class="p-0 m-0 text-muted">
            OVERVIEW
          </h6>
          <h1
            class="p-0 m-0 main-title mb-0 mt-2"
            style="line-height: 23px"
          >
            Empresas
          </h1>
        </div>
        <button
          class="col-lg-3 header-button btn btn-primary"
          @click="$router.push('/admin/companies/new')"
        >
          Criar empresa
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <h2 class="mb-3">
        Usuários Ativos por Empresas
      </h2>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6">
      <div class="card">
        <div class="card-body p-3">
          <div class="row">
            <template v-if="loadingFlags.findTotalLogged">
              <div
                class="col-lg-11 text-center"
                style="height: 22.5px"
              >
                <div
                  class="bg-loading"
                  style="width: 100%; min-height: 16px; margin-top: 3px"
                />
              </div>

              <div class="col-lg-1 text-center">
                <div
                  class="bg-loading"
                  style="width: 100%; min-height: 16px; margin-top: 3px"
                />
              </div>
            </template>
            <template v-else>
              <div class="col-lg-10 border-end">
                <div class="d-flex justify-content-between px-0 text-center align-items-center">
                  <h4 class="py-0 my-0 mx-3">
                    Usuários
                  </h4>
                  <div class="py-0 my-0 mx-3">
                    Logado/Deslogados
                  </div>
                </div>

                <div class="col-lg-12 text-center p-0">
                  <div
                    class="d-flex justify-content-between mx-3"
                    style="color: #6E84A3;"
                  >
                    <span class="">{{ totalLogged }}</span>
                    <span>{{ totalMaxLogin - totalLogged }}</span>
                  </div>
                  <double-progressbar
                    class="col mx-3"
                    style="margin-top: 3px; height: 8px;"
                    :left-count="totalLogged"
                    :right-count="totalMaxLogin - totalLogged"
                  />
                </div>
              </div>
              <div class="col-2 border-start fs-3">
                <div class="text-center p-0">
                  Total
                </div>
                <div class="text-center">
                  {{ totalMaxLogin }}
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="card">
        <div class="card-body p-3">
          <div class="row">
            <template v-if="loadingFlags.findCallStats || !callStats">
              <div
                class="col-lg-11 text-center"
                style="height: 22.5px"
              >
                <div
                  class="bg-loading"
                  style="width: 100%; min-height: 16px; margin-top: 3px"
                />
              </div>

              <div class="col-lg-1 text-center">
                <div
                  class="bg-loading"
                  style="width: 100%; min-height: 16px; margin-top: 3px"
                />
              </div>
            </template>
            <template v-else>
              <div class="col-lg-10 border-end">
                <div class="d-flex justify-content-between px-0 text-center align-items-center">
                  <h4 class="py-0 my-0 mx-3">
                    Chamadas
                  </h4>
                  <div class="p-0 m-0 mx-3">
                    Ativas/disponível
                  </div>
                </div>

                <div class="col-lg-12 text-center p-0">
                  <div
                    class="d-flex justify-content-between mx-3"
                    style="color: #6E84A3;"
                  >
                    <span class="">{{ callStats.total }}</span>
                    <span>{{ callStats.desired - callStats.total }}</span>
                  </div>
                  <double-progressbar
                    class="col mx-3"
                    style="margin-top: 3px; height: 8px;"
                    :left-count="callStats.total"
                    :right-count="callStats.desired - callStats.total"
                  />
                </div>
              </div>
              <div class="col-2 border-start fs-3">
                <div class="text-center p-0">
                  Total
                </div>
                <div class="text-center">
                  {{ callStats.desired }}
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12">
      <h2 class="mb-3">
        Usuários Ativos por Empresas
      </h2>
    </div>

    <div class="col-lg-12 mb-5">
      <div class="card">
        <div
          class="card-header"
          style="border-bottom: none"
        >
          <search-input
            :search="searchValue"
            @search="search"
          />
          <div class="mx-3">
            <input-date-range
              v-if="activeTab == 'blocked'"
              @close="searchBlockedCompanies"
              v-model:start="blockedStartDate"
              v-model:end="blockedEndDate"
              :search-on-close="true"
              :default-date-enabled="false"
              :clean-date="cleanDate"
              format-date="d/m/Y"
              data-test="blockedCompaniesInterval-button"
            />
          </div>
          <button
            v-if="activeTab == 'blocked' && blockedStartDate"
            class="btn btn-outline-primary"
            @click="clearDate"
            data-test="suspensedCompaniesClearFilter-button"
          >
            limpar filtro
          </button>

          <div class="d-flex justify-content-end col">
            <div class="mx-4">
              <div class="dropdown">
                <select
                  class="form-select form-control select-width-pro-mode"
                  v-model="proModeCompanies"
                >
                  <option
                    :value="false"
                  >
                    Todas as empresas
                  </option>

                  <option
                    :value="true"
                  >
                    Empresas PRO
                  </option>
                </select>
              </div>
            </div>
          </div>

          <ul
            class="nav nav-tabs nav-tabs-sm card-header-tabs"
            style="margin-left: 36px"
          >
            <li
              class="nav-item"
              role="presentation"
            >
              <button
                class="nav-link two-lines active"
                ref="active-companies-tab"
                @click="activeTab = 'activeCompanies'"
                type="button"
                role="tab"
                data-bs-toggle="tab"
                data-bs-target="#active-companies"
                aria-controls="active-companies"
                aria-selected="true"
              >
                <span class="text-muted"> ATIVOS </span>
                <br>
                <span class="subtitle">
                  <template v-if="loadingFlags.loadActiveCompanies">
                    <span class="fe fe-clock" />
                  </template>
                  <template v-else>
                    {{ activeCompaniesPagination.total }}
                  </template>
                </span>
              </button>
            </li>
            <li
              class="nav-item"
              role="presentation"
            >
              <button
                class="nav-link two-lines"
                type="button"
                ref="blocked-tab"
                role="tab"
                data-bs-toggle="tab"
                data-bs-target="#blocked"
                aria-controls="blocked"
                aria-selected="true"
                @click="activeTab = 'blocked'"
              >
                <span class="text-muted"> SUSPENSAS </span>
                <br>
                <span class="subtitle">
                  <template v-if="loadingFlags.loadBlockedCompanies">
                    <span class="fe fe-clock" />
                  </template>
                  <template v-else>
                    {{ blockedCompaniesPagination.total }}
                  </template>
                </span>
              </button>
            </li>
            <li
              class="nav-item"
              role="presentation"
            >
              <button
                class="nav-link two-lines"
                type="button"
                role="tab"
                ref="trashed-tab"
                data-bs-toggle="tab"
                data-bs-target="#trashed"
                aria-controls="trashed"
                aria-selected="true"
                @click="activeTab = 'trashed'"
              >
                <span class="text-muted"> EXCLUÍDAS </span>
                <br>
                <span class="subtitle">
                  <template v-if="loadingFlags.loadTrashedCompanies">
                    <span class="fe fe-clock" />
                  </template>
                  <template v-else>
                    {{ trashedCompaniesPagination.total }}
                  </template>
                </span>
              </button>
            </li>
          </ul>
        </div>
        <div class="card-body p-0">
          <div class="tab-content">
            <div
              class="tab-pane fade show active"
              id="active-companies"
              role="tabpanel"
              aria-labelledby="active-companies"
            >
              <admin-active
                :search-value="searchValue"
                :pro-mode-companies="proModeCompanies"
              />
            </div>
            <div
              class="tab-pane fade show"
              id="blocked"
              role="tabpanel"
              aria-labelledby="blocked"
            >
              <admin-blocked
                ref="blockedCompaniesRef"
                :blocked-start-date="blockedStartDate?.concat(' 00:00:00')"
                :blocked-end-date="blockedEndDate?.concat(' 23:59:59')"
                :search-value="searchValue"
                :pro-mode-companies="proModeCompanies"
              />
            </div>
            <div
              class="tab-pane fade show"

              id="trashed"
              role="tabpanel"
              aria-labelledby="trashed"
            >
              <admin-trashed
                :search-value="searchValue"
                :pro-mode-companies="proModeCompanies"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchInput from "@components/search-input";
import DoubleProgressbar from "../components/double-progressbar.vue";
import _ from "lodash";

import AdminActive from "@admin/components/admin-active.vue"
import AdminBlocked from "@admin/components/admin-blocked.vue"
import AdminTrashed from "@admin/components/admin-trashed.vue"

import { AgentStatus } from "@/utils";

import { Tab } from "bootstrap";

import { mapActions, mapGetters, mapMutations } from "vuex";
import InputDateRange from '@components/input-date-range.vue';
import { nextTick } from "vue";

export default {
  data() {
    return {
      activeTab: "activeCompanies",
      AgentStatus: AgentStatus,
      orderBy: 'orderByDesc',
      tabs: {
        activeCompanies: null,
        blocked: null,
        trashed: null,
      },
      searchValue: "",
      blockedStartDate: null,
      blockedEndDate: null,
      cleanDate: false,
      proModeCompanies: false,
    };
  },

  beforeMount() {
    this.findCallStats();
    if(this.activeCompaniesParams.search)
      this.searchValue = this.activeCompaniesParams.search;

      this.findTotalLogged();
    this.findTotalMaxLogin();
  },

  mounted() {
   this.createNewTabs();
  },

  components: {
    SearchInput,
    DoubleProgressbar,
    AdminActive,
    AdminBlocked,
    AdminTrashed,
    InputDateRange,
  },

  computed: {
    ...mapGetters("admin/companies", {
      companies: "getActiveCompanies",
      activeCompaniesParams: "getActiveCompaniesParams",
      allCompanies: "getListOfCompaniesWithoutPagination",
      loadingFlags: "getLoadingFlags",
      activeCompaniesPagination: "getActiveCompaniesPagination",
      blockedCompaniesPagination: "getBlockedCompaniesPagination",
      trashedCompaniesPagination: "getTrashedCompaniesPagination",
      callStats: "getCallStats",
      totalLogged: "getTotalLogged",
      totalMaxLogin: "getTotalMaxLogin",
      blockedCompanies: "getBlockedCompanies",
      trashedCompanies: "getTrashedCompanies",
      proModeCompany: "getProModeCompanies"
    }),

    ...mapGetters("auth", {
      user: "getUser"
    })
  },

  methods: {
    ...mapMutations("admin/companies", ["setFilters", "setBlockedFilters", "setTrashedFilters", "setCurrentPage", "setCurrentBlockedCompanyPage", "setCurrentTrashedCompanyPage"]),
    ...mapActions("auth", ["enterImpersonateMode"]),
    ...mapActions("admin/companies", [
      "findCallStats",
      "findTotalLogged",
      "findTotalMaxLogin",
      "releaseAccessToTrashedCompany",
      "downloadCSV",
      "loadActiveCompanies",
      "loadBlockedCompanies",
      "loadTrashedCompanies"
    ]),

    createNewTabs() {
      this.tabs.activeCompanies = new Tab(this.$refs["active-companies-tab"]);
      this.tabs.blocked = new Tab(this.$refs["blocked-tab"]);
      this.tabs.trashed = new Tab(this.$refs["trashed-tab"]);
    },

    search: _.debounce(function (term) {
      this.searchValue = term;
    }, 300),

    countAgentsOnline(agentStatus) {
      let total = 0;
      for (let agent of agentStatus) total += agent.status != AgentStatus.OFFLINE ? 1 : 0;
      return total;
    },

    searchProModeCompanies() {
      this.$refs.proModeCompany.searchProModeCompanies();
      },

    clearDate() {
      this.blockedStartDate = null;
      this.blockedEndDate = null;
      this.cleanDate = true;
      nextTick(() => this.cleanDate = false);

      this.$refs.blockedCompaniesRef.searchBlockedCompanies();
    },

    searchBlockedCompanies() {
      this.$refs.blockedCompaniesRef.searchBlockedCompanies();
    }
  },
};
</script>

<style lang="scss">
.bg-loading {
  background: #ececec;
  background: linear-gradient(to right, #ececec 20%, #f7f7f7 50%, #ececec 80%);
  background-size: 500px 100px;
  animation-name: moving-gradient;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}
.select-width-pro-mode {
  width: 242px;
}
</style>
