<template>
  <div class="d-flex align-items-center card-empty">
    <img
      class="pluzinha-progress"
      src="@/assets/img/illustrations/pluzinha-edit.svg"
      alt="progress"
    >
    <div>
      <h5 class="mb-1">
        {{ $t("shall_we_begin") }}
      </h5>
      <span
        class="text-break tx-text-gray"
        v-html="$t('fill_fields_to_create_summary')"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "EmptyProgress",
}
</script>


<style scoped lang="scss">
.pluzinha-progress {
  border-radius: 0.625rem !important;
}

.card-empty {
  border-radius: 0.625rem !important;
  border: 1px solid $color-gray-blue-300;
  background-color: #FFFFFF
}
</style>