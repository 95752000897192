export default {
  path: 'feedback-report',
  name: 'feedback-report',
  components: {
    main: require('@dialer/manager/pages/reports/feedback-report').default,
  },
  meta: {
    'short-side-bar': true
  },
}
