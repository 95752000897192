<template>
  <div class="file-uploader p-0">
    <form
      v-show="!file"
      ref="file"
      action="#"
      class="dropzone dropzone-custom"
    />

    <div
      v-if="file"
      class="card d-flex flex-row flex-wrap align-items-center rounded bg-gray-blue-100 p-4 shadow-none"
    >
      <div class="col-12 col-lg-5  d-flex gap-2 align-items-center">
        <div class="avatar col-auto">
          <div
            class="avatar-title card bg-white text-secondary shadow-none"
          >
            <span :class="icon" />
          </div>
        </div>
        <div class="col col-9">
          <span
            :title="fileName"
            class="tx-text-200 mb-1 text-truncate overflow-ellipsis d-flex col-12 "
          >
            {{ fileName }}
          </span>
          <small
            class="text-muted d-flex col-12"
            v-if="file.size"
          >{{ formatBytes(file.size) }}</small>
        </div>
      </div>
      <div class="col-auto p-0 pe-4 ">
        <i class="fs-1 fa-light fa-pipe tx-gray-300" />
      </div>
      <div class="col d-flex align-items-center gap-4">
        <audio-player
          class="w-100 border-0"
          :file="file"
        />
        <div class="dropdown">
          <a
            aria-expanded="false"
            class="dropdown-ellipses dropdown-toggle"
            data-bs-toggle="dropdown"
            role="button"
          >
            <i class="far fa-trash" />
          </a>
          <div class="dropdown-menu dropdown-menu-end">
            <a
              class="dropdown-item"
              @click="remove"
            > {{ this.$t("remove_capitalize") }} </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropzone from "dropzone";
import AudioPlayer from "@components/audio-player.vue";
import { Utils } from "@/utils"

export default {
  components: { AudioPlayer },
  props: {
    filter: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: String,
      default: "fe fe-file",
    },
    urlAudio: {
      type: [String, Object],
      default: "",
    },
  },
  data() {
    return {
      dropzone: null,
      audioElement: null,
      isPlaying: false,
      currentTime: 0,
      audioDuration: 0,
      file: "",
      fileName: "",
    };
  },

  emits: ["select-file"],
  mounted() {
    this.dropzone = new Dropzone(this.$refs.file, {
      acceptedFiles: this.filter.toString(),
      autoProcessQueue: false,
      maxFiles: 1,
      error: function (file) {
        this.removeFile(file);
      },
      dictDefaultMessage: `<i class="fa-regular fa-up-from-bracket pe-2"></i> ${this.$t("drag_and_drop_audio_file")}`,
      addedfiles: files => {
        if (!this.filter.includes(files[0].type)) return
        this.file = files[0];
        this.fileName = files[0].name;
        this.styleDisplayDzMessage();
        this.$emit("select-file", files[0]);
      },
    });
    if (this.urlAudio) {
      this.file = this.urlAudio
      this.fileName = `${this.urlAudio}`.split("&name=")[1]
    }
  },
  methods: {
    styleDisplayDzMessage() {
      const primaryDzMessage = this.$refs.file.querySelector(".dz-default.dz-message");
      if (!this.dropzone.files.length) {
        primaryDzMessage.style.display = "block";
      } else {
        primaryDzMessage.style.display = "none";
      }
    },
    remove() {
      this.dropzone.removeFile(this.file);
      this.styleDisplayDzMessage();
      this.file = "";
      this.$emit("select-file", "");
    },
    formatBytes: Utils.formatBytes,
  },
};

</script>

<style lang="scss" scoped>


.dz-default, dropzone {
  border: 1px dashed #d2ddec;
  border-radius: 10px;
}

:deep(.dz-message) {
  padding: 2.5rem 1rem !important;
}

.dz-max-files-reached > .dz-default {
  display: none;
}

.file-uploader .fa-trash {
  visibility: hidden;
}

.file-uploader:hover .fa-trash {
  color: $color-text-gray;
  visibility: visible;
}

.file-uploader:hover .fa-trash:hover {
  color: $color-red-300;
  visibility: visible;
}

</style>
