<template>
  <div class="line-chart">
    <span
      v-if="isLoading"
      class="fa-regular fa-clock text-muted"
    />
    <div
      v-else
      class="bar"
      :style="{
        backgroundSize: '1px ' + item + '%',
        backgroundImage: barStyle
      }"
      v-for="(item, i) in percentage"
      :key="i"
    />
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  mounted() {
    this.loadData();
    if(Object.values(this.campaign.campaign_information.quantity_of_agents_with_online_status).length)
      this.barStyle = "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAQSURBVHgBAQUA+v8AMFfy/wStAnmhGG7hAAAAAElFTkSuQmCC') !important";
    else
      this.barStyle = "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAEElEQVR4AQEFAPr/AMXFxf8H8QNP1gn4LQAAAABJRU5ErkJggg==') !important";
  },

  computed: {
    ...mapGetters("manager/calls-report", {
      callsStatusCountPerTime: "getCallsStatusCountPerTime",
    }),

    agentsWithOnlineStatus() {
      return this.campaign?.campaign_information?.quantity_of_agents_with_online_status;
    }
  },

  data() {
    return {
      percentage: [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
      ],
      startDate: "",
      endDate: "",
      labels: [],
      data: [],

      /**
       * The loading below is not in the vuex store because I need to individually
       * manipulate it in the campaigns list, if I just created one it will
       * remain the same loading for all requests event using the loadingFlags
       * pattern :)
       */
      isLoading: false,
      barStyle: "",
    };
  },

  props: {
    campaign: {
      type: Object,
      default: () => {},
      required: true,
    },

    sugestedMax: {
      type: Number,
      default: 10,
    },
  },

  watch: {
    callsStatusCountPerTime: {
      handler(data) {
        if (data[this.campaign.id]) {
          this.prepareData(data[this.campaign.id]);
        }
      },
      deep: true,
    },

    agentsWithOnlineStatus: {
      handler(data) {
        if(!Object.values(data).length)
          this.barStyle = "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAEElEQVR4AQEFAPr/AMXFxf8H8QNP1gn4LQAAAABJRU5ErkJggg==') !important"
        else
          this.barStyle = "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAQSURBVHgBAQUA+v8AMFfy/wStAnmhGG7hAAAAAElFTkSuQmCC') !important";
      },
      deep: true
    }
  },

  methods: {
    ...mapActions("manager/calls-report", ["findCallsStatusCountPerTime"]),

    loadData() {
      this.isLoading = true;

      this.endDate = moment();
      this.startDate = moment().subtract(3, "hours");
      this.prepareLabels();
      this.findCallsStatusCountPerTime({
        startDate: this.startDate.format("YYYY-MM-DD HH:mm:00"),
        endDate: this.endDate.format("YYYY-MM-DD HH:mm:00"),
        interval: 5,
        campaign_id: this.campaign.id,
      }).finally(() => this.isLoading = false);
    },

    update() {
      this.percentage = new Array(36).fill(0);
      for (let i in this.data)
        this.percentage[i] = Math.round(this.data[i] / (this.getSugestedMax() / 100));
    },

    getSugestedMax() {
      let max = this.getMax();
      return max > this.sugestedMax ? max : this.sugestedMax;
    },

    getMax() {
      let m = 0;
      for (let i in this.data) if (this.data[i] > m) m = this.data[i];
      return m;
    },

    prepareData(data) {
      this.data = [];
      let temp = {};
      for (let item of data) temp[moment(item.date).format("YYYY-MM-DD HH:mm:00")] = item;
      for (let label of this.labels)
        if (temp[label]) this.data.push(temp[label].total);
        else this.data.push(0);
      this.update();
    },

    prepareLabels() {
      this.labels = [];
      let date = this.startDate.clone();
      let endDate = this.endDate;
      for (date; date < endDate; date = date.add(5, "minute"))
        this.labels.push(date.format("YYYY-MM-DD HH:mm:00"));
    },
  },
};
</script>

<style lang="scss">
.line-chart {
  height: 40px;
  width: 251px;
  margin: auto;

  .bar {
    width: 6px;
    height: 40px;

    float: left;
    margin-left: 1px;
    background-repeat: repeat-x;
    background-position: bottom 0px right 0px;
  }

  .bar:first-child {
    margin-left: 0px;
  }
}
</style>
