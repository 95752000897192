<template>
  <edit-contact
    v-if="currentChat && !chatIsGroup"
    :contact-id="currentChat.contact?.id"
    :show="showEditContact"
    @close="showEditContact = false"
  />
  <edit-group
    v-if="currentChat && chatIsGroup"
    :show="showEditGroup"
    @close="showEditGroup = false"
  />
  <div
    ref="whatsappTopbar"
    class="whatsapp-topbar w-100 d-flex"
    data-test="chatTopBar-div"
  >
    <div class="cursor-pointer list-group-item border-0 p-0 user-info m-0 col col-lg-9 col-xl-8">
      <div class="row align-items-center info-card">
        <button
          v-if="currentWindowWidth < 1200"
          class="btn btn-rounded-circle btn-white me-2"
          @click="$router.back()"
        >
          <i class="fa-regular fa-chevron-left" />
        </button>
        <div
          class="col-auto ps-0 position-relative d-flex align-items-center"
          style="height: 46px"
          @click="handleEditOffCanvas"
        >
          <emoji-chat-mood
            v-if="!!currentChat?.mood"
            :mood="currentChat?.mood"
            class="emoji-chat"
          />
          <span
            class="avatar"
            @mouseenter="avatarHovered = true"
            @mouseleave="avatarHovered = false"
          >
            <img
              v-if="image"
              :src="image"
              alt="contact-img"
              class="avatar-img rounded-circle"
              :class="{'hovered-avatar': avatarHovered}"
              @error="handleImageError"
            >
          </span>
        </div>
        <div class="col ps-0">
          <div class="mb-1 d-flex justify-content-start align-items-center">
            <h4
              v-if="chatContact"
              @click="handleEditOffCanvas()"
              class="mb-0 name-container line-clamp-1"
            >
              <span
                class="px-2 container-loading"
                @mouseenter="nameHovered = true "
                @mouseleave="nameHovered = false"
                :class="{'hovered-name': nameHovered}"
              >
                {{ name }}
              </span>
            </h4>
            <ul
              class="list-unstyled d-flex align-items-center m-0 gap-2 col"
              @click="handleEditOffCanvas"
              ref="tagsList"
            >
              <tag-contact
                class="card-tag-sm"
                v-for="contactTag in visibleTags"
                :key="contactTag.name"
                :tag="contactTag"
              />
              <div
                v-if="ocultTagsCount"
                class="card-tag-sm tag-blue"
              >
                + {{ ocultTagsCount }}
              </div>
            </ul>
          </div>
          <div class="d-inline-flex align-items-center">
            <span
              v-if="currentChat?.agent_name"
              class="col agent-name small line-clamp-1"
            >
              {{ $t('agent_capitalize') }}: {{ currentChat.agent_name }}
            </span>
            <div
              v-if="instances.length > 1"
              class="col-auto"
            >
              <div
                ref="dropdown"
                class="d-flex justify-content-start align-items-center dropdown-center"
                @click="openInstances"
              >
                <small
                  v-if="instanceName"
                  class="small status"
                >
                  <div class="ball" />
                  {{ instanceName }}
                  <div
                    class="spinner-border spinner-border-sm"
                    style="height: 10px; width: 10px;"
                    v-if="loadingInstances"
                  />
                  <span
                    v-else
                    class="far fa-chevron-down"
                  />
                </small>
              </div>
              <div class="dropdown-menu instances-list">
                <instances-list
                  v-if="contactInstances"
                  :contact="chatContact"
                />
              </div>
            </div>
            <small
              v-else-if="instanceName"
              class="small status container-loading connection-name line-clamp-1"
            >
              <div class="ball background-loading" />
              {{ instanceName }}
            </small>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="$route.params.id && currentChat"
      class="d-flex align-items-center col-auto col-lg-3 col-xl-4 pe-lg-4"
      :class="{
        'pe-75rem': currentWindowWidth && currentWindowWidth < 992
      }"
    >
      <div
        ref="opts"
        v-if="!currentChat?.finished || chatIsGroup"
        class="d-flex justify-content-center align-items-center gap-0-75r w-100"
        style="max-width: 100%"
      >
        <done-chat
          v-if="showButtons.done"
          :loading="loading"
          :show-label="!currentWindowWidth || currentWindowWidth && currentWindowWidth >= 1366"
        />
        <top-bar-call
          v-if="showButtons.call"
          :small="!currentWindowWidth || currentWindowWidth && currentWindowWidth < 1366"
        />
        <transfer-chat
          v-if="showButtons.transfer"
          :key="!currentWindowWidth || currentWindowWidth && currentWindowWidth < 1366"
          :loading="loading"
          :small="!currentWindowWidth || currentWindowWidth && currentWindowWidth < 1366"
        />
        <snooze-dropdown
          v-if="showButtons.snooze"
          @send-snooze-note="event => $emit('sendSnoozeNoteToChat', event)"
          :loading="loading"
          :key="!currentWindowWidth || currentWindowWidth && currentWindowWidth < 1366"
          :small="!currentWindowWidth || currentWindowWidth && currentWindowWidth < 1366"
        />
        <more-options-chat-dropdown
          v-if="showButtons.more"
          :is-group="currentChat.is_group"
          :responsive-items="responsiveItems"
          :key="responsiveItems.length"
          :show-list="showList"
          @responsive-item-clicked="handleResponsiveItem"
          @show-edit="handleEditOffCanvas"
        >
          <template #body>
            <div>
              <button
                class="btn bg-transparent"
                @click="handleBack"
              >
                <i class="fa-regular fa-chevron-left" />
              </button>
              <div>
                <done-chat-form v-if="currentOpenedItem && currentOpenedItem === 4" />
                <transfer-chat-form
                  v-if="currentOpenedItem && currentOpenedItem === 2"
                  @dropdown-hide="handleBack"
                />
                <snooze-chat-menu v-if="currentOpenedItem && currentOpenedItem === 1" />
                <is-ramal-buzy-component
                  v-if="currentOpenedItem && currentOpenedItem === 3"
                  :is-ramal-buzy="isRamalBuzy"
                />
              </div>
            </div>
          </template>
        </more-options-chat-dropdown>
      </div>
      <div
        class="d-flex"
        v-else-if="(!currentChat.agent_id && !currentChat.is_group) || currentChat.finished"
      >
        <top-bar-call />
      </div>
    </div>
  </div>
</template>
<script>
import DoneChat from "./top-bar-buttons/done-chat.vue";
import SnoozeDropdown from "./top-bar-buttons/snooze-dropdown.vue";
import EditContact from "./edit-contact.vue";
import EditGroup from "./edit-group.vue";
import InstancesList from "./instances-list.vue";
import TransferChat from "./transfer-form/transfer-chat.vue"
import {Dropdown} from "bootstrap";

import {mapActions, mapGetters, mapMutations} from "vuex";
import TopBarCall from './top-bar-buttons/top-bar-call.vue';
import MoreOptionsChatDropdown from "@whatsapp/components/top-bar-buttons/more-options-chat-dropdown.vue";
import {handleImageError, isValidImageUrl} from "@/utils";
import TagContact from "@whatsapp/components/tags/tag-contact.vue";
import EmojiChatMood from "@whatsapp/components/emoji-chat-mood.vue";

import resizeListener from "@/mixins/resizeListener";
import DoneChatForm from "@dialer/manager/components/topbar/done-chat-form.vue";
import TransferChatForm from "@dialer/manager/components/topbar/transfer-chat-form.vue";
import SnoozeChatMenu from "@dialer/manager/components/topbar/snooze-chat-menu.vue";
import IsRamalBuzyComponent from "@dialer/manager/components/topbar/is-ramal-buzy-component.vue";

export default {
  emits: ["sendSnoozeNoteToChat"],
  mixins: [resizeListener],

  data() {
    return {
      loading: false,
      showDoneForm: false,
      showTransferForm: false,
      showEditContact: false,
      showEditGroup: false,
      dropdown: null,
      dropIsOpenned: false,
      loadingInstances: false,
      nameHovered: false,
      avatarHovered: false,
      resize: null,
      responsiveItems: [],
      currentWindowWidth: null,
      showList: true,
      items: [
        {
          id: 0,
          label: "More Options",
          icon: "fa-ellipsis"
        },
        {
          id: 1,
          label: this.$t("put_on_hold"),
          icon: "fa-snooze"

        },
        {
          id: 2,
          label: this.$t("capitalizes.transfer"),
          icon: "fa-arrow-right-arrow-left"
        },
        {
          id: 3,
          label: this.$t("capitalizes.call"),
          icon: "fa-phone"
        },
        {
          id: 4,
          label: this.$t("capitalizes.finish"),
          icon: "fa-circle-check"
        }
      ],
      currentOpenedItem: null
    };
  },

  mounted() {
    this.setResizeCallback(this.handleResize);

    if (this.enableDropdown) {
      this.dropdown = new Dropdown(this.$refs["dropdown"]);
    }

    if (this.currentChat) {
      this.findContactByID({
        id: this.currentChat.contact.contact_id ?
          this.currentChat.contact.contact_id :
          this.currentChat.contact.id
      });
    }
  },

  computed: {
    ...mapGetters("whatsapp/instance", {
      instances: "getConnectedInstances",
      allowedAgents: "getAllowedAgents",
    }),
    ...mapGetters("whatsapp/chat", {
      loadingFlags: "getLoadingFlags",
      chat: "getCurrentChat",
      emptyChatContact: "getEmptyChatContact"
    }),
    ...mapGetters("whatsapp/contact", {
      contactInstances: "getContactInstances",
      contact: "getContact",
    }),
    ...mapGetters("auth", {
      user: "getUser",
      isAgent: "isAgent",
      onlyHaveWhatsapp: "onlyHaveWhatsapp"
    }),
    ...mapGetters("agent/call", {
      loadingFlagsCall: "getLoadingFlags",
      whatsappCallCurrentNumber: "getWhatsappCallCurrentNumber"
    }),
    ...mapGetters("system", {
      userRects: "getUserRects"
    }),

    enableDropdown() {
      return !this.chatIsGroup && this.instances.length > 1
    },

    visibleTags() {
      if (this.userRects.width || this.userRects.height) {
        const numberOfTagsList = this.calculateNumberOfTags()
        return this.contact?.contact_tags.slice(0, numberOfTagsList)
      }

      return []
    },

    ocultTagsCount() {
      return this.contact ? this.contact.contact_tags.length - this.visibleTags.length : 0
    },

    currentChat() {
      return this.chat[this.$route.params.id];
    },

    enableTopBarActionButtons() {
      return this.currentChat &&
        !this.currentChat.finished &&
        (this.currentChat.agent_id || this.chatIsGroup)
    },

    chatContact() {
      if (this.currentChat) return this.currentChat.contact;
      if (this.emptyChatContact) return this.emptyChatContact;
      return null;
    },

    image() {
      if (this.chatContact && this.chatContact.image && this.isValidImageUrl(this.chatContact.image))
        return this.chatContact.image;
      else
        return require("@/assets/img/avatar.svg")
    },

    name() {
      if (this.chatContact?.name_alias) return this.chatContact.name_alias;
      if (this.chatContact?.name) return this.chatContact.name;
      if (this.chatContact?.number) return this.chatContact.number;
      return "";
    },

    instanceName() {
      if (this.currentChat) {
        if (this.currentChat.instance.data) return this.currentChat.instance.data.name
        return this.currentChat.instance.name
      }
      if (this.chatContact && this.chatContact?.instance) return this.chatContact.instance.name
      return ""
    },

    chatIsGroup() {
      return this.currentChat && this.currentChat.is_group;
    },

    showButtons() {
      const chat = this.currentChat
      return {
        done: ((chat.agent_id && !chat.finished) || chat.chatbot_active) && !this.responsiveItems.length,
        call: !chat.is_group && this.responsiveItems.length <= 1,
        transfer: (!chat.finished && !chat.is_group || chat.chatbot_active) && this.responsiveItems.length < 2,
        snooze: (chat.agent_id && !chat.in_snooze && !chat.finished) && this.responsiveItems.length < 3,
        more: (chat.agent_id && !chat.finished) || chat.is_group
      }
    },

    canStartWhatsappCall() {
      return !this.onlyHaveWhatsapp && !this.loadingFlagsCall.whatsappCall && !this.isRamalBuzy
    },

    isRamalBuzy() {
      const isAnotherClientInCall = this.whatsappCallCurrentNumber !== this.currentChat.number && this.whatsappCallCurrentNumber !== ''
      const isInCall = this.whatsappCallCurrentNumber === this.currentChat.number && !this.loadingFlagsCall.whatsappCall

      return isAnotherClientInCall || isInCall
    },
  },

  components: {
    IsRamalBuzyComponent,
    SnoozeChatMenu,
    TransferChatForm,
    DoneChatForm,
    EmojiChatMood,
    TagContact,
    MoreOptionsChatDropdown,
    DoneChat,
    SnoozeDropdown,
    EditContact,
    EditGroup,
    InstancesList,
    TransferChat,
    TopBarCall
  },

  methods: {
    ...mapMutations("whatsapp/contact", ["setSelectedContact"]),
    ...mapActions("whatsapp/contact", ["findContactInstances", "findContactByID"]),
    ...mapActions("agent/call", ["startWhatsappCall"]),
    ...mapMutations("auth", ["trackEvents"]),

    handleImageError,
    isValidImageUrl,

    handleBack() {
      this.showList = true;
      this.currentOpenedItem = null
    },

    handleResponsiveItem(id) {
      if (id === 3 && this.canStartWhatsappCall) {
        this._startWhatsappCall(this.currentChat.number)
        return
      }

      this.currentOpenedItem = id;
      this.showList = false;
    },

    handleResize(e) {
      this.currentWindowWidth = e?.target?.outerWidth || e?.outerWidth;
      if (this.currentWindowWidth < 1200) {
        if (this.isAgent) {
          this.responsiveItems = this.items.slice(1);
          return
        }

        this.responsiveItems = this.items.filter(item => item.id !== 3 && item.id !== 0);
        return
      }

      if (this.currentWindowWidth >= 1200 && this.responsiveItems.length === this.items.length - 1) {
        this.responsiveItems = []
      }

      const optsFill = Math.trunc(this.$refs.opts.clientWidth / (32 + 7));
      const diff = (optsFill - (this.$refs.opts.children.length - optsFill));

      if (this.$refs.opts.children.length > optsFill) {
        for (let index = this.$refs.opts.children.length - 1; index > diff && index > 0 && index > this.responsiveItems.length; index--) {
          if (!this.isAgent && this.items[index].id === 3) {
            return false
          }
          this.responsiveItems.push(this.items[index])
        }
        return true
      }

      if (this.$refs.opts.children.length < optsFill) {
        this.responsiveItems.pop()

        return true
      }

      return false;
    },

    openInstances() {
      if (this.dropIsOpenned) {
        this.dropdown.hide();
        this.dropIsOpenned = false
      } else {
        this.loadingInstances = true;
        this.setSelectedContact({})
        this.findContactInstances(this.chatContact.number).then(() => {
          this.dropIsOpenned = true
          this.dropdown.show();
        }).finally(() => {
          this.loadingInstances = false;
        })
      }
    },

    handleEditOffCanvas() {
      this.nameHovered = false
      this.avatarHovered = false
      this.chatIsGroup ? (this.showEditGroup = true) : (this.showEditContact = true)
    },

    calculateNumberOfTags() {
      if (this.$refs.tagsList) {
        const width = this.$refs.tagsList.offsetWidth;
        const possibleTags = Math.floor((width - 46) / 166);

        return possibleTags;
      }
      return 2;
    },

    _startWhatsappCall(number) {
      this.startWhatsappCall(number)
        .then(() => {
          this.$router.push('/agent')
          this.trackEvents("[Agent] Omni Dialer Call")
        })
    }
  },

  watch: {
    contactInstances() {
      if (this.enableDropdown) {
        this.dropdown.hide()
      }
    },

    // userRects: {
    //   immediate: true,
    //   deep: true,
    //   handler(rects) {
    //
    //   },
    // }
  },
};
</script>

<style lang="scss" scoped>
.whatsapp-topbar {
  height: 70px;
  background-color: white;
  border-bottom: 1px solid #dddfe3;
  border-right: 1px solid #dddfe3;
  border-bottom-right-radius: 20px;

  @media only screen and (max-width: 1199.999px) {
    border-bottom-right-radius: 0;
  }

  .name-container {
    padding: 0 $whatsapp-spacing-1 0 0;
  }

  .container-loading {
    border-radius: 0.5rem !important;
  }

  .list-group-item {
    padding-left: 0.76rem;
  }

  .user-info {
    margin-top: 6px;

    .ball {
      background-color: rgb(94, 184, 99);
      width: 8px;
      display: inline-block;
      height: 8px;
      border-radius: 50%;
    }

    .status {
      font-weight: 200;
      font-size: 11px;
    }
  }

  .info-card {
    margin: 0.75rem 0 0.75rem 1.5rem;

    @media only screen and (max-width: 1199.999px) {
      margin: 0.75rem 0 0.75rem 0.75rem;
    }
  }

  .v-popper {
    .button-top-bar {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }

  .hovered-name {
    background-color: $color-gray-blue-300;
    border-radius: $border-radius-xsm;
  }

  .hovered-avatar {
    box-shadow: 0 0 0 3px $color-gray-300;
  }

  .agent-name {
    padding-right: $whatsapp-spacing-1;
    border-right: 1px solid $color-gray-300;
    margin-right: $whatsapp-spacing-1;
  }
}

.card-tag-sm {
  width: fit-content;
  height: 1.25rem;
  border-radius: 0.375rem;
  padding: 0 0.5rem;
  font-size: 0.675rem;
}

.tag-blue {
  background-color: #F0F4FA;
  color: #3057F2;
}

.avatar {
  width: 2.5rem;
  height: 2.5rem;
}

.emoji-chat {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

@each $clamp in (1, 2, 3) {
  .line-clamp-#{$clamp} {
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $clamp;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.pe-75rem {
  padding-right: 0.75rem !important;
}

.instances-list {
  width: 330px;
}

@media only screen and (max-width: 1199.999px) {
  .instances-list {
    max-width: 100vw;
    width: calc(100vw - 24px);
  }
}

.gap-0-75r {
  gap: 0.75rem;
}
</style>
