export default {
  getContacts: state => state.contacts,
  getPagination: (state) => state.pagination,
  getLoadingFlags: state => state.loadingFlags,
  getErrors: state => state.errors,

  getContact: state => state.contact,

  getSelectedContact: state => state.selectedContact,
  getContactInstances: state => state.contactInstances ? state.contactInstances.filter(instance => ["authenticated", "connected"].includes(instance.status)) : null,



  /**
   * generate URL parans
   *
   * @param {*} state
   * @returns
   */
  getParams: (state) =>
    Object.assign(
      {
        'page': state.pagination.current_page,
        'per_page': state.pagination.per_page
      }
      , state.filters, { include: state.include.join(',') }),
}
