<template>
  <div style="width: 340px; max-width: 100%">
    <p class="fs-4 tx-text-300 fw-medium mb-4">
      {{ $t('transfer_to') }}
    </p>

    <ul
      style="margin-bottom: 1rem"
      class="nav nav-tabs"
    >
      <li class="nav-item active">
        <a
          class="nav-link p-0 h-100 active text-capitalize"
          data-bs-toggle="tab"
          href="#users"
        >{{ $t('users') }}</a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link p-0 h-100"
          data-bs-toggle="tab"
          href="#instances"
        >{{ $t('service_queue') }}</a>
      </li>
    </ul>
    <div>
      <div class="tab-content">
        <div
          id="users"
          class="tab-pane fade active show"
          @scroll="checkScroll"
        >
          <div
            v-if="!agents.length"
            class="p-0"
          >
            {{ $t('no_agent_found') }}
          </div>
          <users-tab
            :key="currentChat[$route.params.id]?.agent?.id || currentChat[$route.params.id]?.agent_id"
            id="transfer-form"
            :current-user-id="user.id"
            :users="agents"
            :current-owner-id="currentChat[$route.params.id]?.agent?.id || currentChat[$route.params.id]?.agent_id"
            @select-item="transferToAgent"
            @search="search"
            @role="updateRoleFilter"
            :roles="[
              'agent',
              'supervisor'
            ]"
          />
          <div
            v-if="loadingFlags.findAllowedAgentsAndInstances"
            class="d-flex justify-content-center align-items-center"
            style="height: 30px"
          >
            <div class="spinner-border spinner-border-sm" />
          </div>
        </div>
        <div
          id="instances"
          class="tab-pane fade"
        >
          <div
            v-if="!groupChannels?.length"
          >
            {{ $t('no_connection_found') }}
          </div>
          <div
            v-else
            class="accordion"
            id="instancesAccordion"
          >
            <instance-item
              accordion-id="instancesAccordion"
              v-for="groupChannel in groupChannels"
              :key="groupChannel.id"
              :group-channel="groupChannel"
              @selected-instance="(instance_id)=>transferToInstance(instance_id)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UsersTab from "@whatsapp/components/transfer-form/users-tab.vue";
import InstanceItem from "@whatsapp/components/transfer-form/instance-item.vue";
import _ from "lodash";
import {mapActions, mapMutations, mapGetters} from "vuex";

export default {
  name: "TransferChatForm",
  components: {
    UsersTab,
    InstanceItem,
  },

  emits: [
    "handleDropdownClick",
    'dropdownHide'
  ],

  data() {
    return {
      agents: [],
      groupChannels: [],
      agentsPerPage: 15,
      lastPage: null,
      params: {
        page: 1,
        search: "",
        team: null,
        role: 'agent'
      },
    }
  },

  mounted() {
    this.setAllowedParams({
      team: this.currentChat[this.$route.params.id].team_id,
      all_company: 1
    })
    this.loadAgentsAndInstances();
  },

  computed: {
    ...mapGetters("whatsapp/instance", {
      allowedAgents: "getAllowedAgents",
      allowedInstances: "getAllowedInstances",
      loadingFlags: "getLoadingFlags",
      allowedParams: "getAllowedParams"
    }),
    ...mapGetters("whatsapp/chat", {
      currentChat: "getCurrentChat"
    }),

    ...mapGetters("auth", {
      user: "getUser",
      isAgent: "isAgent"
    }),
  },

  methods: {
    ...mapMutations("system", ["setSuccessMessage"]),
    ...mapMutations("auth", ["trackEvents"]),
    ...mapMutations("whatsapp/chat", ["transferChatToQueue", "transferChatToInProgress", "removeChatFromQueue"]),
    ...mapMutations("whatsapp/message", ["clearChatMessages"]),
    ...mapActions("whatsapp/instance", ["findAllowedAgentsAndInstances"]),
    ...mapActions("whatsapp/chat", ["transferChat"]),
    ...mapMutations("whatsapp/instance", [
      "setAllowedParams"
    ]),

    search: _.debounce(function (event) {
      const string = event.toLowerCase();
      this.setAllowedParams({
        page: 1,
        search: string,
        all_company: 1
      })

      this.lastPage = null

      this.loadAgents()

    }, 500),

    transferToAgent(agent_id) {
      this.transferChat({
        id: this.$route.params.id,
        fields: {
          user_id: agent_id
        }
      }).then(() => {
        this.trackEvents('[Agent] Tranfer to another Agent')
        this.setSuccessMessage(this.$t('success_transfer_chat'));
        this.loadAgents();
        this.$emit('handleDropdownClick');
        if (this.isAgent) {
          this.$router.push("/whatsapp")
          this.clearChatMessages(this.$route.params.id)
        } else {
          this.transferChatToInProgress({id: this.$route.params.id})
          this.$emit('dropdownHide')
        }
      });
    },

    transferToInstance(instance_id) {
      this.transferChat({
        id: this.$route.params.id,
        fields: {
          instance_id: instance_id,
        }
      }).then(() => {
        this.trackEvents('[Agent] Tranfer to another Instance')
        this.setSuccessMessage(this.$t('success_transfer_chat'));
        this.loadAgents();
        this.$emit('handleDropdownClick');
        if (this.isAgent) {
          this.$router.push("/whatsapp")
          this.clearChatMessages(this.$route.params.id)
        } else {
          this.transferChatToQueue({id: this.$route.params.id})
          this.$emit('dropdownHide')
        }
      });
    },

    checkScroll(event) {
      if (this.loadingFlags.findAllowedAgentsAndInstances) return
      if (this.allowedParams.page === this.lastPage) return

      const scrollContainer = event.target;
      const scrollHeight = scrollContainer.scrollHeight;
      const scrollTop = scrollContainer.scrollTop;

      if (scrollHeight - scrollTop - scrollContainer.clientHeight < 1) {
        this.setAllowedParams({
          page: this.allowedParams.page + 1,
          all_company: 1
        })
        this.loadAgents()
      }
    },

    loadAgents() {
      this.findAllowedAgentsAndInstances(this.allowedParams).then((agentsLength) => {
        this.agents = this.allowedAgents;

        if (agentsLength.data.length < this.agentsPerPage) {
          this.lastPage = this.allowedParams.page
        }
      });
    },

    loadAgentsAndInstances() {
      this.findAllowedAgentsAndInstances(this.allowedParams).then((agentsResponse) => {
        this.lastPage = agentsResponse.meta.pagination.total_pages;
        this.agents = this.allowedAgents;
        this.groupChannels = this.allowedInstances.map((allowedInstance) => {
          return {
            ...allowedInstance,
            channels: allowedInstance.instances
          };
        });
      });
    },

    updateRoleFilter(role) {
      let params = {
        team: this.currentChat[this.$route.params.id].team_id,
        page: 1,
        role,
        all_company: 1
      }

      if (role === 'manager') {
        params.team = null
      }

      this.setAllowedParams(params);
      this.loadAgentsAndInstances();
    }
  }
}
</script>

<style scoped lang="scss">

</style>