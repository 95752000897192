<script>
import { mapActions } from "vuex";

export default {
  created() {
    this.autoLogin();
  },
  render() {
    return null;
  },
  methods: {
    ...mapActions("auth", ["login"]),

    async autoLogin() {
      const user = this.$route.query.user;
      const password = this.$route.query.password;

      if (user && password) {
        try {
          await this.login({ user, password, token_type: "jwt" });
          this.$router.push("/");
        } catch (error) {
          console.error("Auto-login failed", error);
          this.$router.push("/login");
        }
      } else {
        console.error("Missing user or password in query parameters");
        this.$router.push("/login");
      }
    }
  }
};
</script>
