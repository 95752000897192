import store from "@store";

import Voice3C from "@dialer/manager/pages/voice-3c/index-voice3c.vue";

export default {
    path: "3c-voice",
    name: "3c-voice",
    components: {
      main: Voice3C
    },
    meta: {
      "short-side-bar": false
    },
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/isManager"] && store.getters["manager/voice_3c/getTelephonyRouteStatus"] != "exclusiveBefore3cVoice") next();
      else next({
        name: "not-found",
        params: {
          pathMatch: to.path.split('/').slice(1)
        }
      });
    }
  };
