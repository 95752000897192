export default {
  getLoadingFlags: state => state.loadingFlags,
  getErrors: state => state.errors,
  getActivePartners: state => state.activePartners,
  getActivePartnersPagination: state => state.activePartnersPagination,
  getActivePartnersParams: state =>
    Object.assign(
      {page: state.activePartnersPagination.current_page},
      state.activePartnersFilters
    ),
  getTrashedPartners: state => state.trashedPartners,
  getTrashedPartnersPagination: state => state.trashedPartnersPagination,
  getTrashedPartnersParams: state =>
    Object.assign(
      {page: state.trashedPartnersPagination.current_page},
      state.trashedPartnersFilters
    ),
  getCnpjQueryData: state => state.cnpjQueryData,
  getZipCode: state => state.zipCode,
  getPartner: state => state.partner,

  getWithdrawalAnalysisPartners: state => state.withdrawalAnalysisPartners,
  getWithdrawalAnalysisPartnersPagination: state => state.withdrawalAnalysisPartnersPagination,
  getWithdrawalAnalysisPartnersParams: state =>
    Object.assign(
      {per_page: state.withdrawalAnalysisPartnersPagination.per_page},
      {page: state.withdrawalAnalysisPartnersPagination.current_page},
      state.withdrawalAnalysisPartnersFilters
    ),
  getTotalAnalysisWithdrawPartner: state => state.analysisWithdrawTotalPartners,

  getWithdrawalFinalizedPartners: state => state.withdrawalFinalizedPartners,
  getWithdrawalFinalizedPartnersPagination: state => state.withdrawalFinalizedPartnersPagination,
  getWithdrawalFinalizedPartnersParams: state =>
    Object.assign(
      {per_page: state.withdrawalFinalizedPartnersPagination.per_page},
      {page: state.withdrawalFinalizedPartnersPagination.current_page},
      state.withdrawalFinalizedPartnersFilters
    ),
  getTotalFinalizedWithdrawPartner: state => state.finalizedWithdrawTotalPartners,

  getListPartners: state => state.listPartners,
  getDetailsPartners: state => state.detailsPartner,
  getCompanyDetails: state => state.companyDetails,

  getDashboardPartnerMetrics: state => state.dashboardPartnerMetrics,

  getPartnerDetailsPagination: state => state.partnerDetailsPagination,
};
