<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <router-view
        name="intervals"
        :key="this.$route.fullPath"
      />
    </div>
  </div>
</template>
