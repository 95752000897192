<template>
  <div
    class="kanban-add me-2"
  >
    <div class="card card-sm rounded-4 mb-0">
      <div class="card-body p-4 d-flex flex-column">
        <i
          class="fa-regular fa-close align-self-end cursor-pointer"
          @click="hide"
        />
        <form class="kanban-add-form">
          <div class="form-group">
            <div style="margin-bottom: 16px">
              <label class="form-label">{{ $t('name_capitalize') }}</label>
              <input
                ref="textInput"
                v-model="name"
                :class="{'is-invalid': hasErrors}"
                :placeholder="$t('contract_signing')"
                autofocus
                class="form-control fs-6"
                style="padding: 5px 0 5px 12px"
                type="text"
              >
            </div>

            <div class="mb-4">
              <label class="form-label">{{ $t('color_general') }}</label>
              <color-picker-dropdown
                v-model="color"
                custom-style="padding: 5.5px 16px;"
                has-placeholder
              />
            </div>
          </div>
          <app-button
            :blocked="disabled"
            size="w-100 add-step"
            @click.prevent="addStep"
          >
            {{ $t('create') }}
          </app-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import AppButton from "@components/app-button.vue";
import ColorPickerDropdown from "@components/color-picker-dropdown.vue";

export default {
  components: {ColorPickerDropdown, AppButton},
  emits: ['cancel', 'submit'],
  data() {
    return {
      name: '',
      color: '',
      hasErrors: false
    }
  },

  computed: {
    ...mapGetters("crm/kanban", {
      loadingFlags: "getLoadingFlags",
    }),

    disabled() {
      return !this.name.length || !this.color.length
    }
  },

  mounted() {
    this.$refs.textInput.focus();
  },

  methods: {
    ...mapMutations("auth", ["trackEvents"]),


    addStep() {
      if (this.disabled) {
        this.hasErrors = true
        return;
      }

      this.$emit('submit', {name: this.name, color: this.color});
      this.trackEvents("[General] Created New Opportunity");
    },

    hide() {
      this.text = ''
      this.color = ''
      this.$emit('cancel')
    }
  },

}
</script>

<style lang="scss" scoped>
.add-step {
  padding: 4px 12px;
  font-size: 12px;
}
</style>
