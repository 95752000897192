<template>
  <div
    v-for="(item, index) in accordionItems"
    :key="index"
    class="my-3"
  >
    <div
      v-if="!item.showSelected"
      :id="'accordion'+index"
      class="accordion"
    >
      <div class="accordion-item ">
        <h2 class="accordion-header">
          <button
            :aria-controls="'collapse'+index"
            :data-bs-target="'#collapse'+index"
            :aria-expanded="item.ariaExpanded"
            :class="item.buttonClass + ' accordion-button rounded-0'"
            data-bs-toggle="collapse"
            type="button"
            @click="toggleAccordion(item,index)"
          >
            <span>
              <i
                v-if="item.isSelected"
                class="fal fa-search me-2"
              />
              {{ item.isSelected ? selectedAccordionText : (item.textSelected ? item.textSelected : item.text) }}
            </span>
          </button>
        </h2>
        <div
          :id="'collapse'+index"
          :aria-controls="'collapse'+index"
          :class="{ 'show': item.isSelected }"
          :data-bs-parent="'#accordion'+index"
          class="accordion-collapse collapse"
        >
          <div
            v-for="content in item.content"
            :key="content"
            class="accordion-body bg-white rounded"
          >
            <span
              class="cursor-pointer w-100"
              @click="updateSelectedAccordionText(content, item)"
            >{{ content }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["childClick", "update"],
  data() {
    return {
      accordionItems: [
        {
          text: 'Area de atuação',
          content: ['Credito consignado', 'Consultoria', 'Agencia de marketing', 'Cobrança', 'Inside sales',
            'telemarketing', 'Outros'],
          isSelected: false,
          textSelected: '',
          buttonClass: 'collapsed',
          ariaExpanded: 'false'
        },
        {
          text: 'Cargo',
          content: ['Socio/Fundador', 'C-Level', 'Gerente', 'Supervisor', 'Vendedor', 'Outros'],
          isSelected: false,
          textSelected: '',
          showSelected: false,
          teste: false,
          buttonClass: 'collapsed',
          ariaExpanded: 'false'
        },
      ],
      selectedAccordionText: '',

    };
  },
  methods: {
    toggleAccordion(selectedItem, i) {
      if (selectedItem.isSelected) {
        selectedItem.isSelected = false;
        this.$emit('childClick', false);

        selectedItem.buttonClass = 'collapsed';
        selectedItem.ariaExpanded = 'false';
      } else {
        this.accordionItems.forEach(item => {
          if (item !== selectedItem) {
            item.isSelected = false;
            item.buttonClass = 'collapsed';
            item.ariaExpanded = 'false'
            if (!item.showSelected) {
              item.showSelected = i === 0;
            }
          }
        });
        selectedItem.isSelected = true;
        this.$emit('childClick', true);

        if (selectedItem.textSelected) {
          this.selectedAccordionText = selectedItem.textSelected;
        } else {
          this.selectedAccordionText = selectedItem.text;
        }

        selectedItem.buttonClass = '';
        selectedItem.ariaExpanded = 'true'
      }
      if (!selectedItem.isSelected && i === 0) {
        this.accordionItems[1].showSelected = false;
      }
    },

    updateSelectedAccordionText(content, selectedItem) {
      selectedItem.textSelected = content;
      this.selectedAccordionText = content;
      this.accordionItems.forEach(item => {
        item.isSelected = item === selectedItem;
      });
      this.$emit('update', {textSelected: selectedItem.textSelected, selectedItem});

      this.toggleAccordion(selectedItem, this.accordionItems.indexOf(selectedItem));
    }
  }
};
</script>
<style
  lang="scss"
  scoped
>
.accordion-button {
  box-shadow: none;
  background: none;
  border: none;
  height: 42px;
  padding: 8px 12px;
}

.accordion-body {
  padding: 0.1rem 1.25rem;
}

.accordion-header {
  border: none;
}

</style>
