<template>
  <div>
    <div
      ref="offcanvas"
      class="offcanvas offcanvas-end form-feedback"
      style="overflow-y: scroll; width: 660px !important"
    >
      <main-loading v-if="loadingFlags.findFeedbackByCallId" />
      <template v-else>
        <div
          class="offcanvas-header text-center"
          v-if="feedbackRegister"
        >
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div
          class="offcanvas-body"
          v-if="feedbackRegister"
        >
          <div class="col-lg-12">
            <h3 class="text-center">
              {{
                $t("feedback_used_as_metric")
              }}
            </h3>
            <br>
          </div>
          <div class="row">
            <div class="col-lg-3 call-info">
              <small>{{ $t("agent_capitalize") }}</small><br>
              <strong>
                <small>{{ call.agent }} </small>
              </strong>
            </div>
            <div class="col-lg-3 call-info">
              <small>{{ $t("number") }}</small><br>
              <strong>
                <small>{{ formatPhoneNumber(call.number) }}</small>
              </strong>
            </div>
            <div class="col-lg-3 call-info">
              <small>{{ $t("date_capitalize") }}</small><br>
              <strong>
                <small>{{ formatDate(call.date) }}</small>
              </strong>
            </div>
            <div class="col-lg-3 call-info">
              <small>{{ $t("qualification") }}</small><br>
              <strong>
                <small>{{ call.qualification }}</small>
              </strong>
            </div>
            <div class="col-lg-12 py-4">
              <div class="form-group">
                <label
                  for="name"
                >{{ $t("call") }} </label>
                <audio
                  preload="none"
                  controls
                  controlsList="nodownload"
                  class="w-100"
                >
                  <source
                    :src="recording"
                    type="audio/mpeg"
                  >
                </audio>
              </div>
            </div>
            <div
              class="col-lg-12"
              v-for="(item, i) of this.fields.criterion_json"
              :key="i"
            >
              <div class="form-group">
                <label class="form-label">
                  <dot-status
                    :color="item.color"
                    size="14px"
                  />
                  {{ item.name }}</label>
                <input-text-range
                  :color="item.color"
                  v-model="item.grade"
                  :errors="errors[`criterion_json.${i}`]"
                  mark
                  :max="item.high_score"
                />
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label class="form-label text-left">{{ $t("comment") }}:</label>
                <textarea
                  class="form-control"
                  v-model="fields.comment"
                  rows="3"
                />
              </div>
            </div>
            <div class="col-lg-12">
              <app-button
                class="w-100"
                @click="submit()"
                :loading="loadingFlags.updateFeedback || loadingFlags.applyFeedback"
              >
                {{ feedbackRegister.id ? $t("update_omni") : $t("save_general") }}
              </app-button>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { Offcanvas } from "bootstrap";
import { mapActions, mapGetters } from "vuex";
import InputTextRange from "@components/input-text-range";
import AppButton from "@components/app-button";
import MainLoading from "@/components/main-loading.vue";
import { formatPhoneNumber } from "@/utils";
import moment from "moment";

import DotStatus from "@/components/dot-status";

export default {
  data() {
    return {
      offCanvas: null,
      fields: {
        criterion_json: [],
        comment: "",
        agent_id: null,
        company_id: null,
        manager_id: null,
        id: null,
        created_at: "",
        updated_at: "",
        start_date: "",
        end_date: "",
      },
    };
  },

  mounted() {
    this.offCanvas = new Offcanvas(this.$refs.offcanvas);
    this.show ? this.open() : this.offCanvas.hide();
    this.$refs.offcanvas.addEventListener("hide.bs.offcanvas", this.onHideOffcanvas);
  },

  beforeUnmount() {
    this.setFeedbackRegister(null);
    this.offCanvas.hide();
  },

  emits: ["close", "update-feedback"],

  computed: {
    ...mapGetters("manager/calls-report", {
      feedbackRegister: "getFeedbackRegister",
      loadingFlags: "getLoadingFlags",
      errors: "getErrors",
    }),
  },

  watch: {
    feedbackRegister(val) {
      if (this.$refs.offcanvas.classList.contains("show")) {
        this.fields.agent_id = val.agent_id;
        this.fields.campaign_id = this.campaignId;
        this.fields.call_history_id = this.callHistoryId;
        this.fields.created_at = val.created_at;
        this.fields.updated_at = val.updated_at;
        this.fields.id = val.id;
        this.fields.criterion_json = [];
        this.fields.comment = val.comment;
        for (let item of val.criterion_json) {
          this.fields.criterion_json.push(item);
        }
      }
    },

    show(data) {
      data ? this.open() : this.offCanvas.hide();
    },
  },

  components: {
    InputTextRange,
    AppButton,
    MainLoading,
    DotStatus,
  },

  props: {
    campaignId: {
      type: Number,
      required: true,
    },

    callHistoryId: {
      type: String,
      required: true,
    },

    call: {
      type: Object,
      default: () => null,
    },

    recording: {
      type: String,
      default: "#",
    },

    show: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    formatPhoneNumber: formatPhoneNumber,

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY [às] HH[h]mm[m]ss[s]");
    },

    submit() {
      if (this.feedbackRegister.id) {
        this.updateFeedback({
          fields: this.fields,
        })
            .then(() => {
              this.offCanvas.hide();
              this.$emit("update-feedback", { agent_id: this.fields.agent_id });
            });
      } else {
        this.applyFeedback({
          fields: this.fields,
        }).then(() => {
          this.offCanvas.hide();
        });
      }
    },

    ...mapActions("manager/calls-report", [
      "findFeedbackByCallId",
      "applyFeedback",
      "updateFeedback",
      "setFeedbackRegister",
    ]),

    ...mapActions("manager/feedback-report", [
      "findAgentFeedbackReports",
      "findFeedbackReports",
    ]),

    open() {
      this.offCanvas.show();
      this.findFeedbackByCallId({
        campaign_id: this.campaignId,
        call_history_id: this.callHistoryId,
      });
    },

    onHideOffcanvas() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
.form-feedback {
  width: 500px !important;

  .ball {
    font-size: 18px;
  }
}
</style>
