<template>
  <div class="col-12 my-5 align-self-center align-items-center justify-content-center d-flex flex-column">
    <div class="text-center">
      <img
        src="@assets/img/logo.svg"
        class="img-fluid"
        style="height: 48px !important"
      >
    </div>
    <div class="col-12">
      <h1 class="display-4 mb-3 mt-5 fw-normal text-center">
        Quase lá!
      </h1>
      <p class="text-black text-center fs-4">
        Caso o e-mail informado esteja cadastrado no 3C Plus, você receberá uma mensagem em
        <a
          :href="'mailto: ' + email"
          class="text-primary"
        >{{ email }}</a> 
        com o link para redefinir sua senha.
      </p>
      <button
        @click="$router.push('/login')"
        type="button"
        class="btn btn-primary w-100 mt-3 mb-4"
      >
        Voltar ao login
      </button>
      <p class="tx-text-gray text-center fs-4">
        <span class="fw-bold">Não recebeu o email?</span>
        Verifique se digitou o endereço de e-mail corretamente ou se caiu na caixa de spam
      </p>
    </div>
  </div>
</template>
<script>  
export default {
  props: {
    email: {
      type: String,
      default: () => {}
    }
  },
};
</script>