<template>
  <div class="container-fluid">
    <header-component
      :title="$t('receptive_queues_capitalize')"
      redirect-link="/manager/voice-panel"
    >
      <template #buttons>
        <button
          v-if="user.role.name == 'manager'"
          class="btn btn-primary"
          @click="$router.push({ path: '/manager/receptive-queue/new' })"
        >
          {{ $t("add.receptive_queue") }}
        </button>
      </template>
    </header-component>
    <div class="row">
      <div class="col-lg-8">
        <div class="card h-100">
          <div class="card-header">
            <div class="row align-items-center">
              <search-input @search="search" />
            </div>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-hover table-nowrap card-table">
                <thead>
                  <tr>
                    <th />
                    <th class="ps-0">
                      <sort-link
                        :title="$t('name')"
                        column="name"
                        :dir="sorts.name.dir"
                        @sort="sort"
                        @click.prevent
                        class="text-muted fw-normal"
                      />
                    </th>
                    <th>
                      <sort-link
                        :title="$t('missed_calls_short')"
                        column="abandoned"
                        :dir="sorts.abandoned.dir"
                        @sort="sort"
                        @click.prevent
                        class="text-muted fw-normal"
                      />
                    </th>
                    <th>
                      <sort-link
                        :title="$t('active_calls_short')"
                        column="active_calls"
                        :dir="sorts.active_calls.dir"
                        @sort="sort"
                        @click.prevent
                        class="text-muted fw-normal"
                      />
                    </th>
                    <th>
                      <sort-link
                        :title="$t('online_agents')"
                        column="online_agents"
                        :dir="sorts.online_agents.dir"
                        @sort="sort"
                        @click.prevent
                        class="text-muted fw-normal"
                      />
                    </th>
                    <th>
                      <sort-link
                        :title="$t('received_calls')"
                        column="received_calls"
                        :dir="sorts.received_calls.dir"
                        @sort="sort"
                        @click.prevent
                        class="text-muted fw-normal"
                      />
                    </th>
                    <th>
                      <sort-link
                        title="TME"
                        column="awt"
                        :dir="sorts.awt.dir"
                        @sort="sort"
                        @click.prevent
                        class="text-muted fw-normal"
                      />
                    </th>
                    <th>
                      <sort-link
                        title="TMA"
                        column="ast"
                        :dir="sorts.ast.dir"
                        @sort="sort"
                        @click.prevent
                        class="text-muted fw-normal"
                      />
                    </th>
                    <th>
                      <sort-link
                        :title="$t('priority_capitalize')"
                        column="priority"
                        :dir="sorts.priority.dir"
                        @sort="sort"
                        @click.prevent
                        class="text-muted fw-normal"
                      />
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody class="list fs-base">
                  <tr
                    class="cursor-pointer"
                    @mouseover="selectItem(total)"
                    @mouseleave="unSelectItem()"
                  >
                    <td>
                      <input
                        v-model="activeMetric"
                        :value="total"
                        type="radio"
                        class="form-check-input"
                        :id="'checkList' + total.id"
                      >
                    </td>
                    <td class="ps-0">
                      <div
                        class="text-truncate"
                        style="max-width: 250px;"
                      >
                        {{ $t("all_queues") }}
                      </div>
                    </td>
                    <td> {{ total.abandoned }}</td>
                    <td> {{ total.active_calls + total.hold }}</td>
                    <td> {{ total.agents.status_count[AgentStatus.ONLINE] }}</td>
                    <td> {{ total.connected + total.abandoned }}</td>
                    <td v-if="total.connected || total.abandoned">
                      {{ convertSecondsToTime(parseInt(total.awt)) }}
                    </td>
                    <td v-else>
                      00:00:00
                    </td>
                    <td v-if="total.connected">
                      {{ convertSecondsToTime(parseInt((total.ast))) }}
                    </td>
                    <td v-else>
                      00:00:00
                    </td>
                    <td>
                      <span
                        class="fe fe-chevron-right text-primary"
                        :style="{
                          visibility:
                            total.id == selectedItem ? 'visible' : 'hidden'
                        }"
                      />
                    </td>
                    <td />
                  </tr>
                  <template v-if="queues.length && !loading">
                    <tr
                      class="cursor-pointer"
                      v-for="queue in queues"
                      :key="queue.id"
                      @mouseover="selectItem(queue.id)"
                      @mouseleave="unSelectItem()"
                      @click="redirectToReceptiveQueue(queue.id)"
                    >
                      <td @click.stop>
                        <input
                          v-model="activeMetric"
                          :value="queue"
                          type="radio"
                          class="form-check-input"
                          :id="'checkList' + queue.id"
                        >
                      </td>
                      <td class="ps-0">
                        <div
                          class="d-inline-block text-truncate"
                          style="max-width: 200px;"
                        >
                          {{ queue.extension_number }} - {{ queue.name }}
                        </div>
                      </td>
                      <td>
                        {{ queue.abandoned }}
                      </td>
                      <td>
                        {{ queue.active_calls + queue.hold }}
                      </td>
                      <td>
                        {{ queue.agents.status_count[AgentStatus.ONLINE] }}
                      </td>
                      <td>
                        {{ queue.connected + queue.abandoned }}
                      </td>
                      <td v-if="queue.connected || queue.abandoned">
                        {{ convertSecondsToTime(parseInt(queue.awt)) }}
                      </td>
                      <td v-else>
                        00:00:00
                      </td>
                      <td v-if="queue.connected">
                        {{ convertSecondsToTime(parseInt(queue.ast)) }}
                      </td>
                      <td v-else>
                        00:00:00
                      </td>
                      <td class="text-center">
                        {{ queue.priority }}
                      </td>
                      <td>
                        <span
                          class="fe fe-chevron-right text-primary"
                          :style="{
                            visibility:
                              queue.id == selectedItem ? 'visible' : 'hidden'
                          }"
                        />
                      </td>
                    </tr>
                  </template>
                  <template v-if="loading">
                    <loading-table-component
                      :lines="15"
                      :columns="9"
                      :text-center="false"
                      :list-width="[16,50,50,50,50,50,50,50,50]"
                    />
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4"
        role="tabpanel"
      >
        <agents-chart
          :active-metric="activeMetric"
          :online-agents-chart="onlineAgentsChart"
        />
        <div class="card ">
          <div class="card-header">
            <h4 class="card-header-title fw-normal">
              {{ $t("active_calls_capitalize") }}
            </h4>
            <span v-if="activeMetric">
              {{ activeMetric.active_calls + activeMetric.hold }}
            </span>
          </div>
          <div
            v-if="activeMetric"
            class="card-body align-items-center"
          >
            <progressbar-multiple
              v-if="activeMetric"
              style="height: 5px;"
              :use-icon="false"
              :data="[
                {
                  color: '#5cb85c',
                  percentage: activeMetric.active_calls ? activeMetric.active_calls / (activeMetric.active_calls + activeMetric.hold) * 100 : 0,
                  count: activeMetric.active_calls,
                  title: $t('talking_capitalize')
                },
                {
                  color: '#eb5545',
                  percentage: activeMetric.hold ? activeMetric.hold / (activeMetric.active_calls + activeMetric.hold) * 100 : 0,
                  count: activeMetric.hold,
                  title: $t('waiting_capitalize')
                },
              ]
              "
            />
            <div class="chart-legend">
              <span class="chart-legend-item">
                <span class="chart-legend-indicator bg-speaking" />{{ $t("talking_capitalize") }}
              </span>
              <span class="chart-legend-item">
                <span class="chart-legend-indicator bg-abandoned" />{{ $t("waiting_capitalize") }}
              </span>
            </div>
          </div>
          <div
            v-else
            class="card-body text-center"
          >
            {{ $t("queue_metrics_select_capitalize") }}
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title fw-normal">
              {{ $t("received_calls_capitalize") }}
            </h4>
            <span v-if="activeMetric">{{
              activeMetric.abandoned + activeMetric.connected
            }}</span>
          </div>
          <div
            v-if="activeMetric"
            class="card-body align-items-center"
          >
            <progressbar-multiple
              v-if="activeMetric"
              style="height: 5px;"
              :use-icon="false"
              :data="[
                {
                  color: '#5cb85c',
                  percentage: activeMetric.connected ? activeMetric.connected / (activeMetric.connected + activeMetric.abandoned) * 100 : 0,
                  count: activeMetric.connected,
                  title: $t('connected_capitalize')
                },
                {
                  color: '#eb5545',
                  percentage: activeMetric.abandoned ? activeMetric.abandoned / (activeMetric.connected + activeMetric.abandoned) * 100 : 0,
                  count: activeMetric.abandoned,
                  title: $t('abandoned_capitalize')
                },
              ]
              "
            />
            <div class="chart-legend">
              <span class="chart-legend-item">
                <span class="chart-legend-indicator bg-speaking" />{{ $t("connected_capitalize") }}
              </span>
              <span class="chart-legend-item">
                <span class="chart-legend-indicator bg-abandoned" />{{ $t("abandoned_capitalize") }}
              </span>
            </div>
          </div>
          <div
            v-else
            class="card-body text-center"
          >
            {{ $t("queue_metrics_select_capitalize") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import _ from "lodash";

import { convertSecondsToTime, AgentStatus } from "@/utils.js";

import ProgressbarMultiple from "@components/progressbar-multiple";
import SearchInput from "@components/search-input";
import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";
import SortLink from "@dialer/manager/components/sort-link";
import HeaderComponent from "@/components/header-component.vue";
import AgentsChart from "@dialer/manager/pages/dashboards/receptive/components/agents-chart.vue";

export default {
  data() {
    return {
      AgentStatus,
      onlineAgentsChart: {
        title: this.$t("online_agents_capitalize"),
        labels: [this.$t("idle_capitalize"), this.$t("talking_capitalize"), this.$t("act"), this.$t("manual_capitalize"), this.$t("mact"), this.$t("break_capitalize")],
        colors: ["#C4C4C4", "#5cb85c", "#3075e3", "#de7912", "#863fb3", "#ffc82c"],
      },
      activeMetric: false,
      selectedItem: false,
      term: "",

      sorts: {
        name: {
          dir: "",
        },
        abandoned: {
          dir: "",
        },
        active_calls: {
          dir: "",
        },
        online_agents: {
          dir: "",
        },
        received_calls: {
          dir: "",
        },
        awt: {
          dir: "",
        },
        ast: {
          dir: "",
        },
        priority: {
          dir: "",
        },
      },
    };
  },
  components: {
    AgentsChart,
    SearchInput,
    ProgressbarMultiple,
    LoadingTableComponent,
    SortLink,
    HeaderComponent,
  },
  methods: {
    ...mapActions("manager/receptive_queues", [
      "loadActiveReceptiveQueues",
      "loadReceptiveCalls",
      "loadReceptiveMetrics",
      "loadReceptiveAgents",
    ]),
    ...mapMutations("manager/receptive_queues", [
      "clearQueues",
      "sortReceptiveQueues",
    ]),
    convertSecondsToTime,
    handleOnlineAgentsChartData(status_count) {
      this.onlineAgentsChart.data = [
        status_count[AgentStatus.IDLE],
        status_count[AgentStatus.ON_CALL],
        status_count[AgentStatus.ACW],
        status_count[AgentStatus.ON_MANUAL_CALL],
        status_count[AgentStatus.ON_MANUAL_CALL_ACW],
        status_count[AgentStatus.ON_WORK_BREAK],
      ];
    },

    selectItem(item) {
      this.selectedItem = item;
    },
    unSelectItem() {
      this.selectedItem = false;
    },

    sort(data) {
      this.sorts["name"].dir = "";
      this.sorts["abandoned"].dir = "";
      this.sorts["active_calls"].dir = "";
      this.sorts["online_agents"].dir = "";
      this.sorts["received_calls"].dir = "";
      this.sorts["awt"].dir = "";
      this.sorts["ast"].dir = "";
      this.sorts[data.column].dir = data.dir;

      this.sortReceptiveQueues(data);
    },
    search: _.debounce(function (term) {
      this.clearQueues();
      this.loadActiveReceptiveQueues({
        search: term,
        per_page: -1,
        include: "number_settings",
      });
    }, 500),
    redirectToReceptiveQueue(id) {
      this.$router.push(`/manager/receptive-queue/${id}`);
    },
  },
  computed: {
    ...mapGetters("manager/receptive_queues", {
      queues: "getQueues",
      total: "getTotal",
      loading: "isLoading",
    }),
    ...mapGetters("auth", {
      user: "getUser",
    }),
  },
  async mounted() {
    await this.loadActiveReceptiveQueues({
      per_page: -1,
      include: "number_settings",
    });
    await this.loadReceptiveCalls();
    await this.loadReceptiveMetrics();
    await this.loadReceptiveAgents().then(() => {
      this.handleOnlineAgentsChartData(this.total.agents.status_count);
      this.activeMetric = this.total;
    });
  },
};
</script>

<style lang="scss" scoped>
.header-body {
  border-bottom: none !important;
}

.rounded-calendar {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.table > :not(:first-child) {
  border-top: none;
}
</style>
