<template>
  <div>
    <select
      :class="{
        'form-select': true,
        'is-invalid': (errors[0] != undefined || errors[1] != undefined)
      }"
      @change="$emit('update:modelValue', this.route);"
      v-model="route"
      :disabled="loading"
    >
      <option
        v-if="defaultValue"
        :value="defaultValue.value"
      >
        {{ defaultValue.name }}
      </option>
      <option
        value=""
        v-else
      >
        {{ $t('standard') }}
      </option>

      <template v-if="hideGroups">
        <option 
          v-for="item in dataSelect"
          :key="item.id"
          :value="item.id"
        >
          {{ item.name }}
        </option>
      </template>
      <template v-else>
        <optgroup label="Rotas">
          <template v-for="item in dataSelect">
            <option
              v-if="
                (allowLandline && item.allow_landline) ||
                  (allowMobile && item.allow_mobile)
              "
              :label="item.name"
              :key="item.id"
              :value="{
                id: item.id,
                type: 'route'
              }"
            />
          </template>
        </optgroup>
        <optgroup
          :label="$t('groups')"
          v-if="allowLandline"
        >
          <template
            v-for="item in routeGroups"
            :key="item.id"
          >
            <option
              :value="{
                id: item.id,
                type: 'group'
              }"
              v-if="item.group_type === 'landline'"
            >
              {{ $t('group')+': ' + item.name }}
            </option>
          </template>
        </optgroup>
        <optgroup
          :label="$t('groups')"
          v-if="allowMobile"
        >
          <template
            v-for="item in routeGroups"
            :key="item.id"
          >
            <option
              :value="{
                id: item.id,
                type: 'group'
              }"
              v-if="item.group_type === 'mobile'"
            >
              {{ $t('group')+': ' + item.name }}
            </option>
          </template>
        </optgroup>
      </template>
    </select>
    <div
      class="invalid-feedback"
      v-for="(error, index) in errors"
      :key="index"
    >
      {{ error && error[0] }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      route: ""
    }
  },
  /**
   *
   */
  emits: ["update:modelValue"],

  /**
   *
   */
  mounted() {
    this.loadDataSelect();
    this.listRouteGroups();

    if(this.modelValue.id) {
      this.route = this.modelValue;
    }
  },

  /**
   *
   */
  computed: {
    ...mapGetters("manager/routes", {
      dataSelect: "getDataSelect",
      loading: "isLoading",
    }),
    ...mapGetters("manager/telephony", {
      routeGroups: "getRouteGroups"
    }),
  },

  props: {
    /**
     *
     */
    modelValue: {
      default: null,
      type: [Object, String, Number],
    },
    /**
     *
     */
    allowMobile: {
      type: Boolean,
      default: true,
    },

    /**
     *
     */
    allowLandline: {
      type: Boolean,
      default: true,
    },

    /**
     *
     */
    errors: {
      type: Array,
      default: () => [],
    },

    /**
     *
     */
    defaultValue: {
      type: Object,
      default: null,
    },

    /**
     * if true show only the routes in the select
     */
     hideGroups: {
      type: Boolean,
      default: false
     }
  },

  /**
   *
   */
  methods: {
    ...mapActions("manager/routes", ["loadDataSelect"]),
    ...mapActions("manager/telephony", ["listRouteGroups"]),
  },

};
</script>