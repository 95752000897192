<template>
  <div
    class="position-absolute overflow-hidden container-overflow rounded-4"
    :style="cardStyle"
  >
    <slide-transition
      position="top"
      :opacity="true"
    >
      <div
        v-if="isOpen"
        class="card-open rounded-4"
      >
        <div class="rounded-4 bg-white">
          <div class="d-flex align-items-center justify-content-between">
            <h5
              class="mb-0 tx-text-200"
              style="padding: 1rem;"
            >
              {{ title }}
            </h5>
            <slot name="header" />
          </div>
          <hr class="m-0">
          <slot />
        </div>
      </div>
    </slide-transition>
  </div>
</template>

<script>
import SlideTransition from "@/components/slide-transition.vue";

export default {
  name: "DropdownComponent",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    buttonRect: {
      type: Object,
      default: null,
    },

    direction: {
      type: String,
      default: "left" // right
    },

    title: {
      type: String,
      default: ""
    },

    customWidth: {
      type: String,
      default: "380px"
    },

    leftDisplacement: {
      type: Number,
      default: 80
    },

    rightDisplacement: {
      type: Number,
      default: 395
    },
  },

  components: {
    SlideTransition
  },

  computed: {
    cardStyle() {
      if (!this.buttonRect) return {};

      if(this.direction === "left") {
        return {
          top: `${this.buttonRect.bottom + 11}px`,
          left: `${this.buttonRect.left - this.leftDisplacement}px`,
          zIndex: 1,
          "--custom-width": this.customWidth,
        };
      } else {
        return {
          top: `${this.buttonRect.bottom + 11}px`,
          left: `${this.buttonRect.left - this.rightDisplacement}px`,
          zIndex: 1,
          "--custom-width": this.customWidth,
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-overflow {
  width: var(--custom-width);

  .card-open {
    margin: 18px;
    box-shadow: 0px 4px 18px 0px rgba(34, 54, 77, 0.12);
  }
}
</style>
