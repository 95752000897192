import axios from "axios";

export default {
  findQueuesOnline({ commit }) {
    commit("startLoadingFlag", "findQueuesOnline");
    commit('setListQueuesOnline', []);

    return new Promise((resolve, reject) => {
      axios.get('/receptive_queues/online').
        then((response) => {
          commit('setListQueuesOnline', response.data.data);
          resolve();
        })
        .catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "findQueuesOnline");
        });
    });
  },

  consultQueue({ commit }, payload) {
    commit("startLoadingFlag", "consultQueue");

    return new Promise((resolve, reject) => {
      axios.post('/agent/consult/receptive', payload).
        then(() => resolve())
        .catch((response) => {
          commit("stopLoadingFlag", "consultQueue");
          commit('system/setErrorMessage', response.response.data, { root: true });
          commit("setQueueInConsult", null);
          reject();
        })
    });
  },

  setQueueInConsult({ commit }, queueInConsult) {
    commit("setQueueInConsult", queueInConsult);
  }
}
