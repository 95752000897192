<template>
  <div
    ref="canvas"
    class="offcanvas offcanvas-end container-fluid"
  >
    <div class="offcanvas-header border-0 pb-0">
      <button
        aria-label="Close"
        class="close-offcanvas bg-transparent border-0 text-reset d-flex gap-3 fs-4 align-items-center"
        data-bs-dismiss="offcanvas"
        type="button"
      >
        <i class="fa-regular fa-arrow-left fs-4 border border-1 p-2 bg-white rounded-circle" />
        <span>{{ $t('close') }}</span>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="card">
        <div class="card-body d-flex align-items-center justify-content-between">
          <h1 class="m-0">
            {{ computedCompanyDetails.name }}
          </h1>
          <div class="d-flex align-items-center gap-4">
            <div>
              <h5 class="tx-text-gray">
                {{ $t('value') }}
              </h5>
              <h3
                :class="{'tx-gray-400': !computedCompanyDetails.next_billing?.amount}"
                class="m-0"
              >
                {{
                  computedCompanyDetails.next_billing?.amount ?
                    formatCurrency(parseFloat(computedCompanyDetails.next_billing?.amount)) :
                    '-'
                }}
              </h3>
            </div>
            <div class="divider border border-end-1" />
            <div>
              <h5 class="tx-text-gray">
                {{ $t('invoice') }}
              </h5>
              <badge-status
                v-if="computedCompanyDetails.next_billing?.status"
                :status="computedCompanyDetails.next_billing?.status"
              />
              <h3
                v-else
                class="m-0 tx-gray-400"
              >
                -
              </h3>
            </div>
            <div class="divider border border-end-1" />
            <div>
              <h5 class="tx-text-gray">
                {{ $t('start') }}
              </h5>
              <h3
                :class="{'tx-gray-400': !computedCompanyDetails.next_billing?.due_at}"
                class="m-0"
              >
                {{
                  computedCompanyDetails.next_billing?.due_at ?
                    formatDate(computedCompanyDetails.next_billing?.due_at, 'L') :
                    '-'
                }}
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div class="row gap-4 px-3">
        <template
          v-for="(subscription, i) in computedCompanyDetails?.subscriptions"
          :key="i"
        >
          <div
            v-for="product in subscription.product_items"
            :key="product.id"
            class="col card mb-0 p-0"
            style="min-width: 48%"
          >
            <div class="card-body">
              <div class="d-flex gap-3 align-items-center">
                <div class="icon-detail d-flex justify-content-center align-items-center">
                  <i
                    :class="getIcon(product.product.data.code)"
                    class="text-primary"
                  />
                </div>
                <h4 class="m-0">
                  {{ product.product.data.name }}
                </h4>
              </div>
              <hr>
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h5>{{ `${product.quantity} ${verifyUserRole(product.product.data.code, product.quantity)}` }}</h5>
                  <span class="tx-text-gray fs-6">{{
                    `x ${product.pricing_schema.data.short_format}`
                  }}</span>
                </div>
                <div class="d-flex flex-column align-items-end">
                  <h5>
                    {{
                      formatCurrency(parseFloat(product.quantity) * parseFloat(product.pricing_schema.data.price))
                    }}
                  </h5>
                  <span class="tx-text-gray fs-6">{{ `/${subscription.interval}` }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>

      <div class="card mt-4">
        <div class="card-body p-0">
          <h3 style="margin: 16px 0 16px 24px">
            {{ $t('payment_history') }}
          </h3>
          <div class="tab-content">
            <div
              id="active-partners"
              aria-labelledby="active-partners"
              class="tab-pane fade show active"
              role="tabpanel"
            >
              <table class="table table-nowrap app-datatable m-0">
                <thead>
                  <tr>
                    <th class="ps-4">
                      {{ $t('uppercases.bank_slip') }}
                    </th>
                    <th>
                      {{ $t('uppercases.institution') }}
                    </th>
                    <th>
                      {{ $t('uppercases.date') }}
                    </th>
                    <th>
                      {{ $t('uppercases.value') }}
                    </th>
                    <th>
                      STATUS
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-for="history in computedCompanyDetails.bills"
                    :key="history.id"
                  >
                    <tr v-if="history.charges.data.length">
                      <td class="ps-4 tx-text-200">
                        {{ history.charges.data[history.charges.data.length - 1].payment_method.data.name }}
                      </td>
                      <td class="tx-text-gray">
                        {{ history.charges.data[history.charges.data.length - 1].payment_method.data.public_name }}
                      </td>
                      <td class="tx-text-gray">
                        {{ formatDate(history.due_at, 'L') }}
                      </td>
                      <td class="tx-text-200">
                        {{ formatCurrency(parseFloat(history.amount)) }}
                      </td>
                      <td class="fw-medium">
                        <badge-status :status="history.status" />
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Offcanvas} from "bootstrap";
import {mapGetters} from "vuex";
import BadgeStatus from "@components/badge-status.vue";
import {formatDate, Utils} from "@/utils";

export default {
  name: "OffcanvasPartnerDetails",
  components: {BadgeStatus},
  data() {
    return {
      offcanvas: null,
      roleMapping: {
        ivr: {singular: this.$tc('lowercases.channel', 1), plural: this.$tc('lowercases.channel', 2)},
        receptive: {singular: this.$t('lowercases.number'), plural: this.$t('lowercases.numbers')},
        connection: {singular: this.$t('lowercases.connection'), plural: this.$t('lowercases.connections')},
        default: {singular: this.$t('lowercases.user'), plural: this.$t('lowercases.users')},
      },
      iconMapping: {
        agent: 'fa-regular fa-phone',
        extension: 'fa-regular fa-phone',
        whatsapp: 'fa-regular fa-comment-alt',
        ivr: 'fa-regular fa-voicemail',
        receptive: 'fa-regular fa-phone-arrow-down-left',
        default: 'fa-regular fa-plus',
      },
    }
  },
  mounted() {
    this.offcanvas = new Offcanvas(this.$refs.canvas);
  },
  computed: {
    ...mapGetters("partner", {
      companyDetails: "getCompanyDetails",
      detailsPartner: "getDetailsPartner"
    }),

    ...mapGetters("admin/partners", {
      companyDetailsAdmin: "getCompanyDetails",
    }),

    computedCompanyDetails() {
      if (Object.keys(this.detailsPartner).length === 0) {
        return this.companyDetailsAdmin;
      }
      return this.companyDetails;
    },
  },
  methods: {
    formatCurrency: Utils.formatCurrency,

    new() {
      this.offcanvas.show()
    },

    formatDate: formatDate,

    findMatchingCode(code, mapping) {
      if (!code) {
        return mapping['default'];
      }
      const matchingCode = Object.keys(mapping).find(key => code.includes(key)) || 'default';
      return mapping[matchingCode];
    },

    getIcon(code) {
      return this.findMatchingCode(code, this.iconMapping);
    },

    verifyUserRole(code, quantity) {
      const {singular, plural} = this.findMatchingCode(code, this.roleMapping);
      return quantity > 1 ? plural : singular;
    }
  }
}
</script>
<style lang="scss" scoped>
.offcanvas {
  min-width: 70vw;
  background: #F9FAFC;
}

.close-offcanvas:hover > span {
  color: $color-blue-300;
}

.divider {
  height: 24px;
  border-color: $color-gray-blue-300
}

.icon-detail {
  background: $color-gray-blue-300;
  border-radius: 6px;
  width: 32px;
  height: 32px;
}

.status {
  background: $color-gray-blue-300;
  border-radius: 4px;
  font-size: 12px;
}
</style>
