
export default {
    isLoading: state => state.loading,
    getList: state => state.list,
    getErrorMessage: state => state.errorMessage,
    getErrors: state => state.errors,
    getloadingContext: state => state.loadingContext,
    getPagination: state => state.pagination,
    getCampaign(state) {
        return state.campaign
    },
    getRegister: state => state.register,
    getChartData: state => state.chartData,
    getListDataPreview: (state) => state.listDataPreview,
    getListHeaderPreview: state => state.listHeaderPreview,
    getFileName: state => state.fileName,
    getValidatedMailingListData: state => state.validatedMailingListData,
    getStartedList: state => state.startedList,
}
