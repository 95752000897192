import store from "@store";

import Index from "@whatsapp/manager/pages/index-whatsapp.vue";
import ConnectionsListDashboard from "@whatsapp/manager/pages/dashboards/connections-list/connections-list-dashboard.vue";
import ConnectionDashboard from "@whatsapp/manager/pages/dashboards/connection/connection-dashboard.vue";
import EditConnection from "@whatsapp/manager/pages/dashboards/connection/edit-connection.vue";

export default {
  path: "whatsapp",
  name: "whatsapp",
  components: {
    main: Index
  },
  meta: {
    "short-side-bar": false
  },
  children: [
    {
      path: "",
      name: "whatsapp",
      components: {
        whatsapp: ConnectionsListDashboard
      }
    },
    {
      path: "new",
      name: "new-connection",
      components: {
        whatsapp: EditConnection
      }
    },
    {
      path: ":id",
      name: "whatsapp-dashboard",
      components: {
        whatsapp: ConnectionDashboard
      }
    },
    {
      path: ":id/edit",
      name: "edit-connection",
      components: {
        whatsapp: EditConnection
      }
    }
  ],
beforeEnter: (to, from, next) => {
  if (store.getters["auth/isSupervisorOrManager"]) {
    if(store.getters["auth/getUser"]?.company?.company_financial_status.status === "Bloqueada"){
      store.commit('auth/setPaymentModal', true);
    }else {
      store.commit('auth/setPaymentModal', false);
    }
    next();
  }
  else next({
    name: "not-found",
    params: {
      pathMatch: to.path.split('/').slice(1)
    }
  });
}
};
