<template>
  <input
    class="form-control"
    :class="{ 'is-invalid': errors, customClass: true, 'readonly-input' : readonly}"
    :placeholder="placeholder"
    :maxlength="maxLength"
    :readonly="readonly"
    :disabled="disabled"
    v-model="value"
    @input="money"
    :data-test="this.dataTest"
  >
  <div class="invalid-feedback">
    {{ errors && errors[0] }}
  </div>
</template>

<script>
import {Utils} from '@/utils';

export default {
  data() {
    return {
      value: null,
      avaliableLocales: [
        { value: 'pt-BR', text: 'Português', abbr: 'BRL' },
        { value: 'en-US', text: 'Dolar', abbr: 'USD'},
        { value: 'en-ES', text: 'Euro', abbr: 'EUR'}
      ]
    }
  },

  emits: ["currency", 'update:modelValue'],

  props: {
    modelValue: {
      type: [Number, String],
      default: 0
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ""
    },
    errors: {
      type: Array,
      default: null,
    },

    dataTest: {
      type: String,
      default: ""
    },

    transactionAmount: {
      type: Number,
      default: 0,
    },

    currency: {
      type: String,
      default: "BRL",
    },

    placeholder: {
      type: String,
      default: "R$0,00"
    },

    maxLength: {
      type: [Number, String],
      default: null
    }

  },

  computed:{
    locale () {
      return this.avaliableLocales.find(locale => locale.abbr === this.currency)
    }
  },

  methods: {
    formatCurrency: Utils.formatCurrency,

    money() {
      const cleanValue = +this.value.replace(/\D+/g, '');
      this.value = this.formatCurrency(cleanValue / 100, this.locale, this.currency)
      this.$emit('currency', (cleanValue / 100));
      this.$emit('update:modelValue', (cleanValue / 100));
    },

    clearValue() {
      this.value = null;
    }
  },


  watch: {
    transactionAmount: {
      handler(newVal) {
        this.value = this.formatCurrency(newVal, this.locale, this.currency)
      },
      immediate: true
    },
    currency: {
      handler() {
        if (this.value) {
          this.money()
        }
      },
      immediate: true
    },
    modelValue (value){
      if(value){
        const options = {style: 'currency', currency: 'BRL'};
        this.value = new Intl.NumberFormat('pt-br', options).format(value);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-control::placeholder {
  color: #677C92;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #677C92;
}

.form-control::-ms-input-placeholder {
  color: #677C92;
}

.readonly-input{
  background-color: #F0F4FA;
}
</style>
