<template>
  <section
    id="partner-details"
    :class="{'mt-5': isUpdate}"
  >
    <div class="card">
      <div class="card-header">
        <h4>Dados do parceiro</h4>
      </div>
      <div class="card-body row">
        <div class="col-6 mb-4 border-end">
          <label
            class="form-label required"
            for="cnpj_or_cpf"
          >
            {{ documentType }}
          </label>
          <div class="input-group">
            <input
              id="cnpj_or_cpf"
              class="form-control rounded-end"
              :class="{ 'is-invalid': hasErrors && (errors.cnpj_or_cpf || localErrors.cpf) }"
              type="text"
              placeholder="CNPJ ou CPF"
              v-model="fields.cnpj_or_cpf"
              v-maska
              data-maska="['###.###.###-##','##.###.###/####-##']"
              :maxlength="18"
              autofocus
              :disabled="loadingFlags.checkCnpj"
              :readonly="isUpdate"
              @input="handleCnpjOrCpfInput"
            >
            <div class="invalid-feedback">
              {{ errors.cnpj_or_cpf && errors.cnpj_or_cpf[0] ||
                localErrors.cpf }}
            </div>
          </div>
        </div>
        <div
          v-if="documentType === 'CPF' && !isUpdate"
          class="col-6 mb-4"
        >
          <label
            class="form-label required"
            for="cep"
          >
            CEP
          </label>
          <div class="input-group">
            <input
              id="cep"
              class="form-control rounded-end"
              :class="{ 'is-invalid': hasErrors && (errors.cep || localErrors.cep) }"
              type="text"
              placeholder="CEP"
              v-model="fields.cep"
              v-maska
              data-maska="#####-###"
              :maxlength="9"
              :disabled="loading.cep"
              @input="handleCepInput"
            >
            <div class="invalid-feedback">
              {{ errors.cep && errors.cep[0] ||
                localErrors.cep }}
            </div>
          </div>
          <div
            v-if="cepIsValid"
            class="mt-2"
          >
            <span class="tx-blue-300">
              O endereco fica na
              {{ fields.publicPlace }}, {{ fields.neighborhood }}- {{ fields.city }},
              {{ fields.state }}
            </span>
          </div>
        </div>
        <div
          v-if="documentType === 'CPF' && !isUpdate"
          class="col-6 mb-4 border-end"
        >
          <label
            class="form-label required"
            for="number"
          >
            Número
          </label>
          <div class="input-group">
            <input
              id="number"
              class="form-control rounded-end"
              :class="{ 'is-invalid': hasErrors && errors.number }"
              type="text"
              placeholder="Número"
              v-model="fields.number"
            >
            <div class="invalid-feedback">
              {{ errors.number && errors.number[0] }}
            </div>
          </div>
        </div>
        <div
          v-if="documentType === 'CPF' && !isUpdate"
          class="col-6 mb-4"
        >
          <label
            class="form-label"
            for="complement"
          >
            Complemento
          </label>
          <div class="input-group">
            <input
              id="complement"
              class="form-control rounded-end"
              type="text"
              placeholder="Complemento"
              v-model="fields.complement"
            >
          </div>
        </div>
        <div
          v-if="documentType === 'CNPJ'"
          class="col-6"
        >
          <p class="mb-3">
            Status
          </p>
          <div
            v-if="loadingFlags.checkCnpj"
            class="spinner-border spinner-border-sm tx-text-gray"
          />
          <span
            v-else
            class="px-3 py-2 rounded-1 fs-5"
            :class="cnpjStatusClass"
          >
            {{ fields.cnpj_status }}
          </span>
        </div>
        <div
          v-if="documentType === 'CNPJ'"
          class="col-6"
        >
          <p class="m-0">
            Nome Fantasia
          </p>
          <p class="m-0 tx-text-gray">
            {{ fields.commercial_name || '-' }}
          </p>
        </div>
      </div>
    </div>
  </section>

  <section id="details-person">
    <div class="card">
      <div class="card-header">
        <h4>Dados do responsável</h4>
      </div>
      <div class="card-body row">
        <div class="col-6 border-end">
          <label
            class="form-label required"
            for="name"
          >
            Nome
          </label>
          <div class="input-group">
            <input
              id="name"
              class="form-control rounded-end"
              :class="{ 'is-invalid': hasErrors && errors.name }"
              type="text"
              placeholder="Nome"
              v-model="fields.name"
              :maxlength="60"
            >
            <div class="invalid-feedback">
              {{ errors.name && errors.name[0] }}
            </div>
          </div>
        </div>
        <div class="col-6">
          <label
            class="form-label required"
            for="whatsapp"
          >
            Whatsapp
          </label>
          <div class="input-group">
            <input
              id="whatsapp"
              class="form-control rounded-end"
              :class="{ 'is-invalid': hasErrors && errors.whatsapp }"
              type="text"
              placeholder="Whatsapp"
              v-model="fields.whatsapp"
              v-maska
              data-maska="[
                  '(##) ####-####',
                  '(##) #####-####'
                ]"
            >
            <div class="invalid-feedback">
              {{ errors.whatsapp && errors.whatsapp[0] }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="account-details">
    <div class="card">
      <div class="card-header">
        <h4>Dados da conta</h4>
      </div>
      <div class="card-body row">
        <div class="col-6 border-end">
          <label
            class="form-label required"
            for="email"
          >
            Email
          </label>
          <div class="input-group">
            <input
              id="email"
              class="form-control rounded-end"
              :class="{ 'is-invalid': hasErrors && errors.email }"
              type="email"
              placeholder="Email"
              v-model="fields.email"
            >
            <div class="invalid-feedback">
              {{ errors.email && errors.email[0] }}
            </div>
          </div>
        </div>
        <div class="col-6">
          <label
            class="form-label required"
            for="password"
          >
            Senha
          </label>
          <div class="input-group">
            <input
              id="password"
              class="form-control"
              :class="{ 'is-invalid': hasErrors && errors.password }"
              :type="showPassword ? 'text' : 'password'"
              placeholder="Senha"
              v-model="fields.password"
            >
            <div
              class="input-group-text cursor-pointer rounded-end"
              @click="showPassword = !showPassword"
            >
              <i
                class="fa-regular fa-eye-slash"
                :class="showPassword? 'fa-eye-slash' : 'fa-eye'"
              />
            </div>
            <div class="invalid-feedback">
              {{ errors.password && errors.password[0] }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    v-if="isUpdate"
    id="profit-percentage"
  >
    <div class="card">
      <div class="card-header">
        <h4>Porcentagem de lucro</h4>
      </div>
      <div class="card-body row">
        <div class="col-6 border-end">
          <div class="input-group align-items-center gap-4">
            <div @click="fields.custom_benefit_percentage = false">
              <input
                id="percentage"
                class="form-check-input mt-0"
                type="radio"
                name="tipoSelecao"
                :checked="fields.custom_benefit_percentage === false"
              >
            </div>
            <div>
              <h5 class="mb-1">
                Automático
              </h5>
              <p class="m-0 tx-text-gray fs-6">
                Níveis do partner
              </p>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="input-group align-items-center gap-4">
            <div @click="fields.custom_benefit_percentage = true">
              <input
                id="percentage"
                class="form-check-input mt-0"
                type="radio"
                name="tipoSelecao"
                :checked="fields.custom_benefit_percentage === true"
              >
            </div>
            <div>
              <h5 class="mb-1">
                Manual
              </h5>
              <p class="m-0 tx-text-gray fs-6">
                Escolha a porcentagem
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div v-if="fields.custom_benefit_percentage">
          <h3>Manual</h3>
          <div class="input-group">
            <div
              :class="{ 'border-color-error': errors.benefit_percentage }"
              class="input-group-text cursor-pointer rounded-start"
            >
              %
            </div>
            <input
              class="form-control rounded-end"
              :class="{ 'is-invalid': errors.benefit_percentage }"
              type="number"
              v-model="fields.benefit_percentage"
              max="15"
              min="0"
              @input="validatePercentage"
            >
            <div class="invalid-feedback">
              {{ errors.benefit_percentage }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="button">
    <app-button
      class="w-100"
      :disabled="disableSubmit && hasErrors"
      @click="submit"
    >
      {{ isUpdate? 'Salvar' : 'Criar' }}
    </app-button>
    <app-button
      v-if="isUpdate"
      class="w-100 mt-4"
      color="text-danger"
      @click="_delete"
    >
      <span class="fa-regular fa-trash" /> Excluir
    </app-button>
  </section>
</template>

<script>
import AppButton from "@components/app-button.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import { isValidCPF } from "@/utils";

export default {
  name: "CreateUpdatePartnerForm",
  components: {AppButton},

  props: {
    partnerDetails: {
      type: Object,
      default: ()=>{}
    }
  },

  data() {
    return {
      fields: {
        cnpj_or_cpf: "",
        cnpj_status: 'Inválido',
        commercial_name: '',
        cep: "",
        public_place: null,
        neighborhood: null,
        city: null,
        state: null,
        number: null,
        complement: "",
        name: "",
        whatsapp: "",
        email: "",
        password: "",
        custom_benefit_percentage: false,
        benefit_percentage: "",
      },
      loading:{
        cep: false
      },
      localErrors: {},
      documentType: 'CNPJ ou CPF',
      cpfIsValid: false,
      cepIsValid: false,
      showPassword: false,
      hasErrors: false
    }
  },

  created() {
    if(this.isUpdate){
      this.hasErrors = true
    }
  },

  computed: {
    ...mapGetters("admin/partners", {
      errors: "getErrors",
      cnpjQueryData: "getCnpjQueryData",
      loadingFlags: "getLoadingFlags",
    }),

    isUpdate() {
      return this.$route.params.id
    },

    disableSubmit() {
      const { documentType, cpfIsValid, cepIsValid, fields } = this;
      const { number, cnpj_status, whatsapp, email, password, name, custom_benefit_percentage, benefit_percentage } = fields;

      const createValidations = [
        documentType === 'CNPJ ou CPF',
        documentType === 'CPF' && !cpfIsValid,
        documentType === 'CPF' && !cepIsValid,
        documentType === 'CPF' && !number,
        documentType === 'CNPJ' && cnpj_status !== 'Ativa',
        !name,
        !whatsapp || whatsapp.length < 14 || whatsapp.length > 15,
        !email,
        !password
      ];

      const updateValidations = [
        !name,
        !whatsapp || whatsapp.length < 14 || whatsapp.length > 15,
        !email,
        custom_benefit_percentage && !benefit_percentage
      ];

      const validations = this.isUpdate? updateValidations : createValidations

      return validations.some(validation => validation);
    },

    cnpjStatusClass() {
      switch (this.fields.cnpj_status) {
        case "Ativa":
          return "success";
        case "Inválido":
          return "default";
        default:
          return "incorrect";
      }
    }
  },

  watch: {
    partnerDetails (partner) {
      if (!partner || Object.keys(partner).length === 0) {
        return
      }

      if (partner.cnpj) {
        this.documentType = 'CNPJ'
        this.fields.cnpj_or_cpf = partner.cnpj
        this.fields.cnpj_status = partner.cnpj_status
        this.fields.commercial_name = partner.commercial_name
      }
      if (partner.cpf) {
        this.documentType = 'CPF'
        this.cpfIsValid = true
        this.cepIsValid = true
        this.fields.cnpj_or_cpf = partner.cpf
        this.fields.cep = partner.address.cep
        this.fields.public_place = partner.address.public_place
        this.fields.neighborhood = partner.address.neighborhood
        this.fields.city = partner.address.city
        this.fields.state = partner.address.state
        this.fields.number = partner.address.number
        this.fields.complement = partner.address.complement
      }
      this.fields.name = partner.name
      this.fields.whatsapp = partner.whatsapp
      this.fields.email = partner.email
      this.fields.password = partner.password
      this.fields.custom_benefit_percentage = partner.custom_benefit_percentage

      const benefit = partner.benefit_percentage * 100
      if (Number.isInteger(benefit)) {
        this.fields.benefit_percentage = benefit;
      } else {
        this.fields.benefit_percentage = Math.round((benefit + Number.EPSILON) * 100) / 100;
      }
    }
  },

  methods: {
    isValidCPF,
    ...mapActions("admin/partners", [
      "createPartner",
      "checkCnpj",
      "updatePartner",
      "deletePartner"
    ]),
    ...mapMutations("system", ["setInfoMessage"]),

    handleCnpjOrCpfInput(e) {
      this.localErrors.cpf = ''
      const document = e.target.value;
      const formattedDocument = document.replace(/\D/g, "");

      this.updateDocumentType(formattedDocument)
      this.clearCnpjFields()
      if (this.documentType ===  'CNPJ' && formattedDocument.length === 14) {
        this.validateCnpj(formattedDocument)
      }
      else if (this.documentType ===  'CPF') {
        this.validateCpf(formattedDocument)
      }
    },

    updateDocumentType(document) {
      if (document.length === 11) {
        this.documentType = "CPF";
      }
      if (document.length > 11) {
        this.documentType = "CNPJ";
      }
      if (document.length < 11) {
        this.documentType = "CNPJ ou CPF";
      }
    },

    validateCpf(cpf) {
      this.cpfIsValid = this.isValidCPF(cpf)
      this.localErrors.cpf = this.cpfIsValid? '' : 'CPF inválido'
    },

    async handleCepInput(e) {
      if (this.loading.cep) {
        return
      }

      const cep = e.target.value;
      const zipCode = cep.replace(/-/g, "");

      if (zipCode.length === 8) {
        this.loading.cep = true
        try {
          await this.validateCep(zipCode)
        } catch {
          this.clearAddressAndInvalidCpf()
        }
        this.loading.cep = false
        this.localErrors.cep = this.cepIsValid? '' : 'CEP inválido'
      }
      else if (this.cepIsValid) {
        this.clearAddressAndInvalidCpf()
      }
    },

    async validateCep(zipCode){
      const response = await fetch(`https://viacep.com.br/ws/${zipCode}/json/`)
      const data = await response.json();
      if (data.erro){
        throw new Error
      }
      this.cepIsValid = true
      this.fields.public_place = data.logradouro;
      this.fields.neighborhood = data.bairro;
      this.fields.city = data.localidade;
      this.fields.state = data.uf;
    },

    clearAddressAndInvalidCpf() {
      this.cepIsValid = false
      this.fields.public_place = null
      this.fields.neighborhood = null
      this.fields.city = null
      this.fields.state = null
      this.fields.number = null
      this.fields.complement = null
    },

    async validateCnpj(cnpj) {
      if (this.loading.cnpj) {
        return
      }
      this.loading.cnpj = true

      await this.checkCnpj(cnpj)
      this.loading.cnpj = false
      this.fields.cnpj_status = this.cnpjQueryData.cnpj_status
      this.fields.commercial_name = this.cnpjQueryData.commercial_name
    },

    clearCnpjFields(){
      this.fields.cnpj_status = 'Inválido'
      this.fields.commercial_name = ''
    },

    validatePercentage() {
      let value = this.fields.benefit_percentage;

      if (value < 0 || value > 15 || this.fields.benefit_percentage.length < 1) {
        this.errors.benefit_percentage = "A porcentagem deve ser entre 0 a 15";
        if (value > 15) {
          value = null;
        }
      } else {
        this.errors.benefit_percentage = "";
      }
      this.fields.custom_benefit_percentage = true;
      this.fields.benefit_percentage = value;
    },

    submit() {
      this.hasErrors = true
      this.isUpdate ? this.update() : this.create()
    },

    create() {
      let payload = {
        cnpj_or_cpf: this.fields.cnpj_or_cpf,
        name: this.fields.name,
        whatsapp: this.fields.whatsapp,
        email: this.fields.email,
        password: this.fields.password
      }

      if (this.documentType === 'CNPJ') {
        payload = {
          ...payload,
          cnpj_status: this.fields.cnpj_status,
          commercial_name: this.fields.commercial_name
        }
      }
      else {
        payload = {
          ...payload,
          cep: this.fields.cep,
          public_place: this.fields.public_place,
          neighborhood: this.fields.neighborhood,
          city: this.fields.city,
          state: this.fields.state,
          number: this.fields.number,
          complement: this.fields.complement
        }
      }

      this.createPartner(payload).then(() => {
        this.$router.push("/admin/partners");
      });
    },

    update() {
      let payload = {
        name: this.fields.name,
        whatsapp: this.fields.whatsapp,
        email: this.fields.email,
        password: this.fields.password,
        custom_benefit_percentage: this.fields.custom_benefit_percentage,
        benefit_percentage: this.fields.benefit_percentage
      }

      if (this.fields.password === "") {
        delete payload.password;
      }

      const payloadKeys = Object.keys(payload);
      payloadKeys.forEach(key => {
        // FIXME: this is deleting custom_benefit_percentage key for some reason, when the percentagem is custom
        if (payload[key] === this.partnerDetails[key]) {
          delete payload[key];
        }
      });

      if (Object.keys(payload).length === 0) {
        this.setInfoMessage("Nenhum dado foi alterado.");
        return
      }
      
      payload.benefit_percentage = this.fields.benefit_percentage;
      if (this.fields.custom_benefit_percentage) {
        payload.custom_benefit_percentage = this.fields.custom_benefit_percentage;
      }
      const request = {
        id: this.$route.params.id,
        data: payload
      };

      this.updatePartner(request).then(() => {
        this.$router.push("/admin/partners");
      });

    },

    _delete() {
      this.deletePartner(this.$route.params.id).then(() => {
        this.$router.push("/admin/partners");
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.success {
  background-color: #d6e9d9;
  color: $color-green-400;
}

.default {
  background-color: #e1e9f4;
  color: $color-blue-300;
}

.incorrect {
  background-color: #f2c6d1;
  color: $color-red-400;
}
</style>
