export default {
  isLoading: state => state.loading,
  getList: state => state.list,
  getErrorMessage: state => state.errorMessage,
  getErrors: state => state.errors,
  getLoadingContext: state => state.loadingContext,
  getCampaign(state) {
    return state.campagin
  },

  getDataSelect: (state) => state.dataSelect,
  getActiveDataSelect: (state) => state.activeDataSelect
}
