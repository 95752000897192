export default {

  startLoading: (state, flag) => state.loadingFlags[flag] = true,
  stopLoading: (state, flag) => state.loadingFlags[flag] = false,
  
  setErrors: (state, errors) => { state.errors = errors; },
  clearErrors: (state) => {
    state.errors = {}
    state.errorMessage = ""
  },

  setUnderReviewMailings: (state, underReviewMailings) => state.underReviewMailings = underReviewMailings,
  setFinalizedReviewMailings: (state, finalizedReviewMailings) => state.finalizedReviewMailings = finalizedReviewMailings,

  setTotalUnderReviewMailings: (state, totalUnderReviewMailings) => state.underReviewTotalMailings = totalUnderReviewMailings,
  setTotalFinalizedReviewMailings: (state, totalFinalizedReviewMailings) => state.finalizedReviewTotalMailings = totalFinalizedReviewMailings,

  setUnderReviewPagination: (state, underReviewPagination) => state.underReviewPagination = underReviewPagination,
  setFinalizedReviewPagination: (state, finalizedReviewPagination) => state.finalizedReviewPagination = finalizedReviewPagination,

  setUnderReviewPage: (state, page) => state.underReviewPagination.current_page = page,
  setFinalizedReviewPage: (state, page) => state.finalizedReviewPagination.current_page = page,

  setUnderReviewPerPage: (state, number) => state.underReviewPagination.per_page = number,
  setFinalizedReviewPerPage: (state, number) => state.finalizedReviewPagination.per_page = number,

  setUnderReviewFilters: (state, filters) => state.underReviewFilters = filters,
  setFinalizedReviewFilters: (state, filters) => state.finalizedReviewFilters = filters,
}