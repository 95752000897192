<template>
  <div class="draggable-item col card mb-0">
    <div
      class="card-body table-card d-flex justify-content-between align-items-center text-center py-2"
    >
      <div class="dropdown col-3">
        <button
          aria-expanded="false"
          class="btn d-flex justify-content-center align-items-center gap-3"
          data-bs-toggle="dropdown"
          type="button"
        >
          <i :class="displayItemsWithTranslation(item).icon" />
          <span> {{ displayItemsWithTranslation(item).label }}</span>
        </button>
      </div>
      <div class="col-8 d-flex justify-content-start text-start">
        <span>
          {{ item.label }}
        </span>
      </div>
      <div class="col-auto form-check form-switch d-flex justify-content-center align-items-center">
        <input
          v-model="fields.is_required"
          :checked="item.is_required"
          class="form-check-input"
          type="checkbox"
          @change="editField()"
        >
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      fields: {
        id: null,
        label: null,
        is_primary: null,
        order: null,
        mask: null,
        is_required: null,
        funnel_id: null,
      }
    }
  },

  mounted() {
    this.handleFields(this.item)
  },

  name: "EditFieldContactRow",

  props: {
    item: {type: Object, default: null}
  },

  computed: {
    ...mapGetters('auth', {user: "getUser",})
  },

  methods: {
    ...mapActions('crm/contacts', ['editFormInputs']),

    displayItemsWithTranslation(item) {
      const typeTranslation = {
        'currency': {label: this.$t('value'), icon: 'far fa-dollar-sign'},
        'string': {label: this.$t('text'), icon: 'far fa-align-left'},
        'date': {label: this.$t('date_capitalize'), icon: 'far fa-calendar'},
        'link': {label: 'Link', icon: 'far fa-arrow-up-right-from-square'},
        'cpf': {label: 'CPF', icon: 'far fa-file-lines'},
        'cnpj': {label: 'CNPJ', icon: 'far fa-file-line'},
        'phone': {label: this.$t('phone'), icon: 'far fa-phone'},
      }
      let data = "0"
      if (typeTranslation[item.mask]) {
        data = typeTranslation[item.mask];
      }
      return data;
    },

    handleFields(data) {
      for (const key in this.fields) {
        if (Object.hasOwnProperty.call(data, key)) {
          this.fields[key] = data[key]
        }
      }
    },

    editField() {
      this.editFormInputs(this.fields)
    },
  },
}
</script>

<style lang="scss" scoped>
.form-check-input {
  height: $spacing-2;
  width: 28px;
}
</style>
