<template>
  <div
    ref="dropdown"
    class="dropdown"
  >
    <button
      id="dropdownMenuButton1"
      aria-expanded="false"
      class="btn dropdown-toggle d-flex justify-content-center align-items-center p-0"
      data-bs-toggle="dropdown"
      type="button"
    >
      <i
        ref="optionsTooltip"
        class="fa-regular fa-ellipsis tx-text-gray"
      />
    </button>
    <div
      aria-labelledby="dropdownMenuButton1"
      class="dropdown-menu step-dropdown"
    >
      <ul class="list-group list-group-flush">
        <li
          v-if="disableMoveNextOption"
          class="list-group-item cursor-pointer d-flex align-items-center gap-3"
          style="padding: 8px 12px;"
          @click="moveNext()"
        >
          <i class="fa-regular fa-chevron-right" />
          <h5 class="fw-light m-0">
            {{ $t('move_forward') }}
          </h5>
        </li>
        <li
          v-if="!disableMovePrevOption"
          class="list-group-item cursor-pointer d-flex align-items-center gap-3"
          style="padding: 8px 12px;"
          @click="movePrev()"
        >
          <i class="fa-regular fa-chevron-left" />
          <h5 class="fw-light m-0">
            {{ $t('move_backward') }}
          </h5>
        </li>
        <li
          class="list-group-item cursor-pointer d-flex align-items-center gap-3"
          style="padding: 8px 12px;"
          @click="editStep()"
        >
          <i class="fa-regular fa-pen fs-6" />
          <h5 class="fw-light m-0">
            {{ $t('edit_step') }}
          </h5>
        </li>
        <li
          class="list-group-item cursor-pointer d-flex align-items-center gap-3"
          style="padding: 8px 12px;"
          @click=" this.$emit('removeStep')"
        >
          <i class="fa-regular fa-trash fs-6" />
          <h5 class="fw-light m-0">
            {{ $t('delete_step') }}
          </h5>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {generateTooltip} from "@/utils";

export default {
  emits: ['editStep', 'movePrev', 'moveNext'],

  props: {
    disableMoveNextOption: {default: false, type: Boolean},
    disableMovePrevOption: {default: false, type: Boolean},
  },

  data() {
    return {
      optionsTooltip: null,
    }
  },

  mounted() {
    this.optionsTooltip = this.generateTooltip(this.$t('options'), this.$refs['optionsTooltip'], 'bottom');
  },

  methods: {
    generateTooltip,

    editStep() {
      this.$emit('editStep')
    },
    moveNext() {
      this.$emit('moveNext')
    },
    movePrev() {
      this.$emit('movePrev')
    },
  }
}
</script>

<style lang="scss" scoped>
.dropdown-toggle {
  width: 32px;
  height: 32px;

  &:hover, &:focus, &:active {
    box-shadow: none;
  }

  &::after {
    display: none;
  }
}

.list-group-item {
  border-radius: 10px;

  &:hover {
    background-color: $color-gray-blue-300;
  }
}

.step-dropdown {
  z-index: 9999;
  min-width: 213px;
  padding: 8px;
  max-height: 600px;
  transform: translate3d(-177px, 30px, 0px) !important;
  border: 1px solid #F4F4F4;
  box-shadow: 0 4px 18px 0 rgba(34, 54, 77, 0.25);
}
</style>
