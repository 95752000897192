<script setup>
import {computed} from "vue"
import {useStore} from "vuex";
import {useDashboardQuery} from "@/composables/useDashboardQuery";
import DashCard from "@crm/pages/dashboard/components/dash-card.vue";
import OpportunitiesProgresChart from "@crm/pages/dashboard/components/charts/opportunities-progres-chart.vue";

const store = useStore();
const dateRange = computed(() => store.getters["crm/dashboard/getDateRange"])
const currentFunnel = computed(() => store.getters["crm/funnels/getCurrentFunnel"] ?? null);
const agent = computed(() => store.getters["crm/dashboard/getActiveAgent"] ?? null);

const {data, loading} = useDashboardQuery(
  currentFunnel,
  dateRange,
  dateRange,
  agent,
  'newOpportunities'
);

</script>

<template>
  <dash-card
    :title="$t('new_opportunities')"
    :tooltip-title="$t('tooltips.dashboard.new_opportunities.title')"
    tooltip-width="224px"
    :loading="loading"
    min-height="72"
  >
    <template #body>
      <opportunities-progres-chart
        color="#fff"
        :current-label="dateRange?.currentLabel"
        :previous-label="dateRange?.label"
        :current-data="[data?.new_opportunities]"
        :previous-data="[data?.previous_new_opportunities]"
      />
    </template>
  </dash-card>
</template>

<style scoped lang="scss">

</style>