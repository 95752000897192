<script setup>
import CustomTooltip from "@crm/pages/dashboard/components/custom-tooltip.vue";
import {defineProps, ref} from "vue";

defineProps({
  placement: {
    type: String,
    default: "top"
  },

  minWidth: {
    type: String,
    default: '320px'
  },

  label: {
    type: String,
    default: null
  },

  title: {
    type: String,
    required: true
  },

  subTitle: {
    type: String,
    required: true
  },

  isLateCount: {
    type: [Number, String],
    default: null
  },
  isLatePercent: {
    type: [Number, String],
    default: null
  },

  onTimeCount: {
    type: [Number, String],
    default: null
  },
  onTimePercent: {
    type: [Number, String],
    default: null
  },

  connection: {
    type: String,
    default: null
  },

  bySteps: {
    type: Array,
    default: () => []
  },

  onGoing: {
    type: [Number, String],
    default: null
  },

  gained: {
    type: [Number, String],
    default: null
  },

  lost: {
    type: [Number, String],
    default: null
  },

  display: {
    type: Boolean,
    default: true
  }
});

const show = ref(false);

function toggleShow(value) {
  show.value = value;
}
</script>

<template>
  <custom-tooltip
    :display="display"
    :placement="placement"
    @show="toggleShow(true)"
    @close="toggleShow(false)"
  >
    <template #content>
      <template v-if="label !== null">
        {{ label }}
      </template>
      <slot
        v-else
        name="label"
      />
      <div
        :style="`min-width: ${minWidth}; text-transform: none!important`"
        v-if="show"
        ref="popoverRef"
        class="card position-absolute fs-6 tooltip-card rounded"
      >
        <div
          style="margin-bottom: 1rem"
          class="d-flex align-items-end"
        >
          <span class="tx-text-200 fs-6 fw-medium col">
            {{ title }}
          </span>
          <span
            style="font-size: 0.75rem"
            class="tx-text-gray fw-medium text-end text-uppercase"
          >
            {{ subTitle }}
          </span>
        </div>
        <table
          v-if="gained !== null && lost !== null"
          class="tooltip-table"
        >
          <tr v-if="onGoing !== nul">
            <td class="tx-text-gray fw-medium fs-6">
              {{ $t('capitalizes.in_progress') }}
            </td>
            <td
              style="color: #3057F2"
              class="fw-medium fs-6 text-end"
            >
              {{ onGoing }}
            </td>
          </tr>
          <tr>
            <td class="tx-text-gray fw-medium fs-6">
              {{ $t('capitalizes.gain') }}
            </td>
            <td
              style="color: #006C17"
              class="fw-medium fs-6 text-end"
            >
              {{ gained }}
            </td>
          </tr>
          <tr>
            <td class="tx-text-gray fw-medium fs-6">
              {{ $t('capitalizes.loss') }}
            </td>
            <td
              style="color: #DE033A"
              class="fw-medium fs-6 text-end"
            >
              {{ lost }}
            </td>
          </tr>
        </table>
        <table
          v-else
          class="tooltip-table"
        >
          <tr>
            <td
              style="padding-right: 1rem"
              class="text-red fw-normal fs-6"
            >
              <div class="d-flex justify-content-between gap-2">
                <span> {{ $t('capitalizes.delayed') }} </span>
                <span>{{ isLateCount }}</span>
              </div>
            </td>
            <td class="text-red fw-medium fs-6 text-end">
              {{ isLatePercent }}
            </td>
          </tr>
          <tr>
            <td
              style="padding-right: 1rem"
              class="tx-text-gray fw-normal fs-6"
            >
              <div class="d-flex justify-content-between gap-2">
                <span> {{ $t('capitalizes.on_time') }} </span>
                <span>{{ onTimeCount }}</span>
              </div>
            </td>
            <td class="tx-text-gray fw-medium fs-6 text-end">
              {{ onTimePercent }}
            </td>
          </tr>
        </table>
        <!--BySteps-->
        <hr v-if="bySteps.length">
        <div
          v-if="bySteps.length"
          style="margin-bottom: 1rem"
          class="d-flex align-items-end"
        >
          <span class="tx-text-200 fs-6 fw-medium col">
            {{ $t('by_steps') }}
          </span>
        </div>
        <table class="tooltip-table">
          <tr
            v-for="(perStep, pSIndex) in bySteps"
            :key="`byStep-${pSIndex}`"
          >
            <td
              style="padding-right: 1rem"
              class="tx-text-gray fw-normal fs-6"
            >
              <div class="d-flex justify-content-between gap-2">
                <span> {{ perStep.step_name }} </span>
              </div>
            </td>
            <td class="tx-text-gray fw-medium fs-6 text-end">
              {{ perStep.count }}
            </td>
          </tr>
        </table>
      </div>
    </template>
  </custom-tooltip>
</template>

<style scoped lang="scss">
.tooltip-card {
  box-shadow: 0 4px 18px 0 rgba(34, 54, 77, 0.12);
  z-index: 10;
  padding: 1.5rem;
  margin-top: 0.5rem;
  line-height: 1.2;
}


.tooltip-table {
  border-collapse: separate;
}

.tooltip-body {
  margin-top: 0.5rem;
  padding: 0.5rem;
  gap: 0.5rem;
  color: $color-blue-500;
  background-color: $color-gray-blue-200;
}

.text-red {
  color: #DE033A;
}
</style>