<template>
  <div
    :class="{'d-flex align-items-center w-100': !ura}"
  >
    <div
      :class="[ura ? 'd-flex justify-content-between col' : 'col-auto me-3']"
    >
      <span v-if="ura">{{ title }}</span>
      <div
        v-show="!showInput"
        class="border rounded text-center p-2"
        :class="{'opacity-75': disabled}"
        style="width: 90px;border-color: #D2DDEC !important;"
        @click="showInputField"
      >
        {{ inputValue }}
      </div>
      <input
        :disabled="disabled"
        v-show="showInput"
        ref="inputField"
        v-model="value"
        :class="{'p-2': ura}"
        :max="max"
        :min="min"
        class="form-control text-center"
        style="width: 90px;"
        type="number"
        @blur="showInput = false"
        v-bind="$attrs"
      >
    </div>
    <div class="col pr-0">
      <div
        v-if="mark"
        class="d-flex justify-content-between pb-1"
      >
        <span>{{ min }}</span>
        <span>{{ max }}</span>
      </div>
      <vue-slider
        :disabled="disabled"
        v-model="value"
        :max="max"
        :min="min"
        :process-style="{ backgroundColor: color }"
        :rail-style="railStyle"
        :use-keyboard="false"
        tooltip="none"
        v-bind="$attrs"
      >
        <template
          #dot
        >
          <div
            :class="['custom-dot',{ }]"
            :style="{backgroundColor: color}"
          />
        </template>
      </vue-slider>
    </div>
  </div>
  <div
    v-if="ura"
    class="d-flex position-relative align-items-center"
    style="margin-top: 16px;"
  >
    <span
      class="position-absolute fs-6 tx-text-200 start-0"
    >5 {{ $t('seconds') }}</span>
    <span
      class="position-absolute border pipe"
      style="left: 19.8%;"
    />
    <span
      class="position-absolute border pipe"
      style="left: 39.8%;"
    />
    <span
      class="position-absolute border pipe"
      style="left: 59.8%;"
    />
    <span
      class="position-absolute border pipe"
      style="left: 79.8%;"
    />
    <span
      class="position-absolute end-0 fs-6 tx-text-200"
    >10 {{ $t('seconds') }}</span>
  </div>
</template>

<script>

export default {
  emits: ["update:modelValue"],
  data() {
    return {
      value: 0,
      showInput: false,
    }
  },

  beforeMount() {
    this.value = this.modelValue;
  },

  watch: {
    value(val) {
      let adjustedValue = Math.floor(val);
      adjustedValue = Math.max(this.min, adjustedValue);
      adjustedValue = Math.min(this.max, adjustedValue);

      if (this.value !== adjustedValue) {
        this.value = adjustedValue;
      }

      this.$emit("update:modelValue", this.value);
    },
    showInput(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.$refs.inputField.focus();
        });
      }
    },
    modelValue(val) {
      this.value = val;
    }
  },

  props: {
    modelValue: {
      type: [Number, String],
      default: 0,
    },

    color: {
      type: String,
      default: "#3057F9",
    },

    min: {
      type: [Number, String],
      default: 0,
    },
    max: {
      type: [Number, String],
      default: 10,
    },

    mark: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: ""
    },

    ura: {
      type: Boolean,
      default: false
    },

    railStyle: {
      type: Object,
      default: () => ({
        'height': '6px',
        'backgroundColor': "#e3ebf6"
      })
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    limitValue() {
      if (this.value > this.max) {
        return this.value = this.max
      }
      if (this.value < this.min) {
        return this.value = this.min
      }
    },
    showInputField() {
      if (!this.disabled) {
        this.showInput = true;
      }
    }
  },

  computed: {
    inputValue() {
      const value = this.value || this.min;
      return this.ura ? `${value}s` : value;
    }
  }
}


</script>

<style lang="scss" scoped>
.input-text-range {
  height: 40.5px;
  line-height: 40.5px;

  .col {
    input {
      height: 8px;
    }
  }
}

.input-text-range.is-invalid input {
  border: 1px solid red;
}

.custom-dot {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  cursor: pointer;
}

.pipe {
  height: 12px;
  border-color: #D2DDEC !important;
}
</style>
