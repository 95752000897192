<template>
  <div class="card rounded opportunity-card">
    <div class="card-body">
      <div
        class="d-flex gap-4"
        style="margin-bottom: 1rem"
      >
        <card-loading
          v-if="isLoading"
          :height="49"
          margin-bottom="0"
          class="w-50"
        />
        <div
          v-else
          class="col"
        >
          <button
            v-if="!isUpdatingName"
            @click="toggleUpdateName(true)"
            class="contact-name fs-2 fw-medium w-100 text-start bg-white d-flex align-items-center gap-3 tx-text-200"
            @mouseover="isNameHover = true"
            @mouseleave="isNameHover = false"
          >
            {{ name || $t("opportunity_name") }}
            <i
              v-if="isNameHover"
              class="fa-regular fa-pen tx-gray-300 fs-5"
            />
          </button>

          <div
            class="input-group w-100"
            v-else
          >
            <input
              @click="toggleUpdateName(true)"
              @keydown="handleKeyDown"
              v-model="name"
              autofocus
              :aria-label="$t('opportunity_name')"
              class="form-control border-end-0 tx-text-200 opportunity-name-input"
              :placeholder="$t('opportunity_name')"
              type="text"
              ref="nameInput"
            >
            <span class="input-group-text gap-3">
              <i
                class="fas fa-close cursor-pointer"
                @click="this.toggleUpdateName(false);"
                type="button"
              />
              <i
                v-if="opportunity?.name"
                class="fas fa-check cursor-pointer"
                @click="changeName()"
                type="button"
              />
            </span>
          </div>
          <div
            style="padding: 0 0.5rem"
            class="invalid-feedback d-block"
          >
            {{ errors && errors[0] }}
          </div>
        </div>
      </div>
      <card-loading
        v-if="isLoading"
        :height="28"
        margin-bottom="0"
      />
      <opportunity-stepper
        v-else
        @step-selected="selectStep"
        :enable-choice="enableChoice"
      />

      <div class="d-flex align-items-end mt-4">
        <div
          v-if="enableProducts"
          class="col-6"
        >
          <card-loading
            v-if="isLoading"
            :height="29"
            margin-bottom="0"
          />
          <opportunity-product-indicator
            v-else
            :opportunity-status="opportunity.status"
          />
        </div>


        <div

          :class="enableProducts? 'col-6' : 'col-12'"
        >
          <card-loading
            class="w-50 ms-auto"
            v-if="isLoading"
            :height="29"
            margin-bottom="0"
          />
          <span
            v-else
            class="tx-text-200 text-end d-block"
          >
            {{ currentFunnel.name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OpportunityStepper from "@crm/pages/opportunity/components/opportunity-stepper.vue";
import {mapGetters, mapMutations} from "vuex";
import OpportunityProductIndicator from "@crm/pages/opportunity/components/opportunity-product-indicator.vue";
import CardLoading from "@components/card-loading.vue";
import {nextTick} from "vue";

export default {
  name: 'OpportunityCard',
  components: {
    CardLoading,
    OpportunityProductIndicator,
    OpportunityStepper
  },
  emits: [
    'selectStep',
    'name'
  ],

  props: {
    errors: {
      type: Array,
      default: () => []
    },

    enableChoice: {
      type: Boolean,
      default: false
    },

    enableProducts: {
      type: Boolean,
      default: false
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      name: null,
      selectedStep: null,
      isUpdatingName: true,
      isNameHover: false
    }
  },

  computed: {
    ...mapGetters("crm/opportunities", {
      opportunity: "getOpportunity",
    }),
    ...mapGetters("crm/funnels", {
      currentFunnel: "getCurrentFunnel",
    }),
  },

  methods: {
    ...mapMutations("crm/opportunities", ["setOpportunity"]),
    selectStep(id) {
      this.$emit("selectStep", id)
    },

    changeName() {
      if(this.name!=="") {
        this.$emit('name', this.name);
        let opportunity = this.opportunity;
        opportunity.name = this.name;
        this.setOpportunity(opportunity)
      }
      this.toggleUpdateName(false);
    },

    toggleUpdateName(toggle) {
      this.name = this.opportunity?.name || null;
      this.isUpdatingName = toggle;
      if(toggle){
        nextTick(() => {
          this.$refs.nameInput.focus();
        });
      }
    },
    handleKeyDown(event) {
      if (event.key === 'Enter' && this.opportunity?.name) {
        this.changeName();
      } else if (event.key === 'Escape') {
        this.toggleUpdateName(false);
      }
    },
  },
  mounted() {
    nextTick(() => {
      if (this.$refs.nameInput) {
        this.$refs.nameInput.focus()
      }
    });
  },

  watch: {
    name(){
      if(!this.opportunity?.name){
        this.$emit('name', this.name);
      }
    },
    opportunity(value) {
      if(value) {
        if (!this.name) {
          this.isUpdatingName = false
          this.name = this.opportunity?.name ? this.opportunity.name : null
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.opportunity-card {
  margin-top: 1rem;
  box-shadow: 0 12px 24px 0 rgba(18, 38, 63, 0.03);
  border-color: #E1E9F4
}

.contact-name {
  appearance: none;
  border: none;
  outline: none;
  color: #1B1B2B;
  padding: 0.5rem 0;

  &:hover{
    color:#1B1B2B !important;
  }
}

.cards-container {
  max-height: 80vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none !important;
  }
}

.loading-card {
  margin-bottom: 0;
}
.form-control:focus {
  box-shadow: none;
  border-color: #E1E9F4;
}
.input-group-text{
  border-color: #E1E9F4;
}

.opportunity-name-input {

  color: #1B1B2B;
  padding: 0.5rem 0;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;


  &[type='text'] {
    padding: 0.5rem;
  }

  &::placeholder {
    color: #1B1B2B ;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    opacity: 1;
  }
}

.cards-container {
  max-height: 80vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none !important;
  }
}
</style>