<template>
  <div class="warmup-content">
    <div style="height: 40vh;">
      <div
        class="col-12 mt-2 mx-auto px-6"
      >
        <div class="mt-4 mb-6">
          <div class="row align-items-center d-flex justify-content-between">
            <div class="col p-0">
              <h1 class="header-title align-middle d-inline fw-medium">
                Omnichannel 3C+
              </h1>
            </div>
            <div class="col-auto p-0">
              <button
                @click="submit()"
                data-test="whatsappWarmup-button"
                class="btn btn-primary px-6 fs-5 fw-medium"
              >
                {{ $t('warmup_want_access') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-10 mx-auto px-1 d-flex justify-content-between"
      >
        <div
          class="col-5"
          style="margin-top: 70px;"
        >
          <h1
            class="fw-bold mb-4"
            style="line-height: 48px;"
          >
            {{ $t('warmup_connect') }}
          </h1>
          <h3
            class="fw-normal pb-4"
            style="line-height: 32px;"
          >
            {{ $t('warmup_simplify') }} <span class="fw-bolder">Omnichannel 3C+</span>.
          </h3>
          <button
            @click="submit()"
            data-test="whatsappWarmup-button"
            class="btn btn-outline-primary px-6 fs-5 fw-medium"
          >
            {{ $t('warmup_want_access') }}
          </button>
        </div>
        <div class="col-7 p-0 text-end">
          <img src="@/assets/img/warmup.svg">
        </div>
      </div>
    </div>
    <div class="position-relative">
      <img
        src="@/assets/img/pinkOctagonalStar.svg"
        class="position-absolute pinkStar"
      >
    </div>
    <div
      class="col-12 mx-0 overflow-hidden p-0 position-relative"
      style="height: 5vh; margin-top: 200px;"
    >
      <img
        src="@/assets/img/whatsapp-background-warmup.svg"
        class="background-warmup p-0"
      >
    </div>
    <div
      class="w-100 row text-center mx-0 container-down overflow-hidden p-0"
    >
      <p
        class="fw-medium fs-1 mb-5"
        style="color: var(--color-text-200);"
      >
        {{ $t('warmup_know') }}
      </p>
      <div
        class="d-flex justify-content-center overflow-hidden position-relative"
      >
        <iframe
          width="710"
          height="400"
          src="https://www.youtube.com/embed/tqhWbjYbDPY"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        />
        <img
          src="@/assets/img/plus-blue.svg"
          class="plus-blue-one"
        >
      </div>
      <div
        class="col-12 overflow-hidden"
      >
        <button
          @click="submit()"
          data-test="whatsappWarmup-button"
          class="btn btn-primary px-6 fs-5 fw-medium mt-5 mb-6"
        >
          {{ $t('warmup_want_access') }}
        </button>
      </div>
      <img
        src="@/assets/img/plus-blue.svg"
        class="plus-blue-two"
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  data() {
    return {
      wantAccess: null,
    }
  },

  mounted() {
    this.trackEvents("[Manager] Click on WhatsApp warmup")
    if(this.user && this.user.user_updated_data.add_whatsapp_access_list)
      this.wantAccess = this.user.user_updated_data?.add_whatsapp_access_list
  },

  watch: {
    'user.user_updated_data': {
      handle() {
        if(this.user && this.user.user_updated_data?.add_whatsapp_access_list)
        this.wantAccess = this.user.user_updated_data?.add_whatsapp_access_list
      }
    }
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser",
    }),
  },

  methods: {
    ...mapActions('manager/whatsapp', ['addManagerOnWishListWhatsapp']),
    ...mapActions('auth', ["me"]),
    ...mapMutations('auth', ["trackEvents"]),
    ...mapMutations("system", ["setInfoMessage", "setSuccessMessage", "setCustomizedTitleMessage"]),

    submit() {
      this.addManagerOnWishListWhatsapp({id:this.user.id }).then(() => {
        this.me()
        this.wantAccess = true;
      }).then(() =>{
        if (this.user.user_updated_data.add_whatsapp_access_list) {
          this.setCustomizedTitleMessage(this.$t('warmup_request_done'))
          this.setInfoMessage(this.$t('warmup_already_requested'))
          this.trackEvents("[Manager] Request Omni Access")
        } else {
          this.setInfoMessage(this.$t('warmup_early_access'))
          this.trackEvents("[Manager] Request Omni Access")
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>

.btn {
  border-radius: 10px !important;
}
.pinkStar {
  left: -113px;
  top: 100px;
  height: 250px;
  z-index: -1
}
iframe {
  border-radius: 10px;
  border: 1px solid;
  border-color: #B1C2D9;
}
.warmup-content {
  height: 100vh;
}

h3 {
  font-family: 'Random Grotesque Specimen', sans-serif;
}

.container-down {
  background-color: #E1E9F4;
  padding-top: 10px;
  position: relative;
  min-height: 50vh;
}

.background-warmup {
  position: absolute;
  z-index: -1;
  width: 108%;
  top: 0px;
  right: -18px;
}

.plus-blue-one {
  width: 100px;
  position: absolute;
  top: 0px;
  right: -35px;
  z-index: 2;
}

.plus-blue-two {
  width: 100px;
  position: absolute;
  bottom: 140px;
  left: -30px;
  z-index: 2;
}

</style>
