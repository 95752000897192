import axios from "axios";

export default {
  loadCampaigns({ commit, getters }, campaignStatus) {
    let params = {};
    if(campaignStatus === 'active') {
      commit("startLoadingFlag", "loadActiveCampaigns");
      params = getters.getActiveCampaignsParams;
    } else {
      params = getters.getPausedCampaignsParams;
      commit("startLoadingFlag", "loadPausedCampaigns");
    }

    return new Promise((resolve, reject) => {
      axios.get("/campaigns", { params })
        .then((response) => {
          if(campaignStatus === 'active') {
            commit("setActiveCampaigns", response.data.data);
            commit("setActiveCampaignsPagination", response.data.meta.pagination);
          } else {
            commit("setPausedCampaigns", response.data.data);
            commit("setPausedCampaignsPagination", response.data.meta.pagination);
          }
          resolve();
        }).catch(() => {

          reject();
        }).finally(() => {
          if(campaignStatus === 'active')
            commit("stopLoadingFlag", "loadActiveCampaigns");
          else
            commit("stopLoadingFlag", "loadPausedCampaigns");
        });
    });
  },

  setActiveCampaignsCurrentPage({ commit }, page) {
    commit("setActiveCampaignsCurrentPage", page);
  },

  setPausedCampaignsCurrentPage({ commit }, page) {
    commit("setPausedCampaignsCurrentPage", page);
  },

  setFilters({ commit }, filters) {
    commit("setFilters", filters);
  },

  insertOnlineAgentInActiveCampaign({ commit, getters }, event) {
    let { campaignId } = event;
    if(getters.getActiveCampaigns[campaignId]) {
      commit("insertOnlineAgentInActiveCampaign", event);
    }
  },

  removeAgentFromActiveCampaign({ commit, getters }, event) {
    let { campaignId } = event;
    if(getters.getActiveCampaigns[campaignId]) {
      commit("removeAgentFromActiveCampaign", event);
    }
  },

  clearCampaignsData({ commit }) {
    commit('clearCampaignsData');
  },

  getActivedList({ commit }) {
    commit('setLoadingActive', true)
    commit('setListActive', []);
    return new Promise((resolve, reject) => {
      axios.get('/campaigns', { params: { paused: false, per_page: -1 }})
        .then((response) => {
          commit('setPaginationActive', response.data.meta.pagination);
          commit('setListActive', response.data.data);
          resolve();
        }).catch((response) => {
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('setLoadingActive', false)
        });
    });

  },

  /**
   *
   * @param {*} param0
   * @param {*} getters
   */
  getPausedList({ commit, getters }) {
    commit('setLoadingPaused', true)
    let params = getters.getPausedParams;
    params.paused = true;
    commit('setListPaused', []);
    return new Promise((resolve, reject) => {
      axios.get('/campaigns', { params })
        .then((response) => {
          commit('setPaginationPaused', response.data.meta.pagination);
          commit('setListPaused', response.data.data);
          resolve();
        }).catch((response) => {
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('setLoadingPaused', false)
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   */
  findTotalAgentsPerCampaign({ commit }, payload) {
    commit('startLoading')
    commit('setLoadingContext', 'find-agents');

    return new Promise((resolve, reject) => {
      axios.get('/campaigns/' + payload.id + '/agents/status')
        .then((response) => {
          commit('addTotalAgentsPerCampaign', {
            campaign_id: payload.id,
            agents: response.data.data
          });
          resolve();
        }).catch(() => {
          reject();
        }).finally(() => {
          commit('stopLoading')
          commit('setLoadingContext', '');
        });
    });
  },




  /**
   *
   * @param {*} param0
   * @param {*} payload
   */
  findAgentsByCampaign({ commit }, payload) {

    commit('startLoading')
    commit('setLoadingContext', 'find-total-agents');
    commit('setAgetsPerCampagin', []);

    axios.get('/campaigns/' + payload.id + '/agents')
      .then((response) => {
        commit('setAgetsPerCampagin',
          response.data.data
        );
      }).finally(() => {
        commit('stopLoading')
        commit('setLoadingContext', '');
      });
  },




  // setTotalAgentsPerCampagin: (state) => {
  //   state.totalAgentsPerCampagin = {}
  // },



  /**
  *
  * @param {*} param0
  * @param {*} payload
  */
  create({ commit }, payload) {
    commit('startLoading')
    commit('clearErrors')
    commit('setLoadingContext', 'create');
    commit('setCreateData', [])
    return new Promise((resolve, reject) => {
      axios.post('/campaigns', payload.fields)
        .then((response) => {
          commit('setCreateData', response.data.data)
          commit('system/setSuccessMessage',"success_message.register_campaign", { root: true });
          commit('auth/trackEvents', '[Manager] Create campaign', { root: true });
          resolve();
        })
        .catch((error) => {
          commit('system/setErrorMessage', error.response.data, { root: true });
          error.response.status == 422 && error.response.data.errors && commit('setErrors', error.response.data.errors);
          reject();
        }).finally(() => {
          commit('stopLoading')
          commit('setLoadingContext', '');
        });
    });
  },

  /**
  *
  * @param {*} param0
  * @param {*} payload
  */
  update({ commit }, payload) {
    commit('startLoading')
    commit('clearErrors')
    commit('setLoadingContext', 'update');
    return new Promise((resolve, reject) => {
      axios.put('/campaigns/' + payload.id, payload.fields)
        .then(() => {
          commit('system/setSuccessMessage',"success_message.update_campaign", { root: true });
          resolve();
        }).catch((error) => {
          commit('system/setErrorMessage', error.response.data, { root: true });
            error.response.status == 422 && error.response.data.errors && commit('setErrors', error.response.data.errors);
            reject();
          }).finally(() => {
          commit('stopLoading')
          commit('setLoadingContext', '');
        });
    });
  },

  fetchCampaignById({ commit, getters }, campaignId) {
    commit("startLoadingFlag", "fetchCampaignById");

    return new Promise((resolve, reject) => {
      axios.get('/campaigns/' + campaignId, { params: getters.getCampaignParams }).
        then((response) => {
          commit('setCampaign', response.data.data);
          resolve();
        })
        .catch(() => reject())
        .finally(() => commit("stopLoadingFlag", "fetchCampaignById"));
    });
  },

  logoutAgent({ commit }, payload) {

    commit('startLoading')
    commit('setLoadingContext', 'logout-agent');
    return new Promise((resolve, reject) => {
      axios.post('/agents/' + payload.agent_id + '/logout')
        .then(() => {
          resolve();
        }).catch((response) => {
          reject();
          commit('system/setErrorMessage', response.response.data, { root: true })
          commit('stopLoading')
        })
    });
  },

  /**
   *
   * @param {*} param0
   * @returns
   */
  loadDataSelect({ commit }) {
    commit('startLoading')
    commit('setDataSelect', []);
    commit('setLoadingContext', 'load-data-select');
    return new Promise((resolve, reject) => {
      axios.get('/filters')
        .then((response) => {
          commit('setDataSelect', response.data.data.campaigns);
          resolve();
        }).catch(() => {
          reject();
        }).finally(() => {
          commit('stopLoading')
          commit('setLoadingContext', '');
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */

  resume({ commit }, payload) {
    commit('startLoading')
    commit('setLoadingContext', 'resume');
    return new Promise((resolve, reject) => {
      axios.put('/campaigns/' + payload.id + '/resume').
        then(() => {
          resolve();
        }).catch((response) => {
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('stopLoading')
          commit('setLoadingContext', '');
        });
    });
  },

  /**
   *
   */

  pause({ commit }, payload) {
    commit('startLoading')
    commit('setLoadingContext', 'pause');
    return new Promise((resolve, reject) => {
      axios.put('/campaigns/' + payload.id + '/pause').
        then(() => {
          resolve();
        }).catch((response) => {
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('stopLoading')
          commit('setLoadingContext', '');
        });
    });
  },

  /**
   *
   */
  remove({ commit }, payload) {
    commit('startLoading')
    commit('setLoadingContext', 'remove');
    return new Promise((resolve, reject) => {
      axios.delete('/campaigns/' + payload.id).
        then(() => {
          resolve({ message: "Campanha excluída com sucesso!" });
        }).catch((response) => {
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('stopLoading')
          commit('setLoadingContext', '');
        });
    });
  },

  toggleProductiveMode({ commit }, payload) {
    commit('startLoading');
    commit('setLoadingContext', 'productive-mode');

    return new Promise((resolve, reject) => {
      axios.patch('/campaigns/' + payload.id, {
        is_predictive: payload.is_predictive
      }).
        then(() => {
          resolve();
        }).catch((response) => {
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('stopLoading');
          commit('setLoadingContext', 'productive-mode');
        });
    });
  },

  /**
   *
   * @param {*} payload
   * @returns
   */
  addAgentsInCampaign({ commit }, payload) {
    commit('startLoading')
    commit('setLoadingContext', 'add-agent');
    return new Promise((resolve, reject) => {
      axios.post('/campaigns/' + payload.campaign_id + '/agents', { agents: payload.agents })
        .then((response) => {
          commit('system/setSuccessMessage', response.data.detail, { root: true })
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('stopLoading')
          commit('setLoadingContext', '');
        });
    });
  },


  /**
   *
   */
  deleteAgentsFromCampaign({ commit }, payload) {
    commit('startLoading')
    commit('setLoadingContext', 'remove-agent');
    return new Promise((resolve, reject) => {
      axios.delete('/campaigns/' + payload.campaign_id + '/agents/' + payload.agent_id)
        .then(() => {
          resolve();
        }).catch((response) => {
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('stopLoading')
          commit('setLoadingContext', '');
        });
    });
  },

  /**
   *
   */
    loadCompanyUraLimit({ commit }) {
    commit('startLoading');
    commit('setCompanyUraLimit', []);
    return new Promise((resolve, reject) => {
      axios.get('/company/ura-limit')
        .then((response) => {
          commit('setCompanyUraLimit', response.data.ura_limit);
          resolve();
        }).catch((response) => {
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('stopLoading');
        });
    });
  },



  addTeamInCampaign({ commit }, payload) {
    commit('startLoading')
    commit('setLoadingContext', 'add-team');

    return new Promise((resolve, reject) => {
      axios.post(`/campaigns/${payload.campaign_id}/teams`, { teams: payload.teams })
        .then(() => resolve())
        .catch((error) => {
          commit('system/setErrorMessage', error.response.data, { root: true });
          reject();
        }).finally(() => {
          commit('stopLoading')
          commit('setLoadingContext', '');
        });
    });
  },

  removeTeamFromCampaign({ commit }, payload) {
    commit('startLoading')
    commit('setLoadingContext', 'remove-team');

    return new Promise((resolve, reject) => {
      axios.delete(`/campaigns/${payload.campaign_id}/teams/${payload.team_id}`)
        .then(() => resolve())
        .catch((error) => {
          commit('system/setErrorMessage', error.response.data, { root: true });
          reject();
        }).finally(() => {
          commit('stopLoading')
          commit('setLoadingContext', '');
        });
    });
  },
}
