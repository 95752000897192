<template>
  <a
    href="#"
    class="tx-text-gray d-flex align-items-center gap-2"
    @click="click"
  >
    {{ this.title }}

    <i
      class="fa-regular"
      :class="{
        'fa-sort': dir == '',
        'fa-sort-up': dir == 'asc',
        'fa-sort-down': dir == 'desc'
      }"
    />

    <tooltip-component
      v-if="tooltip"
      color="text-muted"
      :title="tooltip"
    />
  </a>
</template>

<script>
import TooltipComponent from "@components/tooltip-component.vue";

export default {
  components: {TooltipComponent},
  emits: ["sort"],
  props: {
    dir: {
      type: String,
      default: "",
    },

    title: {
      type: String,
      default: ``,
    },

    tooltip: {
      type: String,
      default: "",
    },

    column: {
      type: String,
      default: "",
    },
  },

  methods: {
    click() {
      this.$emit("sort", {
        column: this.column,
        dir: this.dir == "" || this.dir == "desc" ? "asc" : "desc",
      });
    }
  },
};
</script>
