<script setup>
import {computed, defineProps} from "vue"
import {useStore} from "vuex";
import TableTooltip from "@crm/pages/dashboard/components/table-tooltip.vue";

defineProps({
  actualValue: {
    type: String,
    required: true,
  },

  previousValue: {
    type: String,
    required: true
  },

  differenceValue: {
    type: String,
    required: true,
  },

  differenceStatus: {
    type: Boolean,
    default: true
  },

  tooltip: {
    type: Boolean,
    default: false
  },

  title: {
    type: String,
    default: ""
  },

  subTitle: {
    type: String,
    default: null
  },

  currentSubTitle: {
    type: String,
    default: null
  },

  isLateCount: {
    type: [Number, String],
    default: null
  },
  isLatePercent: {
    type: [Number, String],
    default: null
  },

  onTimeCount: {
    type: [Number, String],
    default: null
  },
  onTimePercent: {
    type: [Number, String],
    default: null
  },

  bySteps: {
    type: Array,
    default: () => []
  },

  previousIsLateCount: {
    type: [Number, String],
    default: null
  },
  previousIsLatePercent: {
    type: [Number, String],
    default: null
  },

  previousOnTimeCount: {
    type: [Number, String],
    default: null
  },
  previousOnTimePercent: {
    type: [Number, String],
    default: null
  },

  valueColor: {
    type: String,
    default: null
  },

  valueSize: {
    type: String,
    default: '2.5rem'
  },
})

const store = useStore();


const previousLabel = computed(() => store.getters['crm/dashboard/getDateRange']);
</script>

<script>
export default {
  name: 'RatingFragment',

  methods: {
    getLabel(label) {
      if (label === 'yesterday' || label === 'previous') {
        return this.$t(label)
      }

      return label
    }
  }
}
</script>

<template>
  <div style="line-height: 1.3">
    <div
      style="gap: 1rem"
      class="d-flex align-items-center"
    >
      <span
        :style="`font-size: ${valueSize}; ${valueColor? `transition: all 0.35s ease; color:${valueColor};` : ''}`"
        class="fw-bold"
        :class="{'tx-text-200': !valueColor}"
      >
        <table-tooltip
          :by-steps="bySteps"
          :is-late-count="isLateCount"
          :is-late-percent="isLatePercent"
          :on-time-count="onTimeCount"
          :on-time-percent="onTimePercent"
          :title="title"
          :sub-title="subTitle"
          min-width="241px"
          :label="actualValue"
          placement="top"
          v-if="tooltip"
        />
        <span v-else> {{ actualValue }}</span>
      </span>
      <span
        style="font-size: 0.625rem; line-height: 1.3;gap: 0.25rem"
        class="fw-medium d-lg-inline-flex align-items-center"
        :class="{
          'tx-green-300': differenceStatus,
          'tx-red-300': !differenceStatus,
        }"
      >
        <i
          class="fa-solid"
          :class="{
            'fa-caret-up': differenceStatus,
            'fa-caret-down': !differenceStatus
          }"
        />
        <span>
          {{ differenceValue }}
        </span>
      </span>
    </div>

    <time
      class="text-uppercase tx-text-gray fs-6 fw-medium"
      datetime="2024-07-05"
    >
      <table-tooltip
        :by-steps="bySteps"
        :is-late-count="previousIsLateCount"
        :is-late-percent="previousIsLatePercent"
        :on-time-count="previousOnTimeCount"
        :on-time-percent="previousOnTimePercent"
        :title="title"
        :sub-title="currentSubTitle"
        min-width="241px"
        :label="`${getLabel(previousLabel.label)}: ${previousValue}`"
        placement="top"
        v-if="tooltip"
      />
      <span v-else>{{ getLabel(previousLabel.label) }}: {{ previousValue }}</span>
    </time>
  </div>
</template>

<style scoped lang="scss">

</style>