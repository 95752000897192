<template>
  <li class="nav-item">
    <button
      style="padding: 0 !important; border: none !important;"
      class="nav-link"
      :class="{active: isActive}"
      data-bs-toggle="tab"
      :data-bs-target="`#${dataBsTarget}`"
      :disabled="blocked"
      @click="handleTab"
    >
      <span class="text-muted fw-medium fs-6"> {{ activeState }} </span>
      <br>
      <span
        v-if="loading"
        class="spinner-border spinner-border-sm"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </span>
      <span
        v-else
        class="fs-4"
      >
        {{ categoriesCount }}
      </span>
    </button>
  </li>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CategoriesTableTab",
  emits: ['clearSearch'],

  props: {
    categoriesCount: {
      type: Number,
      required: true
    },
    activeState: {
      type: String,
      required: true,
    },
    dataBsTarget: {
      type: String,
      required: true,
    },
    blocked: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters("manager/products_and_services", {
      activeList: "getActiveList",
    }),

    isActive() {
      return this.activeList === this.dataBsTarget
    }
  },

  methods: {
    ...mapActions("manager/products_and_services", [
      "updateActiveList"
    ]),

    handleTab() {
      this.$emit('clearSearch', "")
      this.updateActiveList(this.dataBsTarget)
    }
  }
}
</script>