export default {
  loading: {
    searchSchedules: false,
    downloadCSV: false,
    searchSchedulesByCampaignId: false,
  },
  downloadCSVFilters: null,
  errorMessage: "",
  errors: {},
  list: [],
  pagination: {},
  campaignSchedules: [],
  paginationCampaignSchedules: {},
  loadingSchedules: false
};
