<template>
  <single-select
    id="selectCriterion"
    ref="selectCriterion"
    :list-array="feedbacks"
    label="name"
    :error="errors && errors[0]"
    :current-option="value"
    @selected-option="$emit('update:modelValue', $event.id)"
    :disabled="loadingFlags.findFilters"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SingleSelect from "@components/single-select.vue";

export default {
  components: { SingleSelect },
  emits: ["update:modelValue"],
  data() {
    return {
      feedbacks: [],
      value: "",
    }
  },
  mounted() {
    this.setFiltersIncludes(["criterion_lists"]);
    this.findFilters();
  },

  computed: {
    ...mapGetters("manager/filters", {
      filters: "getFilters",
      loadingFlags: "getLoadingFlags",
    }),
  },

  props: {
    modelValue: {
      default: "",
      type: [Number, String],
    },

    errors: {
      type: Array,
      default: null,
    },
  },

  methods: {
    ...mapActions("manager/filters", [
      "findFilters",
      "setFiltersIncludes",
    ]),
  },

  watch: {
    filters: {
      handler(val) {
        if (val.criterion_lists) {
          this.feedbacks = val.criterion_lists;
          this.value = this.feedbacks.find((group) => group.id === this.modelValue)?.name;
        }
      },
      deep: true,
    },
  },
};
</script>
