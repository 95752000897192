export default {
  getLoadingFlags: state => state.loadingFlags,
  getInstances: state => state.instances,
  getConnectedInstances: state =>  state.instances.filter(instance => instance.status === "connected"),

  getAllowedAgents: state => state.allowedAgents,
  getAllowedInstances: state => state.allowedInstances,
  getQualifications: state => state.qualifications,
  getGroupChannel: state => state.groupChannel,
  getGroupChannels: state => state.groupChannels,
  getAllowedParams: state => state.allowedParams
}
