export default {
    startLoading: (state, flag) => state.loading[flag] = true,
    stopLoading: (state, flag) => state.loading[flag] = false,
    setList: (state, list) => state.list = list,
    setErrorMessage: (state, errorMesage) => state.errorMessage = errorMesage,
    setErrors: (state, errors) => state.errors = errors,
    clearErrors: (state) => {
        state.errors = {}
        state.errorMessage = ""
    },

    setCampaign: (state, campaign) => state.campaign = campaign,
    setRegister: (state, register) => state.register = register,
    setDeliveredCalls: (state, deliveredCalls) => state.deliveredCalls = deliveredCalls,
}
