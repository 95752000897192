<script setup>
import {computed} from "vue"
import {useStore} from "vuex";
import {useDashboardQuery} from "@/composables/useDashboardQuery";
import DashCard from "@crm/pages/dashboard/components/dash-card.vue";
import RatingFragment from "@crm/pages/dashboard/components/rating-fragment.vue";
import {Utils} from '@/utils';

const store = useStore();
const dateRange = computed(() => store.getters["crm/dashboard/getDateRange"])
const currentFunnel = computed(() => store.getters["crm/funnels/getCurrentFunnel"] ?? null);
const agent = computed(() => store.getters["crm/dashboard/getActiveAgent"] ?? null);

const {data, loading} = useDashboardQuery(
  currentFunnel,
  dateRange,
  dateRange,
  agent,
  'averageTicket'
);

</script>

<script>
export default {
  name: 'AverageTicket',

  methods: {
    toggleCurrency() {
      let locale = 'en';
      let currency = 'USD';

      if (this.$i18n.locale === 'pt') {
        locale = 'pt-BR';
        currency = 'BRL';
      }

      return {
        locale,
        currency
      }
    }
  }
}
</script>

<template>
  <dash-card
    :title="$t('average_ticket')"
    :tooltip-title="$t('tooltips.dashboard.average_ticket.title')"
    :tooltip-body="$t('tooltips.dashboard.average_ticket.body')"
    tooltip-width="320px"
    :loading="loading"
    min-height="72"
  >
    <template #body>
      <rating-fragment
        :actual-value="Utils.formatCurrency(data?.actual_average_ticket, toggleCurrency().locale, toggleCurrency().currency)"
        :previous-value="Utils.formatCurrency(data?.previous_average_ticket, toggleCurrency().locale, toggleCurrency().currency)"
        :difference-value="`${Math.abs(data?.percentage_diff)}%`"
        :difference-status="data?.percentage_diff > 0"
      />
    </template>
  </dash-card>
</template>

<style scoped lang="scss">

</style>