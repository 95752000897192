import store from "@store";

import IndexReceptiveNumbers from "@dialer/manager/pages/settings/receptive-numbers/index-receptive-numbers";
import findReceptiveNumbers from "@dialer/manager/pages/settings/receptive-numbers/list-receptive-numbers";
import EditReceptiveNumbers from "@dialer/manager/pages/settings/receptive-numbers/edit-receptive-number";

export default {
  path: "receptive-numbers",
  name: "receptive-numbers",
  components: {
    main: IndexReceptiveNumbers
  },
  children: [
    {
      path: "",
      components: {
        "receptive-numbers": findReceptiveNumbers
      }
    },
    {
      path: ":id/edit",
      components: {
        "receptive-numbers": EditReceptiveNumbers
      }
    },
  ],
  beforeEnter: (to, from, next) => {
    if (store.getters["auth/isManager"]) next();
    else next({
      name: "not-found",
      params: {
        pathMatch: to.path.split('/').slice(1)
      }
    });
  }
};
