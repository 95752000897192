<template>
  <p class="fs-6 tx-text-gray">
    {{ $t("summary_of_day").charAt(0).toUpperCase() + $t("summary_of_day").slice(1) }}:
  </p>
  <div
    class="row"
    v-if="!loadingFlags['loadCampaignsMetricsCharts'] && !loadingFlags['loadCampaignsMetrics']"
  >
    <div class="col-3">
      <card-chart
        line-color="#9974F0"
        :data="completedPercentage"
        :dotted-data="completedPercentageYesterday"
      >
        <template #text>
          {{ $t('completion_rate') }}
        </template>
        <template #number>
          {{ metrics.completedPercentage }}%
        </template>
      </card-chart>
    </div>

    <div class="col-3">
      <card-chart
        :line-color="'#3057F2'"
        :data="dmc"
        :dotted-data="dmcYesterday"
      >
        <template #text>
          {{ $t('cpc') }}
        </template>
        <template #number>
          {{ metrics.dmc }}%
        </template>
      </card-chart>
    </div>

    <div class="col-3">
      <card-chart
        :line-color="'#9DD4A4'"
        :data="conversion"
        :dotted-data="conversionYesterday"
      >
        <template #text>
          {{ $t('cpc_plus_conversion') }}
        </template>
        <template #number>
          {{ metrics.conversion }}%
        </template>
      </card-chart>
    </div>

    <div class="col-3">
      <card-chart
        :line-color="'#F0B06D'"
        :data="averageIdleness"
        :dotted-data="averageIdlenessYesterday"
        :use-time="true"
      >
        <template #text>
          {{ $t('average_idleness_agent') }}
        </template>
        <template #number>
          {{ formatSeconds(metrics.averageIdleness) }}
        </template>
      </card-chart>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import moment from "moment";
import CardChart from "./card-chart.vue";
import { formatSeconds } from "@/utils";

export default {
  data() {
    return {
      completedPercentage: [],
      completedPercentageYesterday: [],

      dmc: [],
      dmcYesterday: [],

      conversion: [],
      conversionYesterday: [],

      averageIdleness: [],
      averageIdlenessYesterday: [],

      metrics: {
        completedPercentage: 0,
        dmc: 0,
        conversion: 0,
        averageIdleness: 0
      }
    }
  },

  props: {
    campaignId: {
      type: Number,
      default: 0
    }
  },

  mounted() {
    this.handleGraphicsData();
    this.handleCampaignsMetrics();
  },

  components: {
    CardChart
  },

  methods: {
    ...mapActions("manager/campaign/metrics", [
      "loadCampaignsMetricsChart",
      "setCampaignsMetricsChartsFilters",
      "loadCampaignsMetrics",
      "setCampaignsMetricsFilters"
    ]),
    formatSeconds,

    async handleCampaignsMetrics() {
      let filters = {
        start_date: moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment().endOf('day').format("YYYY-MM-DD HH:mm:ss")
      };

      if(this.campaignId)
        filters.campaign_id = this.campaignId;

      this.setCampaignsMetricsFilters(filters);
      await this.loadCampaignsMetrics();

      this.metrics.completedPercentage = this.calculateCompletedPercentage(this.campaignsMetrics);
      this.metrics.dmc = this.calculateDMC(this.campaignsMetrics);
      this.metrics.conversion = this.calculateConversion(this.campaignsMetrics);
      this.metrics.averageIdleness = this.campaignsMetrics.idle_average;
    },

    async handleGraphicsData() {
      let filters = {
        start_date: moment().startOf('day').subtract(1, 'days').format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment().endOf('day').format("YYYY-MM-DD HH:mm:ss")
      };

      if(this.campaignId)
        filters.campaign_id = this.campaignId;

      this.setCampaignsMetricsChartsFilters(filters);

      await this.loadCampaignsMetricsChart();
      this.mountLineGraph("today");
      this.mountLineGraph("yesterday", true);
    },

    calculateCompletedPercentage({ answered, abandoned, total }) {
      if (total > 0)
        return Number(((answered + abandoned) / total * 100).toFixed(2));
      else
        return 0;
    },

    calculateDMC({ dmc, dmc_answered, converted }) {
      if(dmc + dmc_answered + converted > 0)
        return Number((dmc / (dmc + dmc_answered + converted) * 100).toFixed(2));
      else
        return 0;
    },

    calculateConversion({ dmc, dmc_answered, converted }) {
      if(dmc + dmc_answered + converted > 0)
       return Number((converted / (dmc + dmc_answered + converted) * 100).toFixed(2));
      else
        return 0;
    },

    mountLineGraph(selectedDay, isYesterday = false) {
      const target = isYesterday ? "Yesterday" : "";

      this.campaignsMetricsCharts[selectedDay].forEach((metric, i) => {
        this[`completedPercentage${target}`][i] = this.calculateCompletedPercentage(metric);
        this[`dmc${target}`][i] = this.calculateDMC(metric);
        this[`conversion${target}`][i] = this.calculateConversion(metric);
        this[`averageIdleness${target}`][i] = metric.idle_average;
      });

      if (!isYesterday) {
        this.completedPercentage.splice(moment().get('hour') + 1);
        this.dmc.splice(moment().get('hour') + 1);
        this.conversion.splice(moment().get('hour') + 1);
        this.averageIdleness.splice(moment().get('hour') + 1);
      }
    },
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser"
    }),

    ...mapGetters("manager/campaign/metrics", {
      loadingFlags: "getLoadingFlags",
      campaignsMetricsCharts: "getCampaignsMetricsCharts",
      campaignsMetrics: "getCampaignsMetrics",
    })
  }
}
</script>
