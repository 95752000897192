<template>
  <div class="card mt-4">
    <div class="card-header">
      <h4 class="mb-0 tx-text-200">
        {{ $t('first_access') }}
      </h4>
    </div>
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col-12 col-lg-4 text-center">
          <img
            src="@/assets/img/warmup-insights.svg"
            alt="warmup"
            draggable="false"
          >
        </div>
        <div class="col-12 col-lg-8">
          <h4
            class="tx-text-200 fw-bolder"
            style="font-size: 27px"
          >
            {{ $t('configure_ai_insights') }}
          </h4>
          <p class="tx-text-gray mb-0 fs-6">
            {{
              $t('transform_calls_in_insights')
            }}
          </p>
          <router-link
            @click="setCurrentTab('tab-dialer')"
            to="/manager/insights-ai/settings"
            class="btn btn-primary py-0 d-flex align-items-center justify-content-center mt-4"
            style="width: 206px;height: 32px;"
          >
            <i
              class="far fa-gear"
              style="margin-right: 8px;"
            />
            {{ $t('configure') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <card-warmup
      v-for="item in office"
      :key="item.title"
      :title="item.title"
      :text="item.text"
      :icon-class="item.icon"
      height="210px"
    />
  </div>
</template>

<script>
import CardWarmup from "@components/card-warmup.vue";
import {mapMutations} from "vuex";

export default {
  name: "WarmupSetting",
  components: { CardWarmup },
  data() {
    return {
      office: [
        {
          title: this.$t('simplified_summary'),
          text: this.$t('simplified_summary_description'),
          icon: "fal fa-paperclip fw-medium tx-yellow-300"
        },
        {
          title: this.$t('time_efficiency'),
          text: this.$t('time_efficiency_description'),
          icon: "fal fa-clock fw-medium tx-blue-300"
        },
        {
          title: this.$t('strategy_feedback'),
          text: this.$t('strategy_feedback_description'),
          icon: "fal fa-comments fw-medium tx-pink-300"
        }
      ]
    };
  },

  methods:{
    ...mapMutations("manager/insights", ['setCurrentTab'])
  }
};
</script>
