<template>
  <div
    :id="`#${id}`"
    class="accordion"
  >
    <div
      :class="{ 'border-0': !showSearch }"
      class="accordion-item bg-white position-relative"
    >
      <div class="accordion-header d-flex align-items-center">
        <button
          v-show="!showSearch && !isAnimating"
          :aria-controls="`${id}-multi`"
          :data-bs-target="`#${id}-multi`"
          aria-expanded="true"
          class="btn p-0 collapsed bg-white align-items-center w-100  border justify-content-between"
          :class="{ 'd-flex ': !showSearch }"
          data-bs-toggle="collapse"
          style="height: 40px;padding: 8px !important;"
          type="button"
          @click="toggle"
        >
          <div
            v-if="currentSelected.length || options.length"
            class="me-3 w-100"
          >
            <ul
              class="px-0 mb-0 d-flex flex-wrap overflow-hidden align-items-center gap-2"
              style="height: 24px;"
            >
              <li
                v-for="(option, index) in currentSelected"
                :key="index"
                :class="{ 'col-xxl-2': resize }"
              >
                <div
                  class="d-flex justify-content-between align-items-center  badge-status gap-2 col"
                  style="height: 24px;"
                >
                  <p class=" fs-6 mb-0  text-truncate">
                    {{ option[fieldName] || option }}
                  </p>
                  <i
                    class="fal fa-times tx-text-blue-500 m-0 fs-6"
                    style="height: 22px;"
                    data-bs-toggle="collapse"
                    @click.stop="removeOption(index, option.id)"
                  />
                </div>
              </li>
            </ul>
          </div>
          <span
            v-else
            class="tx-text-gray"
          >{{ textSelect }}</span>
          <i class="fal fa-chevron-down tx-text-gray" />
        </button>
        <div
          v-show="showSearch"
          class="input-group  rounded-0 rounded-top"
          style="height: 40px;"
        >
          <span
            id="basic-addon1"
            class="input-group-text pe-0 border-0 border-bottom radius-left  rounded-0 cursor-pointer"
            data-bs-target="#collapseOne"
            data-bs-toggle="collapse"
          ><i class="fal fa-search" /></span>
          <input
            v-model="searchTerm"
            id="inputSearch"
            :disabled="isAnimating"
            class="form-control ps-0 border-0  radius-left rounded-0"
            placeholder="Buscar"
            type="search"
            ref="focusIn"
          >
          <span
            :data-bs-target="`#${id}-multi`"
            :disabled="isAnimating"
            class="input-group-text    border-0 radius-right  rounded-0 cursor-pointer"
            data-bs-toggle="collapse"
            style="padding-left: 8px;"
            @click="toggle"
          ><i class="fal fa-chevron-up tx-text-gray" /></span>
        </div>
      </div>
      <div
        :id="`${id}-multi`"
        :data-bs-parent="`#${id}`"
        class="accordion-collapse collapse position-absolute border border-top-0 w-100 index bg-white"
      >
        <div
          :class="{ 'pt-0': options.length }"
          class="accordion-body rounded-bottom bg-white"
        >
          <div
            v-if="currentSelected.length"
            class="border-bottom  pt-2"
          >
            <ul
              class="mb-0 row overflow-auto"
              style="max-height: 130px;padding: 0 32px!important;"
            >
              <li
                v-for="(option, index) in currentSelected"
                :key="index"
                class="col-sm-6 col-md-4 col-xxl-2 pe-3 ps-0 pt-0 pb-3"
              >
                <div
                  class="d-flex justify-content-between align-items-center badge-status gap-2"
                  style="height: 24px;"
                >
                  <p class=" fs-6 mb-0  text-truncate">
                    {{ option[fieldName] || option }}
                  </p>
                  <i
                    class="fal fa-times tx-text-blue-500 m-0"
                    data-bs-toggle="collapse"
                    @click.stop="removeOption(index, option.id)"
                    style="height: 22px;"
                  />
                </div>
              </li>
            </ul>
          </div>
          <div
            class="overflow-auto"
            style="min-height: 48px;max-height: 300px"
          >
            <ul class="px-0 pt-2 mb-0">
              <li
                v-for="item in search"
                :key="item"
              >
                <p
                  :class="{ active: active }"
                  class="search-hover mb-0  px-4 gap-2 rounded w-100"
                  data-bs-toggle="collapse"
                  @click.stop="selectOption(item)"
                  @mouseenter="active = true"
                  @mouseleave="active = false"
                  style="line-height: 32px;"
                >
                  {{ item[fieldName] }}
                </p>
              </li>
              <li v-if="search.length === 0">
                <p
                  class="search-hover mb-0 p-2 px-4 text-center rounded w-100"
                  style="line-height: 32px;"
                >
                  {{ notFound ? notFound : $t("result_not_found") }}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Collapse } from "bootstrap";

export default {
  props: {
    id: {
      type: String,
      default: ""
    },
    listObject: {
      type: Object,
      default: () => {}
    },
    fieldName: {
      type: String,
      default: ""
    },
    notFound: {
      type: String,
      default: ""
    },
    textSelect: {
      type: String,
      default: "Selecione"
    },
    currentSelected: {
      type: [Array, Object],
      default: null
    }
  },
  emits: ["selected"],
  data() {
    return {
      options: [],
      searchTerm: "",
      showSearch: false,
      isAnimating: false,
      active: false,
      resize: false,
      focus: false,
      collapse: null
    };
  },
  computed: {
    search() {
      return this.listObject.filter(item => {
        const option = this.options.find(option => option[this.fieldName] === item[this.fieldName]);
        if (option) return false;
        return item[this.fieldName].toLowerCase().includes(this.searchTerm.toLowerCase());
      });
    }
  },
  methods: {
    closeWindowSelect(event) {
      const box = document.getElementById(`#${this.id}`);
      const isSelfClick = box.contains(event.target);
      if (this.showSearch && !isSelfClick) {
        this.collapse.hide();
        setTimeout(() => {
          this.showSearch = false;
          this.searchTerm = "";
        }, 300);
      }
    },
    toggle() {
      if (!this.isAnimating) {
        this.isAnimating = !this.isAnimating;
        this.showSearch = !this.showSearch;
        this.searchTerm = "";
        setTimeout(() => {
          this.isAnimating = false;
          this.$nextTick(() => {
            this.onFocus();
          });
        }, 300);
      }
    },
    selectOption(option) {
      this.options.push(option);
      this.showSearch = true;
      this.searchTerm = "";
      this.$emit("selected", option);
    },
    removeOption(index, id) {
      this.options.splice(index, 1);
      this.$emit("selected", { index, id, type: "remove" });
    },
    windowResize() {
      this.resize = window.innerWidth >= 1980;
    },
    selectedOptions() {
      this.options = this.currentSelected?.data || [];
    },
    onFocus() {
      if (!this.showSearch) {
        this.$refs.focusIn.focus();
      }
    }
  },
  mounted() {
    this.selectedOptions();
    this.windowResize();
    this.collapse = new Collapse(document.getElementById(`${this.id}-multi`), {
      toggle: false
    });
    window.addEventListener("resize", this.windowResize);
    document.addEventListener("click", this.closeWindowSelect);
  },
  beforeUnmount() {
    this.windowResize();
    window.removeEventListener("resize", this.windowResize);
    document.removeEventListener("click", this.closeWindowSelect);
  }
};
</script>

<style lang="scss" scoped>
.radius-left {
  border-top-left-radius: 0.625rem !important;
}

.radius-right {
  border-top-right-radius: 0.625rem !important;
}

.index {
  z-index: 6;
}

.px-4 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.search-hover.active:hover {
  background-color: $color-gray-blue-300;
}

ul {
  list-style-type: none;
}

.badge-status {
  border-radius: 6px;
  font-size: 12px;
  padding: 3px 8px;
  background-color: #e1e9f4;
  color: $color-blue-500;
}

@media screen and (min-width: 1980px) {
  .col-xxl-2 {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
}
</style>
