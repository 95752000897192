<template>
  <channels-accordion-item
    v-if="isInstanceQueue(groupChannel)"
    :accordion-id="accordionId"
    :instance="groupChannel"
    :blocked="!currentChat?.agent_id"
    @select-instance="selectInstance"
  />
  <channels-accordion-item
    v-else
    :accordion-id="accordionId"
    :instance="groupChannel"
    @select-instance="selectInstance"
  />
</template>

<script>
import {Tooltip} from "bootstrap";
import {formatPhoneNumber} from "@/utils";
import {mapGetters} from "vuex";
import ChannelsAccordionItem from "@whatsapp/components/transfer-form/channels-accordion-item.vue";

export default {
  components: {
    ChannelsAccordionItem,
  },
  emits: ["selected-instance"],

  props: {
    groupChannel: {
      type: Object,
      default: null
    },
    accordionId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tooltip: null,
    }
  },

  computed: {
    ...mapGetters("whatsapp/chat", {
      chat: "getCurrentChat"
    }),
    currentChat() {
      return this.chat[this.$route.params.id]
    },
  },

  mounted() {
    this.generateTooltip()
  },

  methods: {
    formatPhoneNumber,
    isInstanceQueue(groupChannel) {
      return groupChannel.instances.some(instance => instance.team_id === this.currentChat.team_id)
    },
    selectInstance(instance) {
      this.$emit('selected-instance', instance.id)
    },
    generateTooltip() {
      if (this.currentChat && this.$refs.tooltip) {
        if (this.tooltip && this.currentChat.agent_id) {
          this.tooltip.dispose();
        }
        if (this.isInstanceQueue(this.groupChannel) && !this.currentChat.agent_id) {
          this.tooltip = new Tooltip(this.$refs.tooltip, {
            title: this.$t('current_group_channel')
          })
        }
      }
    },
  },
  beforeUnmount() {
    if (this.tooltip) {
      this.tooltip.dispose();
    }
  }
}

</script>

<style lang="scss" scoped>
.dropdown-item.disabled {
  pointer-events: auto;
  cursor: default;
}
</style>