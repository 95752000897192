<template>
  <div
    class="position-relative"
    style="z-index: 1046"
    ref="selectChannel"
  >
    <div
      class="select-channel d-flex justify-content-between align-items-center cursor-pointer"
      data-test="selectChannel-div"
      @click="openSelect"
    >
      <div
        v-if="selectedChannel"
        class="d-flex justify-content-between align-items-center w-100"
      >
        <p
          v-if="selectedChannel?.phone"
          class="m-0 lh-base"
        >
          {{ formatInternationalPhoneMask(selectedChannel?.phone) }}
        </p>
        <span
          v-if="selectedChannel.type === 'waba'"
          class="tx-text-gray text-small lh-sm"
          :class="{
            'tx-text-100': disabled,
            'tx-text-gray': !disabled
          }"
        >
          WABA
        </span>
        <span
          v-else-if="selectedChannel.type === 'ultramsg'"
          class="tx-text-gray text-small lh-sm"
        >
          Whatsapp 3C+
        </span>
      </div>
      <span
        class="far fa-chevron-down tx-text-gray fs-4"
        :class="open? 'chevron-open' : 'chevron-closed'"
        style="margin-left: 0.75rem"
      />
    </div>
    <div
      class="select-channel w-100 position-absolute top-0 bg-white"
      :class="{'d-none': !open}"
    >
      <div
        class="d-flex justify-content-between align-items-center"
      >
        <div class="d-flex justify-content-between align-items-center w-100">
          <span
            class="far fa-search tx-text-gray"
            style="margin-right: 0.75rem"
          />
          <input
            class="form-control p-0 border-0 text-medium"
            style="border-radius: 0"
            :placeholder="$t('placeholders.search_capitalize')"
            @input="search"
          >
        </div>
        <span
          class="far fa-chevron-down tx-text-gray fs-4 cursor-pointer"
          style="margin-left: 0.75rem"
          :class="open? 'chevron-open' : 'chevron-closed'"
          @click="open = !open"
        />
      </div>
      <div
        class="bg-white"
        :class="{'d-none': !open || !groupChannels.length}"
        style="margin-top: 0.5rem"
      >
        <div
          v-for="groupChannel in groupChannels"
          :key="groupChannel.id"
        >
          <div
            class="d-flex align-items-center"
            style="padding: 0.25rem 0"
          >
            <span
              class="fas fa-circle"
              style="font-size: 0.5rem; margin-right: 0.5rem"
              :style="{color: groupChannel.color}"
            />
            <p class="m-0 fw-medium lh-base">
              {{ groupChannel.name }}
            </p>
          </div>
          <ul class="m-0 p-0">
            <li
              v-for="channel in groupChannel.channels.data"
              :key="channel.id"
              class="list-group-item channel d-flex align-items-center justify-content-between"
              @click="handleClick(channel)"
            >
              <p
                v-if="channel.phone"
                class="m-0 lh-base"
              >
                {{ formatInternationalPhoneMask(channel.phone) }}
              </p>
              <span
                v-if="channel.type === 'waba'"
                class="tx-text-gray text-small lh-sm"
              >
                WABA
              </span>
              <span
                v-else-if="channel.type === 'ultramsg'"
                class="tx-text-gray text-small lh-sm"
              >
                Whatsapp 3C+
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import _ from "lodash";
import {formatInternationalPhoneMask} from "@/utils";

export default {
  name: "SelectChannel",

  emits: ["selectChannel"],

  data() {
    return {
      open: false,
      selectedChannel: null
    }
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  created() {
    this.fetchGroupChannels().then(() => {
      if (this.groupChannels && this.groupChannels.length > 0) {
        let foundChannel = this.groupChannels.map((groupChannel) => {
          return groupChannel.channels.data.find(channel => {
            return channel.status
          })
        })
        if (foundChannel && foundChannel.length > 0) {
          this.selectedChannel = foundChannel[0]
          this.$emit("selectChannel", this.selectedChannel)
        }
      }
    })
  },

  mounted() {
    document.addEventListener("click", this.closeDropdown);
  },

  beforeUnmount() {
    document.removeEventListener("click", this.closeDropdown);
  },

  computed: {
    ...mapGetters("whatsapp/instance", {
      groupChannelsData: "getGroupChannels"
    }),

    groupChannels() {
      return this.groupChannelsData.filter(groupChannel => {
        return groupChannel.channels.data.some(channel => {
          if (channel.status === 'connected') {
            this.$emit("selectChannel", channel)
            this.selectedChannel = channel
          }
          return channel.status === "connected"
        })
      })
    },
  },

  methods: {
    formatInternationalPhoneMask,
    ...mapActions("whatsapp/instance", ["fetchGroupChannels"]),

    search: _.debounce(function(event) {
      const term = event.target.value
      this.fetchGroupChannels(term);
    }, 500),

    handleClick(channel){
      this.$emit("selectChannel", channel)
      this.selectedChannel = channel
      this.open = false
    },

    closeDropdown(event) {
      if (!this.$refs.selectChannel.contains(event.target)) {
        this.open = false;
      }
    },

    openSelect() {
      if (!this.disabled) {
        this.open = !this.open;
      }
    }
  },
}
</script>

<style scoped lang="scss">
.select-channel {
  padding: 0.5rem 1rem;
  border: 1px solid #E1E9F4;
  border-radius: 10px;
}

.channel {
  border: 0;
  border-radius: 10px;
  cursor: pointer;
  padding: 0.25rem 1rem;

  &:hover{
    background-color: #E1E9F4;
  }
}

.chevron-open {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.chevron-closed {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}
</style>
