<template>
  <table-before-search v-if="!searched" />
  <table-chats-report v-else />
</template>

<script>
import TableBeforeSearch from "@dialer/manager/pages/reports/components/table-before-search.vue";
import TableChatsReport from "@dialer/manager/pages/reports/chats-agents/components/table-chats-report.vue";

export default {
  name: "TabChats",
  components: {TableChatsReport, TableBeforeSearch},
  props: {
    searched: {
      type: Boolean,
      default: false
    }
  }
}
</script>