<template>
  <div class="col-12">
    <template v-if="workBreakGroup">
      <div
        v-if="errorMessage"
        class="alert alert-danger fade show"
        role="alert"
      >
        <span class="fe fe-alert-octagon" />&nbsp; {{ errorMessage }}
      </div>

      <div
        v-if="!intervals.length"
        class="alert alert-info fade show"
        role="alert"
      >
        <span class="fe fe-alert-octagon" />&nbsp; <strong>{{ $t("attention") }}</strong>
        {{ $t("no_break_added") }}
        <span
          style="text-decoration: underline; cursor: pointer"
          @click="openNew()"
        >
          {{ $t("click_here_to_add") }}
        </span>
      </div>

      <list-interval
        :intervals="intervals"
        @open-new="openNew"
        @open-edit="openEdit"
      />
      <form-interval ref="formInterval" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FormInterval from "./components/form-interval.vue";
import ListInterval from "./components/list-interval.vue";

export default {
  name: "IntervalsList",
  props: {
    intervals: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters("manager/intervals", {
      workBreakGroup: "getWorkBreakGroup",
      errorMessage: "getErrorMessage",
    })
  },
  components: {
    ListInterval,
    FormInterval
  },
  methods: {
    openNew() {
      this.$refs.formInterval.new();
    },

    openEdit(item) {
      this.$refs.formInterval.edit(item);
    },
  }
};
</script>
