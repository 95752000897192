<template>
  <side-bar />
  <div class="main-content admin">
    <div class="container-fluid b2">
      <router-view name="main" />
    </div>
  </div>
</template>

<script>

import SideBar from "../components/side-bar.vue";

export default {
    components: {
        SideBar,
    },
};
</script>
