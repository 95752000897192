export default {
    // TODO: Remove loading and use only loadingFlags
    loading: false,
    loadingFlags: {
        createIvrAfterCall: false,
        editIvrAfterCall: false,
        createCriteria: false,
        editCriteria: false,
        removeCriteria: false,
        removeIvrAfterCall: false
    },
    errors: {
        createIvrAfterCall: {},
        editIvrAfterCall: {},
        createCriteria: {},
        editCriteria: {}
    },
    alert: null,
    list: [],
    filters: {},
    include: ['keys'],
    pagination: {
        per_page: 12,
        current_page: 1
      },
    register: null,
    paginator: {},
    dataSelect: null,
}
