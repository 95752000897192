<template>
  <div
    ref="modal"
    :class="{ 'blur-background': backgroundBlur }"
    class="modal fade"
  >
    <div
      :class="[center && 'modal-dialog-centered', size]"
      :style="{ maxWidth: customSize.length ? customSize : '' }"
      class="modal-dialog"
    >
      <div class="modal-content rounded-4">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from "bootstrap";

export default {
  emits: ["close"],

  data() {
    return {
      modal: null
    };
  },

  mounted() {
    this.modal = new Modal(this.$refs.modal, {
      backdrop: this.backdrop,
      keyboard: this.keyboard,
      focus: this.focus
    });

    this.$refs.modal.addEventListener("hidden.bs.modal", this.onHideModal);
  },

  beforeUnmount() {
    this.modal.hide();
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },

    size: {
      type: String,
      default: ""
    },

    /**
     * Includes a modal-backdrop element. Alternatively,
     * specify 'static' for a backdrop which doesn't
     * close the modal on click.
     */
    backdrop: {
      type: [Boolean, String],
      default: true
    },

    /**
     * Closes the modal when escape(esc) key is pressed.
     */
    keyboard: {
      type: Boolean,
      default: false
    },

    /**
     * Put the focus on the modal when initialized.
     */
    focus: {
      type: Boolean,
      default: true
    },

    /**
     * modal-dialog-centered or modal-dialog.
     */
    center: {
      type: Boolean,
      default: false
    },

    backgroundBlur: {
      type: Boolean,
      default: false
    },
    customSize: {
      type: String,
      default: ""
    }
  },

  methods: {
    onHideModal() {
      this.$emit("close");
    }
  },

  watch: {
    show(data) {
      if (data) this.modal.show();
      else this.modal.hide();
    }
  }
};
</script>
<style lang="scss" scoped>
.modal-418 {
  max-width: 418px !important;
}

.modal-527-sm {
  max-width: 527px !important;
}

.modal-751-lg {
  max-width: 751px !important;
}
</style>
