<template>
  <div
    ref="canvas"
    class="offcanvas offcanvas-end"
  >
    <div
      class="offcanvas-header"
      style="border: none"
    >
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      />
    </div>

    <div class="offcanvas-body">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h2 style="font-weight: 100">
            {{ mode == "new" ? $t("add_new_m") : $t("edit") }} {{ $t("telephony_group") }}
          </h2>
          <p class="text-muted">
            {{
              $t("telephony_text")
            }}
          </p>
        </div>

        <div class="col-lg-12">
          <div
            class="alert alert-danger fade show"
            role="alert"
            v-if="errorMessage"
          >
            <span class="fe fe-alert-octagon" />&nbsp; {{ errorMessage }}
          </div>
        </div>

        <div class="col-lg-12 text-center">
          <hr>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-label">{{ $t("name_data") }}</label>
            <input
              :class="{
                'form-control': true,
                'is-invalid': errors.name
              }"
              v-model="fields.name"
              :placeholder="$t('name_data')"
            >
            <div class="invalid-feedback">
              {{ errors.name && errors.name[0] }}
            </div>
          </div>
        </div>

        <div
          class="col-lg-12 mb-4"
          v-if="isBrazil"
        >
          <div class="d-flex align-items-center justify-content-between">
            <div class="form-check">
              <input
                :class="{
                  'form-check-input': true,
                  'is-invalid': errors.group_type
                }"
                type="radio"
                name="groupType"
                value="landline"
                v-model="fields.group_type"
                :disabled="mode==='edit'"
              >
              <label class="form-check-label">{{ $t("routes_to_phone") }}</label>
            </div>
            <div class="form-check">
              <input
                :class="{
                  'form-check-input': true,
                  'is-invalid': errors.group_type
                }"
                type="radio"
                name="groupType"
                value="mobile"
                v-model="fields.group_type"
                :disabled="mode==='edit'"
              >
              <label class="form-check-label">{{ $t("routes_to_cellphone") }}</label>
            </div>
          </div>
          <small class="text-danger d-block">
            {{ errors.group_type && errors.group_type[0] }}
          </small>
          <small
            v-if="mode==='edit'"
            class="text-primary"
          >{{ $t("cannot_change_group_type") }}</small>
        </div>

        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-label">{{ $t("routes_capitalize") }}</label>
            <VueMultiselect
              :class="{
                'form-select p-0': true,
                'is-invalid': errors.route
              }"
              v-model="fields.route"
              label="name"
              track-by="id"
              :placeholder="$t('select_route')"
              :select-label="$t('click_to_select')"
              :deselect-label="$t('click_to_remove')"
              :selected-label="$t('selected')"
              :multiple="true"
              :close-on-select="false"
              :preserve-search="true"
              :options="routes"
              open-direction="bottom"
            />
            <small class="text-danger d-block">
              {{ errors.route && errors.route[0] }}
            </small>
          </div>
        </div>

        <div class="col-lg-12">
          <app-button
            :loading="loading.createRouteGroup"
            class="w-100"
            @click="create()"
            v-if="mode == 'new'"
          >
            {{ $t("add_capitalize") }}
          </app-button>
          <app-button
            :loading="loading.update"
            class="w-100"
            @click="update()"
            v-else-if="mode == 'edit'"
          >
            {{ $t("edit") }}
          </app-button>
        </div>
        <div class="col-lg-12">
          <app-button
            :loading="loading.deleteRouteGroup"
            class="fw"
            color="text-danger"
            v-if="mode === 'edit'"
            @click="remove_()"
          >
            <span class="fe fe-trash" />
            {{ $t("exclude") }}
          </app-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Offcanvas } from "bootstrap";
import { colors } from "@/utils";
import { mapActions, mapGetters, mapMutations } from "vuex";

import appButton from "@/components/app-button.vue";
import VueMultiselect from "vue-multiselect";

export default {
  data() {
    return {
      offcanvas: null,
      colors: [],
      fields: {
        id: 0,
        name: "",
        group_type: "",
        route: [],
      },
      routes: [],
      mode: "new",
      errorMessage: "",
    };
  },

  mounted() {
    this.colors = colors;
    this.errorMessage = "";
    this.offcanvas = new Offcanvas(this.$refs.canvas);
  },

  components: {
    appButton,
    VueMultiselect,
  },

  computed: {
    ...mapGetters("manager/telephony", {
      loading: "getLoadingFlags",
      errorFlag: "getErrorFlags",
    }),
    ...mapGetters("auth", {
      user: "getUser",
      isBrazil: "isBrazil",
    }),
    errors() {
      if (this.mode === "new") return this.errorFlag.createRouteGroup;

      return this.errorFlag.updateRouteGroup;
    },
  },

  methods: {
    ...mapActions("manager/telephony", [
      "createRouteGroup",
      "updateRouteGroup",
      "deleteRouteGroup",
      "listRouteGroups",
      "clearSpecifError",
    ]),
    ...mapActions("system", ["showConfirmationMessage"]),

    ...mapActions("manager/filters", ["findFilters"]),

    ...mapMutations("system", ["setSuccessMessage"]),

    ...mapMutations("auth", ["trackEvents"]),

    edit(item, routes) {
      this.clearSpecifError("updateRouteGroup");

      this.routes = routes;

      this.fields = {
        id: item.id,
        name: item.name,
        group_type: item.group_type,
        route: item.routes,
      };
      this.mode = "edit";
      this.offcanvas.show();
    },

    new(routes) {
      this.clearSpecifError("createRouteGroup");
      this.routes = routes;
      let type;
      if (this.isBrazil) type = "landline";
      else type = "outbound_call";
      this.fields = {
        name: "",
        group_type: type,
        route: [],
      };
      this.mode = "new";
      this.offcanvas.show();
    },

    create() {
      const route = this.getIdRoutes();

      this.createRouteGroup({
        group_type: this.fields.group_type,
        name: this.fields.name,
        route: route,
      })
          .then(() => {
            this.findFilters();
            this.triggerSuccessButton(this.$t("create_group_success"));
            this.trackEvents("[Manager] created route group");
          });
    },

    update() {
      const route = this.getIdRoutes();

      this.updateRouteGroup({
        id: this.fields.id,
        group_type: this.fields.group_type,
        name: this.fields.name,
        route: route,
      })
          .then(() => {
            this.findFilters();
            this.triggerSuccessButton(this.$t("update_group_success"));
            this.trackEvents("[Manager] Updated route group");
          });
    },

    async remove_() {
      let response = await this.showConfirmationMessage({
        title: this.$t("delete_group_question"),
        text: `${this.$t("delete_group_confirmation")} <strong>${this.fields.name}</strong>  ${this.$t("delete_group_confirmation_2")}`,
        type: "badWarning",
      });

      if (response.isConfirmed) {
        this.deleteRouteGroup({
          id: this.fields.id,
        })
            .then(() => {
              this.listRouteGroups();
              this.offcanvas.hide();
              this.trackEvents("[Manager] Deleted route group");
            });
      }
    },

    getIdRoutes() {
      return this.fields.route.map(route => route.id);
    },

    triggerSuccessButton(message) {
      this.setSuccessMessage(message);
      this.listRouteGroups();
      this.offcanvas.hide();
    },
  },
};
</script>
