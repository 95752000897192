export default {
  teamAgents: [],
  teamUsers: [],
  formInputs: [],
  agentsPagination: {
    current_page: 1,
    total_pages: 1,
    per_page: 15,
    total: 0
  },
  loadingFlags: {
    contactsLoading: false,
    findContactLogs: false,
    fetchUsersByTeam: false,
    fetchAgents: false,
    postContact: false
  },
  errors: [],
}
