export default {
  loadingFlags: {
    fetchRoutes: false,
    createRoute: false,
    deleteRoute: false
  },
  errors: {},
  errorMessage: "",
  routes: [],
  routesPagination: {
    per_page: 15,
    current_page: 1
  },
  routesFilters: {
    endpoints: []
  },
  includes: [],
  spreadsheet: {}
};
