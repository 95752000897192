<template>
  <div class="d-flex flex-column justify-content-center align-items-center h-100">
    <img
      alt="empty-funnel"
      class="img-fluid mb-4"
      src="@assets/img/empty.svg"
      width="150"
    >
    <h2>{{ $t('waba_message_template_empty_state.title') }}</h2>
    <p>{{ $t('waba_message_template_empty_state.subtitle') }}</p>
    <a
      href="https://business.facebook.com/wa/manage/message-templates"
      target="_blank"
      class="btn btn-primary"
    >
      {{ $t('waba_message_template_empty_state.create_new_template') }}
      <i class="fa-solid fa-arrow-up-right-from-square ms-2" />
    </a>
  </div>
</template>
<script>
export default {
  name: "EnptyStateTemplates"
}
</script>
