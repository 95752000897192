<template>
  <button
    class="w-100 h-100 border-0 d-flex gap-4 justify-content-between align-items-center  text-start rounded card-button"
    @click="$router.push(redirectLink)"
  >
    <span class="tx-text-100">
      {{ title }}
    </span>
    <i class="fa-regular fa-plus tx-blue-200" />
  </button>
</template>
<script>
export default {
  name: "CardButton",
  props: {
    title: {
      type: String,
      required: true,
    },
    redirectLink: {
      type: String,
      required: true,
    },
  },
}
</script>


<style scoped lang="scss">
.card-button {
  background-color: $color-gray-blue-300;
  padding: 1rem
}
</style>