<template>
  <modal-component
    :background-blur="true"
    :center="true"
    :show="show"
    custom-size="647px"
    @close="$emit('close')"
  >
    <template #content>
      <div class="modal-header">
        <div class="modal-title d-flex align-items-center justify-content-between w-100">
          <h4
            class="tx-text-200 mb-0"
          >
            {{ $t(getContent.header) }}
          </h4>
          <div>
            <button
              aria-label="Close"
              class="btn"
              type="button"
              @click="$emit('close')"
            >
              <i class="fa-regular fa-xmark" />
            </button>
          </div>
        </div>
      </div>
      <div
        class="modal-body d-flex flex-column align-items-center"
        style="padding: 40px 40px 32px 40px"
      >
        <h2 :class="scope === 'delete' ? 'mb-5' : 'mb-4'">
          {{ $t(getContent.title) }}
        </h2>
        <img
          v-if="scope === 'delete'"
          alt=""
          class="mb-5"
          src="@assets/img/aloisio/badWarning.svg"
        >
        <h4 class="fw-normal text-center">
          {{ $t(getContent.subtitle) }}
        </h4>

        <button
          :class="scope === 'delete' ? 'danger-btn' : 'btn-primary'"
          class="btn custom-btn"
          style="margin-bottom: 5px"
          @click="dispatchAction"
        >
          {{ $t('yes') }}
        </button>

        <button
          class="btn custom-btn cancel-btn"
          @click="$emit('close')"
        >
          {{ $t('no') }}
        </button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from "@components/modal-component.vue";

export default {
  name: "RestoreOrDeleteOpportunityModal",

  components: {ModalComponent},

  emits: ['close', 'delete', 'restore'],

  props: {
    show: {
      type: Boolean,
      default: false
    },

    scope: {
      type: String,
      default: 'delete'
    }
  },

  computed: {
    getContent() {
      const content = {
        delete: {
          header: 'exclude_opportunity',
          title: 'exclude_opportunity_confirmation',
          subtitle: 'exclude_opportunity_status'
        },
        restore: {
          header: 'reactivate_opportunity',
          title: 'reactivate_opportunity_confirmation',
          subtitle: 'reactivate_opportunity_funnel',
        }
      }
      return this.scope === 'delete'
        ? content.delete
        : content.restore
    }
  },

  methods: {
    dispatchAction() {
      this.scope === 'delete'
        ? this.$emit('delete')
        : this.$emit('restore')
    }
  }
}
</script>

<style lang="scss" scoped>
h4 {
  margin-bottom: 40px;
}

.custom-btn {
  width: 328px;
  height: 40px;
}

.danger-btn {
  background-color: #F23F2C;
  color: $color-text-light;

  &:hover {
    background-color: #D1220F;
  }
}

.cancel-btn {
  color: $color-text-gray;

  &:hover {
    color: $color-text-dark;
  }
}
</style>