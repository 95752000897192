<template>
  <div class="dropdown">
    <button
      ref="dropdown"
      aria-expanded="false"
      :disabled="loading"
      data-test="doneChat-btn"
      class="d-flex justify-content-center align-items-center btn btn-done-chat"
      :class="{
        'small-active': !showLabel
      }"
      @click="dropdown.toggle()"
    >
      <span
        v-show="opened"
        class="finalize-button"
      >
        <i
          v-if="!showLabel"
          class="fa-solid fa-xmark"
        />
        <template v-else>
          {{ $t('cancel_general') }}
        </template>
      </span>
      <span
        :class="opened? 'd-none' : 'd-flex'"
        class="justify-content-center align-items-center"
        :style="showLabel ? 'width: 84px;': ''"
      >
        <span
          class="fe fe-check-circle"
          :class="{
            'pe-2': showLabel
          }"
        />
        <span
          v-if="showLabel"
          class="h6 m-0 pt-1"
        >{{ $t('finalize') }}</span>
      </span>
    </button>
    <div
      class="dropdown-menu"
      ref="dropdownMenu"
    >
      <done-chat-form />
    </div>
  </div>
</template>

<script>
import {Dropdown} from "bootstrap";
import DoneChatForm from "@dialer/manager/components/topbar/done-chat-form.vue";

export default {
  components: {DoneChatForm},
  data() {
    return {
      dropdown: null,
      opened: false,
      fields: {
        qualification: undefined,
        qualification_note: "",
      },
    };
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    },

    showLabel: {
      type: Boolean,
      default: true
    },
  },

  mounted() {
    this.dropdown = new Dropdown(this.$refs.dropdown);

    this.$refs.dropdown.addEventListener('show.bs.dropdown', () => {
      this.opened = true;
    });
    this.$refs.dropdown.addEventListener('hide.bs.dropdown', () => {
      this.opened = false;
    });
    document.addEventListener("click", this.handleClickOutside);
  },

  beforeUnmount() {
    this.$refs.dropdown.removeEventListener('show.bs.dropdown', () => {
      this.opened = true;
    });
    this.$refs.dropdown.removeEventListener('hide.bs.dropdown', () => {
      this.opened = false;
    });
    document.removeEventListener("click", this.handleClickOutside);
  },

  methods: {
    handleClickOutside(event) {
      const dropdownButton = this.$refs.dropdown;
      const dropdownMenu = this.$refs.dropdownMenu;
      const buttonContains = dropdownButton.contains(event.target)
      const menuContains = dropdownMenu.contains(event.target)

      if (!buttonContains && !menuContains && this.opened) {
        this.dropdown.hide()
      }
    },
  }
};
</script>

<style scoped lang="scss">
.btn-done-chat {
  height: 32px;
  background-color: #E1E9f4;
  color: #677C92;

  &.show, &:hover {
    background-color: #3057f2;
    color: white;
  }

  &.small-active {
    max-width: 32px !important;
    min-width: 32px !important;
    width: 32px !important;
    padding: 0.25rem !important;
    font-size: 0.875rem !important;
  }
}

.finalize-button {
  font-size: 14px;
  width: 84px;
  margin-top: 2px;
}

.dropdown-menu {
  width: 274px;
  max-height: 403px;
  border-radius: $whatsapp-border-radius;
  box-shadow: 0px 12px 24px 0px rgba(18, 38, 63, 0.03);
  padding: $whatsapp-spacing-3;

  .disabled {
    opacity: 0.6 !important;
  }

  select, textarea::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: $color-text-200;
  }
}
</style>
