export default {
  loading: {
    findFeedbackReports: false,
    findAgentFeedbackReports: false,
    downloadTable: false,
  },
  errors: {},
  feedbackStatistic: [],
  feedbackAgent: {},
}
