import axios from "axios";

export default {
  /**
   * Fetches campaigns for the agent and updates the state.
   */
  fetchCampaigns({ commit }) {
    commit("startLoadingFlag", "fetchCampaigns");
    return new Promise((resolve, reject) => {
      axios.get('/agent/campaigns', { params: { running: true } }).
        then((response) => {
          commit('setCampaigns', response.data.data);
          resolve();
        })
        .catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "fetchCampaigns");
        });
    })
  },
}
