<template>
  <div class="login d-flex align-items-center">
    <div class="container">
      <div class="row align-items-center">
        <div
          class="
            col-12 col-md-6
            offset-xl-2 offset-md-1
            order-md-2
            mb-5 mb-md-0
          "
        >
          <div class="text-center">
            <img
              src="@/assets/img/illustrations/lost.png"
              alt="..."
              class="img-fluid"
            >
          </div>
        </div>
        <div class="col-12 col-md-5 col-xl-4 order-md-1 my-5">
          <div class="text-center">
            <h3 class="text-uppercase text-muted mb-4">
              ERRO 404
            </h3>

            <h1 class="display-4 mb-3">
              Alo? Caiu!
            </h1>

            <p class="text-muted mb-4">
              Não encontramos nada por aqui, tem certeza que discou corretamente?
            </p>

            <router-link
              to="/"
              class="btn btn-lg btn-primary"
            >
              VOLTAR PARA O INÍCIO
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>


<style scoped lang="scss">
.login {
  height: 100vh;
}
</style>

