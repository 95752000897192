<template>
  <div>
    <div class="row my-4">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <p class="card-header-title fw-bold">
                  Extrato de crédito
                </p>
              </div>
              <div class="col-auto">
                <input-date-range
                  class="mx-3"
                  :max-interval="31"
                  v-model:start="requiredFilters.start_date"
                  v-model:end="requiredFilters.end_date"
                  preset="month"
                  :time-enabled="true"
                  :seconds-enabled="true"
                  format-date="d/m/Y H:i:S"
                  @close="submit()"
                  data-test="creditCompaniesInterval-button"
                />
              </div>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table mb-0 table-nowrap">
                <thead>
                  <tr>
                    <th class="col">
                      Data
                    </th>
                    <th class="col">
                      Operação
                    </th>
                    <th class="col">
                      Motivo
                    </th>
                    <th class="col">
                      Valor
                    </th>
                    <th class="col">
                      Usuário
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="loading.listCompanyCredit">
                    <td
                      class="p-0 loading"
                      v-for="i in 2"
                      :key="i"
                    >
                      <table-loading :rows="3">
                        <div class="cell col" />
                      </table-loading>
                    </td>
                  </tr>
                  <tr
                    v-else-if="list.length"
                    v-for="(item, id) in list"
                    :key="id"
                  >
                    <td>{{ item.data }}</td>
                    <td>
                      {{ item.value.startsWith("-") ? "Débito" : "Crédito" }}
                    </td>
                    <td>{{ item.readable_reason }}</td>
                    <td>{{ item.value_formatted }}</td>
                    <td>{{ item.user_name }}</td>
                  </tr>
                </tbody>
              </table>
              <div
                class="pb-0"
                v-if="!loading.listCompanyCredit"
              >
                <div class="col-lg-12">
                  <paginator
                    :pagination="pagination"
                    @change-page="changePage"
                    v-if="!loading.listCompanyCredit"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import paginator from "@components/app-paginator";
import moment from "moment";
import InputDateRange from "@components/input-date-range";
import TableLoading from "../../components/table-loading.vue";

export default {
  data() {
    return {
      requiredFilters: {
        start_date: moment()
          .startOf("month")
          .format("DD/MM/YYYY HH:mm:ss"),
        end_date: moment()
          .endOf("day")
          .format("DD/MM/YYYY HH:mm:ss")
      }
    };
  },
  /**
   *
   */

  mounted() {
    this.setCompanyCreditFilters({
      start_date: this.requiredFilters.start_date,
      end_date: this.requiredFilters.end_date,
      company_id: this.$route.params.id
    });
    this.listCompanyCredit();
  },
  /**
   *
   */

  components: {
    InputDateRange,
    TableLoading,
    paginator
  },
  /**
   *
   */

  computed: {
    ...mapGetters("admin/companies", {
      list: "getCompanyCredit",
      loading: "getLoadingFlags",
      pagination: "getCompanyCreditPagination"
    })
  },
  /**
   *
   */
  methods: {
    ...mapMutations("admin/companies", ["setCompanyCreditCurrentPage", "setCompanyCreditFilters"]),
    ...mapActions("admin/companies", ["listCompanyCredit"]),
    /**
     *
     */

    submit() {
      this.setCompanyCreditFilters({
        start_date: this.requiredFilters.start_date,
        end_date: this.requiredFilters.end_date,
        company_id: this.$route.params.id
      });
      this.listCompanyCredit();
    },

    changePage(page) {
      this.setCompanyCreditCurrentPage(page);
      this.setCompanyCreditFilters({
        start_date: this.requiredFilters.start_date,
        end_date: this.requiredFilters.end_date,
        company_id: this.$route.params.id
      });
      this.listCompanyCredit();
    }
  }
};
</script>
