<template>
  <BaseEdge
    :id="id"
    :style="style"
    :path="path[0]"
    :marker-end="markerEnd"
    class="edge-with-button"
  />
  <EdgeLabelRenderer>
    <div
      :style="{
        pointerEvents: 'all',
        position: 'absolute',
        transform: `translate(-50%, -50%) translate(${path[1]}px,${path[2]}px)`,
      }"
      class="nodrag nopan edge-button-container"
      @mouseenter="isButtonHover=true"
      @mouseleave="isButtonHover=false"
    >
      <button
        v-if="hoveredEdge === id || isButtonHover"
        class="edge-custom-button btn btn-secondary d-flex align-items-center justify-content-center"
        @click="removeEdge(id)"
      >
        <i class="fa-regular fa-trash" />
      </button>
    </div>
  </EdgeLabelRenderer>
</template>

<script>
import { BaseEdge,EdgeLabelRenderer, getBezierPath, useVueFlow } from '@vue-flow/core'
import {mapActions, mapGetters} from "vuex";


export default {
  components: {EdgeLabelRenderer, BaseEdge},
  inheritAttrs: false,

  props: {
    id: {
      type: String,
      required: true,
    },
    sourceX: {
      type: Number,
      required: true,
    },
    sourceY: {
      type: Number,
      required: true,
    },
    targetX: {
      type: Number,
      required: true,
    },
    targetY: {
      type: Number,
      required: true,
    },
    sourcePosition: {
      type: String,
      required: true,
    },
    targetPosition: {
      type: String,
      required: true,
    },
    markerEnd: {
      type: String,
      default: '',
    },
    style: {
      type: Object,
      default: null,
    },

  },
  data() {
    const { removeEdges, findEdge } = useVueFlow()
    return {
      removeEdges,
      findEdge,
      isButtonHover:false,
    }
  },

  computed: {
    ...mapGetters("chatbot/actions",{
      hoveredEdge: "getHoveredEdgeId"
    }),
    path() {
      return getBezierPath({
        sourceX: this.sourceX,
        sourceY: this.sourceY,
        targetX: this.targetX,
        targetY: this.targetY,
        sourcePosition: this.sourcePosition,
        targetPosition: this.targetPosition,
      })
    }
  },


  methods: {
    ...mapActions("chatbot/actions",["removeConnection", "updateAction"]),

    removeEdge(id) {
      let deleteEdge = this.findEdge(id)
      let sourceNode = deleteEdge.sourceNode
      let targetNode = deleteEdge.targetNode


      if(sourceNode.data.content.type === 'multiple_choice'){
        deleteEdge.source = deleteEdge.sourceHandle.split("-")[1];
      }

      if(['restart', 'back', 'finish'].includes(targetNode.data.content.type)){
        this.updateAction({
          actionId: deleteEdge.source,
            payload: {
              default_next_action: "",
            }
        })
        this.removeEdges(id)
        return;
      }
      if(targetNode.data.content.type === 'transfer'){
        this.updateAction({
          actionId: sourceNode.id,
          payload: {
            transfer_to_group_channel_id: "",
          }
        })
        this.removeEdges(id)
        return;
      }

      let payload = {
        previous_action_id: deleteEdge.source,
        next_action_id: deleteEdge.target
      }
      this.removeConnection({payload});
      this.removeEdges(id)
    }
  }
}
</script>
<style lang="scss">
.edge-button-container{
  z-index: 2;
}
.edge-custom-button {
  border-radius:10px;
  background: #FFF;
  cursor:pointer;
  border:  1px solid  #E1E9F4;
  color: #677C92;
  width: 32px;
  height: 32px;
}

.vue-flow__edge .vue-flow__edge-path {
  stroke: #AFAFAF;
  stroke-width: 2;
}

.vue-flow__edge:hover .vue-flow__edge-path {
  stroke: #5778F5 !important;
}

.edge-custom-button {
  &:hover {
      opacity: 1!important;
      background: #FFF;
      border: 1px solid #E1E9F4;
      color: #373753;

  }
}
.vue-flow__handle {
  pointer-events: auto !important;
  transition: height 0.3s ease, width 0.3s ease;

  &:hover {
    height: 16px;
    width: 16px;
  }
}

</style>