export default {
  getLoadingFlags: state => state.loadingFlags,

  getInstanceParams: state =>
    Object.assign(state.instanceFilters, {
      include: state.instanceIncludes.join(",")
    }),

  getStrategySettings: state => state.strategySettings,
  isLoading: state => state.loading,
  getList: state => state.list,
  getFilters: state => state.filters,
  getPagination: state => state.pagination,
  getErrors: state => state.errors,
  getInstance: state => state.instance,
  getTeamsList: state => state.teamsList,
  getInstanceStatus: state => state.instanceStatus,
  getLoadingContext: state => state.loadingContext,
  getWhatsappQrCode: state => state.whatsappQrCode,
  getWhatsappMetrics: state => state.metrics,
  getOfficeHours: state => state.officeHours,
  getInstanceId: state => state.instance_id,

  getActiveInstances: state => state.instancesData.active,
  getDeletedInstances: state => state.instances.filter(instance => instance.status === "deleted"),

  getEditTabRef: state => state.editTabRef,

  getInstances: state => state.instancesData,
  getInstancesPagination: state => state.instancesPagination,

  getAgents: state => state.agents,
  getAgentsPagination: state => state.agentsPagination,

  getGroupChannelAgents: state => state.groupChannelAgents,
  getGroupChannelAgentsPagination: state => state.groupChannelAgentsPagination,

  getTotalActiveInstances: state => state.totalActiveInstances,

  getOpenAiSettings: state => state.openAiSettings,
  getAgentsSelect: state => state.agentsSelect
};
