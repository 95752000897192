<template>
  <div class="card-body">
    <div class="form-group mb-0">
      <label class="form-label required">{{ $t("capitalizes.name") }}</label>
      <input
        type="text"
        class="border-custom form-control"
        :class="{
          'is-invalid': errors.name,
        }"
        :value="name"
        @input="$emit('update:name', $event.target.value)"
        :placeholder="$t('capitalizes.name')"
      >

      <div class="invalid-feedback">
        {{ errors.name && errors.name[0] }}
      </div>
    </div>
  </div>
  <div class="card-body border-top d-flex flex-wrap justify-content-center">
    <div class="w-100">
      <span class="d-inline-block margin-custom">
        {{ $t("how_do_you_want_to_add_audio") }}
      </span>
      <div class="col-12 d-flex">
        <div
          class="d-flex justify-content-between col-12 card-option"
          v-for="(option, key) in optionsSound"
          :key="key"
        >
          <h5
            class="w-100 mb-0"
            :for="option.id"
          >
            <i
              class="icons-custom"
              :class="option.icon"
            />
            {{ option.label }}
          </h5>
          <input
            type="radio"
            :id="option.id"
            :checked="option.id === typeAudio"
            :value="option.id"
            @input="$emit('update:typeAudio', option.id)"
          >
        </div>
      </div>
    </div>
    <button
      class="align-self-center btn px-4 mt-4 col-6 h-auto py-1"
      @click="$emit('toggleCollapse')"
      :class="typeAudio && name ?'btn-primary':'btn-light' "
      :disabled="!typeAudio || !name"
    >
      {{ $t("continue_to_upload") }}
    </button>
  </div>
</template>
<script>
export default {
  name: "GeneralSettings",
  props: {
    name: {
      type: String,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    typeAudio: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      optionsSound: [
        {
          id: "import",
          label: this.$t("import_audio"),
          icon: "far fa-up-from-bracket tx-blue-300",
        },
      ],
    };
  },

  emits: ["update:name", "update:typeAudio", "toggleCollapse"],
}
</script>
<style scoped lang="scss">
.card-body {
  padding: 1rem 1.5rem 1.5rem 1.5rem;
}

.border-top {
  border-top: 1px solid $color-gray-blue-300 !important;
}

.card-option {
  border: 1px solid $color-gray-blue-300 !important;
  border-radius: 0.625rem;
  padding: 0.5rem 1rem;
}

.margin-custom {
  margin-bottom: 0.5rem;
}

.icons-custom {
  padding-right: 0.5rem;
}

</style>