<template>
  <div>
    <doughnut-chart
      v-if="metrics"
      :title="onlineAgentsChart.title"
      :chart-data="[
        metrics.agents.status_count[AgentStatus.IDLE],
        metrics.agents.status_count[AgentStatus.ON_CALL],
        metrics.agents.status_count[AgentStatus.ACW],
        metrics.agents.status_count[AgentStatus.ON_MANUAL_CALL],
        metrics.agents.status_count[AgentStatus.ON_MANUAL_CALL_ACW],
        metrics.agents.status_count[AgentStatus.ON_WORK_BREAK],
      ]"
      :labels="onlineAgentsChart.labels"
      :colors="onlineAgentsChart.colors"
    >
      <template #empty-state>
        <empty-state
          class="row mx-auto px-5 card-fill"
          icon="agents.svg"
          :title="$t('empty_agents_online')"
          align-text="text-center"
          :sub-title="$t('show_chart_when_agent_online')"
        />
      </template>
    </doughnut-chart>
    <card-loading
      v-else
      height="350"
    />
  </div>
</template>
<script>
import EmptyState from "@components/empty-state.vue";
import DoughnutChart from "@dialer/manager/components/doughnut-chart.vue";
import CardLoading from "@components/card-loading.vue";
import { AgentStatus } from "@/utils";

export default {
  name: "AgentsChart",
  components: { CardLoading, DoughnutChart, EmptyState },
  props: {
    onlineAgentsChart: {
      type: Object,
      required: true,
    },
    activeMetric: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      AgentStatus,
    }
  },
  computed: {
    metrics() {
      return this.activeMetric
    },
  },
}
</script>
