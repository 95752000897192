<template>
  <div style="min-width: 308px">
    <single-select
      :placeholder="$t('select')"
      :list-data="teamUsers"
      label="name"
      return-all-object
      v-model="selectedContact"
      :enable-reset-select="false"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SingleSelect from "@components/select/single-select.vue";

export default {
  components: {SingleSelect},
  emits: ["contactSelected"],

  props: {
    teamUsers: {
      type: Array,
      required: true
    },
    edit: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selectedContact: null,
      copiedContact: null,
    }
  },

  mounted() {
    if (this.edit) {
      this.selectedContact = this.teamUsers.find((user) => user.id === this.opportunity.agent_id);
      this.copiedContact =  this.selectedContact;
    }
  },

  computed: {
    ...mapGetters("crm/opportunities", {
      opportunity: "getOpportunity",
      errors: "getErrors"
    }),
    ...mapGetters("crm/funnels", {
      currentFunnel: "getCurrentFunnel"
    }),
  },

  watch: {
    selectedContact(val) {
      if (val) {
        if (val === this.copiedContact) {
          return
        }
        this.$emit('contactSelected', val.id)
        this.copiedContact = val;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.responsible-dropdown {
  background-color: $color-gray-blue-300;
  color: $color-text-solid-black;
  text-align: end;
}

.responsible-dropdown, .dropdown-menu {
  width: 326px;
}

.dropdown-menu {
  max-height: 540px;
  overflow-y: scroll;
}

.gray-placeholder {
  &::placeholder {
    color: $color-text-gray;
  }
}
</style>
