<template>
  <div class="main-content">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <header-component
          :title="$tc('capitalizes.qualifications', 0)"
          :pretitle="$t('uppercases.reports')"
          redirect-link="/manager/reports"
        />

        <form @submit.prevent="submit()">
          <div class="row">
            <div class="col-lg-3">
              <div class="form-group">
                <label
                  for="startDate"
                  class="form-label"
                >
                  {{ $t('period') }}
                </label>
                <input-date-range
                  preset="month"
                  v-model:start="startDate"
                  v-model:end="endDate"
                />
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <label
                  for="campaign"
                  class="form-label required"
                >
                  {{ $t('campaign_capitalize') }}
                </label>

                <select-campaign v-model="campaign" />
              </div>
            </div>
            <div class="col-lg-3">
              <div class="form-group">
                <label
                  for="campaign"
                  class="form-label"
                >
                  {{ $t('agent_capitalize') }}
                </label>
                <select-agent v-model="agent" />
              </div>
            </div>
            <div class="col-lg-2">
              <label class="form-label">&nbsp;</label>
              <div>
                <app-button
                  class="form-control"
                  :loading="loading.searchQualifications || loading.findDeliveredCalls"
                >
                  {{ $t('search_report') }}
                </app-button>
              </div>
            </div>
          </div>
        </form>
        <div
          class="tab-pane fade show active col-lg-8"
          id="contactsListPane"
          role="tabpanel"
          aria-labelledby="contactsListTab"
        >
          <div
            class="card"
            id="usersList "
          >
            <div class="card-header">
              <h4 class="card-header-title fw-normal">
                {{ $t('qualifications_list') }}
              </h4>

              <ul class="nav nav-tabs nav-tabs-sm card-header-tabs">
                <li class="nav-item">
                  <span class="fs-3">{{ this.list.calls }}</span>
                </li>
              </ul>
              <div class="col-auto mx-3">
                <a
                  href="#"
                  class="btn btn-outline-secondary"
                  @click="generateCSVModal.show()"
                >{{ $t('download_CSV') }}</a>
              </div>
            </div>
            <div class="card-body px-0 py-0">
              <div class="tab-content">
                <div
                  role="tabpanel"
                  id="actives"
                  class="table-responsive tab-pane active"
                >
                  <table class="table table-hover table-nowrap card-table">
                    <thead>
                      <tr>
                        <th>
                          <a
                            class="list-sort text-muted fw-normal"
                            href="#"
                          >{{ $t('uppercases.name') }}</a>
                        </th>
                        <th>
                          <a
                            class="list-sort text-muted fw-normal"
                            href="#"
                          >{{ $t('total_uppercase') }}</a>
                        </th>
                        <th>
                          <a
                            class="list-sort text-muted fw-normal"
                            href="#"
                          >{{ $t('uppercases.percent') }}</a>
                        </th>
                      </tr>
                    </thead>

                    <tbody class="list fs-base">
                      <tr
                        v-for="item in list.qualifications"
                        :key="item.id"
                      >
                        <td>
                          <span
                            class="chart-legend-indicator"
                            :style="'background-color: ' + item.color"
                          />
                          {{ item.name }}
                        </td>
                        <td>{{ item.total }}</td>
                        <td>
                          <progressbar-multiple
                            style="height: 6px"
                            :data="[
                              {
                                color: item.color,
                                percentage: (item.total / this.list.calls) * 100,
                                count: item.total,
                                title: item.name
                              }
                            ]"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade show active col-lg-4 px-5"
          id="contactsListPane"
          role="tabpanel"
          aria-labelledby="contactsListTab"
        >
          <div class="card">
            <div class="card-header">
              <h4 class="card-header-title fw-normal">
                {{ $t('qualification_metrics') }}
              </h4>
            </div>
            <div class="card-body">
              <div class="row d-flex text-center align-items-center border-bottom">
                <h1 v-if="list && list.calls">
                  {{ ((sumData("converted") / list.calls) * 100 || 0).toFixed(1) + "%" }}
                </h1>
                <h1 v-else>
                  0.0%
                </h1>
                <h2>
                  {{ $t('converted') }}
                </h2>
              </div>
              <div class="d-flex justify-content-between border-bottom py-3">
                <span>
                  {{ $t('others') }}
                </span>
                <span
                  class="text-muted"
                  v-if="list && list.calls"
                >
                  {{ list.calls - sumData("dmc") - sumData("converted") - sumData("unknown") }}
                </span>
                <span
                  class="text-muted"
                  v-else
                >0</span>
              </div>
              <div class="d-flex justify-content-between border-bottom py-3">
                <span>
                  {{ $t('crp') }}
                </span>
                <span class="text-muted">
                  {{ sumData("dmc") }}
                </span>
              </div>
              <div class="d-flex justify-content-between border-bottom py-3">
                <span>
                  {{ $t('unknown_capitalize') }}
                </span>
                <span class="text-muted">
                  {{ sumData("unknown") }}
                </span>
              </div>
              <div class="d-flex justify-content-between py-3">
                <span>
                  {{ $t('cpc_plus_conversion') }}
                </span>
                <span class="text-muted">
                  {{ sumData("converted") }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="modalDownloadCSV"
      tabindex="-1"
      aria-labelledby="modalDownloadCSVLabel"
      aria-hidden="true"
      ref="generateCSVModal"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h3
              class="modal-title"
              id="modalDownloadCSVLabel"
            >
              {{ $t('generate_report') }}
            </h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div class="modal-body">
            <p>
              {{ $t('send_report_to_email') }}
            </p>
            <form
              @submit.prevent="generateCSV"
              id="formCSV"
            >
              <div class="mb-3">
                <label
                  for="input-email"
                  class="col-form-label"
                >{{ $t('report_email') }}</label>
                <input
                  type="email"
                  class="form-control"
                  id="input-email"
                  required
                  v-model="email"
                >
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              {{ $t('cancel_general') }}
            </button>
            <app-button
              form="formCSV"
              :loading="loading.downloadTable"
              style="min-width: 6.5rem;"
            >
              {{ $t('generate_csv') }}
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appButton from "@/components/app-button.vue";
import inputDateRange from "@/components/input-date-range.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ProgressbarMultiple from "@components/progressbar-multiple";
import SelectCampaign from "@dialer/manager/components/select-campaign.vue";
import SelectAgent from "@dialer/manager/components/select-agent.vue";
import HeaderComponent from "@/components/header-component.vue";
import { Modal } from "bootstrap";

export default {
  data() {
    return {
      errorTimeCourse: "",
      campaigns: {},
      campaign: 0,
      agents: [],
      agent: "",
      startDate: "",
      endDate: "",
      deliveredCallsData: [],
      email: "",
      generateCSVModal: null
    };
  },

  components: {
    appButton,
    inputDateRange,
    ProgressbarMultiple,
    SelectCampaign,
    SelectAgent,
    HeaderComponent
  },

  methods: {
    ...mapActions("manager/qualification_report", ["searchQualifications", "findDeliveredCalls", "downloadTable"]),
    ...mapActions("manager/filters", ["findFilters"]),
    ...mapMutations("manager/filters", ["setIncludes"]),

    updateStartDate(date) {
      this.startDate = date;
    },

    updateEndDate(date) {
      this.endDate = date;
    },

    submit() {
      this.searchQualifications({
        startDate: this.startDate,
        endDate: this.endDate,
        campaign_id: this.campaign,
        agent_id: this.agent
      }).then(() => {
        this.showSearchMessage = false;
      });
      this.findDeliveredCalls({
        startDate: this.startDate + " 00:00:00",
        endDate: this.endDate + " 23:59:59",
        campaign_id: this.campaign,
        agent_id: this.agent
      }).then(() => {
        this.showSearchMessage = false;
        this.deliveredCallsData = this.delivered_calls;
      });
    },

    toPercentage(total, num) {
      return Math.floor((num / total) * 100).toString();
    },

    sumData(data) {
      return this.deliveredCallsData.reduce((a, b) => b[data] + a, 0).toString();
    },

    generateCSV() {
      this.downloadTable({
        startDate: this.startDate,
        endDate: this.endDate,
        campaignId: this.campaign,
        agentId: this.agent,
        email: this.email
      }).then(() => this.generateCSVModal.hide());
    }
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser"
    }),
    ...mapGetters("manager/filters", {
      filters: "getFilters"
    }),
    ...mapGetters("manager/qualification_report", {
      list: "getList",
      loading: "getLoadingFlags",
      delivered_calls: "getDeliveredCalls"
    })
  },

  watch: {
    filters: {
      handler(data) {
        if (data.agents) {
          this.campaigns = data.campaigns;
          this.agents = data.agents;
        }
      },
      deep: true
    }
  },

  mounted() {
    this.setIncludes(["agents"]);
    this.findFilters();
    this.email = this.user.email;
    this.generateCSVModal = new Modal(this.$refs.generateCSVModal);
  }
};
</script>

<style lang="scss" scoped>
.header-body {
  border-bottom: none;
}

.rounded-calendar {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.table > :not(:first-child) {
  border-top: none !important;
}
</style>
