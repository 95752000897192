<template>
  <div class="container-fluid pb-4">
    <header-component
      :pretitle="$t('settings_uppercase')"
      redirect-link="/manager/ivrs"
      :title="$t('new_active_ivr')"
    />
    <form-active-ivr
      :fields="fields"
      @update:fields="fields = $event"
      @submit="submit"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import HeaderComponent from "@/components/header-component.vue";
import FormActiveIvr from "@dialer/manager/pages/settings/ivrs/form-active-ivr.vue";

export default {
  components: {
    FormActiveIvr,
    HeaderComponent,
  },

  data() {
    return {
      fields: {
        name: "",
        office_hour: null,
        type_audio: "import",
        audio: null,
        wait_time: 0,
        keys: Array(10).fill(false),
        redirect_type: null,
        instances: [],
      },
      WABA_INSTANCE_TYPE_ID: 2,
      ULTRAMSG_INSTANCE_TYPE_ID: 1,
      OMNI_REDIRECT_ID: 2,
    };
  },
  methods: {
    ...mapActions("manager/ivrs", ["createIvr"]),

    createFormData() {
      let formData = new FormData();

      formData.append("name", this.fields.name);
      formData.append("wait_time", this.fields.wait_time);
      formData.append("redirect_type", this.fields.redirect_type);

      if (this.fields.audio) {
        formData.append("audio", this.fields.audio);
      }

      if (this.fields.office_hour !== null) {
        formData.append("office_hour_id", this.fields.office_hour);
      }

      for (let item of this.fields.keys) {
        formData.append("keys[]", item);
      }

      if (this.fields.redirect_type === this.OMNI_REDIRECT_ID) {
        this.fields.instances.forEach((instance, index) => {
          formData.append(`instances[${index}][instance_id]`, instance.instance_id);
          formData.append(`instances[${index}][group_channel_id]`, instance.group_channel_id);
          formData.append(`instances[${index}][type]`, instance.type);
          if (instance.type === this.ULTRAMSG_INSTANCE_TYPE_ID) {
            formData.append(`instances[${index}][quick_message_id]`, instance.quick_message_id);
            formData.append(`instances[${index}][message]`, instance.message);
          }
          if (instance.type === this.WABA_INSTANCE_TYPE_ID) {
            formData.append(`instances[${index}][template_id]`, instance.template_id);
            formData.append(`instances[${index}][template][]`, []);
          }
        });
      }

      return formData;
    },

    submit() {
      let formData = this.createFormData();
      this.createIvr({ formData }).then(() => {
        this.$router.push({ path: `/manager/ivrs` })
      })
    },
  },
};
</script>
