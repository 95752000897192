import EARNINGS_CHART_QUERY from "@/graphql/earningsChart.query.gql"
import apolloClient from "@/plugins/apolloClient";

export default {

  async fetchBooks({commit}) {
    const {data, loading, networkStatus} = await apolloClient.query({
      query: EARNINGS_CHART_QUERY,
      variables: {search: 'the'}
    });

    console.log(data, loading, networkStatus)

    commit('setBooks', data.earningsChart);
    // commit("startLoading", "books");
    // const { result } = useQuery(ALL_BOOKS_QUERY);
    // console.log(result)
    // commit("setBooks", result.value)
    //
    // commit("stopLoading", "books");

    // return new Promise((resolve, reject) => {
    //   crmApiAxios
    //     .post("http://localhost:4000/graphql/", {
    //       query: query,
    //       variables: {
    //         search: 'the',
    //       }
    //     }, {
    //       headers: {
    //         'Content-Type': 'application/json'
    //       }
    //     })
    //     .then(response => {
    //       commit("setBooks", response.data.data);
    //       resolve();
    //     })
    //     .catch(response => {
    //       commit("system/setErrorMessage", response.response.data, {root: true});
    //       reject();
    //     })
    //     .finally(() => {
    //       commit("stopLoading", "books");
    //     });
    // });
  },
}
