import store from "@store";

import IndexReceptiveQueue from "@dialer/manager/pages/dashboards/receptive/index-receptive-queue";
import ReceptiveQueue from "@dialer/manager/pages/dashboards/receptive/receptive-queue";
import ShowReceptiveQueue from "@dialer/manager/pages/dashboards/receptive/show-receptive-queue";
import ReceptiveQueueForm from "@dialer/manager/pages/dashboards/receptive/receptive-queue-form";

export default {
  path: "receptive-queue",
  name: "receptive-queue",
  components: {
    main: IndexReceptiveQueue
  },
  children: [
    {
      path: "",
      components: {
        "receptive-queue": ReceptiveQueue
      }
    },
    {
      path: "new",
      components: {
        "receptive-queue": ReceptiveQueueForm,
      },
      beforeEnter: (to, from, next) => {
        if (store.getters["auth/isManager"]) next();
        else next({
          name: "not-found",
          params: {
            pathMatch: to.path.split('/').slice(1)
          }
        });
      }
    },
    {
      path: ":id",
      components: {
        "receptive-queue": ShowReceptiveQueue
      },
      meta: {
        "short-side-bar": true
      },
    },
    {
      path: ":id/edit",
      components: {
        "receptive-queue": ReceptiveQueueForm
      },
      meta: {
        "short-side-bar": true
      },
    },
  ]
};
