<template>
  <div class="main-content h-100">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div
        class="card fa-card"
        style="width: 628px;"
      >
        <div
          class="card-header p-0"
          style="margin-bottom: 32px;"
        >
          <h1 class="text-center m-0">
            Parabéns, sua conta está mais segura
          </h1>
        </div>
        <div class="card-body p-0">
          <div class="text-center">
            <h4>A verificação em duas etapas está ativada</h4>
            <h5
              class="fw-normal mx-6 tx-text-gray"
              style="margin-bottom: 32px;"
            >
              Um código de login será solicitado regularmente para garantir a segurança de sua conta
            </h5>
          </div>
          <div class="text-center">
            <app-button
              color="btn-primary"
              class="col-7 my-3 text-center"
              :loading="loadingFlags.login"
              :blocked="loadingFlags.login"
              @click="authentication"
            >
              Concluir
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import AppButton from "@/components/app-button.vue";

export default {
  components: {
    AppButton
  },

  computed: {
    ...mapGetters("auth", {
      hasTwoFa: "hasTwoFa",
      authenticated: "isAuthenticated",
      isSupervisorOrManager: "isSupervisorOrManager",
      isAdmin: "isAdmin",
      userLogin: "getUserLogin",
      loadingFlags: "getLoadingFlags",
      isPartner: "isPartner"
    })
  },

  mounted() {
    window.addEventListener("keydown", this.handleKeyDown);
  },

  beforeUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
  },
  watch: {
    authenticated(authenticated) {
      if (authenticated) {
        if (this.isAgent) return this.$router.push(this.onlyHaveWhatsapp ? "/whatsapp" : "/agent");

        if (this.isSupervisorOrManager) {
          this.$router.push("/manager");

          this.setIncludes(["campaigns-landline-routes", "campaigns-mobile-routes", "company"]);
          return this.loadRoutes();
        }

        if (this.isAdmin) return this.$router.push("/admin");
        if (this.isPartner) return this.$router.push("/partner");
      }
    }
  },

  methods: {
    ...mapActions("auth", ["login"]),
    ...mapActions("manager/voice_3c", ["loadRoutes"]),
    ...mapMutations("manager/voice_3c", ["setIncludes"]),

    authentication() {
      this.login({
        user: this.userLogin.user,
        password: this.userLogin.password,
        token_type: this.userLogin.token_type,
        location_identifier: this.hasTwoFa.location_identifier
      });
    },

    handleKeyDown(event) {
      if (event.key === "Enter") {
        this.authentication();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.fa-card {
  box-shadow: 0px 4px 18px 0px rgba(34, 54, 77, 0.12);
  z-index: 1050 !important;
  padding: 32px;
}
</style>
