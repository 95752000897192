<template>
  <div class="main-content">
    <div class="container-fluid pb-6">
      <header-component
        :title="$t('intervals')"
        :pretitle="$t('uppercases.reports')"
        redirect-link="/manager/reports"
      />
      <form
        @submit.prevent="submit()"
        novalidate
      >
        <div class="row">
          <div class="col-lg-3">
            <div class="form-group">
              <label
                for="startDate"
                class="form-label"
              >
                {{ $t('period') }}
              </label>
              <input-date-range
                v-model:start="startDate"
                v-model:end="endDate"
                :time-enabled="true"
                :seconds-enabled="true"
                format-date="d/m/Y H:i:S"
              />
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group">
              <label
                for="campaign"
                class="form-label"
              >
                {{ $t('campaign_capitalize') }}
              </label>
              <select-campaign v-model="campaign" />
            </div>
          </div>

          <div class="col-lg-3 d-flex align-items-center mt-4">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="exceeded"
                id="showTimeoutIntervals"
              >
              <label
                class="form-check-label"
                for="showTimeoutIntervals"
              >
                {{ $t('show_only_exceeded_limit_intervals') }}
              </label>
            </div>
          </div>
          <div class="col-lg-2 ms-auto">
            <label class="form-label">&nbsp;</label>
            <div>
              <app-button
                class="form-control"
                :loading="loadingFlags.searchIntervals"
                :disabled="!campaign"
              >
                {{ $t('search_report') }}
              </app-button>
            </div>
          </div>
        </div>
      </form>

      <all-intervals-table
        :show-search-message="showSearchMessage"
        :campaign="campaign"
        :start-date="startDate"
        :end-date="endDate"
        :exceeded="exceeded"
      />

      <consolidated-intervals />
      <progress-chart
        v-if="totalTimeDatasets.length"
        class="mt-4"
        :title="$t('total_time_graph')"
        :format-ticks="formatSecondsToMMSS"
        :labels="tableAgent"
        :datasets="totalTimeDatasets"
      />

      <card-loading
        v-if="loadingFlags.searchIntervals"
        :height="200"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AppButton from "@/components/app-button.vue";
import InputDateRange from "@/components/input-date-range.vue";
import ProgressChart from "@dialer/manager/components/progress-chart.vue";
import SelectCampaign from "@dialer/manager/components/select-campaign.vue";
import HeaderComponent from "@/components/header-component.vue";
import CardLoading from "@/components/card-loading.vue";
import AllIntervalsTable from "@dialer/manager/pages/reports/intervals/components/all-intervals-table.vue";
import ConsolidatedIntervals from "@dialer/manager/pages/reports/intervals/components/consolidated-intervals.vue";
import { formatSecondsToMMSS } from "@/utils.js";

export default {
  data() {
    return {
      showSearchMessage: true,
      campaigns: {},
      campaign: 0,
      startDate: "",
      endDate: "",
      exceeded: false,
      tableAgent: [],
      totalTimeDatasets: []
    };
  },
  components: {
    AppButton,
    InputDateRange,
    ProgressChart,
    SelectCampaign,
    HeaderComponent,
    CardLoading,
    AllIntervalsTable,
    ConsolidatedIntervals
  },
  mounted() {
    this.findFilters();
  },
  computed: {
    ...mapGetters("manager/filters", {
      filters: "getFilters"
    }),
    ...mapGetters("manager/intervals_report", {
      list: "getList",
      loadingFlags: "getLoadingFlags"
    })
  },
  watch: {
    list() {
      this.totalTimeDatasets = this.realTimeDatasets();
    }
  },
  methods: {
    ...mapActions("manager/intervals_report", ["searchIntervals", "downloadTable"]),
    ...mapActions("manager/filters", ["findFilters"]),
    formatSecondsToMMSS,
    realTimeDatasets() {
      const headerLabelArray = {};
      this.tableAgent = this.list.map(agent => {
        return agent.name;
      });

      this.list.forEach((agent, index) => {
        agent.intervals.forEach(interval => {
          const { name, color } = interval;
          headerLabelArray[name] = headerLabelArray[name] || {
            label: name,
            data: Array(this.list.length).fill(0),
            color: color,
            backgroundColor: color,
            borderRadius: 0
          };
          headerLabelArray[name].data[index] = interval.duration;
        });
      });
      return Object.values(headerLabelArray);
    },
    submit() {
      this.searchIntervals({
        startDate: this.startDate,
        endDate: this.endDate,
        campaign_id: this.campaign,
        exceeded: Number(this.exceeded),
      }).then(() => {
        this.showSearchMessage = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.header-body {
  border-bottom: none;
}

.rounded-calendar {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.table > :not(:first-child) {
  border-top: none !important;
}

td {
  height: 40px !important;
  padding: 8px 16px !important;
}

.card-empty-state {
  height: 200px;
}
</style>
