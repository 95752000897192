<template>
  <hover-button
    @click="enterManualCallACW"
    background-color="var(--bs-white)"
    icon="fa-kit fa-key-mobile"
    :disabled="loadingFlags.enterManualCallACW"
    :text="$t('manual_act')"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import HoverButton from '@dialer/agent/components/hover-button.vue';

export default {
  name: "ManualAcwButton",
  components: {
    HoverButton
  },

  computed: {
    ...mapGetters("system", {
      loadingFlags: "getLoadingFlags",
    }),
  },

  methods: {
    ...mapActions("system", ["enterManualCallACW"]),
  }
}
</script>
