<template>
  <div class="container-fluid">
    <router-view
      :key="$route.fullPath"
      name="crm"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  created() {
    this.initializeFunnel();
  },

  methods: {
    ...mapActions('crm/funnels', ['fetchFunnels']),
    ...mapActions('crm/contacts', ['fetchUsersByTeam']),
    ...mapActions('crm/tour', ['fetchTourSteps']),
    ...mapMutations('crm/tour', ['setStartTour']),

    initializeFunnel() {
      this.fetchFunnels(this.$route.params.id).then((currentFunnel) => {
        if (currentFunnel) {
          if(this.isManager) {
            this.setStartTour(true)
          }
          this.fetchUsersByTeam({ teamId: currentFunnel.team.id, search: "" }).then(() => {
            if (!this.isManager) {
              this.$router.push({ path: `/manager/crm/${currentFunnel.id}` });
            }
          });
        } else if (!this.isManager) {
          this.$router.push({ path: `/manager/crm/empty` });
        }
        if (this.isManager && (this.$route.path === "/manager/crm" || this.$route.path === "/manager/crm/home")) {
          this.checkTourSteps();
        }
      });
    },

    async checkTourSteps() {
      if(this.$route.path.includes('dashboard')) {
        return
      }

      if (!this.tourSteps) {
        await this.fetchTourSteps()
      }
        this.$router.push({ path: this.startTour ? `/manager/crm/home` : `/manager/crm/empty` });
    }
  },

  computed: {
    ...mapGetters('crm/tour', {startTour: "getStartTour", tourSteps: "getTourSteps"}),
    ...mapGetters('auth', {isManager: "isManager"})
  },
}
</script>
