<template>
  <p
    v-if="!intervals"
    class="mb-0 tx-text-gray"
  >
    <i class="fal fa-minus" />
  </p>
  <VTooltip v-else>
    <p class="mb-0 tx-text-gray interval-title">
      {{ duration ? formatSecondsToMMSS(duration) : "-" }}
    </p>
    <template #popper>
      <div>
        <p class="tx-text-200">
          {{ title }}
        </p>
        <div>
          <ul
            class="p-0 m-0"
            v-if="intervals"
          >
            <li
              v-for="(interval, index) in intervals"
              :key="index"
            >
              <div
                v-if="index !== 0"
                class="w-100 border-bottom"
                style="margin: 8px 0;"
              />
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center me-3">
                  <dot-status
                    size="8px"
                    :color="interval.color"
                    style="margin-right: 16px;"
                  />
                  <span class="mb-0 text-medium">
                    {{ interval.name }}
                  </span>
                </div>
                <span class="mb-0 tx-text-gray text-medium">
                  {{ formatSecondsToMMSS(interval.duration) }}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </VTooltip>
</template>

<script>
import DotStatus from "@components/dot-status.vue";
import { formatSecondsToMMSS } from "@/utils.js";

export default {
  name: "IntervalTooltipCard",
  components: { DotStatus },
  data() {
    return {
      minutes: 1000
    };
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    intervals: {
      type: Object,
      default: () => {}
    },
    duration: {
      type: Number,
      default: 0
    }
  },
  methods: {
    formatSecondsToMMSS
  }
};
</script>

<style scoped lang="scss">
ul {
  list-style-type: none;
}

.interval-title {
  cursor: default;
}
</style>

<style lang="scss">
.v-popper--theme-tooltip {
  .v-popper__wrapper {
    .v-popper__inner {
      color: $color-text-200;
      background-color: $color-text-light;
      box-shadow: 0px 4px 18px 0px rgba(77, 72, 34, 0.12);
      padding: 16px;
    }
  }

  .v-popper__arrow-container {
    display: none;
  }
}
</style>
