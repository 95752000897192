<template>
  <div class="col-12 col-xl-6">
    <div class="card">
      <div class="card-header">
        <h4 class="card-header-title">
          {{ $tc('capitalizes.agent', 2) }}
        </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <template
            v-for="status in sortedStatus"
            :key="status"
          >
            <div
              v-if="status !== AgentStatus.OFFLINE"
              class="col-12 col-lg-6 col-xl agent-queue-status p-0 text-truncate"
            >
              <p class="number-status">
                {{ status.count }}
              </p>
              <div class="d-flex align-items-center">
                <span :class="'me-1 dot-status bg-' + status_labels[status.id].text" />
                {{ status_labels[status.id].readable }}
              </div>
            </div>
          </template>
        </div>
        <div class="table-responsive mt-4">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">
                  {{ $t('uppercases.name') }}
                </th>
                <th scope="col">
                  {{ $t('uppercases.status') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="agent in agents"
                :key="agent.id"
              >
                <tr v-if="agent.status != AgentStatus.OFFLINE">
                  <td>{{ agent.extension }} - {{ agent.name }}</td>
                  <td
                    class="d-flex align-items-center"
                    style="min-width: 12rem;"
                  >
                    <div :class="('me-2 dot-status bg-' + status_labels[agent.status].text)" />
                    {{ status_labels[agent.status].readable }}
                    <span class="ms-1">
                      {{ convertSecondsToTime(agent.counter) }}
                    </span>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div
        class="card-footer text-center"
        v-if="!toggle_offline_agents"
      >
        <span class="fe fe-chevron-down text-primary" />
        <button
          class="btn-show-agents"
          @click="toggle_offline_agents = !toggle_offline_agents"
        >
          {{ $t('capitalizes.show') }} {{ status_count[AgentStatus.OFFLINE] }} {{ $t('agents') }}
          {{ $t('offline') }}
        </button>
      </div>
      <div
        class="card-footer text-center"
        v-if="toggle_offline_agents"
      >
        <span class="fe fe-chevron-up text-primary" />
        <button
          class="btn-show-agents"
          @click="toggle_offline_agents = !toggle_offline_agents"
        >
          {{ $t('capitalizes.hide') }} {{ status_count[AgentStatus.OFFLINE] }} {{ $t('agents') }}
        </button>
      </div>
    </div>
    <div
      class="card"
      v-if="toggle_offline_agents"
    >
      <div class="card-body">
        <div class="table-responsive mt-4">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">
                  {{ $t('uppercases.name') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="item in agents"
                :key="item.id"
              >
                <tr>
                  <td
                    v-if="item.status == 0"
                    class="text-secondary"
                  >
                    {{ item.extension }} - {{ item.name }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AgentStatus, convertSecondsToTime } from "@/utils.js";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      AgentStatus,
      toggle_offline_agents: false,
      status_labels: {
        [AgentStatus.IDLE]: { text: "idle", readable: this.$t('capitalizes.idle') },
        [AgentStatus.OFFLINE]: { text: "offline", readable: this.$t('capitalizes.offline')},
        [AgentStatus.ONLINE]: { text: "online", readable: this.$t('capitalizes.online') },
        [AgentStatus.ON_CALL]: { text: "speaking", readable: this.$t('capitalizes.talking') },
        [AgentStatus.ON_MANUAL_CALL]: { text: "manual", readable: this.$t('capitalizes.manual')  },
        [AgentStatus.ON_MANUAL_CALL_ACW]: { text: "manual-acw", readable: this.$t('acronym.mact')  },
        [AgentStatus.ACW]: { text: "acw", readable: this.$t('acronym.act')  },
        [AgentStatus.ON_WORK_BREAK]: { text: "work-break", readable: this.$t('capitalizes.break')  },
      },
    };
  },
  computed: {
    ...mapGetters("manager/receptive_agents", {
      status_count: "getStatusCount",
      agents: "getAgents",
      total: "getTotal",
    }),
    ...mapGetters("auth", {
      user: "getUser"
    }),
    sortedStatus() {
      let sorting = [
        AgentStatus.IDLE,
        AgentStatus.ON_CALL,
        AgentStatus.ACW,
        AgentStatus.ON_MANUAL_CALL_ACW,
        AgentStatus.ON_MANUAL_CALL,
        AgentStatus.ON_WORK_BREAK,
        AgentStatus.ONLINE
      ];
      let result = [];

      sorting.forEach(key => {
        if (this.status_count[key] !== undefined) {
          result.push({
            id: key,
            count: this.status_count[key]
          });
        }
      });

      return result;
    }
  },
  methods: {
    ...mapActions("manager/receptive_agents", ["findAgentStatus"]),
    convertSecondsToTime,
  },
  mounted() {
    this.findAgentStatus({ id: this.$router.currentRoute._rawValue.params.id });
  },
};
</script>
<style scoped lang="scss">
.number-status {
  font-size: 1.5rem;
  color: #8492a6;
  margin: 0;
}

.agent-queue-status {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table > :not(:first-child) {
  border-top: none !important;
}

.btn-show-agents {
  border: none;
  background: none;
}

.dot-status {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
}
</style>
