export default {
  updateCardsColumn(state, columns) {
    state.columns = columns
  },
  setIsDraggingColumn: (state, payload) => state.isDraggingColumn = payload,
  setLockDrag: (state, payload) => state.lockDragCard = payload,

  setSteps(state, steps) {
    state.steps = steps
  },

  clearSteps: (state) => state.steps = [],

  setStepPagination(state, {last_page, current_page, per_page, total, clear = false}) {
    state.stepPagination = clear ? {
      current_page: 1, last_page: 1, per_page: 15, total: 1
    } : state.stepPagination = {
      current_page, last_page, per_page, total
    }
  },

  startLoading: (state, flag) => state.loadingFlags[flag] = true,
  stopLoading: (state, flag) => state.loadingFlags[flag] = false,
}
