<template>
  <div class="card mt-4">
    <div class="card-header">
      <h4 class="card-header-title  text-capitalize fw-bold">
        {{ $t("advanced") }}
      </h4>
    </div>
    <div class="card-body row">
      <div class="row">
        <div class="col-12 col-md-6 ">
          <div class="row">
            <div class="col-auto">
              <div class="form-check form-switch">
                <label class="mb-1"> {{ $t("call_limit_per_route") }} </label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="switchOne"
                  v-model="enable_improductive_calls_limit"
                >
                <label
                  class="form-check-label"
                  for="switchOne"
                />
              </div>
            </div>
            <div class="col ms-n2" />
          </div>
          <label class="form-text fs-6 text">
            {{ $t("notification_list_telephony") }}
          </label>
        </div>
        <div class="col-12 col-md-6 d-flex align-items-center mb-5">
          <button
            class="btn btn-primary border-0 rounded-0 rounded-start"
            @click="limit_unproductive_calls--"
            :disabled="limit_unproductive_calls === 0 || !enable_improductive_calls_limit"
          >
            <span class="fa fa-minus" />
          </button>
          <input
            v-model="limit_unproductive_calls"
            :disabled="!enable_improductive_calls_limit"
            :placeholder="`máx: ${max_unproductive_calls}` "
            class="form-control border border-end-0 border-start-0 rounded-0"
            type="number"
          >
          <button
            class="btn btn-primary border-0 rounded-0 rounded-end"
            @click="limit_unproductive_calls++"
            :disabled="!enable_improductive_calls_limit"
          >
            <span class="fa fa-plus" />
          </button>
        </div>
      </div>
      <hr>
      <div class="row align-items-end">
        <div class="col">
          <h3 class="header-title">
            {{ $t("telephony_group") }}
          </h3>
        </div>
      </div>
      <div class="mt-4">
        <div
          class="card card-new cursor-pointer"
          @click="openTelephonyForm()"
        >
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div
                class="col overflow-ellipsis"
                style="line-height: 36.19px"
              >
                <span class="h5 mb-0">{{ $t("add_new_group") }}</span>
              </div>
              <div class="col-auto">
                <span class="h2 fa fa-plus text-primary mb-0" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div v-if="loading.listRouteGroups">
          <div class="card-body text-center">
            <div class="spinner-border text-muted">
              <span class="visually-hidden" />
            </div>
          </div>
        </div>
        <div
          class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
          v-for="(item, index) in route_groups"
          :key="index"
          v-else
        >
          <div
            class="card card-list-item cursor-pointer"
            @click="open(item)"
          >
            <div class="card-body">
              <div class="row align-items-center gx-0">
                <div class="col overflow-ellipsis">
                  <span class="h5 mb-0">{{ item.name }}</span><br><small class="text-muted">{{ item.routes.length }}
                    {{ item.routes.length > 1 ? $t("routes") : $t("route") }}</small>
                </div>
                <div class="col-auto">
                  <span class="h2 fa fa-chevron-right text-primary mb-0" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <paginator
          v-if="pagination.telephony_route_groups && pagination.telephony_route_groups.total_pages > 1"
          :pagination="pagination.telephony_route_groups"
          @change-page="changePage"
          :loading="!loading.listRouteGroups"
        />
      </div>
    </div>
    <telephony-form ref="TelephonyForm" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import TelephonyForm from "../telephony-form.vue";
import Paginator from "@components/app-paginator.vue";

export default {
  data() {
    return {
      enable_improductive_calls_limit: false,
      limit_unproductive_calls: 0,
      max_unproductive_calls: 100000,
    };
  },
  components: {
    TelephonyForm,
    Paginator,
  },
  emits: ["limit-calls-updated"],
  computed: {
    ...mapGetters("manager/telephony", {
      loading: "getLoadingFlags",
      route_groups: "getRouteGroups",
      routes: "getRoutes",
      pagination: "getPagination",
    }),
    ...mapGetters("auth", {
      user: "getUser",
    }),
  },
  methods: {
    ...mapActions("manager/telephony", ["updateUnproductiveCallsLimit", "listRouteGroups", "setCurrentPage"]),
    open(item) {
      this.$refs.TelephonyForm.edit(item, this.routes);
    },
    openTelephonyForm() {
      this.$refs.TelephonyForm.new(this.routes);
    },
    changeUnproductiveCallsLimit() {
      this.updateUnproductiveCallsLimit({
        fields: {
          limit_unproductive_calls: this.limit_unproductive_calls,
        },
      });
    },
    changePage(page) {
      this.setCurrentPage({ page, flag: "telephony_route_groups" });
      this.listRouteGroups();
    },
  },
  watch: {
    enable_improductive_calls_limit(isEnabled) {
      if (!isEnabled) {
        this.limit_unproductive_calls = 0;
        this.changeUnproductiveCallsLimit();
      }
    },
    limit_unproductive_calls(value, oldValue) {
      if (value > this.max_unproductive_calls) {
        this.limit_unproductive_calls = oldValue
      }
      this.$emit("limit-calls-updated", this.limit_unproductive_calls);
    },
  },
  mounted() {
    this.limit_unproductive_calls = this.user.company.limit_unproductive_calls;
    this.enable_improductive_calls_limit = this.limit_unproductive_calls > 0;
    this.listRouteGroups();
  },
};
</script>
