<template>
  <canvas
    ref="radarChart"
    class="chart-canvas"
  />
</template>

<script>
import { Chart } from "chart.js";

export default {
  name: "RadarChart",
  props: {
    labels: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    chartColor: {
      type: String,
      default: "#5CB868BF"
    }
  },
  mounted() {
    this.createChart();
  },
  methods: {
    createChart() {
      const ctx = this.$refs.radarChart.getContext("2d");
      new Chart(ctx, {
        type: "radar",
        data: {
          labels: this.labels,
          datasets: [
            {
              data: this.data,
              fill: true,
              backgroundColor: this.chartColor,
              borderColor: this.chartColor,
              pointRadius: 0,
              borderWidth: 1,
              tension: 0
            }
          ]
        },
        options: {
          scales: {
            r: {
              ticks: {
                display: false,
                stepSize: 2
              },
              max: 10,
              min: 0
            }
          }
        }
      });
    }
  }
};
</script>
