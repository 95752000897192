import store from "@store";

import IndexIntegration from "@dialer/manager/pages/settings/integration/index-integration";
import ListIntegration from "@dialer/manager/pages/settings/integration/list-integration";
import FormIntegration from "@dialer/manager/pages/settings/integration/form-integration";

export default {
  path: "integration",
  name: "integration",
  components: {
    main: IndexIntegration
  },
  children: [
    {
      path: "",
      components: {
        integration: ListIntegration
      }
    },
    {
      path: "new",
      components: {
        integration: FormIntegration
      }
    },
    {
      path: ":id/edit",
      components: {
        integration: FormIntegration
      }
    }
  ],
  beforeEnter: (to, from, next) => {
    if (store.getters["auth/isManager"]) next();
    else next({
      name: "not-found",
      params: {
        pathMatch: to.path.split('/').slice(1)
      }
    });
  }
};
