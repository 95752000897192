<template>
  <table-before-search v-if="!searched" />
  <table-time-report v-else />
</template>

<script>
import TableBeforeSearch from "@dialer/manager/pages/reports/components/table-before-search.vue";
import TableTimeReport from "@dialer/manager/pages/reports/chats-agents/components/table-time-report.vue";

export default {
  name: "TabTime",
  components: {TableTimeReport, TableBeforeSearch},
  props: {
    searched: {
      type: Boolean,
      default: false
    }
  }
}
</script>