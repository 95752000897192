<template>
  <hover-button
    ref="whatsappRedirect"
    @click="open"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    :icon="getIcon"
    :should-show-close-button="false"
    :background-color="getBackgroundColor"
    :keep-button-hovered="showWhatsappRedirect"
    :disabled="isWhatsappRedirectDisabled"
    :active="isWhatsappRedirectActive"
  />

  <dropdown-component
    :is-open="showWhatsappRedirect"
    :button-rect="buttonRect"
    :title="$t('select_channel_group')"
  >
    <div class="overflow-auto card-whatsapp-redirect-body">
      <instances-list :contact="contact" />
    </div>
  </dropdown-component>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { AgentStatus, CallStatus } from "@/utils";
import { parsePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
import HoverButton from "@dialer/agent/components/hover-button.vue";
import InstancesList from "@whatsapp/components/instances-list.vue";
import DropdownComponent from "@dialer/agent/components/dropdown-component.vue";

export default {
  name: "WhatsappRedirect",
  data() {
    return {
      CallStatus,
      AgentStatus,
      hover: false,
      showWhatsappRedirect: false,
      buttonRect: null
    };
  },

  components: {
    HoverButton,
    InstancesList,
    DropdownComponent
  },

  computed: {
    ...mapGetters("system", {
      status: "getStatus",
      isConsultHold: "isConsultHold"
    }),

    ...mapGetters("auth", {
      instances: "getUserConnectedInstances",
      hasAccessToWhatsapp: "hasAccessToWhatsapp"
    }),

    ...mapGetters("agent/ring_group", {
      callStatus: "getCallStatus",
      callPABX: "getCallPABX"
    }),

    ...mapGetters("agent/agent", {
      loggedCampaign: "getCampaign",
      activeDropdownLeft: "getActiveDropdownLeft"
    }),

    ...mapGetters("agent/call", {
      call: "getCall",
      manualCallAcw: "getManualCallACW"
    }),

    ...mapGetters("whatsapp/chat", {
      whatsappChatLoadingFlags: "getLoadingFlags"
    }),

    ...mapGetters("whatsapp/contact", {
      contactInstances: "getContactInstances",
      whatsappContactLoadingFlags: "getLoadingFlags"
    }),

    contact() {
      const formatNumber = phone => (phone.length === 12 ? phone.slice(0, 4) + "9" + phone.slice(4) : phone);

      const phone = this.call?.phone || this.callPABX?.phone;

      return { number: formatNumber(phone) };
    },

    getBackgroundColor() {
      if (
        this.loggedCampaign &&
        [AgentStatus.ON_CALL, AgentStatus.ACW, AgentStatus.ON_MANUAL_CALL_ACW].includes(this.status)
      ) {
        return "var(--bs-white)";
      }

      return "#E1E9F4";
    },

    getIcon() {
      if (this.whatsappChatLoadingFlags.checkContacts || this.whatsappContactLoadingFlags.findContactInstances) {
        return "fa-regular fa-spinner fa-spin fa-spin";
      } else {
        return "fa-kit fa-whatsapp";
      }
    },

    isWhatsappRedirectDisabled() {
      const isCallFailed = this.call?.status == CallStatus.FAILED && this.status === AgentStatus.ON_CALL;
      const isManualCallAcwFailed = this.manualCallAcw?.status == CallStatus.FAILED;

      return (
        this.isConsultHold ||
        this.status === AgentStatus.CONSULT_CONNECTED ||
        this.whatsappChatLoadingFlags.checkContacts ||
        this.whatsappChatLoadingFlags.findChat ||
        this.whatsappContactLoadingFlags.findContactInstances ||
        isCallFailed ||
        isManualCallAcwFailed ||
        [
          CallStatus.DIALING,
          CallStatus.CONSULT_ANSWERED,
          CallStatus.TRYING_INTERNAL_CALL,
          CallStatus.INTERNAL_CALL_FAILED,
          CallStatus.CONSULT_TRYING
        ].includes(this.callStatus)
      );
    },

    isWhatsappRedirectActive() {
      return (
        (this.status === AgentStatus.ON_CALL &&
          [CallStatus.CONNECTED, CallStatus.EXTERNAL_CALL_ANSWERED, CallStatus.TRANSFERENCE_CONNECTED].includes(
            this.callStatus
          )) ||
        this.call?.status == CallStatus.FAILED
      );
    }
  },

  methods: {
    ...mapMutations("auth", ["trackEvents"]),
    ...mapMutations("whatsapp/chat", ["setEmptyChatContact"]),
    ...mapMutations("whatsapp/contact", ["setSelectedContact"]),

    ...mapActions("whatsapp/chat", ["checkContacts", "findChat"]),
    ...mapActions("whatsapp/contact", ["findContactInstances"]),
    ...mapActions("agent/agent", ["setActiveDropdownLeft"]),

    open() {
      if (this.instances.length <= 1) {
        this.whatsappRedirect();
      } else {
        this.openInstances();
      }
    },
    whatsappRedirect() {
      if (!this.call && !this.callPABX) return;

      if (!this.hasAccessToWhatsapp || this.instances.length === 0) {
        this.redirectToWhatsappWeb();
        return;
      }

      let number = String(this.contact.number);
      if (isValidPhoneNumber(number, "BR")) {
        number = parsePhoneNumber(number, "BR").number.replace("+", "");
      }

      const instanceId = this.instances?.[0]?.id ?? null;
      if (!instanceId) {
        console.error("Instance ID is not available.");
        return;
      }

      this.checkContacts({
        fields: {
          phones: [number],
          instance_id: instanceId
        }
      })
        .then(() => {
          this.redirectToChat(`${number}${instanceId}`);
        })
        .catch(error => {
          console.error("Error checking contacts:", error);
        });
    },

    openInstances() {
      if (this.showWhatsappRedirect) {
        this.toggleDropdown();
        return;
      }

      let number = String(this.contact.number);
      if (isValidPhoneNumber(number, "BR")) {
        number = parsePhoneNumber(number, "BR").number.replace("+", "");
      }

      this.checkContacts({
        fields: {
          phones: [number],
          instance_id: this.instances[0].id
        }
      }).then(() => {
        this.setSelectedContact({});
        this.findContactInstances(number).then(() => {
          this.toggleDropdown();
        });
      });
    },

    redirectToWhatsappWeb() {
      this.trackEvents("[Agent] Click on Mobile (WhatsApp Web)");
      window.open("https://api.whatsapp.com/send?phone=" + this.contact.number, "_blank");
    },

    redirectToChat(chatId) {
      this.findChat(chatId)
        .then(chat => {
          this.$router.push("/whatsapp/" + chat.id);
        })
        .catch(status => {
          if (status === 404) {
            let emptyChatContact = this.contact;
            emptyChatContact.instance = this.instances[0];
            this.setEmptyChatContact(emptyChatContact);
            this.$router.push("/whatsapp/empty-chat");
          }
        });
    },
    toggleDropdown() {
      this.showWhatsappRedirect = !this.showWhatsappRedirect;
      if (this.showWhatsappRedirect) {
        this.setActiveDropdownLeft("WhatsappRedirect");
        this.calculateButtonRect();
        window.addEventListener("resize", this.calculateButtonRect);
      } else {
        window.removeEventListener("resize", this.calculateButtonRect);
      }
    },

    calculateButtonRect() {
      const rect = this.$refs.whatsappRedirect.$el.getBoundingClientRect();
      this.buttonRect = rect;
    }
  },

  watch: {
    activeDropdownLeft(newVal) {
      if (newVal !== "WhatsappRedirect" && this.showWhatsappRedirect) {
        this.toggleDropdown();
      }
    }
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.calculateButtonRect);
  }
};
</script>

<style lang="scss" scoped>
.card-whatsapp-redirect-body {
  max-height: 715px;
  padding: 1rem;
}
</style>
