export default {
  getLoadingFlag: state => state.loadingFlag,
  isLoading: state => state.loading,
  getOnlineUsers: state => state.listOnlineUsers,
  getOfflineUsers: state => state.listOfflineUsers,
  getAlert: state => state.alert,
  getErrors: state => state.errors,
  getErrorFlags: state => typeError => state.errorFlags[typeError],
  getFilters: state => state.filters,
  getLineOfWork: state => state.lineOfWork,
  getPromoter: state => state.promoter,
  getCompanyRole: state => state.companyRole,
  getRegister: state => state.register,
  getPaginationOnlineUsers: state => state.paginationOnlineUsers,
  getPaginationOfflineUsers: state => state.paginationOfflineUsers,
  getList: state => state.list,
  getDataUpdateModal: state => state.dataUpdateModal,
  getListIpAllowed: state => state.listIpAllowed,
  getTemporaryUnlockModal: state => state.temporaryUnlockModal
};
