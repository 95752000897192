<template>
  <div
    class="loading-card"
    :style="`height: ${height}px;`"
  />
</template>

<script>
export default {
  name: "CardLoading",

  props: {
    height: {
      type: Number,
      default: 100
    }
  }
}
</script>

<style scoped lang="scss">
.loading-card {
  background: linear-gradient(110deg, #F0F4FA 8%, #F9FBFD 18%, #F0F4FA 33%);
  border-radius: 8px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  margin-bottom: 1.5rem;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
</style>
