<template>
  <div class="col-12 col-xl-4 col-lg-6">
    <div
      class="card rounded border card-settings m-0 h-100"
      @click="$router.push(redirectLink)"
    >
      <div class="card-body d-flex align-items-center">
        <div class="card-settings-icon d-flex align-items-center justify-content-center rounded-1 me-3">
          <i
            class="text-primary d-flex align-items-center justify-content-center"
            :class="icon"
          />
        </div>
        <div>
          <strong>{{ title }}</strong>
          <br>
          <span class="text-medium tx-text-gray"> {{ description }} </span>
        </div>
        <div class="ms-auto">
          <i class="fa-regular fa-chevron-right tx-idle redirect-icon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardSettings",
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    redirectLink: {
      type: String,
      default: "/",
    },
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.card-settings {
  border: 1px solid $color-gray-blue-300;

  &:hover {
    cursor: pointer;
    background-color: $color-gray-blue-300;

    .redirect-icon {
      color: var(--color-blue-300);
    }
  }

  .card-settings-icon {
    width: 32px;
    height: 32px;
    background-color: $color-gray-blue-200;
  }
}
</style>
