<template>
  <button
    style="padding: 1rem; gap: 1rem"
    class="d-flex align-items-center text-start rounded-1 border group cursor-pointer w-100"
    @click="$router.push({ path: `/manager/qualifications/${group.id}/edit` })"
  >
    <icon-card
      :variant="icon"
    />
    <span
      style="flex: 1; min-height: 40px"
      class="d-flex flex-column"
    >
      <span
        style="line-height: 1.333"
        class="tx-text-200 line-clamp fs-4 fw-medium"
      >
        {{ group.name }}
      </span>
      <span
        style="font-size: 0.75rem; line-height: 1.333"
        class="tx-text-100"
        v-if="group.qualification_count"
      >
        {{ `${group.qualification_count} ${this.$tc('capitalizes.qualifications', group.qualification_count)}` }}
      </span>
    </span>
    <i class="fa-regular fa-chevron-right tx-text-gray" />
  </button>
</template>
<script>
import IconCard from "@dialer/manager/pages/settings/qualifications/components/icon-card.vue";

export default {
  name: "QualificationGroup",
  components: {IconCard},
  props: {
    group: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      iconMap: [
        {
          type: 1,
          icon: "fa-regular fa-phone"
        },
        {
          type: 2,
          icon: "fa-kit fa-whatsapp"
        },
        {
          type: 3,
          icon: "fa-regular fa-arrows-turn-right"
        },
        {
          type: 4,
          icon: "fa-regular fa-square-kanban"
        },
      ]
    }
  },

  computed: {
    icon() {
      return this.iconMap.find((icon) => icon.type === this.group.type).icon
    },
  }
}
</script>
<style scoped lang="scss">
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.group {
  background-color: #fff;

  &:hover,
  &:focus,
  &:focus-visible{
    background-color: $color-gray-blue-200;
  }
}
</style>