<template>
  <div
    v-if="
      !loadingFlags.getCallHistory && !loadingFlags.loadCampaignMetricsChart
    "
    class="position-relative"
    style="margin: 12px 12px;"
  >
    <div class="row mb-4">
      <div
        class="col-6"
        style="height: 175px;"
      >
        <div class="card h-100 card-hover">
          <div class="card-header">
            <h4
              class="m-0 p-0 cerebri-sans"
              style="padding-top: 16px; padding-bottom: 16px;"
            >
              {{ $t("calls_capitalizes") }}
            </h4>
          </div>
          <div
            class="card-body px-4 row justify-content-between m-0"
            style="height: 80px; padding: 8px;"
          >
            <div class="col-4 h-100 cerebri-sans">
              <h1 style="margin-bottom: 8px;">
                {{ showCallsQty(todayCalls) }}
              </h1>
              <h5 class="tx-green-400 mb-1">
                {{ $t("made_today") }}
              </h5>
              <h6 class="tx-text-gray mb-0">
                {{ $t("yesterday_capitalize") }}: {{ showCallsQty(yesterdayCalls) }}
              </h6>
            </div>
            <div class="col-8 px-0 h-100">
              <card-chart
                :data="todayCalls"
                :dotted-data="yesterdayCalls"
                line-color="#008A35"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-6"
        style="height: 175px;"
      >
        <div class="card h-100 card-hover">
          <div class="card-header">
            <h4
              class="m-0 p-0 cerebri-sans"
              style="padding-top: 16px; padding-bottom: 16px;"
            >
              {{ $t("crp_definition") }}
            </h4>
          </div>
          <div
            class="card-body px-4 row justify-content-between m-0"
            style="height: 80px;padding: 8px"
          >
            <div class="col-4 h-100 cerebri-sans">
              <h1 style="margin-bottom: 8px;">
                {{ showCallsQty(todayCpc) }}
              </h1>
              <h5 class="tx-blue-300 mb-1">
                {{ $t("today_contacts") }}
              </h5>
              <h6 class="tx-text-gray">
                {{ $t("yesterday_capitalize") }}: {{ showCallsQty(yesterdayCpc) }}
              </h6>
            </div>
            <div class="col-8 px-0 h-100">
              <card-chart
                :data="todayCpc"
                :dotted-data="yesterdayCpc"
                line-color="#3057F2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row mb-4"
      style="height: 176px;"
    >
      <StatusBar />
      <StatusFeedback />
    </div>
    <div class="row">
      <div class="col-12 mb-3">
        <div class="card m-0">
          <div class="card-header">
            <h4 class="m-0 p-0 cerebri-sans">
              {{ $t("last_calls") }}
            </h4>
            {{ callHistory.length }}
          </div>
          <div class="table-responsive position-relative table-size">
            <table class="table table-hover table-nowrap mb-0">
              <thead
                class="
              position-sticky
              top-0 cerebri-sans"
              >
                <tr>
                  <th style="padding-left: 64px;">
                    {{ $t("phone") }}
                  </th>
                  <th>
                    {{ $t("identifier") }}
                  </th>
                  <th>
                    {{ $t("protocol") }}
                  </th>
                  <th>
                    {{ $t("qualification") }}
                  </th>
                  <th>
                    {{ $t("date_and_time") }}
                  </th>
                  <th>
                    {{ $t("to_call") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-if="!callHistory.length && loadingFlags.getCallHistory"
                >
                  <tr>
                    <td colspan="7">
                      <div
                        class="d-flex justify-content-center align-items-center flex-column my-4"
                      >
                        <img
                          alt="Pluszinha pesquisando"
                          src="@/assets/img/search-pluszinha.svg"
                          style="width: 56px; margin-bottom: 8px;"
                        >
                        <h5 class="text-center fw-normal m-0">
                          {{ $t("no_calls_yet") }}
                        </h5>
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr
                    v-for="(call, index) in callHistory"
                    :key="index"
                  >
                    <td class="h6 m-0 tx-text-200 btn-call-content">
                      <div class="d-flex align-items-center">
                        <span
                          v-if="call.status === CallStatus.ENDED"
                          style="padding-left: 8px;"
                        >
                          <i
                            class="fa-light h5 tx-green-300 m-0"
                            :class="call.call_mode === 'receptive' ? 'fa-phone-arrow-down-left ': 'fa-phone-arrow-up-right'"
                          />
                        </span>
                        <span
                          v-else
                          style="color: #ce4c35;"
                        >
                          <i
                            class="fa-light fa-phone-xmark h5 m-0"
                            style="padding-left: 6px;"
                          />
                        </span>
                        <div
                          class="points-wrapper"
                          style="margin-left: 16px;"
                          @mouseout="copyToClipboardInfo = $t('copy')"
                          @click.stop="copyTextToClipboard(call.number)"
                        >
                          <span
                            :data-tooltip="copyToClipboardInfo"
                            class="tooltip-left cursor-pointer phone"
                          >{{ formatPhoneNumber(call?.number) }}</span>
                        </div>
                      </div>
                    </td>
                    <td
                      v-if="call.mailing_data && typeof call.mailing_data === 'object' && 'identifier' in
                        call.mailing_data"
                      class="h6 m-0 tx-text-gray text-truncate"
                      style="max-width: 120px;"
                    >
                      <div
                        class="points-wrapper"
                        @mouseout="copyToClipboardInfo = $t('copy')"
                        @click.stop="
                          copyTextToClipboard(call.mailing_data.identifier)
                        "
                      >
                        <span
                          :data-tooltip="copyToClipboardInfo"
                          class="tooltip-left cursor-pointer identifier"
                        >{{
                          call.mailing_data.identifier.length > 14 ? `${call.mailing_data.identifier.slice(0, 14)}...` : call.mailing_data.identifier
                        }}</span>
                      </div>
                    </td>
                    <td
                      v-else
                      class="h6 m-0 tx-text-gray"
                    >
                      <div style="margin-left: 50px;">
                        -
                      </div>
                    </td>
                    <td class="h6 m-0 tx-text-gray">
                      <div
                        class="points-wrapper"
                        @mouseout="copyToClipboardInfo = $t('copy')"
                        @click.stop="copyTextToClipboard(call.sid)"
                      >
                        <span
                          :data-tooltip="copyToClipboardInfo"
                          class="tooltip-left cursor-pointer sid"
                        >{{ call.sid }}</span>
                      </div>
                    </td>
                    <td class="h6 m-0 tx-text-gray">
                      {{ call.qualification.name }}
                    </td>
                    <td class="h6 m-0 tx-text-gray">
                      {{ formatDate(call.call_date, "DD/MM [às] HH:mm") }}
                    </td>
                    <td>
                      <div
                        v-show="!loadingFlags.startManualCallAfterAgentEnteredManual || (callingCallId === call._id
                          && loadingFlags.startManualCallAfterAgentEnteredManual)"
                        class="call-cell pe-4"
                      >
                        <div
                          :class="{ calling: callingCallId === call._id }"
                          class="button-call align-self-center d-flex align-items-center justify-content-center rounded"
                          @click="_startManualCallAfterAgentEnteredManual(call)"
                        >
                          <span class="fa-regular fa-phone" />
                          <span class="fa-regular fa-phone-volume" />
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="row mx-3 mt-4"
  >
    <div
      v-for="i in 4"
      :key="i"
      class="col-6"
    >
      <card-loading :height="180" />
    </div>
    <div class="col-12">
      <card-loading :height="350" />
    </div>
  </div>
</template>

<script>
import CardChart from "./card-chart.vue";
import StatusBar from "./status-bar.vue";
import StatusFeedback from "./status-feedback.vue";
import { AgentStatus, CallStatus, formatDate, formatPhoneNumber } from "@/utils";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import CardLoading from "@components/card-loading.vue";

export default {
  components: {
    CardLoading,
    CardChart,
    StatusBar,
    StatusFeedback,
  },

  data() {
    return {
      AgentStatus,
      CallStatus,
      callingCallId: "",
      copyToClipboardInfo: this.$t("copy"),
      show: -1,

      // Graph Data
      todayCalls: [],
      yesterdayCalls: [],
      todayCpc: [],
      yesterdayCpc: [],
    };
  },

  beforeMount() {
    this.getCallHistory({
      start_date: moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
      end_date: moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
      campaign_id: this.campaign.id,
    });

    this.loadCampaignMetricsChart({
      start_date: moment()
          .startOf("day")
          .subtract("1", "day")
          .format("YYYY-MM-DD HH:mm:ss"),
      end_date: moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
      campaign_id: this.campaign.id,
      agent_id: this.user.id,
    })
        .then(() => {
          this.campaignMetricsCharts["today"].forEach((metric, i) => {
            this.todayCalls[i] = metric.calls + metric.manual_calls_made + metric.manual_calls_acw_made;
            this.todayCpc[i] = metric.dmc + metric.converted;
          });
          this.campaignMetricsCharts["yesterday"].forEach((metric, i) => {
            this.yesterdayCalls[i] = metric.calls + metric.manual_calls_made + metric.manual_calls_acw_made;
            this.yesterdayCpc[i] = metric.dmc + metric.converted;
          });
        });
  },

  beforeUnmount() {
    this.clearDashboard();
  },
  computed: {
    ...mapGetters("auth", {
      user: "getUser",
    }),

    ...mapGetters("system", {
      status: "getStatus",
      mode: "getMode",
    }),

    ...mapGetters("agent/agent", {
      agentStats: "getAgentStats",
      campaign: "getCampaign",
      time: "getTimeCalls",
    }),

    ...mapGetters("agent/call", {
      loadingFlags: "getLoadingFlags",
      callHistory: "getCallHistory",
      callHistoryNumberCalling: "getCallHistoryNumberCalling",
      campaignMetricsCharts: "getCampaignMetricsCharts",
    }),
  },

  methods: {
    formatPhoneNumber,
    formatDate,

    ...mapActions("agent/call", [
      "startManualCallAfterAgentEnteredManual",
      "getCallHistory",
      "loadCampaignMetricsChart",
      "clearDashboard",
    ]),

    _startManualCallAfterAgentEnteredManual(call) {
      const { _id, number } = call;
      this.callingCallId = _id;
      if (!this.loadingFlags.startManualCallAfterAgentEnteredManual) {
        this.startManualCallAfterAgentEnteredManual({ _id: _id, phone: number });
      }
    },

    showCallsQty(value) {
      if (value.length) {
        return value.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
        );
      } else {
        return 0;
      }
    },

    copyTextToClipboard(textToCopy) {
      navigator.permissions.query({ name: "clipboard-write" })
          .then(result => {
            if (result.state === "granted" || result.state === "prompt") {
              navigator.clipboard.writeText(textToCopy);
              this.copyToClipboardInfo = this.$t("success_copied");
            }
          });
    },
  },
};
</script>

<style
    lang="scss"
    scoped
>
.card {
  border: 1px solid $color-gray-blue-300;

  button {
    background-color: $color-gray-blue-300;
    border-radius: $border-radius;
  }
}

.card-hover {
  transition: 0.2s ease-in-out;

  &:hover {
    box-shadow: 2px 2px 15px 2px rgba(18, 38, 63, 0.13);
  }
}

.table-size {
  max-height: 250px;

  tr {
    &:hover {
      .button-call {
        background-color: var(--color-call);
        cursor: pointer;
      }
    }

    td {
      height: 40px !important;
      padding: 0px 16px !important;
    }
  }

  .tooltip-left[data-tooltip] .phone {
    .phone,
    .identifier,
    .sid &:hover::after,
    &:active::after {
      left: calc(100vw - 95%);
    }
  }

  .tooltip-left[data-tooltip] {
    &:hover::after,
    &:active::after {
      height: 32px;
      padding: 0.3rem 1rem;
      background-color: $color-text-light;
      color: $color-text-solid-black;
      border-radius: 10px;
      box-shadow: 0px 4px 18px 0px rgba(34, 54, 77, 0.12);
      z-index: 1;
      transition: opacity 0.3s;
    }
  }

  *[data-tooltip] {
    &:hover::after,
    &:active::after {
      content: attr(data-tooltip);
      padding: 2px 10px;
      position: absolute;
      text-align: right;
      text-indent: 0;
      line-height: 25px;
      white-space: nowrap;
      word-wrap: normal;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      z-index: 9999;
      font-weight: normal;
      margin: -36px 48px 0 0;
    }
  }
}

.call-cell {
  width: 100%;

  .button-call {
    display: flex !important;
    color: $color-text-light;
    width: 48px;
    height: 24px;
    background-color: $color-text-light;
  }

  .fa-phone {
    display: block;
  }

  .fa-phone-volume {
    display: none;
  }
}

.btn-call-content {
  margin: 0 10px;
  padding: 0 13px 0 1rem;

  .button-call {
    color: $color-text-light;
    display: none !important;
    width: 48px;
    height: 24px;
  }

  &:hover,
  &:has(.calling) {
    background-color: $color-gray-100;

    .button-call {
      display: flex !important;
    }
  }

  &:has(.loadingFlags) {
    cursor: default;
    background-color: white;
    opacity: 0.3;

    .button-call {
      display: none !important;
    }
  }

  .fa-phone-volume {
    display: none;
  }
}

.position {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.calling {
  cursor: none;
  display: flex !important;
  animation: border-increase 1s linear infinite !important;
  background-color: var(--color-call) !important;

  .fa-phone {
    display: none;
  }

  .fa-phone-volume {
    display: inline-block !important;
    animation: spin 1s linear infinite;
    color: $color-text-light;
  }
}

.table {
  border-spacing: 0 !important;
  border-collapse: initial !important;
}

.position {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.cerebri-sans {
  font-family: "Cerebri Sans", sans-serif;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(0deg);
  }
}

@keyframes border-increase {
  25% {
    outline: 1.78px solid rgba(157, 212, 164, 1);
  }
  50% {
    outline: 3.56px solid rgba(141, 187, 146, 1);
  }
}

@media only screen and (min-height: 850px) {
  .table-size {
    max-height: 600px !important;
    height: 100% !important;
  }
}

@media only screen and (min-height: 1280px) {
  .table-size {
    max-height: 1195px !important;
  }
}

@media only screen and (min-height: 1440px) {
  .table-size {
    max-height: 1375px !important;
  }
}
</style>
