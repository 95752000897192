<template>
  <div class="card-body">
    <div class="row col-12 bg-gray-blue-200 rounded card-key m-0">
      <div class="col-xl-6 align-items-end d-flex flex-wrap ps-0">
        <label
          class="form-label
                          required"
        >{{ $t("capitalizes.keyboard_keys") }}</label>

        <div
          class="col-12 d-flex flex-wrap gap-2"
        >
          <button
            class="btn text-small btn-key col"
            type="button"
            :class="{
              'btn-key-selected':keyAny,
            }"
            @click="toggleNone($t('capitalizes.none_key') )"
          >
            {{ $t("capitalizes.none_key") }}
          </button>
          <button
            class="btn text-small btn-key col"
            type="button"
            v-for="(key, j) of keys_map"
            :class="{
              'btn-key-selected': key,
            }"
            @click="toggleNone(j)"
            :key="j"
          >
            {{ j }}
          </button>
        </div>
      </div>
      <div class="col pe-0">
        <div class="d-flex justify-content-between">
          <label
            class="form-label
                          required"
          >{{ $t("target_location") }}
          </label>
        </div>
        <single-select
          label="name"
          :current-option="redirectTypes.find(type => type.id === redirectType)?.name "
          :list-array="redirectTypes"
          @selected-option="$emit('update:redirectType', $event.id)"
          id="ivrRedirect"
        />

        <div class="invalid-feedback">
          {{
            errors.redirect_type && errors.redirect_type[0]
          }}
        </div>
      </div>
      <div class="col-12 row m-0 p-0">
        <div
          v-if="redirectType === OMNI_REDIRECT_ID"
          class="form-group col-6 ps-0 mb-0"
        >
          <label class="form-label required">{{ $t("group_channels") }}</label>
          <select-channel
            :initial-channel="instances"
            :model-value="selectedChannel"
            @update:model-value="setChannel"
            :error="errors.instances && errors.instances[0]"
          />
        </div>
        <div
          v-if="redirectType === OMNI_REDIRECT_ID"
          class="form-group col-6 pe-0 mb-0 "
        >
          <label class="form-label required">{{ $t("select_the_message") }}</label>
          <select-quick-message
            v-if="selectedChannel?.type === 'ultramsg'"
            :initial-message="instances?.quick_message_id"
            :group-channel-id="selectedChannel?.group_channel_id"
            :model-value="selectedMessage"
            :error="errors['instances.quick_message_id'] && errors['instances.quick_message_id'][0]"
            @update:model-value="setMessage"
            :disabled="!selectedChannel"
          />
          <select-waba-template
            v-else
            :initial-message="instances?.template_id"
            :instance-id="selectedChannel?.id"
            :model-value="selectedMessage"
            :error="errors['instances.template_id'] && errors['instances.template_id'][0]"
            @update:model-value="setMessage"
            :disabled="!selectedChannel"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="footer-custom d-flex justify-content-center">
    <button
      class="btn px-4 col-6 h-auto py-1"
      :class="!disableNext ?'btn-primary':'btn-light' "
      :disabled="disableNext"
      @click="$emit('toggleCollapse')"
    >
      {{ $t("continue_to_advanced_settings") }}
    </button>
  </div>
</template>
<script>
import SingleSelect from "@/components/single-select.vue"
import SelectQuickMessage from "@dialer/manager/pages/settings/ivrs/components/select-quick-message.vue";
import SelectWabaTemplate from "@dialer/manager/pages/settings/ivrs/components/select-waba-template.vue";
import SelectChannel from "@dialer/manager/pages/settings/ivrs/components/select-channel/select-channel.vue";

export default {
  name: "KeysSettings",
  components: {
    SelectChannel,
    SelectWabaTemplate,
    SelectQuickMessage,
    SingleSelect,
  },
  props: {
    keys: {
      type: Array,
      required: true,
    },
    redirectType: {
      type: Number,
      required: true,
    },
    instances: {
      type: Array,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    disableNext: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      keys_map: Array(10).fill(false),
      keyAny: true,
      redirectTypes: [
        {
          name: this.$t("dialer_menu_capitalize"),
          id: 1,
        },
        {
          name: this.$t("Omnichannel"),
          id: 2,
        },
      ],
      WABA_INSTANCE_TYPE_ID: 2,
      ULTRAMSG_INSTANCE_TYPE_ID: 1,
      OMNI_REDIRECT_ID: 2,
      selectedChannel: null,
      selectedMessage: null,
    }
  },
  methods: {
    toggleNone(key) {
      if (key === this.$t("capitalizes.none_key")) {
        this.keyAny = true
        this.keys_map = Array(10).fill(false)
      } else {
        this.keys_map[key] = !this.keys_map[key]
        this.keyAny = !this.keys_map.some(key => key);
      }
      this.$emit("update:keys", this.keys_map)
    },
    setChannel(channel) {
      this.selectedMessage = null

      const instance = {
        instance_id: channel.id,
        group_channel_id: channel.group_channel_id,
        type: channel.type === "ultramsg" ? this.ULTRAMSG_INSTANCE_TYPE_ID : this.WABA_INSTANCE_TYPE_ID,
      }

      if (this.selectedChannel) {
        this.$emit("update:instances", [instance])
      } else {
        this.$emit("update:instances", [{ ...this.instances, ...instance }])
      }

      this.selectedChannel = channel
    },
    setMessage(message) {
      this.selectedMessage = message
      let fields = {}
      if (this.selectedChannel?.type === "ultramsg") {
        fields = {
          quick_message_id: message.id,
          message: message.message,
        }
      }
      if (this.selectedChannel?.type === "waba") {
        fields = {
          template_id: message.id,
        }
      }
      this.$emit("update:instances", [{ ...this.instances, ...fields }])
    },
  },
  emits: ["update:keys", "update:redirectType", "update:instances", "toggleCollapse"],
  watch: {
    keys: {
      handler: function (val) {
        this.keys_map = val
        this.keyAny = !val.some(key => key);
      },
      immediate: true,
    },
  },

}
</script>

<style scoped lang="scss">
.btn-key {
  background-color: $color-text-light;
  border-color: $color-gray-200;
  border-radius: 8px;
  padding: 0.5rem;

  &:hover {
    background-color: $color-blue-100;
    border-color: $color-blue-100;
    color: $color-text-light;
  }
}

.btn-key-selected {
  background-color: $color-blue-200;
  border-color: $color-blue-200;
  color: $color-text-light;
}

.card-key {
  padding: 1rem 1.5rem 1rem 1.5rem;
}

.card-key:hover .fe-trash {
  visibility: visible;
}

.footer-custom {
  padding: 0 0 1.5rem 0;
}

.btn-light:hover {
  background-color: $color-gray-200;
}

.form-group {
  padding: 1rem 0.75rem 0 0.75rem;
}
</style>