<template>
  <div class="input-range">
    <div
      class="bar"
      ref="bar"
      :style="
        'background-color:' +
          colorBackground +
          ' !important; background:  linear-gradient(to right, ' +
          color +
          ' ' +
          (maxLeft - minLeft) +
          'px, transparent 0); background-position:  ' +
          (minLeft + 8) +
          'px 0px !important;'
      "
    >
      <div
        :class="{ ball: true }"
        ref="min"
        :style="'left:' + minLeft + 'px;background-color:' + color"
        @mousedown="mouseDown"
      />

      <div
        :class="{ ball: true }"
        ref="max"
        :style="'left:' + maxLeft + 'px;background-color: ' + color"
        @mousedown="mouseDown"
      />
    </div>
  </div>
</template>

<script>
export default {
  emits: ["update:startInterval", "update:endInterval"],

  /**
   *
   */
  data() {
    return {
      minBall: null,
      maxBall: null,
      bar: null,
      clicked: false,
      active: "",
      value: 5,
      temp: "",
      minLeft: 0,
      maxLeft: 10,
      mouseX: 0,
    };
  },

  /**
   *
   */
  mounted() {
    this.bar = this.$refs.bar;
    this.minBall = this.$refs.min;
    this.maxBall = this.$refs.max;

    window.addEventListener("mouseup", this.mouseUp);
    window.addEventListener("mousemove", this.mouseMove);
    window.addEventListener("resize", this.render);

    this.render();
  },

  /**
   *
   */
  beforeUnmount() {
    window.removeEventListener("mouseup", this.mouseUp);
    window.removeEventListener("mousemove", this.mouseMove);
    window.removeEventListener("resize", this.render);
  },

  /**
   *
   */
  watch: {
    /**
     * update min point
     */
    startInterval(v) {
      if (v > this.endInterval) {
        this.$emit("update:startInterval", this.endInterval);
      }
      this.render();
    },

    /**
     * update max point
     */
    endInterval(v) {
      if (v < this.startInterval) {
        this.$emit("update:endInterval", this.startInterval);
      }
      this.render();
    },
  },

  /**
   *
   */
  props: {
    /**
     *
     */
    color: {
      type: String,
      default: "#286dc0",
    },

    /**
     *
     */
    colorBackground: {
      type: String,
      default: "#e3ebf6",
    },

    /**
     * min interval
     */
    min: {
      type: Number,
      default: -10,
    },

    /**
     * max interval
     */
    max: {
      type: Number,
      default: 10,
    },

    /**
     *
     */
    interval: {
      type: [Number, String],
      default: 1,
    },

    /**
     *
     */
    startInterval: {
      type: Number,
      default: -5,
      required: true,
    },

    /**
     * min selected interval
     */
    endInterval: {
      type: Number,
      default: 5,
      required: true,
    },
  },

  /**
   *
   */
  methods: {
    /**
     *
     */
    render() {
      let w = this.bar.clientWidth - this.maxBall.clientWidth;
      let interval = this.max - this.min;
      this.minLeft = ((this.startInterval - this.min) * w) / interval;
      this.maxLeft = ((this.endInterval - this.min) * w) / interval;
    },

    /**
     *
     */
    mouseDown(e) {
      this.clicked = true;
      this.mouseX = e.clientX;
      if (e.target == this.$refs.min) this.active = "min";
      else if (e.target == this.$refs.max) this.active = "max";
      this.temp = e.target.offsetLeft;
    },

    /**
     *
     */
    getBeginInterval() {
      if (this.active == "max") return this.minBall.offsetLeft;
      else return 0;
    },

    /**
     *
     */
    getEndInterval() {
      if (this.active == "max") return this.bar.clientWidth - this.maxBall.clientWidth;
      else return this.maxBall.offsetLeft;
    },

    /**
     *
     * @param {*} val
     */
    updateMinValue(val) {
      this.minLeft = val;
      let w = this.bar.clientWidth - this.maxBall.clientWidth;
      let percentage = val / (w / 100);
      let interval = this.max - this.min;
      let m = this.min + Math.round((percentage / 100) * interval);
      m = Math.ceil(m / this.interval);
      this.$emit("update:startInterval", m * this.interval);
    },

    /**
     *
     * @param {*} val
     */
    updateMaxValue(val) {
      this.maxLeft = val;
      let w = this.bar.clientWidth - this.maxBall.clientWidth;
      let percentage = val / (w / 100);
      let interval = this.max - this.min;
      let m = this.min + Math.round((percentage / 100) * interval);
      m = Math.floor(m / this.interval);
      this.$emit("update:endInterval", m * this.interval);
    },
    /**
     *
     */
    mouseMove(e) {
      if (this.clicked) {
        this.temp += e.clientX - this.mouseX;
        this.mouseX = e.clientX;
        if (this.temp >= this.getBeginInterval()) {
          if (this.temp <= this.getEndInterval()) {
            if (this.active == "min") this.updateMinValue(this.temp);
            else {
              this.updateMaxValue(this.temp);
            }
          } else {
            if (this.active == "min") this.updateMinValue(this.getEndInterval());
            else this.updateMaxValue(this.getEndInterval());
          }
        } else {
          if (this.active == "min") this.updateMinValue(this.getBeginInterval());
          else this.updateMaxValue(this.getBeginInterval());
        }
      }
    },

    /**
     *
     */
    mouseUp() {
      this.clicked = false;
      //no block!
      this.minBall.style.zIndex = this.active == "min" ? 1 : 0;
      this.maxBall.style.zIndex = this.active == "max" ? 1 : 0;
      this.active = "";
    },
  },
};
</script>

<style lang="scss">
.input-range {
  height: 6px;
  display: inline-block;
  position: relative;

  .bar {
    background-repeat: no-repeat no-repeat, no-repeat no-repeat !important;
    width: 100% !important;
    left: 0px;
    background-color: transparent;
    height: 6px;
    user-select: none;
    position: absolute;
    border: none !important;
    border-radius: 10px;
    display: inline-block;
  }

  .ball {
    user-select: none;
    margin-top: -5px;
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0px;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    border: none !important;

    &.front {
      z-index: 1;
    }
  }
}
</style>
