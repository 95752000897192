<template>
  <div
    :class="{
      'position-relative': positionRelative
    }"
  >
    <span
      class="tooltip-copy-to-clipboard"
      @click.stop="copyTextToClipboard"
      @mouseout="copyToClipboardInfo = $t('copy')"
      @mouseenter="showCopyToClipboardInfo"
    >
      <slot name="main" />
    </span>
    <span
      class="wrapper"
      :class="position"
      :style="customStyle"
    >
      <span class="tooltip-text">
        {{ copyToClipboardInfo }}
        <i
          v-if="copyToClipboardInfo == $t('copy')"
          class="inline-block fa-regular fa-copy ms-3"
        />
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: "CopyToClipboard",
  data() {
    return {
      copyToClipboardInfo: this.$t('copy'),
    }
  },

  props: {
    textToCopy: {
      type: String,
      default: ""
    },

    position: {
      type: String,
      default: "center"
    },

    translateY: {
      type: String,
      default: "calc(-70% - 32px)"
    },

    translateX: {
      type: String,
      default: "calc(-35% - 32px)"
    },
    positionRelative: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    customStyle() {
      return {
        "--translate-y": this.translateY,
        "--translate-x": this.translateX,
      }
    }
  },

  methods: {
    copyTextToClipboard() {
      navigator.permissions.query({ name: 'clipboard-write' }).then((result) => {
        if (result.state === "granted" || result.state === "prompt") {
          navigator.clipboard.writeText(this.textToCopy);
          this.copyToClipboardInfo = this.$t('success_copied');
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.center {
  left: 40%;
}

.position-relative {
  position: relative;
}

.wrapper {
  position: absolute;
  transform: translateY(var(--translate-y)) translateX(var(--translate-x));
  visibility: hidden;
  width: 200px;
  .tooltip-text {
    height: 32px;
    padding: .3rem 1rem;
    background-color: $color-text-light;
    color: $color-text-solid-black;
    border-radius: 10px;
    box-shadow: 0px 4px 18px 0px rgba(34, 54, 77, 0.12);
    z-index: 1;
    bottom: 150%;
    transition: opacity 0.3s;
  }
}

.tooltip-copy-to-clipboard:hover {
    + .wrapper {
      visibility: visible !important;
    }
}
</style>
