<template>
  <base-select
    :disabled="disabled"
    :error-label="errorLabel"
    :errors="errors"
    :has-tags="false"
    :label="label"
    :multiselect="true"
    :placeholder="placeholder"
    :prefix="prefix"
    :select-height="selectHeight"
    :select-width="selectWidth"
    :selected-item="showExhibitionValue"
    @update-term="updateTerm"
  >
    <template #opened-select-content>
      <div
        class="d-flex mb-2 align-items-center gap-3 border-bottom pb-2"
      >
        <input
          id="selectAll"
          class="form-check-input mt-0 "
          type="checkbox"
          v-model="checkAll"
        >
        <label
          for="selectAll"
          class="cursor-pointer"
        >
          {{ $t('select_all_f') }}
        </label>
      </div>
      <ul
        id="multi-select-list"
        v-if="filteredList.length > 0"
        class="overflow-auto list-unstyled tx-text-200 mb-0"
        style="max-height: 180px;"
      >
        <li
          v-for="(option, index) in filteredList"
          :key="`${option[label]}-${index}`"
          class="option mb-0 rounded-1 cursor-pointer d-flex align-items-center gap-3"
          style="padding: 0 16px 0 8px; line-height: 2"
        >
          <input
            :id="`${option[label]}-${index}`"
            class="form-check-input mt-0"
            type="checkbox"
            v-model="option.checked"
            @change="checkOptions"
            :class="{'active-all' : checkAll}"
          >
          <label
            :for="`${option[label]}-${index}`"
            class="cursor-pointer w-100"
          >
            {{ option[label] }}
          </label>
        </li>
        <infinity-pagination-observer
          v-if="validationObserver"
          :loading="loading"
          :threshold="1"
          margin="10px"
          parent-container="multi-select-list"
          @reload="$emit('reload')"
        />
      </ul>
      <p
        v-else
        class="m-0 text-center"
      >
        {{ $t('no_field_found') }}
      </p>
    </template>
  </base-select>
</template>

<script>
import BaseSelect from "@components/select/base/base-select.vue";
import InfinityPaginationObserver from "@components/infinity-pagination-observer.vue";

export default {
  name: 'MultiSelect',
  components: {
    InfinityPaginationObserver,
    BaseSelect,
  },

  emits: ['update:modelValue', 'reload'],

  props: {
    selectHeight: {
      type: String,
      default: '40px',
    },

    selectWidth: {
      type: String,
      default: '100%',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      required: true,
    },

    returnAllObject: {
      type: Boolean,
      default: false,
    },

    listData: {
      type: Array,
      required: true,
    },

    modelValue: {
      type: Array,
      default: () => []
    },

    placeholder: {
      type: String,
      default: 'Selecione',
    },

    errors: {
      type: Object,
      default: () => ({}),
    },

    customLabelReturn: {
      type: String,
      default: null,
    },

    errorLabel: {
      type: String,
      default: '',
    },

    prefix: {
      type: String,
      default: ""
    },

    validationObserver: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    blockReset: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      searchTerm: '',
      checkAll: false,
      isClickOption: false,
      arrayWithCheck: [],
      checksFlag: false
    }
  },

  computed: {
    filteredList() {
      return this.arrayWithCheck
        .filter((option) => option[this.label].toLowerCase().includes(this.searchTerm.toLowerCase()));
    },

    showExhibitionValue() {
      if (!this.modelValue.length) {
        return null;
      }
      return `${this.modelValue.length === this.listData.length ? this.$t('all_dropdown') : this.modelValue.length} ${this.$tc('selected_pluralization', this.modelValue.length)}`
    },
  },

  methods: {
    checkOptions() {
      this.checkAll = this.arrayWithCheck.every((option) => option.checked);
      this.isClickOption = true;
      this.selectItem();
    },

    selectItem() {
      const filteredData = this.arrayWithCheck
        .filter((option) => option.checked)
        .map((option) => {
          const filteredOption = {...option}
          delete filteredOption.checked;
          if (this.returnAllObject) {
            return filteredOption
          }
          if (this.customLabelReturn) {
            return filteredOption[this.customLabelReturn]
          }
          return filteredOption[this.label]
        });

      this.$emit('update:modelValue', filteredData);
    },

    updateTerm(term) {
      this.searchTerm = term;
    },

    resetSelect() {
      this.$emit('update:modelValue', []);
    },

    checkItems(items) {
      items.forEach((val) => {
        this.arrayWithCheck.forEach((check) => {
          const verification1 = this.returnAllObject && check[this.label] === val[this.label];
          const verification2 = this.customLabelReturn && check[this.customLabelReturn] === val;
          const verification3 = check[this.label] === val;
          if (verification1 || verification2 || verification3) {
            check.checked = true
          }
        })
      })
    },

    initializeArrayWithCheck() {
      this.arrayWithCheck = this.listData
        .map((option, index) => {
          if (this.arrayWithCheck[index] && this.arrayWithCheck[index].checked) {
            return this.arrayWithCheck[index];
          }

          return {
            ...option,
            checked: false
          }
        });
    }
  },

  beforeMount() {
    this.initializeArrayWithCheck();
  },

  beforeUnmount() {
    this.resetSelect();
  },

  watch: {
    checkAll: {
      handler() {
        if (!this.isClickOption || this.checkAll) {
          this.arrayWithCheck.forEach((option) => {
            option.checked = this.checkAll;
          })
          this.selectItem();
        }

        this.isClickOption = false
      },

    },
    listData: {
      handler(newVal) {
        if (newVal && !this.blockReset) {
          this.resetSelect();

          return
        }

        this.initializeArrayWithCheck()

      },
      immediate: true
    },

    modelValue: {
      handler(newVal) {
        if (this.listData.length > 0 && newVal.length === this.listData.length) {
          this.checkAll = true;
          return
        }
        this.checkItems(newVal)
      },

      immediate: true,
    },

    arrayWithCheck: {
      handler(val) {
        if (val.length > 0) {
          this.checkItems(this.modelValue)
        }
      },
      once: true
    }
  }
}
</script>

<style lang="scss" scoped>

.form-check-input.active-all:checked {
  background-color: #677C92;
}

.option:hover {
  background-color: $color-gray-blue-300;
}
</style>