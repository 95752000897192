import store from "@store/index"
import logo from "@assets/img/logo.svg";
import audioNotification from "@assets/audio/web_whatsapp.mp3";

const WhatsappNotificationPlayer = new Audio(audioNotification);

export default class UserSocket {
  #user = {};
  #socket = null;

  constructor(user, socket) {
    this.setUser(user);
    this.setSocket(socket);
  }

  setUser(user) {
    this.#user = user;
  }

  setSocket(socket) {
    this.#socket = socket;
  }

  getUser() {
    return this.#user;
  }

  hasAgentId(chat) {
    return Boolean((chat?.agent_id || chat?.agent?.id))
  }

  init() {
    if (this.getUser()) {
      if (this.isManager() || this.isAgent() || this.isSupervisor()) {
        this.newAgentChatWhatsApp();
        this.messageAckWhatsApp();
        this.transferChatWhatsApp();
        this.startSnoozeChatWhatsApp();
        this.finishChatWhatsApp();
        this.newGroupChatWhatsApp();
        this.newMessageWhatsApp();
        this.newQueueWhatsAppChat();
        this.queueChatResponseIsLate();
        this.clientResponseIsLate();
        this.chatAcceptedWhatsApp();
        this.queueChatResponseIsLate();
        this.deleteAgentMessageWhatsApp();
        this.newAgentMessageWhatsApp();
        this.newWhatsAppInternalMessage();
        this.newMessageTemplateWhatsApp();
      }

      if (this.isManager()) {
        this.newChatWhatsApp();
        this.whatsAppInstanceStatusChanged();
      }

      if (this.isAgent()) {
        this.endSnoozeChatWhatsApp();
        this.chatEndedConversationCycle();
        this.whatsAppGroupMessageSent();
      }
    }
  }

  isManager() {
    return this.getUser()?.role?.name === 'manager';
  }

  isSupervisor() {
    return this.getUser()?.role?.name === 'supervisor';
  }

  isAgent() {
    return this.getUser()?.role?.name === 'agent';
  }

  verifySearch({name, name_alias, number, filterSearch}) {
    const matchName = name.includes(filterSearch)
    const matchNameAlias = name_alias && name_alias.includes(filterSearch)
    const matchNumber = number.toString().includes(filterSearch) || filterSearch === ""

    return matchName || matchNameAlias || matchNumber
  }

  verifyFilter({name, name_alias, number}, team_id, group_channel_id, agent_id, contact_tags) {
    const filters = store.getters['whatsapp/chat/getFilters']
    const matchSearch = this.verifySearch({name, name_alias, number, filterSearch: filters.search});
    const matchTeam = (filters.team_id === null || filters.team_id === '') || filters.team_id === team_id
    const matchAgent = agent_id ? filters.agent_id === null || agent_id === filters.agent_id : true
    const matchGroupChannel = filters.group_channel_id === null || filters.group_channel_id === group_channel_id
    const matchTags = !filters.tags_ids.length || contact_tags.some(contactTag => filters.tags_ids.includes(contactTag.tag_id))

    return matchSearch && matchTeam && matchGroupChannel && matchAgent && matchTags
  }

  verifySearchFilter({name, name_alias, number}, contact_tags) {
    const {search, tags_ids} = store.getters['whatsapp/chat/getFilters'];

    const matchSearch =
      !search ||
      name.includes(search) ||
      name_alias.includes(search) ||
      number.toString().includes(search);

    const matchTags =
      !tags_ids.length ||
      contact_tags.some(contactTag => tags_ids.includes(contactTag.tag_id));

    return matchSearch && matchTags
  }

  newAgentMessageWhatsApp() {
    this.#socket.on("new-agent-message-whatsapp", ({message,chat}) => {
      if (this.getUser().id !== message.agent_id) {
        store.dispatch("whatsapp/message/addRecentMessage", message)
        store.commit("whatsapp/message/setNewMessage", message)
      }

      const currentChat = store.getters['whatsapp/chat/getCurrentChat'][chat.id];
      const matchFilters = this.verifyFilter(chat.contact, chat.team_id, chat.instance.group_channel_id, chat?.agent_id, chat.contact_tags)

      if (matchFilters) {
        const talksTab = store.getters['whatsapp/chat/getTalksTab'];

        if (chat.chatbot?.is_active && this.isManager() && talksTab === "inChatbot") {
          store.commit("whatsapp/chat/updateInChatbotTalkOrder", chat)
          return
        }
        if (chat.is_group) {
          store.commit("whatsapp/chat/updateGroupTalkOrder", chat);
          return
        }
        if (this.hasAgentId(chat)) {
          if (currentChat?.queue_response_is_late?.response_is_late) {
            store.dispatch("whatsapp/chat/fetchVerifyNotifiedChats", "queue")
          }
          else if (currentChat?.lag_to_response?.response_is_late) {
            store.commit("whatsapp/chat/setRemoveUnanswerMark", chat.id);
            store.dispatch("whatsapp/chat/fetchVerifyNotifiedChats", "in_progress")
          }

          if (talksTab === "snooze" || store.getters["whatsapp/chat/getSnoozedByManager"]?.id === chat.id) {
            store.commit("whatsapp/chat/removeChatFromSnooze", { id: message.chat_id });
          }

          store.commit("whatsapp/chat/updateInProgressTalkOrder", chat);

        }
      }
    });
  }

  newMessageWhatsApp() {
    this.#socket.on("new-message-whatsapp", ({chat, message}) => {
      const currentUser = store.getters['auth/getUser']
      const openChat = store.getters['whatsapp/chat/getOpenChat']
      const enableNotification = chat.id === openChat?.id ? openChat?.hasBottomButton : true
      const inImpersonate = store.getters['auth/inImpersonate']
      const isOwner = chat.agent_id === currentUser.id && !inImpersonate

      if (Notification.permission === "granted" && (enableNotification || document.hidden) && chat?.agent_id === currentUser.id) {
        new Notification("3C Plus - Omnichannel", {
          body: message.body, icon: logo, silent: true,
        });

        WhatsappNotificationPlayer.volume = 0.1;
        WhatsappNotificationPlayer.play();
      }

      let matchFilters = null
      if (this.isAgent()) {
        matchFilters = this.verifySearchFilter(chat.contact, chat.contact_tags)
      } else {
        matchFilters = this.verifyFilter(chat.contact, chat.team_id, chat.instance.group_channel_id, chat?.agent_id, chat.contact_tags)
      }
      if (this.hasAgentId(chat) && matchFilters) {
        const talksTab = store.getters['whatsapp/chat/getTalksTab'];
        if (talksTab === "snooze") {
          store.commit("whatsapp/chat/removeChatFromSnooze", {
            id: message.chat_id
          });
        }
        chat.is_group?
          store.commit("whatsapp/chat/updateGroupTalkOrder", chat) :
          store.commit("whatsapp/chat/updateInProgressTalkOrder", chat)
      }

      const enableClearUnread = chat.id === openChat?.id && !openChat?.hasBottomButton && isOwner;
      if (enableClearUnread) {
        store.dispatch("whatsapp/chat/clearUnreadMessageCount", chat)
        chat.unread = 0
      }

      if (enableNotification && chat.unread === 1) {
        if (chat.is_group) {
          store.commit("whatsapp/chat/incrementUnreadGroupsConversation")
        } else if (this.hasAgentId(chat)){
          store.commit("whatsapp/chat/incrementUnreadConversation")
        }
      }

      store.dispatch("whatsapp/message/addRecentMessage", message)
      store.commit("whatsapp/message/setNewMessage", message)

      const currentChat = store.getters["whatsapp/chat/getCurrentChat"][chat.id];
      if (chat.id === currentChat?.id) {
        store.commit("whatsapp/chat/setRemovedFromTheGroup", chat);
        store.commit('whatsapp/chat/updateWabaMessageReceived', {
          chat_id: currentChat.id, value: chat.waba_message_received
        })
      }
    })
  }

  newAgentChatWhatsApp() {
    this.#socket.on("new-agent-chat-whatsapp", event => {
      if (this.isManager()) {
        const chat = event.chat;
        const agentId = !chat.is_group ? chat.agent_id : null;
        const contactTags = chat.contact_tags || [];

        if (this.verifyFilter(chat.contact, chat.team_id, chat.instance.group_channel_id, agentId, contactTags)) {
          store.commit("whatsapp/chat/updateInProgressTalkOrder", event.chat)
        }
      }

      if (this.isAgent()) {
        if (this.verifySearchFilter(event.chat.contact, event.chat.contact_tags || [])) {
          store.commit("whatsapp/chat/updateInProgressTalkOrder", event.chat)
        }
      }
    });
  }

  messageAckWhatsApp() {
    this.#socket.on("message-ack-whatsapp", (event) => {
      const currentChat = store.getters['whatsapp/chat/getCurrentChat'][event.message.chat_id];
      store.dispatch("whatsapp/message/updateMessageACK", event.message);
      if (currentChat) {
        store.commit('whatsapp/chat/updateNewMessageTemplateWhatsapp', {
          chat: currentChat,
        })
      }
    });
  }

  transferChatWhatsApp() {
    this.#socket.on("transfer-chat-whatsapp", ({agentId, chat, message}) => {
      const updateCurrentChatOwner = {
        currentChatId: chat?.id,
        userId: null,
        userName: null
      }
      if (!agentId) {
        store.commit("whatsapp/chat/removeChatFromQueue", chat)
      }

      if (this.hasAgentId(chat)) {
        updateCurrentChatOwner.userId = chat?.agent_id || chat?.agent?.id;
        updateCurrentChatOwner.userName = chat?.agent_name || chat?.agent?.name;
      }

      if (this.isManager()) {
        store.commit("whatsapp/chat/updateCurrentChatOwner", updateCurrentChatOwner);
        return true;
      }

      if (this.isAgent()) {
        const currentChatOwner = message.inter_message_data.current_agent;
        const previousChatOwner = message.inter_message_data.previous_agent;

        const currentUser = store.getters["auth/getUser"];
        const instances = store.getters["whatsapp/instance/getInstances"]
        const openChat = store.getters['whatsapp/chat/getOpenChat']

        if (!previousChatOwner && !chat.agent_id && agentId) {
          store.commit("whatsapp/chat/addChatToQueue", chat);
          store.commit("whatsapp/chat/setCurrentChat", chat);
          return
        }
        if (currentUser.id === previousChatOwner.id) {
          store.commit("whatsapp/chat/removeChatById", {id: chat.id});

          if (openChat?.id === chat.id && message.agent.role.data.name !== "agent") {
            store.commit("whatsapp/chat/setTransferedByManager", chat);
          } else if (chat.unread >= 1) {
            store.commit("whatsapp/chat/decrementUnreadConversation")
          }
        }

        if (chat.agent_id && currentUser.id === currentChatOwner.id) {
          store.commit("whatsapp/chat/updateInProgressTalkOrder", chat);
        } else if (!chat.agent_id && instances.some(instance => instance.id === currentChatOwner.id)) {
          store.commit("whatsapp/chat/addChatToQueue", chat);
          store.commit("whatsapp/chat/setCurrentChat", chat);
        }

        return true
      }
    });
  }

  newWhatsAppInternalMessage() {
    this.#socket.on("new-whatsapp-internal-message", ({message, chat}) => {
      const openChat = store.getters['whatsapp/chat/getOpenChat']
      if(openChat && message.type === 'snooze-message'){
        store.dispatch("whatsapp/message/addRecentMessage", message)
      }
      if (this.getUser().id !== message.agent_id && message.type === 'internal-message') {
        store.dispatch("whatsapp/message/addRecentMessage", message)
        store.commit("whatsapp/message/setNewMessage", message)
      }
      if ((message.type === "transfer" || message.type === "protocol-message")) {
        const matchFilters = this.verifyFilter(chat.contact, chat.team_id, chat.instance.group_channel_id, chat?.agent_id, chat.contact_tags)
        if (this.hasAgentId(chat) && matchFilters) {
          store.commit("whatsapp/chat/updateInProgressTalkOrder", chat)
        }
      }
    });
  }

  startSnoozeChatWhatsApp() {
    this.#socket.on("start-snooze-chat-whatsapp", (event) => {
      const openChat = store.getters['whatsapp/chat/getOpenChat']

        store.commit('whatsapp/chat/setChatAsInSnooze', {
          chat_id: event.chat.id, end_snooze: event.chat.end_snooze,
        });
      if (openChat) {
        store.commit("whatsapp/chat/setSnoozedByManager", event.chat);
      }

    });
  }

  #isOpenedChat(openChat, eventChat) {
    const isOpened = openChat && (openChat.id === eventChat.id);
    const isFinished = openChat && (openChat.finished && openChat.number + openChat.instance_id === eventChat.id);
    return {
      isOpened, isFinished, both: isOpened || isFinished,
    };
  }

  finishChatWhatsApp() {
    this.#socket.on("finish-chat-whatsapp", (event) => {
      const openChat = store.getters['whatsapp/chat/getOpenChat']

      store.commit("whatsapp/chat/removeInProgress", event.chat)
      store.commit("whatsapp/chat/decrementUnreadConversation")
      store.commit("whatsapp/chat/removeChatById", {id: event.chat.id});

      const talksTab = store.getters['whatsapp/chat/getTalksTab'];
      if (this.isManager()) {
        if (talksTab === "finished") {
          store.commit("whatsapp/chat/updateFinished", event.chat)
        }

        if (this.#isOpenedChat(openChat, event.chat).both) {
          event.chat.id = event.chat._id
          store.commit("whatsapp/chat/setOpenChat", event.chat)
        }
      }

      if (this.isAgent()) {
        event.chat.finished = true;

        if (talksTab === "finished") {
          store.commit('whatsapp/chat/clearFinished')
          store.dispatch("whatsapp/chat/fetchFinished")
        }

        if (this.#isOpenedChat(openChat, event.chat).isOpened) {
          store.commit("whatsapp/chat/setFinalizedByManager", event.chat)
        }

        if (this.#isOpenedChat(openChat, event.chat).isFinished) {
          event.chat.id = event.chat._id
          store.commit("whatsapp/chat/setOpenChat", event.chat)
          store.commit("whatsapp/chat/setCurrentChat", event.chat)
        }
      }
    });
  }

  newGroupChatWhatsApp() {
    this.#socket.on("new-group-chat-whatsapp", (event) => {
      store.commit("whatsapp/chat/addGroupsByNewGroupEvent", event.chat)
      store.commit("whatsapp/chat/updateGroupTalkOrderByEvent", event.chat)
    });
  }

  newQueueWhatsAppChat() {
    this.#socket.on("new-queue-chat-whatsapp", event => {
      if (event.chat.chatbot.is_active) {
        if (this.isAgent()) {
          return true
        }
        store.commit("whatsapp/chat/addChatToInChatbot", event.chat)
      } else {
        store.commit("whatsapp/chat/removeFromCurrentChat", event.chat)
        store.commit("whatsapp/chat/addChatToQueue", event.chat)
        store.commit("whatsapp/chat/setCurrentChat", event.chat)
      }
    });
  }

  clientResponseIsLate() {
    this.#socket.on("client-response-is-late", (event) => {
      store.commit("whatsapp/chat/setClientResponseLate", event)
    });
  }

  chatAcceptedWhatsApp() {
    this.#socket.on("chat-accepted-whatsapp", event => {
      store.commit("whatsapp/chat/removeChatFromQueue", event.chat);

      if (event.chat?.unread) {
        store.commit("whatsapp/chat/incrementUnreadConversation");
      }

      const currentChat  = store.getters["whatsapp/chat/getCurrentChat"][event.chat.id]
      const chat = currentChat ? Object.assign(currentChat, event.chat) : event.chat;
      if (this.isManager()) {
        store.commit("whatsapp/chat/addChatToInProgress", {
          chat: chat, agent_id: (event.agent.id || event?.agent_id)
        });
      }
    });
  }

  newChatWhatsApp() {
    this.#socket.on("new-chat-whatsapp", event => {
      if (this.isManager()) {
        const chat = event.chat;
        const agentId = !chat.is_group ? chat.agent_id : null;
        const contactTags = chat.contact_tags || [];

        if (this.verifyFilter(chat.contact, chat.team_id, chat.instance.group_channel_id, agentId, contactTags)) {
          store.commit("whatsapp/chat/removeFromCurrentChat", event.chat);
          store.commit("whatsapp/chat/addChatToQueue", event.chat);
          store.commit("whatsapp/chat/setCurrentChat", event.chat);
        }
      }
    });
  }

  whatsAppInstanceStatusChanged() {
    this.#socket.on("whatsapp-instance-status-changed", (event) => {
      if (this.isManager()) {
        const instance = event.instance
        store.commit("manager/whatsapp_group_channel/setInstanceStatusInGroupChannel", {
          groupChannelId: instance.group_channel_id, instanceId: instance.id, status: instance.status,
        })
      }
    });
  }

  queueChatResponseIsLate() {
    this.#socket.on("queue-chat-response-is-late", (event) => {
      store.commit("whatsapp/chat/setClientResponseLateQueue", event)
      if (this.isManager()) {
        store.dispatch("whatsapp/chat/fetchQueue")
      }
    });
  }

  endSnoozeChatWhatsApp() {
    this.#socket.on("end-snooze-chat-whatsapp", (event) => {
      if (this.isAgent()) {
        const talksTab = store.getters['whatsapp/chat/getTalksTab'];
        if (talksTab === "snooze") {
          store.commit("whatsapp/chat/removeChatFromSnooze", event.chat);
        }
        store.commit("whatsapp/chat/updateInProgressTalkOrder", event.chat);
      }
    });
  }

  deleteAgentMessageWhatsApp() {
    this.#socket.on("delete-agent-message-whatsapp", ({chat, message}) => {
      const currentChat = store.getters['whatsapp/chat/getCurrentChat'][chat.id];
      if (currentChat?.id) {
        store.dispatch("whatsapp/message/markMessageAsDeleted", {...message, internal_id: message._id});
      }
    })
  }

  newMessageTemplateWhatsApp() {
    this.#socket.on('new-message-template-whatsapp', event => {
        store.commit('whatsapp/chat/updateNewMessageTemplateWhatsapp', {
          chat: event.chat,
        })
    })
  }

  chatEndedConversationCycle() {
    this.#socket.on('chat-ended-conversation-cycle', event => {
      if (this.isAgent()) {
        store.commit('whatsapp/chat/updateNewMessageTemplateWhatsapp', {
          chat: event.chat,
        })
      }
    })
  }

  whatsAppGroupMessageSent() {
    this.#socket.on("whatsapp-group-message-sent", event => {
      if (this.isAgent()) {
        const message = event.message;
        const user = store.getters["auth/getUser"];
        const currentChat = store.getters["whatsapp/chat/getCurrentChat"][message.chat_id];

        if ((message.agent.id !== user.id || message?.agent_id !== user.id) && message.chat_id === currentChat?.id) {
          store.dispatch("whatsapp/message/addRecentMessage", message)
          store.commit("whatsapp/message/setNewMessage", message)
        }

        const groups = store.getters["whatsapp/chat/getGroups"]
        if (groups[event.message.chat_id]) {
          const chat = groups[event.message.chat_id]
          store.commit("whatsapp/chat/updateGroupTalkOrder", chat);
          store.commit("whatsapp/chat/setChatLastMessage", {chatType: "groups", message: event.message})
        }
      }
    });
  }

  groupMessageSent() {
    this.#socket.on("group-message-sent", () => {
    });
  }
}


