export default {
  getLoadingFlags: state => state.loadingFlags,

  // Active Companies
  getActiveCompanies: state => state.activeCompanies,
  getActiveCompaniesPagination: state => state.activeCompaniesPagination,
  getActiveCompaniesParams: (state) =>
    Object.assign(
      { page: state.activeCompaniesPagination.current_page },
      state.activeCompaniesFilters,
      { include: state.activeCompaniesIncludes.join(",") }
    ),

  // Blocked Companies
  getBlockedCompanies: (state) => state.blockedCompanies,
  getBlockedCompaniesPagination: (state) => state.blockedCompaniesPagination,
  getBlockedCompaniesParams: (state) =>
    Object.assign(
      { page: state.blockedCompaniesPagination.current_page },
      state.blockedCompaniesFilters,
      { include: state.blockedCompaniesIncludes.join(',') }
    ),

  // Trashed Companies
  getTrashedCompanies: (state) => state.trashedCompanies,
  getTrashedCompaniesPagination: (state) => state.trashedCompaniesPagination,
  getTrashedCompaniesParams: (state) =>
    Object.assign(
      { page: state.trashedCompaniesPagination.current_page },
      state.trashedCompaniesFilters,
      { include: state.trashedCompaniesIncludes.join(',') }
    ),

  getListOfCompaniesWithoutPagination : state => state.listOfCompaniesWithoutPagination,
  getPagination: (state) => state.pagination,
  getCompanyRoutesList: (state) => state.companyRoutesList,
  getRegister: (state) => state.register,
  getErrors: state => state.errors,
  getRemoveCompanyErrors: state => state.removeCompanyErrors,
  getCompanyHistory: (state) => state.companyHistory,
  getCompanyHistoryParams: (state) =>
    Object.assign  (
      {
        'page': state.companyHistoryPagination.current_page,
        'per_page': state.companyHistoryPagination.per_page
      }
    , state.companyHistoryFilters),
  getCompanyHistoryPagination: (state) => state.companyHistoryPagination,
  getBlockCompaniesParams: (state) => state.blockCompaniesParams,
  getCompanyWithoutPaginationParams: (state) =>
    Object.assign(
      state.filters, { include: state.includes.join(',') }
    ),
  getRemoveCompanyParams: (state) =>
    Object.assign(
      {
        'domain': state.removeCompanyDomain
      }
    ),
  getTotalLogged: state => state.totalLogged,
  getTotalMaxLogin: state => state.totalMaxLogin,
  getCallStats: state => state.callStats,
  getReceptiveNumbers: (state) => state.receptiveNumbersList,
  getReceptiveNumbersPagination: state => state.receptiveNumbersPagination,
  getReceptiveNumbersParams: state => Object.assign(
    { 'page': state.receptiveNumbersPagination.current_page, 'per_page': state.receptiveNumbersPagination.per_page },
    state.receptiveNumbersFilters
  ),
  getCompanyRoutesPagination: state => state.companyRoutesPagination,
  getCompanyRoutesParams: state => Object.assign(
    { 'page': state.companyRoutesPagination.current_page }
  ),
  getReceptiveNumbersErrors: state => state.receptiveNumbersErrors,
  getCompanyRoutesErrors: state => state.companyRoutesErrors,
  getTelephonyRatesErrors: state => state.telephonyRatesErrors,
  getCompanyBilling: state => state.companyBilling,
  getCompanyCredit: state => state.companyCredit,
  getCompanyCreditParams: state => Object.assign(
    {
      'page': state.companyCreditPagination.current_page,
      'per_page': state.companyCreditPagination.per_page
    }
    , state.companyCreditFilters
  ),
  getCompanyCreditPagination: state => state.companyCreditPagination,
  getNewCompanyDataFeedback: state => state.newCompanyDataFeedback
}
