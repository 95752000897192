<template>
  <crm-page-loading
    v-if="loadingFlags.fetchFunnels"
    title="Carregando suas oportunidades"
  />
  <div
    v-else-if="currentFunnel"
    :class="{
      'crm-funnel': activeTab === 'kanban',
    }"
    class="row flex-column"
  >
    <div class="mt-4 col-auto">
      <div class="row align-items-start justify-content-between">
        <funnel-list-select />
        <div
          v-if="currentFunnel && currentFunnel.id"
          class="col-6 d-flex justify-content-end align-items-center gap-3"
        >
          <div
            v-if="activeTab === 'kanban'"
            class="col-6"
          >
            <div
              class="input-group input-group-merge input-group-reverse align-items-center"
              style="border-color: #D2DDEC;"
            >
              <input
                v-model="filter.search"
                :class="{'disabled': loading.contactsLoading}"
                :placeholder="$t('placeholders.search_capitalize')"
                class="form-control bg-transparent rounded-4"
                style="font-size: 14px;height: 44px"
                type="search"
                @input="search"
              >
              <div
                class="input-group-text bg-transparent rounded-4"
                style="height: 44px;"
              >
                <span
                  class="fa-regular fa-search m-0 tx-text-gray fs-6"
                />
              </div>
            </div>
          </div>
          <router-link
            v-if="isManager"
            :to="`/manager/crm/${currentFunnel.id}/settings`"
            class="btn btn-white"
          >
            <i class="fa-regular fa-gear tx-text-gray m-0" />
          </router-link>
          <template v-if="!isAgent">
            <router-link
              v-if="!currentFunnel.is_importing"
              :to="`/manager/crm/${currentFunnel.id}/import`"
              class="btn btn-white"
            >
              <i class="fa-regular fa-file-import tx-text-gray" />
            </router-link>
            <in-importing-button v-else />
          </template>
          <button
            class="btn btn-primary btn-users"
            style="width: 208px;"
            @click="$router.push(`${currentFunnel.id}/opportunity`)"
          >
            <i class="fe fe-plus" />
            {{ $t("new_opportunity") }}
          </button>
        </div>
      </div>
      <div class="d-flex pt-3 position-relative">
        <ul
          id="nav-tab"
          class="nav nav-tabs col-12"
          role="tablist"
        >
          <!--Todo: Remover condicional allowCompany ao subir CRM Dashboard para o ambiente de produção-->
          <li
            v-if="false"
            class="nav-item"
            role="presentation"
          >
            <button
              :class="activeTab === 'dashboard' ? 'active' : ''"
              class="nav-link"
              id="tab-dashboard"
              data-bs-toggle="tab"
              data-bs-target="#funnel-dashboard"
              type="button"
              role="tab"
              aria-controls="funnel-dashboard"
              aria-selected="true"
              data-test="dashboard-tab"
              @click="activeTab = 'dashboard'"
            >
              {{ $t('capitalizes.dashboard') }}
            </button>
          </li>
          <li
            class="nav-item"
            role="presentation"
          >
            <button
              class="nav-link"
              id="tab-kanban"
              aria-controls="funnel-kanban"
              aria-selected="true"
              :class="activeTab === 'kanban' ? 'active' : ''"
              data-bs-target="#funnel-kanban"
              data-bs-toggle="tab"
              data-test="kanban-tab"
              role="tab"
              type="button"
              @click="activeTab = 'kanban'"
            >
              {{ $t('funnel') }}
            </button>
          </li>
          <li
            class="nav-item"
            role="presentation"
          >
            <button
              id="tab-activities"
              aria-controls="funnel-activities"
              aria-selected="true"
              class="nav-link"
              :class="activeTab === 'activities' ? 'active' : ''"
              data-bs-target="#funnel-activities"
              data-bs-toggle="tab"
              data-test="activities-tab"
              role="tab"
              type="button"
              @click="activeTab = 'activities'"
            >
              {{ $t('activities') }}
            </button>
          </li>
        </ul>
        <div
          v-if="false"
          class="amount-opportunities fw-medium d-flex align-items-center justify-content-end gap-2 position-absolute"
        >
          <p class="mb-0">
            {{ formatCurrency(currentFunnel.products_value) }}
          </p>
          <i
            class="fa-solid fa-circle"
            style="font-size: 4px"
          />
          <p class="mb-0">
            {{ currentFunnel.opportunities_quantity }}
          </p>
          <p class="mb-0">
            {{ currentFunnel.opportunities_quantity > 0 ? $t('opportunities') : $t('opportunity') }}
          </p>
        </div>
      </div>
    </div>

    <div
      v-if="currentFunnel && currentFunnel.id"
      class="tab-content col overflow-hidden"
    >
      <div
        v-if="false"
        style="overflow-y: auto; overflow-x: hidden; max-height: 100%"
        class="tab-pane fade"
        id="funnel-dashboard"
        aria-labelledby="tab-dashboard"
        role="tabpanel"
        tabindex="0"
        :class="activeTab === 'dashboard' ? 'show active' : ''"
      >
        <crm-dashboard-index />
      </div>
      <div
        class="tab-pane fade h-100"
        id="funnel-kanban"
        aria-labelledby="tab-kanban"
        :class="activeTab === 'kanban' ? 'show active' : ''"
        role="tabpanel"
        tabindex="0"
      >
        <div class="col-auto my-4">
          <div class="d-flex col-12 justify-content-between align-items-center">
            <status-button @select-status="selectStatus" />
            <agent-dropdown
              v-if="!isAgent"
              id="crm-funnel"
              @select-agents="selectAgents"
            />
          </div>
        </div>
        <kanban-board @list-activities="listActivities" />
      </div>

      <div
        id="funnel-activities"
        aria-labelledby="tab-activities"
        class="tab-pane fade"
        :class="activeTab === 'activities' ? 'show active' : ''"
        role="tabpanel"
        tabindex="0"
      >
        <activity-dashboard />
      </div>
    </div>
    <ai-assistant v-if="!isAgent && [8078, 30, 8040, 8742].includes(user.company.id)" />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import _ from "lodash";
import KanbanBoard from "@crm/components/kanban/kanban-board.vue";
import FunnelListSelect from "@crm/components/shared/funnel-list-select.vue";
import AgentDropdown from "@crm/pages/manager/crm-settings/components/agent-dropdown.vue";
import CrmPageLoading from "@crm/components/shared/crm-page-loading.vue";
import ActivityDashboard from "@crm/components/activity/activity-dashboard.vue";
import InImportingButton from "../components/funnel/in-importing-button.vue";
import StatusButton from "@crm/pages/manager/crm-settings/components/status-button.vue";
import {Utils, elementObserverById} from "@/utils";
import AiAssistant from "@crm/components/ai-assistant/ai-assistant.vue";
import CrmDashboardIndex from "@crm/pages/dashboard/crm-dashboard-index.vue";

export default {
  components: {
    AiAssistant,
    CrmDashboardIndex,
    InImportingButton,
    StatusButton,
    CrmPageLoading,
    FunnelListSelect,
    ActivityDashboard,
    KanbanBoard,
    AgentDropdown
  },

  data() {
    return {
      filter: {
        search: null,
      },
      activeTab: 'kanban',
      //Todo: Remover allowedCompanies ao subir CRM Dashboard para o ambiente de produção
      allowedCompanies: [
        {
          name: 'Bettega',
          id: 30
        }
      ]
    }
  },

  computed: {
    ...mapGetters("crm/kanban", {
      steps: "getSteps",
    }),
    ...mapGetters("crm/funnels", {
      funnels: "getFunnels",
      currentFunnel: "getCurrentFunnel",
      loadingFlags: "getLoadingFlags"
    }),
    ...mapGetters("auth", {
      user: "getUser",
      isAgent: "isAgent",
      isManager: "isManager",
    }),
    ...mapGetters("crm/opportunities", {
      loading: "getLoadingFlags",
      opportunityFilters: "getOpportunityFilters"
    }),
    ...mapGetters("crm/tour", {
      currentTab: "getCurrentTab"
    }),

    //Todo: Remover allowCompany ao subir CRM Dashboard para o ambiente de produção
    allowCompany() {
      return this.allowedCompanies.some((company) => company?.id === this.user?.company?.id)
    }
  },
  mounted() {
    if (this.currentTab === 'activities') {
      this.activeTab = 'activities'
    }

    this.setActivityCard({});
  },

  methods: {
    formatCurrency: Utils.formatCurrency,
    elementObserverById,
    ...mapMutations('crm/opportunities', ['setOpportunityFilters']),
    ...mapActions('crm/opportunities', ['fetchFunnelOpportunities']),
    ...mapActions('crm/funnels', ['fetchFunnels']),
    ...mapMutations("crm/activities", [
      "setActivityCard"
    ]),

    selectStatus(value) {
      this.setOpportunityFilters({
        filter: "status",
        value: value
      })
      this.getContactsList()
    },

    selectAgents(value) {
      if (value.length > 0) {
        this.setOpportunityFilters({
          filter: "owner_id",
          value: value
        })
      } else {
        this.setOpportunityFilters({
          filter: "owner_id",
          value: []
        })
      }
      this.getContactsList()
    },

    search: _.debounce(function () {
      const search = this.filter.search ? this.filter.search : null

      this.setOpportunityFilters({
        filter: "search",
        value: search
      })
      this.getContactsList()
    }, 1000),

    getContactsList() {
      if (!this.loading.fetchFunnelOpportunities) {
        this.fetchFunnelOpportunities(this.currentFunnel.steps)
      }
    },

    listActivities(id) {
      this.activeTab = 'activities';
      this.$router.push({
        path: `/manager/crm/${this.currentFunnel.id}/opportunity/${id}`,
        hash: `#${this.$t('activities_lowercase')}`
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.crm-funnel {
  height: 100vh !important;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
}

input::placeholder {
  color: #677C92;
}

.amount-opportunities {
  top: 40%;
  right: 0;
}
</style>
