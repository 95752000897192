export default {
  /**
   * Clear the calls array
   *
   * @param {Object} state
   * @returns
   */
  clearCalls: state => (state.calls = []),

  /**
   * Fill the calls array with the current
   * calls retrieved by the api
   *
   * @param {Object} state
   * @param {Array} calls
   */
  setCalls(state, calls) {
    for (let i in calls) {
      if (calls[i].queue_id !== undefined) {
        state.calls[i] = calls[i];
        const call = state.calls[i];
        if (call.agent && call.agent.id) {
          const speaking_time = call.current_time - call.connected_time;

          call.speaking_time = speaking_time;
          call.agent = `${call.agent.extension.extension_number} - ${call.agent.name}`;
          call.interval = setInterval(() => {
            call.speaking_time++;
          }, 1000);
        } else {
          const hold_time = call.current_time - call.queue_entry_time;

          call.hold_time = hold_time;
          call.agent = `-`;
          call.interval = setInterval(() => {
            call.hold_time++;
          }, 1000);
        }
      }
    }

    state.calls.sort((a, b) => b.speaking_time - a.speaking_time);
  },


  /**
   * Set the call status as hold when the receptive
   * call enter the queue.
   *
   * @param {Object} state
   * @param {Object} call
   */
  setCallsStatusHold(state, call) {
    let found = false;
    for (let index in state.calls) {
      const connected = state.calls[index];
      if (connected.telephony_id === call.telephony_id) {
        found = true;
      }
    }
    if (!found) {
      const count = state.calls.length;
      state.calls[count]= {};
      state.calls[count].telephony_id = call.telephony_id;
      state.calls[count].phone = call.phone;
      state.calls[count].hold_time = 0;
      state.calls[count].agent = "-";
      state.calls[count].interval = setInterval(() => {
        state.calls[count].hold_time++;
      }, 1000);
    }
  },

  /**
   * Set the call status as connected when the
   * call that was in hold is answered.
   *
   * @param {Object} state
   * @param {Object} payload
   */
  setCallsStatusConnected (state, payload) {
    for (let index in state.calls) {
      const connected = state.calls[index]
      if (connected.telephony_id === payload.call.telephony_id) {
        clearInterval(state.calls[index].interval);
        state.calls[index].telephony_id = payload.call.telephony_id;
        state.calls[index].phone = payload.call.phone;
        state.calls[index].speaking_time = 0;
        state.calls[index].agent = `${payload.extension} - ${payload.agent.name}`;
        state.calls[index].interval = setInterval(() => {
          state.calls[index].speaking_time++;
        }, 1000);
      }
    }
  },

  /**
   * Remove the call from the array based on the
   * telephony_id received by the socket event.
   *
   * @param {Object} state
   * @param {String} telephony_id
   */
  removeCall (state, telephony_id) {
    for (let index in state.calls) {
      const call = state.calls[index];
      if (call.telephony_id === telephony_id) {
        clearInterval(state.calls[index].interval);
        state.calls.splice(index, 1);
      }
    }
  },
};
