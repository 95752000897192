export default {
  startLoadingFlag: (state, flag) => (state.loadingFlags[flag] = true),
  stopLoadingFlag: (state, flag) => (state.loadingFlags[flag] = false),
  setCallPABX: (state, callPABX) => (state.callPABX = callPABX),
  setCallStatus: (state, callStatus) => (state.callPABX.status = callStatus),
  setConsultedAgentId: (state, consultedAgentId) => (state.consultedAgentId = consultedAgentId),
  setRingGroupUsers: (state, ringGroupUsers) => (state.ringGroupUsers = ringGroupUsers),
  setInternalCallAgent: (state, internalCallAgent) => (state.internalCallAgent = internalCallAgent),
  setShowToast: (state, showToast) => (state.showToast = showToast),
};
