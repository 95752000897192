import axios from "@crm/plugins/axios-config";

export default {
  fetchFunnels ({commit, dispatch}, funnelId) {
    commit('startLoading', 'fetchFunnels');
    return new Promise((resolve, reject) => {
      axios.get(`/funnels`).then((response) => {
        const funnels = response.data.data
        commit('setPagination', response.data.meta)
        commit('setFunnels', funnels);
        let currentFunnel = null
        if (funnels.length) {
          const findFunnel = funnels.find(funnel => funnel.id == funnelId)
          findFunnel ? currentFunnel = findFunnel : currentFunnel = funnels[0]
          commit('setCurrentFunnel', currentFunnel)
          dispatch('updateLastAccessedFunnel', {
            team_id: currentFunnel.team.id,
            funnel_id: currentFunnel.id
          })
        }
        resolve(currentFunnel)
      }).catch(() => {
        commit('system/setErrorMessage', 'Erro ao carregar os funis', {root: true})
        reject()
      }).finally(() => {
        commit('stopLoading', 'fetchFunnels');
      })
    })
  },

  paginatedFetchFunnels({commit, dispatch, getters}, payload) {
    commit('startLoading', 'selectFunnels');
    return new Promise((resolve, reject) => {
      axios.get(`/funnels?page=${payload.page}`).then((response) => {
        const funnels = response.data.data
        commit('setPagination', response.data.meta)
        const paginatedFunnels = getters.getFunnels.concat(funnels);
        commit('setFunnels', paginatedFunnels);
        let currentFunnel = null
        if (funnels.length) {
          const findFunnel = funnels.find(funnel => funnel.id == payload.funnelId)
          findFunnel ? currentFunnel = findFunnel : currentFunnel = funnels[0]
          commit('setCurrentFunnel', currentFunnel)
          dispatch('updateLastAccessedFunnel', {
            team_id: currentFunnel.team.id,
            funnel_id: currentFunnel.id
          })
        }
        resolve(currentFunnel)
      }).catch(() => {
        commit('system/setErrorMessage', 'Erro ao carregar os funis', {root: true})
        reject()
      }).finally(() => {
        commit('stopLoading', 'selectFunnels');
      })
    })
  },

  postFunnel ({commit, dispatch}, funnelData) {
    commit('startLoading', 'postFunnel');
    return new Promise((resolve, reject) => {
      axios.post(`/funnels`, funnelData).then((response) => {
        commit('setErrorsMessage', {})
        commit('system/setSuccessMessage', "success_message.create_funnel", {root: true})
        commit('addNewFunnel', response.data.data)
        commit('setCurrentFunnel', response.data.data)
        dispatch('updateLastAccessedFunnel', {
          team_id: response.data.data.team.id,
          funnel_id: response.data.data.id
        })
        resolve(response.data.data.id)
      }).catch((e) => {
        commit('setErrorsMessage', e.response.data.errors)
        commit('system/setErrorMessage', e.response.data, {root: true})
        reject()
      }).finally(() => {
        commit('stopLoading', 'postFunnel');
      })
    })
  },

  updateFunnel ({commit, dispatch, getters}, payload) {
    commit('startLoading', 'updateFunnel');
    return new Promise((resolve, reject) => {
      axios.put(`/funnels/${ payload.funnel_id }`, payload)
        .then((response) => {
          commit('system/setSuccessMessage', "success_message.update_funnel", {root: true})
          commit('setCurrentFunnel', response.data.data)
          dispatch('updateLastAccessedFunnel', {
            team_id: response.data.data.team.id,
            funnel_id: response.data.data.id
          })
          let funnels = getters.getFunnels
          funnels = funnels.map(funnel => {
            return funnel.id === payload.funnel_id? {...funnel, name: payload.name} : funnel
          })
          commit('setFunnels', funnels)
          resolve();
        })
        .catch((error) => {
          commit('system/setErrorMessage', error.response.data, {root: true})
          reject();
        })
        .finally(() => {
          commit('stopLoading', 'updateFunnel');
        })
    })
  },

  updateCurrentFunnel ({commit, dispatch}, funnel_id) {
    return new Promise((resolve, reject) => {
      axios.get(`/funnels/${ funnel_id }`)
        .then((response) => {
          commit('setCurrentFunnel', response.data.data)
          dispatch('updateLastAccessedFunnel', {
            team_id: response.data.data.team.id,
            funnel_id: response.data.data.id
          })
          resolve();
        })
        .catch(() => {
          reject();
        })
    })
  },

  updateLastAccessedFunnel (state, {team_id, funnel_id}) {
    return new Promise(() => {
      axios.post("/last-accessed-funnel", {team_id, funnel_id})
    })
  },
}
