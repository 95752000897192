import store from "@store";
import Index from '@dialer/manager/pages/audience/index-audience.vue';
import WarmupAudience from "../../scopes/dialer/manager/pages/audience/warmup-audience.vue";
export default {
  path: "audience",
  name: "audience",
  components: {
    main: Index
  },
  children: [
    {
      path: "",
      name: "audience",
      components: {
        audience: WarmupAudience,
      }
    },
  ],

  beforeEnter: (to, from, next) => {
    if (store.getters["auth/isManager"]) next();
    else next({
      name: "not-found",
      params: {
        pathMatch: to.path.split('/').slice(1)
      }
    });
  }
};
