export default {
  startLoading: (state, flag) => (state.loadingFlags[flag] = true),
  stopLoading: (state, flag) => (state.loadingFlags[flag] = false),

  setErrors: (state, errors) => (state.errors = errors),
  clearErrors: state => {
    state.errors = {};
    state.errorMessage = "";
  },

  setRoutes: (state, routes) => (state.routes = routes),

  setRoutesPagination: (state, routesPagination) => (state.routesPagination = routesPagination),
  setRoutesPage: (state, page) => (state.routesPagination.current_page = page),
  setRoutesPerPage: (state, number) => (state.routesPagination.per_page = number),

  setRoutesFilters: (state, filters) => (state.routesFilters = filters),

  clearParams: state => {
    state.routesPagination.current_page = 1;
    state.routesPagination.per_page = 15;
    state.routesFilters = {
      endpoints: []
    };
  },
  setSpreadsheet: (state, spreadsheet) => (state.spreadsheet = spreadsheet)
};
