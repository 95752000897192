<template>
  <div
    class="mx-auto deactivated-extension text-center d-flex flex-column align-items-center justify-content-center"
    style="max-width: 650px;"
  >
    <img
      src="@/assets/img/illustrations/pluzinha-dormindo.svg"
      width="120"
    >
    <span
      class="fs-1 fw-bold"
    >
      {{ $t('deactivated_extension') }}
    </span>
    <span class="text-large">
      {{ $t('cannot_receive_calls_with_inactive_extension')
      }}
    </span>

    <div class="w-100">
      <app-button
        icon="power-off"
        :blocked="loadingFlags.updateRingGroupStatus"
        class="col-6 mx-auto justify-content-center mt-4"
        @click="_updateRingGroupStatus"
      >
        {{ $t('activate_extension') }}
      </app-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AppButton from "@/components/app-button.vue";
export default {
  name: "DeactivatedExtension",
  components: {
    AppButton,
  },

  computed: {
    ...mapGetters("agent/ring_group", {
      isRingGroupActive: "isRingGroupActive",
      loadingFlags: "getLoadingFlags",
    }),
  },

  methods: {
    ...mapActions("agent/ring_group", ["updateRingGroupStatus"]),
    _updateRingGroupStatus() {
      this.updateRingGroupStatus(!this.isRingGroupActive);
    }
  },
}
</script>

<style lang="scss" scoped>
.deactivated-extension {
  height: calc(100vh - 56px);
  gap: 8px;
}
</style>
