import store from "@/store";
import CrmIndex from "@crm/pages/crm-index";
import CrmFunnel from "@crm/pages/crm-funnel"
import CrmSettings from "@crm/pages/manager/crm-settings/crm-settings";
import CrmNewOpportunity from "@crm/pages/opportunity/crm-new-opportunity";
import CrmOpportunity from "@crm/pages/opportunity/crm-opportunity";
import CrmEmpty from "@crm/pages/empty-funnel/crm-empty.vue";
import CrmImport from "@crm/pages/manager/crm-import/crm-import.vue";
import CrmLoading from "@crm/pages/crm-loading.vue";
import CrmHome from "@crm/pages/manager/crm-tour/crm-home.vue";
import CrmDashboardView from "@crm/pages/crm-dashboard-view.vue";

export default {
  path: "crm",
  components: {
    main: CrmIndex
  },
  meta: {
    'short-side-bar': true
  },
  children: [
    {
      path: "",
      name: "",
      components: {
        crm: CrmLoading
      }
    },
    {
      path: "home",
      name: "",
      components: {
        crm: CrmHome
      }
    },
    {
      path: ":id",
      name: "crm-funnel",
      components: {
        crm: CrmFunnel
      },
      beforeRouteLeave: (to, from, next) => {
        store.commit("crm/tour/setCurrentTab", '');
        next();
      }
    },
    {
      path: "empty",
      components: {
        crm: CrmEmpty
      },
    },
    {
      path: ":id/settings",
      components: {
        crm: CrmSettings
      },
      beforeRouteEnter: (to, from, next) => {
        if (store.getters["auth/isManager"]) {
          next();
        } else next({
          name: "not-found",
          params: {
            pathMatch: to.path.split('/').slice(1)
          }
        });
      }
    },
    {
      path: ":id/import",
      components: {
        crm: CrmImport
      },
      beforeRouteEnter: (to, from, next) => {
        if (!store.getters["auth/isAgent"]) {
          if (!store.getters["crm/funnels/getCurrentFunnel"]) {
            next("manager/crm")
          }
          next();
        } else {
          next({
            name: "not-found",
            params: {
              pathMatch: to.path.split('/').slice(1)
            }
          });
        }
      },
      beforeRouteLeave: (to, from, next) => {
        store.commit("crm/tour/setCurrentTab", '');
        store.commit("crm/tour/setOpenModal", false);
        next();
      }
    },
    {
      path: ":id/opportunity",
      components: {
        crm: CrmNewOpportunity
      }
    },
    {
      path: ":id/opportunity/:opportunityId",
      components: {
        crm: CrmOpportunity
      },
    },
    {
      path: ":id/dashboard",
      components:  {
        crm: CrmDashboardView
      },
      beforeRouteEnter(to, from, next) {
        if(store.getters["auth/isAgent"]) {
          next('manager/crm')
        }
        next()
      }
    }
  ],
};
