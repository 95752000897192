<template>
  <div class="main-content h-100 login">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div class="card col-sm-6 col-md-6 col-lg-5 login-card">
        <div class="card-body">
          <div
            v-if="!reseted"
            class="col-12 my-5 align-self-center align-items-center justify-content-center d-flex flex-column"
          >
            <div class="text-center">
              <img
                src="@assets/img/logo.svg"
                class="img-fluid"
                style="height: 48px !important"
              >
            </div>
            <h1 class="display-4 text-center mb-3 fw-normal">
              Redefinir minha senha
            </h1>
            <p class="tx-text-gray text-center fs-4">
              Digite sua nova senha para acessar o 3C Plus
            </p>

            <form
              class="col-12"
              @submit.prevent="reset"
            >
              <div class="d-flex justify-content-center align-items-start mb-4">
                <div class="form-floating form-group col-11 mb-0">
                  <input
                    v-model="fields.password"
                    @input="handleAllValidations"
                    @focus="inputs.password = true"
                    @blur="inputs.password = false"
                    class="border-end-0 form-control first-input input-login"
                    :class="{
                      'is-invalid': errors && errors.password
                    }"
                    :type="showPass ? 'text' : 'password'"
                  >
                  <label
                    class="form-label floating-label"
                    :class="{ 'label-on-border': fields.password || inputs.password }"
                  >
                    Nova Senha</label>
                </div>
                <div
                  class="d-flex justify-content-center align-items-center text-center  input-group-text col border-start-0 rounded-end p-0 last-input"
                  :class="{ 'first-eye-input': inputs.password }"
                  style="height: 40px"
                  @click="showPass = !showPass"
                >
                  <i
                    class="fe fe-eye-off"
                    v-if="!showPass"
                  />
                  <i
                    class="fe fe-eye"
                    v-else
                  />
                </div>
                <div class="invalid-feedback">
                  {{ errors && errors.password && errors.password[0] }}
                </div>
              </div>
              <div class="d-flex justify-content-center align-items-start">
                <div class="form-floating form-group col-11 mb-0">
                  <input
                    :disabled="loadingFlags.resetPassword"
                    :type="showConfirmationPass ? 'type' : 'password'"
                    @focus="inputs.checkPassword = true"
                    @blur="inputs.checkPassword = false"
                    @input="handlePasswordsEqualsValidation"
                    class="border-end-0 form-control first-input input-login"
                    v-model="fields.checkPassword"
                  >
                  <label
                    class="form-label floating-label"
                    :class="{ 'label-on-border': fields.checkPassword || inputs.checkPassword }"
                  >
                    Repita a Senha</label>
                </div>
                <div
                  class="d-flex justify-content-center align-items-center text-center input-group-text col border-start-0 rounded-end p-0 last-input"
                  :class="{ 'last-eye-input': inputs.checkPassword }"
                  style="height: 40px"
                  id="inputGroupInvalid"
                  @click="showConfirmationPass = !showConfirmationPass"
                >
                  <i
                    class="fe fe-eye-off"
                    v-if="!showConfirmationPass"
                  />
                  <i
                    class="fe fe-eye"
                    v-else
                  />
                </div>
              </div>
              <div class="fs-5">
                <p class="p-0 mb-2 mt-2">
                  Critérios:
                </p>
                <div class="g-2 fs-6 row tx-text-100 mb-3">
                  <div>
                    <span
                      class="fa-solid fa-check"
                      :class="validations.isLengthValid ? 'text-primary' : 'tx-text-100'"
                    />
                    <span class="p-2">Possuir de 8 a 16 caracteres </span>
                  </div>
                  <div>
                    <span
                      class="fa-solid fa-check"
                      :class="
                        validations.isStringAndNumberValid &&
                          validations.isOneLetterUppercase &&
                          validations.isOneSpecialCharacter
                          ? 'text-primary'
                          : 'tx-text-100'
                      "
                    />
                    <span class="p-2">Possuir pelo menos uma letra maiúscula, um número e um caractere especial</span>
                  </div>
                  <div>
                    <span
                      class="fa-solid fa-check"
                      :class="validations.isSequenceValid ? 'text-primary' : 'tx-text-100'"
                    />
                    <span class="p-2">Não possuir repetições ou sequencias iguais (111, 123, abc) </span>
                  </div>
                  <div>
                    <span
                      class="fa-solid fa-check"
                      :class="validations.isPasswordsEquals ? 'text-primary' : 'tx-text-100'"
                    />
                    <span class="p-2">Os campos possuem a mesma senha </span>
                  </div>
                  <span>Dica: Evite datas de nascimento, documentos pessoais ou email </span>
                </div>
              </div>

              <app-button
                class="w-100 reset-button"
                :loading="loadingFlags.resetPassword"
                :disabled="!handleAllValidations()"
              >
                Redefinir senha
              </app-button>
            </form>
          </div>
          <div
            v-else
            class="col-12 align-self-center align-items-center justify-content-center d-flex flex-column"
          >
            <div class="text-center">
              <img
                src="@assets/img/logo.svg"
                class="img-fluid"
                style="height: 48px !important;"
              >
            </div>
            <h1 class="display-4 text-center mb-3 fw-normal">
              Senha redefinida com
              <span class="tx-blue-300">sucesso!</span>
            </h1>
            <p class="tx-text-gray text-center mb-4 fs-4">
              Sua jornada começa agora
            </p>
            <button
              @click="this.$router.push('/login')"
              class="btn btn-primary"
            >
              Voltar ao login
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import AppButton from "@/components/app-button.vue";

export default {
  /**
   *
   */
  mounted() {
    this.token = this.$route.params.resetToken;
  },

  /**
   * data
   */
  data() {
    return {
      showPass: false,
      showConfirmationPass: false,
      token: "",
      fields: {
        password: "",
        checkPassword: ""
      },
      validations: {
        isLengthValid: false,
        isStringAndNumberValid: false,
        isOneLetterUppercase: false,
        isOneSpecialCharacter: false,
        isPasswordsEquals: false,
        isSequenceValid: false
      },
      inputs: {
        password: false,
        checkPassword: false
      },
      reseted: false
    };
  },

  computed: {
    ...mapGetters("auth", {
      loadingFlags: "getLoadingFlags",
      errors: "getErrors"
    })
  },

  components: {
    AppButton
  },

  methods: {
    ...mapActions("auth", ["resetPassword"]),

    reset() {
      this.resetPassword({
        password: this.fields.password,
        password_confirmation: this.fields.checkPassword,
        reset_token: this.token
      }).then(() => {
        this.reseted = true;
        setTimeout(() => {
          this.$router.push("/manager").then(() => {
            location.reload();
          });
        }, 3000);
      });
    },

    handlePasswordLengthValidation() {
      const validation = this.fields.password && this.fields.password.length >= 8 && this.fields.password.length <= 16;

      this.validations.isLengthValid = validation;
      return validation;
    },

    handleLetterAndNumberValidation() {
      const regExp = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
      const validation = regExp.test(this.fields.password);

      this.validations.isStringAndNumberValid = validation;
      return validation;
    },

    handlePasswordsEqualsValidation() {
      const validation = this.fields.password === this.fields.checkPassword && this.fields.password.trim().length > 0;

      this.validations.isPasswordsEquals = validation;
      return validation;
    },

    handleSequenceValidation() {
      const validation = this.validateSequence(this.fields.password) && this.fields.password.trim().length > 0;

      this.validations.isSequenceValid = validation;
      return validation;
    },

    validateSequence(str) {
      for (let i = 1; i < str.length; i++) {
        const currentChar = str.charAt(i);
        const previousChar = str.charAt(i - 1);
        const nextChar = str.charAt(i + 1);

        if (currentChar === previousChar && nextChar && currentChar === nextChar && currentChar !== " ") {
          return false;
        }

        const currentCharCode = currentChar.charCodeAt(0);
        const previousCharCode = previousChar.charCodeAt(0);
        const nextCharCode = nextChar && nextChar.charCodeAt(0);

        if (currentCharCode === previousCharCode + 1 && currentCharCode === nextCharCode - 1) {
          return false;
        }

        if (currentCharCode === previousCharCode - 1 && currentCharCode === nextCharCode + 1) {
          return false;
        }
      }

      return true;
    },

    handleOneLetterUppercaseValidation() {
      const regExp = /[A-Z]/;
      const validation = regExp.test(this.fields.password);

      this.validations.isOneLetterUppercase = validation;
      return validation;
    },

    handleSpecialCharacterValidation() {
      const regExp = /[!|@|$|!|%|*|#|?|&]/;
      const validation = regExp.test(this.fields.password);

      this.validations.isOneSpecialCharacter = validation;
      return validation;
    },

    handleAllValidations() {
      const validations = [
        "handlePasswordLengthValidation",
        "handleLetterAndNumberValidation",
        "handlePasswordsEqualsValidation",
        "handleSequenceValidation",
        "handleOneLetterUppercaseValidation",
        "handleSpecialCharacterValidation"
      ];

      let isValid = true;
      validations.forEach(validation => {
        let verification = this[validation]();

        if (!verification) isValid = false;
      });

      return isValid;
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  height: 100vh;
  .first-input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .first-eye-input,
  .last-eye-input {
    border-color: $color-blue-300;
    transition: border-color 0.15s ease-in-out;
  }
  .last-input {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .login-card {
    box-shadow: 0px 4px 18px 0px rgba(34, 54, 77, 0.12);
    z-index: 1051 !important;
  }
  .card-body {
    padding: 56px;
  }
  form,
  .display-4,
  .reset-button {
    margin-top: $whatsapp-spacing-4;
  }
  .input-group-text {
    cursor: pointer !important;
  }
  .input-login {
    height: 40px;
    padding: 15px;
  }
  .input-login.form-control {
    background-color: #ffffff !important;
  }
  .floating-label {
    color: $color-text-200 !important;
  }
  .label-on-border {
    transform: translateY(-18px) !important;
    opacity: 1 !important;
    height: 20px !important;
    padding-top: 5px !important;
    background-color: white !important;
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
}
</style>
