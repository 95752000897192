import axios from "axios";

export default {
  loadAgentList({ commit }) {

    commit('clearErrors')
    commit('startLoading')
    commit('setList', []);

    return new Promise((resolve, reject) => {
      axios.get('/users/role/agent')
        .then((response) => {
          commit('setList', response.data.data);
          resolve();
        })
        .catch((response) => {
          commit('setAlert', { message: response.response.data.detail, type: 'danger' });
          response.response.status == 422 && response.response.data.errors && commit('setErrors', response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit('stopLoading')
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @returns
   */
  loadDataSelect({ commit }) {
    commit('startLoading')
    commit('setDataSelect', []);
    return new Promise((resolve, reject) => {
      axios.get('/filters?include=agents')
        .then((response) => {
          commit('setDataSelect', response.data.data.agents);
          resolve();
        }).catch(() => {
          reject();
        }).finally(() => {
          commit('stopLoading')
        });
    });
  },

  /**
   *
   * @param {*} param0
   */
  loadActiveDataSelect({ commit }) {
    commit('startLoading')
    commit('setActiveDataSelect', []);
    return new Promise((resolve, reject) => {
      axios.get('/agents?all=true&status=active')
        .then((response) => {
          commit('setActiveDataSelect', response.data.data);
          resolve();
        }).catch(() => {
          reject();
        }).finally(() => {
          commit('stopLoading')
        });
    });
  }
}

