<template>
  <div class="main-content pb-6">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div
          v-if="register && !loadingFilters.findFilters"
        >
          <header-component
            :title="register.name"
            :pretitle="$t('receptive_IVR')"
            redirect-link="/manager/receptive-ivrs"
          />
          <forms-receptive-ivr
            :fields="fields"
            @update:fields="fields = $event"
            :show-modal="showModal"
            @update:show-modal="showModal = $event"
            @submit="submit"
          >
            <template #button>
              <app-button
                :loading="loading.update"
                class="text-capitalize"
                color="text-danger"
                @click="remove_()"
              >
                <span class="fe fe-trash" /> {{ $t("delete") }}
              </app-button>
            </template>
          </forms-receptive-ivr>
        </div>
        <main-loading v-else-if="loading" />
      </div>
    </div>
  </div>
</template>


<script>
import mainLoading from "@/components/main-loading.vue";

import { mapActions, mapGetters, mapMutations } from "vuex";

import AppButton from "@components/app-button";
import HeaderComponent from "@/components/header-component.vue";
import FormsReceptiveIvr from "@dialer/manager/pages/settings/receptive_ivrs/forms-receptive-ivr.vue";

export default {

  components: {
    FormsReceptiveIvr,
    mainLoading,
    AppButton,
    HeaderComponent,
  },
  data() {
    return {
      fields: {
        name: "",
        timeout: 0,
        type_audio: "import",
        redirect_id: "",
        keys: [],
        audio: "",
      },
      showModal: false,
    };
  },

  mounted() {
    this.setIncludes(["queues"]);
    this.findFilters();
    this.setRegister(null);
    this.findById({ id: this.$route.params.id });
  },

  computed: {
    ...mapGetters("manager/filters", {
      loadingFilters: "getLoadingFlags",
    }),
    ...mapGetters("manager/receptive_ivrs", {
      register: "getRegister",
      errors: "getErrors",
      loading: "getLoadingFlags",
    }),
    ...mapGetters("auth", {
      user: "getUser",
    }),
  },
  watch: {
    register(register) {
      if (register) {
        this.fields.name = register.name;
        this.fields.timeout = register.timeout;
        this.fields.redirect_id = register.redirect_id;

        this.fields.keys[0] = {
          id: register.redirect_id,
          key: this.$t("capitalizes.none_key"),
          type: "ReceptiveQueue",
        }

        for (let item of register.keys) {
          this.fields.keys.push({
            id: item.redirect.id,
            key: item.key,
            type: "ReceptiveQueue",
          });
        }
        this.fields.audio = this.getFullAudioPath()
      }
    },
  },
  methods: {
    ...mapActions("manager/receptive_ivrs", ["update", "remove", "findById"]),
    ...mapActions("manager/filters", ["findFilters"]),
    ...mapMutations("manager/receptive_ivrs", ["setRegister"]),
    ...mapMutations("manager/filters", ["setIncludes"]),
    ...mapActions("system", ["showConfirmationMessage"]),
    getFullAudioPath() {
      return (
          process.env.VUE_APP_BA12AS.replace("/api/v1", "") +
          this.register.audio_path +
          "&api_token=" +
          this.user.api_token +
          "&name=" +
          this.register.original_audio_name
      );
    },
    createFormData() {
      let formData = new FormData();

      if (!this.fields.redirect_id) {
        this.showModal = true;
        return;
      }
      if (this.fields.audio !== this.getFullAudioPath()) {
        formData.append("audio", this.fields.audio);
      }

      formData.append("_method", "PUT");
      const with_keys = this.fields.keys.filter(key => key.key !== this.$t("capitalizes.none_key"));
      formData.append("name", this.fields.name);
      formData.append("timeout", this.fields.timeout);
      formData.append("redirect_id", this.fields.redirect_id);
      formData.append("redirect_type", "ReceptiveQueue");

      with_keys.forEach((key, index) => {
        formData.append(`keys[${index}][id]`, key.id);
        formData.append(`keys[${index}][key]`, key.key);
        formData.append(`keys[${index}][type]`, key.type);
      });
      return formData;
    },
    submit() {
      let formData = this.createFormData();
      if (!formData) return;
      this.update({ formData, id: this.register.id })
          .then(() => {
            this.$router.push("/manager/receptive-ivrs")
          })
    },
    async remove_() {

      let response = await this.showConfirmationMessage({
        title: this.$t("delete_receptive_ivr_confirmation"),
        text: this.$t("receptive_ivr_deletion_cannot_be_undone"),
        type: "badWarning",
      })

      if (response.isConfirmed) {
        this.remove({
          id: this.register.id,
        })
            .then(() => {
              this.$router
                  .push({
                    path: `/manager/receptive-ivrs`,
                  })
            })
      }
    },
  },
};
</script>
