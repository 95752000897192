<template>
  <only-me-filters
    :users="contactsList[allowedParams.role]"
    @select-filter="updateFilters"
    @update-role="updateRole"
    @search="search"
    v-model="selectedUsers"
    min-width="400"
    :loading="loadingFlags.findAllowedAgentsAndInstances"
    :id="id"
  />
</template>

<script>
import _ from "lodash";
import {mapGetters, mapActions, mapMutations} from "vuex";
import OnlyMeFilters from "@crm/components/only-me-filters.vue";

export default {
  emits: ['selectAgents'],

  props: {
    id: {
      type: String,
      default: 'id'
    }
  },

  data() {
    return {
      selectedUsers: [],
      filter: 'all'
    }
  },

  created() {
    if (this.currentFunnel.team) {
      this.setAllowedParams({
        team: this.currentFunnel.team.id,
        all_company: 0
      })
      this.updateContactsList();
    }
  },

  computed: {
    ...mapGetters("crm/funnels", {
      currentFunnel: "getCurrentFunnel"
    }),
    ...mapGetters("whatsapp/instance", {
      allowedAgents: "getAllowedAgents",
      loadingFlags: "getLoadingFlags",
      allowedParams: "getAllowedParams"
    }),
    ...mapGetters("crm/opportunities", {
      opportunityFilter: "getOpportunityFilters",
      contactsList: 'getContactsList'
    }),
    ...mapGetters("auth", {
      user: "getUser"
    })
  },

  components: {
    OnlyMeFilters,
  },

  methods: {
    ...mapActions("whatsapp/instance", ["findAllowedAgentsAndInstances"]),
    ...mapMutations("whatsapp/instance", ["setAllowedParams"]),
    ...mapMutations('crm/opportunities', [
      'setContactsList'
    ]),

    search: _.debounce((event) => {
      this.setAllowedParams({
        search: event,
        all_company: 0
      })
      this.findAllowedAgentsAndInstances(this.allowedParams)
    }, 500),

    updateFilters(e) {
      if (e === 'me') {
        return this.selectedUsers = [this.user]
      }
      return this.selectedUsers = []
    },

    updateRole(role) {
      let params = {
        team: this.currentFunnel.team.id,
        role,
        all_company: 0
      }

      if (role === 'manager') {
        params.team = null
      }

      this.setAllowedParams(params);
      if (!this.contactsList[this.allowedParams.role].length) {
        this.updateContactsList();
      }
    },

    updateContactsList() {
      this.findAllowedAgentsAndInstances(this.allowedParams).then(() => {
        this.setContactsList({
          role: this.allowedParams.role,
          contactsList: this.allowedAgents
        })
      })
    }
  },

  watch: {
    selectedUsers: {
      handler(users) {
        const usersId = users.map((user) => user.id)
        this.$emit("selectAgents", usersId)
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
