<template>
  <div class="d-flex flex-column justify-content-start align-items-center">
    <img
      src="@assets/img/empty.svg"
      class="img-fluid my-5 mt-2"
      alt="empty-funnel"
      width="196"
    >
    <h2 class="mt-2">
      {{ $t('nothing_to_show') }}...
    </h2>
    <p>{{ $t('click_to_add') }}</p>
  </div>
</template>

<script>
export default {
  name: "EmptyFormInputs"
}
</script>
