<script setup>
import {computed, defineProps, ref} from "vue"
import {useStore} from "vuex";
import {useDashboardQuery} from "@/composables/useDashboardQuery";
import DashCard from "@crm/pages/dashboard/components/dash-card.vue";
import {Utils} from "@/utils";
import EmptyChart from "@crm/pages/dashboard/components/charts/empty-chart.vue";


defineProps({
  cols: {
    type: Number,
    default: 5,
  }
})

const store = useStore();
const dateRange = computed(() => store.getters["crm/dashboard/getDateRange"])
const currentFunnel = computed(() => store.getters["crm/funnels/getCurrentFunnel"] ?? null);
const agent = computed(() => store.getters["crm/dashboard/getActiveAgent"] ?? null);
const amount = ref(6)

const {data, loading} = useDashboardQuery(
  currentFunnel,
  dateRange,
  dateRange,
  agent,
  'latestOpportunities',
  amount
);

</script>

<script>
export default {
  name: 'LatestOpportunities',

  methods: {
    toggleCurrency() {
      let locale = 'en';
      let currency = 'USD';

      if (this.$i18n.locale === 'pt') {
        locale = 'pt-BR';
        currency = 'BRL';
      }

      return {
        locale,
        currency
      }
    }
  }
}
</script>

<template>
  <dash-card
    :title="$t('latest_opportunities')"
    :loading="loading"
    min-height="160"
  >
    <template #body>
      <div
        :style="`row-gap: 1.5rem`"
        :class="`row justify-content-center row-cols-${cols}`"
      >
        <template v-if="!data?.latest_opportunities.length">
          <div
            v-for="empty in amount"
            :key="`empty-${empty}`"
          >
            <empty-chart
              class="card p-3"
              min-height="162"
              :msg="$t('empty_earned_opportunities')"
            />
          </div>
        </template>

        <template v-else>
          <div
            v-for="(opportunity, oIndex) in data?.latest_opportunities"
            :key="`opportunity-${oIndex}`"
            class="col"
          >
            <div
              style="min-height: 162px"
              class="card h-100"
            >
              <div
                style="padding: 1rem"
                class="card-body h-100 d-flex flex-column justify-content-between gap-2"
              >
                <span class="tx-text-300 fs-6 fw-medium line-clamp">
                  {{ opportunity.name }}
                </span>

                <div class="d-flex justify-content-between gap-2">
                  <time
                    class="time-badge d-inline-block fw-medium rounded"
                    datetime="2008-02-14 20:00"
                  >
                    <i class="fa-regular fa-circle-star me-2" />
                    {{ opportunity.date }}
                  </time>

                  <div>
                    <span
                      style="font-size: 0.75rem; margin-right: 0.5rem;"
                      class="tx-text-300 fs-6 fw-medium"
                    >
                      {{
                        Utils.formatCurrency(parseFloat(opportunity.value), toggleCurrency().locale, toggleCurrency().currency)
                      }}
                    </span>
                    <span
                      class="d-inline-flex justify-content-center align-items-center rounded-circle tx-text-200 name-badge"
                    >
                      {{ opportunity.user_name }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </dash-card>
</template>

<style scoped lang="scss">
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.time-badge {
  font-size: 0.625rem;
  padding: 0.25rem 0.5rem;
  background-color: #C9F2CD;
  color: #015901;
}


.name-badge {
  font-size: 0.75rem;
  padding: 3px;
  background-color: #E1E9F4;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
}

.card {
  margin-bottom: 0;
}
</style>