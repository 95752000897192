<template>
  <div
    class="progress progress-sm position-absolute"
    :class="{
      'bg-yellow-100': status == AgentStatus.ON_WORK_BREAK,
      'bg-blue-100': status == AgentStatus.ACW,
    }"
    ref="progressBar"
  >
    <div
      class="progress-bar"
      :class="{
        'bg-yellow-500': status == AgentStatus.ON_WORK_BREAK,
        'bg-blue-500': status == AgentStatus.ACW,
      }"
      role="progressbar"
      :style="`width: ${percentage}%`"
      aria-valuenow="10"
      aria-valuemin="0"
      aria-valuemax="100"
      ref="progress"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AgentStatus } from "@/utils.js"

export default {
  name: "ProgressBar",
  data() {
    return {
      AgentStatus,
      percentage: 0,
      progressPx: 0,
    };
  },

  computed: {
    ...mapGetters("agent/work-break", {
      intervalActive: "getIntervalActive",
      expired: "isExpired"
    }),

    ...mapGetters("system", {
      totalSeconds: "getTotalSeconds",
      status: "getStatus",
      acwTimeout: "getAcwTimeout",
    })
  },

  mounted() {
    switch (this.status) {
      case AgentStatus.ON_WORK_BREAK:
        if (!this.expired && this.intervalActive.minutes !== 0) {
          this.$refs.progress.classList.add("transition");
        } else {
          this.$refs.progress.classList.add("transition-none");
        }
        break;

      case AgentStatus.ACW:
        if (this.acwTimeout !== 0) {
          this.$refs.progress.classList.add("transition");
        } else {
          this.$refs.progress.classList.add("transition-none");
        }
        break;
    }
  },

  methods: {
    updatePercentage() {
      this.progressPx = this.$refs.progressBar.clientWidth * (this.percentage / 100);
    },
  },

  watch: {
    totalSeconds(totalSeconds) {
      switch (this.status) {
        case AgentStatus.ON_WORK_BREAK:
          if (this.expired || this.intervalActive.minutes === 0) this.percentage = 100;
          else {
            let total = this.intervalActive.minutes * 60;
            this.percentage = 100 - totalSeconds / (total / 100);
          }
          this.updatePercentage();
          break;

        case AgentStatus.ACW:
          if (this.acwTimeout == 0) this.percentage = 100;
          else {
            this.percentage = 100 - totalSeconds / (this.acwTimeout / 100);
          }
          this.updatePercentage();
          break;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.progress {
  border-radius: unset;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  height: 0.25rem;

  .progress-bar {
    border-radius: unset;
  }
}

.transition {
  transition: width 1s linear 0s;
}

.transition-none {
  transition: none;
}
</style>
