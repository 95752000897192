<template>
  <div>
    <div
      class="container-fluid vh-100 d-flex justify-content-center align-items-center"
    >
      <div class="empty-state d-flex flex-column overflow-hidden rounded-2 bg-text-light">
        <div class="d-flex bg-textures position-relative align-items-center">
          <div
            class="d-flex flex-column gap-4"
            style="margin-left: 68px; max-width: 30%"
          >
            <img
              src="@assets/img/3czinhos.svg"
              style="width: 85px"
            >
            <h1
              class="m-0"
              style="line-height: 1.2"
            >
              {{ $t('revolution_with_chatbot') }}
              <span class="tx-blue-300">{{ $t('its_free') }}</span>
            </h1>
          </div>
          <img
            alt="Chatbot screens"
            class="position-absolute chatbot-screens"
            src="@assets/img/chatbot-screens.png"
          >
        </div>
        <div
          class="flex-grow-1 d-flex align-items-center rounded-2"
          style="padding: 40px"
        >
          <div class="d-flex align-items-end justify-content-between gap-4 w-100">
            <ul class="m-0">
              <li>{{ $t('instant_service') }}</li>
              <li>{{ $t('quick_solutions_to_frequent_questions') }}</li>
              <li>{{ $t('compatible_only_with_omni_numbers') }}</li>
            </ul>

            <div
              class="d-flex flex-column align-items-end gap-2"
              v-if="!isWhatsappAllowed"
            >
              <img
                src="@/assets/img/omni-required.svg"
                style="width: 180px"
              >
              <app-button
                style="width: 269px;"
                @click="handlerButtons"
                :disabled="handlerClick"
              >
                {{ handlerClick? $t('solicitation_sent') : $t('i_want_to_hire') }}
              </app-button>
            </div>
            <app-button
              v-else
              style="width: 269px;"
              @click="$emit('create-chatbot')"
            >
              {{ $t('try_for_free') }}
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "@components/app-button.vue";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "EmptyStateChatbot",

  components: {AppButton},

  emits: ['create-chatbot'],

  data() {
    return {
      handlerClick: false
    }
  },

  computed: {
    ...mapGetters('auth', {
      isWhatsappAllowed: "isWhatsappAllowed"
    }),
  },

  methods: {
    ...mapMutations("auth", ["trackEvents"]),

    handlerButtons() {
      if (this.handlerClick) {
        return
      }

      this.handlerClick = true
      this.trackEvents(`[Manager] Request Omni Access`)
    }
  },

}
</script>

<style lang="scss" scoped>
.container-fluid, .bg-textures {
  background-repeat: no-repeat;
  background-size: cover;
}

.container-fluid {
  background-image: url("~@assets/img/chatbot-empty-background.png");
  background-position: top right;
}

.empty-state {
  width: 80%;
  height: 54%;
}

.bg-textures {
  background-image: url("~@assets/img/textures.svg");
  background-position: top center;
  height: 65%;
}

.chatbot-screens {
  right: 0;
  max-width: 65%;
  height: 100%;
  object-fit: contain;
  object-position: bottom;
}

@media (max-width: 1366px) {
  h1 {
    font-size: 1.5rem;
  }

  li {
    font-size: 0.875rem;
  }
}

@media (min-width: 1920px) {
  .empty-state {
    max-width: 1298px;
    max-height: 584px;
  }

  h1 {
    font-size: 2.5rem;
  }

  li {
    font-size: 1.5rem;
  }
}
</style>