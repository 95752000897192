<template>
  <section v-if="amount <= detailsPartner.partner_l1_threshold && position === 1">
    <div class="text-center">
      <div
        class="w-100"
      >
        <img
          :alt="`Imagem do seu nível 2`"
          src="@/assets/img/partner/lock-lvl2.svg"
        >
      </div>
    </div>
    <div>
      <span
        class="text-muted d-flex justify-content-end"
      >
        0%
      </span>
      <div
        class="progress"
        style="height: 8px"
      >
        <div
          aria-valuemax="100"
          aria-valuemin="0"
          aria-valuenow="0"
          class="progress-bar"
          role="progressbar"
        />
      </div>
    </div>
    <span
      class="text-muted d-flex justify-content-center mt-4"
    >
      {{ `${$t('over')} ${formatCurrency(parseFloat(detailsPartner.partner_l1_threshold))}` }}
    </span>
  </section>
  <section v-else-if="amount <= detailsPartner.partner_l2_threshold && position === 2">
    <div class="text-center">
      <div
        class="w-100"
      >
        <img
          :alt="`Imagem do seu nível 3`"
          src="@/assets/img/partner/lock-lvl3.svg"
        >
      </div>
    </div>
    <div>
      <span
        class="text-muted d-flex justify-content-end"
      >
        0%
      </span>
      <div
        class="progress"
        style="height: 8px"
      >
        <div
          aria-valuemax="100"
          aria-valuemin="0"
          aria-valuenow="0"
          class="progress-bar"
          role="progressbar"
        />
      </div>
    </div>
    <span
      class="text-muted d-flex justify-content-center mt-4"
    >
      {{ `${$t('over')} ${formatCurrency(parseFloat(detailsPartner.partner_l2_threshold))}` }}
    </span>
  </section>
  <section v-else>
    <div class="text-center">
      <img
        :alt="`Imagem do seu nível ${levelPartner.image}`"
        :src="levelPartner.image"
      >
    </div>
    <div>
      <span
        v-if="amount >= levelPartner.maxBilling"
        class="text-muted d-flex justify-content-end"
      >
        {{ progressBar(levelPartner.maxBilling).percentage }}%
      </span>
      <span
        v-else
        class="text-muted d-flex justify-content-end"
      >
        {{ progressBar(amount).percentage }}%
      </span>
      <div
        v-if="amount >= levelPartner.maxBilling"
        :class="['progress', infoPartner(levelPartner.maxBilling).progress]"
        style="height: 8px"
      >
        <div
          :aria-valuenow="progressBar(levelPartner.maxBilling).percentage"
          :class="['progress-bar', infoPartner(levelPartner.maxBilling).classLevel]"
          :style="{width: progressBar(levelPartner.maxBilling).percentage + '%'}"
          aria-valuemax="100"
          aria-valuemin="0"
          role="progressbar"
        />
      </div>
      <div
        v-else
        :class="['progress', infoPartner(amount).progress]"
        style="height: 8px"
      >
        <div
          :aria-valuenow="progressBar(amount).percentage"
          :class="['progress-bar', infoPartner(amount).classLevel]"
          :style="{width: progressBar(amount).percentage + '%'}"
          aria-valuemax="100"
          aria-valuemin="0"
          role="progressbar"
        />
      </div>
      <div
        v-if="amount >= levelPartner.maxBilling"
        class="d-flex align-items-baseline gap-3 justify-content-center mt-4"
      >
        <span>
          {{ formatCurrency(levelPartner.maxBilling) }}
        </span>
        <i
          :class="levelPartner.check"
          class="fa-regular fa-check"
        />
      </div>
      <span
        v-else
        class="d-flex justify-content-center mt-4"
      >
        {{ formatCurrency(amount) }} / {{ formatCurrency(levelPartner.maxBilling) }}
      </span>
    </div>
  </section>
</template>

<script>
import {Utils} from "@/utils";
import {mapGetters} from "vuex";

export default {
  name: "LockedLevelPartner",
  props: {
    levelPartner: {type: Object, default: () => ({}), required: true},
    amount: {type: Number, default: 0, required: true},
    position: {type: Number, default: 0, required: true},
  },

  data() {
    return {
      partnerInfo: [
        {
          classLevel: "level-1",
          progress: "progress-1",
        },
        {
          classLevel: "level-2",
          progress: "progress-2",
        },
        {
          classLevel: "level-3",
          progress: "progress-3",
        }
      ],
    }
  },

  methods: {
    getPercentage(value) {
      const L1_THRESHOLD = this.computedDetailsPartner.partner_l1_threshold;
      const L2_THRESHOLD = this.computedDetailsPartner.partner_l2_threshold;
      const L3_THRESHOLD = this.computedDetailsPartner.partner_l3_threshold;

      if (value <= L1_THRESHOLD) {
        return (value / L1_THRESHOLD) * 100;
      }

      if (value <= L2_THRESHOLD) {
        return ((value - L1_THRESHOLD) / (L2_THRESHOLD - L1_THRESHOLD)) * 100;
      }

      return ((value - L2_THRESHOLD) / (L3_THRESHOLD - L2_THRESHOLD)) * 100;
    },

    progressBar(value) {
      const percentage = this.getPercentage(value);

      const progress = Math.min(Math.floor(percentage), 100);

      return {percentage: progress};
    },

    formatCurrency: Utils.formatCurrency,

    infoPartner(wallet) {
      const L1_THRESHOLD = this.computedDetailsPartner.partner_l1_threshold;
      const L2_THRESHOLD = this.computedDetailsPartner.partner_l2_threshold;

      if (wallet <= L1_THRESHOLD) return this.partnerInfo[0];
      if (wallet <= L2_THRESHOLD) return this.partnerInfo[1];
      if (wallet > L2_THRESHOLD) return this.partnerInfo[2];
    }
  },

  computed: {
    ...mapGetters("partner", {
      loadingFlags: "getLoadingFlags",
      detailsPartner: "getDetailsPartner",
    }),
    ...mapGetters("admin/partners", {
      adminDetailPartner: "getDetailsPartners",
      adminLoadingFlags: "getLoadingFlags"
    }),
    computedDetailsPartner() {
      if (Object.keys(this.detailsPartner).length === 0) {
        return this.adminDetailPartner;
      }
      return this.detailsPartner;
    },
  }
};
</script>

<style
  lang="scss"
  scoped
>
.img {
  width: 115.129px;
  height: 115.129px;
  -webkit-filter: drop-shadow(0px 0px 4px #12263f);
  filter: drop-shadow(0px 5px 5px rgba(34, 54, 77, 0.12));
}

.level-1 {
  background-color: $color-blue-300;
}

.level-2 {
  background-color: var(--color-pink-300);
}

.level-3 {
  background-color: $color-yellow-300;
}

.progress-1 {
  background-color: #2a57f21f;
}

.progress-2 {
  background-color: #ff1f7b1f;
}

.progress-3 {
  background-color: #ffbb3a1f;
}

.check-1 {
  color: $color-blue-300;
}

.check-2 {
  color: var(--color-pink-300);
}

.check-3 {
  color: $color-yellow-300;
}
</style>
