<template>
  <div
    class="col-6"
    style="height: 176px;"
  >
    <div class="card h-100 card-hover">
      <div class="card-header">
        <h4 class="m-0 p-0 cerebri-sans">
          {{ $t('service_time_today') }}
        </h4>
      </div>
      <div
        class="card-body p-4 row justify-content-between m-0"
        style="height: 80px;"
      >
        <div class="col-6 h-100 cerebri-sans">
          <h1 style="margin-bottom: 10px;">
            {{ formatSeconds(total, false) }}
          </h1>
          <h5 class="m-0 fw-normal ">
            {{ $t('list_metrics_total') }}
          </h5>
        </div>
        <div class="col-6 h-100 ">
          <div
            class="d-flex align-items-center justify-content-between cerebri-sans"
          >
            <div class="p-0">
              <DotStatus
                :size="'8px'"
                color="#3057F2"
              />
              <span class="h5 ms-2 ">{{ $t('call') }}</span>
              <h6 class="mt-1 fw-normal tx-text-gray m-0 ">
                {{ formatSeconds(speaking, false) }}
              </h6>
            </div>
            <div class="p-0">
              <DotStatus
                :size="'8px'"
                color="#D2DDEC"
              />
              <span class="h5 ms-2 ">{{ $t('idle_capitalize') }}</span>
              <h6 class="mt-1 fw-normal tx-text-gray m-0 ">
                {{ formatSeconds(idle, false) }}
              </h6>
            </div>
          </div>
          <div
            class="d-flex align-items-center justify-content-between"
            style="padding-top: 16px;"
          >
            <div
              :class="{
                progress: true,
                'status-progress-bar': true,
                'ms-2': true
              }"
              :style="{
                width: (speaking / (speaking + idle)) * 100 + '%',
                height: '7px'
              }"
            >
              <div
                :style="{
                  width: '100%',
                  backgroundColor: '#3057F2'
                }"
                aria-valuemax="100"
                aria-valuemin="0"
                aria-valuenow="100"
                class="progress-bar"
                role="progressbar"
              />
            </div>
            <div
              :class="{
                progress: true,
                'status-progress-bar': true,
                'ms-2': true
              }"
              :style="{
                width: (idle / (speaking + idle)) * 100 + '%',
                height: '7px'
              }"
            >
              <div
                :style="{
                  width: '100%',
                  backgroundColor: '#D2DDEC'
                }"
                aria-valuemax="100"
                aria-valuemin="0"
                aria-valuenow="100"
                class="progress-bar"
                role="progressbar"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DotStatus from "@components/dot-status.vue";
import { mapGetters } from "vuex";
import { formatSeconds } from "@/utils";

export default {
  name: "StatusBar",
  data() {
    return {
      speaking: 0,
      idle: 0,
      total: 0
    };
  },

  mounted() {

    this.speaking = this.campaignMetricsCharts["today"].reduce(
      (acc, curr) =>
        acc +
        curr.speaking +
        curr.manual_calls_speaking +
        curr.manual_calls_acw_speaking,
      0
    );
    this.idle = this.campaignMetricsCharts["today"].reduce(
      (acc, curr) => acc + curr.idle,
      0
    );
    this.total = this.campaignMetricsCharts["today"].reduce(
      (acc, curr) =>
        acc +
        curr.speaking +
        curr.manual_calls_speaking +
        curr.manual_calls_acw_speaking +
        curr.idle,
      0
    );
  },

  components: {
    DotStatus
  },

  computed: {
    ...mapGetters("agent/call", {
      campaignMetricsCharts: "getCampaignMetricsCharts"
    })
  },
  methods: {
    formatSeconds
  }
};
</script>

<style lang="scss" scoped>
.card {
  border: 1px solid $color-gray-blue-300;

  button {
    background-color: $color-gray-blue-300;
    border-radius: $border-radius;
  }
}

.card-hover {
  transition: 0.2s ease-in-out;

  &:hover {
    box-shadow: 2px 2px 15px 2px rgba(18, 38, 63, 0.13);
  }
}

.status-label {
  color: $color-text-gray;
}

.status-progress-bar {
  transition: width 0.3s ease;
}

.loading-card {
  height: 176px;
  background: linear-gradient(110deg, #f0f4fa 8%, #f9fbfd 18%, #f0f4fa 33%);
  border-radius: 8px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  margin-bottom: 1.5rem;
}

.cerebri-sans {
  font-family: "Cerebri Sans", sans-serif;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
</style>
