<template>
  <modal-component
    :show="showModal"
    @close="closeModal"
    :center="true"
    custom-size="1000px"
  >
    <template #content>
      <div
        class="modal-body p-0"
      >
        <div
          class="row"
        >
          <div class="col-6 texture pb-4">
            <img src="@assets/img/3czinhos.svg">
            <h1 class="fw-bolder modal-title">
              {{ selectedProduct.name }}
            </h1>
            <p>{{ selectedProduct.description }}</p>
            <img
              :src="selectedProduct.imgPath"
              class="product-image"
            >
            <p
              class="text-small mt-3 mb-0"
              v-if="isChatbot"
            >
              <strong> *{{ $t("compatible_only_with_omni_numbers") }}</strong>
            </p>
          </div>
          <div
            class="col-6 d-flex flex-column"
          >
            <div>
              <button
                type="button"
                class="btn-close me-3 mt-3"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div class="d-flex flex-column justify-content-between h-100 modal-body-content">
              <div>
                <h2 class="modal-subtitle">
                  {{ selectedProduct.title }}
                </h2>
                <ul class="product-items">
                  <li
                    v-for="(item, index) in selectedProduct.items"
                    :key="index"
                    class="mb-3 product-item"
                    v-html="item"
                  />
                </ul>
              </div>
              <app-button
                class="btn-gray-blue text-medium w-100 tx-text-gray"
                @click="redirectTo(selectedProduct.link)"
              >
                {{ $t('see_all_benefits') }}
                <i class="fa-regular fa-arrow-up-right-from-square ms-2" />
              </app-button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from "@components/modal-component.vue";
import AppButton from "@components/app-button.vue";

export default {
  components: {AppButton, ModalComponent},
  props: {
    product: {
      type: String,
      default: "",
    },
    showPopup: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedProduct() {
      return this.productModal.find(
          (product) => product.name === this.product
      );
    },
    isChatbot() {
      return this.selectedProduct.name === "Chatbot";
    },
  },
  emits: ["close"],
  data() {
    return {
      showModal: false,
      productModal: [
        {
          name: "Omnichannel",
          description: this.$t("modal_omni_description"),
          imgPath: require("@assets/img/omni-preview.png"),
          title: this.$t("modal_omni_title"),
          items: this.$tm("omnichannel_benefits_lists"),
          link: "https://3cplusnow.com/pt/omnichannel/",
        },
        {
          name: "Chatbot",
          description: this.$t("modal_chatbot_description"),
          imgPath: require("@assets/img/chatbot-preview.png"),
          title: this.$t("modal_chatbot_title"),
          items: this.$tm("chatbot_benefits_list"),
          link: "https://3cplusnow.com/pt/chatbot/",
        },
        {
          name: this.$t("automatic_dialer"),
          description: this.$t("modal_dialer_description"),
          imgPath: require("@assets/img/discadora-preview.png"),
          title: this.$t("modal_dialer_title"),
          items: this.$tm("dialer_benefits_list"),
          link: "https://3cplusnow.com/pt/discador/",
        },
        {
          name: "PABX",
          description: this.$t("modal_pabx_description"),
          imgPath: require("@assets/img/pabx-preview.png"),
          title: this.$t("modal_pabx_title"),
          items: this.$tm("pabx_benefits_list"),
          link: "https://3cplusnow.com/pt/pabx/",
        },
        {
          name: this.$t("receptive_capitalize"),
          description: this.$t("modal_receptive_description"),
          imgPath: require("@assets/img/receptivo-preview.png"),
          title: this.$t("modal_receptive_title"),
          items: this.$tm("receptive_benefits_lists"),
          link: "https://3cplusnow.com/pt/receptivo/",
        },
      ],
    };
  },
  mounted() {
    this.showModal = true;
  },
  methods: {
    closeModal() {
      this.showModal = false;
      this.$emit("close");
    },
    redirectTo(url) {
      window.open(url, "_blank");
    },
  },
  watch: {
    showPopup() {
      this.showModal = this.showPopup;
    },
  },
};
</script>

<style scoped>
.texture {
  background-image: url("~@assets/img/bg-products-modal.png");
  background-size: cover;
  padding: 45px;
  border-radius: 10px 0 0 10px;
}

.modal-title {
  margin-block: 27px;
}

.product-image {
  width: 100%;
  max-width: 429px;
}


.modal-body-content {
  padding: 40px;
}

.modal-subtitle {
  margin-bottom: 40px;
}

.product-items {
  color: #575778;
  padding: 0;
}

.product-item {
  margin-left: 20px;
}
</style>
