<template>
  <div
    class="alert alert-light alert-csv d-flex align-items-center justify-content-center p-4 gap-4"
    role="alert"
  >
    <i class="fa-regular fa-triangle-exclamation fs-1" />
    <p class="m-0">
      {{ $t('before_file_import') }}
      <button
        class="btn p-0 strong alert-csv"
        @click="redirectToCustomFields"
        type="button"
      >
        <strong>{{ $t('click_here') }}</strong>
      </button>.
    </p>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "AlertImport",
  computed:{
    ...mapGetters("crm/funnels", {
      currentFunnel: "getCurrentFunnel"
    }),
  },
  methods:{
    ...mapMutations("crm/tour", ["setCurrentTab"]),
    redirectToCustomFields(){
      this.setCurrentTab('form-inputs-tab')
      this.$router.push({path: `/manager/crm/${this.currentFunnel.id}/settings`})
    }

  }
}
</script>

<style scoped lang="scss">
.alert-csv{
  background-color: #E1E9F4;
  color: #1A4A89;
}
</style>
