import axios from "axios";

export default {
  /**
   * Retrieve the calls from the receptive queue
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  loadCalls({ commit }, payload) {
    commit("clearCalls");

    return new Promise((resolve, reject) => {
      axios.get(`/receptive_queues/${payload.queue_id}/calls`)
        .then(response => {
          commit("setCalls", response.data.data);
          resolve();
        })
        .catch((error) => {
          commit('system/setErrorMessage', error.response.data, { root: true });
          reject();
        });
    });
  }
};
