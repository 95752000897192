import axios from "axios";

export default {
  listUnproductiveStats({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading");
    commit("setRegister", []);

    return new Promise((resolve, reject) => {
      axios
        .get("/routes/dailyStatistics", {
          params: {
            startDate: payload.start_date,
            endDate: payload.end_date
          }
        })
        .then(response => {
          commit("setRegister", response.data.data);
          resolve();
        })
        .catch(response => {
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  }
};
