<template>
  <div
    ref="canvas"
    class="offcanvas offcanvas-end"
  >
    <div class="offcanvas-body">
      <div class="row">
        <div class="col-12">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div class="col-12 mb-2 text-center">
          <span class="fs-1">Informações do SMS</span>
        </div>
        <div class="px-3">
          <hr class="navbar-divider">
        </div>
        <div
          v-if="this.fields.company.data"
          class="container-fluid ms-1"
        >
          <div class="col-12 d-flex flex-column mb-4">
            <label
              class="form-label fs-2 mb-0"
              style="color: #737373"
            >Empresa</label>
            <span>{{ this.fields.company.data.name }}</span>
          </div>
          <div class="col-12 d-flex flex-column mb-4">
            <label
              class="form-label fs-2 mb-0"
              style="color: #737373"
            >ID</label>
            <span>{{ this.fields.company.data.id }}</span>
          </div>
          <div class="col-12 d-flex flex-column">
            <label
              class="form-label fs-2 mb-0"
              style="color: #737373"
            >Status</label>
          </div>
          <span
            class="px-4 py-2 fs-5 mb-4"
            :class="this.fields.status == 2 ? 'badge bg-success-soft' : 'badge bg-danger-soft'"
          >
            {{ this.fields.status == 2 ? 'Aprovado' : 'Recusado' }}
          </span>
          <div
            v-if="this.fields.evaluator.name"
            class="col-12 d-flex flex-column mb-4"
          >
            <label
              class="form-label fs-2 mb-0"
              style="color: #737373"
            >Avaliador</label>
            <span>{{ this.fields.evaluator.name }}</span>
          </div>
          <div
            v-if="this.fields.start_date && this.fields.end_date"
            class="col-12 d-flex justify-content-between mb-4"
          >
            <div class="col-6 d-flex flex-column">
              <label
                class="form-label fs-4 mb-0"
                style="color: #737373"
              >
                Início da solicitação
              </label>
              <span> {{ formatDate(this.fields.start_date) }}</span>
            </div>
            <div class="col-6">
              <label
                class="form-label fs-4 mb-0"
                style="color: #737373"
              >
                Fim da solicitação
              </label>
              <span> {{ formatDate(this.fields.end_date) }}</span>
            </div>
          </div>
          <div class="col-12 d-flex flex-column mb-4">
            <label
              class="form-label fs-4 mb-0"
              style="color: #737373"
            >Motivo</label>
            <span>{{ this.fields.reason }}</span>
          </div>
          <div class="col-12 d-flex flex-column mb-4">
            <label
              class="form-label fs-4 mb-0"
              style="color: #737373"
            >Mailing</label>
            <div class="col-3 mt-2">
              <button
                @click="downloadMailing(this.fields.id)"
                class="btn btn-sm btn-outline-primary"
                style="border-radius: 10px; min-width: 100px"
              >
                <div
                  v-if="loadingFlags.downloadFinalizedMailingSms"
                  class="spinner-border spinner-border-sm"
                >
                  <span class="visually-hidden">Carregando...</span>
                </div>
                <div v-else>
                  <i class="fe fe-download" />
                  <span>
                    Baixar
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Offcanvas } from "bootstrap";
import moment from "moment";
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      mode: "",
      offcanvas: null,
      fields: {
        company: {},
        end_date: null,
        evaluator: {},
        id: null,
        message: "",
        name: "",
        reason: "",
        schedule: null,
        start_date: null,
        status: null
      }
    }
  },

  /**
   *
   */
  mounted() {
    this.offcanvas = new Offcanvas(this.$refs.canvas);
  },

   /**
   *
   */
  computed: {
    ...mapGetters("admin/sms", {
      loadingFlags: "getLoadingFlags",
    })
  },

  /**
   *
   */
  methods: {
    ...mapActions("admin/sms", ["downloadFinalizedMailingSms"]),

    /**
     *
     */
    downloadMailing(){
      this.downloadFinalizedMailingSms({
        id: this.fields.id
      })
    },

    open(item) {
      for (const key in this.fields) {
        if (Object.hasOwnProperty.call(this.fields, key)) {
          this.fields[key] = item[key];
        }
      }
      this.offcanvas.show()
    },

    /**
     * format date on finalized Review table rows
     */
    formatDate(timeStamp) {
      return moment(timeStamp * 1000).local().format('DD/MM [às] HH:mm')
    },
  },
}
</script>
