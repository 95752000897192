<template>
  <div class="container-warmup">
    <div class="d-flex bg-img-insights">
      <div class="d-flex flex-column justify-content-center container-illustration">
        <img
          src="@/assets/img/3czinhos.svg"
          style="width: 85px; margin-bottom: 40px"
        >
        <h1 style="margin-bottom: 1rem">
          {{ $t('call_evaluation_by_ai') }}
        </h1>
        <h4
          style="margin-bottom: 40px"
          class="fw-normal"
          v-html="$t('transform_calls_in_insights')"
        />
        <app-button
          v-if="!handlerClick"
          @click="handlerButtons"
          style="width: 300px"
        >
          {{ $t('early_access') }}
        </app-button>
        <app-button
          v-else
          disabled
          style="width: 300px"
        >
          {{ $t('access_requested') }}
        </app-button>
      </div>
    </div>
    <div>
      <h2 class="mb-4">
        {{ $t('what_the_tool_offers') }}:
      </h2>

      <div class="row">
        <card-warmup
          :title="$t('simplified_summary')"
          :text="$t('simplified_summary_description')"
          icon-class="fal fa-paperclip fw-medium tx-yellow-300"
        />
        <card-warmup
          :title="$t('time_efficiency')"
          :text="$t('time_efficiency_description')"
          icon-class="fal fa-clock fw-medium tx-blue-300"
        />
        <card-warmup
          :title="$t('strategy_feedback_description')"
          :text="$t('strategy_feedback_description')"
          icon-class="fal fa-comments fw-medium tx-pink-300"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CardWarmup from "@components/card-warmup.vue";

import AppButton from "@components/app-button.vue";
import { mapMutations } from "vuex";


export default {
  name: "WarmupInsightsIa",

  components: { AppButton,  CardWarmup },

  data(){
    return {
      handlerClick: false
    }
  },

  methods: {
    ...mapMutations("auth", ["trackEvents"]),

    handlerButtons() {
      this.handlerClick = true;
      this.trackEvents(`[Manager] Request access warmup insights-ia`);
    }
  }
};
</script>

<style scoped>
.container-warmup {
  max-width: 1670px;
  margin: 0 auto;
}

.container-illustration {
  width: 415px;
  margin-left: 40px;
  margin-top: 40px;
}

.bg-img-insights {
  background-image: url("~@assets/img/bg-warmup-insights.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  margin-bottom: 1.5rem;
  height: 340px;

  @media (min-width: 1280px) {
    aspect-ratio: 2.86/1;
    height: auto;
  }
}
</style>
