<template>
  <div class="container-fluid">
    <header-component
      :title="$t('utilization_report')"
      :pretitle="$t('reports_capitalize')"
      redirect-link="/manager/reports"
    />
    <div class="row">
      <div class="col-lg-3">
        <label class="form-label required">{{ $t('period') }}</label>
        <input-date-range
          :max-interval="31"
          v-model:start="minDate"
          v-model:end="maxDate"
          :time-enabled="true"
          :seconds-enabled="true"
          format-date="d/m/Y H:i:S"
        />
      </div>
      <div class="col-lg-2">
        <div class="form-group">
          <label class="form-label">{{ $t('campaign_capitalize') }}</label>
          <select-campaign v-model="campaign_id" />
        </div>
      </div>
      <div class="col-lg-2">
        <div class="form-group">
          <label class="form-label">{{ $t('agent_capitalize') }}</label>
          <select-agent
            v-model="agent_id"
          />
        </div>
      </div>
      <div class="col-lg-3">
        <div class="form-group">
          <label class="form-label">{{ $t('hide_empty_period') }}</label>
          <select
            class="form-select"
            v-model="shortResult"
          >
            <option :value="true">
              {{ $t('yes') }}
            </option>
            <option :value="false">
              {{ $t('no') }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-lg-2">
        <app-button
          class="w-100 btn-report"
          :blocked="!canSearch()"
          @click="search"
          :loading="searchLoading"
        >
          {{ $t('search_report') }}
        </app-button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div
          class="card py-4"
          v-if="totalPerTime.length == 0 && callsStatistics.length == 0 && deliveredCalls.length == 0 && qualificationStatistics.length == 0 &&!showSearchMessage && !searchLoading"
        >
          <div class="card-body text-center">
            {{ $t('no_info_found') }}
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div
          class="card py-4"
          v-if="showSearchMessage"
        >
          <div class="card-body text-center">
            {{ $t('search_metrics') }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="row"
      v-show="totalPerTime.length && !showSearchMessage"
    >
      <div class="col-lg-8">
        <bar-chart
          :labels="totalPerTimeLabels"
          :title="this.$t('agents_metrics_per_period')"
          :format-ticks="secondsToHoursFormat"
          :chart-data="totalPerTimeData"
          :datasets="[
            { label: this.$t('act'), color: '#3075e3' },
            { label: this.$t('idle_capitalize'), color: '#c4c4c4' },
            { label: this.$t('break_capitalize'), color: '#ffd037' },
            { label: this.$t('manual_capitalize'), color: '#de7912' },
            { label: this.$t('mact'), color: '#863fb3' },
            { label: this.$t('capitalizes.talking'), color: '#5cb85c' },
          ]"
        />
      </div>
      <div class="col-lg-4">
        <doughnut-chart
          :title="$t('total_call')"
          :chart-data="totalTotalPerTimeData"
          :format-tooltip="convertSecondsToTime"
          :labels="[
            this.$t('act'),
            this.$t('idle_capitalize'),
            this.$t('break_capitalize'),
            this.$t('manual_capitalize'),
            this.$t('mact'),
            this.$t('capitalizes.talking'),
          ]"
          :colors="[
            '#3075e3',
            '#c4c4c4',
            '#ffd037',
            '#de7912',
            '#863fb3',
            '#5cb85c',
          ]"
        />
      </div>
    </div>
    <div
      class="row"
      v-show="callsStatistics.length && !showSearchMessage"
    >
      <div class="col-lg-4 h-100">
        <doughnut-chart
          :title="$t('total_call')"
          :labels="[
            this.$t('abandoned'),
            this.$t('post_call_ivr'),
            this.$t('pre_call_ivr'),
            this.$t('capitalizes.connected'),
            this.$t('fail'),
            this.$t('not_answered'),
          ]"
          :colors="['#de7912', '#ce4c35', '#8a0728', '#5cb85c', '#b2b3c1', '#989898']"
          :chart-data="totalCallsStatisticsData"
        />
      </div>
      <div class="col-lg-8">
        <bar-chart
          :title="this.$t('calls_per_period')"
          :labels="totalCallsLabels"
          :datasets="[
            { label: this.$t('abandoned'), color: '#de7912' },
            { label: this.$t('post_call_ivr'), color: '#ce4c35' },
            { label: this.$t('pre_call_ivr'), color: '#8a0728' },
            { label: this.$t('capitalizes.connected'), color: '#5cb85c' },
            { label: this.$t('fail'), color: '#b2b3c1' },
            { label: this.$t('not_answered'), color: '#989898' },
          ]"
          :chart-data="callsStatisticsData"
        />
      </div>
    </div>
    <div
      class="row"
      v-show="deliveredCalls.length && !showSearchMessage"
    >
      <div class="col-lg-8">
        <bar-chart
          :title="this.$t('calls_in_period')"
          :labels="deliveredCallsLabels"
          :datasets="[
            { label: this.$t('cpc_plus_conversion'), color: '#3075e3' },
            { label: this.$t('crp'), color: '#5cb85c' },
            { label: this.$t('unknown_capitalize'), color: '#E60B42' },
            { label: this.$t('others'), color: '#c4c4c4' },
          ]"
          :chart-data="deliveredCallsData"
        />
      </div>
      <div class="col-lg-4">
        <doughnut-chart
          :title="this.$t('calls_in_period')"
          :labels="[this.$t('cpc_plus_conversion'), this.$t('crp'), this.$t('unknown_capitalize'), this.$t('others')]"
          :colors="['#3075e3', '#5cb85c', '#E60B42', '#c4c4c4']"
          :chart-data="totalDeliveredCallsData"
        />
      </div>
    </div>
    <div
      class="row"
      v-show="qualificationStatistics.length && !showSearchMessage"
    >
      <div class="col-lg-4">
        <!-- amd here -->
        <doughnut-chart
          :title="$t('total_qualifications')"
          :labels="qualificationsTotalLabels"
          :colors="qualificationsColors"
          :chart-data="qualificationsTotals"
        />
      </div>
      <div class="col-lg-8">
        <bar-chart
          :title="$t('metrics_in_period')"
          :labels="qualificationsLabels"
          :datasets="qualificationsDatasets"
          :chart-data="qualificationsData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { convertSecondsToTime } from "@/utils.js";
import inputDateRange from "@components/input-date-range";
import selectCampaign from "@dialer/manager/components/select-campaign";
import selectAgent from "@dialer/manager/components/select-agent";
import AppButton from "@components/app-button";
import HeaderComponent from "@/components/header-component.vue";
import barChart from "@dialer/manager/components/bar-chart";
import doughnutChart from "@dialer/manager/components/doughnut-chart";
import {
  mapActions,
  mapGetters,
  mapMutations
} from "vuex";
import moment from "moment";
export default {
  /**
   *
   */
  data() {
    return {
      //
      minDate: "",
      maxDate: "",
      campaign_id: null,
      agent_id: null,
      //
      shortResult: false,
      //
      showSearchMessage: true,
      //
      totalPerTimeData: [[], [], [], [], [], []],
      totalTotalPerTimeData: [0, 0, 0, 0, 0, 0],
      totalPerTimeLabels: [],
      //
      callsStatisticsData: [[], [], [], [], []],
      totalCallsStatisticsData: [0, 0, 0, 0, 0],
      totalCallsLabels: [],
      //
      deliveredCallsData: [[], [], [], []],
      totalDeliveredCallsData: [0, 0, 0, 0],
      deliveredCallsLabels: [],
      //
      qualificationsDatasets: [],
      qualificationsColors: [],
      qualificationsLabels: [],
      qualificationsTotalLabels: [],
      qualificationsData: [],
      qualificationsTotals: [],
      //
    };
  },
  /**
   *
   */
  mounted() {
    moment.locale("pt-br");
  },
  /**
   *
   */
  computed: {
    ...mapGetters("manager/utilization_report", {
      searchLoading: "isLoading",
      totalPerTime: "getTotalPerTime",
      deliveredCalls: "getDeliveredCalls",
      callsStatistics: "getCallsStatistics",
      qualificationStatistics: "getQualificationStatistics",
    }),
  },
  /**
   *
   */
  watch: {
    /**
     *
     */
    shortResult() {
      if (this.totalPerTime.length) this.updateTotalPerTimeChart();
      if (this.callsStatistics.length) this.updateCallsStatisticsChart();
      if (this.deliveredCalls.length) this.updateDeliveredCallsChart();
      if (this.qualificationStatistics.length)
        this.updateQualificationStatisticsChart();
    },
    /**
     *
     */
    totalPerTime(val) {
      if (val.length) this.updateTotalPerTimeChart();
    },
    /**
     *
     */
    callsStatistics(val) {
      if (val.length) this.updateCallsStatisticsChart();
    },
    /**
     *
     */
    deliveredCalls(val) {
      if (val.length) this.updateDeliveredCallsChart();
    },
    /**
     *
     */
    qualificationStatistics(val) {
      if (val.length) this.updateQualificationStatisticsChart();
    },
  },
  /**
   *
   */
  components: {
    inputDateRange,
    selectCampaign,
    selectAgent,
    AppButton,
    barChart,
    doughnutChart,
    HeaderComponent
  },
  /**
   *
   */
  methods: {
    /**
     *
     */
    ...mapActions("manager/utilization_report", [
      "findMetricsPerTime",
      "findCallsStatistics",
      "findDeliveredCalls",
      "findQualificationStatistics",
    ]),
    ...mapMutations("manager/utilization_report", ["clearAll"]),
    convertSecondsToTime,
    /**
     *
     */
    updateTotalPerTimeChart() {
      let tempData = [[], [], [], [], [], []];
      let tempTotalData = [0, 0, 0, 0, 0, 0];
      let data = this.prepareData(this.totalPerTime);
      Object.keys(data).forEach((key) => {
        if (data[key]) {
          tempData[0].push(data[key].acw);
          tempData[1].push(data[key].idle);
          tempData[2].push(data[key].interval);
          tempData[3].push(data[key].manual);
          tempData[4].push(data[key].manual_acw);
          tempData[5].push(data[key].speaking);
        } else for (let i = 0; i < tempData.length; i++) tempData[i].push(0);
      });
      for (let i = 0; i < tempData.length; i++)
        tempTotalData[i] =  this.sumArray(tempData[i]);
      this.totalPerTimeData = tempData;
      this.totalTotalPerTimeData = tempTotalData;
      this.totalPerTimeLabels = Object.keys(data);
    },
    /**
     *
     */
    updateCallsStatisticsChart() {
      let tempData = [[], [], [], [], [], []];
      let tempTotalData = [0, 0, 0, 0, 0];
      let data = this.prepareData(this.callsStatistics);
      Object.keys(data).forEach((key) => {
        if (data[key]) {
          tempData[0].push(data[key].abandoned);
          tempData[1].push(data[key].abandoned_due_amd);
          tempData[2].push(data[key].not_answered_due_progress_amd || 0);
          tempData[3].push(data[key].answered);
          tempData[4].push(data[key].failed);
          tempData[5].push(data[key].not_answered);
        } else for (let i = 0; i < tempData.length; i++) tempData[i].push(0);
      });
      for (let i = 0; i < tempData.length; i++)
        tempTotalData[i] = this.sumArray(tempData[i]);
      this.callsStatisticsData = tempData;
      this.totalCallsStatisticsData = tempTotalData;
      this.totalCallsLabels = Object.keys(data);
    },
    /**
     *
     */
    updateDeliveredCallsChart() {
      let tempData = Array.from({ length: 4 }, () => []);
      let tempTotalData = new Array(4).fill(0);
      let data = this.prepareData(this.deliveredCalls);
      Object.keys(data).forEach((key) => {
        if (data[key]) {
          tempData[0].push(data[key].converted);
          tempData[1].push(data[key].dmc);
          tempData[2].push(data[key].unknown);
          tempData[3].push(data[key].answered);
        } else for (let i = 0; i < tempData.length; i++) tempData[i].push(0);
      });
      for (let i = 0; i < tempData.length; i++)
        tempTotalData[i] = this.sumArray(tempData[i]);
      this.deliveredCallsData = tempData;
      this.totalDeliveredCallsData = tempTotalData;
      this.deliveredCallsLabels = Object.keys(data);
    },
    /**
     *
     */
    updateQualificationStatisticsChart() {
      let data = this.prepareData(this.qualificationStatistics);
      let tempDatasets = {};
      let tempTotalData = [];
      let tempCounts = {};
      let tempData = {};
      Object.keys(data).forEach((i) => {
        tempCounts[i] = {};
        if (data[i])
          Object.keys(data[i].qualifications).forEach((j) => {
            let item = data[i].qualifications[j];
            if (!tempDatasets[item.id]) {
              tempDatasets[item.id] = { label: item.name, color: item.color };
              tempData[item.id] = {};
            }
            tempCounts[i][item.id] = item.count;
          });
      });
      Object.keys(tempDatasets).forEach((j) => {
        tempData[j] = [];
      });
      Object.keys(tempCounts).forEach((i) => {
        Object.keys(tempDatasets).forEach((j) => {
          if (tempCounts[i][j]) tempData[j].push(tempCounts[i][j]);
          else tempData[j].push(0);
        });
      });
      tempData = Object.values(tempData);
      for (let i = 0; i < tempData.length; i++)
        tempTotalData[i] = this.sumArray(tempData[i]);
      this.qualificationsTotals = tempTotalData;
      this.qualificationsColors = Object.values(tempDatasets).map(
        (item) => item.color
      );
      this.qualificationsTotalLabels = Object.values(tempDatasets).map(
        (item) => item.label
      );
      this.qualificationsDatasets = Object.values(tempDatasets);
      this.qualificationsData = tempData;
      this.qualificationsLabels = Object.keys(tempCounts);
    },
    /**
     *
     */
    search() {
      this.clearAll();
      if (this.campaign_id && !this.agent_id)
        this.findCallsStatistics({
          campaign_id: this.campaign_id,
          start_date: this.minDate,
          end_date: this.maxDate,
          interval: moment(this.minDate).isSame(this.maxDate, 'day') ? 60 : 1440
        });
      let params = {
        start_date: this.minDate,
        end_date: this.maxDate,
        'trashed[0]': 'campaign'
      };
      if (this.campaign_id) params["campaign_id"] = this.campaign_id;
      if (this.agent_id) params["agent_id"] = this.agent_id;
      this.findDeliveredCalls(params);
      this.findQualificationStatistics(params);
      if (this.campaign_id) this.findMetricsPerTime(params);
      this.showSearchMessage = false;
    },
    /**
     *  sum array
     */
    sumArray(array) {
      return array.reduce((a, b) => parseInt(a) + parseInt(b), 0);
    },
    /**
     * format hors
     */
    secondsToHoursFormat(seconds) {
      return moment.utc(seconds * 1000).format("HH:mm:ss");
    },
    /**
     * sort and fill empty dates
     */
    prepareData(val) {
      let sorted = this.sortByDate(val);
      let data = this.prepareLabels();

      if(moment(this.minDate).format("YYYY-MM-DD") == moment(this.maxDate).format("YYYY-MM-DD")) {
        for (let item of sorted) data[item.date.format("HH") + 'h'] = item;
      } else {
        for (let item of sorted) data[item.date.format("D MMM")] = item;
      }

      if (this.shortResult)
        data = Object.fromEntries(
          Object.entries(data).filter((item) => item[1])
        );
      return data;
    },
    /**
     * sort by date
     */
    sortByDate(obj) {
      for (let i in obj) obj[i].date = moment(obj[i].date);
      obj = obj.sort((a, b) => {
        if (a.date < b.date) return -1;
        else if (a.date > b.date) return 1;
        else return 0;
      });
      return obj;
    },
    /**
     * generate search interval
     */
    prepareLabels() {
      let min = moment(this.minDate);
      let max = moment(this.maxDate);
      let date;
      let labels = {};

      if(min.format("YYYY-MM-DD") == max.format("YYYY-MM-DD")) {
        for (date = min; date <= max; date = date.add(1, "hours"))
          labels[date.format("HH") + 'h'] = null;
      } else {
        for (date = min; date <= max; date = date.add(1, "day"))
          labels[date.format("D MMM")] = null;
      }

      return labels;
    },
    /**
     *
     */
    canSearch() {
      return this.minDate && this.maxDate;
    },
  },
};
</script>
