import axios from "axios";

export default {
  fetchAgents({ commit, getters }) {
    commit("startLoadingFlag", "fetchAgents");

    return new Promise((resolve, reject) => {
      axios
        .get("/agents/status/metrics/total", {
          params: getters.getAgentsParams
        })
        .then(response => {
          commit("setAgents", response.data.data);
          commit("setAgentsPagination", response.data.meta.pagination);
          resolve();
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "fetchAgents");
        });
    });
  },

  updateAgentOnline({ commit, getters }, event) {
    const { agent } = event;
    if (getters.getAgents[agent.id]) {
      commit("updateAgentOnline", event);
    }
  },

  incrementCallsAndConvertedMetrics({ commit, getters }, event) {
    const { callHistory } = event;
    if (getters.getAgents[callHistory.agent.id]) {
      commit("incrementCallsAndConvertedMetrics", callHistory);
    }
  },

  setAgentsFilters({ commit }, filters) {
    commit("setAgentsFilters", filters);
  },

  setAgentsCurrentPage({ commit }, page) {
    commit("setAgentsCurrentPage", page);
  },

  clearAgents({ commit }) {
    commit("clearAgents");
  },

  setWorkBreakExitPending({ commit, getters }, { id, work_break_exit_pending }) {
    if(getters.getAgents[id]) {
      commit("setWorkBreakExitPending", { id, work_break_exit_pending});
    }
  },
};
