<template>
  <div>
    <table class="table app-table mb-0">
      <thead>
        <tr>
          <th class="col-1">
            ID
          </th>
          <th class="col-3">
            Nome da empresa
          </th>
          <th class="col-2">
            Domínio
          </th>
          <th class="col-2">
            Limite de Agentes
          </th>
          <th
            class="col-2"
          >
            CRÉDITO
          </th>
          <th class="col-2" />
        </tr>
      </thead>
      <tbody>
        <tr v-if="loadingFlags.loadTrashedCompanies">
          <td
            class="p-0 loading"
            v-for="i in 6"
            :key="i"
          >
            <table-loading :rows="12">
              <div class="cell col" />
            </table-loading>
          </td>
        </tr>

        <tr v-else-if="!trashedCompanies.length">
          <td
            class="text-center text-muted"
            colspan="5"
          >
            <span class="fe fe-alert-octagon" /> Nenhuma empresa encontrada!
          </td>
        </tr>

        <tr
          v-for="company in trashedCompanies"
          :key="company.id"
          class="cursor-pointer"
        >
          <td>
            <span href="#">
              {{ company.id }}
            </span>
          </td>
          <td>
            <span
              href="#"
            >
              {{ company.name }}
              <img
                v-if="company.pro_mode"
                class="pro-mode-icon mx-2"
                src="~@assets/img/icons/pro-mode.svg"
              >
            </span>
          </td>
          <td>
            <span
              href="#"
            >
              {{ company.domain }}
            </span>
          </td>
          <td>
            <span
              href="#"
            >
              {{ company.max_agents_login }}
            </span>
          </td>
          <td>
            <span>
              {{ company.balance.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
            </span>
          </td>
          <td>
            <button
              class="col-12 btn btn-sm btn-primary"
              @click="restoreTrashedCompany(company.id)"
            >
              Reativar
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="card-footer"
      v-if="!loadingFlags.loadTrashedCompanies && trashedCompanies.length"
    >
      <div class="col-lg-12">
        <paginator
          v-if="!loadingFlags.loadTrashedCompanies"
          :pagination="trashedCompaniesPagination"
          @change-page="changeTrashedCompanyPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TableLoading from "@admin/components/table-loading.vue";
import paginator from "@components/app-paginator";

export default {
  props: {
    searchValue: {
      type: String,
      default: ''
    },

    proModeCompanies: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    this.loadTrashedCompanies();
  },

  components: {
    TableLoading,
    paginator,
  },

  computed: {
    ...mapGetters("admin/companies", {
      loadingFlags: "getLoadingFlags",
      trashedCompaniesPagination: "getTrashedCompaniesPagination",
      trashedCompanies: "getTrashedCompanies",
    }),
  },

  methods: {

    ...mapActions("admin/companies", [
      "loadTrashedCompanies",
      "setTrashedCompaniesFilters",
      "setTrashedCompaniesCurrentPage",
      "releaseAccessToTrashedCompany",
      "loadBlockedCompanies"
    ]),

    fetchTrashedCompanies(params) {
      if (this.proModeCompanies === false) {
        delete params.pro_mode}
        this.setTrashedCompaniesFilters({
          ...params
        });
        this.setTrashedCompaniesCurrentPage(1);
        this.loadTrashedCompanies();
    },

     changeTrashedCompanyPage(page){
      this.setTrashedCompaniesCurrentPage(page);
      this.loadTrashedCompanies();
    },

    async restoreTrashedCompany(id) {
      await this.releaseAccessToTrashedCompany({id});
      this.loadTrashedCompanies();
      this.loadBlockedCompanies();
    }
  },

  watch: {
    searchValue(search) {
      this.fetchTrashedCompanies({search,
        trashed: true,
      });

      this.setTrashedCompaniesFilters({
        search: this.searchValue,
        trashed: true
      });

      this.setTrashedCompaniesCurrentPage(1);

      this.loadTrashedCompanies();
    },

    proModeCompanies(pro_mode) {
      this.fetchTrashedCompanies({pro_mode,
        trashed: true,
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.pro-mode-icon {
  width: 16px;
  height: 16px;
  top: 6px;
  left: 187px;
}
</style>
