<template>
  <form
    @submit.prevent="submit"
    class="needs-validation"
    novalidate
  >
    <div class="card pt-3 pb-5">
      <p class="px-4 text-dark fw-medium fs-4 mb-0">
        Dados pessoais
      </p>
      <hr class="my-3">
      <div class="px-4 row g-4 d-flex flex-row flex-wrap">
        <div class="col-md-6">
          <label class="form-label">Nome</label>
          <input
            type="text"
            v-model="personalData.username"
            :disabled="loading.createNewCompany"
            :class="{
              'form-control rounded-3': true,
              ' is-invalid': errors.name && errors.name
            }"
            placeholder="Nome"
            data-test="createNewAccountPersonalDataName-input"
          >
          <div class="invalid-feedback">
            {{ errors.name && errors.name[0] }}
          </div>
        </div>
        <div class="col-md-6">
          <label
            for="validationCustom02"
            class="form-label"
          >Email</label>
          <input
            type="email"
            v-model="personalData.email"
            :disabled="loading.createNewCompany"
            :class="{
              'form-control rounded-3': true,
              ' is-invalid': errors.email && errors.email
            }"
            placeholder="Email"
            data-test="createNewAccountPersonalDataEmail-input"
          >
          <div class="invalid-feedback">
            {{ errors.email && errors.email[0] }}
          </div>
        </div>
        <div class="col-md-6">
          <label
            for="validationCustomUsername"
            class="form-label"
          >Telefone</label>
          <div class="input-group has-validation">
            <input
              v-maska
              data-maska="[
              '+# ###-###-####',
              '+## (##) ####-####',
              '+## (##) # ####-####',
              '+### (##) # ####-####'
              ]"
              v-model="personalData.phone"
              :disabled="loading.createNewCompany"
              class="form-control rounded-3"
              placeholder="Telefone"
              data-test="createNewAccountPersonalDataPhone-input"
            >
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-label">Senha</label>
          <div class="input-group has-validation">
            <input
              v-model="personalData.password"
              :disabled="true"
              :class="{
                'form-control rounded-3 password-input': true,
                'is-invalid': errors.password && errors.password
              }"
              placeholder="Senha"
              data-test="createNewAccountPersonalDataPassword-input"
            >
            <span
              @click="generateRandomPassword"
              class="input-group-text bg-primary cursor-pointer border-1"
              data-test="createNewAccountPersonalDataGeneratePassword-button"
            >
              <i class="fa-solid fa-rotate-left text-light" />
            </span>
            <div class="invalid-feedback">
              {{ errors.password && errors.password[0] }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card pt-3 pb-4">
      <p class="px-4 text-dark fw-medium fs-4 mb-0">
        Dados da empresa
      </p>
      <hr class="my-3">
      <div class="px-4 row g-4 d-flex flex-row flex-wrap">
        <div class="col-md-6">
          <label class="form-label">Nome da empresa</label>
          <input
            type="text"
            v-model="companyData.name"
            :disabled="loading.createNewCompany"
            :class="{
              'form-control rounded-3': true,
              'is-invalid': errors.company_name && errors.company_name
            }"
            placeholder="Nome"
            data-test="createNewAccountCompanyDataName-input"
          >
          <div class="invalid-feedback">
            {{ errors.company_name && errors.company_name[0] }}
          </div>
        </div>
        <div class="col-md-6">
          <div class="d-flex align-items-center">
            <div class="w-100">
              <label class="form-label">Domínio</label>
              <input
                type="text"
                v-model="companyData.domain"
                :disabled="loading.createNewCompany"
                class="form-control rounded-3"
                placeholder="Domínio"
                data-test="createNewAccountCompanyDataDomain-input"
              >
            </div>
            <div class="align-self-center mt-5 ms-3">
              .3c.plus
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-label">Plano</label>
          <select
            class="form-select"
            v-model="companyData.plan"
            :disabled="loading.createNewCompany"
            data-test="createNewAccountCompanyDataPlan-select"
          >
            <option
              selected
              disabled
              value=""
              data-test="createNewAccountCompanyDataPlanEmpty-option"
            >
              {{ $t("select") }}
            </option>
            <option
              value="1"
              data-test="createNewAccountCompanyDataPlanIlimited-option"
            >
              Ilimitado
            </option>
            <option
              value="2"
              data-test="createNewAccountCompanyDataPlanMinute-option"
            >
              Minutagem
            </option>
            <option
              value="3"
              data-test="createNewAccountCompanyDataPlanMinuteExternal-option"
            >
              Minutagem externa
            </option>
          </select>
        </div>
        <div class="col-md-6">
          <label class="form-label">Tags </label>
          <VueMultiselect
            v-model="tag"
            label="name"
            track-by="id"
            placeholder="Selecione as tags"
            :select-label="$t('click_or_press_enter_to_select')"
            :deselect-label="$t('click_or_press_enter_to_remove')"
            :selected-label="$t('selected')"
            open-direction="bottom"
            :multiple="true"
            :close-on-select="false"
            :preserve-search="true"
            :options="tags"
          />
        </div>
        <div
          class="col-12"
          v-if="countries && countries.length"
        >
          <p class="mb-2">
            Pais
          </p>
          <single-select
            id="country"
            label="name"
            :list-array="countries"
            @selected-option="handlerCountry"
          />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between mb-0 mt-2">
      <div class="mb-3">
        <div
          v-if="!couponOpen"
          class="p-2 cursor-pointer"
          @click="couponOpen = !couponOpen"
          data-test="createNewAccountSelectCupon-button"
        >
          <a class="text-secondary fs-4">
            Possui cupom?
          </a>
          <i class="ps-2 fa-solid fs-6 text-secondary fa-chevron-down" />
        </div>
        <div
          v-else
          class=" position-relative"
        >
          <i class="icon-tag fe fe-tag position-absolute text-primary" />
          <input
            v-model="coupon"
            class="form-control rounded-3 ps-5"
            placeholder="Digite seu cupom"
            data-test="createNewAccountCupon-input"
          >
        </div>
      </div>
    </div>
    <button
      type="submit"
      class="col-12 btn btn-lg btn-primary mt-5"
      :disabled="loading.createNewCompany || isFieldsDisabled"
      data-test="createNewAccountSubmit-button"
    >
      <template v-if="loading.createNewCompany">
        <div
          class="spinner-border spinner-border-sm"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
        Criar
      </template>
      <template v-else>
        Criar
      </template>
    </button>
  </form>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import VueMultiselect from "vue-multiselect";
import SingleSelect from "@components/single-select.vue";

export default {
  data() {
    return {
      companyData: {
        name: "",
        domain: "",
        plan: "",
        country_id: "",
        currency: "",
        language: "",
      },
      personalData: {
        username: "",
        email: "",
        phone: "",
        password: "",
      },
      tag: "",
      coupon: "",
      couponOpen: false,
      countries: [],
    };
  },

  components: {
    SingleSelect,
    VueMultiselect,
  },

  mounted() {
    this.generateRandomPassword();
    this.setFiltersIncludes(["countries"]);
    this.findFilters();
    this.findTags();
  },

  computed: {
    ...mapGetters("admin/companies", {
      loading: "getLoadingFlags",
      errors: "getErrors",
    }),
    ...mapGetters("manager/filters", {
      filters: "getFilters",
    }),
    ...mapGetters("admin/tags", {
      tags: "getList",
    }),

    fields() {
      return { ...this.companyData, ...this.personalData };
    },

    isFieldsDisabled() {
      let validation = false;

      Object.entries(this.fields)
          .forEach(field => {
            let value = field[1];
            if (!value) return (validation = true);
          });

      return validation;
    },
  },

  methods: {
    ...mapActions("manager/filters", ["findFilters", "setFiltersIncludes"]),
    ...mapActions("admin/companies", ["createNewCompany"]),
    ...mapActions("admin/tags", ["findTags"]),
    submit() {
      this.createNewCompany({
        ...this.fields,
        coupon: this.coupon,
        tag: this.tag ? this.tag.map(t => t.id) : [],
      });
    },

    generateRandomPassword() {
      const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
      const uppercaseLetters = lowercaseLetters.toUpperCase();
      const numbers = "0123456789";

      const allCharacters = lowercaseLetters + uppercaseLetters + numbers;
      const passwordLength = Math.floor(Math.random() * 9) + 8;

      const specialCharacterIndex = Math.floor(Math.random() * passwordLength);
      const specialCharacters = "@$!%*#?&";
      const specialCharacterIndexSelected = Math.floor(Math.random() * specialCharacters.length);

      let password = "";
      let lastCharacter = "";

      while (password.length < passwordLength) {
        const randomIndex = Math.floor(Math.random() * allCharacters.length);
        const randomCharacter = allCharacters[randomIndex];

        if (specialCharacterIndex === password.length) {
          password += specialCharacters[specialCharacterIndexSelected];
        } else if (randomCharacter !== lastCharacter) {
          password += randomCharacter;
          lastCharacter = randomCharacter;
        }
      }

      if (!/[a-zA-Z]/.test(password) || !/\d/.test(password)) {
        return this.generateRandomPassword();
      }

      this.personalData.password = password;
    },
    handlerCountry(country) {
      this.companyData.country_id = country.id;
    },
    handleLanguage() {
      if (this.companyData.country_id === 33) {
        this.companyData.language = "pt-br";
        this.companyData.currency = "BRL";
      } else {
        this.companyData.language = "en-us";
        this.companyData.currency = "USD";
      }
    },
  },
  watch: {
    filters: {
      handler(data) {
        if (data.countries) {
          this.countries = data.countries;
        }
      },
      deep: true,
    },
    "companyData.country_id": {
      handler() {
        this.handleLanguage();
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.password-input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.icon-tag {
  z-index: 2;
  margin-top: 9px;
  margin-left: 16px;
}
</style>
