<template>
  <div class="card-body pt-0">
    <div class="row justify-content-between align-items-start">
      <div class="col position-relative">
        <div
          ref="internalNoteBtn"
          class="position-absolute end-0 top-0 rounded-circle pin-icon text-center cursor-pointer"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          :title="notePayload.is_pinned ? $t('unpin') : $t('pin_on_top')"
          :class="notePayload.is_pinned ? 'unpin':'bg-white'"
          @click="pin"
        >
          <span class="fa-regular fa-thumbtack rotate-45 text-small" />
        </div>
        <textarea
          v-model="notePayload.note"
          class="internal-note form-control overflow-auto create-note"
          :class="{
            'is-invalid': errors.note,
            'border-0': !errors.note
          }"
          rows="1"
          @input="autoGrowTextArea"
        />
        <div class="invalid-feedback">
          {{ errors.note && errors.note[0] }}
        </div>
      </div>
      <app-button
        @click="submitNote"
        style="width: 124px"
        :loading="loadingFlags.createNote"
      >
        <i class="fa-regular fa-check align-self-center" />
        {{ $t('capitalizes.save') }}
      </app-button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import { autoGrowTextArea } from "@/utils";
import AppButton from "@components/app-button.vue";

export default {
  name: "CreateNote",
  components: {AppButton},

  data() {
    return {
      notePayload: {
        note: "",
        is_pinned: false
      },
    }
  },

  mounted() {
    if(this.activityCard && this.activityCard?.data?.note && this.activityCard.opportunity_id === this.opportunity?.id) {
      this.notePayload = this.activityCard.data;
      this.setActivityCard({})
    }
  },

  computed:{
    ...mapGetters("crm/activities", {
      loadingFlags: "getLoadingFlags",
      errorsData: "getErrors",
      activityCard: "getActivityCard"
    }),

    ...mapGetters("crm/opportunities", {
      opportunity: "getOpportunity",
      currentTab: "getCurrentTabTimeline",
    }),

    errors() {
      return this.errorsData.createNote
    }
  },

  methods: {
    autoGrowTextArea,
    ...mapActions("crm/activities", ["createNote"]),
    ...mapMutations("crm/activities", [
      "setActivityCard"
    ]),

    pin() {
      this.notePayload.is_pinned = !this.notePayload.is_pinned
    },

    submitNote() {
      const payload = {
        opportunityId: this.opportunity.id,
        opportunityFunnelId: this.opportunity.opportunity_funnel_id,
        funnelId: this.$route.params.id,
        note: this.notePayload,
        currentTab: this.currentTab,
        contactIds: this.opportunity.contacts.map(contact => contact.id),
      }
      this.createNote(payload).then(()=>{
        this.clearPayload()
        let element = document.getElementById('activity-timeline');
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      })
    },

    clearPayload() {
      this.notePayload = {
        note: "",
        is_pinned: false
      }
      this.setActivityCard({})
    }
  },

  watch: {
    notePayload: {
      handler(newVal) {
        this.setActivityCard({
          opportunity_id: this.opportunity.id,
          data: newVal
        })
      },
      deep: true
    }
  },
}
</script>

<style lang="scss" scoped>
.internal-note {
  background: #FFE9A9 !important;
}

.pin-icon {
  width: 26px;
  height: 26px;
  margin-top: 9px;
  margin-right: 20px;
  padding-top: 1px;
  .rotate-45 {
    transform: rotate(45deg);
  }
}
.create-note{
  padding-right: 40px;
  resize: none;
  max-height: 88px;
}
.unpin{
  &:hover{
    background-color: #FFFFFF;
    opacity: 0.5;
  }
}
</style>
