<template>
  <span class="d-inline-block">
    <i
      :class="{
        'opacity-0': !showStatus,
        'tx-red-300 fa-minus': status < 0 ,
        'tx-gray-500 fa-minus': status === 0 ,
        'tx-green-400 fa-plus': status > 0 ,
      }"
      class="fa-regular fs-6 me-1"
    />
    <span
      :class="{
        'tx-text-gray': !showStatus,
        'tx-text-200': showStatus
      }"
      class="d-inline-block"
    >
      {{ formatCurrency(parseFloat(value)) }}
    </span>
  </span>
</template>
<script>
import {Utils} from "@/utils";

export default {
  name: "BalanceComponent",
  props: {
    value: {
      type: [Number, String],
      required: true
    },

    status: {
      type: Number,
      default: 0
    },

    showStatus: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    formatCurrency: Utils.formatCurrency,
  }
}
</script>

<style scoped lang="scss">

</style>