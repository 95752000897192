export default {
  isLoading: state => state.loading,
  getLoadingFlags: state => state.loadingFlags,

  // Active Campaigns
  getActiveCampaigns: state => state.activeCampaigns,
  getActiveCampaignsPagination: state => state.activeCampaignsPagination,
  getActiveCampaignsParams: (state) =>
    Object.assign(
      {
        page: state.activeCampaignsPagination.current_page,
        paused: false
      }
      , state.filters, { include: state.include.join(',') }),

  // Paused Campaigns
  getPausedCampaigns: state => state.pausedCampaigns,
  getPausedCampaignsPagination: state => state.pausedCampaignsPagination,
  getPausedCampaignsParams: (state) =>
    Object.assign(
      {
        page: state.pausedCampaignsPagination.current_page,
        paused: true
      }
      , state.filters, { include: state.include.join(',') }),

  // Dashboard Show Campaign
  getCampaign: state => state.campaign,
  getCampaignParams: state => Object.assign({ include: state.campaignInclude.join(',') }),

  getFilters: state => state.filters,
  getList: state => state.list,
  getListActive: state => state.listActive,
  getAlert: state => state.alert,
  getErrors: state => state.errors,
  getRegister: state => state.register,
  getPagination: state => state.pagination,
  getDataSelect: state => state.dataSelect,
  getLoadingContext: state => state.loadingContext,
  getAgetsPerCampagin: state => state.agetsPerCampagin,
  getCreateData: state => state.createData,
  getCompanyUraLimit: (state) => state.companyUraLimit,
}
