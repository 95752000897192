<template>
  <div
    class="d-flex flex-wrap"
  >
    <div
      class="card col-12 "
      :class="{'border-bottom-0': !showKeysConfig}"
    >
      <div
        class="collapse resume p-4 mb-0 border-bottom rounded"
        id="activeIvrAudio"
      >
        <div class="d-flex pb-0">
          <h4>{{ $t("abstract") }}</h4>
        </div>
        <div>
          <audio-player
            v-if="fields.audio"
            :file="fields?.audio"
            :bg-button="false"
            class="bg-gray-blue-100 p-custom rounded"
          />
        </div>
      </div>
      <div
        class="card-body collapse card-keys"
        id="activeIvrResumeKeys"
      >
        <h4 class="tx-text-200 h4-custom border-bottom">
          {{ $t("keys_settings") }}
        </h4>
        <div
          class="d-flex flex-wrap gap-custom"
        >
          <div
            class="d-flex flex-wrap gap-2 align-items-center"
          >
            <h5 class="flex-shrink-0 mb-0">
              {{ selectedKeys.length > 1 ? $t("capitalizes.keyboard_keys") : $t("key") }}
            </h5>
            <template
              v-if="selectedKeys.length > 0"
            >
              <template
                v-for="(key, i) in selectedKeys"
                :key="i"
              >
                <h5
                  class="flex-shrink-0 mb-0"
                >
                  {{ key }} {{ i < selectedKeys.length - 1 ? "," : "" }}
                </h5>
              </template>
              <h5
                class="mb-0"
              >
                :
              </h5>
            </template>
            <h5
              v-else
              class="flex-shrink-0 mb-0"
            >
              {{ $t("capitalizes.none_key") }}:
            </h5>
            <span
              class="text-truncate"
            >{{ redirectTypes[fields.redirect_type]?.name }}</span>
          </div>
          <div
            class="d-flex gap-2 align-items-center col-12"
            v-if="fields.redirect_type === 2 && getGoupChannelName"
          >
            <h5 class="flex-shrink-0 mb-0">
              {{ $t("channel_group") }}:
            </h5>
            <span
              :title="getGoupChannelName "
              class="text-truncate"
            >{{ getGoupChannelName }}</span>
          </div>
          <div
            class="d-flex gap-2 align-items-center col-12"
            v-if="fields.redirect_type === 2 && fields.instances[0]?.message"
          >
            <h5 class="flex-shrink-0 mb-0">
              {{ $t("message") }}:
            </h5>
            <span
              :title="fields.instances[0]?.message "
              class="text-truncate"
            >{{ fields.instances[0]?.message }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card collapse col-12"
      id="activeIvrResumeAdvanced"
    >
      <div class="card-body">
        <h4 class="tx-text-200 h4-custom border-bottom">
          {{ $t("advanced_settings") }}
        </h4>
        <div
          class="d-flex flex-wrap gap-custom"
        >
          <div
            v-if=" fields.wait_time"
            class="d-flex gap-2 align-items-center"
          >
            <h5 class=" mb-0">
              {{ $t("waiting_time") }}:
            </h5>
            <span>{{ fields.wait_time }}s</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Collapse } from "bootstrap";
import AudioPlayer from "@components/audio-player.vue";
import { mapGetters } from "vuex";

export default {
  name: "ResumeProgress",
  components: {
    AudioPlayer,
  },
  props: {
    fields: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeIvrAudio: null,
      activeIvrResumeKeys: null,
      activeIvrResumeAdvanced: null,
      redirectTypes: {
        1: {
          name: this.$t("dialer_menu_capitalize"),
        },
        2: {
          name: this.$t("Omnichannel"),
        },
      },
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.activeIvrAudio = new Collapse("#activeIvrAudio", {
        toggle: false,
      });
      this.activeIvrResumeKeys = new Collapse("#activeIvrResumeKeys", {
        toggle: false,
      });
      this.activeIvrResumeAdvanced = new Collapse("#activeIvrResumeAdvanced", {
        toggle: false,
      });
      this.$nextTick(() => {
        this.showAudio ? this.activeIvrAudio.show() : this.activeIvrAudio.hide();
        this.showKeysConfig ? this.activeIvrResumeKeys.show() : this.activeIvrResumeKeys.hide();
        this.showAdvancedConfig ? this.activeIvrResumeAdvanced.show() : this.activeIvrResumeAdvanced.hide();
      });
    });
  },

  methods: {
    getNameRedirect(id) {
      return this.receptiveQueues.find(queue => queue.id == id)?.name;
    },
  },
  computed: {
    ...mapGetters("manager/ivrs", {
      groupChannels: "getGroupChannels",
    }),

    showAudio() {
      return !!this.fields.audio;
    },

    showKeysConfig() {
      return !!this.fields.redirect_type
    },

    showAdvancedConfig() {
      return !!(this.fields.wait_time);
    },

    selectedKeys() {
      return this.fields.keys
          .map((key, index) => key ? index : null)
          .filter(index => index !== null);
    },

    getGoupChannelName() {
      return this.groupChannels.find(gc => gc.id === this.fields.instances[0]?.group_channel_id)?.name
    },
  },

  watch: {
    showAudio(value) {
      value ? this.activeIvrAudio?.show() : this.activeIvrAudio?.hide();
    },
    showKeysConfig(value) {
      value ? this.activeIvrResumeKeys?.show() : this.activeIvrResumeKeys?.hide();
    },
    showAdvancedConfig(value) {
      value ? this.activeIvrResumeAdvanced?.show() : this.activeIvrResumeAdvanced?.hide();
    },
  },
}
</script>
<style scoped lang="scss">
.resume {
  background: url("~@/assets/img/covers/grainy-cover.png") 0px 0px / 100% 100% no-repeat;
}

.h4-custom {
  padding-bottom: 1rem;
  margin-bottom: 1rem !important;
}

.gap-custom {
  gap: 0.5rem;
}

.p-custom {
  padding: 0 1rem 0 0.5rem;
}
</style>

