<template>
  <hover-button
    ref="manualButton"
    @click="enterManual"
    background-color="var(--color-manual)"
    text-color="var(--color-offline)"
    icon="fa-kit fa-key-mobile"
    :disabled="loadingFlags.enterManual || !campaign.allows_manual"
  />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import HoverButton from "@dialer/agent/components/hover-button.vue";

export default {
  name: "ManualButton",
  components: {
    HoverButton,
  },

  computed: {
    ...mapGetters("system", {
      loadingFlags: "getLoadingFlags",
    }),

    ...mapGetters("agent/agent", {
      campaign: "getCampaign",
    })
  },

  methods: {
    ...mapActions("system", ["enterManual"])
  },
};
</script>
