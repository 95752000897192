export default {
  getLoadingFlags: state => state.loadingFlags,
  getImprovedMessage: state => state.improvedMessage,
  getSuggestedMessage: state => state.suggestedMessage,
  getNewMessage: state => state.newMessage,
  getMessages: state => chatId => state.messages[chatId],
  getPaginations: state => chatId =>  state.paginations[chatId],
  getAllPaginations: state => state.paginations,
  getStashedMessages: state => chatId => state.stashedMessages[chatId],
  getMessagesForSearch: state => chatId => state.messagesForSearch[chatId],
  getPaginationsForSearch: state => chatId => state.paginationsForSearch[chatId],
}
