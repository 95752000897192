export default {
  startLoadingFlag: (state, flag) => state.loadingFlags[flag] = true,
  stopLoadingFlag: (state, flag) => state.loadingFlags[flag] = false,

  // TODO: Remove loading mutations below, change the mutation
  // name above to startLoading and stopLoading and use only loading flags
  startLoading: state => (state.loading = true),
  stopLoading: state => (state.loading = false),
  stopLoadingContext: state => (state.loadingContext = false),
  setLoadingContext: (state, loadingContext) => state.loadingContext = loadingContext,
  setGroupChannelInstance: (state, groupChannelInstance) => state.groupChannelInstance.push(groupChannelInstance),
  clearGroupChannelInstance: (state) => state.groupChannelInstance = [],
  setGroupChannelsPagination: (state, {status, groupChannelsPagination}) => state.groupChannelsPagination[status] = groupChannelsPagination,
  setGroupChannels: (state, {status, groupChannels}) => state.groupChannels[status] = groupChannels,
  setGroupChannel: (state, groupChannel) => state.groupChannel = groupChannel,
  setPagination: (state, pagination) => {
    state.pagination = pagination;
  },

  setInstanceStatusInGroupChannel: (state, { groupChannelId, instanceId, status }) => {
    const groupChannelIndex = state.groupChannels.active.findIndex(el => el.id == groupChannelId)

    if (groupChannelIndex !== -1) {
      const instanceIndex = state.groupChannels.active[groupChannelIndex].channels.data.findIndex(el => el.id == instanceId)

      if (instanceIndex !== -1) {
        state.groupChannels.active[groupChannelIndex].channels.data[instanceIndex].status = status
      }
    }
  }
};
