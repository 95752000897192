<template>
  <modal-component
    :show="congratsModal"
    @close="closeCongratsModal"
    :center="true"
    :custom-size="'500px'"
  >
    <template #content>
      <div class="w-100">
        <button
          type="button"
          class="btn-close me-4 mt-4"
          @click="closeCongratsModal"
          aria-label="Close"
        />
      </div>
      <div class="modal-body p-0">
        <div
          class="d-flex flex-column justify-content-center align-items-center"
          style="gap:32px; padding: 48px;"
        >
          <img
            src="@assets/img/congrats.png"
            class="d-block m-auto me-auto"
            style="height: 280px"
          >
          <div>
            <h3 class="text-center">
              {{ $t("complete_all_steps") }}
            </h3>
            <p class="text-center">
              {{ $t("enjoy_crm_tools") }}
            </p>
          </div>
          <div class="d-flex flex-column justify-content-center align-items-center gap-3">
            <button
              class="btn btn-primary"
              style="width: 309px"
              @click="goToActivities"
            >
              {{ $t("go_to_my_activities") }}
            </button>
            <button
              class="btn btn-primary border-0"
              style="background-color: #E1E9F4; color: #677C92; width: 309px"
              @click="closeCongratsModal"
            >
              {{ $t("close") }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from "@components/modal-component.vue";
import {mapActions, mapMutations} from "vuex";

export default {
  components: {ModalComponent},
  emits: ['close'],
  props: {
    congratsModal: {
      type: Boolean,
      default: false
    },
    currentFunnelId: {
      type: Number,
      default: null
    }
  },
  methods: {
    ...mapMutations("crm/tour", ["setCurrentTab"]),
    ...mapActions('crm/tour', {
      updateTourStep: 'updateTourSteps'
    }),
    closeCongratsModal() {
      this.updateTourStep({ finish_tour: true }).then(() => {
        this.$emit('close');
      });
    },
    goToActivities(){
      this.setCurrentTab('activities')
      this.$router.push(`${this.currentFunnelId}`)
    }
  }
}
</script>


<style scoped lang="scss">

</style>