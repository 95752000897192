
<template>
  <p
    class="p-0 m-0"
    ref="connections"
    @mouseenter="showTooltip"
    @mouseleave="hideTooltip"
  >
    {{ $t('channels') }}: {{ active }}/{{ licenses }}
  </p>
  <div
    ref="connectionsTooltip"
    id="tooltip"
  >
    <div class="card p-3 text-medium">
      <h5 class="card-title mb-3">
        {{ $t('channels') }}
      </h5>
      <div class="d-flex justify-content-between">
        <p class="m-0">
          {{ $t('list_connections_active') }}
        </p>
        <span class="fw-medium">{{ active }}</span>
      </div>
      <hr class="navbar-divider my-2">
      <div class="d-flex justify-content-between">
        <p class="m-0">
          {{ $t('total_available_channel') }}
        </p>
        <span class="fw-medium">{{ licenses }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import {mapGetters} from "vuex";

export default {
  props: {
    licenses: {
      type: Number,
      default: 0
    }
  },

  data(){
    return{
      connectionsTooltip: null,
      popperInstance: null
    }
  },

  computed:{
    ...mapGetters("auth", {
      isManager: "isManager",
      user: "getUser"
    }),

    ...mapGetters("manager/whatsapp", {
      metrics: "getWhatsappMetrics",
    }),

    active() {
      return this.metrics.channels || 0
    }
  },

  mounted(){
    this.configTooltip()
  },

  methods:{
    configTooltip(){
      if(!this.user.company.whatsapp_licenses < 1 || !this.user.company.whatsapp_licenses == null ) {
        const connections = this.$refs.connections;
        this.connectionsTooltip = this.$refs.connectionsTooltip;

        this.popperInstance = createPopper(connections, this.connectionsTooltip, {
          placement: "bottom"
        });
      }
    },

    showTooltip() {
      this.connectionsTooltip.setAttribute("data-show", "");
      this.popperInstance.update()
    },

    hideTooltip() {
      this.connectionsTooltip.removeAttribute("data-show");
    },
  }
};
</script>

<style scoped lang="scss">
#tooltip {
  display: none;
  width: 285px;
  z-index: 1080;
}

#tooltip[data-show] {
  display: block;
}
</style>
