<!-- PasswordValidator.vue -->
<template>
  <div
    v-if="password.length > 5 && showFields"
    class="p-0 h-auto mt-2"
  >
    <div class="h-auto p-3">
      <h5 class="tx-text-300">
        Critérios
      </h5>
      <div
        class="d-flex mt-2 gap-1"
      >
        <i
          :class="verifyFields().isValid ? 'fas fa-check tx-text-blue' : 'fal fa-times'"
          class="mx-0 p-2 pt-0"
        />
        <p>
          Possuir pelo menos uma letra maiúscula, uma minúscula, um número e um caractere especial
        </p>
      </div>
      <div

        class="d-flex align-items-center"
      >
        <i
          :class="verifyFields().passwordLength ? 'fas fa-check tx-text-blue' : 'fal fa-times'"
          class="mx-0 p-2"
        />
        <p>Possuir de 8 a 16 caracteres</p>
      </div>
      <div
        class="d-flex mb-2 align-items-center"
      >
        <i
          :class="verifyFields().sequenceRepetition && verifyFields().sequence ? 'fas fa-check tx-text-blue' :
            'fal fa-times'"
          class="mx-0 p-2"
        />
        <p>Nao possuir repetição ou sequencias iguais (111,123,abc,qwe)</p>
      </div>
      <span class="tx-text-gray">Dica: Evite datas de nascimento, documentos ou email</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "PasswordValidate",
  props: {
    password: {
      type:  String,
      default: ""
    }
  },
  data() {
    return {
      showFields: true,
      noSequentialNumbers: '01234567890',
      noSequentialLetters: 'abcdefghijklmnopqrstuvwxyz',
    }
  },
  emits: ['validPassword'],
  computed: {
    hasLowercase() {
      return /[a-z]/.test(this.password);
    },
    hasUppercase() {
      return /[A-Z]/.test(this.password);
    },
    hasNumber() {
      return /\d/.test(this.password);
    },
    hasSpecialChar() {
      return /[!@#$%^&*]/.test(this.password);
    },
    hasLength() {
      return this.password.length >= 8 && this.password.length <= 16;
    },
    hasRepeatingSequences() {
      return !/(.)\1{2,}/.test(this.password);
    },
    hasSequentialNumbers() {
      for (let i = 0; i < this.noSequentialNumbers.length - 2; i++) {
        const sequence = this.noSequentialNumbers.substring(i, i + 3);
        if (this.password.includes(sequence)) {
          return false;
        }
      }
      return true;
    },

    hasSequentialLetters() {
      for (let i = 0; i < this.noSequentialLetters.length - 2; i++) {
        const sequence = this.noSequentialLetters.substring(i, i + 3);
        if (this.password.toLowerCase().includes(sequence)) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    verifyPassword() {
      const isValid =
        this.hasLength &&
        this.hasUppercase &&
        this.hasLowercase &&
        this.hasSpecialChar &&
        this.hasNumber &&
        this.hasRepeatingSequences &&
        this.hasSequentialNumbers &&
        this.hasSequentialLetters;

      if (isValid) {
        this.showFields = false;
        this.$emit("validPassword", isValid);
      } else {
        this.showFields = true;
      }
    },
    verifyFields() {
      const isValid =
        this.hasUppercase &&
        this.hasLowercase &&
        this.hasSpecialChar &&
        this.hasNumber;

      const passwordLength = this.hasLength;
      const sequenceRepetition = this.hasRepeatingSequences;
      const sequence = this.hasSequentialNumbers &&
        this.hasSequentialLetters;
      return {isValid, passwordLength, sequenceRepetition, sequence};

    },
  },
  watch: {
    password: "verifyPassword",
  },
};
</script>

<style
  lang="scss"
  scoped
>
p {
  margin: 0;
}

.tx-text-blue {
  color: $color-blue-300;
}
</style>
