export default {
  loading: false,
  errors: {},
  totalPerTime: [],
  callsStatistics: [],
  deliveredCalls: [],
  qualificationStatistics: [],
  agentMetrics: [],
  statistics: null,
}
