export default {
  loadingFlags: {
    fetchFunnelIntegrations: false,
    fetchMetaWebhookData: false,
    postMetaIntegration: false,
    fetchWebhookData: false,
    createRDStationIntegration: false,
    updateRDStationIntegration: false
  },

  rdStationErrors: [],
  integrations: [],
  metaWebhookData: {
    webhook: "",
    token: ""
  },
  rdStationWebhookData: {
    webhook: ""
  }
}
