<template>
  <div
    class="modal"
    ref="dataUpdateModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="d-flex justify-content-start">
          <div
            class="rounded-start ps-0 ms-0"
            style="min-width: 10px !important; min-height: 100% !important; background-color: #2C7BE5 !important;"
          />
          <div class="col px-3 py-4">
            <div class="text-start ms-2 mb-3">
              <img
                src="@assets/img/aloisio.svg"
                class="img-fluid"
                style="width: 57px !important"
                alt=""
              >
              <span class="ms-2 fs-3">{{ $t("we_do_not_have_your_data") }}</span>
            </div>
            <div>
              <div class="mx-4">
                <div class="d-flex justify-content-between input-group">
                  <label class="form-label required">{{ $t("confirm_name") }}</label>
                </div>
                <input
                  v-model="fields.name"
                  required
                  type="text"
                  :class="{ 'form-control': true, 'is-invalid': errors.name, required: true }"
                >
                <div class="invalid-feedback">
                  {{ errors.name && errors.name[0] }}
                </div>
              </div>
              <div class="col-12 d-flex justify-content-between">
                <div class="col mx-4 my-3">
                  <div class="d-flex justify-content-between">
                    <label class="form-label required">Whatsapp</label>
                  </div>
                  <input
                    v-maska
                    data-maska="[
                      '(##) ####-####',
                      '(##) #####-####'
                      ]"
                    v-model="fields.whatsapp_number"
                    @change="handleNumberValidation()"
                    :class="{ 'form-control': true, 'is-invalid': errors.whatsapp_number, required: true }"
                  >
                  <div class="invalid-feedback">
                    {{ (errors.whatsapp_number && errors.whatsapp_number[0]) || this.whatsappErrorMessage }}
                  </div>
                </div>
                <div class="col me-4 my-3">
                  <div class="d-flex justify-content-between">
                    <label class="form-label required">{{ $t("what_is_your_email") }}</label>
                  </div>
                  <input
                    v-model="fields.email"
                    required
                    type="text"
                    :class="{ 'form-control': true, 'is-invalid': errors.email, required: true }"
                  >
                  <div class="invalid-feedback">
                    {{ errors.email && errors.email[0] }}
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex justify-content-between">
                <div class="col mx-4 my-3">
                  <div class="d-flex justify-content-between">
                    <label class="form-label required">{{ $t("what_is_your_work") }}</label>
                  </div>
                  <VueMultiselect
                    v-if="this.lineOfWork"
                    v-model="fields.lines_of_works_ids"
                    label="name"
                    track-by="name"
                    :class="{ 'form-control': true, 'is-invalid': errors.lines_of_works_ids, required: true }"
                    :placeholder="$t('select_sector')"
                    :select-label="$t('click_or_enter_to_select')"
                    :deselect-label="$t('click_or_enter_to_remove')"
                    :selected-label="$t('selected')"
                    open-direction="bottom"
                    :multiple="true"
                    :close-on-select="false"
                    :preserve-search="true"
                    :options="this.lineOfWork"
                  />
                  <div class="invalid-feedback">
                    {{ errors.lines_of_works_ids && errors.lines_of_works_ids[0] }}
                  </div>
                </div>
                <div class="col me-4 my-3">
                  <div class="d-flex justify-content-between">
                    <label class="form-label required">{{ $t("what_is_your_role") }}</label>
                  </div>
                  <VueMultiselect
                    v-if="this.companyRole"
                    v-model="fields.roles_ids"
                    label="name"
                    track-by="name"
                    :class="{ 'form-control': true, 'is-invalid': errors.roles_ids, required: true }"
                    :placeholder="$t('select_role')"
                    :select-label="$t('click_or_enter_to_select')"
                    :deselect-label="$t('click_or_enter_to_remove')"
                    :selected-label="$t('selected')"
                    open-direction="bottom"
                    :multiple="true"
                    :close-on-select="false"
                    :preserve-search="true"
                    :options="this.companyRole"
                  />
                  <div class="invalid-feedback">
                    {{ errors.roles_ids && errors.roles_ids[0] }}
                  </div>
                </div>
              </div>
              <div
                v-if="this.fields.lines_of_works_ids && checkIfHasLineOfWorkIdSelected == true"
                class="mx-4 mb-3"
              >
                <div class="d-flex justify-content-between">
                  <label class="form-label">{{ $t("who_is_promoter") }}</label>
                </div>
                <VueMultiselect
                  v-if="this.promoter"
                  v-model="fields.promoters_ids"
                  label="name"
                  track-by="name"
                  :class="{ 'form-control': true, 'is-invalid': errors.promoters_ids, required: true }"
                  :placeholder="$t('select_promoter')"
                  :select-label="$t('click_or_enter_to_select')"
                  :deselect-label="$t('click_or_enter_to_remove')"
                  :selected-label="$t('selected')"
                  open-direction="bottom"
                  :multiple="true"
                  :close-on-select="false"
                  :preserve-search="true"
                  :options="this.promoter"
                />
                <div class="invalid-feedback">
                  {{ errors.promoters_ids && errors.promoters_ids[0] }}
                </div>
              </div>
              <div class="mx-4 mb-4">
                <span
                  v-if="this.warningTermsOfUse"
                  :style="this.warningTermsOfUse ? 'color: #E60B42' : ''"
                >*{{ $t("select_to_continue") }}</span>
                <div>
                  <input
                    v-model="this.termsOfUse"
                    type="checkbox"
                    @click="this.warningTermsOfUse = false"
                    class="form-check-input"
                    :style="this.warningTermsOfUse ? 'background-color: #F06D8E' : ''"
                  >
                  <span class="ms-2">{{ $t("read_and_accept") }}
                    <u
                      type="button"
                      @click="redirectToTermsOfUse()"
                    > {{ $t("license_2") }} </u>
                  </span>
                </div>
              </div>
              <div class="d-flex flex-column justify-content-center">
                <app-button
                  class="btn btn-primary col mx-4 mb-3"
                  :loading="loading"
                  @click="submit()"
                >
                  {{ $t("send_capitalize") }}
                </app-button>
                <user-logout />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Modal} from "bootstrap";
import appButton from "@components/app-button";
import VueMultiselect from "vue-multiselect";
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
import { Mask } from "maska";
import { numberValidation } from "@/utils.js";
import UserLogout from "./user-logout.vue";

export default {
  data() {
    return {
      dataUpdateModal: null,
      termsOfUse: false,
      warningTermsOfUse: false,
      whatsappErrorMessage: "",
      fields: {
        email: null,
        lines_of_works_ids: [],
        name: null,
        roles_ids: [],
        user_id: null,
        whatsapp_number: null,
        promoters_ids: []
      }
    };
  },
  /**
   *
   */
  mounted() {
    this.fields.user_id = this.user.id;
    this.findLineOfWork().then(() => {
      if (this.user.user_updated_data) {
        for (let item of this.lineOfWork) {
          if (this.user.user_updated_data.lines_of_works_ids.sort().includes(item.id))
            this.fields.lines_of_works_ids.push(item);
        }
      }
    });
    this.findPromoter().then(() => {
      if (this.user.user_updated_data) {
        for (let item of this.promoter) {
          if (this.user.user_updated_data.promoters_ids.sort().includes(item.id)) this.fields.promoters_ids.push(item);
        }
      }
    });
    this.findCompanyRole().then(() => {
      if (this.user.user_updated_data) {
        for (let item of this.companyRole) {
          if (this.user.user_updated_data.roles_ids.sort().includes(item.id)) this.fields.roles_ids.push(item);
        }
      }
    });

    if (this.user.user_updated_data) {
      this.fields.email = this.user.user_updated_data.email;
      this.fields.name = this.user.user_updated_data.name;
      this.fields.whatsapp_number = this.user.user_updated_data.whatsapp_number;
    }
    if (this.user.user_updated_data?.user_data_verify || this.user.user_updated_data === null) {
      this.dataUpdateModal = new Modal(this.$refs.dataUpdateModal, {
        backdrop: "static",
        keyboard: false
      });
      this.dataUpdateModal.show();
    }
  },

  /**
   *
   */
  components: {
    appButton,
    VueMultiselect,
    UserLogout
  },

  /**
   *
   */
  computed: {
    ...mapGetters("manager/user", {
      lineOfWork: "getLineOfWork",
      promoter: "getPromoter",
      companyRole: "getCompanyRole",
      errors: "getErrors",
      loading: "isLoading",
      showDataUpdateModal: "getDataUpdateModal"
    }),
    ...mapGetters("auth", {
      user: "getUser",
      nextDataUpdate: "getNextDataUpdate",
      isLogged: "getIsRecentlyLogged"
    }),

    /**
     * check if "Consignado" has selected
     */
    checkIfHasLineOfWorkIdSelected() {
      return this.fields.lines_of_works_ids.some(el => el.id == 4);
    }
  },

  watch: {
    checkIfHasLineOfWorkIdSelected(data) {
      data == false ? (this.fields.promoters_ids = []) : "";
    },
    showDataUpdateModal(showDataUpdateModal) {
      if (showDataUpdateModal) {
        if (this.user.user_updated_data.user_data_verify) this.dataUpdateModal.show();
      }
    }
  },

  /**
   *
   */
  unmounted() {
    if (this.dataUpdateModal) this.dataUpdateModal.dispose();
  },

  /**
   *
   */
  methods: {
    /**
     *
     */
    ...mapMutations("auth", ["setNextDataUpdate"]),
    ...mapMutations("manager/user", ["clearErrors", "hideDataUpdateModal"]),
    numberValidation,

    /**
     *
     */

    ...mapActions("manager/user", [
      "findUserData",
      "updateUserData",
      "findLineOfWork",
      "findPromoter",
      "findCompanyRole"
    ]),
    ...mapActions("auth", ["me"]),

    /**
     *
     */
    handleNumberValidation() {
      this.whatsappErrorMessage = numberValidation(this.fields.whatsapp_number);
    },

    /**
     * format whatsapp number for user
     */
    phoneNumber() {
      if (this.fields.whatsapp_number) {
        const mask = new Mask({ mask: ["(##) ####-####", "(##) #####-####"] });

        return mask.masked(JSON.stringify(this.fields.number));
      }
    },

    /**
     * filter objects and return id and format whatsapp number for POST/PUT call
     */
    handleFormFields() {
      let fields = {
        email: this.fields.email,
        name: this.fields.name,
        user_id: this.fields.user_id,
        whatsapp_number: null,
        lines_of_works_ids: [],
        roles_ids: [],
        promoters_ids: []
      };

      //regex to remove all non digits
      if (this.fields.whatsapp_number) fields.whatsapp_number = this.fields.whatsapp_number.replace(/[^\d]+/g, "");

      fields.lines_of_works_ids = this.fields.lines_of_works_ids.map(el => el.id);
      fields.roles_ids = this.fields.roles_ids.map(el => el.id);
      if (this.fields.promoters_ids.length) {
        fields.promoters_ids = this.fields.promoters_ids.map(el => el.id);
      }
      return fields;
    },

    /**
     * create or update user data
     */
    submit() {
      if (this.termsOfUse) {
        let { user_id } = this.handleFormFields();
        this.updateUserData({
          id: user_id,
          fields: this.handleFormFields()
        }).then(() => {
          this.me();
          let newDate = moment()
            .add(6, "months")
            .unix();
          this.setNextDataUpdate(newDate);
          this.dataUpdateModal.hide();
          this.hideDataUpdateModal();
        });
      } else this.warningTermsOfUse = true;
    },
    /**
     *
     */
    redirectToTermsOfUse() {
      this.dataUpdateModal.hide();
      this.hideDataUpdateModal();
      this.$router.push({ path: "../terms-of-use", replace: true });
    }
  }
};
</script>
<style lang="scss" scoped>
.multiselect {
  margin: 0px !important;
  padding: 0px !important;
}

.multiselect__single {
  margin-bottom: 13px !important;
}
</style>
