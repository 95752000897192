<template>
  <div
    ref="datepicker"
    class="input-group input-group-merge"
    :class="{
      'input-clean-state': cleanState,
    }"
  >
    <flat-pickr
      :disabled="isDisabled"
      v-model="date"
      class="form-control"
      :class="{
        'active-input': isEditing && cleanState,
        'border-0 data-input': cleanState,
        [customClass]: customClass
      }"
      :config="config"
      data-input
      :placeholder="getPlaceholder"
      type="text"
      @on-close="onClose"
    />
    <div
      class="input-group-text cursor-pointer"
      v-if="!cleanState"
      data-toggle
    >
      <span class="fe fe-calendar" />
    </div>
  </div>
</template>
<script>
import FlatPickr from "vue-flatpickr-component";
import {Portuguese} from "flatpickr/dist/l10n/pt.js";
import moment from "moment";

export default {
  emits: ["update", "close"],
  components: {
    FlatPickr,
  },
  props: {
    isDisabled: {type: Boolean, default: false},
    formatDate: {type: String, default: "d/m/Y"},
    preset: {type: String, default: "day"},
    timeEnabled: {type: Boolean, default: false},
    secondsEnabled: {type: Boolean, default: false},
    cleanDate: {type: Boolean, default: false},
    searchOnClose: {type: Boolean, default: false},
    isEditing: {type: Boolean, default: false},
    cleanState: {type: Boolean, default: true},
    customClass: {type: String, default: null},
  },
  data () {
    return {
      flatpickr: null,
      date: null,
      config: {
        locale: Portuguese,
        inline: false,
        dateFormat: this.formatDate,
        wrap: true,
        maxDate: null,
        minDate: null,
        enableTime: this.timeEnabled,
        enableSeconds: this.secondsEnabled,
        allowInput: true,
        defaultHour: 0,
        defaultDate: this.preset ? moment().startOf(this.preset).format("DD/MM/YYYY HH:mm:ss") : null
      },
    };
  },
  mounted () {
    this.date = this.preset;
  },
  computed: {
    getPlaceholder () {
      if (this.formatDate === 'd/m/Y' && !this.cleanState) {
        return 'DD/MM/YYYY'
      }

      return ''
    }
  },
  watch: {
    date (data) {
      if (data)
        this.$emit("update", data);
    },

    cleanDate (cleanDate) {
      if (cleanDate) this.date = null
    }
  },
  methods: {
    onClose () {
      this.$emit("close");
    },

    clearDate() {
      this.date = null;
    }
  },
};
</script>
<style lang="scss" scoped>
.input-clean-state:hover {
  background-color: #F0F4FA;
  border-radius: $border-radius;

  .form-control {
    background-color: #F0F4FA;
  }

  .input-group-text {
    background-color: transparent;
  }
}

.active-input {
  background-color: #F0F4FA;
}

.custom-input {
  &.is-invalid {
    outline: 1px solid $color-red-300;
  }
}
</style>
