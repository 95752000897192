<template>
  <div class="main-content pb-6">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-12 col-xl-12">
          <header-component
            :title="$t('IVR_after_call_capitalize')"
            :pretitle="$t('settings_uppercase')"
            redirect-link="/manager/ivr-after-call"
          />
        </div>

        <flash-message />

        <div class="row">
          <div class="col-lg-12">
            <div class="form-group 0">
              <label class="form-label required">{{ $t('name_data') }}</label>
              <input
                type="text"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.createIvrAfterCall.name,
                }"
                v-model="fields.name"
                :placeholder="$t('name_data')"
              >
              <div class="invalid-feedback">
                {{
                  errors.createIvrAfterCall.name &&
                    errors.createIvrAfterCall.name[0]
                }}
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-label required">{{ $t('capitalizes.starting_audio') }}</label>
              <span class="form-text">{{ $t('send_mp3_file') }}</span>
              <div
                :class="{
                  'card mb-0': true,
                  'is-invalid': errors.createIvrAfterCall.audio_start,
                }"
              >
                <div class="card-body">
                  <file-upload
                    @select-file="selectFileStart"
                    @removed-file="selectFileStart"
                    filter="audio/mp3"
                    icon="fe fe-headphones"
                  />
                </div>
              </div>
              <div class="invalid-feedback">
                {{
                  errors.createIvrAfterCall.audio_start &&
                    errors.createIvrAfterCall.audio_start[0]
                }}
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-label required">{{ $t('capitalizes.audio_for_no_key_presses') }}</label>
              <span class="form-text">{{ $t('send_mp3_file') }}</span>
              <div
                :class="{
                  'card mb-0': true,
                  'is-invalid': errors.createIvrAfterCall.audio_null_key,
                }"
              >
                <div class="card-body">
                  <file-upload
                    @select-file="selectFileNullKey"
                    @removed-file="selectFileNullKey"
                    filter="audio/mp3"
                    icon="fe fe-headphones"
                  />
                </div>
              </div>
              <div class="invalid-feedback">
                {{
                  errors.createIvrAfterCall.audio_null_key &&
                    errors.createIvrAfterCall.audio_null_key[0]
                }}
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-label required">{{ $t('capitalizes.audio_for_invalid_key') }}</label>
              <span class="form-text">{{ $t('send_mp3_file') }}</span>
              <div
                :class="{
                  'card mb-0': true,
                  'is-invalid': errors.createIvrAfterCall.audio_invalid_key,
                }"
              >
                <div class="card-body">
                  <file-upload
                    @select-file="selectFileInvalidKey"
                    @removed-file="selectFileInvalidKey"
                    filter="audio/mp3"
                    icon="fe fe-headphones"
                  />
                </div>
              </div>
              <div class="invalid-feedback">
                {{
                  errors.createIvrAfterCall.audio_invalid_key &&
                    errors.createIvrAfterCall.audio_invalid_key[0]
                }}
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-label required">{{ $t('capitalizes.final_audio') }}</label>
              <span class="form-text">{{ $t('send_mp3_file') }}</span>
              <div
                :class="{
                  'card mb-0': true,
                  'is-invalid': errors.createIvrAfterCall.audio_end,
                }"
              >
                <div class="card-body">
                  <file-upload
                    @select-file="selectFileEnd"
                    @removed-file="selectFileEnd"
                    filter="audio/mp3"
                    icon="fe fe-headphones"
                  />
                </div>
              </div>
              <div class="invalid-feedback">
                {{
                  errors.createIvrAfterCall.audio_end &&
                    errors.createIvrAfterCall.audio_end[0]
                }}
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <label class="form-label required">{{ $t('waiting_time') }}</label>
            <span class="text-muted ">{{ ' '+ $t('in_seconds') }}</span>
            <div class="form-group 0">
              <input-text-range
                v-model="fields.max_wait_time"
                :max="10"
                :min="5"
                :class="{
                  'is-invalid': errors.createIvrAfterCall.max_wait_time,
                }"
                :color="'#2C7BE5'"
              />
              <div class="invalid-feedback">
                {{
                  errors.createIvrAfterCall.max_wait_time &&
                    errors.createIvrAfterCall.max_wait_time[0]
                }}
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <label class="form-label required">{{ $t('maximum_number_of_attempts') }}</label>
            <div class="form-group 0">
              <input-text-range
                v-model="fields.attempt_number"
                :max="5"
                :min="1"
                :class="{
                  'is-invalid': errors.createIvrAfterCall.attempt_number,
                }"
                :color="'#2C7BE5'"
              />
              <div class="invalid-feedback">
                {{
                  errors.createIvrAfterCall.attempt_number &&
                    errors.createIvrAfterCall.attempt_number[0]
                }}
              </div>
            </div>
          </div>

          <div class="col-lg-12">
            <app-button
              color="btn-primary"
              :disabled="isButtonDisabled"
              :loading="loadingFlags.createIvrAfterCall"
              class="w-100"
              @click="submit()"
            >
              {{ $t('capitalizes.save') }}
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fileUpload from "@components/file-uploader";
import flashMessage from "@/components/flash-message";
import InputTextRange from "@components/input-text-range";
import AppButton from "@components/app-button";
import HeaderComponent from "@/components/header-component.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      fields: {
        name: "",
        max_wait_time: 5,
        attempt_number: 1,
        audio_start: null,
        audio_null_key: null,
        audio_invalid_key: null,
        audio_end: null
      }
    }
  },

  mounted() {
    this.clearErrors();
  },

  components: {
    fileUpload,
    flashMessage,
    InputTextRange,
    AppButton,
    HeaderComponent
  },

  computed: {
    ...mapGetters("manager/ivr-after-call", {
      loadingFlags: "getLoadingFlags",
      errors: "getErrors",
    }),

    isButtonDisabled() {
      for (const key in this.fields)
        if(!this.fields[key])
          return true; // Disable the button

      return false; // Enable the button
    }
  },

  methods: {
    ...mapActions("manager/ivr-after-call", [
        "createIvrAfterCall",
        "clearErrors"
    ]),

    selectFileStart(file) {
      this.fields.audio_start = file
    },
    selectFileNullKey(file) {
      this.fields.audio_null_key = file
    },
    selectFileInvalidKey(file) {
      this.fields.audio_invalid_key = file
    },
    selectFileEnd(file) {
      this.fields.audio_end = file
    },

    submit() {
      let formData = new FormData();
      formData.append('name', this.fields.name);
      formData.append('max_wait_time', this.fields.max_wait_time);
      formData.append('attempt_number', this.fields.attempt_number);
      formData.append('audio_start', this.fields.audio_start);
      formData.append('audio_null_key', this.fields.audio_null_key);
      formData.append('audio_invalid_key', this.fields.audio_invalid_key);
      formData.append('audio_end', this.fields.audio_end);

      this.createIvrAfterCall({ formData })
      .then(() => this.$router.push(`/manager/ivr-after-call`))
    }
  },
}
</script>
