<template>
  <div>
    <div
      class="input-group input-group-merge"
      ref="datepicker"
    >
      <flat-pickr
        class="form-control"
        :placeholder="$t('break_capitalize')"
        type="text"
        aria-label="Input group appended"
        aria-describedby="inputGroup"
        data-input
        :config="config"
        v-model="date"
        @on-change="onChange"
        @on-close="onClose"
      />
      <div
        class="input-group-text cursor-pointer"
        data-toggle
      >
        <span class="fe fe-calendar" />
      </div>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import localeFlatPicker from "@/mixins/localeFlatPicker";
import { mapGetters } from "vuex";

export default {
  emits: ["update:start", "update:end", "close"],
  data() {
    return {
      dateTimeFormats: {
        "pt": "DD/MM/YYYY HH:mm:ss",
        "es": "DD/MM/YYYY HH:mm:ss",
        "en": "YYYY-MM-DD HH:mm:ss",
      },
      dateFormats: {
        "pt": "DD/MM/YYYY",
        "es": "DD/MM/YYYY",
        "en": "YYYY-MM-DD",
      },
      flatpickr: null,
      date: null,
      config: {
        disable: [this.isDayDisabled],
        inline: false,
        mode: "range",
        wrap: true,
        maxDate: null,
        minDate: null,
        enableTime: this.timeEnabled,
        enableSeconds: this.secondsEnabled,
        allowInput: true,
        defaultHour: 0,
        static: true,
        defaultDate: this.defaultDateEnabled && [
          moment().startOf(this.preset).format("DD/MM/YYYY HH:mm:ss"),
          moment().endOf("day").format("DD/MM/YYYY HH:mm:ss"),
        ],
      },
    };
  },
  components: {
    flatPickr,
  },
  mixins: [localeFlatPicker],
  mounted() {
    if (!this.defaultDateEnabled) return

    if (this.timeEnabled && this.secondsEnabled) {
      this.$emit("update:start", moment().startOf(this.preset).format("YYYY-MM-DD HH:mm:ss"));
      this.$emit("update:end", moment().endOf("day").format("YYYY-MM-DD 23:59:59"));
    } else {
      this.$emit("update:start", moment().startOf(this.preset).format("YYYY-MM-DD"));
      this.$emit("update:end", moment().endOf("day").format("YYYY-MM-DD"));
    }
  },
  computed: {
    ...mapGetters("auth", {
      language: "getLanguage",
    }),
  },
  methods: {
    isDayDisabled(date) {
      if (!this.minDate) {
        return false;
      }

      return (date < new Date(this.minDate));
    },
    onChange(selectedDates, dateStr, instance) {
      if (selectedDates.length < 2) {
        if (this.maxInterval > 0) {
          this.$emit("update:start", "");
          this.$emit("update:end", "");

          let min = moment(selectedDates[0]);
          let max = moment(selectedDates[0]);
          min.add(-this.maxInterval, "days");
          max.add(this.maxInterval, "days");
          this.config.minDate = min.toDate();
          this.config.maxDate = max.toDate();
        }
      } else {
        if (this.timeEnabled && this.secondsEnabled && this.date && !this.date.includes(this.language === "en" ? " to " : this.language === "es" ? "a" : " até ")) {
          instance.setDate([
            moment(selectedDates[0]).format(this.dateTimeFormats[this.language]),
            moment(selectedDates[1]).format(`${this.dateFormats[this.language]} 23:59:59`),
          ]);
        }
      }
    },
    onClose(selectedDates) {
      this.config.minDate = null;
      this.config.maxDate = null;
      if (selectedDates.length > 1 && this.searchOnClose) {
        this.$emit("update:start", moment(selectedDates[0]).format("YYYY-MM-DD"));
        this.$emit("update:end", moment(selectedDates[1]).format("YYYY-MM-DD"));
      }
      this.$emit("close");
    },

    handleDateValues(start_date, end_date) {
      let formatSeconds = "";
      if (this.timeEnabled && this.secondsEnabled)
        formatSeconds = " HH:mm:ss";

      this.$emit("update:start", moment(start_date.trim(), this.dateFormats[this.language] + " HH:mm:ss")
          .format(`YYYY-MM-DD${formatSeconds}`));
      this.$emit("update:end", moment(end_date.trim(), this.dateFormats[this.language] + " HH:mm:ss")
          .format(`YYYY-MM-DD${formatSeconds}`));
    },
  },

  watch: {
    date(data) {
      if (data) {
        const dates = data.split(this.language === "en" ? " to " : this.language === "es" ? " a " : " até ");

        if (dates.length < 2)
          this.handleDateValues(data, data);
        else
          this.handleDateValues(dates[0], dates[1]);
      }
    },

    cleanDate(cleanDate) {
      if (cleanDate) this.date = null
    },
    language: {
      handler(val) {
        if (this.defaultDateEnabled) {
          this.config.defaultDate = [
            moment().startOf(this.preset).format(this.dateTimeFormats[val]),
            moment().endOf("day").format(this.dateTimeFormats[val]),
          ];
        }

        if (this.formatDate === "d/m/Y") {
          this.config.dateFormat = {
            "pt": "d/m/Y",
            "es": "d/m/Y",
            "en": "Y-m-d",
          }[this.language];
        } else {
          this.config.dateFormat = {
            "pt": "d/m/Y H:i:S",
            "es": "d/m/Y H:i:S",
            "en": "Y-m-d H:i:S",
          }[this.language];
        }
      },
      immediate: true,
    },
  },
  props: {
    maxInterval: {
      type: Number,
      default: 0,
    },
    timeEnabled: {
      type: Boolean,
      default: false,
    },
    secondsEnabled: {
      type: Boolean,
      default: false,
    },
    formatDate: {
      type: String,
      default: "d/m/Y",
    },
    preset: {
      type: String,
      default: "day",
    },
    defaultDateEnabled: {
      type: Boolean,
      default: true,
    },
    cleanDate: {
      type: Boolean,
      default: false,
    },
    searchOnClose: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: String,
      default: null,
    },
  },
};
</script>
