<template>
  <modal-component
    :center="true"
    :show="showModal"
    size="modal-418"
    @close="showModal = false"
  >
    <template
      #content
    >
      <div class="border rounded-1">
        <div
          class="modal-header px-4"
          style="padding-top: 16px;"
        >
          <div class="w-100">
            <h4 class="m-0 modal-title">
              {{ title }}
            </h4>
          </div>
          <button
            aria-label="Close"
            class="btn-close mb-1"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div
          class="modal-body p-0"
        >
          <table class="table m-0">
            <thead>
              <tr>
                <th
                  class="ps-4 small"
                  scope="col"
                >
                  {{ $t('capitalizes.criterion') }}
                </th>
                <th
                  class="pe-4 small text-end"
                  scope="col"
                >
                  {{ $t('note') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(review, index) in agentStats?.criterion_json"
                :key="index"
              >
                <th class="h6 fw-normal ps-4">
                  {{ review.name }}
                </th>
                <td class="h6 fw-normal pe-4 text-end">
                  {{ totalGrades(review.total_grade, review.total_count) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from "@/components/modal-component.vue";
import {mapGetters} from "vuex";

export default {

  components: {
    ModalComponent,
  },

  props: {
    title: {
      type: String,
      default: ""
    },
  },

  data() {
    return {
      showModal: false,
    };
  },

  computed: {
    ...mapGetters("agent/agent", {
      agentStats: "getAgentStats",
    }),
  },

  methods: {
    openModal() {
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
    },

    totalGrades(total_grade, total_count) {
      return total_count > 0 ? (total_grade / total_count).toFixed(2) : 0;
    },
  }
};
</script>

<style
  lang="scss"
  scoped
>

.modal-header .btn-close {
  margin-right: -1.1rem;
  margin-top: -1rem;
}
</style>
