export default {
  startLoadingFlag: (state, flag) => state.loadingFlags[flag] = true,
  stopLoadingFlag: (state, flag) => state.loadingFlags[flag] = false,
  setList: (state, schedules) => state.schedules = schedules,
  clearSchedules: state => {
    state.schedules = [];
    state.pagination.current_page = 1;
  } ,
  addSchedules: (state, schedules) => {
    schedules.forEach((schedule) => {
      state.schedules.push(schedule);
    })
  },
  setErrorMessage: (state, errorMesage) => state.errorMessage = errorMesage,
  setErrors: (state, errors) => state.errors = errors,
  setLoadingContext: (state, loadingContext) => state.loadingContext = loadingContext,
  clearErrors: (state) => {
    state.errors = {}
    state.errorMessage = ""
  },
  setPagination: (state, pagination) => state.pagination = pagination,
  setCurrentPage: (state, page) => state.pagination.current_page = page,
  setScheduleToUpdateModalData: (state, data) => state.scheduleToUpdateModal.data = data,
  setFilters: (state, filters) => state.filters = filters
}
