<template>
  <div
    v-if="isVisible"
    :class="['card mb-3 border-0', cardClass]"
  >
    <div class="card-body">
      <div class="d-flex align-items-center invoice-wrapper">
        <img
          :src="currentImage"
          alt="Ilustração de cards"
          class="img-fluid"
        >
        <small class="flex-fill">
          <div v-if="daysRemaining >= 1">
            {{ $t("due_date_in") }}
            <strong> {{ $tc("day_pluralization", this.daysRemaining, { days: this.daysRemaining }) }} </strong>
          </div>
          <div
            v-else
            class="fw-bold"
          >
            {{ $t("blocked_access") }}
          </div>

          <a
            v-if="nextBilling?.url"
            :href="nextBilling?.url"
            target="_blank"
          > {{ $t("make_payment") }} </a>
        </small>
        <i
          v-if="daysRemaining >= 1"
          class="fa-regular fa-xmark cursor-pointer"
          @click="closeNotification"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "InvoiceNotification",
  data() {
    return {
      images: {
        cardsPrimary: require("@assets/img/illustrations/cards-primary.svg"),
        cardsYellow: require("@assets/img/illustrations/cards-yellow.svg"),
        pluzinhaCardsRed: require("@assets/img/illustrations/pluzinha-cards-red.svg")
      }
    };
  },
  computed: {
    ...mapGetters("auth", {
      user: "getUser",
      inImpersonate: "inImpersonate",
      nextBilling: "getNextBilling"
    }),
    ...mapGetters("system", {
      invoiceNotificationCloseTime: "getInvoiceNotificationCloseTime"
    }),

    daysRemaining() {
      const company = this.user.company;
      if (!company || !company.blocked_at) return 0;

      const today = moment();
      const date = moment(company.blocked_at, "X");
      return date < today ? 0 : date.diff(today, "days") + 1;
    },

    cardClass() {
      if (this.daysRemaining > 1 && this.daysRemaining <= 5) return "bg-blue-soft";
      if (this.daysRemaining == 1) return "bg-yellow-soft";
      return "bg-red-soft";
    },

    currentImage() {
      if (this.daysRemaining > 1 && this.daysRemaining <= 5) return this.images.cardsPrimary;
      if (this.daysRemaining == 1) return this.images.cardsYellow;
      return this.images.pluzinhaCardsRed;
    },

    isVisible() {
      return this.showNotification() && this.timeElapsed();
    }
  },
  methods: {
    ...mapActions("system", ["setInvoiceNotificationCloseTime"]),

    closeNotification() {
      this.setInvoiceNotificationCloseTime(moment());
    },

    showNotification() {
      const { company, role } = this.user;
      return !this.blockedOrSupervisor(company, role) && !this.hasNoInvoiceTag(company);
    },
    blockedOrSupervisor(company, role) {
      return !company.blocked_at || role.name === "supervisor" || this.inImpersonate;
    },
    hasNoInvoiceTag(company) {
      return company.tags.some(tag => tag.name === "sem-aviso-de-fatura" || tag.name === "Enterprise");
    },
    timeElapsed() {
      return (
        this.daysRemaining <= 5 &&
        (!this.invoiceNotificationCloseTime || moment().diff(this.invoiceNotificationCloseTime, "hours") >= 12)
      );
    }
  }
};
</script>

<style scoped lang="scss">
.card {
  width: 232px;
  margin-left: -15px;
  &.bg-blue-soft {
    color: var(--color-blue-500);
    background-color: var(--color-gray-blue-200) !important;
    border: 1px solid var(--color-gray-blue-300) !important;

    a {
      color: var(--color-blue-500);
      text-decoration: underline;
    }
  }

  &.bg-yellow-soft {
    color: var(--color-yellow-600);
    background-color: $color-yellow-soft !important;
    border: 1px solid $color-yellow-soft !important;

    a {
      color: var(--color-yellow-600);
      text-decoration: underline;
    }
  }

  &.bg-red-soft {
    color: $color-light-text-tags-red;
    background-color: $color-red-soft !important;

    a {
      color: $color-light-text-tags-red;
      text-decoration: underline;
    }
  }

  .card-body {
    padding: 0.75rem 0.5rem 0.75rem 1rem;

    .invoice-wrapper {
      gap: 0.5rem;
    }
  }
}
</style>
