export default {
  loading: {
    downloadTable: false,
    addNumberToBlocklist: false,
    removeNumberOfBlocklist: false,
    removeCsvOfBlocklist: false,
    addCsvInBlocklist: false,
    getIsNumberOnBlocklist: false,
  },
  alert: null,
  errors: {},
  list: [],
  filters: {},
  register: null,
  pagination: {},
  deletedNumber: [],
}
