import { createI18n } from "vue-i18n";

let userLanguage;
if (process.env.NODE_ENV === "test") {
  userLanguage = "pt";
} else {
  const store = require("./store").default;
  userLanguage = store.getters["auth/getUser"]?.settings?.language?.substring(0, 2) || "pt";
}

const loadLocaleMessages = require(`../locales/${userLanguage}.json`);

document.documentElement.setAttribute("lang", userLanguage);

export const i18n = createI18n({
  locale: userLanguage,
  fallbackLocale: userLanguage,
  messages: {
    [userLanguage]: loadLocaleMessages
  }
});

const messages = {
  pt: () => import("../locales/pt.json"),
  en: () => import("../locales/en.json"),
  es: () => import("../locales/es.json")
};

export function loadLanguage(lang) {
  return messages[lang]().then(i18nMessages => {
    i18n.global.setLocaleMessage(lang, i18nMessages);
    i18n.global.locale = lang;
    document.documentElement.setAttribute("lang", lang);
  });
}
