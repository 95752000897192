<template>
  <button
    type="button"
    class="btn btn-link text-dark p-0"
    ref="endedByAgent"
  >
    <span class="fe fe-phone-missed" />
  </button>
</template>

<script>
import { Tooltip } from "bootstrap";


export default {
  data() {
    return {
      tooltip: null,
    }
  },
  mounted() {
    this.generateEndedByAgentTootlip();
  },
  methods: {
    /**
     *
     */
    generateEndedByAgentTootlip() {
      let tooltip = document.createElement("div");
      let tooltipItem = document.createElement("div");
      tooltipItem.innerHTML =
        `<div class='text-primary'>${this.$t('call_ended_by_agent')}</div>`;
      tooltip.appendChild(tooltipItem);

      this.tooltip = new Tooltip(this.$refs.endedByAgent, {
        html: true,
        container: "body",
        title: tooltip,
      });
    },
  }

}
</script>

<style>

</style>
