export default {
  startLoading: state => (state.loading = true),

  stopLoading: state => {
    state.loading = false;
    state.loadingContext = "";
  },
  startLoadingFlag: (state, flag) => (state.loadingFlags[flag] = true),
  stopLoadingFlag: (state, flag) => (state.loadingFlags[flag] = false),

  setLoadingContext: (state, loadingContext) => (state.loadingContext = loadingContext),

  setErrorMessage: (state, errorMesage) => {
    state.errorMessage = errorMesage;

    if (errorMesage) state.isMessage = true;
    else setTimeout(() => (state.isMessage = false), 1);
  },
  setSuccessMessage: (state, successMesage) => {
    state.successMesage = successMesage;

    if (successMesage) state.isMessage = true;
    else setTimeout(() => (state.isMessage = false), 1);
  },
  setInfoMessage: (state, infoMessage) => {
    state.infoMessage = infoMessage;

    if (infoMessage) state.isMessage = true;
    else setTimeout(() => (state.isMessage = false), 1);
  },
  setWarningMessage: (state, warningMessage) => {
    state.warningMessage = warningMessage;

    if (warningMessage) state.isMessage = true;
    else setTimeout(() => (state.isMessage = false), 1);
  },
  setTimeMessage: (state, timeMessage) => {
    state.timeMessage = timeMessage;

    if (timeMessage) state.isMessage = true;
    else setTimeout(() => (state.isMessage = false), 1);
  },
  setCustomizedTitleMessage: (state, customizedTitleMessage) => {
    state.customizedTitleMessage = customizedTitleMessage;
  },

  setCustomMessageType: (state, messageData) => {
    state.customMessageType = messageData;

    if (messageData) state.isMessage = true;
    else setTimeout(() => state.isMessage = false, 1);
  },

  setSpyParams: (state, spyParams) => state.spyParams = spyParams,

  setConfirmationMessage: (state, confirmationMessage) => (state.confirmationMessage = confirmationMessage),
  setConfirmationMessageResponse: (state, confirmationMessageResponse) =>
    (state.confirmationMessageResponse = confirmationMessageResponse),

  clearErrors: state => {
    state.errors = {};
    state.errorMessage = "";
  },

  setSocketIOStatus: (state, status) => (state.socketIOStatus = status),
  setJsSipStatus: (state, status) => (state.jsSipStatus = status),

  setRegistrationAttemp: (state, attemp) => (state.registrationAttemp = attemp),
  incRegistrationAttemp: state => state.registrationAttemp++,

  setUserMediaPermission: (state, userMediaPermission) => (state.userMediaPermission = userMediaPermission),
  setAcwTimeout: (state, acwTimeout) => (state.acwTimeout = acwTimeout),

  setTimeAsString: (state, timeAsString) => (state.timeAsString = timeAsString),
  setTimerTargetAchieved: (state, timerTargetAchieved) => (state.timerTargetAchieved = timerTargetAchieved),
  setTotalSeconds: (state, totalSeconds) => (state.totalSeconds = totalSeconds),

  resetTimer: state => {
    state.setTotalSeconds = 0;
    state.setTimeAsString = "00:00:00";
    state.timerTargetAchieved = false;
  },

  toggleDialer: state => (state.showDialer = !state.showDialer),
  dialerInput: (state, key) => (state.dialerText += key),
  clearDialerText: state => (state.dialerText = ""),
  backSpaceDialer: state => {
    state.dialerText = state.dialerText.slice(0, -1);
  },

  setMode: (state, mode) => (state.mode = mode),
  setStatus: (state, status) => (state.status = status),

  setConsulting: (state, consulting) => (state.consulting = consulting),
  setConsultingAgentName: (state, consultingAgentName) => (state.consultingAgentName = consultingAgentName),

  setConsultHold: (state, consultHold) => (state.consultHold = consultHold),

  setAgentInConsult: (state, agentInConsult) => (state.agentInConsult = agentInConsult),

  sendDTMF: (state, key) => (state.DTMFkeys += key),
  clearDTMFKeys: state => (state.DTMFkeys = ""),

  setMuted: (state, muted) => (state.muted = muted),

  setFlashMessage: (state, flashMessage) => {
    state.flashMessage = flashMessage;
  },

  setIntegrationURL: (state, integrationURL) => (state.integrationURL = integrationURL),

  setRemaningACWTime: (state, remaningACWTime) => (state.remaningACWTime = remaningACWTime),

  setSpiedAgent: (state, spiedAgent) => (state.spiedAgent = spiedAgent),
  setSelectedAgentToSpy: (state, selectedAgentToSpy) => (state.selectedAgentToSpy = selectedAgentToSpy),
  setShowSpy: state => (state.showSpy = !state.showSpy),
  setSpyError: (state, spyError) => {
    state.spyError = spyError;
    setTimeout(() => (state.spyError = ""), 1);
  },

  setManualTimeLimited: (state, manualTimeLimited) => (state.manualTimeLimited = manualTimeLimited),
  setManualTimeout: (state, manualTimeout) => (state.manualTimeout = manualTimeout),

  /**
   *
   *
   *
   */
  setJsSIPActive: (state, active) => (state.jsSIPActive = active),
  setSocketIOActive: (state, active) => (state.socketIOActive = active),
  setJsSipStream: (state, jsSipStream) => (state.jsSipStream = jsSipStream),

  // Request limit
  setRequestLimitExceeded: (state, requestLimitExceeded) => (state.requestLimitExceeded = requestLimitExceeded),
  toggleShowTransferCard: state => (state.showTransferCard = !state.showTransferCard),

  setCurrentAudioUrl: (state, audio) => (state.currentAudioUrl = audio),
  setMediaRecorder: (state, mediaRecorder) => (state.mediaRecorder = mediaRecorder),
  setUserIp: (state, userIp) => state.userIp = userIp,
  setIsOnline: (state, isOnline) => state.isOnline = isOnline,
  setUserRects: (state, {flag, userRects}) => state.userRects[flag] = userRects,
  setInvoiceNotificationCloseTime: (state, invoiceNotificationCloseTime) =>
    state.invoiceNotificationCloseTime = invoiceNotificationCloseTime
};
