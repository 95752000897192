import {createRouter, createWebHistory} from "vue-router";

import store from "@/store";

import BaseLogin from "@pages/auth/base-login";
import BaseExtensionIntegration from "@pages/integration/base-integration";
import ResetPassword from "@pages/auth/reset-password";
import Login from "@pages/auth/log-in";
import AutoLogin from "@pages/auth/auto-login";
import JssipIntegration from "@pages/integration/components/jssip-integration";

// import BaseLogin from "@scopes/auth/log-in";
import ForgotPassword from "@pages/auth/components/forgot-password";
import RegisterPartner from "@pages/auth/components/register-partner.vue";
import PartnerForgotPassword from "@pages/auth/components/partner-forgot-password.vue";
import BaseAgent from "@dialer/agent/pages/base-agent";
import NotFound404 from "@pages/error/not-found-404";
import PixPayment from "@pages/payment/pix-payment";
import TwoFaQrCode from "@pages/auth/components/two-fa-qr-code";
import TwoFaCode from "@pages/auth/components/two-fa-code";
import TwoFaConfirmation from "@pages/auth/components/two-fa-confirmation";

import BaseManager from "@dialer/manager/pages/base-manager";
import Feedback from "@routes/manager/feedback";
import Integration from "@routes/manager/integration";
import Qualification from "@routes/manager/qualifications";
import Teams from "@routes/manager/teams";
import Users from "@routes/manager/users";
import Intervals from "@routes/manager/intervals";
import Telephony from "@routes/manager/telephony";
import ReceptiveNumbers from "@routes/manager/receptive-numbers";
import OfficeHours from "@routes/manager/office-hours";
import ReceptiveIVRS from "@routes/manager/receptive_ivr";
import Schedules from "@routes/manager/schedules";
import ManualCalls from "@routes/manager/manual-calls";
import UtilizationReport from "@routes/manager/utilization-report";
import AgentsReport from "@routes/manager/agents-report";
import LoginHistory from "@routes/manager/login-history";
import QualificationsReport from "@routes/manager/qualifications-report";
import ProductsAndServices from "@routes/manager/products-and-services";
import ChatsReport from "@routes/manager/chats-report";
import ChatsAgentsReport from "@routes/manager/chats-agents-report";
import GroupChannelsReport from "@routes/manager/group-channels-report";
import ChatQualificationsReport from "@routes/manager/chat-qualifications-report";
import PerformanceReport from "@routes/manager/performance-report";
import ivrs from "@routes/manager/ivrs";
import Lists from "@routes/manager/lists.js";
import intervalsReport from "@routes/manager/intervals-report";
import FeedbackReport from "@routes/manager/feedback-report";
import ReceptiveQueue from "@routes/manager/receptive-queue";
import Campaign from "@routes/manager/campaign";
import RingGroup from "@routes/manager/ring-group";
import CallsReport from "@routes/manager/calls-report";
import Reports from "@routes/manager/reports";
import smsDashboard from "@routes/manager/sms-dashboard.js";
import Voice3C from "@routes/manager/voice-3c.js";
import Blocklist from "@routes/manager/blocklist.js";
import IvrAfterCall from "@routes/manager/ivr-after-call.js";
//import Refer from "@routes/manager/refer";
import ManagerWhatsapp from "@routes/manager/manager-whatsapp.js";
import UserSettings from "@routes/manager/user-settings.js";
import TermsOfUse from "@routes/manager/terms-of-use.js";
import TermsOfUseLogin from "@dialer/manager/components/terms-of-use.vue";
import VoiceSettings from "@routes/manager/voice-settings";
import VoicePanel from "@routes/manager/voice-panel";
import BaseAdmin from "@admin/pages/base-admin.vue";

import AdminCompanies from "@routes/admin/admin-companies";
import Partners from "@routes/admin/partners";
import Tags from "@routes/admin/tags";
import Coupons from "@routes/admin/coupons";
import AdminUsers from "@routes/admin/admin-users";
import AdminSms from "@routes/admin/sms";
import AdminTelecom from "@routes/admin/telecom";
import AdminSystem from "@routes/admin/system";

import Agent from "@routes/agent/agent"
import BaseWhatsapp from "@whatsapp/pages/base-whatsapp";
import WhatsappIndex from "@routes/whatsapp/whatsapp-index";

import CrmIndex from "./routes/crm/crm-index";
import BaseCrm from "@crm/pages/base-crm.vue";
import CrmEmpty from "@crm/pages/empty-funnel/crm-empty.vue";
import CrmFunnel from "@crm/pages/crm-funnel.vue";
import CrmNewOpportunity from "@crm/pages/opportunity/crm-new-opportunity.vue";
import CrmOpportunity from "@crm/pages/opportunity/crm-opportunity.vue";

import BasePartner from "@partner/pages/base-partner";
import PartnerDashboard from "@partner/pages/partner-dashboard.vue";
import Financial from "@routes/admin/financial";
import PartnerResetPassword from "@pages/auth/components/partner-reset-password.vue";
import VerifyEmail from "@pages/auth/components/verify-email.vue";

import Audience from "@routes/manager/audience";
import Chatbot from "@routes/manager/chatbot";
import InsightsAi from "@routes/manager/insights-ai";
import CrmLoading from "@crm/pages/crm-loading.vue";
import crmHome from "@crm/pages/manager/crm-tour/crm-home.vue";
import ExtractView from "@partner/pages/extract-view.vue";


const routes = [
  {
    path: "/admin",
    name: "admin",
    redirect: "/admin/companies",
    components: {
      base: BaseAdmin
    },

    children: [AdminCompanies, Partners, Financial, Tags, Coupons, AdminSms, AdminUsers, AdminTelecom, AdminSystem],

    beforeEnter: (to, from, next) => {
      if (store.getters["auth/isAdmin"]) next();
      else next("/");
    }
  },

  {
    path: "/partner",
    name: "partner",
    redirect: "/partner/dashboard",
    components: {
      base: BasePartner
    },

    children: [
      {
        path: "dashboard",
        name: "dashboard",
        components: {
          main: PartnerDashboard
        }
      },
      {
        path: "extract",
        name: "extract",
        components: {
          main: ExtractView
        }
      },
    ],

    beforeEnter: (to, from, next) => {
      if (store.getters["auth/isPartner"]) next();
      else next("/");
    }
  },

  {
    path: "/",
    redirect: () => {
      if (!store.getters["auth/isAuthenticated"]) return "/login";
      else if (store.getters["auth/isAdmin"]) return "/admin";
      else if (store.getters["auth/isSupervisorOrManager"]) return "/manager";
      else if (store.getters["auth/isPartner"]) return "/partner";
      else if (store.getters["auth/isAgent"]) {
        if (store.getters["auth/onlyHaveWhatsapp"]) return "/whatsapp";
        else return "/agent";
      }
    }
  },

  {
    path: "/whatsapp",
    name: "whatsapp-index",

    components: {
      base: BaseWhatsapp
    },

    children: [WhatsappIndex],

    beforeEnter: (to, from, next) => {
      store.dispatch("agent/agent/setActiveDropdownLeft", "");
      store.dispatch("agent/agent/setActiveDropdownRight", "");
      if (!store.getters["auth/isAdmin"] && store.getters["auth/isWhatsappAllowed"]) next();
      else next("/");
    }
  },
  {
    path: "/crm",
    components: {
      base: BaseCrm
    },
    children: [
      {
        path: "",
        name: "",
        components: {
          crm: CrmLoading
        }
      },
      {
        path: "home",
        name: "",
        components: {
          crm: crmHome
        }
      },
      {
        path: ":id",
        components: {
          crm: CrmFunnel
        }
      },
      {
        path: "empty",
        components: {
          crm: CrmEmpty
        }
      },
      {
        path: ":id/opportunity",
        components: {
          crm: CrmNewOpportunity
        }
      },
      {
        path: ":id/opportunity/:opportunityId",
        components: {
          crm: CrmOpportunity
        }
      },
    ],
    beforeEnter: (to, from, next) => {
      store.dispatch("agent/agent/setActiveDropdownLeft", "");
      store.dispatch("agent/agent/setActiveDropdownRight", "");
      if (store.getters["auth/isAgent"]) next();
      else next("/");
    }
  },

  {
    path: "/agent",
    components: {
      base: BaseAgent
    },
    children: [
      Agent,
    ],

    beforeEnter: (to, from, next) => {
      if (store.getters["auth/isAgent"]) next();
      else next("/");
    },
  },
  {
    path: "/manager",
    name: "manager",
    redirect: () => {
      if (store.getters["auth/onlyHaveWhatsapp"]) return "/manager/whatsapp";
      else return "/manager/voice-panel";
    },

    components: {
      base: BaseManager
    },
    children: [
      Feedback,
      Qualification,
      Teams,
      Users,
      Intervals,
      Telephony,
      ReceptiveNumbers,
      OfficeHours,
      ReceptiveIVRS,
      Schedules,
      ManualCalls,
      UtilizationReport,
      AgentsReport,
      LoginHistory,
      QualificationsReport,
      ProductsAndServices,
      ChatsReport,
      ChatsAgentsReport,
      GroupChannelsReport,
      ChatQualificationsReport,
      PerformanceReport,
      ivrs,
      intervalsReport,
      Lists,
      FeedbackReport,
      ReceptiveQueue,
      RingGroup,
      Campaign,
      CallsReport,
      Reports,
      smsDashboard,
      Voice3C,
      Blocklist,
      ManagerWhatsapp,
      IvrAfterCall,
      //Refer,
      UserSettings,
      TermsOfUse,
      CrmIndex,
      Integration,
      Audience,
      Chatbot,
      Integration,
      InsightsAi,
      VoiceSettings,
      VoicePanel
    ],
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/isSupervisorOrManager"]) next();
      else next("/");
    }
  },

  {
    path: "/extension",
    components: {
      base: BaseExtensionIntegration
    },
    children: [
      {
        path: "",
        name: "extension",
        components: {
          main: JssipIntegration
        }
      }
    ],
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/isAuthenticated"]) next();
      else next("/not-found");
    },
  },

  {
    path: "/auto-login",
    components: {
      base: BaseLogin
    },
    children: [
      {
        path: "",
        name: "auto-login",
        components: {
          main: AutoLogin
        }
      }
    ]
  },

  {
    path: "/login",
    components: {
      base: BaseLogin
    },

    /**
     *
     */
    children: [
      {
        path: "",
        name: "login",
        components: {
          main: Login
        }
      },
      {
        path: "forgot-password",
        name: "forgot-password",
        components: {
          main: ForgotPassword
        }
      },
      {
        path: "reset-password/:resetToken",
        name: "reset-password",
        components: {
          main: ResetPassword
        }
      },
      {
        path: "register-partner",
        name: "register-partner",
        components: {
          main: RegisterPartner
        }
      },
      {
        path: "partner/forgot-password",
        name: "partner-forgot-password",
        components: {
          main: PartnerForgotPassword
        }
      },
      {
        path: "partner/reset-password/:resetToken",
        name: "partner-reset-password",
        components: {
          main: PartnerResetPassword
        }
      },
      {
        path: "partner/verify-email",
        name: "partner-verify-email",
        components: {
          main: VerifyEmail
        }
      },
      {
        path: "two-fa-qr-code",
        name: "two-fa-qr-code",
        components: {
          main: TwoFaQrCode
        },
        beforeEnter: (to, from, next) => {
          const hasTwoFa = store.getters["auth/hasTwoFa"];

          if (hasTwoFa && hasTwoFa.two_factor_location_status && !store.getters["auth/isAuthenticated"]) {
            next();
          } else {
            next({name: "login"});
          }
        }
      },
      {
        path: "two-fa-code",
        name: "two-fa-code",
        components: {
          main: TwoFaCode
        },
        beforeEnter: (to, from, next) => {
          const hasTwoFa = store.getters["auth/hasTwoFa"];

          if (hasTwoFa && hasTwoFa.two_factor_location_status && !store.getters["auth/isAuthenticated"]) next();
          else next({name: "login"});
        }
      },
      {
        path: "two-fa-confirmation",
        name: "two-fa-confirmation",
        components: {
          main: TwoFaConfirmation
        },
        beforeEnter: (to, from, next) => {
          const hasTwoFa = store.getters["auth/hasTwoFa"];

          if (hasTwoFa && hasTwoFa.location_identifier && !store.getters["auth/isAuthenticated"]) next();
          else next({name: "login"});
        }
      },
    ],

    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/isAuthenticated"]) next();
      else next("/");
    }
  },
  {
    path: "/terms",
    name: "terms-use-login",
    components: {
      base: TermsOfUseLogin
    }
  },
  {
    path: "/pix/:id",
    name: "pix-payment",
    components: {
      base: PixPayment
    }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    components: {
      base: NotFound404
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

const publicRoutes = {
  login: true,
  "auto-login": true,
  "forgot-password": true,
  "reset-password": true,
  "pix-payment": true,
  "register-partner": true,
  "partner-forgot-password": true,
  "partner-reset-password": true,
  "partner-verify-email": true,
  "two-fa-qr-code": true,
  "two-fa-code": true,
  "two-fa-confirmation": true,
  "terms-use-login": true,
  "extension": true,
};

router.beforeEach(async (to, from, next) => {
  if (from.path.startsWith("/whatsapp") || from.path.startsWith("/manager/whatsapp")) {
    store.commit("whatsapp/chat/setLastVisitedWhatsAppRoute", from.path);
    store.commit('auth/setPaymentModal', false);
  }

  if (to.path.startsWith("/whatsapp")) {
    store.commit("whatsapp/chat/setLastVisitedWhatsAppRoute", to.path);
  }

  const showDataUpdateModal = store.getters["auth/isSupervisorOrManager"] && from.name == "terms-of-use";

  if (store.getters["auth/isAuthenticated"]) {
    if (showDataUpdateModal) {
      store.commit("manager/user/showDataUpdateModal");
      return;
    }

    next();
  } else if (!publicRoutes[to.name]) {
    if (store.getters["auth/hasTwoFa"] && store.getters["auth/hasTwoFa"].two_factor_location_status) {
      next();
    } else {
      next({name: "login"});
    }
  } else next();
});

export default router;
