import store from "@store";

import IndexReports from "@dialer/manager/pages/reports/index-reports";
import ListReports from "@dialer/manager/pages/reports/list-reports";

export default {
  path: "reports",
  name: "reports",
  components: {
    main: IndexReports
  },
  children: [
    {
      path: "",
      components: {
        reports: ListReports
      }
    },
  ],
  beforeEnter: (to, from, next) => {
    if (store.getters["auth/isManager"] || store.getters["auth/isSupervisor"]) next();
    else next({
      name: "not-found",
      params: {
        pathMatch: to.path.split('/').slice(1)
      }
    });
  }
};
