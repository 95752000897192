export default {
    startLoadingFlag: (state, loadingFlag) => state.loadingFlags[loadingFlag] = true,
    stopLoadingFlag: (state, loadingFlag) => state.loadingFlags[loadingFlag] = false,
    startLoading: (state) => state.loading = true,
    stopLoading: state => {
        state.loading = false
        state.loadingContext = ""
    },
    setLoadingContext: (state, loadingContext) => state.loadingContext = loadingContext,

    setErrorMessage: (state, errorMesage) => state.errorMessage = errorMesage,
    setErrors: (state, errors) => state.errors = errors,
    clearErrors: (state) => {
        state.errors = {}
        state.errorMessage = ""
    },
    setCall: (state, call) => state.call = call,
    setManualCallACW: (state, manualCallACW) => state.manualCallACW = manualCallACW,

    setMailing: (state, mailing) => state.mailing = mailing,
    setAgentCallStatus: (state, status) => state.agentCallStatus = status,
    setWhatsappCallCurrentNumber: (state, phone) => state.whatsappCallCurrentNumber = phone,
    setCallHistory: (state, callHistory) => state.callHistory = callHistory,
    setCallHistoryNumberCalling: (state, callHistoryNumberCalling) => state.callHistoryNumberCalling = callHistoryNumberCalling,
    setCampaignMetricsCharts: (state, campaignMetricsCharts) => state.campaignMetricsCharts = campaignMetricsCharts
}
