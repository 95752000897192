import axios from "axios";

export default {
  fetchWorkBreakIntervals({ commit }) {
    commit("startLoadingFlag", "fetchWorkBreakIntervals");
    return new Promise((resolve, reject) => {
      axios
        .get("/agent/work_break_intervals", { params: { per_page: -1 }})
        .then(response => {
          commit("setWorkBreakIntervals", response.data.data);
          resolve();
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "fetchWorkBreakIntervals");
        });
    });
  },

  scheduleWorkBreak({ commit }, payload) {
    commit("setScheduledWorkBreak", false);
    commit("startLoadingFlag", "scheduleWorkBreak");
    axios
      .post("/agent/work_break/" + payload.work_break_id + "/enter")
      .then(() => {
        commit("setScheduledWorkBreak", true);
      })
      .catch(error => {
        commit("system/setErrorMessage", error.response.data, { root: true });
      })
      .finally(() => {
        commit("stopLoadingFlag", "scheduleWorkBreak");
      });
  },

  requestIntervalReturn({ commit }, payload) {
    commit("startLoadingFlag", "requestIntervalReturn");
    const { id } = payload;

    return new Promise((resolve, reject) => {
      axios
        .post(`/agent/work_break/${id}/exit_request`)
        .then(() => {
          commit("setReturnRequested", true);
          resolve();
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => commit("stopLoadingFlag", "requestIntervalReturn"));
    })
  }
};
