<template>
  <header-component
    :title="$t('international_route')"
    pretitle="TELECOM"
  >
    <template #buttons>
      <button
        @click="showModalNewEndpoint = true"
        class="btn btn-primary d-flex justify-content-center align-items-center"
        style="height: 40px;width: 264px"
      >
        <i class="fa-light fa-upload me-3" />
        Atualizar planilha
      </button>
    </template>
  </header-component>
  <table-international :table-data="spreads" />

  <modal-component
    :center="true"
    size="modal-lg"
    :show="showModalNewEndpoint"
    @close="closeModal"
  >
    <template #content>
      <div class="modal-header border">
        <h2 class="modal-title  mx-auto">
          Importar planilha
        </h2>
        <button
          type="button"
          class="btn tx-text-gray fs-4 p-0"
          @click="closeModal"
        >
          <i class="far fa-times" />
        </button>
      </div>
      <div class="modal-body border border-top-0">
        <div
          class="d-flex justify-content-center"
          style="margin-bottom: 32px;"
        >
          <step-by-step
            custom-class="fs-2"
            :index="step"
          />
        </div>
        <div>
          <h2 class="fw-normal text-center">
            Planilha <span class="fw-medium">{{ textStep() }}</span>
          </h2>
          <h4 class="tx-text-gray text-center fw-normal">
            Faça upload da planilha no campo abaixo
          </h4>
        </div>
        <file-uploader
          filter="text/csv,text/plain"
          @select-file="selectFileStep"
          ref="fileUploader"
        />
        <div
          class="row"
          style="margin-top: 40px;"
        >
          <div class="col-6">
            <button
              class="btn btn-gray-blue w-100 tx-text-gray"
              @click="closeModal"
            >
              Cancelar
            </button>
          </div>
          <div class="col-6">
            <button
              :disabled="disabled"
              class="btn btn-primary w-100"
              @click="nextStep"
            >
              Avançar
            </button>
          </div>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import TableInternational from "@admin/pages/telecom/components/table-International.vue";
import HeaderComponent from "@/components/header-component.vue";
import ModalComponent from "@components/modal-component.vue";
import StepByStep from "@pages/auth/components/step-by-step.vue";
import FileUploader from "@components/file-uploader.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "RouteInternational",
  components: { FileUploader, StepByStep, ModalComponent, HeaderComponent, TableInternational },
  data() {
    return {
      showModalNewEndpoint: false,
      collapse: false,
      step: -1,
      plans: {
        gold: null,
        platinum: null,
        instant: null
      },
      disabled: true
    };
  },
  computed: {
    ...mapGetters("admin/telecom", {
      spreads: "getSpreadsheet"
    })
  },
  methods: {
    ...mapActions("admin/telecom", ["createSpreadsheet", "spreadsheet"]),
    selectFileStep(file) {
      if (this.step === -1) {
        this.plans.gold = file;
      } else if (this.step === 0) {
        this.plans.platinum = file;
      } else if (this.step === 1) {
        this.plans.instant = file;
      }
    },
    textStep() {
      if (this.step === -1) {
        return "Gold";
      } else if (this.step === 0) {
        return "Platinum";
      } else if (this.step === 1) {
        return "Instant";
      }
    },
    nextStep() {
      let plan;
      const { gold, platinum, instant } = this.plans;
      switch (this.step) {
        case -1:
          plan = gold;
          break;
        case 0:
          plan = platinum;
          break;
        case 1:
          plan = instant;
          break;
        default:
          plan = null;
          break;
      }

      if (this.step < 1 && plan) {
        this.disableButton(true);
        this.step++;
        this.$refs.fileUploader.removeFiles(plan);
      }

      if ((gold && platinum && instant) !== null) {
        this.sendFile();
      }
    },
    sendFile() {
      let formData = new FormData();
      formData.append("gold_pricing_table", this.plans.gold);
      formData.append("platinum_pricing_table", this.plans.platinum);
      formData.append("idt_instant_pricing_table", this.plans.instant);
      this.createSpreadsheet(formData).then(() => this.closeModal());
    },
    disableButton(boolean) {
      this.disabled = boolean;
    },
    closeModal() {
      this.showModalNewEndpoint = false;
      this.plans = {
        gold: null,
        platinum: null,
        instant: null
      };
      this.$refs.fileUploader.dropzone.removeAllFiles();
    }
  },
  mounted() {
    this.spreadsheet();
  },
  watch: {
    plans: {
      handler() {
        this.disableButton(false);
        if (this.plans.instant !== null) {
          this.step++;
        }
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: none;
}
.btn-gray-blue {
  background-color: $color-gray-blue-300;
}
</style>
