export default {
  loadingFlags: {
    qualify: false,
    manualQualify: false
  },
  errorMessage: '',
  errors: {},
  lastCallIsqualificated: false,
  list: [],
  callQualificated: false,
  manualCallACWQualified: false,
}
