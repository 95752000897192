export default function createTimerPlugin(timer) {
  return store => {
    timer.addEventListener("secondsUpdated", () => {
      store.commit("system/setTimeAsString", timer.getTimeValues().toString());
      let totalSeconds =
        timer.getTimeValues().seconds +
        timer.getTimeValues().minutes * 60 +
        timer.getTimeValues().hours * 60 * 60;
      store.commit("system/setTotalSeconds", totalSeconds);
    });
    timer.addEventListener('targetAchieved', function () {
      store.commit("system/setTimerTargetAchieved", true);
    });
  }
}
