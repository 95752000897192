<template>
  <div class="container-fluid">
    <header-component :title="$t('capitalizes.voice')">
      <template #buttons>
        <router-link
          v-if="user && user.role.name === 'manager'"
          to="/manager/voice-settings"
          class="btn btn-primary px-6"
        >
          {{ $t('settings') }}
        </router-link>
      </template>
    </header-component>

    <div class="row">
      <insights-ai-card />

      <dial-feature-card
        :title="$t('dialer_menu_capitalize')"
        icon="fa-phone-volume"
        redirect-link="campaign"
        :current-state="currentStateCampaigns"
        :empty-text="$t('have_a_cloud_based_solution_to_enhance_your_calls')"
        :data="campaigns"
        :modal-title="$t('automatic_dialer')"
        :modal-description="$t('automatic_dialer_3cplus_message')"
        :benefits-list="dialerBenefitsList"
        benefits-link="https://3cplusnow.com/discador"
        :loading="!currentStateCampaigns || loadingCampaignFlags.loadActiveCampaigns"
        :images="{
          empty1: images.dialerFront,
          empty2: images.dialerBack,
        }"
      />

      <dial-feature-card
        :title="$t('receptive_capitalize')"
        icon="fa-phone-arrow-down"
        redirect-link="receptive-queue"
        :current-state="currentStateReceptiveQueues"
        :empty-text="$t('manage_incoming_calls_efficiently_with_queues_and_menus')"
        :data="receptiveQueues"
        :modal-title="$t('receptive_capitalize')"
        :modal-description="$t('optmize_receptive_calls_message')"
        :benefits-list="receptiveBenefitsList"
        benefits-link="https://3cplusnow.com/receptivo"
        :loading="!currentStateReceptiveQueues || isReceptiveQueueLoading"
        :images="{
          empty1: images.receptiveFront,
          empty2: images.receptiveBack,
        }"
      />

      <dial-feature-card
        :title="$t('PABX')"
        icon="fa-phone-office"
        redirect-link="ring-group"
        :current-state="currentStateRingGroups"
        :empty-text="$t('optimize_internal_and_external_communication_with_and_efficient_system')"
        :data="ringGroups"
        benefits-link="https://3cplusnow.com"
        :modal-title="$t('PABX')"
        :modal-description="$t('enhance_communication_with_ring_groups')"
        :benefits-list="ringGroupBenefitsList"
        :loading="!currentStateRingGroups ||
          loadingRingGroupFlags.loadRingGroups ||
          loadingRingGroupFlags.loadRingGroupCalls ||
          loadingRingGroupFlags.loadRingGroupMetrics ||
          loadingRingGroupFlags.loadRingGroupAgents"
        :images="{
          empty1: images.pabxFront,
          empty2: images.pabxBack,
        }"
      />

      <agents-card />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

import HeaderComponent from "@/components/header-component.vue";
import InsightsAiCard from "./components/insights-ai-card.vue";
import DialFeatureCard from "./components/dial-feature-card.vue";
import AgentsCard from "./components/agents-card.vue";
import images from "./components/images";
export default {
  name: "VoicePanel",
  data() {
    return {
      images,
      currentStateCampaigns: "",
      currentStateReceptiveQueues: "",
      currentStateRingGroups: "",
      dialerBenefitsList: this.$tm('dialer_benefits_list'),
      receptiveBenefitsList: this.$tm('receptive_benefits_lists'),
      ringGroupBenefitsList: this.$tm('pabx_benefits_list')
    };
  },

  components: {
    HeaderComponent,
    InsightsAiCard,
    DialFeatureCard,
    AgentsCard
  },

  async mounted() {
    this.setFiltersIncludes(["queues", "ring_groups"]);
    await this.findFilters();
    this.loadCurrentStates();
    this.loadActiveCampaigns();
    this.loadReceptiveQueues();
    this.loadRingGroupsList();
  },

  beforeUnmount() {
    this.clearCampaignsData();
    this.clearReceptiveQueuesData();
    this.clearRingGroupData();
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser"
    }),
    ...mapGetters("manager/filters", {
      filters: "getFilters"
    }),
    ...mapGetters("manager/campaign", {
      activeCampaigns: "getActiveCampaigns",
      loadingCampaignFlags: "getLoadingFlags"
    }),
    ...mapGetters("manager/receptive_queues", {
      queues: "getQueues",
      isReceptiveQueueLoading: "isLoading"
    }),
    ...mapGetters("manager/ring_group", {
      ringGroupsList: "getRingGroups",
      loadingRingGroupFlags: "getLoadingFlags"
    }),
    campaigns() {
      return Object.values(this.activeCampaigns)
        .sort(
          (a, b) =>
            Object.values(b.campaign_information.quantity_of_agents_with_online_status).length -
            Object.values(a.campaign_information.quantity_of_agents_with_online_status).length
        )
        .slice(0, 3)
        .map(campaign => {
          return {
            id: campaign.id,
            name: campaign.name,
            calls_today: campaign.campaign_information.quantity_of_calls_today,
            agents_with_online_status: Object.values(
              campaign.campaign_information.quantity_of_agents_with_online_status
            ).length
          };
        });
    },

    receptiveQueues() {
      const queues = [...this.queues];
      return queues
        .sort((queueA, queueB) =>
            Object.values(queueB.agents.status_count)
              .reduce((acc, count, index) => (index !== 0 ? acc + count : acc), 0) -
            Object.values(queueA.agents.status_count)
              .reduce((acc, count, index) => (index !== 0 ? acc + count : acc), 0)
        )
        .slice(0, 3)
        .map(queue => {
          return {
            id: queue.id,
            name: queue.name,
            calls_today: queue.connected + queue.abandoned,
            agents_with_online_status: Object.values(queue.agents.status_count)
              .reduce((acc, count, index) => (index !== 0 ? acc + count : acc), 0)
          };
        });
    },

    ringGroups() {
      const ringGroups = [...this.ringGroupsList];
      return ringGroups.slice(0, 3).map(ringGroup => {
        return {
          id: ringGroup.id,
          name: ringGroup.name,
          calls_today: ringGroup.all_calls ? ringGroup.all_calls : 0,
          agents_with_online_status: this.countUsersActive(ringGroup.ring_group_users)
        };
      });
    }
  },

  methods: {
    ...mapActions("manager/campaign", ["loadCampaigns", "setFilters", "clearCampaignsData"]),
    ...mapActions("manager/filters", ["findFilters", "setFiltersIncludes"]),
    ...mapActions("manager/receptive_queues", [
      "loadActiveReceptiveQueues",
      "loadReceptiveCalls",
      "loadReceptiveMetrics",
      "loadReceptiveAgents",
      "clearReceptiveQueuesData"
    ]),
    ...mapActions("manager/ring_group", [
      "loadRingGroups",
      "loadRingGroupCalls",
      "loadRingGroupMetrics",
      "loadRingGroupAgents",
      "clearRingGroupData"
    ]),
    loadActiveCampaigns() {
      if (this.currentStateCampaigns === "default") {
        this.setFilters({
          start_date: moment()
            .startOf("day")
            .format("YYYY-MM-DD HH:mm:ss"),
          end_date: moment()
            .endOf("day")
            .format("YYYY-MM-DD HH:mm:ss")
        });

        this.loadCampaigns("active");
      }
    },

    async loadReceptiveQueues() {
      await this.loadActiveReceptiveQueues({
        per_page: -1,
        include: "number_settings"
      });
      await this.loadReceptiveCalls();
      await this.loadReceptiveMetrics();
      await this.loadReceptiveAgents();
    },

    async loadRingGroupsList() {
      await this.loadRingGroups();
      await this.loadRingGroupCalls();
      await this.loadRingGroupMetrics();
      await this.loadRingGroupAgents();
    },

    loadCurrentStates() {
      const { max_agents_login, pabx, ring_group_licenses } = this.user.company;
      const { campaigns, queues, ring_groups } = this.filters;

      if (max_agents_login === 0) {
        this.currentStateCampaigns = "empty";
        this.currentStateReceptiveQueues = "empty";
      } else {
        this.currentStateCampaigns = campaigns.active.length ? "default" : "config";
        this.currentStateReceptiveQueues = queues.length ? "default" : "config";
      }

      if (!pabx || !ring_group_licenses) {
        this.currentStateRingGroups = "empty";
      } else {
        this.currentStateRingGroups = ring_groups.length ? "default" : "config";
      }
    },

    countUsersActive(users) {
      const count = users.reduce((acc, user) => user.is_online_in_ring_group ? acc + 1 : acc, 0);
      return count;
    },
  }
};
</script>
