export default {
  getMetrics: state => state.metrics,
  getChatbots: state => state.chatbots,
  getChatbotsPagination: state => state.chatbotsPagination,
  getChatbotsParams: state => state.chatbotsParams,
  getChatbot: state => state.chatbot,
  getLoadingFlags: state => state.loadingFlags,
  getNewChatbot: state => state.newChatbot,
  getDeletedChatbot: state => state.deletedChatbot,
}
