<template>
  <div
    class="tab-pane fade"
    id="tab-dialer"
    role="tabpanel"
    aria-labelledby="list-tab-dialer"
  >
    <warmup-insights-ia v-if="!stereo.aiEvolution" />
    <warmup-setting v-else-if="stereo.aiEvolution && stereo.audio && !stereo.aiSettings" />
    <template v-else>
      <card-general-metrics
        v-if="!loadingFlags['loadDashboardIA'] && Object.values(metrics).length"
        :metrics="metrics"
      />

      <div
        class="row"
        v-else
      >
        <div class="col-7">
          <card-loading
            v-if="loadingFlags['loadDashboardIA']"
            :height="108"
          />
          <card-loading
            v-if="loadingFlags['loadDashboardIA']"
            :height="224"
          />
        </div>
        <card-loading
          class="col-5"
          v-if="loadingFlags['loadDashboardIA']"
          :height="348"
        />
      </div>
      <div
        v-if="Object.values(metrics).length"
        id="agentsList"
        class="card"
      >
        <div
          class="card-header border-0 p-0"
          style="height: 56px;"
        >
          <search-input
            @search="search"
            :search="term"
            class="w-100 mx-4"
          />
        </div>
        <div class="card-body px-0 py-0">
          <div class="table-responsive">
            <table class="table table-hover table-nowrap card-table">
              <thead>
                <tr>
                  <th>
                    <a
                      class="list-sort text-muted"
                      href="#"
                    >{{ $t('CAMPAIGN') }}</a>
                  </th>
                  <th class="text-center pe-5">
                    <a
                      class="list-sort text-muted"
                      href="#"
                    >{{ $t("uppercases.grades_average") }}</a>
                  </th>
                  <th class="px-0">
                    <a
                      class="list-sort text-muted"
                      href="#"
                    >{{ $t("uppercases.grades") }}</a>
                  </th>
                  <th class="text-center">
                    {{ $t("uppercases.feedbacks_total") }}
                  </th>
                </tr>
              </thead>
              <tbody class="list fs-base">
                <loading-table-component
                  v-if="loadingFlags['loadCampaignsIA']"
                  :columns="4"
                  :lines="5"
                  :list-width="[72, 72, 72]"
                />
                <template v-if="campaigns.length && !loadingFlags['loadCampaignsIA']">
                  <tr
                    style="height: 40px !important;"
                    class="py-0"
                    v-for="(campaign, index) in campaigns"
                    :key="campaign.campaign_id"
                    @click="$router.push(`/manager/insights-ai/campaign-ai/${campaign.campaign_id}`)"
                    @mouseenter="activeHover = campaign.campaign_id"
                    @mouseleave="activeHover = ''"
                  >
                    <td>
                      <div class="d-flex">
                        <img
                          v-if="index < 3 && campaign.ranking_position > 0"
                          class="me-3"
                          :src="rankingPosition(campaign.ranking_position)"
                          alt="award"
                        >
                        <p
                          v-else
                          class="my-0 me-3 tx-text-200 fw-medium"
                          style="margin-left: 8px !important;"
                        >
                          {{ index }}
                        </p>
                        <div
                          class="overflow-ellipsis"
                          style="width: 230px;"
                        >
                          {{ campaign.name }}
                          <i
                            v-if="campaign.overall_score < 3"
                            class="fa-regular fa-triangle-exclamation tx-yellow-400 ms-3"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <p class="mb-0 tx-text-gray text-center pe-3">
                        <span
                          :class="scoreIcon(metrics.overall_score).textColor"
                          class="fw-medium"
                        >{{
                          scoreFixed(campaign.overall_score)
                        }}</span>
                        /10
                      </p>
                    </td>
                    <td class="pe-0 ps-5">
                      <div class="w-100">
                        <progress-bar-multiple
                          :data="[
                            {
                              title: this.$t('good'),
                              percentage: calculatePercentage(campaign.calls_quantity.good, campaign.calls_quantity.total),
                              count: campaign.calls_quantity.good,
                              color: '#34C759',
                              icon: 'fas fa-circle text-small'
                            },
                            {
                              title: this.$t('neutral'),
                              percentage: calculatePercentage(
                                campaign.calls_quantity.neutral,
                                campaign.calls_quantity.total
                              ),
                              color: '#FFCC00',
                              count: campaign.calls_quantity.neutral,
                              icon: 'fas fa-circle text-small'
                            },
                            {
                              title: this.$t('bad'),
                              percentage: calculatePercentage(campaign.calls_quantity.bad, campaign.calls_quantity.total),
                              color: '#F23F2C',
                              count: campaign.calls_quantity.bad,
                              icon: 'fas fa-circle text-small'
                            }
                          ]"
                          custom-width="100%"
                          style="min-width: 360px !important;"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-center justify-content-center position-relative">
                        <span>{{ campaign.calls_quantity.total }}</span>
                        <i
                          v-show="activeHover === campaign.campaign_id"
                          class="far fa-chevron-right position-absolute end-0 me-4"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div
            v-if="!campaigns.length && !loadingFlags['loadCampaignsIA']"
            class="text-center my-4"
          >
            <img
              src="@/assets/img/search-pluszinha.svg"
              alt="pluzinha busca"
            >
            <p class="mb-0 tx-text-200">
              {{ $t('no_campaign_yet') }}
            </p>
          </div>
        </div>
        <div
          class="card-footer p-0"
          v-if="agentsPagination.total_pages > 1"
        >
          <app-paginator
            v-if="!loadingFlags['loadCampaignsIA']"
            :pagination="agentsPagination"
            @change-page="changePage"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SearchInput from "@components/search-input.vue";
import CardLoading from "@components/card-loading.vue";
import CardGeneralMetrics from "@dialer/manager/pages/insights-ai/components/card-general-metrics.vue";
import WarmupInsightsIa from "@dialer/manager/pages/insights-ai/dashboard/tabs/warmups/warmup-insights.vue";
import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";
import ProgressBarMultiple from "@components/progressbar-multiple-lg.vue";
import AppPaginator from "@components/app-paginator.vue";
import {mapActions, mapGetters} from "vuex";
import _ from "lodash";
import WarmupSetting from "@dialer/manager/pages/insights-ai/dashboard/tabs/warmups/warmup-setting.vue";

export default {
  name: "TabDialer",

  components: {
    WarmupSetting,
    AppPaginator,
    ProgressBarMultiple,
    LoadingTableComponent,
    WarmupInsightsIa,
    CardGeneralMetrics,
    CardLoading,
    SearchInput
  },

  data() {
    return {
      term: "",
      imgAwards: [
        { id: 1, src: require("@/assets/img/first-award.svg") },
        { id: 2, src: require("@/assets/img/second-award.svg") },
        { id: 3, src: require("@/assets/img/third-award.svg") }
      ],
      activeHover: ""
    };
  },

  computed: {
    ...mapGetters("manager/insights", {
      metrics: "getDashboardIA",
      campaigns: "getCampaignsIA",
      loadingFlags: "getLoadingFlags",
      agentsPagination: "getCampaignsPagination",
      currentTab: "getCurrentTab"
    }),

    ...mapGetters("auth", {
      auth: "getUser"
    }),

    stereo() {
      const data = this.auth;
      const audio = data.company?.enabled_recordings_in_stereo;
      const aiEvolution = data.company?.ai_call_evaluation;
      const aiSettings = data.company?.ai_settings_configured;
      return { audio, aiEvolution, aiSettings };
    },
  },

  methods: {
    ...mapActions("manager/insights", [
      "loadDashboardIA",
      "loadCampaignsIA",
      "setCampaignsCurrentPage",
      "setCampaignsFilters"
    ]),

    scoreFixed(value) {
      return value.toFixed(1);
    },

    calculatePercentage(value, total) {
      return (value / total) * 100;
    },

    insightsNote() {
      const badNote = this.metrics.criteria.filter(item => item.score < 6);
      const goodNote = this.metrics.criteria.filter(item => item.score >= 6);
      return { badNote, goodNote };
    },

    handlerCriteria() {
      const names = this.metrics.criteria.map(item => item.name);
      const scores = this.metrics.criteria.map(item => item.score);
      return { names, scores };
    },

    scoreIcon(value) {
      let note, badge, textColor;
      if (value >= 6) {
        note = "fa-thumbs-up";
        badge = "good";
        textColor = "tx-green-400";
      } else if (value >= 3) {
        note = "fa-meh";
        badge = "alert";
        textColor = "tx-yellow-400";
      } else {
        note = "fa-thumbs-down";
        badge = "danger";
        textColor = "text-danger";
      }

      return { note, badge, textColor };
    },

    changePage(page) {
      this.setCampaignsCurrentPage(page);
      this.loadCampaignsIA();
    },

    search: _.debounce(function(term) {
      this.term = term;
      this.setCampaignsFilters({
        search: term
      });
      this.loadCampaignsIA();
    }, 700),

    rankingPosition(ranking) {
      const imgAward = this.imgAwards.filter(img => img.id === ranking);
      return imgAward[0].src;
    }
  },

  mounted() {
    this.loadDashboardIA();
    this.loadCampaignsIA();
  },

  watch: {
    getMetrics: {
      handler() {
        this.handlerCriteria();
        this.insightsNote();
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.table td {
  padding: 0 !important;
  line-height: 40px;
}
</style>
