export default {
  loading: false,
  loadingFlag: {
    listOnlineUsers: false,
    listOfflineUsers: false,
    switchWebPhone: false,
    switchExtension: false,
    listFilters: false,
    findById: false,
    create: false,
    update: false,
    updatePassword: false,
    remove: false,
    deactivate: false,
    disable: false,
    findManagerDataSelect: false,
    downloadCSV: false,
    findUserData: false,
    updateUserData: false,
    findLineOfWork: false,
    findPromoter: false,
    findCompanyRole: false,
    updateUserIpAllowedVerification: false,
    listIpsAllowed: false,
    createIp: false,
    deleteIp: false,
    updateIp: false
  },
  errorFlags: {
    updateIp: {},
    createIp: {}
  },

  alert: null,
  userData: null,
  lineOfWork: null,
  promoter: null,
  companyRole: null,
  errors: {},
  listOnlineUsers: [],
  listOfflineUsers: [],
  filters: {},
  register: null,
  paginationOnlineUsers: {},
  paginationOfflineUsers: {},
  list: [],
  managerDataSelect: [],
  dataUpdateModal: false,
  temporaryUnlockModal: false,
  listIpAllowed: []
};
