import axios from "axios";

export default {

  findTags({ commit, getters }) {
    commit("startLoadingFlags", "findTags");

    commit("setList", []);
    return new Promise((resolve, reject) => {
      axios.get("/tags", { params: getters.getParams })
        .then((response) => {
          commit("setPagination", response.data.meta.pagination)
          commit("setList", response.data.data);
          resolve();
        })
        .catch((response) => {
          commit("system/setErrorMessage", response.response.data, { root: true })
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoadingFlags", "findTags");
        });
    });
  },
  findCompanies({ commit }) {
    commit("startLoadingFlags", "findCompanies");
    commit("setListCompanies", []);
    return new Promise((resolve, reject) => {
      axios.get(`/companies?per_page=-1`)
        .then((response) => {
          commit("setListCompanies", response.data.data);
          resolve();
        })
        .catch((response) => {
          commit("system/setErrorMessage", response.response.data, { root: true })
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoadingFlags", "findCompanies");
        });
    });
  },
  findCompaniesWithTags({ commit }) {
    commit("startLoadingFlags", "findCompaniesWithTags");
    commit("setAllList", []);
    axios.get("/companies?per_page=-1&include=tags")
      .then((response) => {

        commit("setAllList", response.data.data);
      })
      .catch((response) => {
        commit("system/setErrorMessage", response.response.data, { root: true })
      })
      .finally(() => {
        commit("stopLoadingFlags", "findCompaniesWithTags");
      });
  },
  editTags({ commit }, payload) {
    commit("startLoadingFlags", "editTags");
    commit("setErrors", [])
    return new Promise((resolve, reject) => {
      axios.put(`/tags/${payload.id}`, {
        color: payload.color,
        companies: payload.companies,
        name: payload.name,
      })
        .then(() => {
          commit("system/setSuccessMessage", "Tag atualizada com sucesso!", { root: true })
          resolve();
        })
        .catch((response) => {
          reject({ message: response.response.data.detail });
          commit("setErrors", response.response.data.errors);
        })
        .finally(() => {
          commit("stopLoadingFlags", "editTags");
        });
    });
  },
  createTags({ commit }, payload) {
    commit("startLoadingFlags", "createTags");
    commit("setErrors", [])
    return new Promise((resolve, reject) => {
      axios.post(`/tags`, {
        color: payload.color,
        companies: payload.companies,
        name: payload.name,
      })
        .then(() => {
          commit("system/setSuccessMessage", "Tag criada com sucesso!", { root: true })
          resolve();
        })
        .catch((response) => {
          reject({ message: response.response.data.detail });
          commit("setErrors", response.response.data.errors);
        })
        .finally(() => {
          commit("stopLoadingFlags", "createTags");
        });
    });
  },

  deleteTags({ commit }, payload) {
    commit("startLoadingFlags", "deleteTags");

    return new Promise((resolve, reject) => {
      axios.delete(`/tags/${payload.id}`)
        .then(() => {
          commit("system/setSuccessMessage", "Tag excluída com sucesso!", { root: true })
          resolve();
        })
        .catch((error) => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlags", "deleteTags");
        });
    });
  },

  setCurrentPage({ commit }, page) {
    commit("setCurrentPage", page);
  },
  setPerPage({ commit }, perPage) {
    commit("setPerPage", perPage);
  },

  setFilters({ commit }, filters) {
    commit("setFilters", filters);
  },

  setErrors({ commit }, errors) {
    commit("setErrors", errors);
  },
}
