import {useQuery} from "@vue/apollo-composable";
import EARNINGS_CHART_QUERY from "@/graphql/earningsChart.query.gql";
import CONVERSION_RATE_QUERY from "@/graphql/conversionRate.query.gql";
import AVERAGE_TICKET_QUERY from "@/graphql/averageTicket.query.gql";
import PROGRESS_RATE_QUERY from "@/graphql/progressRateWidget.query.gql";
import AVERAGE_SALES_CYCLE_QUERY from "@/graphql/averageSalesCycle.query.gql";
import OPPORTUNITIES_QUERY from "@/graphql/opportunities.query.gql";
import NEW_OPPORTUNITIES_QUERY from "@/graphql/newOpportunities.query.gql"
import ON_GOING_OPPORTUNITIES_QUERY from "@/graphql/onGoingOpportunities.query.gql"
import REASONS_LOSS_QUERY from "@/graphql/reasonsLoss.query.gql"
import LOSSES_STEPS_QUERY from "@/graphql/lossesSteps.query.gql"
import COMPLETED_ACTIVITIES_QUERY from "@/graphql/completedActivities.query.gql"
import TOP_SELLING_PRODUCTS_QUERY from "@/graphql/topSellingProducts.query.gql"
import LATEST_OPPORTUNITIES_QUERY from "@/graphql/latestOpportunities.query.gql"
import {computed} from "vue";

const queries = {
  earningsChart: {
    query: EARNINGS_CHART_QUERY,
  },
  conversionRate: {
    query: CONVERSION_RATE_QUERY,
  },
  averageTicket: {
    query: AVERAGE_TICKET_QUERY,
  },
  progressRateWidget: {
    query: PROGRESS_RATE_QUERY,
  },
  averageSalesCycle: {
    query: AVERAGE_SALES_CYCLE_QUERY,
  },
  opportunities: {
    query: OPPORTUNITIES_QUERY,
  },
  newOpportunities: {
    query: NEW_OPPORTUNITIES_QUERY,
  },
  onGoingOpportunities: {
    query: ON_GOING_OPPORTUNITIES_QUERY,
  },
  reasonsLoss: {
    query: REASONS_LOSS_QUERY,
  },
  lossesSteps: {
    query: LOSSES_STEPS_QUERY,
  },
  completedActivities: {
    query: COMPLETED_ACTIVITIES_QUERY,
  },
  topSellingProducts: {
    query: TOP_SELLING_PRODUCTS_QUERY,
  },
  latestOpportunities: {
    query: LATEST_OPPORTUNITIES_QUERY,
  }
}


export function useDashboardQuery(
  funnel_id,
  start_date,
  end_date,
  agent_id,
  query,
  amount = 5,
) {

  const enabled = computed(() => {
    return !!start_date.value.startDate && !!end_date.value.endDate && !!funnel_id.value.id
  });

  const {result, loading, error} = useQuery(queries[query].query,
    () => ({
      funnel_id: funnel_id.value.id,
      start_date: start_date.value.startDate,
      end_date: end_date.value.endDate,
      agent_id: agent_id.value,
      amount: amount.value,
    }),
    () => ({
      debounce: 0,
      enabled: enabled,
      fetchPolicy: 'no-cache'
    })
  );

  const data = computed(() => result.value?.[query] ?? null);

  return {
    data,
    loading,
    error
  }
}