import axios from "axios";

export default {

    loadAllActiveCalls({commit}) {
        commit("startLoading", "loadAllActiveCalls");

        return new Promise((resolve, reject) => {
            axios.get('/company/calls')
                .then((response) => {
                    commit("setAllActiveCalls", response.data.data);
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    commit("stopLoading", "loadAllActiveCalls");
                });
        })
    },
    updateOrCreateActiveCall({commit, getters}, event) {
        let {call} = event;

        // Remove call from its previous status
        for (const callStatus in getters.getAllActiveCalls) {
            const callsMap = getters.getAllActiveCalls[callStatus];

            if(callsMap[call.id]) {
                commit("removeActiveCall", callsMap[call.id]);
                break;
            }
        }

        // Update or create active call
        if (getters.getAllActiveCalls[call.status] && getters.getAllActiveCalls[call.status][call.id]) {
            commit("updateCallStatus", call);
        } else {
            commit("createActiveCall", call);
        }
    },

    removeActiveCall({commit, getters}, call) {
        // Remove call from its previous status
        for (const callStatus in getters.getAllActiveCalls) {
            const callsMap = getters.getAllActiveCalls[callStatus];

            if(callsMap[call.id]) {
                commit("removeActiveCall", callsMap[call.id]);
                return;
            }
        }
    }
}
