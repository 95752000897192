<template>
  <div v-html="renderedMarkdown" />
</template>

<script>
import MarkdownIt from 'markdown-it'

export default {
  name: 'MarkdownRenderer',
  props: {
    content: {
      type: String,
      required: true
    }
  },
  computed: {
    renderedMarkdown() {
      const md = new MarkdownIt();
      return md.render(this.content);
    }
  }
};
</script>

<style scoped>

</style>
