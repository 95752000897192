export default {
    startLoading: (state, loadingContext = '') => {
        state.loading = true
        state.loadingContext = loadingContext
    },
    stopLoading: state => {
        state.loading = false
        state.loadingContext = ''
    },
    setList: (state, list) => state.list = list,
    setErrorMessage: (state, errorMesage) => state.errorMessage = errorMesage,
    setErrors: (state, errors) => state.errors = errors,
    clearErrors: (state) => {
        state.errors = {}
        state.errorMessage = ""
    }
}
