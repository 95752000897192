<template>
  <modal-component
    :backdrop="'static'"
    :show="show"
    :center="true"
    class="category-modal"
  >
    <template #content>
      <div class="modal-header">
        <h5
          class="modal-title fs-4"
          style="color: #575778"
        >
          {{ notification.title }}
        </h5>
      </div>
      <div class="modal-body text-center d-flex flex-column align-items-center">
        <img
          src="@/assets/img/sad-pluszinha.svg"
          alt="Pluszinha triste"
          style="padding-bottom: 16px; width: 139px;"
        >
        <strong class="mb-3">{{ notification.textStrong }}</strong>
        <p
          class="fw-normal"
          style="color: #575778"
          v-html="notification.text"
        />
      </div>
      <div
        style="gap: 1rem"
        class="modal-footer flex-column p-4 pt-0 border-0"
      >
        <app-button
          style="width: 328px"
          @click="handleClick(notification.clickButton)"
        >
          {{ notification.buttonText }}
        </app-button>
      </div>
    </template>
  </modal-component>
</template>

<script>


import {mapActions, mapGetters} from "vuex";

import ModalComponent from "@components/modal-component.vue";
import AppButton from "@components/app-button.vue";



export default {
  components: { AppButton, ModalComponent},

  data(){
    return{
      show: false,
    }
  },

  computed: {
    ...mapGetters("auth", {
      isManager: "isManager",
      showPaymentModal:"showPaymentModal"
    }),
    notification() {
      return {
        title: this.$t('attention_needed'),
        textStrong: this.$t('something_is_not_working'),
        text: this.$t('defaulter_client_agent'),
        buttonText: this.$t('back_to_login'),
        clickButton: "handleLogout",
      };
    }
  },
  methods:{
    ...mapActions("auth", ["logout"]),
    
    handleLogout() {
      this.logout().then(() => {
        this.show = false;
        window.location.href = "/login";
      });
    },
    handleClick(method) {
      this[method]();
    }
  },
  watch: {
    showPaymentModal(showPaymentModal) {
      this.show = showPaymentModal
    }
  }
};
</script>
