<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h5
            :class="styleTitle"
            class="card-title tx-text-gray fs-6"
          >
            {{ title }}
          </h5>
          <slot name="content" />
          <h3 class="mb-0">
            {{ number === 0 ? "-" : number }}
          </h3>
        </div>
        <span
          v-if="!badgeNone"
          :class="badge"
          class="badge d-flex align-items-center justify-content-center mb-0"
          style="height: 40px;width: 40px;"
        >
          <i
            :class="icon"
            class="fs-3"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardDetails",
  props: {
    title: {
      type: String,
      required: true
    },
    styleTitle: {
      type: String,
      default: ""
    },
    number: {
      type: [String, Number],
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    badge: {
      type: String,
      required: true
    },
    badgeNone: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.badge {
  border-radius: 10px !important;
}

.good {
  background-color: #d6e9d9;
  color: #015d0d;
}

.alert {
  background-color: #f7eac4;
  color: $color-orange-400;
}
.danger {
  background-color: $color-hover-danger;
  color: #920000;
}
.default {
  background-color: $color-gray-blue-300;
  color: #677c92;
}
</style>
