<template>
  <label class="form-label text-capitalize">{{ $t("team") }}<span class="text-lowercase">(s)</span></label>
  <button
    type="button"
    class="btn btn-link p-0"
    ref="teamsInfo"
  >
    <span class="fe fe-info text-primary ms-2 cursor-pointer" />
  </button>
  <small class="form-text text-muted">
    {{ $t("agent_access_description") }}
  </small>
  <VueMultiselect
    label="name"
    track-by="id"
    :placeholder="$t('select_more_teams')"
    :select-label="$t('click_or_enter_to_select')"
    :deselect-label="$t('click_or_enter_to_remove')"
    :selected-label="$t('selected')"
    open-direction="bottom"
    v-model="teams"
    :multiple="true"
    :close-on-select="false"
    :preserve-search="true"
    :options="optionTeams"
    @select="addTeam"
    @remove="removeTeam"
    :disabled=" loading && (loadingContext == 'add-team' ||
      loadingContext == 'remove-team')"
  />
</template>

<script>
import VueMultiselect from "vue-multiselect";
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { generateTooltip } from "@/utils.js";

export default {
emits: ['update-teams'],
  data() {
    return {
      teams: [],
      optionTeams: [],
    }
  },
  props: {
    selectedTeams: {
      type: Array,
      default: () => [],
    }
  },
  components: {
    VueMultiselect
  },
  mounted() {
    this.teams = this.selectedTeams;
    this.setIncludes(["teams"]);
    this.findFilters();

    this.teamsInfoTooltip = this.generateTooltip(
      this.$t('tooltips.teams_info'),
      this.$refs.teamsInfo
    );
  },
  computed: {
    ...mapGetters("manager/filters", {
      filters: "getFilters"
    }),
    ...mapGetters("manager/campaign", {
      loading: "isLoading",
      loadingContext: "getLoadingContext",
    }),
  },
  methods: {
    generateTooltip,
    ...mapActions("manager/filters", ["listFilters"]),
    ...mapActions("manager/campaign", [
      "addTeamInCampaign",
      "removeTeamFromCampaign",
    ]),
    ...mapActions("manager/filters", ["findFilters"]),
    ...mapMutations("manager/filters", ["setIncludes"]),

    /**
     *  Binds a team to the campaign
     *
     * @param {Object} team
     */
     addTeam(team) {
      this.addTeamInCampaign({
        campaign_id: this.$route.params.id,
        teams: [team.id]
      }).then(() => this.$emit("update-teams", this.teams))
    },

    /**
     * Removes the team binded to the campaign
     *
     * @param {Object} team
     */
    removeTeam(team) {
      this.removeTeamFromCampaign({
        campaign_id: this.$route.params.id,
        team_id: team.id
      }).then(() => this.$emit("update-teams", this.teams))
    },
  },
  watch: {
    filters: {
      handler(data) {
        if(data.teams)
          this.optionTeams = data.teams;

      },
      deep: true,
    }
  }
}
</script>

<style>

</style>
