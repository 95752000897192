<script>
export default {
  name: "StatusServices",
}
</script>

<template>
  <iframe
    src="https://status.3c.plus/badge?theme=light"
    scrolling="no"
  />
</template>