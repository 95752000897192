<template>
  <div
    ref="canvas"
    class="offcanvas offcanvas-end rounded-start offcanvas-style"
  >
    <div class="offcanvas-header border-0">
      <div
        class="d-flex align-items-center justify-content-between"
        style="height: 40px;"
      >
        <button
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          class="btn btn-rounded-circle btn-white float-start me-3 p-0 tx-text-200"
          style="width: 40px;height: 40px;"
        >
          <span class="fe fe-chevron-left" />
        </button>
        <h1 class="ms-2 mb-0 tx-text-200">
          {{ $t("back_capitalize") }}
        </h1>
      </div>
    </div>
    <div class="offcanvas-body pt-0">
      <div class="card stroke">
        <div class="card-header">
          <h4 class="mb-0">
            {{ mode === "edit" ? $t("update_omni") : $t("add_capitalize") }} {{ $t("ip_and_network_ranges") }}
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div
              v-for="(ip, index) in ipList"
              :key="index"
              class="col-12"
            >
              <div
                class="form-group d-flex align-items-center gap-3"
                :class="{ 'mb-0': errorsCreate || errorsUpdate }"
              >
                <input
                  v-maska
                  data-maska="#00.#00.#00.#00/#0"
                  data-maska-tokens="0:[0-9]:optional"
                  class="form-control"
                  :class="{
                    'is-invalid': errorsCreate[`ip.${index}`]
                      ? errorsCreate[`ip.${index}`][0]
                      : errorsUpdate[`data.${index}.ip`]
                        ? errorsUpdate[`data.${index}.ip`][0]
                        : false
                  }"
                  v-model.trim="ipList[index].ip"
                  style="max-width: 499px;"
                  placeholder="EX: 186.226.54.153/32"
                >
                <i
                  v-if="index > 0 || mode === 'edit'"
                  @mouseenter="hoverIcon = index"
                  @mouseleave="hoverIcon = ''"
                  class="far fa-trash cursor-pointer"
                  :class="[hoverIcon === index ? 'text-danger' : 'tx-text-gray']"
                  @click="descrementList(ip, index)"
                />
              </div>
              <p
                class="text-danger"
                v-if="errorsCreate || errorsUpdate"
              >
                {{ errorsCreate[`ip.${index}`] ? errorsCreate[`ip.${index}`][0] : "" }}
                {{ errorsUpdate[`data.${index}.ip`] ? errorsUpdate[`data.${index}.ip`][0] : "" }}
              </p>
            </div>
            <div class="d-flex align-items-center gap-4 w-100">
              <div
                class="border col"
                style="height: 2px;"
              />
              <button
                class="btn btn-sm col-5 bg-gray-blue-300 tx-text-gray px-4"
                @click="incrementList"
              >
                <i class="far fa-plus" />
                {{ $t("add_network_range") }}
              </button>
              <div
                class="border col"
                style="height: 2px;"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button
          :disabled="!disabledButton"
          class="btn btn-primary w-50 px-4"
          @click="submit()"
        >
          <span v-if="loadingFlags.createIp || loadingFlags.updateIp"><i class="fa fa-spinner fa-spin" /> </span>
          <span v-else> {{ $t("capitalizes.save") }} </span>
        </button>
      </div>
      <div
        v-if="mode === 'edit'"
        class="d-flex justify-content-center my-0"
      >
        <app-button
          @click="destroy()"
          :loading="loadingFlags.deleteIp"
          icon="trash"
          color="btn-outline-danger border-0 mt-3"
        >
          {{ $t("capitalizes.exclude") }}
        </app-button>
      </div>
    </div>
  </div>
  <modal-component
    :show="showModal"
    :center="true"
    @close="showModal = false"
  >
    <template #content>
      <div class="card mb-0">
        <div class="card-header">
          <h4 class="modal-title">
            {{ $t("attention") }}
          </h4>
          <button
            class="btn tx-text-gray"
            @click="showModal = false"
          >
            <i class="far fa-times" />
          </button>
        </div>
        <div
          class="card-body"
          style="padding: 40px 40px 32px 40px"
        >
          <h2 class="mb-0 tx-text-200 text-center">
            {{ $t("range_not_contain_your_ip") }}
          </h2>
          <p
            class="mb-0 text-center"
            style="margin-top: 16px;"
          >
            {{ $t("add_ip_to_avoid_connection") }}
          </p>
          <div
            class="d-flex justify-content-center align-items-center bg-gray-blue-200 rounded"
            style="height: 50px;margin-top: 32px;"
          >
            <p
              class="mb-0 tx-text-200 "
              style="margin-right: 8px;"
            >
              {{ $t("current_ip") }}:
            </p>
            <h2 class="mb-0 tx-text-200">
              {{ userIp }}
            </h2>
          </div>
          <div
            class="d-flex flex-column justify-content-center align-items-center"
            style="margin-top: 32px;"
          >
            <button
              @click="submit(true)"
              class="btn btn-danger"
              style="width: 328px;"
            >
              {{ $t("save_anyway") }}
            </button>
            <button
              @click="showModal = false"
              class="btn tx-text-gray"
            >
              {{ $t("back_to_edition") }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import { Offcanvas } from "bootstrap";
import { mapActions, mapGetters, mapMutations } from "vuex";
import AppButton from "@/components/app-button.vue";
import ModalComponent from "@components/modal-component.vue";

export default {
  name: "CreateEditIp",
  props: {
    listIp: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      id: null,
      mode: "",
      offcanvas: null,
      hoverIcon: "",
      count: 1,
      ipList: [],
      disabledButton: true,
      showModal: false
    };
  },

  components: {
    ModalComponent,
    AppButton
  },

  mounted() {
    this.offcanvas = new Offcanvas(this.$refs.canvas);
    this.$refs.canvas.addEventListener("hidden.bs.offcanvas", () => {
      this.listIpsAllowed();
    });
  },
  beforeUnmount() {
    this.offcanvas.dispose();
    this.$refs.canvas.removeEventListener("hidden.bs.offcanvas", null);
  },
  computed: {
    ...mapGetters("manager/user", {
      loadingFlags: "getLoadingFlag",
      errorFlags: "getErrorFlags"
    }),
    ...mapGetters("system", {
      userIp: "getUserIp"
    }),
    errorsCreate: {
      get() {
        const renameKeys = (obj, incrementBy) => {
          let renamedObj = {};
          Object.keys(obj).forEach(key => {
            let parts = key.split(".");
            let index = parseInt(parts[1]);
            let newKey = `ip.${index + incrementBy}`;
            renamedObj[newKey] = obj[key];
          });
          return renamedObj;
        };
        return this.listIp?.length
          ? renameKeys(this.errorFlags("createIp"), this.listIp.length)
          : this.ipList?.length
          ? this.errorFlags("createIp")
          : [];
      }
    },
    errorsUpdate: {
      get() {
        return this.errorFlags("updateIp");
      }
    }
  },

  methods: {
    ...mapActions("manager/user", ["createIp", "listIpsAllowed", "deleteIp", "updateIp"]),
    ...mapActions("system", ["showConfirmationMessage"]),
    ...mapMutations("manager/user", ["clearErrorFlags"]),
    incrementList() {
      let lastPosition = this.ipList.length ? this.ipList.length - 1 : undefined;
      let filledField = false;

      if (lastPosition !== undefined) {
        filledField = this.ipList[lastPosition].ip !== "";
      }
      if (filledField) {
        this.ipList.push({ ip: "" });
      }
    },
    descrementList(item, index) {
      if (item.id) {
        this.destroy(item.id);
        return;
      }
      if (this.ipList.length > 1) {
        this.count--;
        this.hoverIcon = "";
        this.ipList.splice(index, 1);
      }
    },
    edit() {
      this.mode = "edit";
      this.resetIpList();
      for (let i = 0; i < this.listIp.length; i++) {
        this.ipList[i] = this.listIp[i];
      }
      this.ipList = this.ipList.map(item => {
        return {
          id: item.id,
          ip: `${item.ip}/${item.range}`
        };
      });

      this.offcanvas.show();
    },

    new() {
      this.mode = "new";
      this.resetIpList();
      this.ipList.push({ ip: "" });
      this.offcanvas.show();
    },

    resetIpList() {
      this.ipList = [];
      this.clearErrorFlags("updateIp");
      this.clearErrorFlags("createIp");
    },

    submit(confirmation = false) {
      this.showModal = false;
      const ip = this.ipList.map(item => {
        return item.ip;
      });
      const removeSuffix = str => str.replace(/\/\d{1,2}$/, "");
      const verifyMyIp = ip.find(item => removeSuffix(item) === this.userIp);
      if (!verifyMyIp && !confirmation) {
        this.showModal = true;
        return;
      }
      if (this.mode === "edit") {
        const updateIpList = this.ipList.filter(item => item.id);
        const createIp = this.ipList.filter(item => !item.id).map(item => item.ip);
        let erro = false;
        if (updateIpList.length || createIp.length) {
          this.updateIp({ data: updateIpList })
            .catch(() => (erro = true))
            .finally(() => {
              if (createIp.length) {
                this.createIp({ ip: createIp })
                  .catch(() => (erro = true))
                  .finally(() => {
                    if (!erro) this.offcanvas.hide();
                  });
              } else {
                if (!erro) this.offcanvas.hide();
              }
            });
          return;
        }
      }

      this.createIp({ ip })
        .then(() => {
          this.offcanvas.hide();
          this.showModal = false;
        })
        .catch(() => {
          this.showModal = false;
        });
    },

    async destroy(id) {
      const response = await this.showConfirmationMessage({
        title: this.$t("delete_ip_confirmation"),
        text: this.$t("delete_feedback_confirmation"),
        type: "badWarning"
      });

      if (response.isConfirmed) {
        const data = id ? { id: [id] } : { id: [] };
        this.deleteIp(data).then(() => {
          this.offcanvas.hide();
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.bg-gray-blue-200 {
  background-color: $color-gray-blue-200;
}

.bg-gray-blue-300 {
  background-color: $color-gray-blue-300;
}

.btn-danger {
  background-color: #f23f2c !important;
}

.offcanvas-style {
  width: 624px !important;
  background-color: #f9fafc;
}

.stroke {
  box-shadow: 0 12px 24px 0 #12263f07 !important;
}
</style>
