<template>
  <div class="col-12">
    <p class="text-medium tx-text-gray">
      {{ $t('today_capitalize') }}:
    </p>
    <card-loading
      v-if="loadingFlags.loadDashboardIA"
      :height="currentState === 'default' ? 88 : 75"
    />
    <div
      v-else
      :class="['card', currentState]"
    >
      <div
        class="card-body cursor-pointer"
        @click="$router.push('/manager/insights-ai')"
      >
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <div class="custom-icon d-flex align-items-center justify-content-center rounded-1 me-3">
              <i
                class="fa-regular fa-wand-magic-sparkles"
                :class="{
                  'text-primary': currentState === 'default',
                  'blue-500': currentState === 'empty'
                }"
              />
            </div>
            <h2
              class="card-title m-0"
              :class="{
                'blue-500': currentState === 'empty',
              }"
            >
              {{ currentState === 'default' ? $t('ia_insights') : $t('no_rating_done') }}
            </h2>
          </div>
          <div
            v-if="currentState === 'default'"
            class="d-flex align-items-center"
          >
            <small class="me-4">
              <strong>
                {{ $t('average_company_rating_uppercase') }}
              </strong>
            </small>
            <div class="me-3">
              <span class="fs-3 tx-green-400 fw-bold">{{ metrics.overall_score?.toFixed(1) }}</span>/<span class="fs-5 tx-text-gray">10</span>
            </div>
            <i class="fa-regular fa-chevron-right fs-2" />
          </div>

          <div
            v-if="currentState === 'empty'"
            class="d-flex align-items-center text-primary gap-2"
          >
            <i class="fa-regular fa-plus fs-5" />
            <span>{{ $t('start_using') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CardLoading from "@components/card-loading.vue";

export default {
  name: "InsightsAiCard",
  data() {
    return {
      currentState: ""
    }
  },

  mounted() {
    if (this.user.company.enabled_recordings_in_stereo && this.user.company.ai_call_evaluation && this.user.company.ai_settings_configured) {
      this.currentState = "default";
    } else {
      this.currentState = "empty";
    }

    this.loadDashboardIA();
  },

  components: {
    CardLoading
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser"
    }),

    ...mapGetters("manager/insights", {
      metrics: "getDashboardIA",
      loadingFlags: "getLoadingFlags"
    })
  },

  methods: {
    ...mapActions("manager/insights", [
      "loadDashboardIA",
    ]),
  }
};
</script>

<style scoped lang="scss">
.card {
  &.default {
    background: url("~@/assets/img/covers/grainy-cover.png") 0px 0px / 100% 100% no-repeat;
    &:hover {
      background: $color-gray-blue-300;

      .custom-icon {
        background-color: $color-gray-blue-200;
      }

      .fa-chevron-right {
        color: var(--color-blue-300);
      }
    }
    .custom-icon {
      width: 40px;
      height: 40px;
      background-color: $color-gray-blue-300;
    }

    .fa-chevron-right {
      color: var(--color-text-gray);
    }
  }

  &.empty {
    background-color: $color-gray-blue-200;
    border: 1px solid $color-gray-blue-300;

    .blue-500 {
      color: $color-blue-500;
    }
  }
}
</style>
