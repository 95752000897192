export default {
  loadingFlags: {
    fetchChatsReportList: false,
    fetchSendCsvEmail: false,
    fetchChatPdf: false,
    fetchChatTimeline: false
  },
  errors: {},
  filters: {},
  include: [],
  pagination: {
    current_page: 1,
    per_page: 20,
  },
  chatsReportList: [],
  spiedChat: null,
  chatLogs: {}
}
