
export default {
  contacts: {},

  contact: null,

  loadingFlags: {
    findContacts: false,
    editContact: false,
    findContactByID: false,
    findContactInstances: false
  },
  //params
  filters: {},
  include: [],
  pagination: {
    per_page: 25,
    current_page: 1
  },
  
  selectedContact: null,
  
  contactInstances: [],
}
