<template>
  <div class="main-content pb-6">
    <div class="container-fluid">
      <div>
        <header-component
          redirect-link="/manager/receptive-ivrs"
          :title="$t('receptive_IVR_capitalize')"
        />
        <forms-receptive-ivr
          :fields="fields"
          @update:fields="fields = $event"
          :show-modal="showModal"
          @update:show-modal="showModal = $event"
          @submit="submit"
        />
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapMutations } from "vuex";

import HeaderComponent from "@/components/header-component.vue";
import FormsReceptiveIvr from "@dialer/manager/pages/settings/receptive_ivrs/forms-receptive-ivr.vue";

export default {
  components: {
    FormsReceptiveIvr,
    HeaderComponent,
  },
  mounted() {
    this.setIncludes(["queues"]);
    this.findFilters();
  },
  data() {
    return {
      fields: {
        name: "",
        timeout: 0,
        redirect_id: "",
        keys: [{
          id: "",
          key: this.$t("capitalizes.none_key"),
          type: "ReceptiveQueue",
        }],
        type_audio: "import",
      },
      showModal: false,
    };
  },
  methods: {
    ...mapActions("manager/filters", ["findFilters"]),
    ...mapMutations("manager/filters", ["setIncludes"]),
    ...mapActions("manager/receptive_ivrs", ["create"]),
    ...mapMutations("system", ["setFlashMessage"]),
    createFormData() {
      let formData = new FormData();
      this.showModal = false;

      if (!this.fields.redirect_id) {
        this.showModal = true;
        return;
      }
      const with_keys = this.fields.keys.filter(key => key.key !== this.$t("capitalizes.none_key"));

      formData.append("audio", this.fields.audio);
      formData.append("name", this.fields.name);
      formData.append("timeout", this.fields.timeout);
      formData.append("redirect_id", this.fields.redirect_id);
      formData.append("redirect_type", "ReceptiveQueue");

      with_keys.forEach((key, index) => {
        formData.append(`keys[${index}][id]`, key.id);
        formData.append(`keys[${index}][key]`, key.key);
        formData.append(`keys[${index}][type]`, key.type);
      });
      return formData;
    },

    async submit() {
      let formData = this.createFormData();
      if (!formData) return;
      await this.create({ formData })
      this.$router.push({ path: `/manager/receptive-ivrs` })
    },
  },

};
</script>
