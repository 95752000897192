<template>
  <div
    ref="canvas"
    class="offcanvas offcanvas-end"
  >
    <div class="offcanvas-header elements-margin border-0">
      <span class="fs-2">{{ $t('contact_data') }}</span>
      <button
        type="button"
        class="btn-close text-reset fs-2"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      />
    </div>
    <div class="offcanvas-body pt-0 d-flex flex-column justify-content-start">
      <div
        class="skeleton-loading col d-flex justify-content-centermy-3"
        v-if="loadingFlags.findContactByID"
      >
        <div class="avatar-xxl background-loading rounded-circle" />
      </div>

      <div
        class="row elements-margin d-flex space"
        v-else-if="contact"
      >
        <div class="col-2 justify-content-start mt-2 d-flex">
          <span class="avatar">
            <img
              v-if="chatImage"
              :src="chatImage"
              class="avatar-img rounded-circle"
              @error="handleImageError"
            >
          </span>
        </div>

        <div class="col-8 justify-content-start mt-1 ms-1">
          <div class="row">
            <span class="fw-light fs-6">{{ $t('number') }}</span>
          </div>
          <div class="row">
            <span
              class="phoneNumber fs-3"
            >
              {{ phoneNumber }}
            </span>
          </div>
        </div>
      </div>

      <div class="row elements-margin">
        <div class="col-xs-12">
          <div class="form-group">
            <label class="form-label fs-6 required">{{ $t('name_data') }}</label>
            <input
              data-test="contact-name-field"
              type="text"
              class="form-control"
              v-model="fields.name_alias"
            >
          </div>
        </div>
      </div>
      <edit-contact-tags
        v-if="contact"
        class="mb-4"
        :contact="contact"
      />
      <div class="row ps-3 pe-3 border-3">
        <button
          class="btn btn-primary col-xs-12 rounded"
          style="font-size: 12px !important"
          @click="submit"
        >
          {{ $t('save_general') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {Offcanvas} from "bootstrap";
import {mapGetters, mapActions, mapMutations} from "vuex";
import {Mask} from "maska";
import {handleImageError, isValidImageUrl} from "@/utils";
import EditContactTags from "@whatsapp/components/tags/edit-contact-tags.vue";

export default {
  components: {EditContactTags},
  emits: ["close"],
  props: {
    show: { type: Boolean, default: false },
    contactId: { type: Number, default: null },
  },

  data() {
    return {
      fields: {
        name_alias: "",
        number: null,
      },
      offCanvas: null,
    };
  },

  beforeUnmount() {
    this.$refs.canvas.removeEventListener("hidden.bs.offcanvas", this.close);
    this.$refs.canvas.removeEventListener("show.bs.offcanvas", this.open);
  },

  mounted() {
    this.offCanvas = new Offcanvas(this.$refs.canvas);
    this.$refs.canvas.addEventListener("hidden.bs.offcanvas", this.close);
    this.$refs.canvas.addEventListener("show.bs.offcanvas", this.open);
  },

  computed: {
    ...mapGetters("whatsapp/contact", {
      loadingFlags: "getLoadingFlags",
      contact: "getContact",
    }),
    ...mapGetters("whatsapp/chat", {
      currentChat: "getCurrentChat",
    }),

    chatImage (){
      if(this.contact && this.contact.image && this.isValidImageUrl(this.contact.image))
        return this.contact.image;
      else
        return require("@/assets/img/avatar.svg")
    },

    phoneNumber() {
      const mask = new Mask({
        mask: [
          "(##) #####-####",
          "(##) ####-####",
          "+## (##) #####-####",
          "+## (##) ####-####",
        ],
      });

      return mask.masked(JSON.stringify(this.contact.number));
    },
  },

  watch: {
    show(show) {
      if (show) {
        this.offCanvas.show();
      }
    },
  },

  methods: {
    ...mapActions("whatsapp/contact", ["updateContactInfo"]),
    ...mapMutations("whatsapp/chat", ["updateChatName"]),
    handleImageError,
    isValidImageUrl,

    open() {
      this.fields.name_alias = this.contact?.name_alias || this.contact?.name;
      this.fields.number = this.contact?.number;
    },

    close() {
      this.$emit("close");
      this.fields.name_alias = ""
    },

    submit() {
      let fixed = {};
      fixed.name_alias = this.fields.name_alias
        ? this.fields.name_alias
        : this.contact.name;
      this.updateContactInfo({
        id: this.contact.id,
        fields: fixed,
      }).then((contact) => {
        this.offCanvas.hide();
        this.$emit("close");
        this.updateChatName({
          chatId: this.$route.params.id,
          contact: contact
        })
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-img {
  width: 3.375rem !important;
  height: 3.375rem !important;
}
.offcanvas-header{
  padding-bottom: $whatsapp-spacing-1 !important;
}
.phoneNumber{
  height: $whatsapp-spacing-4 !important;
}
.form-group{
  margin-bottom: 0 !important;
}
.elements-margin{
  margin-bottom: $whatsapp-spacing-4;
}
</style>
