<template>
  <div
    class="d-flex align-items-center tx-text-200 text-large"
    :class="{
      'tx-text-light': isTextLight
    }"
  >
    <div
      style="max-width: 270px;"
      class="text-truncate"
      :class="{ 'me-2': isConsultTrying || loggedCampaign }"
      :title="`${readableCallStatus} ${showDialInfo}`"
    >
      {{ readableCallStatus }}
      <span
        class="pe-2"
        v-if="showDialInfoSpan"
      >
        {{ showDialInfo }}
      </span>
    </div>
    <span v-if="!isInternalCallFailed"> ({{ formatSecondsToMMSS(totalSeconds) }}) </span>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { isPossibleNumber, isValidPhoneNumber, validatePhoneNumberLength } from "libphonenumber-js";
import { Mask } from "maska";
import { AgentStatus, CallStatus, formatSecondsToMMSS } from "@/utils";

export default {
  data() {
    return {
      AgentStatus,
      CallStatus,
      phoneNumberOptions: {
        mask: ["(##) ####-####", "(##) #####-####", "##############9"],
        tokens: {
          "9": {
            pattern: /[0-9]/,
            repeated: true,
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters("agent/ring_group", {
      callStatus: "getCallStatus",
      callPABX: "getCallPABX",
      ringGroupUsers: "getRingGroupUsers",
      consultedAgentId: "getConsultedAgentId",
      internalCallAgent: "getInternalCallAgent",
      isRingGroupActive: "isRingGroupActive",
    }),

    ...mapGetters("system", {
      status: "getStatus",
      mode: "getMode",
      totalSeconds: "getTotalSeconds",
      isTimerTargetAchieved: "isTimerTargetAchieved",
      isConsultHold: "isConsultHold",
      agentInConsult: "getAgentInConsult",
      consultingAgentName: "getConsultingAgentName",
    }),

    ...mapGetters("agent/agent", {
      loggedCampaign: "getCampaign",
    }),

    ...mapGetters("agent/call", {
      call: "getCall",
      manualCallAcw: "getManualCallACW",
      loadingFlags: "getLoadingFlags",
    }),

    isCallActive() {
      return (
          this.status === AgentStatus.ON_CALL &&
          ((this.callPABX.call_mode === "manual" &&
                  [CallStatus.CONNECTED, CallStatus.INTERNAL_CALL_ANSWERED, CallStatus.INTERNAL_CALL_FAILED].includes(
                      this.callStatus,
                  )) ||
              (this.callPABX.call_mode === "receptive" &&
                  [CallStatus.CONSULT_ANSWERED, CallStatus.INTERNAL_CALL_ANSWERED].includes(this.callStatus)))
      );
    },
    isConsultTrying() {
      return [CallStatus.CONSULT_TRYING].includes(this.callStatus);
    },
    showDialInfoSpan() {
      if (this.isRingGroupActive) {
        return (
            this.callStatus &&
            ![CallStatus.TRYING_INTERNAL_CALL, CallStatus.INTERNAL_CALL_FAILED, CallStatus.CONSULT_TRYING, CallStatus.FAILED].includes(
                this.callStatus,
            )
        );
      }

      if (this.loggedCampaign) {
        return (
            [AgentStatus.ON_CALL, AgentStatus.CONSULT_CONNECTED].includes(this.status) &&
            !this.isConsultHold &&
            !this.isCallFailed &&
            !this.isManualCallAcwFailed
        );
      }

      return false;
    },

    isInternalCallFailed() {
      return this.callStatus === CallStatus.INTERNAL_CALL_FAILED;
    },

    showDialInfo() {
      if (this.isRingGroupActive) {
        const findUserByExtensionNumber = extensionNumber => {
          for (let group of this.ringGroupUsers) {
            const user = group.users.find(user => user.extension_number === extensionNumber);
            if (user) {
              return `${user.extension_number} - ${user.name}`;
            }
          }
          return "";
        };

        const findUserById = id => {
          for (let group of this.ringGroupUsers) {
            const user = group.users.find(user => user.id === id);
            if (user) {
              return `${user.extension_number} - ${user.name}`;
            }
          }
          return "";
        };

        if (this.callPABX.phone.includes("consultant")) {
          return findUserByExtensionNumber(this.callPABX.extension_number);
        }

        if (this.callPABX.phone.includes("internalcall")) {
          return findUserByExtensionNumber(this.callPABX.extension_number);
        }

        if (this.consultedAgentId) {
          return findUserById(this.consultedAgentId);
        }

        if (this.internalCallAgent) {
          return `${this.internalCallAgent.extension_number} - ${this.internalCallAgent.name}`;
        }

        return this.formatPhoneNumber(this.callPABX.phone);
      }

      if (this.loggedCampaign) {
        if (this.status === AgentStatus.CONSULT_CONNECTED) {
          if (this.isLoggedAgentBeingConsulted) {
            return this.consultingAgentName;
          }

          const { name } = this.agentInConsult;
          return name;
        }

        const phone = this.manualCallAcw ? this.manualCallAcw.phone : this.call?.phone;
        return phone ? this.formatPhoneNumber(phone) : "";
      }

      return "";
    },

    readableCallStatus() {
      if (this.isRingGroupActive) {
        return this.getRingGroupStatus();
      }

      if (!this.loggedCampaign) {
        return "";
      }

      return this.getCampaignStatus();
    },

    isTextLight() {
      if (this.isRingGroupActive) {
        let isTextLight = false;
        if (this.isConsultTrying) {
          isTextLight = true;
        }

        if ([CallStatus.FAILED, CallStatus.INTERNAL_CALL_ANSWERED].includes(this.callStatus) ||
            (CallStatus.CONNECTED === this.callStatus && this.callPABX.call_mode !== "receptive")
        ) {
          isTextLight = true;
        }
        return isTextLight;
      }

      if (this.loggedCampaign) {
        if (this.mode === "manual") {
          return [AgentStatus.IDLE, AgentStatus.ON_CALL, AgentStatus.ACW].includes(this.status);
        }

        if (this.mode === "dialer") {
          const AGENT_STATUSES = [AgentStatus.ON_CALL, AgentStatus.ACW, AgentStatus.ON_MANUAL_CALL_ACW];

          return (!this.isConsultHold && AGENT_STATUSES.includes(this.status)) || this.isLoggedAgentBeingConsulted;
        }
      }

      return false;
    },

    isLoggedAgentBeingConsulted() {
      return !this.call && this.status === AgentStatus.CONSULT_CONNECTED;
    },

    isCallFailed() {
      return this.call?.status == CallStatus.FAILED && this.status === AgentStatus.ON_CALL || this.callPABX?.status == CallStatus.FAILED;
    },

    isManualCallAcwFailed() {
      return this.manualCallAcw?.status == CallStatus.FAILED;
    },
  },

  methods: {
    ...mapActions("system", ["exitManual", "handleAgentStatusAfterManualCallACW"]),
    formatSecondsToMMSS,
    formatPhoneNumber(phoneNumber) {
      if (
          isPossibleNumber(phoneNumber, "BR") &&
          isValidPhoneNumber(phoneNumber, "BR") &&
          validatePhoneNumberLength(phoneNumber, "BR") === undefined
      ) {
        if (phoneNumber.length >= 12) {
          phoneNumber = phoneNumber.slice(2);
        }
        const mask = new Mask(this.phoneNumberOptions);
        return mask.masked(phoneNumber);
      }
      return phoneNumber;
    },

    getRingGroupStatus() {
      if (this.isCallFailed) {
        return this.$t(`hangup_causes.${this.callPABX.hangup_cause}.text`);
      }

      const statusMap = {
        [CallStatus.DIALING]: "calling_capitalize",
        [CallStatus.CONSULT_CONNECTED]: "consult",
        [CallStatus.CONSULT_ANSWERED]: "consult",
        [CallStatus.TRANSFERENCE_CONNECTED]: "talking_capitalize",
        [CallStatus.INTERNAL_CALL_FAILED]: "occupied_extension",
        [CallStatus.CONSULT_TRYING]: "waiting_consult",
        [CallStatus.RECEIVING_TRANSFERENCE]: "transfer_capitalize",
      };

      return this.$t(statusMap[this.callStatus] || "");
    },

    getCampaignStatus() {
      if (this.status === AgentStatus.IDLE) {
        return this.getIdleStatus();
      }

      if (this.status === AgentStatus.ON_WORK_BREAK) {
        return this.$t("capitalizes.break");
      }

      if (this.status === AgentStatus.ON_CALL) {
        if (this.isCallFailed) {
          return this.$t(`hangup_causes.${this.call.hangup_cause}.text`);
        }

        if (this.isManualCallAcwFailed) {
          return this.$t(`hangup_causes.${this.manualCallAcw.hangup_cause}.text`);
        }

        return this.isConsultHold ? this.$t("waiting_consult") : this.$t("talking_capitalize");
      }

      if ([AgentStatus.ACW, AgentStatus.ON_MANUAL_CALL_ACW].includes(this.status)) {
        return this.$t("post_service_time");
      }

      if (this.status === AgentStatus.CONSULT_CONNECTED) {
        return this.$t("in_consultation_with");
      }

      return "";
    },

    getIdleStatus() {
      if (this.mode === "dialer") {
        return this.$t("waiting_call");
      }

      if (this.mode === "manual") {
        if (this.loadingFlags.startManualCall || (this.call && this.call.status === CallStatus.DIALING)) {
          return `${this.$t("calling_capitalize")}...`;
        }

        return this.$t("manual_mode");
      }

      return "";
    },
  },

  watch: {
    isTimerTargetAchieved(isTimerTargetAchieved) {
      if (this.status === AgentStatus.ACW && this.mode === "manual" && isTimerTargetAchieved) {
        this.handleAgentStatusAfterManualCallACW();
      } else if (this.status === AgentStatus.IDLE && this.mode == "manual" && isTimerTargetAchieved && !this.call) {
        this.exitManual();
      }
    },
  },
};
</script>
