export default {
  loadingFlags: {
    dateRange: false
  },
  dateRange: {
    value: null,
    label: null,
    currentLabel: null,
    startDate: null,
    endDate: null,
    unitType: null
  },
  activeAgent: [],
}
