<template>
  <div
    class="modal fade"
    ref="transferChatModal"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div
        class="modal-content"
        v-if="agent"
      >
        <div class="modal-header border-0">
          <button
            type="button"
            class="btn-close"
            @click="transferChatModal.hide()"
          />
        </div>
        <div class="modal-body">
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-8 text-center">
              <p class="fs-2">
                {{ $t('pending_chats') }}
              </p>
              <p>
                {{ $t('agent_capitalize') }} <strong>{{ agent.name }}</strong> {{ $t('have') }} {{ activeConversationText(agent) }}
                {{ $t('pending_chats_2') }}
              </p>
              <p class="text-primary fw-bold">
                {{ $t('transfer') }} {{ agent.active_chats }} {{ amountConversationText(agent) }} {{ $t('transferred_to.to') }}:
              </p>
            </div>
            <div
              v-if="(agent?.removed_teams && agent?.removed_teams.length === 1) || (!agent?.removed_teams && teamsUser.length===1)"
              class="col-lg-8 d-flex justify-content-center mb-3"
            >
              <div class="form-check form-check-inline">
                <input
                  type="radio"
                  class="form-check-input"
                  value="agent"
                  v-model="transferTo"
                >
                <label class="form-check-label">
                  {{ $t('agent_capitalize') }}
                </label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  type="radio"
                  class="form-check-input"
                  value="queue"
                  v-model="transferTo"
                >
                <label class="form-check-label">
                  {{ $t('chats_queue') }}
                </label>
              </div>
            </div>
            <div
              class="col-lg-8 d-flex justify-content-center mb-3"
              v-else
            >
              <label class="form-check-label">
                {{ $t('service_queue') }}
              </label>
            </div>
            <div
              class="col-lg-8 mb-5"
              v-if="transferTo === 'agent'"
            >
              <select
                class="form-select"
                :class="{
                  'is-invalid': !agentToTransfer
                }"
                v-model="agentToTransfer"
              >
                <option :value="null">
                  {{ $t('select') }}
                </option>
                <template
                  v-for="teamAgent in teamAgents"
                  :key="teamAgent.id"
                >
                  <option
                    v-if="teamAgent.id != agent.id"
                    :value="teamAgent.id"
                  >
                    {{ teamAgent.name }}
                  </option>
                </template>
              </select>
              <div
                class="invalid-feedback"
                v-if="!agentToTransfer"
              >
                {{ $t('no_agent_chosen') }}
              </div>
            </div>

            <div class="col-lg-8 mb-3">
              <app-button
                :loading="loading"
                class="w-100"
                @click="transferChats"
              >
                {{ $t('transfer') }}
              </app-button>
            </div>

            <div class="col-lg-8">
              <app-button
                class="w-100"
                :color="'text-danger border-0'"
                @click="finishChats"
              >
                <span class="fe fe-trash-2" />
                {{ $t('end_all_chats') }}
              </app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';
import { mapActions, mapGetters } from 'vuex';

import AppButton from "@/components/app-button.vue";

export default {
  emits: ['handleSubmit'],
  data() {
    return {
      transferChatModal: null,
      transferTo: 'queue', // Define queue as default to transfer the chats
      agentToTransfer: null,
      teamAgents: [],
      teamsAgents: [],
      agent: null,
    }
  },
  mounted() {
    if(this.errors && this.errors.agent && this.errors.agent.active_chats) {
      /**
       * Verify if the triggered error is related to
       * the active chats of the user that is about to
       * be DISABLED
       */
      this.agent = this.errors.agent;
      this.transferChatModal = new Modal(this.$refs.transferChatModal);
      this.transferChatModal.show();
      if(this.agent?.removed_teams && this.agent?.removed_teams.length === 1){
        this.fetchTeamById({ id: this.agent?.removed_teams[0].id });
      }else{
        this.fetchTeamById({ id: this.teamId });
      }
    }
  },
  components: {
    AppButton
  },
  props: {
    teamId: {
      type: Number,
      default: null
    },
    errors: {
      type: Object,
      default: () => {}
    },
    teamsUser:{
      type: Object,
      default: () => {}
    }
  },
  methods: {
    ...mapActions("manager/teams", ["fetchTeamById"]),
    ...mapActions("manager/whatsapp", ["agentTransferChats", "finishAgentChat"]),
    ...mapActions("system", ["showConfirmationMessage"]),

    transferChats() {
      const payload = {
        user_id: this.agentToTransfer,
        internal_note: !this.agentToTransfer ? this.$t('transferred_by_manager.to_queue') : this.$t('transferred_by_manager.to_agent'),
        team_ids: this.agent?.removed_teams?.length>0 ? this.agent.removed_teams?.map(team => team.id) : this.teamsUser.map(team => team.id)
      }
      this.agentTransferChats({ agentId: this.agent.id, payload }).then(() => {
        this.$emit('handleSubmit')
        this.transferChatModal.hide();
      });
    },
    async finishChats() {
      let response = await this.showConfirmationMessage({
        title: this.$t('end_all_chats_confirmation'),
        text: "",
        type: "badWarning"
      })

      if (response.isConfirmed) {
        const params = {
          qualification: this.team.qualification_list_id,
          qualification_note: this.$t('end_all_chats_by_manager')
        };

        this.finishAgentChat({ id: this.agent.id, params }).then(() => {
          this.$emit('handleSubmit')
          this.transferChatModal.hide();
        });
      }
    },
    activeConversationText(data) {
      return data.active_chats > 1 ? this.$t('transfer_active_chats.active_chats') : this.$t('transfer_active_chats.active_chat');
    },
    amountConversationText(data) {
      return data.active_chats > 1 ? this.$t('transfer_active_chats.chats') : this.$t('transfer_active_chats.chat');
    }
  },
  computed: {
    ...mapGetters("manager/teams", {
      team: "getTeam"
    }),
    ...mapGetters("manager/whatsapp", {
      loading: "isLoading"
    }),
  },
  watch: {
    team(data) {
      if(data)
        this.teamAgents = data.agents;
    },
    /**
     * Clear the selected agent if the user chooses
     * to transfer the chats to the queue
     */
    transferTo(data) {
      if(data === 'queue')
        this.agentToTransfer = null;
    },
  }
}
</script>
