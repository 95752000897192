import axios from "axios";
import axiosOmni from "@/plugins/axios-omni.js"

export default {
  findInstances({ commit, rootGetters }) {
    //TODO: improve
    commit('setInstances', { teams: [] })
    commit('startLoading', 'findInstances');

    const role = rootGetters["auth/getUser"].role.name;
    let instancesParent = role === 'manager' ? 'company' : 'teams';

    return new Promise((resolve, reject) => {
      //TODO: create endpoint get /instances
      axios.get(`/me?include=${instancesParent}.instances`)
        .then(response => {
          commit('setInstances', response.data.data)
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
          reject();
        }).finally(() => {
          commit('stopLoading', 'findInstances');
        });
    });
  },

  findGroupChannel({ commit }, payload) {
    //TODO: improve
    commit('setGroupChannel', {})
    commit('startLoading', 'findGroupChannel');
    return new Promise((resolve, reject) => {
      //TODO: create endpoint get /instances
      commit('setGroupChannel', {})
      axiosOmni.get(`/group-channel/team/${payload.team_id}`, {params: payload})
        .then(response => {
          commit('setGroupChannel', response.data.data)
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
          reject();
        }).finally(() => {
          commit('stopLoading', 'findGroupChannel');
        });
    });
  },

  findAllowedAgentsAndInstances({ commit }, params) {
    commit('startLoading', 'findAllowedAgentsAndInstances');
    return new Promise((resolve, reject) => {
      axiosOmni.get('/whatsapp/chats/transfer/list', {params: params})
        .then(response => {
          if (params.page == 1) {
            commit('setAllowedAgents', response.data.data.users.data)
          }
          else{
            commit('addAllowedAgents', response.data.data.users.data)
          }
          commit('setAllowedInstances', response.data.data.group_channels)
          resolve(response.data.data.users);
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
          reject();
        }).finally(() => {
          commit('stopLoading', 'findAllowedAgentsAndInstances');
        });
    });
  },

  fetchGroupChannels({ commit }, search) {
    commit("startLoading", "fetchGroupChannels")
    return new Promise((resolve, reject) => {
      axiosOmni.get('/group-channel', {params: { per_page: 100, status: "active", search: search}})
          .then((response) => {
            commit('setGroupChannels', response.data.data);
            resolve();
          })
          .catch((response) => {
            commit('system/setErrorMessage', response.response.data.errors, {root: true})
            reject();
          })
          .finally(() => {
            commit("stopLoading", "fetchGroupChannels")
          });
    });
  },

  /**
 * find qualifications
 *
 * @param {*} param0
 * @returns
 */
  //TODO: modulo provisório
  findQualifications({ commit }, payload) {
    commit('startLoading', 'findQualifications');
    commit('setQualifications', []);
    return new Promise((resolve, reject) => {
      axios.get(`/agents/qualifications/${payload.id}`)
        .then(response => {
          commit('setQualifications', response.data.data)
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
          reject();
        }).finally(() => {
          commit('stopLoading', 'findQualifications');
        });
    });
  },

  fetchQualificationsById({ commit }, group_channel_id) {
    commit('startLoading', 'fetchQualificationsById');
    commit('setQualifications', []);
    return new Promise((resolve, reject) => {
      axiosOmni.get(`/group-channel/${group_channel_id}/qualifications`)
        .then(response => {
          commit('setQualifications', response.data.data)
          resolve();
        }).catch((response) => {
        commit('system/setErrorMessage', response.response.data, { root: true })
        reject();
      }).finally(() => {
        commit('stopLoading', 'fetchQualificationsById');
      });
    });
  },

  ping() {
    return axiosOmni.post('/whatsapp/agent/ping')
  },
}
