import axios from "axios";

export default {
    fetchStrategy({ commit }, id) {
      commit('setStrategy', {})
      commit("startLoading", "fetchStrategy");
      return new Promise((resolve, reject) => {
        axios.get(`/campaign/${id}/retry-strategy`)
          .then((response) => {
            commit('setStrategy', response.data.data);
            resolve();
          })
          .catch(() => {
            reject();
          })
          .finally(() => {
            commit("stopLoading", "fetchStrategy");
          });
      })
    },

    updateStrategy({ commit }, payload) {
      commit("startLoading", "updateStrategy");
      return new Promise((resolve, reject) => {
        axios.put(`/campaign/${payload.id}/retry-strategy`, payload.fields)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            commit('system/setErrorMessage', error.response.data, { root: true });
            reject();
          })
          .finally(() => {
            commit("stopLoading", "updateStrategy");
          });
      })
    },
}
