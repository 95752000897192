<template>
  <div
    class="tab-pane fade show"
    id="extensions"
    role="tabpanel"
    aria-labelledby="extensions-tab"
  >
    <div v-if="values.length">
      <div
        v-for="(field_agents, index) in values"
        :key="index"
      >
        <div class="card mt-4">
          <div class="card-header">
            <div class="d-flex align-items-center ">
              <h4 class="card-header-title">
                {{ index + 1 }}º {{ $t("extension_preference") }}
              </h4>
              <span
                v-show="(values.length - 1 == index) && (index != 0)"
                @click="removePreference()"
                class="fe fe-trash text-primary cursor-pointer ps-2"
              />
            </div>

            <ul class="nav nav-tabs nav-tabs-sm card-header-tabs">
              <li class="nav-item">
                <button
                  class="nav-link active two-lines"
                  data-bs-toggle="tab"
                  data-bs-target="#actives"
                >
                  <span class="text-muted"> TOTAL </span>
                  <br>
                  <span class="subtitle">
                    {{ field_agents.ring_group_users.length }}
                  </span>
                </button>
              </li>
            </ul>
          </div>
          <div class="card-body">
            <label>
              {{ $t("add_users_to_extension_group") }}
            </label>
            <VueMultiselect
              v-model="field_agents.ring_group_users"
              :placeholder="$t('select_agents_capitalize')"
              :track-by="'id'"
              group-values="users"
              group-label="label"
              :select-label="$t('click_or_press_enter_to_select')"
              :deselect-label="$t('click_or_press_enter_to_remove')"
              :selected-label="$t('selected')"
              :label="'name'"
              :multiple="true"
              :close-on-select="false"
              :preserve-search="true"
              :options="options"
            />
          </div>
        </div>
      </div>
      <div
        class="col-lg-12 mb-4"
        style="margin-top: 2rem;"
      >
        <div class="d-flex align-items-center gap-3">
          <hr class="w-100">
          <button
            class="btn btn-light btn-primary text-nowrap text-small new-preference-button"
            @click="addPreference"
            data-test="addNewPreference-button"
          >
            <span class="fe fe-plus" />
            {{ $t("new_preference_capitalize") }}
          </button>
          <hr class="w-100">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import VueMultiselect from "vue-multiselect";

export default {
  data() {
    return {
      values: [{
        skill: 1,
        ring_group_users: [],
      }],
    }
  },

  components: {
    VueMultiselect,
  },

  props: {
    agentSkills: {
      type: Array,
      default: () => [],
    },

    options: {
      type: Array,
      default: () => [],
    },
  },

  emits: ["update:agentSkills", "addAgent", "removeAgent"],

  mounted() {
    this.values = this.agentSkills
  },

  methods: {
    ...mapActions("system", ["showConfirmationMessage"]),

    addPreference() {
      const skill = this.values.length + 1

      this.values.push({
        ring_group_users: [],
        skill,
      })
    },

    removePreference() {
      this.values.pop();
    },

    removeAgent(agent, skill) {
      this.$emit("removeAgent", { agent, skill });
    },

    async remove() {
      let response = await this.showConfirmationMessage({
        title: this.$t("questions.delete_receptive_queue"),
        text: this.$t("questions_info.delete_receptive_queue"),
        type: "badWarning",
      })

      if (response.isConfirmed) {
        this.deleteReceptiveQueue({ id: this.$route.params.id }).then(() => {
          this.$router.push("/manager/receptive-queue");
        });
      }
    },
  },

  watch: {
    values: {
      handler(data) {
        this.$emit("update:agentSkills", data)
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.new-preference-button {
  box-shadow: unset;
  padding: .375rem 1rem;

  &.btn:active {
    box-shadow: unset;
  }

  &.btn-light {
    background-color: white;
  }

  &.btn-light:hover {
    color: $color-blue-300;
    border-color: rgba(48, 87, 242, .5);
  }
}
</style>
