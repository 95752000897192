<template>
  <div
    :class="{'skeleton-loading': loadingFlags.openIaSuggest || loadingFlags.openIaImprove}"
    class="d-flex gap-2 justify-content-center actions"
  >
    <!--INTERNAL NOTES-->
    <button
      :class="{'btn-internal-note active': tab === 'internal-notes'}"
      :disabled="activedAudioRecord || selectedMessageWabaTemplate"
      class="btn button-message-input-note d-flex justify-content-center align-items-center p-0"
      data-test="internalNotes-button"
      @click="changeTab"
    >
      <span :class="{'fe fe-x': tab === 'internal-notes', 'fal fa-note fw-medium m-0': tab === 'whatsapp'}" />
    </button>
    <!--MESSAGE TEMPLATES-->
    <button
      v-if="!currentChat.is_group && selectedChatType === 'waba'"
      :disabled="tab === 'internal-notes' || activedAudioRecord || !expiredCycle"
      class="btn button-message-input d-flex justify-content-center align-items-center p-0"
      data-test="templateMessages-button"
      @click="openMessagesTemplatesModal"
    >
      <span
        :class="{'fe fe-x': isOpenMessagesTemplatesModal, 'fal fa-memo fw-medium m-0': !isOpenMessagesTemplatesModal}"
      />
    </button>
    <!--EMOJI MESSAGE-->
    <button
      :class="{
        'btn button-message-input d-flex justify-content-center align-items-center p-0': true,
        active: showThisModal === 'emoji'
      }"
      :disabled="tab === 'internal-notes' || activedAudioRecord || selectedMessageWabaTemplate || lockActions"
      data-test="showEmoji-button"
      @click.stop="$emit('update:showThisModal', showThisModal !== 'emoji' ? 'emoji' : null)"
    >
      <i :class="{'fe fe-x': showThisModal === 'emoji', 'fa-kit fa-pluszinha': showThisModal !== 'emoji'}" />
    </button>
    <!--QUICK MESSAGES-->
    <button
      :class="{
        'btn button-message-input d-flex justify-content-center align-items-center p-0': true,
        active: showThisModal === 'quick-messages'
      }"
      :disabled="tab === 'internal-notes' || activedAudioRecord || selectedMessageWabaTemplate || lockActions"
      data-test="showQuickMessages-button"
      @click.stop="$emit('update:showThisModal', showThisModal !== 'quick-messages' ? 'quick-messages' : null)"
    >
      <span :class="{'fe fe-x': showThisModal === 'quick-messages', 'fe fe-zap': showThisModal !== 'quick-messages'}" />
    </button>
    <!--FILE UPLOAD-->
    <button
      :disabled="tab === 'internal-notes' || activedAudioRecord || lockActions"
      class="btn button-message-input d-flex justify-content-center align-items-center p-0"
      data-test="selectFile-button"
      @click="selectFile"
    >
      <span class="fe fe-paperclip" />
    </button>
    <!--3C IA-->
    <template v-if="currentChat.instance.data?.open_ai || currentChat.instance.open_ai">
      <div class="dropdown">
        <button
          ref="dropdown"
          type="button"
          :class="{'background-loading': loadingFlags.openIaSuggest || loadingFlags.openIaImprove}"
          :data-bs-toggle="text.length ? 'dropdown' : ''"
          :disabled="tab === 'internal-notes' || activedAudioRecord || lockActions"
          class="btn button-message-input d-flex justify-content-center align-items-center p-0"
          data-bs-auto-close="true"
          @click="text.length ? '' : suggestMessage()"
        >
          <span class="fa-solid fa-wand-magic-sparkles" />
        </button>
        <div class="dropdown-menu">
          <span class="fs-6 p">{{ $t('help_for') }}</span>
          <button
            class="fw-light dropdown-item d-flex justify-content-start align-items-center"
            data-bs-toggle="dropdown"
            @click="suggestMessage()"
          >
            <small class="text-truncate"> {{ $t('continue_chat') }} </small>
          </button>
          <hr class="navbar-divider">
          <button
            :disabled="text.length <=3"
            class="fw-light dropdown-item d-flex justify-content-start align-items-center"
            data-bs-toggle="dropdown"
            @click="improveMessage()"
          >
            <small class="text-truncate"> {{ $t('improve_message') }} </small>
          </button>
        </div>
      </div>
    </template>
    <input
      v-show="false"
      ref="file"
      type="file"
      @change="setFile"
    >
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {Dropdown} from "bootstrap";

export default {
  name: "MessageActions",
  emits: [
    'update:tab',
    'update:text',
    'update:isOpenMessagesTemplatesModal',
    'update:showThisModal',
    'setFile'
  ],
  props: {
    loadingFlags: {type: Object, required: true},
    currentChat: {type: Object, required: true},
    selectedChatType: {type: String, default: 'ultramsg'},
    tab: {type: String, required: true},
    showThisModal: {type: String, default: null},
    text: {type: String, default: null},
    activedAudioRecord: {type: Boolean, required: true},
    isOpenMessagesTemplatesModal: {type: Boolean, default: true},
    locked: {type: Boolean, default: false},
  },
  data (){
    return {
      dropdown: null
    }
  },

  mounted (){
    if (this.$refs.dropdown && (this.currentChat.instance.data?.open_ai || this.currentChat.instance.open_ai)) {
      this.dropdown = new Dropdown(this.$refs.dropdown);
      this.$refs.dropdown.addEventListener("hidden.bs.dropdown", this.dropdown.hide())
    }
  },
  computed: {
    ...mapGetters("auth", {
      user: "getUser",
      isAgent: "isAgent"
    }),
    ...mapGetters("whatsapp/message", {
      improvedMessage: "getImprovedMessage",
      suggestedMessage: "getSuggestedMessage",
    }),
    ...mapGetters("whatsapp/waba", {
      selectedMessageWabaTemplate: "getSelectedMessageTemplate",
    }),

    lockMessage_received () {
      return !this.currentChat?.waba_message_received?.message_received && !this.expiredCycle
    },

    lockActions () {
      return !this.currentChat.is_group && this.selectedChatType === 'waba' && (this.expiredCycle || this.lockMessage_received)
    },
    expiredCycle() {
      return this.currentChat?.waba_message_received?.end_message_cycle < Date.now() / 1000;
    },
  },
  methods: {
    ...mapActions("whatsapp/message", ["openIaImprove", "openIaSuggest"]),
    ...mapMutations('system', ['setErrorMessage']),

    changeTab (){
      this.$emit('update:tab', this.activedAudioRecord ? this.tab : this.tab === 'internal-notes' ? 'whatsapp' : 'internal-notes')
    },

    openMessagesTemplatesModal (){
      this.$emit('update:isOpenMessagesTemplatesModal', !this.isOpenMessagesTemplatesModal)
    },

    selectFile (){
      this.$refs["file"].click();
    },
    setFile (e){
      if(e.target.files && e.target.files[0]) this.$emit("setFile", e.target.files[0]);
      this.$refs.file.value = null;
    },

    improveMessage () {
      this.openIaImprove({
        instance_id: this.currentChat.instance_id,
        message: this.text,
        instructions: "melhore a sintaxe e clareza da mensagem escrevendo de forma humanizada."
      }).then(() => {
        this.$emit('update:text', this.improvedMessage)
        if (this.dropdown)
          this.dropdown.hide()
      })
    },

    suggestMessage (){
      this.openIaSuggest({
        instance_id: this.currentChat.instance_id,
        chat_id: this.currentChat.id,
        latest: 20,
      }).then(() => {
        this.$emit('update:text', this.suggestedMessage)
        if (this.$refs.dropdown && this.dropdown)
          this.dropdown.hide()
      })
    },
  },
  beforeUnmount (){
    if (this.dropdown){
      this.dropdown.hide()
      this.$refs.dropdown.removeEventListener("hidden.bs.dropdown", this.dropdown.hide())
    }
  }
}
</script>
<style lang="scss" scoped>
.skeleton-loading {
  .background-loading {
    border-radius: 8px;
    user-select: none;
    color: transparent !important;
    &.option {
      text-decoration: none !important;
      color: transparent !important;
      border: none !important;
    }
  }
  .container-loading {
    border-radius: 0.5rem !important;
  }
}

.dropdown-menu {
  padding: 16px;
}

.dropdown-item {
  padding: 8px 12px;
}

.dropdown-item {
  margin: 4px 0px;
  width: auto;
  display: block;
  border-radius: 8px !important;
  cursor: pointer;
  &:hover {
    background-color: $color-gray-100 !important;
  }
}

.actions {
  .btn:not(.btn-dropdown) {
    width: 2.25rem;
    color: #677C92;
  }
}

.button-message-input {
  border-radius: $whatsapp-border-radius;
  &:not(:last-child) {
    padding: $whatsapp-button-padding !important;
    height: $whatsapp-button-size !important;
    width: $whatsapp-button-size !important;
  }
  &:hover,
  &.active {
    background-color: #3057f2 !important;
    color: $color-text-light !important;
  }
}

.button-message-input-note {
  border-radius: $whatsapp-border-radius;
  &:not(:last-child) {
    padding: $whatsapp-button-padding !important;
    height: $whatsapp-button-size !important;
    width: $whatsapp-button-size !important;
  }
  &.active {
    background-color: #FFBB3A !important;
    color: #754000 !important;
    &:hover {
      background-color: #FFE9A9 !important;
      color: #754000 !important;
    }
  }
  &:hover {
    background-color: #3057f2 !important;
    color: $color-text-light !important;
  }
}
.navbar-divider {
  margin: 0.5rem 0;
}
</style>
