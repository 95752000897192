import store from "@store";

import IndexFeedback from "@dialer/manager/pages/settings/feedbacks/index-feedback";
import ListFeedback from "@dialer/manager/pages/settings/feedbacks/list-feedback";
import NewFeedback from "@dialer/manager/pages/settings/feedbacks/new-feedback";
import EditFeedback from "@dialer/manager/pages/settings/feedbacks/edit-feedback";

export default {
  path: "feedbacks",
  name: "feedbacks",
  components: {
    main: IndexFeedback
  },
  children: [
    {
      path: "",
      components: {
        feedbacks: ListFeedback
      }
    },
    {
      path: "new",
      components: {
        feedbacks: NewFeedback
      }
    },
    {
      path: ":id/edit",
      components: {
        feedbacks: EditFeedback
      }
    }
  ],
  beforeEnter: (to, from, next) => {
    if (store.getters["auth/isManager"]) next();
    else next({
      name: "not-found",
      params: {
        pathMatch: to.path.split('/').slice(1)
      }
    });
  }
};
