import IndexTelecom from "@admin/pages/telecom/index-telecom.vue";
import Telecom from "@admin/pages/telecom/list-routes.vue";
import store from "@/store";
import RouteInternational from "@admin/pages/telecom/route-international.vue";

export default {
  path: "telecom",
  components: {
    main: IndexTelecom
  },
  children: [
    {
      path: "",
      name: "telecom",
      components: {
        telecom: Telecom
      }
    },
    {
      path: "international",
      name: "international",
      components: {
        telecom: RouteInternational
      }
    }
  ],

  beforeEnter: (to, from, next) => {
    const restrictedAdminLevels = store.getters["admin/getRestrictedAdminLevels"];
    if (restrictedAdminLevels.includes(store.getters["auth/getUser"].settings.access)) next();
    else next("/");
  }
};
