<template>
  <div class="position-fixed top-50 start-50 translate-middle">
    <div
      ref="allowMicToast"
      id="allowMicToast"
      class="toast bg-warning"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="toast-body">
        <span class="fe fe-mic" />
        {{ $t('allow_mic') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Toast } from "bootstrap";

export default {
  name: "AllowMicToast",
  computed: {
    ...mapGetters("system", {
      userMediaPermission: "getUserMediaPermission",
    })
  },

  watch: {
    userMediaPermission(value) {
      if (value == 'not-allowed') {
        const toastEl = this.$refs.allowMicToast;
        const toast = new Toast(toastEl, {
          autohide: false
        });

        toast.show();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.toast {
  width: 359px;
}
</style>
