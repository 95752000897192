window.fbAsyncInit = function() {
  window.FB.init({
    appId            : process.env.VUE_APP_DUAQPWA,
    xfbml            : true,
    cookie           : true,
    version          : 'v18.0'
  });
};

(function(d, s, id){
  let js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {return;}
  js = d.createElement(s); js.id = id;
  js.src = "https://connect.facebook.net/pt_BR/sdk.js";
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));
