<template>
  <hover-button
    ref="internalTransference"
    @click="toggleDropdown"
    icon="fa-regular fa-phone-arrow-right"
    :keep-button-hovered="showInternalTransferenceCard"
    :disabled="[CallStatus.DIALING, CallStatus.CONSULT_ANSWERED, CallStatus.CONSULT_TRYING].includes(callStatus) || callPABX?.call_mode === 'manual'"
    :active="status === AgentStatus.ON_CALL && [CallStatus.CONNECTED, CallStatus.EXTERNAL_CALL_ANSWERED, CallStatus.TRANSFERENCE_CONNECTED, CallStatus.INTERNAL_CALL_FAILED, CallStatus.TRYING_INTERNAL_CALL].includes(callStatus)"
  />

  <dropdown-component
    :is-open="showInternalTransferenceCard"
    :button-rect="buttonRect"
    :title="$t('transfer_talk')"
  >
    <div class="overflow-auto card-internal-transference-body">
      <div v-if="ringGroupUsers.length && !loadingFlags.fetchRingGroupUsers">
        <search-input
          class="search-input"
          :search="searchText"
          @search="search => (searchText = search)"
        />
        <ul
          v-for="ringGroup in filteredRingGroups"
          :key="ringGroup.id"
        >
          <li
            class="d-flex align-items-center position-relative ring-group-item rounded"
            v-if="
              searchText === '' ||
                ringGroup.name
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                ringGroup.extension_number.toString().includes(searchText) ||
                ringGroup.users.some(user =>
                  user.name.toLowerCase().includes(searchText.toLowerCase())
                )
            "
            :title="ringGroup.name"
          >
            <span class="tx-text-gray">
              {{ ringGroup.extension_number }}
            </span>
            <strong
              class="ms-3 fw-medium text-truncate"
              style="width: 240px;"
            >
              {{ ringGroup.name }}
            </strong>

            <div
              v-if="
                ringGroup.users.length &&
                  ringGroup.users.some(user =>
                    user.name.toLowerCase().includes(searchText.toLowerCase())
                  )
              "
              class="position-absolute group-indicator"
            />

            <i
              v-if="ringGroup == hoveredItem"
              class="fa-regular fa-arrow-right fw-normal tx-text-gray pe-3"
            />
          </li>

          <ul
            v-for="user in ringGroup.users"
            :key="user.id"
          >
            <li
              v-if="
                user.name !== myUser.name &&
                  (searchText === '' ||
                    user.name.toLowerCase().includes(searchText.toLowerCase()) ||
                    user.extension_number.toString().includes(searchText))
              "
              class="d-flex align-items-center justify-content-between ring-group-item ps-5"
              :class="{
                'hover-to-call tx-text-200 rounded cursor-pointer':
                  user.is_online_in_ring_group,
                'tx-gray-200': !user.is_online_in_ring_group
              }"
              @mouseenter="hoveredItem = user"
              @mouseleave="hoveredItem = null"
              @click="_consultAgent(user)"
            >
              <div>
                <span> {{ user.extension_number }} </span>
                <span class="ms-3">{{ user.name }} </span>
              </div>

              <i
                v-if="user == hoveredItem && user.is_online_in_ring_group"
                class="fa-regular fa-arrow-right fw-normal tx-text-gray pe-3"
              />

              <div
                v-if="!user.is_online_in_ring_group"
                class="offline-badge bg-gray-200 tx-gray-300 text-medium fw-medium"
              >
                Offline
              </div>
            </li>
          </ul>
        </ul>
      </div>
      <div
        class="d-flex flex-column text-center mt-6"
        v-if="filteredRingGroups.length === 0 && !loadingFlags.fetchRingGroupUsers"
      >
        <h2 class="my-0">
          Oops...
        </h2>
        <img
          :src="require(`@/assets/img/aloisio/aloisioThinking.svg`)"
          class="align-self-center my-4"
          style="width: 68px;"
        >
        <p class="fs-6">
          {{ $t('no_extension_group_found') }}.
        </p>
      </div>

      <div
        class="h-100 d-flex flex-column justify-content-center align-items-center"
        v-if="loadingFlags.fetchRingGroupUsers"
      >
        <div
          class="spinner-border mb-3 text-primary"
          role="loading"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
        <p>{{ $t('loading_capitalize') }}...</p>
      </div>
    </div>
  </dropdown-component>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { AgentStatus, CallStatus } from "@/utils";
import HoverButton from "@dialer/agent/components/hover-button.vue";
import DropdownComponent from "@dialer/agent/components/dropdown-component.vue";
import SearchInput from "@/components/search-input.vue";

export default {
  name: "InternalTransference",
  data() {
    return {
      CallStatus,
      AgentStatus,
      showInternalTransferenceCard: false,
      buttonRect: null,
      hoveredItem: null,
      searchText: ""
    }
  },

  components: {
    HoverButton,
    DropdownComponent,
    SearchInput
  },

  methods: {
    ...mapActions("agent/ring_group", [
      "consultAgent",
      "fetchRingGroupUsers"
    ]),

    toggleDropdown() {
      this.showInternalTransferenceCard = !this.showInternalTransferenceCard
      if (this.showInternalTransferenceCard) {
        this.calculateButtonRect();
        window.addEventListener('resize', this.calculateButtonRect);
        this.fetchRingGroupUsers();
      } else {
        window.removeEventListener('resize', this.calculateButtonRect);
      }
    },

    calculateButtonRect() {
      const rect = this.$refs.internalTransference.$el.getBoundingClientRect();
      this.buttonRect = rect;
    },

    _consultAgent(user) {
      const { id, is_online_in_ring_group } = user;
      if(is_online_in_ring_group) {
        this.consultAgent(id);
      }
    }
  },

  computed: {
    ...mapGetters("agent/ring_group", {
      ringGroupUsers: "getRingGroupUsers",
      loadingFlags: "getLoadingFlags",
      callStatus: "getCallStatus",
      callPABX: "getCallPABX"
    }),

    ...mapGetters("auth", {
      myUser: "getUser"
    }),

    ...mapGetters("system", {
      status: "getStatus"
    }),

    filteredRingGroups() {
      return this.ringGroupUsers.filter(ringGroup => {
        return (
          ringGroup.name
            .toLowerCase()
            .includes(this.searchText.toLowerCase()) ||
          ringGroup.extension_number.toString().includes(this.searchText) ||
          ringGroup.users.some(user =>
            user.name.toLowerCase().includes(this.searchText.toLowerCase())
          )
        );
      });
    },
  },

  watch: {
    callStatus() {
      if (this.showInternalTransferenceCard) {
        this.toggleDropdown();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card-internal-transference-body {
  height: 345px;
  padding: 0.5rem 1rem;

  .search-input {
    padding: 0 1rem;
    margin-bottom: 1rem;
  }

  .spinner-border {
    width: 3.5rem;
    height: 3.5rem;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      &.ring-group-item {
        padding: 4px 0 4px 1rem;

        .group-indicator {
          bottom: -16px;
          left: 20px;
          height: 16px;
          width: 8px;
          border-left: 1.5px solid $color-gray-200;
          border-bottom: 1.5px solid $color-gray-200;
        }

        .offline-badge {
          padding: 4px 8px;
          line-height: 16px;
          border-radius: 6px;
        }
      }

      &.hover-to-call {
        &:hover {
          background-color: $color-gray-blue-300;
        }
      }
    }
  }
}
</style>
