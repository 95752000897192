import store from "@store";

import IndexQualification from "@dialer/manager/pages/settings/qualifications/index-qualifications";
import ListQualifications from "@dialer/manager/pages/settings/qualifications/list-qualifications";
import NewQualification from "@dialer/manager/pages/settings/qualifications/new-qualification";
import EditQualification from "@dialer/manager/pages/settings/qualifications/edit-qualification";

export default {
  path: "qualifications",
  name: "qualifications",
  components: {
    main: IndexQualification
  },
  children: [
    {
      path: "",
      components: {
        qualifications: ListQualifications
      }
    },
    {
      path: "new",
      components: {
        qualifications: NewQualification
      }
    },
    {
      path: ":id/edit",
      components: {
        qualifications: EditQualification
      }
    }
  ],
  beforeEnter: (to, from, next) => {
    if (store.getters["auth/isManager"]) next();
    else next({
      name: "not-found",
      params: {
        pathMatch: to.path.split('/').slice(1)
      }
    });
  }
};
