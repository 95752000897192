<template>
  <div class="main-content pb-6">
    <div class="container-fluid">
      <header-component
        :title="$t('active_IVR')"
        redirect-link="/manager/voice-settings"
      >
        <template #buttons>
          <router-link
            class="header-button btn btn-primary float-end px-6"
            to="/manager/ivrs/new"
          >
            {{ $t("new_active_ivr") }}
          </router-link>
        </template>
      </header-component>

      <card-tip
        class="mb-4"
        :text="$t('info_active_ivr')"
      />
      <div class="col-lg-12">
        <div class="card">
          <div
            v-if="!loadingFlags.getList && pagination.total === 0 && !term"
            class="d-flex justify-content-center"
          >
            <empty-state
              class="col-12 col-lg-10 col-xl-8"
              :title="$t('empty_active_ivr')"
              icon="ivr.svg"
              sub-title=" "
            >
              <template #buttons>
                <button
                  class="btn btn-primary px-6"
                  @click="$router.push('/manager/ivrs/new')"
                >
                  <i class="fal fa-plus" /> {{ $t("new_active_ivr") }}
                </button>
              </template>
            </empty-state>
          </div>
          <template v-else>
            <div class="card-header">
              <search-Input
                :placeholder="$t('search_active_ivr')"
                @search="search"
              />
            </div>
            <div class="card-body p-0">
              <table class="table app-datatable m-0">
                <thead>
                  <tr>
                    <th class="fw-normal">
                      {{ $t("capitalizes.name") }}
                    </th>
                    <th />
                  </tr>
                </thead>

                <tbody>
                  <template v-if="loadingFlags.getList">
                    <loading-table-component
                      :columns="2"
                      :lines="15"
                      :list-width="[300, 20]"
                    />
                  </template>

                  <tr v-else-if="!list.length">
                    <td
                      class="text-center text-muted"
                      colspan="4"
                    >
                      <span class="fal fa-alert-octagon" /> {{ $t("no_active_ivr_found") }}
                    </td>
                  </tr>

                  <tr
                    v-for="item of list"
                    v-else
                    :key="item.id"
                    class="cursor-pointer"
                    @click="$router.push(`/manager/ivrs/${item.id}/edit`)"
                  >
                    <td>{{ item.name }}</td>
                    <td
                      class="text-end py-0"
                    >
                      <span
                        class="h4 far fa-chevron-right text-primary my-0"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              v-if="pagination.total_pages > 1"
              class="card-footer"
            >
              <div class="col-lg-12">
                <paginator
                  v-if="!loadingFlags.getList"
                  :pagination="pagination"
                  @change-page="changePage"
                />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import searchInput from "@components/search-input";
import paginator from "@components/app-paginator";
import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";
import _ from "lodash";
import HeaderComponent from "@/components/header-component.vue";
import EmptyState from "@components/empty-state.vue";
import CardTip from "@components/card-tip.vue";

export default {
  data() {
    return {
      term: "",
    };
  },

  mounted() {
    this.setFilters({
      search: "",
    });
    this.getList();
  },

  components: {
    CardTip,
    EmptyState,
    searchInput,
    paginator,
    LoadingTableComponent,
    HeaderComponent,
  },

  computed: {
    ...mapGetters("manager/ivrs", {
      list: "getList",
      loadingFlags: "getLoadingFlags",
      pagination: "getPagination",
    }),
  },

  methods: {
    ...mapActions("manager/ivrs", ["getList"]),
    ...mapMutations("manager/ivrs", ["setFilters", "setCurrentPage"]),

    changePage(page) {
      this.setCurrentPage(page);
      this.getList();
    },

    search: _.debounce(function (search) {
      this.setFilters({ search });
      this.setCurrentPage(1);
      this.getList();
      this.term = search
    }, 300),
  },
};
</script>

<style lang="scss">
tr:hover td span.h4.far.fa-chevron-right {
  visibility: visible;
}

td span.h4.far.fa-chevron-right {
  visibility: hidden;
}
</style>
