export default {
  startLoading: state => state.loading = true,
  stopLoading: state => state.loading = false,
  setErrors: (state, errors) => state.errors = errors,
  clearErrors: (state) => state.errors = {},
  setOfficeHours: (state, officeHours) => state.officeHours = officeHours,
  setPagination: (state, pagination) => state.pagination = pagination,
  setCurrentPage: (state, page) => state.pagination.current_page = page,
  setRegister: (state, register) => state.register = register,
  setFilters: (state, filters) => state.filters = filters,
  setInclude: (state, include) => state.include = include
}
