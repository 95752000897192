<template>
  <div class="col-12">
    <header-component
      :title="$t('integration_capitalize')"
      :pretitle="$t('settings_uppercase')"
    >
      <template #buttons>
        <button
          class="btn btn-primary px-6"
          @click="$router.push('/manager/integration/new')"
        >
          {{ $t('create') }} webhook
        </button>
      </template>
    </header-component>
    <integration-table-content />
  </div>
</template>

<script>
import HeaderComponent from "@/components/header-component.vue";
import IntegrationTableContent from "./components/integration-table-content.vue";

export default {
  components: {
    HeaderComponent,
    IntegrationTableContent
  },
};
</script>
