import { AgentStatus, CallStatus, ReceptiveStatus } from "@/utils.js";

/**
 * Count the amount of online agents inside an array.
 * @param {Array} agents
 * @returns The amount of online agents inside the received array.
 */
const totalAgentsOnline = agents => {
  let total = 0;

  for (const agent of agents) {
    if (agent.status !== AgentStatus.OFFLINE) total++;
  }

  return total;
};

/**
 * Update the agent status based on a new status
 * passed by params.
 *
 * @param {Array} group
 * @param {Number} newStatus
 * @param {Number} agentId
 * @returns {void}
 */
 const changeAgentStatus = (group, newStatus, agentId) => {
  const agent = group.agents.agents.find(a => a.id === agentId);
  if (!agent) return;

  group.agents.status_count[agent.status]--;
  group.agents.status_count[newStatus]++;

  agent.status = newStatus;

  group.agents.status_count[AgentStatus.ONLINE] = totalAgentsOnline(group.agents.agents);

}

export default {
  setRingGroup: (state, ringGroup) => state.ringGroup = ringGroup,
  startLoadingFlag: (state, flag) => state.loadingFlags[flag] = true,
  stopLoadingFlag: (state, flag) => state.loadingFlags[flag] = false,
  startLoadingContext: (state, loadingContext) => state.loadingContext[loadingContext] = true,
  stopLoadingContext: (state, loadingContext) => state.loadingContext[loadingContext] = false,
  setErrors: (state, errors) => (state.errors = errors),
  clearErrors: state => state.errors = {},
  setList: (state, list) => (state.list = list),

  setPagination: (state, pagination) => (state.pagination = pagination),

  setCurrentPage: (state, page) => state.pagination.current_page = page,

  setRegister: (state, register) => (state.register = register),

  setFilters: (state, filters) => (state.filters = filters),

  setDataSelect: (state, dataSelect) => (state.dataSelect = dataSelect),
  setConsultMetrics: (state, consultMetrics) =>
    (state.consultMetrics = consultMetrics),
  setQualificationsMetrics: (state, qualificationMetrics) =>
    (state.qualificationMetrics = qualificationMetrics),
  clearGroups: state => state.ringGroups = [],

  /**
   * Fill an array with an object that contains information
   * about real time and static data from a ring group.
   *
   * @param {Object} state
   * @param {Array} groups
   */
  setRingGroups: (state, groups) => {
    state.ringGroups = groups
  },

  /**
   * Fill an array with information about
   * all the ring group calls happening at
   * the moment.
   *
   * @param {Object} state
   * @param {Array} calls
   */
  setCalls(state, calls) {
    let totalHold = 0;
    let totalActiveCalls = 0;

    for (let index in state.groups) {
      const group= state.groups[index];
      let hold = 0;
      let active_calls = 0;
      if (Object.prototype.hasOwnProperty.call(calls, group.id)) {
        let callsGroup = calls[group.id];
        for (let i in callsGroup) {
          let call = callsGroup[i];
          if (call.group_id !== undefined) {
            if (call.agent !== undefined) {
              active_calls++;
              totalActiveCalls++;
            } else {
              hold++;
              totalHold++;
            }
          }
        }
      }

      state.groups[index].hold = hold;
      state.groups[index].active_calls = active_calls;
    }

    if (totalActiveCalls + totalHold) {
      state.total.active_calls = totalActiveCalls;
      state.total.hold = totalHold;
    }
  },

  /**
   * Add in an Object all the metrics from all the ring group that belongs to the company.
   *
   * @param {Object} state
   * @param {Array} metrics
   */
  setMetrics(state, metrics) {
    state.metrics = metrics

    state.ringGroups.forEach((ringGroup) => {
      const metric = state.metrics.find(metric => metric.ring_group_id === ringGroup.id)
      if (metric){
        ringGroup.all_calls = metric.connected + metric.abandoned
      }
      else {
        ringGroup.all_calls = 0
      }
    })

  },

  /**
   * Add in an Object all the agent metrics from
   * all the ring groups that belongs to the
   * company.
   *
   * @param {Object} state
   * @param {Array} groups
   */
  setAgents(state, agents) {
    state.agents = agents
    // const getStatus = agent => {
    //   if (agent.manual_call_status) {
    //     let isConnectedManualOrAcwCall =
    //       agent.manual_call_status === AgentStatus.MANUAL_CALL_ACW_CONNECTED ||
    //       agent.manual_call_status === AgentStatus.MANUAL_CALL_CONNECTED;

    //     if (isConnectedManualOrAcwCall) {
    //       return AgentStatus.ON_CALL;
    //     }

    //     agent.status = agent.manual_call_status;
    //   }

    //   if (agent.status === AgentStatus.MANUAL_CALL_CONNECTED)
    //     return AgentStatus.ON_CALL;

    //   return agent.status;
    // };

    // const setStatus = group => {
    //   for (const agent of group.agents.agents) {
    //     agent.status = getStatus(agent);
    //     group.agents.status_count[agent.status]++;
    //   }
    // };

    // for (let group of groups) {
    //   let agents = group.agents;
    //   group = state.groups.find(q => q.id === group.id);
    //   if (!group) continue;

    //   group.agents.status_count[AgentStatus.ONLINE] = totalAgentsOnline(agents);
    //   group.agents.agents = agents;
    //   setStatus(group);

    //   for (const agent of agents) {
    //     if (state.total.agents.agents.find(a => a.id === agent.id)) {
    //       continue;
    //     }
    //     state.total.agents.agents.push(Object.assign({}, agent));
    //   }
    // }

    // let agents = state.total.agents.agents;
    // state.total.agents.status_count[AgentStatus.ONLINE] = totalAgentsOnline(
    //   agents
    // );
    // setStatus(state.total);
  },

  /**
   * Sort the ring groups table based on what
   * column the user clicks.
   *
   * @param {Object} state
   * @param {Object} sorting
   */
  // sortRingGroups(state, sorting) {
  //   state.groups.sort((a, b) => {
  //     if (sorting.column == "online_agents") {
  //       return a.agents.status_count[AgentStatus.ONLINE] < b.agents.status_count[AgentStatus.ONLINE] ?
  //         -1 : a.agents.status_count[AgentStatus.ONLINE] > b.agents.status_count[AgentStatus.ONLINE] ?
  //          1 : 0;
  //     }
  //     if (sorting.column === "received_calls") {
  //       return a["connected"] + a["abandoned"] < b["connected"] + b["abandoned"] ?
  //       -1 : a["connected"] + a["abandoned"] > b["connected"] + b["abandoned"] ?
  //        1 : 0;
  //     }
  //     return a[sorting.column] < b[sorting.column] ?
  //     -1 : a[sorting.column] > b[sorting.column] ?
  //     1: 0;
  //   });
  //   if (sorting.dir === "desc") state.groups.reverse();
  // },

  // Real time
  /**
   * Updates the call status that is
   * happening at the moment.
   *
   * @param {Object} state
   * @param {Object} payload
   */
  setStatusQueue (state, payload) {
    let group = state.groups.find(q => q.id === payload.groupId);

    switch (payload.status) {
      case ReceptiveStatus.IN_QUEUE:
        group.hold++;
        state.total.hold++;
        break;
      case ReceptiveStatus.CONNECTED:
        state.total.hold--;
        state.total.active_calls++;
        group.hold--;
        group.active_calls++;
        break;
      case ReceptiveStatus.ABANDONED:
        state.total.hold--;
        group.hold--;
        break;
      case ReceptiveStatus.ENDED:
        state.total.active_calls--;
        group.active_calls--;
        break;
    }
  },

  /**
   * Updates the agent status based on the
   * event received by socket
   *
   * @param {Object} state
   * @param {Object} payload
   */
    setAgentStatus(state, payload) {
      changeAgentStatus(state.total, payload.status, payload.id);

      for (const groupId of payload.groups) {
        let group = state.groups.find(q => q.id === groupId)
        if (!group) continue;

        changeAgentStatus(group, payload.status, payload.id);
      }
    },

  setActiveAgents: (state, activeAgents) => (state.activeAgents = activeAgents),
  setActiveCalls: (state, activeCalls) => {
    for (let item of activeCalls) {
      state.activeCalls[item.telephony_id] = item;
    }

    state.statusCallsCount[CallStatus.ANSWERED] = activeCalls.filter((el) => el.status == CallStatus.ANSWERED).length;
    state.statusCallsCount[CallStatus.CONNECTED] = activeCalls.filter((el) => el.status == CallStatus.CONNECTED).length;

  },
  setRingGroupMetrics: (state, ringGroupMetrics) => state.ringGroupMetrics = ringGroupMetrics,
  clearRingGroupData: (state) => {
    state.ringGroup = null;
    state.ringGroupMetrics = null;
    state.activeAgents = [];
    state.activeCalls = [];
    state.statusCallsCount = {};
  }
};
