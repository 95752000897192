<template>
  <form
    ref="billingForm"
    @submit.prevent="submit"
  >
    <div class="col-12 col-md-12">
      <label class="form-label fs-2 fw-bold px-1"> {{ $t('billing_data') }} </label>
      <div class="row px-1">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <div class="row">
              <div class="col">
                <label class="form-label"> {{ $t('first_name') }} </label>
                <input
                  v-model="fields.first_name"
                  ref="firstName"
                  type="text"
                  required
                  class="form-control"
                  data-test="firstNameBiling-input"
                  :class="{
                    'text-muted': placeholder,
                    'is-invalid': errors.first_name,
                  }"
                >
                <small
                  v-if="errors !== null && errors.first_name"
                  class="text-danger"
                >
                  {{ errors.first_name[0] }}
                </small>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <div class="row">
              <div class="col">
                <label class="form-label"> {{ $t('lastname') }} </label>
                <input
                  ref="lastName"
                  data-test="lastNameBiling-input"
                  v-model="fields.last_name"
                  type="text"
                  required
                  class="form-control"
                  :class="{
                    'text-muted': placeholder,
                    'is-invalid': errors.last_name,
                  }"
                >
                <small
                  v-if="errors.last_name"
                  class="text-danger"
                >
                  {{ errors.last_name[0] }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row px-1">
        <div class="col-12 col-md-12">
          <div class="form-group">
            <div class="row">
              <div class="col">
                <label class="form-label"> E-mail </label>
                <input
                  id="emailInput"
                  ref="email"
                  v-model="fields.email"
                  type="email"
                  required
                  class="form-control"
                  :class="[
                    errors.email ? 'is-invalid' : '',
                    { 'text-muted': placeholder },
                  ]"
                  data-test="emailBiling-input"
                >
                <small
                  v-if="errors.email"
                  class="text-danger"
                >
                  {{ errors.email[0] }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row px-1">
        <div class="col-12 col-md-12">
          <div class="form-group">
            <div class="row">
              <div class="col">
                <label class="form-label"> CPF/CNPJ </label>
                <input
                  v-model="fields.user_document"
                  ref="userDocument"
                  v-maska
                  required
                  data-maska="[
                    '###.###.###-##', 
                    '##.###.###/####-##'
                  ]"
                  data-test="cpfBiling-input"
                  type="text"
                  class="form-control"
                  :class="{
                    'text-muted': placeholder,
                    'is-invalid': errors.user_document,
                  }"
                >
                <small
                  v-if="errors.user_document"
                  class="text-danger"
                >
                  {{ errors.user_document[0] }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row px-1">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <div class="row">
              <div class="col">
                <label class="form-label"> {{ $t('zip_code') }} </label>
                <div class="input-group input-group-merge input-group mb-3">
                  <input
                    required
                    ref="zipCode"
                    v-model="fields.zip_code"
                    v-maska
                    data-maska="['#####-###']"
                    data-test="zipCodeBiling-input"
                    @blur="getCEPData()"
                    type="text"
                    class="form-control"
                    :class="{
                      'text-muted': placeholder,
                      'is-invalid': errors.zip_code || cepNotFound,
                    }"
                  >
                  <div
                    class="input-group-text"
                    id="inputGroupReverse"
                  >
                    <div
                      v-if="loadingCep"
                      class="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
                <small
                  v-if="errors.zip_code"
                  class="text-danger"
                >
                  {{ errors.zip_code[0] }}
                </small>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <div class="row">
              <div class="col">
                <label class="form-label"> {{ $t('district') }} </label>
                <input
                  disabled
                  required
                  ref="neighborhood"
                  v-model="fields.neighborhood"
                  data-test="NeighborhoodBiling-input"
                  type="text"
                  class="form-control"
                  :class="{
                    'text-muted': placeholder,
                    'is-invalid': errors.neighborhood,
                  }"
                >
                <small
                  v-if="errors.neighborhood"
                  class="text-danger"
                >
                  {{ errors.neighborhood[0] }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row px-1">
        <div class="col-12 col-md-8">
          <div class="form-group">
            <div class="row">
              <div class="col">
                <label class="form-label"> {{ $t('street') }} </label>
                <input
                  disabled
                  required
                  ref="streetName"
                  v-model="fields.street_name"
                  data-test="streetNameBiling-input"
                  type="text"
                  class="form-control"
                  :class="{
                    'text-muted': placeholder,
                    'is-invalid': errors.street_name,
                  }"
                >
                <small
                  v-if="errors.street_name"
                  class="text-danger"
                >
                  {{ $t('street_required') }}
                </small>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="form-group">
            <div class="row">
              <div class="col">
                <label class="form-label"> Nº </label>
                <input
                  ref="streetNumber"
                  v-model="fields.street_number"
                  data-test="streetNumberBiling-input"
                  type="text"
                  class="form-control"
                  :class="{
                    'text-muted': placeholder,
                    'is-invalid': errors.street_number,
                  }"
                >
                <small
                  v-if="errors.street_number"
                  class="text-danger"
                >
                  {{ errors.street_number[0] }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <amount-credits
      v-model="transactionAmount"
      :initial-value="300"
      :amount-values="amountValues"
      payment-type="billet"
    />
    <div class="modal-footer d-flex flex-column justify-content-center">
      <button
        type="submit"
        class="btn btn-primary"
        data-test="byCredits-button"
      >
        <i class="fe fe-plus" />
        {{ $t('buy_credit') }}
      </button>
    </div>
  </form>
</template>

<script>
import amountCredits from './amount-credits.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: { amountCredits },
  emits: ['update-billing-form', 'show-billet-billing'],
  data() {
    return {
      placeholder: false,
      fields: {
        city: "",
        email: "",
        federal_unit: "",
        first_name: "",
        last_name: "",
        neighborhood: "",
        street_name: "",
        street_number: "",
        type: "",
        user_document: "",
        zip_code: "",
        other: false,
      },
      transactionAmount: null,
      amountValues: [
        { value: "300", label: "R$ 300,00" },
        { value: "500", label: "R$ 500,00" },
        { value: "1000", label: "R$ 1000,00" },
        { value: "outro", label: this.$t('other') },
      ],
      loadingCep: false,
      cepNotFound: false
    };
  },
  watch: {
    fields: {
      handler(value) {
        this.$emit('update-billing-form', value)
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("manager/user_profile", {
      errors: "getErrors"
    })
  },
  methods: {
    ...mapActions("manager/user_profile", [
      "postTransactionTicket",
      "postCompanyData"
    ]),

    updateAmount (amount) {
      this.transactionAmount = amount
    },
    getCEPData() {
      this.loadingCep = true
      fetch(`https://viacep.com.br/ws/${this.fields.zip_code}/json/`)
        .then((response) => response.json()).finally(() => { this.loadingCep = true })
        .then((json) => {
          if (!json.erro) {
            (this.fields.city = json.localidade),
            (this.fields.federal_unit = json.uf),
            (this.fields.neighborhood = json.bairro),
            (this.fields.street_name = json.logradouro);
          }
          this.cepNotFound = json.erro
          this.loadingCep = false
        });
    },
    submit () {    
      const dataToSend = {
        city: this.fields.city,
        email: this.fields.email,
        federal_unit: this.fields.federal_unit,
        first_name: this.fields.first_name,
        last_name: this.fields.last_name,
        neighborhood: this.fields.neighborhood,
        street_name: this.fields.street_name,
        street_number: this.fields.street_number,
        type:
          this.formatDocument(this.fields.user_document).length == 11
            ? "CPF"
            : "CNPJ",
          user_document: this.formatDocument(this.fields.user_document),
          zip_code: this.formatZipCode(this.fields.zip_code),
      }
      this.postCompanyData({ fields: dataToSend }).then(() => {
        this.postTransactionTicket({
            transaction_amount: this.transactionAmount === 'outro' ? this.otherAmount.replace(',', '.') : this.transactionAmount
          }).then(() => {
            this.$emit('show-billet-billing');
          });
        })
    },
    formatDocument(el) {
      return el
        .toString()
        .replace("-", "")
        .replace(".", "")
        .replace(".", "")
        .replace("/", "");
    },
    formatZipCode(el) {
      return el.toString().replace("-", "").replace(".", "");
    },
  }
};
</script>

