<template>
  <div class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h4 class="m-0">
        {{ $t('list_setting') }}
      </h4>
      <div class="d-flex gap-4">
        <div class="d-flex gap-2">
          <p class="m-0">
            {{ $t('selected_required_fields') }}:
          </p>
          <strong>{{ selectedRequiredInputs }}/{{ totalRequiredInputs }}</strong>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-borderless card-table">
        <thead>
          <tr>
            <th>
              {{ $t('column') }}
            </th>
            <th>
              {{ $t('column_type') }}
            </th>
            <th
              v-for="index in preAdjustedCsv.data.slice(0,3).length"
              :key="index"
            >
              {{ $t('line') + " " + index }}
            </th>
          </tr>
        </thead>
        <tbody>
          <csv-validation-row-item
            v-for="index in preAdjustedCsv.header.length"
            :key="index"
            :index="index"
            :grouped-form-inputs="groupedFormInputs"
            @select="selectFieldHeader"
          />
        </tbody>
      </table>
    </div>
  </div>
  <div class="w-100 d-flex flex-column gap-3">
    <app-button
      v-if="allRequiredSelected"
      class="w-100"
      :loading="loadingFlags.createValidateCsv"
      @click="submit"
    >
      {{ $t('capitalizes.save') }}
    </app-button>
    <button
      v-else
      class="disabled-btn w-100 bg-gray-blue-300 tx-text-gray border-0"
      ref="tooltip"
    >
      {{ $t('validate_list') }}
    </button>
  </div>
</template>

<script>
import CsvValidationRowItem from "../csv-validation-row-item.vue";
import AppButton from "@components/app-button.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";

import {generateTooltip} from "@/utils";

export default {
  name: "CsvValidation",

  components: {
    CsvValidationRowItem,
    AppButton,
  },

  data () {
    return {
      labels: [],
      typeHeader:[]

    }
  },

  created() {
    this.fetchFormInputs(this.currentFunnel.id)
  },
  mounted() {
    this.generateTooltip(`${this.$t("select_all_required_fields")}`, this.$refs.tooltip);
  },

  computed: {
    ...mapGetters('crm/imports', {
      preAdjustedCsv: "getPreAdjustedCsv",
      errors: "getErrors",
      loadingFlags: "getLoadingFlags"
    }),
    ...mapGetters('crm/funnels', {
      currentFunnel: 'getCurrentFunnel'
    }),
    ...mapGetters('crm/opportunities', {
      formInputs: 'getFormInputs'
    }),

    requiredInputsIds() {
      return this.formInputs
          .filter(input => input.is_required)
          .map(input => input.id);
    },


    totalRequiredInputs() {
      return Object.values(this.groupedFormInputs).reduce((count, fields) => {
        return count + Object.values(fields).reduce((fieldCount, field) => {
          return fieldCount + (field.is_required ? 1 : 0);
        }, 0);
      }, 0);
    },

    selectedRequiredInputs() {
      const countedIds = [];

      return this.typeHeader.reduce((total, item) => {
        if (total >= this.totalRequiredInputs) {
          return total;
        }

        if (item.type === 'form_input_answer' && this.requiredInputsIds.includes(item.input_id)) {
          if (!countedIds.includes(item.input_id)) {
            countedIds.push(item.input_id);
            return total + 1;
          }
        }

        if (item.type === 'opportunity_name' && !countedIds.includes('opportunity_name')) {
          countedIds.push('opportunity_name');
          return total + 1;
        }

        if (item.type_contact === 'phone' && !countedIds.includes('phone')) {
          countedIds.push('phone');
          return total + 1;
        }

        return total;
      }, 0);
    },

    allRequiredSelected(){
      return this.selectedRequiredInputs >= this.totalRequiredInputs
    },


    groupedFormInputs () {
      const formInputs = {}
      formInputs.custom = this.formInputs
      formInputs.contacts = [
        {
          id: 1,
          type: 'contact',
          label: 'Nome do contato',
          type_contact: 'name',
          is_required: false
        },
        {
          id: 2,
          type: 'contact',
          label: this.$t('phone'),
          type_contact: 'phone',
          is_required: true
        },
        {
          id: 3,
          type: 'contact',
          label: 'E-mail',
          type_contact: "email",
          is_required: false
        }
      ]

      formInputs.opportunity = [
        {
          id: 1,
          type: 'opportunity_name',
          label: 'Nome da oportunidade',
          is_required: true
        },
      ]

      return formInputs
    },

    header () {
      return this.preAdjustedCsv.header.map(() => {
        return { is_active: true }
      })
    }
  },

  methods: {
    ...mapActions('crm/opportunities', [
      'fetchFormInputs'
    ]),
    ...mapActions('crm/imports', [
      'createValidateCsv',
      'clearCsvList'
    ]),
    ...mapMutations("crm/imports", ["setHeaderLabels"]),
    generateTooltip,

    selectFieldHeader ({field, isActive ,index}) {
      this.setLabel(field, index)
      this.setHeader(field, isActive ,index)
    },

    setLabel(field, index) {
      field? this.labels[index -1] = field.label : this.labels.splice(index, 1)
    },

    setHeader(field, isActive, index) {
      if(!field) {
        this.labels.splice(index, 1)
        this.typeHeader[index - 1] = { is_active: isActive }
        this.header[index - 1] = { is_active: isActive }
        return
      }

      let item = {}

      if(['phone', 'email', 'name'].includes(field.type_contact)) {
        item = {
          type: 'contact',
          type_contact: field.type_contact,
          is_active: isActive
        }
      } else if (field.type === 'opportunity_name') {
        item = {
          type: 'opportunity_name',
          is_active: isActive
        }
      } else {
        item = {
          type: 'form_input_answer',
          input_id: field.id,
          is_active: isActive
        }
      }

      this.typeHeader[index - 1] = item
      this.header[index - 1] = item
    },

    deactivateRows(){
      this.header.forEach(field => {
        const keys = Object.keys(field);
        if (keys.length === 1) {
          field.is_active = false
        }
      })
    },

    submit () {
      this.deactivateRows()
      this.createValidateCsv({
        formData: {
          separator: ',',
          delimiter: 'quotes',
          has_header: true,
          file_name: this.preAdjustedCsv.file_name,
          name: this.preAdjustedCsv.original_name,
          header: this.header,
          funnel_id: this.currentFunnel.id,
        }
      })
      this.setHeaderLabels(this.labels)
    },
  },

}
</script>
<style scoped>
.disabled-btn{
  opacity: 0.4;
  height: 40px;
  border-radius: 10px;
  cursor: default;
}
</style>