<template>
  <div class="main-content pb-6">
    <div class="d-flex justify-content-center mt-4">
      <div class="col-12 align-self-center">
        <div v-if="!showFeedback">
          <h1 class="mt-4 mb-3 fs-1">
            Criando uma conta
          </h1>
        </div>
        <div v-else>
          <button
            class="btn btn-rounded-circle btn-white float-start me-3"
            @click="showFeedback = false"
            data-test="newAccountGoBack-button"
          >
            <span class="fe fe-arrow-left" />
          </button>
          <div class="col-auto">
            <h6 class="header-pretitle text-uppercase">
              Criando uma conta
            </h6>
            <h1 class="header-title">
              Conta criada
            </h1>
          </div>
        </div>
        <hr class="my-4">
        <new-company-form 
          v-if="!showFeedback" 
        />
        <new-company-feedback 
          v-else
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import NewCompanyFeedback from './new-company-feedback.vue';
import NewCompanyForm from './new-company-form.vue';

export default {
  data() {
    return {
      showFeedback: false
    };
  },

  computed: {
    ...mapGetters("admin/companies", {
      newCompanyDataFeedback: "getNewCompanyDataFeedback"
    }),
  },

  watch: {
    newCompanyDataFeedback(newCompanyDataFeedback) {
      if(newCompanyDataFeedback) this.showFeedback = true
    }
  },

  components: { 
    NewCompanyFeedback,
    NewCompanyForm 
  },
};
</script>
