<script>
import AppButton from "@components/app-button.vue";
// import SingleSelect from "@components/select/single-select.vue";
import DateRangeBtn from "@crm/pages/dashboard/components/date-range-btn.vue";
import UsersTab from "@whatsapp/components/transfer-form/users-tab.vue";

export default {
  name: "FiltersBar",
  components: {
    AppButton,
    // SingleSelect,
    DateRangeBtn,
    UsersTab
  },

  data() {
    return {
      filtersButtons: [
        {
          label: this.$t("capitalizes.today"),
          value: 'days'
        },
        {
          label: this.$t("capitalizes.week"),
          value: 'week'
        },
        {
          label: this.$t("capitalizes.month"),
          value: 'month'
        },
        {
          label: this.$t("capitalizes.quarter"),
          value: 'quarter'
        },
        {
          label: this.$t("capitalizes.semester"),
          value: 'semester'
        },
        {
          label: this.$t("capitalizes.year"),
          value: 'year'
        },
        {
          label: this.$t("capitalizes.custom"),
          value: 'custom'
        },
      ]
    }
  },
}
</script>

<script setup>
import {ref, defineProps, defineEmits, computed, onMounted} from "vue";
import store from "@store";

defineProps({
  activeDateRange: {
    type: String,
    default: ""
  },
  loading: {
    type: Boolean,
    default: false
  },
  agents: {
    type: Array,
    default: () => []
  }
});

const startDate = ref('');
const endDate = ref('')

const emit = defineEmits([
  'filter-change',
  'update-role',
  'search',
  'agent-change'
]);

const currentFunnel = computed(() => store.getters["crm/funnels/getCurrentFunnel"])
// const agents = computed(() => store.getters["crm/contacts/getTeamAgents"]);
const dateRange = computed(() => store.getters["crm/dashboard/getDateRange"])

onMounted(() => {
  store.dispatch("crm/contacts/fetchUsersByTeam", {
    teamId: currentFunnel.value.team.id,
  });
});

const activeFiltersModel = ref();
const selectedUser = ref(null)


function
setCustomDate(range) {
  emit('filter-change', {
    startDate: startDate.value,
    endDate: endDate.value,
    range: range
  });
}

function setDateRange(range) {
  if (dateRange.value.value !== range) {
    emit('filter-change', range)
  }
}

// function setAgent() {
//   if (agents.value !== activeFiltersModel.value) {
//     emit('agent-change', activeFiltersModel)
//   }
// }

// function selectItem(item) {
//   console.log(item)
// }

function search(query) {
  emit('search', query)
}

function role(role) {
  emit('update-role', role)
}


function selectItem(item) {
  if (selectedUser.value !== item) {
    selectedUser.value = item;
    emit('agent-change', item?.id || null)
  }
}
</script>

<template>
  <div class="d-flex gap-4 justify-content-between align-items-center my-4">
    <div
      style="gap: 0.5rem"
      class="d-flex flex-wrap"
    >
      <div
        v-for="(filter, fIndex) in filtersButtons"
        :key="`filter-${fIndex}`"
        class="w-auto"
      >
        <date-range-btn
          v-if="filter.value === 'custom'"
          @close="setCustomDate(filter.value)"
          :placeholder="filter.label"
          :custom-class="activeDateRange !== filter.value ? 'btn-gray-blue' : 'btn-primary'"
          v-model:start="startDate"
          v-model:end="endDate"
        />

        <app-button
          v-else
          style="padding-left: 1rem; padding-right: 1rem"
          class="btn-sm"
          :color="activeDateRange !== filter.value ? 'btn-gray-blue' : 'btn-primary'"
          @click="setDateRange(filter.value)"
        >
          {{ filter.label }}
        </app-button>
      </div>
    </div>

    <div style="min-width: 400px;">
      <div class="dropdown">
        <button
          :ref="`dropdownBtn-crm-dashboard`"
          class="btn btn-dropdown dropdown-toggle cursor-pointer d-flex bg-white justify-content-between align-items-center gap-3 w-100"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          data-bs-auto-close="outside"
        >
          <span class="tx-text-gray d-inline-block min-width-152">
            {{ selectedUser?.name || $t('select') }}
          </span>
        </button>
        <ul
          :style="`min-width: ${400}px; padding: 1.5rem;`"
          class="dropdown-menu"
        >
          <li>
            <users-tab
              id="crm-dashboard"
              return-all-object
              :key="activeFiltersModel"
              :current-user-id="-1"
              :users="agents"
              label="name"
              @role="role"
              @select-item="selectItem"
              @search="search"
              :loading="loading"
            />
          </li>
        </ul>
      </div>
      <!--      <single-select-->
      <!--        select-height="32px"-->
      <!--        fontsize="14px"-->
      <!--        padding="5px 16px"-->
      <!--        :placeholder="$t('select')"-->
      <!--        :list-data="agents"-->
      <!--        label="name"-->
      <!--        custom-label-return="id"-->
      <!--        v-model="activeFiltersModel"-->
      <!--        @update:model-value="setAgent"-->
      <!--      />-->
    </div>
  </div>
</template>

<style scoped lang="scss">
.btn-sm {
  border: none;

  &:focus {
    box-shadow: none;
  }
}

.btn-dropdown {
  padding: 5px 16px;
  font-size: 14px;
  border: 1px solid #e1e9f4;
}
</style>