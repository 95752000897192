<template>
  <span
    @click="copy"
    class="far fa-copy cursor-pointer ms-2"
    :class="{
      'tx-text-gray': !copied,
      'tx-green-300': copied
    }"
  />
</template>

<script>
export default {
  name: "IconCopyText",

  props: {
    text: {
      type: String,
      default: "",
    }
  },

  data() {
    return {
      copied: false,
    }
  },

  methods: {
    copy(){
      navigator.clipboard.writeText(this.text)
      this.copied = true
    },
  }
}
</script>
