<template>
  <div class="main-content">
    <div class="container-fluid">
      <header-component title="Sistema" />
      <div class="tab-pane fade show active">
        <div class="col-lg-12">
          <div class="card">
            <div
              class="card-body py-2 cursor-pointer border-end"
              @mouseover="isCardHovered()"
              @mouseleave="isCardHovered(false)"
              @click="$refs.editLimitCallPerAgent.openModal()"
              :style="hover_item ? 'color: #2C7BE5 !important' : ''"
            >
              <div
                style="border-radius: 7px;"
                :style="hover_item ? 'background-color: #d5e5fa;' : ''"
                class="d-flex justify-content-around py-2"
              >
                <div>
                  <span 
                    class="me-4"
                    :class="hover_item ? '' : 'text-muted'"
                  >
                    Limite de chamadas por agente
                  </span>
                  <span class="mb-0 mt-1 fw-normal mx-2">
                    {{ limit_call_per_agent }}
                  </span>
                  <span
                    class="fe fe-edit-2"
                    :class="hover_item ? '' : 'text-muted'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <edit-system-calls-limit-modal
      ref="editLimitCallPerAgent"
      v-model="limit_call_per_agent"
    />
  </div>
</template>

<script>
import HeaderComponent from "@/components/header-component.vue";
import EditSystemCallsLimitModal from "./edit-system-calls-limit-modal.vue";
import { mapActions } from 'vuex';

export default {
    data() {
        return {
          limit_call_per_agent: 0,
          hover_item: false
        }
    },
    mounted() {
      this.getSystemLimitCallPerAgent().then((limit) => {
        this.limit_call_per_agent = limit;
      });
    },
    methods: {
      ...mapActions("admin/system", ["getSystemLimitCallPerAgent"]),
      isCardHovered(value = true) {
        this.hover_item = value;
      },
    },
    components: {
        HeaderComponent,
        EditSystemCallsLimitModal
    },
}
</script>