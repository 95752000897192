<template>
  <div
    class="offcanvas-body d-flex flex-column gap-1rem"
  >
    <action-component
      icon-class="fa-regular fa-message-question"
      icon-color="#5CB868"
      :name="$t('make_a_question')"
    />

    <div class="col-auto">
      <label
        class="form-label"
        for="message"
      >{{ $t('message_text') }}</label>
      <textarea-with-count
        id="message"
        v-model="textContent"
        :is-invalid="!textContent"
        :placeholder="$t('what_is_your_question')"
        :max-length="255"
        :max-height="264"
      />
    </div>
    <div class="mt-auto" />
    <app-button
      :disabled="!textContent || loadingFlags.updateAction"
      @click="editAction"
    >
      {{ $t('capitalizes.save') }}
    </app-button>
    <app-button
      color="text-danger"
      @click="_deleteAction"
    >
      <span class="fa-regular fa-trash" />
      {{ $t('capitalizes.exclude') }}
    </app-button>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

import ActionComponent from "@chatbot/pages/workflow/components/action-component.vue";
import AppButton from "@components/app-button.vue";
import TextareaWithCount from "@components/textarea-with-count.vue";

export default {
  name: "EditQuestion",

  components: {
    TextareaWithCount,
    AppButton,
    ActionComponent
  },

  data() {
    return {
      textContent: ""
    }
  },

  mounted() {
    this.textContent = this.actionToEdit.text_content
  },

  computed: {
    ...mapGetters("chatbot/actions", {
      actionToEdit: "getActionToEdit",
      loadingFlags: "getLoadingFlags",
    })
  },

  methods: {
    ...mapActions("chatbot/chatbots", ["fetchChatbot"]),
    ...mapActions("chatbot/actions", [
      "updateAction",
      "deleteAction"
    ]),
    ...mapMutations("chatbot/actions", [
      "setOffcanvasEditActionIsOpen",
      "setActionToEdit"
    ]),

    editAction() {
      this.updateAction({
        actionId: this.actionToEdit.id,
        payload: {
          text_content: this.textContent
        }
      }).then(() => {
        this.setOffcanvasEditActionIsOpen(false)
      })
    },

    _deleteAction() {
      this.deleteAction(this.actionToEdit.id).then(() => {
        this.setOffcanvasEditActionIsOpen(false)
        this.fetchChatbot(this.$route.params.id)
      })
    }
  },
  watch: {
    actionToEdit(value){
      this.textContent = value.text_content
    }
  }
}
</script>
<style scoped>
.gap-1rem {
  gap: 1rem;
}
</style>