export default {
    isLoading: state => state.loading,
    getLoadingFlags: state => state.loadingFlags,
    getErrors: state => state.errors,
    getList: state => state.list,
    getAlert: state => state.alert,
    getFilters: (state) => state.filters,
    getRegister: (state) => state.register,
    getPagination: (state) => state.pagination,
    getDataSelect: state => state.dataSelect,
    getParams: (state) =>
    Object.assign(
      {
        'page': state.pagination.current_page,
        'per_page': state.pagination.per_page
      }
      , state.filters, { include: state.include.join(',') }),
}
