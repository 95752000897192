import axios from "axios";
import moment from "moment"

export default {

  /**
   *
   * @param {*} commit
   * @param {*} payload
   * @returns
   */
  findCallsStatusCountPerTime({commit}, payload) {
    commit('startLoadingFlags', 'findCallsStatusCountPerTime');
    commit('setCallsStatusCountPerTime', {
      campaign_id: payload.campaign_id,
      data: []
    })
    let params = {
      startDate: payload.startDate,
      endDate: payload.endDate,
      interval: payload.interval,
      'trashed[0]': 'campaign'
    }
    return new Promise((resolve, reject) => {
      axios.get('/campaigns/' + payload.campaign_id + '/statistics', {params})
      .then((response) => {
        commit('setCallsStatusCountPerTime', {
          campaign_id: payload.campaign_id,
          data: response.data.data
        })
        resolve();
      }).catch(() => {
        reject();
      })
      .finally(() => {
        commit('stopLoadingFlags', 'findCallsStatusCountPerTime')
      });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  findTotalCallsFromLastDayPerCampaign({commit}, payload) {
    commit('startLoadingFlags', 'findTotalCallsFromLastDayPerCampaign');
    let params = {
      startDate: moment().format("YYYY-MM-DD") + " 00:00:00",
      endDate: moment().format("YYYY-MM-DD") + " 23:59:59",
      interval: 1440
    }
    return new Promise((resolve, reject) => {
      axios.get('/campaigns/' + payload.id + '/statistics', {params})
      .then((response) => {

        if (response.data.data[0])
          commit('setTotalCallsFromLastDayPerCampaign',
            {
              campaign_id: payload.id,
              total: response.data.data[0].total
            }
          );
        else
          commit('setTotalCallsFromLastDayPerCampaign',
            {
              campaign_id: payload.id,
              total: 0
            }
          );
        resolve();
      }).catch(() => {
        reject();
      })
      .finally(() => {
        commit('stopLoadingFlags', 'findTotalCallsFromLastDayPerCampaign')
      });
    });
  },


  /**
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  findCallsReports({commit, getters}) {

    commit('startLoadingFlags', 'findCallsReports');

    return new Promise((resolve, reject) => {
      axios.get('/calls', {params: getters.getParams})
      .then((response) => {
        commit('setCallsReport', response.data.data);
        commit('setPagination', response.data.meta.pagination);
        resolve();
      })
      .catch(() => {
        reject();
      })
      .finally(() => {
        commit('stopLoadingFlags', 'findCallsReports')
      });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */

  findFeedbackByCallId({commit}, payload) {

    commit('startLoadingFlags', 'findFeedbackByCallId');

    return new Promise((resolve, reject) => {
      axios.get('/feedbacks/' + payload.campaign_id + '/' + payload.call_history_id)
      .then((response) => {


        commit('setFeedbackRegister', response.data.data);
        resolve();
      }).catch(() => {
        reject();
      })
      .finally(() => {
        commit('stopLoadingFlags', 'findFeedbackByCallId')
      });
    });
  },

  setFeedbackRegister({commit}, feedbackRegister) {
    commit("setFeedbackRegister", feedbackRegister);
  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  applyFeedback({commit}, payload) {

    commit('startLoadingFlags', 'applyFeedback');

    return new Promise((resolve, reject) => {
      axios.post('/feedbacks', payload.fields)
      .then((response) => {
        commit("setFeedbackInCallsReport", {
          callsReportsId: response.data.data.call_history_id,
          feedbackId: response.data.data.id
        })
        resolve();
      })
      .catch((error) => {
        commit('setErrors', error.response.data.errors);
        reject();
      })
      .finally(() => {
        commit('stopLoadingFlags', 'applyFeedback')
      });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   */
  updateFeedback({commit}, payload) {
    commit('startLoadingFlags', 'updateFeedback');

    return new Promise((resolve, reject) => {
      axios.put('/feedbacks', payload.fields)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        commit('setErrors', error.response.data.errors);
        reject();
      }).finally(() => {
        commit('stopLoadingFlags', 'updateFeedback')
      });
    });
  },

  /**
   *
   * @param {*} param0
   * @returns
   */
  loadHangupCausesDataSelect({commit}) {
    commit('startLoadingFlags', 'loadHangupCausesDataSelect')
    commit('setHangupCausesDataSelect', []);
    return new Promise((resolve, reject) => {
      axios.get('/hangup_causes')
      .then((response) => {
        commit('setHangupCausesDataSelect', response.data.data);
        resolve();
      }).catch(() => {
        reject();
      }).finally(() => {
        commit('stopLoadingFlags', 'loadHangupCausesDataSelect')
      });
    });
  },


  /**
   *
   * @param {*} param0
   * @returns
   */
  loadListDataSelect({commit}, payload) {
    commit('startLoadingFlags', 'loadListDataSelect')
    commit('setListDataSelect', []);
    return new Promise((resolve, reject) => {
      axios.get('/campaigns/' + payload.campaign_id + '/lists', {params: payload.fields})
      .then((response) => {
        commit('setListDataSelect', response.data.data);
        resolve();
      }).catch(() => {
        reject();
      }).finally(() => {
        commit('stopLoadingFlags', 'loadListDataSelect')
      });
    });
  },

  /**
   *
   * @param {*} param0
   * @returns
   */
  loadRoutesDataSelect({commit}) {
    commit('startLoadingFlags', 'loadRoutesDataSelect')
    commit('setRoutesDataSelect', []);
    return new Promise((resolve, reject) => {
      axios.get('/filters', {params: {include: 'routes'}})
      .then((response) => {
        commit('setRoutesDataSelect', response.data.data.routes);
        resolve();
      }).catch(() => {
        reject();
      }).finally(() => {
        commit('stopLoadingFlags', 'loadRoutesDataSelect')
      });
    });
  },

  /**
   *
   * @param {*} param0
   * @returns
   */
  loadStatusesDataSelect({commit}) {
    commit('startLoadingFlags', 'loadStatusesDataSelect')
    commit('setStatusesSelect', []);
    return new Promise((resolve, reject) => {
      axios.get('/filters', {params: {include: 'statuses'}})
      .then((response) => {
        commit('setStatusesSelect', response.data.data.statuses);
        resolve();
      }).catch(() => {
        reject();
      }).finally(() => {
        commit('stopLoadingFlags', 'loadStatusesDataSelect')
      });
    });
  },
  downloadTable({commit, getters}) {
    commit("clearErrors");
    commit("startLoadingFlags", "downloadTable");

    return new Promise((resolve, reject) => {
      axios
      .get(`/calls/csv`, {
        params: getters.getDownloadTableParams
      })
      .then(() => {
        commit("system/setSuccessMessage", "success_message.generate_report", {root: true});
        resolve();
      })
      .catch(response => {
        if (response.response.status == 422) {
          const responseErrors = response.response.data.errors
          responseErrors &&
          commit("setErrorFlag", {errors: responseErrors, errorFlag: "downloadTable"});
          commit("system/setErrorMessage", response.response.data, {root: true});
        } else {
          const errorMessage = "Houve um problema ao gerar o relatório. Verifique a campanha e tente novamente mais tarde"
          commit("system/setErrorMessage", errorMessage, {root: true});
        }
        reject();
      })
      .finally(() => {
        commit("stopLoadingFlags", "downloadTable");
      });
    });
  },

  getCallsTotal({commit, getters}) {
    commit("clearErrors");
    commit("startLoadingFlags", "getCallsTotal");
    commit("setTotalCallsData", []);

    return new Promise((resolve, reject) => {
      axios.get('/calls/total', {params: getters.getTotalParams})
      .then((response) => {
        commit("setTotalCallsData", response.data);
        resolve();
      }).catch(() => {
        reject();
      })
      .finally(() => {
        commit('stopLoadingFlags', "getCallsTotal")
      });
    });
  },

  loadCallById({commit}, payload) {
    commit("startLoadingFlags", "loadCallById");
    commit("setLoadedCall", null);

    return new Promise((resolve, reject) => {
      axios.get('/calls/' + payload.id)
      .then((response) => {
        commit("setLoadedCall", response.data.data);
        resolve();
      }).catch(() => {
        reject();
      })
      .finally(() => {
        commit('stopLoadingFlags', "loadCallById");
      });
    });
  },

  fetchAudioBlob({commit}, url) {
    commit("startLoadingFlags", "fetchAudioBlob");

    return new Promise((resolve) => {
      fetch(url)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => {
        const audioBlob = new Blob([arrayBuffer], {type: "audio/mp3"});
        const blobURI = window.URL.createObjectURL(audioBlob);
        resolve(blobURI);
      })
      .finally(() => commit("stopLoadingFlags", "fetchAudioBlob"));
    })
  },

  setDownloadTableParams({commit}, payload) {
    commit("setDownloadTableParams", payload)
  },
  clearErrors({commit}) {
    commit("clearErrors", {});
  }
}
