<template>
  <div>
    <div
      id="attachmentCollapse"
      ref="attachmentCollapse"
      class="collapse pt-0 "
    >
      <attachment-field-list
        v-model="attachmentList"
        @close="closeAttachCollapse"
      />
    </div>
    <div
      id="internalNoteCollapse"
      ref="internalNoteCollapse"
      class="collapse pt-0 "
    >
      <note-field-list
        v-model="noteList"
        @close="closeNotesCollapse"
      />
    </div>
    <div
      id="linkCollapse"
      ref="linkCollapse"
      class="collapse pt-0 "
    >
      <link-field-list
        v-model="linkList"
        @close="closeLinkCollapse"
      />
    </div>
  </div>
</template>
<script>
import {defineComponent} from 'vue'
import {Collapse} from "bootstrap";
import LinkFieldList from "@crm/components/activity/activity-fields/link-field-list.vue";
import NoteFieldList from "@crm/components/activity/activity-fields/note-field-list.vue";
import AttachmentFieldList from "@crm/components/activity/activity-fields/attachment-field-list.vue";
import moment from 'moment';

export default defineComponent({
  name: "FooterAttachments",
  emits: ['submitActivity'],
  components: { AttachmentFieldList, NoteFieldList, LinkFieldList},


  data() {
    return {
      attachesCollapse: null,
      notesCollapse: null,
      linksCollapse: null,
      is_completed: false,
      shouldShowIsCompleted: true,

      attachmentList: [],
      noteList: [],
      linkList: []
    }
  },

  props: {
    open:{
      type:String,
      default: null
    }

  },

  mounted() {
    this.attachesCollapse = new Collapse(this.$refs.attachmentCollapse, {toggle: false})
    this.notesCollapse = new Collapse(this.$refs.internalNoteCollapse, {toggle: false})
    this.linksCollapse = new Collapse(this.$refs.linkCollapse, {toggle: false})
  },

  methods: {
    submit() {
      this.$emit('submitActivity', {
            is_completed: this.is_completed,
            attachments: this.filledAttachments
          }
      )
    },

    closeAttachCollapse() {
      if (this.attachesCollapse) {
        this.attachesCollapse.hide()
      }
    },
    closeNotesCollapse() {
      if (this.notesCollapse) {
        this.notesCollapse.hide()
      }
    },
    closeLinkCollapse() {
      if (this.linksCollapse) {
        this.linksCollapse.hide()
      }
    },
  },

  computed: {
    filledAttachments() {
      return [
        ...this.attachmentList.filter((attachment) => attachment.content),
        ...this.noteList.filter((attachment) => attachment.content),
        ...this.linkList.filter((attachment) => attachment.content)
      ]
    },
  },

  watch: {
    dueDate(newVal) {
      const now = moment().startOf('day');
      const newDate = moment(newVal, "DD/MM/YYYY");
      this.shouldShowIsCompleted = newDate.isBefore(now) || newDate.isSame(now);
      this.is_completed = newDate.isBefore(now);
    },
    open(value){
      if(value){
        if(value==='link')
          this.linksCollapse.show()
        if(value==='attach')
          this.attachesCollapse.show()
        if (value ==='note')
          this.notesCollapse.show()
      }

    }
  },
})
</script>
<style lang="scss" scoped>

.form-check-input {
  &:not(:checked) {
    background-color: white;
    border-color: white;
  }

  &:checked {
    background-color: #3057F2
  }
}
.disabled{
  color: rgba(103, 124, 146, 0.40);
  opacity: 1;
}
.icon-buttons {
  &:hover {
    background-color: $color-gray-200 !important;
  }
}
</style>
