export default {
  startLoading: state => state.loading = true,
  stopLoading: state => state.loading = false,
  setErrors: (state, errors) => state.errors = errors,
  setCallsPerDay: (state, callsPerDay) => state.callsPerDay = callsPerDay,
  setTotalPerTime: (state, totalPerTime) => state.totalPerTime = totalPerTime,
  setCallsStatistics: (state, callsStatistics) => state.callsStatistics = callsStatistics,
  setStatistics: (state, statistics) => state.statistics = statistics,
  setDeliveredCalls: (state, deliveredCalls) => state.deliveredCalls = deliveredCalls,
  setQualificationStatistics: (state, qualificationStatistics) => state.qualificationStatistics = qualificationStatistics,
  setAgentMetrics: (state, agentMetrics) => state.agentMetrics = agentMetrics,
  clearAll: (state) => {
    state.totalPerTime = [];
    state.callsStatistics = [],
    state.qualificationStatistics = []
    state.deliveredCalls = []
  }
}
