<template>
  <span
    :style="{
      'display': 'inline-block',
      'background-color': color,
      'width': size,
      'height': size,
      'border-radius': '50%',
    }"
  />
</template>

<script>
export default {
  props:{
    size: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
  }
}
</script>
