<template>
  <crm-page-loading v-if="loadingFlags.fetchFunnels" />
  <div
    v-if="currentFunnel"
    class="main-content"
  >
    <div class="container-fluid">
      <header-component
        :pretitle="currentFunnel.name"
        :title="$t('general_config')"
        :redirect-link="`/manager/crm/${currentFunnel.id}`"
      >
        <template #buttons>
          <router-link
            :to="`/manager/crm/${currentFunnel.id}`"
            class="btn btn-outline"
            style="width: 112px; margin-right: 2rem"
          >
            {{ $t('cancel_general') }}
          </router-link>
          <app-button
            class="btn-primary"
            style="width: 260px"
            @click="submit"
          >
            {{ $t('save_and_exit') }}
          </app-button>
        </template>
      </header-component>

      <ul
        id="myTab"
        class="nav nav-tabs"
        role="tablist"
      >
        <li
          class="nav-item"
          role="presentation"
        >
          <button
            id="general-tab"
            aria-controls="general-tab-pane"
            :aria-selected="currentTab === ''"
            class="nav-link"
            :class="tabClass('')"
            data-bs-target="#general-tab-pane"
            data-bs-toggle="tab"
            role="tab"
            type="button"
          >
            {{ $t('general_config') }}
          </button>
        </li>
        <li
          class="nav-item"
          role="presentation"
        >
          <button
            id="form-inputs-tab"
            aria-controls="form-inputs-tab-pane"
            :aria-selected="currentTab === 'form-inputs-tab'"
            class="nav-link"
            :class="tabClass('form-inputs-tab')"
            data-bs-target="#form-inputs-tab-pane"
            data-bs-toggle="tab"
            role="tab"
            type="button"
          >
            {{ $t('fields') }}
          </button>
        </li>
        <li
          class="nav-item"
          role="presentation"
        >
          <button
            id="integrations-tab"
            aria-controls="integrations-tab-pane"
            :aria-selected="currentTab === 'integrations-tab'"
            class="nav-link"
            :class="tabClass('integrations-tab')"
            data-bs-target="#integrations-tab-pane"
            data-bs-toggle="tab"
            role="tab"
            type="button"
          >
            {{ $t('integration_capitalize') }}
          </button>
        </li>
      </ul>
      <div
        id="myTabContent"
        class="tab-content"
      >
        <general-tab
          :class=" currentTab === '' ? 'show active' : ''"
          @update-fields="updateFields"
        />
        <form-inputs-tab :class=" currentTab === 'form-inputs-tab' ? 'show active' : ''" />
        <integrations-tab :class=" currentTab === 'integrations-tab' ? 'show active' : ''" />
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import HeaderComponent from "@/components/header-component.vue";
import AppButton from "@/components/app-button.vue";
import GeneralTab from "@crm/pages/manager/crm-settings/tabs/general-tab.vue";
import FormInputsTab from "@crm/pages/manager/crm-settings/tabs/form-inputs-tab.vue";
import IntegrationsTab from "@crm/pages/manager/crm-settings/tabs/integrations-tab.vue";
import CrmPageLoading from "@crm/components/shared/crm-page-loading.vue";

export default {
  components: {
    CrmPageLoading,
    HeaderComponent,
    AppButton,
    GeneralTab,
    FormInputsTab,
    IntegrationsTab
  },

  data() {
    return {
      fields: {
        company_id: null,
        funnel_id: null,
        name: "",
        team_id: "",
        qualification_list_id: null,
      }
    };
  },

  mounted() {
    this.fields.funnel_id = Number(this.$route.params.id);
    this.updateCurrentFunnel(this.fields.funnel_id).then(() => {
      this.fields.company_id = this.currentFunnel.company_id;
      this.fields.name = this.currentFunnel.name;
      this.fields.team_id = this.currentFunnel.team.id;
      this.fields.qualification_list_id = this.currentFunnel.qualification_list_id;
    });
    this.list({
      type: 4
    });
  },

  computed: {
    ...mapGetters("crm/funnels", {
      currentFunnel: "getCurrentFunnel",
      loadingFlags: "getLoadingFlags"
    }),
    ...mapGetters("crm/tour", {
      currentTab: "getCurrentTab"
    })
  },

  methods: {
    ...mapActions("crm/funnels", ["updateFunnel", "updateCurrentFunnel", "fetchFunnels"]),
    ...mapActions("manager/qualification", ["list"]),

    updateFields({key, value}) {
      this.fields[key] = value;
    },

    submit() {
      if (!this.fields.name.length) {
        this.fields.name = this.currentFunnel.name;
      }

      this.updateFunnel(this.fields).then(() => {
        this.$router.push(`/manager/crm/${this.currentFunnel.id}`);
      });
    },
    tabClass(tab) {
      return this.currentTab === tab ? 'active' : '';
    }
  }
};
</script>
