<template>
  <div
    class="offcanvas-body d-flex flex-column overflow-scroll gap-1rem"
  >
    <action-component
      icon-class="fa-regular fa-sparkles"
      icon-color="#7745EB"
      :name="$t('sdr_ai_qualification')"
    />
    <div>
      <p class="m-0 fw-medium">
        {{ $t('generate_answers_until_client_write') }}
      </p>
      <p class="m-0 text-medium lh-1 tx-text-gray">
        {{ $t('ai_decides_the_question_moment') }}
      </p>
    </div>
    <div
      v-for="(question, index) in questions"
      :key="index"
      class="col-auto"
    >
      <div class="input-group">
        <input
          id="optionTitle"
          v-model="questions[index]"
          :class="{'is-invalid': hasErrors && !questions[index]}"
          :placeholder="$t('what_is_your_question')"
          class="form-control"
        >
        <span
          v-if="index > 0"
          class="input-group-text fe fe-x cursor-pointer"
          @click="questions.splice(index, 1)"
        />
      </div>
    </div>
    <button
      v-if="questions.length < 10"
      class="btn btn-sm btn-light align-self-center btn-add-question"
      @click="addNewQuestion"
    >
      + {{ $t('new_option') }}
    </button>
    <hr class="w-100">
    <div>
      <p class="fw-medium">
        {{ $t('after_conclude_questions') }}
      </p>
      <p class="m-0 fw-medium">
        {{ $t('answer') }}
      </p>
      <p class="m-0 text-medium lh-1 tx-text-gray">
        {{ $t('ai_will_write_text') }}
      </p>
    </div>
    <div>
      <textarea-with-count
        id="message"
        v-model="endTrigger"
        :is-invalid="hasErrors && !endTrigger"
        :placeholder="$t('answer')"
        :max-length="255"
        :max-height="264"
      />
    </div>
    <div class="mt-auto" />
    <app-button
      :disabled="hasErrors && (!endTrigger || Object.values(questions).some(el => !el)) || loadingFLags.createAction"
      class="col-auto"
      @click="createMultiChoice"
    >
      {{ $t('create') }}
    </app-button>
    <app-button
      class="col-auto"
      color="btn-outline-secondary border-0"
      @click="$emit('return')"
    >
      {{ $t('back_capitalize') }}
    </app-button>
  </div>
</template>

<script>
import ActionComponent from "@chatbot/pages/workflow/components/action-component.vue";
import AppButton from "@components/app-button.vue";

import {mapActions, mapGetters, mapMutations} from "vuex";
import TextareaWithCount from "@components/textarea-with-count.vue";

export default {
  name: "CreateSdr",

  emits: ["return", "close"],

  components: {
    TextareaWithCount,
    AppButton,
    ActionComponent
  },

  data() {
    return {
      questions: [""],
      endTrigger: "",
      hasErrors: false,
    }
  },

  computed: {
    ...mapGetters("chatbot/actions", {
      prevActionId: "getPrevActionId",
      selectedAction: "getSelectedAction",
      loadingFLags: "getLoadingFlags",

    })
  },

  methods: {
    ...mapActions("chatbot/actions", ["createAction"]),
    ...mapMutations("chatbot/actions", ["removeAction", "setSelectedAction","addNodesPosition"]),
    ...mapMutations("auth", ["trackEvents"]),

    addNewQuestion() {
      if (this.questions.length == 10) {
        return
      }
      this.questions.push("")
    },

    createMultiChoice() {
      if (!this.endTrigger || Object.values(this.questions).some(el => !el)) {
        this.hasErrors = true
        return
      } else {
        this.hasErrors = false
      }

      let payload = {
        type: "sdr",
        need_chat_response: true,
        chatbot_id: this.$route.params.id,
        end_trigger: this.endTrigger,
        questions: this.questions,
        x_coordinate: this.selectedAction?.position?.x || 0,
        y_coordinate: this.selectedAction?.position?.y || 0
      }
      if(this.prevActionId){
        payload.previous_action_id = this.prevActionId
      }
      this.createAction(payload).then((newAction) => {
        this.textContent = "";

        const newPosition = {
          id: `${newAction.id}`,
          position: {
            x: this.selectedAction?.position?.x || 0,
            y: this.selectedAction?.position?.y || 0
          }
        };

        this.addNodesPosition(newPosition);

        if (this.selectedAction) {
          this.removeAction(this.selectedAction.id);
          this.setSelectedAction(null);
        }

        this.$emit("close")
        this.trackEvents("[Manager] Create Chatbot SDR IA")
      })
    }
  }
}
</script>
<style scoped>
.gap-1rem {
  gap: 1rem;
}
.btn-add-question{
  width: 141px;
}
</style>
