<template>
  <div>
    <div class="row">
      <h3 class="mb-4">
        {{ $t("explore_other_tools") }}
      </h3>
      <div class="col-12 p-0">
        <div class="card-list items d-flex">
          <div
            class="card-wrapper"
            v-for="(card, index) in cards"
            :key="index"
          >
            <div
              class="card h-100 d-flex flex-column"
              style="width: 280px; height: 256px"
              @click="handleClick(card.title)"
            >
              <div class="card-body d-flex flex-column justify-content-between">
                <div>
                  <i
                    :class="card.icon"
                    class="tx-blue-300 btn mb-4"
                    style="background-color: #E1E9F4"
                  />
                  <h5 class="card-title">
                    {{ card.title }}
                  </h5>
                  <p style="color: #575778">
                    {{ card.content }}
                  </p>
                </div>
                <div class="mt-auto">
                  <span class="d-flex align-items-center">
                    <button
                      class="btn tx-blue-300 p-0"
                      @click="handleClick(card.title)"
                    >{{ $t('know_more') }}</button>
                    <i class="fe fe-arrow-right tx-blue-300" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <product-popup
      v-if="selectedProduct"
      :show-popup="showPopup"
      @close="showPopup = false"
      :product="selectedProduct"
    />
  </div>
</template>

<script>


import ProductPopup from "@crm/pages/manager/crm-tour/components/product-popup.vue";

export default {
  components: {ProductPopup},

  data() {
    return {
      selectedProduct: null,
      showPopup: false,
      cards: [
        {
          title: 'Omnichannel',
          content: this.$t("maximize_chats"),
          icon: 'fa-regular fa-messages',
          onClick: () => this.$router.push('/crm/omnichannel'),
        },
        {
          title: 'Chatbot',
          icon: 'fa-regular fa-robot',
          content: this.$t('automate_your_whatsapp_support'),
          onClick: () => this.$router.push('/crm/chatbot'),
        },
        {
          title: this.$t("automatic_dialer"),
          icon: 'fa-regular fa-phone-volume',
          content: this.$t("cloud_solution_to_power_your_calls"),
          onClick: () => this.$router.push('/crm/telefone'),
        },
        {
          title: 'PABX',
          icon: 'fa-regular fa-phone-office',
          content: this.$t("optimize_your_communication"),
          onClick: () => this.$router.push('/crm/chat'),
        },
        {
          title: this.$t('receptive_capitalize'),
          icon: 'fa-regular fa-phone-arrow-down-left',
          content: this.$t("manage_your_receptive_calls"),
          onClick: () => this.$router.push('/crm/email'),
        }
      ]
    }
  },
  mounted() {
    const slider = document.querySelector('.items');
    let isDown = false;
    let startX;
    let scrollLeft;

    this.handleMouseDown = (e) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };

    this.handleMouseLeave = () => {
      isDown = false;
      slider.classList.remove('active');
    };

    this.handleMouseUp = () => {
      isDown = false;
      slider.classList.remove('active');
    };

    this.handleMouseMove = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 2;
      slider.scrollLeft = scrollLeft - walk;
    };

    slider.addEventListener('mousedown', this.handleMouseDown);
    slider.addEventListener('mouseleave', this.handleMouseLeave);
    slider.addEventListener('mouseup', this.handleMouseUp);
    slider.addEventListener('mousemove', this.handleMouseMove);
  },
  beforeUnmount() {
    const slider = document.querySelector('.items');
    if (slider) {
      slider.removeEventListener('mousedown', this.handleMouseDown);
      slider.removeEventListener('mouseleave', this.handleMouseLeave);
      slider.removeEventListener('mouseup', this.handleMouseUp);
      slider.removeEventListener('mousemove', this.handleMouseMove);
    }
  },
  methods: {
    handleClick(product) {
      this.selectedProduct = product;
      this.showPopup = true;
    }
  }
}
</script>

<style>

.card-list {
  display: flex;
}
.card-wrapper {
  flex: 0 0 auto;
  scroll-snap-align: center;
  padding: 0 10px;
  box-sizing: border-box;
}
.items {
  position: relative;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  user-select: none;
  cursor: pointer;
}

.items.active {
  cursor: grabbing;
  transform: scale(1);
}

.items::-webkit-scrollbar {
  display: none;
}

</style>
