import axios from "axios";

export default {
  searchAgentsReport({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading", "searchAgentsReport");
    commit("setReportList", []);

    return new Promise((resolve, reject) => {
      axios
        .get(`/${payload.campaignId ? "campaigns/" + payload.campaignId : "current-company"}/agents/metrics/total`, {
          params: {
            start_date: payload.startDate,
            end_date: payload.endDate,
            order_by_desc: "calls",
          },
        })
        .then(response => {
          commit("setReportList", response.data.data);
          resolve();
        })
        .catch(response => {
          response.response.status == 422 &&
          response.response.data.errors &&
          commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading", "searchAgentsReport");
        });
    });
  },
  searchQualifications({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading", "searchQualifications");
    commit("setQualificationList", []);

    return new Promise((resolve, reject) => {
      axios
        .get(`/${payload.campaignId ? "campaigns/" + payload.campaignId : "company"}/agents/qualifications`, {
          params: {
            start_date: payload.startDate,
            end_date: payload.endDate,
          },
        })
        .then(response => {
          commit("setQualificationList", response.data.data);
          resolve();
        })
        .catch(response => {
          response.response.status == 422 &&
          response.response.data.errors &&
          commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading", "searchQualifications");
        });
    });
  },
  downloadTable({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading", "downloadTable");

    return new Promise((resolve, reject) => {
      axios
        .get(`/${payload.campaignId ? "campaigns/" + payload.campaignId : "current-company"}/agents/metrics/total/csv`, {
          params: {
            start_date: payload.startDate,
            end_date: payload.endDate,
            email: payload.email,
          },
        })
        .then(() => {
          commit("system/setSuccessMessage", "success_message.generate_report", { root: true });
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          response.response.status == 422 &&
          response.response.data.errors &&
          commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data });
        })
        .finally(() => {
          commit("stopLoading", "downloadTable");
        });
    });
  },

  /**
   * Retrieve the calls statistics by agent
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {Promise}
   */
  searchConnectedCallsData({ commit }, payload) {
    commit("setConnectedCallsData", []);

    return new Promise((resolve, reject) => {
      axios.get(`/agents/statistics/by_agent`, { params: payload })
        .then(response => {
          commit("setConnectedCallsData", response.data.data);
          resolve();
        }).catch(error => {
        commit("system/setErrorMessage", error.response.data, { root: true });
        reject();
      });
    });
  },

  downloadCsvQualificationsPerAgent({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading", "downloadCsvQualificationsPerAgent");

    return new Promise((resolve, reject) => {
      axios
        .get(`/campaigns/${payload.campaign_id}/agents/qualifications/csv`, {
          params: { ...payload, order_by_desc: "calls" },
        })
        .then(() => {
          commit("system/setSuccessMessage", "success_message.generate_report", { root: true });
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          response.response.status == 422 &&
          response.response.data.errors &&
          commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data });
        })
        .finally(() => {
          commit("stopLoading", "downloadCsvQualificationsPerAgent");
        });
    });
  },

  downloadCsvCallsConectedPerAgent({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading", "downloadCsvCallsConectedPerAgent");

    return new Promise((resolve, reject) => {
      axios
        .get(`/agents/statistics/by_agent/csv`, {
          params: payload,
        })
        .then(() => {
          commit("system/setSuccessMessage", "success_message.generate_report", { root: true });
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          response.response.status == 422 &&
          response.response.data.errors &&
          commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data });
        })
        .finally(() => {
          commit("stopLoading", "downloadCsvCallsConectedPerAgent");
        });
    });
  },
};
