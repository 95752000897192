<template>
  <VueMultiselect
    :multiple="multiple"
    v-model="selecteds"
    label="name"
    track-by="id"
    :placeholder="$t('all_dropdown')"
    :select-label="$t('click_to_select')"
    :deselect-label="$t('click_to_remove')"
    :selected-label="$t('selected')"
    :options="receptiveQueues"
    :disabled="loadingFlags.findFilters"
    @select="select"
    @remove="remove"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import VueMultiselect from "vue-multiselect";

export default {
  data() {
    return {
      selecteds: "",
      receptiveQueues: []
    };
  },

  emits: ["update:modelValue"],

  mounted() {
    this.setIncludes(["queues"]);
    this.findFilters();
    if (this.modelValue) this.selecteds = this.modelValue

  },

  components: {
    VueMultiselect
  },

  watch: {
    filters: {
      handler(data) {
        if (data.queues) this.receptiveQueues = data.queues;
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters("manager/filters", {
      filters: "getFilters",
      loadingFlags: "getLoadingFlags"
    })
  },

  props: {
    modelValue: {
      type: [String, Array, Number, Object],
      default: ""
    },

    multiple: {
      type: Boolean,
      default: false
    },
  },

  methods: {
    ...mapActions("manager/filters", ["findFilters"]),
    ...mapMutations("manager/filters", ["setIncludes"]),

    select(value) {
      if (this.multiple) {
        const selecteds = [...this.selecteds, value];
        const ids = selecteds.map((item) => item.id).join(",");
        this.$emit("update:modelValue", ids);
      } else {
        this.$emit("update:modelValue", value);
      }
    },

    remove(value) {
      if (this.multiple) {
        const selecteds = this.selecteds.filter((item) => item.id !== value.id);
        const ids = selecteds.map((item) => item.id).join(",");
        this.$emit("update:modelValue", ids);
      } else {
        this.$emit("update:modelValue", "");
      }
    }
  }
};
</script>
