<template>
  <header-component
    :title="$t('reports_capitalize')"
  />
  <div class="row">
    <wrapper-card-settings
      :description="false"
      :title="$t('capitalizes.voice')"
      :settings="reports"
    />
    <div class="px-3">
      <hr class="mt-0 mb-4">
    </div>
    <wrapper-card-settings
      :description="false"
      :title="$t('Omnichannel')"
      :settings="omnichannel"
    />
  </div>
</template>
<script>
import HeaderComponent from "@/components/header-component.vue";
import { mapGetters } from "vuex";
import WrapperCardSettings from "@dialer/manager/pages/settings/voice-settings/wrapper-card-settings.vue";

export default {
  data() {
    return {
      reports: [
        {
          title: this.$t("calls_capitalizes"),
          icon: "far fa-phone-volume",
          redirectLink: "/manager/calls-report",
          show: true,
        },
        {
          title: this.$tc("capitalizes.agent", 2),
          icon: "far fa-user-group",
          redirectLink: "/manager/agents-report",
          show: true,
        },
        {
          title: this.$t("qualifications_capitalize"),
          icon: "far fa-check",
          redirectLink: "/manager/qualifications-report",
          show: true,
        },
        {
          title: this.$t("utilization_capitalize"),
          icon: "far fa-wave-pulse",
          redirectLink: "/manager/utilization-report",
          show: !this.isSupervisor,
        },
        {
          title: this.$t("lists_capitalizes"),
          icon: "far fa-list-ul",
          redirectLink: "/manager/list-metrics",
          show: !this.isSupervisor,
        },
        {
          title: this.$t("schedules"),
          icon: "far fa-calendar",
          redirectLink: "/manager/schedules",
          show: true,
        },
        {
          title: this.$t("manuals_capitalizes"),
          icon: "fa-kit fa-key-mobile",
          redirectLink: "/manager/manual-calls",
          show: true,
        },
        {
          title: "Feedbacks",
          icon: "far fa-comment",
          redirectLink: "/manager/feedback-report",
          show: true,
        }, {
          title: this.$t("breaks_capitalize"),
          icon: "far fa-clock",
          redirectLink: "/manager/intervals-report",
          show: true,
        },
        {
          title: this.$t("login_history_capitalize"),
          icon: "far fa-arrow-right-to-bracket",
          redirectLink: "/manager/login-history",
          show: true,
        },
      ],
      omnichannel: [
        {
          title: this.$t("chats"),
          icon: "far fa-message",
          redirectLink: "/manager/chats-report",
          show: true,
        },
        {
          title: this.$t("capitalizes.agents"),
          icon: "far fa-user-group",
          redirectLink: "/manager/chats-agents-report",
          show: true,
        },
        {
          title: this.$t("group_channels"),
          icon: "far fa-qrcode",
          redirectLink: "/manager/group-channels-report",
          show: true,
        },
        {
          title: "Qualificações",
          icon: "fa-regular fa-check",
          redirectLink: "/manager/chats-qualifications-report",
          show: true,
        },
        {
          title: "Aproveitamento",
          icon: "fa-regular fa-wave-pulse",
          redirectLink: "/manager/performance-report",
          show: true,
        },
      ],
    };
  },
  components: {
    WrapperCardSettings,
    HeaderComponent,
  },

  computed: {
    ...mapGetters("auth", {
      isSupervisor: "isSupervisor",
      isAgent: "isAgent",
    }),
  },
};
</script>