<template>
  <div class="dropdown">
    <button
      class="d-flex justify-content-center align-items-center btn btn-more"
      type="button"
      data-test="moreChatOptions-button"
      data-bs-toggle="dropdown"
      data-bs-auto-close="outside"
      aria-expanded="false"
    >
      <span class="far fa-ellipsis" />
      <span class="far fa-close" />
    </button>
    <div class="dropdown-menu">
      <template v-if="showList">
        <h6 style="font-weight: 500; margin-bottom: 16px;">
          {{ $t('more_options') }}
        </h6>
        <ul
          v-if="responsiveItems.length"
          class="list-group list-group-flush"
        >
          <li
            style="gap: 0.5rem"
            v-for="(item, key) in responsiveItems"
            :key="`responsive-item-${key}`"
            class="list-group-item border-0 h5 fw-normal mb-0 d-flex align-items-center"
            @click="$emit('responsiveItemClicked',item.id)"
          >
            <i
              class="fa-regular"
              :class="item.icon"
            />
            {{ item.label }}
          </li>
        </ul>
        <div
          v-if="responsiveItems.length"
          style="height: 1px; margin: 0.25rem 0"
          class="w-100 border-top"
        />
        <ul class="list-group list-group-flush">
          <li
            class="list-group-item border-0 h5 fw-normal mb-0"
            data-test="showProfile-button"
            @click="$emit('showEdit')"
          >
            <span v-if="isGroup">{{ $t('group_details') }}</span>
            <span v-else>{{ $t('view_profile') }}</span>
          </li>
          <li
            v-if="!isGroup"
            class="list-group-item border-0 h5 fw-normal mb-0"
            style="cursor: pointer; padding: 12px;"
            data-test="showProtocols-button"
            @click="showProtocolMenu = !showProtocolMenu"
          >
            {{ $t('protocol') }}
          </li>
          <li
            class="list-group-item border-0 h5 fw-normal mb-0"
            style="cursor: pointer; padding: 12px;"
            data-test="searchChat-button"
            @click="searchInChat = true"
          >
            {{ $t('search_in_chat') }}
          </li>
          <li
            v-if="!isGroup"
            class="list-group-item border-0 h5 fw-normal mb-0"
            :class="{'disabled': !funnels.length}"
            data-test="showFunnels-button"
            @click="showFunnels = !showFunnels"
          >
            {{ $t('connect_to_funnel') }}
          </li>
        </ul>
        <funnels-list v-if="showFunnels" />
      </template>
      <slot
        v-else
        name="body"
      />
    </div>
    <protocol-menu
      v-if="!isGroup && showList"
      v-model="showProtocolMenu"
    />
    <search-messages-canvas
      v-if="currentChat && showList"
      :contact-id="contactId"
      :current-chat="currentChat"
      :model-value="searchInChat"
      @close="searchInChat = false"
    />
  </div>
</template>

<script>
import FunnelsList from "@whatsapp/components/funnels-list.vue";
import {mapGetters} from "vuex";
import ProtocolMenu from "../protocols/protocol-menu.vue";
import SearchMessagesCanvas from "../search-messages/search-messages-canvas.vue";

export default {
  emits: ["showEdit", 'responsiveItemClicked'],

  components: {
    SearchMessagesCanvas,
    ProtocolMenu,
    FunnelsList,
  },
  props: {
    isGroup: {
      type: Boolean,
      default: false
    },
    responsiveItems: {
      type: Array,
      default: () => []
    },
    showList: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      showFunnels: false,
      showProtocolMenu: false,
      searchInChat: false,

    }
  },

  computed: {
    ...mapGetters("whatsapp/crm", {
      funnels: "getFunnels",
    }),
    ...mapGetters("whatsapp/chat", {
      chats: "getCurrentChat",
    }),

    currentChat() {
      return this.chats[this.$route.params.id]
    },
    contactId() {
      return this.currentChat.contact.contact_id ? this.currentChat.contact.contact_id : this.currentChat.contact.id
    }

  },
};
</script>

<style lang="scss" scoped>
.btn-more {
  height: 32px;
  width: 32px;
  background-color: #E1E9f4;
  color: #677C92;

  &.show, &:hover {
    background-color: #3057f2;
    color: white;
  }

  .fa-close {
    display: none;
  }

  &.show {
    .fa-ellipsis {
      display: none;
    }
    .fa-close {
      display: inline-block;
    }
  }
}

.dropdown {
  display: inline-block;

  .dropdown-menu {
    min-width: 240px;
    padding: 1rem;

    .list-group-item {
      cursor: pointer;
      padding: 8px 12px;
      border-radius: 8px;
      border-color: #E1E9F4;

      &:hover {
        background-color: $color-gray-blue-300;
        color: $color-blue-300;
      }
    }

    @media only screen and (max-width: 1199.999px) {
      max-width: calc(100vw - 48px);
    }
  }
}
</style>
