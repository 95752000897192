<template>
  <div class="dropdown">
    <button
      class="btn d-flex align-items-center btn-sm dropdown-toggle"
      :style="{
        backgroundColor : selectedItem? '#3057F2' : '#F0F4FA',
        color : selectedItem? 'white' : '#373753'
      }"
      type="button"
      data-bs-toggle="dropdown"
      data-bs-auto-close="outside"
      aria-expanded="false"
    >
      {{ title }}
    </button>
    <div class="dropdown-menu filter-menu">
      <div
        v-if="enableSearch"
        class="input-group input-group-flush input-group-merge mb-05r"
      >
        <div class="input-group-text p-0 tx-text-gray mr-075r">
          <span class="fe fe-search" />
        </div>
        <input
          type="search"
          class="form-control list-search p-0 tx-text-gray"
          :placeholder="$t('placeholders.search_capitalize')"
          v-model="search"
        >
      </div>
      <template v-if="enableAll">
        <div class="filter-item d-flex align-items-center">
          <input
            class="form-check-input mt-0 filter-check-input"
            type="radio"
            :id="'clean-' + title"
            v-model="selectedItem"
            value=""
            @input="$emit('radioSelected', null)"
          >
          <label
            class="form-check-label"
            :for="'clean-' + title"
          >{{ $t('all_dropdown') }}</label>
        </div>
        <hr
          v-if="categorize"
          class="dropdown-divider"
        >
      </template>
      <div
        v-if="categorize"
        class="filter-list"
      >
        <ul
          class="list-unstyled m-0 p-0"
          v-for="(itens, type) in filteredList"
          :key="type"
        >
          <h5 class="my-025r">
            {{ type }}
          </h5>
          <li
            v-if="!itens.length"
            class="filter-item"
          >
            {{ $t('search_messages.results_not_found') }}
          </li>
          <li
            v-for="item in itens"
            :key="item.id"
            class="filter-item d-flex align-items-center ps-1r"
          >
            <input
              class="form-check-input mt-0 filter-check-input col-auto"
              type="radio"
              :id="`${title}-${item.id}`"
              v-model="selectedItem"
              :value="item.id"
              @input="$emit('radioSelected', item.id)"
            >
            <label
              class="form-check-label line-clamp"
              :for="`${title}-${item.id}`"
            >{{ item.name }}</label>
          </li>
          <li
            v-if="loading"
            class="filter-item d-flex justify-content-center"
          >
            <div class="spinner-border spinner-border-sm" />
          </li>
        </ul>
      </div>
      <ul
        v-else
        class="list-unstyled filter-list p-0 m-0"
        @scroll="checkScroll"
      >
        <li
          v-if="!filteredList.length"
          class="filter-item"
        >
          {{ $t('search_messages.results_not_found') }}
        </li>
        <li
          v-for="item in filteredList"
          :key="item.id"
          class="filter-item d-flex align-items-center"
        >
          <input
            class="form-check-input mt-0 filter-check-input col-auto"
            type="radio"
            :id="`${title}-${item.id}`"
            v-model="selectedItem"
            :value="item.id"
            @input="$emit('radioSelected', item.id)"
          >
          <label
            class="form-check-label line-clamp"
            :for="`${title}-${item.id}`"
          >{{ item.name }}</label>
        </li>
        <li
          v-if="loading"
          class="filter-item d-flex justify-content-center"
        >
          <div class="spinner-border spinner-border-sm" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  emits: ["radioSelected", "filterScroll"],

  props: {
    title:{ type: String, default: "" },
    list:{ type: [Array, Object], required: true },
    categorize: { type: Boolean, default: false },
    enableSearch:{ type: Boolean, default: false },
    initialSelectedId:{ type: String, default: "" },
    loading:{ type: Boolean, default: false },
    enableAll: { type: Boolean, default: true }
  },

  data(){
    return {
      selectedItem: "",
      search: "",
    }
  },

  created(){
    this.selectedItem = this.initialSelectedId
  },

  computed:{
    filteredList(){
      const string = this.search.toLowerCase();

      if (this.categorize) {
        const categorizedFiltered = {};
        Object.keys(this.list).forEach(key => {
          categorizedFiltered[key] = this.list[key].filter(
            item => item.name.toLowerCase().includes(string)
          );
        });
        return categorizedFiltered;
      }
      return this.list.filter(
        item => item.name.toLowerCase().includes(string)
      )
    }
  },

  methods:{
    checkScroll(event) {
      const scrollContainer = event.target;
      const scrollHeight = scrollContainer.scrollHeight;
      const scrollTop = scrollContainer.scrollTop;

      if (scrollHeight - scrollTop - scrollContainer.clientHeight < 1) {
        this.$emit("filterScroll")
      }
    },
  }
}
</script>

<style scoped lang="scss">
button.show {
  background-color: $color-blue-300 !important;
  color: $color-text-light !important;
}

.btn-selected {
  background-color: $color-blue-300 !important;
  color: $color-text-light !important;
}

.btn-not-selected {
  background-color: #F0F4FA;
}

.filter-check-input {
  margin-right: 0.5rem;
  background-color: #C5C5C5;

  &:checked {
    background-color: $color-blue-300;
  }
}

.filter-menu {
  padding: 0.5rem 1rem;
  min-width: 300px
}
.filter-list {
  max-height: 320px;
  overflow-y: scroll;
}
.filter-item {
  padding: 0.25rem 0;
}

.mb-05r {
  margin-bottom: 0.5rem;
}
.mr-075r {
  margin-right: 0.75rem;
}
.my-025r {
  margin: 0.25rem 0;
}
.ps-1r {
  padding-left: 1rem;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>