import axiosOmni from "@/plugins/axios-omni.js";
import Promise from "lodash/_Promise";

export default {
  fetchGroupChannelsReportList({commit, getters}) {
    commit("startLoadingFlags", "fetchGroupChannelsReportList");
    commit("setChatsReportList", []);
    commit("setTimeReportList", []);
    commit("setQualificationsReportList", []);

    return axiosOmni
      .get("/whatsapp/reports/group-channel", {params: getters.getParams})
      .then(response => {
        const {
          chats_metrics: chatsMetrics,
          time_metrics: timeMetrics,
          qualifications_metrics: qualificationsMetrics
        } = response.data;

        commit("setChatsReportList", chatsMetrics);

        const updatedTimeMetrics = timeMetrics.group_channel_time_metrics.map(timeMetric => {
          const correspondingChatMetric = chatsMetrics.group_channels_metrics.find(
            chatMetric => chatMetric.group_channel_id === timeMetric.group_channel_id
          );

          if (correspondingChatMetric) {
            timeMetric.group_channel_chats = correspondingChatMetric.group_channel_chats;
          }

          return timeMetric;
        });

        commit("setTimeReportList", {
          ...timeMetrics,
          group_channel_time_metrics: updatedTimeMetrics,
        });


        timeMetrics.group_channel_total_time_metrics.group_channel_chats = chatsMetrics.group_channels_total_metrics.group_channel_chats;

        commit("setQualificationsReportList", qualificationsMetrics.group_channel_qualifications);
      })
      .catch(error => {
        console.error(error);
        commit("system/setErrorMessage", error.response?.data || "An error occurred", {root: true});
        return Promise.reject(error);
      })
      .finally(() => {
        commit("stopLoadingFlags", "fetchGroupChannelsReportList");
      });
  },

  fetchSendChatCsvEmail({commit}, payload) {
    commit("startLoadingFlags", "fetchSendChatCsvEmail");
    return new Promise((resolve, reject) => {
      axiosOmni
        .get(`/whatsapp/reports/group-channel/chat/csv`, {params: {payload}})
        .then(() => {
          commit("system/setSuccessMessage", "CSV enviado!", {root: true});
          resolve();
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, {root: true});
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlags", "fetchSendChatCsvEmail");
        });
    });
  },

  fetchSendTimeCsvEmail({commit}, payload) {
    commit("startLoadingFlags", "fetchSendTimeCsvEmail");
    return new Promise((resolve, reject) => {
      axiosOmni
        .get(`/whatsapp/reports/group-channel/time/csv`, {params: {payload}})
        .then(() => {
          commit("system/setSuccessMessage", "CSV enviado!", {root: true});
          resolve();
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, {root: true});
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlags", "fetchSendTimeCsvEmail");
        });
    });
  }
};
