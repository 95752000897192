<template>
  <div class="d-flex">
    <div
      style="margin-left: 67px"
      class="container-fluid"
    >
      <router-view
        name="crm"
        :key="$route.fullPath"
      />
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  created () {
    this.fetchFunnels(this.$route.params.id).then((currentFunnel)=>{
      if (currentFunnel && this.$route.path === "/crm"){
        this.$router.push({path: `/crm/${currentFunnel.id}`})
      }
    })
  },

  beforeRouteUpdate(to){
    if (to.fullPath === "/crm"){
      this.$router.back()
    }
  },

  computed: {
    ...mapGetters("auth", {
      isManager: "isManager",
      isSupervisor: "isSupervisor"
    })
  },

  methods: {
    ...mapActions('crm/funnels', ['fetchFunnels']),
    ...mapActions("manager/whatsapp", ["findInstances"]),
  }
}
</script>
