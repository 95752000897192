<template>
  <div
    class="recommendation-box position-fixed"
    style="z-index: 999"
    :style="{
      top: position == 'top' ? 0 : null,
      bottom: position == 'bottom' ? 0 : null
    }"
  >
    <slide-transition
      :position="position"
      :opacity="true"
    >
      <div
        class="toast-customized shadow rounded-0 bg-white"
        :class="position == 'top' ? 'rounded-bottom' : 'rounded-top'"
        v-if="showNpsToast"
      >
        <div class="toast-header border-0 border-bottom text-center d-flex justify-content-center">
          <strong>{{ $t('recommendation') }} </strong>
          <button
            type="button"
            class="btn-close position-absolute end-0 me-2"
            aria-label="Close"
            :disabled="loading.sendNps"
            @click="closeNpsWhenOpen"
            data-test="closeNps-button"
          />
        </div>
        <div class="toast-body px-0">
          <div class="row">
            <div>
              <div
                class="btn-group w-100 gap-2"
                role="group"
                aria-label="First group"
              >
                <button
                  v-for="i in 10"
                  :key="i"
                  @click="recommendationScore = i"
                  :class="{
                    'btn btn-sm rounded border': true,
                    'bad-recommendation': i <= 6,
                    'medium-recommendation': i > 6 && i < 9,
                    'good-recommendation': i >= 9 && i <= 10,
                    'selected': recommendationScore == i
                  }"
                  :disabled="loading.sendNps"
                  data-test="scoreNps-button"
                >
                  {{ i }}
                </button>
              </div>
              <div
                v-if="recommendationScore <= 8 && recommendationScore !== ''"
                class="mt-2 position-relative"
              >
                <textarea
                  class="form-control"
                  style="margin: 1rem 0;"
                  :placeholder="$t('justify_rating')"
                  v-model="recommendationReason"
                  data-test="reasonNps-input"
                />
                <span
                  v-if="recommendationScore < 7"
                  class="position-absolute end-0 top-0 text-secondary text-small-2"
                  :style="{ marginTop: '64px' }"
                >
                  {{ $t('min_20_char') }}
                </span>
                <button
                  class="btn w-100 fw-medium d-flex align-content-center justify-content-center mt-3"
                  :class="{
                    'btn-outline-primary': recommendationReason.trim().length >= 20 || recommendationScore >= 7,
                    'btn-secondary text-dark': recommendationReason.trim().length < 20 && recommendationScore < 7
                  }"
                  :disabled="recommendationScore < 7 && (recommendationReason.trim().length < 20 || loading.sendNps)"
                  @click="send"
                  data-test="sendReasonNps-button"
                >
                  <i
                    class="fe fe-send me-2"
                    v-if="recommendationReason.trim().length >= 20 || recommendationScore >= 7"
                  />
                  <span> 
                    {{ $t('send_capitalize') }}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </slide-transition>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import SlideTransition from './slide-transition.vue';

export default {
  data() {
    return {
      recommendationScore: "",
      recommendationReason: "",
      position: "top",
    };
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser",
      isAgent: "isAgent",
      inImpersonate: "inImpersonate"
    }),

    ...mapGetters("nps", {
      loading: "getLoadingFlag",
      closedAt: "getClosedAt",
      showNpsToast: "showNps"
    }),

    ...mapGetters("system", {
      isSystemMessage: "isMessage",
    }),
  },

  mounted() {
    this.verifyIfShowNps()
  },

  components: {
    SlideTransition
  },

  watch: {
    recommendationScore(recommendationScore) {
      if(recommendationScore > 8) {
        this.submit({
          score: this.recommendationScore,
        })
      }
    },

    isSystemMessage(isSystemMessage) {
      if(isSystemMessage) {
        this.closeNpsWhenOpen()
      }
    }
  },

  methods: {
    ...mapActions("nps", ["submit", "closeNpsWhenOpen", "showNpsAfterClosed", "showNps"]),

    send() {
      this.submit({
        score: this.recommendationScore,
        reason: this.recommendationReason,
      })
    },

    /*
    * Show nps to new users after 2 weeks
    * After the nps is answered it will appear every 3 months
    * If user close nps it will appear after 12 hours
     */
    verifyIfShowNps() {
      if(this.inImpersonate) return;
      if(this.isAgent) this.position = 'bottom';
      else this.position = 'top';

      let currDate = moment();
      let userLastNps = moment(this.user.last_nps);
      let userCreatedAt = moment(this.user.created_at);

      let diffUserCreatedAtInWeeks = currDate.diff(userCreatedAt, "weeks");
      let diffUserLastNpsInMonths = currDate.diff(userLastNps, "months");

      if(this.closedAt) this.showNpsAfterClosed();
      else {
        if(this.user.last_nps) {
          if(diffUserLastNpsInMonths >= 3) {
            this.showNps();
          }
        }
        else {
          if(diffUserCreatedAtInWeeks >= 2) {
            let fiveMinutesDelayManager = this.isAgent ? 0 : 5 * 60 * 1000;
            setTimeout(() => this.showNps(), fiveMinutesDelayManager);
          }
        }
      }
    }
  },
};
</script>
<style lang="scss" scoped>
$recommendation-box-width: 456px;
.recommendation-box {
  left: calc(50% - #{$recommendation-box-width} / 2);
  .toast-customized {
    z-index: 9999;
    padding: 0 32px;
    width: $recommendation-box-width;
    .btn-group {
      .btn {
        height: 32px !important;
        height: 2.5rem;
      }
      .btn-secondary.disabled {
        background-color: red !important;
      }
      .btn:hover, .btn.selected {
        color: white;
      }
      .bad-recommendation:hover, .bad-recommendation.selected  {
        background: $color-red-100;
        border-color: $color-red-100 !important;
      }
      .medium-recommendation:hover, .medium-recommendation.selected {
        background: $color-yellow-200;
        border-color: $color-yellow-200 !important;
        color: #12263f;
      }
      .good-recommendation:hover, .good-recommendation.selected {
        background: $color-green-300;
        border-color: $color-green-300 !important;
      }
    }
    .btn-secondary {
      background-color: #DFDFDF;
      border: none;
    }
  }
}

</style>
