<template>
  <div
    class="modal fade"
    ref="transferChatModal"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0">
          <button
            type="button"
            class="btn-close"
            @click="transferChatModal.hide()"
          />
        </div>
        <div
          class="modal-body"
          v-if="agents.length"
        >
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-8 text-center">
              <p class="fs-2">
                {{ $t('pending_chats') }}
              </p>
              <p>
                {{ activeConversationText }} {{ $t('pending_chats_2') }}
              </p>
            </div>
            <div class="col-lg-8 px-0 table-scroll overflow-auto">
              <table class="table table-striped m-0">
                <tbody>
                  <tr
                    v-for="agent in agents"
                    :key="agent.id"
                  >
                    <td> {{ agent.name }} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-lg-8 text-center">
              <p class="text-primary fw-bold mt-4">
                {{ $t('transfer') }} {{ amountOfChats }} {{ amountConversationText(amountOfChats) }} {{ $t('transferred_to.to') }}:
              </p>
            </div>
            <div
              class="col-lg-8 d-flex justify-content-center mb-3"
              v-if="agents.length===1"
            >
              <div class="form-check form-check-inline">
                <input
                  type="radio"
                  class="form-check-input"
                  value="agent"
                  v-model="transferTo"
                >
                <label class="form-check-label">
                  {{ $t('agent_capitalize') }}
                </label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  type="radio"
                  class="form-check-input"
                  value="queue"
                  v-model="transferTo"
                >
                <label class="form-check-label">
                  {{ $t('chats_queue') }}
                </label>
              </div>
            </div>
            <div
              class="col-lg-8 d-flex justify-content-center mb-3"
              v-else
            >
              <label class="form-check-label">
                Fila de conversas
              </label>
            </div>
            <div
              class="col-lg-8 mb-5"
              v-if="transferTo === 'agent'"
            >
              <select
                class="form-select"
                :class="{
                  'is-invalid': !agentToTransfer
                }"
                v-model="agentToTransfer"
              >
                <option :value="null">
                  {{ $t('select') }}
                </option>
                <template
                  v-for="teamAgent in teamList"
                  :key="teamAgent.id"
                >
                  <option
                    v-if="!agents.find((agent) => agent.id === teamAgent.id)"
                    :value="teamAgent.id"
                  >
                    {{ teamAgent.name }}
                  </option>
                </template>
              </select>
              <div
                class="invalid-feedback"
                v-if="!agentToTransfer"
              >
                {{ $t('no_agent_chosen') }}
              </div>
            </div>

            <div class="col-lg-8 mb-3">
              <app-button
                :loading="loading"
                class="w-100"
                @click="transferChats"
              >
                {{ $t('transfer') }}
              </app-button>
            </div>

            <div class="col-lg-8">
              <app-button
                class="w-100"
                :color="'text-danger border-0'"
                @click="finishChats"
              >
                <span class="fe fe-trash-2" />
                {{ $t('end_all_chats') }}
              </app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Modal} from 'bootstrap';
import {mapActions, mapGetters} from 'vuex';

import AppButton from "@/components/app-button.vue";

export default {
  data() {
    return {
      transferChatModal: null,
      transferTo: 'queue', // Define queue as default to transfer the chats
      agentToTransfer: null,
      teamAgents: [],
      agents: [],
      seletedAgentId:null
    }
  },
  mounted() {
    if(this.errors && this.errors.agents) {
      /**
       * Verify if the triggered error is related to
       * the active chats of the user that is about to
       * be REMOVED FROM TEAM
       */
      this.agents = this.errors.agents;
      this.transferChatModal = new Modal(this.$refs.transferChatModal);
      this.transferChatModal.show();
    }
  },
  components: {
    AppButton
  },
  props: {
    teamList: {
      type: Array,
      default: () => []
    },
    errors: {
      type: Object,
      default: () => {}
    },
    whatsappQualificationId: {
      type: Number,
      default: -1,
      required: true
    },
    teamId: {
      type: String,
      default: null
    }
  },
  emits: ["onTransferConversations"],
  methods: {
    ...mapActions("manager/whatsapp", ["agentTransferChats", "finishAgentChat"]),
    ...mapActions("system", ["showConfirmationMessage"]),

    transferChats() {
      const payload = {
        user_id: this.agentToTransfer,
        internal_note: !this.agentToTransfer ?
            this.$t('transferred_by_manager.to_queue') :
            this.$t('transferred_by_manager.to_agent'),
        team_ids: [parseInt(this.teamId)]
      };

      let promises = [];
      this.agents.forEach((agent) => {
        promises.push(this.agentTransferChats({ agentId: agent.id, payload }));
      });

      Promise.all(promises).then(() => {
        this.transferChatModal.hide();
        this.$emit("onTransferConversations");
      });
    },
    async finishChats() {
      let response = await this.showConfirmationMessage({
        title: this.$t('end_all_chats_confirmation'),
        text: "",
        type: "badWarning"
      })

      if (response.isConfirmed) {
        const params = {
          qualification: this.whatsappQualificationId,
          qualification_note: this.$t('end_all_chats_by_manager')
        };

        let promises = [];
        this.agents.forEach((agent) => {
          promises.push(this.finishAgentChat({ id: agent.id, params }));
        });

        Promise.all(promises).then(() => {
          this.transferChatModal.hide();
          this.$emit("onTransferConversations");
        });
      }
    },
    amountConversationText(amount) {
      return amount > 1 ? this.$t('transfer_active_chats.chats') : this.$t('transfer_active_chats.chat');
    }
  },
  computed: {
    ...mapGetters("manager/teams", {
      team: "getTeam"
    }),
    ...mapGetters("manager/whatsapp", {
      loading: "isLoading"
    }),
    amountOfChats() {
      return this.agents.reduce((previousValue, currentValue) => {
        const activeChats = typeof currentValue.active_chats === 'number' ? currentValue.active_chats : 0;
        return previousValue + activeChats;
      }, 0);
    },
    activeConversationText() {
      let text = "";
      if(this.agents.length > 1)
        text = this.$t('agents_with_active_chats');
      else {
        if(this.agents[0].active_chats > 1)
          text = this.$t('agent_with_active_chats');
        else
          text = this.$t('agent_with_active_chat');
      }

      return text;
    },
  },
  watch: {
    team(data) {
      if(data)
        this.teamAgents = data.agents;
    },
    /**
     * Clear the selected agent if the user chooses
     * to transfer the chats to the queue
     */
    transferTo(data) {
      if(data === 'queue')
        this.agentToTransfer = null;
    },
  }
}
</script>

<style lang="scss" scoped>
.table-scroll {
  max-height: 200px;
  border: 1px solid $color-gray-100;
  border-radius: $whatsapp-border-radius;
}
</style>
