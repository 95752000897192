<template>
  <div class="modal-body pt-3 border-0">
    <div
      class="row"
      style="border: 1px red"
      v-if="loading"
    >
      <div class="col-lg-12 mt-2 mb-2 text-center">
        <br>
        <div
          class="spinner-border text-muted"
          role="status"
        >
          <span class="visually-hidden" />
        </div>
        <p
          style="border: 1px red"
          class="text-center"
        >
          <span class="text-muted">{{ $t('generating_qr_code') }}... </span>
        </p>
      </div>
    </div>
    <div
      v-else-if="!loading"
      class="col card mx-2 cursor-pointer"
    >
      <div class="card-body">
        <div class="row align-items-center d-flex flex-column">
          <img
            src="@assets/img/logo.svg"
            class="d-flex justify-content-center col-md-2 mx-auto"
          >
          <div class="col-auto d-flex justify-content-center">
            <span
              v-show="pixPaid"
              class="fe fe-check-circle text-speaking"
              style="font-size: 4rem"
            />
            <img
              v-show="!pixPaid"
              class="col-md-6 w-100"
              :src="'data:image/jpeg;base64,' + transactionData.qr_code_base64"
            >
          </div>
        </div>
        <button
          v-if="pixPaid"
          class="col-lg-12 btn btn-primary"
          @click="hide()"
        >
          Ok
        </button>
        <button
          v-if="!pixPaid"
          class="col-lg-12 btn btn-primary"
          @click="copyToClipboard()"
        >
          {{ $t('copy_pix') }}
        </button>
      </div>
      <div
        v-if="isCopied"
        class="d-flex justify-content-center align-items-center"
      >
        <span class="fe fe-check text-success h2" />
        <label class="form-label h2">{{ $t('copied') }}</label>
      </div>
    </div>
    <div
      class="d-flex justify-content-center align-items-center align-text-center"
    >
      <label class="form-label h2">
        {{ $t('automatic_credit') }}.</label>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  emits: ["hidePixBilling"],
  data() {
    return {
      isCopied: false,
    };
  },
  computed: {
    ...mapGetters("manager/user_profile", {
      loading: "isLoading",
      transactionData: "getTransactionData",
      list: "getList",
      pixPaid: "getIsPaid",
    }),
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.transactionData.qr_code);
      this.isCopied = true;
    },
    hide() {
      this.$emit("hidePixBilling");
    },
  },
};
</script>
