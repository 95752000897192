<template>
  <template v-if="!registered && !isExternalSipActive && userMediaPermission != 'not-allowed'">
    {{ connectingInfo }}
  </template>
  <hover-button
    ref="loginCampaignButton"
    v-else-if="status === AgentStatus.OFFLINE"
    @click="toggleDropdown"
    :text="this.$t('join_campaign')"
    hover-color="#5778F5"
    :keep-button-hovered="showLoginCampaignCard"
  />

  <dropdown-component
    :is-open="showLoginCampaignCard"
    :button-rect="buttonRect"
    :title="$t('campaign_select')"
  >
    <div class="overflow-auto card-campaigns-body">
      <div v-if="!loadingFlags.fetchCampaigns">
        <div v-if="campaigns.length">
          <search-input
            style="padding: 0 1rem;"
            :search="campaignToSearch"
            @search="search => (campaignToSearch = search)"
          />
          <template
            v-for="campaign in campaigns"
            :key="campaign.id"
          >
            <div
              v-if="campaignToSearch === '' || campaign.name.toLowerCase().includes(campaignToSearch.toLowerCase())"
              @mouseenter="hoveredCampaign = campaign"
              @mouseleave="hoveredCampaign = null"
              @click="loginCampaign"
              class="d-flex align-items-center justify-content-between campaign-item tx-text-200 rounded cursor-pointer"
              :class="{
                disabled: loadingFlagsAgent.login
              }"
            >
              {{ campaign.name }}

              <i
                v-if="campaign == hoveredCampaign"
                class="fal fa-arrow-right fw-normal tx-text-gray"
              />
            </div>
          </template>
        </div>
        <div
          class="d-flex flex-column text-center mt-6"
          v-else
        >
          <h2 class="my-0">
            Oops...
          </h2>
          <img
            :src="require(`@/assets/img/aloisio/aloisioThinking.svg`)"
            class="align-self-center my-4"
            style="width: 68px;"
          >
          <p class="fs-6">
            {{ $t('not_linked_to_any_campaign') }}
          </p>
        </div>
      </div>
      <div v-else>
        {{ $t('loading_capitalize') }}...
      </div>
    </div>
  </dropdown-component>
</template>

<script>
import { AgentStatus } from "@/utils";
import { mapActions, mapGetters } from "vuex";
import HoverButton from "@dialer/agent/components/hover-button.vue";
import DropdownComponent from "@dialer/agent/components/dropdown-component.vue";
import SearchInput from "@/components/search-input.vue";
export default {
  name: "LoginCampaignButton",
  data() {
    return {
      showLoginCampaignCard: false,
      AgentStatus,
      buttonRect: null,
      hoveredCampaign: null,
      campaignToSearch: ""
    };
  },
  mounted() {
    this.fetchCampaigns();
  },
  components: {
    HoverButton,
    DropdownComponent,
    SearchInput
  },
  methods: {
    ...mapActions("agent/campaign", ["fetchCampaigns"]),
    ...mapActions("nps", ["closeNpsWhenOpen"]),
    ...mapActions("agent/agent", ["login", "setActiveDropdownLeft"]),

    toggleDropdown() {
      this.showLoginCampaignCard = !this.showLoginCampaignCard;
      if (this.showLoginCampaignCard) {
        this.setActiveDropdownLeft("LoginCampaignButton");
        this.calculateButtonRect();
        window.addEventListener("resize", this.calculateButtonRect);
      } else {
        window.removeEventListener("resize", this.calculateButtonRect);
      }
    },

    calculateButtonRect() {
      const rect = this.$refs.loginCampaignButton.$el.getBoundingClientRect();
      this.buttonRect = rect;
    },

    loginCampaign() {
      if (this.loadingFlagsAgent.login) return;
      this.login({
        campaign: this.hoveredCampaign.id,
        mode: "dialer"
      }).then(() => {
        this.toggleDropdown();
        this.closeNpsWhenOpen();
      });
    }
  },
  computed: {
    ...mapGetters("system", {
      status: "getStatus",
      connectingInfo: "getConnectingInfo",
      registered: "isRegistered",
      userMediaPermission: "getUserMediaPermission"
    }),

    ...mapGetters("auth", {
      isExternalSipActive: "isExternalSipActive",
      user: "getUser"
    }),

    ...mapGetters("agent/campaign", {
      campaigns: "getCampaigns",
      loadingFlags: "getLoadingFlags"
    }),

    ...mapGetters("agent/agent", {
      loadingFlagsAgent: "getLoadingFlags",
      activeDropdownLeft: "getActiveDropdownLeft"
    })
  },

  watch: {
    activeDropdownLeft(newVal) {
      if (newVal !== "LoginCampaignButton" && this.showLoginCampaignCard) {
        this.toggleDropdown();
      }
    }
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.calculateButtonRect);
  }
};
</script>

<style lang="scss" scoped>
.card-campaigns-body {
  height: 345px;
  padding: 0.5rem 1rem;
  > div:first-child {
    height: 320px;
    overflow-y: scroll;
  }

  .campaign-item {
    padding: 0.25rem 1rem;

    &:hover {
      background-color: $color-gray-blue-300;
    }
  }

  .disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
</style>
