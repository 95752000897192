<template>
  <div>
    <div
      :style="{'background-color': backgroundColor}"
      class="badge-icon d-flex align-items-center justify-content-center"
    >
      <i :class="`${icon} ${color} m-0`" />
    </div>
  </div>
</template>

<script>
export default {
  name: "BadgeIcon",
  props: {
    icon: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    backgroundColor: {
      type: String,
      default: "#E1E9F4"
    },
  }
}
</script>

<style lang="scss"
       scoped>
.badge-icon {
  border-radius: 50%;
  height: 32px;
  width: 32px;
}
</style>
