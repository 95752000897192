<template>
  <create-note v-if="type === 'internal-note'" />
  <create-activity
    v-else
    :show-modal="showModal"
    :type="type"
    @close="activityCreated"
  />
</template>

<script>
import CreateNote from "./create-note.vue";
import CreateActivity from "./create-activity.vue";

export default {
  name: "FormActivity",
  components: {CreateActivity, CreateNote},
  props: {
    type: {
      type: String,
      required: true,
    },

    showModal: {
      type: Boolean,
      default: false,
    },
  },
  emits:['close'],
  methods:{
    activityCreated(activityId){
      this.$emit('close', activityId)
    }
  }
}
</script>
