<template>
  <hover-button
    icon="fa-regular fa-right-from-bracket"
    @click="stopInterval"
    background-color="var(--bs-white)"
    :disabled="loadingFlags.stopInterval || isExitWorkBreakDisabled"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { IntervalsReturnType } from "@/utils";
import HoverButton from "@dialer/agent/components/hover-button.vue";

export default {
  data() {
    return {
      IntervalsReturnType
    };
  },

  components: {
    HoverButton
  },

  computed: {
    ...mapGetters("system", {
      loadingFlags: "getLoadingFlags"
    }),

    ...mapGetters("agent/work-break", {
      intervalActive: "getIntervalActive",
      expired: "isExpired",
    }),

    isExitWorkBreakDisabled() {
      return this.intervalActive.return_type === IntervalsReturnType.REQUEST_RETURN && this.expired;
    }
  },

  methods: {
    ...mapActions("system", ["stopInterval"])
  }
};
</script>
