<template>
  <div
    class="container-fluid"
    :class="{'background-top': $route.name === 'campaign-ai'}"
  >
    <router-view name="insights" />
  </div>
</template>

<script>
export default {
  name: "IndexInsights",
};
</script>

<style scoped>
.background-top {
  background: linear-gradient(
    180deg,
    #e4ecf7 0%,
    #e3ecf7 7.06%,
    #e4ecf6 14.74%,
    #eff3f9 22.09%,
    #f7f8fb 28.11%,
    #f9fafc 34.29%
  )
  no-repeat;
}
</style>

