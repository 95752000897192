
<template>
  <div class="position-relative h-100">
    <card-starter
      v-if="actionStarter"
      :action="action"
    />

    <card-actions
      v-else
      :action="action"
      :node-id="id"
    />
    
    <handle
      v-if="showSourceHandle"
      type="source"
      :id="`source-${id}`"
      :position="sourcePosition"
      class="custom-handle-source"
      :class="{'disconnected-handle': !hasNextAction}"
      :connectable="true"
    />
    <handle
      v-if="action.type !== 'starter'"
      :id="`target-${id}`"
      type="target"
      :position="targetPosition"
      class="custom-handle-target"
      :class="{'disconnected-handle': !isDisconnected}"
      :connectable="true"
      :connectable-start="false"
    />
  </div>
</template>
<script>

import {mapGetters} from "vuex";
import {Handle, Position, useVueFlow} from '@vue-flow/core'
import CardActions from "@chatbot/pages/workflow/components/action-cards/card-actions.vue";
import CardStarter from "@chatbot/pages/workflow/components/action-cards/card-starter.vue";


export default {
  name: "StarterNode",
  components: {CardStarter, CardActions, Handle,},
  props: {
    id: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: '',
    },
    position: {
      type: Object,
      default: null,
    },
    sourcePosition: {
      type: String,
      default: Position.Right
    },
    targetPosition: {
      type: String,
      default: Position.Left
    },
  },
  computed:{
    Position() {
      return Position
    },
    showSourceHandle() {
      if (this.action.type === 'starter') {
        return !this.action.need_channel;
      }
      const emptyTextContent = (this.action.type === 'message' || this.action.type === 'question') && !this.action.text_content;
      if(emptyTextContent){
        return false;
      }
      return !["back", "restart", "finish", "default", "transfer", "multiple_choice"].includes(this.action.type);
    },
    action(){
      return this.data.content;
    },
    actionStarter() {
      return this.data?.content?.type === "starter"
    },
    ...mapGetters("chatbot/actions", {
      actions: "getActions",
      loadingFlags: "getLoadingFlags",
      offcanvasEditActionIsOpen: "getOffcanvasEditActionIsOpen",
      offcanvasNewActionIsOpen: "getOffcanvasNewActionIsOpen",
      selectedAction: "getSelectedAction"
    }),

    ...mapGetters('auth', {
      isManager: "isManager"
    }),
    hasNextAction(){
      return this.action.next_action_id || this.action.default_next_action || this.action.transfer_to_group_channel_id || null;
    },
    isDisconnected(){
      const {getEdges} = useVueFlow('chatbot');
      let edges = getEdges.value
      return edges.find(edge => edge.target.includes(this.action.id)) || null;
    }
  },
  watch:{
    selectedAction:{
      handler(){
        if(this.id === this.selectedAction?.id){
          this.action.type = this.selectedAction.type
          this.action.need_chat_response = this.selectedAction.need_chat_response
        }
      },deep:true
    }
  }
}
</script>


<style scoped lang="scss">

.custom-handle-target{
  z-index: 1;
  width: 12px;
  height: 12px;
  background-color: #AFAFAF;
  border: 2px solid #AFAFAF;
  top: 32px;
}

.custom-handle-source{
  z-index: 1;
  width: 12px;
  height: 12px;
  top: calc(100% - 28px);
  background-color: #AFAFAF;
  border: 2px solid #AFAFAF;
}
.disconnected-handle{
  background-color: #FFF;
  border: 2px solid #AFAFAF;
}
</style>