export default {
  getLoadingFlags: state => state.loadingFlags,
  getSettingsIA: state => state.settingsIA,
  getError: state => state.errors,
  getDashboardIA: state => state.dashboardIA,
  getCampaignsIA: state => state.campaigns,
  getMetricsCampaign: state => state.metricsCampaign,
  getCampaignsAgentsIA: state => state.campaignsAgents,
  getAgentsCalls: state => state.agentsCalls,
  getCampaignsPagination: state => state.campaignsPagination,
  getCampaignsAgentsPagination: state => state.campaignAgentsPagination,
  getAgentCallsPagination: state => state.agentCallsPagination,
  getCampaignParams: state =>
    Object.assign(
      { page: state.campaignsPagination.current_page },
      state.campaignsFilters
    ),
  getCampaignAgentsParams: state =>
    Object.assign(
      { page: state.campaignAgentsPagination.current_page },
      state.campaignAgentsFilters
    ),
  getAgentCallsParams: state =>
    Object.assign(
      { page: state.agentCallsPagination.current_page },
      state.agentsCallsFilters
    ),
  getCurrentTab: state => state.currentTab
};
